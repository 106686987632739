const SEPA_TYPE_OF_REQUESTS = [
  {
    value: 'RECALL_REQUEST_FOR_WITHDRAWAL',
    label: 'sepa.RECALL_REQUEST_FOR_WITHDRAWAL',
    type: 'Payout',
  },
  {
    value: 'BANK_TRANSFER_RECALL',
    label: 'sepa.BANK_TRANSFER_RECALL',
    type: 'Payout',
  },
  {
    value: 'WITHDRAWAL_ISSUED',
    label: 'sepa.WITHDRAWAL_ISSUED',
    type: 'Payin',
  },
  {
    value: 'WITHDRAWAL_RECEIVED',
    label: 'sepa.WITHDRAWAL_RECEIVED',
    type: 'Payout',
  },
  {
    value: 'BANK_TRANSFER_ISSUED',
    label: 'sepa.BANK_TRANSFER_ISSUED',
    type: 'Payout',
  },
  {
    value: 'BANK_TRANSFER_RECEIVED',
    label: 'sepa.BANK_TRANSFER_RECEIVED',
    type: 'Payin',
  },
  {
    value: 'ICS_REQUEST',
    label: 'sepa.ICS_REQUEST',
    type: 'Other',
  },
  {
    value: 'BANK_INFORMATION_REQUEST',
    label: 'sepa.BANK_INFORMATION_REQUEST',
    type: 'Other',
  },
];

export default SEPA_TYPE_OF_REQUESTS;
