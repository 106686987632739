import templateService from '../../services/TemplateService';
import { setGlobalError } from '../GlobalError';

import {
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FAILED,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
} from '../Types';

import {
  userCanReadTemplate,
  userCanCreateTemplate,
  userCanUpdateTemplate,
} from '../../selectors/rightsSelector/templateRequests';

import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

export const getTemplates = () => async (dispatch, getState) => {
  try {
    if (!userCanReadTemplate(getState())) {
      dispatch({
        type: FETCH_TEMPLATES,
        payload: [],
      });
      return;
    }

    const companyId = selectLoggedUserCompanyId(getState());

    const result = await templateService.fetchTemplates({
      company_id: companyId,
    });
    dispatch({
      type: FETCH_TEMPLATES,
      payload: result.data,
    });
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: FETCH_TEMPLATES_FAILED, error });
  }
};

export const createTemplate = (values, cb) => async (dispatch, getState) => {
  try {
    if (!userCanCreateTemplate(getState())) {
      cb();
      return;
    }

    // eslint-disable-next-line no-param-reassign
    values.company_id = selectLoggedUserCompanyId(getState());

    const response = (await templateService.createTemplate(values)).data;
    dispatch({ type: CREATE_TEMPLATE, payload: response });
    cb(null);
  } catch (error) {
    cb(error);
  }
};

/* eslint-disable-next-line */
export const updateTemplate =
  (id, values, cb) => async (dispatch, getState) => {
    try {
      if (!userCanUpdateTemplate(getState())) {
        cb();
        return;
      }

      await templateService.updateTemplate(id, values);
      dispatch({ type: UPDATE_TEMPLATE, payload: { values, id } });
      cb(null);
    } catch (error) {
      cb(error);
    }
  };
