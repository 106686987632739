import roleServices from '../../services/RoleService';
import { setGlobalError } from '../GlobalError';
import {
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILED,
  CREATE_ROLE,
  UPDATE_ROLE,
  UPDATE_USER_ROLE,
  FETCH_USER_ROLES,
  FETCH_USER_ROLES_SUCCESS,
  FETCH_USER_ROLES_FAILED,
} from '../Types';

import {
  userCanCreateUserRoleManagement,
  userCanReadUserRoleManagement,
  userCanUpdateUserRoleManagement,
} from '../../selectors/rightsSelector/userRoleManagementRequests';

import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

export const fetchRoles = () => async (dispatch, getState) => {
  try {
    if (!userCanReadUserRoleManagement(getState())) {
      dispatch({
        type: FETCH_ROLES,
        payload: [],
      });

      return;
    }

    const companyId = selectLoggedUserCompanyId(getState());
    dispatch({ type: FETCH_ROLES });

    const result = await roleServices.fetchRoles({ company_id: companyId });

    dispatch({
      type: FETCH_ROLES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: FETCH_ROLES_FAILED, error });
  }
};

export const createRole = (values, cb) => async (dispatch, getState) => {
  try {
    if (!userCanCreateUserRoleManagement(getState())) {
      cb();
      return;
    }

    const companyId = selectLoggedUserCompanyId(getState());

    // eslint-disable-next-line no-param-reassign
    values.company_id = companyId;

    const result = await roleServices.createRole(values);
    dispatch({
      type: CREATE_ROLE,
      payload: result.data,
    });
    cb();
  } catch (error) {
    cb(error);
  }
};

export const updateRole = (id, values, cb) => async (dispatch, getState) => {
  try {
    if (!userCanUpdateUserRoleManagement(getState())) {
      cb();
      return;
    }

    const result = await roleServices.updateRole(id, values);
    dispatch({
      type: UPDATE_ROLE,
      payload: {
        id,
        data: result.data,
      },
    });
    dispatch({
      type: UPDATE_USER_ROLE,
      payload: {
        id,
        data: result.data,
      },
    });
    cb();
  } catch (error) {
    cb(error);
  }
};

export const fetchUserRoles = () => async (dispatch) => {
  try {
    dispatch({ type: FETCH_USER_ROLES }); // TODO: FETCHING LOGGED USER ROLES

    const { data, msg } = await roleServices.fetchAuthUserRoles();
    if (!data.length) {
      dispatch({ type: FETCH_USER_ROLES_FAILED, msg });
    } else {
      dispatch({
        type: FETCH_USER_ROLES_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    dispatch({ type: FETCH_USER_ROLES_FAILED, error });
  }
};
