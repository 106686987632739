/* eslint-disable-next-line */
export default ({ id, clients, clientImport }) => {
  // typeof is here to fix the issue with the late januaray 2020 refactoring using MUIDdatatables
  // id is expected to be a string it is a class coming from the selectClientId hook when click
  // on plus button from the list's header

  if (clientImport) {
    const clientSelected = clientImport.clientList[id];
    return clientSelected;
  }
  if (id && typeof id === 'string') {
    const client = { ...clients.data[id] };
    client.clientHasDifferentBillingAddress = !!client.adresses.length;
    return clients.data[id];
  }
  return {
    name: '',
    description: '',
    intracom_vat: '',
    address: '',
    adresses: [],
    zipcode: '',
    city: '',
    country: 'FR',
    contact: '',
    email: '',
    siret: '',
    title: '',
    iban: '',
    bic: '',
    isCompany: true,
    is_prospect: false,
    clientHasDifferentBillingAddress: false,
  };
};
