/* Package */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';

/* Services */
import { fetchWalletTransaction } from 'actions/WalletActions';

/* Components */
import Layout from 'components/Layout';
import Header from 'components/Header';
import CircularProgressCentered from 'components/CircularProgressCentered';
import SEPARequestInput from 'containers/BankBBF/components/SEPARequestInput';
import SEPARequestSelect from 'containers/BankBBF/components/SEPARequestSelect';
import SEPARequestCheckbox from 'containers/BankBBF/components/SEPARequestCheckbox';
import SEPAConfirmCard from 'containers/BankBBF/components/SEPAConfirmCard';

const SEPAViewRequestForm = () => {
  const { state: locationState } = useLocation();
  const { t } = useTranslation();

  const loggedUserEmail = useSelector((state) => state.loggedUser.user.email);

  const [transactionInfo, setTransactionInfo] = useState(null);

  const payMutationId = locationState?.payMutationId || '';
  const typeOfRequest = locationState?.typeOfRequest || '';
  const payMutationType = locationState?.payMutationType || '';
  const subject = locationState?.subject || '';
  const isComplaint = locationState?.isComplaint || '';
  const isCriticalRequestWithImmediateEscalation =
    locationState?.isCriticalRequestWithImmediateEscalation || '';
  const description = locationState?.description || '';

  useEffect(() => {
    const getTransactionInfo = async () => {
      const responseData = await fetchWalletTransaction(
        new URLSearchParams({
          foreignId: payMutationId,
        }).toString()
      );
      setTransactionInfo(responseData.data);
    };
    getTransactionInfo();
  }, [payMutationId]);

  return (
    <SetupLayout>
      <LoadingIndicator isLoading={false}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <SEPARequestInput
              name="email"
              subLabel="Eu esse consectetur dolore ut exercitation culpa quis sit duis exercitation mollit."
              label={t('sepa.email')}
              type="email"
              field={{
                value: loggedUserEmail,
              }}
              readOnly
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestInput
              name="payInOutId"
              label={
                typeOfRequest === 'Payin'
                  ? t('sepa.pay_in_id')
                  : t('sepa.payout_id')
              }
              field={{
                value: payMutationId,
              }}
              type="text"
              subLabel="Eu esse consectetur dolore ut exercitation culpa quis sit duis exercitation mollit."
              readOnly
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestSelect
              type="select"
              name="typeOfRequest"
              label={t('sepa.type_of_request')}
              subLabel="Eu esse consectetur dolore ut exercitation culpa quis sit duis exercitation mollit."
              selectOptionType={payMutationType}
              field={{
                value: typeOfRequest,
              }}
              readOnly
            />
          </Grid>

          <Grid item xs={6}>
            <SEPARequestInput
              name="subject"
              label={t('sepa.subject')}
              subLabel="Eu esse consectetur dolore ut exercitation culpa quis sit duis exercitation mollit."
              type="text"
              field={{
                value: subject,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestInput
              name="description"
              subLabel="Eu esse consectetur dolore ut exercitation culpa quis sit duis exercitation mollit. Ad aute in nostrud id irure amet cupidatat consequat."
              label={t('sepa.description')}
              type="text"
              multiline={true}
              rows={8}
              field={{
                value: description,
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestCheckbox
              name="agree"
              label={t('sepa.complaint')}
              subLabel="Une réclamation utilisateur est une demande d'un de vos utilisateurs exprimant un mécontentement. Ici, elle nécessite une action de la part de Treezor. Il est obligatoire de respecter un délai de 15 jours pour y apporter une réponse (pouvant être étendu à 35 jours en cas d'analyse approfondie nécessaire et après avoir informé votre client). Nous fournir les date de réception initiale et d'accusé réception par vos soins est indispensable pour respecter les délais."
              field={{
                checked: isComplaint,
              }}
              disabled
            />
          </Grid>
          <Grid item xs={6}>
            <SEPARequestCheckbox
              name="agree2"
              label={t('sepa.critical_request')}
              subLabel="Cette case est à cocher lorsque la demande est critique et demande une intervention immédiate."
              field={{
                checked: isCriticalRequestWithImmediateEscalation,
              }}
              disabled
            />
          </Grid>

          <Grid item xs={6}>
            <SEPAConfirmCard
              foreignId={transactionInfo?.foreignId}
              transactionType={transactionInfo?.transactionType}
              amount={transactionInfo?.amount}
              currency={transactionInfo?.currency}
              createdDate={transactionInfo?.createdDate}
            />
          </Grid>
        </Grid>
      </LoadingIndicator>
    </SetupLayout>
  );
};

function SetupLayout({ children }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Layout
      header={
        <Header
          name={t('sepa.SEPA_ticket_detail')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={children}
    />
  );
}

function LoadingIndicator(props) {
  const { children, isLoading } = props;
  return <>{isLoading ? <CircularProgressCentered /> : children}</>;
}

LoadingIndicator.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

SetupLayout.propTypes = {
  children: PropTypes.node,
};

export default SEPAViewRequestForm;
