import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, TextField } from '@material-ui/core';

const BeforAfterDate = ({
  onBeforeChange,
  onAfterChange,
  before,
  after,
  beforeTitle,
  afterTitle,
}) => {
  return (
    <>
      <InputLabel id="demo-mutiple-checkbox-label">{afterTitle}</InputLabel>
      <TextField
        type="date"
        value={after}
        onChange={(e) => {
          onAfterChange(e.target.value);
        }}
        inputProps={{ max: before }}
      />
      <InputLabel id="demo-mutiple-checkbox-label">{beforeTitle}</InputLabel>
      <TextField
        type="date"
        value={before}
        onChange={(e) => {
          onBeforeChange(e.target.value);
        }}
        inputProps={{ min: after }}
      />
    </>
  );
};

BeforAfterDate.propTypes = {
  onBeforeChange: PropTypes.func.isRequired,
  onAfterChange: PropTypes.func.isRequired,
  before: PropTypes.string,
  after: PropTypes.string,
  beforeTitle: PropTypes.string,
  afterTitle: PropTypes.string,
};

export default BeforAfterDate;
