import React, { useState, useEffect } from 'react';
import { Field } from 'formik';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { storeScanOCR } from 'actions/ScanOCRActions';

import AppTextInput from '../../AppTextInput';
import AppButton from '../../AppButton';
import SignUpService from '../../../services/SignUpService';
// import { AppDividerM } from '../../AppDividers';
import LoadingDialog from '../../LoadingDialog';
import {
  AppTable,
  AppTableCell,
  AppTableBody,
  AppTableRow,
} from '../../AppTableComponents';
import capitalizeForm from '../../../helpers/capitalizeForm';
import capitalize from '../../../helpers/capitalize';
import legalFormArray from '../../../assets/legalForm';

const useStyles = makeStyles(() => ({
  companyLabel: {
    marginLeft: '5px',
    fontSize: '12px',
    fontWeight: 'normal',
    color: 'rgb(136, 136, 136)',
  },
  companyLabelRequire: {
    marginLeft: '5px',
    fontSize: '12px',
    fontWeight: 'normal',
    color: 'red',
  },
}));

const AppCompanySearch = ({
  t,
  values,
  fetchInfoGreffe,
  setFieldValue,
  isClient,
  onSelect,
  disabled = false,
  require = true,
  isOnboarding = false,
}) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [infoGreffeState, setInfoGreffeState] = useState({
    infoGreffeReturn: false,
    companiesReturned: null,
    loadingInfoGreffe: false,
    noSearchResults: false,
  });
  const scanOCR = useSelector((state) => state.scanOCR);

  const _oninfoGreffe = (err, data) => {
    if (!err && data.length) {
      setInfoGreffeState({
        infoGreffeReturn: true,
        companiesReturned: data,
        loadingInfoGreffe: false,
        noSearchResults: false,
      });
    } else {
      setInfoGreffeState({
        infoGreffeReturn: false,
        noSearchResults: true,
        loadingInfoGreffe: false,
        companiesReturned: null,
      });
    }
  };

  const fetchRcs = (zipcode, city) => {
    if (zipcode && zipcode.match(/[\d]{5}/) && city) {
      SignUpService.getRcs(zipcode, city).then((res) => {
        if (res.data.rcs) setFieldValue('rcs', res.data.rcs);
      });
    }
  };

  const _selectCompany = (company) => {
    if (onSelect) onSelect();
    fetchRcs(company.code_postal, company.ville);
    setFieldValue('address', company.adresse);
    setFieldValue('zipcode', company.code_postal);
    setFieldValue('city', company.ville);
    setFieldValue('name', capitalize(company.denomination));
    if (!isClient) {
      const tmpLegalForm = _.find(
        legalFormArray,
        (elem) => elem.code === company.id_forme_juridique
      );
      setFieldValue('business_name', company.nom_commercial);
      setFieldValue('siren', company.siren);
      setFieldValue('nic', company.nic);
      setFieldValue('code_ape', company.code_ape);
      setFieldValue('legal_form_id', company.id_forme_juridique);
      // console.log('company.id_forme_juridique:', company.id_forme_juridique);
      // console.log('tmpLegalForm:', tmpLegalForm);
      setFieldValue(
        'legal_form',
        tmpLegalForm !== undefined ? tmpLegalForm.display : ''
      );
      setFieldValue(
        'intracomvat',
        `FR ${(3 * (company.siren % 97) + 12) % 97}${company.siren}`
      );
    } else {
      setFieldValue('siret', `${company.siren}${company.nic}`);
      setFieldValue('country', 'FR');
    }

    setInfoGreffeState({
      infoGreffeReturn: false,
      noSearchResults: false,
      companiesReturned: null,
      loadingInfoGreffe: false,
    });
  };

  const _fetchInfoGreffe = () => {
    const { name } = values; // 81275451300016

    if (scanOCR.siret && scanOCR.isAutoCreateProvider) {
      setFieldValue('name', scanOCR.siret);

      setInfoGreffeState({ ...infoGreffeState, loadingInfoGreffe: true });
      fetchInfoGreffe(scanOCR.siret, _oninfoGreffe);
      return;
    }

    if (name) {
      setInfoGreffeState({ ...infoGreffeState, loadingInfoGreffe: true });
      fetchInfoGreffe(name, _oninfoGreffe);
    }
  };

  useEffect(() => {
    if (values.searchInfoGreffe) {
      _fetchInfoGreffe();
      setFieldValue('searchInfoGreffe', false);
    }
  }, [values.searchInfoGreffe]);

  useEffect(() => {
    if (scanOCR.isAutoCreateProvider) {
      _fetchInfoGreffe();
      dispatch(
        storeScanOCR({
          siret: '',
          isAutoCreateProvider: false,
        })
      );
    }
  }, []);

  return (
    <>
      {infoGreffeState.loadingInfoGreffe && (
        <LoadingDialog title={t('signup.company.search_greffe')} />
      )}
      {/* <AppDividerM /> */}
      <Field
        name="name"
        label={t('signup.company.name')}
        placeholder={t('signup.company.name')}
        component={AppTextInput}
        value={values.name}
        withSearchIcon
        onIconClick={_fetchInfoGreffe}
        color="secondary"
        mandatory={require}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            e.preventDefault();
            _fetchInfoGreffe();
          }
        }}
        onChange={(e) => {
          capitalizeForm(e, setFieldValue);
        }}
        disabled={disabled}
      />
      {!infoGreffeState.infoGreffeReturn && (
        <label
          className={
            isOnboarding ? classes.companyLabelRequire : classes.companyLabel
          }
        >
          {t('signup.company.helper_info_greffe')}
        </label>
      )}
      {infoGreffeState.infoGreffeReturn && (
        <AppTable>
          <AppTableBody>
            {_.map(infoGreffeState.companiesReturned, (company, index) => (
              <AppTableRow key={index} useStyle={{ margin: '30px' }}>
                <AppTableCell setWidthManualy="20%">{`${t(
                  'signup.company.name'
                )}: ${company.denomination}`}</AppTableCell>
                <AppTableCell setWidthManualy="35%">
                  {`${t('signup.company.address')}: ${company.adresse} ${
                    company.code_postal
                  } ${company.ville}`}
                </AppTableCell>
                <AppTableCell setWidthManualy="30%">
                  {`${t('signup.company.siren')}: ${company.siren}, ${t(
                    'signup.company.nic'
                  )}: ${company.nic}`}
                </AppTableCell>
                <AppTableCell setWidthManualy="15%">
                  <AppButton
                    color="secondaryLight"
                    text={t('signup.company.select_company')}
                    onClick={() => _selectCompany(company)}
                  />
                </AppTableCell>
              </AppTableRow>
            ))}
          </AppTableBody>
        </AppTable>
      )}

      {infoGreffeState.noSearchResults && (
        <AppTable>
          <AppTableBody>
            <AppTableRow useStyle={{ margin: '30px' }}>
              <AppTableCell setWidthManualy="20%">
                {`${t('signup.company.no_results')}`}
              </AppTableCell>
            </AppTableRow>
          </AppTableBody>
        </AppTable>
      )}
    </>
  );
};
AppCompanySearch.propTypes = {
  setFieldValue: PropTypes.func,
  t: PropTypes.func,
  values: PropTypes.object,
  fetchInfoGreffe: PropTypes.func,
  isClient: PropTypes.bool,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  require: PropTypes.bool,
  isOnboarding: PropTypes.bool,
};
AppCompanySearch.propTypes = {};

export default AppCompanySearch;
