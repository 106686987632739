import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchInternalActivities = async (filter) =>
  axios.get(`${process.env.REACT_APP_ACTIVITIES_URL}/activities`, {
    params: filter,
  });

const createActivity = async (activity) =>
  axios.post(`${process.env.REACT_APP_ACTIVITIES_URL}/activities`, activity);

const updateActivity = async (activity) =>
  axios.put(
    `${process.env.REACT_APP_ACTIVITIES_URL}/activities/${activity._id}`,
    activity
  );

const deleteActivity = async (id) =>
  axios.delete(`${process.env.REACT_APP_ACTIVITIES_URL}/activities/${id}`);

export default {
  fetchInternalActivities,
  createActivity,
  updateActivity,
  deleteActivity,
};
