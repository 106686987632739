import _ from 'lodash';

import {
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_FAILED,
  LOGOUT_USER_SUCCESS,
  ADD_ACTIVITY,
  UPDATE_ACTIVITY,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

export default (state = {}, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_ACTIVITIES:
      return _.mapKeys(action.payload, '_id');
    case ADD_ACTIVITY: {
      return { ...state, [action.payload._id]: action.payload };
    }
    case UPDATE_ACTIVITY: {
      return {
        ...state,
        [action.payload._id]: {
          ...state[action.payload._id],
          ...action.payload,
        },
      };
    }
    case FETCH_ACTIVITIES_FAILED:
      return [];
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return [];
    default:
      return state;
  }
};
