import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
    marginTop: '10px',
  },
  pricingContainer: {
    gridGrap: '8px',
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit,minmax(350px,350px))',
    justifyContent: 'center',
    width: '100%',
  },
  tabs: {
    width: 'fit-content',
    margin: 'auto',
    border: '1px solid hsla(0,0%,10%,.1)',
    borderRadius: '22px',
    backgroundColor: 'hsla(0,0%,10%,.05)',
  },
  appBar: {
    backgroundColor: '#ffff',
    boxShadow: 'none',
  },
  button: {
    borderRadius: '22px',
  },
  buttonActive: {
    borderRadius: '22px',
    color: '#ffffff !important',
    backgroundColor: '#3d319d',
    boxShadow: '0 2px 4px 0 rgba(0,0,0,.07), 0 1px 1.5px 0 rgba(0,0,0,.05)',
    fontWeight: '500',
  },
}));

export default useStyles;
