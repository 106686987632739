import s3FileService from 'services/S3FileService';
import { getFileExtension } from 'helpers/utils';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line import/prefer-default-export
export const uploadUserFileInS3 = async (
  dispatch,
  file,
  indentityFile,
  company,
  setFieldValue
) => {
  const ext = getFileExtension(file.name);
  const folder = `company-files-${company.alternativeId}`;
  const name = `${uuidv4()}-${new Date().toISOString()}.${ext}`;
  const filename = `${folder}/${name}`;
  try {
    /* eslint-disable-next-line */
    await s3FileService.upload(file, folder, name);
    setFieldValue(indentityFile, filename);
  } catch (error) {
    throw new Error({ message: 'error upload file' });
  }
};
