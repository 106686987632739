export const discovery = {
  every_time: ['0', '1', '2'],
  monthly_free_period: ['0', '1', '2', '3'],
  monthly_refund_period: ['0', '1', '2', '3'],
  monthly_non_refund_period: ['0', '1', '2', '3'],
};
export const standard = {
  monthly_free_period: ['0', '1', '2', '3'],
  monthly_refund_period: ['0', '1', '2', '3'],
  monthly_non_refund_period: ['0', '1', '2', '3'],

  yearly_free_period: ['0', '1', '2', '3'],
  yearly_refund_period: ['0', '1', '2', '3'],
  yearly_non_refund_period: ['0', '1', '2', '3'],
};
export const efficiency = {
  monthly_free_period: ['0', '1', '2', '3'],
  monthly_refund_period: ['0', '1', '2', '3'],
  monthly_none_refund_period: ['0', '1', '2', '3'],

  yearly_free_period: ['0', '1', '2', '3'],
  yearly_refund_period: ['0', '1', '2', '3'],
  yearly_none_refund_period: ['0', '1', '2', '3'],
};

export const DISCOVERY = 'discovery';
export const STANDARD = 'standard';
export const EFFICIENCY = 'efficiency';

export const MAPPING_CANCEL_SUBSCRIPTION_TYPE = {
  MONTHLY_FREE_PERIOD: 'monthly_free_period',
  MONTHLY_REFUND_PERIOD: 'monthly_refund_period',
  MONTHLY_NON_REFUND_PERIOD: 'monthly_non_refund_period',
  YEARLY_FREE_PERIOD: 'yearly_free_period',
  YEARLY_REFUND_PERIOD: 'yearly_refund_period',
  YEARLY_NON_REFUND_PERIOD: 'yearly_non_refund_period',
};

export const INTERVAL_NONE = 'every_time';
