import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { fetchCompanySubscription } from 'actions/CompanySubscription';
import { fetchCompanySubscriptionHistory } from 'actions/CompanySubscriptionHistory';
import Header from '../../../components/Header';
import { useRouter } from '../../../hooks/routerHooks';
import { IconRefresh } from '../../../components/AppIcons';
import { useThunkDispatch } from '../../../hooks/generalHooks';

const SubscriptionHeader = () => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const { history } = useRouter();

  const fetchProvidersUsersCb = () => {
    dispatch(fetchCompanySubscription());
    dispatch(fetchCompanySubscriptionHistory());
  };

  return (
    <Header
      name={t('settings_section.subscription.card_title')}
      spaceBetween
      goBack={() => {
        history.goBack();
      }}
    >
      <IconRefresh onClick={fetchProvidersUsersCb} />
    </Header>
  );
};

SubscriptionHeader.propTypes = {
  openProviderFormDialog: PropTypes.func,
};

export default SubscriptionHeader;
