import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
// import classNames from 'classnames';
import style from '../../assets/jss/components/appTableComponents';

const AppTableColGroup = ({
  // classes,
  columns,
}) => (
  <colgroup>
    {!!columns &&
      columns.map((value, index) => (
        <col
          className={value.props ? value.props.columnClassName : {}}
          key={index}
        />
      ))}
  </colgroup>
);
AppTableColGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  columns: PropTypes.array,
};
AppTableColGroup.defaultProps = {
  columns: null,
};

export default withStyles(style)(AppTableColGroup);
