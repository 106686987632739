import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { useRouter } from 'hooks/routerHooks';
import IconClients from 'assets/images/clientIcon.svg';
import IconUsers from 'assets/images/usersIcon.svg';

import { userHasUserManagementAccessFeature } from 'selectors/rightsSelector/userManagementRequests';
import { EmojiObjectsSharp } from '@material-ui/icons';

const SideBar = () => {
  const { t } = useTranslation();
  const { history } = useRouter();

  const hasUserManagementAccess = useSelector(
    userHasUserManagementAccessFeature
  );

  function renderClientLink() {
    return (
      <Fragment>
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            marginBottom: '16px',
            // boxShadow: '1px 4px 4px 0 #34223B',
          }}
          onClick={() => history.push('/settings/providers')}
        >
          <div
            style={{
              backgroundColor: 'white',
              border: '1px solid #3d319d',
              height: '60px',
              width: '60px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }}
          >
            <img
              src={IconClients}
              alt={t('settings_section.providers.card_title')}
            />
          </div>
          <p
            style={{
              color: '#3d319d',
              textTransform: 'uppercase',
              fontSize: '11px',
              lineHeight: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('settings_section.providers.card_title')}
          </p>
        </div>

        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            marginBottom: '16px',
            // boxShadow: '1px 4px 4px 0 #34223B',
          }}
          onClick={() => history.push('/projects')}
        >
          <div
            style={{
              backgroundColor: 'white',
              border: '1px solid #3d319d',
              height: '60px',
              width: '60px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }}
          >
            <EmojiObjectsSharp style={{ fontSize: '45px' }} color="primary" />
          </div>
          <p
            style={{
              color: '#3d319d',
              textTransform: 'uppercase',
              fontSize: '11px',
              lineHeight: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('settings_section.clients.client_project')}
          </p>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      {renderClientLink()}

      {hasUserManagementAccess && (
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            marginBottom: '16px',
            // boxShadow: '1px 4px 4px 0 #34223B',
          }}
          onClick={() => history.push('/settings/users')}
        >
          <div
            style={{
              backgroundColor: 'white',
              border: '1px solid #3d319d',
              height: '60px',
              width: '60px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }}
          >
            <img
              src={IconUsers}
              alt={t('settings_section.users.card_title')}
              style={{ width: '36px', height: '40px' }}
            />
          </div>
          <p
            style={{
              color: '#3d319d',
              textTransform: 'uppercase',
              fontSize: '11px',
              lineHeight: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('settings_section.users.card_title')}
          </p>
        </div>
      )}
    </Fragment>
  );
};

export default SideBar;
