import * as Yup from 'yup';
import { PHONE_NUMBER_VALIDATE_SCHEMA } from 'constants/form';

const validationSchema = () => {
  const REQUIRED = 'Ce champ est obligatoire';
  const Schema = Yup.object().shape({
    dummy: Yup.string(),
    taxResidence: Yup.object({
      country: Yup.boolean()
        .oneOf(
          [true],
          "Si vous n'êtes pas un résidant fiscal français, merci de bien vouloir nous contacter"
        )
        .required(),
    }),
    title: Yup.string().required(REQUIRED),
    specifiedUSPerson: Yup.boolean()
      .oneOf(
        [true],
        'Si vous êtes un citoyen Américain, merci de bien vouloir nous contacter'
      )
      .required(),
    lastname: Yup.string().required(REQUIRED),
    firstname: Yup.string().required(REQUIRED),
    userEmail: Yup.string().required().email(REQUIRED),
    userMobile: Yup.string()
      .matches(
        PHONE_NUMBER_VALIDATE_SCHEMA,
        "pas d'espace et doit commencer par +33 exemple :  +33683817316"
      )
      .required(REQUIRED),
    birthday: Yup.string().required(REQUIRED).nullable(),
    placeOfBirth: Yup.string().required(REQUIRED),
    birthCountry: Yup.object().required(REQUIRED),
    nationality: Yup.object().required(REQUIRED),
  });
  return Schema;
};
/* eslint-disable import/prefer-default-export */
export { validationSchema };
