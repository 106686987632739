import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import {
  AppTable,
  AppTableBody,
  AppTableRow,
  AppTableCell,
} from '../../AppTableComponents';
import { AppTextSubTitle } from '../../AppText';

import AbstractExpense from './AbstractExpense';

const ExpensesList = ({ data: { expenses }, t }) => {
  if (!expenses) return <></>;
  // console.log('all expenses', expenses);
  return (
    <>
      <AppTextSubTitle>{'Liste des frais'}</AppTextSubTitle>
      <AppTable>
        <AppTableBody>
          <AppTableRow>
            <AppTableCell>{t('expenses.form_date')}</AppTableCell>
            <AppTableCell>{t('No')}</AppTableCell>
            <AppTableCell>{t('expenses.expense.form_user')}</AppTableCell>
            <AppTableCell>{t('expenses.list.category')}</AppTableCell>
            <AppTableCell alignRight>
              {t('expenses.expense.withoutvat')}
            </AppTableCell>
            <AppTableCell alignRight>
              {t('expenses.expense.vatrate')}
            </AppTableCell>
            <AppTableCell alignRight>
              {t('expenses.expense.vat_amount')}
            </AppTableCell>
            <AppTableCell alignRight>
              {t('expenses.expense.withvat')}
            </AppTableCell>
            <AppTableCell alignRight>
              {t('expenses.expense.form_paymenttype')}
            </AppTableCell>
          </AppTableRow>
          {map(expenses, (value) => (
            <AbstractExpense key={value._id} expense={value} />
          ))}
        </AppTableBody>
      </AppTable>
    </>
  );
};

ExpensesList.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func,
};

export default ExpensesList;
