/* eslint-disable no-template-curly-in-string */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import mapPropsToValues from './formstate';
import handle from './handleSubmit';

import state from './state';
import dispatch from './dispatch';

import LoadingIndicator from '../../../components/LoadingIndicatorDialog';
import SwitchField from '../../../components/FormElements/SwitchField';

import styles from '../../../assets/jss/root';
import RichTextEditor from '../../../components/richTextEditor';
import AppButton from '../../../components/AppButton';
import { AppTextHeader } from '../../../components/AppText';

class EmailTemplateForm extends Component {
  constructor(props) {
    super(props);
    this.editorRef = React.createRef();
    this.htmlContent = props.values.content;
    this.showEditable = props.values.company_id === 'template_fr';
    this.state = { editable: !(props.values.company_id === 'template_fr') };
  }

  renderLoading(isSubmitting) {
    const { t } = this.props;
    if (isSubmitting) {
      return <LoadingIndicator title={t('loading')} open={true} />;
    }
    return null;
  }

  onSubmit() {
    const { setFieldValue, closeDialog, handleSubmit } = this.props;
    if (!this.state.editable) {
      if (closeDialog) closeDialog();
      return;
    }
    setFieldValue('content', this.editorRef.current.htmlToGet);
    handleSubmit();
  }

  render() {
    const { values, isSubmitting, t, errors } = this.props;

    return (
      <div className="section p-10">
        {this.renderLoading(isSubmitting)}
        <Form>
          <Grid container spacing={2}>
            {errors && errors.internalError && (
              <Grid item xs={12}>
                <div className="row">
                  <div>
                    <span style={{ color: 'red' }}>{errors.internalError}</span>
                  </div>
                </div>
              </Grid>
            )}
            <Grid item xs={12}>
              <AppTextHeader>{values.description}</AppTextHeader>
            </Grid>
            <Grid item xs={12}>
              <div className="row" style={{ fontSize: 10 }}>
                (ref: {values.reference})
              </div>
            </Grid>
            {this.showEditable && (
              <Grid item xs={12}>
                <SwitchField
                  name={'editable'}
                  labelLeft={`${t('forms.edit')}:`}
                  value={this.state.editable}
                  onChange={() => {
                    this.setState({ editable: !this.state.editable });
                  }}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <RichTextEditor
                ref={this.editorRef}
                initialHtml={this.htmlContent}
                readOnly={!this.state.editable}
              />
              <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                {t('company.card_title')}
                <br />
                {`${t('settings_section.users.form_label_name')} ${t(
                  'settings_section.users.form_label_firstname'
                )}`}
              </span>
            </Grid>

            <Grid item container xs={12} justify="flex-end">
              <AppButton
                isDisabled={isSubmitting}
                color="secondaryLight"
                text={t('draft')}
                type="button"
                onClick={() => {
                  this.onSubmit();
                }}
                noBorder={true}
              />
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  }
}

EmailTemplateForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  values: PropTypes.object.isRequired,
  company: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  closeDialog: PropTypes.func,
  errors: PropTypes.object,
};

const EmailTemplateWithForm = withFormik({
  displayName: 'EmailTemplateForm',
  enableReinitialize: true,
  mapPropsToValues,
  handleSubmit: handle,
})(EmailTemplateForm);

const EmailTemplateFormWithStyles = withStyles(styles)(EmailTemplateWithForm);

const TranslatedEmailTemplateFormWithStyles = withTranslation()(
  EmailTemplateFormWithStyles
);

export default connect(state, dispatch)(TranslatedEmailTemplateFormWithStyles);
