import {
  GLOBAL_ERROR,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

const INITIAL_STATE = [];

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GLOBAL_ERROR:
      // return [...state, action.payload];
      return [...Object.assign(state, [action.payload])];
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return INITIAL_STATE;
    default:
      return state;
  }
};
