import * as yup from 'yup';
import { PHONE_NUMBER_VALIDATE_SCHEMA, REGEX } from 'constants/form';
import { isValidEmail } from '../../../helpers/utils';

/* eslint import/prefer-default-export: off */
export const validationSchema = ({ t }) =>
  yup.object().shape({
    name: yup.string().required(t('forms.mandatory_field')),
    firstname: yup.string().required(t('forms.mandatory_field')),
    description: yup.string(),
    iban: yup
      .string()
      .max(34, t('settings_section.clients.iban_validation'))
      .min(23, t('forms.iban_minimum'))
      .matches(REGEX.IBAN, t('forms.iban_invalid')),
    bic: yup.string().max(11, t('settings_section.clients.bic_validation')),
    email: yup
      .string()
      .email(t('signup.company.email_valid_mandatory'))
      .test('wrongMail', t('signup.company.email_valid_mandatory'), (value) => {
        if (!isValidEmail(value)) {
          return false;
        }
        return true;
      })
      .required(t('signup.company.email_mandatory')),
    phonenumber: yup
      .string()
      .required(t('signup.company.phonenumber_mandatory'))
      .matches(
        PHONE_NUMBER_VALIDATE_SCHEMA,
        t('signup.company.valid_phone_number'),
        {
          excludeEmptyString: false,
        }
      ),
    role: yup.array().min(1, 'At least one checkbox is required'),
  });
