import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PinInput from 'react-pin-input';
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import SecurityIcon from '@material-ui/icons/Security';
// import { Autocomplete } from 'formik-material-ui-lab';
import { useFormikContext } from 'formik';
import {
  Grid,
  Typography,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  // TextField,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ErrorMessage from '../../ErrorMessage';
import Security from './security';
import Alert from './Alert';

const TreezorCardConfigField = ({ virtual, cardId }) => {
  const { setFieldValue, errors, touched, setFieldTouched } =
    useFormikContext();
  const { t } = useTranslation();
  const [securitySelected, setSecuritySelected] = useState(true);
  const [alertSelected, setAlertSelected] = useState(false);

  const renderListTitleSelected = () => {
    if (securitySelected) return t('security_parameters');
    if (alertSelected) return t('limits_and_alerts');
    // if (scheduleSelected) return 'jours et plages horaires autorisées';
    return '';
  };

  const renderComponentSelected = () => {
    if (securitySelected) return <Security virtual={virtual} />;
    if (alertSelected) return <Alert />;
    // if (scheduleSelected) return <Schedule />;
    return null;
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant="button"
          style={{ fontSize: '20px', color: '#02cbcd' }}
        >
          {cardId ? t('card_config') : t('card_creation')}
        </Typography>
      </Grid>
      {/* <Grid item xs={12} sm={6}>
        <Typography variant="subtitle1">
          À qui est destinée la carte ?
        </Typography>
        <Field
          name="userId"
          component={Autocomplete}
          options={users}
          getOptionLabel={(option) =>
            `${option.title} ${option.firstname} ${option.lastname} - ${option.email}`
          }
          // style={{ width: 300 }}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              variant="outlined"
              margin="dense"
              error={touched.userId && !!errors.userId}
              helperText={touched.userId && errors.userId}
              label={t('expenses.advance.form_label_payee')}
            />
          )}
        />
      </Grid> */}
      {!cardId && (
        <Grid item xs={12} sm={6}>
          {virtual ? null : (
            <div
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle1">
                {t('choose_pin_code')}
              </Typography>
              <PinInput
                length={4}
                initialValue=""
                // secret
                onChange={(value) => {
                  setFieldValue('pin', value);
                  setFieldTouched('pin', true);
                }}
                type="numeric"
                style={{ padding: '10px' }}
                inputStyle={{ borderColor: '#02cbcd' }}
                inputFocusStyle={{ borderColor: 'blue' }}
                // onComplete={(value, index) => {}}
              />
              <ErrorMessage
                error={errors.pin ? errors.pin : ''}
                showError={errors && errors.pin && touched && touched.pin}
              />
            </div>
          )}
        </Grid>
      )}
      <Grid container>
        <Grid item xs={12} md={3}>
          <Typography
            variant="button"
            style={{ fontSize: '20px', color: 'grey' }}
          >
            {t('list_of_parameters')}
          </Typography>
          <List component="nav" aria-label="main mailbox folders">
            <ListItem
              button
              onClick={() => {
                setSecuritySelected(true);
                setAlertSelected(false);
                // setScheduleSelected(false);
              }}
              selected={securitySelected}
            >
              <ListItemIcon>
                <SecurityIcon />
              </ListItemIcon>
              <ListItemText primary={t('security_parameters')} />
            </ListItem>
            <ListItem
              button
              onClick={() => {
                setSecuritySelected(false);
                setAlertSelected(true);
                // setScheduleSelected(false);
              }}
              selected={alertSelected}
            >
              <ListItemIcon>
                <NotificationImportantIcon />
              </ListItemIcon>
              <ListItemText primary={t('limits_and_alerts')} />
            </ListItem>
            {/*           <ListItem
                      button
                      onClick={() => {
                      setSecuritySelected(false);
                      setAlertSelected(false);
                      setScheduleSelected(true);
                      }}
                      selected={scheduleSelected}
                  >
                      <ListItemIcon>
                      <DateRangeIcon />
                      </ListItemIcon>
                      <ListItemText primary="jours et plages horaires" />
                  </ListItem> */}
          </List>
        </Grid>
        <Grid item xs={12} md={9}>
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: '9px' }}>
              <Typography
                variant="button"
                style={{ fontSize: '20px', color: '#02cbcd' }}
              >
                {renderListTitleSelected()}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {renderComponentSelected()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

TreezorCardConfigField.propTypes = {
  closetreezorCardConfigField: PropTypes.func,
  virtual: PropTypes.bool,
  cardId: PropTypes.number,
};

export default TreezorCardConfigField;
