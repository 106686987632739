import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Form, Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import { updateCategory } from 'actions/CategoriesActions';

import AppButton from 'components/AppButton';
import TextField from 'components/FormElements/textField';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';

import { validationSchema } from './validation';
import AddType from './AddType';

const formatSubmitAccounting = (arr = [], vatRates = [], account) => {
  const newArr = [...arr];
  const result = newArr.map((item) => {
    const currVat = vatRates.find((vat) => vat._id === item.vatRateId);
    return {
      vatRateId: item.vatRateId,
      vatRate: currVat.value,
      accountNumber: Number(
        String(account).slice(0, 3) + String(item.accountNumber)
      ),
    };
  });
  return result;
};

const formatInitAccounting = (arr = []) => {
  if (!arr.length) return arr;

  const newArr = arr.map((item) => ({
    ...item,
    accountNumber: item.accountNumber?.toString().substring(3),
  }));
  return newArr;
};

const CategoryForm = ({
  showDialog,
  closeCategoryFormDialog,
  selectedCateInfo,
}) => {
  const { t } = useTranslation();

  const initValues = {
    display: selectedCateInfo?.display_modified || selectedCateInfo?.display,
    account:
      selectedCateInfo?.account_modified || selectedCateInfo?.account || '',
    isVatRates: !!selectedCateInfo?.tvaAccounting?.length || false,
    intracom: !!selectedCateInfo?.intracomAccounting?.length || false,
    importation: !!selectedCateInfo?.importationAccounting?.length || false,
    ss: selectedCateInfo?.contractAccounting
      ? !!Object.keys(selectedCateInfo?.contractAccounting).length || false
      : false,
    tvaAccounting: formatInitAccounting(selectedCateInfo?.tvaAccounting || []),
    intracomAccounting: formatInitAccounting(
      selectedCateInfo?.intracomAccounting || []
    ),
    importationAccounting: formatInitAccounting(
      selectedCateInfo?.importationAccounting || []
    ),
    ssValue: selectedCateInfo?.contractAccounting
      ? formatInitAccounting([selectedCateInfo?.contractAccounting] || [])
      : [],
  };

  const categories = useSelector((state) => state.categories);
  const loggedUserCompany = useSelector((state) => state.loggedUserCompany);
  const vatRates = useSelector((state) => state.fullVatRates);
  const dispatch = useDispatch();

  function cb(err, action) {
    const { setStatus, setErrors } = action;
    if (err) {
      setStatus({ success: false });
      setErrors({
        internalError: t('forms.internal_error'),
      });
    } else {
      setStatus({ success: true });
      closeCategoryFormDialog();
    }
  }

  function onSubmit(values, action) {
    const oldCategory = categories?.data?.[selectedCateInfo._id];

    // format
    const tvaAccounting = values?.isVatRates
      ? formatSubmitAccounting(values.tvaAccounting, vatRates, values.account)
      : null;
    const intracomAccounting = values?.intracom
      ? formatSubmitAccounting(
          values.intracomAccounting,
          vatRates,
          values.account
        )
      : null;
    const importationAccounting = values?.importation
      ? formatSubmitAccounting(
          values.importationAccounting,
          vatRates,
          values.account
        )
      : null;

    const contractAccounting =
      values.ss && values.ssValue.length > 0
        ? {
            accountNumber: Number(
              String(values.account).slice(0, 3) +
                String(values.ssValue[0]?.accountNumber)
            ),
          }
        : null;

    const vatIds = tvaAccounting?.map((item) => ({ id: item.vatRateId }));
    const format = {
      ...selectedCateInfo,
      ...values,
      tvaAccounting,
      intracomAccounting,
      importationAccounting,
      contractAccounting,
      vat: { ...selectedCateInfo.vat, vatRates: vatIds },
    };

    delete format.isVatRates;
    delete format.intracom;
    delete format.importation;
    delete format.ss;
    delete format.ssValue;

    async function updatingCategory() {
      const res = await updateCategory(
        dispatch,
        loggedUserCompany.company._id,
        format,
        oldCategory
      );
      cb(res.message, action);
    }
    updatingCategory();
  }

  if (showDialog) {
    return (
      <div className="section p-10">
        <Formik
          initialValues={initValues}
          validationSchema={validationSchema({ t })}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            values,
            handleChange,
            setFieldValue,
            errors,
            handleSubmit,
            dirty,
          }) => (
            <>
              {isSubmitting && (
                <LoadingIndicatorDialog
                  title={t('loading_single')}
                  open={true}
                />
              )}
              <Form
                onSubmit={(e) => {
                  if (!dirty) {
                    closeCategoryFormDialog();
                    return;
                  }
                  handleSubmit(e);
                }}
              >
                <Grid container spacing={2}>
                  {/* row of display */}
                  <Grid item xs={12}>
                    <TextField
                      name="display"
                      label={t('category.display')}
                      placeholder={t('category.display')}
                      color="secondary"
                      id="display"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      name="account"
                      disabled
                      fullWidth
                      label={t('category.account')}
                      placeholder={t('category.account')}
                      id="account"
                      onChange={handleChange}
                      defaultValue={initValues.account}
                    />
                  </Grid>

                  {values.isVatRates && (
                    <AddType
                      errors={errors}
                      handleChange={handleChange}
                      title={t('category.vat_info')}
                      values={values}
                      name="tvaAccounting"
                      maxLength={5}
                      options={vatRates}
                    />
                  )}
                  {values.intracom && (
                    <AddType
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      title={t('category.intracom_info')}
                      name="intracomAccounting"
                      values={values}
                      options={vatRates}
                      maxLength={4}
                    />
                  )}
                  {values.importation && (
                    <AddType
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      title={t('category.import_info')}
                      name="importationAccounting"
                      values={values}
                      options={vatRates}
                      maxLength={4}
                    />
                  )}
                  {values.ss && (
                    <AddType
                      errors={errors}
                      setFieldValue={setFieldValue}
                      handleChange={handleChange}
                      title={t('category.sub_contract')}
                      name="ssValue"
                      values={values}
                      maxLength={1}
                      options={vatRates}
                      isContract
                    />
                  )}

                  <Grid item container xs={12} justify="flex-end">
                    <AppButton
                      isDisabled={isSubmitting}
                      color="transparentGrey"
                      text={t('cancel')}
                      onClick={closeCategoryFormDialog}
                    />
                    <AppButton
                      isDisabled={isSubmitting}
                      color="secondaryLight"
                      text={t('draft')}
                      type="submit"
                      noBorder={true}
                    />
                  </Grid>
                </Grid>
              </Form>
            </>
          )}
        </Formik>
      </div>
    );
  }

  return null;
};

CategoryForm.propTypes = {
  showDialog: PropTypes.bool,
  closeCategoryFormDialog: PropTypes.func,
  selectedCateInfo: PropTypes.object,
};

export default CategoryForm;
