import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { map } from 'lodash';

import spinnerService from 'services/SpinnerService';
import Layout from '../../../components/Layout';
import AppPlusButton from '../../../components/AppPlusButton';

import { IconEdit, IconRefresh } from '../../../components/AppIcons';
import Header from '../../../components/Header';

import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';

import { useRouter } from '../../../hooks/routerHooks';
import { useThunkDispatch } from '../../../hooks/generalHooks';

import { getTemplates } from '../../../actions/TemplateActions';

import {
  userCanReadTemplate,
  userCanCreateTemplate,
  userCanUpdateTemplate,
} from '../../../selectors/rightsSelector/templateRequests';

const TemplatesList = () => {
  const dispatch = useThunkDispatch();
  const { t } = useTranslation();
  const { history } = useRouter();

  useEffect(() => {
    spinnerService.startSpinner();
    dispatch(getTemplates()).finally(() => spinnerService.endSpinner());
  }, []);

  const templates = map(useSelector((state) => state.templates)).map(
    (template) => ({
      id: template._id,
      display: template.display,
    })
  );

  const canReadTemplate = useSelector(userCanReadTemplate);
  const canCreateTemplate = useSelector(userCanCreateTemplate);
  const canUpdateTemplate = useSelector(userCanUpdateTemplate);

  const getTemplatesCb = useCallback(
    () => dispatch(getTemplates()),
    [dispatch]
  );

  function renderList() {
    const columns = [
      {
        label: t('template.table_name'),
        name: 'display',
      },
      {
        label: ' ',
        name: 'id',
        options: {
          viewColumns: false,
          filter: false,
          sort: false,
          search: false,
          // eslint-disable-next-line react/display-name
          customBodyRender: (value) => {
            if (!canUpdateTemplate) {
              return null;
            }

            return (
              <IconEdit
                sm
                onClick={() => {
                  history.push(`/settings/template/edit/${value}`);
                }}
              />
            );
          },
        },
      },
    ];

    return (
      <MUIDataTable
        data={templates}
        columns={columns}
        options={getMuiDatatableOptions(t, templates.length)}
      />
    );
  }

  return (
    <Layout
      header={
        <Header
          name={t('template.title')}
          spaceBetween
          goBack={() => {
            history.goBack();
          }}
        >
          <IconRefresh onClick={getTemplatesCb} disabled={!canReadTemplate} />
          {canCreateTemplate && (
            <AppPlusButton
              label={t('create')}
              onClick={() => {
                history.push(`/settings/template/new`);
              }}
            />
          )}
        </Header>
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderList()}
    />
  );
};

export default TemplatesList;
