import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppDialog from '../AppDialog';

function ConfirmDialog({
  toggleDialog,
  confirmMessage,
  title,
  isShowDialog,
  onConfirm,
  type,
  isCancelConfirm,
  cancelText,
  confirmText,
}) {
  const { t } = useTranslation();
  let isCancel = false;
  if (
    type === 'importError' ||
    type === 'importEmpty' ||
    type === 'selectedEmpty'
  ) {
    isCancel = true;
  } else {
    isCancel = false;
  }

  const handleOnConfirm = (isConfirm) => {
    toggleDialog(false);
    onConfirm(isConfirm, type);
  };
  if (isShowDialog) {
    return (
      <AppDialog
        sm
        footer
        center={isCancel}
        title={isCancel ? '' : title || t('sure')}
        closeDialog={() => {
          handleOnConfirm(false);
        }}
        onConfirmText={cancelText || t('yes')}
        onCancelText={isCancelConfirm ? confirmText || t('no') : false}
        onConfirm={() => {
          handleOnConfirm(true);
        }}
        contentText={confirmMessage}
        color="primaryLight"
      />
    );
  }

  return null;
}

ConfirmDialog.propTypes = {
  toggleDialog: PropTypes.func,
  confirmMessage: PropTypes.string,
  title: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
  isShowDialog: PropTypes.bool,
  type: PropTypes.bool,
  onConfirm: PropTypes.func,
  isCancelConfirm: PropTypes.bool,
};

export default ConfirmDialog;
