import { authInstance as axios } from 'helpers/axiosInterceptor';

const getAllPDFOriginal = (companyId, userId, qs) =>
  axios.get(`${process.env.REACT_APP_CREA}/pdf-pool-items?${qs}`, {
    headers: {
      'x-user-id': userId,
    },
  });

const getPDFOriginal = (companyId, userId, originalId) =>
  axios.get(
    `${process.env.REACT_APP_CREA}/pdf-pool-item?splitPDFPoolId=${originalId}`,
    {
      headers: {
        'x-user-id': userId,
      },
    }
  );

const getPDFHistory = (companyId, userId, originalId, qs) =>
  axios.get(
    `${process.env.REACT_APP_CREA}/pdf-pool-items/history?splitPDFPoolId=${originalId}&${qs}`,
    {
      headers: {
        'x-user-id': userId,
      },
    }
  );

const splitPDF = (companyId, userId, data) =>
  axios.post(`${process.env.REACT_APP_CREA}/split-pdf`, data, {
    headers: {
      'x-user-id': userId,
    },
  });

const renameOriginalFile = (formData) =>
  axios.put(`${process.env.REACT_APP_CREA}/pdf-pool-item/rename`, formData);

const renameSplitFile = (formData) =>
  axios.put(
    `${process.env.REACT_APP_CREA}/pdf-pool-item/history/rename`,
    formData
  );

const archiveHistoryFile = (formData) =>
  axios.put(
    `${process.env.REACT_APP_CREA}/pdf-pool-item/history/archive`,
    formData
  );

const markAsDone = (formData) =>
  axios.put(
    `${process.env.REACT_APP_CREA}/pdf-pool-item/mark-as-done`,
    formData
  );

const updateAfterCreateExpense = (formData) =>
  axios.put(
    `${process.env.REACT_APP_CREA}/pdf-pool-item/history/update-after-create-expense`,
    formData
  );

const storeDepositPoolItem = (formData) =>
  axios.post(`${process.env.REACT_APP_CREA}/deposit-pool-item/save`, formData);

const getDepositPoolItems = async (userId, qs) => {
  // const response = await axios.get(
  //   `http://localhost:3001/deposit-pool-items?${qs}`,
  //   {
  //     headers: {
  //       'x-user-id': userId,
  //     },
  //   }
  // );
  const response = await axios.get(
    `${process.env.REACT_APP_CREA}/deposit-pool-items?${qs}`,
    {
      headers: {
        'x-user-id': userId,
      },
    }
  );
  return response;
};

const renameDepositPoolItemFile = async (formData) =>
  axios.put(`${process.env.REACT_APP_CREA}/deposit-pool-item/rename`, formData);

const archiveDepositPoolItemFile = async (formData) =>
  axios.put(
    `${process.env.REACT_APP_CREA}/deposit-pool-item/archive`,
    formData
  );

const markAsUsedDepositPoolItemFile = async (formData) =>
  axios.put(
    `${process.env.REACT_APP_CREA}/deposit-pool-item/mark-as-used`,
    formData
  );

const updateTagsDepositPoolItemFile = async (formData) =>
  axios.put(
    `${process.env.REACT_APP_CREA}/deposit-pool-item/update-tags`,
    formData
  );

export default {
  getAllPDFOriginal,
  getPDFOriginal,
  getPDFHistory,
  splitPDF,
  renameOriginalFile,
  renameSplitFile,
  archiveHistoryFile,
  markAsDone,
  updateAfterCreateExpense,
  storeDepositPoolItem,
  renameDepositPoolItemFile,
  getDepositPoolItems,
  archiveDepositPoolItemFile,
  markAsUsedDepositPoolItemFile,
  updateTagsDepositPoolItemFile,
};
