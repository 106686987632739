import React from 'react';
import PropTypes from 'prop-types';
import colors from '../../constants/colors.json';

const AppError = ({ error, style, display }) => (
  <div
    style={{
      fontSize: '10px',
      color: colors.red,
      fontFamily: 'Kumbh Sans',
      letterSpacing: '0.36px',
      ...style,
    }}
  >
    {display ? error : null}
  </div>
);

AppError.propTypes = {
  error: PropTypes.string,
  style: PropTypes.object,
  display: PropTypes.bool,
};

AppError.defaultProps = {
  display: true,
};

export default AppError;
