/* eslint-disable */

/* import packages */

import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';

/* import Material UI */
import { Box, Stepper, Step, StepButton } from '@material-ui/core';

/* import components */
import CreateDebitFirstStepYesOption from './CreateDebitFirstStepYesOption';
import CreateDebitSecondStepYesOption from './CreateDebitSecondStepYesOption';
import CreateDebitThirdStepYesOption from './CreateDebitThirdStepYesOption';
import VerificationSettingUpDebitStep from './CreateDebitVerificationStep';
import CreateDebitFinalStepYesOption from './CreateDebitFinalStepYesOption';

/* import services */
import payoutService from 'services/PayoutService';

/* import validation schema */
import validationSchema from './validation';
import { getFileExtension } from 'helpers/utils';
import uploadFile from 'helpers/fileUploadHelper';

const BankBBFDebitCreationStepper = (props) => {
  const { handleOpenDebitCreationSection, handleOpenTableDebitSection } = props;

  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const currentWallet = useSelector((state) =>
    state.wallet && state.wallet.wallet && state.wallet.wallet.length > 0
      ? state.wallet.wallet[0]
      : {}
  );
  const currentCompany = useSelector(
    (state) => state.loggedUserCompany.company
  );
  const providers = useSelector((state) => state.providers);
  const { _id: userId } = useSelector((state) => state.loggedUser.user);

  const translateDomain = (stepLabel) =>
    `debits_page.debits_creation_page.steps.${stepLabel}`;

  const stepTitles = [
    translateDomain('step1.step_title'),
    translateDomain('step2.step_title'),
    translateDomain('step3.step_title'),
    translateDomain('step5.step_title'),
  ];

  const totalSteps = () => {
    return stepTitles.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? stepTitles.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const stepComponents = [
    <CreateDebitFirstStepYesOption
      key="1"
      handleContinue={handleComplete}
      handleCancel={handleBack}
    />,
    <CreateDebitSecondStepYesOption
      key="2"
      handleContinue={handleComplete}
      handleCancel={handleBack}
    />,
    <CreateDebitThirdStepYesOption
      key="3"
      handleContinue={handleComplete}
      handleCancel={handleBack}
    />,
    <VerificationSettingUpDebitStep
      key="4"
      handleCancel={handleBack}
      handleContinue={handleComplete}
    />,
  ];

  const handleUploadFile = async (file) => {
    const filePath = await uploadFile(file, currentCompany);
    return filePath;
  };

  const handleOnSubmit = async (values) => {
    handleComplete();

    const { provider: entityOId, amount, otp, files } = values;

    const { iban, bic, beneficiaryId } = providers[entityOId];

    const { walletId } = currentWallet;

    const filePath = await handleUploadFile(files[0]);

    const formatedData = {
      amount,
      otp,
      walletId,
      // payoutDocuments,
      source: filePath,
      iban,
      bic,
      beneficiaryId,
      entityOId,
      userId,
      payoutTypeId: 2,
      // expenseId,
    };

    console.log(formatedData);

    // actions.setSubmitting(true);
    const response = await payoutService.createPayouts(formatedData);
    console.log(
      '🚀 ~ file: index.js ~ line 131 ~ handleOnSubmit ~ response',
      response
    );
  };

  return (
    <Box sx={{ width: '100%' }} style={{ height: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {stepTitles.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {t(label)}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <CreateDebitFinalStepYesOption
            handleCreateNewDebit={handleOpenDebitCreationSection}
            handleViewDebit={handleOpenTableDebitSection}
          />
        ) : (
          <React.Fragment>
            <Formik
              onSubmit={handleOnSubmit}
              initialValues={{
                files: null,
                provider: '',
                category: '',
                urm: '',
                ics: '',
                paymentType: '',
                amount: '',
                otp: '',
              }}
              // validationSchema={validationSchema}
            >
              {stepComponents[activeStep]}
            </Formik>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
};

BankBBFDebitCreationStepper.propTypes = {
  handleOpenDebitCreationSection: PropTypes.func,
  handleOpenTableDebitSection: PropTypes.func,
};

export default BankBBFDebitCreationStepper;
