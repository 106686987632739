import { bindActionCreators } from 'redux';
import { changeAvatar, changePassport } from '../../actions/UserActions';

export default (dispatch) =>
  bindActionCreators(
    {
      changePassport,
      changeAvatar,
    },
    dispatch
  );
