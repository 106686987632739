/* eslint-disable prefer-template */
/* eslint-disable no-plusplus */
/* eslint-disable import/prefer-default-export */
import FileSaver from 'file-saver';
import XLSX from 'xlsx';
import {
  PDFName,
  PDFDict,
  PDFArray,
  PDFStream,
  decodePDFRawStream,
} from 'pdf-lib';

export const loadExcelFileToObj = async (filePath = '') => {
  try {
    const response = await fetch(filePath);
    const data = await response.arrayBuffer();
    const workbook = XLSX.read(data, { type: 'array' });
    const sheetName = workbook.SheetNames[0];
    const sheet = workbook.Sheets[sheetName];
    const jsonData = XLSX.utils.sheet_to_json(sheet);
    return jsonData;
  } catch (error) {
    console.error(error);
  }
  return null;
};

const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.csv';

export const exportToCSV = (csvData, fileName) => {
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'csv', type: 'array' });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName + fileExtension);
};

export function xmlToJson(xml) {
  const obj = {};

  // Element node
  if (xml.nodeType === 1) {
    // Attributes
    if (xml.attributes.length > 0) {
      for (let j = 0; j < xml.attributes.length; j++) {
        const attribute = xml.attributes.item(j);
        obj[
          attribute.nodeName
            .replace('ram:', '')
            .replace('rsm:', '')
            .replace('udt:', '') +
            '_' +
            attribute.localName
        ] = attribute.nodeValue;
      }
    }
    // Value of the tag
    if (
      xml.firstChild &&
      xml.firstChild.nodeType === 3 &&
      xml.childNodes.length === 1
    ) {
      obj[
        xml.nodeName.replace('ram:', '').replace('rsm:', '').replace('udt:', '')
      ] = xml.firstChild.nodeValue.trim();
      // Check if attributes exist
      if (xml.attributes.length > 0) {
        for (let j = 0; j < xml.attributes.length; j++) {
          const attribute = xml.attributes.item(j);
          obj[
            xml.nodeName
              .replace('ram:', '')
              .replace('rsm:', '')
              .replace('udt:', '') +
              '_' +
              attribute.nodeName
          ] = attribute.nodeValue;
        }
      }
    }
  } else if (xml.nodeType === 3) {
    // Text node
    return xml.nodeValue.trim();
  }

  // Child nodes
  if (xml.hasChildNodes()) {
    for (let i = 0; i < xml.childNodes.length; i++) {
      const item = xml.childNodes.item(i);
      if (item.nodeType === 1) {
        // Element node
        const nodeName = item.nodeName
          .replace('ram:', '')
          .replace('rsm:', '')
          .replace('udt:', '');
        if (typeof obj[nodeName] === 'undefined') {
          obj[nodeName] = xmlToJson(item);
        } else {
          if (typeof obj[nodeName].push === 'undefined') {
            const old = obj[nodeName];
            obj[nodeName] = [];
            obj[nodeName].push(old);
          }
          obj[nodeName].push(xmlToJson(item));
        }
      }
    }
  }

  return obj;
}

const extractRawAttachments = (pdfDoc) => {
  if (!pdfDoc.catalog.has(PDFName.of('Names'))) return [];
  const Names = pdfDoc.catalog.lookup(PDFName.of('Names'), PDFDict);

  if (!Names.has(PDFName.of('EmbeddedFiles'))) return [];
  const EmbeddedFiles = Names.lookup(PDFName.of('EmbeddedFiles'), PDFDict);

  if (!EmbeddedFiles.has(PDFName.of('Names'))) return [];
  const EFNames = EmbeddedFiles.lookup(PDFName.of('Names'), PDFArray);

  const rawAttachments = [];
  for (let idx = 0, len = EFNames.size(); idx < len; idx += 2) {
    const fileName = EFNames.lookup(idx);
    const fileSpec = EFNames.lookup(idx + 1, PDFDict);
    rawAttachments.push({ fileName, fileSpec });
  }

  return rawAttachments;
};
export const extractAttachments = async (pdfDoc) => {
  const rawAttachments = extractRawAttachments(pdfDoc);
  return Promise.all(
    rawAttachments.map(async ({ fileName, fileSpec }) => {
      const stream = fileSpec
        .lookup(PDFName.of('EF'), PDFDict)
        .lookup(PDFName.of('F'), PDFStream);
      const data = await decodePDFRawStream(stream).decode();
      const blob = new Blob([data], { type: 'text/xml' }); // Assuming the data is XML
      return new File([blob], fileName.decodeText(), { type: 'text/xml' });
    })
  );
};
