import NumberFormat from './NumberFormat';

/*
 * desc: handle input currency with decimal Character
 * @param {number} val
 * @param {string} decimalChar
 * @param {string} exceptDecimalChar
 * @return {boolean|string}
 */
function getNumberFormatInput(
  val,
  decimalChar,
  exceptDecimalChar,
  language = 'fr'
) {
  const value =
    language === 'fr' ? val.replace(exceptDecimalChar, decimalChar) : val;
  const lastChar = value.charAt(value.length - 1);

  if (value.split(decimalChar).length > 2 || lastChar === exceptDecimalChar)
    return null;

  if (Number.isNaN(Number(lastChar)) && lastChar !== decimalChar) return null;

  if (lastChar === decimalChar) {
    return value;
  }
  const number = NumberFormat.getNumber(value, decimalChar, ' ');

  if (number < 0) return null;
  return value;
}

const InputHelper = {
  getNumberFormatInput,
};

export default InputHelper;
