import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/Layout';
import RefundBankInfoHeader from './components/header';
import Form from './Form';

const RefundBankInfo = () => {
  return (
    <Layout
      header={<RefundBankInfoHeader />}
      showUserCard={false}
      body={<Form />}
    />
  );
};

RefundBankInfo.propTypes = {
  values: PropTypes.object.object,
  setFieldValue: PropTypes.func,
  setValues: PropTypes.func,
};

export default RefundBankInfo;
