import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { withFormik, Form } from 'formik';
import { isEmpty, find } from 'lodash';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import TextField from 'components/FormElements/textField';
import { useTranslation, withTranslation } from 'react-i18next';
import AppButton from 'components/AppButton';
import AppCompanyInfoSettings from 'components/AppCompany/AppCompanyInfoSettings';
import AppCompanySearch from 'components/AppCompany/AppCompanySearch';
import SignUpService from 'services/SignUpService';
import SelectAutocomplete from 'components/SelectAutocomplete';
import AppCheckbox from 'components/AppCheckbox';
import {
  LOCAL_STORAGE_PERSONAL_INFO,
  LOCAL_STORAGE_SUBSCRIPTION,
} from 'config/Constants';
import capitalizeForm from 'helpers/capitalizeForm';
import UseLocalStorage from 'hooks/useLocalStorage';
import mapPropsToValues from './formstate';
import { validationSchema } from './validation';
import dispatch from './dispatch';
import state from './state';
import storagePersonalInfo from './storagePersonalInfor';
// import { titleCode } from '../utils';

const PersonalInfo = ({
  values,
  fetchInfoGreffe,
  setFieldValue,
  setPersonalInfo,
  require = false,
  isAccountInfo,
  isRaisonSociale,
  errors,
  companyType,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [fieldsDisplay, setFieldsDisplay] = React.useState(false);
  const [confirmInfo, setConfirmInfo] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const location = useLocation();
  const { getData: getSubscription } = UseLocalStorage(
    LOCAL_STORAGE_SUBSCRIPTION
  );
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    localStorage.removeItem(LOCAL_STORAGE_PERSONAL_INFO);
  }, []);

  useEffect(() => {
    if (companyType) {
      setFieldValue('companyType', companyType);
    }
  }, [companyType]);

  const checkDisabled = () => {
    if (!confirmInfo) {
      return true;
    }
    if (
      confirmInfo ||
      !isRaisonSociale ||
      (!isEmpty(values.name) &&
        !isEmpty(values.address) &&
        !isEmpty(values.zipcode) &&
        !isEmpty(values.city) &&
        !isEmpty(values.siren) &&
        !isEmpty(values.nic) &&
        !isEmpty(values.legal_form) &&
        !isEmpty(values.rcs) &&
        !isEmpty(values.code_ape))
    ) {
      return false;
    }
    return !fieldsDisplay;
  };
  const checkValidation = () => {
    const contactValidateList = [
      'userName',
      'firstname',
      'phonenumber',
      'email',
      'title',
    ];
    const validateList = [
      'name',
      'address',
      'zipcode',
      'city',
      'rcs',
      'siren',
      'code_ape',
      'nic',
      'userName',
      'firstname',
      'phonenumber',
      'email',
      'title',
    ];
    const { subscriptionId } = getSubscription();

    if (!subscriptionId) {
      setErrorMessage(t('onboarding.validate_subscriptionId'));
      return true;
    }
    setErrorMessage('');

    if (isRaisonSociale) {
      return validateList.some((element) => {
        return Object.keys(errors).includes(element);
      });
    }
    return contactValidateList.some((element) => {
      return Object.keys(errors).includes(element);
    });
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!checkValidation()) {
      const companyInfo = { ...values, companyType };
      const { subscriptionId } = getSubscription();
      if (require) {
        setPersonalInfo(companyInfo);
      } else {
        history.push(`/signup?subscription_id=${subscriptionId}`);
      }
      storagePersonalInfo(companyInfo);
    }
  };

  const fetchRcs = (name, value) => {
    setFieldValue(name, value);
    const zipcode = name === 'zipcode' ? value : values.zipcode;
    const city = name === 'city' ? value : values.city;
    if (zipcode.match(/[\d]{5}/) && city.city) {
      SignUpService.getRcs(zipcode, city).then((res) => {
        if (res.data.rcs) setFieldValue('rcs', res.data.rcs);
      });
    }
  };

  const displayTitleInCompanyMode = () => {
    const chooseTitles = [
      { label: 'M.', value: 'M.' },
      { label: 'Mme.', value: 'Mme.' },
    ];

    return (
      <Grid item xs={2}>
        <SelectAutocomplete
          required
          name="title"
          label={t('client.title')}
          onChange={(title) => {
            setFieldValue('title', title ? title.value : '');
          }}
          getOptionLabel={(option) => option.label}
          values={chooseTitles}
          valueSelected={find(
            chooseTitles,
            (elem) => elem.value === values.title
          )}
          // showError={errors.unit && touched.unit}
          // error={errors.unit}
        />
      </Grid>
    );
  };

  const displayCompany = () => {
    return (
      <>
        <AppCompanySearch
          setFieldValue={setFieldValue}
          fetchInfoGreffe={fetchInfoGreffe}
          t={t}
          values={values}
          // isClient
          require={require}
          onSelect={() => {
            setFieldsDisplay(true);
          }}
          isOnboarding
        />
        <AppCompanyInfoSettings
          t={t}
          errors={{}}
          values={values}
          setFieldValue={setFieldValue}
          history={history}
          fetchInfoGreffe={fetchInfoGreffe}
          onCodeSelected={(code) => setFieldValue('code_ape', code?.Code)}
          onZipcodeCityChange={(e) => {
            fetchRcs(e.target.name, e.target.value);
          }}
          onSirenChange={(e) => setFieldValue('siren', e.target.value)}
          isSettings={true}
          isOnboarding
        />
      </>
    );
  };
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} container justify="center">
          <h2
            style={{
              color: 'rgb(61, 49, 157)',
              fontSize: '27px',
              fontFamily: 'Kumbh Sans',
              lineHeight: '37px',
              letterSpacing: '0.5px',
            }}
          >
            {t(
              `onboarding.${
                isAccountInfo
                  ? 'account_personal_info'
                  : 'business_personal_info'
              }`
            )}
          </h2>
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <div className="row">
              <div className="input-field col s12">
                <span className="helper-text" style={{ color: 'red' }}>
                  {errorMessage}
                </span>
              </div>
            </div>
          </Grid>
        )}

        {(isRaisonSociale || queryParams.get('adf') === '1') && (
          <Grid item xs={12}>
            {displayCompany()}
          </Grid>
        )}

        <Grid container spacing={2} item style={{ display: 'flex' }}>
          {displayTitleInCompanyMode()}

          {/* Row with Firstname and lastname */}
          <Grid item xs={4} sm={5}>
            <TextField
              type="text"
              value={values.userName}
              name="userName"
              label={t('signup.name')}
              placeholder={t('signup.name')}
              color="secondary"
              onChange={(e) => {
                capitalizeForm(e, setFieldValue);
              }}
              required
            />
          </Grid>
          <Grid item xs={4} sm={5}>
            <TextField
              type="text"
              value={values.firstname}
              name="firstname"
              label={t('signup.first_name')}
              placeholder={t('signup.first_name')}
              color="secondary"
              onChange={(e) => {
                capitalizeForm(e, setFieldValue);
              }}
              required
            />
          </Grid>
        </Grid>
        {/* Row with Email */}
        <Grid item xs={5} sm={6}>
          <TextField
            type="text"
            name="email"
            label={t('signup.company.email')}
            placeholder={t('signup.company.email')}
            color="secondary"
            required
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            setWidthManualy="32%"
            label={t('settings_section.clients.form_label_phone')}
            placeholder={t('settings_section.clients.form_label_phone')}
            color="secondary"
            name="phonenumber"
            type="text"
            required
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AppCheckbox
            onClick={() => {
              setConfirmInfo(!confirmInfo);
            }}
            checked={confirmInfo}
            labelText={t('onboarding.confirm_info')}
          />
        </Grid>
        <Grid item xs={12} container justify="flex-end">
          <AppButton
            disabled={checkDisabled()}
            color="secondaryLight"
            text={t('next')}
            type="submit"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

PersonalInfo.propTypes = {
  setPersonalInfo: PropTypes.func,
  classes: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  t: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  fetchInfoGreffe: PropTypes.func.isRequired,
  require: PropTypes.bool,
  isAccountInfo: PropTypes.bool,
  isRaisonSociale: PropTypes.bool,
  errors: PropTypes.object,
  companyType: PropTypes.string,
};

const UserFormWithFormik = withFormik({
  displayName: 'PersonalInfo',
  enableReinitialize: true,
  validationSchema,
  mapPropsToValues,
  handleSubmit: () => {},
})(PersonalInfo);
const TranslatedUserFormWithStyles = withTranslation()(UserFormWithFormik);
export default connect(state, dispatch)(TranslatedUserFormWithStyles);
