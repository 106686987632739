import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import { Typography } from '@material-ui/core';

const index = ({ openCardConfig, text }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexFlow: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        width: '100%',
        border: '#02cbcd dotted 3px',
        borderRadius: '18px',
      }}
    >
      <Typography variant="subtitle1" style={{ color: '#02cbcd' }}>
        {text}
      </Typography>
      <IconButton aria-label="add" onClick={openCardConfig}>
        <AddIcon style={{ color: '#02cbcd' }} />
      </IconButton>
    </div>
  );
};

index.propTypes = {
  text: PropTypes.string,
  openCardConfig: PropTypes.func,
};

export default index;
