import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { TIME_FORMAT } from 'constants/date';
import formatUTCDate from 'helpers/dateHelper';
import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';
import { tableFrame } from '../../../helpers/mui-datatableHelper';

const useStyles = makeStyles({
  tableContainer: {
    padding: '20px',
    boxShadow:
      '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)',
  },
  title: {
    marginBottom: '20px',
  },
});

const SubscriptionHistoryList = ({
  openProviderFormDialog,
  subscriptionHistory,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dataTable = useMemo(() => {
    return subscriptionHistory.map((item) => {
      const sub = { ...item };
      sub.status = sub?.modified
        ? t('subscription_history.modified')
        : t(`subscription_history.${sub.status}`);
      sub.subscriptionType =
        sub?.subscriptionType && sub?.subscriptionType !== '-'
          ? t(`subscription_history.${sub.subscriptionType}`)
          : '-';
      return {
        ...sub,
        amount: `${(item.amount / 100).toFixed(2)} €`,
      };
    });
  }, [subscriptionHistory]);

  const tableColumns = useMemo(() => {
    const columnsHeader = [
      {
        label: t('subscription.form.start_date'),
        name: 'startDate',
        options: {
          customBodyRender: (value) => formatUTCDate(value, TIME_FORMAT.D_M_Y),
        },
      },
      {
        label: t('subscription.form.end_date'),
        name: 'endDate',
        options: {
          customBodyRender: (value) => formatUTCDate(value, TIME_FORMAT.D_M_Y),
        },
      },
      {
        label: t('subscription.form.subscription_name'),
        name: 'subscriptionName',
      },
      {
        label: t('subscription.form.subscription_type'),
        name: 'subscriptionType',
      },
      { label: t('subscription.form.amount'), name: 'amount' },
      { label: t('subscription.form.status'), name: 'status' },
    ];

    const headerStyles = {
      textAlign: 'left',
      paddingLeft: '16px',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      fontWeight: '500',
    };

    const columns = tableFrame(columnsHeader, headerStyles);

    return columns;
  }, [openProviderFormDialog]);

  return (
    <Grid xs={12}>
      <Typography className={classes.title} variant="h4">
        {t('subscription.form.history_table_title')}
      </Typography>
      <Grid xs={12} className={classes.tableContainer}>
        <MUIDataTable
          data={dataTable}
          columns={tableColumns}
          options={getMuiDatatableOptions(t, 0)}
        />
      </Grid>
    </Grid>
  );
};

SubscriptionHistoryList.propTypes = {
  openProviderFormDialog: PropTypes.func,
  subscriptionHistory: PropTypes.array,
};

export default SubscriptionHistoryList;
