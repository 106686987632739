import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'hooks/routerHooks';
import { format, endOfMonth, getYear } from 'date-fns';
import queryString from 'query-string';
import { useTheme } from '@material-ui/core';
import { getThemeColor } from 'helpers/utils';

/* import helpers */
import { getMonthLabels } from 'constants/chart';

/* import services */
import InvoiceService from 'services/InvoiceService';

/* import components */
import CircularProgressCentered from 'components/CircularProgressCentered';
import ApexChart from 'components/ApexChart';
import AppDashboardCard from '../components/AppDashboardCard';

/* import assets */
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../../../assets/css/react_dates_custom.css';

const InvoiceStatisticOverview = () => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [invoicesData, setInvoicesData] = useState({});
  const [quotationsData, setQuotationsData] = useState({});
  const [creditNotesData, setCreditNotesData] = useState({});
  const [otherPurchasesData, setOtherPurchasesData] = useState({});
  const theme = useTheme();

  const fetchAllInvoices = async () => {
    try {
      setLoading(true);
      const response = await InvoiceService.fetchInvoicePaginate(
        'pagination=false'
      );
      const data = response.data.docs || [];

      const dataTemplate = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

      const groupDataFollowMonth = (type = 'invoice') => {
        return data
          .filter(
            (item) =>
              new Date(item.created_at).getFullYear() ===
                new Date().getFullYear() &&
              (item.state === 'draft' || item.state === 'pending')
          )
          .reduce(
            (acc, current) => {
              if (current.type !== type) return acc;
              const month = new Date(current.created_at).getMonth();
              acc[month] += 1;

              return acc;
            },
            [...dataTemplate]
          );
      };

      const quotations = groupDataFollowMonth('quotation');
      setQuotationsData({ type: 'quotation', quotations });

      const invoices = groupDataFollowMonth('invoice');
      setInvoicesData({ type: 'invoice', invoices });

      const creditNotes = groupDataFollowMonth('credit_note');
      setCreditNotesData({ type: 'credit_note', creditNotes });

      const orderPurchases = groupDataFollowMonth('order_form');
      setOtherPurchasesData({ type: 'order_form', orderPurchases });
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const getMonthFollowChart = (chartBarIndex = 0) => {
    return format(
      new Date(`${getYear(new Date())}/${chartBarIndex}/01`),
      'yyyy-MM-dd'
    );
  };

  const getElementAtEvent = ({ dataPointIndex, seriesIndex, seriesName }) => {
    let elementType = '';

    // eslint-disable-next-line default-case
    switch (seriesName) {
      case t('counter_chart.invoice'):
        elementType = 'invoice';
        break;
      case t('counter_chart.credit_note'):
        elementType = 'credit_note';
        break;
      case t('counter_chart.purchase_other'):
        elementType = 'order_form';
        break;
      case t('counter_chart.quotation'):
        elementType = 'quotation';
        break;
    }

    const invoiceFilters = {
      type: [elementType],
      state: ['draft', 'pending'],
      after: getMonthFollowChart(dataPointIndex + 1, seriesIndex),
    };

    invoiceFilters.before = format(
      endOfMonth(new Date(invoiceFilters.after)),
      'yyyy-MM-dd'
    );

    history.push(
      queryString.stringifyUrl(
        {
          url: 'invoices/list',
          query: invoiceFilters,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  const chartData = {
    series: [
      {
        name: t('counter_chart.invoice'),
        data: invoicesData.invoices,
      },
      {
        name: t('counter_chart.quotation'),
        data: quotationsData.quotations,
      },
      {
        name: t('counter_chart.credit_note'),
        data: creditNotesData.creditNotes,
      },
      {
        name: t('counter_chart.purchase_other'),
        data: otherPurchasesData.orderPurchases,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection(event, chartContext, opts) {
            const {
              dataPointIndex,
              seriesIndex,
              w: {
                globals: { seriesNames },
              },
            } = opts;
            getElementAtEvent({
              dataPointIndex,
              seriesIndex,
              seriesName: seriesNames[seriesIndex],
            });
          },
        },
      },

      colors: [
        getThemeColor(theme.palette.primary[300]),
        getThemeColor(theme.palette.primary[500]),
        getThemeColor(theme.palette.primary[700]),
        getThemeColor(theme.palette.primary.main),
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
        x: {
          formatter(value) {
            return `${value} ${getYear(new Date())}`;
          },
        },
      },
      labels: getMonthLabels(t),
      yaxis: {
        labels: {
          style: {
            colors: [
              getThemeColor(theme.palette.neutral[400]),
              getThemeColor(theme.palette.neutral[500]),
            ],
            fontSize: '0.875rem',
            fontWeight: 600,
          },
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: getThemeColor(theme.palette.neutral[500]),
            fontSize: '0.75rem',
            fontWeight: 500,
          },
        },
      },
      legend: {
        labels: {
          colors: getThemeColor(theme.palette.neutral[700]),
        },
        markers: {
          shape: 'circle',
        },
      },
    },
  };

  useEffect(() => {
    fetchAllInvoices();
  }, []);

  return (
    <AppDashboardCard title={t('dasboard.activity_follow_up')}>
      {error && <p>{error}</p>}
      {!loading ? (
        <ApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
        />
      ) : (
        <CircularProgressCentered />
      )}
    </AppDashboardCard>
  );
};

export default InvoiceStatisticOverview;
