import {
  FETCH_DISTANCES_RATES,
  FETCH_DISTANCES_RATES_FAILED,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

import { groupBy } from '../../helpers/utils';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_DISTANCES_RATES: {
      let distanceRates = action.payload;
      if (!distanceRates) return state;
      distanceRates = groupBy(distanceRates, 'vehicule', true);

      // eslint-disable-next-line
      for (const key in distanceRates) {
        distanceRates[key] = groupBy(distanceRates[key], 'power');
      }
      return distanceRates;
    }
    case FETCH_DISTANCES_RATES_FAILED:
      return {};
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return {};
    default:
      return state;
  }
};
