export const PENDING = 'PENDING';
export const VALIDATED = 'VALIDATED';
export const NEEDED = 'NEEDED';

export const CARDS = 'CARDS';
export const REFUSED = 'REFUSED';
export const DOCS_NEEDED = 'DOCS_NEEDED';
export const TREEZOR_PENDING = 'TREEZOR_PENDING';
export const LOADING = 'LOADING';

// Card status
export const LOCK = 'LOCK';
export const UNLOCK = 'UNLOCK';
export const LOST = 'LOST';
export const STOLEN = 'STOLEN';

export const APP_USE_TREEZOR = process.env.REACT_APP_USE_TREEZOR === 'true';
