import React, { useEffect, useRef } from 'react';
import { cancelRequests } from '../../helpers/axiosInterceptor';

// eslint-disable-next-line react/display-name
const withUnmounted = (WrappedComponent) => (props) => {
  const isMount = useRef();
  isMount.current = true;

  useEffect(() => {
    isMount.current = true;
    return () => {
      isMount.current = false;
      cancelRequests();
    };
  }, []);

  return <WrappedComponent isMounted={isMount.current} {...props} />;
};

export default withUnmounted;
