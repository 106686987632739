import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import classNames from 'classnames';
import style from '../../assets/jss/components/appSelect';
import UserCard from '../UserCard';

const AppSelectUserCard = ({
  classes,
  color,
  fullWidth,
  onButtonClose,
  maxHeight,
  heigherIndex,
}) => {
  const [displayDropdown, toggleDropdown] = useState(false);

  function onAppButtonClick() {
    if (!!onButtonClose && displayDropdown === true) {
      // the dropdown is visible and we have a valid onButtonClose function => We close the dropdown and execute the callback
      onButtonClose();
      toggleDropdown(!displayDropdown);
    } else {
      toggleDropdown(!displayDropdown);
    }
  }

  const itemsContainer = classNames({
    [classes.itemsUserCard]: true,
    [classes.open]: displayDropdown,
    [classes[color]]: color,
    [classes.fullWidth]: fullWidth,
    [classes.heigherIndex]: heigherIndex,
    [classes[maxHeight]]: maxHeight,
  });

  return (
    <UserCard
      toggleDropdown={onAppButtonClick}
      displayDropdown={displayDropdown}
      itemsContainer={itemsContainer}
      selectItemUserCard={style.selectItemUserCard}
    />
  );
};

AppSelectUserCard.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  valueSelected: PropTypes.string,
  textOnSelectOpened: PropTypes.string,
  textAfterSelected: PropTypes.string,
  name: PropTypes.string,
  color: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fullWidth: PropTypes.bool,
  noErrowBorder: PropTypes.bool,
  inputDisplay: PropTypes.bool,
  heigherIndex: PropTypes.bool,
  noBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  onButtonClose: PropTypes.func,
  showError: PropTypes.bool,
  mandatory: PropTypes.bool,
  errorMessage: PropTypes.string,
};
AppSelectUserCard.defaultProps = {
  color: 'primary',
  fullWidth: false,
  noBorder: false,
  inputDisplay: false,
  disabled: false,
  heigherIndex: false,
  textOnSelectOpened: null,
  textAfterSelected: null,
  maxHeight: null,
  onButtonClose: null,
  showError: false,
  noErrowBorder: false,
  mandatory: false,
  errorMessage: null,
};

export default withStyles(style)(AppSelectUserCard);
