import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import { style } from 'assets/jss/style';

import ChooseCompany from 'components/ChooseCompany';
import Avatar from 'components/Avatar/Avatar';
import {
  AppRowCenterStartNoMargin,
  AppColumnStartCenter,
} from 'components/AppContainers';
import { MBlueBoldN, MBlueN } from 'components/AppText';
import AppDialog from 'components/AppDialog';

import OutsideClick from 'helpers/outsideClick';

import state from './state';

const { userCardWrap } = style;

class UserCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openCompaniesDialog: false,
    };

    this._toggleChangeCompany = this._toggleChangeCompany.bind(this);
  }

  componentWillUnmount() {
    // TODO: useless no ?
    this._isMounted = false;
  }

  renderLogout() {
    const { t } = this.props;
    const { logoutDialog } = this.state;

    if (logoutDialog) {
      return (
        <AppDialog
          title={t('logout')}
          closeDialog={() => {
            this.setState({ logoutDialog: false });
          }}
          sm
          iconClose
          onConfirmText={t('yes')}
          onCancelText={t('no')}
          footer
          onConfirm={() => {
            this.props.history.push('/logout');
          }}
          contentText={t('confirm_logout')}
          color="secondary"
        />
      );
    }
    return null;
  }

  _toggleChangeCompany() {
    if (this.props.loggedUserCompany.companies.length > 1) {
      this.setState({
        openCompaniesDialog: !this.state.openCompaniesDialog,
      });
    }
  }

  _chooseCompany() {
    return (
      <ChooseCompany
        closeDialog={this._toggleChangeCompany}
        userLoggedIn={true}
      />
    );
  }

  render() {
    const {
      loggedUser: { user },
      toggleDropdown,
      displayDropdown,
      itemsContainer,
      selectItemUserCard,
      t,
      history,
    } = this.props;

    if (user) {
      return (
        <Fragment>
          {this.state.openCompaniesDialog && this._chooseCompany()}
          {this.renderLogout()}
          <div
            style={{ ...userCardWrap, zIndex: 1001 }}
            onClick={toggleDropdown}
          >
            <OutsideClick
              displayDropdown={displayDropdown}
              uponClick={() => toggleDropdown(!displayDropdown)}
              disabled={false}
            >
              <AppRowCenterStartNoMargin TPcenter>
                <div style={{ height: 'auto', width: '8px' }} />
                <Avatar image={this.props.loggedUser.user.avatarBase64 || ''} />
                <div style={{ height: 'auto', width: '8px' }} />
                <AppColumnStartCenter TPnone>
                  <MBlueBoldN>{user.display_name}</MBlueBoldN>
                  <MBlueN>Bonjour!</MBlueN>
                </AppColumnStartCenter>
              </AppRowCenterStartNoMargin>
              <div className={itemsContainer}>
                <Link to="/account">
                  <button
                    type="button"
                    style={selectItemUserCard}
                    onClick={(e) => e}
                  >
                    {t('profile')}
                  </button>
                </Link>
                {this.props.loggedUserCompany.companies.length > 1 && (
                  <button
                    type="button"
                    style={selectItemUserCard}
                    onClick={this._toggleChangeCompany}
                  >
                    {t('my_companies')}
                  </button>
                )}
                <button
                  type="button"
                  style={selectItemUserCard}
                  onClick={() => {
                    history.push({
                      pathname: '/signup',
                      state: {
                        user,
                      },
                    });
                  }}
                >
                  {t('create_company')}
                </button>
                <button
                  type="button"
                  style={selectItemUserCard}
                  onClick={() => {
                    this.setState({ logoutDialog: true });
                  }}
                >
                  {t('logout')}
                </button>
              </div>
            </OutsideClick>
          </div>
        </Fragment>
      );
    }

    return null;
  }
}

UserCard.propTypes = {
  loggedUser: PropTypes.object,
  itemsContainer: PropTypes.string,
  toggleDropdown: PropTypes.func.isRequired,
  displayDropdown: PropTypes.bool.isRequired,
  selectItemUserCard: PropTypes.object,
  t: PropTypes.func,
  history: PropTypes.object,
  loggedUserCompany: PropTypes.object,
};

const UserCardTranslated = withTranslation()(UserCard);
const UserCardWithRouter = withRouter(UserCardTranslated);
export default connect(state)(UserCardWithRouter);
