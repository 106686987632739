import React, { useContext, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
// import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CGUForm from 'components/CardComponents/Treezor/CGUForm';
// import ActionsAccordion from './actionsAccordion';
// import PrepareFormContainer from './userFormContainer/prepareFormContainer';
import {
  UPDATE_TREEZOR_USER,
  SET_SHOW_ADD_COLLABORATEUR,
  SET_STEP,
} from 'contexts/TreezorContext/TreezorType';
import {
  DispatchContext,
  TreezorFormContext,
} from 'contexts/TreezorContext/TreezorFormContext';
import { onBoardingTreezorUser } from 'actions/UserTreezorActions';
import { TreezorDocumentTypes } from '@bebusinessfocus/compta-hub-core';
import {
  prepareUsers,
  prepareDataToCreateNewUser,
} from 'helpers/TreezorHelper';
import {
  TYPE_AUTO_ENTREPRENEUR,
  TYPE_ENTREPRENEUR_INDIVIDUEL,
  TYPE_SOCIETE,
} from 'constants/company';
import { createUser } from 'actions/UserActions';
import { initialValues } from 'containers/CardContainers/Treezor/userFormContainer/VIHAutoIndep/initialValues';
import { prepareData } from 'containers/CardContainers/Treezor/userFormContainer/VIHAutoIndep/handleSubmit';
import { validationSchema } from 'containers/CardContainers/Treezor/userFormContainer/VIHAutoIndep/validation';
import { initialValues as initValComapny } from 'containers/CardContainers/Treezor/userFormContainer/VIHCompany/initialValues';
import { prepareData as prepareDataCompany } from 'containers/CardContainers/Treezor/userFormContainer/VIHCompany/handleSubmit';
import { validationSchema as validationCompany } from 'containers/CardContainers/Treezor/userFormContainer/VIHCompany/validation';
import ActionsAccordion from './actionsAccordion';
import PrepareFormContainer from './userFormContainer/prepareFormContainer';
import CompanyFormContainer from './compagnyFormContainer/compagnyFormContainer';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

const VerticalLinearStepper = ({
  legalFormArray,
  legalAnnualTurnOvers,
  legalNetIncomeRanges,
  legalNumberOfEmployeeRanges,
  countryList,
  personelTitle,
  error,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { activeStep, shouldCreateNewUser } = useContext(TreezorFormContext);
  const contextDispatch = useContext(DispatchContext);
  const { loggedUserCompany, loggedUser, treezor } = useSelector(
    (reduxState) => reduxState
  );
  const { companyType } =
    loggedUserCompany?.company?.settings?.tax_registration;

  // const handleBack = () => {
  //   contextDispatch({
  //     type: SET_STEP,
  //     payload: activeStep - 1,
  //   });
  // };

  const setCompanyCredentials = () => {
    if (TYPE_AUTO_ENTREPRENEUR === companyType) {
      return TreezorDocumentTypes.soleTraderRegistration;
    }
    if (TYPE_ENTREPRENEUR_INDIVIDUEL === companyType) {
      return TreezorDocumentTypes.independantRegistration;
    }
    if (TYPE_SOCIETE === companyType) {
      return TreezorDocumentTypes.companyRegistration;
    }
    return null;
  };

  const getSteps = () => {
    return [
      'treezor.company',
      TYPE_SOCIETE === companyType
        ? 'treezor.CorporateGovernance'
        : 'treezor.user',
      'Contrats',
    ];
  };
  const steps = getSteps();

  const userOnSubmit = (user) => {
    if (treezor.treezorUser) {
      const { userId } = treezor.treezorUser;
      const company = {
        userId,
      };
      // contextDispatch({ type: UPDATE_TREEZOR_COMPANY, payload: user });
      dispatch(
        onBoardingTreezorUser(prepareUsers([user], companyType)[0], company)
      );
      if (shouldCreateNewUser) {
        dispatch(createUser(prepareDataToCreateNewUser([user])));
      }
    }
    contextDispatch({ type: UPDATE_TREEZOR_USER, payload: user });
  };

  useEffect(() => {
    if (treezor.treezorUsers.length > 1) {
      if (companyType === TYPE_ENTREPRENEUR_INDIVIDUEL) {
        contextDispatch({
          type: SET_STEP,
          payload: 2,
        });
      }
      contextDispatch({ type: SET_SHOW_ADD_COLLABORATEUR, payload: true });
    }
  }, [treezor.treezorUsers]);

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <CompanyFormContainer
            loggedUserCompany={loggedUserCompany.company}
            legalFormArray={legalFormArray}
            legalAnnualTurnOvers={legalAnnualTurnOvers}
            legalNetIncomeRanges={legalNetIncomeRanges}
            legalNumberOfEmployeeRanges={legalNumberOfEmployeeRanges}
            companyCredentials={setCompanyCredentials()}
            proofOfAddress={TreezorDocumentTypes.proofOfAddress}
            companyRegistrationSecondary={
              TreezorDocumentTypes.companyRegistrationSecondary
            }
            loggedUser={loggedUser}
            error={error}
            treezorUser={treezor.treezorUser}
          />
        );
      case 1:
        return (
          <PrepareFormContainer
            company={loggedUserCompany.company}
            identityList={TreezorDocumentTypes.identityPrimaryList}
            countryList={countryList}
            personelTitle={personelTitle}
            identityList2={TreezorDocumentTypes.identitySecondaryList}
            proofOfAddress={TreezorDocumentTypes.proofOfAddress}
            companyCredentials={setCompanyCredentials()}
            onSubmit={userOnSubmit}
            initialValues={
              companyType === TYPE_SOCIETE ? initValComapny : initialValues
            }
            prepareData={
              companyType === TYPE_SOCIETE ? prepareDataCompany : prepareData
            }
            validationSchema={
              companyType === TYPE_SOCIETE
                ? validationCompany
                : validationSchema
            }
          />
        );
      case 2:
        return (
          <>
            <CGUForm />
            <ActionsAccordion />
          </>
        );
      default:
        return 'Unknown step';
    }
  };

  return (
    <div className={classes.root}>
      <Stepper activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepLabel>{t(label)}</StepLabel>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              {/* <div className={classes.actionsContainer}>
                <div>
                  <Button
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    className={classes.button}
                  >
                    Back
                  </Button>
                </div>
              </div> */}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

VerticalLinearStepper.propTypes = {
  legalFormArray: PropTypes.array,
  legalAnnualTurnOvers: PropTypes.array,
  legalNetIncomeRanges: PropTypes.array,
  legalNumberOfEmployeeRanges: PropTypes.array,
  company: PropTypes.object,
  countryList: PropTypes.array,
  personelTitle: PropTypes.array,
  error: PropTypes.object,
};

export default VerticalLinearStepper;
