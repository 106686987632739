import {
  userCanOverviewExpense,
  userCanValidateExpense,
} from '../../selectors/rightsSelector/expenseRequests';
import {
  userCanOverviewBank,
  userCanOverviewInvoice,
} from '../../selectors/rightsSelector/dashboardRequests';
import {
  companyDataAreMissing,
  userDataAreMissing,
} from '../../selectors/missingData';

export default ({
  expenseVatList,
  bankBalances,
  treezorBalances,
  treezor,
  ...restState
}) => ({
  invoiceTurnOver: restState.loggedUserCompany.invoiceTurnOver,
  revenueOverview: restState.revenues.overview,
  bankBalances,
  treezorBalances,
  expenseOverview: restState.expenseOverview.expenseOverview,
  expenseByCategories: restState.expenseOverview.byCategories,
  expenseVatList,
  treezor,
  userCanSeeInvoiceOverview: userCanOverviewInvoice(restState),
  userCanValidateExpense: userCanValidateExpense(restState),
  userCanSeeExpenseOverview: userCanOverviewExpense(restState),
  userCanSeeBankOverview: userCanOverviewBank(restState),
  companyDataAreMissing: companyDataAreMissing(restState),
  userDataAreMissing: userDataAreMissing(restState),
  companyId: restState.loggedUserCompany.company._id,
  appInitialized: restState.appInitialized,
  agingBalance: restState.agingBalance,
});
