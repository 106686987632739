import { bindActionCreators } from 'redux';
import { fetchInfoGreffe } from '../../actions/infoGreffeActions';
import {
  signupWithRemoveApiVersion,
  logout,
  isAuthenticated,
} from '../../actions/AuthActions';

export default (dispatch) =>
  bindActionCreators(
    {
      fetchInfoGreffe,
      signupWithRemoveApiVersion,
      logout,
      isAuthenticated,
    },
    dispatch
  );
