import {
  inputPlaceholder,
  inputText,
  inputPlaceholderActive,
  inputLabel,
} from './appText';
import colors from '../../../constants/colors.json';

const style = {
  inputContainer: {
    ...inputText,
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '35px',
    border: '1px solid #b2b2b2',
    borderRadius: '4px',
    backgroundColor: colors.white,
    '&:active': {
      border: `1px solid ${colors.green}`,
    },
    '&:hover': {
      border: '1px solid #888888',
    },
    '&:focus-within': { border: `1px solid ${colors.green}` },
    '&:disabled': {
      backgroundColor: colors.fillsGrey,
    },
  },

  fullWidth: {
    width: '100%',
  },
  inputField: {
    ...inputText,
    textAlign: 'left',
    border: 'none',
    paddingLeft: '10px',
    backgroundColor: 'transparent',
    '&::placeholder': {
      ...inputPlaceholder,
    },
    '&:hover': {
      cursor: 'auto',
    },
    '&:active': {
      '&::placeholder': {
        ...inputPlaceholderActive,
      },
    },
    '&:focus': {
      '&::placeholder': {
        ...inputPlaceholderActive,
      },
    },
    '&:disabled': {
      backgroundColor: colors.background,
    },
  },

  alignRight: {
    textAlign: 'right',
    paddingLeft: '0',
  },
  alignCenter: {
    textAlign: 'center',
  },

  iconStyle: {
    ...inputPlaceholder,
    fontSize: '20px',
    paddingLeft: '5px',
  },

  primaryCaret: {
    caretColor: colors.blue,
  },
  secondaryCaret: {
    caretColor: colors.green,
  },
  primaryWithValue: {
    '&:focus-within': { border: '1px solid #3d319d' },
    '&:hover': {
      cursor: 'auto',
    },
    '&:active': {
      border: '1px solid #3d319d',
    },
    '&:focus': {
      border: '1px solid #3d319d',
    },
  },

  secondaryWithValue: {
    '&:focus-within': {
      border: '1px solid #02CBCD',
    },

    '&:active': {
      border: '1px solid #02CBCD',
    },
    '&:focus': {
      border: '1px solid #02CBCD',
    },
  },

  primaryLabel: {
    ...inputLabel,
    color: colors.blue,
    position: 'absolute',
    width: 'auto',
    backgroundColor: colors.white,
    zIndex: 100,
    left: '10px',
    top: '13px',
    padding: '0 3px',
    transform: 'scaleY(0)',
  },
  secondaryLabel: {
    ...inputLabel,
    // color: colors.green,
    position: 'absolute',
    width: 'auto',
    backgroundColor: colors.white,
    zIndex: 100,
    left: '10px',
    top: '13px',
    padding: '0 3px',
    transform: 'scaleY(0)',
  },

  primaryLabelFocused: {
    color: colors.blue,
  },
  secondaryLabelFocused: {
    color: colors.green,
  },
  showLabel: {
    transform: 'scaleY(1)',
    top: '-8px',
    transition: 'top 0.4s ease',
  },

  primaryError: {
    border: ' 1px solid #FF2B44',
    '&:active': {
      border: '1px solid #FF2B44',
    },
    '&:focus-within': { border: '1px solid #FF2B44' },
  },
  secondaryError: {
    border: ' 1px solid #FF2B44',
    '&:active': {
      border: '1px solid #FF2B44',
    },
    '&:focus-within': { border: '1px solid #FF2B44' },
  },

  primaryErrorMessage: {
    ...inputLabel,
    color: colors.red,
    position: 'absolute',
    width: 'auto',
    backgroundColor: colors.white,
    zIndex: 100,
    // left: '10px',
    top: '35px',
    padding: '0 3px',
    transform: 'scaleY(1)',
  },
  secondaryErrorMessage: {
    ...inputLabel,
    color: colors.red,
    position: 'absolute',
    width: 'auto',
    backgroundColor: colors.white,
    zIndex: 100,
    // left: '10px',
    top: '35px',
    padding: '0 3px',
    transform: 'scaleY(1)',
  },

  // secondaryError: {
  //   border: ' 1px solid #FEC432',
  //   '&:active': {
  //     border: '1px solid #FEC432',
  //   },
  //   '&:focus-within': { border: '1px solid #FEC432' },
  // },

  primaryLabelError: {
    color: colors.red,
  },
  secondaryLabelError: {
    color: colors.red,
    // color: colors.yellow,
  },

  mandatory: {
    '&::placeholder': {
      ...inputPlaceholderActive,
    },
  },

  noBoder: {
    border: '1px solid transparent',
  },
  disabled: {
    backgroundColor: colors.background,
    borderBottomRightRadius: '0',
    borderBottomLeftRadius: '0',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderBottom: '1px solid #b2b2b2',
    '&:hover': {
      cursor: 'auto',
      backgroundColor: colors.background,
    },
    '&:active': {
      backgroundColor: colors.background,
      borderBottom: '1px solid #B2B2B2',
    },
    '&:focus': {
      backgroundColor: colors.background,
    },
    '&:disabled': {
      backgroundColor: colors.background,
    },
  },
};

export default style;
