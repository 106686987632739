import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import useLocalStorageReducer from '../hooks/useLocalStorageReducer';
import ownersReducer from '../reducers/ownersList.reducer';

export const OwnersContext = createContext();
export const DispatchContext = createContext();

export function OwnersProvider(props) {
  const [owners, dispatch] = useLocalStorageReducer(
    'owners',
    ownersReducer,
    []
  );

  return (
    <OwnersContext.Provider value={owners}>
      <DispatchContext.Provider value={dispatch}>
        {props.children}
      </DispatchContext.Provider>
    </OwnersContext.Provider>
  );
}

OwnersProvider.propTypes = {
  children: PropTypes.object,
};
