import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import PropTypes from 'prop-types';

import RevenueForm from 'components/RevenueForms/SimpleForm';
import Layout from 'components/Layout';
import Header from 'components/Header';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import { useRouter } from 'hooks/routerHooks';

import initialValues from './formstate';
import { validationSchema } from './validation';

const TwoStepForm = ({ onSubmit }) => {
  // Once we have a category this hooks is used to render the form in place of the category list
  const [isCategorySelected, setCategorySelected] = useState(false);

  // Vat Rates from redux for the Revenue Form
  const vatRates = useSelector((state) => state.vatRates);

  // Categories tree from redux for the Category List component
  const categoriesTree = useSelector((state) => state.revenueCategories.tree);
  const loadingCategories = useSelector(
    (state) => state.revenueCategories.loading
  );

  const creationErrorObject = useSelector((state) => state.revenues.error);

  const userId = useSelector((state) => state.loggedUser.user._id);
  const companyId = useSelector((state) => state.loggedUserCompany.company._id);
  // eslint-disable-next-line
  const { bank_details } = useSelector(
    (state) => state.loggedUserCompany.company
  );

  const { t } = useTranslation();

  const { history } = useRouter();

  function goBack() {
    if (isCategorySelected) {
      setCategorySelected(false);
    } else {
      history.push('/invoices/list');
    }
  }

  const getPayementMode = () => {
    const mode = [
      {
        _id: 'cash',
        display: 'Espèces',
      },
    ];
    bank_details.forEach((bank) => {
      mode.push({
        display: `${bank.bank_name} ${bank.iban}`,
        _id: 'bank',
      });
    });
    return mode;
  };
  return (
    <React.Fragment>
      {loadingCategories && (
        <LoadingIndicatorDialog title={t('loading_single')} open={true} />
      )}
      <Layout
        header={
          <Header
            name={t('revenues.revenue.create')}
            goBack={goBack}
            spaceBetween
          />
        }
        sidebarLeft={true}
        sidebarRight={null}
        showUserCard={true}
        body={
          <Formik
            initialValues={initialValues(companyId, userId)}
            validationSchema={validationSchema({ t })}
            onSubmit={onSubmit}
          >
            <RevenueForm
              vatRates={vatRates}
              modes={getPayementMode()}
              errorMessage={
                creationErrorObject ? creationErrorObject.message : ''
              }
              categories={categoriesTree}
            />
          </Formik>
        }
      />
    </React.Fragment>
  );
};

TwoStepForm.propTypes = {
  onSubmit: PropTypes.object,
};

export default TwoStepForm;
