import React from 'react';
import PropTypes from 'prop-types';

import { map, isObject, find } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { Select, MenuItem } from '@material-ui/core';
import { INSEE as codeList } from '@bebusinessfocus/compta-hub-core';

import TextField from '../../FormElements/textField';
import { AppResponsiveDividerS } from '../../AppDividers';
import TextFieldWithAdornment from '../../FormElements/TextFieldWithAdornment';
import SelectAutoComplete from '../../SelectAutocomplete';

import legalFormArray from '../../../assets/legalForm';

// const codeList = require('../../../assets/data/INSEE.json');

const AppCompanyInfo = ({
  t,
  values,
  setFieldValue,
  // onBusinesLogoSelected,
  onCodeSelected,
  onBlur,
  onSirenChange,
  onZipcodeCityChange,
  isOnboarding = false,
}) => {
  function onSelectedTypeChanged(e) {
    setFieldValue('rcsType', e.target.value);
  }

  return (
    <>
      {/* Section with Company name and Business name */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            label={t('signup.company.name')}
            placeholder={t('signup.company.name')}
            name="name"
            color="secondary"
            mandatory
            required
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            type="text"
            name="business_name"
            label={t('signup.company.business_name')}
            placeholder={t('signup.company.business_name')}
            color="secondary"
            fullWidth
          />
        </Grid>
      </Grid>
      {/* Section with Address, Zip code and City */}
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={6}>
          <TextField
            type="text"
            name="address"
            label={t('signup.company.address')}
            placeholder={t('signup.company.address')}
            color="secondary"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            name="zipcode"
            label={t('signup.company.zipcode')}
            placeholder={t('signup.company.zipcode')}
            color="secondary"
            onBlur={onBlur}
            onChange={onZipcodeCityChange}
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            name="city"
            label={t('signup.company.city')}
            placeholder={t('signup.company.city')}
            color="secondary"
            onBlur={onBlur}
            onChange={onZipcodeCityChange}
            required
            fullWidth
          />
        </Grid>
      </Grid>

      {/* Section with Phone number, SIREN, NIC and RCS */}
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={3}>
          {isOnboarding ? (
            <SelectAutoComplete
              mandatory
              required
              name="legal_form"
              label={t('signup.company.legal_form')}
              getOptionLabel={(elem) => elem.display}
              values={map(legalFormArray, (el) => el)}
              // valueSelected={'Entrepreneur individuel'}
              valueSelected={
                find(
                  legalFormArray,
                  (elem) => elem.display === values.legal_form
                ) ||
                find(
                  legalFormArray,
                  (elem) => elem.code === values.legal_form_id
                )
              }
              onChange={(legalForm) => {
                // console.log(legalForm);
                if (legalForm === null || legalForm === undefined) {
                  setFieldValue('legal_form', '');
                  setFieldValue('legal_form_id', undefined);
                } else {
                  setFieldValue('legal_form', legalForm.display);
                  setFieldValue('legal_form_id', legalForm.code);
                }
              }}
            />
          ) : (
            <TextField
              type="text"
              name="phone_number"
              label={t('signup.company.phonenumber')}
              placeholder={t('signup.company.phonenumber')}
              color="secondary"
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            name="siren"
            label={t('signup.company.siren')}
            placeholder={t('signup.company.siren')}
            onChange={onSirenChange}
            color="secondary"
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            name="nic"
            label={t('signup.company.nic')}
            placeholder={t('signup.company.nic')}
            color="secondary"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextFieldWithAdornment
            adornmentPosition="start"
            adornmentText={
              <Select
                value={values.rcsType}
                onChange={onSelectedTypeChanged}
                variant="standard"
                margin="dense"
                fullWidth
                displayEmpty
              >
                {[
                  { text: 'RCS', value: 'RCS' },
                  { text: 'RM', value: 'RM' },
                ].map(({ text, value }) => (
                  <MenuItem key={`${text}, ${value}`} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            }
            type="text"
            name="rcs"
            label={t('signup.company.rcs')}
            placeholder={t('signup.company.rcs')}
            color="secondary"
            fullWidth
          />
        </Grid>
      </Grid>

      {/* Section with Activity code and Legal Form */}
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid item xs={12} sm={6}>
          <SelectAutoComplete
            mandatory
            name="code_ape"
            label={t('signup.company.code_ape')}
            getOptionLabel={(elem) => {
              if (!elem || (elem && isObject(elem) && !elem.Code)) {
                return '';
              }

              if (elem.Code) {
                return `${elem.Code} - ${elem.Intitulés_NAF}`;
              }

              const item = codeList.find((i) => i.Code === elem);

              if (item) {
                return `${item.Code} - ${item.Intitulés_NAF}`;
              }

              return elem;
            }}
            values={map(codeList, (ac) => ac)}
            valueSelected={values.code_ape || undefined}
            onChange={onCodeSelected}
          />
        </Grid>
        {!isOnboarding && (
          <Grid item xs={12} sm={6}>
            {/* <TextField
              type="text"
              name="legal_form"
              label={t('signup.company.legal_form')}
              placeholder={t('signup.company.legal_form_help')}
              color="secondary"
              required
              fullWidth
            /> */}
            <SelectAutoComplete
              mandatory
              required
              name="legal_form"
              label={t('signup.company.legal_form')}
              getOptionLabel={(elem) => elem.display}
              values={map(legalFormArray, (el) => el)}
              // valueSelected={'Entrepreneur individuel'}
              valueSelected={
                find(
                  legalFormArray,
                  (elem) => elem.display === values.legal_form
                ) ||
                find(
                  legalFormArray,
                  (elem) => elem.code === values.legal_form_id
                )
              }
              onChange={(legalForm) => {
                // console.log(legalForm);
                if (legalForm === null || legalForm === undefined) {
                  setFieldValue('legal_form', '');
                  setFieldValue('legal_form_id', undefined);
                } else {
                  setFieldValue('legal_form', legalForm.display);
                  setFieldValue('legal_form_id', legalForm.code);
                }
              }}
            />
          </Grid>
        )}
      </Grid>

      {/* Treezor Informations */}
      <Grid container spacing={2} direction="row" alignItems="center">
        {values.legalRegistrationDate && (
          <Grid item xs={12} sm={3}>
            <TextField
              type="text"
              name="legalRegistrationDate"
              label={t('legal_registration_date')}
              color="secondary"
              fullWidth
              disabled
            />
          </Grid>
        )}
        {values.legalRegistrationDate && (
          <Grid item xs={12} sm={3}>
            <TextField
              type="text"
              name="legalAnnualTurnOver"
              label={t('legal_annual_turn_over')}
              color="secondary"
              fullWidth
              disabled
            />
          </Grid>
        )}
        {values.legalRegistrationDate && (
          <Grid item xs={12} sm={3}>
            <TextField
              type="text"
              name="legalShareCapital"
              label={t('capital')}
              color="secondary"
              fullWidth
              disabled
            />
          </Grid>
        )}
        {values.legalRegistrationDate && (
          <Grid item xs={12} sm={3}>
            <TextField
              type="text"
              name="legalNumberOfEmployeeRange"
              label={t('legal_number_of_employee_range')}
              color="secondary"
              fullWidth
              disabled
            />
          </Grid>
        )}
      </Grid>
      <AppResponsiveDividerS />
    </>
  );
};
AppCompanyInfo.propTypes = {
  t: PropTypes.func,
  values: PropTypes.object,
  onCodeSelected: PropTypes.func,
  setFieldValue: PropTypes.func,
  fetchInfoGreffe: PropTypes.func,
  onBusinesLogoSelected: PropTypes.func,
  onSirenChange: PropTypes.func,
  onBlur: PropTypes.func,
  isOnboarding: PropTypes.bool,
  onZipcodeCityChange: PropTypes.func,
};
AppCompanyInfo.propTypes = {};

export default AppCompanyInfo;
