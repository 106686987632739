import {
  EXPENSE_VAT_LIST,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

export default (state = {}, action) => {
  switch (action.type) {
    case EXPENSE_VAT_LIST:
      return action.payload;
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return {};
    default:
      return state;
  }
};
