import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import { withStyles } from '@material-ui/core/styles';

import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
} from '@material-ui/core';

import {
  companyTaxRegistrationDataAreMissing,
  companyAddressDataAreMissing,
  companyIdentityDataAreMissing,
  companyEndFiscalYearDataAreMissing,
  companyKBISDataIsMissing,
  companySIRENDataIsMissing,
  // companyLogoDataIsMissing,
  userEmailIsMissing,
  userPassportIsMissing,
  userPhoneNumberIsMissing,
} from 'helpers/userMissingData';

import { updateCompany } from 'actions/CompanyActions';

import { AppTextSmallTitle, AppTextTitle } from '../AppText';
import AppCompleteSignup from '../AppCompleteSignup';

const styles = () => ({
  missingDataWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 50,
  },
});

const OTHER = '---other---';

const CompleteSignup = ({
  classes,
  history,
  companyDataAreMissing,
  userDataAreMissing,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [comeFrom, setComeFrom] = useState('');
  const [comeFromOther, setComeFromOther] = useState('');
  const loggedUserCompany = useSelector((state) => state.loggedUserCompany);
  useEffect(() => {
    const referrer = loggedUserCompany?.company?.referrer;
    if (referrer) {
      setComeFrom(referrer);
    }
  }, [loggedUserCompany]);
  const handleChangeComeFromOther = (event) => {
    const referrer = event.target.value;
    setComeFromOther(referrer);
  };
  const handleClickComeFromOther = () => {
    const company = {
      ...loggedUserCompany.company,
      referrer: comeFromOther,
    };
    dispatch(updateCompany(company));
  };

  const handleChangeComeFrom = (event) => {
    const referrer = event.target.value;
    setComeFrom(referrer);
    if (referrer === OTHER) {
      return;
    }

    const company = {
      ...loggedUserCompany.company,
      referrer,
    };

    dispatch(updateCompany(company));
  };

  const missingDataWrapperClass = classNames({
    [classes.missingDataWrapper]: true,
  });

  const getCompanyCheckList = () => {
    const checkList = [
      {
        title: t('signup.completion.company.tax_registration'),
        ...companyTaxRegistrationDataAreMissing(companyDataAreMissing),
      },
      {
        title: t('signup.completion.company.address'),
        ...companyAddressDataAreMissing(companyDataAreMissing),
      },
      {
        title: t('signup.completion.company.identity'),
        ...companyIdentityDataAreMissing(companyDataAreMissing),
      },
      {
        title: t('signup.company.end_fiscal_year'),
        ...companyEndFiscalYearDataAreMissing(companyDataAreMissing),
      },
    ];
    if (loggedUserCompany?.company?.isContractor?.toString() === 'true') {
      checkList.push({
        title: `Avis répertoire SIRENE`,
        ...companySIRENDataIsMissing(companyDataAreMissing),
      });
    } else {
      checkList.push({
        title: `KBIS`,
        ...companyKBISDataIsMissing(companyDataAreMissing),
      });
    }

    return checkList.sort((x, y) =>
      // eslint-disable-next-line no-nested-ternary
      x.checked === y.checked ? 0 : x.checked ? 1 : -1
    );
  };

  const getUserCheckList = () => {
    return [
      {
        title: t('signup.completion.user.email_verification'),
        ...userEmailIsMissing(userDataAreMissing),
      },
      {
        title: t('signup.completion.user.passport'),
        ...userPassportIsMissing(userDataAreMissing),
      },
      {
        title: t('signup.completion.user.phone_number'),
        ...userPhoneNumberIsMissing(userDataAreMissing),
      },
    ].sort((x, y) =>
      // eslint-disable-next-line no-nested-ternary
      x.checked === y.checked ? 0 : x.checked ? 1 : -1
    );
  };

  if (
    (!companyDataAreMissing ||
      (companyDataAreMissing && !companyDataAreMissing.length)) &&
    (!userDataAreMissing || (userDataAreMissing && !userDataAreMissing.length))
  ) {
    return null;
  }

  return (
    <div>
      <div
        style={{ marginLeft: '20px', marginTop: '20px', marginBottom: '10px' }}
      >
        <AppTextTitle style={{ marginLeft: '10px' }}>
          Comment nous avez-vous connu ?
        </AppTextTitle>
      </div>
      <div style={{ marginLeft: '30px' }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Sélectionnez...</FormLabel>
          <RadioGroup
            aria-label="referrer"
            name="referrer"
            value={comeFrom}
            onChange={handleChangeComeFrom}
          >
            <FormControlLabel
              value="mcommemutuelle"
              control={<Radio />}
              label="Vous êtes
        client M comme Mutuelle"
            />
            <FormControlLabel
              value="google"
              control={<Radio />}
              label="Un moteur de recherche"
            />
            <FormControlLabel
              value="ad"
              control={<Radio />}
              label="Une publicité"
            />
            <FormControlLabel
              value="accountant"
              control={<Radio />}
              label="Votre expert-comptable"
            />
            <FormControlLabel
              value="colleague"
              control={<Radio />}
              label="Un collègue"
            />
            <FormControlLabel value={OTHER} control={<Radio />} label="Autre" />
          </RadioGroup>
          {comeFrom === OTHER && (
            <>
              <TextField
                color="secondary"
                value={comeFromOther}
                placeholder="Précisez"
                onChange={handleChangeComeFromOther}
              />{' '}
              <Button
                disabled={comeFromOther === ''}
                onClick={handleClickComeFromOther}
              >
                Confirmer
              </Button>
            </>
          )}
        </FormControl>
      </div>
      <div style={{ marginLeft: '20px', marginTop: '30px' }}>
        <AppTextTitle style={{ marginLeft: '10px' }}>
          {t('signup.completion.title')}
        </AppTextTitle>
        <AppTextSmallTitle style={{ marginLeft: '10px' }}>
          {t('signup.completion.subtitle')}
        </AppTextSmallTitle>
      </div>
      <div className={missingDataWrapperClass}>
        <AppCompleteSignup
          title={t('signup.completion.user.title')}
          subtitle={t('signup.completion.percentage_message')}
          buttonText={t('signup.completion.complete')}
          styles={{ width: '45%' }}
          defaultPercentage={70}
          checkList={getUserCheckList()}
          onButtonClick={() => history.push('/account')}
        />
        <AppCompleteSignup
          title={t('signup.completion.company.title')}
          subtitle={t('signup.completion.percentage_message')}
          buttonText={t('signup.completion.complete')}
          styles={{ width: '45%' }}
          checkList={getCompanyCheckList()}
          onButtonClick={() => history.push('/settings/company')}
        />
      </div>
    </div>
  );
};

CompleteSignup.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object,
  userDataAreMissing: PropTypes.array,
  companyDataAreMissing: PropTypes.array,
};

const CompleteSignupWithStyles = withStyles(styles)(CompleteSignup);
export default CompleteSignupWithStyles;
