import React from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import Header from 'components/Header';

import { useRouter } from 'hooks/routerHooks';

// to remove : it will be purchase
import { getExpenseTitle } from 'helpers/expenseHelpers';
// import { useLocation } from 'react-router-dom';

const PurchaseFormHeader = () => {
  const { history } = useRouter();
  // const location = useLocation();
  const { t } = useTranslation();

  const {
    values: { payment_type: payment },
    // values,
    // setFieldValue,
    // handleReset,
  } = useFormikContext();

  const goBack = () => {
    // if (values.category_id === undefined) {
    history.push('/purchases/list');

    //   history.push({
    //     pathname: location?.state?.goBackPath || '/purchases/list',
    //     search: location?.state?.goBackQueryParams,
    //     state: {
    //       pageTitle: location?.state?.pageTitle,
    //     },
    //   });
    // } else {
    //   setFieldValue('category_id', undefined);
    //   handleReset();
    // }
  };
  return (
    <Header
      name={t(getExpenseTitle(payment))}
      goBack={goBack}
      spaceBetween
    ></Header>
  );
};

export default PurchaseFormHeader;
