import * as yup from 'yup';
import { PHONE_NUMBER_VALIDATE_SCHEMA } from 'constants/form';
import password from '../../assets/data/passwords';
import { TYPE_SOCIETE } from '../../constants/company';

/* eslint import/prefer-default-export: off */
export const validationSchema = ({ t }) =>
  yup.object().shape({
    signedUser: yup.boolean(),
    isContractor: yup.boolean(),
    name: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.name_empty'), {
        excludeEmptyString: true,
      })
      .when('isContractor', {
        // isContractor can be null
        is: (isContractor) => isContractor !== true,
        then: (schema) => schema.required(t('signup.company.name_mandatory')),
      }),
    business_name: yup.string(),
    address: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.address_empty'), {
        excludeEmptyString: true,
      })
      .required(t('signup.company.address_mandatory')),
    zipcode: yup
      .string()
      .matches(/^[\w-]+$/, t('signup.company.zipcode_false'), {
        excludeEmptyString: true,
      })
      .max(15, t('signup.company.zipcode_max'))
      .required(t('signup.company.zipcode_mandatory')),
    city: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.adress_mandatory'), {
        excludeEmptyString: true,
      })
      .required(t('signup.company.address_mandatory')),
    rcs: yup.string().matches(/[^\s\\]/, t('signup.company.rcs_empty'), {
      excludeEmptyString: true,
    }),
    siren: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.siren_empty'), {
        excludeEmptyString: true,
      })
      .min(9, t('signup.company.siren_min_mandatory'))
      .max(9, t('signup.company.siren_min_mandatory'))
      .required(t('signup.company.siren_mandatory')),
    intracomvat: yup.string().when('tva', {
      is: true,
      then: yup
        .string()
        .matches(/[^\s\\]/, t('signup.company.intracomvat_empty'), {
          excludeEmptyString: true,
        })
        .required(t('signup.company.intracomvat_mandatory')),
    }),
    tva: yup.boolean().required(t('signup.company.intracomvat_mandatory')),
    code_ape: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.code_ape_empty'), {
        excludeEmptyString: true,
      }),
    userName: yup.string().when('signedUser', {
      is: (signedUser) => !signedUser,
      then: yup
        .string()
        .matches(/[^\s\\]/, t('signup.company.userName_empty'), {
          excludeEmptyString: true,
        })
        .required(t('signup.company.userName_mandatory')),
    }),

    firstname: yup.string().when('signedUser', {
      is: (signedUser) => !signedUser,
      then: yup
        .string()
        .matches(/[^\s\\]/, t('signup.company.userName_empty'), {
          excludeEmptyString: true,
        })
        .required(t('signup.company.firstName_mandatory')),
    }),

    email: yup
      .string()
      .email(t('signup.company.email_valid_mandatory'))
      .trim()
      .required(t('signup.company.email_mandatory')),
    phonenumber: yup
      .string()
      .matches(
        PHONE_NUMBER_VALIDATE_SCHEMA,
        t('signup.company.valid_phone_number'),
        {
          excludeEmptyString: true,
        }
      ),
    phone_number: yup
      .string()
      .matches(
        PHONE_NUMBER_VALIDATE_SCHEMA,
        t('signup.company.valid_phone_number'),
        {
          excludeEmptyString: true,
        }
      ),

    userPassword: yup.string().when('signedUser', {
      is: (signedUser) => !signedUser,
      then: yup
        .string()
        .min(8, t('signup.password_length'))
        .matches(
          // eslint-disable-next-line no-useless-escape
          /^.*((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
          t('signup.password')
        )
        .notOneOf(password, 'mot de passe trop courant')
        .required(t('mandatory')),
    }),

    confirmPassword: yup.string().when('signedUser', {
      is: (signedUser) => !signedUser,
      then: yup
        .string()
        .oneOf([yup.ref('userPassword')], t('signup.password_match'))
        .required(t('mandatory')),
    }),
    activity: yup.string().notRequired().nullable(),
    prestation: yup.boolean().when('vente', {
      is: (vente) => !vente,
      then: yup.boolean().oneOf([true], t('mandatory')),
    }),
    capital: yup.string().when('companyType', {
      is: TYPE_SOCIETE,
      then: yup.string().required(t('mandatory')),
    }),
    micro: yup.boolean(),
    companyType: yup.string().required(t('mandatory')),
    endFiscalYear: yup
      .string()
      .matches(
        /^((0[1-9]|[12][0-9]|3[01])\/(0[13578]|1[02])|(0[1-9]|[12][0-9]|30)\/(0[469]|11)|(0[1-9]|1[0-9]|2[0-8])\/02)$/,
        t('signup.end_fiscal_year')
      )
      .required(t('mandatory')),
    nic: yup.string().required(t('signup.company.nic_empty')),
  });
