const combinations = [
  {
    settings: { tva: false },
    mentions: [
      { key: 'tva293B', value: 'TVA non applicable, art. 293B du CGI' },
    ],
  },
  {
    settings: { cga: true },
    mentions: [
      {
        key: 'cga',
        value:
          "Membre d'une association agréée, le règlement des factures par chèque est accepté",
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      return_on_debit: false,
      clientIsInsideUE: true,
      clientHasNumIntraCom: false,
    },
    mentions: [{ key: 'tvaencaissements', value: 'TVA sur les encaissements' }],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      return_on_debit: true,
      clientIsInsideUE: true,
      clientHasNumIntraCom: false,
    },
    mentions: [{ key: 'tvadebits', value: 'TVA sur les débits' }],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      clientIsInsideUE: true,
      clientHasNumIntraCom: true,
      clientIsFrench: false,
    },
    mentions: [
      {
        key: 'tva283-2',
        value: 'Autoliquidation TVA due par le preneur article 283-2 du CGI​',
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: false,
      clientIsInsideUE: true,
      clientHasNumIntraCom: true,
      clientIsFrench: false,
    },
    mentions: [
      {
        key: 'tva262ter1',
        value:
          'Autoliquidation - TVA due par le preneur article 262 ter I du CGI​​',
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      clientIsInsideUE: false,
      clientHasNumIntraCom: true,
      isCompany: true,
      clientIsInDomTom: false,
    },
    mentions: [
      {
        key: 'tva283-2horsUE',
        value: 'Exonération TVA article 283-2 du CGI​​',
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      clientIsInsideUE: false,
      clientIsInDomTom: true,
      clientCountry: 'GF',
    },
    mentions: [
      {
        key: 'tva283-2horsUE',
        value: 'Exonération TVA article 283-2 du CGI​​',
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      clientIsInsideUE: false,
      clientIsInDomTom: true,
      clientCountry: 'YT',
    },
    mentions: [
      {
        key: 'tva283-2horsUE',
        value: 'Exonération TVA article 283-2 du CGI​​',
      },
    ],
  },

  {
    settings: {
      clientIsInsideUE: false,
      clientHasNumIntraCom: false,
      clientIsInDomTom: false,
      prestation: true,
      return_on_debit: true,
      tva: true,
    },
    mentions: [
      {
        key: 'tvadebits',
        value: 'TVA sur les débits',
      },
    ],
  },
  {
    settings: {
      clientIsInsideUE: true,
      clientHasNumIntraCom: true,
      clientIsFrench: true,
      prestation: true,
      return_on_debit: true,
      tva: true,
    },
    mentions: [
      {
        key: 'tvadebits',
        value: 'TVA sur les débits',
      },
    ],
  },
  {
    settings: {
      clientIsInsideUE: false,
      clientHasNumIntraCom: false,
      clientIsInDomTom: false,
      prestation: true,
      return_on_debit: false,
      tva: true,
    },
    mentions: [
      {
        key: 'tvaencaissements',
        value: 'TVA sur les encaissements',
      },
    ],
  },
  {
    settings: {
      clientIsInsideUE: true,
      clientHasNumIntraCom: true,
      clientIsFrench: true,
      prestation: true,
      return_on_debit: false,
      tva: true,
    },
    mentions: [
      {
        key: 'tvaencaissements',
        value: 'TVA sur les encaissements',
      },
    ],
  },
  {
    settings: {
      prestation: false,
      tva: true,
      clientIsInDomTom: true,
    },
    mentions: [
      {
        key: 'tva294',
        value: "Exoneration de TVA en application de l'article 294 du CGI",
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: false,
      clientIsInDomTom: false,
      clientIsFrench: false,
      clientIsInsideUE: false,
    },
    mentions: [
      {
        key: 'tva262-1',
        value: 'Exonération de TVA - Article 262-1 du CGI',
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      clientIsInDomTom: true,
      return_on_debit: false,
      clientCountry: 'GP',
    },
    mentions: [
      {
        key: 'tvaencaissements',
        value: 'TVA sur les encaissements',
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      clientIsInDomTom: true,
      return_on_debit: true,
      clientCountry: 'GP',
    },
    mentions: [
      {
        key: 'tvadebits',
        value: 'TVA sur les débits',
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      clientIsInDomTom: true,
      return_on_debit: false,
      clientCountry: 'MQ',
    },
    mentions: [
      {
        key: 'tvaencaissements',
        value: 'TVA sur les encaissements',
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      clientIsInDomTom: true,
      return_on_debit: true,
      clientCountry: 'MQ',
    },
    mentions: [
      {
        key: 'tvadebits',
        value: 'TVA sur les débits',
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      clientIsInDomTom: true,
      return_on_debit: false,
      clientCountry: 'RE',
    },
    mentions: [
      {
        key: 'tvaencaissements',
        value: 'TVA sur les encaissements',
      },
    ],
  },
  {
    settings: {
      tva: true,
      prestation: true,
      clientIsInDomTom: true,
      return_on_debit: true,
      clientCountry: 'RE',
    },
    mentions: [
      {
        key: 'tvadebits',
        value: 'TVA sur les débits',
      },
    ],
  },
];

export default combinations;
