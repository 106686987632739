import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import './onboardingButton.css';

const OnboardingButton = ({ title, options, onClick, styles }) => {
  const { t } = useTranslation();
  const [expand, setExpand] = useState(true);
  return (
    <div className="onboardingButton" style={styles} onClick={() => onClick()}>
      <Button className="button" onClick={() => onClick()}>
        {t(`onboarding.${title}`)}
      </Button>
      <div className="mainscript">{t(`onboarding.script.${title}`)}</div>

      <div
        className="onboardingButton__funcTitle"
        onClick={(e) => {
          e.stopPropagation();
          setExpand(!expand);
        }}
      >
        {t('onboarding.features')}{' '}
        {expand ? <ExpandMoreIcon /> : <ExpandLessIcon />}
      </div>
      {expand && (
        <div className="onboardingButton__func">
          {options.map((item, index) => (
            <div key={index} className="onboardingButton__funcItem">
              <CheckCircleOutlineIcon fontSize="small" />
              <span>{t(`onboarding.options.${item}`)}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

OnboardingButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  styles: PropTypes.object,
  options: PropTypes.array.isRequired,
};

export default OnboardingButton;
