import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Grid, Collapse } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { formuleTexData, blocksPricingHeader } from '../utils';
import sciIcon from '../../../assets/images/onboarding/pic-1.png';
import lmnLmnp from '../../../assets/images/onboarding/pic-2.png';
import sociteteCommercialeIcon from '../../../assets/images/onboarding/pic-3.png';
import individualEntrepreneurIcon from '../../../assets/images/onboarding/pic-4.png';
import activiryLibrale from '../../../assets/images/onboarding/pic-5.png';
import associationIcon from '../../../assets/images/onboarding/pic-6.png';

const useStyles = makeStyles(() => ({
  content: {
    padding: '15px',
    height: 'fit-content',
  },
  headerContainer: {
    textAlign: 'center',
  },
  priceColumn: {
    borderRadius: '50px',
    padding: '34px 36px 28px',
    alignItems: 'center',
  },
  priceColumnRounded: {
    borderRadius: '50px',
    padding: '10px 20px',
    alignItems: 'center',
    backgroundColor: 'hsla(0,0%,10%,.05)',
    border: '1px solid hsla(0,0%,10%,.1)',
    width: '100%',
    // margin: 'auto',
  },
  product: {
    flexDirection: 'column',
  },
  title: {
    fontWeight: '600',
    fontSize: '15px',
    color: '#1a1a1a',
  },
  button: {
    backgroundColor: 'rgb(61, 49, 157)',
    borderColor: 'rgb(61, 49, 157)',
    color: '#ffffff',
    borderRadius: '22px',
    marginTop: '10px',
    padding: '12px 24px',
    cursor: 'pointer',
    height: '44px',
    border: '1px solid transparent',
    width: '100%',
  },
  buttonText: {
    whiteSpace: 'nowrap',
    fontWeight: '500',
  },
  features: {
    width: '100%',
    color: '#1a1a1a',
    flexDirection: 'column',
  },
  featureHeader: {
    display: 'flex',
    margin: '12px 0',
    fontSize: '15px',
    color: '#1a1a1a',
    justifyContent: 'center',
  },
  featuresItem: {
    marginBottom: '8px',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    display: 'flex',
  },
  featuresItemText: {
    lineHeight: '1.2',
    fontSize: '11px',
    color: '#1a1a1a',
    margin: 'auto 0',
  },
  checkContainer: {
    marginTop: '2px',
    minWidth: '28px',
    marginRight: '12px',
  },
  titleContent: {
    // minHeight: '100px',
  },
  image: {
    borderRadius: '6px',
    // marginBottom: '16px',
    maxHeight: '120px',
    maxWidth: '100%',
  },
  priceContainer: {
    display: 'flex',
  },
  price: {
    fontSize: '33px',
    fontWeight: '700',
    marginRight: '4px',
  },
  perMonth: { fontSize: '13px', opacity: '.5', margin: 'auto 0' },
  descriptions: {
    opacity: '.5',
    fontSize: '11px',
    color: '#1a1a1a',
  },
  featureHeaderText: {
    margin: 'auto 0',
  },
}));

const Formula = ({ type, handleChoose, isDescriptions = false }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isCollapse, setIsColapse] = React.useState(true);
  const textsData = formuleTexData[type];
  const handleClick = () => {
    handleChoose(type);
  };
  const titleTop = () => {
    if (!isDescriptions) {
      return t(
        `onboarding.blocks_pricing_header.${blocksPricingHeader[type]}`
      ).toUpperCase();
    }
    return t(
      `onboarding.formule_text_data.${type}.title.${formuleTexData[type]?.title?.top}`
    );
  };

  const icon = () => {
    if (type === 'sci' || type === 'future_sci') {
      return sciIcon;
    }
    if (type === 'lmp_lmnp' || type === 'future_lmp_lmnp') {
      return lmnLmnp;
    }
    if (type === 'commercial_company' || type === 'future_commercial_company') {
      return sociteteCommercialeIcon;
    }
    if (
      type === 'individual_entrepreneur' ||
      type === 'future_individual_entrepreneur'
    ) {
      return individualEntrepreneurIcon;
    }
    if (type === 'liberal_professional' || type === 'liberal_professional') {
      return activiryLibrale;
    }
    return associationIcon;
  };

  return (
    <Grid container xs={4} className={classes.content}>
      <div className={classes.priceColumnRounded}>
        <div className={classes.headerContainer}>
          <div>
            <img className={classes.image} src={icon()} />
          </div>
          <div className={classes.titleContent}>
            <div className={classes.title}>{t(titleTop())}</div>
            <div className={classes.descriptions}>
              {t(
                `onboarding.formule_text_data.${type}.title.${textsData.title.bottom}`
              )}
            </div>
            <div className={classes.descriptions}>
              {t(
                `onboarding.formule_text_data.${type}.title.${textsData.title.bottom_second}`
              )}
            </div>
          </div>
        </div>
        <div>
          <button className={classes.button} onClick={() => handleClick()}>
            {t(`onboarding.continue`)}
          </button>
        </div>
        {isDescriptions && (
          <>
            <div className={classes.features}>
              <div className={classes.featureHeader}>
                <div className={classes.featureHeaderText}>
                  {t(`onboarding.features`)}
                </div>
                <>
                  {isCollapse ? (
                    <ExpandMoreIcon onClick={() => setIsColapse(!isCollapse)} />
                  ) : (
                    <ExpandLessIcon onClick={() => setIsColapse(!isCollapse)} />
                  )}
                </>
              </div>
            </div>
            <div>
              {textsData.texts.map((item, index) => {
                return (
                  <Collapse
                    key={index}
                    in={isCollapse}
                    timeout="auto"
                    unmountOnExit
                  >
                    <div className={classes.featuresItem}>
                      <CheckCircleOutlineIcon
                        fontSize="small"
                        className={classes.checkContainer}
                      />
                      <span className={classes.featuresItemText}>
                        {t(
                          `onboarding.formule_text_data.${type}.texts.${item}`
                        )}
                      </span>
                    </div>
                  </Collapse>
                );
              })}
            </div>
          </>
        )}
      </div>
    </Grid>
  );
};

Formula.propTypes = {
  boardType: PropTypes.string,
  data: PropTypes.string,
  setData: PropTypes.string,
  personalInfo: PropTypes.object,
  handleNext: PropTypes.func,
  type: PropTypes.string,
  handleChoose: PropTypes.func,
  icon: PropTypes.string,
  isDescriptions: PropTypes.bool,
};

export default Formula;
