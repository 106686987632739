import React, { useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';

/* Import custom hooks */
import { useDueDates } from 'hooks/invoiceHooks';

/* Import helpers */
import NumberFormat from 'helpers/NumberFormat';
import { typeHelpers } from 'helpers/invoiceHelpers';

/* Import constants */
import colors from 'constants/colors.json';

/* Import components */
import AppButton from 'components/AppButton';
import PaymentsDialog from './PaymentsDialog';

const useStyles = makeStyles(() => ({
  mGreyText: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '13px',
  },
  payment: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '36px',
    borderRadius: '4px',
    marginLeft: '10px',
  },
  paymentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  dueDatesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  dueDatesBtnWrapper: {
    padding: '4px',
  },
  greyWrapper: {
    borderRadius: '4px',
    border: '1px solid #A6A6A6',
    backgroundColor: colors.white,
  },
  marginMid: {
    marginTop: '20px',
  },
  widthContainer: {
    width: '300px',
  },
}));

const DueDateButton = ({ toggleDialog, prePaymentsList }) => {
  const classes = useStyles();
  const language = useSelector((state) => state.guiLanguage);
  const { t } = useTranslation();

  const {
    isSubmitting,
    values: { payments },
  } = useFormikContext();

  const renderDueDate = (payment) => {
    let dueDate = ``;

    let latestDate = null;
    const dueDateCondition =
      prePaymentsList &&
      prePaymentsList.length > 0 &&
      prePaymentsList.every((prePayment) => prePayment.amount);
    if (dueDateCondition) {
      const latest = prePaymentsList.reduce(function (prev, current) {
        const currentDate = new Date(current.due_date);
        const prevDate = new Date(prev.due_date);
        return prevDate > currentDate ? prev : current;
      });
      latestDate = latest.due_date;
    }
    if (new Date(payment.date) > new Date(latestDate)) {
      dueDate = `${NumberFormat.formatNumberByNation(
        payment.amount,
        language
      )}€ au ${format(new Date(payment.date), 'dd/MM/yyyy')}`;
    } else {
      dueDate = `${NumberFormat.formatNumberByNation(
        payment.amount,
        language
      )}€ au ${format(new Date(latestDate), 'dd/MM/yyyy')}`;
    }

    return dueDate;
  };

  return (
    <div className={classes.dueDatesWrapper}>
      <div className={classes.dueDatesBtnWrapper}>
        <AppButton
          isDisabled={isSubmitting}
          color="secondaryGrey"
          text={t('invoices.change_due_date')}
          onClick={toggleDialog}
        />
      </div>
      <div className={classes.paymentWrapper}>
        {payments &&
          payments.map((payment, index) => (
            <div className={classes.payment} key={index}>
              <p className={classes.mGreyText}>{renderDueDate(payment)}</p>
            </div>
          ))}
      </div>
    </div>
  );
};

DueDateButton.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  prePaymentsList: PropTypes.array,
};

const DueDates = ({ prePaymentsList }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    values: {
      payments,
      gross_total: grossTotal,
      prepayments,
      type: invoiceType,
      duePayableAmount,
    },
    setFieldValue,
  } = useFormikContext();

  function calculateTotal() {
    if (
      !typeHelpers.isQuotation(invoiceType) &&
      !typeHelpers.isOrderForm(invoiceType) &&
      duePayableAmount
    ) {
      return duePayableAmount;
    }

    if (
      !typeHelpers.isQuotation(invoiceType) &&
      !typeHelpers.isOrderForm(invoiceType) &&
      !duePayableAmount
    ) {
      return grossTotal;
    }

    const prepaymentsTotal = prepayments.reduce(
      (acc, { amount }) => acc + amount,
      0
    );
    return grossTotal - prepaymentsTotal;
  }

  const {
    setNewDueDate,
    dueDateChosen,
    setDueDateChosen,
    numberOfPayments,
    setNumberOfPayments,
    newPayments,
    amountError,
    changeDate,
    changeAmount,
  } = useDueDates(payments, calculateTotal(), (pay) => {
    setFieldValue('payments', pay);
  });

  const [showDialog, setShowDialog] = useState(false);

  function toggleDialog() {
    setShowDialog(!showDialog);
  }

  function onPaymentsConfirm(pay) {
    setFieldValue('payments', pay);
    toggleDialog(newPayments);
  }

  function renderDialog() {
    if (!showDialog) {
      return null;
    }

    return (
      <PaymentsDialog
        paymentsCancel={toggleDialog}
        paymentsConfirm={onPaymentsConfirm}
        t={t}
        grossTotal={calculateTotal()}
        methods={{
          setNewDueDate,
          dueDateChosen,
          setDueDateChosen,
          numberOfPayments,
          setNumberOfPayments,
          newPayments,
          amountError,
          changeDate,
          changeAmount,
          prePaymentsList,
        }}
      />
    );
  }

  return (
    <div
      className={classNames(
        classes.greyWrapper,
        classes.marginMid,
        classes.widthContainer
      )}
    >
      {renderDialog()}
      <DueDateButton
        toggleDialog={toggleDialog}
        prePaymentsList={prePaymentsList}
      />
    </div>
  );
};

DueDates.propTypes = {
  prePaymentsList: PropTypes.array,
};

export default DueDates;
