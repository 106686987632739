export default ({ id, emailTemplates }) => {
  if (id && typeof id === 'string' && emailTemplates[id]) {
    return emailTemplates[id];
  }

  return {
    reference: '',
    description: '',
    content: '',
    company_id: '',
  };
};
