import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, MenuItem, Select, makeStyles } from '@material-ui/core';
import CustomGenericDialog from 'components/CustomGenericDialog';
import { useTranslation } from 'react-i18next';
import BankCard from 'components/BankForm/BankCard';
import AppButton from 'components/AppButton';

const useStyles = makeStyles(() => ({
  chooseBankContent: {
    padding: '16px',
    minHeight: '200px',
  },
  selectContent: {
    marginBottom: '30px',
  },
  alignRight: {
    display: 'flex',
    justifyContent: 'end',
  },
}));
const ChooseBankDialog = ({
  toggleDialog,
  loggedUserCompany,
  handleConfirmChooseBank,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const selecOptions = loggedUserCompany.bank_details
    .filter((item) => item?.iban)
    .reduce((prev, curr) => {
      const option = { text: curr.bank_name, value: curr._id };
      return [...prev, option];
    }, []);

  const defaultBank =
    loggedUserCompany.bank_details.find((item) => item?.isDefault) ||
    loggedUserCompany?.bank_details[0];
  const [bankId, setBankId] = useState(defaultBank._id || null);

  const renderBanks = () => {
    const bankChoosed = loggedUserCompany.bank_details.find(
      (bank) => bank._id === bankId
    );

    return (
      <BankCard
        required
        disabled={true}
        bank={bankChoosed || loggedUserCompany.bank_details[0]}
      />
    );
  };

  const handleOnClickConfirm = () => {
    if (handleConfirmChooseBank) {
      const bankChoosed = loggedUserCompany.bank_details.find(
        (bank) => bank._id === bankId
      );
      handleConfirmChooseBank(bankChoosed);
    }
  };
  return (
    <CustomGenericDialog
      title={t('invoice.form.email.dialog.choose_bank_title')}
      onCancel={toggleDialog}
      confirmText={t('invoice.form.email.dialog.confirm')}
      cancelText={t('no')}
      iconClose
      closeDialog={toggleDialog}
      color="secondary"
      maxWidth="sm"
    >
      <Grid className={classes.chooseBankContent}>
        <Grid className={classes.selectContent}>
          <Select
            value={bankId || selecOptions[0].value}
            onChange={(e) => setBankId(e.target.value)}
            variant="standard"
            margin="dense"
            fullWidth
            displayEmpty
          >
            {selecOptions.map(({ text, value }) => (
              <MenuItem key={`${text}, ${value}`} value={value}>
                {text}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        {bankId && (
          <>
            {renderBanks()}
            <Grid item xs={12}>
              <div className={classes.alignRight}>
                <AppButton
                  color="secondaryLight"
                  text={t('invoice.form.email.dialog.confirm')}
                  noBorder={true}
                  onClick={handleOnClickConfirm}
                />
              </div>
            </Grid>
          </>
        )}
      </Grid>
    </CustomGenericDialog>
  );
};

ChooseBankDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  loggedUserCompany: PropTypes.object.isRequired,
  handleConfirmChooseBank: PropTypes.func.isRequired,
};

export default ChooseBankDialog;
