import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  messageModal: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: '30%',
    padding: '30px 30px',
  },

  iconMessageModal: {
    width: '60px',
    height: '60px',
    marginBottom: '10px',
  },

  messageModalTitle: {
    textAlign: 'center',
    marginBottom: '10px',
  },

  buttonsBlock: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
  },
}));

export default useStyles;
