import React from 'react';
import PropTypes from 'prop-types';
import {
  header,
  inputPlaceholderActive,
  titleText,
  contentText,
  inputTextBold,
  subtitleText,
  smallTitleText,
  mBlueBoldN,
  mBlueN,
} from '../../assets/jss/components/appText';

export const AppTextHeader = ({ children }) => (
  <h2 style={header}>{children}</h2>
);
AppTextHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AppTextPlain = ({ children }) => (
  <p style={inputPlaceholderActive}>{children}</p>
);
AppTextPlain.propTypes = {
  children: PropTypes.node.isRequired,
};
export const MBlueBoldN = ({ children }) => (
  <p style={mBlueBoldN}>{children}</p>
);
MBlueBoldN.propTypes = {
  children: PropTypes.node.isRequired,
};
export const MBlueN = ({ children }) => <p style={mBlueN}>{children}</p>;
MBlueN.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AppTextTitle = ({ children, styles }) => (
  <h3 style={{ ...titleText, ...styles }}>{children}</h3>
);
AppTextTitle.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
};

export const AppTextContent = ({ children, styles }) => (
  <h3 style={{ ...contentText, ...styles }}>{children}</h3>
);
AppTextContent.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
};
export const AppGreyBold = ({ children, styles }) => (
  <h3 style={{ ...inputTextBold, ...styles }}>{children}</h3>
);
AppGreyBold.propTypes = {
  children: PropTypes.node.isRequired,
  styles: PropTypes.object,
};

export const AppTextSubTitle = ({ children }) => (
  <h3 style={subtitleText}>{children}</h3>
);
AppTextSubTitle.propTypes = {
  children: PropTypes.node.isRequired,
};

export const AppTextSmallTitle = ({ children, style }) => (
  <p style={{ ...smallTitleText, ...style }}>{children}</p>
);
AppTextSmallTitle.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
};
AppTextSmallTitle.defaultProps = {
  style: {},
};
