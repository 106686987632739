/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import MaterialTable from 'components/MaterialTable';
import { IconEdit } from 'components/AppIcons';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { useRouter } from 'hooks/routerHooks';
import { Refresh } from '@material-ui/icons';
import { fetchTransactionPaginate } from 'actions/WalletActions';

const WalletTransactionTable = ({ treezorWallet }) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const [refresh, setRefresh] = useState(false);
  const tableRef = React.createRef();
  const dispatch = useDispatch();
  // For filter button later
  const filters = {};

  function fetch() {
    return tableRef.current && tableRef.current.onQueryChange();
  }

  useEffect(() => {
    if (refresh) {
      fetch();
    }
  }, [refresh]);

  const renderDate = (rowData) => {
    return (
      <div>
        <div>{format(new Date(rowData.createdDate), 'dd/MM/yyyy')}</div>
        <div style={{ fontStyle: 'italic' }}>
          {format(new Date(rowData.executionDate), 'dd/MM/yyyy')}
        </div>
      </div>
    );
  };

  const columns = [
    {
      title: t('date'),
      field: 'date',
      filtering: false,
      render: (rowData) => renderDate(rowData),
    },
    {
      title: t('currency'),
      field: 'currency',
      options: {
        filtering: false,
      },
    },
    {
      title: t('debit'),
      field: 'debit',
      filtering: false,
      // eslint-disable-next-line react/display-name
      render: (rowData) =>
        rowData.transactionType === 'Transfer' ||
        rowData.transactionType === 'Payout' ? (
          <>{rowData.amount}</>
        ) : (
          ''
        ),
    },
    {
      title: t('credit'),
      field: 'credit',
      filtering: false,
      // eslint-disable-next-line react/display-name
      render: (rowData) =>
        rowData.transactionType === 'Payin' ? <>{rowData.amount}</> : '',
    },
    {
      title: t('transaction.reconcile'),
      field: 'reconcile',
      filtering: false,
      // eslint-disable-next-line react/display-name
      render: (rowData) => {
        return (
          <IconEdit
            sm
            onClick={() => {
              history.push(`/transaction/wallet/${rowData._id}`);
            }}
          />
        );
      },
    },
  ];

  const fetchData = async (query) => {
    const res = await dispatch(
      fetchTransactionPaginate(treezorWallet.walletId, query, filters, refresh)
    );
    if (res) {
      setRefresh(false);
      return { ...res, data: res.data || [] };
    }
  };

  return (
    <MaterialTable
      tableRef={tableRef}
      title="Transactions"
      data={(query) => fetchData(query)}
      columns={columns}
      options={{
        filtering: false,
        search: true,
        showTitle: false,
      }}
      actions={[
        {
          // eslint-disable-next-line react/display-name
          icon: () => <Refresh />,
          isFreeAction: true,
          onClick: () => setRefresh(true),
        },
      ]}
    />
  );
};

WalletTransactionTable.propTypes = {
  transactions: PropTypes.array,
};

export default WalletTransactionTable;
