/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },

  gridItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },

  // Card Style
  cardWrapper: {
    padding: '15px 20px',
    height: '100%',
  },

  cardTitleWrapper: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    padding: '5px 0 15px',
  },

  cardTitle: {
    fontSize: '19px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },

  cardTitleIcon: {
    fontSize: '24px',
    color: '#3E319D',
  },
}));

export default useStyles;
