import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';

import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { InputLabel, MenuItem, Select, Grid } from '@material-ui/core';

import TextField from '../../../components/FormElements/textField';
import AppButton from '../../../components/AppButton';

import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';

import state from './state';
import dispatch from './dispatch';

import styles from '../../../assets/jss/root';
import { contentText } from '../../../assets/jss/components/appText';
import LoadingIndicator from '../../../components/LoadingIndicatorDialog';

const useStyles = {
  ...styles,
  contentText,
};

class ClientProjectForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    const { handleSubmit: formikSubmit } = this.props;
    formikSubmit(e);
  }

  renderLoading(isSubmitting) {
    const { t } = this.props;

    if (isSubmitting) {
      return <LoadingIndicator title={t('loading_single')} open={true} />;
    }

    return null;
  }

  _renderClientDiv() {
    const {
      clientId,
      t,
      clients,
      classes,
      handleChange,
      setFieldValue,
      values,
    } = this.props;
    if (!clientId) {
      const filteredClients = _.filter(
        _.sortBy(clients.data, 'name'),
        (o) => !o.deleted
      );
      return (
        <div>
          <br />
          <InputLabel htmlFor="age-simple">
            {t('settings_section.projects.form_label_client')}
          </InputLabel>
          <Select
            color="secondary"
            style={{ width: '100%' }}
            name="client_id"
            value={values.client_id}
            onChange={(e) => {
              handleChange(e);
              setFieldValue('client_id', e.target.value);
            }}
            className={classes.textFieldWide}
          >
            {filteredClients.map((client, index) => (
              <MenuItem key={index} value={client._id}>
                {client.name}
              </MenuItem>
            ))}
          </Select>
        </div>
      );
    }

    return (
      <TextField
        type="text"
        name="clientName"
        label={t('settings_section.projects.form_label_client')}
        placeholder={t('settings_section.projects.form_label_client')}
        value={clients.data[clientId].name}
        className={classes.textFieldWide}
        disabled={true}
      />
    );
  }

  render() {
    const {
      t,
      errors,
      values,
      closeDialog,
      deleteDialog,
      id,
      isSubmitting,
      classes,
      userCanDeleteProject,
      userCanUpdateProject,
    } = this.props;

    return (
      <div className="section p-10">
        {this.renderLoading(isSubmitting)}
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {errors && errors.internalError && (
                <div className="row">
                  <div className="input-field col s12">
                    <span className="helper-text">{errors.internalError}</span>
                  </div>
                </div>
              )}
            </Grid>

            {values.account && (
              <Grid item xs={12}>
                <div className={classes.contentText}>
                  {t('settings_section.projects.number')}: {values.account}
                </div>
              </Grid>
            )}

            <Grid item xs={12}>
              {this._renderClientDiv()}
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="name"
                required={true}
                label={t('settings_section.projects.form_label_name')}
                placeholder={t('settings_section.projects.form_label_name')}
                color="secondary"
                disabled={isSubmitting || !userCanUpdateProject}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="description"
                required={true}
                color="secondary"
                label={t('settings_section.projects.form_label_description')}
                placeholder={t(
                  'settings_section.projects.form_label_description'
                )}
                disabled={isSubmitting || !userCanUpdateProject}
              />
            </Grid>

            <Grid item container xs={12} justify="flex-end">
              {id && userCanDeleteProject ? (
                <AppButton
                  color="transparentGrey"
                  text={t('delete')}
                  onClick={deleteDialog}
                />
              ) : null}

              <AppButton
                color="transparentGrey"
                text={t('cancel')}
                onClick={closeDialog}
              />

              {userCanUpdateProject && (
                <AppButton
                  color="transparentGrey"
                  text={t('draft')}
                  type="button"
                  onClick={this.onSubmit}
                />
              )}
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  }
}

ClientProjectForm.propTypes = {
  classes: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  status: PropTypes.object,
  values: PropTypes.object.isRequired,
  closeDialog: PropTypes.func,
  deleteDialog: PropTypes.func,
  t: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  addingNewProject: PropTypes.bool,
  clientId: PropTypes.string,
  clients: PropTypes.object,
  id: PropTypes.string,
  isSubmitting: PropTypes.bool.isRequired,
  childRef: PropTypes.object,
  domRef: PropTypes.object,
  onChange: PropTypes.func,
  userCanDeleteProject: PropTypes.bool,
  userCanUpdateProject: PropTypes.bool,
  handleSubmit: PropTypes.func,
};

const ClientProjectWithForm = withFormik({
  displayName: 'ClientProjectForm',
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(ClientProjectForm);

const ClientProjectFormWithStyles = withStyles(useStyles)(
  ClientProjectWithForm
);

const TranslatedClientProjectFormWithStyles = withTranslation()(
  ClientProjectFormWithStyles
);

export default connect(state, dispatch)(TranslatedClientProjectFormWithStyles);
