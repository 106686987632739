import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import { style } from '../../assets/jss/style';

/* eslint import/prefer-default-export: off */
const SideBarRight = ({ children, wrapForm, classes }) => {
  // const [barOpened, setBar] = useState(false);

  const sideBarRightClasses = classNames({
    [classes.rightSideBarWrapForm]: wrapForm,
    [classes.rightSideBarWrap]: !wrapForm,
  });
  return (
    <>
      <div className={sideBarRightClasses} style={{ zIndex: 1000 }}>
        <div className={classes.toolsContainer}>{children}</div>
      </div>
    </>
  );
};

SideBarRight.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
  wrapForm: PropTypes.bool,
  classes: PropTypes.object,
};

export default withStyles(style)(SideBarRight);
