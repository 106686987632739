import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchClientProjects = async (filter) =>
  axios.get(`${process.env.REACT_APP_CLIENT_URL}/projects`, { params: filter });
// axios.get(`http://localhost:3000/dev/v1/projects`, {
//   params: filter,
// });

const createClientProject = async (project) =>
  axios.post(`${process.env.REACT_APP_CLIENT_URL}/projects`, project);
// axios.post(`http://localhost:3000/dev/v1/projects`, project);

const editClientProject = async (project) =>
  axios.put(
    `${process.env.REACT_APP_CLIENT_URL}/projects/${project._id}`,
    project
  );
// axios.put(`http://localhost:3000/dev/v1/projects/${project._id}`, project);

const deleteClientProject = async (id) =>
  axios.delete(`${process.env.REACT_APP_CLIENT_URL}/projects/${id}`);

export default {
  fetchClientProjects,
  createClientProject,
  editClientProject,
  deleteClientProject,
};
