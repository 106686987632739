import service from 'services/BankinApiService';
// import { setGlobalError } from 'actions/GlobalError';

import {
  FETCHING_BANK_ACCOUNTS,
  FETCH_BANK_ACCOUNT_FAILED,
  FETCH_BANK_ACCOUNT_SUCCESS,
  FETCHING_BALANCES,
  FETCH_BALANCES_FAILED,
  FETCH_BALANCES_SUCCESS,
} from 'actions/Types';

export const gettingBankAccounts = () => ({
  type: FETCHING_BANK_ACCOUNTS,
});

export const getBankAccountsSuccess = (resources) => ({
  type: FETCH_BANK_ACCOUNT_SUCCESS,
  payload: { resources },
});

export const getBankAccountsFailed = (error) => ({
  type: FETCH_BANK_ACCOUNT_FAILED,
  payload: { error },
});

export const getBankAccounts = (companyId) => async (dispatch) => {
  dispatch(gettingBankAccounts());
  try {
    const result = await service.getBankAccounts(companyId);
    dispatch(getBankAccountsSuccess(result.data));
  } catch (error) {
    // dispatch(setGlobalError(error));
    dispatch(getBankAccountsFailed(error));
  }
};

export const gettingBalances = () => ({
  type: FETCHING_BALANCES,
});

export const getBalancesSuccess = (resources) => ({
  type: FETCH_BALANCES_SUCCESS,
  payload: { resources },
});

export const getBalancesFailed = (error) => ({
  type: FETCH_BALANCES_FAILED,
  payload: { error },
});
