export default (values, { setErrors, setStatus, setSubmitting, props }) => {
  const cb = (err) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      setStatus({ success: true });

      props.history.push('/settings/roles');
    }
  };

  const { id } = props.match.params;

  if (!id) {
    props.createRole(values, cb);
  } else {
    props.updateRole(id, values, cb);
  }
};
