// eslint-disable-next-line import/prefer-default-export
export const TRANSACTION_TYPE = {
  DEBIT: 'debit',
  CREDIT: 'credit',
};

export const TRANSACTION_STATE = {
  RECONCILED: 'reconciled',
  NOT_RECONCILED: 'not_reconciled',
};
