import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

function WalletDeleteReConfirm2(props) {
  const { onChangeStep } = props;
  const history = useHistory();

  const onClickContinue = () => {
    onChangeStep(6);
  };

  const onClickKeepMyAccount = () => {
    history.push('/bank-bbf/accounts');
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box style={{ padding: '10px 0', maxWidth: '800px' }}>
        <Box style={{ paddingBottom: '10px' }}>
          <Typography
            variant="h4"
            color="primary"
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              paddingBottom: '5px',
            }}
          >
            Vous souhaitez clôturer votre compte
          </Typography>
          <Typography
            variant="h4"
            color="primary"
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              paddingBottom: '5px',
            }}
          >
            A la date d’aujourd’hui vous ne remplissez pas les conditions pour
            clôturer votre compte.
          </Typography>
          <Typography
            variant="h5"
            style={{ color: 'black', textAlign: 'center' }}
          >
            Pour rappel à la fin du mois ;
          </Typography>
        </Box>
        <Box
          style={{
            paddingBottom: '30px',
            color: '#f44336',
          }}
        >
          <ul>
            <li>
              <Typography style={{ fontSize: '16px' }}>
                Votre compte doit présenter un solde à 0 en fin de mois
              </Typography>
            </li>
            <li>
              <Typography style={{ fontSize: '16px' }}>
                Vous ne devez plus avoir aucune opération en cours à la fin du
                mois – entrantes ou sortantes
              </Typography>
            </li>
          </ul>

          <Typography style={{ fontSize: '16px' }}>
            Toutefois, si vous ne remplissez pas les conditions ci-dessous à la
            fin du mois, votre compte restera ouvert jusqu’au dernier jour du
            mois suivant (le dernier jour du mois suivant).
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '0 120px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onClickContinue}
          >
            Je souhaite clôturer définitivement mon compte en fin de mois si je
            respecte les conditions
          </Button>
          <Button
            variant="outlined"
            color="default"
            size="large"
            onClick={onClickKeepMyAccount}
          >
            Conserver mon compte
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

WalletDeleteReConfirm2.propTypes = {
  onChangeStep: PropTypes.func,
};

export default WalletDeleteReConfirm2;
