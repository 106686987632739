import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';

import { AppDividerXS, AppSepLine } from '../../AppDividers';
import AppButton from '../../AppButton';

const FormActions = ({
  errors,
  isSubmitting,
  t,
  onSave,
  onSend,
  onValidate,
  onPay,
  onCancel,
  state = 'draft',
  userCanValidate,
  isOwner,
  readOnly,
  horizontal,
  newExpense,
}) => {
  function renderOnSendButton() {
    if (!readOnly && !newExpense && state === 'draft' && isOwner) {
      return (
        <Tooltip
          arrow
          title={t(`expenses.tooltip.${state}.send`)}
          placement="top"
        >
          <span>
            <AppButton
              onClick={onSend}
              type="button"
              fullWidth={true}
              text={t('send')}
              color="primary"
              noBorder={true}
              isDisabled={isSubmitting}
            />
          </span>
        </Tooltip>
      );
    }

    return null;
  }

  function renderSaveButton() {
    if (!readOnly && newExpense) {
      return (
        <Fragment>
          {!horizontal && <AppDividerXS />}
          <Tooltip
            arrow
            title={t(`expenses.tooltip.${state}.save`)}
            placement="top"
          >
            <span>
              <AppButton
                onClick={onSave}
                type="button"
                fullWidth={true}
                text={t('draft')}
                noBorder={true}
                color="primary"
                isDisabled={isSubmitting}
              />
            </span>
          </Tooltip>
        </Fragment>
      );
    }

    return null;
  }

  function renderValidateButton() {
    if (userCanValidate && ['pending'].includes(state)) {
      return (
        <Fragment>
          {!horizontal && <AppDividerXS />}
          <Tooltip
            arrow
            title={t(`expenses.tooltip.${state}.validate`)}
            placement="top"
          >
            <span>
              <AppButton
                onClick={onValidate}
                type="button"
                fullWidth={true}
                text={t('validate')}
                noBorder={true}
                color="primary"
                isDisabled={isSubmitting}
              />
            </span>
          </Tooltip>
        </Fragment>
      );
    }

    return null;
  }

  function renderPayButton() {
    if (userCanValidate && ['validated'].includes(state)) {
      return (
        <Fragment>
          {!horizontal && <AppDividerXS />}
          <Tooltip
            arrow
            title={t(`expenses.tooltip.${state}.pay`)}
            placement="top"
          >
            <span>
              <AppButton
                onClick={onPay}
                type="button"
                fullWidth={true}
                text={t('transaction.reconcile')}
                noBorder={true}
                color="primary"
                isDisabled={isSubmitting}
              />
            </span>
          </Tooltip>
        </Fragment>
      );
    }
    return null;
  }

  function renderRejectlButton() {
    if (userCanValidate && ['pending'].includes(state)) {
      return (
        <Fragment>
          {!horizontal && <AppDividerXS />}
          <Tooltip
            arrow
            title={t(`expenses.tooltip.${state}.reject`)}
            placement="top"
          >
            <span>
              <AppButton
                onClick={onCancel}
                type="button"
                fullWidth={true}
                text={t('reject')}
                noBorder={true}
                color="secondary"
                isDisabled={isSubmitting}
              />
            </span>
          </Tooltip>
        </Fragment>
      );
    }
    return null;
  }

  return (
    <>
      {errors && (
        <pre style={{ display: 'none' }}>{JSON.stringify(errors, null, 2)}</pre>
      )}
      {renderOnSendButton()}
      {renderSaveButton()}
      {renderValidateButton()}
      {renderPayButton()}
      {renderRejectlButton()}
      {!horizontal && <AppSepLine />}
    </>
  );
};
FormActions.propTypes = {
  isSubmitting: PropTypes.bool,
  t: PropTypes.func,
  onSave: PropTypes.func,
  onSend: PropTypes.func,
  onCancel: PropTypes.func,
  onValidate: PropTypes.func,
  onPay: PropTypes.func,
  state: PropTypes.string,
  userCanValidate: PropTypes.bool,
  isOwner: PropTypes.bool,
  readOnly: PropTypes.bool,
  horizontal: PropTypes.bool,
  newExpense: PropTypes.bool,
  errors: PropTypes.object,
};

FormActions.defaultProps = {
  horizontal: false,
};
export default FormActions;
