import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useThunkDispatch } from '../../../hooks/generalHooks';
import { deleteClient } from '../../../actions/ClientActions';
import AppDialog from '../../../components/AppDialog';

const ClientDeleteDialog = ({ showDialog, closeDialog, selectedClientId }) => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();

  const deleteClientCb = useCallback(
    () => dispatch(deleteClient(selectedClientId, closeDialog)),
    [dispatch, selectedClientId]
  );

  if (showDialog) {
    return (
      <AppDialog
        title={t('sure')}
        closeDialog={closeDialog}
        sm
        iconClose
        onConfirmText={t('yes')}
        onCancelText={t('no')}
        footer
        onConfirm={deleteClientCb}
        contentText={t('settings_section.clients.delete_client_content')}
        color="secondary"
      />
    );
  }

  return null;
};

ClientDeleteDialog.propTypes = {
  showDialog: PropTypes.bool,
  selectedClientId: PropTypes.string,
  closeDialog: PropTypes.func,
};

export default ClientDeleteDialog;
