import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import DueDates from './DueDates';
import PrePayments from './PrePayments';

import colors from '../../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  columnStartStretch: {
    display: 'flex',
    flexDirection: 'column',
  },
  blueContainerStart: {
    backgroundColor: colors.backgroundGrey,
    color: colors.white,
    height: '34px',
    width: '260px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 14px',
    fontWeight: 'normal',
    fontSize: '10px',
  },
  captionBigSelected: {
    color: colors.textDarkGrey,
    fontFamily: 'Kumbh Sans',
    fontSize: '12px',
    lineHeight: '14px',
  },
  greyWrapper: {
    borderRadius: '4px',
    border: '1px solid #A6A6A6',
    backgroundColor: colors.white,
  },
  marginMid: {
    marginTop: '20px',
  },
}));

const PaymentTypes = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [prePayments, setPrePayments] = useState([]);

  const {
    values: {
      paying_mode: { display },
    },
  } = useFormikContext();

  const handlePrepaymentDueDates = (newPrePayments) => {
    setPrePayments(newPrePayments);
  };

  return (
    <div className={classNames(classes.columnStartStretch)}>
      <div className={classes.blueContainerStart}>
        <p>{t('invoices.payments')}</p>
      </div>
      <div className={classes.captionBigSelected}>
        <p>{display}</p>
      </div>
      <PrePayments
        handlePrepaymentDueDates={handlePrepaymentDueDates}
        prePaymentsList={prePayments}
      />
      <DueDates prePaymentsList={prePayments} />
    </div>
  );
};

export default PaymentTypes;
