import emailTemplateServices from '../../services/EmailTemplateService';
import {
  FETCH_EMAIL_TEMPLATES_SUCCESS,
  FETCH_EMAIL_TEMPLATES_FAILED,
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
} from '../Types';

import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';
import { userCanUpdateEmailTemplate } from '../../selectors/rightsSelector/emailTemplateRequests';

export const getEmailTemplates = () => async (dispatch, getState) => {
  try {
    const companyId = selectLoggedUserCompanyId(getState());
    if (!companyId) {
      dispatch({ type: FETCH_EMAIL_TEMPLATES_SUCCESS, payload: [] });
      return;
    }
    const result = await emailTemplateServices.fetchEmailTemplates();

    dispatch({
      type: FETCH_EMAIL_TEMPLATES_SUCCESS,
      payload: result.data,
    });
  } catch (error) {
    dispatch({ type: FETCH_EMAIL_TEMPLATES_FAILED, error });
  }
};

export const createEmailTemplate =
  (id, values, cb) => async (dispatch, getState) => {
    try {
      if (!userCanUpdateEmailTemplate(getState())) {
        cb({ errorMessage: 'user doesnt have right to update email template' });
        return;
      }

      const result = await emailTemplateServices.createEmailTemplate(values);
      dispatch({
        type: CREATE_EMAIL_TEMPLATE,
        payload: {
          oldId: id,
          id: result.data._id,
          data: result.data,
        },
      });
      cb();
    } catch (error) {
      cb(error);
    }
  };

export const updateEmailTemplate =
  (id, values, cb) => async (dispatch, getState) => {
    try {
      if (!userCanUpdateEmailTemplate(getState())) {
        cb({ errorMessage: 'user doesnt have right to update email template' });
        return;
      }

      const result = await emailTemplateServices.updateEmailTemplate(
        id,
        values
      );
      dispatch({
        type: UPDATE_EMAIL_TEMPLATE,
        payload: {
          id,
          data: result.data,
        },
      });
      cb();
    } catch (error) {
      cb(error);
    }
  };
