import { bindActionCreators } from 'redux';
import {
  createInvoiceExpense,
  updateInvoiceExpense,
} from '../../../actions/ExpensesActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createInvoiceExpense,
      updateInvoiceExpense,
    },
    dispatch
  );
