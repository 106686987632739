import { queryStringHelper } from 'helpers/querystringHelper';
import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchUnexports = async () => {
  // todo: remove later
  if (window.debugger === 1) {
    return axios.get(`${process.env.REACT_APP_FEC_URL}/unexported-data/test`);
  }

  return axios.get(`${process.env.REACT_APP_FEC_URL}/unexported-data`);
};

const fetchExport = async (query, companyId) => {
  // todo: remove later
  if (window.debugger === 1) {
    return axios.get(
      `${process.env.REACT_APP_FEC_URL}/exports/${companyId}/test?${
        queryStringHelper(query).querystring
      }`
    );
  }

  return axios.get(
    `${process.env.REACT_APP_FEC_URL}/exports/${companyId}?${
      queryStringHelper(query).querystring
    }`
  );
};

const generateExport = async (data) => {
  // todo: remove later
  if (window.debugger === 1) {
    return axios.post(
      `${process.env.REACT_APP_FEC_URL}/exports/generate/test`,
      data
    );
  }

  return axios.get(`${process.env.REACT_APP_API_URL_NO_VERSION}/fec/export`, {
    responseType: 'blob',
  });
};

const downloadExport = async (id) => {
  return axios.get(
    `${process.env.REACT_APP_API_URL_NO_VERSION}/fec/download/${id}`,
    {
      responseType: 'blob',
    }
  );
};

export default { fetchUnexports, fetchExport, generateExport, downloadExport };
