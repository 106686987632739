import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AppState from './AppState';

const PurchaseState = ({ state }) => {
  const { t } = useTranslation();

  if (state === t(`expenses.expense.state.draft`)) {
    return (
      <AppState state="greyState" value={t(`expenses.expense.state.draft`)} />
    );
  }

  if (state === t(`expenses.expense.state.pending`)) {
    return (
      <AppState
        state="yellowState"
        value={t(`expenses.expense.state.pending`)}
      />
    );
  }

  if (state === t(`expenses.expense.state.validated`)) {
    return (
      <AppState
        state="greenState"
        value={t(`expenses.expense.state.upcoming`)}
      />
    );
  }

  if (state === t(`expenses.expense.state.paid`)) {
    return (
      <AppState state="greenState" value={t(`expenses.expense.state.paid`)} />
    );
  }

  if (state === t(`expenses.expense.state.canceled`)) {
    return (
      <AppState
        state="greyState"
        value={t(`expenses.expense.state.canceled`)}
      />
    );
  }

  if (state === t(`expenses.expense.state.rejected`)) {
    return (
      <AppState
        state="greyState"
        value={t(`expenses.expense.state.rejected`)}
      />
    );
  }

  if (state === t(`expenses.expense.state.payoutpending`)) {
    return (
      <AppState
        state="greenState"
        value={t(`expenses.expense.state.payoutpending`)}
      />
    );
  }

  if (state === t(`expenses.expense.state.overdue`)) {
    return (
      <AppState state="redState" value={t(`expenses.expense.state.overdue`)} />
    );
  }

  return null;
};

PurchaseState.propTypes = {
  state: PropTypes.string.isRequired,
};

export default PurchaseState;
