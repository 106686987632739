// TO REMOVE

import React, { useContext, Fragment } from 'react';

import { forOwn } from 'lodash';

import { Grid } from '@material-ui/core';
import TextField from '../../FormElements/textField';
import SelectAutoComplete from '../../SelectAutocomplete';
import GoogleDirectionLink from '../../GoogleDirectionLink';

import { isOD, isWithDistanceRate } from '../../../helpers/TravelHelpers';

import { ExpenseFormContext } from './Context';

function createDistanceRate(dd) {
  const arr = [];
  forOwn(dd, (types, distanceRateIndex) =>
    forOwn(types, (type, typeIndex) =>
      arr.push(`${distanceRateIndex} - ${typeIndex}`)
    )
  );

  return arr;
}

const DistanceInfo = () => {
  const { t, distanceRates, values, setters, index, selectedCategory } =
    useContext(ExpenseFormContext);

  const {
    travel_distance_rate: distanceRate,
    travel_from: from,
    travel_to: to,
  } = values;

  const needsDistanceRate = isWithDistanceRate(selectedCategory._id);
  const needsOD = isOD(selectedCategory._id);

  const { changeExpenseValue, changeDistance, changeDistanceRate } = setters;

  function onDistanceChanged({ target: { value } }) {
    changeDistance(index, value);
  }

  function changeFrom({ target: { value } }) {
    changeExpenseValue(`travel_from`, index, value);
  }

  function changeTo({ target: { value } }) {
    changeExpenseValue(`travel_to`, index, value);
  }

  function onDistanceRateChanged(DistanceRateClicked) {
    if (!DistanceRateClicked) {
      // the user cleared the selection - we do nothing. This will force the user to select a new value
      return;
    }
    changeDistanceRate(index, DistanceRateClicked);
  }

  function displayGoogleLink() {
    return isWithDistanceRate(selectedCategory._id) && from !== '' && to !== '';
  }

  if (!needsDistanceRate && !needsOD) {
    return null;
  }

  return (
    <Fragment>
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            color="secondary"
            name={`expenses[${index}].travel_from`}
            id={`expenses[${index}].travel_from`}
            label={t('expenses.expense.form_expensefrom')}
            placeholder={t('expenses.expense.form_expensefrom')}
            required
            onChange={changeFrom}
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            color="secondary"
            name={`expenses[${index}].travel_to`}
            placeholder={t('expenses.expense.form_expenseto')}
            label={t('expenses.expense.form_expenseto')}
            required
            onChange={changeTo}
          />
        </Grid>

        {needsDistanceRate && (
          <Grid
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                color="secondary"
                name={`expenses[${index}].travel_distance`}
                placeholder={t('expenses.expense.form_distance')}
                label={t('expenses.expense.form_distance')}
                required
                onChange={onDistanceChanged}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <SelectAutoComplete
                required
                id="distance_rate"
                name={`expenses[${index}].travel_distance_rate`}
                label={t('expenses.expense.form_distance_rate')}
                helperText={t('expenses.expense.form_distance_rate')}
                getOptionLabel={(elem) => elem}
                onChange={onDistanceRateChanged}
                color="secondary"
                values={createDistanceRate(distanceRates).sort()}
                valueSelected={distanceRate || null}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {displayGoogleLink() && (
        <GoogleDirectionLink origin={from} destination={to} />
      )}
    </Fragment>
  );
};

export default DistanceInfo;
