import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form, Field } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import _ from 'lodash';

import { Grid } from '@material-ui/core';

import { getCorseVatRates } from 'helpers/CategoryHelpers';
import { isCorseCompany } from 'actions/CompanyActions/CompanyHelpers';
import { fetchItems } from 'actions/ItemsActions';
import AppRadioGroup from '../../../components/AppRadioGroup';
import AppRadioButton from '../../../components/AppRadioButton';
import TextField from '../../../components/FormElements/textField';
import LoadingIndicator from '../../../components/LoadingIndicatorDialog';

import SelectAutoComplete from '../../../components/SelectAutocomplete';

import AppButton from '../../../components/AppButton';

import { debounceFn, itemListUtils } from '../../../helpers/utils';

import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';

import state from './state';
import dispatch from './dispatch';

import styles from '../../../assets/jss/root';
// import capitalizeForm from '../../../helpers/capitalizeForm';

class ItemForm extends Component {
  constructor(props) {
    super(props);

    const {
      loggedUserCompany: {
        company: { settings },
      },
      vatRates,
      t,
    } = this.props;
    this.state = {
      units: itemListUtils(t),
      // units: ITEM_UNITS,
      isApplyVAT: settings?.tax_registration?.tva,
      vatRates,
      company: this.props?.loggedUserCompany?.company,
    };

    this._onTypeSelected = this._onTypeSelected.bind(this);
    this._selectUnitAndSet = this._selectUnitAndSet.bind(this);
    this._selectVatAndSet = this._selectVatAndSet.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    const { handleSubmit: formikSubmit } = this.props;
    dispatch(fetchItems());
    formikSubmit(e);
  }

  renderLoading(isSubmitting) {
    const { t } = this.props;
    if (isSubmitting) {
      return <LoadingIndicator title={t('loading_single')} open={true} />;
    }
    return null;
  }

  _onTypeSelected(e) {
    const { setFieldValue } = this.props;
    setFieldValue('type', e.target.value);
  }

  _selectUnitAndSet(unit) {
    // called with a vtatRate object
    if (unit) {
      this.props.setFieldValue('unit', unit.value);
    } else {
      this.props.setFieldValue('unit', '');
    }
  }

  _selectVatAndSet(vatRate) {
    // called with a vtatRate object
    if (vatRate) {
      this.props.setFieldValue('vat_rate', vatRate.value);
    } else {
      this.props.setFieldValue('vat_rate', '');
    }
  }

  componentDidMount() {
    if (!this.state.isApplyVAT) {
      this._selectVatAndSet(this.state.vatRates?.[0]);
    }
  }

  getVatRates() {
    if (isCorseCompany(this.state.company)) {
      return getCorseVatRates();
    }
    return this.state.vatRates;
  }

  render() {
    const { t, errors, touched, values, closeDialog, isSubmitting } =
      this.props;
    return (
      <Form>
        {this.renderLoading(isSubmitting)}
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {errors && errors.internalError && (
              <div className="row">
                <div className="input-field col s12">
                  <span className="helper-text" style={{ color: 'red' }}>
                    {errors.internalError}
                  </span>
                </div>
              </div>
            )}
          </Grid>
          <Grid item xs={12}>
            <AppRadioGroup
              row
              name="type"
              valueSelected={values.type || 'Product'}
              onChange={this._onTypeSelected}
            >
              <AppRadioButton
                color="secondary"
                value="M"
                text={t('item.product')}
                disabled={isSubmitting}
              />
              <div style={{ padding: '15px' }} />
              <AppRadioButton
                color="secondary"
                value="P"
                text={t('item.manufactured_product')}
                disabled={isSubmitting}
              />
              <div style={{ padding: '15px' }} />
              <AppRadioButton
                color="secondary"
                value="S"
                text={t('item.service')}
                disabled={isSubmitting}
              />
              <div style={{ padding: '15px' }} />
              <AppRadioButton
                color="secondary"
                value="T"
                text={t('item.works')}
                disabled={isSubmitting}
              />
              <div style={{ padding: '15px' }} />
            </AppRadioGroup>
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="name"
              label={t('item.label')}
              id="name"
              fullWidth
              required
              placeholder={t('item.name')}
              color="primary"
              // onChange={e => {
              //   capitalizeForm(e, setFieldValue);
              // }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              type="text"
              name="description"
              label={t('item.description')}
              id="description"
              fullWidth
              placeholder={t('item.description')}
              color="primary"
            />
          </Grid>
          <Grid item xs={3}>
            <SelectAutoComplete
              name="vat_rate"
              color="primary"
              label={t('expenses.expense.form_vatrate_0')}
              onChange={this._selectVatAndSet}
              getOptionLabel={(option) => t(option.display)}
              values={this.getVatRates().map((item) => ({
                ...item,
                display: t(item.display),
              }))}
              valueSelected={_.find(
                this.getVatRates(),
                (elem) => elem.value === parseFloat(values.vat_rate)
              )}
              required
              showError={errors.vat_rate}
              error={errors.vat_rate}
              readOnly={!this.state.isApplyVAT}
            />
          </Grid>
          <Grid item xs={3}>
            <SelectAutoComplete
              name="unit"
              color="primary"
              label={t('item.unit')}
              onChange={this._selectUnitAndSet}
              getOptionLabel={(option) => option.label}
              values={_.orderBy(this.state.units, ['label'], ['asc'])}
              valueSelected={_.find(
                this.state.units,
                (elem) => elem.value === values.unit
              )}
              showError={errors.unit && touched.unit}
              error={errors.unit}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={TextField}
              variant="outlined"
              required
              name="net_price"
              type="number"
              margin="dense"
              label={t('item.net')}
              placeholder={t('item.net')}
            />
          </Grid>
          <Grid container item xs={12} justify="flex-end">
            <AppButton
              isDisabled={isSubmitting}
              color="transparentGrey"
              text={t('cancel')}
              onClick={closeDialog}
            />

            <AppButton
              isDisabled={isSubmitting}
              color="secondaryLight"
              text={t('draft')}
              onClick={debounceFn(this.onSubmit)}
              noBorder={true}
              // onClick={e =>  this.props.onCreated(e)}
              // onClick={e => !!this.props.onCreated && this.props.onCreated(e)}
            />
          </Grid>
        </Grid>
      </Form>
    );
  }
}

ItemForm.propTypes = {
  classes: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  status: PropTypes.object,
  values: PropTypes.object.isRequired,
  closeDialog: PropTypes.func,
  onCreated: PropTypes.func,
  setFieldValue: PropTypes.func,
  t: PropTypes.func,
  vatRates: PropTypes.array,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  loggedUserCompany: PropTypes.object,
};

const AdvancesWithForm = withFormik({
  displayName: 'ItemForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(ItemForm);

const ItemFormWithStyles = withStyles(styles)(AdvancesWithForm);

const TranslatedItemFormWithStyles = withTranslation()(ItemFormWithStyles);

export default connect(state, dispatch)(TranslatedItemFormWithStyles);
