import {
  userCanReadItem,
  userCanUpdateItem,
  userCanCreateItem,
} from '../../../selectors/rightsSelector/itemRequests';

export default (state) => ({
  items: state.items,
  userCanReadItem: userCanReadItem(state),
  userCanUpdateItem: userCanUpdateItem(state),
  userCanCreateItem: userCanCreateItem(state),
});
