/* Packages */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

/* Components */
import { EyesIcon } from 'components/AppIcons';

/* Selectors */
import {
  userCanReadExpense,
  userCanUpdateExpense,
} from 'selectors/rightsSelector/expenseRequests';

/* Constants */
import { CLEAR_EXPENSE } from 'actions/Types';

const TransferTableEdit = ({ rowData, editPath, pageTitle }) => {
  const canReadExpense = useSelector(userCanReadExpense);
  const canUpdateExpense = useSelector(userCanUpdateExpense);

  const { __t: expenseType } = rowData;

  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();

  const canSeeExpensePage = canUpdateExpense || canReadExpense;

  const userCanSeeButton =
    ['GroupedExpense', 'SimpleExpense', 'InvoiceExpense', 'Purchase'].includes(
      expenseType
    ) && canSeeExpensePage;

  if (!userCanSeeButton) {
    return null;
  }

  const onClickEdit = () => {
    dispatch({ type: CLEAR_EXPENSE });
    history.push({
      ...editPath(rowData),
      state: {
        ...editPath(rowData).state,
        goBackPath: location.pathname,
        goBackQueryParams: location.search,
        pageTitle,
        notShowReconclilier: true,
      },
    });
  };

  return <EyesIcon sm onClick={onClickEdit} />;
};

TransferTableEdit.propTypes = {
  rowData: PropTypes.any,
  editPath: PropTypes.func,
  pageTitle: PropTypes.string,
};

export default TransferTableEdit;
