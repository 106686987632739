/* Packages */
import { Box, Tooltip, Typography, withStyles } from '@material-ui/core';
import React from 'react';
import PropTypes from 'prop-types';

/* Icons */
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import HelpIcon from '@material-ui/icons/Help';

/* Styles */
import useStyles from './styles';

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(16),
    border: '1px solid #dadde9',
    maxWidth: 'none',
  },
}))(Tooltip);

const WalletDeleteConditionIcon = (props) => {
  const { status, title, tooltipTitle } = props;

  const classes = useStyles();

  return (
    <Box className={classes.conditionIconWrapper}>
      {status ? (
        <CheckCircleIcon className={classes.successIcon} />
      ) : (
        <CancelIcon className={classes.errorIcon} />
      )}

      <Typography className={classes.title}>{title}</Typography>

      <HtmlTooltip title={tooltipTitle} placement="top">
        <HelpIcon style={{ fontSize: 15, color: '#424242' }} />
      </HtmlTooltip>
    </Box>
  );
};

WalletDeleteConditionIcon.propTypes = {
  status: PropTypes.bool,
  title: PropTypes.string,
  tooltipTitle: PropTypes.string,
};

WalletDeleteConditionIcon.defaultProps = {
  status: true,
  title: '',
  tooltipTitle: '',
};

export default WalletDeleteConditionIcon;
