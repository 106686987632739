import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { Box, List } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

/* Import components */
import AppDashboardCard from 'components/AppDashboard/components/AppDashboardCard';
import AppDashboardListItem from 'components/AppDashboard/components/AppDashboardListItem';

/* Import custom hooks */
import { useRouter } from 'hooks/routerHooks';

/* Import services */
import expenseServices from 'services/ExpenseService';
import CircularProgressCentered from 'components/CircularProgressCentered';
import NumberFormat from 'helpers/NumberFormat';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    minHeight: 300,
  },
}));

const TopProvider = () => {
  const classes = useStyles();

  const { history } = useRouter();
  const { t } = useTranslation();
  const providers = useSelector((state) => state.providers);

  const [topProviders, setTopProviders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchTopProviders = async () => {
    try {
      setLoading(true);
      const res = await expenseServices.fetchAllPurchases();

      const listProviders = res.data.docs
        .map((elem) => ({
          provider: providers[elem.provider]?.name || '',
          _id: elem.provider,
          taxBasisTotalAmount: elem.taxBasisTotalAmount,
        }))
        .filter((elem) => elem.provider.length > 0);

      const helper = {};
      const reduceListProviders = listProviders.reduce((r, o) => {
        const key = o.provider;

        if (!helper[key]) {
          helper[key] = { ...o }; // create a copy of o
          r.push(helper[key]);
        } else {
          helper[key].taxBasisTotalAmount += o.taxBasisTotalAmount;
        }

        return r;
      }, []);

      const top10Providers = reduceListProviders
        .sort((a, b) => b.taxBasisTotalAmount - a.taxBasisTotalAmount)
        .slice(0, 10);

      setTopProviders(top10Providers);
    } catch (_error) {
      setError(_error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTopProviders();
  }, []);

  return (
    <AppDashboardCard title={t('dasboard.top_10_paid_provider')}>
      {error && <p>{error}</p>}
      {!loading || topProviders?.length ? (
        <Box className={classes.container}>
          <List style={{ padding: 0 }}>
            {topProviders.map((provider, index) => {
              return (
                <AppDashboardListItem
                  key={index}
                  titleLeft={provider?.provider}
                  titleRight={`${NumberFormat.n(
                    provider?.taxBasisTotalAmount
                  )}€`}
                  onClick={() => {
                    const expenseFilters = {
                      //  state: ['validated', 'paid'],
                      provider: [provider._id],
                    };

                    history.push(
                      queryString.stringifyUrl(
                        { url: 'purchases/list', query: expenseFilters },
                        { arrayFormat: 'index' }
                      )
                    );
                  }}
                />
              );
            })}
          </List>
        </Box>
      ) : (
        <CircularProgressCentered />
      )}
    </AppDashboardCard>
  );
};

export default TopProvider;
