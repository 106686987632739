import * as Yup from 'yup';

const REQUIRED = 'Ce champ est obligatoire';

const validationSchema = () => {
  return Yup.object().shape({
    type: Yup.string().required(REQUIRED).nullable(),
    filename: Yup.string().required(REQUIRED),
  });
};

/* eslint-disable import/prefer-default-export */
export default validationSchema;
