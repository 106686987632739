import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Grid, IconButton } from '@material-ui/core';
import { AppTextContent, AppTextTitle } from 'components/AppText';
import DeleteIcon from '@material-ui/icons/Delete';
import { Link } from 'react-router-dom';
import { DispatchContext } from '../contexts/ownersList.context';
import { REMOVE_OWNER } from '../constants/actions';

const OwnersList = ({ owners = [] }) => {
  const dispatch = useContext(DispatchContext);

  return (
    <Grid
      container
      style={{ flexGrow: 1 }}
      direction="column"
      justify="center"
      alignItems="center"
    >
      {owners.map((owner) => {
        return (
          <Grid
            key={owner.id}
            container
            style={{
              margin: 5,
              padding: 10,
              borderStyle: 'solid',
              borderRadius: 15,
              borderWidth: 1,
              borderColor: 'lightGrey',
              position: 'relative',
            }}
          >
            <Grid
              style={{ marginBottom: 5 }}
              container
              direction="row"
              justify="space-between"
            >
              <AppTextTitle>{owner.name}</AppTextTitle>
              <AppTextContent>{`${owner.share}%`}</AppTextContent>
            </Grid>
            <Grid container direction="row" justify="space-between">
              <AppTextContent>
                {owner.company ? 'company' : 'individual'}
              </AppTextContent>
              {owner.company && (
                <Link
                  style={{ cursor: 'pointer' }}
                  to={{
                    pathname: '/addOwner',
                    state: { parentId: owner.id },
                  }}
                >
                  Add owner
                </Link>
              )}
            </Grid>
            {owner.company && owner.subOwners && owner.subOwners.length && (
              <Grid container style={{ marginLeft: 10 }}>
                <OwnersList owners={owner.subOwners} />
              </Grid>
            )}
            <div style={{ position: 'absolute', top: -20, right: -20 }}>
              <IconButton
                onClick={() => {
                  dispatch({ type: REMOVE_OWNER, payload: owner });
                }}
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          </Grid>
        );
      })}
    </Grid>
  );
};

OwnersList.propTypes = {
  owners: PropTypes.array.isRequired,
};

export default OwnersList;
