import React from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';

import VatTable from './VAT';
import PrepaymentsTable from './Prepayments';
import Total from './Total';

const useStyles = makeStyles(() => ({
  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
  marginMid: {
    marginTop: '30px',
  },
}));

const Amounts = ({ readOnly }) => {
  const classes = useStyles();

  return (
    <div className={classes.rowSpaceBetween}>
      <div>
        <VatTable />
        <PrepaymentsTable />
      </div>
      <Total readOnly={readOnly} />
    </div>
  );
};

Amounts.propTypes = {
  readOnly: PropTypes.bool,
};

export default Amounts;
