import React from 'react';
import PropTypes from 'prop-types';

import SimpleExpense from './SimpleExpense';
import AdvancedExpense from './AdvancedExpense';
import GroupedExpense from './GroupedExpense';

const AbstractExpense = ({ expense }) => {
  // console.log('expense', expense);
  if (!expense || !expense.__t || !expense.__t) return <></>;

  if (expense.__t === 'SimpleExpense') {
    return <SimpleExpense expense={expense} />;
  }
  if (expense.__t === 'AdvancedExpense') {
    return <AdvancedExpense advance={expense} />;
  }
  if (expense.__t === 'GroupedExpense') {
    return <GroupedExpense group={expense} />;
  }
  return <></>;
};
AbstractExpense.propTypes = {
  expense: PropTypes.object.isRequired,
};

export default AbstractExpense;
