/* eslint-disable no-unused-vars */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import CreateProviderButton from 'containers/purchase/PurchaseForm/Sidebar/CreateProviderButton';
import AppButton from '../../AppButton';

const OtherActions = ({
  t,
  showClientFormDialog,
  showProjectFormDialog,
  readOnly,
  newProvider,
  toggleNewProviderDialog,
}) => {
  return (
    <Fragment>
      {!readOnly ? (
        <>
          <div>
            <CreateProviderButton
              newProvider={newProvider}
              toggleNewProviderDialog={toggleNewProviderDialog}
            />
            <AppButton
              onClick={showClientFormDialog}
              type="button"
              text={t('new_client')}
              color="secondaryLight"
              style={{ marginLeft: 16 }}
            />
          </div>

          <AppButton
            onClick={showProjectFormDialog}
            type="button"
            text={t('new_project')}
            color="secondaryLight"
          />
        </>
      ) : null}
    </Fragment>
  );
};

OtherActions.propTypes = {
  t: PropTypes.func,
  showClientFormDialog: PropTypes.func,
  showProjectFormDialog: PropTypes.func,
  readOnly: PropTypes.bool,
  toggleNewProviderDialog: PropTypes.func,
  newProvider: PropTypes.object,
};

export default OtherActions;
