import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import MTable from 'material-table';

const MaterialTable = ({ exportName, ...rest }) => {
  const { t } = useTranslation();

  return (
    <MTable
      {...rest}
      localization={{
        body: {
          emptyDataSourceMessage: t(
            'material_table.body.empty_data_source_message'
          ),
          addTooltip: t('material_table.body.add_tooltip'),
          deleteTooltip: t('material_table.body.delete_tooltip'),
          editTooltip: t('material_table.body.edit_tooltip'),
          filterRow: {
            filterTooltip: t('material_table.body.filter_row.filter_tooltip'),
          },
          editRow: {
            deleteText: t('material_table.body.edit_row.delete_text'),
            cancelTooltip: t('material_table.body.edit_row.cancel_tooltip'),
            saveTooltip: t('material_table.body.edit_row.save_tooltip'),
          },
        },
        grouping: {
          placeholder: t('material_table.grouping.placeholder'),
          groupedBy: t('material_table.grouping.grouped_by'),
        },
        header: {
          actions: t('material_table.header.actions'),
        },
        pagination: {
          labelDisplayedRows: t(
            'material_table.pagination.label_displayed_rows'
          ),
          labelRowsSelect: t('material_table.pagination.label_rows_select'),
          labelRowsPerPage: t('material_table.pagination.label_rows_per_page'),
          firstAriaLabel: t('material_table.pagination.first_aria_label'),
          firstTooltip: t('material_table.pagination.first_tooltip'),
          previousAriaLabel: t('material_table.pagination.previous_aria_label'),
          previousTooltip: t('material_table.pagination.previous_tooltip'),
          nextAriaLabel: t('material_table.pagination.next_aria_label'),
          nextTooltip: t('material_table.pagination.next_tooltip'),
          lastAriaLabel: t('material_table.pagination.last_aria_label'),
          lastTooltip: t('material_table.pagination.last_tooltip'),
        },
        toolbar: {
          addRemoveColumns: t('material_table.toolbar.add_remove_columns'),
          nRowsSelected: t('material_table.toolbar.n_rows_selected'),
          showColumnsTitle: t('material_table.toolbar.show_columns_title'),
          showColumnsAriaLabel: t(
            'material_table.toolbar.show_columns_aria_label'
          ),
          exportTitle: t('material_table.toolbar.export_title'),
          exportAriaLabel: t('material_table.toolbar.export_aria_label'),
          exportName: t('material_table.toolbar.export_name'),
          searchTooltip: t('material_table.toolbar.search_tooltip'),
          searchPlaceholder: t('material_table.toolbar.search_placeholder'),
          exportCSVName: exportName || t('material_table.toolbar.export_name'),
        },
      }}
    />
  );
};

MaterialTable.propTypes = {
  exportName: PropTypes.string,
};

export default MaterialTable;
