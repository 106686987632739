import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';

import { userCanReadExpense } from 'selectors/rightsSelector/expenseRequests';

import ExpenseListSideBar from './ExpenseListSideBar';
import ExpenseListHeader from './ExpenseListHeader';
import ExpenseListBody from './ExpenseListBody';

const ExpenseList = () => {
  const canReadExpense = useSelector(userCanReadExpense);

  const { _id: companyId } = useSelector(
    (state) => state.loggedUserCompany.company
  );

  const [refreshing, setRefreshing] = useState(false);

  function toggleRefreshing() {
    setRefreshing((prev) => !prev);
  }

  return (
    <Layout
      header={
        <ExpenseListHeader
          refreshing={refreshing}
          canReadExpense={canReadExpense}
          toggleRefresh={toggleRefreshing}
        />
      }
      sidebarLeft={true}
      sidebarRight={<ExpenseListSideBar />}
      body={
        <ExpenseListBody
          canReadExpense={canReadExpense}
          refreshing={refreshing}
          companyId={companyId}
        />
      }
      showUserCard={true}
    />
  );
};

ExpenseList.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ExpenseList;
