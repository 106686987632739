import greffeService from '../../services/GreffeService';

import { setGlobalError } from '../GlobalError';

/* eslint import/prefer-default-export: off */
export const fetchInfoGreffe = (name, cb) => async (dispatch) => {
  try {
    const result = await greffeService.infoGreffe(name, { timeout: 6000 });
    cb(null, result.data);
  } catch (error) {
    dispatch(setGlobalError(error));
    cb(error);
  }
};
