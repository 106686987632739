import {
  FETCH_LOGGED_USER,
  LOGIN_USER_SUCCESS,
  UPDATE_LOGGED_USER,
  LOGIN_USER_FAILED,
  LOGOUT_USER_SUCCESS,
  CHANGE_AVATAR,
  FETCH_USERS,
  CHANGE_PASSPORT,
  CHANGE_NEW_IDENTITY,
  FETCH_TREEZOR_USERS_SUCCESS,
} from '../../actions/Types';

const INITIAL_STATE = {
  user: null,
  isFetchingUser: false,
  errorFetchingUser: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_USERS:
      return {
        ...state,
        user: {
          ...state.user,
          function: action.payload.userFunc,
          userCompanyId: action.payload.userCompanyId, // TODO investigate if it is still used
          treezorUser: action.payload.treezorUser,
        },
      };
    case FETCH_TREEZOR_USERS_SUCCESS: {
      let treezorUser;
      const { treezorUsers } = action.payload;
      if (treezorUsers.length === 1) {
        // use the first element of the array
        [treezorUser] = treezorUsers;
      } else if (treezorUsers.length === 0) {
        treezorUser = null;
      } else {
        const userOId = state.user._id;
        for (let a = 0; a < treezorUsers.length && !treezorUser; a += 1) {
          const u = treezorUsers[a];
          if (u.userTag === userOId) {
            treezorUser = u;
          }
        }
      }
      return {
        ...state,
        user: {
          ...state.user,
          treezorUser,
        },
      };
    }
    case FETCH_LOGGED_USER:
      return {
        ...state,
        isFetchingUser: true,
        errorFetchingUser: null,
      };
    case LOGIN_USER_SUCCESS:
      return {
        ...state,
        user: action.payload,
        isFetchingUser: false,
        errorFetchingUser: null,
      };
    case UPDATE_LOGGED_USER:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    case LOGIN_USER_FAILED:
      return {
        ...state,
        user: null,
        isFetchingUser: false,
        errorFetchingUser: action.payload,
      };
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;
    case CHANGE_AVATAR:
      return {
        ...state,
        user: {
          ...state.user,
          avatar: action.payload.avatar,
          avatarBase64: action.payload.b64,
        },
      };
    case CHANGE_PASSPORT:
      return { ...state, user: { ...state.user, passport: action.payload } };
    case CHANGE_NEW_IDENTITY:
      return {
        ...state,
        user: { ...state.user, newIdentityCard: action.payload },
      };
    default:
      return state;
  }
};
