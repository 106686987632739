import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PENDING_IMAGE from 'assets/images/pending-document.svg';
import { useFormikContext } from 'formik';
import TreezorDropZone from '../NewCompagnyForm/TreezorDropZone';

const useStyles = makeStyles({
  pending: {
    width: '100px',
    height: 'auto',
  },
});

const TreezorDocsNeed = ({
  message,
  name,
  title,
  optionList,
  company,
  setFieldValue,
  resetUploadName,
  setDisableSendBtn,
  displayName,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const classes = useStyles();
  return (
    <Grid
      container
      direction="row"
      justify="center"
      spacing={2}
      style={{ marginTop: '4%' }}
    >
      <Grid item xs={12} md={12}>
        <Typography
          variant="button"
          style={{ fontSize: '20px', color: '#02cbcd' }}
        >
          Document complémentaire requis: {displayName}
        </Typography>
        <Typography
          variant="body1"
          style={{ fontSize: '20px', paddingTop: '2%' }}
        >
          Message de notre partenaire Treezor : <br />
          <br />
        </Typography>
        <Typography variant="body1" style={{ fontSize: '15px' }}>
          {message} <br />
          Merci de nous fournir ce document.
        </Typography>
      </Grid>
      <Grid item xs={12} md={3}>
        {values && values.filename !== null && values.type !== null ? (
          <img
            src={PENDING_IMAGE}
            alt="Document is pending"
            className={classes.pending}
          />
        ) : (
          <TreezorDropZone
            name={name}
            indentityFile="filename"
            title={t(title)}
            optionList={optionList}
            company={company}
            setFieldValue={setFieldValue}
            resetUploadName={resetUploadName}
            setDisableSendBtn={setDisableSendBtn}
          />
        )}
      </Grid>
    </Grid>
  );
};

TreezorDocsNeed.propTypes = {
  message: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  displayName: PropTypes.string,
  optionList: PropTypes.array,
  company: PropTypes.object,
  setFieldValue: PropTypes.func,
  setDisableSendBtn: PropTypes.func,
  resetUploadName: PropTypes.bool,
};

export default TreezorDocsNeed;
