import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { format, sub } from 'date-fns';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  Grid,
  IconButton,
  Dialog,
  DialogTitle,
  Divider,
  Box,
  Tooltip,
  Snackbar,
} from '@material-ui/core';
import SettingsIcon from '@material-ui/icons/Settings';
import CloseIcon from '@material-ui/icons/Close';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import DescriptionIcon from '@material-ui/icons/Description';

import AppError from 'components/AppError';
import { useRouter } from 'hooks/routerHooks';

import NumberFormat from 'helpers/NumberFormat';
import logger from 'helpers/logger';
import { getBankAccounts } from 'actions/BankAccountsActions';
import {
  fetchWallet,
  fetchWalletTransactionsForPDFExporting,
} from 'actions/WalletActions';
import Header from 'components/Header';
import Layout from 'components/Layout';
import AppButton from 'components/AppButton';
import CircularProgressCentered from 'components/CircularProgressCentered';
import BankCard from 'components/BankForm/BankCard';
import BankSync from 'components/BankSync';
import WalletTransactionTable from 'components/WalletTransactionTable';
import BankTransactionStatementPopup from 'components/BankTransactionStatementPopup/BankTransactionStatementPopup';
import BankinApiService from 'services/BankinApiService';
import { buildQueryString } from 'helpers/querystringHelper';
import useQueryParams from 'hooks/useQueryParams';

const BankAccounts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { history } = useRouter();
  const { type, documentId } = useQueryParams();

  const [showParamsAccounts, setShowParamsAccounts] = useState(false);
  // For RIB/IBAN dialog
  const [open, setOpen] = useState(false);
  // For Statement dialog
  const [isStatementDialogOpen, setStatementDialogOpen] = useState(false);
  // Generate statement: start date
  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState(
    sub(selectedEndDate, { months: 1 })
  );
  // Snackbar data
  const [snackbarMessage, setSnackbarMessage] = useState(null);
  const [isSnackbarOpen, setSnackbarOpen] = useState(false);

  const [isLoadingApi, setIsLoadingApi] = useState(false);

  const companyId = useSelector((state) => state.loggedUserCompany.company._id);
  const treezorWallet = useSelector((state) =>
    state.wallet && state.wallet.wallet ? state.wallet.wallet[0] : null
  );
  // const walletTransactions = useSelector((state) =>
  //   state.walletTransactions && state.walletTransactions.transactions
  //     ? state.walletTransactions.transactions
  //     : []
  // );
  const user = useSelector((state) => state.loggedUser.user);
  const bankConnector = useSelector((state) => state.bankConnector);
  const bankAccounts = useSelector((state) => state.bankAccounts);

  const banks = useSelector(
    (state) => state.loggedUserCompany.company.bank_details
  );

  const totalTransactions = useSelector(
    (state) =>
      (state.transactionPaginate && state.transactionPaginate.totalCount) || 0
  );

  const linkToBank = async () => {
    try {
      setIsLoadingApi(true);
      const {
        data: { data },
      } = await BankinApiService.createBankConnection(user._id);

      const redirectUrl = data.redirect_url;

      if (!redirectUrl) {
        throw new Error('No redirect url');
      }
      setIsLoadingApi(false);
      window.location.replace(redirectUrl);
    } catch (error) {
      logger.log(error);
      setIsLoadingApi(false);

      setSnackbarMessage(t('error'));
    }
  };

  console.log({ bankAccounts });

  useEffect(() => {
    dispatch(getBankAccounts(companyId));
  }, [companyId]);

  useEffect(() => {
    if (!treezorWallet) {
      dispatch(fetchWallet());
    }
  }, [treezorWallet]);

  function renderParamsDialog() {
    if (bankAccounts.loading || bankConnector.loading || isLoadingApi) {
      return <CircularProgressCentered />;
    }
    return (
      <Grid container>
        <Grid container>
          <Grid item xs={11}>
            <Grid container justify="center">
              <AppButton
                text={t('add_bank')}
                noBorder
                type="button"
                onClick={linkToBank}
              />
            </Grid>
          </Grid>

          <Grid item xs={1}>
            <IconButton
              aria-label="close-params"
              color="secondary"
              onClick={() => {
                dispatch(getBankAccounts(companyId));
                setShowParamsAccounts(false);
              }}
            >
              <CloseIcon fontSize="large"></CloseIcon>
            </IconButton>
          </Grid>
        </Grid>

        <Grid item xs={11}>
          Vous pouvez sélectionner ici les comptes que vous voulez utiliser pour
          votre comptabilité
        </Grid>
        <List
          style={{
            width: '100%',
          }}
        >
          {bankAccounts.data.data.map((item) => (
            <div key={item.bank?.id} style={{ width: '100%' }}>
              <Typography
                variant="h4"
                gutterBottom
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <AccountBalanceIcon
                  color="primary"
                  style={{ fontSize: '2rem', marginRight: '20px' }}
                />
                {item?.bank?.name}
              </Typography>
              <BankSync accounts={item.accounts} />
            </div>
          ))}
        </List>
      </Grid>
    );
  }

  const renderBankList = () => {
    const _banks = banks.filter((bank) => !(bank.bankinId || bank.walletId));

    if (!_banks.length) return null;

    return (
      <Grid container spacing={2}>
        {_banks.map((bank, index) => {
          if (bank.bankinId || bank.walletId) return null;
          return (
            <Grid key={index} item>
              <BankCard
                required
                bank={bank}
                disabled={true}
                synchronizeButton={() => (
                  <AppButton
                    text={t('synchronize')}
                    noBorder
                    type="button"
                    onClick={linkToBank}
                    fullWidth
                  />
                )}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const renderTreezorWalletTransactions = () => {
    return (
      <div style={{ marginTop: 2, marginBottom: 25 }}>
        <WalletTransactionTable treezorWallet={treezorWallet} />
      </div>
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderRIB = () => {
    let fullname = `${treezorWallet.userFirstname} ${treezorWallet.userLastname}`;
    fullname = fullname && 'N/A';

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="simple-dialog-title"
        open={open}
      >
        <DialogTitle id="simple-dialog-title">
          <Typography
            color="primary"
            variant="h5"
            gutterBottom
            style={{ fontWeight: 'bold', textAlign: 'center' }}
          >
            RIB / IBAN
          </Typography>
        </DialogTitle>
        <Divider variant="middle" style={{ border: '1px solid #3d319d' }} />
        <Box m={2}>
          <div>Titulaire du compte</div>
          <div style={{ fontWeight: 'bold', fontSize: 15 }}>{fullname}</div>
          <br />
          {banks.map((bank) => {
            return (
              <div key={bank._id}>
                <div>Banque</div>
                <div style={{ fontWeight: 'bold', fontSize: 15 }}>
                  {bank.bank_name}
                </div>
                <br />
                <Box
                  p={2}
                  style={{
                    backgroundColor: 'lightgrey',
                    width: 400,
                    color: 'black',
                    borderRadius: 5,
                  }}
                >
                  <div>IBAN</div>
                  <div style={{ fontWeight: 'bold', fontSize: 15 }}>
                    {bank.iban || 'N/A'}
                  </div>
                  <br />
                  <div>BIC/SWIFT</div>
                  <div style={{ fontWeight: 'bold', fontSize: 15 }}>
                    {bank.bic || 'N/A'}
                  </div>
                </Box>
                <br />
              </div>
            );
          })}

          {/* <br />
            
          <Button
            variant="contained"
            href="#contained-buttons"
            style={{ backgroundColor: '#3d319d', color: '#fff', width: '100%' }}
          >
            TÉLÉCHARGER
          </Button> */}
        </Box>
      </Dialog>
    );
  };

  const exportTransactionsToPDF = () => {
    const { walletId } = treezorWallet;

    return fetchWalletTransactionsForPDFExporting(
      walletId,
      format(selectedStartDate, 'yyyy-MM-dd'),
      format(selectedEndDate, 'yyyy-MM-dd')
    )
      .then((res) => {
        const { pdfFileContentBase64, pdfFileName, message } = res;

        if ((pdfFileContentBase64, pdfFileName)) {
          const linkSource = `data:application/pdf;base64,${pdfFileContentBase64}`;
          const downloadLink = document.createElement('a');
          downloadLink.href = linkSource;
          downloadLink.download = pdfFileName;
          downloadLink.click();
        }

        if (message) {
          setSnackbarMessage(message);
          setSnackbarOpen(true);
        }
      })
      .catch((err) => {
        const { message } = err;
        if (message) {
          setSnackbarMessage(message);
          setSnackbarOpen(true);
        }
      })
      .finally(() => {
        setStatementDialogOpen(false);
      });
  };

  const renderTreezorWalletSolde = () => {
    return (
      <>
        <div style={{ marginTop: 2, marginBottom: 25 }}>
          <Typography
            variant="h4"
            gutterBottom
            style={{ display: 'flex', alignItems: 'center' }}
          >
            <AccountBalanceIcon
              color="primary"
              style={{ fontSize: '2rem', marginRight: '20px' }}
            />
            {t('treezorAccount')}
          </Typography>
        </div>

        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1 }}>
            <Typography
              variant="h5"
              gutterBottom
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <AccountBalanceWalletIcon
                color="primary"
                style={{ fontSize: '1.5rem', marginRight: '20px' }}
              />
              {t('Solde')}:{' '}
              {treezorWallet && treezorWallet.solde ? treezorWallet.solde : 0} €
            </Typography>
          </div>
          <div>
            <Tooltip title={t('RIB/IBAN')} placement="top">
              <DescriptionIcon
                color="primary"
                style={{
                  fontSize: '2rem',
                  marginLeft: '40px',
                  cursor: 'pointer',
                }}
                onClick={handleClickOpen}
              />
            </Tooltip>
            <Tooltip title={t('Relevé de compte')} placement="top">
              <DescriptionIcon
                color="primary"
                style={{
                  fontSize: '2rem',
                  marginLeft: '40px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  setStatementDialogOpen(true);
                }}
              />
            </Tooltip>
          </div>
        </div>
      </>
    );
  };

  const renderBody = () => {
    if (bankAccounts.loading || bankConnector.loading || isLoadingApi) {
      return <CircularProgressCentered />;
    }
    if (bankAccounts.data && bankAccounts.data.data.length) {
      return (
        <div>
          {treezorWallet && renderTreezorWalletSolde()}
          {treezorWallet && renderTreezorWalletTransactions()}
          {banks && banks.length && renderBankList()}
          {treezorWallet && banks && renderRIB()}
          {treezorWallet && totalTransactions > 0 && (
            <BankTransactionStatementPopup
              selectedStartDate={selectedStartDate}
              setSelectedStartDate={setSelectedStartDate}
              selectedEndDate={selectedEndDate}
              setSelectedEndDate={setSelectedEndDate}
              exportTransactionsToPDF={exportTransactionsToPDF}
              setStatementDialogOpen={setStatementDialogOpen}
              isStatementDialogOpen={isStatementDialogOpen}
            />
          )}
          <Grid container justify="flex-end">
            <Grid item xs={11}>
              <Grid container justify="center" alignItems="center">
                <AppButton
                  text={t('add_bank')}
                  noBorder
                  type="button"
                  onClick={linkToBank}
                />
              </Grid>
            </Grid>
            <Grid item xs={1}>
              <IconButton
                aria-label="params"
                color="secondary"
                onClick={() => {
                  setShowParamsAccounts(true);
                }}
              >
                <SettingsIcon fontSize="large"></SettingsIcon>
              </IconButton>
            </Grid>
          </Grid>
          {bankAccounts.data.data.map((item) => (
            <div key={item.id}>
              <Typography
                variant="h4"
                gutterBottom
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <AccountBalanceIcon
                  color="primary"
                  style={{ fontSize: '2rem', marginRight: '20px' }}
                />
                {item?.name}
              </Typography>
              {/* {item.status !== 0 && (
                <div style={{ display: 'flex' }}>
                  <div
                    style={{ fontSize: 15, paddingTop: 12, marginRight: 10 }}
                  >
                    <ErrorMessage
                      error={t(`bridgeapi.itemstatus.${item.status}`)}
                      showError
                    />
                  </div>
                  {[402, 1010, 1100].includes(item.status) && (
                    <AppButton
                      text={t('bridgeapi.reconnect')}
                      onClick={() => {
                        editItem(item);
                      }}
                    />
                  )}
                </div>
              )} */}
              <List>
                {item.accounts.map((account) => {
                  return (
                    <ListItem
                      key={account?.id}
                      button
                      onClick={() => {
                        return history.push({
                          pathname: buildQueryString(
                            `/bank/account/${account?.id}`,
                            { type, documentId }
                          ),
                          state: {
                            accountName: account?.name,
                            bankName: item?.name,
                          },
                        });
                      }}
                    >
                      <ListItemIcon>
                        <AccountBalanceWalletIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText
                        primary={account?.name}
                        secondary={`${NumberFormat.n(account?.balance || 0)}€`}
                      />
                    </ListItem>
                  );
                })}
              </List>
            </div>
          ))}
        </div>
      );
    }
    if (bankAccounts.error || bankConnector.error) {
      if (bankConnector.error && bankConnector.error.code === 404) {
        return (
          <>
            {treezorWallet && renderTreezorWalletSolde()}
            {treezorWallet && renderTreezorWalletTransactions()}
            {renderBankList()}
            {treezorWallet && banks && renderRIB()}
            {treezorWallet && totalTransactions > 0 && (
              <BankTransactionStatementPopup
                selectedStartDate={selectedStartDate}
                setSelectedStartDate={setSelectedStartDate}
                selectedEndDate={selectedEndDate}
                setSelectedEndDate={setSelectedEndDate}
                exportTransactionsToPDF={exportTransactionsToPDF}
                setStatementDialogOpen={setStatementDialogOpen}
                isStatementDialogOpen={isStatementDialogOpen}
              />
            )}
            <Grid container justify="center">
              <AppButton
                text={t('add_bank')}
                noBorder
                type="button"
                onClick={linkToBank}
              />
            </Grid>
          </>
        );
      }
      return (
        <>
          <AppError
            error={
              <>
                {bankAccounts.error
                  ? bankAccounts.error.message
                  : bankConnector.error.message}
              </>
            }
          />
          {treezorWallet && renderTreezorWalletSolde()}
          {treezorWallet && renderTreezorWalletTransactions()}
        </>
      );
    }
    return (
      <>
        {treezorWallet && renderTreezorWalletSolde()}
        {treezorWallet && renderTreezorWalletTransactions()}
        {renderBankList()}
        {treezorWallet && banks && renderRIB()}
        {treezorWallet && totalTransactions > 0 && (
          <BankTransactionStatementPopup
            selectedStartDate={selectedStartDate}
            setSelectedStartDate={setSelectedStartDate}
            selectedEndDate={selectedEndDate}
            setSelectedEndDate={setSelectedEndDate}
            exportTransactionsToPDF={exportTransactionsToPDF}
            setStatementDialogOpen={setStatementDialogOpen}
            isStatementDialogOpen={isStatementDialogOpen}
          />
        )}
        <Grid container justify="center">
          <AppButton
            text={t('add_bank')}
            noBorder
            type="button"
            onClick={linkToBank}
          />
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={isSnackbarOpen}
          autoHideDuration={6000}
          onClose={() => {
            setSnackbarOpen(false);
          }}
          message={snackbarMessage}
          action={
            <Fragment>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  setSnackbarOpen(false);
                }}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Fragment>
          }
        />
      </>
    );
  };

  return (
    <div style={{ boxSizing: 'border-box' }}>
      <Layout
        header={
          <Header
            name={t('Banque')}
            goBack={() => {
              history.goBack();
            }}
          />
        }
        sidebarLeft={true}
        sidebarRight={null}
        showUserCard={true}
        body={showParamsAccounts ? renderParamsDialog() : renderBody()}
      />
    </div>
  );
};

BankAccounts.propTypes = {
  dummy: PropTypes.bool,
};

export default BankAccounts;
