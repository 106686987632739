import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  boxMessage: {
    padding: '24px 0px',
    display: 'flex',
    width: '100%',
    gap: '16px',
    alignItems: 'flex-start',
  },
  contentRight: {
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },
  boxMessage__content: {
    maxWidth: '700px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  message__time: {
    color: theme.palette.neutral[500],
  },
  userMessage: {
    backgroundColor: theme.palette.primary[100],
    borderRadius: '8px',
    padding: '8px 16px',
  },
}));

const UserMessage = (props) => {
  const { time, content } = props;
  const classes = useStyles();
  return (
    <Box className={classNames(classes.boxMessage, classes.contentRight)}>
      <Box
        className={classNames(
          classes.boxMessage__content,
          classes.contentRight
        )}
      >
        <Typography variant="body1" className={classes.message__time}>
          {time}
        </Typography>
        <Box className={classes.userMessage}>
          <Typography variant="body1">{content}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

UserMessage.propTypes = {
  time: PropTypes.string,
  content: PropTypes.string,
};

export default UserMessage;
