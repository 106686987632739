import React from 'react';
import PropTypes from 'prop-types';
import { isArray } from 'util';

import { IconRefresh } from '../AppIcons';

import './index.css';

const Refresh = ({ loadData, callback, preFn, loading, disabled, errorFn }) => {
  function _onFinished(data) {
    if (callback && typeof callback === 'function') {
      callback(data);
    }
  }

  function _onError(err) {
    if (errorFn && typeof errorFn === 'function') {
      errorFn(err);
    }
  }

  function _preLoad() {
    if (preFn && typeof preFn === 'function') {
      preFn();
    }
  }

  function _inSequence(tasks) {
    return tasks.reduce((p, task) => p.then(task), Promise.resolve());
  }

  function _onClick() {
    _preLoad();
    if (isArray(loadData)) {
      _inSequence(loadData).then(_onFinished).catch(_onError);
    } else {
      loadData().then(_onFinished).catch(_onError);
    }
  }

  return (
    <IconRefresh disabled={disabled} onClick={_onClick} refreshing={loading} />
  );
};

Refresh.propTypes = {
  loadData: PropTypes.any,
  callback: PropTypes.func,
  preFn: PropTypes.func,
  errorFn: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default Refresh;
