import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography } from '@material-ui/core';
import classNames from 'classnames';
import AssistantIcon from 'assets/images/assistant.png';

const useStyles = makeStyles((theme) => ({
  boxMessage: {
    padding: '24px 0px',
    display: 'flex',
    width: '100%',
    gap: '16px',
    alignItems: 'flex-start',
  },
  contentLeft: {
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  boxMessage__content: {
    maxWidth: '700px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  message__time: {
    color: theme.palette.neutral[500],
  },
}));

const AssistantMessage = (props) => {
  const { time, content } = props;
  const classes = useStyles();

  return (
    <Box className={classNames(classes.boxMessage, classes.contentLeft)}>
      <Box>
        <img src={AssistantIcon} alt="Assistant Icon" />
      </Box>
      <Box
        className={classNames(classes.boxMessage__content, classes.contentLeft)}
      >
        <Typography variant="body1" className={classes.message__time}>
          {time}
        </Typography>

        <Typography variant="body1">{content}</Typography>
      </Box>
    </Box>
  );
};

AssistantMessage.propTypes = {
  time: PropTypes.string,
  content: PropTypes.string,
};

export default AssistantMessage;
