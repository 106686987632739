import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { SUBSCRIPTION_NAME } from 'constants/subscriptions';
import { ROLE } from 'constants/roles';
import { Grid, FormLabel, FormHelperText } from '@material-ui/core';

import _ from 'lodash';
import LoadingIndicator from '../../../components/LoadingIndicatorDialog';
import AppCheckbox from '../../../components/AppCheckbox';
import AppButton from '../../../components/AppButton';
import SelectAutocomplete from '../../../components/SelectAutocomplete';

import TextField from '../../../components/FormElements/textField';

import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';

import state from './state';
import dispatch from './dispatch';

import styles from '../../../assets/jss/root';

import capitalizeForm from '../../../helpers/capitalizeForm';

class UserForm extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  _hasSubscriptionRight(roleName) {
    const {
      companySubscription: { data },
    } = this.props;
    if (
      data?.name === SUBSCRIPTION_NAME.DISCOVERY_PACKAGE &&
      roleName !== ROLE.UTILISATEUR_FACTURATION
    ) {
      return false;
    }
    return true;
  }

  _renderRolesOrLanguage() {
    const {
      companyAccessRights: { roles },
      touched,
      errors,
      setFieldValue,
      values,
      isSubmitting,
      t,
    } = this.props;

    return (
      <>
        <FormLabel>{t('settings_section.users.table_role')}</FormLabel>
        <div style={{ display: 'flex', flexFlow: 'row wrap' }}>
          {roles.map((role, index) => (
            <div key={index} style={{ marginRight: '10px' }}>
              <AppCheckbox
                checked={
                  values.role && _.find(values.role, (r) => r === role._id)
                }
                disabled={
                  isSubmitting || !this._hasSubscriptionRight(role.role)
                }
                onClick={(e) => {
                  if (e.target.checked) {
                    setFieldValue('role', [...values.role, e.target.value]);
                  } else {
                    setFieldValue(
                      'role',
                      values.role.filter((r) => r !== e.target.value)
                    );
                  }
                }}
                labelText={role.role}
                value={role._id}
                key={index}
                id={index}
              />
            </div>
          ))}
        </div>

        {touched.role && errors.role && (
          <FormHelperText error>{t('forms.mandatory_field')}</FormHelperText>
        )}
      </>
    );
  }

  onSubmit(e) {
    const { handleSubmit: formikSubmit } = this.props;
    formikSubmit(e);
  }

  renderLoading(isSubmitting) {
    const { t } = this.props;
    if (isSubmitting) {
      return <LoadingIndicator title={t('loading_single')} open={true} />;
    }
    return null;
  }

  render() {
    const {
      t,
      // errors,
      values,
      classes,
      setFieldValue,
      closeDialog,
      isSubmitting,
      companySubscription: {
        data: { userLimited },
      },
    } = this.props;
    const { remaining: remainingUserInvitations } = userLimited;

    const chooseTitles = [
      { label: 'M.', value: 'M.' },
      { label: 'Mme', value: 'Mme' },
    ];

    return (
      <>
        <div className="section p-10">
          <Form>
            {this.renderLoading(isSubmitting)}
            {/* {Object.keys(errors).length > 0 && (
            <div className="row">
              <div className="input-field col s12">
                <span className="helper-text" style={{ color: 'red' }}>
                  {errors.internalError}
                </span>
              </div>
            </div>
          )} */}

            <div style={{ marginLeft: 'auto', width: 'fit-content' }}>
              {t('settings_section.users.form_remaining_user')}:{' '}
              {remainingUserInvitations}
            </div>
            <Grid container spacing={2}>
              <Grid item xs={3} sm={3}>
                <SelectAutocomplete
                  name="title"
                  label={t('client.title')}
                  onChange={(title) => {
                    if (title) {
                      this.props.setFieldValue('title', title.value);
                    } else {
                      // if the user clears the field we reset the value
                      this.props.setFieldValue('title', '');
                    }
                  }}
                  getOptionLabel={(option) => option.label}
                  values={chooseTitles}
                  valueSelected={_.find(
                    chooseTitles,
                    (elem) => elem.value === values.title
                  )}
                />
              </Grid>
              <Grid item xs={5} sm={5}>
                <TextField
                  type="text"
                  required
                  name="name"
                  label={t('settings_section.users.form_label_name')}
                  placeholder={t('settings_section.users.form_helper_name')}
                  className={classes.textFieldWide}
                  onChange={(e) => {
                    capitalizeForm(e, setFieldValue);
                  }}
                />
              </Grid>
              <Grid item xs={4} sm={4}>
                <TextField
                  type="text"
                  required
                  name="firstname"
                  label={t('settings_section.users.form_label_firstname')}
                  placeholder={t(
                    'settings_section.users.form_helper_firstname'
                  )}
                  className={classes.textFieldWide}
                  onChange={(e) => {
                    capitalizeForm(e, setFieldValue);
                  }}
                />
              </Grid>
              <Grid item xs={6} style={{ marginBottom: '4px' }}>
                <TextField
                  type="text"
                  required
                  disabled={!!values._id}
                  name="email"
                  label={t('settings_section.users.form_label_email')}
                  placeholder={t('settings_section.users.form_label_email')}
                  className={classes.textFieldWide}
                  style={{ marginBottom: '9px' }}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  type="text"
                  required
                  name="phonenumber"
                  label={t('signup.company.mobilePhone')}
                  placeholder={t('signup.company.mobilePhone')}
                  className={classes.textFieldWide}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="description"
                  label={t('settings_section.users.form_label_description')}
                  placeholder={t(
                    'settings_section.users.form_label_description'
                  )}
                  className={classes.textFieldWide}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  type="text"
                  name="function"
                  label={t('settings_section.users.form_label_function')}
                  placeholder={t('settings_section.users.form_label_function')}
                  className={classes.textFieldWide}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  type="text"
                  name="iban"
                  label={t('invoices.IBAN')}
                  placeholder={t('invoices.IBAN')}
                  className={classes.textFieldWide}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  type="text"
                  name="bic"
                  label={t('invoices.BIC')}
                  placeholder={t('invoices.BIC')}
                  className={classes.textFieldWide}
                />
              </Grid>
              <Grid item xs={12}>
                {this._renderRolesOrLanguage()}
                <div className={classes.alignRight}>
                  {closeDialog && (
                    <AppButton
                      color="transparentGrey"
                      text={t('cancel')}
                      onClick={closeDialog}
                    />
                  )}

                  <AppButton
                    onClick={this.onSubmit}
                    color="secondaryLight"
                    text={
                      this.props.id
                        ? t('draft')
                        : t('settings_section.users.invite')
                    }
                    noBorder={true}
                    type="button"
                  />
                </div>
              </Grid>
            </Grid>
          </Form>
        </div>
      </>
    );
  }
}

UserForm.propTypes = {
  classes: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  closeDialog: PropTypes.func,
  status: PropTypes.object,
  values: PropTypes.object.isRequired,
  t: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  users: PropTypes.object,
  companyAccessRights: PropTypes.object,
  loggedUser: PropTypes.object,
  companySubscription: PropTypes.object,
  id: PropTypes.string,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  setShowAddUserFormDialog: PropTypes.func,
};

const UserFormWithFormik = withFormik({
  displayName: 'UserForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(UserForm);

const UserFormWithStyles = withStyles(styles)(UserFormWithFormik);

const TranslatedUserFormWithStyles = withTranslation()(UserFormWithStyles);

export default connect(state, dispatch)(TranslatedUserFormWithStyles);
