import { userCanUpdateUserManagement } from '../../selectors/rightsSelector/userManagementRequests';
import { userCanUpdateCompanySettings } from '../../selectors/rightsSelector/companySettingsRequests';
import { userHasTemplateAccessFeature } from '../../selectors/rightsSelector/templateRequests';
import { userHasUserRoleManagementAccessFeature } from '../../selectors/rightsSelector/userRoleManagementRequests';
import { userHasItemAccessFeature } from '../../selectors/rightsSelector/itemRequests';
import { userHasClientAccessFeature } from '../../selectors/rightsSelector/clientRequests';
import { userCanUpdateEmailTemplate } from '../../selectors/rightsSelector/emailTemplateRequests';

export default (state) => ({
  userCanUpdateUsers: userCanUpdateUserManagement(state),
  userCanUpdateCompany: userCanUpdateCompanySettings(state),
  userHasTemplateAccess: userHasTemplateAccessFeature(state),
  userHasUserRoleManagementAccess:
    userHasUserRoleManagementAccessFeature(state),
  userHasItemAccessFeature: userHasItemAccessFeature(state),
  userHasClientAccess: userHasClientAccessFeature(state),
  userCanUpdateEmailTemplate: userCanUpdateEmailTemplate(state),
});
