import React, { useCallback, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';
import { map } from 'lodash';

import spinnerService from 'services/SpinnerService';
import Layout from '../../../components/Layout';
import { useRouter } from '../../../hooks/routerHooks';

import { IconEdit, IconRefresh } from '../../../components/AppIcons';
import Header from '../../../components/Header';

import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';

import { useThunkDispatch } from '../../../hooks/generalHooks';

import { getEmailTemplates } from '../../../actions/EmailTemplateActions';

import { userCanUpdateEmailTemplate } from '../../../selectors/rightsSelector/emailTemplateRequests';

import EmailTemplateFormDialog from '../../../components/EmailTemplateDialog';

const TemplatesList = () => {
  const dispatch = useThunkDispatch();
  const { t } = useTranslation();
  const { history } = useRouter();

  const [showTemplatetDialog, setShowTemplateDialog] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);

  useEffect(() => {
    spinnerService.startSpinner();
    dispatch(getEmailTemplates()).finally(() => spinnerService.endSpinner());
  }, []);

  function openTemplateDialog(id = null) {
    setShowTemplateDialog(true);
    setSelectedTemplateId(id);
  }

  const templates = map(
    useSelector((state) => state.emailTemplate.emailTemplates),
    (template) => ({
      id: template._id,
      description: template.description,
    })
  );

  const canUpdateTemplate = useSelector(userCanUpdateEmailTemplate);

  const getTemplatesCb = useCallback(
    () => dispatch(getEmailTemplates()),
    [dispatch]
  );

  function renderList() {
    const columns = [
      {
        label: t('settings_section.clients.table_description'),
        name: 'description',
        options: {
          filter: false,
        },
      },
      {
        label: ' ',
        name: 'id',
        options: {
          viewColumns: false,
          filter: false,
          sort: false,
          search: false,
          // eslint-disable-next-line react/display-name
          customBodyRender: (value) => {
            if (!canUpdateTemplate) {
              return null;
            }

            return <IconEdit sm onClick={() => openTemplateDialog(value)} />;
          },
        },
      },
    ];

    return (
      <>
        <EmailTemplateFormDialog
          showDialog={showTemplatetDialog}
          closeTemplateFormDialog={() => {
            setShowTemplateDialog(false);
          }}
          selectedTemplateId={selectedTemplateId}
        ></EmailTemplateFormDialog>
        <MUIDataTable
          data={templates}
          columns={columns}
          options={{
            ...getMuiDatatableOptions(t, templates.length),
            filter: false,
          }}
        />
      </>
    );
  }

  return (
    <Layout
      header={
        <Header
          name={t('template.title')}
          spaceBetween
          goBack={() => {
            history.goBack();
          }}
        >
          <IconRefresh onClick={getTemplatesCb} />
        </Header>
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderList()}
    />
  );
};

export default TemplatesList;
