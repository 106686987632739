import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import UserFormContainer from 'containers/CardContainers/Treezor/userFormContainer';
import { TYPE_SOCIETE, TYPE_ENTREPRENEUR_INDIVIDUEL } from 'constants/company';
import {
  TreezorFormContext,
  DispatchContext,
} from 'contexts/TreezorContext/TreezorFormContext';
import { useSelector } from 'react-redux';
import FormLoading from 'components/CardComponents/Treezor/FormLoading';
import ListOfUser from 'components/CardComponents/Treezor/NewUserForm/listOfUser';
import SelectUser from 'components/CardComponents/Treezor/NewUserForm/selectUser';
import {
  SET_STEP,
  SET_ROLE,
  SET_SHOW_ADD_COLLABORATEUR,
  IS_PERSONNE_MORALE,
  IS_NEW_USER,
  CREATE_NEW_USER,
} from 'contexts/TreezorContext/TreezorType';
import { hasAtLeastOneUser } from 'helpers/TreezorHelper';
// import { initialValues } from './VIHAutoIndep/initialValues';
// import { prepareData } from './VIHAutoIndep/handleSubmit';
// import { validationSchema } from './VIHAutoIndep/validation';
// import { initialValues as initValComapny } from './VIHCompany/initialValues';
// import { prepareData as prepareDataCompany } from './VIHCompany/handleSubmit';
// import { validationSchema as validationCompany } from './VIHCompany/validation';

const PrepareFormContainer = ({
  company,
  countryList,
  personelTitle,
  identityList,
  identityList2,
  proofOfAddress,
  companyCredentials,
  newUser,
  onSubmit,
  initialValues,
  prepareData,
  validationSchema,
  goBackFuntion,
}) => {
  // const classes = useStyles();
  const { users, loggedUser } = useSelector((reduxState) => reduxState);
  const { loading, treezorUsers } = useSelector((state) => state.treezor);
  const dispatch = useContext(DispatchContext);
  const { companyType } = company?.settings?.tax_registration;
  const {
    user: contextUser,
    role,
    showAddCollaborateur,
    rattacheAPersonneMorale,
  } = useContext(TreezorFormContext);
  const [user, setUser] = useState(newUser || (contextUser && contextUser[0]));
  // const [showAddCollaborateur, setShowAddCollaborateur] = useState(false);

  const selectUser = (event) => {
    setUser(event.target.value);
  };

  const selectRole = (newRole) => {
    dispatch({ type: SET_ROLE, payload: newRole });
  };

  const setCreateUser = (createUserBoolean) => {
    dispatch({
      type: CREATE_NEW_USER,
      payload: createUserBoolean,
    });
  };

  useEffect(() => {
    if (companyType === TYPE_ENTREPRENEUR_INDIVIDUEL) {
      setUser(loggedUser.user);
      dispatch({ type: SET_SHOW_ADD_COLLABORATEUR, payload: false });
    }
  }, []);

  const setRattacheAPersonneMorale = (isPM) => {
    dispatch({ type: IS_PERSONNE_MORALE, payload: isPM });
  };

  const addUser = () => {
    setUser(null);
    dispatch({ type: SET_SHOW_ADD_COLLABORATEUR, payload: false });
    dispatch({ type: IS_NEW_USER, payload: false });
    dispatch({
      type: CREATE_NEW_USER,
      payload: false,
    });
    // setShowAddCollaborateur(false);
  };

  const closePanel = () => {
    if (hasAtLeastOneUser(treezorUsers)) {
      dispatch({
        type: SET_STEP,
        payload: 2,
      });
    }
  };

  const addNewUser = () => {
    dispatch({ type: IS_NEW_USER, payload: true });
    setUser({});
  };

  const goBack = () => {
    setUser(null);
    if (goBackFuntion) {
      goBackFuntion();
    } else {
      dispatch({ type: SET_ROLE, payload: 'undefined' });
      dispatch({ type: SET_SHOW_ADD_COLLABORATEUR, payload: true });
    }
  };

  return (
    <>
      <FormLoading loading={loading} />
      {companyType === TYPE_SOCIETE && showAddCollaborateur && (
        <ListOfUser
          addUser={addUser}
          closePanel={closePanel}
          selectRole={selectRole}
          setRattacheAPersonneMorale={setRattacheAPersonneMorale}
          treezorUsers={treezorUsers}
          companyName={company.name}
        />
      )}
      {!showAddCollaborateur && (role === 'undefined' || !user) && (
        <SelectUser
          selectUser={selectUser}
          addNewUser={addNewUser}
          users={users}
          treezorUsers={treezorUsers}
        />
      )}
      {user && role !== 'undefined' && !showAddCollaborateur && (
        <UserFormContainer
          countryList={countryList}
          personelTitle={personelTitle}
          identityList={identityList}
          identityList2={identityList2}
          proofOfAddress={proofOfAddress}
          companyCredentials={companyCredentials}
          company={company}
          initialValues={initialValues}
          prepareData={prepareData}
          validationSchema={validationSchema}
          // initialValues={
          //   companyType === TYPE_SOCIETE ? initValComapny : initialValues
          // }
          // prepareData={
          //   companyType === TYPE_SOCIETE ? prepareDataCompany : prepareData
          // }
          // validationSchema={
          //   companyType === TYPE_SOCIETE ? validationCompany : validationSchema
          // }
          setUser={setUser}
          user={user}
          // setShowAddCollaborateur={setShowAddCollaborateur}
          role={role}
          rattacheAPersonneMorale={rattacheAPersonneMorale}
          submit={onSubmit}
          goBack={goBack}
          selectRole={selectRole}
          setCreateUser={setCreateUser}
          treezorUsers={treezorUsers}
        />
      )}
    </>
  );
};

PrepareFormContainer.propTypes = {
  company: PropTypes.object,
  countryList: PropTypes.array,
  personelTitle: PropTypes.array,
  identityList: PropTypes.array,
  identityList2: PropTypes.array,
  proofOfAddress: PropTypes.array,
  companyCredentials: PropTypes.array,
  newUser: PropTypes.object,
  onSubmit: PropTypes.func,
  initialValues: PropTypes.func,
  prepareData: PropTypes.func,
  validationSchema: PropTypes.func,
  goBackFuntion: PropTypes.func,
};

export default PrepareFormContainer;
