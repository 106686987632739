import { Projects as Rights } from '../../config/Rights';
import { Projects as Feature } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreateProject = () => {
  return hasRequestedAccess(Rights.ProjectsCreate);
};

export const userCanUpdateProject = () => {
  return hasRequestedAccess(Rights.ProjectsUpdate);
};

export const userCanReadProject = () => {
  return hasRequestedAccess(Rights.ProjectsRead);
};

export const userCanDeleteProject = () => {
  return hasRequestedAccess(Rights.ProjectsDelete);
};

export const userHasProjectAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
