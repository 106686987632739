import React from 'react';
import PropTypes from 'prop-types';
import { TextField } from '@material-ui/core';
import ErrorMessage from 'components/ErrorMessage';
import { isEmpty } from 'lodash';

/**
 * Purepose: Custom reuired and validation for special field with out Formik validation
 */

const CustomTextField = ({
  style,
  type,
  name,
  label,
  placeholder,
  color,
  required = false,
  value,
  onChange,
  immediatelyEror,
  errorMessage,
}) => {
  const [touched, setTouhced] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleOnchange = (e) => {
    if (required && isEmpty(e.target.value)) {
      setError(true);
    } else {
      setError(false);
    }
    onChange(e);
  };
  const handleOnBlur = (e) => {
    if (!touched) {
      setTouhced(true);
    }
    if (required && isEmpty(e.target.value)) {
      setError(true);
    }
  };
  const checkError = () => {
    return (required && error && touched) || immediatelyEror;
  };

  return (
    <>
      <TextField
        fullWidth
        margin="dense"
        variant="outlined"
        style={style}
        type={type}
        name={name}
        label={label}
        placeholder={placeholder}
        color={color}
        error={checkError()}
        required={required}
        value={value}
        onChange={(e) => handleOnchange(e)}
        onBlur={(e) => handleOnBlur(e)}
      />
      <ErrorMessage showError={checkError()} error={errorMessage} />
    </>
  );
};

CustomTextField.propTypes = {
  style: PropTypes.object,
  type: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  color: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  errorMessage: PropTypes.string,
  immediatelyEror: PropTypes.bool,
};

export default CustomTextField;
