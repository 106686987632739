const data = [
  {
    country: 'FR',
    abbrv: '',
    display: 'SARL unipersonnelle',
    code: '5498',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société à responsabilité limitée',
    code: '5499',
  },
  {
    country: 'FR',
    abbrv: 'SAS',
    display: 'SAS, société par actions simplifiée',
    code: '5710',
  },
  {
    country: 'FR',
    abbrv: 'SASU',
    display:
      'Société par actions simplifiée à associé unique ou société par actions simplifiée unipersonnelle',
    code: '5720',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA à directoire',
    code: '5699',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA à conseil d'administration",
    code: '5599',
  },
  {
    country: 'FR',
    abbrv: 'EIRL',
    display: 'Auto entrepreneur/Micro entreprise',
    code: '1000',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Entrepreneur individuel',
    code: '1001',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Indivision entre personnes physiques',
    code: '2110',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Indivision avec personne morale',
    code: '2120',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société créée de fait entre personnes physiques',
    code: '2210',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société créée de fait avec personne morale',
    code: '2220',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société en participation entre personnes physiques',
    code: '2310',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société en participation avec personne morale',
    code: '2320',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société en participation de professions libérales',
    code: '2385',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Fiducie',
    code: '2400',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Paroisse hors zone concordataire',
    code: '2700',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Autre groupement de droit privé non doté de la personnalité morale',
    code: '2900',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Représentation ou agence commerciale d'état ou organisme public étranger immatriculé au RCS",
    code: '3110',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société commerciale étrangère immatriculée au RCS',
    code: '3120',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Organisation internationale',
    code: '3205',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'État, collectivité ou établissement public étranger',
    code: '3210',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société étrangère non immatriculée au RCS',
    code: '3220',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre personne morale de droit étranger',
    code: '3290',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Établissement public national à caractère industriel ou commercial doté d'un comptable public",
    code: '4110',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Établissement public national à caractère industriel ou commercial non doté d'un comptable public",
    code: '4120',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Exploitant public',
    code: '4130',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Établissement public local à caractère industriel ou commercial',
    code: '4140',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Régie d'une collectivité locale à caractère industriel ou commercial",
    code: '4150',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Institution Banque de France',
    code: '4160',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société de caution mutuelle',
    code: '5191',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société coopérative de banque populaire',
    code: '5192',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Caisse de crédit maritime mutuel',
    code: '5193',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Caisse fédérale de crédit mutuel',
    code: '5194',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Association coopérative inscrite - droit local Alsace Moselle',
    code: '5195',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Caisse d'épargne et de prévoyance à forme coopérative",
    code: '5196',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société en nom collectif',
    code: '5202',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société en nom collectif coopérative',
    code: '5203',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société en commandite simple',
    code: '5306',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société en commandite simple coopérative',
    code: '5307',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société en commandite par actions',
    code: '5308',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société en commandite par actions coopérative',
    code: '5309',
  },
  {
    country: 'FR',
    abbrv: 'SPFPL SCA',
    display:
      'Société de Participations Financières de Profession Libérale Société en commandite par actions - SPFPL SCA',
    code: '5370',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Société d'exercice libéral en commandite par actions",
    code: '5385',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SARL nationale',
    code: '5410',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SARL d'économie mixte",
    code: '5415',
  },
  {
    country: 'FR',
    abbrv: 'SICOMI',
    display: "SARL immobilière pour le commerce et l'industrie - SICOMI",
    code: '5422',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SARL immobilière de gestion',
    code: '5426',
  },
  {
    country: 'FR',
    abbrv: 'SAFER',
    display: "SARL d'aménagement foncier et d'équipement rural - SAFER",
    code: '5430',
  },
  {
    country: 'FR',
    abbrv: 'SMIA',
    display: "SARL mixte d'intérêt agricole - SMIA",
    code: '5431',
  },
  {
    country: 'FR',
    abbrv: 'SICA',
    display: "SARL d'intérêt collectif agricole - SICA",
    code: '5432',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SARL d'attribution",
    code: '5442',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SARL coopérative de construction',
    code: '5443',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SARL coopérative de consommation',
    code: '5451',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SARL coopérative artisanale',
    code: '5453',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SARL coopérative d'intérêt maritime",
    code: '5454',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SARL coopérative de transport',
    code: '5455',
  },
  {
    country: 'FR',
    abbrv: 'SCOP',
    display: 'SARL coopérative ouvrière de production - SCOP',
    code: '5458',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SARL union de sociétés coopératives',
    code: '5459',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre SARL coopérative',
    code: '5460',
  },
  {
    country: 'FR',
    abbrv: 'SPFPL SARL',
    display:
      'Société de Participations Financières de Profession Libérale Société à responsabilité limitée - SPFPL SARL',
    code: '5470',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Société d'exercice libéral à responsabilité limitée",
    code: '5485',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA à participation ouvrière à conseil d'administration",
    code: '5505',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA nationale à conseil d'administration",
    code: '5510',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA d'économie mixte à conseil d'administration",
    code: '5515',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Fonds à forme sociétale à conseil d'administration",
    code: '5520',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "SA immobilière pour le commerce et l'industrie - SICOMI - à conseil d'administration",
    code: '5522',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA immobilière d'investissement à conseil d'administration",
    code: '5525',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "SA d'aménagement foncier et d'équipement rural - SAFER - à conseil d'administration",
    code: '5530',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Société anonyme mixte d'intérêt agricole - SMIA - à conseil d'administration",
    code: '5531',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "SA d'intérêt collectif agricole - SICA - à conseil d'administration",
    code: '5532',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA d'attribution à conseil d'administration",
    code: '5542',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA coopérative de construction à conseil d'administration",
    code: '5543',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA de HLM à conseil d'administration",
    code: '5546',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA coopérative de production de HLM à conseil d'administration",
    code: '5547',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA de crédit immobilier à conseil d'administration",
    code: '5548',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA coopérative de consommation à conseil d'administration",
    code: '5551',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "SA coopérative de commerçants-détaillants à conseil d'administration",
    code: '5552',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA coopérative artisanale à conseil d'administration",
    code: '5553',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA coopérative (d'intérêt) maritime à conseil d'administration",
    code: '5554',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA coopérative de transport à conseil d'administration",
    code: '5555',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "SA coopérative ouvrière de production - SCOP - à conseil d'administration",
    code: '5558',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA union de sociétés coopératives à conseil d'administration",
    code: '5559',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Autre SA coopérative à conseil d'administration",
    code: '5560',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Société de Participations Financières de Profession Libérale Société anonyme à conseil d'administration - SPFPL SA à conseil d'administration",
    code: '5570',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Société d'exercice libéral à forme anonyme à conseil d'administration",
    code: '5585',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA à participation ouvrière à directoire',
    code: '5605',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA nationale à directoire',
    code: '5610',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA d'économie mixte à directoire",
    code: '5615',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Fonds à forme sociétale à directoire',
    code: '5620',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "SA immobilière pour le commerce et l'industrie - SICOMI - à directoire",
    code: '5622',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA immobilière d'investissement à directoire",
    code: '5625',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Safer anonyme à directoire',
    code: '5630',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA mixte d'intérêt agricole - SMIA",
    code: '5631',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA d'intérêt collectif agricole - SICA",
    code: '5632',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA d'attribution à directoire",
    code: '5642',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA coopérative de construction à directoire',
    code: '5643',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA de HLM à directoire',
    code: '5646',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société coopérative de production de HLM anonyme à directoire',
    code: '5647',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA de crédit immobilier à directoire',
    code: '5648',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA coopérative de consommation à directoire',
    code: '5651',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA coopérative de commerçants-détaillants à directoire',
    code: '5652',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA coopérative artisanale à directoire',
    code: '5653',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SA coopérative d'intérêt maritime à directoire",
    code: '5654',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA coopérative de transport à directoire',
    code: '5655',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA coopérative ouvrière de production - SCOP - à directoire',
    code: '5658',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SA union de sociétés coopératives à directoire',
    code: '5659',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre SA coopérative à directoire',
    code: '5660',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Société de Participations Financières de Profession Libérale Société anonyme à Directoire (SPFPL SA à directoire)',
    code: '5670',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Société d'exercice libéral à forme anonyme à directoire",
    code: '5685',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Société de Participations Financières de Profession Libérale Société par actions simplifiée - SPFPL SAS',
    code: '5770',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Société d'exercice libéral par action simplifiée",
    code: '5785',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société européenne',
    code: '5800',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Caisse d'Épargne et de Prévoyance",
    code: '6100',
  },
  {
    country: 'FR',
    abbrv: 'GEIE',
    display: "Groupement européen d'intérêt économique - GEIE",
    code: '6210',
  },
  {
    country: 'FR',
    abbrv: 'GIE',
    display: "Groupement d'intérêt économique - GIE",
    code: '6220',
  },
  {
    country: 'FR',
    abbrv: 'CUMA',
    display: "Coopérative d'utilisation de matériel agricole en commun - CUMA",
    code: '6316',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société coopérative agricole',
    code: '6317',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Union de sociétés coopératives agricoles',
    code: '6318',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Société d'assurance à forme mutuelle",
    code: '6411',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Sociétés Interprofessionnelles de Soins Ambulatoires',
    code: '6511',
  },
  {
    country: 'FR',
    abbrv: 'SCPI',
    display: 'Société civile de placement collectif immobilier - SCPI',
    code: '6521',
  },
  {
    country: 'FR',
    abbrv: 'SICA',
    display: "Société civile d'intérêt collectif agricole - SICA",
    code: '6532',
  },
  {
    country: 'FR',
    abbrv: 'GAEC',
    display: "Groupement agricole d'exploitation en commun - GAEC",
    code: '6533',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Groupement foncier agricole',
    code: '6534',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Groupement agricole foncier',
    code: '6535',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Groupement forestier',
    code: '6536',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Groupement pastoral',
    code: '6537',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Groupement foncier et rural',
    code: '6538',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société civile foncière',
    code: '6539',
  },
  {
    country: 'FR',
    abbrv: 'SCI',
    display: 'Société civile immobilière',
    code: '6540',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société civile immobilière de construction-vente',
    code: '6541',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Société civile d'attribution",
    code: '6542',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société civile coopérative de construction',
    code: '6543',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Société civile immobilière d' accession progressive à la propriété",
    code: '6544',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société civile coopérative de consommation',
    code: '6551',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Société civile coopérative d'intérêt maritime",
    code: '6554',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société civile coopérative entre médecins',
    code: '6558',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre société civile coopérative',
    code: '6560',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SCP d'avocats",
    code: '6561',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SCP d'avocats aux conseils",
    code: '6562',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SCP d'avoués d'appel",
    code: '6563',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SCP d'huissiers",
    code: '6564',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SCP de notaires',
    code: '6565',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SCP de commissaires-priseurs',
    code: '6566',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SCP de greffiers de tribunal de commerce',
    code: '6567',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SCP de conseils juridiques',
    code: '6568',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SCP de commissaires aux comptes',
    code: '6569',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SCP de médecins',
    code: '6571',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SCP de dentistes',
    code: '6572',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SCP d'infirmiers",
    code: '6573',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SCP de masseurs-kinésithérapeutes',
    code: '6574',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SCP de directeurs de laboratoire d'analyse médicale",
    code: '6575',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SCP de vétérinaires',
    code: '6576',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'SCP de géomètres experts',
    code: '6577',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "SCP d'architectes",
    code: '6578',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre société civile professionnelle',
    code: '6585',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société civile laitière',
    code: '6588',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Société civile de moyens',
    code: '6589',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Caisse locale de crédit mutuel',
    code: '6595',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Caisse de crédit agricole mutuel',
    code: '6596',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Société civile d'exploitation agricole",
    code: '6597',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Exploitation agricole à responsabilité limitée',
    code: '6598',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre société civile',
    code: '6599',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Autre personne de droit privé inscrite au registre du commerce et des sociétés',
    code: '6901',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autorité constitutionnelle',
    code: '7111',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autorité administrative ou publique indépendante',
    code: '7112',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Ministère',
    code: '7113',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Service central d'un ministère",
    code: '7120',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Service du ministère de la Défense',
    code: '7150',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Service déconcentré à compétence nationale d'un ministère (hors Défense)",
    code: '7160',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Service déconcentré de l'État à compétence - inter - régionale",
    code: '7171',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Service déconcentré de l'État à compétence - inter - départementale",
    code: '7172',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "(Autre) Service déconcentré de l'État à compétence territoriale",
    code: '7179',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Ecole nationale non dotée de la personnalité morale',
    code: '7190',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Commune et commune nouvelle',
    code: '7210',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Département',
    code: '7220',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Collectivité et territoire d'Outre Mer",
    code: '7225',
  },
  {
    country: 'FR',
    abbrv: '',
    display: '(Autre) Collectivité territoriale',
    code: '7229',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Région',
    code: '7230',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Commune associée et commune déléguée',
    code: '7312',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Section de commune',
    code: '7313',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Ensemble urbain',
    code: '7314',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Association syndicale autorisée',
    code: '7321',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Association foncière urbaine',
    code: '7322',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Association foncière de remembrement',
    code: '7323',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Établissement public local d'enseignement",
    code: '7331',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Pôle métropolitain',
    code: '7340',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Secteur de commune',
    code: '7341',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'District urbain',
    code: '7342',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Communauté urbaine',
    code: '7343',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Métropole',
    code: '7344',
  },
  {
    country: 'FR',
    abbrv: 'SIVOM',
    display: 'Syndicat intercommunal à vocation multiple - SIVOM',
    code: '7345',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Communauté de communes',
    code: '7346',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Communauté de villes',
    code: '7347',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Communauté d'agglomération",
    code: '7348',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Autre établissement public local de coopération non spécialisé ou entente',
    code: '7349',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Institution interdépartementale ou entente',
    code: '7351',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Institution interrégionale ou entente',
    code: '7352',
  },
  {
    country: 'FR',
    abbrv: 'SIVU',
    display: 'Syndicat intercommunal à vocation unique - SIVU',
    code: '7353',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Syndicat mixte fermé',
    code: '7354',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Syndicat mixte ouvert',
    code: '7355',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Commission syndicale pour la gestion des biens indivis des communes',
    code: '7356',
  },
  {
    country: 'FR',
    abbrv: 'PETR',
    display: "Pôle d'équilibre territorial et rural - PETR",
    code: '7357',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Centre communal d'action sociale",
    code: '7361',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Caisse des écoles',
    code: '7362',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Caisse de crédit municipal',
    code: '7363',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Établissement d'hospitalisation",
    code: '7364',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Syndicat inter hospitalier',
    code: '7365',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Établissement public local social et médico-social',
    code: '7366',
  },
  {
    country: 'FR',
    abbrv: 'CIAS',
    display: "Centre Intercommunal d'action sociale - CIAS",
    code: '7367',
  },
  {
    country: 'FR',
    abbrv: 'OPHLM',
    display: "Office public d'habitation à loyer modéré - OPHLM",
    code: '7371',
  },
  {
    country: 'FR',
    abbrv: 'SDIS',
    display: "Service départemental d'incendie et de secours - SDIS",
    code: '7372',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Établissement public local culturel',
    code: '7373',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Régie d'une collectivité locale à caractère administratif",
    code: '7378',
  },
  {
    country: 'FR',
    abbrv: '',
    display: '(Autre) Établissement public administratif local',
    code: '7379',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Organisme consulaire',
    code: '7381',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Établissement public national ayant fonction d'administration centrale",
    code: '7382',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Établissement public national à caractère scientifique culturel et professionnel',
    code: '7383',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Autre établissement public national d'enseignement",
    code: '7384',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Autre établissement public national administratif à compétence territoriale limitée',
    code: '7385',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Établissement public national à caractère administratif',
    code: '7389',
  },
  {
    country: 'FR',
    abbrv: 'GIP',
    display: "Groupement d'intérêt public - GIP",
    code: '7410',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Établissement public des cultes d'Alsace-Lorraine",
    code: '7430',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Etablissement public administratif, cercle et foyer dans les armées',
    code: '7450',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Groupement de coopération sanitaire à gestion publique',
    code: '7470',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre personne morale de droit administratif',
    code: '7490',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Régime général de la Sécurité Sociale',
    code: '8110',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Régime spécial de Sécurité Sociale',
    code: '8120',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Institution de retraite complémentaire',
    code: '8130',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Mutualité sociale agricole',
    code: '8140',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Régime maladie des non-salariés non agricoles',
    code: '8150',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Régime vieillesse ne dépendant pas du régime général de la Sécurité Sociale',
    code: '8160',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Régime d'assurance chômage",
    code: '8170',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre régime de prévoyance sociale',
    code: '8190',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Mutuelle',
    code: '8210',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Assurance mutuelle agricole',
    code: '8250',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre organisme mutualiste',
    code: '8290',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Comité central d'entreprise",
    code: '8310',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Comité d'établissement",
    code: '8311',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Syndicat de salariés',
    code: '8410',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Syndicat patronal',
    code: '8420',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Ordre professionnel ou assimilé',
    code: '8450',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      'Centre technique industriel ou comité professionnel du développement économique',
    code: '8470',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre organisme professionnel',
    code: '8490',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Institution de prévoyance',
    code: '8510',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Institution de retraite supplémentaire',
    code: '8520',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Syndicat de copropriété',
    code: '9110',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Association syndicale libre',
    code: '9150',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Association non déclarée',
    code: '9210',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Association déclarée',
    code: '9220',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Association déclarée d'insertion par l'économique",
    code: '9221',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Association intermédiaire',
    code: '9222',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Groupement d'employeurs",
    code: '9223',
  },
  {
    country: 'FR',
    abbrv: '',
    display:
      "Association d'avocats à responsabilité professionnelle individuelle",
    code: '9224',
  },
  {
    country: 'FR',
    abbrv: '',
    display: "Association déclarée, reconnue d'utilité publique",
    code: '9230',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Congrégation',
    code: '9240',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Association de droit local - Bas-Rhin, Haut-Rhin et Moselle',
    code: '9260',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Fondation',
    code: '9300',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Autre personne morale de droit privé',
    code: '9900',
  },
  {
    country: 'FR',
    abbrv: '',
    display: 'Groupement de coopération sanitaire à gestion privée',
    code: '9970',
  },
];

export default data;
