import React, { useState } from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import TopServiceProducts from './TopServiceProducts';
import TopClientInInvoice from '../InvoiceOverview/TopClientInInvoices';
import AppDashboardCard from './AppDashboardCard';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    width: '100%',
    height: '100%',
  },
  tabs: {
    borderRadius: '8px',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
    width: '100%',
    backgroundColor: theme.palette.background.default,
  },
  tab: {
    textTransform: 'none',
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.neutral[200]}`,
    },
    fontWeight: 500,
    fontSize: '1rem',
  },
  activeTab: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary[50],
  },
}));

const TopProductAndClient = (props) => {
  const classes = useStyles(props);
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();

  const tabList = [
    {
      title: t('dasboard.top_10_products_services'),
      component: <TopServiceProducts />,
    },
    { title: t('dasboard.top_customer'), component: <TopClientInInvoice /> },
  ];

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };
  return (
    <AppDashboardCard title="Top 10" titlePosition={'center'}>
      <Box className={classes.tabContainer}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          variant="fullWidth"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          className={classes.tabs}
        >
          {tabList.map((item, index) => {
            return (
              <Tab
                key={index}
                label={item.title}
                className={classNames(classes.tab, {
                  [classes.activeTab]: index === tabIndex,
                })}
              />
            );
          })}
        </Tabs>
        {tabList[tabIndex]?.component}
      </Box>
    </AppDashboardCard>
  );
};

TopProductAndClient.propTypes = {};
export default TopProductAndClient;
