// bad
/* eslint-disable react/prop-types */

// TODO : https://material-ui-pickers.dev/demo/datepicker

import React, { useState } from 'react';
// @material-ui/core
import { FormHelperText } from '@material-ui/core';
import { Warning } from '@material-ui/icons';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import enLocale from 'date-fns/locale/en-US';
import frLocale from 'date-fns/locale/fr';
import { withTranslation } from 'react-i18next';
import i18n from '../../i18n';

const localeMap = {
  en: enLocale,
  fr: frLocale,
};

const DatePicker2 = ({
  t,
  field,
  form,
  disabled = false,
  onChange,
  fullWidth,
  setDueDate,
  inputStyle,
  label,
  customStyle,
  prePaymentsList,
  prePaymentType,
  selectedIndex,
  customClass = '',
  duePayments,
  ...rest
}) => {
  const [isClearDate, setIsClearDate] = useState(false);
  const findLatestDate = () => {
    const latest = prePaymentsList.reduce(function (prev, current) {
      const currentDate = new Date(current.due_date);
      const prevDate = new Date(prev.due_date);
      return prevDate > currentDate ? prev : current;
    });
    return latest;
  };

  const findFirtsPayment = () => {
    if (!duePayments) return null;
    return duePayments.reduce(function (prev, current) {
      const currentDate = new Date(current.date);
      const prevDate = new Date(prev.date);
      return prevDate < currentDate ? prev : current;
    });
  };

  const { name } = field;
  const { errors, isSubmitting } =
    form; /* couldnt make touched work (maybe intended) */

  let fieldValue = field.value;

  if (setDueDate) {
    fieldValue = setDueDate.toString();
  } else if (prePaymentsList && prePaymentsList[selectedIndex]?.due_date) {
    fieldValue = prePaymentsList[selectedIndex]?.due_date;
  }

  return (
    <React.Fragment>
      <MuiPickersUtilsProvider
        utils={DateFnsUtils}
        locale={localeMap[i18n.language]}
        disableUnderline={true}
      >
        <KeyboardDatePicker
          InputProps={{
            margin: 'dense',
          }}
          inputVariant="outlined"
          fullWidth
          // style={rest.useStyle}
          className={customClass}
          style={{ marginTop: '8px', ...(customStyle || {}) }}
          name={name}
          value={isClearDate ? null : fieldValue}
          onChange={(value) => {
            setIsClearDate(!value);
            onChange(value);
          }}
          format={'dd/MM/yyyy'}
          // mask={[/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
          error={!!errors[name]}
          disabled={isSubmitting || disabled}
          cancelLabel={t('cancel')}
          autoComplete="off"
          label={label}
          id={rest.id}
          allowKeyboardControl={rest.keyboard}
          orientation="right"
          clearable
          required={rest.required}
          shouldDisableDate={(dateParam) => {
            if (prePaymentType === 'pre_payment') {
              const limitDate = prePaymentsList.at(selectedIndex - 1).due_date;
              const nextDate =
                selectedIndex === prePaymentsList.length - 1
                  ? false
                  : prePaymentsList.at(selectedIndex + 1).due_date;

              const firtsPayment = findFirtsPayment()?.date;
              if (selectedIndex === 0) {
                return (
                  dateParam < new Date(new Date()) ||
                  dateParam >
                    new Date(new Date(firtsPayment).setHours(0, 0, 0, 0))
                );
              }
              return (
                dateParam <=
                  new Date(new Date(limitDate).setHours(0, 0, 0, 0)) ||
                (nextDate &&
                  dateParam >=
                    new Date(new Date(nextDate).setHours(0, 0, 0, 0)))
              );
            }
            if (prePaymentType === 'maturity') {
              const latestDate = findLatestDate();
              const dueDate = new Date(latestDate.due_date);
              const limitDate = dueDate.setDate(dueDate.getDate() + 1);
              return dateParam < new Date(limitDate).setHours(0, 0, 0, 0);
            }
            return false;
          }}
        />
        {/* </div> */}
      </MuiPickersUtilsProvider>
      {errors[name] ? (
        <FormHelperText
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-start',
            alignItems: 'center',
            color: 'red',
          }}
        >
          <Warning style={{ marginRight: '10px' }} />
          {errors[name]}
        </FormHelperText>
      ) : null}
    </React.Fragment>
  );
};

DatePicker2.displayName = 'FormikMaterialUIDatePicker';

export default withTranslation()(DatePicker2);
