import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import TextField from 'components/FormElements/textField';
import SwitchField from 'components/FormElements/SwitchField';

import colors from 'constants/colors.json';

const useStyles = makeStyles(() => ({
  mGreyText: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '13px',
  },
}));

const ClientExtraAddress = ({ fieldNames, isInvoice = false }) => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const renderDeliveryAdressBlock = () => {
    return (
      <Grid item xs={12}>
        <TextField
          fullWidth
          rows="4"
          name={fieldNames.address}
          label={t('invoice.form.client.address')}
          placeholder={t('invoice.form.client.address')}
          onChange={(e) => {
            setFieldValue(fieldNames.address, e.target.value);
          }}
        />

        <TextField
          fullWidth
          name={fieldNames.zipcode}
          label={t('invoice.form.client.postalCode')}
          placeholder={t('invoice.form.client.postalCode')}
          onChange={(e) => {
            setFieldValue(fieldNames.zipcode, e.target.value);
          }}
        />

        <TextField
          fullWidth
          name={fieldNames.city}
          label={t('invoice.form.client.city')}
          placeholder={t('invoice.form.client.city')}
          onChange={(e) => {
            setFieldValue(fieldNames.city, e.target.value);
          }}
        />

        <TextField
          fullWidth
          name={fieldNames.country}
          label={t('invoice.form.client.country')}
          placeholder={t('invoice.form.client.country')}
          onChange={(e) => {
            setFieldValue(fieldNames.country, e.target.value);
          }}
        />
      </Grid>
    );
  };

  const onFieldChange = (value) => {
    if (value) {
      const address = isInvoice
        ? values?.client?.adresses?.[0] ??
          values?.client?.billingAddress ??
          null
        : values?.adresses?.[0] ?? null;
      setFieldValue('adresses', [
        {
          address: address?.address ?? '',
          city: address?.city ?? '',
          zipcode: address?.zipcode ?? '',
          country: address?.country ?? '',
        },
      ]);
      if (isInvoice)
        setFieldValue('client.billingAddress', {
          address: address?.address ?? '',
          city: address?.city ?? '',
          zipcode: address?.zipcode ?? '',
          country: address?.country ?? '',
        });
    } else if (!isInvoice) {
      setFieldValue('adresses', []);
    }
    setFieldValue('clientHasDifferentBillingAddress', value);
  };

  useEffect(() => {
    onFieldChange(
      isInvoice
        ? values?.client?.adresses?.length > 0 || values?.client?.billingAddress
        : values?.adresses?.length > 0
    );
  }, []);
  // eslint-disable-next-line no-debugger
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography className={classes.mGreyText}>
          {t('invoice.form.client.label')}
        </Typography>
        <SwitchField
          name={'clientHasDifferentBillingAddress'}
          onChange={() =>
            onFieldChange(!values.clientHasDifferentBillingAddress)
          }
          labelLeft={t('no')}
          labelRight={t('yes')}
        />
      </Grid>

      {values.clientHasDifferentBillingAddress && renderDeliveryAdressBlock()}
    </Grid>
  );
};

ClientExtraAddress.propTypes = {
  fieldNames: PropTypes.object,
  isInvoice: PropTypes.bool,
};

export default ClientExtraAddress;
