import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';

import Grid from '@material-ui/core/Grid';

import { Box, Divider, Tooltip } from '@material-ui/core';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import style from '../../../assets/jss/components/appDashboard';
// import { ExpensesVatList } from '../../Expenses';
import AppCard from '../../AppCard';
import NumberFormat from '../../../helpers/NumberFormat';
import ExpenseDougnut from './ExpenseDougnut';

const Overview = ({
  fetchExpenseOverview,
  companyId,
  hasExpenseValidateAccess,
  expenseOverview,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const fetchExpenseOverviewCallback = useCallback(fetchExpenseOverview);

  const navigatePage = (url, queryParams) => {
    history.push(
      queryString.stringifyUrl(
        {
          url,
          query: queryParams,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  useEffect(() => {
    if (hasExpenseValidateAccess) {
      fetchExpenseOverviewCallback();
    }
  }, [companyId, fetchExpenseOverviewCallback, hasExpenseValidateAccess]);
  // console.log('hasExpenseValidateAccess', hasExpenseValidateAccess);

  return (
    <Grid container justify="space-between">
      {/**
       * charge engagee vert
       * t('expense.overview.incurred')
       * expenseOverview.incurred || 0
       *
       * depenses a payer orange
       * t('expense.overview.validatedPurchases')
       * expenseOverview.validatedPurchases || 0
       *
       * notes de frais a rembourser jaune
       * t('expense.overview.validatedExpenses')
       * expenseOverview.validatedExpenses || 0
       *
       * t('expense.overview.validated')
       * expenseOverview.validatedPurchases + expenseOverview.validatedExpenses || 0
       *  */}
      <Grid item xs={12} style={{ marginBottom: '20px' }}>
        <ExpenseDougnut
          orange={expenseOverview.validatedPurchases || 0}
          yellow={expenseOverview.validatedExpenses || 0}
        />
      </Grid>

      {/* expense to pay */}
      <Grid item xs={3}>
        <Tooltip title={t('view.details')} arrow placement="top-start">
          <Box
            onClick={() => {
              const expenseFilter = { state: ['validated'] };
              navigatePage('/purchases/list', expenseFilter);
            }}
          >
            <AppCard
              text={t('expense.overview.validated_purchases')}
              data={`${NumberFormat.n(
                expenseOverview.validatedPurchases || 0
              )}€`}
            />
          </Box>
        </Tooltip>
      </Grid>
      <Divider orientation="vertical" flexItem variant="fullWidth" />
      <Grid item xs={3}>
        <Tooltip title={t('view.details')} arrow placement="top-start">
          <Box
            onClick={() => {
              const expenseFilter = { state: ['validated'] };
              navigatePage('/expenses/list', expenseFilter);
            }}
          >
            <AppCard
              text={t('expense.overview.validated_expenses')}
              data={`${NumberFormat.n(
                expenseOverview.validatedExpenses || 0
              )}€`}
            />
          </Box>
        </Tooltip>
      </Grid>
      <Divider orientation="vertical" flexItem variant="fullWidth" />
      <Grid item xs={3}>
        <Box
          onClick={() => {
            const expenseFilter = { state: ['validated'] };
            navigatePage('/purchases/list', expenseFilter);
          }}
        >
          <AppCard
            text={t('expense.overview.validated')}
            data={`${NumberFormat.n(
              expenseOverview.validatedPurchases +
                expenseOverview.validatedExpenses || 0
            )}€`}
          />
        </Box>
      </Grid>

      {/* <Grid item xs={3}>
            <AppCard
              text={t('expense.overview.advance_expense_total')}
              data={`${NumberFormat.n(
                expenseOverview.advanceExpenseTotal || 0
              )}€`}
            />
          </Grid>
          <Grid xs={3}>
            <AppCard
              text={t('expense.overview.cash_used')}
              data={`${NumberFormat.n(expenseOverview.usedCashFlow || 0)}€`}
            />
          </Grid>
          <Grid xs={3}>
            <AppCard
              text={t('expense.overview.cash_requirements')}
              data={`${NumberFormat.n(expenseOverview.neededCashFlow || 0)}€`}
            />
          </Grid>
          <Grid xs={3}>
            <AppCard
              text={t('expense.overview.vat_to_recover')}
              data={`${NumberFormat.n(expenseOverview.returnedVAT || 0)}€`}
            />
          </Grid>
          <Grid xs={3}>
            <AppCard
              text={t('expense.overview.vat_to_recoverable')}
              data={`${NumberFormat.n(
                expenseOverview.returnedReimbursedVAT || 0
              )}€`}
            />
          </Grid> */}
    </Grid>
  );
};

Overview.propTypes = {
  fetchExpenseOverview: PropTypes.func,
  companyId: PropTypes.string,
  hasExpenseValidateAccess: PropTypes.bool,
  expenseOverview: PropTypes.object,
};

export default withStyles(style)(Overview);
