import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import _ from 'lodash';
import classNames from 'classnames';
import styleasset from '../../assets/jss/components/appSelect';
import AppSelectButton from '../AppSelectButton';
import printErrors from '../../helpers/errorHelpers';

const AppSelect = ({
  onClick,
  name,
  children,
  valueSelected,
  values,
  label,
  classes,
  color,
  fullWidth,
  textOnSelectOpened,
  textAfterSelected,
  onButtonClose,
  noBorder,
  maxHeight,
  disabled,
  heigherIndex,
  showError,
  errorMessage,
  mandatory,
  noErrowBorder,
  inputDisplay,
  form,
  field,
  setWidthManualy,
  showLabel,
  style,
}) => {
  const [displayDropdown, toggleDropdown] = useState(false);

  const selectedText =
    _.find(values, (value) => value._id && value._id === valueSelected) ||
    _.find(values, (value) => value.type && value.type === valueSelected) ||
    _.find(
      values,
      (value) => value.contract && value.contract === valueSelected
    ) ||
    _.find(values, (value) => value.name && value.name === valueSelected) ||
    _.find(
      values,
      (value) => value && value.toString() === (valueSelected || '').toString()
    ) ||
    _.find(
      values,
      (value) => value.value != null && value.value === valueSelected
    ) ||
    valueSelected;

  function toggleandselect(e) {
    toggleDropdown(!displayDropdown);
    onClick(e);
  }

  function onAppButtonClick() {
    if (!!onButtonClose && displayDropdown === true) {
      /* eslint-disable-next-line */
      onButtonClose();
      toggleDropdown(!displayDropdown);
    }

    toggleDropdown(!displayDropdown);
  }

  function displayButtonText() {
    if (selectedText && selectedText.display && !textAfterSelected) {
      return selectedText.display;
    }
    if (selectedText && selectedText.name && !textAfterSelected) {
      return selectedText.name;
    }
    if (selectedText && !textAfterSelected) {
      return selectedText;
    }
    if (textOnSelectOpened && displayDropdown) {
      return textOnSelectOpened;
    }
    if (textAfterSelected && selectedText) {
      return textAfterSelected;
    }
    return label;
  }

  const childrenWithProps = React.Children.map(children, (child) => {
    if (child) {
      return React.cloneElement(child, {
        name,
        valueSelected,
        toggleandselect,
        toggleDropdown,
        color,
      });
    }
    return undefined;
  });

  const itemsContainer = classNames({
    [classes.itemsContainer]: true,
    [classes.open]: displayDropdown,
    [classes[color]]: color,
    [classes.fullWidth]: fullWidth,
    [classes.heigherIndex]: heigherIndex,
    [classes[maxHeight]]: maxHeight,
  });

  const setWidth = setWidthManualy ? { width: setWidthManualy } : null;

  const errorMessageStyle = classNames({
    [classes[`${color}ErrorMessage`]]: true,
  });

  return (
    <div className={classes.selectContainer} style={setWidth}>
      {!!label && showLabel && (
        <div
          className={classNames({
            [classes.label]: label,
            [classes.showLabel]:
              label &&
              valueSelected !== null &&
              valueSelected.toString().length > 0,
            [classes.labelFocused]: true,
          })}
        >
          {label} {mandatory && <span style={{ color: 'red' }}>*</span>}
        </div>
      )}
      <AppSelectButton
        valueSelected={valueSelected}
        toggleDropdown={onAppButtonClick}
        selectOpened={displayDropdown}
        text={displayButtonText()}
        color={color}
        noBorder={noBorder}
        disabled={disabled}
        showError={showError}
        errorMessage={errorMessage}
        noErrowBorder={noErrowBorder}
        mandatory={mandatory}
        inputDisplay={inputDisplay}
        style={style}
      />
      <div className={itemsContainer}>{childrenWithProps}</div>

      {form &&
        printErrors(
          field.name,
          mandatory,
          form.errors,
          form.touched,
          errorMessageStyle,
          {
            marginTop: '5px',
          }
        )}
    </div>
  );
};

AppSelect.propTypes = {
  style: PropTypes.object,
  form: PropTypes.object,
  field: PropTypes.object,
  children: PropTypes.node,
  classes: PropTypes.object.isRequired,
  label: PropTypes.string,
  onClick: PropTypes.func,
  values: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  valueSelected: PropTypes.any,
  textOnSelectOpened: PropTypes.string,
  textAfterSelected: PropTypes.string,
  name: PropTypes.string,
  setWidthManualy: PropTypes.string,
  color: PropTypes.string,
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  fullWidth: PropTypes.bool,
  noErrowBorder: PropTypes.bool,
  inputDisplay: PropTypes.bool,
  heigherIndex: PropTypes.bool,
  noBorder: PropTypes.bool,
  disabled: PropTypes.bool,
  onButtonClose: PropTypes.func,
  showError: PropTypes.bool,
  mandatory: PropTypes.bool,
  errorMessage: PropTypes.string,
  touched: PropTypes.object,
  errors: PropTypes.object,
  showLabel: PropTypes.bool,
};
AppSelect.defaultProps = {
  color: 'primary',
  fullWidth: false,
  noBorder: false,
  inputDisplay: false,
  disabled: false,
  heigherIndex: false,
  textOnSelectOpened: null,
  textAfterSelected: null,
  maxHeight: null,
  onButtonClose: null,
  setWidthManualy: null,
  showError: false,
  noErrowBorder: false,
  mandatory: false,
  errorMessage: null,
};

export default withStyles(styleasset)(AppSelect);
