import axios from 'axios';

const getClientRefund = async (sessionEditing) => {
  if (!sessionEditing) {
    return Promise.reject(new Error('No session editing'));
  }
  return axios.get(
    `${process.env.REACT_APP_INVOICES_STACKS_URL}/info/refund-bank?sessionEditing=${sessionEditing}`
  );
};

const updateClientBankInfo = async (payload, queryParams) => {
  return axios.post(
    `${process.env.REACT_APP_INVOICES_STACKS_URL}/info/refund-bank?${queryParams}`,
    payload
  );
};

export default {
  getClientRefund,
  updateClientBankInfo,
};
