import { initializePurchase } from 'models/purchase';

const initialValues = (props) => {
  const { id: expenseId } = props.match.params;
  const { expenses } = props;
  const { company } = props.loggedUserCompany;
  const category =
    props.location && props.location.state
      ? props.location.state.category
      : null;

  return initializePurchase(category, company, expenses, expenseId);
};

export default initialValues;
