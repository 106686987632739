import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Tabs,
  Tab,
  Card,
  CardContent,
  Avatar,
  Typography,
} from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { fetchCards } from 'actions/CardActions';
import NewCard from 'components/CardComponents/CardCreation/NewCard';
import { userCanCreateCard } from 'selectors/rightsSelector/cardRequests';

const TabPanel = ({ indexTab, children, indexPanel }) => {
  return <div>{indexTab === indexPanel && <div>{children}</div>}</div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '16px',
  },
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    // large avatar
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  addCard: {
    width: '300px',
    height: '145px',
  },
}));

const SelectCardsRouter = ({ history }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [indexTab, setValue] = useState(0);
  const canCreateCard = useSelector((reduxState) =>
    userCanCreateCard(reduxState)
  );
  const { cards } = useSelector((reduxState) => reduxState.cardReducer);

  useEffect(() => {
    dispatch(fetchCards(id));
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderAddCard = (isVirtualCard) => {
    return (
      <Grid item xs={12} sm={6} md={4} className={classes.addCard}>
        <NewCard
          text={t('addCard')}
          openCardConfig={() => {
            /* eslint-disable no-unused-expressions */
            isVirtualCard
              ? history.push(`/card/virtual/new?userId=${id}`)
              : history.push(`/card/physical/new?userId=${id}`);
            // ? history.push(`/card/virtual/new/${id}`)
            // : history.push(`/card/physical/new/${id}`);
          }}
        />
      </Grid>
    );
  };
  const renderListVirualCards = () => {
    return (
      cards &&
      cards
        .filter(
          (elm) =>
            !elm.physical && !elm.virtualConverted && elm.bbfUserId === id
        )
        .map((card, idx) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={idx}
              style={{ cursor: 'pointer' }}
            >
              <Card
                onClick={() => {
                  history.push(`/card/${card._id}?userId=${id}`);
                }}
              >
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item></Grid>
                    <Grid item>
                      <Avatar className={classes.orange}>
                        <h2>{card.deliveryFirstname[0]}</h2>
                      </Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Typography variant="h5">
                    {/* AEWEB Online Expenses */}
                    {card.maskedPan ? card.maskedPan : t('activate_card')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })
    );
  };
  const renderPhysicalCardList = () => {
    return (
      cards &&
      cards
        .filter(
          (elm) =>
            (elm.physical || elm.virtualConverted) && elm.bbfUserId === id
        )
        .map((card, idx) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={idx}
            style={{ cursor: 'pointer' }}
          >
            <Card
              onClick={() => {
                history.push(`/card/${card._id}?userId=${id}`);
              }}
            >
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid item></Grid>
                  <Grid item>
                    <Avatar className={classes.orange}>
                      <h2>{card.deliveryFirstname[0]}</h2>
                    </Avatar>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <Typography variant="h5">
                  {/* AEWEB Online Expenses */}
                  {card.maskedPan ? card.maskedPan : t('activate_card')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))
    );
  };

  return (
    <>
      <Tabs
        value={indexTab}
        onChange={handleChange}
        aria-label="simple tabs example"
      >
        <Tab label={t('physical_cards')} />
        <Tab label={t('virtual_cards')} />
      </Tabs>

      <TabPanel indexTab={indexTab} indexPanel={0}>
        {/* physical Card */}
        <Grid container spacing={2} className={classes.root}>
          {renderPhysicalCardList()}
          {canCreateCard && renderAddCard(false)}
        </Grid>
      </TabPanel>
      <TabPanel indexTab={indexTab} indexPanel={1}>
        {/* Virtual cards */}
        <Grid container spacing={2} className={classes.root}>
          {renderListVirualCards()}
          {canCreateCard && renderAddCard(true)}
        </Grid>
      </TabPanel>
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  indexTab: PropTypes.any.isRequired,
  indexPanel: PropTypes.any.isRequired,
};

SelectCardsRouter.propTypes = {
  history: PropTypes.object,
};

export default SelectCardsRouter;
