import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

// @material-ui/core
import { TextField as MaterialTextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
// other components
import ErrorMessage from '../ErrorMessage';
// helper function
import { initTextFieldValue } from '../../helpers/textFieldHelper';

const TextFieldWithAdornment = ({
  adornmentPosition,
  adornmentText,
  name,
  styles,
  ...rest
}) => {
  // Hook field to Formik
  const [field, meta] = useField(name);
  // Extract key values needed for render
  const { onChange: handleChange, onBlur: handleBlur, ...restField } = field;
  const { error, value, touched } = meta;

  // determine which value to display, the prop or Formik
  const inputValue = initTextFieldValue(rest.value, value);

  return (
    <React.Fragment>
      <MaterialTextField
        style={styles || {}}
        fullWidth // defaults to fullWidth unless it's overrided by a prop
        margin="dense"
        variant="outlined"
        InputProps={{
          inputProps: {
            style: rest.inputStyle,
          },
          startAdornment: adornmentPosition === 'start' && (
            <InputAdornment position={adornmentPosition}>
              {adornmentText}
            </InputAdornment>
          ),
          endAdornment: adornmentPosition === 'end' && (
            <InputAdornment position={adornmentPosition}>
              {adornmentText}
            </InputAdornment>
          ),
        }}
        {...rest}
        {...restField}
        autoComplete="off"
        value={inputValue}
        onChange={rest.onChange || handleChange}
        onBlur={rest.onBlur || handleBlur}
        error={error && touched}
      />
      <ErrorMessage showError={error && touched} error={error} />
    </React.Fragment>
  );
};

TextFieldWithAdornment.propTypes = {
  adornmentPosition: PropTypes.oneOf(['start', 'end']),
  adornmentText: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
    .isRequired,
  name: PropTypes.string.isRequired,
  styles: PropTypes.object,
};

export default TextFieldWithAdornment;
