import { bindActionCreators } from 'redux';
import { selectedCompany } from 'actions/UserCompanyActions';
import { changeLanguage } from 'actions/LanguageActions';
import { isAuthenticated } from 'actions/AuthActions';
import { resizeScreen } from 'actions/ResizeScreenActions';

export default (dispatch) =>
  bindActionCreators(
    {
      selectedCompany,
      changeLanguage,
      isAuthenticated,
      resizeScreen,
    },
    dispatch
  );
