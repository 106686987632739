/* eslint-disable */
import querystring from 'qs';
import { isEmpty, isEqual } from 'lodash';

export const queryStringHelper = (query) => {
  const qs = {
    page: query.page + 1,
    limit: query.pageSize,
    search: query.search,
    isArchived: query.isArchived,
  };

  const filters = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const key in query.filters) {
    if (query.filters[key] && query.filters[key].length)
      filters[key] = query.filters[key];
  }

  if (!isEmpty(filters)) qs.filters = filters;

  if (query.orderBy) {
    qs.orderBy = query.orderBy.field;
    qs.orderDirection = query.orderDirection;
  }
  if (query?.pagination === false) {
    qs.pagination = false;
  }
  return { querystring: querystring.stringify(qs), ...qs };
};

export const compareQs = (oldQs, newQs) => {
  return (
    newQs.limit === oldQs.limit &&
    newQs.search === oldQs.search &&
    isEqual(newQs.filters, oldQs.filters) &&
    newQs.orderBy === oldQs.orderBy &&
    newQs.orderDirection === oldQs.orderDirection &&
    newQs.isArchived === oldQs.isArchived
  );
};

export const buildQueryString = (baseUrl, params) => {
  const queryParams = Object.entries(params)
    .map(([key, value]) => {
      return value ? `${key}=${encodeURI(value)}` : null;
    })
    .filter((item) => item !== null)
    .join('&');

  return queryParams ? `${baseUrl}?${queryParams}` : baseUrl;
};
