import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  Grid,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
  Hidden,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ReceiptIcon from '@material-ui/icons/Receipt';
import AppCheckbox from '../../AppCheckbox';
import FilePreviewDialog from '../../UploadFile/FilePreviewDialog';

import NumberFormat from '../../../helpers/NumberFormat';
import PaymentTypes from '../../../helpers/PaymentTypes';

const FalseInput = ({ label, value, alignRight }) => {
  if (!value) {
    return (
      <Typography
        style={{
          color: 'lightgray',
          height: '50px',
          display: 'flex',
          justifyContent: alignRight ? 'flex-start' : 'center',
          alignItems: 'center',
          overflow: 'hidden',
          width: '100%',
          borderBottom: '1px lightgray solid',
          marginBottom: '3px',
        }}
      >
        {label}
      </Typography>
    );
  }
  return (
    <div
      style={{
        height: '50px',
        overflow: 'hidden',
        width: '100%',
        borderBottom: '1px lightgray solid',
        marginBottom: '3px',
      }}
    >
      <div style={{ color: 'lightgray' }}>{label}</div>
      <Typography
        style={{
          display: 'flex',
          justifyContent: alignRight ? 'flex-start' : 'center',
          alignItems: 'center',
          height: '80%',
          marginLeft: '5px',
        }}
      >
        {value}
      </Typography>
    </div>
  );
};

FalseInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  alignRight: PropTypes.bool,
};

const NewExpenseRow = ({
  expense,
  // users,
  // fetchExpenseFile,
  categories,
  clients = {},
  clientProjects = {},
  activities = {},
  fetchExpenseFile,
}) => {
  const { setFieldValue } = useFormikContext();
  const { t } = useTranslation();
  const [openDialog, setOpenDialog] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);
  const [type, setType] = useState(null);
  const fetchExpenseFileCallback = useCallback(fetchExpenseFile);
  useEffect(() => {
    if (expense.filename && typeof expense.filename === 'string') {
      fetchExpenseFileCallback(
        expense.filename,
        (err, { url = null, type: ContentType } = {}) => {
          setFileUrl(err ? null : url);
          setType(err ? null : ContentType);
        }
      );
    }
  }, [expense.filename]);

  function renderCategory(exp) {
    let catId = null;

    switch (exp.__t) {
      case 'GroupedExpense':
        // TODO: I bet it is wrong, but is not diplsayed... could be a hard coded value "NOT USED"
        catId = exp.expenses[0].category_id;
        break;
      case 'SimpleExpense':
      case 'InvoiceExpense':
      case 'Purchase':
        catId = exp.category_id;
        break;
      default:
        catId = null;
    }

    return catId && categories && categories.data[catId]
      ? categories.data[catId].description
      : '';
  }
  function toggleDialog(e) {
    e.stopPropagation();
    setOpenDialog((prevValue) => !prevValue);
  }
  function getFileContent() {
    const receipt = { value: fileUrl, type };
    return receipt;
  }
  // eslint-disable-next-line camelcase
  let payment_type;
  const tmpPaymentArray = PaymentTypes.filter(
    (el) => el._id === expense.payment_type
  );
  if (tmpPaymentArray && tmpPaymentArray.length > 0) {
    // eslint-disable-next-line camelcase
    payment_type = tmpPaymentArray[0].display;
  }

  let activitie;
  if (activities && activities[expense.internal_activity]) {
    activitie = activities[expense.internal_activity].display;
  }

  let client;
  if (clients && clients[expense.client_id]) {
    client = clients[expense.client_id].name;
  }

  let project;
  const { projects } = clientProjects;
  if (projects) {
    if (projects[expense.client_id] && projects[expense.client_id].length > 0) {
      const projectFiltered = projects[expense.client_id].filter(
        (el) => el._id === expense.project_id
      );
      if (projectFiltered && projectFiltered.length > 0) {
        project = projectFiltered[0].description;
      }
    }
  }

  const displayAmount = NumberFormat.n(
    expense.duePayableAmount || expense.grandTotalAmount || expense.total
  );
  useEffect(() => {
    setFieldValue(`categoryDisplay${expense._id}`, renderCategory(expense));
    setFieldValue(`grandTotalAmount${expense._id}`, displayAmount);

    setFieldValue(`payment_typeDisplay${expense._id}`, payment_type);
    setFieldValue(`internal_activityDisplay${expense._id}`, activitie);
    setFieldValue(`clientDisplay${expense._id}`, client);
    setFieldValue(`client_id`, expense.client_id);
    setFieldValue(`projectDisplay${expense._id}`, project);
    setFieldValue(`description${expense._id}`, expense.description);
  }, []);

  // console.log({ expense });
  return (
    <React.Fragment>
      {/* preview file expense's pdf */}
      <FilePreviewDialog
        content={getFileContent()}
        toggleDialog={toggleDialog}
        show={openDialog}
      />
      <ExpansionPanel>
        <ExpansionPanelSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={6} md={3}>
              <Typography style={{ overflow: 'hidden' }}>
                {format(new Date(expense.date), 'dd/MM/yyyy')}
              </Typography>
            </Grid>
            <Hidden smDown>
              <Grid item xs={6}>
                <Typography style={{ overflow: 'hidden' }}>
                  {renderCategory(expense)}
                </Typography>
              </Grid>
            </Hidden>
            <Grid item xs={3}>
              {displayAmount ? (
                <Typography style={{ overflow: 'hidden', textAlign: 'right' }}>
                  {`${displayAmount}€`}
                </Typography>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </ExpansionPanelSummary>
        <ExpansionPanelDetails>
          <Grid container spacing={2} justifyContent="center">
            <Grid item xs={12}>
              <FalseInput
                label={t('expenses.expense.description')}
                value={expense.description}
                alignRight={true}
              />
            </Grid>
            <Grid item xs={6}>
              <FalseInput
                label={t('expenses.expense.category_sub_category')}
                value={renderCategory(expense)}
              />
            </Grid>
            <Grid item xs={6}>
              <FalseInput
                label={t('expenses.expense.form_activity')}
                value={activitie}
              />
            </Grid>
            <Grid item xs={6}>
              <FalseInput
                label={t('expenses.expense.form_paymenttype')}
                // eslint-disable-next-line camelcase
                value={payment_type}
              />
            </Grid>
            <Grid item xs={6}>
              <FalseInput
                label={t('expenses.expense.form_client')}
                value={client}
              />
            </Grid>
            <Grid item xs={6}>
              <FalseInput
                label={t('expenses.expense.form.total_gross')}
                value={`${displayAmount}€`}
              />
            </Grid>
            <Grid item xs={6}>
              <FalseInput
                label={t('expenses.expense.form_project')}
                value={project}
              />
            </Grid>
            <Grid item xs={6}>
              <AppCheckbox
                checked={expense.is_intern}
                labelText={t('expenses.expense.form_isIntern')}
                name={`is_intern`}
                id={`is_intern`}
                onClick={() => {}}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              <AppCheckbox
                checked={expense.is_billable}
                labelText={t('expenses.expense.form_isBbillable')}
                name={`is_billable`}
                id={`is_billable`}
                onClick={() => {}}
                disabled={true}
              />
            </Grid>
            <Grid item xs={6}>
              {' '}
              <FalseInput
                label={t('expenses.expense.form_expensefrom')}
                value={expense.travel ? expense.travel.from : undefined}
              />
            </Grid>
            <Grid item xs={6}>
              <FalseInput
                label={t('expenses.expense.form_expenseto')}
                value={expense.travel ? expense.travel.to : undefined}
              />
            </Grid>
            <Grid item xs={6}>
              <FalseInput
                label={t('expenses.expense.form_distance')}
                value={expense.travel ? expense.travel.distance : undefined}
              />
            </Grid>
            <Grid item xs={6}>
              <FalseInput
                label={t('expenses.expense.form_distance_rate')}
                value={
                  expense.travel ? expense.travel.distance_rate : undefined
                }
              />
            </Grid>
            <Grid item xs={12}>
              {expense.filename ? (
                <div
                  onClick={toggleDialog}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                    flexDirection: 'column',
                    height: '48px',
                    marginBottom: '4px',
                    paddingTop: '16px',
                  }}
                >
                  {/* <Typography>See your receipt</Typography> */}
                  <ReceiptIcon style={{ fontSize: '35pt' }} />
                </div>
              ) : null}
            </Grid>
          </Grid>
        </ExpansionPanelDetails>
      </ExpansionPanel>
    </React.Fragment>
  );
};

NewExpenseRow.propTypes = {
  expense: PropTypes.object,
  users: PropTypes.object,
  fetchExpenseFile: PropTypes.func.isRequired,
  activities: PropTypes.object.isRequired,
  categories: PropTypes.object.isRequired,
  clients: PropTypes.object.isRequired,
  clientProjects: PropTypes.object.isRequired,
};

export default NewExpenseRow;
