import { bindActionCreators } from 'redux';
import {
  createInternalActivity,
  updateInternalActivity,
} from '../../../actions/InternalActivitiesActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createInternalActivity,
      updateInternalActivity,
    },
    dispatch
  );
