import spinnerService from 'services/SpinnerService';

export default (values, { setErrors, setStatus, setSubmitting, props }) => {
  const fetchData = () => {
    spinnerService.startSpinner();
    props.fetchProviders().finally(() => spinnerService.endSpinner());
  };
  const cb = (err, provider) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      if (err.message === 'All providers are existed')
        setErrors({
          internalError: props.t('settings_section.providers.duplicate'),
        });
      else {
        setErrors({
          internalError: props.t('forms.internal_error'),
        });
      }
    } else {
      setStatus({ success: true });
      if (props.onCreated) {
        props.onCreated(provider);
      }
      props.closeDialog();
      fetchData();
    }
  };

  const {
    address,
    title,
    contactName,
    phone,
    email,
    zipcode,
    city,
    country,
    countryAlpha3,
    ...restValues
  } = values;

  const data = {
    address: {
      address,
      zipcode,
      city,
      country,
      countryAlpha3,
    },
    contact: {
      title,
      contactName: contactName || '',
      phone,
      email,
    },
    ...restValues,
  };

  if (props.isImport) {
    props.updateProviderImport(values);
    setSubmitting(false);
    props.closeDialog();
  } else if (!props.id) {
    props.createProvider([data], cb);
  } else {
    props.updateProvider(props.id, data, cb);
  }
};
