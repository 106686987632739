/* Packages & components */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Button, Typography, Box } from '@material-ui/core';
import { TextField as FormikTextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import ErrorMessage from 'components/ErrorMessage';
import OTPIcon from 'assets/images/otp.svg';

import FormLoading from '../FormLoading';
import BankTransferFormFields from './BankTransferFormFields';

const useStyles = makeStyles({
  otpIcon: {
    display: 'block',
    width: 120,
    margin: '10px auto',
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
  },
  submitBtn: {
    marginTop: 20,
    marginBottom: 10,
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  otpInput: {
    marginRight: 10,
  },
  amountErrMessage: {
    textAlign: 'center',
    color: 'red',
  },
});

const BankTransferForm = ({
  readOnly = false,
  providers,
  clients,
  users,
  companyAccounts,
  beneficiaryTypes,
  showOTPPart,
  handleGetOTP,
  showBeneficiaryExtraInfo,
  setShowBeneficiaryExtraInfo,
  showIban,
  setShowIban,
  showBic,
  setShowBic,
  wallet,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { errors, handleSubmit, isSubmitting, values, setSubmitting } =
    useFormikContext();
  const [isDisabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [canMakePayout, setCanMakePayout] = useState(false);

  useEffect(() => {
    if (wallet && wallet.authorizedBalance && wallet.authorizedBalance > 0) {
      setDisabledSubmitButton(false);
      setCanMakePayout(true);
    } else {
      setDisabledSubmitButton(true);
      setCanMakePayout(false);
    }

    if (
      wallet &&
      wallet.authorizedBalance &&
      wallet.authorizedBalance > values.amount
    ) {
      setDisabledSubmitButton(false);
      setCanMakePayout(true);
    } else {
      setDisabledSubmitButton(true);
      setCanMakePayout(false);
    }
  }, [values.amount]);

  useEffect(() => {
    if (!values.isShowedUploadPart) {
      if (isSubmitting) {
        setDisabledSubmitButton(true);
      } else {
        setDisabledSubmitButton(false);
      }
    }
    if (values.isShowedUploadPart) {
      if (isSubmitting) {
        setDisabledSubmitButton(true);
      } else {
        setDisabledSubmitButton(false);
      }

      if (values.contractFiles && values.contractFiles.length > 0) {
        setDisabledSubmitButton(false);
      } else {
        setDisabledSubmitButton(true);
      }
    }
  }, [isSubmitting, values.isShowedUploadPart, values.contractFiles, errors]);

  const reSendOtp = async () => {
    setSubmitting(true);
    await handleGetOTP();
    setSubmitting(false);
  };

  const renderOTPPart = () => {
    return (
      <div>
        <img src={OTPIcon} className={classes.otpIcon} alt="OTP Icon" />
        <Typography className={classes.title}>{t('otp-title')}</Typography>
        <Typography>{t('otp-message')}</Typography>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Field
            name="otp"
            type="text"
            component={FormikTextField}
            required
            fullWidth
            disabled={readOnly}
            variant="outlined"
            className={classes.otpInput}
          />
          <Button color="primary" onClick={() => reSendOtp()}>
            {t('otp-resend')}
          </Button>
        </Box>
        <Button
          color="primary"
          variant="contained"
          className={classes.submitBtn}
          onClick={handleSubmit}
        >
          {t('otp-submit')}
        </Button>
      </div>
    );
  };

  const renderBody = () => {
    if (showOTPPart) {
      return renderOTPPart();
    }
    return (
      <div>
        <Grid container spacing={2}>
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <ErrorMessage
                error={
                  errors.handleSubmitTreezorErrors
                    ? errors.handleSubmitTreezorErrors
                    : ''
                }
                showError={errors && errors.handleSubmitTreezorErrors}
              />
            </Grid>
          </Grid>

          <BankTransferFormFields
            readOnly={readOnly}
            providers={providers}
            clients={clients}
            users={users}
            companyAccounts={companyAccounts}
            beneficiaryTypes={beneficiaryTypes}
            setDisabledSubmitButton={setDisabledSubmitButton}
            showBeneficiaryExtraInfo={showBeneficiaryExtraInfo}
            setShowBeneficiaryExtraInfo={setShowBeneficiaryExtraInfo}
            showIban={showIban}
            setShowIban={setShowIban}
            showBic={showBic}
            setShowBic={setShowBic}
          />

          {/* Submit button */}

          <Grid container item xs={12} spacing={2}>
            {canMakePayout ? null : (
              <>
                <Grid item xs={9}></Grid>
                <Grid item xs={3}>
                  <p className={classes.amountErrMessage}>
                    Fonds non suffisant
                  </p>
                </Grid>
              </>
            )}

            <Grid item xs={9}></Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!canMakePayout || isDisabledSubmitButton}
              >
                {t('Validation, passez à l’étape suivante')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <>
      <FormLoading loading={isSubmitting} />
      {renderBody()}
    </>
  );
};

BankTransferForm.propTypes = {
  readOnly: PropTypes.bool,
  providers: PropTypes.array,
  clients: PropTypes.array,
  users: PropTypes.array,
  companyAccounts: PropTypes.array,
  beneficiaryTypes: PropTypes.array,
  showOTPPart: PropTypes.bool,
  handleGetOTP: PropTypes.func,
  showBeneficiaryExtraInfo: PropTypes.bool,
  setShowBeneficiaryExtraInfo: PropTypes.func,
  showIban: PropTypes.bool,
  setShowIban: PropTypes.func,
  showBic: PropTypes.bool,
  setShowBic: PropTypes.func,
  wallet: PropTypes.object,
};

export default BankTransferForm;
