import React, { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Select, MenuItem, makeStyles } from '@material-ui/core';
import AppCheckbox from 'components/AppCheckbox';
import { PAYMENT_LINK_ELEMENT } from 'constants/invoice';

import {
  DISCOVERY_PACKAGE,
  STANDARD_FORMULA,
  EFFICIENCY_FORMULA,
} from 'constants/subscriptions';
import BankCard from 'components/BankForm/BankCard';
import { ROLE } from 'constants/roles';
import RoleHelper from 'helpers/RoleHelper';
import styles from '../../../../../../assets/jss/root';

import AppButton from '../../../../../../components/AppButton';
import AppDialog from '../../../../../../components/AppDialog';
import CustomGenericDialog from '../../../../../../components/CustomGenericDialog';
import RichTextEditor from '../../../../../../components/richTextEditor';

import { useDialog } from '../../../../../../hooks/dialogHooks';

import {
  typeHelpers,
  invoiceButtons,
  getInvoiceEmail,
} from '../../../../../../helpers/invoiceHelpers';
import SimpleUpload from '../../../../../../components/SimpleUpload';

const StyleCheckbox = withStyles(() => ({
  root: {
    color: 'red',
  },
  label: {
    fontSize: '14px',
  },
}))(AppCheckbox);

const useStyles = makeStyles(() => ({
  chooseBankContent: {
    padding: '16px',
    minHeight: '200px',
  },
  selectContent: {
    marginBottom: '30px',
  },
}));

const ValidateButton = ({ separatorStyle, invoiceId, horizontal, classes }) => {
  const customStyles = useStyles();
  const { showDialog: showApproveDialog, toggleDialog: toggleApprovalDialog } =
    useDialog();
  const { showDialog: showEmailDialog, toggleDialog: toggleEmailDialog } =
    useDialog();
  const {
    showDialog: showChooseBankDialog,
    toggleDialog: toggleChooseBankDialog,
  } = useDialog();
  const {
    showDialog: showAdditionalPaymentLinkDialog,
    toggleDialog: toggleAdditionalPaymentLinkDialog,
  } = useDialog();
  const {
    showDialog: showContactAdminDialog,
    toggleDialog: toggleContactAdminDialogDialog,
  } = useDialog();
  const { t } = useTranslation();
  const { values, isSubmitting, setFieldValue } = useFormikContext();
  const companySubscription = useSelector(
    (state) => state.companySubscription.data || {}
  );
  const loggedUserCompany = useSelector(
    (state) => state.loggedUserCompany.company
  );
  const loggedUser = useSelector((state) => state.loggedUser.user);
  const emailTemplates = useSelector(
    (state) => state.emailTemplate.emailTemplates
  );

  const remainingPaymentLink = useSelector(
    (state) => state.companySubscription?.data?.remainingPaymentLink || 0
  );

  const defaultBank =
    loggedUserCompany.bank_details.find((item) => item?.isDefault) ||
    loggedUserCompany?.bank_details[0];

  const [choosedBankId, setChoosedBankId] = useState(defaultBank._id || null);

  const { type, client, isUsePaymentLink, payments } = values;

  const isAdmin = RoleHelper.hasRoles([ROLE.ADMINISTRATEUR]);

  function validate() {
    toggleApprovalDialog();

    const email = client ? client.email : null;

    if (
      email &&
      (typeHelpers.isInvoice(type) ||
        typeHelpers.isCreditNote(type) ||
        typeHelpers.isPrePayment(type))
    ) {
      toggleEmailDialog();
    } else {
      setFieldValue('action', 'validate', false);
    }
  }

  function correctToggle() {
    const email = client ? client.email : null;

    if (
      email &&
      (typeHelpers.isInvoice(type) ||
        typeHelpers.isCreditNote(type) ||
        typeHelpers.isPrePayment(type))
    ) {
      toggleEmailDialog();
    } else {
      toggleApprovalDialog();
    }
  }

  function setButtonText() {
    if (typeHelpers.isDeliveryForm(type)) {
      return 'invoices.delivery_to_client';
    }

    if (
      typeHelpers.isInvoice(type) ||
      typeHelpers.isCreditNote(type) ||
      typeHelpers.isPrePayment(type)
    ) {
      return 'invoices.send_to_client';
    }

    return 'invoices.approved_by_client';
  }

  function setLabelCheckboxAddPaymentLink() {
    if (typeHelpers.isInvoice(type) || typeHelpers.isPrePayment(type)) {
      return `${t(
        'invoice.Add_payment_link_to_invoice'
      )}: ${remainingPaymentLink}`;
    }
    if (typeHelpers.isCreditNote(type)) {
      return `${t(
        'invoice.add_payment_link_to_refund_invoice'
      )}: ${remainingPaymentLink}`;
    }
    return null;
  }

  const ref = React.createRef();

  function sendEmail() {
    toggleAdditionalPaymentLinkDialog();
    toggleEmailDialog();
    setFieldValue('emailInput', ref.current.htmlToGet, false);
    setFieldValue('action', 'validate', false);
  }

  function cancelChooseBank() {
    setChoosedBankId(defaultBank._id || {});
    toggleChooseBankDialog();
  }

  function handleConfirmChooseBank() {
    const bankChoosed = loggedUserCompany.bank_details.find(
      (bank) => bank._id === choosedBankId
    );
    setFieldValue('iban', bankChoosed.iban);
    setFieldValue('bic', bankChoosed.bic);
    setFieldValue('company', {
      ...values.company,
      bank_details: bankChoosed,
    });
    toggleChooseBankDialog();
    sendEmail();
  }

  const handleConfirmAdditionalPaymentLink = () => {
    toggleAdditionalPaymentLinkDialog();
    if (typeHelpers.isCreditNote(type)) {
      sendEmail();
    } else {
      toggleChooseBankDialog();
    }
  };

  function renderDialog() {
    if (showApproveDialog) {
      return (
        <AppDialog
          sm
          iconClose
          footer
          title={t('sure')}
          closeDialog={toggleApprovalDialog}
          onConfirmText={t('yes')}
          onCancelText={t('no')}
          onConfirm={validate}
          contentText={t('invoice.confirm.validate')}
          color="secondary"
        />
      );
    }

    return null;
  }
  function renderBanks() {
    const bankChoosed = loggedUserCompany.bank_details.find(
      (bank) => bank._id === choosedBankId
    );

    return (
      <BankCard
        required
        disabled={true}
        bank={bankChoosed || loggedUserCompany.bank_details[0]}
      />
    );
  }
  function renderChooseBankDialog() {
    const selecOptions = loggedUserCompany.bank_details
      .filter((item) => item?.iban)
      .reduce((prev, curr) => {
        const option = { text: curr.bank_name, value: curr._id };
        return [...prev, option];
      }, []);

    if (showChooseBankDialog) {
      return (
        <CustomGenericDialog
          title={t('invoice.form.email.dialog.choose_bank_title')}
          onCancel={cancelChooseBank}
          confirmText={t('invoice.form.email.dialog.confirm')}
          cancelText={t('no')}
          iconClose
          closeDialog={cancelChooseBank}
          color="secondary"
          maxWidth="sm"
        >
          <Grid className={customStyles.chooseBankContent}>
            <Grid className={customStyles.selectContent}>
              <Select
                value={choosedBankId || selecOptions[0].value}
                onChange={(e) => setChoosedBankId(e.target.value)}
                variant="standard"
                margin="dense"
                fullWidth
                displayEmpty
              >
                {selecOptions.map(({ text, value }) => (
                  <MenuItem key={`${text}, ${value}`} value={value}>
                    {text}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            {choosedBankId && (
              <>
                {renderBanks()}
                <Grid item xs={12}>
                  <div className={classes.alignRight}>
                    <AppButton
                      color="secondaryLight"
                      text={t('invoice.form.email.dialog.confirm')}
                      noBorder={true}
                      onClick={() => handleConfirmChooseBank()}
                    />
                  </div>
                </Grid>
              </>
            )}
          </Grid>
        </CustomGenericDialog>
      );
    }

    return null;
  }

  function additionalPaymentLink() {
    if (
      !(
        typeHelpers.isQuotation(type) ||
        typeHelpers.isInvoice(type) ||
        typeHelpers.isCreditNote(type)
      ) ||
      !isUsePaymentLink
    ) {
      sendEmail();
      return;
    }
    if (remainingPaymentLink - payments.length >= 0) {
      toggleChooseBankDialog();
      return;
    }
    if (!isAdmin) {
      toggleContactAdminDialogDialog();
      return;
    }
    toggleAdditionalPaymentLinkDialog();
  }

  function additionalPaymentLinkDialog() {
    let cost;
    switch (companySubscription.name) {
      case DISCOVERY_PACKAGE:
        cost = 2;
        break;
      case STANDARD_FORMULA:
        cost = 1.25;
        break;
      case EFFICIENCY_FORMULA:
        cost = 1;
        break;
      default:
        // eslint-disable-next-line no-unused-vars
        cost = 0;
        break;
    }
    const text =
      t('invoice.additional_payment_link_message_1') +
      cost +
      t('invoice.additional_payment_link_message_2');
    if (showAdditionalPaymentLinkDialog) {
      return (
        <AppDialog
          sm
          iconClose
          footer
          title={t('sure')}
          closeDialog={toggleAdditionalPaymentLinkDialog}
          onConfirmText={t('accept')}
          onCancelText={t('cancel')}
          onConfirm={handleConfirmAdditionalPaymentLink}
          contentText={text}
          color="secondary"
          setWidthManualy="500px"
          setHeightManualy="200px"
        />
      );
    }
    return null;
  }

  const rawMailTemplate = React.useMemo(() => {
    return getInvoiceEmail(type, loggedUser, loggedUserCompany, emailTemplates);
  }, [type, loggedUser, loggedUserCompany, emailTemplates]);

  const prepareHtml = React.useCallback(
    (template) => {
      if (isUsePaymentLink) {
        return template.replace(
          PAYMENT_LINK_ELEMENT.RAW,
          PAYMENT_LINK_ELEMENT.AFTER_EDIT
        );
      }
      return template.replace(
        PAYMENT_LINK_ELEMENT.AFTER_EDIT,
        PAYMENT_LINK_ELEMENT.RAW
      );
    },
    [isUsePaymentLink]
  );

  const toggleUsePaymentLink = (e) => {
    const value = e.target.checked;
    setFieldValue('isUsePaymentLink', value);
  };

  const handleConfirmContactAdmin = () => {
    toggleContactAdminDialogDialog();
    toggleEmailDialog();
    setFieldValue('action', 'draft', false);
    setFieldValue('redirectToList', true);
  };

  const renderContactAdminDialog = () => {
    if (showContactAdminDialog) {
      return (
        <AppDialog
          sm
          iconClose
          footer
          title={t('sure')}
          closeDialog={toggleContactAdminDialogDialog}
          onConfirmText={t('ok')}
          onConfirm={handleConfirmContactAdmin}
          contentText={t('invoice.additional_payment_link_message_3')}
          color="secondary"
          colorConfirm="secondary"
          setWidthManualy="500px"
          setHeightManualy="200px"
          right
        />
      );
    }
    return null;
  };

  function renderEmailDialog() {
    if (showEmailDialog) {
      const initialHtml = prepareHtml(rawMailTemplate);

      return (
        <CustomGenericDialog
          title={t('invoice.form.email.dialog.title')}
          onConfirm={sendEmail}
          onCancel={toggleEmailDialog}
          confirmText={t('invoice.form.email.dialog.confirm')}
          cancelText={t('no')}
          iconClose
          closeDialog={toggleEmailDialog}
          color="secondary"
          maxWidth="md"
        >
          {renderChooseBankDialog()}
          {additionalPaymentLinkDialog()}
          {renderContactAdminDialog()}
          <div>{t('invoice.form.email.dialog.description')}</div>
          <RichTextEditor ref={ref} initialHtml={initialHtml} />
          <Grid container spacing={6}>
            <Grid item xs={8}>
              <span style={{ fontWeight: 'bold', fontSize: 16 }}>
                {loggedUserCompany.name}
                <br />
                {loggedUser.display_name}
              </span>
              {(typeHelpers.isQuotation(type) ||
                typeHelpers.isInvoice(type) ||
                typeHelpers.isCreditNote(type) ||
                typeHelpers.isPrePayment(type)) && (
                <div
                  style={{
                    marginTop: 10,
                  }}
                >
                  <StyleCheckbox
                    onClick={toggleUsePaymentLink}
                    disabled={
                      typeHelpers.isCreditNote(type) ||
                      isSubmitting ||
                      !values.paying_mode?.bridgePaymentLink
                    }
                    color="primary"
                    checked={typeHelpers.isCreditNote(type) || isUsePaymentLink}
                    labelText={setLabelCheckboxAddPaymentLink()}
                    name={`account`}
                    id={`account`}
                    style={{
                      fontSize: 14,
                    }}
                  />
                </div>
              )}
            </Grid>
            {!typeHelpers.isCreditNote(type) && (
              <Grid item xs={4}>
                <SimpleUpload
                  onChange={(files) => {
                    setFieldValue('files', files);
                  }}
                  text={t('invoice.form.email.file')}
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <div className={classes.alignRight}>
              <AppButton
                color="transparentGrey"
                text={t('no')}
                onClick={toggleEmailDialog}
              />
              <AppButton
                color="secondaryLight"
                text={t('invoice.form.email.dialog.confirm')}
                noBorder={true}
                onClick={additionalPaymentLink}
              />
            </div>
          </Grid>
        </CustomGenericDialog>
      );
    }

    return null;
  }

  if (!invoiceButtons[type].canValidate(values) || !invoiceId) {
    return null;
  }

  return (
    <Fragment>
      {renderDialog()}
      {renderEmailDialog()}
      <AppButton
        fullWidth={!horizontal}
        isDisabled={isSubmitting}
        text={t(setButtonText())}
        onClick={() => {
          correctToggle();
        }}
      />
      <div className={separatorStyle} />
    </Fragment>
  );
};

ValidateButton.propTypes = {
  separatorStyle: PropTypes.string,
  invoiceId: PropTypes.string,
  horizontal: PropTypes.bool,
  classes: PropTypes.object,
};

ValidateButton.defaultProps = {
  horizontal: false,
};

export default withStyles(styles)(ValidateButton);
