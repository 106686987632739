export default () => {
  return {
    name: '',
    vat: false,
    intracom_vat: '',
    address: '',
    zipcode: '',
    city: '',
    country: '',
    contact: '',
    email: '',
    siret: '',
    title: '',
    iban: '',
    bic: '',
    isCompany: true,
  };
};
