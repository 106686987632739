import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { map, isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import DragNDropZone from 'components/DragNDropZone';

import UploadInstructions from 'components/UploadInstructions';
import { SnackBarView } from 'components/SnackBars';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import { debounceFn } from 'helpers/utils';
import SelectedRowsMessage from 'components/SelectedRowsMessage';
import { IconDelete, IconEdit } from '../../../components/AppIcons';
import {
  AppRowCenterCenter,
  AppRowStartSpaceAround,
} from '../../../components/AppContainers';
import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';
import { contentText } from '../../../assets/jss/components/appText';
import AppButton from '../../../components/AppButton';
import ImportValidationWarning from '../../../components/ImportValidationWarning';
import { tableFrame } from '../../../helpers/mui-datatableHelper';

const useStyles = makeStyles(() => ({
  contentText,
  hoverShow: {
    visibility: 'visible',
    '&:nth-child(2)': {
      marginLeft: '5px',
    },
  },
}));

const ClientProjects = ({ clientId, openProjectFormDialog }) => {
  const classes = useStyles();
  const clientProjects = useSelector((state) => state.clientProjects.projects);

  return map(
    clientProjects[clientId],
    (project) =>
      !project.deleted && (
        <Link
          key={project._id}
          to="/settings/clients"
          onClick={(e) => {
            e.preventDefault();
            openProjectFormDialog(clientId, project._id);
          }}
        >
          <div className={classes.contentText}>{project.name}</div>
        </Link>
      )
  );
};

const ClientImportBody = ({
  openClientFormDialog,
  clients,
  onFileChange,
  handleDeleteRow,
  isCompany,
  handleSave,
  handlOnCloseSnackBar,
  snackBarMessage,
  isOpenSnackBar,
  autoHideDuration,
  snackBarType,
  showSpinner,
}) => {
  const { t } = useTranslation();

  let columns = [];

  if (isCompany) {
    const columnsHeader = [
      { label: t('settings_section.clients.table_name'), name: 'name' },
      {
        label: t('settings_section.clients.form_is_prospect'),
        name: 'is_prospect',
      },
      {
        label: t('settings_section.clients.form_label_contact'),
        name: 'contact',
      },
      { label: t('settings_section.clients.form_label_phone'), name: 'phone' },
      { label: t('settings_section.clients.form_label_email'), name: 'email' },
    ];
    const headerStyles = {
      paddingLeft: '16px',
      textAlign: 'left',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      fontWeight: '500',
    };
    columns = tableFrame(columnsHeader, headerStyles);
    columns.push({
      label: ' ',
      name: 'index',
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value) => (
          <AppRowStartSpaceAround>
            <IconDelete sm onClick={() => handleDeleteRow(value)} />

            <IconEdit
              sm
              onClick={() => {
                openClientFormDialog(value);
              }}
            />
          </AppRowStartSpaceAround>
        ),
      },
    });
  } else {
    const columnsHeader = [
      { label: t('settings_section.clients.table_name'), name: 'name' },
      {
        label: t('settings_section.clients.form_is_prospect'),
        name: 'is_prospect',
      },
      {
        label: t('settings_section.clients.form_label_contact'),
        name: 'nomDuContact',
      },
      { label: t('settings_section.clients.form_label_phone'), name: 'phone' },
      { label: t('settings_section.clients.form_label_email'), name: 'email' },
    ];
    const headerStyles = {
      paddingLeft: '16px',
      textAlign: 'left',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      fontWeight: '500',
    };
    columns = tableFrame(columnsHeader, headerStyles);
    columns.push({
      label: ' ',
      name: 'index',
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value) => (
          <AppRowCenterCenter>
            <IconDelete sm onClick={() => handleDeleteRow(value)} />

            <IconEdit
              sm
              onClick={() => {
                openClientFormDialog(value);
              }}
            />
          </AppRowCenterCenter>
        ),
      },
    });
  }
  const options = {
    selectableRows: true,
    onRowSelectionChange: (val) => {
      if (!clients.clientList) {
        return;
      }
      if (!val || !val.length) {
        clients.clientList.forEach((elem) => {
          // eslint-disable-next-line no-param-reassign
          elem.setSelected(false);
        });
        // return;
      } else if (val.length > 1 && clients.clientList.length === val.length) {
        clients.clientList.forEach((elem) => {
          // eslint-disable-next-line no-param-reassign
          elem.setSelected(true);
        });
        // return;
      } else if (val.length === 1) {
        const [{ index }] = val;
        clients.clientList[index].toggleSelected();
      }
    },
    setRowProps: (_, index) => {
      if (!clients || !clients.clientList[index]) {
        return '';
      }
      const isValidate = clients.clientList[index].isValidated;
      return {
        style: isValidate
          ? {}
          : {
              border: '4px solid',
              borderColor: 'red',
            },
      };
    },
    isRowSelectable: (dataIndex) => {
      if (!clients && !clients.clientList[dataIndex]) {
        return false;
      }
      return clients.clientList[dataIndex].isValidated;
    },
  };

  return !isEmpty(clients.clientList) ? (
    <React.Fragment>
      <SnackBarView
        message={snackBarMessage}
        open={isOpenSnackBar}
        onClose={handlOnCloseSnackBar}
        autoHideDuration={autoHideDuration}
        variant={snackBarType || 'error'}
      />
      <div style={{ textAlign: 'right', marginTop: '8px' }}>
        {showSpinner && (
          <LoadingIndicatorDialog title={t('loading_single')} open={true} />
        )}
        <AppButton
          // isDisabled={isEmpty(selectedRows)}
          style={{ marginRight: '8px' }}
          color="secondaryLight"
          text={t('create_income')}
          onClick={debounceFn(() => handleSave(), 500)}
        />
      </div>
      {!clients.clientList.some((client) => client._isValidated) && (
        <ImportValidationWarning />
      )}
      <SelectedRowsMessage
        message={t('settings_section.clients.hint_import')}
      />
      <MUIDataTable
        data={clients.clientList}
        columns={columns}
        options={getMuiDatatableOptions(t, clients.clientList.length, options)}
      />
    </React.Fragment>
  ) : (
    <div>
      <UploadInstructions type="client" isCompany={isCompany} />
      <DragNDropZone
        chooseFile={onFileChange}
        acceptFile=".xlsx"
        isMultiple={false}
      />
    </div>
  );
};

ClientImportBody.propTypes = {
  openProjectFormDialog: PropTypes.func,
  openClientFormDialog: PropTypes.func,
  clients: PropTypes.array,
  onFileChange: PropTypes.func,
  handleDeleteRow: PropTypes.func,
  isCompany: PropTypes.bool,
  handleSave: PropTypes.func,
  handlOnCloseSnackBar: PropTypes.func,
  snackBarMessage: PropTypes.string,
  isOpenSnackBar: PropTypes.bool,
  autoHideDuration: PropTypes.number,
  snackBarType: PropTypes.string,
  showSpinner: PropTypes.bool,
};

ClientProjects.propTypes = {
  openProjectFormDialog: PropTypes.func,
  clientId: PropTypes.string,
};

export default ClientImportBody;
