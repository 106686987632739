/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  actionNewTransferButton: {
    fontSize: '16px',
    backgroundColor: '#f3f3f3',
    width: '100%',
    justifyContent: 'start',
    padding: '8px 13px',
    borderRadius: '10px',
    textTransform: 'inherit',
    fontWeight: 'normal',

    transition: 'background-color 0.2s ease',

    '&:hover': {
      backgroundColor: '#D9E1EA',
    },
  },
}));

export default useStyles;
