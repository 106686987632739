import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { getOTP } from 'actions/CardActions';
import OTPDialog from 'components/OTPDialog';
import LoadingDialog from 'components/LoadingDialog';
import AppButton from '../../AppButton';
import TreezorCardConfigField from './treezorCardConfigField';

const CardConfig = ({ virtual, cardId, setOtp, setOpenOTP, openOTP }) => {
  const { t } = useTranslation();
  const { handleSubmit } = useFormikContext();
  const dispatch = useDispatch();

  const [cardLoading, setCardLoading] = useState(false);

  const getOTPCode = async () => {
    try {
      setCardLoading(true);
      await dispatch(getOTP());
      setCardLoading(false);
      setOpenOTP(true);
    } catch (error) {
      setCardLoading(false);
    }
  };

  const submitOTP = async (otp) => {
    setOtp(otp);
    try {
      setCardLoading(true);
      handleSubmit();

      setCardLoading(false);
    } catch (error) {
      setCardLoading(false);
    }
  };

  return (
    <Grid container>
      <TreezorCardConfigField virtual={virtual} cardId={cardId} />
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-around',
        }}
      >
        <AppButton onClick={getOTPCode} text="valider" />
      </div>
      {cardLoading && <LoadingDialog title={t('loading_single')} />}
      <OTPDialog
        open={openOTP}
        handle={setOpenOTP}
        submit={submitOTP}
        resend={getOTPCode}
      />
    </Grid>
  );
};

CardConfig.propTypes = {
  closeCardConfig: PropTypes.func,
  setOtp: PropTypes.func,
  setOpenOTP: PropTypes.func,
  openOTP: PropTypes.bool,
  virtual: PropTypes.bool,
  // users: PropTypes.array,
  cardId: PropTypes.number,
};

export default CardConfig;
