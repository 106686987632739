import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';

import { getUnixTime, startOfDay, isAfter } from 'date-fns';
import ValidateButton from './ValidateButton';
import CancelButton from './CancelButton';
import SendButton from './SendButton';
import ResendButton from './ResendButton';
import PayButton from './PayButton';
// import RejectButton from './RejectButton';
import SaveButton from './SaveButton';
import RefundButton from './RefundButton';

// hooks
import { useInvoiceFormContext } from '../../../../../hooks/invoiceHooks';
import RecoveryButton from './ResendButton/recoveryButton';

const Actions = ({ separatorStyle, horizontal, inv }) => {
  const {
    values: { state, type },
  } = useFormikContext();
  const { t } = useTranslation();

  const { invoiceId } = useInvoiceFormContext();

  const theinvoice = inv[invoiceId];
  let isOverdue = false;

  if (theinvoice !== undefined) {
    ((overdueinvoice) => {
      if (
        (overdueinvoice.type === 'invoice' ||
          overdueinvoice.type === 'pre_payment') &&
        overdueinvoice.state === 'validated'
      ) {
        const tmp = overdueinvoice.payments;
        let youngestDate;
        tmp.forEach((element) => {
          if (
            !youngestDate ||
            getUnixTime(youngestDate) < getUnixTime(element.date)
          ) {
            youngestDate = element.date;
          }
        });
        if (youngestDate) {
          if (isAfter(startOfDay(new Date()), new Date(youngestDate))) {
            isOverdue = true;
          }
        }
      }
    })(theinvoice);
  }

  return (
    <div
      style={
        horizontal
          ? {
              display: 'flex',
              justifyContent: 'space-evenly',
              width: '100%',
            }
          : { width: '100%' }
      }
    >
      <Tooltip
        arrow
        title={t(`invoices.tooltip.${type}.${state}.save`)}
        placement="top"
      >
        <span>
          <SaveButton horizontal={horizontal} />
        </span>
      </Tooltip>
      <Tooltip
        arrow
        title={t(`invoices.tooltip.${type}.${state}.validate`)}
        placement="top"
      >
        <span>
          <ValidateButton
            separatorStyle={separatorStyle}
            invoiceId={invoiceId}
            horizontal={horizontal}
          />
        </span>
      </Tooltip>
      <Tooltip
        arrow
        title={t(`invoices.tooltip.${type}.${state}.pay`)}
        placement="top"
      >
        <span>
          <PayButton
            separatorStyle={separatorStyle}
            invoiceId={invoiceId}
            horizontal={horizontal}
          />
        </span>
      </Tooltip>
      <Tooltip
        arrow
        title={t(`invoices.tooltip.${type}.${state}.refund`)}
        placement="top"
      >
        <span>
          <RefundButton
            separatorStyle={separatorStyle}
            invoiceId={invoiceId}
            horizontal={horizontal}
          />
        </span>
      </Tooltip>
      <Tooltip
        arrow
        title={t(`invoices.tooltip.${type}.${state}.send`)}
        placement="top"
      >
        <span>
          <SendButton
            separatorStyle={separatorStyle}
            invoiceId={invoiceId}
            horizontal={horizontal}
            inv={inv}
          />
        </span>
      </Tooltip>
      <Tooltip
        arrow
        title={t(`invoices.tooltip.${type}.${state}.resend`)}
        placement="top"
      >
        <span>
          <ResendButton
            separatorStyle={separatorStyle}
            horizontal={horizontal}
            isOverdue={isOverdue}
            reminders={theinvoice ? theinvoice.reminders : null}
          />
        </span>
      </Tooltip>

      <Tooltip
        arrow
        title={t(`invoices.tooltip.invoice.overdue.recovery`)}
        placement="top"
      >
        <span>
          <RecoveryButton
            separatorStyle={separatorStyle}
            horizontal={horizontal}
            isOverdue={isOverdue}
            reminders={theinvoice ? theinvoice.reminders : null}
          />
        </span>
      </Tooltip>

      <Tooltip
        arrow
        title={t(`invoices.tooltip.${type}.${state}.cancel`)}
        placement="top"
      >
        <span>
          <CancelButton
            separatorStyle={separatorStyle}
            invoiceId={invoiceId}
            horizontal={horizontal}
          />
        </span>
      </Tooltip>
      {/* <Tooltip
        arrow
        title={t(`invoices.tooltip.${type}.${state}.reject`)}
        placement="top"
      >
        <span>
          <RejectButton
            separatorStyle={separatorStyle}
            invoiceId={invoiceId}
            horizontal={horizontal}
          />
        </span>
      </Tooltip> */}
    </div>
  );
};

Actions.propTypes = {
  separatorStyle: PropTypes.string,
  horizontal: PropTypes.bool,
  inv: PropTypes.object,
};

Actions.defaultProps = {
  horizontal: false,
};

export default Actions;
