import { Clients as Rights } from '../../config/Rights';
import { Clients as Feature } from '../../config/Features';
import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreateClient = () => {
  return hasRequestedAccess(Rights.ClientsCreate);
};

export const userCanReadClient = () => {
  return hasRequestedAccess(Rights.ClientsRead);
};

export const userCanUpdateClient = () => {
  return hasRequestedAccess(Rights.ClientsUpdate);
};

export const userCanDeleteClient = () => {
  return hasRequestedAccess(Rights.ClientsDelete);
};

export const userHasClientAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
