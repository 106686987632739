// eslint-disable-next-line import/prefer-default-export
export const prepareData = (
  loggedUser,
  isUpdateConfig,
  values,
  wallet,
  treezorUsers,
  treezorUser,
  virtual,
  otp
) => {
  let currentTreezorUser;
  if (treezorUsers.length > 1) {
    [currentTreezorUser] = treezorUsers.filter(
      (curUser) => curUser.userTag === loggedUser.id
    );
  } else {
    currentTreezorUser = { ...treezorUser };
  }

  const bbfUserId = loggedUser._id;
  const { companyId } = treezorUser;

  const data = {
    walletId: wallet[0].walletId, // required
    userId: currentTreezorUser.userId, // required
    permsGroup: values.permsGroup || 'TRZ-CU-016', // required
    cardTag: bbfUserId,
    bbfUserId,
    companyId,
    embossLegalName: true,
  };

  // ATM limitation
  if (values.yearAtm) {
    data.limitAtmYear = values.yearAtm;
  }
  if (values.montlyAtm) {
    data.limitAtmMonth = values.montlyAtm;
  }
  if (values.weeklyAtm) {
    data.limitAtmWeek = values.weeklyAtm;
  }
  if (values.dailyAtm) {
    data.limitAtmDay = values.dailyAtm;
  }

  // Payment limitation
  if (values.yearPayment) {
    data.limitPaymentYear = values.yearPayment;
  }
  if (values.montlyPayment) {
    data.limitPaymentMonth = values.montlyPayment;
  }
  if (values.weeklyPayment) {
    data.limitPaymentWeek = values.weeklyPayment;
  }
  if (values.dailyPayment) {
    data.limitPaymentDay = values.dailyPayment;
  }

  // Handle uncheck the checkbox in card configuration
  if (isUpdateConfig) {
    // Payment limitation
    if (!values.limitPaymentDay) {
      data.limitPaymentDay = 0;
    }
    if (!values.limitPaymentWeek) {
      data.limitPaymentWeek = 0;
    }
    if (!values.limitPaymentMonth) {
      data.limitPaymentMonth = 0;
    }
    if (!values.limitPaymentYear) {
      data.limitPaymentYear = 0;
    }

    // ATM limitation
    if (!values.limitAtmDay) {
      data.limitAtmDay = 0;
    }
    if (!values.limitAtmMonth) {
      data.limitAtmMonth = 0;
    }
    if (!values.limitAtmWeek) {
      data.limitAtmWeek = 0;
    }
    if (!values.limitAtmYear) {
      data.limitAtmYear = 0;
    }

    // Card options
    if (!virtual) {
      data.nfc = values.nfc ? Number(values.nfc) : 0;
      data.atm = values.atm ? Number(values.atm) : 0;
    }
    data.online = values.onLine ? Number(values.onLine) : 0;
    data.foreign = values.foreign ? Number(values.foreign) : 0;
  }

  if (!virtual) {
    data.pin = values.pin;
  }
  data.otp = otp;
  return data;
};
