import { Grid } from '@material-ui/core';
import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import AppButton from 'components/AppButton';
import { OwnersContext, OwnersProvider } from './contexts/ownersList.context';
import OwnersList from './components/OwnersList';

const OwnerListContainer = () => {
  const owners = useContext(OwnersContext);

  return <OwnersList owners={owners} />;
};

const CompanyOwners = () => {
  const history = useHistory();
  return (
    <OwnersProvider>
      <Grid container direction="column" justify="center" alignItems="center">
        <AppButton text="Add Owner" onClick={() => history.push('addOwner')} />
        <Grid item xs={12} sm={6}>
          <OwnerListContainer />
        </Grid>
      </Grid>
    </OwnersProvider>
  );
};

export default CompanyOwners;
