import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// material-ui/core components
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import EditIcon from '@material-ui/icons/Edit';

const EditIconComp = (props) => {
  const { onClick, t } = props;

  return (
    <Tooltip title={t('forms.edit')} placement="top">
      <EditIcon
        onClick={onClick}
        style={{
          width: '0.7em ',
          height: '0.7em',
        }}
      />
    </Tooltip>
  );
};

EditIconComp.propTypes = {
  onClick: PropTypes.func.isRequired,

  t: PropTypes.func,
};

const EditIconWithStylesTranslated = withTranslation()(EditIconComp);

export default EditIconWithStylesTranslated;
