import { FETCH_COMPANY_SUBSCRIPTION_HISTORY } from 'actions/Types';
import subscriptionHistoryService from 'services/SubscriptionHistoryService';
import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

// eslint-disable-next-line import/prefer-default-export
export const fetchCompanySubscriptionHistory =
  () => async (dispatch, getState) => {
    try {
      const companyId = selectLoggedUserCompanyId(getState());

      if (!companyId) {
        dispatch({
          type: FETCH_COMPANY_SUBSCRIPTION_HISTORY,
          payload: [],
        });
        return;
      }
      const res = await subscriptionHistoryService.getSubscriptionHistory();
      dispatch({
        type: FETCH_COMPANY_SUBSCRIPTION_HISTORY,
        payload: res.data,
      });

      return;
    } catch (error) {
      // dispatch(setGlobalError(error));
      dispatch({ type: FETCH_COMPANY_SUBSCRIPTION_HISTORY, payload: [] });
    }
  };
