/* eslint-disable camelcase */
import React from 'react';
import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import PaymentTypes from './PaymentTypes';
import BankDetails from './BankDetails';

import { typeHelpers } from '../../../../helpers/invoiceHelpers';

const useStyles = makeStyles(() => ({
  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    marginTop: '30px',
  },
}));

const Payments = () => {
  const classes = useStyles();
  const {
    values: {
      type: invoiceType,
      company: { bank_details },
    },
  } = useFormikContext();

  if (typeHelpers.isPrePayment(invoiceType)) {
    return null;
  }

  return (
    <div className={classes.rowSpaceBetween}>
      <PaymentTypes />
      <BankDetails invoiceBankDetail={bank_details} />
    </div>
  );
};

export default Payments;
