import { RESIZE_SCREEN } from '../../actions/Types';

export default (state = { isCollapse: true }, action) => {
  switch (action.type) {
    case RESIZE_SCREEN: {
      return { ...state, isCollapse: action.isCollapse };
    }
    default:
      return state;
  }
};
