import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import AppButton from 'components/AppButton';
import { useSelector } from 'react-redux';
import OcrService from 'services/OcrService';
import logger from 'helpers/logger';
import spinnerService from 'services/SpinnerService';
import UserService from 'services/UserService';
import { CACHE_TYPE } from 'constants/subscriptions';

const { default: AppDialog } = require('components/AppDialog');
const { useTranslation } = require('react-i18next');

const useStyles = makeStyles(() => ({
  boxAction: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
}));

const AddUserFormDialog = ({
  showDialog,
  closeAddUserFormDialog,
  inviteFormData,
}) => {
  const [confirmChecker, setConfirmChecker] = useState(false);
  const { t } = useTranslation();
  const { _id: userId } = useSelector((state) => state.loggedUser.user);
  const classes = useStyles();
  const {
    company: { _id: companyId },
  } = useSelector((state) => state.loggedUserCompany);

  const onSubmit = async () => {
    try {
      spinnerService.startSpinner();
      const roles = {
        addRoles: inviteFormData.role,
        removeRoles: [],
      };

      const {
        data: { _id: cacheId },
      } = await OcrService.cacheData(
        userId,
        {
          ...inviteFormData,
          roles,
          company_id: companyId,
        },
        undefined,
        undefined,
        CACHE_TYPE.INVITE_USER
      );

      const {
        data: { url },
      } = await UserService.buyUserQuota({
        quantity: 1,
        cacheId,
      });
      window.open(url, '_blank');
      closeAddUserFormDialog();
    } catch (error) {
      logger.log({ error });
    } finally {
      spinnerService.endSpinner();
    }
  };

  if (showDialog) {
    return (
      <AppDialog
        title={t('settings_section.users.add_user_title')}
        closeDialog={closeAddUserFormDialog}
        onConfirmType="submit"
        onConfirmText="submit"
        iconClose
        color="secondary"
      >
        <div className="section p-10">
          <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
            <div>{t('settings_section.users.policy_description_1')}</div>
            <div>{t('settings_section.users.policy_description_2')}</div>

            <FormControlLabel
              style={{
                paddingBottom: '30px',
              }}
              label={t('settings_section.users.confirm_message')}
              control={
                <Checkbox
                  onChange={(e) => setConfirmChecker(e.target.checked)}
                  name="confirmChecker"
                />
              }
            />
            <Grid item xs={12}>
              <div className={classes.boxAction}>
                {closeAddUserFormDialog && (
                  <AppButton
                    color="transparentGrey"
                    text={t('settings_section.users.add_user_cancel')}
                    onClick={closeAddUserFormDialog}
                  />
                )}

                <AppButton
                  onClick={onSubmit}
                  color="secondaryLight"
                  text={t('settings_section.users.add_user_confirm')}
                  noBorder={true}
                  type="button"
                  isDisabled={!confirmChecker}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </AppDialog>
    );
  }

  return null;
};

AddUserFormDialog.propTypes = {
  showDialog: PropTypes.bool,
  closeAddUserFormDialog: PropTypes.func,
  inviteFormData: PropTypes.object,
};

export default AddUserFormDialog;
