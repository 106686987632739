import { bindActionCreators } from 'redux';
import { fetchCompanySubscription } from 'actions/CompanySubscription';
import {
  createUser,
  updateUser,
  fetchCompanyUsers,
} from '../../../actions/UserActions';
import { changeLanguage } from '../../../actions/LanguageActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createUser,
      updateUser,
      changeLanguage,
      fetchCompanyUsers,
      fetchCompanySubscription,
    },
    dispatch
  );
