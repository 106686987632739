import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';
import OwnerForm from './OwnerForm';
import {
  DispatchContext,
  OwnersProvider,
} from '../contexts/ownersList.context';
import { ADD_OWNER } from '../constants/actions';

const validation = Yup.object().shape({
  name: Yup.string().required(),
  company: Yup.bool().required(),
});

const FormikContainer = () => {
  const dispatch = useContext(DispatchContext);
  const history = useHistory();
  const location = useLocation();

  let parentId;
  if (location.state) parentId = location.state.parentId;
  return (
    <Formik
      validationSchema={validation}
      initialValues={{ name: '', company: false, share: 50 }}
      onSubmit={(values) => {
        dispatch({
          type: ADD_OWNER,
          payload: parentId ? { ...values, parentId } : values,
        });
        history.goBack();
      }}
    >
      <OwnerForm />
    </Formik>
  );
};

const AddOwnerContainer = () => {
  return (
    <OwnersProvider>
      <FormikContainer />
    </OwnersProvider>
  );
};

AddOwnerContainer.propTypes = {
  onSubmit: PropTypes.func,
};

export default AddOwnerContainer;
