import _ from 'lodash';

import {
  CREATE_PROVIDER,
  FETCH_PROVIDERS,
  UPDATE_PROVIDER,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  UPDATE_PROVIDER_BENEFICIARY,
} from '../../actions/Types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_PROVIDERS: {
      return _.mapKeys(action.payload, '_id');
    }
    case CREATE_PROVIDER: {
      return { ...state, [action.payload._id]: action.payload };
    }
    case UPDATE_PROVIDER: {
      return {
        ...state,
        [action.payload._id]: {
          ...state[action.payload._id],
          ...action.payload,
        },
      };
    }
    case UPDATE_PROVIDER_BENEFICIARY: {
      return {
        ...state,
        [action.payload.providerOId]: {
          ...state[action.payload.providerOId],
          beneficiaryId: action.payload.beneficiaryId,
        },
      };
    }
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return {};
    default:
      return state;
  }
};
