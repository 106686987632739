import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchInvoices = async (filter) =>
  axios.get(`${process.env.REACT_APP_INVOICES_URL}/invoices`, {
    params: filter,
  });

const fetchInvoicePaginate = async (qs) =>
  axios.get(`${process.env.REACT_APP_INVOICES_URL}/invoices?${qs}`);
// axios.get(`http://localhost:3001/dev/v1/invoices?${qs}`);
const fetchInvoice = async (id) =>
  axios.get(`${process.env.REACT_APP_INVOICES_URL}/invoices/${id}`);
// axios.get(`http://localhost:3001/dev/v1/invoices/${id}`);

const createInvoice = async (data) =>
  axios.post(`${process.env.REACT_APP_INVOICES_URL}/invoices`, data);
// axios.post(`http://localhost:3001/dev/v1/invoices`, data);

const updateInvoice = async (id, data) =>
  axios.put(`${process.env.REACT_APP_INVOICES_URL}/invoices/${id}`, data);

const validateInvoice = async (id, emailContent) => {
  return axios.post(
    `${process.env.REACT_APP_INVOICES_URL}/invoices/${id}/validate`,
    // `http://localhost:3001/dev/v1/invoices/${id}/validate`,
    {
      emailContent,
    }
  );
};

const cancelInvoice = async (id, emailContent) =>
  axios.post(`${process.env.REACT_APP_INVOICES_URL}/invoices/${id}/cancel`, {
    emailContent,
  });

const sendInvoice = async (id, emailContent) =>
  axios.post(`${process.env.REACT_APP_INVOICES_URL}/invoices/${id}/send`, {
    emailContent,
  });

const resendInvoice = async (id, emailContent) =>
  axios.post(`${process.env.REACT_APP_INVOICES_URL}/invoices/${id}/resend`, {
    emailContent,
  });

const payInvoice = async (id, transaction) =>
  axios.post(`${process.env.REACT_APP_INVOICES_URL}/invoices/${id}/pay`, {
    transaction,
  });

const refundInvoice = async (id) =>
  axios.post(`${process.env.REACT_APP_INVOICES_URL}/invoices/${id}/refund`);

const deleteInvoice = async (id) =>
  axios.delete(`${process.env.REACT_APP_INVOICES_URL}/invoices/${id}`);

const getInvoicePDF = async (invoice) =>
  axios.post(`${process.env.REACT_APP_PDF_PREVIEW}/previewPdf`, invoice, {
    responseType: 'arraybuffer',
    headers: {
      Accept: 'application/pdf',
    },
  });
// axios.post(`http://localhost:3000/v1/previewPdf`, invoice, {
//   responseType: 'arraybuffer',
//   headers: {
//     Accept: 'application/pdf',
//   },
// });

const fetchTurnOver = (endFiscalYear) =>
  axios.get(
    `${process.env.REACT_APP_INVOICES_URL}/invoices/turnovers?endFiscalYear=${endFiscalYear}`
  );

const clientSignInvoice = async (id, clientId, file) => {
  const formData = new FormData();
  formData.append('file', file);
  return axios.post(
    `${process.env.REACT_APP_INVOICES_URL}/clients/${clientId}/invoices/${id}/sign`,
    formData,
    {
      headers: {
        'content-type': 'multipart/form-data',
      },
    }
  );
};

const fetchClientInvoice = (id, clientId) => {
  return axios.get(
    `${process.env.REACT_APP_INVOICES_URL}/clients/${clientId}/invoices/${id}`
  );
};

const clientValidateInvoice = async (id, clientId) =>
  axios.post(
    `${process.env.REACT_APP_INVOICES_URL}/clients/${clientId}/invoices/${id}/validate`
  );

const clientCancelInvoice = async (id, clientId, emailContent) =>
  axios.put(
    `${process.env.REACT_APP_INVOICES_URL}/clients/${clientId}/invoices/${id}/cancel`,
    { emailContent }
  );

const getAgingBalance = async () =>
  axios.get(`${process.env.REACT_APP_INVOICES_URL}/agingBalance`);

const getAgingBalanceOverdue = async () =>
  axios.get(`${process.env.REACT_APP_INVOICES_URL}/agingBalanceOverdue`);

const createPaymentLink = async (data, userId) =>
  axios.post(
    `${process.env.REACT_APP_BRIDGE_URL}/request-to-pay?userId=${userId}`,
    data
  );

const createPaymentLinkToUsePaymentLink = async (
  invoiceId,
  total = 1,
  request = 1
) =>
  axios.post(`${process.env.REACT_APP_STRIPE_URL}/pay-payment-link`, {
    invoiceId,
    total: total.toString(),
    request: request.toString(),
  });

const checkPayToPaymentLinkStatus = async (paymentLinkId) =>
  axios.get(
    `${process.env.REACT_APP_STRIPE_URL}/pay-payment-link/${paymentLinkId}`
  );

const checkPaymentLinkExprided = async (paymentLinkId) =>
  axios.get(`${process.env.REACT_APP_BRIDGE_URL}/details/${paymentLinkId}`);

const downloadInvoice = async (invoiceIds) =>
  axios.post(
    `${process.env.REACT_APP_API_URL_NO_VERSION}/invoice/download`,
    {
      invoiceIds,
    },
    { responseType: 'blob' }
  );

export default {
  fetchInvoices,
  fetchInvoice,
  fetchInvoicePaginate,

  createInvoice,
  updateInvoice,
  deleteInvoice,

  validateInvoice,
  cancelInvoice,
  payInvoice,
  sendInvoice,
  resendInvoice,
  refundInvoice,

  getInvoicePDF,
  fetchTurnOver,

  fetchClientInvoice,
  clientSignInvoice,
  clientValidateInvoice,
  clientCancelInvoice,

  getAgingBalance,
  getAgingBalanceOverdue,

  createPaymentLink,
  createPaymentLinkToUsePaymentLink,
  checkPayToPaymentLinkStatus,
  checkPaymentLinkExprided,
  downloadInvoice,
};
