/* eslint-disable-next-line */
export default ({ id, activities }) => {
  if (id) {
    return activities[id];
  }

  return {
    value: '',
    display: '',
    description: '',
  };
};
