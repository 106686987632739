/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import MaterialTable from 'components/MaterialTable';
import { Box, Button, Typography } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';

import { getChequePayins } from 'services/PayinService';
import { DEFAULT_PAGESIZE } from '../../../../../config/Constants';

const BankBBFChequeTable = ({ treezorWallet, openDepositCreateHandler }) => {
  const { t } = useTranslation();

  const [refresh, setRefresh] = useState(false);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGESIZE);
  const tableRef = React.createRef();

  function fetch() {
    return tableRef.current && tableRef.current.onQueryChange();
  }

  const renderStatus = (status) => {
    if (status === 'PENDING') {
      return (
        <div
          style={{
            borderRadius: '50%',
            width: '5px',
            height: '5px',
            background: 'orange',
          }}
        ></div>
      );
    }
    if (status === 'VALIDATED') {
      return (
        <div
          style={{
            borderRadius: '50%',
            width: '5px',
            height: '5px',
            background: 'green',
          }}
        ></div>
      );
    }
    return (
      <div
        style={{
          borderRadius: '50%',
          width: '5px',
          height: '5px',
          background: 'red',
        }}
      ></div>
    );
  };

  useEffect(() => {
    if (refresh) {
      fetch();
    }
  }, [refresh]);

  const columns = [
    {
      title: t('cheque.table_cheque_deposit_headers.transmitter'),
      field: 'sequence_nb',
      options: {
        filter: false,
      },
      render: (value) => <div>{value.userFirstname}</div>,
    },
    {
      title: t('cheque.table_cheque_deposit_headers.date_deposit'),
      field: 'date_emission',
      render: (value) => (
        <div>{format(new Date(value.createdDate), 'dd/MM/yyyy')}</div>
      ),
    },
    {
      title: t('cheque.table_cheque_deposit_headers.amount'),
      field: 'category_id',
      render: (value) => <div>{value.amount}</div>,
    },
    {
      title: t('cheque.table_cheque_deposit_headers.status'),
      field: 'grand_total_amount',
      render: (value) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ marginRight: '10px' }}>
            {value.informationStatus || 'Done'}
          </div>
          <>{renderStatus(value.payinStatus)}</>
        </div>
      ),
    },
  ];

  return (
    <>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginBottom: '15px',
        }}
      >
        <Typography variant="h4">
          {t('cheque.table_cheque_deposit_title')}
        </Typography>
        <Button
          variant="contained"
          color="primary"
          onClick={openDepositCreateHandler}
        >
          {t('cheque.deposit_check')}
        </Button>
      </Box>
      <Box>
        <MaterialTable
          title="Cheques"
          tableRef={tableRef}
          columns={columns}
          data={(query) =>
            new Promise((resolve) => {
              const { pageSize: limit, page } = query;
              const queryString = `page=${page + 1}&limit=${limit}`;

              getChequePayins(treezorWallet.walletId, queryString).then(
                (res) => {
                  setRefresh(false);
                  resolve({
                    data: res.data.docs || [],
                    totalCount: res.data.totalDocs,
                    page: res.data.page - 1,
                  });
                }
              );
            })
          }
          onChangeRowsPerPage={(ps) => {
            setPageSize(ps);
          }}
          options={{
            filtering: false,
            pageSize,
            search: true,
            showTitle: false,
          }}
          actions={[
            {
              icon: () => <Refresh />,
              isFreeAction: true,
              onClick: () => setRefresh(true),
            },
          ]}
        />
      </Box>
    </>
  );
};

BankBBFChequeTable.propTypes = {
  refreshing: PropTypes.bool,
  treezorWallet: PropTypes.object,
  openDepositCreateHandler: PropTypes.func,
};

export default BankBBFChequeTable;
