/*eslint-disable */
import React, { Fragment, useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import ItemsTable from './ItemsTable';
import ItemList from '../../../Item/ListDropdown';

import { typeHelpers } from '../../../../helpers/invoiceHelpers';

const useStyles = makeStyles(() => ({
  marginSM: {
    marginTop: '7px',
  },
}));

const Items = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const items = useSelector((state) => state.items);
  const [selectedItems, setSelectedItems] = useState([]);
  // const [itemMessage, setItemMessage] = useState('');
  // console.log(
  //   '🚀 ~ file: index.js ~ line 27 ~ Items ~ itemMessage',
  //   itemMessage
  // );
  const {
    values: { items: invoiceItems, type: invoiceType, itemMessage },
    setFieldValue,
    errors,
    touched,
  } = useFormikContext();

  const renderMessageAfterChooseArticle = () => {
    const typeList = invoiceItems.map((item) => item.type);

    let mess = '';
    if (!typeList.length) {
      // setItemMessage(mess);
      setFieldValue('itemMessage', mess);
      return;
    }

    if (
      (typeList.includes('M') || typeList.includes('P')) &&
      (typeList.includes('S') || typeList.includes('T'))
    ) {
      mess =
        'Cette facture se rapporte à la fois à des livraisons de biens et à des prestations de services.​';
    }

    if (!typeList.includes('M') && !typeList.includes('P')) {
      mess =
        'Cette facture se rapporte exclusivement à des prestations de services.​';
    }

    if (!typeList.includes('S') && !typeList.includes('T')) {
      mess =
        'Cette facture se rapporte exclusivement à des livraisons de biens.​​';
    }

    setFieldValue('itemMessage', mess);
  };

  useEffect(() => {
    renderMessageAfterChooseArticle();
  }, [invoiceItems]);

  function onItemSelected(data) {
    setSelectedItems(data);
  }

  function emptySelectedItems() {
    setSelectedItems([]);
  }

  function onItemConfirm() {
    const newItems = selectedItems.map((id) => {
      const item = items[id];
      const { net_price: netPrice, gross_price: grossPrice } = item;
      const quantity = typeHelpers.isCreditNote(invoiceType) ? -1 : 1;
      return {
        ...item,
        discount: 0,
        quantity,
        unit: t(item.unit),
        unit_price: netPrice,
        net_price: parseFloat(netPrice) * quantity,
        gross_price: parseFloat(grossPrice) * quantity,
        vat_price: parseFloat(grossPrice) - parseFloat(netPrice),
      };
    });
    emptySelectedItems();
    setFieldValue('items', [...invoiceItems, ...newItems]);
  }

  if (typeHelpers.isPrePayment(invoiceType)) {
    return null;
  }

  return (
    <Fragment>
      <ItemsTable />
      <div className={classes.marginSM} />
      <ItemList
        onSelect={onItemSelected}
        closeDialog={emptySelectedItems}
        selectedItems={items}
        onConfirm={onItemConfirm}
        confirmText="Ok"
        showCheckboxes
        showError={errors.items && touched.items}
        errorMessage={errors.items}
        invoiceItems={invoiceItems}
      />
    </Fragment>
  );
};

export default Items;
