import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import cl from 'classnames';

const useStyles = makeStyles(() => ({
  NoneValidRecord: {
    color: 'red',
    marginLeft: 20,
  },
}));
const ValidationWarning = ({
  message = 'none_valid_record',
  className = '',
  ...rest
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={cl(classes.NoneValidRecord, className)} {...rest}>
      {t(message)}
    </div>
  );
};

ValidationWarning.propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

export default ValidationWarning;
