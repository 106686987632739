import _ from 'lodash';

import {
  FETCHING_CLIENTS,
  FETCH_CLIENTS,
  FETCH_CLIENTS_FAILED,
  CREATE_CLIENT,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  UPDATE_CLIENT_BENEFICIARY,
} from '../../actions/Types';

const INITIAL_STATE = {
  data: {},
  isFetchingClients: false,
  errorFetchingClients: null,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCHING_CLIENTS:
      return {
        ...state,
        isFetchingClients: true,
        errorFetchingClients: null,
      };
    case FETCH_CLIENTS:
      return {
        ...state,
        data: _.mapKeys(action.payload, '_id'),
        isFetchingClients: false,
        errorFetchingClients: null,
      };
    case CREATE_CLIENT:
      return {
        ...state,
        data: { ...state.data, [action.payload._id]: action.payload },
        isFetchingClients: false,
        errorFetchingClients: null,
      };
    case UPDATE_CLIENT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.id]: {
            ...state.data[action.payload.id],
            ...action.payload.client,
          },
        },
        isFetchingClients: false,
        errorFetchingClients: null,
      };
    case UPDATE_CLIENT_BENEFICIARY: {
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.clientOId]: {
            ...state[action.payload.clientOId],
            beneficiaryId: action.payload.beneficiaryId,
          },
        },
        isFetchingClients: false,
        errorFetchingClients: null,
      };
    }
    case DELETE_CLIENT:
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload]: {
            ...state.data[action.payload],
            deleted: true,
          },
        },
      };
    case FETCH_CLIENTS_FAILED:
      return {
        ...state,
        data: {},
        isFetchingClients: false,
        errorFetchingClients: action.payload,
      };
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return INITIAL_STATE;
    default:
      return state;
  }
};
