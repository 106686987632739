import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import formatUTCDate from 'helpers/dateHelper';
import { TIME_FORMAT } from 'constants/date';

const styles = {
  inputLablel: {
    color: '#1a1a1a',
    fontWeight: '500',
  },
  textField: {
    marginBottom: '20px',
  },
  textFieldValue: {
    color: '#1a1a1a',
  },
  leftGroup: {
    paddingRight: '10px',
  },
  rightGroup: {
    paddingLeft: '10px',
  },
  title: {
    marginBottom: '20px',
  },
  container: {
    padding: '10px',
  },
};

const SubscriptionInfo = () => {
  const { t } = useTranslation();
  const companySubscription = useSelector(
    (state) => state.companySubscription.data || {}
  );
  return (
    <Grid container spacing={2} direction="row" style={styles.container}>
      <Typography style={styles.title} variant="h4">
        {t('subscription.form.title')}
      </Typography>
      <Grid container spacing={2} direction="row">
        <Grid item xs={6} style={styles.leftGroup}>
          <TextField
            style={styles.textField}
            inputProps={{
              style: styles.textFieldValue,
            }}
            InputLabelProps={{ style: styles.inputLablel }}
            variant="outlined"
            type="text"
            name={`current_subscription`}
            label={t('subscription.form.current_subscription')}
            color="secondary"
            fullWidth
            value={companySubscription.name || '-'}
            disabled
          />
          <TextField
            style={styles.textField}
            inputProps={{
              style: styles.textFieldValue,
            }}
            InputLabelProps={{ style: styles.inputLablel }}
            variant="outlined"
            type="text"
            name={`start_date`}
            label={t('subscription.form.start_date')}
            color="secondary"
            fullWidth
            value={
              companySubscription.startDate
                ? new Date(companySubscription.startDate).toLocaleDateString(
                    'en-GB'
                  )
                : '-'
            }
            disabled
          />
          <TextField
            style={styles.textField}
            inputProps={{
              style: styles.textFieldValue,
            }}
            InputLabelProps={{ style: styles.inputLablel }}
            variant="outlined"
            type="text"
            name={`trialStartDate`}
            label={t('subscription.form.trial_start_date')}
            color="secondary"
            fullWidth
            value={
              companySubscription.trialStartDate
                ? new Date(
                    companySubscription.trialStartDate
                  ).toLocaleDateString('en-GB')
                : '-'
            }
            disabled
          />
        </Grid>
        <Grid item xs={6} style={styles.rightGroup}>
          <TextField
            style={styles.textField}
            inputProps={{
              style: styles.textFieldValue,
            }}
            InputLabelProps={{ style: styles.inputLablel }}
            variant="outlined"
            type="text"
            name={`subscription_type`}
            label={t('subscription.form.subscription_type')}
            color="secondary"
            fullWidth
            value={
              companySubscription?.interval &&
              companySubscription?.interval !== '-'
                ? t(`subscription.form.${companySubscription.interval}`)
                : '-'
            }
            disabled
          />
          <TextField
            style={styles.textField}
            inputProps={{
              style: styles.textFieldValue,
            }}
            InputLabelProps={{ style: styles.inputLablel }}
            variant="outlined"
            type="text"
            name={`renewal_date`}
            label={t('subscription.form.renewal_date')}
            color="secondary"
            fullWidth
            value={
              companySubscription.endDate
                ? formatUTCDate(companySubscription.endDate, TIME_FORMAT.D_M_Y)
                : '-'
            }
            disabled
          />
          <TextField
            style={styles.textField}
            inputProps={{
              style: styles.textFieldValue,
            }}
            InputLabelProps={{ style: styles.inputLablel }}
            variant="outlined"
            type="text"
            name={`trialEndDate`}
            label={t('subscription.form.trial_end_date')}
            color="secondary"
            fullWidth
            value={
              companySubscription.trialEndDate
                ? formatUTCDate(
                    companySubscription.trialEndDate,
                    TIME_FORMAT.D_M_Y
                  )
                : '-'
            }
            disabled
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SubscriptionInfo;
