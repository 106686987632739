/*
 * Nov 2019: this component should now be used by default for ALL input fields inside the App
 * It is wired via hooks to the Formik context and leverages Material UI
 *
 */

// @ts-ignore
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

// @material-ui/core
import { TextField as MaterialTextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import IconButton from '@material-ui/core/IconButton';

// other components
import ErrorMessage from '../ErrorMessage';
// helper function
import { initTextFieldValue } from '../../helpers/textFieldHelper';

const TextField = ({
  name: nameAsProp,
  disabled = false,
  onChange,
  onBlur,
  withPasswordVisibility,
  inputStyle,
  ...rest
}) => {
  // this allows us to use the component either as <TextField name=.. /> or <Field name="" component={TextField} />
  const name = nameAsProp || (rest.field && rest.field.name);

  const [field, meta] = useField(name);

  const { onChange: handleChange, onBlur: handleBlur, ...restField } = field; // TODO : here isn't it handleChange from the formik bag instead of the onChange from field?
  const { error, value, touched } = meta;

  // determine which value to display, the prop or Formik
  const inputValue = initTextFieldValue(rest.value, value);

  // state to handle the case where we use this as a password field, where the password can be shown or not
  const [showPassword, setShowPassword] = useState(false);

  function getFieldType() {
    // TODO : use the rest.type = password instead of new prop : withPasswordVisibility
    // if the prop withPasswordVisibility is not set, we use the type sent as prop, or we default to text
    if (!withPasswordVisibility) {
      return rest.type || 'text';
    }

    // how we check the local state
    if (showPassword) {
      return 'text';
    }

    return 'password';
  }
  const handleInputLabelProps = () => {
    // Fix value overlap placeholder
    if (value) {
      return {
        shrink: true,
      };
    }
    return {};
  };
  return (
    <React.Fragment>
      <MaterialTextField
        fullWidth // defaults to fullWidth unless it's overrided by a prop
        margin="dense"
        variant="outlined"
        {...rest}
        {...restField}
        autoComplete="off"
        type={getFieldType()}
        value={inputValue}
        onChange={onChange || handleChange}
        disabled={disabled}
        onBlur={onBlur || handleBlur}
        error={error && touched}
        InputLabelProps={handleInputLabelProps()}
        InputProps={{
          inputProps: {
            style: inputStyle,
          },
          endAdornment: withPasswordVisibility ? (
            <InputAdornment position="end">
              <IconButton
                style={{ textAlign: 'right' }}
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                onMouseDown={() => setShowPassword(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          ) : null,
        }}
      />
      <ErrorMessage showError={error && touched} error={error} />
    </React.Fragment>
  );
};

TextField.displayName = 'FormikMaterialUITextField';

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  field: PropTypes.object,
  form: PropTypes.object,
  disabled: PropTypes.bool,
  checked: PropTypes.object,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  withPasswordVisibility: PropTypes.bool,
  inputStyle: PropTypes.object,
};

TextField.defaultProps = {
  withPasswordVisibility: false,
};
export default TextField;
