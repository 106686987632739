import TreezorService from 'services/TreezorService';

export const createNewSEPARequest = (data) =>
  TreezorService.createNewSEPARequest({ ...data });

export const updateSEPARequest = (data) =>
  TreezorService.updateSEPARequest({ ...data });

export const fetchSEPARequest = (requestOId) =>
  TreezorService.getSEPARequest(requestOId);

export const getSEPARequests = (query) => TreezorService.getSEPARequests(query);
