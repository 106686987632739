import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appTableComponents';

const AppTableRow = ({ classes, children, header, greyHeader, useStyle }) => {
  const tableRowClasses = classNames({
    // [classes.tableRow]: true,
    [classes.header]: header,
  });

  const childrenWithProps = React.Children.map(
    React.Children.toArray(children).filter((o) => o),
    (child) =>
      React.cloneElement(child, {
        header,
        greyHeader,
        useStyle,
      })
  );

  return header ? (
    <tr className={tableRowClasses} style={useStyle}>
      {childrenWithProps}
    </tr>
  ) : (
    <tr className={tableRowClasses} style={useStyle}>
      {childrenWithProps}
    </tr>
  );
};
AppTableRow.propTypes = {
  classes: PropTypes.object.isRequired,
  useStyle: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  header: PropTypes.bool,
  greyHeader: PropTypes.bool,
};
AppTableRow.defaultProps = {
  header: false,
  useStyle: null,
  greyHeader: false,
};

export default withStyles(style)(AppTableRow);
