import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import Layout from 'components/Layout';

import { useThunkDispatch } from 'hooks/generalHooks';
import { userCanReadInvoice } from 'selectors/rightsSelector/invoiceRequests';
import { fetchInvoices } from 'actions/InvoiceActions';
import { fetchRevenues } from 'actions/RevenueActions';

import InvoiceListHeader from './InvoiceListHeader';
import InvoiceListSideBar from './InvoiceListSideBar';
import Body from './Body';

const InvoicesList = ({ setScreenName }) => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();

  const { _id: companyId, settings: companySettings } = useSelector(
    (state) => state.loggedUserCompany.company
  );

  const fetchedAt = useSelector((state) => state.timeStamps.invoices);

  const canReadInvoice = useSelector(userCanReadInvoice);

  const [refreshing, setRefreshing] = useState(false);

  function toggleRefreshing() {
    setRefreshing((prev) => !prev);
  }

  const fetchInvoicesCb = useCallback(
    () => dispatch(fetchInvoices(toggleRefreshing)),
    [dispatch]
  );
  const fetchRevenuesCb = useCallback(
    () => dispatch(fetchRevenues(toggleRefreshing)),
    [dispatch]
  );

  useEffect(() => {
    setScreenName(t('invoices.title'));
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (canReadInvoice && (!fetchedAt || Date.now() - fetchedAt > 900000)) {
      // toggleRefreshing();
      // fetchInvoicesCb();
      // TODO : use a dedicated use effect with revenues state timestamp
      // fetchRevenuesCb();
    }
  }, [companyId, fetchInvoicesCb, fetchRevenuesCb]);

  return (
    <Layout
      header={
        <InvoiceListHeader
          companySettings={companySettings}
          refreshing={refreshing}
          canReadInvoice={canReadInvoice}
          toggleRefresh={toggleRefreshing}
          fetchInvoicesCb={fetchInvoicesCb}
          fetchRevenuesCb={fetchRevenuesCb}
        />
      }
      sidebarLeft={true}
      sidebarRight={<InvoiceListSideBar />}
      body={<Body canReadInvoice={canReadInvoice} refreshing={refreshing} />}
      showUserCard={true}
    />
  );
};

InvoicesList.propTypes = {
  setScreenName: PropTypes.func,
  location: PropTypes.object,
};

export default InvoicesList;
