import { Invoice as Rights } from '../../config/Rights';

import { Invoice as Feature } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreateInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceCreate);
};

export const userCanReadInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceRead);
};

export const userCanUpdateInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceUpdate);
};

export const userCanCancelInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceCancel);
};

export const userCanValidateInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceValidate);
};

export const userCanResendInvoice = () => {
  return hasRequestedAccess(Rights.InvoiceValidate);
};

export const userHasInvoiceAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
