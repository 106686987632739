import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import TransactionDialog from 'components/TransactionDialog';
import { TRANSACTION_TYPE } from 'constants/banks';
import AppButton from '../../../../../../components/AppButton';
// import AppDialog from '../../../../../../components/AppDialog';

import { useDialog } from '../../../../../../hooks/dialogHooks';

import { invoiceButtons } from '../../../../../../helpers/invoiceHelpers';

const PayButton = ({ separatorStyle, invoiceId, horizontal }) => {
  const { showDialog: showApproveDialog, toggleDialog: toggleApprovalDialog } =
    useDialog();

  const { t } = useTranslation();
  const { values, isSubmitting, setFieldValue } = useFormikContext();

  const { type } = values;

  function pay() {
    toggleApprovalDialog();
    setFieldValue('action', 'pay', false);
  }

  function renderDialog() {
    if (showApproveDialog) {
      return (
        // <AppDialog
        //   sm
        //   iconClose
        //   footer
        //   title={t('sure')}
        //   closeDialog={toggleApprovalDialog}
        //   onConfirmText={t('yes')}
        //   onCancelText={t('no')}
        //   onConfirm={pay}
        //   contentText={t('invoice.confirm.paid')}
        //   color="secondary"
        // />
        <TransactionDialog
          toggleDialog={toggleApprovalDialog}
          onSubmit={(transaction) => {
            setFieldValue('transaction', transaction);
            pay();
          }}
          type={TRANSACTION_TYPE.CREDIT}
          defaultAmount={values.duePayableAmount || values.gross_total}
          documentId={values?._id}
        />
      );
    }

    return null;
  }

  if (!invoiceButtons[type].canPay(values) || !invoiceId) {
    return null;
  }

  return (
    <Fragment>
      {renderDialog()}
      <AppButton
        fullWidth={!horizontal}
        isDisabled={isSubmitting}
        text={t(`transaction.reconcile`)}
        onClick={() => {
          toggleApprovalDialog();
        }}
      />
      <div className={separatorStyle} />
    </Fragment>
  );
};

PayButton.propTypes = {
  separatorStyle: PropTypes.string,
  invoiceId: PropTypes.string,
  horizontal: PropTypes.bool,
};

PayButton.defaultProps = {
  horizontal: false,
};

export default PayButton;
