/* Packages */
import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider, Typography } from '@material-ui/core';
import { format } from 'date-fns';

/* Styles */
import useStyles from './styles';

const BankBBFTableDate = (props) => {
  const { createdDate } = props;
  const classnames = useStyles();

  return (
    <Box className={classnames.tableDateWrapper}>
      <Typography className={classnames.tableDate}>
        {format(new Date(createdDate), 'dd')}
      </Typography>
      <Box>
        <Typography className={classnames.tableMonthYear}>
          {format(new Date(createdDate), 'MMM')}
        </Typography>
        <Typography className={classnames.tableMonthYear}>
          {format(new Date(createdDate), 'yyyy')}
        </Typography>
      </Box>
      <Divider orientation="vertical" flexItem />
      <Typography className={classnames.tableMonthYear}>
        {format(new Date(createdDate), 'HH:mm')}
      </Typography>
    </Box>
  );
};

BankBBFTableDate.propTypes = {
  createdDate: PropTypes.string,
};

export default BankBBFTableDate;
