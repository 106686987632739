import {
  getEmailQuotation as getEmailQuotationSample,
  getEmailOrderForm as getEmailOrderFormSample,
  getEmailDefaultResend as getEmailDefaultResendSample,
  getEmailDefault as getEmailDefaultSample,
  getEmailCreditNote as getEmailCreditNoteSample,
  getEmailInvoice,
} from './EmailHelper';

export const INVOICE_TYPE = {
  QUOTATION: 'quotation',
  ORDER_FORM: 'order_form',
  DELIVERY_FORM: 'delivery_form',
  INVOICE: 'invoice',
  CREDIT_NOTE: 'credit_note',
  PRE_PAYMENT: 'pre_payment',
};

export const INVOICE_STATE = {
  DRAFT: 'draft',
  PENDING: 'pending',
  CANCELED: 'canceled',
  REJECTED: 'rejected',
  VALIDATED: 'validated',
  PAID: 'paid',
};

export const stateHelpers = {
  isValidated: (state) => state === INVOICE_STATE.VALIDATED,
  isPending: (state) => state === INVOICE_STATE.PENDING,
  isDraft: (state) => state === INVOICE_STATE.DRAFT,
  isCanceled: (state) =>
    state === INVOICE_STATE.CANCELED || state === INVOICE_STATE.REJECTED,
  isPaid: (state) => state === INVOICE_STATE.PAID,
  isRejected: (state) => state === INVOICE_STATE.REJECTED,
};

export const typeHelpers = {
  isQuotation: (type) => type === INVOICE_TYPE.QUOTATION,
  isOrderForm: (type) => type === INVOICE_TYPE.ORDER_FORM,
  isDeliveryForm: (type) => type === INVOICE_TYPE.DELIVERY_FORM,
  isInvoice: (type) => type === INVOICE_TYPE.INVOICE,
  isCreditNote: (type) => type === INVOICE_TYPE.CREDIT_NOTE,
  isPrePayment: (type) => type === INVOICE_TYPE.PRE_PAYMENT,
};

export const invoiceButtons = {
  [INVOICE_TYPE.QUOTATION]: {
    canSend: (invoice) => stateHelpers.isDraft(invoice.state),
    canValidate: (invoice) => stateHelpers.isPending(invoice.state),
    canCancel: (invoice) => stateHelpers.isPending(invoice.state),
    canReject: (invoice) => stateHelpers.isPending(invoice.state),
    canPay: () => false,
    canRefund: () => false,
    canSave: (invoice) => !invoice.state || stateHelpers.isDraft(invoice.state),
  },
  [INVOICE_TYPE.ORDER_FORM]: {
    canSend: (invoice) => stateHelpers.isDraft(invoice.state),
    canValidate: (invoice) => stateHelpers.isPending(invoice.state),
    canCancel: (invoice) => stateHelpers.isPending(invoice.state),
    canReject: (invoice) => stateHelpers.isPending(invoice.state),
    canPay: () => false,
    canRefund: () => false,
    canSave: (invoice) => !invoice.state || stateHelpers.isDraft(invoice.state),
  },
  [INVOICE_TYPE.DELIVERY_FORM]: {
    canSend: () => false,
    canValidate: (invoice) => stateHelpers.isDraft(invoice.state),
    canCancel: () => false,
    canReject: () => false,
    canPay: () => false,
    canRefund: () => false,
    canSave: (invoice) => !invoice.state || stateHelpers.isDraft(invoice.state),
  },
  [INVOICE_TYPE.INVOICE]: {
    canSend: () => false,
    canValidate: (invoice) => stateHelpers.isDraft(invoice.state),
    canCancel: (invoice) =>
      stateHelpers.isValidated(invoice.state) && !invoice.child,
    canReject: (invoice) => stateHelpers.isValidated(invoice.state),
    canPay: (invoice) => stateHelpers.isValidated(invoice.state),
    canRefund: (invoice) =>
      stateHelpers.isPaid(invoice.state) && !invoice.child,
    canSave: (invoice) => !invoice.state || stateHelpers.isDraft(invoice.state),
  },
  [INVOICE_TYPE.CREDIT_NOTE]: {
    canSend: () => false,
    canValidate: (invoice) => stateHelpers.isDraft(invoice.state),
    canCancel: () => false,
    canReject: () => false,
    canPay: () => false,
    canRefund: () => false,
    canSave: (invoice) => !invoice.state || stateHelpers.isDraft(invoice.state),
  },
  [INVOICE_TYPE.PRE_PAYMENT]: {
    canSend: () => false,
    canValidate: (invoice) => stateHelpers.isDraft(invoice.state),
    canPay: (invoice) => stateHelpers.isValidated(invoice.state),
    canCancel: () => false,
    canReject: () => false,
    canRefund: () => false,
    canSave: () => false,
  },
};

export const getInvoiceEmail = (
  type,
  user,
  company,
  emailTemplates,
  resend = null
) => {
  switch (type) {
    case INVOICE_TYPE.QUOTATION: {
      return getEmailQuotationSample(user, company, emailTemplates);
    }
    case INVOICE_TYPE.ORDER_FORM: {
      return getEmailOrderFormSample(user, company, emailTemplates);
    }
    case INVOICE_TYPE.INVOICE: {
      if (resend === true) {
        return getEmailDefaultResendSample(user, company, emailTemplates);
      }
      return getEmailInvoice(user, company, emailTemplates);
    }
    case INVOICE_TYPE.PRE_PAYMENT: {
      if (resend === true) {
        return getEmailDefaultResendSample(user, company, emailTemplates);
      }
      return getEmailInvoice(user, company, emailTemplates);
    }
    case INVOICE_TYPE.CREDIT_NOTE: {
      return getEmailCreditNoteSample(user, company, emailTemplates);
    }
    default: {
      return getEmailDefaultSample(user, company, emailTemplates);
    }
  }
};

export const deepCopy = (arr) => [...arr].map((o) => ({ ...o }));

export const invoiceHasDifferentBillingAddress = (invoice) => {
  if (
    invoice &&
    invoice.company &&
    invoice.company.billingAddress &&
    Object.keys(invoice.company.billingAddress).length > 0
  ) {
    return true;
  }
  return false;
};

export const invoiceHasdifferentClientBillingAddress = (invoice) => {
  if (
    invoice &&
    invoice.client &&
    invoice.client.billingAddress &&
    Object.keys(invoice.client.billingAddress).length > 0
  ) {
    return true;
  }
  return false;
};

export const canShowActions = (state, type, readOnly) => {
  return !(
    stateHelpers.isValidated(state) ||
    stateHelpers.isCanceled(state) ||
    typeHelpers.isCreditNote(type) ||
    typeHelpers.isPrePayment(type) ||
    readOnly
  );
};
