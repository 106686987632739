import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { useRouter } from 'hooks/routerHooks';

import { userHasTemplateAccessFeature } from 'selectors/rightsSelector/templateRequests';
import { userHasItemAccessFeature } from 'selectors/rightsSelector/itemRequests';
import { userHasClientAccessFeature } from 'selectors/rightsSelector/clientRequests';
import { userHasUserManagementAccessFeature } from 'selectors/rightsSelector/userManagementRequests';
import { EmojiObjectsSharp } from '@material-ui/icons';

import TemplateIcon from 'assets/images/templateIcon.svg';
import ItemsIcon from '../../../assets/images/itemsIcon.svg';
import IconClients from '../../../assets/images/clientIcon.svg';
import IconUsers from '../../../assets/images/usersIcon.svg';

const InvoiceListSideBar = () => {
  const { t } = useTranslation();
  const { history } = useRouter();

  const hasTemplateAccess = useSelector(userHasTemplateAccessFeature);
  const hasItemAccess = useSelector(userHasItemAccessFeature);
  const hasClientAccess = useSelector(userHasClientAccessFeature);
  const hasUserManagementAccess = useSelector(
    userHasUserManagementAccessFeature
  );

  return (
    <Fragment>
      {hasTemplateAccess && (
        <Fragment>
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: '16px',
              // boxShadow: '1px 4px 4px 0 #34223B',
            }}
            onClick={() => history.push('/settings/template')}
          >
            <div
              style={{
                backgroundColor: 'white',
                border: '1px solid #3d319d',
                height: '60px',
                width: '60px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
              }}
            >
              <img src={TemplateIcon} alt={t('template.card_title')} />
            </div>
            <p
              style={{
                color: '#3d319d',
                textTransform: 'uppercase',
                fontSize: '11px',
                lineHeight: '15px',
                fontWeight: 'bold',
              }}
            >
              {t('template.card_title')}
            </p>
          </div>
        </Fragment>
      )}

      {hasItemAccess && (
        <Fragment>
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: '16px',
              // boxShadow: '1px 4px 4px 0 #34223B',
            }}
            onClick={() => history.push('/settings/items')}
          >
            <div
              style={{
                backgroundColor: 'white',
                border: '1px solid #3d319d',
                height: '60px',
                width: '60px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
              }}
            >
              <img
                src={ItemsIcon}
                alt={t('settings_section.item.card_title')}
              />
            </div>
            <p
              style={{
                color: '#3d319d',
                textTransform: 'uppercase',
                fontSize: '11px',
                lineHeight: '15px',
                fontWeight: 'bold',
              }}
            >
              {t('settings_section.item.card_title')}
            </p>
          </div>
        </Fragment>
      )}

      {hasClientAccess && (
        <>
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: '16px',
              // boxShadow: '1px 4px 4px 0 #34223B',
            }}
            onClick={() => history.push('/settings/clients')}
          >
            <div
              style={{
                backgroundColor: 'white',
                border: '1px solid #3d319d',
                height: '60px',
                width: '60px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
              }}
            >
              <img
                src={IconClients}
                alt={t('settings_section.item.card_title')}
              />
            </div>
            <p
              style={{
                color: '#3d319d',
                textTransform: 'uppercase',
                fontSize: '11px',
                lineHeight: '15px',
                fontWeight: 'bold',
              }}
            >
              {t('settings_section.clients.card_title')}
            </p>
          </div>
          <div
            style={{
              backgroundColor: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: 'pointer',
              marginBottom: '16px',
              // boxShadow: '1px 4px 4px 0 #34223B',
            }}
            onClick={() => history.push('/projects')}
          >
            <div
              style={{
                backgroundColor: 'white',
                border: '1px solid #3d319d',
                height: '60px',
                width: '60px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                borderRadius: '4px',
              }}
            >
              <EmojiObjectsSharp style={{ fontSize: '45px' }} color="primary" />
            </div>
            <p
              style={{
                color: '#3d319d',
                textTransform: 'uppercase',
                fontSize: '11px',
                lineHeight: '15px',
                fontWeight: 'bold',
              }}
            >
              {t('settings_section.clients.client_project')}
            </p>
          </div>
        </>
      )}

      {hasUserManagementAccess && (
        <div
          style={{
            backgroundColor: 'white',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            marginBottom: '16px',
            // boxShadow: '1px 4px 4px 0 #34223B',
          }}
          onClick={() => history.push('/settings/users')}
        >
          <div
            style={{
              backgroundColor: 'white',
              border: '1px solid #3d319d',
              height: '60px',
              width: '60px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '4px',
            }}
          >
            <img
              src={IconUsers}
              alt={t('settings_section.users.card_title')}
              style={{ width: '36px', height: '40px' }}
            />
          </div>
          <p
            style={{
              color: '#3d319d',
              textTransform: 'uppercase',
              fontSize: '11px',
              lineHeight: '15px',
              fontWeight: 'bold',
            }}
          >
            {t('settings_section.users.card_title')}
          </p>
        </div>
      )}

      {/* <AppSepLine />
        <AppColumnCenterSpaceBetween className={classes.statusContainer}>
          <AppRowCenterStart>
            <p className={classes.legendTypeText}>
              {`BRO  - ${t('invoices.types.draft')}`}
              <br />
              {`DEV  - ${t('invoices.types.quotation')}`}
              <br />
              {`BDL  - ${t('invoices.types.delivery_form')}`}
              <br />
              {`FAC  - ${t('invoices.types.invoice')}`}
              <br />
              {`AVO  - ${t('invoices.types.credit_note')}`}
            </p>
          </AppRowCenterStart>
        </AppColumnCenterSpaceBetween> */}
    </Fragment>
  );
};

export default InvoiceListSideBar;
