import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import style from '../../assets/jss/components/appHeader';
import { header } from '../../assets/jss/components/appText';
import '../../assets/css/icons.css';
import state from './state';

const Header = ({
  name,
  children,
  goBack,
  spaceBetween,
  flexEnd,
  classes,
  pdfPage,
  headerStyle,
}) => {
  const { backIcon, flexStartRow } = style;
  const childrenContainerClasses = classNames({
    [classes.flexStartRow]: true,
    [classes.flexOne]: true,
    [classes.spaceBetween]: spaceBetween,
    [classes.flexEnd]: flexEnd,
  });

  const headerWrapClasses = classNames({
    [classes.mainHeaderWrap]: true,
    [classes.pdfHeader]: pdfPage,
  });

  const iconClasses = `icon icon-arrow-down`;

  return (
    <div className={headerWrapClasses}>
      <div style={flexStartRow}>
        {!!goBack && (
          <span style={backIcon} className={iconClasses} onClick={goBack} />
        )}
        <p style={{ ...header, ...headerStyle }}>{name}</p>
      </div>
      {!!children && <div className={childrenContainerClasses}>{children}</div>}
    </div>
  );
};

Header.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  goBack: PropTypes.func,
  spaceBetween: PropTypes.bool,
  pdfPage: PropTypes.bool,
  flexEnd: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.node,
  ]),
  headerStyle: PropTypes.object,
};

Header.defaultProps = {
  goBack: null,
  spaceBetween: false,
  pdfPage: false,
  flexEnd: false,
};

const HeaderWithStyle = withStyles(style)(Header);

export default connect(state)(HeaderWithStyle);
