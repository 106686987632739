import React from 'react';

import Fr from './Fr';
import En from './En';

export const DEFAULT_LANGUAGE = 'fr';

export default [
  {
    value: 'fr',
    label: 'languages.french',
    flag: '🇫🇷',
    display: <Fr />,
  },
  {
    value: 'en',
    label: 'languages.english',
    flag: '🇬🇧',
    display: <En />,
  },
];
