import React from 'react';

const FlagFR = () => (
  <span role="img" aria-label="flag: France">
    &#xd83c;&#xddeb;&#xd83c;&#xddf7;
  </span>
);
const FlagGB = () => (
  <span role="img" aria-label="flag: United Kingdom">
    &#xd83c;&#xddec;&#xd83c;&#xdde7;
  </span>
);

export { FlagFR, FlagGB };
