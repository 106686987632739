import { format } from 'date-fns';
import { isArray } from 'lodash';

import { getDefaultVAT } from '../helpers/expenseHelpers';

const amount = (vatRate) => ({
  amount: '',
  total: '',
  vat: '',
  vat_rate: vatRate,
});

const travelModel = (travel) => ({
  travel_distance: travel.distance,
  travel_distance_rate: travel.distance_rate,
  travel_from: travel.from,
  travel_to: travel.to,
});

const toTravel = (expense) => {
  const {
    travel_distance: distance,
    travel_distance_rate: distanceRate,
    travel_from: from,
    travel_to: to,
    ...rest
  } = expense;
  return {
    ...rest,
    travel: { distance, distance_rate: distanceRate, to, from },
  };
};

const amountModel = (categoryId) => {
  const vat = getDefaultVAT(categoryId);

  if (!isArray(vat)) {
    return [amount(vat)];
  }

  return vat.map((vatRate) => amount(vatRate));
};

const expenseModel = (categoryId) => {
  const paymentType = 'personal';

  return {
    date: format(new Date(), 'yyyy-MM-dd'),
    state: 'draft',
    category_id: categoryId || null,
    client_id: null,
    project_id: null,
    internal_activity: null,
    provider: null,
    providerSiret: '',
    currency: 'EUR',
    description: '',
    is_billable: false,
    is_intern: true,
    payment_type: paymentType,
    filename: null,
    travel_distance: '',
    travel_distance_rate: '',
    travel_from: '',
    travel_to: '',
    amount0: 0,
    vat0: 0,
    vatrate0: 0,
    total0: 0,
    amount1: 0,
    vat1: 0,
    vatrate1: 0,
    total1: 0,
    amount2: 0,
    vat2: 0,
    vatrate2: 0,
    total2: 0,
    amount3: 0,
    vat3: 0,
    vatrate3: 0,
    total3: 0,
  };
};

export { expenseModel, amountModel, travelModel, toTravel };
