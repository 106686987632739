import * as Yup from 'yup';

const validationSchema = ({ t }) =>
  Yup.object().shape({
    categoryId: Yup.string().required(t('mandatory')).nullable(),
    children: Yup.array().ensure(),
    subCategoryId: Yup.string()
      .nullable()
      .when('children', (children, schema) =>
        children.length ? schema.required(t('mandatory')) : schema
      ),
    description: Yup.string(),
    filename: Yup.string(),
    payingMode: Yup.string().required('Methode de paiement obligatoire'),
    grandTotalAmount: Yup.number()
      .typeError('Le total est obligatoire')
      .positive('Total > 0')
      .required('Total est obligatoire'),
  });
/* eslint-disable import/prefer-default-export */
export { validationSchema };
