/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, connect } from 'react-redux';
import { Form, useFormikContext, withFormik } from 'formik';
import { withRouter, useHistory } from 'react-router-dom';

import { filter } from 'lodash';

import Layout from 'components/Layout';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import ConfirmDialog from 'components/ConfirmDialog';
import ClientDialog from 'components/ClientDialog';
import PurchaseForm from 'containers/purchase/PurchaseForm/Form/PurchaseForm';
import { useCreateDialog } from 'hooks/dialogHooks';
import usePreventEnter from 'hooks/usePreventEnter';
import { getVatRatesByCategory } from 'helpers/VATRateHelpers';
import { useScanOcr } from 'hooks/scanOcr/useScanOcr';
import PaymentTypes from 'helpers/PaymentTypes';
import { EXPENSE_TYPE } from 'constants/expense';
import style from 'assets/jss/components/appDialog';
import validationSchema from './validation';
import Sidebar from './Sidebar';
import { ProviderFormDialog } from './Dialogs';

import Header from './Header';

import handleSubmit from './handlesubmit';
import mapPropsToValues from './formstate';

import State from './state';
import Dispatch from './dispatch';

const PurchaseFormContainer = (props) => {
  const { t } = useTranslation();
  const readOnly = false;
  const [formType, setFormType] = useState('ndf');
  const { values, setFieldValue } = useFormikContext();
  const history = useHistory();

  const {
    showDialog: showNewProviderDialog,
    toggleDialog: toggleNewProviderDialog,
    newItem: newProvider,
    setNewItem: setNewProvider,
    removeNewItem: removeNewProvider,
  } = useCreateDialog();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [showClientFormDialog, setClientFormDialog] = useState(false);
  const urlParams = new URLSearchParams(window.location.search);
  const cacheId = urlParams.get('cacheId');

  const categoryLoading = useSelector((state) => state.categories.loading);
  const categories = useSelector((state) => state.categories.data);
  const fullVatRates = useSelector((state) => state.fullVatRates);

  const [filterVatRates, setFilterVatRates] = useState([]);

  const { onOcr, onOcrDimarc, ocrError, handleCache, getValuesFromCache } =
    useScanOcr({
      setFieldValue,
      values,
      toggleNewProviderDialog,
      type: EXPENSE_TYPE.PURCHASE,
    });

  usePreventEnter();

  const paymentTypes = filter(PaymentTypes, (type) => type._id !== 'personal');

  const currentSubCategory = useMemo(
    () => categories[values.sub_category_id],
    [values.sub_category_id]
  );

  const navigate = (path) => {
    history.push(path);
  };

  useEffect(() => {
    const filterVAT = getVatRatesByCategory(currentSubCategory, fullVatRates);
    setFilterVatRates(filterVAT);
  }, [currentSubCategory]);

  const toggleClientFormDialog = () => {
    if (values?.importClientError && showClientFormDialog) {
      setFieldValue('importClientError', false);
    }
    setClientFormDialog(!showClientFormDialog);
  };

  useEffect(() => {
    getValuesFromCache(cacheId);
  }, [cacheId]);

  const handleConfirmOcr = async (isConfirm) => {
    if (!isConfirm) {
      await onOcr();
      return;
    }
    await handleCache();
    navigate('/purchases/list');
  };

  const renderBody = () => {
    return (
      <Fragment>
        <ProviderFormDialog
          showNewProviderDialog={showNewProviderDialog}
          toggleNewProviderDialog={toggleNewProviderDialog}
          setNewProvider={setNewProvider}
          stylePaper={style.positionRight}
        />
        <ConfirmDialog
          toggleDialog={setOpenConfirm}
          confirmMessage={t('expenses.ocr_confirm.description')}
          title={t('expenses.ocr_confirm.title')}
          isShowDialog={openConfirm}
          confirmText={t('expenses.ocr_confirm.wait_btn')}
          cancelText={t('expenses.ocr_confirm.return_btn')}
          // type={confirmType}
          onConfirm={handleConfirmOcr}
          isCancelConfirm={true}
        />
        {showClientFormDialog && (
          <ClientDialog
            t={t}
            closeDialog={toggleClientFormDialog}
            values={values}
            setFieldValue={setFieldValue}
          />
        )}
        <LoadingIndicatorDialog
          open={props.isSubmitting || categoryLoading}
          title={t('purchases.procesing')}
        />
        <PurchaseForm
          defaultTaxes={[]}
          clientProjects={props.clientProjects}
          clients={props.clients}
          isPurchase={true}
          paymentTypes={paymentTypes}
          toggleNewProviderDialog={toggleNewProviderDialog}
          onSelectFormType={setFormType}
          toggleClientFormDialog={toggleClientFormDialog}
          setOpenConfirmScan={setOpenConfirm}
          onOcr={onOcr}
          ocrError={ocrError}
        />
      </Fragment>
    );
  };

  const renderSideBar = () => {
    return (
      <Sidebar
        readOnly={readOnly}
        newProvider={{
          provider: newProvider,
          removeNewProvider,
        }}
        toggleNewProviderDialog={toggleNewProviderDialog}
        toggleClientFormDialog={toggleClientFormDialog}
        setFieldValue={setFieldValue}
      />
    );
  };

  return (
    <Fragment>
      <Form>
        <Layout
          header={<Header />}
          sidebarLeft={true}
          sidebarTop={renderSideBar()}
          showUserCard={true}
          body={renderBody()}
        />
      </Form>
    </Fragment>
  );
};

PurchaseFormContainer.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  isSubmitting: PropTypes.bool,
  values: PropTypes.object.isRequired, // just for logs
  setFieldValue: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
  clientProjects: PropTypes.object.isRequired,
};

const PurchaseFormContainerWithFormik = withFormik({
  displayName: 'PurchaseFormContainerWithFormik',
  enableReinitialize: false,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(PurchaseFormContainer);

// TODO is it used ?
const PurchaseWithRouter = withRouter(PurchaseFormContainerWithFormik);

export default connect(State, Dispatch)(PurchaseWithRouter);
