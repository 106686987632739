/* eslint-disable import/prefer-default-export */
export const CORSE_VAT_RATES = [
  {
    value: 0.9,
    display: '0.9 %',
  },
  {
    value: 13,
    display: '13 %',
  },
];
