/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { MONTHLY, QUATERLY } from 'constants/subscriptions';
import {
  BIC,
  BNC,
  CASH,
  COMMITMENT,
  ONBOARDING_DOCUMENT,
  PRESENTATION,
  VENTE,
} from 'config/Constants';

import TextFieldWithAdornment from 'components/FormElements/TextFieldWithAdornment';
import TextField from 'components/FormElements/textField';
import QuestionMarkInformation from 'components/QuestionMarkInformation';
import AppRadioGroup from '../../AppRadioGroup';
import AppRadioButton from '../../AppRadioButton';
import { AppTextTitle, AppTextHeader } from '../../AppText';
import AppError from '../../AppError';
// import TextFieldWithAdornment from '../../FormElements/TextFieldWithAdornment';
import { AppGreenDivider } from '../../AppDividers';

import {
  TYPE_AUTO_ENTREPRENEUR,
  TYPE_ENTREPRENEUR_INDIVIDUEL,
  TYPE_SOCIETE,
} from '../../../constants/company';

import ProgressGreen from '../../../assets/images/progress_green.svg';

const AppCompanyVatInfo = ({
  t,
  computeVatNum,
  values,
  setFieldValue,
  errors,
  submitted,
  stageOne,
  stageTwo,
  // isSettings,
}) => {
  const { users } = useSelector((state) => state);
  const types = [
    TYPE_AUTO_ENTREPRENEUR,
    TYPE_ENTREPRENEUR_INDIVIDUEL,
    TYPE_SOCIETE,
  ];
  const displayCapital = () => {
    return (
      <Grid item xs={12} sm={12} md={6}>
        <TextFieldWithAdornment
          styles={{ margin: '0 auto' }}
          adornmentPosition="end"
          adornmentText="€"
          name="capital"
          label={t('signup.company.capital')}
          placeholder={t('signup.company.capital')}
          color="secondary"
          type="number"
          required
          fullWidth
        />
      </Grid>
    );
  };

  useEffect(() => {
    if (values.companyType === types[0])
      setFieldValue('endFiscalYear', '31/12');

    // activity
    if (values.vente) {
      setFieldValue('activity', VENTE);
    } else if (values.prestation) {
      setFieldValue('activity', PRESENTATION);
    }

    setFieldValue('micro', false);
  }, [values.filename]);

  useEffect(() => {
    if (values.activity === PRESENTATION) {
      setFieldValue('prestation', true);
      setFieldValue('vente', false);
    } else if (values.activity === VENTE) {
      setFieldValue('prestation', false);
      setFieldValue('vente', true);
    }
  }, [values.activity]);

  const renderTaxSystemInfoContent = () => (
    <>
      <p>{t('signup.company.tax_system_info.description_1')}</p>
      <p>{t('signup.company.tax_system_info.description_2')}</p>
      <p>{t('signup.company.tax_system_info.description_3.title')}</p>
      <ul>
        <li>{t('signup.company.tax_system_info.description_3.option_1')}</li>
        <li>{t('signup.company.tax_system_info.description_3.option_2')}</li>
        <li>{t('signup.company.tax_system_info.description_3.option_3')}</li>
      </ul>
      <p>{t('signup.company.tax_system_info.description_4.title')}</p>
      <ul>
        <li>{t('signup.company.tax_system_info.description_4.option_1')}</li>
        <li>{t('signup.company.tax_system_info.description_4.option_2')}</li>
        <li>{t('signup.company.tax_system_info.description_4.option_3')}</li>
      </ul>
    </>
  );

  return (
    <div>
      {isEmpty(users) && (
        <>
          <Grid item xs={12} container justify="center">
            <AppTextHeader>
              {t('signup.company.step3').toUpperCase()}
            </AppTextHeader>
          </Grid>
          {/* Form image stepper */}
          <Grid item xs={12} container justify="center">
            <img
              alt=""
              src={ProgressGreen}
              onClick={stageOne}
              style={{
                cursor: 'pointer',
              }}
            />
            <AppGreenDivider />
            <img
              alt=""
              src={ProgressGreen}
              onClick={stageTwo}
              style={{
                cursor: 'pointer',
              }}
            />
            <AppGreenDivider />
            <img
              alt=""
              src={ProgressGreen}
              style={{
                cursor: 'pointer',
              }}
            />
          </Grid>
        </>
      )}

      {/* end_fiscal_year */}
      <Grid item xs={12} sm={12}>
        <Grid style={{ display: 'flex', marginTop: '14px', gap: '16px' }}>
          <AppTextTitle
            styles={{
              margin: 'revert',
              fontSize: '18px',
              fontWeight: '500',
            }}
          >
            {values.companyType === types[1]
              ? t('signup.company.end_fiscal_year_invidual')
              : t('signup.company.end_fiscal_year')}
            :
          </AppTextTitle>
          <div
            style={{
              display: 'flex',
              gap: 4,
            }}
          >
            <div style={{ width: 250 }}>
              <TextField
                type="text"
                name="endFiscalYear"
                // label={t('signup.company.end_fiscal_year')}
                placeholder={t('signup.company.end_fiscal_year')}
                color="secondary"
                required
                disabled={values.companyType === types[0]}
              />
            </div>
            <QuestionMarkInformation
              title={t('signup.company.end_fiscal_info_title')}
              style={{ marginTop: 16 }}
              content={
                <>
                  <p>{t('signup.company.end_fiscal_info_description')}</p>
                </>
              }
            />
          </div>
        </Grid>
      </Grid>

      {/* Section turnover to URSSAF */}
      {values.companyType === types[0] && (
        <Grid item xs={12} sm={12} md={6} style={{ marginBottom: '10px' }}>
          <Grid container>
            <Grid item xs={12} style={{ paddingRight: '10px' }}>
              <AppTextTitle
                styles={{
                  margin: 'revert',
                  fontSize: '18px',
                  fontWeight: '500',
                }}
              >
                {t('signup.company.turn_over_to_urssaf')}
              </AppTextTitle>
              <AppError display={submitted} error={errors.bookkeeping} />
            </Grid>
            <Grid item xs={12}>
              <AppRadioGroup
                name="periodicityReport"
                valueSelected={values.periodicityReport}
                onChange={(e) => {
                  setFieldValue('periodicityReport', e.target.value);
                }}
                color="secondary"
                row
                styles={{ width: '100%' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ width: '50%', display: 'flex' }}>
                    <AppRadioButton
                      color="secondary"
                      text={t('signup.company.mensuellement')}
                      value={MONTHLY}
                    />
                  </div>
                  <div style={{ width: '50%', display: 'flex' }}>
                    <AppRadioButton
                      color="secondary"
                      text={t('signup.company.trimestriellement')}
                      value={QUATERLY}
                    />
                  </div>
                </div>
              </AppRadioGroup>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* Section with RATTACHÉ À UN CENTRE DE GESTION and END OF FISCAL YEAR */}
      {values.companyType !== types[0] && (
        <Grid xs={12} container style={{ marginBottom: '14px' }}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid item xs={12}>
                <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                  <AppTextTitle
                    styles={{
                      margin: 'revert',
                      fontSize: '18px',
                      fontWeight: '500',
                    }}
                  >
                    {t('signup.company.cga')}
                  </AppTextTitle>
                  <QuestionMarkInformation
                    title={t('signup.company.cga_info_title')}
                    content={
                      <>
                        <p>{t('signup.company.cga_info_description')}</p>
                      </>
                    }
                  />
                </div>
                <AppError display={submitted} error={errors.cga} />
              </Grid>
              <Grid item xs={12}>
                <AppRadioGroup
                  name="cga"
                  valueSelected={
                    // eslint-disable-next-line no-nested-ternary
                    values.cga ? 'yes1' : values.cga === undefined ? '' : 'no1'
                  }
                  styles={{ width: '100%' }}
                  onChange={(e) => {
                    setFieldValue('cga', e.target.value === 'yes1');
                  }}
                  color="secondary"
                  disabled={false}
                >
                  <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ width: '50%', display: 'flex' }}>
                      <AppRadioButton
                        color="secondary"
                        text={t('yes')}
                        value="yes1"
                      />
                    </div>

                    <div style={{ width: '50%', display: 'flex' }}>
                      <AppRadioButton
                        color="secondary"
                        text={t('no')}
                        value="no1"
                      />
                    </div>
                  </div>
                </AppRadioGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* d’activité  */}
      <Grid item xs={12} sm={12} md={6} style={{ marginBottom: '14px' }}>
        <Grid container>
          <Grid xs={12} style={{ paddingRight: '10px' }}>
            <AppTextTitle
              styles={{
                margin: 'revert',
                fontSize: '18px',
                fontWeight: '500',
              }}
            >
              {t('signup.company.activity')}
            </AppTextTitle>
          </Grid>
          <Grid xs={12} style={{ display: 'flex' }}>
            <AppRadioGroup
              name="activity"
              valueSelected={values.activity}
              onChange={(e) => {
                setFieldValue('activity', e.target.value);
              }}
              color="secondary"
              row
              disabled={false}
              styles={{ width: '100%' }}
            >
              <div style={{ display: 'flex', width: '100%' }}>
                <div style={{ width: '50%', display: 'flex' }}>
                  <AppRadioButton
                    color="secondary"
                    text={t('signup.company.prestation')}
                    value={PRESENTATION}
                  />
                </div>
                <div style={{ width: '50%', display: 'flex' }}>
                  <AppRadioButton
                    color="secondary"
                    text={t('signup.company.vente')}
                    value={VENTE}
                  />
                </div>
              </div>

              {/* <AppError
                    display={submitted}
                    error={errors.prestation || errors.vente}
                  /> */}
            </AppRadioGroup>
            <AppError display={submitted} error={errors.activity} />
          </Grid>
        </Grid>
      </Grid>

      {/* TAX SYSTEM */}
      {values.activity === PRESENTATION && (
        <Grid item xs={12} sm={12} md={6} style={{ marginBottom: '14px' }}>
          <Grid container>
            <Grid item xs={12} style={{ paddingRight: '10px' }}>
              <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <AppTextTitle
                  styles={{
                    margin: 'revert',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  {t('signup.company.tax_system')}
                </AppTextTitle>

                <QuestionMarkInformation
                  title={t('signup.company.tax_system_info.title')}
                  content={renderTaxSystemInfoContent()}
                />
              </div>
              <AppError display={submitted} error={errors.bookkeeping} />
            </Grid>
            <Grid item xs={12}>
              <AppRadioGroup
                name="isCommercial"
                valueSelected={values.isCommercial}
                onChange={(e) => {
                  // eslint-disable-next-line prefer-destructuring
                  const value = e.target.value;
                  if (value === 'true') setFieldValue('isCommercial', true);
                  else if (value === 'false')
                    setFieldValue('isCommercial', false);
                }}
                color="secondary"
                row
                styles={{ width: '100%' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ width: '50%', display: 'flex' }}>
                    <AppRadioButton
                      color="secondary"
                      text={t('signup.company.bnc_script')}
                      value={BNC}
                    />
                  </div>

                  <div style={{ width: '50%', display: 'flex' }}>
                    <AppRadioButton
                      color="secondary"
                      text={t('signup.company.bic_script')}
                      value={BIC}
                    />
                  </div>
                </div>
              </AppRadioGroup>
            </Grid>
          </Grid>
        </Grid>
      )}

      {/* COMPTABILITÉ */}
      {(values.isCommercial !== undefined ||
        values.activity === VENTE ||
        values.companyType === types[2]) && (
        <Grid item xs={12} sm={12} md={6} style={{ marginBottom: '14px' }}>
          <Grid container>
            <Grid item xs={12} style={{ paddingRight: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <AppTextTitle
                  styles={{
                    margin: 'revert',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  {t('signup.company.bookkeeping')}
                </AppTextTitle>
                <QuestionMarkInformation
                  title={t('signup.company.bookkeeping_info_title')}
                  content={
                    <>
                      <p>
                        {t('signup.company.bookkeeping_info_description_1')}
                      </p>
                      <p>
                        {t('signup.company.bookkeeping_info_description_2')}
                      </p>
                    </>
                  }
                />
              </div>
              <AppError display={submitted} error={errors.bookkeeping} />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '8px' }}>
              <AppRadioGroup
                name="bookkeeping"
                valueSelected={
                  values.activity === VENTE ||
                  (values.isCommercial === BIC &&
                    values.activity === PRESENTATION)
                    ? COMMITMENT
                    : values.bookkeeping
                }
                onChange={(e) => {
                  setFieldValue('bookkeeping', e.target.value);
                }}
                disabled={
                  values.activity !== VENTE && values.isCommercial === BNC
                }
                color="secondary"
                row
                styles={{ width: '100%' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ width: '50%', display: 'flex' }}>
                    <AppRadioButton
                      color="secondary"
                      text={t('bookkeeping.cash')}
                      value={CASH}
                      disabled={
                        values.activity === VENTE || values.isCommercial === BIC
                      }
                    />
                  </div>

                  <div style={{ width: '50%', display: 'flex' }}>
                    <AppRadioButton
                      color="secondary"
                      text={t('bookkeeping.commitment')}
                      value={COMMITMENT}
                      disabled={
                        values.activity === VENTE || values.isCommercial === BIC
                      }
                    />
                  </div>
                </div>
              </AppRadioGroup>
            </Grid>
            {values.companyType === types[0] && (
              <Typography
                color="textSecondary"
                style={{ border: '1px solid #ccc', padding: '8px' }}
              >
                {t('signup.company.bookkepping_script')}
              </Typography>
            )}
          </Grid>
        </Grid>
      )}

      {/* Section with VAT apply */}
      <Grid container style={{ marginBottom: '14px' }}>
        <Grid xs={12}>
          <AppTextTitle
            styles={{
              fontSize: '18px',
              fontWeight: '500',
              margin: 'revert',
            }}
          >
            {t('signup.company.tva')}
          </AppTextTitle>
        </Grid>
        <Grid xs={12} sm={12} md={6} style={{ margin: 'auto 0' }}>
          <AppRadioGroup
            name="tva"
            valueSelected={
              /* eslint-disable-next-line */
              values.tva === undefined
                ? undefined
                : values.tva === 'true' || values.tva === true
            }
            onChange={(e) => {
              computeVatNum(values.siren, e.target.value);
              if (!e.target.value) {
                setFieldValue('intracomvat', '');
              }
              setFieldValue('tva', e.target.value);
            }}
            color="secondary"
            row
            disabled={false}
            styles={{ width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
              }}
            >
              <div style={{ width: '50%', display: 'flex' }}>
                <AppRadioButton
                  color="secondary"
                  text={t('yes')}
                  value={true}
                  disabled={!isEmpty(users)}
                />
              </div>
              <div style={{ width: '50%', display: 'flex' }}>
                <AppRadioButton
                  color="secondary"
                  text={t('no')}
                  value={false}
                  disabled={!isEmpty(users)}
                />
              </div>
            </div>
          </AppRadioGroup>
          <AppError display={submitted} error={errors.tva} />
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display:
              values.tva === 'true' || values.tva === true ? 'block' : 'none',
            fontSize: 14,
            fontWeight: 500,
          }}
        >
          {t('signup.company.intracomvat_info')} {values.intracomvat}
        </Grid>
      </Grid>
      {/* Section with PERIODIC VAT RETURNS AND DOMAINE D'ACTIVITÉ */}
      {(values.tva === 'true' || values.tva === true) && (
        <Grid container style={{ marginBottom: '14px' }}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid
                xs={12}
                style={{ paddingRight: '10px', display: 'flex', gap: 4 }}
              >
                <AppTextTitle
                  styles={{
                    margin: 'revert',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  {t('signup.company.periodic_VAT_returns')}
                </AppTextTitle>
                <QuestionMarkInformation
                  title={t(
                    'signup.company.periodic_VAT_returns_company_info_title'
                  )}
                  style={{ marginTop: 16 }}
                  content={
                    <p>
                      {t(
                        'signup.company.periodic_VAT_returns_company_info_description'
                      )}
                    </p>
                  }
                />
              </Grid>
              <Grid xs={12}>
                <AppRadioGroup
                  name="periodic_VAT_returns"
                  valueSelected={
                    /* eslint-disable-next-line */
                    values.periodic_VAT_returns === undefined
                      ? 'monthly'
                      : values.periodic_VAT_returns
                  }
                  onChange={(e) => {
                    setFieldValue('periodic_VAT_returns', e.target.value);
                  }}
                  color="secondary"
                  row
                  disabled={false}
                  styles={{ width: '100%' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div style={{ width: '50%', display: 'flex' }}>
                      <AppRadioButton
                        color="secondary"
                        text={t('monthly')}
                        value="monthly"
                      />
                    </div>
                    <div style={{ width: '50%', display: 'flex' }}>
                      <AppRadioButton
                        color="secondary"
                        text={t('quaterly')}
                        value="quaterly"
                      />
                    </div>
                    <div style={{ width: '50%', display: 'flex' }}>
                      <AppRadioButton
                        color="secondary"
                        text={t('yearly')}
                        value="yearly"
                      />
                    </div>
                  </div>
                </AppRadioGroup>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* Section with TAX RETURN ON DEBIT */}
      {(values.tva === 'true' || values.tva === true) && (
        <Grid container style={{ marginBottom: '10px' }}>
          {/* {values.tva && values.activity === PRESENTATION && ( */}
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid
                item
                xs={12}
                style={{
                  paddingRight: '10px',
                  display: 'flex',
                  gap: 4,
                  alignItems: 'center',
                }}
              >
                <AppTextTitle
                  styles={{
                    margin: 'revert',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  {t('signup.company.return_on_debit')}
                </AppTextTitle>
                <QuestionMarkInformation
                  title={t('signup.company.return_on_debit_company_info_title')}
                  content={
                    <>
                      <p>
                        {t(
                          'signup.company.return_on_debit_company_info_description_1'
                        )}
                      </p>
                      <p>
                        {t(
                          'signup.company.return_on_debit_company_info_description_2'
                        )}
                      </p>
                      <p>
                        {t(
                          'signup.company.return_on_debit_company_info_description_3'
                        )}
                      </p>
                    </>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <AppRadioGroup
                  name="return_on_debit"
                  valueSelected={
                    /* eslint-disable-next-line */
                    values.activity === VENTE
                      ? 'no'
                      : // eslint-disable-next-line no-nested-ternary
                      values.return_on_debit
                      ? 'yes'
                      : values.return_on_debit === undefined
                      ? ''
                      : 'no'
                  }
                  onChange={(e) => {
                    setFieldValue('return_on_debit', e.target.value === 'yes');
                  }}
                  color="secondary"
                  row
                  disabled={values.activity === PRESENTATION}
                  styles={{ width: '100%' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div style={{ width: '50%', display: 'flex' }}>
                      <AppRadioButton
                        color="secondary"
                        text={t('signup.company.encaissements')}
                        value="yes"
                        disabled={values.activity === VENTE}
                      />
                    </div>
                    <div style={{ width: '50%', display: 'flex' }}>
                      <AppRadioButton
                        color="secondary"
                        text={t('signup.company.debit')}
                        value="no"
                        disabled={values.activity === VENTE}
                      />
                    </div>
                  </div>
                </AppRadioGroup>
              </Grid>
            </Grid>
          </Grid>
          {values.companyType === TYPE_SOCIETE && displayCapital()}
          {/* )} */}
        </Grid>
      )}

      <div style={{ marginBottom: '10px' }}></div>

      <iframe src={ONBOARDING_DOCUMENT.ANNEXE_ONE} width="100%" height="800" />
      <div style={{ marginBottom: '10px' }}></div>
    </div>
  );
};
AppCompanyVatInfo.propTypes = {
  t: PropTypes.func,
  computeVatNum: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  isSettings: PropTypes.bool,
  stageOne: PropTypes.func,
  stageTwo: PropTypes.func,
  errors: PropTypes.object,
  submitted: PropTypes.bool,
  endFiscalYearEditable: PropTypes.bool.isRequired,
};

export default AppCompanyVatInfo;
