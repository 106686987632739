import { selectLoggedUserCompanyId } from 'selectors/loggedUserCompanySelectors';

import distanceRatesService from 'services/DistanceRatesService';

import {
  FETCH_DISTANCES_RATES,
  FETCH_DISTANCES_RATES_FAILED,
} from 'actions/Types';

import { setGlobalError } from 'actions/GlobalError';

// eslint-disable-next-line import/prefer-default-export
export const fetchDistanceRates = () => async (dispatch, getState) => {
  try {
    const companyId = selectLoggedUserCompanyId(getState());

    if (companyId) {
      const distanceRates = await distanceRatesService.fetchDistanceRates();
      dispatch({ type: FETCH_DISTANCES_RATES, payload: distanceRates.data });
    } else {
      dispatch({ type: FETCH_DISTANCES_RATES, payload: null });
    }
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: FETCH_DISTANCES_RATES_FAILED, error });
  }
};
