import { formatVATRates } from 'helpers/utils';
import vatServices from '../../services/VATService';

import {
  FETCH_VATRATES,
  FETCH_VATRATES_FOR_NO_VAT,
  FETCH_VATRATES_FAILED,
  FULL_VATRATES,
} from '../Types';
import { setGlobalError } from '../GlobalError';

// eslint-disable-next-line import/prefer-default-export
export const fetchVATRates = () => async (dispatch, getState) => {
  try {
    const { loggedUserCompany } = getState();
    const isApplyVAT =
      loggedUserCompany.company?.settings?.tax_registration?.tva;

    const result = await vatServices.fetchVATRates();
    const vatRates = formatVATRates(result.data);
    dispatch({ type: FULL_VATRATES, payload: vatRates });

    if (!isApplyVAT) {
      const noVATRates = vatRates.filter((VATRate) => VATRate.value === 0);
      dispatch({ type: FETCH_VATRATES_FOR_NO_VAT, payload: noVATRates });
      return;
    }

    dispatch({ type: FETCH_VATRATES, payload: vatRates });
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: FETCH_VATRATES_FAILED, error });
  }
};
