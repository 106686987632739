import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import SendButton from './SendButton';
import PayButton from './PayButton';

const FormActions = ({ horizontal, expenseId, readOnly }) => {
  const {
    values: { state },
  } = useFormikContext();
  const { t } = useTranslation();
  return (
    <div
      style={
        horizontal
          ? { display: 'flex', justifyContent: 'space-evenly' }
          : { width: '100%' }
      }
    >
      <Tooltip
        arrow
        title={t(`purchases.tooltip.${state}.pay`)}
        placement="top"
      >
        <span>
          <PayButton
            horizontal
            expenseId={expenseId}
            style={{ marginTop: '30px' }}
          />
        </span>
      </Tooltip>
      {readOnly ? (
        ''
      ) : (
        <Tooltip
          arrow
          title={t(`purchases.tooltip.${state}.send`)}
          placement="top"
        >
          <span>
            <SendButton horizontal readOnly={readOnly} />
          </span>
        </Tooltip>
      )}
    </div>
  );
};

FormActions.propTypes = {
  expenseId: PropTypes.string,
  horizontal: PropTypes.bool,
  readOnly: PropTypes.bool,
};

FormActions.defaultProps = {
  horizontal: false,
};

export default FormActions;
