import {
  DEFAULT_VATRATE,
  getVATValuesByCatId,
  isCategoryWithoutVAT,
} from './VATRateHelpers';

const getExpenseTitle = (paymentType) => {
  if (!paymentType) {
    return 'expenses.expense.card_title';
  }

  if (paymentType === 'personal') {
    return 'expenses.expense.expense';
  }

  return 'expenses.expense.other_expense';
};

const getExpenseURL = (paymentType) => {
  if (paymentType === 'personal') {
    return 'expenses';
  }

  return 'purchases';
};

const isExpensePurchase = (path) => {
  return path.includes('purchases');
};

const getExpenseURLByPath = (path) => {
  if (isExpensePurchase(path)) {
    return 'purchases';
  }

  return 'expenses';
};

const getDefaultVAT = (categoryId) => {
  if (!categoryId) {
    return DEFAULT_VATRATE;
  }

  if (categoryId && isCategoryWithoutVAT(categoryId)) {
    return 0;
  }

  if (categoryId && !getVATValuesByCatId(categoryId).length) {
    return DEFAULT_VATRATE;
  }

  return getVATValuesByCatId(categoryId);
};

const getExpenseByCategoriesData = (data) => {
  const res = {
    labels: [],
    datasets: [
      {
        data: [],
        backgroundColor: '#f5aa42',
      },
    ],
  };
  let i = 0;
  while (i < data.length) {
    res.labels.push(data[i].label);
    res.datasets[0].data.push(data[i].total);
    i += 1;
  }

  return res;
};

export {
  getExpenseTitle,
  getDefaultVAT,
  getExpenseURL,
  isExpensePurchase,
  getExpenseURLByPath,
  getExpenseByCategoriesData,
};
