import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import logger from 'helpers/logger';

const svgCache = new Map();

const SvgIcon = ({ path, width, height, color }) => {
  const [svgContent, setSvgContent] = useState('');

  useEffect(() => {
    const loadSvg = async () => {
      if (!svgCache.has(path)) {
        try {
          const response = await fetch(path);
          const svgText = await response.text();
          svgCache.set(path, svgText);
        } catch (error) {
          logger.error('Error loading SVG:', error);
          return;
        }
      }

      const svgText = svgCache.get(path);
      const parser = new DOMParser();
      const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
      const svgElement = svgDoc.querySelector('svg');

      if (svgElement) {
        if (color) {
          svgElement.setAttribute('fill', color);
          const paths = svgElement.querySelectorAll('path');
          paths.forEach((pathElement) => {
            pathElement.setAttribute('fill', color);
          });
        }
        if (width) {
          svgElement.setAttribute('width', width);
        }
        if (height) {
          svgElement.setAttribute('height', height);
        }

        setSvgContent(svgElement.outerHTML);
      }
    };

    loadSvg();
  }, [path, width, height, color]);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
      dangerouslySetInnerHTML={{ __html: svgContent }}
    />
  );
};

SvgIcon.propTypes = {
  path: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string,
};

export default SvgIcon;
