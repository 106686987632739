import { Card as Rights } from '../../config/Rights';
import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';
import { Cards as Feature } from '../../config/Features';

export const userCanCreateCard = () => {
  return hasRequestedAccess(Rights.CardCreate);
};

export const userCanReadCard = () => {
  return hasRequestedAccess(Rights.CardRead);
};

export const userCanUpdateCard = () => {
  return hasRequestedAccess(Rights.CardUpdate);
};

export const userHasCardsAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
