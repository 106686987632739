import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { TRANSACTION_TYPE } from 'constants/banks';
import TransactionDialog from 'components/TransactionDialog';
import AppButton from '../../../components/AppButton';

const Button = () => {
  const { values, isSubmitting, handleSubmit, setFieldValue } =
    useFormikContext();
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  //   if (values === null) {
  //     return null;
  //   }
  if (values.state === 'validated') {
    return (
      <div style={{ paddingBottom: '20px' }}>
        {showDialog && (
          <TransactionDialog
            toggleDialog={() => setShowDialog(!showDialog)}
            onSubmit={(transaction) => {
              setFieldValue('transaction', transaction);
              handleSubmit();
            }}
            defaultAmount={values.duePayableAmount}
            type={TRANSACTION_TYPE.DEBIT}
            documentId={values?.id}
          />
        )}

        <Tooltip
          arrow
          title={t('purchases.tooltip.revenue.reconcile')}
          placement="top"
        >
          <span>
            <AppButton
              onClick={() => {
                setShowDialog(true);
              }}
              type="button"
              text={t('transaction.reconcile')}
              color="primary"
              noBorder={true}
              isDisabled={isSubmitting}
            />
          </span>
        </Tooltip>
      </div>
    );
  }
  return null;
};

Button.propTypes = {
  dummy: PropTypes.bool,
};

export default Button;
