import React from 'react';
import PropTypes from 'prop-types';
import usePreventEnter from 'hooks/usePreventEnter';
import GeneralInfo from './GeneralInfo';

const ExpenseFormComponent = ({
  isDistanceRate,
  isPurchase,
  defaultTaxes,
  onSelectFormType,
  readOnly,
  paymentTypes,
  selectedCategory,
  setSelectedCategory,
  onOcr,
  ocrError,
  setOpenConfirmScan,
}) => {
  usePreventEnter();

  return (
    <>
      <GeneralInfo
        // selectedCategory={selectedCategory}
        defaultTaxes={defaultTaxes}
        paymentTypes={paymentTypes}
        onSelectFormType={onSelectFormType}
        isPurchase={isPurchase}
        setSelectedCategory={setSelectedCategory}
        isDistanceRate={isDistanceRate}
        readOnly={readOnly}
        selectedCategory={selectedCategory || {}}
        onOcr={onOcr}
        ocrError={ocrError}
        setOpenConfirmScan={setOpenConfirmScan}
      />
    </>
  );
};

ExpenseFormComponent.propTypes = {
  isPurchase: PropTypes.bool,
  defaultTaxes: PropTypes.array,
  readOnly: PropTypes.bool,
  isDistanceRate: PropTypes.bool,
  paymentTypes: PropTypes.array,
  onSelectFormType: PropTypes.func,
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.func,
  ocrError: PropTypes.object,
  onOcr: PropTypes.func,
  setOpenConfirmScan: PropTypes.func,
};

export default ExpenseFormComponent;
