import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import CARD_IMAGE from 'assets/images/bbf_card_CMYK_Convert_verso.png';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PinInput from 'react-pin-input';
import { activatePhysicalCard, fetchDetailCard } from 'actions/CardActions';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  card: {
    marginTop: '2%',
    textAlign: 'center',
  },
  cardTextLeft: {
    marginTop: '2%',
    textAlign: 'left',
  },
  card_img: {
    width: '300px',
    height: 'auto',
  },
});

const ActivateCard = ({ cardId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();
  const [showPin, setShowPin] = useState(true);
  const globalErrors = useSelector((state) => state.globalErrors);

  const activateCard = async (last4digits) => {
    await dispatch(
      activatePhysicalCard(cardId, {
        mask: Number(last4digits),
      })
    );

    // If activate card successfully
    if (globalErrors.length === 0) {
      await dispatch(fetchDetailCard(cardId));
    }
    setShowPin(true);
  };

  return (
    <Grid container className={classes.root} justify="center" spacing={2}>
      <Grid item xs={12} className={classes.card}>
        <img
          src={CARD_IMAGE}
          className={classes.card_img}
          alt="carte BeBusinessFocus"
        />
      </Grid>
      <Grid item xs={12} md={4} className={classes.cardTextLeft}>
        <Typography variant="h5" style={{ padding: '2% 0' }}>
          {t('activate_card_title')}
        </Typography>
        <Typography variant="p">{t('activate_card_text')}</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        className={classes.card}
        style={{ paddingBottom: '5%' }}
      >
        {showPin && (
          <PinInput
            length={4}
            initialValue=""
            onChange={(value) => {
              if (value.length === 4) {
                setShowPin(false);
                // setShowError(false);
                activateCard(value);
              }
            }}
            type="numeric"
            style={{ padding: '10px' }}
            inputStyle={{ borderColor: '#02cbcd' }}
            inputFocusStyle={{ borderColor: 'blue' }}
          />
        )}
        {!showPin && <CircularProgress />}
      </Grid>
    </Grid>
  );
};

ActivateCard.propTypes = {
  cardId: PropTypes.any.isRequired,
};

export default ActivateCard;
