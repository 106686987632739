import { bindActionCreators } from 'redux';
import { fetchCompanyLogo } from 'actions/CompanyActions';

import { getAgingBalance, getAgingBalanceOverdue } from 'actions/AgingBalance';
import { getBalancesTreezor } from 'actions/TreezorAccountActions.js';
import { fetchTurnOver } from '../../actions/InvoiceActions';
import {
  fetchExpenseOverview,
  fetchExpenseByCategories,
} from '../../actions/ExpensesActions';
import { fetchRevenueOverview } from '../../actions/RevenueActions';

export default (dispatch) =>
  bindActionCreators(
    {
      fetchTurnOver,
      fetchExpenseOverview,
      fetchRevenueOverview,
      getAgingBalance,
      getAgingBalanceOverdue,
      fetchExpenseByCategories,
      getBalancesTreezor,
      fetchCompanyLogo,
    },
    dispatch
  );
