import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appDividers';

export const AppDividerXS = () => <div style={style.xSmall} />;
export const AppDividerS = () => <div style={style.small} />;
export const AppDividerColS = () => <div style={style.smallCol} />;
export const AppDividerColM = () => <div style={style.meduimCol} />;
export const AppDividerM = () => <div style={style.medium} />;
export const AppDividerL = () => <div style={style.large} />;

export const AppGreenDivider = () => <div style={style.greenDivider} />;
export const AppGreyDivider = () => <div style={style.greyDivider} />;
export const AppGreenGreyDivider = () => <div style={style.greenGreyDivider} />;
export const AppSepLine = () => <div style={style.sepLine} />;

export const AppRowSpaceBetween = ({ children }) => (
  <div style={style.rowSpaceAround}>{children}</div>
);
AppRowSpaceBetween.propTypes = {
  children: PropTypes.node.isRequired,
};

const ResponsiveDividerS = ({ classes, displayM }) => {
  const classesReesponsiveDividerS = classNames({
    [classes.responsiveDividerS]: true,
    [classes.displayM]: displayM,
  });
  return <div className={classesReesponsiveDividerS} />;
};

ResponsiveDividerS.propTypes = {
  classes: PropTypes.object.isRequired,
  displayM: PropTypes.bool,
};

export const AppResponsiveDividerS = withStyles(style)(ResponsiveDividerS);
