import React from 'react';
// import PropTypes from 'prop-types';
import _ from 'lodash';
import { TreezorBeneficiaryTypes } from '@bebusinessfocus/compta-hub-core';
import { useSelector } from 'react-redux';
// import { useLocation } from 'react-router-dom';
import { useRouter } from 'hooks/routerHooks';

import Layout from 'components/Layout';
import Header from 'components/Header';
// import CircularProgressCentered from '../../../components/CircularProgressCentered';
import BankTransferFormik from '../../../components/CardComponents/Treezor/BankTransferForm/BankTransferFormik';

const BankTransfer = () => {
  // const location = useLocation();
  // const dispatch = useDispatch();
  const { history } = useRouter();
  // const [loading, setLoading] = useState(true);

  /* Data for beneficiary types */
  const companyAccounts = useSelector((state) =>
    state.loggedUserCompany.companies ? state.loggedUserCompany.companies : {}
  );
  const providers = useSelector((state) =>
    state.providers ? state.providers : {}
  );
  const clients = useSelector((state) =>
    state.clients && state.clients.data ? state.clients.data : {}
  );
  const users = useSelector((state) => (state.users ? state.users : {}));

  const renderBody = () => {
    // if (loading) {
    //   return <CircularProgressCentered />;
    // }
    // setLoading(false);
    return (
      <BankTransferFormik
        providers={[
          ..._.values(providers).map((provider) => ({
            ...provider,
            id: provider._id,
          })),
        ]}
        clients={[
          ..._.values(clients).map((client) => ({
            ...client,
            id: client._id,
          })),
        ]}
        users={[
          ..._.values(users).map((user) => ({
            ...user,
            id: user._id,
          })),
        ]}
        companyAccounts={[
          ...companyAccounts.map((account) => ({
            ...account,
            id: account._id,
          })),
        ]}
        beneficiaryTypes={TreezorBeneficiaryTypes}
      />
    );
  };

  return (
    <Layout
      header={
        <Header
          name={'Bank Transfer'}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

BankTransfer.propTypes = {};

export default BankTransfer;
