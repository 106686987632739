/* eslint-disable */

import React from 'react';
import PropTypes from 'prop-types';

/* import MUI */
import { Modal, Typography, Box, Button } from '@material-ui/core';

/* import styles */
import useStyles from './styles';
import { InfoRounded, ReportOffRounded } from '@material-ui/icons';

const MessageModal = (props) => {
  const { title, message, type, open, handleCloseModal, onRetry } = props;
  const classes = useStyles();

  const renderIconByType = () => {
    switch (type) {
      case 'info': {
        return (
          <InfoRounded color="primary" className={classes.iconMessageModal} />
        );
      }
      case 'error': {
        return (
          <ReportOffRounded
            color="error"
            className={classes.iconMessageModal}
          />
        );
      }
      default: {
        return <InfoRounded className={classes.iconMessageModal} />;
      }
    }
  };

  const renderButtonByType = () => {
    switch (type) {
      case 'info': {
        return (
          <Box className={classes.buttonsBlock}>
            <Button color="primary" variant="contained">
              OK
            </Button>
          </Box>
        );
      }
      case 'error': {
        return (
          <Box
            className={classes.buttonsBlock}
            style={{ justifyContent: 'space-around', width: '100%' }}
          >
            <Button
              color={onRetry !== undefined ? 'default' : 'primary'}
              variant="contained"
              onClick={handleCloseModal}
            >
              Cancel
            </Button>
            {onRetry !== undefined ? (
              <Button color="primary" variant="contained">
                Retry
              </Button>
            ) : null}
          </Box>
        );
      }
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleCloseModal}
      style={{
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className={classes.messageModal}>
        <div>{renderIconByType()}</div>
        <div>
          <Typography
            className={classes.messageModalTitle}
            align="center"
            style={{ fontWeight: 'bold' }}
            variant="h5"
          >
            {title}
          </Typography>
          <Typography
            style={{ padding: '0 20px', margin: '20px 0px' }}
            align="center"
          >
            {message}
          </Typography>
        </div>
        <div style={{ width: '100%' }}>{renderButtonByType()}</div>
      </div>
    </Modal>
  );
};

MessageModal.propTypes = {
  title: PropTypes.string,
  message: PropTypes.any,
  type: PropTypes.string,
  open: PropTypes.bool,
  handleCloseModal: PropTypes.func,
  onRetry: PropTypes.func,
};

export default MessageModal;
