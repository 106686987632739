import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import spinnerService from 'services/SpinnerService';
import { fetchClients } from 'actions/ClientActions';
import Layout from '../../../components/Layout';
import ClientHeader from '../components/ClientHeader';
import ClientListBody from '../Table/ClientListBody';
import ClientFormDialog from '../components/ClientFormDialog';
import ProjectFormDialog from '../components/ProjectFormDialog';
import ProjectDeleteDialog from '../components/ProjectDeleteDialog';
import ClientDeleteDialog from '../components/ClientDeleteDialog';

const ClientList = () => {
  const [showClientDialog, setShowClientDialog] = useState(false);
  const [showProjectDialog, setShowProjectDialog] = useState(false);

  const [showClientDeleteDialog, setShowClientDeleteDialog] = useState(false);
  const [showProjectDeleteDialog, setShowProjectDeleteDialog] = useState(false);

  const [selectedClientId, setSelectedClientId] = useState(null);
  const [selectedProjectId, setSelectedProject] = useState(null);

  const dispatch = useDispatch();
  useEffect(() => {
    spinnerService.startSpinner();
    dispatch(fetchClients()).finally(() => spinnerService.endSpinner());
  }, []);

  function openClientFormDialog(clientId = null) {
    setShowClientDialog(true);
    setSelectedClientId(clientId);
  }

  function closeClientFormDialog() {
    setShowClientDialog(false);
    setSelectedClientId(null);
  }

  function openProjectFormDialog(clientId, projectId = null) {
    setShowProjectDialog(true);
    setSelectedClientId(clientId);
    setSelectedProject(projectId);
  }

  function closeProjectFormDialog() {
    setShowProjectDialog(false);
    setSelectedClientId(null);
    setSelectedProject(null);
  }

  function toggleDeleteClientDialog() {
    setShowClientDeleteDialog((prevValue) => !prevValue);
  }

  function toggleDeleteProjectDialog() {
    setShowProjectDeleteDialog((prevValue) => !prevValue);
  }

  function showDeleteProjectDialog(projectId, clientId) {
    toggleDeleteProjectDialog();
    setSelectedClientId(clientId);
    setSelectedProject(projectId);
  }

  function showDeleteClientDialog(clientId) {
    toggleDeleteClientDialog();
    setSelectedClientId(clientId);
  }

  function closeDeleteProjectDialog() {
    toggleDeleteProjectDialog();
    setSelectedClientId(null);
    setSelectedProject(null);
  }

  function closeDeleteClientDialog() {
    toggleDeleteClientDialog();
    setSelectedClientId(null);
  }

  function renderBody() {
    return (
      <Fragment>
        <ProjectDeleteDialog
          showDialog={showProjectDeleteDialog}
          closeDialog={closeDeleteProjectDialog}
          selectedClientId={selectedClientId}
          selectedProjectId={selectedProjectId}
        />
        <ClientDeleteDialog
          showDialog={showClientDeleteDialog}
          closeDialog={closeDeleteClientDialog}
          selectedClientId={selectedClientId}
        />

        <ClientFormDialog
          showDialog={showClientDialog}
          selectedClientId={selectedClientId}
          closeClientFormDialog={closeClientFormDialog}
        />
        <ProjectFormDialog
          showDialog={showProjectDialog}
          selectedClientId={selectedClientId}
          selectedProjectId={selectedProjectId}
          closeProjectFormDialog={closeProjectFormDialog}
          showDeleteDialog={showDeleteProjectDialog}
        />

        <ClientListBody
          openProjectFormDialog={openProjectFormDialog}
          openClientFormDialog={openClientFormDialog}
          showDeleteDialog={showDeleteClientDialog}
        />
      </Fragment>
    );
  }

  return (
    <Layout
      header={
        <ClientHeader
          openClientFormDialog={openClientFormDialog}
          isCreateActions
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

export default ClientList;
