const style = {
  AppCompleteSignupTitleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  AppCompleteSignupSubtitle: {
    marginTop: '16px',
    marginBottom: '16px',
  },
  AppCompleteChecklist: {
    marginTop: '27px',
    marginLeft: '10px',
  },
};

export default style;
