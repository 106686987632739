import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { TreezorDocumentTypes } from '@bebusinessfocus/compta-hub-core';
import { combineIdentityOptions } from 'helpers/TreezorHelper';
import TreezorDocsNeedForm from './treezorDocsNeedForm';

const TreezorCompelentaryDocFormik = ({
  document,
  treezorUser,
  company,
  initialValues,
  onSubmit,
  validationSchema,
}) => {
  const getOptionList = () => {
    return [
      ...combineIdentityOptions(),
      ...TreezorDocumentTypes.proofOfAddress,
      ...TreezorDocumentTypes.companyRegistration,
      ...TreezorDocumentTypes.treezorDocumentTypes,
    ];
  };

  return (
    <Formik
      initialValues={initialValues(treezorUser._id, document, company._id)}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <TreezorDocsNeedForm
        treezorUser={treezorUser}
        document={document}
        title={''}
        optionList={getOptionList()}
        company={company}
      />
    </Formik>
  );
};

TreezorCompelentaryDocFormik.propTypes = {
  document: PropTypes.object,
  company: PropTypes.object,
  treezorUser: PropTypes.object,
  initialValues: PropTypes.func,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.func,
};

export default TreezorCompelentaryDocFormik;
