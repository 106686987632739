import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { selectLoggedUser } from 'selectors/loggedUserSelectors';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import LoadingDialog from 'components/LoadingDialog';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { red, green } from '@material-ui/core/colors';
import qs from 'qs';
import { useRouter } from 'hooks/routerHooks';
import { fetchCards } from 'actions/CardActions';
// import CardConfig from '../CardConfig';
import TreezorCardConfig from 'containers/CardContainers/Treezor/treeezorCreditCard';
import { userCanUpdateCard } from 'selectors/rightsSelector/cardRequests';

const CardCreation = ({
  virtual,
  setOpenCardConfig,
  selectedTreezorCard,
  ...props
}) => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [cardCreationSucsess, setCardCreationSucsess] = useState(false);
  const [cardCreationFailed, setCardCreationFailed] = useState(false);
  const location = useLocation();
  const treezorCard = useSelector((state) => state.cardReducer);

  const state = useSelector((reduxState) => reduxState);
  // const user = selectLoggedUser(state);
  let cardId = null;
  // if it's a creation cardId must be null, so we check the path and if the path contain 'new' the parameter is an userId
  // But if the path doesn't contain 'new' the parameter is the cardID
  if (!location.pathname.includes('new')) {
    cardId =
      props.match && props.match.params
        ? props.match.params.id
        : selectedTreezorCard.cardId;
  }
  const dispatch = useDispatch();
  const { treezorUser } = state.treezor;

  useEffect(() => {
    const { userId } = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });
    (async () => {
      setLoading(true);
      if (treezorCard.cards.length === 0) {
        await dispatch(fetchCards(userId));
      }
      setLoading(false);
    })();
  }, []);

  const renderLoading = () => {
    // cardCreationSucsess
    return (
      <div>
        {(treezorCard.loading || loading) && (
          <LoadingDialog title={t('loading_single')} />
        )}
        {cardCreationFailed && (
          <Dialog
            open={cardCreationFailed}
            onClose={() => {
              setCardCreationFailed(false);
            }}
          >
            <DialogContent style={{ width: '400px', margin: '10px' }}>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  variant="button"
                  style={{ fontSize: 20, color: red[500] }}
                >
                  Failed to create card !
                </Typography>
                <ErrorIcon style={{ fontSize: 40, color: red[500] }} />
              </Box>
            </DialogContent>
            <Divider />
            <Box display="flex" justifyContent="center">
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCardCreationFailed(false);
                  }}
                >
                  Ok
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        )}
        {cardCreationSucsess && (
          <Dialog
            open={cardCreationSucsess}
            onClose={() => {
              setCardCreationSucsess(false);
              dispatch(fetchCards());
              setOpenCardConfig(false);
            }}
          >
            <DialogContent style={{ width: '400px', margin: '10px' }}>
              <Box display="flex" justifyContent="space-between">
                <Typography
                  variant="button"
                  style={{ fontSize: 20, color: green[500] }}
                >
                  Card Created successfully
                </Typography>
                <CheckCircleIcon style={{ fontSize: 40, color: green[500] }} />
              </Box>
            </DialogContent>
            <Divider />
            <Box display="flex" justifyContent="center">
              <DialogActions>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCardCreationSucsess(false);
                    dispatch(fetchCards());
                    setOpenCardConfig(false);
                  }}
                >
                  Ok
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
        )}
      </div>
    );
  };

  const renderCardConfig = () => {
    return (
      <TreezorCardConfig
        virtual={virtual}
        closeCardConfig={() => setOpenCardConfig(false)}
        cardId={cardId}
        user={treezorUser}
      />
    );
  };

  return (
    <div>
      {renderLoading()}
      {userCanUpdateCard(state) && renderCardConfig()}
    </div>
  );
};

CardCreation.propTypes = {
  setOpenCardConfig: PropTypes.func,
  virtual: PropTypes.bool,
  selectedTreezorCard: PropTypes.object,
  props: PropTypes.object,
  match: PropTypes.object,
};

export default CardCreation;
