import {
  userCanDeleteProject,
  userCanUpdateProject,
} from '../../../selectors/rightsSelector/projectRequests';

export default ({ clientProjects, clients, ...restState }) => ({
  clientProjects,
  clients,
  userCanUpdateProject: userCanUpdateProject(restState),
  userCanDeleteProject: userCanDeleteProject(restState),
});
