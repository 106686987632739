import { bindActionCreators } from 'redux';
import {
  createClientProject,
  updateClientProject,
} from '../../../actions/ClientProjectActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createClientProject,
      updateClientProject,
    },
    dispatch
  );
