import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import TextField from '../../../../../components/FormElements/textField';

import { useInvoiceTotal } from '../../../../../hooks/invoiceHooks';

import NumberFormat from '../../../../../helpers/NumberFormat';
import { typeHelpers } from '../../../../../helpers/invoiceHelpers';

import colors from '../../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  columnStartStretch: {
    display: 'flex',
    flexDirection: 'column',
  },
  blueContainer: {
    backgroundColor: colors.backgroundGrey,
    color: colors.white,
    height: '34px',
    width: '260px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 14px',
    fontWeight: 'normal',
    fontSize: '11px',
  },
  mWhiteText: {
    color: colors.white,
    fontSize: '13px',
  },
  deliveryContainer: {
    backgroundColor: colors.white,
    height: '35px',
    width: '260px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 5px 0 14px',
    marginBottom: '8px',
  },
  sGreyBold: {
    color: colors.textDarkGrey,
    fontFamily: 'Kumbh Sans',
    fontSize: '11px',
    lineHeight: '13px',
    fontWeight: 'bold',
    letterSpacing: '0.17px',
  },
}));

const Total = ({ readOnly }) => {
  const classes = useStyles();

  const { values, setFieldValue, isSubmitting } = useFormikContext();
  const language = useSelector((state) => state.guiLanguage);
  const { t } = useTranslation();

  const {
    gross_total: grossTotal,
    net_total: netTotal,
    vat_total: vatTotal,
    delivery_fee: deliveryFee,
    type: invoiceType,
    toPay,
    duePayableAmount,
  } = values;

  useInvoiceTotal(({ totalGross, totalNet, totalVat, totalPayments }) => {
    setFieldValue('gross_total', totalGross, false);
    setFieldValue('net_total', totalNet, false);
    setFieldValue('vat_total', totalVat, false);
    setFieldValue('payments', totalPayments, false);
  }, values);

  function onDeliveryFeeChanged(e) {
    const { value } = e.target;
    setFieldValue('delivery_fee', value);
  }

  function renderDeliveryFee() {
    if (typeHelpers.isPrePayment(invoiceType)) {
      return null;
    }
    if (
      deliveryFee !== null &&
      (readOnly || typeHelpers.isCreditNote(invoiceType))
    ) {
      return (
        <div className={classNames(classes.blueContainer, classes.mWhiteText)}>
          <p>{t('invoices.delivery_fee')}</p>
          <p>{`${deliveryFee} €`}</p>
        </div>
      );
    }
    if (deliveryFee !== null && !readOnly) {
      return (
        <div className={classes.deliveryContainer}>
          <p className={classes.sGreyBold}>{t('invoices.delivery')}</p>

          <TextField
            type="number"
            color="secondary"
            placeholder="00,00€"
            disabled={isSubmitting}
            name="delivery_fee"
            onChange={onDeliveryFeeChanged}
          />
        </div>
      );
    }

    return null;
  }

  function renderPrepayment() {
    if (!typeHelpers.isPrePayment(invoiceType) || !toPay) {
      return null;
    }

    return (
      <div className={classNames(classes.blueContainer, classes.mWhiteText)}>
        <p>{t('invoices.to_pay')}</p>
        <p>{`${NumberFormat.n(toPay.amount)} €`}</p>
      </div>
    );
  }

  function renderDuePayableAmount() {
    if (!typeHelpers.isInvoice(invoiceType) || !duePayableAmount) {
      return null;
    }

    return (
      <div className={classNames(classes.blueContainer, classes.mWhiteText)}>
        <p>{t('invoices.due_payable_amount')}</p>
        <p>{`${NumberFormat.n(duePayableAmount)} €`}</p>
      </div>
    );
  }

  function renderTotal() {
    if (typeHelpers.isPrePayment(invoiceType)) {
      return null;
    }

    return (
      <Fragment>
        <div className={classNames(classes.blueContainer, classes.mWhiteText)}>
          <p>{t('invoices.total_net')}</p>
          <p>{`${NumberFormat.formatNumberByNation(netTotal, language)}€`}</p>
        </div>
        <div className={classNames(classes.blueContainer, classes.mWhiteText)}>
          <p>{t('invoices.vat_amount')}</p>
          <p>{`${NumberFormat.formatNumberByNation(vatTotal, language)}€`}</p>
        </div>
        <div className={classNames(classes.blueContainer, classes.mWhiteText)}>
          {deliveryFee ? (
            <p>{t('invoices.total_gross_delivery')}</p>
          ) : (
            <p>{t('invoices.total_gross')}</p>
          )}

          <p>{`${NumberFormat.formatNumberByNation(grossTotal, language)}€`}</p>
        </div>
      </Fragment>
    );
  }

  return (
    <div className={classes.columnStartStretch}>
      {renderDeliveryFee()}
      {renderTotal()}
      {renderPrepayment()}
      {renderDuePayableAmount()}
    </div>
  );
};

Total.propTypes = {
  readOnly: PropTypes.bool,
};

export default Total;
