import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import style from '../../assets/jss/components/appCard';
import '../../assets/css/icons.css';

const AppCard = ({ classes, text, data }) => {
  const textClasses = classNames({
    [classes.text]: true,
  });
  const iconClasses = classNames({
    [classes.text]: true,
  });
  const dataClasses = classNames({
    [classes.data]: true,
  });

  return (
    <>
      <div
        style={{
          minHeight: '50px',
          marginBottom: '20px',
          display: 'flex',
        }}
      >
        <div className={textClasses}>{text}</div>
        <div className={iconClasses}>
          <ArrowRightIcon />
        </div>
      </div>
      <div>
        <p className={dataClasses}>{data}</p>
      </div>
    </>
  );
};

AppCard.propTypes = {
  classes: PropTypes.object.isRequired,
  text: PropTypes.string,
  data: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
// AppCard.defaultProps = {};

export default withStyles(style)(AppCard);
