/* eslint-disable */
import React, { Component } from 'react'; // useState, useEffect
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { PAYMENTS_METHODS_KEYS, TYPE_SOCIETE } from 'constants/company';
import { Box, Grid } from '@material-ui/core';
// components
import BankList from 'components/BankForm';
import LoadingIndicator from '../../components/LoadingIndicatorDialog';
import Layout from '../../components/Layout';
import SignUpService from '../../services/SignUpService';
import AppButton from '../../components/AppButton';
import AppCheckbox from '../../components/AppCheckbox';
import Header from '../../components/Header';
import { AppRowCenterEnd } from '../../components/AppContainers';
import { AppTextTitle } from '../../components/AppText';
import AppCompanySetting from '../../components/AppCompany/AppCompanySetting';
import AppCompanyInfoSettings from '../../components/AppCompany/AppCompanyInfoSettings';
import AppCompanyVatInfoSettings from '../../components/AppCompany/AppCompanyVatInfoSettings';
import CompanyIntro from 'components/CompanyIntro';
// Formik functions
import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';
// redux
import state from './state';
import dispatch from './dispatch';
// style, helper and constants
import styles from '../../assets/jss/root';
import helperIntracom from '../../helpers/TVAIntracom';
import CustomTextField from 'components/CustomTextField';
import ErrorMessage from 'components/ErrorMessage';
import AppContractorInfoSettings from 'components/AppCompany/AppContractorInfoSettings';

class CompanyForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorBankDetails: false,
      errorCompanyLogo: false,
      submitted: false,
      returnFromCallback: undefined,
      showSuccessDialog: false,
      paypalOptionChecked: !!this.props.values.client_id,
      immediatelyEror: false,
      bankDetailsError: false,
      paymentMethodRequired: false,
    };
    this._onBusinessLogoSelected = this._onBusinessLogoSelected.bind(this);
    this._onCodeSelected = this._onCodeSelected.bind(this);
    this.computeVatNum = this.computeVatNum.bind(this);
    this.fetchRcs = this.fetchRcs.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleOnchange = this.handleOnchange.bind(this);
    this.paymentsDisabledClick = this.paymentsDisabledClick.bind(this);
    this.togglePaymentMethod = this.togglePaymentMethod.bind(this);
    this.handlePaypalChange = this.handlePaypalChange.bind(this);
    console.log('CompanyForm props', props);
    console.log('CompanyForm state', this.state);
    console.log('CompanyForm values', this.props.values);
  }

  onSubmit(e) {
    const { setFieldValue, values, handleSubmit: formikSubmit } = this.props;

    console.log('values', values);

    if (isEmpty(values.bank_details)) {
      this.setState({ bankDetailsError: true });
      return;
    }
    const checkEmpty = values.bank_details.some((item) => {
      return !!item?.accountId
        ? isEmpty(item.bank_name) ||
            (isEmpty(values.client_id) && this.state.paypalOptionChecked)
        : isEmpty(item.bank_name) ||
            isEmpty(item.iban) ||
            isEmpty(item.bic) ||
            (isEmpty(values.client_id) && this.state.paypalOptionChecked);
    });

    this.setState({ bankDetailsError: checkEmpty });
    if (checkEmpty) {
      if (isEmpty(values.client_id) && this.state.paypalOptionChecked) {
        this.setState({ immediatelyEror: true });
      }
      return;
    }

    if (!this.state.paypalOptionChecked) {
      setFieldValue('client_id', '');
    }

    // Always disabled payment method = HiPay
    setFieldValue('hiPay', false);

    this.setState({ submitted: true });
    formikSubmit(e);
    handleSubmit(values, this.props);
  }

  _onCodeSelected(code) {
    /**
     * We Set the code_ape to code.Code, because, code is an object, that has the format :
     * code: { Code:"100","Intitulés_NAF": "text......", }
     * and we want Only the Code field in this object.
     */
    this.props.setFieldValue('code_ape', code?.Code);
  }

  _onBusinessLogoSelected(file, values) {
    this.props.changeCompanyLogo(file, values, (err) => {
      if (err) {
        this.setState({ errorCompanyLogo: true });
      } else this.setState({ errorCompanyLogo: false });
    });
  }

  computeVatNum(siren, tva) {
    if (/^[0-9]{9}$/.test(siren) && tva) {
      this.props.setFieldValue('intracomvat', helperIntracom(siren));
    }
  }

  fetchRcs(name, value) {
    const { values, setFieldValue } = this.props;
    setFieldValue(name, value);
    const zipcode = this.name === 'zipcode' ? value : values.zipcode;
    const city = this.name === 'city' ? value : values.city;
    if (zipcode?.match(/[\d]{5}/) && city) {
      SignUpService.getRcs(zipcode, city).then((res) => {
        if (res.data.rcs) setFieldValue('rcs', res.data.rcs);
      });
    }
  }

  renderLoading(isSubmitting) {
    const { t } = this.props;
    if (isSubmitting) {
      return <LoadingIndicator title={t('loading_single')} open={true} />;
    }
    return null;
  }

  /*
   * PARTIAL RENDER FUNCTIONS
   */

  renderSidebarRight() {
    const { isSubmitting, t } = this.props;

    return (
      <AppButton
        // color="secondaryLight"
        fullWidth
        text={t('save')}
        isDisabled={isSubmitting}
        noBorder
        type="button"
        onClick={(e) => {
          console.log('onSubmit');
          this.onSubmit(e);
        }}
      />
    );
  }

  paymentsDisabledClick(name) {
    const { values } = this.props;
    const payments = { ...values, [name]: false };
    return (
      !payments.bridgePaymentLink &&
      !payments.hiPay &&
      !payments.paypal &&
      !payments.check &&
      !payments.cash &&
      !payments.bankTransfer &&
      !payments.lcr
    );
  }

  handleOnchange(e) {
    this.props.setFieldValue('client_id', e.target.value);
    if (this.state.immediatelyEror && !isEmpty(e.target.value)) {
      this.setState({ immediatelyEror: false });
    }
    if (paymentMethodRequired && e.target.value) {
      this.setState({ paymentMethodRequired: false });
    }
  }
  togglePaymentMethod(name, event) {
    const { setFieldValue, values } = this.props;
    const { paymentMethodRequired } = this.state;

    if (this.paymentsDisabledClick(name) && values[name]) {
      this.setState({ paymentMethodRequired: true });
    } else {
      setFieldValue(name, event.target.checked);
      if (paymentMethodRequired && event.target.checked) {
        this.setState({ paymentMethodRequired: false });
      }
    }
  }
  onKeyDown(keyEvent) {
    if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
      keyEvent.preventDefault();
    }
  }

  handlePaypalChange() {
    const { paypalOptionChecked } = this.state;
    const { setFieldValue } = this.props;
    if (
      this.paymentsDisabledClick(PAYMENTS_METHODS_KEYS.PAYPAL) &&
      paypalOptionChecked
    ) {
      this.setState({ paymentMethodRequired: true });
    } else {
      setFieldValue(PAYMENTS_METHODS_KEYS.PAYPAL, !paypalOptionChecked);
      this.setState({
        paypalOptionChecked: !paypalOptionChecked,
      });
    }
  }

  renderBody() {
    const {
      classes,
      touched,
      t,
      errors,
      values,
      setFieldValue,
      handleChange,
      setSubmitting,
      history,
      isSubmitting,
      fetchInfoGreffe,
      loggedUserCompany: { company },
      companyDataAreMissing,
    } = this.props;

    const {
      paypalOptionChecked,
      immediatelyEror,
      bankDetailsError,
      paymentMethodRequired,
    } = this.state;
    return (
      <div style={{ paddingTop: '10px' }}>
        {errors && errors.internalError && (
          <div className={classes.ErrorMessage}>{errors.internalError}</div>
        )}
        {values?.isContractor?.toString() === 'true' ? (
          <AppContractorInfoSettings
            t={t}
            values={values}
            setFieldValue={setFieldValue}
            onCodeSelected={(code) => setFieldValue('code_ape', code?.Code)}
            onSirenChange={(e) => setFieldValue('siren', e.target.value)}
            isCompanySetting
          />
        ) : (
          <AppCompanyInfoSettings
            t={t}
            errors={errors}
            values={values}
            classes={classes}
            setSubmitting={setSubmitting}
            setFieldValue={setFieldValue}
            history={history}
            touched={touched}
            fetchInfoGreffe={fetchInfoGreffe}
            onCodeSelected={this._onCodeSelected}
            onZipcodeCityChange={(e) => {
              this.fetchRcs(e.target.name, e.target.value);
            }}
            onSirenChange={(e) => {
              handleChange(e);
              this.computeVatNum(e.target.value, values.tva);
            }}
            isSettings={true}
          />
        )}

        {values.companyType === TYPE_SOCIETE ? (
          <AppCompanySetting
            t={t}
            classes={classes}
            setFieldValue={setFieldValue}
            values={values}
            computeVatNum={this.computeVatNum}
            isSettings
            errors={errors}
            submitted={this.state.submitted}
            endFiscalYearEditable={!!company.endFiscalYear}
          />
        ) : (
          <AppCompanyVatInfoSettings
            t={t}
            classes={classes}
            setFieldValue={setFieldValue}
            values={values}
            computeVatNum={this.computeVatNum}
            isSettings
            errors={errors}
            submitted={this.state.submitted}
            endFiscalYearEditable={!!company.endFiscalYear}
          />
        )}
        <Grid container>
          {/* invoices setting */}
          <Grid item xs={12} sm={12} md={6}>
            <AppTextTitle
              styles={{ margin: 'revert', width: '100%', textAlign: 'left' }}
            >
              {t('signup.company.invoices').toUpperCase()}
            </AppTextTitle>
            <Box style={{ display: 'flex', margin: 'auto 0', width: '100%' }}>
              <AppCheckbox
                checked={values.quotation}
                disabled={isSubmitting}
                onClick={(e) => {
                  setFieldValue('quotation', e.target.checked);
                }}
                labelText={t('signup.company.quotation')}
                id="quotation"
              />

              <AppCheckbox
                checked={values.order_form}
                disabled={isSubmitting}
                onClick={(e) => {
                  setFieldValue('order_form', e.target.checked);
                }}
                labelText={t('signup.company.order_form')}
                id="order_form"
              />

              <AppCheckbox
                checked={values.delivery_form}
                disabled={isSubmitting}
                onClick={(e) => {
                  setFieldValue('delivery_form', e.target.checked);
                }}
                labelText={t('signup.company.delivery_form')}
                id="delivery_form"
              />
            </Box>
          </Grid>

          {/* payment method */}
          <Grid item xs={12} sm={12} md={6}>
            <AppTextTitle
              styles={{ margin: 'revert', width: '100%', textAlign: 'left' }}
            >
              {t('setting.payment_method').toUpperCase()}
            </AppTextTitle>
            <Grid container>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <AppCheckbox
                  checked={values.bridgePaymentLink}
                  disabled={isSubmitting}
                  onClick={(e) => {
                    this.togglePaymentMethod(
                      PAYMENTS_METHODS_KEYS.BRIDGE_PAYMENT_LINK,
                      e
                    );
                  }}
                  isDisableClick={this.paymentsDisabledClick(
                    PAYMENTS_METHODS_KEYS.BRIDGE_PAYMENT_LINK
                  )}
                  labelText={t(
                    'settings_section.company_settings.payments_methods.bridge_payment_link'
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                {/* Always disabled payment method = HiPay */}
                <AppCheckbox
                  // checked={values.hiPay}
                  disabled={true}
                  onClick={(e) => {
                    this.togglePaymentMethod(PAYMENTS_METHODS_KEYS.HI_PAY, e);
                  }}
                  isDisableClick={this.paymentsDisabledClick(
                    PAYMENTS_METHODS_KEYS.HI_PAY
                  )}
                  labelText={t(
                    'settings_section.company_settings.payments_methods.hi_pay'
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <>
                  <AppCheckbox
                    checked={paypalOptionChecked}
                    disabled={isSubmitting}
                    onClick={() => {
                      this.handlePaypalChange();
                    }}
                    isDisableClick={this.paymentsDisabledClick(
                      PAYMENTS_METHODS_KEYS.PAYPAL
                    )}
                    labelText={t(
                      'settings_section.company_settings.payments_methods.paypal'
                    )}
                  />
                  {paypalOptionChecked && (
                    <div
                      style={{
                        width: '80%',
                        margin: '0 12px',
                      }}
                    >
                      <CustomTextField
                        style={{ margin: 0 }}
                        type="text"
                        name="client_id"
                        label={t('paypal.client_id')}
                        placeholder={t('paypal.client_id')}
                        color="secondary"
                        errorMessage={t('forms.mandatory_field')}
                        immediatelyEror={immediatelyEror}
                        required={paypalOptionChecked}
                        value={values.client_id}
                        onChange={(e) => this.handleOnchange(e)}
                      />
                    </div>
                  )}
                </>
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <AppCheckbox
                  checked={values.check}
                  disabled={isSubmitting}
                  onClick={(e) => {
                    this.togglePaymentMethod(PAYMENTS_METHODS_KEYS.CHECK, e);
                  }}
                  isDisableClick={this.paymentsDisabledClick(
                    PAYMENTS_METHODS_KEYS.CHECK
                  )}
                  labelText={t(
                    'settings_section.company_settings.payments_methods.check'
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <AppCheckbox
                  checked={values.cash}
                  disabled={isSubmitting}
                  onClick={(e) => {
                    this.togglePaymentMethod(PAYMENTS_METHODS_KEYS.CASH, e);
                  }}
                  isDisableClick={this.paymentsDisabledClick(
                    PAYMENTS_METHODS_KEYS.CASH
                  )}
                  labelText={t(
                    'settings_section.company_settings.payments_methods.cash'
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <AppCheckbox
                  checked={values.bankTransfer}
                  disabled={isSubmitting}
                  onClick={(e) => {
                    this.togglePaymentMethod(
                      PAYMENTS_METHODS_KEYS.BANK_TRANSFER,
                      e
                    );
                  }}
                  isDisableClick={this.paymentsDisabledClick(
                    PAYMENTS_METHODS_KEYS.BANK_TRANSFER
                  )}
                  labelText={t(
                    'settings_section.company_settings.payments_methods.bankTransfer'
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={4}>
                <AppCheckbox
                  checked={values.lcr}
                  disabled={isSubmitting}
                  onClick={(e) => {
                    this.togglePaymentMethod(PAYMENTS_METHODS_KEYS.LCR, e);
                  }}
                  isDisableClick={this.paymentsDisabledClick(
                    PAYMENTS_METHODS_KEYS.LCR
                  )}
                  labelText={t(
                    'settings_section.company_settings.payments_methods.lcr'
                  )}
                />
              </Grid>
            </Grid>
            {paymentMethodRequired && (
              <ErrorMessage
                showError={true}
                error={t(
                  'settings_section.company_settings.payments_method_require'
                )}
              />
            )}
          </Grid>
          <Grid item xs={11} sm={12}>
            <CompanyIntro
              companyValues={values}
              setFieldValues={setFieldValue}
            />
          </Grid>
          <Grid item xs={12}>
            <BankList
              banks={values.bank_details}
              setFieldValue={setFieldValue}
              bankDetailsError={bankDetailsError}
              isSetting
              companyDataAreMissing={companyDataAreMissing}
            />
          </Grid>
        </Grid>

        {/* submit button */}
        <Grid container style={{ marginTop: '50px' }}>
          <Grid item xs={12}>
            <AppRowCenterEnd heightAuto>
              <AppButton
                // color="secondaryLight"
                text={t('save')}
                isDisabled={isSubmitting}
                noBorder
                type="button"
                onClick={(e) => {
                  console.log('onSubmit');
                  this.onSubmit(e);
                }}
                fullWidth
              />
            </AppRowCenterEnd>
          </Grid>
        </Grid>
      </div>
    );
  }

  /*
   * MAIN RENDER FUNCTION
   */
  render() {
    const { t, isSubmitting, history } = this.props;
    return (
      <Form onKeyDown={this.onKeyDown}>
        {this.renderLoading(isSubmitting)}
        <Layout
          header={
            <Header
              name={t('settings_section.clients.form_label_company')}
              goBack={() => {
                history.goBack();
              }}
            />
          }
          sidebarLeft={true}
          sidebarRight={this.renderSidebarRight()}
          showUserCard={true}
          body={this.renderBody()}
        />
      </Form>
    );
  }
}
CompanyForm.propTypes = {
  classes: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  t: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  setSubmitting: PropTypes.func.isRequired,
  fetchInfoGreffe: PropTypes.func.isRequired,
  history: PropTypes.object,
  fetchCompanyLogo: PropTypes.func,
  isSubmitting: PropTypes.bool,
  changeBankDetails: PropTypes.func.isRequired,
  changeCompanyLogo: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,

  // required to create bank connection
  // dispatch
  createBankConnector: PropTypes.func,
  // state
  allReduxState: PropTypes.object,
  loggedUserCompany: PropTypes.object,
  loggedUser: PropTypes.object,
  bankConnector: PropTypes.object,
  // get info from callback bankin
  location: PropTypes.object,
};

const CompanyWithForm = withFormik({
  displayName: 'CompanyForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit: () => {},
})(CompanyForm);

const CompanyFormWithStyles = withStyles(styles)(CompanyWithForm);
const CompanyFormWithRouter = withRouter(CompanyFormWithStyles);
const TranslatedCompanyFormWithStyles = withTranslation()(
  CompanyFormWithRouter
);

export default connect(state, dispatch)(TranslatedCompanyFormWithStyles);
