export default (values, { setErrors, setStatus, setSubmitting, props }) => {
  const cb = (err) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      setStatus({ success: true });
      props.closeDialog();
    }
  };

  if (!values._id) {
    props.createInternalActivity(values, cb);
  } else {
    props.updateInternalActivity(values, cb);
  }
};
