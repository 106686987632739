import { TYPE_AUTO_ENTREPRENEUR } from 'constants/company';

/* eslint-disable-next-line */
export default (props) => {
  return {
    name: '',
    userName: '',
    firstname: '',
    email: '',
    phonenumber: '',
    // eslint-disable-next-line camelcase
    business_name: '',
    address: '',
    rcs: '',
    rcsType: 'RCS',
    siren: '',
    nic: '',
    // eslint-disable-next-line camelcase
    code_ape: '',
    city: '',
    zipcode: '',
    // eslint-disable-next-line camelcase
    bookkeeping: 'cash',
    legal_form: '',
    title: '',
    companyType: TYPE_AUTO_ENTREPRENEUR,
    isContractor: false,
  };
};
