import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appState';
import '../../assets/css/icons.css';

const AppState = ({ classes, state, value }) => {
  const stateClasses = classNames({
    [classes[state]]: state,
  });

  if (state === 'bState') {
    return <p className={stateClasses}>B</p>;
  }

  return <div className={stateClasses}>{value}</div>;
};

AppState.propTypes = {
  classes: PropTypes.object.isRequired,
  state: PropTypes.string,
  value: PropTypes.string,
};

export default withStyles(style)(AppState);
