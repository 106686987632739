/* eslint-disable */
import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  createRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  Box,
  Button,
  Grid,
  Popover,
  TextField,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  Input,
  MenuItem,
  ListItemText,
  Checkbox,
  Typography,
  Chip,
} from '@material-ui/core';

import {
  CloudUpload,
  AccountBalance,
  Group,
  Description,
  AttachMoney,
  Receipt,
  Business,
  Clear,
  Save,
  Check,
  Archive,
  Refresh,
  Add,
} from '@material-ui/icons';

import CircularProgressCentered from 'components/CircularProgressCentered';

import {
  ArchivePDFIcon,
  IconSee,
  RenameIcon,
  ShoppingCartIcon,
} from 'components/AppIcons';

/* Import actions */
import { getDepositPoolItems, uploadPDF, extractPDF } from 'actions/PDFActions';

/* Import services */
import PDFService from 'services/PDFService';
import S3FileService from 'services/S3FileService';
import ExpenseService from 'services/ExpenseService';

/* Import custom hooks */
import { useRouter } from 'hooks/routerHooks';

/* Import component */
import Layout from 'components/Layout';
import MaterialTable from 'components/MaterialTable';
import FilePreviewDialog from 'components/UploadFile/FilePreviewDialog';
import DepositHeader from './header';
import AppSearchInput from 'components/AppSearchInput';
/* Custom style */
import styles from './styles.module.css';

import spinnerService from 'services/SpinnerService';
import { DEFAULT_PAGESIZE } from 'config/Constants';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  color: 'white',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

// const POPOVER_ACTIONS = {
//   ''
// }

const tagsData = [
  {
    title: 'Juridique',
    icon: <Description />,
  },
  {
    title: 'Social',
    icon: <Group />,
  },
  {
    title: 'Fiscal',
    icon: <AttachMoney />,
  },
  {
    title: 'Banque',
    icon: <AccountBalance />,
  },
  {
    title: 'Facture client​',
    icon: <Receipt />,
  },
  {
    title: 'Facture fournisseur​',
    icon: <Receipt />,
  },
  {
    title: 'Situation',
    icon: <AccountBalance />,
  },
  {
    title: 'Etats financiers',
    icon: <Business />,
  },
];

const useStyles = makeStyles(() => ({
  filterList: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: '12px',
  },
}));

const Deposit = () => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [showProgressBar, setProgressBarAppearance] = useState(false);
  const [tagsFilter, setTagsFilter] = useState([]);
  const [depositsData, setDepositsData] = useState({
    data: [],
    page: 0,
    total: 0,
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [isRefresh, setRefresh] = useState(0);
  const loggedUserCompany = useSelector(
    (state) => state.loggedUserCompany.company
  );

  const [filePreview, setFilePreview] = useState(undefined);
  const [showFilePreviewDilog, setShowPreviewDilog] = useState(false);

  const loggedUser = useSelector((state) => state.loggedUser.user);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileTags, setFileTags] = useState(new Set());
  const [updateFileTags, setUpdateFileTags] = useState(new Set());
  const [updatedTags, setUpdatedTags] = useState();
  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedRowOId, setSelectedRowOId] = useState(null);
  const [anchorTypeEl, setAnchorTypeEl] = useState(null);
  const [editPDFField, setEditPDFField] = useState('');
  const [pageSize, setPageSize] = useState(DEFAULT_PAGESIZE);

  const tableRef = createRef();

  const formatDepositTableData = async (data) => {
    const promises = data.map(async (item) => {
      let response;

      if (item.expenseId) {
        response = await ExpenseService.fetchExpense(item.expenseId);
        return {
          ...item,
          filename: item.customFileName || item.originalFileKey,
          expenseNumber: response.data.expense_nb || '',
        };
      }

      return {
        ...item,
        filename: item.customFileName || item.originalFileKey,
        expenseNumber: '',
      };
    });

    const tableData = await Promise.all(promises);

    return tableData;
  };

  // Begin: Popup functions

  const handleOpenPopover = ({ type, event, id }) => {
    if (type === 'CHANGE_DOCUMENT_TYPE') {
      setSelectedRowOId(id);
      setAnchorTypeEl(event.currentTarget);
    }

    if (type === 'RENAME_DOCUMENT') {
      setSelectedRowOId(id);
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    // Clear rename popover
    setAnchorEl(null);
    // setSelectedRowOId(null);

    // Update deposit data after close popover
    if (updateFileTags.size) {
      setDepositsData((prev) => {
        const newData = prev.data.map((item) => {
          if (item._id === selectedRowOId) {
            const updatedTags = [...item.tags, ...Array.from(updateFileTags)];
            setUpdatedTags(updatedTags);

            return {
              ...item,
              tags: updatedTags,
            };
          }
          return item;
        });
        return { ...prev, data: newData };
      });
    }
    // Clear change type document popover
    setAnchorTypeEl(null);
    setUpdateFileTags(new Set());
  };

  useEffect(() => {
    if (updatedTags && selectedRowOId) {
      // TODO: Revert state when callling API got error
      PDFService.updateTagsDepositPoolItemFile({
        tags: updatedTags,
        depositPDFPoolId: selectedRowOId,
      });
    }
  }, [updatedTags]);

  const onChangeEditPDFInput = (e) => {
    setEditPDFField(e.target.value);
  };

  const onClickEditPDFField = async () => {
    // setTableLoading(true);
    // handleClose();

    if (editPDFField === '') return;

    await PDFService.renameDepositPoolItemFile({
      customFileName: editPDFField,
      depositPDFPoolId: selectedRowOId,
    });

    setRefresh((prev) => prev + 1);

    handleClose();

    // const editOriginFileNameResponse = await dispatch(
    //   renameOriginalFile(selectedRowOId, `${editPDFField}.pdf`)
    // );
    // setTableData((prev) => {
    //   const prevCloned = [...prev];
    //   const foundIndex = prevCloned.findIndex(
    //     (i) => i._id === editOriginFileNameResponse.data.data._id
    //   );
    //   if (foundIndex === -1) return prevCloned;
    //   prevCloned[foundIndex] = {
    //     ...prevCloned[foundIndex],
    //     customFileName: editOriginFileNameResponse.data.data.customFileName,
    //   };
    //   return prevCloned;
    // });
    // setTableLoading(false);
  };

  // End: Popup fuctions

  const handleTogglePreviewDilog = () => {
    setShowPreviewDilog((prev) => !prev);
  };

  const fetchDepositData = async (searchText, page) => {
    setTableLoading(true);
    const response = await dispatch(
      getDepositPoolItems(loggedUser.id, {
        pageSize,
        page: page ?? depositsData.page,
        isArchived: false,
        filters: {
          tag: tagsFilter,
          customFileName: searchText || '',
        },
      })
    );

    const responseData = response.data || [];
    const data = await formatDepositTableData(responseData);

    setDepositsData({
      ...depositsData,
      data: [...data],
      total: response.totalCount,
    });
    setTableLoading(false);
  };

  useEffect(() => {
    fetchDepositData();
  }, [isRefresh, depositsData.page]);

  const saveFilesInBatches = async (files, batchSize) => {
    let index = 0;

    while (index < files.length) {
      const batch = files.slice(index, index + batchSize);
      await Promise.all(batch.map((file) => saveFile(file)));
      index += batchSize;
    }
  };

  const onSave = async () => {
    try {
      spinnerService.startSpinner();
      await saveFilesInBatches(selectedFile, 1);
      setRefresh((prev) => prev + 1);

      // Reset upload form
      setSelectedFile(null);
      setFileTags(new Set());
      // return keyFile;
      return true;
    } catch (error) {
      return false;
    } finally {
      spinnerService.resetSpinner();
    }
  };

  const saveFile = async (file) => {
    const originalS3 = await dispatch(uploadPDF(file));

    const payload = {
      originalFileKey: originalS3,
      customFileName: file.name,
      numberOfPages: 0,
      // usedPages: [Number],
      tags: Array.from(fileTags),
      // markAsDone: { type: Boolean, default: false },
      companyId: loggedUserCompany._id,
      userId: loggedUser._id,
    };

    await PDFService.storeDepositPoolItem(payload);
  };

  const onArchive = async (depositPDFPoolId) => {
    try {
      const result = await PDFService.archiveDepositPoolItemFile({
        isArchived: true,
        depositPDFPoolId,
      });
      setRefresh((prev) => prev + 1);
      handleClose();
      return result;
    } catch (error) {
      return false;
    }
  };

  const renderToolbar = () => (
    <Grid
      container
      alignItems="center"
      style={{
        display: 'flex',
        justifyContent: 'end',
        marginTop: 10,
        gap: 10,
        padding: '10px 0',
      }}
    >
      <Button
        startIcon={<Clear />}
        onClick={() => {
          setSelectedFile(null);
          setFileTags(new Set());
        }}
      >
        {t('cancel')}
      </Button>

      <Button
        variant="contained"
        color="primary"
        startIcon={<Save />}
        onClick={onSave}
        // disabled={selectedIndices.length === 0}
      >
        {t('pdf_purchase.save_draft')}
      </Button>
    </Grid>
  );

  const onDrop = useCallback(async (acceptedFiles) => {
    setSelectedFile(acceptedFiles);
  }, []);

  const { getInputProps, getRootProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({ onDrop });

  const dropzoneStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const renderDragNDropZone = () => {
    const listTypesButton = () => {
      tagsData.sort(
        (first, second) => first.title.length - second.title.length
      );

      const renderTagButtons = tagsData.map((item, idx) => (
        <Button
          key={idx.toString()}
          color={fileTags.has(item.title) ? 'secondary' : 'default'}
          variant="contained"
          style={{
            borderRadius: '50px',
            color: '#5257ad',
          }}
          onClick={() => {
            if (fileTags.has(item.title)) {
              setFileTags((prev) => {
                const newTags = new Set(prev);
                newTags.delete(item.title);
                return newTags;
              });
            } else {
              setFileTags((prev) => new Set(prev).add(item.title));
            }
          }}
          startIcon={item.icon && item.icon}
        >
          {item.title}
        </Button>
      ));

      return renderTagButtons;
    };

    if (selectedFile) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: '0px 100px',
            overflow: 'auto',
            border: '1px solid #dddddd',
            borderRadius: 16,
            backgroundColor: '#5257ad',
            // padding: pdfImages && pdfImages.length > 0 ? '20px 0' : 0,
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
            padding={'10px 0px'}
          >
            {selectedFile?.length > 0 &&
              selectedFile.map((item, index) => {
                return <Typography variant="h5">{item.name}</Typography>;
              })}
          </Box>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              flexWrap: 'wrap',
              gap: '10px',
              padding: '0.5rem 5rem 2rem 5rem',
            }}
          >
            {listTypesButton()}
          </div>
        </div>
      );
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          flexWrap: 'wrap',
          justifyContent: 'center',
          gap: '0px 100px',
          overflow: 'auto',
          border: '1px solid #dddddd',
          borderRadius: 16,
          backgroundColor: '#5257ad',
          // padding: pdfImages && pdfImages.length > 0 ? '20px 0' : 0,
        }}
      >
        <div className={styles.dropzone}>
          <div {...getRootProps({ style: dropzoneStyle })}>
            <input {...getInputProps()} accept="application/pdf" />
            <CloudUpload style={{ fontSize: 80 }} />
            <h2>{t('pdf_purchase.drag_and_drop_to_upload_file')}</h2>
            <div style={{ marginBottom: 12, fontSize: 15 }}>
              {t('pdf_purchase.or')}
            </div>
            <Button
              className={styles['upload-button']}
              style={{ background: 'white' }}
              variant="contained"
              component="span"
            >
              <span style={{ color: '#5257ad' }}>
                {t('pdf_purchase.browse_file')}
              </span>
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const renderFilterBar = () => {
    return (
      <Box className={classes.filterList}>
        <FormControl style={{ width: 200, marginRight: 10 }}>
          <InputLabel id="demo-mutiple-checkbox-label">
            Type of document
          </InputLabel>
          <Select
            onClose={() => {
              setRefresh((prev) => prev + 1);
            }}
            fullWidth={true}
            multiple
            value={tagsFilter}
            onChange={(event) => setTagsFilter(event.target.value)}
            input={<Input />}
            renderValue={(selected) => selected.map((s) => t(s)).join(', ')}
          >
            {tagsData.map((tag) => (
              <MenuItem key={tag.title} value={tag.title}>
                <Checkbox
                  key={tag.title}
                  color="secondary"
                  checked={tagsFilter.indexOf(tag.title) > -1}
                />
                <ListItemText primary={tag.title} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <AppSearchInput
          queryFunction={(searchText) => fetchDepositData(searchText, 0)}
        />
      </Box>
    );
  };

  const renderTagsBar = () => {
    const foundDepositData = depositsData?.data.find(
      (item) => item._id === selectedRowOId
    );

    const filteredTags = tagsData.filter((obj) => {
      return foundDepositData?.tags?.indexOf(obj.title) == -1;
    });

    const renderTagButtons = filteredTags.map((item, idx) => (
      <Button
        key={idx.toString()}
        color={updateFileTags.has(item.title) ? 'secondary' : 'default'}
        variant="contained"
        style={{
          borderRadius: '50px',
          color: '#5257ad',
        }}
        onClick={() => {
          if (updateFileTags.has(item.title)) {
            setUpdateFileTags((prev) => {
              const newTags = new Set(prev);
              newTags.delete(item.title);
              return newTags;
            });
          } else {
            setUpdateFileTags((prev) => new Set(prev).add(item.title));
          }
        }}
        startIcon={item.icon && item.icon}
      >
        {item.title}
      </Button>
    ));

    return renderTagButtons;
  };

  const renderTable = () => {
    const renderTag = ({ tags, id }) => {
      const tagBaseStyle = {
        backgroundColor: '#02cbcd',
        color: 'white',
        fontWeight: 'bold',
      };
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px 10px' }}>
          {tags.map((item, idx) => (
            <Chip
              key={idx.toString()}
              style={{ ...tagBaseStyle }}
              label={item}
              onDelete={() => {
                setDepositsData((prev) => {
                  const newData = prev.data.map((ele) => {
                    if (ele._id === id) {
                      const updatedTags = ele.tags.filter(
                        (tag) => tag !== item
                      );
                      setSelectedRowOId(id);
                      setUpdatedTags(Array.from(updatedTags));
                      return {
                        ...ele,
                        tags: updatedTags,
                      };
                    }
                    return ele;
                  });
                  return { ...prev, data: newData };
                });
              }}
            />
          ))}
          <Chip
            // style={{ ...tagBaseStyle }}
            icon={<Add />}
            variant="outlined"
            label={t('deposit.add_more_type')}
            onClick={(e) =>
              handleOpenPopover({ type: 'CHANGE_DOCUMENT_TYPE', event: e, id })
            }
          />
          <Popover
            id={id}
            open={Boolean(anchorTypeEl)}
            anchorEl={anchorTypeEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div style={{ padding: 12, display: 'flex', gap: 20 }}>
              {selectedRowOId && renderTagsBar()}
            </div>
          </Popover>
        </div>
      );
    };

    const getFileDataFromFileKeyHandler = async (originalKeyFile = '') => {
      const filename = await S3FileService.geturl(originalKeyFile);
      const formatFilename = {
        type: filename.type,
        value: filename.url,
      };

      return formatFilename;
    };

    const handleSplitPdf = async (file, depositId) => {
      if (!file) {
        return;
      }

      const { originalOId } = await dispatch(
        extractPDF({
          file,
          indices: [],
        })
      );

      if (!originalOId) {
        return;
      }

      history.push(`/pdf/split?id=${originalOId}&depositId=${depositId}`);
    };

    const columns = [
      {
        title: t('deposit.created_at'),
        field: 'created_at',
        render: ({ created_at: createdAt }) =>
          `${new Date(createdAt).toLocaleDateString()} - ${new Date(
            createdAt
          ).toLocaleTimeString()}`,
        sorting: false,
      },
      {
        title: t('deposit.file_name'),
        field: 'filename',
        sorting: false,
      },
      {
        title: t('Type de document'),
        field: 'type',
        render: renderTag,
        sorting: false,
      },
      {
        title: t('invoices.list.nb'),
        field: 'expenseId',
        render: ({ expenseNumber, expenseId }) => {
          if (!expenseNumber) return '_';
          return (
            <Link to={`/purchases/edit/${expenseId}`}>{expenseNumber}</Link>
          );
        },
        // render: renderTag,
        sorting: false,
      },
      {
        title: ' ',
        field: 'id',
        sorting: false,
        // eslint-disable-next-line react/prop-types, react/display-name
        render: ({ _id: id, originalFileKey, isUsed }) => (
          <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
            <RenameIcon
              sm
              onClick={(e) =>
                handleOpenPopover({ type: 'RENAME_DOCUMENT', event: e, id })
              }
            />
            <Popover
              id={id}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div style={{ padding: 12, display: 'flex', gap: 20 }}>
                <TextField
                  variant="outlined"
                  label={t('pdf_purchase.rename')}
                  size="small"
                  onChange={onChangeEditPDFInput}
                />
                <IconButton
                  size="small"
                  aria-label="rename"
                  onClick={() => {
                    onClickEditPDFField();
                  }}
                >
                  <Check />
                </IconButton>
              </div>
            </Popover>
            <ArchivePDFIcon
              sm
              onClick={() => {
                // history.push(`/pdf/split?id=${id}`);
                onArchive(id);
              }}
            />
            <IconSee
              contentTooltip="deposit.buttons.view-file"
              sm
              onClick={async () => {
                setProgressBarAppearance(true);
                const file = await getFileDataFromFileKeyHandler(
                  originalFileKey
                );
                setProgressBarAppearance(false);
                setFilePreview(file);
                setShowPreviewDilog(true);
              }}
            />

            <ShoppingCartIcon
              contentTooltip="pdf_purchase.create_an_purchase"
              sm
              disabled={isUsed}
              onClick={() => {
                handleSplitPdf(originalFileKey, id);
              }}
            />
          </div>
        ),
      },
    ];

    return (
      <MaterialTable
        tableRef={tableRef}
        title={t('deposit.table_title')}
        columns={columns}
        page={depositsData.page}
        onChangePage={(p) => {
          setDepositsData({ ...depositsData, page: p });
        }}
        totalCount={depositsData.total}
        isLoading={tableLoading}
        data={depositsData.data}
        options={{
          filtering: false,
          // sorting: false,
          search: false,
          showTitle: false,
          pageSize,
        }}
        onChangeRowsPerPage={(ps) => {
          setPageSize(ps);
          setRefresh((prev) => prev + 1);
        }}
        actions={[
          {
            // eslint-disable-next-line react/display-name
            icon: () => <Refresh />,
            isFreeAction: true,
            onClick: () => setRefresh((prev) => prev + 1),
          },
        ]}
      />
    );
  };

  const renderFilePreview = (source, toggleDilog, show = false) => {
    const filePreviewDialog = source ? (
      <FilePreviewDialog
        show={show}
        toggleDialog={toggleDilog}
        content={source}
      />
    ) : null;
    return filePreviewDialog;
  };

  const renderBody = () => {
    return (
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '20px ',
        }}
      >
        {selectedFile && renderToolbar()}
        <div>{renderDragNDropZone()}</div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          {renderFilterBar()}
          <Button
            size="small"
            startIcon={<Archive />}
            variant="contained"
            color="primary"
            disabled={tableLoading}
            onClick={() => {
              history.push(`/deposit/archive`);
            }}
          >
            {t('pdf_purchase.archived_files')}
          </Button>
        </div>
        <div>{renderTable()}</div>
        <div>
          {renderFilePreview(
            filePreview,
            handleTogglePreviewDilog,
            showFilePreviewDilog
          )}
        </div>
        {showProgressBar ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              position: 'absolute',
              width: '-webkit-fill-available',
              height: '100%',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minWidth: '30%',
                minHeight: '30%',
                padding: '10px 20px',
                backgroundColor: 'white',
                boxShadow: '0 1px 5px 0 rgb(0 0 0 / 20%)',
                zIndex: '10',
              }}
            >
              <CircularProgressCentered />
              <Typography variant="h6">
                {t('deposit.please_wait_preview')}
              </Typography>
            </Box>
          </div>
        ) : null}
      </Box>
    );
  };
  return (
    <Layout
      header={<DepositHeader />}
      sidebarLeft={true}
      sidebarRight={null}
      body={renderBody()}
    />
  );
};

export default Deposit;
