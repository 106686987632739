import React, { useState, useEffect } from 'react';
import { List } from '@material-ui/core';

/* import services */
import InvoiceService from 'services/InvoiceService';
import logger from 'helpers/logger';

import queryString from 'query-string';
/* Import custom hooks */
import { useRouter } from 'hooks/routerHooks';
import CircularProgressCentered from 'components/CircularProgressCentered';
import AppDashboardListItem from '../components/AppDashboardListItem';

const TopClientInInvoice = () => {
  const [topClients, setTopClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const { history } = useRouter();
  const fetchTopClients = async () => {
    try {
      // Fetch all invoice data without pagination
      setLoading(true);
      const res = await InvoiceService.fetchInvoicePaginate('pagination=false');

      // Get list clients and their paid money (filter unknown client)
      const listClients = res.data.docs
        .map((elem) => ({
          client: elem.client.name || '',
          _id: elem.client._id,
          netTotal: elem.net_total,
        }))
        .filter((elem) => elem.client.length > 0);

      const helper = {};
      const reduceListClients = listClients.reduce((r, o) => {
        const key = o.client;

        if (!helper[key]) {
          helper[key] = { ...o }; // create a copy of o
          r.push(helper[key]);
        } else {
          helper[key].netTotal += o.netTotal;
        }

        return r;
      }, []);

      const top10Clients = reduceListClients
        .sort((a, b) => b.netTotal - a.netTotal)
        .slice(0, 10);

      setTopClients(top10Clients);
    } catch (_error) {
      logger.error({ _error });
    } finally {
      setLoading(false);
    }
  };

  const onClickClient = (client) => {
    if (!client) {
      return;
    }
    const invoiceFilters = {
      // state: ['canceled', 'rejected', 'validated', 'paid'],
      type: ['invoice'],
      client: [client._id],
    };
    history.push(
      queryString.stringifyUrl(
        {
          url: 'invoices/list',
          query: invoiceFilters,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  useEffect(() => {
    fetchTopClients();
  }, []);

  return (
    <>
      {!loading ? (
        <List style={{ padding: 0 }}>
          {topClients?.length > 0 &&
            topClients.map((client, index) => {
              return (
                <AppDashboardListItem
                  key={index}
                  onClick={() => onClickClient(client)}
                  titleLeft={client?.client}
                  titleRight={`${client?.netTotal}€`}
                />
              );
            })}
        </List>
      ) : (
        <CircularProgressCentered />
      )}
    </>
  );
};

export default TopClientInInvoice;
