import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AppPlusSelect from 'components/AppPlusSelect';
import APP_ROUTING from 'constants/appRouting';
import Header from '../../../components/Header';
import { useRouter } from '../../../hooks/routerHooks';
import { IconRefresh } from '../../../components/AppIcons';
import { useThunkDispatch } from '../../../hooks/generalHooks';
import { fetchProviders } from '../../../actions/ProviderAction';

const ProviderListHeader = ({ openProviderFormDialog }) => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const { history } = useRouter();

  const optionsCreate = useMemo(
    () => [
      {
        name: t('settings_section.providers.create_a_provider'),
        show: true,
        onClick: () => openProviderFormDialog(),
      },
      {
        name: t('settings_section.providers.import_provider_list'),
        show: true,
        onClick: () => history.push(APP_ROUTING.IMPORT_PROVIDER),
      },
    ],
    [history, openProviderFormDialog]
  );

  const fetchProvidersUsersCb = useCallback(
    () => dispatch(fetchProviders()),
    [dispatch]
  );

  return (
    <Header
      name={t('settings_section.providers.card_title')}
      spaceBetween
      goBack={() => {
        history.goBack();
      }}
    >
      <IconRefresh onClick={fetchProvidersUsersCb} />
      <AppPlusSelect items={optionsCreate} name="name" label={t('create')} />
    </Header>
  );
};

ProviderListHeader.propTypes = {
  openProviderFormDialog: PropTypes.func,
};

export default ProviderListHeader;
