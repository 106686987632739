import React, { useState } from 'react';

import { useSelector } from 'react-redux';

import Layout from 'components/Layout';

import { userCanReadExpense } from 'selectors/rightsSelector/expenseRequests';

import PurchaseListBody from './Body';
import SideBar from './SideBar';
import PurchaseListHeader from './Header';

const PurchaseList = () => {
  // const dispatch = useThunkDispatch();

  const canReadExpense = useSelector(userCanReadExpense);

  const { _id: companyId } = useSelector(
    (state) => state.loggedUserCompany.company
  );

  // const fetchedAt = useSelector((state) => state.timeStamps.expenses);

  const [refreshing, setRefreshing] = useState(false);

  function toggleRefreshing() {
    setRefreshing((prev) => !prev);
  }

  // const fetchExpensesCb = useCallback(
  //   () => dispatch(fetchExpenses(toggleRefreshing)),
  //   [dispatch]
  // );

  // useEffect(() => {
  //   if (canReadExpense && (!fetchedAt || Date.now() - fetchedAt > 900000)) {
  //     toggleRefreshing();
  //     fetchExpensesCb();
  //   }
  // }, [companyId, fetchExpensesCb]);

  return (
    <Layout
      header={
        <PurchaseListHeader
          refreshing={refreshing}
          canReadExpense={canReadExpense}
          toggleRefresh={toggleRefreshing}
        />
      }
      sidebarLeft={true}
      sidebarRight={<SideBar />}
      body={
        <PurchaseListBody
          canReadExpense={canReadExpense}
          refreshing={refreshing}
          companyId={companyId}
        />
      }
      showUserCard={true}
    />
  );
};

PurchaseList.propTypes = {};

export default PurchaseList;
