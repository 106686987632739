import { bindActionCreators } from 'redux';
import {
  fetchInvoicePDF,
  fetchClientInvoice,
  clientSignInvoice,
  clientCancelInvoice,
  clientValidateInvoice,
} from 'actions/InvoiceActions';

import { fetchClientProjects } from 'actions/ClientProjectActions';

export default (dispatch) =>
  bindActionCreators(
    {
      fetchInvoicePDF,
      fetchClientInvoice,
      clientSignInvoice,
      clientCancelInvoice,
      clientValidateInvoice,
      fetchClientProjects,
    },
    dispatch
  );
