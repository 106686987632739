import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { format, subYears } from 'date-fns';
import { Box, Collapse, Grid, useTheme } from '@material-ui/core';
import queryString from 'query-string';
/* Import helpers */
import { fiscalYearHelper } from 'helpers/InvoicesTurnoverHelpers';
// import { getBalanceTotal } from 'helpers/balanceHelper';

/* Import components */

import Header from 'components/Header';
import { InvoiceOverview } from 'components/AppDashboard';
import CompleteSignup from 'components/CompleteSignup';
import Layout from 'components/Layout';
import DebtsOverview from 'components/AppDashboard/InvoiceOverview/DebtsOverview';
import LatePaymentOverview from 'components/AppDashboard/InvoiceOverview/LatePaymentOverview';
import CardNumber from 'components/CardComponents/CardNumber';
import InvoiceStatisticOverview from 'components/AppDashboard/InvoiceStatisticOverview';
import TopProductAndClient from 'components/AppDashboard/components/TopProductAndClient';
import ExpenseTabs from 'components/AppDashboard/components/ExpenseTabs';
import TopProvider from 'components/AppDashboard/TopProvider';
import ClientProjectOverview from 'components/AppDashboard/ClientProjectOverview';
import AppDashboardCard from 'components/AppDashboard/components/AppDashboardCard';
import AppDashboardCollapse from 'components/AppDashboard/components/AppDashboardCollapse';
import ExpenseDoughnutOverview from 'components/AppDashboard/ExpenseDoughnutOverview';
import DisbursementChart from 'components/AppDashboard/DisbursementChart';
import TopBiggestDebts from 'components/AppDashboard/TopBiggestDebts';
import SvgIcon from 'components/AppIcons/SvgIcon';
import AgingBalanceOverview from 'components/AppDashboard/AgingBalance';
import { style as _style } from 'assets/jss/style';
import { getBankAccounts } from 'actions/BankAccountsActions';
import { Dashboard } from 'config/Rights';
import { hasRequestedAccess } from 'helpers/RoleHelper';
/* Import state managements */
import { globalDispatch } from 'store';
import { DashBoardAccess } from 'config/AccessGroup';
import state from './state';
import dispatch from './dispatch';

/* Import assets */
import profileIcon from '../../assets/images/profile.svg';
import companyIcon from '../../assets/images/company.svg';
import ScaleBalanced from '../../assets/icon/scale-balanced.svg';
import EuroIcon from '../../assets/icon/euro.svg';
import CreditCardPlus from '../../assets/icon/credit-card-plus.svg';
import ReceiptIcon from '../../assets/icon/receipt.svg';
import ChartMixedDollar from '../../assets/icon/chart-mixed-dollar.svg';
import CartPlusAlt from '../../assets/icon/cart-plus-alt.svg';

const { userCardWrap } = _style;

const styles = (theme) => ({
  addButton: {
    position: 'fixed',
    bottom: 0,
    right: 0,
    width: '70px',
    height: '70px',
    borderRadius: '50%',
    backgroundColor: 'red',
    color: 'white',
    margin: '0 95px 40px 0',
    border: '1px solid rgba(117, 77, 133, 0.27)',
    boxShadow: '1px 4px 4px 0 rgba(117, 77, 133, 0.27)',
  },
  whiteText: {
    color: 'white',
    fontWeight: 'bold',
  },
  missingDataWrapper: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: 50,
  },

  sideBarProfile: {
    backgroundColor: 'white',
    border: '1px solid #3d319d',
    height: '60px',
    width: '60px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
  },
  sideBarProfileText: {
    color: '#3d319d',
    textTransform: 'uppercase',
    fontSize: '11px',
    lineHeight: '15px',
    fontWeight: 'bold',
  },
  sideBarProfileWrapper: {
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '36px',
    cursor: 'pointer',
  },
  cardSection: {
    ...userCardWrap,
    padding: '20px',
    marginTop: '20px',
    marginBottom: '20px',
  },

  cardStatistics: {
    width: '100%',
    height: '100%',
    maxWidth: '310px',
  },

  cardStatisticsCustomHeight: {
    height: '130px',
  },
  cardStatisticsBox: {
    padding: '0 40px',
  },
  secondarySection: {
    padding: '30px 40px',
  },
  customMargin: {
    marginBottom: '20px',
  },

  chartContentSpacing: {
    marginTop: '20px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: theme.spacing(3),
    backgroundColor: theme.palette.neutral[50],
    padding: '40px',
  },
  containerSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    width: '100%',
  },
});

const Home = ({
  t,
  companyId,
  classes,
  history,
  userDataAreMissing,
  companyDataAreMissing,
  fetchTurnOver,
  invoiceTurnOver,
  appInitialized,
  fetchExpenseOverview,
  userCanSeeInvoiceOverview,
  userCanValidateExpense,
  userCanSeeExpenseOverview,
  expenseOverview,
  fetchRevenueOverview,
  revenueOverview,
  // bankBalances,
  // treezorBalances,
  agingBalance,
  getAgingBalance,
  getAgingBalanceOverdue,
  fetchExpenseByCategories,
  expenseByCategories,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [invoiceData, setInvoiceData] = useState({
    turnover: 0,
    overdue: 0,
    unpaid: 0,
  });

  const [turnover, setTurnover] = useState(0);
  const [expense, setExpense] = useState(0);
  const [unpaid, setUnpaid] = useState(0);
  const [overdue, setOverdue] = useState(0);
  const [filterDate, setFilterDate] = useState();
  const [treasury, setTreasury] = useState(0);
  const [collapse, setCollapse] = useState({
    saleSection: true,
    expenseSection: true,
  });

  const theme = useTheme();
  const endFiscalYear = useSelector(
    // eslint-disable-next-line no-shadow
    (state) => state.loggedUserCompany.company.endFiscalYear
  );
  // const treezorWallet = useSelector((reduxState) =>
  //   reduxState.wallet && reduxState.wallet.wallet
  //     ? reduxState.wallet.wallet[0]
  //     : null
  // );

  const bankAccounts = useSelector((reduxState) => reduxState.bankAccounts);

  // global classes
  // const cardSectionClass = classNames({
  //   [classes.cardSection]: true,
  // });

  const storeInvoiceData = () => {
    if (invoiceTurnOver) {
      if (revenueOverview.data)
        setTurnover(invoiceTurnOver.turnover + revenueOverview.data.total || 0);
      setUnpaid(invoiceTurnOver.unpaid || 0);
      setOverdue(invoiceTurnOver.overdue || 0);
    }
  };

  useEffect(() => {
    const bankData =
      bankAccounts?.data?.data?.map((item) => item.accounts) || [];
    let accounts = [];
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < bankData.length; i++) {
      accounts = [...accounts, ...bankData[i]];
    }
    const balance = accounts.reduce((sum, cur) => sum + cur.balance || 0, 0);
    setTreasury(balance);
  }, [bankAccounts]);

  useEffect(() => {
    globalDispatch(getBankAccounts(companyId));
  }, [companyId]);

  useEffect(() => {
    if (expenseOverview && expenseOverview.incurred) {
      setExpense(expenseOverview.incurred || 0);
    }
  }, [expenseOverview]);

  useEffect(() => {
    if (appInitialized) {
      fetchTurnOver();
      fetchRevenueOverview();
    }
  }, [companyId, appInitialized, fetchTurnOver, fetchRevenueOverview]);

  useEffect(() => {
    storeInvoiceData();
  }, [invoiceTurnOver, revenueOverview, companyId, storeInvoiceData]);

  function _renderSidebarRight() {
    const sideBarProfileClass = classNames({
      [classes.sideBarProfile]: true,
    });

    const sideBarProfileTextClass = classNames({
      [classes.sideBarProfileText]: true,
    });

    const sideBarProfileWrapperClass = classNames({
      [classes.sideBarProfileWrapper]: true,
    });

    if (companyDataAreMissing.length) {
      return (
        <React.Fragment>
          <div
            className={sideBarProfileWrapperClass}
            onClick={() => history.push('/account')}
          >
            <div className={sideBarProfileClass}>
              <img src={profileIcon} alt={t('user.card_title')} />
            </div>
            <p className={sideBarProfileTextClass}>{t('user.card_title')}</p>
          </div>

          <div
            className={sideBarProfileWrapperClass}
            onClick={() => history.push('/settings/company')}
          >
            <div className={sideBarProfileClass}>
              <img
                src={companyIcon}
                style={{ fill: '#000000' }}
                alt={t('company.card_title')}
              />
            </div>
            <p className={sideBarProfileTextClass}>{t('company.card_title')}</p>
          </div>
        </React.Fragment>
      );
    }

    return null;
  }

  const navigatePage = (url, queryParams) => {
    history.push(
      queryString.stringifyUrl(
        {
          url,
          query: queryParams,
        },
        { arrayFormat: 'index' }
      )
    );
  };
  // eslint-disable-next-line no-unused-vars
  const handleFilterDateChange = (date) => {
    setFilterDate(date);
  };

  const _renderCardList = () => {
    return (
      <Grid container spacing={2} xs={12} sm={12} md={8}>
        {hasRequestedAccess(Dashboard.Balancesheet) && (
          <Grid item xs={12} sm={6} md={6}>
            <CardNumber
              tooltipTitle={t('view.details')}
              data={treasury || 0}
              href="/bank/accounts"
              text={t('balances.dashboard.title')}
              icon={<SvgIcon path={ScaleBalanced} />}
              bgIcon={theme.palette.primary.main}
            />
          </Grid>
        )}
        {hasRequestedAccess(Dashboard.Result) && (
          <Grid item xs={12} sm={6} md={6}>
            <CardNumber
              icon={
                <SvgIcon path={EuroIcon} color="white" width={49} height={48} />
              }
              bgIcon={theme.palette.yellow[500]}
              text={t('turn_over.result')}
              data={turnover - expense || 0}
              readOnly
            />
          </Grid>
        )}
        {hasRequestedAccess(Dashboard.InvoiceOverview) && (
          <Grid item xs={12} sm={6} md={6}>
            <CardNumber
              tooltipTitle={t('view.details')}
              text={t('turn_over.title')}
              data={turnover}
              icon={<SvgIcon path={CreditCardPlus} />}
              bgIcon={theme.palette.accent.green}
              onClick={() => {
                const endFiscalYearDate = fiscalYearHelper(endFiscalYear);
                const [dd, mm, yyyy] = endFiscalYearDate.split('/');
                const beforeDate = filterDate?.to
                  ? format(filterDate?.to, 'yyyy-MM-dd')
                  : format(new Date(), 'yyyy-MM-dd');
                const afterDate = filterDate?.from
                  ? format(filterDate?.from, 'yyyy-MM-dd')
                  : format(
                      new Date(subYears(new Date(yyyy, mm, dd), 1)),
                      'yyyy-MM-dd'
                    );
                const invoiceFilters = {
                  state: ['overdue', 'validated', 'paid'],
                  type: ['invoice', 'credit_note', 'pre_payment'],
                  before: beforeDate,
                  after: afterDate,
                };
                navigatePage('/invoices/list', invoiceFilters);
              }}
            />
          </Grid>
        )}
        {hasRequestedAccess(Dashboard.ExpenseOverview) && (
          <Grid item xs={12} sm={6} md={6}>
            <CardNumber
              tooltipTitle={t('view.details')}
              text={t('turn_over.expenses')}
              data={expense}
              icon={<SvgIcon path={ReceiptIcon} />}
              bgIcon={theme.palette.accent.red}
              onClick={() => {
                const endFiscalYearDate = fiscalYearHelper(endFiscalYear);
                const [dd, mm, yyyy] = endFiscalYearDate.split('/');
                const beforeDate = filterDate?.to
                  ? format(filterDate?.to, 'yyyy-MM-dd')
                  : format(new Date(yyyy, mm, dd), 'yyyy-MM-dd');
                const afterDate = filterDate?.from
                  ? format(filterDate?.from, 'yyyy-MM-dd')
                  : format(
                      new Date(subYears(new Date(yyyy, mm, dd), 1)),
                      'yyyy-MM-dd'
                    );
                const expenseFilter = {
                  state: [
                    'validated',
                    'paid',
                    'payoutpending',
                    'overdue',
                    'upcoming',
                  ],
                  before: beforeDate,
                  after: afterDate,
                };
                navigatePage('/purchases/list', expenseFilter);
              }}
            />
          </Grid>
        )}

        {hasRequestedAccess(Dashboard.InvoiceOverview) && (
          <>
            <Grid item xs={12} sm={12} md={12} padding={1}>
              <InvoiceOverview
                companyId={companyId}
                hasInvoiceOverviewAccess={userCanSeeInvoiceOverview}
                fetchTurnOver={fetchTurnOver}
                invoiceTurnOver={invoiceTurnOver}
                setInvoiceData={setInvoiceData}
                appInitialized={appInitialized}
                fetchRevenueOverview={fetchRevenueOverview}
                revenueOverview={revenueOverview}
              />
            </Grid>
          </>
        )}
      </Grid>
    );
  };

  const _renderSection1 = () => (
    <>
      <Grid container justify="space-between" spacing={1}>
        {_renderCardList()}
        {hasRequestedAccess([
          Dashboard.Top10Customers,
          Dashboard.Top10ProductsServices,
        ]) && (
          <Grid container xs={12} sm={12} md={4}>
            <TopProductAndClient />
          </Grid>
        )}
      </Grid>
    </>
  );

  const _renderSection2 = () => {
    return (
      <Grid container direction="row" spacing={2}>
        {hasRequestedAccess(Dashboard.ActivityFollowUp) && (
          <Grid item xs={12} sm={12} md={6}>
            <InvoiceStatisticOverview />
          </Grid>
        )}
        {hasRequestedAccess(Dashboard.ProjectsTracking) && (
          <Grid item xs={12} sm={12} md={6}>
            <ClientProjectOverview />
          </Grid>
        )}
      </Grid>
    );
  };

  const _renderSection3 = () => {
    return (
      <Box className={classes.containerSection}>
        <AgingBalanceOverview
          companyId={companyId}
          hasInvoiceOverviewAccess={userCanSeeInvoiceOverview}
          appInitialized={appInitialized}
          agingBalance={agingBalance}
          getAgingBalance={getAgingBalance}
          getAgingBalanceOverdue={getAgingBalanceOverdue}
          unpaid={unpaid}
          overdue={overdue}
        />

        <Grid
          container
          justify="space-between"
          alignItems="stretch"
          spacing={2}
        >
          {/* section with Top 10 late customers chart */}
          <Grid item sm={12} md={6}>
            {hasRequestedAccess(Dashboard.Top10LateCustomers) && (
              <LatePaymentOverview />
            )}
          </Grid>
          {/* section with Top 10 most indebted clients chart */}
          <Grid item sm={12} md={6}>
            {hasRequestedAccess(Dashboard.Top10MostIndebtedClients) && (
              <DebtsOverview />
            )}
          </Grid>
        </Grid>
      </Box>
    );
  };

  const _renderSection4 = () => {
    return (
      <>
        {hasRequestedAccess([
          Dashboard.AnalyticalSortOfDebts,
          Dashboard.Expenses,
        ]) && (
          <ExpenseTabs
            companyId={companyId}
            fetchExpenseByCategories={fetchExpenseByCategories}
            hasExpenseValidateAccess={userCanValidateExpense}
            appInitialized={appInitialized}
            expenseByCategories={expenseByCategories}
          />
        )}
      </>
    );
  };

  const _renderSection5 = () => {
    return (
      <Grid
        container
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
      >
        <Grid item sm={12} md={6}>
          {hasRequestedAccess(Dashboard.Top10Debts) && <TopBiggestDebts />}
        </Grid>
        <Grid item sm={12} md={6}>
          {hasRequestedAccess(Dashboard.Top10PaidProviders) && <TopProvider />}
        </Grid>
      </Grid>
    );
  };

  const _renderMain = () => {
    return (
      <Box className={classes.body}>
        <CompleteSignup
          history={history}
          companyDataAreMissing={companyDataAreMissing}
          userDataAreMissing={userDataAreMissing}
        />
        {!companyDataAreMissing.length && !userDataAreMissing.length && (
          <>
            {_renderSection1()}

            <>
              {hasRequestedAccess(DashBoardAccess.section2) && (
                <> {_renderSection2()}</>
              )}
              <AppDashboardCollapse
                isOpen={collapse.saleSection}
                onToggle={(status) =>
                  setCollapse((prev) => ({ ...prev, saleSection: !status }))
                }
                title={t('dasboard.sale')}
                icon={<SvgIcon path={ChartMixedDollar} />}
              />
              <Collapse in={collapse.saleSection} style={{ width: '100%' }}>
                {hasRequestedAccess(DashBoardAccess.section3) &&
                  _renderSection3()}
              </Collapse>
            </>
            <AppDashboardCollapse
              isOpen={collapse.expenseSection}
              onToggle={(status) =>
                setCollapse((prev) => ({ ...prev, expenseSection: !status }))
              }
              title={t('expenses.expense.other_expenses')}
              icon={<SvgIcon path={CartPlusAlt} />}
            />

            {userCanSeeExpenseOverview && (
              <Collapse in={collapse.expenseSection} style={{ width: '100%' }}>
                <Box className={classes.containerSection}>
                  {hasRequestedAccess(DashBoardAccess.section4) && (
                    <>
                      <AppDashboardCard>{_renderSection4()}</AppDashboardCard>

                      {hasRequestedAccess(Dashboard.MyExpenses) && (
                        <AppDashboardCard>
                          <ExpenseDoughnutOverview
                            companyId={companyId}
                            fetchExpenseOverview={fetchExpenseOverview}
                            hasExpenseValidateAccess={userCanValidateExpense}
                            expenseOverview={expenseOverview}
                          />
                        </AppDashboardCard>
                      )}
                    </>
                  )}

                  {hasRequestedAccess(DashBoardAccess.DisbursementOverview) && (
                    <AppDashboardCard>
                      <DisbursementChart />
                    </AppDashboardCard>
                  )}
                  {hasRequestedAccess(DashBoardAccess.section5) &&
                    _renderSection5()}
                </Box>
              </Collapse>
            )}
          </>
        )}
      </Box>
    );
  };

  return (
    <Layout
      header={<Header name={t('home')} />}
      body={_renderMain()}
      sidebarLeft={true}
      sidebarRight={_renderSidebarRight()}
      showUserCard={true}
      hasCardBodyStyles={false}
    />
  );
};

Home.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  history: PropTypes.object,
  companyId: PropTypes.string,
  companyDataAreMissing: PropTypes.array,
  userDataAreMissing: PropTypes.array,
  fetchTurnOver: PropTypes.func.isRequired,
  invoiceTurnOver: PropTypes.object,
  fetchExpenseOverview: PropTypes.func,
  userCanSeeInvoiceOverview: PropTypes.bool,
  userCanValidateExpense: PropTypes.bool,
  userCanSeeExpenseOverview: PropTypes.bool,
  expenseOverview: PropTypes.object,
  appInitialized: PropTypes.bool,
  expenses: PropTypes.object,
  users: PropTypes.object,
  fetchRevenueOverview: PropTypes.func.isRequired,
  revenueOverview: PropTypes.object,
  getBalances: PropTypes.func.isRequired,
  getBalancesTreezor: PropTypes.func.isRequired,
  bankBalances: PropTypes.object.isRequired,
  treezorBalances: PropTypes.object.isRequired,
  treezor: PropTypes.object.isRequired,
  agingBalance: PropTypes.object.isRequired,
  getAgingBalance: PropTypes.func.isRequired,
  getAgingBalanceOverdue: PropTypes.func.isRequired,
  expenseByCategories: PropTypes.object.isRequired,
  fetchExpenseByCategories: PropTypes.func.isRequired,
  fetchCompanyLogo: PropTypes.func,
};

const HomeWithStyles = withStyles(styles)(Home);
const TranslatedHome = withTranslation()(HomeWithStyles);
const ConnectedHome = connect(state, dispatch)(TranslatedHome);
export default ConnectedHome;
