/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import styles from '../../../../../../assets/jss/root';

import AppButton from '../../../../../../components/AppButton';
import AppDialog from '../../../../../../components/AppDialog';
import CustomGenericDialog from '../../../../../../components/CustomGenericDialog';
import RichTextEditor from '../../../../../../components/richTextEditor';

import { useDialog } from '../../../../../../hooks/dialogHooks';

import {
  typeHelpers,
  stateHelpers,
  getInvoiceEmail,
} from '../../../../../../helpers/invoiceHelpers';

const ResendButton = ({ separatorStyle, horizontal, classes, isOverdue }) => {
  const { showDialog: showApprovalDialog, toggleDialog: toggleApprovalDialog } =
    useDialog();
  const { showDialog: showEmailDialog, toggleDialog: toggleEmailDialog } =
    useDialog();
  const { t } = useTranslation();
  const {
    values,
    isSubmitting,
    setFieldValue,
    validateForm,
    setErrors,
    setTouched,
  } = useFormikContext();

  const loggedUserCompany = useSelector(
    (state) => state.loggedUserCompany.company
  );
  const loggedUser = useSelector((state) => state.loggedUser.user);
  const emailTemplates = useSelector(
    (state) => state.emailTemplate.emailTemplates
  );
  const { state, type, client, parent } = values;

  function orderFormWithEmail() {
    return (
      typeHelpers.isOrderForm(type) && stateHelpers.isDraft(state) && !parent
    );
  }

  const ref = React.createRef();

  function sendEmail() {
    toggleEmailDialog();
    if (isOverdue) {
      setFieldValue('emailInput', ref.current.htmlToGet, false);
      setFieldValue('action', 'resend', false);
    } else {
      setFieldValue('emailInput', ref.current.htmlToGet, false);
      setFieldValue('action', 'send', false);
    }
  }

  async function send() {
    toggleApprovalDialog();

    const email = client ? client.email : null;
    if (isOverdue) {
      toggleEmailDialog();
      return;
    }

    if (typeHelpers.isInvoice(type) || typeHelpers.isCreditNote(type)) {
      setFieldValue('action', 'validate', false);
    } else if (
      (typeHelpers.isQuotation(type) || orderFormWithEmail()) &&
      email
    ) {
      toggleEmailDialog();
    } else {
      setFieldValue('action', 'send', false);
    }
  }

  async function correctToggle() {
    const email = client ? client.email : null;

    if (isOverdue) {
      toggleEmailDialog();
      return;
    }

    if (typeHelpers.isInvoice(type) || typeHelpers.isCreditNote(type)) {
      toggleApprovalDialog();
    } else if (
      (typeHelpers.isQuotation(type) || orderFormWithEmail()) &&
      email
    ) {
      toggleEmailDialog();
    } else {
      toggleApprovalDialog();
    }
  }

  function renderApproveDialog() {
    if (showApprovalDialog) {
      return (
        <AppDialog
          sm
          iconClose
          footer
          title={t('sure')}
          closeDialog={toggleApprovalDialog}
          onConfirmText={t('yes')}
          onCancelText={t('no')}
          onConfirm={send}
          contentText={t('invoice.confirm.create')}
          color="secondary"
        />
      );
    }

    return null;
  }

  function renderEmailDialog() {
    if (showEmailDialog) {
      const initialHtml = getInvoiceEmail(
        type,
        loggedUser,
        loggedUserCompany,
        emailTemplates,
        true
      );
      return (
        <CustomGenericDialog
          title={t('invoice.form.email.dialog.title')}
          onConfirm={sendEmail}
          onCancel={toggleEmailDialog}
          confirmText={t('invoice.form.email.dialog.confirm')}
          cancelText={t('no')}
          iconClose
          closeDialog={toggleEmailDialog}
          color="secondary"
        >
          <div>{t('invoice.form.email.dialog.description')}</div>
          <RichTextEditor ref={ref} initialHtml={initialHtml} />
          <span style={{ fontWeight: 'bold', fontSize: 16 }}>
            {loggedUserCompany.name}
            <br />
            {loggedUser.display_name}
          </span>
          <Grid item xs={12}>
            <div className={classes.alignRight}>
              <AppButton
                color="transparentGrey"
                text={t('no')}
                onClick={toggleEmailDialog}
              />
              <AppButton
                color="secondaryLight"
                text={t('invoice.form.email.dialog.confirm')}
                noBorder={true}
                onClick={sendEmail}
              />
            </div>
          </Grid>
        </CustomGenericDialog>
      );
    }
    return null;
  }
  if (isOverdue) {
    return (
      <Fragment>
        {renderApproveDialog()}
        {renderEmailDialog()}
        <AppButton
          fullWidth={!horizontal}
          isDisabled={isSubmitting}
          text={t('invoices.resend_to_client')}
          onClick={async () => {
            const errors = await validateForm();

            if (Object.keys(errors).length) {
              setErrors(errors);
              setTouched(errors);
              return;
            }
            correctToggle();
          }}
        />
        <div className={separatorStyle} />
      </Fragment>
    );
  }
  return null;
};

ResendButton.propTypes = {
  separatorStyle: PropTypes.string,
  invoiceId: PropTypes.string,
  horizontal: PropTypes.bool,
  classes: PropTypes.object,
  isOverdue: PropTypes.bool,
};

ResendButton.defaultProps = {
  horizontal: false,
};

export default withStyles(styles)(ResendButton);
