import {
  FETCH_CARD,
  FETCH_CARD_FAILED,
  CREATING_CARD,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAILED,
  PROCESSING_LOCK_UNLOCK_CARD,
  LOCK_UNLOCK_CARD_SUCCESS,
  LOCK_UNLOCK_CARD_FAILED,
  UPDATING_CARD_CONFIG,
  UPDATE_CARD_CONFIG_SUCCESS,
  UPDATE_CARD_CONFIG_FAILED,
  FETCH_DETAIL_CARD_SUCCESS,
  FETCHING_DETAIL_CARD,
  FETCH_DETAIL_CARD_FAILED,
} from '../../actions/Types';

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: null,
  timestamp: null,
  cards: [],
};

export default (state = INITIAL_STATE, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_CARD:
      return {
        ...state,
        success: true,
        loading: false,
        cards: action.payload,
      };
    case FETCH_CARD_FAILED:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload,
      };
    case CREATING_CARD:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case CREATE_CARD_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        cards: [...state.cards, { ...action.payload }],
      };
    case CREATE_CARD_FAILED: {
      const { error } = action.payload;

      return {
        ...state,
        success: false,
        loading: false,
        error,
      };
    }
    case PROCESSING_LOCK_UNLOCK_CARD:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case LOCK_UNLOCK_CARD_SUCCESS: {
      const { payload: newCard } = action;
      const cards = [...state.cards];
      const foundIndex = cards.findIndex((el) => el._id === newCard._id);
      if (foundIndex > -1) {
        cards[foundIndex] = { ...newCard };
      }
      return {
        ...state,
        success: true,
        loading: false,
        cards: [...cards],
        error: null,
      };
    }
    case LOCK_UNLOCK_CARD_FAILED: {
      const { error } = action.payload;

      return {
        ...state,
        success: false,
        loading: false,
        error,
      };
    }
    case UPDATING_CARD_CONFIG:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case UPDATE_CARD_CONFIG_SUCCESS: {
      const { payload: newCard } = action;
      const cards = [...state.cards];
      const foundIndex = cards.findIndex((el) => el._id === newCard._id);
      if (foundIndex > -1) {
        cards[foundIndex] = { ...newCard };
      }
      return {
        ...state,
        success: true,
        loading: false,
        cards: [...cards],
        error: null,
      };
    }
    case UPDATE_CARD_CONFIG_FAILED: {
      const { error } = action.payload;

      return {
        ...state,
        success: false,
        loading: false,
        error,
      };
    }
    case FETCHING_DETAIL_CARD:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case FETCH_DETAIL_CARD_SUCCESS: {
      const { payload: newCard } = action;
      const cards = [...state.cards];
      const foundIndex = cards.findIndex((el) => el._id === newCard._id);

      if (foundIndex > -1) {
        cards[foundIndex] = { ...newCard };
      }
      return {
        ...state,
        success: true,
        loading: false,
        cards: [...cards],
      };
    }
    case FETCH_DETAIL_CARD_FAILED: {
      const { error } = action.payload;

      return {
        ...state,
        success: false,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
};
