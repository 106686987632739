/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Layout from 'components/Layout';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { fetchExpensePdf } from 'actions/ExpensesActions';
import expenseService from 'services/ExpenseService';
import { makeStyles } from '@material-ui/core';
import colors from '../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  pdfViewContaier: {
    border: 'none',
    background: '#e2e2e2',
    height: '100%',
    minHeight: '100vh',
    overflowY: 'hidden',
    position: 'relative',
    borderRadius: '4px',
  },
  pdfPreview: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
  pdfError: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    color: colors.red,
    lineHeight: '13px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  pdfLoading: {
    position: 'absolute',
    left: '50%',
    top: '20%',
    transform: 'translateX(-50%)',
    color: colors.blue,
    lineHeight: '13px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
}));
const Pdf = ({ expenseId }) => {
  const { t } = useTranslation();
  const [pdf, setPdf] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const classes = useStyles();

  const activities = useSelector((state) => state.activities);
  const expenses = useSelector((state) => state.expenses);
  const clients = useSelector((state) => state.clients.data);
  const clientProjects = useSelector((state) => state.clientProjects.projects);

  const categories = useSelector((state) => state.categories.data);

  const company = useSelector((state) => state.loggedUserCompany.company);

  const prepareExpense = (_expense) => {
    const category = categories[_expense.category_id];
    const project = clientProjects[_expense.client_id]?.find(
      (projectItem) => projectItem.client_id === _expense.client_id
    );

    let subCategory = null;
    if (category) {
      subCategory = categories[category.parent];
    }

    const expenseData = {
      ..._expense,
      client: clients[_expense.client_id],
      activity: activities[_expense.internal_activity],
      category,
      subCategory,
      project,
    };

    return expenseData;
  };

  const fetchExpensePreviewPdf = async () => {
    if (!expenseId) {
      setError(t('invoices.pdf.errorGeneratePdf'));
    } else {
      setLoading(true);
      const data = expenses[expenseId];
      if (data?.expenses) {
        fetchExpensePdf({
          expenses: data.expenses.map((item) => prepareExpense(item)),
          company,
        }).then((res) => {
          const fileBlob = new Blob([res], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(fileBlob);
          setLoading(false);
          setPdf(fileURL);
        });
      }
    }
  };

  useEffect(() => {
    if (expenseId) {
      fetchExpensePreviewPdf();
    }
  }, [expenseId, expenses]);

  const renderContent = () => {};

  return (
    <div className={classes.pdfViewContaier}>
      {loading && (
        <div className={classes.pdfLoading}>{t('pdf.loading')}...</div>
      )}
      {pdf && <iframe className={classes.pdfPreview} src={pdf} />}
      {error && <div className={classes.pdfError}>{error}</div>}
    </div>
  );
};

Pdf.propTypes = {
  expenseId: PropTypes.string,
};

export default Pdf;
