import { FETCH_WALLET, FETCH_WALLET_FAILED } from '../../actions/Types';

const INITIAL_STATE = {
  loading: true,
  success: false,
  error: null,
  timestamp: null,
  wallet: [],
};

export default (state = INITIAL_STATE, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_WALLET:
      return {
        ...state,
        success: true,
        loading: false,
        wallet: action.payload,
      };
    case FETCH_WALLET_FAILED:
      return {
        ...state,
        success: false,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
