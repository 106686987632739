/* Packages */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import { useHistory } from 'react-router-dom';
import MenuItem from '@material-ui/core/MenuItem';
import qs from 'qs';

/* Icons */
import AddCircleIcon from '@material-ui/icons/AddCircle';

/* Constants */
import TRANSFER from 'containers/BankBBF/constants/transferMultipleLevelSelect';

/* Styles */
import useStyles from './styles';

/* Styled Components */
import { StyledMenu } from './styledComponents';

const AccountActionNewTransfer = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);

  const onClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseParent = () => {
    setAnchorEl(null);
  };

  const onClickItem = ({ path, filter, title, type }) => {
    const parseObjectToQueryString = qs.stringify({
      orderBy: 'date',
      filters: {
        type,
        state: filter,
      },
    });

    if (path) {
      history.push({
        pathname: path,
        search: `?${parseObjectToQueryString}`,
        state: {
          pageTitle: title,
        },
      });
    }
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={onClickButton}
        className={classes.actionNewTransferButton}
      >
        <AddCircleIcon style={{ marginRight: '10px' }} />
        {t('bank_bbf.new_transfer')}
      </Button>

      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseParent}
      >
        {TRANSFER.map((item) => (
          <MenuItem
            key={item.title}
            onClick={() =>
              onClickItem({
                path: item.path,
                filter: item.filter,
                title: item.title,
                type: item.type,
              })
            }
          >
            {t(`bank_bbf.${item.title}`)}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

export default AccountActionNewTransfer;
