import React from 'react';
import PropTypes from 'prop-types';
import { InputLabel, TextField, Grid } from '@material-ui/core';

const HorizontalBeforAfterDate = ({
  onBeforeChange,
  onAfterChange,
  before,
  after,
  beforeTitle,
  afterTitle,
}) => {
  return (
    <>
      <Grid
        container
        direction="row"
        // justify="space-between"
        alignItems="center"
        spacing={4}
      >
        <Grid item>
          <InputLabel id="demo-mutiple-checkbox-label">{afterTitle}</InputLabel>
          <TextField
            type="date"
            value={after}
            onChange={(e) => {
              onAfterChange(e.target.value);
            }}
            inputProps={{ max: before }}
          />
        </Grid>
        <Grid item>
          <InputLabel id="demo-mutiple-checkbox-label">
            {beforeTitle}
          </InputLabel>
          <TextField
            type="date"
            value={before}
            onChange={(e) => {
              onBeforeChange(e.target.value);
            }}
            inputProps={{ min: after }}
          />
        </Grid>
      </Grid>
    </>
  );
};

HorizontalBeforAfterDate.propTypes = {
  onBeforeChange: PropTypes.func.isRequired,
  onAfterChange: PropTypes.func.isRequired,
  before: PropTypes.string,
  after: PropTypes.string,
  beforeTitle: PropTypes.string,
  afterTitle: PropTypes.string,
};

export default HorizontalBeforAfterDate;
