import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { AppDividerS } from '../../../components/AppDividers';

import TextField from '../../../components/FormElements/textField';
import AppButton from '../../../components/AppButton';

import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';

import state from './state';
import dispatch from './dispatch';

import styles from '../../../assets/jss/root';

class IternalActivityForm extends Component {
  render() {
    const { t, errors, values, closeDialog } = this.props;

    return (
      <div className="section p-10">
        <Form>
          {errors && errors.internalError && (
            <div className="row">
              <div className="input-field col s12">
                <span className="helper-text">{errors.internalError}</span>
              </div>
            </div>
          )}
          <AppDividerS />
          <TextField
            type="text"
            name="value"
            label={t('settings_section.internal_activity.form_label_value')}
            placeholder={t(
              'settings_section.internal_activity.form_label_value'
            )}
            disabled={!!values._id}
            helperText={t(
              'settings_section.internal_activity.form_label_value'
            )}
            color="secondary"
          />

          <AppDividerS />

          <TextField
            type="text"
            name="display"
            label={t('settings_section.internal_activity.form_label_display')}
            placeholder={t(
              'settings_section.internal_activity.form_label_display'
            )}
            helperText={t(
              'settings_section.internal_activity.form_label_display'
            )}
            color="secondary"
          />
          <AppDividerS />
          <TextField
            type="text"
            name="description"
            label={t(
              'settings_section.internal_activity.form_label_description'
            )}
            placeholder={t(
              'settings_section.internal_activity.form_label_description'
            )}
            helperText={t(
              'settings_section.internal_activity.form_label_description'
            )}
            color="secondary"
          />

          <div
            style={{
              display: 'flex',
              position: 'absolute',
              bottom: 0,
              margin: 0,
              padding: 0,
              width: '93%',
              minHeight: '90px',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              backgroundColor: 'white',
              zIndex: 102,
            }}
          >
            <AppButton
              color="transparentGrey"
              text={t('cancel')}
              onClick={closeDialog}
            />

            <AppButton
              color="secondaryLight"
              text={t('draft')}
              noBorder={true}
              type="submit"
            />
          </div>
        </Form>
      </div>
    );
  }
}

IternalActivityForm.propTypes = {
  classes: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  status: PropTypes.object,
  values: PropTypes.object.isRequired,
  closeDialog: PropTypes.func,
  t: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

const AdvancesWithForm = withFormik({
  displayName: 'IternalActivityForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(IternalActivityForm);

const IternalActivityFormWithStyles = withStyles(styles)(AdvancesWithForm);

const TranslatedIternalActivityFormWithStyles = withTranslation()(
  IternalActivityFormWithStyles
);

export default connect(
  state,
  dispatch
)(TranslatedIternalActivityFormWithStyles);
