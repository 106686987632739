import React, { Component, Fragment } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import MUIDataTable from 'mui-datatables';

import _ from 'lodash';

import spinnerService from 'services/SpinnerService';
import APP_ROUTING from 'constants/appRouting';
import AppSelect from '../../../components/AppSelect';
import AppPlusSelect from '../../../components/AppPlusSelect';
import AppTextInputSecondary from '../../../components/AppTextInputSecondary';
import Header from '../../../components/Header';

import AppCheckbox from '../../../components/AppCheckbox';
import AppDialog from '../../../components/AppDialog';

import Form from '../Form';
import {
  IconDelete,
  IconEdit,
  IconRefresh,
} from '../../../components/AppIcons';
import { AppRowCenterStart } from '../../../components/AppContainers';

import state from './state';
import dispatch from './dispatch';

import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';

import styles from '../../../assets/jss/root';
import NumberFormat from '../../../helpers/NumberFormat';
import Layout from '../../../components/Layout';

export class ItemsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      selected: null,
      checked: [],
      search: '',
      showDeletedDialog: false,
      idToDelete: null,
    };

    this._onSearch = this._onSearch.bind(this);
    this._deleteSearch = this._deleteSearch.bind(this);
    this._onNewButtonClick = this._onNewButtonClick.bind(this);
    this._closeDeleteDialog = this._closeDeleteDialog.bind(this);
    this._deleteItem = this._deleteItem.bind(this);
    this._createNewItem = this._createNewItem.bind(this);

    this.onConfirmFunc = this.onConfirmFunc.bind(this);
  }

  componentDidMount() {
    spinnerService.startSpinner();
    this.props.fetchItems().finally(() => spinnerService.endSpinner());
  }

  _selectRow(checkboxStatus, index) {
    const { checked } = this.state;
    let newChecked = [...checked];

    if (checkboxStatus) {
      newChecked.push(index);
    } else {
      newChecked = newChecked.filter((item) => item !== index);
    }

    this.setState({
      checked: newChecked,
    });

    this.props.onSelect(newChecked);
  }

  _selectAll(checked) {
    const allChecked = [];
    if (checked) {
      _.forEach(this.props.items, (exp, id) => {
        allChecked.push(id);
      });
    }
    this.setState({
      checked: allChecked,
    });

    this.props.onSelect(allChecked);
  }

  _editState(showDialog, selected) {
    this.setState({ showDialog, selected });
  }

  _createNewItem(showDialog) {
    this.setState({ showDialog, selected: null });
  }

  _renderFormDialog() {
    const { t } = this.props;
    const { showDialog, selected } = this.state;

    if (showDialog) {
      return (
        <AppDialog
          title={t('item.dialog_title')}
          color="secondary"
          iconClose
          closeDialog={() => this._editState(false)}
        >
          <Form id={selected} closeDialog={() => this._editState(false)} />
        </AppDialog>
      );
    }

    return null;
  }

  _onSearch(e) {
    this.setState({ search: e.target.value });
  }

  _deleteSearch() {
    this.setState({ search: '' });
  }

  _onNewButtonClick() {
    this._editState(true);
  }

  _showDeleteDialog(idToDelete) {
    this.setState({ showDeletedDialog: true, idToDelete });
  }

  _closeDeleteDialog() {
    this.setState({ showDeletedDialog: false, idToDelete: null });
  }

  _toggleDeleteDialog(value) {
    this.setState({ showDeletedDialog: value });
  }

  async _deleteItem() {
    try {
      spinnerService.startSpinner();
      await this.props.deleteItem(
        this.state.idToDelete,
        this._closeDeleteDialog
      );
    } catch (error) {
      throw new Error(error);
    } finally {
      spinnerService.resetSpinner();
    }
  }

  _renderDeletedDialog() {
    const { t } = this.props;
    if (this.state.showDeletedDialog) {
      return (
        <AppDialog
          sm
          footer
          title={t('settings_section.item.delete_item_title')}
          closeDialog={() => {
            this._toggleDeleteDialog(false);
          }}
          onConfirmText={t('ok')}
          onCancelText={t('cancel')}
          onConfirm={this._deleteItem}
          contentText={t('settings_section.item.delete_item')}
          color="primaryLight"
        />
      );
    }

    return null;
  }

  _renderCheckBox(items, checked) {
    return (
      <AppCheckbox
        onClick={(e) => {
          this._selectAll(e.target.checked);
        }}
        checked={checked.length === _.size(items)}
        id="sectAll"
      />
    );
  }

  _renderList() {
    const { t, items, userCanUpdateItem } = this.props;
    const listItems = _.map(items)
      .filter((item) => !item.deleted)
      .map((item) => ({
        type: item.type,
        name: item.name,
        description: item.description,
        vatRate: item.vat_rate,
        netPrice: item.net_price,
        grossPrice: item.gross_price,
        id: { id: item._id, isUsed: item?.isUsed || false },
      }));

    const columns = [
      {
        label: t('item.type'),
        name: 'type',
        options: {
          // eslint-disable-next-line react/display-name
          customBodyRender: (value) => {
            return value.substr(0, 1);
          },
        },
      },
      {
        label: t('item.name'),
        name: 'name',
      },
      {
        label: t('item.description'),
        name: 'description',
      },
      {
        label: t('item.net'),
        name: 'netPrice',
        options: {
          customBodyRender: (value) => {
            return Number(value) ? `${NumberFormat.n(value)} €` : '';
          },
        },
      },
      {
        label: t('item.vat'),
        name: 'vatRate',
        options: {
          customBodyRender: (value) => {
            return `${value} %`;
          },
        },
      },
      {
        label: t('item.gross'),
        name: 'grossPrice',
        options: {
          customBodyRender: (value) => {
            return Number(value) ? `${NumberFormat.n(value)} €` : '';
          },
        },
      },
      {
        label: ' ',
        name: 'id',
        options: {
          viewColumns: false,
          filter: false,
          sort: false,
          search: false,
          // eslint-disable-next-line react/display-name
          customBodyRender: (value) => {
            if (!userCanUpdateItem) {
              return null;
            }

            return (
              <>
                {userCanUpdateItem && (
                  <IconEdit
                    sm
                    onClick={() => {
                      this._editState(true, value.id);
                    }}
                  />
                )}
                {!value?.isUsed && (
                  <IconDelete
                    sm
                    onClick={() => {
                      this._showDeleteDialog(value.id);
                    }}
                  />
                )}
              </>
            );
          },
        },
      },
    ];

    return (
      <Fragment>
        {this._renderFormDialog()}
        {this._renderDeletedDialog()}

        <MUIDataTable
          data={listItems}
          columns={columns}
          options={getMuiDatatableOptions(t, listItems.length)}
        />
      </Fragment>
    );
  }

  onConfirmFunc(func) {
    this.setState({ checked: [], search: '' });
    func();
  }

  createOptions() {
    const { t } = this.props;
    const options = [
      {
        name: t('create'),
        show: true,
        onClick: () => this._createNewItem(true),
      },
      {
        name: t('settings_section.item.import_items'),
        show: true,
        onClick: () => this.props.history.push(APP_ROUTING.IMPORT_ITEM_LIST),
      },
    ];
    return options.filter((option) => option.show);
  }

  render() {
    const {
      t,
      onConfirm,
      isDropdown,
      errorMessage,
      showError,
      fetchItems,
      userCanReadItem,
      userCanCreateItem,
    } = this.props;

    if (isDropdown) {
      return (
        <AppSelect
          color="secondaryGrey"
          label={t('invoices.add_item')}
          textOnSelectOpened={t('click.confirm')}
          onButtonClose={() => this.onConfirmFunc(onConfirm)}
          showError={showError}
          errorMessage={errorMessage}
          mandatory
        >
          <AppTextInputSecondary
            placeholder={t('search.item')}
            fullWidth={true}
            value={this.state.search}
            onChange={this._onSearch}
            withSearchIcon
            color="secondary"
          />
          {this._renderList()}
        </AppSelect>
      );
    }
    return (
      <Layout
        header={
          <Header
            name={t('settings_section.item.title')}
            spaceBetween
            goBack={() => {
              this.props.history.goBack();
            }}
          >
            <AppRowCenterStart>
              <IconRefresh onClick={fetchItems} disabled={!userCanReadItem} />
            </AppRowCenterStart>

            {userCanCreateItem && (
              <AppPlusSelect
                items={this.createOptions()}
                name="name"
                label={t('create')}
              />
            )}
          </Header>
        }
        sidebarLeft={true}
        sidebarRight={null}
        showUserCard={true}
        body={this._renderList()}
      />
    );
  }
}

ItemsList.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func,
  history: PropTypes.object,
  items: PropTypes.object,
  showCheckboxes: PropTypes.bool,
  onSelect: PropTypes.func,
  onNewButtonClick: PropTypes.func,
  fetchItems: PropTypes.func,
  deleteItem: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  isDropdown: PropTypes.bool,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  userCanCreateItem: PropTypes.bool,
  userCanReadItem: PropTypes.bool,
  userCanUpdateItem: PropTypes.bool,
};

ItemsList.defaultProps = {
  isDropdown: false,
  showError: false,
  errorMessage: null,
};

const ItemsListWithStyle = withStyles(styles)(ItemsList);
const RouterItemsListWithStyle = withRouter(ItemsListWithStyle);
const RouterItemsListWithTranslate = withTranslation()(
  RouterItemsListWithStyle
);

const RouterItemsListWithConnect = connect(
  state,
  dispatch
)(RouterItemsListWithTranslate);

export default RouterItemsListWithConnect;
