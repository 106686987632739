import axios from 'axios';

import { ID_TOKEN } from 'config/Constants';

import { selectLoggedUserCompanyId } from 'selectors/loggedUserCompanySelectors';

import { store } from '../../store';

const postError = (data) =>
  axios.post(`${process.env.REACT_APP_ERROR_REPORT_URL}/error`, {
    ...data,
    ...{
      userId: localStorage.getItem(ID_TOKEN),
      companyId: selectLoggedUserCompanyId(store.getState()),
    },
  });

// eslint-disable-next-line import/prefer-default-export
export { postError };
