import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { useFormikContext } from 'formik';

import CurrencyTextField from 'components/FormElements/CurrencyTextField';
import VATLine from 'components/VATLine';

const useStyles = makeStyles({
  root: {
    paddingTop: '0px',
    paddingBottom: '0px',
    borderBottom: 'none',
  },
});

export default function RevenuesVATLineGroup({
  readOnly = false,
  noVAT = false,
  defaultTaxes = [],
  vatRates = [],
}) {
  /**
   * The two if statements below are setting the number of Actives Vat line
   * We need it for displaying, adding and deleting Vat Lines
   * It's assuming that if VatIdsArrayFromCategory is empty,
   * the number of active Vat lines is not always 0 (vat rate can be set later)
   */
  let numberOfActiveVats = 1;
  if (defaultTaxes.length === 0) {
    if (noVAT === true) {
      numberOfActiveVats = 0;
    }
  } else {
    numberOfActiveVats = defaultTaxes.length;
  }
  if (numberOfActiveVats > 4) {
    numberOfActiveVats = 4;
  } else if (numberOfActiveVats < 0) {
    numberOfActiveVats = 0;
  }
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const [numberActiveVatLines, setnumberActiveVatLines] = useState(0);
  const [showVat, setshowVat] = useState([false, false, false, false]);
  // If WithoutVat is True We have no visible vat line
  useEffect(() => {
    if (noVAT) {
      setnumberActiveVatLines(0);
      setFieldValue('numberOfActiveVats', 0);
      setshowVat([false, false, false, false]);
    }
    setFieldValue('numberOfActiveVats', numberOfActiveVats);
  }, [noVAT]);
  useEffect(() => {
    const tempArrayShowVAT = [false, false, false, false];
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index < numberOfActiveVats; index++) {
      tempArrayShowVAT[index] = true;
    }
    setshowVat(tempArrayShowVAT);
    setnumberActiveVatLines(numberOfActiveVats);
  }, [numberOfActiveVats]);

  const calculateTotals = () => {
    if (readOnly) return;
    setFieldValue(
      'vat',
      (
        parseFloat(values.vat0) +
        parseFloat(values.vat1) +
        parseFloat(values.vat2) +
        parseFloat(values.vat3)
      ).toFixed(2)
    );
    const amount = (
      parseFloat(values.amount0) +
      parseFloat(values.amount1) +
      parseFloat(values.amount2) +
      parseFloat(values.amount3)
    ).toFixed(2);
    setFieldValue('taxBasisTotalAmount', amount);
    // TODO : ugly but for now triplicates
    const grandTotalAmount = (
      parseFloat(values.total0) +
      parseFloat(values.total1) +
      parseFloat(values.total2) +
      parseFloat(values.total3)
    ).toFixed(2);

    setFieldValue('grandTotalAmount', grandTotalAmount);
    setFieldValue('duePayableAmount', parseFloat(grandTotalAmount));
    setFieldValue('taxTotalAmount', (grandTotalAmount - amount).toFixed(2));
    setFieldValue('totalForm', grandTotalAmount);
  };

  // Effect to calculate Form Grand Total, Vat Total and Amount Total
  const timeOut = useRef(null);

  useEffect(() => {
    clearTimeout(timeOut.current);
    timeOut.current = setTimeout(() => {
      calculateTotals();
      setFieldValue('updateOfTotalDone', true);
    }, 50);
  }, [values.updateOfTotalDone]);

  // Utils function to set back Values of Vat Line to 0 in Formik on Delete
  const setFormikValues = (UpdatedValues, i) => {
    setFieldValue(`amount${i}`, parseFloat(UpdatedValues.amount));
    setFieldValue(`vatrate${i}`, parseFloat(UpdatedValues.vat_rate));
    setFieldValue(`vat${i}`, parseFloat(UpdatedValues.vat));
    setFieldValue(`total${i}`, parseFloat(UpdatedValues.total));
  };

  const onDelete = (i) => {
    const newShowVat = [...showVat];
    newShowVat[i] = false;
    setshowVat(newShowVat);
    setnumberActiveVatLines(numberActiveVatLines - 1);
    setFieldValue('numberOfActiveVats', numberActiveVatLines - 1);
    setFormikValues(
      {
        amount: 0,
        vat_rate: 0,
        vat: 0,
        total: 0,
      },
      i
    );
    setFieldValue('updateOfTotalDone', false);
  };

  const onAdd = (i) => {
    const newShowVat = [...showVat];
    newShowVat[i] = true;
    setshowVat(newShowVat);
    setnumberActiveVatLines(numberActiveVatLines + 1);
    setFieldValue('numberOfActiveVats', numberActiveVatLines + 1);
  };

  let vatrate0id;
  if (defaultTaxes[0] && defaultTaxes[0].id) {
    vatrate0id = defaultTaxes[0].id;
  } else if (values.vatrate0) {
    for (let index = 0; index < vatRates.length; index += 1) {
      const el = vatRates[index];
      if (el.value === values.vatrate0) {
        vatrate0id = el._id;
      }
    }
  }
  let vatrate1id;
  if (defaultTaxes[1] && defaultTaxes[1].id) {
    vatrate1id = defaultTaxes[1].id;
  } else if (values.vatrate1) {
    for (let index = 0; index < vatRates.length; index += 1) {
      const el = vatRates[index];
      if (el.value === values.vatrate1) {
        vatrate1id = el._id;
      }
    }
  }
  let vatrate2id;
  if (defaultTaxes[2] && defaultTaxes[2].id) {
    vatrate2id = defaultTaxes[2].id;
  } else if (values.vatrate2) {
    for (let index = 0; index < vatRates.length; index += 1) {
      const el = vatRates[index];
      if (el.value === values.vatrate2) {
        vatrate2id = el._id;
      }
    }
  }
  let vatrate3id;
  if (defaultTaxes[3] && defaultTaxes[3].id) {
    vatrate3id = defaultTaxes[3].id;
  } else if (values.vatrate3) {
    for (let index = 0; index < vatRates.length; index += 1) {
      const el = vatRates[index];
      if (el.value === values.vatrate3) {
        vatrate3id = el._id;
      }
    }
  }

  const classes = useStyles();

  const isDistanceCategorySelected =
    values.category_display ===
    t('expenses.categories.Indemnités kilométriques');

  const renderTotalVAT = () => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '8px',
          }}
        >
          <p className={classes.root} style={{ width: '30%' }}>
            {t('expenses.expense.form.total_net')}
          </p>
          <div className={classes.root}>
            <CurrencyTextField
              style={{ margin: 0 }}
              name={'taxBasisTotalAmount'}
              value={parseFloat(values.taxBasisTotalAmount).toFixed(2)}
              placeholder={'0,00'}
              disabled={true}
              required
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '8px',
          }}
        >
          <p className={classes.root} style={{ width: '30%' }}>
            {t('expenses.expense.form.vat_amount')}
          </p>
          <div className={classes.root}>
            <CurrencyTextField
              style={{ margin: 0 }}
              name={'taxTotalAmount'}
              value={parseFloat(values.taxTotalAmount).toFixed(2)}
              placeholder={'0,00'}
              disabled={true}
              required
            />
          </div>
        </div>
      </div>
    );
  };

  const renderVatLine = () => {
    return (
      <>
        {showVat[0] && (
          <>
            <Grid item xs={readOnly ? 12 : 10}>
              <VATLine
                readOnly={readOnly}
                vatRates={vatRates}
                selectedVatRate={vatrate0id}
                amount_name={'amount0'}
                vat_name={'vat0'}
                vatrate_name={'vatrate0'}
                total_name={'total0'}
                amount={values.amount0}
                vat={values.vat0}
                vat_rate={values.vatrate0}
                total={values.total0}
                key={0}
                index={0}
              />
            </Grid>
            {readOnly ? null : (
              <Grid item xs={2}>
                {numberActiveVatLines - 1 === 0 ? (
                  <div>
                    <Tooltip title={t('add_vat_line')} placement="top">
                      <IconButton
                        color="primary"
                        aria-label="add Vat Line"
                        onClick={() => {
                          onAdd(1);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : null}
              </Grid>
            )}
          </>
        )}
        {showVat[1] && (
          <>
            <Grid item xs={readOnly ? 12 : 10}>
              <VATLine
                readOnly={readOnly}
                vatRates={vatRates}
                selectedVatRate={vatrate1id}
                amount_name={'amount1'}
                vat_name={'vat1'}
                vatrate_name={'vatrate1'}
                total_name={'total1'}
                amount={values.amount1}
                vat={values.vat1}
                vat_rate={values.vatrate1}
                total={values.total1}
                key={1}
                index={1}
              />
            </Grid>
            {readOnly ? null : (
              <Grid item xs={2}>
                {!readOnly && numberActiveVatLines - 1 === 1 ? (
                  <div>
                    <Tooltip title={t('delete_this_vat_line')} placement="top">
                      <IconButton
                        color="primary"
                        aria-label="delete VAT Line"
                        onClick={() => {
                          onDelete(1);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('add_vat_line')} placement="top">
                      <IconButton
                        color="primary"
                        aria-label="add VAT Line"
                        onClick={() => {
                          onAdd(2);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : null}
              </Grid>
            )}
          </>
        )}
        {showVat[2] && (
          <>
            <Grid item xs={readOnly ? 12 : 10}>
              <VATLine
                readOnly={readOnly}
                vatRates={vatRates}
                selectedVatRate={vatrate2id}
                amount_name={'amount2'}
                vat_name={'vat2'}
                vatrate_name={'vatrate2'}
                total_name={'total2'}
                amount={values.amount2}
                vat={values.vat2}
                vat_rate={values.vatrate2}
                total={values.total2}
                key={2}
                index={2}
              />
            </Grid>
            {readOnly ? null : (
              <Grid item xs={2}>
                {!readOnly && numberActiveVatLines - 1 === 2 ? (
                  <div>
                    <Tooltip title={t('delete_this_vat_line')} placement="top">
                      <IconButton
                        color="primary"
                        aria-label="delete VAT Line"
                        onClick={() => {
                          onDelete(2);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={t('add_vat_line')} placement="top">
                      <IconButton
                        color="primary"
                        aria-label="add VAT Line"
                        onClick={() => {
                          onAdd(3);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : null}
              </Grid>
            )}
          </>
        )}
        {showVat[3] && (
          <>
            <Grid item xs={readOnly ? 12 : 10}>
              <VATLine
                readOnly={readOnly}
                vatRates={vatRates}
                selectedVatRate={vatrate3id}
                amount_name={'amount3'}
                vat_name={'vat3'}
                vatrate_name={'vatrate3'}
                total_name={'total3'}
                amount={values.amount3}
                vat={values.vat3}
                vat_rate={values.vatrate3}
                total={values.total3}
                key={3}
                index={3}
              />
            </Grid>
            {readOnly ? null : (
              <Grid item xs={2}>
                {!readOnly && numberActiveVatLines - 1 === 3 ? (
                  <div>
                    <Tooltip title={t('delete_this_vat_line')} placement="top">
                      <IconButton
                        color="primary"
                        aria-label="delete VAT Line"
                        onClick={() => {
                          onDelete(3);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                ) : null}
              </Grid>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          justifyContent="flex-end"
          alignItems="flex-end"
        >
          {!isDistanceCategorySelected && renderVatLine()}
          {noVAT ? (
            ''
          ) : (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={readOnly ? 12 : 10}>
                <Table aria-label="total details">
                  <TableBody>
                    {!isDistanceCategorySelected && renderTotalVAT()}

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: '8px',
                      }}
                    >
                      <p style={{ width: '30%' }} className={classes.root}>
                        {t('expenses.expense.form.total_gross')}
                      </p>
                      <div className={classes.root}>
                        <CurrencyTextField
                          style={{ margin: 0 }}
                          name={'grandTotalAmount'}
                          value={parseFloat(values.grandTotalAmount).toFixed(2)}
                          placeholder={'0,00'}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={readOnly ? 0 : 2}></Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

RevenuesVATLineGroup.propTypes = {
  readOnly: PropTypes.bool,
  noVAT: PropTypes.bool,
  vatRates: PropTypes.array,
  defaultTaxes: PropTypes.array,
};
