import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import { deepOrange } from '@material-ui/core/colors';
import Typography from '@material-ui/core/Typography';
import Avatar from '@material-ui/core/Avatar';
import PropTypes from 'prop-types';
import { selectLoggedUser } from 'selectors/loggedUserSelectors';
import { userCanCreateCard } from 'selectors/rightsSelector/cardRequests';
import { fetchCards } from 'actions/CardActions';
import Header from 'components/Header';
import Layout from 'components/Layout';
import CardCreation from 'components/CardComponents/CardCreation';
import NewCard from 'components/CardComponents/CardCreation/NewCard';
import CardsOwners from 'components/CardComponents/CardsOwners';
import { TYPE_AUTO_ENTREPRENEUR } from 'constants/company';
import { LOCK, LOST, STOLEN } from 'constants/treezor';
import LockIcon from '@material-ui/icons/Lock';
import { useRouter } from '../../../hooks/routerHooks';

const TabPanel = ({ indexTab, children, indexPanel }) => {
  return <div>{indexTab === indexPanel && <div>{children}</div>}</div>;
};

const useStyles = makeStyles((theme) => ({
  root: {
    paddingTop: '16px',
  },
  // Avatar styles
  orange: {
    color: theme.palette.getContrastText(deepOrange[500]),
    backgroundColor: deepOrange[500],
    // large avatar
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  addCard: {
    width: '300px',
    height: '145px',
  },
  warningWord: {
    color: 'red',
    fontWeight: 700,
  },
}));

const CardList = (props) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [indexTab, setValue] = useState(0);

  const [openCardConfig, setOpenCardConfig] = useState(false);
  /* eslint-disable no-unused-vars */
  const [virtual, setVirtual] = useState(false);
  let cards;
  /**
   * Some usefull information.
   *  - The first Letter form the user's firstname
   */
  const state = useSelector((reduxState) => reduxState);
  const { companyType } = useSelector(
    (reduxState) =>
      reduxState.loggedUserCompany?.company?.settings?.tax_registration
  );
  const { _id: loggedUserId } = useSelector(
    (reduxState) => reduxState.loggedUser.user
  );
  const canCreateCard = userCanCreateCard(state);
  const user = selectLoggedUser(state);
  const firstLette = user.firstname[0];

  // Get cards
  if (state.cardReducer.cards) {
    cards = state.cardReducer.cards;
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const renderAddCard = (isVirtualCard) => {
    return (
      <Grid item xs={12} sm={6} md={4} className={classes.addCard}>
        <NewCard
          text={t('addCard')}
          openCardConfig={() => {
            /* eslint-disable no-unused-expressions */
            isVirtualCard
              ? history.push(`/card/virtual/new?userId=${user._id}`)
              : history.push(`/card/physical/new?userId=${user._id}`);
            // ? history.push(`/card/virtual/new/${user._id}`)
            // : history.push(`/card/physical/new/${user._id}`);
            // setOpenCardConfig(true);
            // setVirtual(trueOrFalse);
          }}
        />
      </Grid>
    );
  };
  const renderCardStatus = (status) => {
    const cardStatuses = {
      [LOCK]: <LockIcon />,
      [LOST]: (
        <Typography variant="h5" className={classes.warningWord}>
          {t(`${LOST}`)}
        </Typography>
      ),
      [STOLEN]: (
        <Typography variant="h5" className={classes.warningWord}>
          {t(`${STOLEN}`)}
        </Typography>
      ),
      default: <></>,
    };

    return cardStatuses[status] || cardStatuses.default;
  };

  const renderListVirualCards = () => {
    return (
      cards &&
      cards
        .filter(
          (elm) =>
            !elm.physical &&
            !elm.virtualConverted &&
            elm.bbfUserId === loggedUserId
        )
        .map((card, idx) => {
          return (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={idx}
              style={{ cursor: 'pointer' }}
            >
              <Card
                onClick={() => {
                  if (card.statusCode === STOLEN || card.statusCode === LOST) {
                    history.push(`#`);
                  } else {
                    history.push(`/card/${card._id}`);
                  }
                }}
              >
                <CardContent>
                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="flex-start"
                  >
                    <Grid item>{renderCardStatus(card.statusCode)}</Grid>
                    <Grid item>
                      <Avatar className={classes.orange}>
                        <h2>{firstLette}</h2>
                      </Avatar>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Typography variant="h5">
                    {/* AEWEB Online Expenses */}
                    {card.maskedPan ? card.maskedPan : t('activate_card')}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          );
        })
    );
  };
  const renderPhysicalCardList = () => {
    return (
      cards &&
      cards
        .filter(
          (elm) =>
            (elm.physical || elm.virtualConverted) &&
            elm.bbfUserId === loggedUserId
        )
        .map((card, idx) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={idx}
            style={{ cursor: 'pointer' }}
          >
            <Card
              onClick={() => {
                if (card.statusCode === STOLEN || card.statusCode === LOST) {
                  history.push(`#`);
                } else {
                  history.push(`/card/${card._id}`);
                }
              }}
            >
              <CardContent>
                <Grid
                  container
                  direction="row"
                  justify="space-between"
                  alignItems="flex-start"
                >
                  <Grid item>{renderCardStatus(card.statusCode)}</Grid>
                  <Grid item>
                    <Avatar className={classes.orange}>
                      <h2>{firstLette}</h2>
                    </Avatar>
                  </Grid>
                </Grid>
              </CardContent>
              <CardContent>
                <Typography variant="h5">
                  {/* AEWEB Online Expenses */}
                  {card.maskedPan ? card.maskedPan : t('activate_card')}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))
    );
  };
  const renderCardConfig = () => {
    if (openCardConfig)
      return (
        <CardCreation
          virtual={virtual}
          setOpenCardConfig={setOpenCardConfig}
          {...props}
        />
      );
    return null;
  };

  useEffect(() => {
    dispatch(fetchCards(user._id));
  }, []);

  const renderBody = () => {
    return (
      <div>
        {renderCardConfig()}
        {!openCardConfig && (
          <>
            <Tabs
              value={indexTab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label={t('physical_cards')} />
              <Tab label={t('virtual_cards')} />
              {companyType !== TYPE_AUTO_ENTREPRENEUR && (
                <Tab label={t('other_users')} />
              )}
            </Tabs>

            <TabPanel indexTab={indexTab} indexPanel={0}>
              {/* physical Card */}
              <Grid container spacing={2} className={classes.root}>
                {renderPhysicalCardList()}
                {canCreateCard && renderAddCard(false)}
              </Grid>
            </TabPanel>
            <TabPanel indexTab={indexTab} indexPanel={1}>
              {/* Virtual cards */}
              <Grid container spacing={2} className={classes.root}>
                {renderListVirualCards()}
                {canCreateCard && renderAddCard(true)}
              </Grid>
            </TabPanel>
            <TabPanel indexTab={indexTab} indexPanel={2}>
              <CardsOwners />
            </TabPanel>
          </>
        )}
      </div>
    );
  };

  return (
    <Layout
      header={
        <Header
          name={t('cards')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  indexTab: PropTypes.any.isRequired,
  indexPanel: PropTypes.any.isRequired,
};

CardList.propTypes = {
  props: PropTypes.object,
};

export default CardList;
