/*
 * Helper function to determine which value to display in a TextField
 * This is useful as some fields can sometimes be connected to Formik but also may be overwritten by a value given in a prop
 *
 */

// eslint-disable-next-line import/prefer-default-export
export const initTextFieldValue = (propValue, formikValue) => {
  return propValue ?? formikValue;
};
