import { mapKeys } from 'lodash';

import {
  ADD_TRANSACTIONS,
  LOGGED_USER_COMPANY_SELECTED,
  LOGOUT_USER_SUCCESS,
} from '../../actions/Types';

export default (state = {}, action) => {
  switch (action.type) {
    case ADD_TRANSACTIONS:
      return mapKeys(action.transactions, '_id');
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return {};
    default:
      return state;
  }
};
