import React from 'react';
import { useTranslation } from 'react-i18next';
// import PropTypes from 'prop-types';
import Header from '../../../components/Header';
import { useRouter } from '../../../hooks/routerHooks';

const OnboardingHeader = () => {
  const { t } = useTranslation();
  const { history } = useRouter();

  return (
    <Header
      name={t('Onboarding')}
      spaceBetween
      goBack={() => {
        history.goBack();
      }}
    ></Header>
  );
};

OnboardingHeader.propTypes = {};

export default OnboardingHeader;
