/* eslint-disable no-plusplus */
/* eslint-disable prefer-destructuring */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import _, { filter, sortBy } from 'lodash';

import { format, parse } from 'date-fns';
import { makeStyles } from '@material-ui/core/styles';

import { xmlToJson } from 'helpers/fileHelper';
import { Grid } from '@material-ui/core';
import ClientService from 'services/ClientService';
import ItemService from 'services/ItemService';
import { fetchClients } from 'actions/ClientActions';
import { fetchItems } from 'actions/ItemsActions';
import { ITEM_UNITS } from 'constants/units';
import spinnerService from 'services/SpinnerService';
import AppButton from 'components/AppButton';
import { handleImportClient } from 'helpers/importHelper';
import SelectAutocomplete from '../../../../components/SelectAutocomplete';
import Actions from './Actions';
import CreateActions from './CreateActions';
import Payments from './Payments';

import { canShowActions } from '../../../../helpers/invoiceHelpers';

import { useInvoiceFormContext } from '../../../../hooks/invoiceHooks';
import { userCanReadInvoice } from '../../../../selectors/rightsSelector/invoiceRequests';
// state selector
import { getTemplatesAsArray } from '../../../../reducers/TemplateReducer/templateSelectors';

import colors from '../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  customGridItem: {
    display: 'flex',
    alignItems: 'center',
  },
  customMargin: {
    marginRight: '30px',
  },

  payments: {
    display: 'flex',
    alignItems: 'center',
    '@media only screen and (max-width: 959px)': {
      display: 'unset',
    },
  },
  importBtn: {
    color: '#FFFFFF',
    height: 40,
    fontSize: 13,
    fontWeight: 'bold',
    borderRadius: '4px',
    letterSpacing: 0.5,
    textTransform: 'uppercase',
    backgroundColor: '#3d319d',
    cursor: 'pointer',
    width: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'rgba(61, 49, 157,0.8)',
    },
  },

  rowFlexStart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '200px',
  },
  marginSM: {
    marginTop: '7px',
  },
  mGreyText: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
}));

const InvoiceInlineSidebar = ({
  inv,
  paymentsError = false,
  setPaymentsError,
}) => {
  const dispatch = useDispatch();

  const classes = useStyles();

  const {
    values: { state, type, template },
    setFieldValue,
  } = useFormikContext();

  const [readOnly, setReadOnly] = useState(false);
  const { invoiceId } = useInvoiceFormContext();
  const canReadInvoice = useSelector(userCanReadInvoice);
  const templates = useSelector(getTemplatesAsArray);
  const { _id: companyId } = useSelector((s) => s.loggedUserCompany.company);
  const items = useSelector((s) => s.items);
  const clientState = useSelector((s) => s.clients.data);
  const { t } = useTranslation();

  const clients = filter(sortBy(clientState, 'name'), (o) => !o.deleted);

  const renderOther = canShowActions(state, type, readOnly);

  useEffect(() => {
    const isState = [
      'canceled',
      'rejected',
      'validated',
      'pending',
      'paid',
    ].includes(state);

    setReadOnly(isState || (invoiceId && !canReadInvoice));
  }, [state]);

  const renderInvoiceTemplate = () => {
    if (!renderOther) {
      return null;
    }
    return (
      <Grid item xs={12} md={2}>
        <SelectAutocomplete
          name="template"
          label={t('template.card_title')}
          onChange={(tem) => {
            if (template && tem) {
              setFieldValue('template', tem._id);
            } else {
              setFieldValue('template', '');
            }
          }}
          getOptionLabel={(option) => option.display}
          values={templates}
          valueSelected={_.find(templates, (elem) => elem._id === template)}
          fullWidth
        />
      </Grid>
    );
  };

  const isSameItems = (item1, item2) => {
    return (
      item1.description === item2.description &&
      item1.name === item2.name &&
      String(item1.vat_rate) === String(item2.vat_rate) &&
      String(item1.gross_price) === String(item2.gross_price) &&
      item1.type === item2.type &&
      item1.unit === item2.unit
    );
  };

  const handleImportItems = async (jsonObject) => {
    const itemsData =
      jsonObject.CrossIndustryInvoice.SupplyChainTradeTransaction
        .IncludedSupplyChainTradeLineItem;

    const itemsObj = Object.values(items);

    const itemsFormat = itemsData.map((item) => {
      const vatRate =
        item.SpecifiedLineTradeSettlement.ApplicableTradeTax
          .RateApplicablePercent.RateApplicablePercent * 1;
      const netPrice =
        item.SpecifiedLineTradeAgreement.NetPriceProductTradePrice.ChargeAmount
          .ChargeAmount * 1;

      const grossPrice = (1 + vatRate / 100) * netPrice;

      const quantity =
        item.SpecifiedLineTradeDelivery.BilledQuantity.BilledQuantity * 1;
      const unitCode =
        item.SpecifiedLineTradeDelivery.BilledQuantity.BilledQuantity_unitCode;
      const unit = ITEM_UNITS.find((i) => i.value === unitCode);
      const unitKey = unit ? unitCode : 'C62';

      return {
        description: '',
        discount: 0,
        name: item.SpecifiedTradeProduct.Name.Name,
        type: item.SpecifiedLineTradeSettlement.ApplicableTradeTax.CategoryCode
          .CategoryCode,
        vat_rate: vatRate,
        gross_price: grossPrice,
        net_price: netPrice,
        unit: unitKey,
        quantity,
      };
    });

    const filterExists = itemsObj.filter((item) => {
      const val = itemsFormat.find((i) => isSameItems(i, item));

      return !!val;
    });

    let newItems = [];

    const filterUnexists = itemsFormat.filter((item) => {
      const val = itemsObj.find((i) => isSameItems(i, item));

      return !val;
    });

    newItems = [...newItems, ...filterExists];

    if (filterUnexists.length > 0) {
      const removedQuantityItems = filterUnexists.map((obj) =>
        _.omit(obj, ['quantity'])
      );
      const resItems = await ItemService.createItem({
        items: removedQuantityItems,
        company_id: companyId,
      });
      newItems = [...newItems, ...resItems.data];
      dispatch(fetchItems());
    }
    const finalItems = newItems.map((item) => {
      const { net_price: netPrice, gross_price: grossPrice } = item;
      const itemInImport = itemsFormat.find((i) => isSameItems(i, item));
      const quantity = itemInImport?.quantity || 1;
      return {
        ...item,
        discount: 0,
        quantity,
        unit: t(item.unit),
        unit_price: netPrice,
        net_price: parseFloat(netPrice) * quantity,
        gross_price: parseFloat(grossPrice) * quantity,
        vat_price: parseFloat(grossPrice) - parseFloat(netPrice),
      };
    });
    setFieldValue('items', [...finalItems]);
  };

  function handleFileChange(event) {
    try {
      spinnerService.startSpinner();
      const file = event.target.files[0];

      if (!file) {
        return;
      }

      const reader = new FileReader();

      reader.onload = async function (e) {
        const xmlString = e.target.result;
        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
        const jsonObject = xmlToJson(xmlDoc);

        // issue date
        const date =
          jsonObject.CrossIndustryInvoice.ExchangedDocument.IssueDateTime
            .DateTimeString.DateTimeString;

        const parsedDate = parse(date, 'yyyyMMdd', new Date());

        setFieldValue(
          'date',
          `${format(new Date(parsedDate), 'yyyy-MM-dd')}T${
            format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").split('T')[1]
          }`
        );

        // import client
        await handleImportClient(jsonObject, clients, setFieldValue, companyId);
        dispatch(fetchClients());

        // import items
        await handleImportItems(jsonObject);
        // const duePayableAmount =
        //   jsonObject.CrossIndustryInvoice.SupplyChainTradeTransaction
        //     .ApplicableHeaderTradeSettlement
        //     .SpecifiedTradeSettlementHeaderMonetarySummation.DuePayableAmount;
        // setFieldValue('duePayableAmount', duePayableAmount);

        // duedate of first payments
        const dueDate =
          jsonObject.CrossIndustryInvoice.SupplyChainTradeTransaction
            .ApplicableHeaderTradeSettlement.SpecifiedTradePaymentTerms
            .DueDateDateTime.DateTimeString.DateTimeString;
        const parsedDueDate = parse(dueDate, 'yyyyMMdd', new Date());
        setFieldValue('payments[0].date', format(parsedDueDate, 'yyyy-MM-dd'));

        // payment mode
        setFieldValue('paying_mode.bankTransfer', true);
        setFieldValue('paying_mode.cash', true);
        setFieldValue('paying_mode.check', true);
      };

      reader.readAsText(file);
    } catch (error) {
      throw new Error();
    } finally {
      spinnerService.resetSpinner();
    }
  }

  return (
    <Grid container spacing={2} justify="space-between">
      {renderInvoiceTemplate()}
      <Grid item xs={12} md={4} className={classes.customGridItem}>
        <Actions horizontal inv={inv} />
        {/* {state === 'draft' && type === 'invoice' && !invoiceId && (
          <label htmlFor="import-xml" className={classes.importBtn}>
            <input
              type="file"
              id="import-xml"
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            Import xml
          </label>
        )} */}
      </Grid>
      <Grid item xs={12} md={4} className={classes.customGridItem}>
        <CreateActions separatorStyle={classes.customMargin} />
      </Grid>
      <Grid item xs={12} className={classes.payments}>
        <Payments
          rowFlexStart={classes.rowFlexStart}
          mGreyText={classes.mGreyText}
          marginSM={classes.marginSM}
          paymentsError={paymentsError}
          setPaymentsError={setPaymentsError}
        />
      </Grid>
    </Grid>
  );
};

InvoiceInlineSidebar.propTypes = {
  readOnly: PropTypes.bool,
  inv: PropTypes.object,
  paymentsError: PropTypes.bool,
  setPaymentsError: PropTypes.func,
};

export default InvoiceInlineSidebar;
