import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { Checkbox } from 'formik-material-ui';
import { Field } from 'formik';

const MailAlert = ({ text, name }) => {
  return (
    <div
      style={{
        height: '90px',
        width: '100%',
        border: '1px solid blue',
        borderRadius: '2px',
        display: 'flex',
        paddingLeft: '15px',
        alignItems: 'center',
      }}
    >
      <Field component={Checkbox} type="checkbox" name={name} />
      <Typography
        variant="subtitle1"
        color="primary"
        style={{ marginLeft: '15px', width: '70%' }}
      >
        {text}
      </Typography>
    </div>
  );
};

MailAlert.propTypes = {
  text: PropTypes.string,
  lastone: PropTypes.bool,
  name: PropTypes.string,
};

export default MailAlert;
