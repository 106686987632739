import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
// import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { EXCEL_TEMPLATE_URL } from 'config/Constants';

const useStyles = makeStyles({
  uploadContent: {
    textAlign: 'center',
  },
  uploadButton: {
    margin: '0.5rem 0',
  },
  stepContainer: {
    padding: '0rem 5rem',
    width: '100%',
    height: '100%',
    display: 'block',
    textAlign: 'left',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    // justifyContent: 'center',
    gap: '0px 100px',
    overflow: 'auto',
    border: '1px solid #dddddd',
    borderRadius: 16,
    backgroundColor: 'white',
    marginBlock: '20px',
  },
  stepContent: {
    marginBottom: '20px',
    fontSize: '20px',
  },
  downloadContainer: {
    textAlign: 'right',
  },
  iConButton: {
    borderRadius: 16,
    backgroundColor: '#5257ad',
    margin: '8px',
  },
  button: {
    width: 250,
    height: 45,
    margin: '8px',
    borderRadius: 16,
  },
  buttonIcon: {
    marginLeft: 8,
    fontSize: 20,
  },
});

const UploadInstructions = ({ type, isCompany }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleDownloadTemplateUrl = () => {
    if (type === 'client') {
      return isCompany
        ? EXCEL_TEMPLATE_URL.CLIENT_COMPANY
        : EXCEL_TEMPLATE_URL.CLIENT_PERSONAL;
    }

    if (type === 'item') {
      return EXCEL_TEMPLATE_URL.ITEM_LIST;
    }

    if (type === 'provider') {
      return EXCEL_TEMPLATE_URL.PROVIDER;
    }
    return null;
  };
  let translateType = '';
  if (type === 'client') {
    translateType = 'clients';
  }
  if (type === 'provider') {
    translateType = 'providers';
  }
  if (type === 'item') {
    translateType = 'item';
  }
  return (
    <div className={classes.content}>
      <div className={classes.downloadContainer}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          href={handleDownloadTemplateUrl()}
          target="_blank"
        >
          {t('download_template')}
          <CloudDownloadIcon className={classes.buttonIcon} />
        </Button>
        {/* <div className={classes.downloadContainer}>
          <IconButton
            className={classes.iConButton}
            onClick={() => downloadTemplate()}
          >
            <CloudDownloadIcon />
          </IconButton>
        </div> */}
      </div>

      <div className={classes.stepContainer}>
        <div className={classes.stepContent}>
          <b>
            {t(
              `settings_section.${translateType}.upload_instructions.step_1.step`
            )}
          </b>
          <span>
            {t(
              `settings_section.${translateType}.upload_instructions.step_1.text`
            )}
          </span>
        </div>
        <div className={classes.stepContent}>
          <b>
            {t(
              `settings_section.${translateType}.upload_instructions.step_2.step`
            )}
          </b>
          <span>
            {t(
              `settings_section.${translateType}.upload_instructions.step_2.text`
            )}
          </span>
        </div>
        <div className={classes.stepContent}>
          <b>
            {t(
              `settings_section.${translateType}.upload_instructions.step_3.step`
            )}
          </b>
          <span>
            {t(
              `settings_section.${translateType}.upload_instructions.step_3.text`
            )}
          </span>
        </div>
        <div className={classes.stepContent}>
          <b>
            {t(
              `settings_section.${translateType}.upload_instructions.step_4.step`
            )}
          </b>
          <span>
            {t(
              `settings_section.${translateType}.upload_instructions.step_4.text`
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

UploadInstructions.propTypes = {
  type: PropTypes.string,
  isCompany: PropTypes.bool,
};

export default UploadInstructions;
