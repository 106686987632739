/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  accountInformationCopyButton: {
    paddingLeft: '10px',
  },

  accountInformationCopyIcon: {
    fontSize: '16px',
  },
}));

export default useStyles;
