import React, { Component } from 'react';

import PropTypes from 'prop-types';

import CustomGenericDialog from '../CustomGenericDialog';
import ClientForm from '../../containers/Clients/Form';

export default class ClientDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.escFunction = this.escFunction.bind(this);
    this.toggleClientDialogWithCheck =
      this.toggleClientDialogWithCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleOnCreated = this.handleOnCreated.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction(event) {
    const { closeDialog } = this.props;
    if (event.keyCode === 27) {
      closeDialog();
    }
  }

  toggleClientDialogWithCheck() {
    const { formChanged } = this.state;
    const { closeDialog } = this.props;
    if (!formChanged) closeDialog();
  }

  handleChange() {
    this.setState({ formChanged: true });
  }

  handleOnCreated(clients) {
    const { onCreated } = this.props;

    if (onCreated) {
      onCreated(clients);
    }

    // if (clients.length && setFieldValue) {
    //   const client = clients[0];
    //   setFieldValue('client', client);
    //   setFieldValue('client_id', client._id);
    //   setFieldValue('importClientError', false);
    //   setFieldValue('clientForm', null);
    // }
  }

  render() {
    const { t, closeDialog, values } = this.props;

    return (
      <CustomGenericDialog
        title={t('settings_section.clients.dialog_title_create')}
        closeDialog={() => this.toggleClientDialogWithCheck()}
      >
        <ClientForm
          closeDialog={() => closeDialog()}
          onCreated={this.handleOnCreated}
          onChange={this.handleChange}
          init={values?.clientForm}
          importClientError={values?.importClientError || false}
        />
      </CustomGenericDialog>
    );
  }
}

ClientDialog.propTypes = {
  values: PropTypes.object.isRequired,
  closeDialog: PropTypes.func,
  t: PropTypes.func,
  onCreated: PropTypes.func,
  setFieldValue: PropTypes.func,
};
