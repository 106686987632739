import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import TreezorRefused from 'components/CardComponents/Treezor/TreezorRefused/treezorRefused';
import CircularProgressCentered from 'components/CircularProgressCentered';
import { TreezorFormContextProvider } from 'contexts/TreezorContext/TreezorFormContext';
import { KYCStatus } from '@bebusinessfocus/compta-hub-core';
import { isEmpty } from 'lodash';
import {
  getScreenToDisplay,
  checkIfOneMoreDocumentIsRequired,
} from 'helpers/TreezorHelper';
import {
  CARDS,
  REFUSED,
  DOCS_NEEDED,
  TREEZOR_PENDING,
} from 'constants/treezor';
import { ASSOCIE } from 'constants/roles';
import PAGE_CONSTRUCTION_IMAGE from 'assets/images/page-under-construction.png';
import { useRouter } from '../../../hooks/routerHooks';
import Header from '../../../components/Header';
import Layout from '../../../components/Layout';
import TreezorOnboardingStepper from './treezorOnboardingStepper';
import style from './style';

const TreezorContainer = () => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const { treezorUser, error, treezorUsers } = useSelector(
    (state) => state.treezor
  );
  const { wallet } = useSelector((state) => state);

  const { loggedUserCompany } = useSelector((reduxState) => reduxState);
  const treezorUserLoggedUserCompany = loggedUserCompany.company.treezorUser;

  const isUsersDocumentsAreInvalid = () => {
    let retour = false;
    if (treezorUsers) {
      treezorUsers.forEach((tUser) => {
        const { documents } = tUser;
        if (documents) {
          documents.forEach((document) => {
            if (document.status === 3) {
              retour = true;
            }
          });
        }
      });
    }
    return retour;
  };

  useEffect(() => {
    if (!isEmpty(wallet.wallet)) {
      localStorage.removeItem('TreezorValue'); // clear the local storage after finishedd the onboarding
    }
  }, [wallet]);

  const getScreen = () => {
    if (!treezorUser || isEmpty(wallet.wallet)) {
      return <TreezorOnboardingStepper errorMessage={error} />;
    }
    if (
      treezorUsers &&
      treezorUsers.length > 0 &&
      isUsersDocumentsAreInvalid()
    ) {
      history.push('treezorDocumentsInvalids');
    }
    if (
      treezorUsers &&
      treezorUsers.length > 0 &&
      checkIfOneMoreDocumentIsRequired(treezorUsers)
    ) {
      // history.push('treezorDocumentsInvalids');
      // return <div></div>;
      return <Redirect to="/treezorDocumentNeeded" />;
    }
    const status = KYCStatus.getKYCStatus(
      Number(treezorUser.kycLevel),
      Number(treezorUser.kycReview)
    );
    const companyStatus = KYCStatus.getKYCStatus(
      Number(treezorUserLoggedUserCompany.kycLevel),
      Number(treezorUserLoggedUserCompany.kycReview)
    );
    const screenToDisplay = getScreenToDisplay(status, companyStatus);
    switch (screenToDisplay) {
      case CARDS:
        history.push('cards');
        break;
      case TREEZOR_PENDING:
        history.push('treezorPending');
        break;
      case DOCS_NEEDED:
        history.push('treezorDocumentsInvalids');
        break;
      case REFUSED:
        return <TreezorRefused />;
      default:
        return <CircularProgressCentered />;
    }
    return <CircularProgressCentered />;
  };

  // eslint-disable-next-line no-unused-vars
  const renderBody = () => {
    // const user = cloneDeep(loggedUser.user);
    // user.avatarBase64 = '';

    return (
      <TreezorFormContextProvider
        value={{
          company: loggedUserCompany.company,
          user: [],
          cgu: false,
          cguTreezor: false,
          showAddCollaborateur: true,
          activeStep: 0,
          role: ASSOCIE,
          rattacheAPersonneMorale: false,
          newUser: false,
          shouldCreateNewUser: false,
        }}
      >
        {getScreen()}
      </TreezorFormContextProvider>
    );
  };

  const underConstructionBody = () => {
    return (
      <div style={style.constructionPage}>
        <div style={style.constructionPage__script}>
          <div>{t('page_under_construction_script_1')}</div>
          <div>{t('page_under_construction_script_2')}</div>
        </div>
        <div>
          <img
            src={PAGE_CONSTRUCTION_IMAGE}
            width="80%"
            alt="page_under_construction"
          />
        </div>
      </div>
    );
  };

  return (
    <Layout
      header={
        <Header
          name={t('page_under_construction')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={underConstructionBody()}
    />
  );
};

// TreezorContainer.propTypes = {
//   props: PropTypes.object,
// };

export default TreezorContainer;
