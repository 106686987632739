import { Grid, Slider, Typography } from '@material-ui/core';
import { TextField, CheckboxWithLabel } from 'formik-material-ui';
import AppButton from 'components/AppButton';
import { Field, useFormikContext } from 'formik';
import React from 'react';

const OwnerForm = () => {
  const { submitForm, values, setFieldValue } = useFormikContext();

  return (
    <Grid
      style={{ padding: 10 }}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <Grid item xs={12} sm={6}>
        <Grid style={{ margin: 10 }}>
          <Field type="text" label="name" component={TextField} name="name" />
          <Field
            type="checkbox"
            Label={{ label: 'is company ?' }}
            component={CheckboxWithLabel}
            name="company"
          />
        </Grid>
        <Grid container>
          <Typography>Share (%)</Typography>
          <Slider
            valueLabelDisplay="auto"
            value={values.share}
            onChange={(e, value) => setFieldValue('share', value)}
          />
        </Grid>
        <Grid container direction="row" justify="center">
          <AppButton text="add" onClick={submitForm} />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OwnerForm;
