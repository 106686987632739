import colors from '../../../constants/colors.json';
import '../../css/fonts.css';

const style = {
  appContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 0,
    margin: 0,
    cursor: 'pointer',
  },
  plusButton: {
    color: colors.white,
    border: 'none',
    margin: 0,
    textDecoration: 'none',
    minHeight: '30px',
    minWidth: '30px',
    textTransform: 'uppercase',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: colors.red,
    padding: 0,
    cursor: 'pointer',
    '&:hover': {},
    '&:disabled': {},
  },
  mdPlusButton: {
    minHeight: '20px',
    minWidth: '20px',
  },
  iconStyle: {
    position: 'relative',
    fontSize: '24px',
    margin: 0,
    fontWeight: 200,
    padding: 0,
    top: '2px',
  },
  mdIconStyle: {
    fontSize: '14px',
    top: '1px',
  },

  divider: {
    width: '100%',
    height: '1px',
    backgroundColor: colors.red,
    opacity: 0.4,
  },

  disabled: {
    backgroundColor: colors.background,
    opacity: 1,
    color: colors.fillsGrey,
    pointerEvents: 'none',
    cursor: 'not-allowed',
  },
};

export default style;
