import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Stepper,
  Step,
  StepButton,
  Button,
  Box,
  Typography,
} from '@material-ui/core';

import emailIcon from 'assets/images/email.png';

import BankBFFChequeGuideline from '../BankBBFChequeGuideline';
import BankBBFChequeForm from '../BankBBFChequeForm';

const BankBBFCreateCheckStepper = (props) => {
  const { handleReset, handleExit } = props;
  const { t } = useTranslation();

  const steps = ['cheque.begin', 'cheque.add_check_information'];

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? steps.findIndex((step, index) => !(index in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const sections = [
    <BankBFFChequeGuideline key="1" handleConfirmComplete={handleComplete} />,
    <BankBBFChequeForm
      key="2"
      handleCompleteStep={handleComplete}
      isReviewMode={false}
    />,
    <BankBBFChequeForm
      key="3"
      handleCompleteStep={handleComplete}
      isReviewMode={true}
    />,
  ];

  return (
    <div>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={index} completed={completed[index]}>
            <StepButton onClick={handleStep(index)}>{t(label)}</StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <img
              src={emailIcon}
              style={{ maxWidth: '25%', display: 'block', margin: 'auto' }}
            />
            <Typography
              variant="h4"
              align="center"
              style={{ marginTop: '20px' }}
            >
              {t('cheque.final_step_title')}
            </Typography>

            <Typography align="center" style={{ marginTop: '20px' }}>
              {t('cheque.final_step_message_1')}
            </Typography>

            <Typography
              align="center"
              // style={{ width: '50%', marginBottom: '20px' }}
            >
              {t('cheque.final_step_message_2')}
            </Typography>

            <Typography
              variant="body1"
              style={{
                fontSize: '16px',
                textAlign: 'center',
                fontWeight: 'bold',
                marginBottom: '20px',
              }}
            >
              {t('cheque.final_step_warning')}
            </Typography>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
              }}
            >
              <Button variant="contained" color="primary" onClick={handleReset}>
                {t('cheque.create_another_cheque')}
              </Button>
              <Button variant="contained" color="default" onClick={handleExit}>
                {t('cheque.exit_button')}
              </Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>{sections[activeStep]}</React.Fragment>
        )}
      </div>
    </div>
  );
};

BankBBFCreateCheckStepper.propTypes = {
  handleReset: PropTypes.func,
  handleExit: PropTypes.func,
};

export default BankBBFCreateCheckStepper;
