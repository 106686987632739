import React from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import { AppTableRow, AppTableCell } from '../../AppTableComponents';

import NumberFormat from '../../../helpers/NumberFormat';
import { getUserById } from '../../../helpers/UsersHelpers';

const AdvancedExpense = ({ advance }) => {
  if (!advance) return <></>;

  return (
    <AppTableRow>
      <AppTableCell>
        {format(new Date(advance.date), 'dd/MM/yyyy')}
      </AppTableCell>
      <AppTableCell>{advance.expense_nb}</AppTableCell>
      <AppTableCell>
        {getUserById(advance.payee_id)
          ? getUserById(advance.payee_id).name
          : '-'}
      </AppTableCell>
      <AppTableCell>AVANCE</AppTableCell>
      <AppTableCell>&nbsp;</AppTableCell>
      <AppTableCell>&nbsp;</AppTableCell>
      <AppTableCell>&nbsp;</AppTableCell>
      <AppTableCell alignRight>-{NumberFormat.n(advance.total)}€</AppTableCell>
      <AppTableCell>&nbsp;</AppTableCell>
    </AppTableRow>
  );
};

AdvancedExpense.propTypes = {
  advance: PropTypes.object.isRequired,
};

export default AdvancedExpense;
