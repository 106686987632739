const styles = {
  root: {
    flexGrow: 1,
  },
  container: {
    spacing: 24,
    marginLeft: 20,
    marginRight: 20,
  },
  loginButton: {
    color: '#fff',
    width: '55%',
    backgroundColor: '#754d85',
    textAlign: 'center',
  },
  '@media (min-width: 320px)': {
    container: {
      spacing: 8,
      marginLeft: 5,
      marginRight: 5,
    },
  },
  registerContainer: {
    position: 'relative',
    height: '100vh',
  },
  registerContentContainer: {
    width: '80%',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '1.5em',
    textAlign: 'center',
  },
};

export default styles;
