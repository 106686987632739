import services from 'services/TreezorService';

export const fetchUserCards = async (userTag) => {
  try {
    const card = await services.fetchCards(userTag);
    return card;
  } catch (e) {
    return null;
  }
};

export const removeAllCardsOfUser = (userTag) => {
  // eslint-disable-next-line no-console
  console.log('userTag', userTag);
};
