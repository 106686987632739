import React from 'react';
import PropTypes from 'prop-types';

import { map } from 'lodash';

import SimpleExpense from './SimpleExpense';

const GroupedExpense = ({ group }) => {
  // console.log('GroupedExpense', group);
  if (!group || !group.expenses) return <></>;

  return (
    <>
      {map(group.expenses, (expense) => (
        <SimpleExpense key={expense._id} expense={expense} />
      ))}
    </>
  );
};

GroupedExpense.propTypes = {
  group: PropTypes.object.isRequired,
};

export default GroupedExpense;
