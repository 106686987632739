import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchAuthUserCompanies = () =>
  axios.get(`${process.env.REACT_APP_USER_URL}/mycompanies`);

const fetchUserCompanies = async (filter) =>
  axios.get(`${process.env.REACT_APP_USER_URL}/usercompany`, {
    params: filter,
  });

const createUserCompany = async (data) =>
  axios.post(`${process.env.REACT_APP_USER_URL}/usercompany`, data);

const deleteUserCompany = async (id) =>
  axios.delete(`${process.env.REACT_APP_USER_URL}/usercompany/${id}`);

const fetchUserCompaniesUsers = async (filter) =>
  axios.get(`${process.env.REACT_APP_USER_URL}/usercompany/users`, {
    params: filter,
  });

export default {
  fetchUserCompanies,
  createUserCompany,
  deleteUserCompany,
  fetchAuthUserCompanies,
  fetchUserCompaniesUsers,
};
