import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import { useDispatch } from 'react-redux';

import { createClient, fetchClients } from 'actions/ClientActions';
import { Client } from './useImportClients';

const useImportAction = (clientList, setClientList, checkValidateData) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const CONFIRM_TYPE = React.useState(null);
  const ROW_SELECTED = React.useState(null);
  const IS_CANCEL_CONFIRM = React.useState(true);
  const IS_SHOW_DIALOG = React.useState(false);
  const CONFIRM_MESSAGE = React.useState('');

  const [showSpinner, setShowSpinner] = React.useState(false);

  const [confirmType, setConfirmType] = CONFIRM_TYPE;
  const [rowSelected, setRowSelected] = ROW_SELECTED;
  const [isCancelConfirm, setIsCancelConfirm] = IS_CANCEL_CONFIRM;
  const [isShowDialog, setIsShowDialog] = IS_SHOW_DIALOG;
  const [confirmMessage, setConfirmMessage] = CONFIRM_MESSAGE;
  const [isOpenSnackBar, setIsOpenSnackBar] = useState(false);
  const [autoHideDuration, setAutoHideDuration] = useState(0);
  const [snackBarMessage, setSnackBarMessage] = React.useState('');
  const [snackBarType, setSnackBarType] = React.useState(null);
  const [showClientDialog, setShowClientDialog] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState(null);
  const [showProjectDialog, setShowProjectDialog] = useState(false);

  const [showClientDeleteDialog, setShowClientDeleteDialog] = useState(false);
  const [showProjectDeleteDialog, setShowProjectDeleteDialog] = useState(false);

  const [selectedProjectId, setSelectedProject] = useState(null);

  function openClientFormDialog(clientId = null) {
    setShowClientDialog(true);
    setSelectedClientId(clientId);
  }

  function closeClientFormDialog() {
    setShowClientDialog(false);
    setSelectedClientId(null);
  }

  function closeProjectFormDialog() {
    setShowProjectDialog(false);
    setSelectedClientId(null);
    setSelectedProject(null);
  }

  function toggleDeleteClientDialog() {
    setShowClientDeleteDialog((prevValue) => !prevValue);
  }

  function toggleDeleteProjectDialog() {
    setShowProjectDeleteDialog((prevValue) => !prevValue);
  }

  function showDeleteProjectDialog(projectId, clientId) {
    toggleDeleteProjectDialog();
    setSelectedClientId(clientId);
    setSelectedProject(projectId);
  }

  function closeDeleteProjectDialog() {
    toggleDeleteProjectDialog();
    setSelectedClientId(null);
    setSelectedProject(null);
  }

  function closeDeleteClientDialog() {
    toggleDeleteClientDialog();
    setSelectedClientId(null);
  }

  const handleUpdateClient = (client, id) => {
    const clientListClone = [...clientList];
    clientListClone[id] = client;
    checkValidateData(clientListClone, true);
  };

  const handleDeleteRow = (index) => {
    setConfirmType('deleteRow');
    setConfirmMessage(t('settings_section.clients.delete_client_content'));
    setIsShowDialog(true);
    setRowSelected(index);
    setIsCancelConfirm(true);
  };
  const handleRemoveProvider = () => {
    const newProvidersTable = clientList.filter(
      ({ index }) => index !== rowSelected
    );
    setClientList(newProvidersTable);
    setRowSelected(null);
    setConfirmType(null);
  };
  const handlOnCloseSnackBar = () => {
    setIsOpenSnackBar(false);
  };
  const getClientSelected = React.useCallback(() => {
    const newProviderSelected = clientList.filter(
      ({ isSelected }) => isSelected
    );

    return newProviderSelected;
  }, [clientList]);
  const handleSave = () => {
    const selectedClients = clientList.filter((client) => client.isSelected);
    if (!isEmpty(selectedClients)) {
      setConfirmType('save');
      setConfirmMessage(t('settings_section.clients.create_clients'));
      setIsShowDialog(true);
      setIsCancelConfirm(true);
    } else {
      setConfirmType('selectedEmpty');
      setConfirmMessage(`0 ${t('settings_section.clients.selected_clients')}`);
      setIsShowDialog(true);
      setIsCancelConfirm(false);
    }
  };
  const handleSubmit = React.useCallback(() => {
    const data = getClientSelected().map((elem) => Client.toJson(elem));
    const dataSubmit = { clients: data };
    if (!dataSubmit.clientHasDifferentBillingAddress) {
      delete dataSubmit.adresses;
    }
    setShowSpinner(true);
    dispatch(
      createClient(dataSubmit, (err, res) => {
        setShowSpinner(false);
        setIsOpenSnackBar(true);
        if (err) {
          setSnackBarMessage(t('settings_section.clients.create_failed'));
          setSnackBarType('error');
          setAutoHideDuration(6000);
        } else {
          const successMessage = [
            res.length,
            t('settings_section.clients.import_successfully'),
          ].join(' ');
          setSnackBarMessage(successMessage);
          setSnackBarType('success');
          setAutoHideDuration(6000);
          dispatch(fetchClients());
        }
      })
    );
    setConfirmType(null);
  }, [getClientSelected]);

  const handleOnConfirm = (isConfirm, type) => {
    if (isConfirm) {
      switch (type) {
        case 'deleteRow':
          handleRemoveProvider();
          break;
        case 'save':
          handleSubmit();
          break;
        case 'importError':
          setConfirmType(null);
          setIsShowDialog(false);
          setIsCancelConfirm(true);
          break;
        case 'importEmpty':
          setConfirmType(null);
          setIsShowDialog(false);
          setIsCancelConfirm(true);
          break;
        case 'selectedEmpty':
          setConfirmType(null);
          setIsShowDialog(false);
          setIsCancelConfirm(true);
          break;
        default:
          break;
      }
    }
  };

  const handleToggleDialog = React.useCallback(
    (bool) => {
      setIsShowDialog(bool);
    },
    [setIsShowDialog]
  );

  return {
    handleDeleteRow,
    // handleEditProvider,
    handleSave,
    handleToggleDialog,
    // handleRemoveProvider,
    handleSubmit,
    handleOnConfirm,
    handlOnCloseSnackBar,
    // updateProviderSelected,
    openClientFormDialog,
    closeClientFormDialog,
    closeProjectFormDialog,
    showDeleteProjectDialog,
    closeDeleteProjectDialog,
    closeDeleteClientDialog,
    handleUpdateClient,
    state: {
      confirmType,
      rowSelected,
      isCancelConfirm,
      isShowDialog,
      confirmMessage,
      showSpinner,
      snackBarType,
      snackBarMessage,
      isOpenSnackBar,
      autoHideDuration,
      showClientDialog,
      selectedClientId,
      showProjectDialog,
      showClientDeleteDialog,
      showProjectDeleteDialog,
      selectedProjectId,
    },
  };
};
export default useImportAction;
