import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';

import { roundedNumber } from 'helpers/utils';

import { typeHelpers } from '../../../../helpers/invoiceHelpers';

const useStyles = makeStyles({
  root: {
    width: '100%',
    overflowX: 'auto',
  },
  table: {
    minWidth: 650,
    marginTop: '30px',
  },
  tc: {
    backgroundColor: '#6D7278',
    color: '#FFFFFF',
    height: '30px',
    fontSize: '10px',
    fontFamily: 'Kumbh Sans',
    fontWeight: 'normal',
    lineHeight: '11px',
    letterSpacing: 'normal',
    textTransform: 'uppercase',
  },
});

const PrePaymentTable = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    values: {
      toPay,
      type: invoiceType,
      prepayments_display: prepaymentsDisplay,
    },
  } = useFormikContext();

  if (!typeHelpers.isPrePayment(invoiceType) || !toPay) {
    return null;
  }

  const prePayment = useMemo(
    () =>
      prepaymentsDisplay.find(({ description, amount }) => {
        return description === toPay.description && amount === toPay.amount;
      }),
    []
  );

  return (
    <Table className={classes.table} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell className={classes.tc}>
            {t('template.form_label_description')}
          </TableCell>
          <TableCell className={classes.tc}>
            {t('invoices.amount_net')}
          </TableCell>
          <TableCell className={classes.tc}>{t('item.vat')} %</TableCell>
          <TableCell className={classes.tc}>
            {t('invoices.total_with_vat')}
          </TableCell>
          <TableCell className={classes.tc}>{t('invoices.due_date')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        <TableRow>
          <TableCell component="th" scope="row">
            {prePayment.description}
          </TableCell>
          <TableCell component="th" scope="row">
            <p className={classes.mGreyText}>
              {roundedNumber(prePayment.net_amount)} €
            </p>
          </TableCell>
          <TableCell component="th" scope="row">
            <p className={classes.mGreyText}>
              {roundedNumber(prePayment.tax_rate)} %
            </p>
          </TableCell>
          <TableCell component="th" scope="row">
            <p className={classes.mGreyText}>
              {roundedNumber(prePayment.amount)} €
            </p>
          </TableCell>
          <TableCell component="th" scope="row">
            <p className={classes.mGreyText}>
              {format(new Date(prePayment.due_date), 'dd/MM/yyyy')}
            </p>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
};

export default PrePaymentTable;
