import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Menu, MenuItem, Typography } from '@material-ui/core';
import SvgIcon from 'components/AppIcons/SvgIcon';
import PenIcon from 'assets/icon/pen-outline.svg';
import TrashIcon from 'assets/icon/trash-outline.svg';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

const useStyles = makeStyles((theme) => ({
  menuList: {
    '& .MuiMenu-paper': {
      borderRadius: '8px',
      border: `1px solid ${theme.palette.neutral[50]}`,
      boxShadow:
        '0px 1px 3px 0px rgba(0, 0, 0, 0.10), 0px 1px 2px -1px rgba(0, 0, 0, 0.10)',
      padding: '12px 20px',
    },
  },
  menuItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '10px 20px',
    borderRadius: '8px',
    '&:hover': {
      backgroundColor: theme.palette.primary[50],
    },
  },
}));

const MenuConversation = (props) => {
  const { menuAnchorEl, handleCloseMenu, setShowDeleteDialog } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();

  return (
    <Box>
      <Menu
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={handleCloseMenu}
        anchorOrigin={{ horizontal: 'left' }}
        transformOrigin={{ horizontal: 'right' }}
        className={classes.menuList}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            setFieldValue('rename', true);
            handleCloseMenu();
          }}
        >
          <SvgIcon path={PenIcon} />
          <Typography variant="body1">{t('agent.menu.rename')}</Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            handleCloseMenu();
            setShowDeleteDialog(true);
          }}
        >
          <SvgIcon path={TrashIcon} />
          <Typography variant="body1">{t('agent.menu.delete')}</Typography>
        </MenuItem>
      </Menu>
    </Box>
  );
};

MenuConversation.propTypes = {
  handleCloseMenu: PropTypes.func,
  menuAnchorEl: PropTypes.object.isRequired,
  setShowDeleteDialog: PropTypes.func,
};

export default MenuConversation;
