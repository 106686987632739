import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Currencies } from '@bebusinessfocus/compta-hub-core';

import { fetchCardTransactions } from 'actions/CardTransactionActions';
import NumberFormat from 'helpers/NumberFormat';

const CardTransactions = ({ cardId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const cardTransactions = useSelector(
    (state) => state.cardTransactions && state.cardTransactions.cardTransactions
  );

  useEffect(() => {
    dispatch(fetchCardTransactions(cardId));
  }, []);

  const paymentStatusFomarter = (status) => {
    switch (status) {
      case 'A':
        return t('Accepted');
      case 'R':
        return t('Refund');
      case 'S':
        return t('Settled');
      case 'C':
        return t('Cleared');
      case 'I':
        return t('Declined');
      case 'V':
        return t('Reversed');
      default:
        return 'N/A';
    }
  };

  const currencyFomarter = (numericCode) => {
    const foundCurrency = Currencies.find(
      (currency) => currency.NumericCode === Number(numericCode)
    );

    return foundCurrency ? foundCurrency.AlphabeticCode : 'N/A';
  };

  const filterTransactionsByPaymentId = (transactions) => {
    // Sort transactions by created date
    const sortedTransactions = [...transactions].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    );

    // Remove transactions have duplicate paymentId
    const filteredTransactions = sortedTransactions.filter(
      (value, index, self) =>
        index === self.findIndex((item) => item.paymentId === value.paymentId)
    );

    return filteredTransactions;
  };

  const filteredTransactions = filterTransactionsByPaymentId(cardTransactions);

  const renderTableBody = () => {
    return filteredTransactions.length === 0
      ? t('no_transactions_yet')
      : filteredTransactions.map((row, idx) => (
          <TableRow key={idx}>
            <TableCell component="th" scope="row">
              <Typography variant="p">{row.paymentLocalTime}</Typography>
              <Typography variant="p" component="h5">
                {paymentStatusFomarter(row.paymentStatus)}
              </Typography>
            </TableCell>
            <TableCell>
              {NumberFormat.n(row.paymentAmount)}{' '}
              {currencyFomarter(row.paymentCurrency)}
            </TableCell>
            <TableCell>
              <Typography variant="p">{row.merchantName}</Typography>
              <Typography variant="p" component="h5">
                {`${row.merchantAddress} ${row.merchantCity}`}
              </Typography>
            </TableCell>
          </TableRow>
        ));
  };

  return (
    <div>
      <Typography
        variant="button"
        style={{ fontSize: '20px', color: '#02cbcd' }}
      >
        Transactions
      </Typography>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>{t('date')}</TableCell>
              <TableCell>{t('amount')}</TableCell>
              <TableCell>{t('retailer')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

CardTransactions.propTypes = {
  cardId: PropTypes.any.isRequired,
};

export default CardTransactions;
