import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  columnStartStretch: {
    display: 'flex',
    flexDirection: 'column',
  },
  blueContainerStart: {
    backgroundColor: colors.backgroundGrey,
    color: colors.white,
    height: '34px',
    width: '260px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 14px',
    fontWeight: 'normal',
    fontSize: '10px',
  },
  lWhiteContainerStart: {
    backgroundColor: colors.white,
    color: 'black',
    height: '34px',
    width: '382px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 14px',
    fontWeight: 'normal',
    fontSize: '10px',
  },
  smGreyTextPadding: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '11px',
    paddingRight: '20px',
    width: '45px',
  },
  mGreyText: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '13px',
  },
}));

const BankDetails = ({ invoiceBankDetail }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const company = useSelector((state) => state.loggedUserCompany.company);
  const bankDetails = invoiceBankDetail || company.bank_details[0];

  if (!bankDetails) {
    return null;
  }

  return (
    <div className={classes.columnStartStretch}>
      <div className={classes.blueContainerStart} style={{ width: '382px' }}>
        <p>{t('invoices.bank_details')}</p>
      </div>
      <div className={classes.lWhiteContainerStart}>
        <p className={classes.smGreyTextPadding}>{t('invoices.bank_name')}</p>
        <p className={classes.mGreyText}>{bankDetails.bank_name}</p>
      </div>
      <div className={classes.lWhiteContainerStart}>
        <p className={classes.smGreyTextPadding}>{t('invoices.IBAN')}</p>
        <p className={classes.mGreyText}>{bankDetails.iban}</p>
      </div>
      <div className={classes.lWhiteContainerStart}>
        <p className={classes.smGreyTextPadding}>{t('invoices.BIC')}</p>
        <p className={classes.mGreyText}>{bankDetails.bic}</p>
      </div>
    </div>
  );
};

BankDetails.propTypes = {
  invoiceBankDetail: PropTypes.object,
};

export default BankDetails;
