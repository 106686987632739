import { expenseModel } from 'models/expense';

const initialValues = (props) => {
  const { id: expenseId } = props.match.params;
  const { expenses } = props;
  // TODO
  // const { company } = props.loggedUserCompany;
  // const category =
  //   props.location && props.location.state
  //     ? props.location.state.category
  //     : null;

  if (!expenseId) return expenseModel();

  return expenses[expenseId];
};

export default initialValues;
