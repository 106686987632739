import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getThemeColor } from 'helpers/utils';
import { useRouter } from 'hooks/routerHooks';
import { add, format, sub } from 'date-fns';
import queryString from 'query-string';
import NumberFormat from 'helpers/NumberFormat';

import ApexChart from 'components/ApexChart';
import SvgIcon from 'components/AppIcons/SvgIcon';
import CircularProgressCentered from 'components/CircularProgressCentered';
import AppError from 'components/AppError';
import AppDashboardCard from '../components/AppDashboardCard';

import EuroIcon from '../../../assets/icon/euro.svg';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    alignSelf: 'stretch',
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
  },
  contentLeft: {
    display: 'flex',
    flex: '1 0 0',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
    cursor: 'pointer',
  },
  contentLeftSubTitle: {
    color: theme.palette.neutral[500],
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  contentLeftBoxTotal: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(0.25),
  },
  contentLeftTotal: {
    color: theme.palette.teal[600],
    fontSize: '1.875rem',
    fontWeight: 700,
    lineHeight: '1.875rem',
  },
  contentRight: {
    display: 'flex',
    gap: theme.spacing(5),
    alignItems: 'center',
  },

  dataLabelContainer: ({ onClick }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    cursor: onClick ? 'pointer' : 'inherit',
  }),
  ellipse: ({ color }) => ({
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: color,
  }),
  dataLabelTitle: {
    color: theme.palette.neutral[500],
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  dataLabelBoxTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  dataLabelBoxAmount: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.25),
  },
  dataLabelAmount: {
    color: theme.palette.neutral[700],
    fontSize: '1.25rem',
    lineHeight: '1.875rem',
    fontWeight: 700,
  },
}));

const DataLabel = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { title, amount, onClick } = props;
  return (
    <Box className={classes.dataLabelContainer} onClick={onClick}>
      <Box className={classes.dataLabelBoxTitle}>
        <Box className={classes.ellipse} />
        <span className={classes.dataLabelTitle}>{title}</span>
      </Box>
      <Box className={classes.dataLabelBoxAmount}>
        <span className={classes.dataLabelAmount}>{amount}</span>
        <SvgIcon
          path={EuroIcon}
          color={theme.palette.neutral[800]}
          width={20}
          height={20}
        />
      </Box>
    </Box>
  );
};

const fields = [
  'under8',
  'f8to15',
  'f15to21',
  'f21to30',
  'f30to45',
  'f45to60',
  'over60',
];

const ranges = [
  {
    from: 0,
    to: 8,
  },
  {
    from: 8,
    to: 15,
  },
  {
    from: 15,
    to: 21,
  },
  {
    from: 21,
    to: 30,
  },
  {
    from: 30,
    to: 45,
  },
  {
    from: 45,
    to: 60,
  },
];

const AgingBalanceOverview = (props) => {
  const {
    companyId,
    appInitialized,
    getAgingBalance,
    getAgingBalanceOverdue,
    unpaid,
    overdue,
    agingBalance: { agingBalanceComing, agingBalanceOverdue },
  } = props;

  const classes = useStyles(props);
  const theme = useTheme();
  const { t } = useTranslation();
  const { history } = useRouter();
  const labels = fields.map((field) => t(`agingBalance.overdue.${field}`));

  const renderSeriesData = () => {
    const seriesData = {};
    if (agingBalanceComing?.data) {
      seriesData.agingBalanceComingData = fields.map(
        (field) => agingBalanceComing?.data[field]
      );
    }
    if (agingBalanceOverdue?.data) {
      seriesData.agingBalanceOverdueData = fields.map(
        (field) => agingBalanceOverdue?.data[field]
      );
    }
    return seriesData;
  };

  const { agingBalanceOverdueData, agingBalanceComingData } =
    renderSeriesData();

  const getElementAtEvent = (dataPointIndex, seriesName) => {
    const type =
      seriesName === t('agingBalance.coming.title') ? 'coming' : 'overdue';
    const invoiceFilters = {
      state: [type === 'coming' ? 'validated' : 'overdue'],
      type: ['invoice', 'credit_note', 'pre_payment'],
    };
    if (dataPointIndex === 6) {
      if (type === 'coming')
        invoiceFilters.paymentAfter = format(
          add(new Date(), {
            days: 60,
          }),
          'yyyy-MM-dd'
        );
      else
        invoiceFilters.paymentBefore = format(
          sub(new Date(), {
            days: 60,
          }),
          'yyyy-MM-dd'
        );
    } else {
      invoiceFilters.paymentBefore =
        type === 'coming'
          ? format(
              add(new Date(), {
                days: ranges[dataPointIndex].to,
              }),
              'yyyy-MM-dd'
            )
          : format(
              sub(new Date(), {
                days: ranges[dataPointIndex].from,
              }),
              'yyyy-MM-dd'
            );
      invoiceFilters.paymentAfter =
        type === 'coming'
          ? format(
              add(new Date(), {
                days: ranges[dataPointIndex].from,
              }),
              'yyyy-MM-dd'
            )
          : format(
              sub(new Date(), {
                days: ranges[dataPointIndex].to,
              }),
              'yyyy-MM-dd'
            );
    }
    history.push(
      queryString.stringifyUrl(
        { url: 'invoices/list', query: invoiceFilters },
        { arrayFormat: 'index' }
      )
    );
  };

  const navigatePage = (url, queryParams) => {
    history.push(
      queryString.stringifyUrl(
        {
          url,
          query: queryParams,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  const formatAmount = (amount) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(amount)) {
      return `${NumberFormat.n(amount, 0)}`;
    }
    return `${amount}`;
  };

  const chartData = {
    series: [
      {
        name: t('agingBalance.overdue.title'),
        data: agingBalanceOverdueData,
      },
      {
        name: t('agingBalance.coming.title'),
        data: agingBalanceComingData,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection(event, chartContext, opts) {
            const {
              dataPointIndex,
              seriesIndex,
              w: {
                globals: { seriesNames },
              },
            } = opts;
            getElementAtEvent(dataPointIndex, seriesNames[seriesIndex]);
          },
        },
      },
      colors: [
        getThemeColor(theme.palette.red[500]),
        getThemeColor(theme.palette.teal[400]),
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      labels,
      xaxis: {
        show: true,
        labels: {
          style: {
            colors: getThemeColor(theme.palette.neutral[500]),
            fontWeight: 400,
            fontSize: '0.875rem',
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: getThemeColor(theme.palette.neutral[500]),
            fontWeight: 600,
            fontSize: '0.875rem',
          },
          formatter(val) {
            return `${val}€`;
          },
        },
      },

      legend: { show: false },
    },
  };

  const renderBody = () => {
    if (agingBalanceComing?.loading || agingBalanceOverdue?.loading) {
      return <CircularProgressCentered />;
    }
    if (agingBalanceComing?.error || agingBalanceOverdue?.error) {
      return <AppError error={t('error')} />;
    }

    return (
      <>
        <Box className={classes.heading}>
          <Box
            className={classes.contentLeft}
            onClick={() => {
              const invoiceFilters = {
                state: ['validated'],
                type: ['invoice', 'pre_payment', 'credit_note'],
              };
              navigatePage('/invoices/list', invoiceFilters);
            }}
          >
            <Box className={classes.contentLeftBoxTotal}>
              <span className={classes.contentLeftTotal}>
                {formatAmount(unpaid)}
              </span>
              <SvgIcon
                path={EuroIcon}
                color={theme.palette.teal[600]}
                width={24}
                height={24}
              />
            </Box>
            <span className={classes.contentLeftSubTitle}>
              {t('turn_over.unpaid')}
            </span>
          </Box>
          <Box className={classes.contentRight}>
            <DataLabel
              title={t('agingBalance.overdue.title')}
              amount={formatAmount(overdue)}
              color={theme.palette.red[500]}
              onClick={() => {
                const invoiceFilters = {
                  state: ['overdue'],
                  type: ['invoice', 'pre_payment', 'credit_note'],
                };
                navigatePage('/invoices/list', invoiceFilters);
              }}
            />
            <DataLabel
              title={t('agingBalance.coming.title')}
              amount={formatAmount(unpaid - overdue)}
              color={theme.palette.teal[400]}
              onClick={() => {
                const invoiceFilters = {
                  state: ['validated', 'pending'],
                  type: ['invoice', 'pre_payment', 'credit_note'],
                };
                navigatePage('/invoices/list', invoiceFilters);
              }}
            />
          </Box>
        </Box>
        <Box style={{ width: '100%' }}>
          <ApexChart
            options={chartData.options}
            series={chartData.series}
            type="bar"
            height={300}
          />
        </Box>
      </>
    );
  };

  useEffect(() => {
    if (appInitialized) {
      getAgingBalance();
      getAgingBalanceOverdue();
    }
  }, [companyId, appInitialized, getAgingBalance, getAgingBalanceOverdue]);

  return <AppDashboardCard>{renderBody()}</AppDashboardCard>;
};

AgingBalanceOverview.propTypes = {
  companyId: PropTypes.string,
  appInitialized: PropTypes.bool,
  agingBalance: PropTypes.object.isRequired,
  getAgingBalance: PropTypes.func.isRequired,
  getAgingBalanceOverdue: PropTypes.func.isRequired,
  unpaid: PropTypes.number,
  overdue: PropTypes.number,
};

DataLabel.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default AgingBalanceOverview;
