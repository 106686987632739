import React from 'react';
import PropTypes from 'prop-types';
import BankBBFDebitCreationStepper from './BankBBFDebitCreatorStepper';

const BankBBFDebitCreator = (props) => {
  const { handleOpenTableDebit, handleOpenNewDebit } = props;
  return (
    <BankBBFDebitCreationStepper
      handleOpenDebitCreationSection={handleOpenNewDebit}
      handleOpenTableDebitSection={handleOpenTableDebit}
    />
  );
};

BankBBFDebitCreator.propTypes = {
  handleOpenNewDebit: PropTypes.func,
  handleOpenTableDebit: PropTypes.func,
};

export default BankBBFDebitCreator;
