import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getThemeColor, subtractDates } from 'helpers/utils';
import queryString from 'query-string';
import NumberFormat from 'helpers/NumberFormat';
import { useRouter } from 'hooks/routerHooks';
import { add, format, sub } from 'date-fns';

/* import services */
import expenseServices from 'services/ExpenseService';

/* import components */
import ApexChart from 'components/ApexChart';
import SvgIcon from 'components/AppIcons/SvgIcon';
import FiveBiggestCard from '../components/FiveBiggestCard';

import EuroIcon from '../../../assets/icon/euro.svg';
import styles from './style';

const useStyles = makeStyles(styles);

const DataLabel = (props) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { title, amount, onClick } = props;
  return (
    <Box className={classes.dataLabelContainer} onClick={onClick}>
      <Box className={classes.dataLabelBoxTitle}>
        <Box className={classes.ellipse} />
        <span className={classes.dataLabelTitle}>{title}</span>
      </Box>
      <Box className={classes.dataLabelBoxAmount}>
        <span className={classes.dataLabelAmount}>{amount}</span>
        <SvgIcon
          path={EuroIcon}
          color={theme.palette.neutral[800]}
          width={20}
          height={20}
        />
      </Box>
    </Box>
  );
};

const fields = [
  'under8',
  'f8to15',
  'f15to21',
  'f21to30',
  'f30to45',
  'f45to60',
  'over60',
];

const ranges = [
  {
    from: 0,
    to: 8,
  },
  {
    from: 8,
    to: 15,
  },
  {
    from: 15,
    to: 21,
  },
  {
    from: 21,
    to: 30,
  },
  {
    from: 30,
    to: 45,
  },
  {
    from: 45,
    to: 60,
  },
];

const DisbursementChart = (props) => {
  const [latePaymentTotal, setLatePaymentTotal] = useState(0);
  const [latePayments, setLatePayments] = useState([]);
  const [topLatePayments, setTopLatePayments] = useState(0);

  const [upcomingPaymentTotal, setUpcomingPaymentTotal] = useState(0);
  const [upcomingPaymentData, setUpcomingPaymentData] = useState([]);
  const [top5HighestUpcomingPayments, setTopPayments] = useState(0);

  const [errors, setError] = useState(null);
  const { history } = useRouter();
  const classes = useStyles(props);
  const theme = useTheme();
  const { t } = useTranslation();
  const labels = fields.map((field) => t(`agingBalance.overdue.${field}`));
  const latePaymentFields = {
    under8: 0,
    f8to15: 0,
    f15to21: 0,
    f21to30: 0,
    f30to45: 0,
    f45to60: 0,
    over60: 0,
  };
  const upcomingPaymentBars = {
    under8: 0,
    f8to15: 0,
    f15to21: 0,
    f21to30: 0,
    f30to45: 0,
    f45to60: 0,
    over60: 0,
  };

  const navigatePage = (url, queryParams) => {
    history.push(
      queryString.stringifyUrl(
        {
          url,
          query: queryParams,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  const clickEventLatePayment = (dataPointIndex) => {
    const purchaseFilters = {
      state: ['overdue'],
    };

    if (dataPointIndex === 6) {
      purchaseFilters.paymentBefore = format(
        sub(new Date(), {
          days: 60,
        }),
        'yyyy-MM-dd'
      );
    } else {
      purchaseFilters.paymentBefore = format(
        sub(new Date(), {
          days: ranges[dataPointIndex].from,
        }),
        'yyyy-MM-dd'
      );
      purchaseFilters.paymentAfter = format(
        sub(new Date(), {
          days: ranges[dataPointIndex].to,
        }),
        'yyyy-MM-dd'
      );
    }
    navigatePage('purchases/list', purchaseFilters);
  };

  const clickEventUpcomingPayment = (dataPointIndex) => {
    const purchaseFilters = {
      state: ['upcoming'],
      // type: ['invoice'],
    };
    if (dataPointIndex === 6) {
      purchaseFilters.paymentAfter = format(
        add(new Date(), {
          days: 60,
        }),
        'yyyy-MM-dd'
      );
    } else {
      purchaseFilters.paymentBefore = format(
        add(new Date(), {
          days: ranges[dataPointIndex].to,
        }),
        'yyyy-MM-dd'
      );

      purchaseFilters.paymentAfter = format(
        add(new Date(), {
          days: ranges[dataPointIndex].from,
        }),
        'yyyy-MM-dd'
      );
    }
    navigatePage('purchases/list', purchaseFilters);
  };

  const getElementAtEvent = (seriesIndex, dataPointIndex) => {
    if (seriesIndex === 0) {
      clickEventLatePayment(dataPointIndex);
    } else {
      clickEventUpcomingPayment(dataPointIndex);
    }
  };

  const countTopLatePayment = ({ count = 5, paymentList }) =>
    paymentList
      .filter((payment) => payment !== false)
      .sort((a, b) => b.toPayAmount - a.toPayAmount)
      .slice(0, count)
      .reduce((acc, curr) => acc + curr.toPayAmount, 0);

  const fetchLatePayments = async () => {
    try {
      const res = await expenseServices.fetchAllPurchases();

      const purchases =
        res.data.docs.filter((purchase) => purchase.state === 'overdue') || [];

      const latePaymentList = purchases.map((purchase) => {
        // Compare due_date to current day
        // If diffDay <= 0 that means we have a overdue purchase, and vice verca.
        const currentDate = new Date();
        const dueDate = new Date(purchase.due_date);
        const diffDay = subtractDates(currentDate, dueDate);

        // Skip iteration when payment isn't overdue
        if (diffDay > 0) return false;

        if (diffDay <= 8) {
          latePaymentFields.under8 += purchase.duePayableAmount;
          // return;
        }

        if (diffDay > 8 && diffDay <= 15) {
          latePaymentFields.f8to15 += purchase.duePayableAmount;
        }

        if (diffDay > 15 && diffDay <= 21) {
          latePaymentFields.f15to21 += purchase.duePayableAmount;
        }

        if (diffDay > 21 && diffDay <= 30) {
          latePaymentFields.f21to30 += purchase.duePayableAmount;
        }

        if (diffDay > 30 && diffDay <= 45) {
          latePaymentFields.f30to45 += purchase.duePayableAmount;
        }

        if (diffDay > 45 && diffDay <= 60) {
          latePaymentFields.f45to60 += purchase.duePayableAmount;
        }

        if (diffDay > 60) {
          latePaymentFields.over60 += purchase.duePayableAmount;
        }

        return {
          toPayAmount: purchase.duePayableAmount,
        };
      });

      const top5LatePayments = countTopLatePayment({
        count: 5,
        paymentList: latePaymentList,
      });

      setTopLatePayments(top5LatePayments);
      setLatePayments(latePaymentFields);
      if (setLatePaymentTotal) {
        const totalLatePayment = latePaymentList
          .filter((payment) => payment !== false)
          .reduce((acc, current) => {
            return acc + current.toPayAmount;
          }, 0);
        setLatePaymentTotal(totalLatePayment);
      }
    } catch (error) {
      setError(error);
    }
  };

  const fetchUpcomingPurchases = async () => {
    try {
      const res = await expenseServices.fetchAllPurchases();
      const purchases =
        res.data.docs.filter((purchase) => purchase.state === 'validated') ||
        [];

      const upcomingPayments = purchases.map((purchase) => {
        const currentDate = new Date();
        const dueDate = new Date(purchase.due_date);
        const diffDay = subtractDates(currentDate, dueDate);

        // Skip payment overdue
        if (diffDay <= 0) return false;

        if (diffDay <= 8) {
          upcomingPaymentBars.under8 += purchase.duePayableAmount;
          // return;
        }

        if (diffDay > 8 && diffDay <= 15) {
          upcomingPaymentBars.f8to15 += purchase.duePayableAmount;
        }

        if (diffDay > 15 && diffDay <= 21) {
          upcomingPaymentBars.f15to21 += purchase.duePayableAmount;
        }

        if (diffDay > 21 && diffDay <= 30) {
          upcomingPaymentBars.f21to30 += purchase.duePayableAmount;
        }

        if (diffDay > 30 && diffDay <= 45) {
          upcomingPaymentBars.f30to45 += purchase.duePayableAmount;
        }

        if (diffDay > 45 && diffDay <= 60) {
          upcomingPaymentBars.f45to60 += purchase.duePayableAmount;
        }

        if (diffDay > 60) {
          upcomingPaymentBars.over60 += purchase.duePayableAmount;
        }

        return {
          toPayAmount: purchase.duePayableAmount,
        };
      });

      const top5LargestUpcomingPayment = upcomingPayments
        .filter((item) => item !== false)
        .sort((first, second) => second.toPayAmount - first.toPayAmount)
        .slice(0, 5)
        .reduce((acc, current) => acc + current.toPayAmount, 0);

      setUpcomingPaymentData({ ...upcomingPaymentBars });
      setTopPayments(top5LargestUpcomingPayment);
      if (setUpcomingPaymentTotal) {
        const totalUpcomingPayments = upcomingPayments
          .filter((item) => item !== false)
          .reduce((acc, current) => acc + current.toPayAmount, 0);
        setUpcomingPaymentTotal(totalUpcomingPayments);
      }
    } catch (e) {
      setError(e);
    }
  };

  const chartData = {
    series: [
      {
        name: t('agingBalance.overdue.title'),
        data: Object.values(latePayments),
      },
      {
        name: t('agingBalance.coming.title'),
        data: Object.values(upcomingPaymentData),
      },
    ],
    options: {
      chart: {
        type: 'bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection(event, chartContext, opts) {
            const { dataPointIndex, seriesIndex } = opts;
            getElementAtEvent(seriesIndex, dataPointIndex);
          },
        },
      },
      colors: [
        getThemeColor(theme.palette.accent.red),
        getThemeColor(theme.palette.yellow[400]),
      ],
      plotOptions: {
        bar: {
          horizontal: false,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      labels,
      xaxis: {
        labels: {
          show: true,
          style: {
            colors: getThemeColor(theme.palette.neutral[500]),
            fontWeight: 400,
            fontSize: '0.875rem',
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          style: {
            colors: getThemeColor(theme.palette.neutral[500]),
            fontWeight: 600,
            fontSize: '0.875rem',
          },
          formatter(val) {
            return `${val}€`;
          },
        },
      },
      legend: { show: false },
    },
  };

  useEffect(() => {
    fetchUpcomingPurchases();
  }, []);

  useEffect(() => {
    fetchLatePayments();
  }, []);
  return (
    <>
      <Box className={classes.heading}>
        {errors ? <p style={{ color: 'red' }}>{errors}</p> : null}
        <Box
          className={classes.contentLeft}
          onClick={() => {
            const purchaseFilters = {
              state: ['upcoming', 'paid'],
            };
            navigatePage('/purchases/list', purchaseFilters);
          }}
        >
          <Box className={classes.contentLeftBoxTotal}>
            <span className={classes.contentLeftTotal}>
              {`${NumberFormat.n(
                latePaymentTotal + upcomingPaymentTotal || 0
              )}`}
            </span>
            <SvgIcon
              path={EuroIcon}
              color={theme.palette.red[600]}
              width={24}
              height={24}
            />
          </Box>
          <span className={classes.contentLeftSubTitle}>
            {t('my_purchase.total_disbursed')}
          </span>
        </Box>
        <Box className={classes.contentRight}>
          <DataLabel
            title={t('my_purchase.overdue.title')}
            amount={`${NumberFormat.n(latePaymentTotal || 0)}€`}
            color={theme.palette.accent.red}
            onClick={() => {
              const purchaseFilters = {
                state: ['overdue'],
              };
              navigatePage('/purchases/list', purchaseFilters);
            }}
          />
          <DataLabel
            title={t('my_purchase.upcoming.title')}
            amount={`${NumberFormat.n(upcomingPaymentTotal || 0)}€`}
            color={theme.palette.yellow[400]}
            onClick={() => {
              const purchaseFilters = {
                state: ['upcoming'],
              };
              navigatePage('/purchases/list', purchaseFilters);
            }}
          />
        </Box>
      </Box>
      <Box style={{ width: '100%' }}>
        <ApexChart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={300}
        />
      </Box>
      <Box className={classes.boxFiveBiggestCard}>
        <FiveBiggestCard
          textColor={theme.palette.red[600]}
          bgColor={theme.palette.red[50]}
          titleLeft={`${t('my_purchase.overdue.fiveBiggest')} :`}
          titleRight={`${NumberFormat.n(topLatePayments || 0)}€`}
          style={{ width: '100%' }}
          onClick={() => {
            const purchaseFilters = {
              state: ['overdue'],
              orderByTotal: true,
            };
            navigatePage('purchases/list', purchaseFilters);
          }}
        />
        <FiveBiggestCard
          textColor={theme.palette.yellow[800]}
          bgColor={theme.palette.yellow[50]}
          titleLeft={`${t('my_purchase.upcoming.fiveBiggest')} :`}
          titleRight={`${NumberFormat.n(top5HighestUpcomingPayments || 0)}€`}
          style={{ width: '100%' }}
          onClick={() => {
            const filters = {
              state: ['upcoming'],
              orderByTotal: true,
            };
            navigatePage('purchases/list', filters);
          }}
        />
      </Box>
    </>
  );
};

DisbursementChart.propTypes = {};

DataLabel.propTypes = {
  title: PropTypes.string.isRequired,
  amount: PropTypes.number.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func,
};

export default DisbursementChart;
