import React, { useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import { map } from 'lodash';

import spinnerService from 'services/SpinnerService';
import { IconEdit, IconRefresh } from '../../../components/AppIcons';
import AppPlusButton from '../../../components/AppPlusButton';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';

import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';

import {
  userCanCreateUserRoleManagement,
  userCanUpdateUserRoleManagement,
} from '../../../selectors/rightsSelector/userRoleManagementRequests';

import { useThunkDispatch } from '../../../hooks/generalHooks';
import { useRouter } from '../../../hooks/routerHooks';

import { fetchRoles } from '../../../actions/RolesActions';

const RoleListHeader = ({ openForm, history }) => {
  const dispatch = useThunkDispatch();
  const { t } = useTranslation();
  const canCreateUserRoleManagement = useSelector(
    userCanCreateUserRoleManagement
  );

  const fetchRolesCb = useCallback(() => dispatch(fetchRoles()), [dispatch]);

  return (
    <Header
      name={t('roles.title')}
      spaceBetween
      goBack={() => {
        history.goBack();
      }}
    >
      <IconRefresh onClick={fetchRolesCb} />
      {canCreateUserRoleManagement && (
        <AppPlusButton label={t('create')} onClick={() => openForm()} />
      )}
    </Header>
  );
};

const RoleListBody = ({ openForm }) => {
  const { t } = useTranslation();

  const canUpdateUserRoleManagement = useSelector(
    userCanUpdateUserRoleManagement
  );

  const companyAccessRights = map(
    useSelector((state) => state.companyAccessRights.roles)
  ).map((rights) => ({
    role: rights.role,
    id: rights._id,
  }));

  const columns = [
    {
      label: t('roles.role'),
      name: 'role',
      options: {
        customBodyRender: (value) => {
          return t(`roles.${value}`) !== `roles.${value}`
            ? t(`roles.${value}`)
            : value;
        },
      },
    },
    {
      label: ' ',
      name: 'id',
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value) => {
          if (!canUpdateUserRoleManagement) {
            return null;
          }
          return <IconEdit sm onClick={() => openForm(value)} />;
        },
      },
    },
  ];

  return (
    <MUIDataTable
      data={companyAccessRights}
      columns={columns}
      options={getMuiDatatableOptions(t, companyAccessRights.length)}
    />
  );
};

const RolesList = () => {
  const { history } = useRouter();
  const dispatch = useDispatch();
  useEffect(() => {
    spinnerService.startSpinner();
    dispatch(fetchRoles()).finally(() => spinnerService.endSpinner());
  }, []);
  function openForm(id = null) {
    if (id) {
      history.push(`/settings/roles/edit/${id}`);
    } else {
      history.push('/settings/roles/new');
    }
  }

  return (
    <Layout
      header={<RoleListHeader openForm={openForm} history={history} />}
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={<RoleListBody openForm={openForm} />}
    />
  );
};

RoleListHeader.propTypes = {
  openForm: PropTypes.func,
  history: PropTypes.object.isRequired,
};

RoleListBody.propTypes = {
  openForm: PropTypes.func,
};

export default RolesList;
