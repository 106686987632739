import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { StyledDropZone } from 'react-drop-zone';
import 'react-drop-zone/dist/styles.css';

const UploadFile = ({ onFileSelected, reset, accept, ...props }) => {
  const [label, setLabel] = useState('Cliquer ici');

  useEffect(() => {
    setLabel('Cliquer ici');
  }, [reset]);
  return (
    <StyledDropZone
      onDrop={(file) => {
        if (!accept.includes(file.type)) {
          return;
        }
        if (file.name) {
          setLabel(file.name);
        } else {
          let uploadLabel = '';
          const fileList = Object.values(file);
          // eslint-disable-next-line array-callback-return
          fileList.map((el, idx) => {
            if (idx === 0) {
              uploadLabel = `${el.name}`;
            }
            if (idx > 0) {
              uploadLabel = `${uploadLabel}, ${el.name}`;
            }
          });
          setLabel(uploadLabel);
        }
        onFileSelected(file);
      }}
      {...props}
    >
      {label}
    </StyledDropZone>
  );
};

UploadFile.propTypes = {
  onFileSelected: PropTypes.func,
  reset: PropTypes.bool,
  accept: PropTypes.string,
};

export default UploadFile;
