import { titleText, contentText } from './appText';
import colors from '../../../constants/colors.json';

const style = {
  dialog: {
    position: 'fixed',
    top: 0,
    left: 0,
    border: 'none',
    margin: 0,
    padding: 0,
    width: '100vw',
    height: '100vh',
    backgroundColor: 'rgba(0,0,0,0.25)',
    zIndex: 1000,
    '&:hover': {},
    '&:active': {},
    '&:focus': {},
    '&:disabled': {},
  },
  mainContainer: {
    border: 'none',
    borderRadius: '0 0 2px 2px',
    padding: '30px 22px 10px 22px',
    // width: 'auto',
    width: '590px',
    // height: '370px',
    transform: 'translate(-50%, -50%)',
    backgroundColor: colors.white,
    boxShadow:
      '0 3px 5px 1px rgba(178,178,178,0.2), 0 2px 5px 1px rgba(178,178,178,0.18), 0 5px 5px -3px rgba(178,178,178,0.23)',
    zIndex: 101,
    position: 'absolute',
    left: '50%',
    top: '50%',
    '&:hover': {},
    '&:active': {},
    '&:focus': {},
    '&:disabled': {},
  },

  primaryBorder: {
    borderBottom: '2px solid #3d319d',
  },
  secondaryBorder: {
    borderBottom: '2px solid #02CBCD',
  },

  header: {
    display: 'flex',
    margin: 0,
    padding: 0,
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    minHeight: '50px',
  },

  title: {
    ...titleText,
    margin: '0',
    padding: 0,
  },

  content: {
    ...contentText,
  },
  iconClose: {
    color: colors.textLightGrey,
    '&:hover': { color: colors.textGrey, fontSize: '20px' },
  },

  contentContainer: {
    height: '85%',
  },

  footer: {
    display: 'flex',
    position: 'absolute',
    bottom: 0,
    margin: 0,
    padding: 0,
    width: '93%',
    minHeight: '90px',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
    zIndex: 102,
  },

  overflowScroll: {
    overflowY: 'scroll',
  },

  headerSm: {
    minHeight: '30px',
  },
  mainContainerSm: {
    height: '170px',
    width: '317px',
    padding: '23px 30px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  footerSm: {
    padding: '0 0 5px 0',
    width: '93%',
    minHeight: '30px',
  },
  footerCenter: {
    justifyContent: 'center',
  },
  footerRight: {
    justifyContent: 'flex-end',
  },
  positionRight: {
    position: 'absolute',
    right: '5%',
  },
};

export default style;
