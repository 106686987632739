import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Box, TextField, useTheme } from '@material-ui/core';
import NumberFormat from 'helpers/NumberFormat';
import queryString from 'query-string';

/* Import custom hooks */
import { useRouter } from 'hooks/routerHooks';

/* import services */
import clientProjectService from 'services/ClientProjectService';
import InvoiceService from 'services/InvoiceService';
import ExpenseService from 'services/ExpenseService';
import { formatNumberWithSpacesAndDecimal, getThemeColor } from 'helpers/utils';

import ApexChart from 'components/ApexChart';
import AppButton from 'components/AppButton';
import SvgIcon from 'components/AppIcons/SvgIcon';
import AppDashboardCard from '../components/AppDashboardCard';

/* import assets */
import SearchIcon from '../../../assets/icon/search-outline.svg';

const useStyles = makeStyles((theme) => ({
  headerAction: {
    marginTop: theme.spacing(3),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'stretch',
    gap: theme.spacing(1.25),
  },
}));

const ClientProjectOverview = () => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const classes = useStyles();
  const clients = useSelector((state) => state.clients.data);
  const loggedUserCompany = useSelector(
    (state) => state.loggedUserCompany.company
  );
  const chartDataType = 'all';
  const [projects, setProjects] = useState([]);
  const [foundProjects, setFoundProjects] = useState([]);
  const [purchaseProjects, setPurchaseProjects] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [invoiceProjects, setInvoiceProjects] = useState([]);
  const [error, setError] = useState(null);
  const theme = useTheme();

  const fetchClientProjects = async () => {
    try {
      const { data: response } = await clientProjectService.fetchClientProjects(
        {
          company_id: loggedUserCompany._id,
        }
      );

      setProjects(response);
    } catch (e) {
      setError(e);
    }
  };

  const fetchInvoices = async () => {
    try {
      const { data: response } = await InvoiceService.fetchInvoicePaginate(
        'pagination=false'
      );

      const invoiceHasProjects = response.docs.filter(
        (invoice) => invoice.project
      );

      setInvoiceProjects(invoiceHasProjects);
    } catch (e) {
      setError(e);
    }
  };

  const fetchPurchases = async () => {
    try {
      const { data: response } = await ExpenseService.fetchAllPurchases();

      const purchaseHasProjects = response.docs.filter(
        (purchase) => purchase.project_id
      );

      setPurchaseProjects(purchaseHasProjects);
    } catch (e) {
      setError(e);
    }
  };

  const handleChangeClient = (_, value) => {
    if (!value) {
      setFoundProjects([]);
      return;
    }
    const foundClientProjects = projects.filter(
      (project) => project.client_id === value.id
    );

    setSelectedClient(value.id);
    setFoundProjects(foundClientProjects);
  };

  const prepareDataChart = () => {
    const invoiceHelper = {};
    const purchaseHelper = {};
    const invoiceData = invoiceProjects
      .filter((invoice) => invoice.client._id === selectedClient)
      .map((invoice) => ({
        projectId: invoice.project,
        grandTotalAmount: invoice.net_total,
      }))
      .reduce((r, o) => {
        const key = o.projectId;

        if (!invoiceHelper[key]) {
          invoiceHelper[key] = { ...o }; // create a copy of o
          r.push(invoiceHelper[key]);
        } else {
          invoiceHelper[key].grandTotalAmount += o.grandTotalAmount;
        }

        return r;
      }, []);
    const purchaseData = purchaseProjects
      .filter((purchase) => purchase.client_id === selectedClient)
      .map((purchase) => ({
        projectId: purchase.project_id,
        taxBasisTotalAmount: purchase.taxBasisTotalAmount,
      }))
      .reduce((r, o) => {
        const key = o.projectId;

        if (!purchaseHelper[key]) {
          purchaseHelper[key] = { ...o }; // create a copy of o
          r.push(purchaseHelper[key]);
        } else {
          purchaseHelper[key].taxBasisTotalAmount += o.taxBasisTotalAmount;
        }

        return r;
      }, []);

    const labelChartData = [...foundProjects];
    const revisedData = labelChartData.map((project) => {
      if (project.budgetsRevised.length) {
        // Get latest revised budgets
        const { revisedHT } = project.budgetsRevised.at(-1);
        return Number(revisedHT);
      }

      return project.budget;
    });

    const purchaseProjectsData = labelChartData.map((itm) => ({
      ...purchaseData.find((item) => item.projectId === itm._id && item),
      ...itm,
    }));

    const invoiceProjectsData = labelChartData.map((itm) => ({
      ...invoiceData.find((item) => item.projectId === itm._id && item),
      ...itm,
    }));

    const _data = [];

    labelChartData.forEach((project, index) => {
      _data.push({
        name: project.name,
        budget: revisedData[index],
        purchase: purchaseProjectsData[index].taxBasisTotalAmount,
        invoice: invoiceProjectsData[index].grandTotalAmount,
      });
    });

    // fill 10 items to _data if _data.length < 10
    if (_data.length < 10) {
      for (let i = 0; i < 10 - _data.length; i += 1) {
        _data.push({
          name: '',
          budget: 0,
          purchase: 0,
          invoice: 0,
        });
      }
    }

    // sort _data take 10 items from _data and reverse by budget

    let sortedData = _data;

    if (chartDataType !== 'all') {
      sortedData = _data.sort((a, b) => b.budget - a.budget).slice(0, 10);
    }

    return {
      labels: sortedData.map((item) => item.name),
      series: [
        {
          name: t('dasboard.budget'),
          data: sortedData.map((item) => item.budget),
        },
        {
          name: t('dasboard.purchase'),
          data: sortedData.map((item) => item.purchase),
        },
        {
          name: t('dasboard.invoice'),
          data: sortedData.map((item) => item.invoice),
        },
      ],
    };
  };

  const navigatePage = (url, queryParams) => {
    history.push(
      queryString.stringifyUrl(
        {
          url,
          query: queryParams,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  const onClickColumnChart = ({ label, seriesIndex }) => {
    // Find projectId based on project's name
    const projectName = label;
    const foundProject = projects.find(
      (project) => project.name === projectName
    );

    switch (seriesIndex) {
      case 0: {
        // User click in Revised column
        const projectFilters = {
          search: projectName,
        };
        navigatePage('projects', projectFilters);
        break;
      }
      case 1: {
        // User click in Purchase column
        const expenseFilters = {
          client: [selectedClient],
          project: [foundProject._id],
        };
        navigatePage('purchases/list', expenseFilters);
        break;
      }
      case 2: {
        // User click in Invoice column
        const invoiceFilters = {
          type: ['invoice'],
          client: [selectedClient],
          project: [foundProject._id],
        };
        navigatePage('invoices/list', invoiceFilters);
        break;
      }
      default: {
        break;
      }
    }
  };

  const { series, labels } = prepareDataChart();
  const chartData = {
    series,
    options: {
      chart: {
        type: 'bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection(event, chartContext, opts) {
            const {
              dataPointIndex,
              seriesIndex,
              w: {
                globals: { labels: categoryLabels },
              },
            } = opts;
            onClickColumnChart({
              seriesIndex,
              label: categoryLabels[dataPointIndex],
            });
          },
        },
      },
      colors: [
        getThemeColor(theme.palette.primary.hover),
        getThemeColor(theme.palette.red[500]),
        getThemeColor(theme.palette.accent.green),
      ],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      labels,
      xaxis: {
        labels: {
          show: true,
          rotate: -30,
          rotateAlways: true,
          style: {
            colors: getThemeColor(theme.palette.neutral[500]),
            fontWeight: 500,
            fontSize: '0.75rem',
          },
          formatter(val) {
            const value = formatNumberWithSpacesAndDecimal(val);
            return `${NumberFormat.n(value)}€`;
          },
        },
      },
      yaxis: {
        labels: {
          show: true,
          rotate: -35,
          rotateAlways: true,
          style: {
            colors: getThemeColor(theme.palette.text.grey),
            fontWeight: 400,
            fontSize: '0.75rem',
            cssClass: classes.yaxis,
          },
        },
      },
    },
  };

  useEffect(() => {
    fetchClientProjects();
    fetchInvoices();
    fetchPurchases();
  }, []);

  return (
    <AppDashboardCard
      title={t('dasboard.projects_tracking')}
      headerAction={
        clients && (
          <Box className={classes.headerAction}>
            <Autocomplete
              id="client-dropdown"
              options={Object.values(clients)}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Clients"
                  variant="outlined"
                  size="small"
                />
              )}
              style={{ width: '60%' }}
              onChange={handleChangeClient}
            />
            <AppButton
              text={<SvgIcon path={SearchIcon} />}
              style={{ minWidth: 'fit-content' }}
            />
          </Box>
        )
      }
    >
      <>
        {error ? (
          <p>{error}</p>
        ) : (
          <Box style={{ width: '100%' }}>
            <ApexChart
              options={chartData.options}
              series={chartData.series}
              type="bar"
              height={400}
            />
          </Box>
        )}
      </>
    </AppDashboardCard>
  );
};

export default ClientProjectOverview;
