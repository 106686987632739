import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
/* Package */
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  Typography,
  Divider,
  Box,
} from '@material-ui/core';
import CopyContentToClipboard from 'components/CopyContentToClipboard';

/* Styles */
import useStyles from './styles';

function BankBBFAccountRIBModal(props) {
  const { isRIBModalOpen, handleRIBModalClose, banks } = props;
  const wallets = useSelector((state) => state.wallet?.wallet || []);
  const classes = useStyles();
  const bankData = wallets.reduce((prev, curr) => {
    const data = banks.find((bank) => bank.walletId === curr.walletId);
    if (data) {
      return [...prev, data];
    }
    return prev;
  }, []);

  const fullName = wallets[0]?.userFirstname
    ? `${wallets[0].userFirstname} ${wallets[0].userLastname}`
    : 'N/A';

  const handleCopyValue = (values) => {
    return `Full name\n${fullName}\n\nIBAN\n${values.iban}\n\nBIC/SWIFT\n${values.bic}`;
  };

  return (
    <Dialog
      open={isRIBModalOpen}
      onClose={handleRIBModalClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      <DialogTitle
        id="form-dialog-title"
        color="primary"
        className={classes.accountModalTitle}
      >
        RIB / IBAN
      </DialogTitle>
      <Divider variant="middle" style={{ border: '1px solid #3d319d' }} />

      <DialogContent>
        <Box className={classes.accountDialogContent}>
          <Box className={classes.accountDialogItem}>
            <Typography
              color="primary"
              variant="caption"
              className={classes.accountModalLabel}
            >
              Full name
            </Typography>
            <Typography variant="h3" className={classes.accountModalText}>
              {fullName}
            </Typography>
          </Box>
          {bankData.map((bank) => (
            <Fragment key={bank._id}>
              <Divider />
              <Box className={classes.accountDialogItem}>
                <div
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Typography
                    color="primary"
                    variant="caption"
                    className={classes.accountModalLabel}
                  >
                    Banque
                  </Typography>
                  <div>
                    <CopyContentToClipboard
                      values={handleCopyValue(bank)}
                      title="Banque"
                    />
                  </div>
                </div>

                <Typography variant="h3" className={classes.accountModalText}>
                  {bank.bank_name}
                </Typography>

                <Box className={classes.accountBoxWrapper}>
                  <div>IBAN</div>
                  <div style={{ fontWeight: 'bold', fontSize: 15 }}>
                    {bank.iban || 'N/A'}
                  </div>
                  <br />
                  <div>BIC/SWIFT</div>
                  <div style={{ fontWeight: 'bold', fontSize: 15 }}>
                    {bank.bic || 'N/A'}
                  </div>
                </Box>
              </Box>
            </Fragment>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleRIBModalClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BankBBFAccountRIBModal.propTypes = {
  isRIBModalOpen: PropTypes.bool,
  handleRIBModalClose: PropTypes.func,
  banks: PropTypes.array,
};

export default BankBBFAccountRIBModal;
