/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  accountActionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '14px',
  },

  accountActionItemWrapper: {
    backgroundColor: '#f3f3f3',
    borderRadius: '10px',
    width: '100%',
    padding: '10px 15px',
    fontSize: '16px',
    cursor: 'pointer',

    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    transition: 'background-color 0.2s ease',

    '&:hover': {
      backgroundColor: '#D9E1EA',
    },
  },
}));

export default useStyles;
