import { makeStyles } from '@material-ui/core';
import colors from '../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  pdfViewContaier: {
    border: 'none',
    background: '#e2e2e2',
    height: '100%',
    overflowY: 'hidden',
    position: 'relative',
    borderRadius: '4px',
  },
  pdfPreview: {
    border: 'none',
    width: '100%',
    height: '100%',
  },
  pdfError: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    color: colors.red,
    lineHeight: '13px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
}));

export default useStyles;
