import { mBlueBold, inputTextBold } from './appText';

const style = {
  text: {
    ...mBlueBold,
    color: '#1E8ADC',
    fontSize: '16px',
    marginRight: '16px',
  },
  icon: { color: '#1E8ADC', fontSize: '16px' },
  data: {
    ...inputTextBold,
    color: '#061F6B',
    textTransform: 'uppercase',
    fontSize: '20px',
    padding: 0,
    margin: 0,
  },
};

export default style;
