/* Packages */
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { FastField, Form, Formik } from 'formik';
import { Grid, Button, Box, Snackbar } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

/* Icons */
import SendIcon from '@material-ui/icons/Send';

/* Components */
import Header from 'components/Header';
import Layout from 'components/Layout';
import CircularProgressCentered from 'components/CircularProgressCentered';
import SEPARequestInput from 'containers/BankBBF/components/SEPARequestInput';
import SEPARequestSelect from 'containers/BankBBF/components/SEPARequestSelect';
import SEPARequestCheckbox from 'containers/BankBBF/components/SEPARequestCheckbox';
import SEPAConfirmCard from 'containers/BankBBF/components/SEPAConfirmCard';

/* Service */
import { createNewSEPARequest } from 'actions/SEPAActions';

/* Styles */
import useStyles from './styles';

function SEPARequestForm() {
  const { t } = useTranslation();

  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();

  const foreignId = location.state?.foreignId;
  const transactionType = location.state?.transactionType;
  const amount = location.state?.amount;
  const currency = location.state?.currency;
  const createdDate = location.state?.createdDate;

  const loggedUser = useSelector((state) => state.loggedUser.user);

  const [isLoading, setIsLoading] = useState(false);
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBar, setSnackBar] = useState({
    status: 'success',
    message: 'Success',
    description: 'Submit successfully',
  });

  const handleOpenSnackBar = () => setOpenSnackBar(true);
  const handleCloseSnackBar = (event, reason) => {
    if (reason === 'clickaway') return;
    setOpenSnackBar(false);
  };

  const initialValues = {
    email: loggedUser ? loggedUser.email : '',
    payInOutId: foreignId,
    typeOfRequest:
      transactionType === 'Payin'
        ? 'WITHDRAWAL_ISSUED'
        : 'RECALL_REQUEST_FOR_WITHDRAWAL',
    subject: '',
    agree: false,
    agree2: false,
    description: '',
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    payInOutId: Yup.string().required(),
    typeOfRequest: Yup.string().required(),
    subject: Yup.string().required(),
    agree: Yup.boolean(),
    agree2: Yup.boolean(),
    description: Yup.string(),
  });

  const onSubmit = async (formData) => {
    setIsLoading(true);

    const payload = {
      payMutationId: foreignId,
      payMutationType: transactionType === 'Payin' ? 'Payin' : 'Payout',
      isComplaint: formData.agree,
      typeOfRequest: formData.typeOfRequest,
      isCriticalRequestWithImmediateEscalation: formData.agree2,
      subject: formData.subject,
      description: formData.description,
      userId: loggedUser._id,
    };

    const response = await createNewSEPARequest(payload);
    if (response.status === 200) {
      setSnackBar({
        status: 'success',
        message: t('sepa.snack_success_message'),
        description: t('sepa.snack_success_description'),
      });
    } else {
      setSnackBar({
        status: 'error',
        message: t('sepa.snack_error_message'),
        description: t('sepa.snack_error_description'),
      });
    }

    handleOpenSnackBar();
    setIsLoading(false);
    history.push('/bank-bbf/sepa/requests');
  };

  return (
    <SetupLayout>
      <LoadingIndicator isLoading={isLoading}>
        <div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ handleSubmit }) => (
              <Form>
                <Box style={{ width: '100%', textAlign: 'right' }}>
                  <Button
                    onClick={handleSubmit}
                    type="submit"
                    color="primary"
                    variant="contained"
                    startIcon={<SendIcon />}
                    size="large"
                  >
                    {t('sepa.submit_your_ticket')}
                  </Button>
                </Box>

                <Grid container className={classes.root} spacing={2}>
                  <Grid item xs={6}>
                    <FastField
                      name="email"
                      placeholder="Enter your email"
                      subLabel={t('sepa.email_subtitle')}
                      label={t('sepa.email')}
                      type="email"
                      readOnly
                      component={SEPARequestInput}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FastField
                      name="payInOutId"
                      placeholder="Enter your PayIn / Payout ID"
                      label={
                        transactionType === 'Payin'
                          ? t('sepa.pay_in_id')
                          : t('sepa.payout_id')
                      }
                      type="text"
                      subLabel={t('sepa.pay_out_in_subtitle')}
                      readOnly
                      component={SEPARequestInput}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FastField
                      type="select"
                      name="typeOfRequest"
                      placeholder="Enter your type of request"
                      label={t('sepa.type_of_request')}
                      subLabel={t('sepa.type_of_request_subtitle')}
                      component={SEPARequestSelect}
                      selectOptionType={transactionType}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FastField
                      name="subject"
                      placeholder={t('sepa.subject_placeholder')}
                      label={t('sepa.subject')}
                      subLabel={t('sepa.subject_subtitle')}
                      type="text"
                      component={SEPARequestInput}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FastField
                      name="description"
                      placeholder={t('sepa.description_placeholder')}
                      subLabel={t('sepa.description_subtitle')}
                      label={t('sepa.description')}
                      type="text"
                      multiline={true}
                      rows={8}
                      component={SEPARequestInput}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FastField
                      name="agree"
                      label={t('sepa.complaint')}
                      subLabel={t('sepa.complaint_subtitle')}
                      component={SEPARequestCheckbox}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <FastField
                      name="agree2"
                      label={t('sepa.critical_request')}
                      subLabel={t('sepa.critical_request_subtitle')}
                      component={SEPARequestCheckbox}
                    />
                  </Grid>

                  <Grid item xs={6}>
                    <SEPAConfirmCard
                      foreignId={foreignId}
                      transactionType={transactionType}
                      amount={amount}
                      currency={currency}
                      createdDate={createdDate}
                    />
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
          <Snackbar
            open={openSnackBar}
            onClose={handleCloseSnackBar}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Alert
              elevation={6}
              variant="filled"
              onClose={handleCloseSnackBar}
              severity={snackBar.status}
            >
              <AlertTitle>{snackBar.message}</AlertTitle>
              {snackBar.description}
            </Alert>
          </Snackbar>
        </div>
      </LoadingIndicator>
    </SetupLayout>
  );
}

function LoadingIndicator(props) {
  const { children, isLoading } = props;
  return <>{isLoading ? <CircularProgressCentered /> : children}</>;
}

function SetupLayout({ children }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Layout
      header={
        <Header
          name={t('sepa.SEPA_request_support')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={children}
    />
  );
}

SetupLayout.propTypes = {
  children: PropTypes.node,
};

LoadingIndicator.propTypes = {
  children: PropTypes.node,
  isLoading: PropTypes.bool,
};

export default SEPARequestForm;
