import { createStore, applyMiddleware } from 'redux';
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer } from 'redux-persist';

import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import reducers from './reducers';

import { filterStateForAnalytics } from './helpers/analyticsHelper';

const analyticsMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    // we retrieve the current State
    const currentState = getState();
    // Then we extract only the values we need for Analytis (userId, comany name, etc.)
    const analyticsState = filterStateForAnalytics(currentState);

    window.dataLayer = window.dataLayer || [];

    if (!action || (action && !action.type)) {
      return null;
    }

    window.dataLayer.push({
      event: action.type,
      payload: action.payload,
      data: analyticsState,
    });

    return next(action);
  };

const middlewares = [ReduxThunk, analyticsMiddleware];

let enhancer = applyMiddleware(...middlewares);
if (process.env.NODE_ENV !== 'production') {
  middlewares.push(createLogger());
  enhancer = composeWithDevTools(applyMiddleware(...middlewares));
}

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
  whitelist: [
    'loggedUser',
    'loggedUserCompany',
    'companyAccessRights',
    'loggedUserRights',
  ],
};

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);
export const globalDispatch = store.dispatch;
