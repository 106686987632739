import * as yup from 'yup';
import { PHONE_NUMBER_VALIDATE_SCHEMA } from 'constants/form';

const validationSchema = ({ t }) =>
  yup.object().shape({
    email: yup
      .string()
      .email(t('signup.company.email_valid_mandatory'))
      .required(t('forms.mandatory_field')),
    userName: yup.string().required(t('forms.mandatory_field')),
    firstname: yup.string().required(t('forms.mandatory_field')),
    phonenumber: yup
      .string()
      .required(t('forms.mandatory_field'))
      .matches(
        PHONE_NUMBER_VALIDATE_SCHEMA,
        t('signup.company.valid_phone_number'),
        {
          excludeEmptyString: false,
        }
      ),
    business_name: yup.string(),
    address: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.address_empty'), {
        excludeEmptyString: true,
      })
      .required(t('signup.company.address_mandatory')),
    zipcode: yup
      .string()
      .matches(/^[\w-]+$/, t('signup.company.zipcode_false'), {
        excludeEmptyString: true,
      })
      .max(15, t('signup.company.zipcode_max'))
      .required(t('signup.company.zipcode_mandatory')),
    city: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.adress_mandatory'), {
        excludeEmptyString: true,
      })
      .required(t('signup.company.address_mandatory')),
    siren: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.siren_empty'), {
        excludeEmptyString: true,
      })
      .min(9, t('signup.company.siren_min_mandatory'))
      .max(9, t('signup.company.siren_min_mandatory'))
      .required(t('signup.company.siren_mandatory')),
    code_ape: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.code_ape_empty'), {
        excludeEmptyString: true,
      }),
    nic: yup.string().required(t('signup.company.nic_empty')),
    title: yup.string().required(t('forms.mandatory_field')),
    legal_form: yup.string().required(),
  });
export default validationSchema;
