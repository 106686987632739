// import { smDarkGrey, mWhiteBold } from './appText';
// import colors from '../../../constants/colors.json';

const style = {
  xSmall: {
    margin: '10px 0',
  },
  small: {
    margin: 0,
    padding: 0,
    height: '40px',
  },
  medium: {
    margin: '50px 0',
  },
  large: {
    margin: '35px 0',
  },
  smallCol: {
    height: '100%',
    width: '40px',
  },
  meduimCol: {
    height: '100%',
    width: '40px',
    padding: 0,
    margin: 0,
  },
  rowSpaceAround: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  rowSpaceAroundWrap: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  sepLine: {
    height: '1px',
    width: '92%',
    borderBottom: '1px solid #D7D7D7',
    marginTop: '25px',
    marginBottom: '25px',
  },
  greenDivider: {
    padding: 0,
    margin: '0 6px',
    height: '2px',
    width: '265px',
    backgroundColor: '#02CBCD',
  },
  greyDivider: {
    padding: 0,
    margin: '0 6px',
    height: '2px',
    width: '265px',
    backgroundColor: '#d7d7d7',
  },
  greenGreyDivider: {
    height: '2px',
    width: '264px',
    margin: '0 6px',
    background: 'linear-gradient(to right, #02CBCD 50%, #d7d7d7 50%)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 2px',
  },
  responsiveDividerS: {
    margin: 0,
    padding: 0,
    height: '20px',
    width: '100%',
  },
  displayM: {
    display: 'none',
  },
  '@media (max-width: 767px)': {
    displayM: {
      display: 'block',
    },
  },
};

export default style;
