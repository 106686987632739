import { Dashboard } from './Rights';

export const DashBoardAccess = {
  section1: [
    Dashboard.InvoiceOverview,
    Dashboard.Top10ProductsServices,
    Dashboard.Top10Customers,
  ],
  section2: [Dashboard.ProjectsTracking, Dashboard.ActivityFollowUp],
  section3: [
    Dashboard.Overdue,
    Dashboard.Unpaid,
    Dashboard.FutureReceipts,
    Dashboard.FiveBiggestPayments,
    Dashboard.Top10LateCustomers,
    Dashboard.FiveBiggestLatePayments,
    Dashboard.Top10MostIndebtedClients,
    Dashboard.ComingPayments,
    Dashboard.OverduePayments,
  ],
  section4: [
    Dashboard.Expenses,
    Dashboard.AnalyticalSortOfDebts,
    Dashboard.MyExpenses,
  ],
  section5: [Dashboard.Top10Debts, Dashboard.Top10PaidProviders],
  DisbursementOverview: [
    Dashboard.LateInDisbursement,
    Dashboard.TotalToBeDisbursed,
    Dashboard.UpcomingDisbursements,
  ],
};

export default DashBoardAccess;
