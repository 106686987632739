/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Header from 'components/Header';
import { useHistory } from 'react-router-dom';
import AppPlusButton from 'components/AppPlusButton';
import { IconRefresh } from 'components/AppIcons';
import { fetchClientProjects } from 'actions/ClientProjectActions';
import { useDispatch } from 'react-redux';

const ProjectHeader = (props) => {
  const { onCreateProject } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { goBack } = useHistory();

  const fetchProjectsCb = React.useCallback(
    () => dispatch(fetchClientProjects()),
    [dispatch]
  );

  return (
    <Header
      name={t('project.header-title')}
      spaceBetween
      goBack={() => goBack()}
    >
      <IconRefresh onClick={() => fetchProjectsCb()} />
      <AppPlusButton
        onClick={onCreateProject}
        label={t('project.create-project-btn')}
      />
    </Header>
  );
};

ProjectHeader.propTypes = {
  onCreateProject: PropTypes.func.isRequired,
};

export default ProjectHeader;
