const calculateTotals = (amounts) => {
  let taxBasisTotalAmount = 0;
  let taxTotalAmount = 0;
  let grandTotalAmount = 0;
  amounts.forEach((amountItem) => {
    const { amount, vat, total } = amountItem;
    taxBasisTotalAmount += parseFloat(amount);
    grandTotalAmount += parseFloat(total);
    taxTotalAmount += parseFloat(vat);
  });
  return {
    taxBasisTotalAmount: parseFloat(taxBasisTotalAmount).toFixed(2),
    taxTotalAmount: parseFloat(taxTotalAmount).toFixed(2),
    grandTotalAmount: parseFloat(grandTotalAmount).toFixed(2),
  };
};

export default calculateTotals;
