import React, { createContext } from 'react';
import PropTypes from 'prop-types';

const InvoiceFormContext = createContext({});
const InvoiceFormContextProvider = ({ children, value }) => (
  <InvoiceFormContext.Provider value={value}>
    {children}
  </InvoiceFormContext.Provider>
);

InvoiceFormContextProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
};

export { InvoiceFormContext, InvoiceFormContextProvider };
