import axios from 'axios';
import { ID_TOKEN, X_COMPANY_ID } from 'config/Constants';
import { postError } from 'services/ErrorReport';
import {
  getSelectedCompany,
  selectLoggedUserCompanyId,
} from 'selectors/loggedUserCompanySelectors';
import { store } from '../store';

let signal = axios.CancelToken.source();

/**
 * returns if error is being caused by axios cancel
 * @function
 * @returns {Boolean} - true if the error caused by canceling the request
 */
const areRequestsCanceled = (err) => err && axios.isCancel(err);

/**
 * cancels every request
 * @function
 */
const cancelRequests = () => {
  signal.cancel({ isCanceled: true, message: 'Requests canceled' });
  signal = axios.CancelToken.source();
};

const reportError = async (error) => {
  try {
    await postError({
      status: error.response.status,
      message: JSON.stringify(error.response.data),
      method: error.config.method,
      url: error.config.url,
      headers: error.config.headers,
    });
  } catch (err) {
    // eslint-disable-next-line
    console.log({ err });
  }
};

/**
 *
 * parse error response
 */
async function parseError(error) {
  if (error.response) {
    await reportError(error);
    return Promise.reject(error.response.data);
  }
  return Promise.reject(error);
}

/**
 * axios instances
 */
const simpleInstance = axios.create({});
const authInstance = axios.create({});

// response parse
simpleInstance.interceptors.response.use((response) => response, parseError);
// request parse
simpleInstance.interceptors.request.use((request) => {
  request.cancelToken = signal.token;
  return request;
}, parseError);

// response parse
authInstance.interceptors.response.use((response) => response, parseError);
// request parse
authInstance.interceptors.request.use((request) => {
  request.cancelToken = signal.token;
  if (localStorage.getItem(ID_TOKEN)) {
    request.headers.Authorization = `Bearer ${localStorage.getItem(ID_TOKEN)}`;
  }
  const state = store.getState();

  const companyId =
    selectLoggedUserCompanyId(state) || getSelectedCompany(state);

  if (companyId) {
    request.headers[X_COMPANY_ID] = companyId;
  }
  return request;
}, parseError);

export { simpleInstance, authInstance, areRequestsCanceled, cancelRequests };
