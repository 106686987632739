import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import Reorder, { reorder } from 'react-reorder';
import { isEuClient } from 'helpers/ClientHelpers';
import {
  AppTableRow,
  AppTableCell,
} from '../../../../../../components/AppTableComponents';

import ItemRow from './ItemRow';

const ItemsTableBody = ({ useStyle }) => {
  const { values, setFieldValue } = useFormikContext();
  const items = useSelector((state) => state.items);

  const { items: selectedItems, client } = values;

  const prevSelectedClient = useRef(null);

  function onReorder(event, previousIndex, nextIndex) {
    setFieldValue(
      'items',
      reorder([...selectedItems], previousIndex, nextIndex)
    );
  }

  useEffect(() => {
    if (isEuClient(client)) {
      prevSelectedClient.current = client;
    }

    if (isEuClient(client) && selectedItems?.length) {
      const newItems = selectedItems.map((item) => ({
        ...item,
        vat_rate: 0,
      }));
      setFieldValue('items', newItems);
    } else if (
      isEuClient(prevSelectedClient.current) &&
      selectedItems?.length
    ) {
      const newItems = selectedItems.map((item) => {
        return {
          ...item,
          vat_rate: items[item._id].vat_rate,
        };
      });
      setFieldValue('items', newItems);
      prevSelectedClient.current = null;
    }
  }, [client]);

  return (
    <Reorder
      reorderId="itemListComponent"
      component="tbody"
      lock="horizontal"
      onReorder={onReorder}
      autoScroll={true}
      disabled={true}
      holdTime={300}
      touchHoldTime={300}
      mouseHoldTime={300}
      disableContextMenus={true}
      style={useStyle}
      placeholder={
        <AppTableRow>
          <AppTableCell />
          <AppTableCell />
          <AppTableCell />
          <AppTableCell />
          <AppTableCell />
          <AppTableCell />
          <AppTableCell />
        </AppTableRow>
      }
    >
      {selectedItems.map((item, index) => (
        <ItemRow key={index} index={index} item={item} />
      ))}
    </Reorder>
  );
};

ItemsTableBody.propTypes = {
  useStyle: PropTypes.object,
};

export default ItemsTableBody;
