import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchClients = async (filter) =>
  axios.get(`${process.env.REACT_APP_CLIENT_URL}/clients`, { params: filter });

const createClient = async (client) =>
  axios.post(`${process.env.REACT_APP_CLIENT_URL}/clients`, client);

const updateClient = async (id, client) =>
  axios.put(`${process.env.REACT_APP_CLIENT_URL}/clients/${id}`, client);

const deleteClient = async (id) =>
  axios.delete(`${process.env.REACT_APP_CLIENT_URL}/clients/${id}`);

export default {
  fetchClients,
  createClient,
  updateClient,
  deleteClient,
};
