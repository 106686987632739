import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import dispatch from './dispatch';

const AuthPage = (props) => {
  const { login } = props;
  useEffect(() => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const redirect = params.get('r');
    login(redirect);
  }, []);
  return null;
};

AuthPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
};

const AuthPagewithRouter = withRouter(AuthPage);
const ConnectedAuthPage = connect(null, dispatch)(AuthPagewithRouter);
export default ConnectedAuthPage;
