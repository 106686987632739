import SubscriptionRight from 'config/SubscriptionRight';
import { COMPANY_TYPE } from 'constants/company';
import { isArray } from 'lodash';
import { getUserRights } from 'selectors/rolesSelector';
import { getCurrentSubscriptionName } from 'selectors/subscriptionSelector';
import { store } from 'store';
import { isSubArray } from 'utils';

export const hasSubscriptionAccess = (subscriptionRight, requestedRight) => {
  if (!isArray(subscriptionRight)) {
    return false;
  }
  if (isArray(requestedRight)) {
    return isSubArray(subscriptionRight, requestedRight);
  }
  return subscriptionRight.includes(requestedRight);
};

export const hasRequestedAccess = (requestedRight) => {
  const state = store.getState();

  const { company } = state?.loggedUserCompany;
  if (company?.type === COMPANY_TYPE.ADMIN) {
    return true;
  }

  const roles = getUserRights(state);
  const subscriptionRight =
    SubscriptionRight[getCurrentSubscriptionName(state)];

  const checkIncludeRequestRight = roles.some(({ rights }) =>
    rights.includes(requestedRight)
  );
  if (!subscriptionRight) {
    return checkIncludeRequestRight;
  }

  if (isArray(requestedRight)) {
    return (
      roles.some(({ rights }) => isSubArray(rights, requestedRight)) &&
      hasSubscriptionAccess(subscriptionRight, requestedRight)
    );
  }

  return (
    checkIncludeRequestRight &&
    hasSubscriptionAccess(subscriptionRight, requestedRight)
  );
};

export const hasRequestedAccessFeature = (prefix) => {
  const state = store.getState();

  const { company } = state?.loggedUserCompany;
  if (company?.type === COMPANY_TYPE.ADMIN) {
    return true;
  }

  const roles = getUserRights(state);
  const subscriptionRight =
    SubscriptionRight[getCurrentSubscriptionName(state)];

  const checkRoleRight = roles.some(({ rights }) =>
    rights.find((r) => r.startsWith(prefix))
  );

  if (subscriptionRight) {
    const checkSubscriptionRight =
      subscriptionRight?.length > 0 &&
      subscriptionRight.some((right) => right.startsWith(prefix));
    return checkRoleRight && checkSubscriptionRight;
  }

  return checkRoleRight;
};

const hasRoles = (roleCheck) => {
  const roles = getUserRights(store.getState());
  const rolesList = roles.map((item) => item.role);
  return roleCheck.every((role) => rolesList.includes(role));
};

export default {
  hasRoles,
};
