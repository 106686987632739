import { getObjectMissingFields } from '../helpers/utils';
import { TYPE_SOCIETE } from '../constants/company';

export const companyDataAreMissing = ({
  loggedUserCompany: { company },
  loggedUserRights: { roles },
}) => {
  // eslint-disable-next-line camelcase
  if (!roles.find(({ is_admin }) => is_admin)) {
    return [];
  }

  const mandatory = [
    'address',
    'city',
    'zipcode',
    'siren',
    'legal_form',
    'settings.tax_registration',
    'endFiscalYear',
  ];

  if (company?.isContractor?.toString() === 'true') {
    mandatory.push('sirenDoc');
  } else {
    mandatory.push('kbiz');
  }

  if (
    company?.settings?.tax_registration &&
    company.settings.tax_registration.companyType === TYPE_SOCIETE
  ) {
    mandatory.push('capital');
  }

  return getObjectMissingFields(mandatory, company);
};

export const userDataAreMissing = ({
  loggedUser: { user },
  loggedUserRights: { roles },
}) => {
  // eslint-disable-next-line camelcase
  if (!roles.find(({ is_admin }) => is_admin)) {
    return [];
  }

  const mandatory = ['email', 'phonenumber', 'passport'];
  return getObjectMissingFields(mandatory, user);
};
