import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle } from '@material-ui/core';

import CircularProgressCentered from '../CircularProgressCentered';

const LoadingIndicatorDialog = ({ title, open }) => (
  <Dialog
    open={open}
    fullWidth={true}
    aria-labelledby="responsive-dialog-title"
  >
    {title && (
      <DialogTitle style={{ textAlign: 'center' }} id="responsive-dialog-title">
        {title}
      </DialogTitle>
    )}

    <CircularProgressCentered />
  </Dialog>
);

LoadingIndicatorDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
};

export default LoadingIndicatorDialog;
