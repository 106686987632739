import colors from '../../../constants/colors.json';

require('../../css/keyframe.css');

const style = {
  icon: {
    color: colors.fillsDarkGrey,
    padding: '10px',
    borderRadius: '50%',
    margin: 0,
    fontSize: '23px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    transform: 'rotate(0deg)',
    '&:hover': {
      cursor: 'pointer',
      color: colors.blue,
    },
    '&:active': {
      backgroundColor: colors.background,
    },
    '&:focus': {
      color: colors.blue,
      backgroundColor: 'transparent',
    },
  },

  leftRight: {
    transform: 'rotate(90deg)',
  },

  disabled: {
    backgroundColor: colors.fillsGrey,
    '&:hover': {
      backgroundColor: colors.fillsGrey,
    },
    pointerEvents: 'none',
  },

  rotate: {
    transform: 'rotate(360deg)',
    transition: 'transform 3s ease',
  },

  spin: { animation: 'spin 3s linear infinite' },

  secondary: {
    '&:hover': {
      color: colors.green,
    },
    '&:active': {
      color: colors.green,
    },
    '&:focus': {
      color: colors.green,
    },
  },

  sm: {
    fontSize: '15px',
    padding: '7px',
  },
  md: {
    fontSize: '23px',
    padding: '10px',
  },
  lg: {
    fontSize: '30px',
    padding: '14px',
  },
  xl: {
    fontSize: '60px',
    padding: '14px',
  },
  fill: {
    borderRadius: 0,
    width: '100%',
    height: '100%',
    backgroundColor: colors.light_grey,
  },
};

export default style;
