/* eslint-disable no-unused-vars */
/* eslint-disable import/no-duplicates */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import S3FileService from 'services/S3FileService';
import colors from '../../constants/colors.json';
import './pdfPreview.css';

const useStyles = makeStyles(() => ({
  pdfViewContaier: {
    minHeight: '100vh',
  },
  pdfViewContaierAsLogo: {
    height: '40vh',
  },
  pdfViewAsLogo: {
    minHeight: '30vh',
  },
  pdfDialogContaier: {
    height: 'auto',
    width: '80%',
    margin: '0 auto',
  },
  pdfPreview: {
    border: 'none',
    width: '100%',
    minHeight: '100vh',
  },
  pdfPreviewAsLogo: {
    border: 'none',
    width: '100%',
    height: '40vh',
  },
  pdfError: {
    position: 'absolute',
    top: '20%',
    left: '50%',
    transform: 'translateX(-50%)',
    color: colors.red,
    lineHeight: '13px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  pdfLoading: {
    position: 'absolute',
    left: '50%',
    top: '20%',
    transform: 'translateX(-50%)',
    color: colors.blue,
    lineHeight: '13px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
}));

const PdfViewer = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { file } = props;
  const [fileUrl, setFileUrl] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [error, serError] = useState(false);
  const containerViewPdfStyle =
    props.shape === 'companyDocument'
      ? classes.pdfViewContaierAsLogo
      : classes.pdfViewContaier;

  const getFileContent = async () => {
    try {
      setLoadingPdf(true);
      const data = await S3FileService.getContentPdfByUrl(file);
      const fileBlob = new Blob([data], { type: 'application/pdf' });
      const objectUrl = URL.createObjectURL(fileBlob);
      setFileUrl(objectUrl);
      setLoadingPdf(false);
    } catch {
      setLoadingPdf(false);
      serError('faild to loading pdf');
    }
  };
  useEffect(() => {
    getFileContent();
  }, [file]);
  return (
    <div
      style={{
        border: 'none',
        background: '#e2e2e2',
        overflowY: 'hidden',
        position: 'relative',
        borderRadius: '4px',
      }}
      className={
        props.isDialog ? classes.pdfDialogContaier : containerViewPdfStyle
      }
    >
      {loadingPdf && (
        <div className={classes.pdfLoading}>{t('pdf.loading')}...</div>
      )}
      {fileUrl && (
        <iframe
          className={
            props.shape === 'companyDocument'
              ? classes.pdfPreviewAsLogo
              : classes.pdfPreview
          }
          src={fileUrl}
        />
      )}
      {error && <div className={classes.pdfError}>{error}</div>}
    </div>
  );
};

PdfViewer.propTypes = {
  file: PropTypes.string,
  isDialog: PropTypes.bool,
  shape: PropTypes.string,
};

export default PdfViewer;
