// @ts-ignore
import React from 'react';
import PropTypes from 'prop-types';
import { useField } from 'formik';

// @material-ui/core
import { Typography, Grid, Switch } from '@material-ui/core';
import ErrorMessage from '../ErrorMessage';

const SwitchField = ({ name, labelLeft, labelRight, onChange, disabled }) => {
  const [field, meta] = useField(name);

  const { onChange: handleChange, onBlur: handleBlur, ...restField } = field;
  const { error, value } = meta;

  return (
    <Typography component="div">
      <Grid component="label" container alignItems="center" spacing={1}>
        <Grid item>{labelLeft}</Grid>
        <Grid item>
          <Switch
            checked={Boolean(value)}
            onChange={onChange || handleChange}
            disabled={disabled}
            {...restField}
          />
        </Grid>
        <Grid item>{labelRight}</Grid>
      </Grid>
      <ErrorMessage error={error} showError={!!error} />
    </Typography>
  );
};

SwitchField.displayName = 'FormikMaterialUISwitchField';

SwitchField.propTypes = {
  name: PropTypes.string.isRequired,
  labelLeft: PropTypes.string,
  labelRight: PropTypes.string,
  disabled: PropTypes.object,
  onChange: PropTypes.func,
};

export default SwitchField;
