/* eslint-disable no-template-curly-in-string */
import { find } from 'lodash';

const getEmailQuotation = (user, company, emailTemplates) => {
  const res = find(
    emailTemplates,
    (emailTemplate) => emailTemplate.reference === 'quotation1'
  );
  if (res && res.content)
    return res.content
      .replace('${company.name}', company.name)
      .replace('${user.display_name}', user.display_name);
  return '';
};

const getEmailOrderForm = (user, company, emailTemplates) => {
  const res = find(
    emailTemplates,
    (emailTemplate) => emailTemplate.reference === 'orderForm1'
  );
  if (res && res.content)
    return res.content
      .replace('${company.name}', company.name)
      .replace('${user.display_name}', user.display_name);
  return '';
};

const getEmailInvoice = (user, company, emailTemplates) => {
  const res = find(
    emailTemplates,
    (emailTemplate) => emailTemplate.reference === 'invoice1'
  );
  if (res && res.content)
    return res.content
      .replace('${company.name}', company.name)
      .replace('${user.display_name}', user.display_name);
  return '';
};

const getEmailDefaultResend = (user, company, emailTemplates) => {
  const res = find(
    emailTemplates,
    (emailTemplate) => emailTemplate.reference === 'resend1'
  );
  if (res && res.content)
    return res.content
      .replace('${company.name}', company.name)
      .replace('${user.display_name}', user.display_name);
  return '';
};

const getEmailDefault = (user, company, emailTemplates) => {
  const res = find(
    emailTemplates,
    (emailTemplate) => emailTemplate.reference === 'default'
  );
  if (res && res.content)
    return res.content
      .replace('${company.name}', company.name)
      .replace('${user.display_name}', user.display_name);
  return '';
};

const getEmailCreditNote = (user, company, emailTemplates) => {
  const res = find(
    emailTemplates,
    (emailTemplate) => emailTemplate.reference === 'creditNote1'
  );
  if (res && res.content)
    return res.content
      .replace('${company.name}', company.name)
      .replace('${user.display_name}', user.display_name);
  return '';
};

export {
  getEmailQuotation,
  getEmailCreditNote,
  getEmailInvoice, // with invoice view link
  getEmailOrderForm,
  getEmailDefaultResend,
  getEmailDefault,
};
