import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const WalletDeleteReConfirmCompleteCondition = (props) => {
  const { onChangeStep } = props;
  const history = useHistory();

  const [confirmChecker, setConfirmChecker] = useState(false);

  const onChangeConfirmChecker = (e) => setConfirmChecker(e.target.checked);

  const onClickContinue = () => {
    onChangeStep(6);
  };

  const onClickKeepMyAccount = () => {
    history.push('/bank-bbf/accounts');
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box style={{ padding: '10px 0', maxWidth: '800px' }}>
        <Box style={{ paddingBottom: '10px' }}>
          <Typography
            variant="h4"
            color="primary"
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              paddingBottom: '5px',
            }}
          >
            Vous souhaitez clôturer votre compte
          </Typography>
          <Typography
            variant="h5"
            style={{ color: 'black', textAlign: 'center' }}
          >
            A la date d’aujourd’hui vous ne remplissez pas les conditions pour
            clôturer votre compte.
          </Typography>
          <Typography
            variant="h5"
            style={{ color: 'black', textAlign: 'center' }}
          >
            Vous avez donc la possibilité de clôturer immédiatement votre
            compte.
          </Typography>
        </Box>
        <FormControlLabel
          style={{
            paddingBottom: '30px',
          }}
          label="Je comprends que la clôture de mon compte est une opération définitive et irréversible"
          control={
            <Checkbox
              checked={confirmChecker}
              onChange={onChangeConfirmChecker}
              name="confirmChecker"
            />
          }
        />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '0 120px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onClickContinue}
            disabled={!confirmChecker}
          >
            Clôturer définitivement mon compte
          </Button>
          <Button
            variant="outlined"
            color="default"
            size="large"
            onClick={onClickKeepMyAccount}
          >
            Conserver mon compte
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

WalletDeleteReConfirmCompleteCondition.propTypes = {
  onChangeStep: PropTypes.func,
};

export default WalletDeleteReConfirmCompleteCondition;
