/* Packages */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import MaterialTable from 'material-table';
import { getSEPARequests } from 'actions/SEPAActions';
import { format } from 'date-fns';
import qs from 'qs';

/* Icons */
import { EyesIcon } from 'components/AppIcons';

/* Constants */
import SEPA_TYPE_OF_REQUESTS from 'containers/BankBBF/constants/typeOfRequest';

/* Components */
import Layout from 'components/Layout';
import Header from 'components/Header';

const SEPARequestList = () => {
  const history = useHistory();
  const tableRef = useRef();
  const { t } = useTranslation();

  const columns = [
    {
      title: 'Created At',
      field: 'created_at',
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <span>{format(new Date(rowData.created_at), 'dd/MM/yyyy HH:mm')}</span>
      ),
      filtering: false,
      sorting: false,
    },
    {
      title: 'ID',
      field: '_id',
      filtering: false,
      sorting: false,
    },

    {
      title: 'Type',
      field: 'typeOfRequest',
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <span>
          {t(
            SEPA_TYPE_OF_REQUESTS.find((r) => r.value === rowData.typeOfRequest)
              .label
          )}
        </span>
      ),
      filtering: false,
      sorting: false,
    },
    {
      title: 'Subject',
      field: 'subject',
      filtering: false,
      sorting: false,
    },
    {
      title: 'Status',
      field: 'requestStatus',
      filtering: false,
      sorting: false,
    },
    {
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <EyesIcon
          sm
          onClick={() => {
            history.push({
              pathname: `/bank-bbf/sepa/request/view`,
              state: rowData,
            });
          }}
        />
      ),
      filtering: false,
      sorting: false,
    },
  ];

  const getSEPARequestListData = async (queryTable) => {
    const parseObjectToQueryString = qs.stringify({
      ...queryTable,
      page: queryTable.page + 1,
    });

    const response = await getSEPARequests(parseObjectToQueryString);

    return {
      data: response.data.docs,
      totalCount: response.data.totalDocs,
      page: response.data.page - 1,
    };
  };

  return (
    <SetupLayout>
      <MaterialTable
        tableRef={tableRef}
        title="Transactions"
        data={getSEPARequestListData}
        columns={columns}
        options={{
          filtering: false,
          search: true,
          showTitle: false,
          pageSizeOptions: [],
        }}
        localization={{
          toolbar: { searchPlaceholder: t('sepa.search_by_subject_or_id') },
        }}
      />
    </SetupLayout>
  );
};

function SetupLayout({ children }) {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Layout
      header={
        <Header
          name={t('sepa.your_SEPA_request_tickets')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={children}
    />
  );
}

SetupLayout.propTypes = {
  children: PropTypes.node,
};

export default SEPARequestList;
