import {
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  FETCHING_BALANCES,
  FETCH_BALANCES_FAILED,
  FETCH_BALANCES_SUCCESS,
} from 'actions/Types';

const initialState = {
  loading: false,
  error: null,
  timestamp: null,
  data: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_BALANCES:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case FETCH_BALANCES_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        data: null,
        loading: false,
        error,
      };
    }
    case FETCH_BALANCES_SUCCESS: {
      const { resources } = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        data: resources,
      };
    }

    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return initialState;

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
};
