import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchCategories = async (filter) =>
  axios.get(`${process.env.REACT_APP_CATEGORIES_URL}/categories`, {
    params: filter,
  });

const updateCategory = async (newCategory) =>
  axios.post(`${process.env.REACT_APP_CATEGORIES_URL}/category`, newCategory);

export default {
  fetchCategories,
  updateCategory,
};
