import React from 'react';
import PropTypes from 'prop-types';

import FormControl from '@material-ui/core/FormControl';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';

import withStyles from '@material-ui/core/styles/withStyles';
// import classNames from 'classnames';
import style from '../../assets/jss/components/appRadioButton';

const AppRadioGroup = ({
  name,

  onChange,
  valueSelected,
  children,
  grouplabel,
  row,
  color,
  classes,
  styles,
}) => {
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      name,
      valueselected: valueSelected,
      onChange,
      color,
    })
  );

  return (
    <FormControl
      component="fieldset"
      style={styles}
      className={classes.formControl}
    >
      {grouplabel && <FormLabel component="legend">{grouplabel}</FormLabel>}

      <RadioGroup
        aria-label={name}
        name={name}
        value={valueSelected}
        onChange={onChange}
        row={row}
      >
        {childrenWithProps}
      </RadioGroup>
    </FormControl>
  );
};

AppRadioGroup.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  valueSelected: PropTypes.string,
  grouplabel: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.node]),
  disabled: PropTypes.bool,
  row: PropTypes.bool,
  swap: PropTypes.bool,
  styles: PropTypes.object,
};
AppRadioGroup.defaultProps = {
  valueSelected: '',
  color: 'primary',
  grouplabel: null,
  value: null,
  row: false,
  swap: false,
};

export default withStyles(style)(AppRadioGroup);
