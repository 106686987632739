import { deepValue } from './utils';

// Helper function
function concatUserRoles(roleArray) {
  const roleArrayConcatenated = [];
  // we parse the arry of roles. Each role will have a role key
  // we builda string such as 'collaborator, administrator
  if (roleArray && roleArray.length > 0) {
    roleArray.forEach((element) => {
      roleArrayConcatenated.push(element.role);
    });
  }
  return roleArrayConcatenated.toString();
}

// eslint-disable-next-line import/prefer-default-export
export function filterStateForAnalytics(currentState) {
  // This function extracts key properties from the Redux State, and exports them in flat array, for simple use by the GA Data Layer
  const analyticsState = {
    loggedUser: {},
    loggedUserCompany: {},
    loggedUserRights: {},
  };
  // User  detail
  if (currentState) {
    analyticsState.loggedUser._id = deepValue(
      currentState,
      'loggedUser.user._id'
    );
    analyticsState.loggedUser.status = deepValue(
      currentState,
      'loggedUser.user._id'
    )
      ? 'logged_in'
      : 'logged_out';
  }
  // Company  detail
  if (currentState) {
    analyticsState.loggedUserCompany._id = deepValue(
      currentState,
      'loggedUserCompany.company._id'
    );
    analyticsState.loggedUserCompany.name = deepValue(
      currentState,
      'loggedUserCompany.company.name'
    );
    analyticsState.loggedUserCompany.suspended = deepValue(
      currentState,
      'loggedUserCompany.company.suspended'
    );
  }
  // User Rights  detail
  if (currentState) {
    analyticsState.loggedUserRights.count =
      currentState &&
      currentState.loggedUserRights &&
      currentState.loggedUserRights.roles
        ? currentState.loggedUserRights.roles.length
        : null;
    analyticsState.loggedUserRights.rights =
      currentState &&
      currentState.loggedUserRights &&
      currentState.loggedUserRights.roles
        ? concatUserRoles(currentState.loggedUserRights.roles)
        : null;
  }

  return analyticsState;
}
