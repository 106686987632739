import { authInstance as axios } from '../../helpers/axiosInterceptor';
import { store } from '../../store';

const fetchProviders = async (filter) => {
  const companyId =
    store.getState().loggedUserCompany &&
    store.getState().loggedUserCompany.company != null
      ? store.getState().loggedUserCompany.company._id
      : undefined;
  if (companyId)
    return axios.get(`${process.env.REACT_APP_PROVIDER_URL}/providers`, {
      params: filter,
    });
  // if not logged in returns an empty array
  return new Promise((resolve) => {
    resolve({ data: [] });
  });
};

const createProvider = async (provider) =>
  axios.post(`${process.env.REACT_APP_PROVIDER_URL}/providers`, provider);

const updateProvider = async (id, provider) =>
  axios.put(`${process.env.REACT_APP_PROVIDER_URL}/providers/${id}`, provider);

export default {
  updateProvider,
  createProvider,
  fetchProviders,
};
