import logger from 'helpers/logger';

// eslint-disable-next-line import/prefer-default-export
export const prepareData = (
  values,
  walletId,
  payoutDocuments,
  source,
  showIban,
  showBic,
  expenseId
) => {
  logger.log('THIS FORM IS getting FORMIK VALUES', values);
  const { beneficiary, isShowedUploadPart } = values;
  const { beneficiaryId, _id: entityOId } = beneficiary;

  let payoutData = {
    otp: values.otp,
    amount: values.amount,
    reference: values.reference,
    transferDate: values.transferDate,
    note: values.note,
    walletId,
    currency: 'EUR',
  };

  if (!beneficiaryId) {
    const { beneficiaryType } = values;
    payoutData = { ...payoutData, beneficiaryType, entityOId };
  } else {
    payoutData = { ...payoutData, beneficiaryId };
  }

  if (isShowedUploadPart && payoutDocuments) {
    const { beneficiary: bbfBeneficiary } = values;
    payoutData = { ...payoutData, payoutDocuments, bbfBeneficiary };
  }

  if (source) {
    payoutData = { ...payoutData, source };
  }

  if (showIban) {
    payoutData = { ...payoutData, iban: values.iban };
  }

  if (showBic) {
    payoutData = { ...payoutData, bic: values.bic };
  }

  if (expenseId) {
    payoutData = { ...payoutData, expenseId };
  }

  return { ...payoutData };
};
