import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core/';

const InvoiceHeader = ({ data }) => {
  const { t } = useTranslation();
  return (
    <>
      <Typography variant="h3" style={{ textAlign: 'center' }}>
        {data.title}
      </Typography>
      <Typography gutterBottom variant="h5">
        {t('invoices.from')}
        <span style={{ float: 'right' }}>{data.date_emission}</span>
      </Typography>
      <div style={{ paddingLeft: '2%', paddingBottom: '2%' }}>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.company_name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.company_address1}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.company_address2}
        </Typography>
      </div>
      <Typography gutterBottom variant="h5" component="h2">
        {t('invoices.to')}
      </Typography>
      <div style={{ paddingLeft: '2%', paddingBottom: '2%' }}>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.client_name}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.client_contact}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.client_address1}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.client_address2}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.client_email}
        </Typography>
      </div>
      <Typography gutterBottom variant="h5" component="h2">
        {t('invoices.header.reference_info')}
      </Typography>
      <div style={{ paddingLeft: '2%', paddingBottom: '2%' }}>
        <Typography variant="body2" color="textSecondary" component="p">
          {data.invoice_nb}
        </Typography>
      </div>
    </>
  );
};

InvoiceHeader.propTypes = {
  data: PropTypes.object,
};

export default InvoiceHeader;
