export const PHONE_NUMBER_VALIDATE_SCHEMA =
  /^(0|(\+33[\s]?([0]?|[(0)]{3}?)))[1-9]([-. ]?[0-9]{2}){4}$/;

export const REGEX = {
  PHONE: {
    FR: {
      E164: /^(?:(?:\+)33)\s*[1-9](?:[\s.-]*\d{2}){4}$/,
    },
  },
  IBAN: /^FR\d{12}[0-9A-Z]{11}\d{2}$/,
  IS_NUMBER: /^[0-9]*$/gm,
};

export const PHONE_NUMBER_TEXT_REGEX = /^[0-9+]*$/;
