import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { authInstance as axios } from 'helpers/axiosInterceptor';
import { Grid, Typography } from '@material-ui/core';

import Lottie from 'react-lottie';

import * as successLottie from 'assets/lottieAnimations/success.json';
import * as bankApiConnectionFailedLottie from 'assets/lottieAnimations/bankApiConnectionFailed.json';

// import AppButton from '../../components/AppButton';
import { findIndex } from 'lodash';
import CircularProgressCentered from 'components/CircularProgressCentered';
import { updateCompany } from 'actions/CompanyActions';
import BankSync from 'components/BankSync';
import AppButton from 'components/AppButton';
import { useRouter } from '../../../hooks/routerHooks';
import Header from '../../../components/Header';
import Layout from '../../../components/Layout';

const defaultOptionsSuccess = {
  loop: false,
  autoplay: true,
  animationData: successLottie.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
const defaultOptionsFailure = {
  loop: false,
  autoplay: true,
  animationData: bankApiConnectionFailedLottie.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

const CallbackBankin = ({ responseFromBankinApi }) => {
  //   console.log('responseFromBankinApi:', responseFromBankinApi);

  const companyId = useSelector((state) => state.loggedUserCompany.company._id);
  const companySettings = useSelector(
    (state) => state.loggedUserCompany.company.settings
  );
  const user = useSelector((state) => state.loggedUser.user);

  const { history } = useRouter();
  const [accounts, setAccounts] = useState([]);
  const [banks, setBanks] = useState(
    useSelector((state) => state.loggedUserCompany.company.bank_details)
  );
  const [itemDetails, setItemDetails] = useState({});
  // const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [syncAccount, setSyncAccount] = useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const getItemDetails = async () => {
    // TODO: action / service
    setLoading(true);
    const res = await axios.get(
      `${process.env.REACT_APP_BRIDGE_URL}/accounts`,
      {
        headers: {
          'X-Company-Id': companyId,
          'Accept-Language': 'fr',
          'Content-Type': 'application/json',
          Origin: window.location.origin,
        },
        params: {
          user_id: user._id,
          item_id: responseFromBankinApi.item,
        },
      }
    );

    const dataRes = res.data;

    if (dataRes.data) {
      const synchronized = findIndex(dataRes.data, (account) => {
        return account.synchronize === true;
      });
      if (synchronized !== -1) {
        history.push('/bank/accounts');
      }
      setItemDetails(dataRes);
    } else {
      // setError(t('thereWasAnErrorPleaseRefreshThePage'));
    }
  };

  useEffect(() => {
    if (responseFromBankinApi.success === 'true') getItemDetails();
  }, []);

  useEffect(() => {
    if (itemDetails.data) {
      setAccounts(itemDetails.data);
      setSyncAccount(true);
    }
  }, [itemDetails]);

  useEffect(() => {
    if (syncAccount === true) {
      const promises = [];
      let updateBanks = false;
      const newBanks = [...banks];
      const newAccounts = [...accounts];

      for (let i = 0; i < accounts.length; i += 1) {
        const bankIndex = findIndex(
          banks,
          (bank) =>
            accounts[i].iban === bank.iban && !bank.bankinId && bank.walletId
        );
        if (bankIndex !== -1) {
          updateBanks = true;
          newAccounts[i] = {
            ...accounts[i],
            synchronize: true,
          };
          newBanks[bankIndex] = {
            ...banks[bankIndex],
            bank_name: `${accounts[i].name}`,
            bankinId: accounts[i].id,
          };
          // TODO: action / service
          promises.push(
            axios.put(
              `${process.env.REACT_APP_BANKFEED_URL}/accounts/${companyId}/${accounts[i]._id}`,
              {
                synchronize: true,
              }
            )
          );
        }
      }
      if (updateBanks)
        dispatch(
          updateCompany({
            _id: companyId,
            bank_details: newBanks,
            settings: companySettings,
          })
        );
      setBanks(newBanks);
      setAccounts(newAccounts);
      Promise.all(promises).then(() => {
        setLoading(false);
      });
    }
  }, [syncAccount]);

  const renderSuccess = () => {
    if (responseFromBankinApi && responseFromBankinApi.success === 'true') {
      return (
        <div>
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            {t('successful_connexion')}
          </Typography>
          <Lottie options={defaultOptionsSuccess} height={100} width={100} />
        </div>
      );
    }
    if (responseFromBankinApi && responseFromBankinApi.success === 'false') {
      return (
        <div>
          <Typography variant="h4" style={{ textAlign: 'center' }}>
            {t('connexion_failed')}
          </Typography>
          <Lottie options={defaultOptionsFailure} height={100} width={100} />
        </div>
      );
    }
    return null;
  };

  const renderBody = () => {
    return (
      <Grid container>
        <Grid item xs={12}>
          {renderSuccess()}
          {(loading || syncAccount) && (
            <Typography variant="subtitle1" style={{ textAlign: 'center' }}>
              {t('pleaseSelectTheAccountsYouWantToUseForYourBookkeeping')}
            </Typography>
          )}
          <div
            style={{
              margin: 10,
            }}
          >
            <AppButton
              text={t('bridgeapi.callBackButton')}
              onClick={() => {
                history.push('/bank/accounts');
              }}
            />
          </div>
        </Grid>
        <Grid item xs={12}>
          {loading && <CircularProgressCentered />}
          {!loading && accounts.length > 0 && <BankSync accounts={accounts} />}
        </Grid>
      </Grid>
    );
  };

  return (
    <Layout
      header={
        <Header
          name={t('callback_from_bank_connexion')}
          goBack={() => {
            history.push('/bank/accounts');
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

CallbackBankin.propTypes = {
  responseFromBankinApi: PropTypes.object,
};

export default CallbackBankin;
