import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { List } from '@material-ui/core';

// import AppButton from '../../components/AppButton';
import { dropWhile, findIndex, mapKeys, mapValues, isEmpty } from 'lodash';
import { updateCompany } from 'actions/CompanyActions';
import { AppTextContent, AppTextPlain } from 'components/AppText';
import BankCard from 'components/BankForm/BankCard';
import AppButton from 'components/AppButton';

import AccountItem from 'components/AccountItem';

const BankSync = (props) => {
  const companyId = useSelector((state) => state.loggedUserCompany.company._id);
  const companySettings = useSelector(
    (state) => state.loggedUserCompany.company.settings
  );
  const [accounts, setAccounts] = useState([]);
  const [banks, setBanks] = useState(
    useSelector((state) => state.loggedUserCompany.company.bank_details)
  );
  const [toSync, setToSync] = useState({});
  const [bankDetailsError, setBankDetailsError] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    setAccounts(props.accounts);
    setToSync(
      mapValues(mapKeys(props.accounts, '_id'), (account) => ({
        bank_name: account.name,
        iban: account.iban || '',
        ibanDisabled: !!account.iban,
      }))
    );
  }, [props.accounts]);

  const synchronize = (account, bank) => {
    if (isEmpty(bank.bank_name) || isEmpty(bank.bic) || isEmpty(bank.iban)) {
      setBankDetailsError(true);
      return;
    }
    if (bankDetailsError) {
      setBankDetailsError(false);
    }

    const newBank = bank;
    const newBanks = [...banks];
    const newAccounts = [...accounts];
    newBank.bank_name = account.name;
    newBank.bankinId = account.id;
    let index = findIndex(banks, (b) => b.iban === newBank.iban);
    if (index !== -1) newBanks[index] = newBank;
    else newBanks.push(newBank);
    index = findIndex(accounts, (a) => a._id === account._id);
    setAccounts(newAccounts);
    setBanks(newBanks);
    dispatch(
      updateCompany({
        _id: companyId,
        bank_details: newBanks,
        settings: companySettings,
      })
    );
  };

  const renderBankSync = (account) => {
    const synchronizedWith = findIndex(
      banks,
      // eslint-disable-next-line eqeqeq
      (bank) => bank.bankinId == account.id
    );
    const unSync = dropWhile(banks, (bank) => bank.bankinId || bank.walletId);
    if (synchronizedWith !== -1) {
      return (
        <span>
          <AppTextContent>{'Synchronisé avec: '}</AppTextContent>
          <AppTextPlain>{`${banks[synchronizedWith].bank_name} IBAN: ${banks[synchronizedWith].iban}`}</AppTextPlain>
        </span>
      );
    }
    if (unSync.length) {
      return (
        <span>
          {toSync[account._id].selected && (
            <BankCard
              required
              hideJournal
              disabled
              bankDetailsError={bankDetailsError}
              bank={{ ...toSync[account._id], bank_name: account.name }}
              synchronizeButton={() => (
                <AppButton
                  text="OK"
                  fullWidth
                  onClick={() => {
                    synchronize(account, toSync[account._id]);
                  }}
                />
              )}
            />
          )}
        </span>
      );
    }
    return (
      <>
        <p>
          Desole notre partenaire bancaire ne nous a pas fourni toutes les
          informations du compte
        </p>
        <BankCard
          required
          hideJournal
          disabledFields={{
            bank_name: true,
            iban: toSync[account._id].ibanDisabled,
          }}
          bankDetailsError={bankDetailsError}
          onChange={(value, name) => {
            const newObj = { ...toSync };
            newObj[account._id][name] = value;
            setToSync(newObj);
          }}
          bank={toSync[account._id]}
          synchronizeButton={() => (
            <AppButton
              text="OK"
              fullWidth
              onClick={() => {
                synchronize(account, toSync[account._id]);
              }}
            />
          )}
        />
      </>
    );
  };

  return (
    <List>
      {accounts.map((account, index) => (
        <div
          key={account._id}
          style={{
            borderWidth: 1,
            borderColor: 'LightGray',
            borderStyle: 'solid',
            borderRadius: 10,
            margin: 5,
            padding: 10,
          }}
        >
          <AccountItem
            key={account.id}
            account={account}
            companyId={companyId}
            isSync={account.synchronize}
            onSynchronize={(sync) => {
              const newAccounts = [...accounts];
              newAccounts[index] = {
                ...accounts[index],
                synchronize: sync,
                noValidate: true, // only to have the possibility to uncheck
              };
              setAccounts(newAccounts);
            }}
            disabled={
              (account.synchronize && !account.noValidate) ||
              // eslint-disable-next-line eqeqeq
              findIndex(banks, (bank) => bank.bankinId == account.id) !== -1
            }
          />
          {account.synchronize && renderBankSync(account)}
        </div>
      ))}
    </List>
  );
};

BankSync.propTypes = {
  responseFromBankinApi: PropTypes.object,
  accounts: PropTypes.array.isRequired,
};

export default BankSync;
