/*
 *
 * DEPRECATED
 * Nov 2019 - Please no longer use
 *
 */

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import { IconButton } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import classNames from 'classnames';
import style from '../../assets/jss/components/appTextInput';
import { IconSearch } from '../AppIcons';
import '../../assets/css/icons.css';
import printErrors from '../../helpers/errorHelpers';

const colors = require('../../constants/colors.json');

const AppTextInput = ({
  placeholder,
  type,
  fullWidth,
  setWidthManualy,
  value,
  onChange,
  classes,
  color,
  label,
  disabled,
  alignRight,
  error,
  mandatory,
  form,
  field,
  onBlur,
  id,
  onKeyUp,
  withSearchIcon,
  onIconClick,
  withCurrency,
  onKeyPress,
  helperText,
  DT,
  TL,
  TP,
  M,
  ...rest
}) => {
  const { name } = field;
  const { errors, isSubmitting, touched, handleChange } = form;

  const [settedType, setType] = useState(type);

  function onButtonClick() {
    setType(settedType === 'password' ? 'text' : 'password');
  }

  function renderButton() {
    if (type !== 'password') {
      return (
        <IconButton onClick={onButtonClick}>
          <Visibility />
        </IconButton>
      );
    }

    return (
      <IconButton onClick={onButtonClick}>
        <VisibilityOff />
      </IconButton>
    );
  }

  const [labelFocused, changeLabelFocus] = useState(false);
  const [setValue, handleValueChange] = useState('');
  const inputContainerClasses = classNames({
    [classes.inputContainer]: true,
    [classes[`inputContainerDT${DT}`]]: !!DT,
    [classes[`inputContainerTL${TL}`]]: !!TL,
    [classes[`inputContainerTP${TP}`]]: !!TP,
    [classes[`inputContainerM${M}`]]: !!M,
    [classes.fullWidth]: fullWidth,
    [classes[color]]: color,
    [classes[`${color}Caret`]]: color,
    [classes[`${color}WithValue`]]: value && value.length > 0,
    [classes.disabled]: isSubmitting || disabled,
    [classes.mandatory]: mandatory,
    [classes[`${color}Error`]]:
      (touched[name] && !!errors[name] && mandatory) || error,
  });
  const inputClasses = classNames({
    [classes.inputField]: true,
    [classes.fullWidth]: true,
    [classes.alignRight]: alignRight,
  });
  const inputLabel = classNames({
    [classes[`${color}Label`]]: label,
    [classes.showLabel]: label && value && value.length > 0,
    [classes[`${color}LabelFocused`]]: labelFocused,
    [classes[`${color}LabelError`]]: (touched[name] && !!errors[name]) || error,
  });
  const errorMessage = classNames({
    [classes[`${color}ErrorMessage`]]: true,
  });

  const setWidth = setWidthManualy ? { width: setWidthManualy } : null;

  function handleAndForm(event) {
    handleValueChange(event.target.value);
    if (handleChange) {
      handleChange(event);
    }
  }

  useEffect(() => handleValueChange(value), [value]);

  const handleOnblur = (e) => {
    changeLabelFocus(false);
    field.onBlur(e);
    onBlur(e);
  };

  return (
    <>
      <div className={inputContainerClasses} style={setWidth}>
        {!!label && (
          <div className={inputLabel}>
            {label} {mandatory && <span>*</span>}
          </div>
        )}

        <input
          {...rest}
          onFocus={() => changeLabelFocus(true)}
          onBlur={(e) => handleOnblur(e)}
          className={inputClasses}
          type={settedType}
          placeholder={`${placeholder} ${mandatory ? '*' : ''}`}
          value={setValue}
          onChange={onChange || handleAndForm}
          autoComplete="Vasenka"
          disabled={disabled}
          name={name}
          id={id}
          /* eslint-disable-next-line */
          onKeyUp={(e) => onKeyUp(e)}
          onKeyPress={onKeyPress}
        />
        {type === 'password' && renderButton()}
        {withSearchIcon && <IconSearch onClick={() => onIconClick()} />}
        {withCurrency && (
          <span
            style={{
              ...style.iconStyle,
              ...{
                marginRight: '20px',
              },
            }}
          >
            €
          </span>
        )}
        {printErrors(name, mandatory, errors, touched, errorMessage)}
      </div>

      {/* {helperText && !touched[name] && ( */}
      {helperText && (
        <label
          style={{
            marginLeft: '5px',
            fontSize: '12px',
            fontWeight: 'normal',
            color: colors.textGrey,
          }}
        >
          {helperText}
        </label>
      )}
    </>
  );
};
AppTextInput.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object,
  field: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  id: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onIconClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  placeholder: PropTypes.string,
  setWidthManualy: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool,
  error: PropTypes.bool,
  alignRight: PropTypes.bool,
  withSearchIcon: PropTypes.bool,
  withCurrency: PropTypes.bool,
  type: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  color: PropTypes.string,
  label: PropTypes.string,
  onKeyPress: PropTypes.func,
  helperText: PropTypes.string,
  DT: PropTypes.string,
  TL: PropTypes.string,
  TP: PropTypes.string,
  M: PropTypes.string,
};
AppTextInput.defaultProps = {
  placeholder: 'Search',
  type: 'text',
  withSearchIcon: false,
  fullWidth: false,
  disabled: false,
  mandatory: false,
  error: false,
  value: '',
  id: '',
  label: '',
  alignRight: false,
  withCurrency: false,
  setWidthManualy: null,
  form: null,
  onIconClick: null,
  field: null,
  onChange: null,
  onKeyUp: () => {},
  onBlur: () => {},
  color: 'primary',
};

export default withStyles(style)(AppTextInput);
