import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import AppButton from 'components/AppButton';
import { TreezorFormContext } from 'contexts/TreezorContext/TreezorFormContext';
import IncomeRange from 'assets/data/IncomeRange';
import PersonalAssets from 'assets/data/PersonalAssets';
import ErrorMessage from '../../../ErrorMessage';
import FormLoading from '../FormLoading';
import UserFormFields from './UserFormFields';

const UserForm = ({
  countryList,
  personelTitle,
  identityList,
  identityList2,
  proofOfAddress,
  companyCredentials,
  company,
  goBack,
  selectRole,
  setCreateUser,
}) => {
  const { t } = useTranslation();
  const { errors, handleSubmit, isSubmitting, setFieldValue } =
    useFormikContext();
  const { role, rattacheAPersonneMorale, newUser } =
    useContext(TreezorFormContext);
  const [disableBtn, setDisableBtn] = useState(false);
  const setDisableSendBtn = (val) => {
    setDisableBtn(val);
  };

  return (
    <div>
      <FormLoading loading={isSubmitting} />
      <Grid container justify="center" spacing={1}>
        <Grid item xs={12}>
          <ErrorMessage
            error={
              errors.handleSubmitTreezorErrors
                ? errors.handleSubmitTreezorErrors
                : ''
            }
            showError={errors && errors.handleSubmitTreezorErrors}
          />
        </Grid>
      </Grid>
      <UserFormFields
        countryList={countryList}
        personelTitle={personelTitle}
        identityList={identityList}
        identityList2={identityList2}
        proofOfAddress={proofOfAddress}
        companyCredentials={companyCredentials}
        company={company}
        setFieldValue={setFieldValue}
        setDisableSendBtn={setDisableSendBtn}
        incomeRange={IncomeRange}
        personalAssets={PersonalAssets}
        role={role}
        goBack={goBack}
        selectRole={selectRole}
        rattacheAPersonneMorale={rattacheAPersonneMorale}
        setCreateUser={setCreateUser}
        newUser={newUser}
      />

      <Grid container>
        <Grid item xs={12}>
          <AppButton
            text={t('save')}
            // isDisabled={user[0].formSaved || disableBtn}
            isDisabled={disableBtn}
            noBorder
            type="button"
            onClick={handleSubmit}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
};

UserForm.propTypes = {
  countryList: PropTypes.array,
  personelTitle: PropTypes.array,
  identityList: PropTypes.array,
  identityList2: PropTypes.array,
  proofOfAddress: PropTypes.array,
  companyCredentials: PropTypes.array,
  company: PropTypes.object,
  setUser: PropTypes.func,
  goBack: PropTypes.func,
  selectRole: PropTypes.func,
  setCreateUser: PropTypes.func,
};

export default UserForm;
