import { v4 as uuidv4 } from 'uuid';
import { setGlobalError } from 'actions/GlobalError';
import s3FileService from 'services/S3FileService';
import payoutService from 'services/PayoutService';
import { getFileExtension } from 'helpers/utils';

import {
  CREATING_PAYOUT,
  CREATE_PAYOUT_SUCCESS,
  CREATE_PAYOUT_FAILED,
} from '../Types';

export const creatingPayouts = () => ({
  type: CREATING_PAYOUT,
});

export const createPayoutSuccess = (payout) => ({
  type: CREATE_PAYOUT_SUCCESS,
  payload: { payout },
});

export const createPayoutFailed = (error) => ({
  type: CREATE_PAYOUT_FAILED,
  payload: { error },
});

// eslint-disable-next-line
export const createPayout = (data) => async (dispatch, getState) => {
  try {
    const {
      loggedUser: { user: loggedUser },
    } = getState();

    const { _id: userId } = loggedUser;

    const payoutData = { ...data, userId };
    dispatch(creatingPayouts());
    const response = (await payoutService.createPayouts({ ...payoutData }))
      .data;

    const { treezorPayoutData } = response;
    if (treezorPayoutData) {
      const { payoutId, errors } = treezorPayoutData;
      if (payoutId) {
        dispatch(createPayoutSuccess(treezorPayoutData));
      }
      if (errors) {
        dispatch(createPayoutFailed(errors[0]));
        dispatch(setGlobalError(errors[0]));
      }
    }
    return response;
  } catch (err) {
    dispatch(setGlobalError(err));
    dispatch(createPayoutFailed(err));
    return err;
  }
};

/* eslint-disable-next-line */
export const uploadContractFiles = (files, company) => async (dispatch) => {
  try {
    const documents = [];
    await Promise.all(
      files.map(async (file) => {
        const ext = getFileExtension(file.name);
        const folder = `company-files-${company.alternativeId}`;
        const name = `${uuidv4()}-${new Date().toISOString()}.${ext}`;
        const filename = `${folder}/${name}`;
        await s3FileService.upload(file, folder, name);
        documents.push({ type: 1, filename });
      })
    );
    return documents;
  } catch (error) {
    dispatch(setGlobalError(error));
  }
};
