import React from 'react';
import PropTypes from 'prop-types';

const NoMatchPage = ({ location }) => (
  <div className="container">
    <h4 className="display-4">
      No match for <code>{location.pathname}</code>
    </h4>
  </div>
);

NoMatchPage.propTypes = { location: PropTypes.object.isRequired };

export default NoMatchPage;
