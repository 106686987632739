/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { useTranslation } from 'react-i18next';
import { map, uniqBy } from 'lodash';
import { format } from 'date-fns';

import { getMuiDatatableOptions } from 'helpers/tableHelpers';

import { IconEdit } from 'components/AppIcons';
import { useRouter } from 'hooks/routerHooks';
import { useDispatch } from 'react-redux';
import { TRANSACTION_STATE, TRANSACTION_TYPE } from 'constants/banks';
import useQueryParams from 'hooks/useQueryParams';
import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { exportFile } from 'helpers/utils';
import {
  EXPORT_BOOK_TYPE,
  EXPORT_FILE_CONTENT_TYPE,
  EXPORT_FILE_TYPE,
} from 'config/Constants';

const types = ['all', ...Object.values(TRANSACTION_TYPE)];
const states = ['all', ...Object.values(TRANSACTION_STATE)];

const BankTransactionTable = ({
  ressources,
  // bankCategories,
  // accountId,
}) => {
  const { documentId, type: typeParams } = useQueryParams();
  const [type, setType] = useState(typeParams || 'all');
  const [state, setState] = useState('all');
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { history } = useRouter();
  const [filterResources, setFilterResources] = useState([]);
  const [categories, setCategories] = useState([]);

  const categoriesNames = uniqBy(ressources, (item) => item.category._id).map(
    (item) => item?.category?.name
  );

  const customSort = (val1, val2, order) => {
    if (val1 === null) return 1;
    if (val2 === null) return -1;
    return (val1 - val2) * (order === 'asc' ? 1 : -1);
  };

  const handleSelectAllCategories = () => {
    if (categories.length === categoriesNames.length) {
      setCategories([]);
    } else {
      setCategories([...categoriesNames]);
    }
  };

  const columns = [
    {
      label: t('invoices.form_date'),
      name: 'date',
      options: {
        customBodyRender: (value) => format(new Date(value), 'dd/MM/yyyy'),
        filter: false,
        sortDirection: 'desc',
      },
    },

    {
      label: t('expenses.expense.category'),
      name: 'category',
      options: {
        sortDirection: 'none',
        filterList: [],
      },
    },
    {
      label: t('Description'),
      name: 'description',
      options: {
        customBodyRender: (value) => {
          const [bankDescription, cleanDescription] = value.split('%%%');

          return (
            <div>
              {bankDescription}
              <br />
              <i>{cleanDescription}</i>
            </div>
          );
        },

        sortDirection: 'none',
      },
    },
    {
      label: t('debit'),
      name: 'debit',
      options: {
        filter: false,
        customBodyRender: (value) => (value ? `${value?.toFixed(2)} €` : ''),
        sortCompare: (order) => {
          return (obj1, obj2) => customSort(obj1.data, obj2.data, order);
        },
      },
    },
    {
      label: t('credit'),
      name: 'credit',
      options: {
        filter: false,
        customBodyRender: (value) => (value ? `${value?.toFixed(2)} €` : ''),
        sortCompare: (order) => {
          return (obj1, obj2) => customSort(obj1.data, obj2.data, order);
        },
      },
    },
    {
      label: t('transaction.to_reconcile'),
      name: 'reconcile',
      options: {
        customBodyRender: ({ toReconcile }) => `${toReconcile.toFixed(2)}€`,
        sortCompare: (order) => {
          return (obj1, obj2) =>
            customSort(obj1.data?.toReconcile, obj2.data?.toReconcile, order);
        },
      },
    },
    {
      label: t('transaction.reconcile'),
      name: 'reconcile',
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value) => {
          if (value.toReconcile > 0)
            return (
              <IconEdit
                sm
                onClick={() => {
                  history.push({
                    pathname: `/transaction/bank/${value.id}`,
                    state: { documentId },
                  });
                }}
              />
            );
          return null;
        },
        sort: false,
      },
    },
  ];

  const [cols, setCols] = useState(columns);

  const handleFilterState = (currentState) => {
    setState(currentState);
  };

  const handleFilterCategory = () => {
    const filteredCols = [...cols];
    const categoryColIndex = filteredCols.findIndex(
      (item) => item.name === 'category'
    );
    filteredCols[categoryColIndex].options.filterList = categories;
    setCols(filteredCols);
  };

  const transactions = map(filterResources, (el) => {
    return {
      ...el,
      description: `${el.bank_description} %%% ${el.clean_description}`,
      date: el.date,
      category: el.category?.name || '',
      amount: `${el.amount.toFixed(2)} €`,
      reconcile: {
        id: el._id,
        amount: `${(el.amount - (el?.amountUsed || 0)).toFixed(2)} €`,
        toReconcile: Math.abs((el.amount - (el?.amountUsed || 0)).toFixed(2)),
      },
      credit: el.amount > 0 ? el.amount : null,
      debit: el.amount < 0 ? el.amount : null,
    };
  });

  const exportData = () => {
    return transactions.map((item) => {
      return {
        [t('invoices.form_date')]: format(new Date(item.date), 'dd/MM/yyyy'),
        [t('expenses.expense.category')]: item.category,
        [t('Description')]: item.description,
        [t('debit')]: item?.debit ? `${item?.debit?.toFixed(2)} €` : '',
        [t('credit')]: item?.credit ? `${item?.credit?.toFixed(2)} €` : '',
        [t('transaction.to_reconcile')]: item?.reconcile?.toReconcile
          ? `${item?.reconcile?.toReconcile?.toFixed(2)} €`
          : '',
      };
    });
  };

  const handleDownloadCSV = () => {
    const json = exportData();
    exportFile(
      json,
      'BankAccounts',
      EXPORT_FILE_CONTENT_TYPE,
      EXPORT_FILE_TYPE,
      EXPORT_BOOK_TYPE
    );
    return false;
  };

  const renderToolbar = () => {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={3}
        style={{
          paddingBottom: '10px',
        }}
      >
        <Grid item>
          <FormControl style={{ width: 200, marginRight: 10 }}>
            <InputLabel>{t('type')}</InputLabel>
            <Select value={type} onChange={(e) => setType(e.target.value)}>
              {types.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {t(item)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: 200, marginRight: 10 }}>
            <InputLabel>{t('expenses.expense.category')}</InputLabel>
            <Select
              value={categories}
              onChange={(e) => {
                const { value } = e.target;
                if (!value.includes('all')) {
                  setCategories(e.target.value);
                }
              }}
              multiple
              renderValue={(selected) => selected.join(', ')}
              onClose={handleFilterCategory}
            >
              <MenuItem value="all" onClick={handleSelectAllCategories}>
                <Checkbox
                  checked={categories.length === categoriesNames.length}
                  indeterminate={
                    categories.length > 0 &&
                    categories.length < categoriesNames.length
                  }
                />
                <ListItemText primary={t('transaction.categories.all')} />
              </MenuItem>
              {categoriesNames.map((name, index) => (
                <MenuItem key={index} value={name}>
                  <Checkbox checked={categories.includes(name)} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: 200, marginRight: 10 }}>
            <InputLabel>{t('transaction.state.label')}</InputLabel>
            <Select
              value={state}
              onChange={(e) => handleFilterState(e.target.value)}
            >
              {states.map((item, index) => (
                <MenuItem key={index} value={item}>
                  {t(`transaction.state.${item}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    let filteredResources = ressources;

    if (type && type !== 'all') {
      const isDebit = type === TRANSACTION_TYPE.DEBIT;
      filteredResources = filteredResources.filter((item) =>
        isDebit ? item.amount < 0 : item.amount > 0
      );
    }

    if (state && state !== 'all') {
      filteredResources = filteredResources.filter((item) => {
        const difference = Math.abs(
          (item.amount - (item?.amountUsed || 0)).toFixed(2)
        );
        if (state === TRANSACTION_STATE.RECONCILED) return difference <= 0;
        if (state === TRANSACTION_STATE.NOT_RECONCILED) return difference > 0;
        return true;
      });
    }

    setFilterResources(filteredResources);
  }, [ressources, type, state, categories]);

  return (
    <>
      {renderToolbar()}
      <MUIDataTable
        data={transactions}
        columns={cols}
        options={getMuiDatatableOptions(t, transactions.length, {
          download: true,
          searchAlwaysOpen: true,
          filter: false,
          onDownload: () => handleDownloadCSV(),
        })}
        components={{
          TableFilterList: () => <div></div>,
        }}
      />
    </>
  );
};

BankTransactionTable.propTypes = {
  ressources: PropTypes.object,
  companyId: PropTypes.string,
  bankCategories: PropTypes.object,
  accountId: PropTypes.string,
};

export default BankTransactionTable;
