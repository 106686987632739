import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../../hooks/routerHooks';

import Header from '../../../components/Header';
import Layout from '../../../components/Layout';
import SelectCardRouter from '../CardRouter/SelectCardsRouter';

const CardContainer = ({ ...props }) => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const renderBody = () => {
    return <div>{<SelectCardRouter history={history} {...props} />}</div>;
  };
  return (
    <Layout
      header={
        <Header
          name={t('Type de carte')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

CardContainer.propTypes = {
  props: PropTypes.object,
};

export default CardContainer;
