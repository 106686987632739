import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { Box, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ApexChart from 'components/ApexChart';
import { getThemeColor } from 'helpers/utils';

/* Import services */
import expenseServices from 'services/ExpenseService';
import CircularProgressCentered from 'components/CircularProgressCentered';
import NumberFormat from 'helpers/NumberFormat';
import { useRouter } from 'hooks/routerHooks';

const useStyles = makeStyles(() => ({}));

const ActivityDebtsChart = (props) => {
  const [topActivities, setTopActivities] = useState([]);
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const theme = useTheme();
  const { history } = useRouter();
  const { chartDataType } = props;
  const activities = useSelector((state) => state.activities);
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();

  const prepareDataChart = () => {
    let dataInput;
    const usedActivityIds = topActivities.map((elem) => elem._id);

    // Save all activities ( both unused/used activities)
    const dataChart = Object.values(activities).map((elem) => {
      if (usedActivityIds.includes(elem._id)) {
        const foundActivities = topActivities.find(
          (item) => item._id === elem._id
        );

        return {
          ...foundActivities,
          activity: foundActivities.activity,
        };
      }
      return { ...elem, taxBasisTotalAmount: 0 };
    });

    if (chartDataType === 'top10') {
      // filter top 10 activities
      dataInput = [...dataChart]
        .slice(0, 10)
        .sort((a, b) => b.taxBasisTotalAmount - a.taxBasisTotalAmount);
    } else {
      dataInput = [...dataChart].sort(
        (a, b) => a.taxBasisTotalAmount - b.taxBasisTotalAmount
      );
    }
    setSeries([
      {
        data: dataInput.map((elem) => elem.taxBasisTotalAmount) || [],
      },
    ]);
    setLabels([...dataInput].map((elem) => elem.display || elem.activity));
  };

  const fetchTopActivities = async () => {
    try {
      setLoading(true);
      const { data: response } = await expenseServices.fetchAllPurchases();
      const listActivities = response.docs
        .filter(
          (elem) =>
            elem.internal_activity &&
            Object.keys(activities).includes(elem.internal_activity)
        )
        .map((elem) => {
          return {
            _id: elem.internal_activity,
            activity: activities[elem.internal_activity].display,
            taxBasisTotalAmount: elem.taxBasisTotalAmount,
          };
        });

      const helper = {};
      const reduceListActivities = listActivities.reduce((r, o) => {
        const key = o.activity;

        if (!helper[key]) {
          helper[key] = { ...o }; // create a copy of o
          r.push(helper[key]);
        } else {
          helper[key].taxBasisTotalAmount += o.taxBasisTotalAmount;
        }

        return r;
      }, []);

      const top10Activities = reduceListActivities.sort(
        (a, b) => b.taxBasisTotalAmount - a.taxBasisTotalAmount
      );

      // .slice(0, 10);

      setTopActivities(top10Activities);
    } catch (_error) {
      setError(_error);
    } finally {
      setLoading(false);
    }
  };

  const getElementAtEvent = (label) => {
    const [selectedActivity] = topActivities.filter(
      (elem) => elem.activity === label
    );

    const expenseFilters = {
      activity: [selectedActivity._id],
    };

    history.push(
      queryString.stringifyUrl(
        {
          url: 'purchases/list',
          query: expenseFilters,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  const chartData = {
    series,
    options: {
      chart: {
        type: 'bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection(event, chartContext, opts) {
            const {
              dataPointIndex,
              w: {
                globals: { labels: categoryLabels },
              },
            } = opts;
            getElementAtEvent(categoryLabels[dataPointIndex]);
          },
        },
      },
      grid: {
        show: false,
      },
      colors: [getThemeColor(theme.palette.primary[600])],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        style: {
          colors: [getThemeColor(theme.palette.neutral[900])],
          fontWeight: 600,
        },
        textAnchor: 'start',
        offsetX: 99999,
        formatter(val) {
          return `${NumberFormat.n(val, 0)} €`;
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      labels,
      xaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
          formatter(val) {
            return `${NumberFormat.n(val, 0)}€`;
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: getThemeColor(theme.palette.neutral[700]),
            fontWeight: 400,
            fontSize: '0.875rem',
          },
        },
      },
    },
  };

  useEffect(() => {
    fetchTopActivities();
  }, []);

  useEffect(() => {
    if (topActivities?.length) {
      prepareDataChart();
    }
  }, [topActivities?.length, chartDataType]);

  if (loading) {
    return <CircularProgressCentered />;
  }

  if (!labels?.length && series) {
    return null;
  }

  return (
    <Box style={{ width: '100%' }}>
      {error && <p>{error}</p>}
      <ApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={300}
      />
    </Box>
  );
};

ActivityDebtsChart.propTypes = {
  chartDataType: PropTypes.string.isRequired,
};

export default ActivityDebtsChart;
