import { FRANCE_COUNTRY_CODE } from 'config/Constants';
import { EU_COUNTRY_CODES } from 'config/provider';

const ALL_COUNTRIES = require('../assets/data/countryList.json');
const DOMTOM = require('../assets/data/DomTom.json');

export const isInsideUE = (country) => {
  const countryFound = ALL_COUNTRIES.filter((c) => c.alpha2 === country)[0];
  if (countryFound && countryFound.UE === 'True') {
    return true;
  }
  return false;
};

export const isDomTom = (country) => {
  const countryFound = ALL_COUNTRIES.filter((c) => c.alpha2 === country)[0];
  if (countryFound && DOMTOM.includes(countryFound.alpha2)) {
    return true;
  }
  return false;
};

export const isEuCountry = (country) => {
  return EU_COUNTRY_CODES.includes(country);
};

export const isNonCountry = (country) => {
  return country !== FRANCE_COUNTRY_CODE && !EU_COUNTRY_CODES.includes(country);
};
