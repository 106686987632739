/* THIS FILE SHOULD MOVE TO SHARED LIB */
import * as Yup from 'yup';

const fn = () => {};

const validationSchema = (t = fn, showOTPPart, showIban, showBic) =>
  Yup.object().shape({
    isOtpStep: Yup.boolean(),
    otp: showOTPPart
      ? Yup.string().required(t('forms.mandatory_field'))
      : Yup.string().nullable(),
    iban: showIban
      ? Yup.string().required(t('forms.mandatory_field'))
      : Yup.string().nullable(),
    bic: showBic
      ? Yup.string().required(t('forms.mandatory_field'))
      : Yup.string().nullable(),
    beneficiary: Yup.object().required(t('forms.mandatory_field')),
    beneficiaryType: Yup.string().required(t('forms.mandatory_field')),
    reference: Yup.string().required(t('forms.mandatory_field')),
    amount: Yup.number().required(t('forms.mandatory_field')),
    transferDate: Yup.date().required(t('forms.mandatory_field')),
  });

/* eslint-disable import/prefer-default-export */
export { validationSchema };
