import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import style from '../../assets/jss/components/appContainers';

const RowCenterSpaceBetween = ({ children, className, classes, addStyle }) => {
  const containerClasses = classNames({
    [classes.rowCenterSpaceBetween]: true,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
RowCenterSpaceBetween.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
};
RowCenterSpaceBetween.defaultProps = {
  className: null,
  addStyle: null,
};
export const AppRowCenterSpaceBetween = withStyles(style)(
  RowCenterSpaceBetween
);
const RowCenterSpaceBetweenNoMargin = ({
  children,
  className,
  classes,
  addStyle,
  wrap,
}) => {
  const containerClasses = classNames({
    [classes.rowCenterSpaceBetweenNoMargin]: true,
    [className]: !!className,
    [classes.wrap]: wrap,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
RowCenterSpaceBetweenNoMargin.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
  wrap: PropTypes.bool,
};
RowCenterSpaceBetweenNoMargin.defaultProps = {
  className: null,
  addStyle: null,
  wrap: false,
};
export const RowCSB = withStyles(style)(RowCenterSpaceBetweenNoMargin);

const RowCenterSpaceAround = ({
  children,
  className,
  classes,
  addStyle,
  wrap,
}) => {
  const containerClasses = classNames({
    [classes.rowCenterSpaceAround]: true,
    [className]: !!className,
    [classes.wrap]: wrap,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
RowCenterSpaceAround.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
  wrap: PropTypes.bool,
};
RowCenterSpaceAround.defaultProps = {
  className: null,
  addStyle: null,
};
export const AppRowStartSpaceAround = withStyles(style)(RowCenterSpaceAround);

const RowCenterFlexStart = ({
  children,
  className,
  classes,
  addStyle,
  onClick,
}) => {
  const containerClasses = classNames({
    [classes.rowCenterFlexStart]: true,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle} onClick={onClick}>
      {children}
    </div>
  );
};
RowCenterFlexStart.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
  onClick: PropTypes.func,
};
RowCenterFlexStart.defaultProps = {
  className: null,
  addStyle: null,
  onClick: null,
};
export const AppRowCenterStart = withStyles(style)(RowCenterFlexStart);

const RowCenterEnd = ({
  children,
  className,
  classes,
  addStyle,
  heightAuto,
}) => {
  const containerClasses = classNames({
    [classes.rowCenterEnd]: !heightAuto,
    [classes.rowCenterEndHeightAuto]: heightAuto,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
RowCenterEnd.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
  heightAuto: PropTypes.bool,
};
RowCenterEnd.defaultProps = {
  className: null,
  heightAuto: false,
  addStyle: null,
};
export const AppRowCenterEnd = withStyles(style)(RowCenterEnd);

const RowCenterStart = ({
  children,
  className,
  classes,
  addStyle,
  TPcenter,
}) => {
  const containerClasses = classNames({
    [classes.rowCenterStart]: true,
    [classes.TPcenter]: TPcenter,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
RowCenterStart.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
  TPcenter: PropTypes.bool,
};
RowCenterStart.defaultProps = {
  className: null,
  addStyle: null,
};
export const AppRowCenterStartNoMargin = withStyles(style)(RowCenterStart);

const RowCenterCenter = ({ children, className, classes, addStyle }) => {
  const containerClasses = classNames({
    [classes.rowCenterCenter]: true,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
RowCenterCenter.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
};
RowCenterCenter.defaultProps = {
  className: null,
  addStyle: null,
};
export const AppRowCenterCenter = withStyles(style)(RowCenterCenter);

const RowStartStart = ({ children, className, classes, addStyle }) => {
  const containerClasses = classNames({
    [classes.rowStartStart]: true,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
RowStartStart.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
};
RowStartStart.defaultProps = {
  className: null,
  addStyle: null,
};
export const AppRowStartStart = withStyles(style)(RowStartStart);

const ColumnCenterSpaceBetween = ({
  children,
  className,
  classes,
  addStyle,
}) => {
  const containerClasses = classNames({
    [classes.columnCenterSpaceBetween]: true,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
ColumnCenterSpaceBetween.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
};
ColumnCenterSpaceBetween.defaultProps = {
  className: null,
  addStyle: null,
};
export const AppColumnCenterSpaceBetween = withStyles(style)(
  ColumnCenterSpaceBetween
);

const ColumnStartBetween = ({ children, className, classes, addStyle }) => {
  const containerClasses = classNames({
    [classes.columnStartBetween]: true,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
ColumnStartBetween.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
};
ColumnStartBetween.defaultProps = {
  className: null,
  addStyle: null,
};
export const AppColumnStartBetween = withStyles(style)(ColumnStartBetween);
const ColumnStartCenter = ({
  children,
  className,
  classes,
  addStyle,
  TPnone,
}) => {
  const containerClasses = classNames({
    [classes.columnStartCenter]: true,
    [classes.TPnone]: TPnone,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
ColumnStartCenter.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
  TPnone: PropTypes.bool,
};
ColumnStartCenter.defaultProps = {
  className: null,
  addStyle: null,
};
export const AppColumnStartCenter = withStyles(style)(ColumnStartCenter);

const ColumnStartStart = ({ children, className, classes, addStyle }) => {
  const containerClasses = classNames({
    [classes.columnStartStart]: true,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
ColumnStartStart.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
};
ColumnStartStart.defaultProps = {
  className: null,
  addStyle: null,
};
export const AppColumnStartStart = withStyles(style)(ColumnStartStart);

const WrapContainer = ({ children, className, classes, addStyle }) => {
  const containerClasses = classNames({
    [classes.wrapContainer]: true,
    [className]: !!className,
  });
  return (
    <div className={containerClasses} style={addStyle}>
      {children}
    </div>
  );
};
WrapContainer.propTypes = {
  children: PropTypes.node.isRequired,
  classes: PropTypes.object.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  addStyle: PropTypes.object,
};
WrapContainer.defaultProps = {
  className: null,
  addStyle: null,
};
export const AppWrapContainer = withStyles(style)(WrapContainer);
