import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
// components
import { useThunkDispatch } from '../../../../../hooks/generalHooks';

import { fetchCompanyLogo } from '../../../../../actions/CompanyActions';

import colors from '../../../../../constants/colors.json';

import defaultCompanyLogo from '../../../../../assets/images/image-placeholder.png';

const useStyles = makeStyles(() => ({
  imageCompanyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 'auto',
    // width: '270px',
    margin: '0 auto',
  },
  mGreyText: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '13px',
  },
  divider: {
    height: '1px',
    backgroundColor: colors.textDarkGrey,
    width: '95%',
    margin: '5px auto',
  },
  captionBigSelectedBold: {
    color: colors.textDarkGrey,
    fontFamily: 'Kumbh Sans',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
  },
  imageStyle: {
    maxWidth: '240px',
    height: '140px',
    width: 'auto',
  },
}));

const CompanyInfo = () => {
  const classes = useStyles();
  const dispatch = useThunkDispatch();
  const [companyLogo, setCompanyLogo] = useState(defaultCompanyLogo);
  const [addressLines, setAddreddLines] = useState([]);
  const [endlines, setEndlines] = useState('');

  // redux state
  const company = useSelector((state) => state.loggedUserCompany.company);

  function onCompanyLogoFetched(err, { url = null } = {}) {
    setCompanyLogo(url);
  }

  const fetchCompanyLogoCb = useCallback(
    () => dispatch(fetchCompanyLogo(company.filename, onCompanyLogoFetched)),
    [dispatch, company.filename]
  );

  useEffect(() => {
    if (company.filename) {
      fetchCompanyLogoCb();
    }
  }, [fetchCompanyLogoCb]);

  useEffect(() => {
    const companyAddress = company.address;
    setAddreddLines(companyAddress ? companyAddress.split('\n') : []);

    if (company.zipcode) {
      setEndlines((prevValue) => `${prevValue} ${company.zipcode}`);
    }
    if (company.city) {
      setEndlines((prevValue) => `${prevValue} ${company.city}`);
    }
  }, []);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs={6}>
        <div className={classes.imageCompanyContainer}>
          <img
            alt="companyLogo"
            className={classes.imageStyle}
            src={companyLogo}
          />
        </div>
      </Grid>
      <Grid item xs={6}>
        <p className={classes.captionBigSelectedBold}>{company.name}</p>
        {addressLines.map(
          (line, index) =>
            line !== 'undefined' && (
              <p key={index} className={classes.mGreyText}>
                {line}
              </p>
            )
        )}
        <p className={classes.mGreyText}>{`${endlines}`}</p>
      </Grid>
      <div className={classes.divider}></div>
    </Grid>
  );
};

export default CompanyInfo;
