import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import SvgIcon from 'components/AppIcons/SvgIcon';
import Fonts from 'config/fontFamily';
import AngleUp from '../../../assets/icon/angle-up.svg';
import AngleDown from '../../../assets/icon/angle-down.svg';

const useStyles = makeStyles((theme) => ({
  root: ({ onToggle }) => ({
    display: 'flex',
    padding: `${theme.spacing(1)}px ${theme.spacing(3)}px`,
    justifyContent: 'space-between',
    backgroundColor: 'white',
    borderRadius: theme.rounded.xl,
    width: '100%',
    cursor: onToggle ? 'pointer' : 'inherit',
  }),
  contentLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1.5),
  },
  title: {
    fontSize: '2rem',
    color: theme.palette.neutral[800],
    fontWeight: 400,
    lineHeight: '3rem',
    letterSpacing: '0.06rem',
    fontFamily: Fonts.secondary,
  },
  buttonCollapse: {
    border: `1px solid ${theme.palette.neutral[500]}`,
    padding: theme.spacing(0.5),
    width: '40px',
    height: '40px',
  },
}));

const AppDashboardCollapse = (props) => {
  const classes = useStyles(props);
  const { isOpen, icon, title, onToggle } = props;

  return (
    <Box className={classes.root} onClick={() => onToggle(isOpen)}>
      <Box className={classes.contentLeft}>
        {icon} <span className={classes.title}>{title}</span>
      </Box>

      <IconButton
        className={classes.buttonCollapse}
        onClick={() => onToggle(isOpen)}
      >
        {isOpen ? <SvgIcon path={AngleUp} /> : <SvgIcon path={AngleDown} />}
      </IconButton>
    </Box>
  );
};

AppDashboardCollapse.propTypes = {
  isOpen: PropTypes.bool,
  icon: PropTypes.node,
  title: PropTypes.string,
  onToggle: PropTypes.func,
};

export default AppDashboardCollapse;
