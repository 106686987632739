import { format } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';
import logger from './logger';

const formatUTCDate = (date, formatDate) => {
  try {
    if (date) {
      return format(utcToZonedTime(date, 'UTC'), formatDate, {
        timeZone: 'UTC',
      });
    }
    return '-';
  } catch (error) {
    logger.log({ error });
    return '-';
  }
};

export function formatTimestamp(timestamp) {
  const date = new Date(timestamp);

  const day = date.getDate();

  const monthNames = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];
  const month = monthNames[date.getMonth()];

  const year = date.getFullYear();

  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');

  return `${day} ${month} ${year} ${hours}:${minutes}`;
}

export default formatUTCDate;
