/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  timepickerWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default useStyles;
