import { bindActionCreators } from 'redux';
import {
  createTemplate,
  updateTemplate,
} from '../../../actions/TemplateActions';
import { fetchCompanyLogo } from '../../../actions/CompanyActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createTemplate,
      updateTemplate,
      fetchCompanyLogo,
    },
    dispatch
  );
