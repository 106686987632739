// TREEZOR
export const UPDATE_TREEZOR_COMPANY = 'UPDATE_TREEZOR_COMPANY';
export const UPDATE_TREEZOR_USER = 'UPDATE_TREEZOR_USER';
export const TREEZOR_CGU_FORM = 'TREEZOR_CGU_FORM';
export const CGU_FORM = 'CGU_FORM';
export const SET_SHOW_ADD_COLLABORATEUR = 'SET_SHOW_ADD_COLLABORATEUR';
export const SET_ROLE = 'SET_ROLE';
export const SET_STEP = 'SET_STEP';
export const IS_PERSONNE_MORALE = 'IS_PERSONNE_MORALE';
export const IS_NEW_USER = 'IS_NEW_USER';
export const CREATE_NEW_USER = 'CREATE_NEW_USER';
