import logger from './logger';

const localStorageHelper = {
  setItem(key, value) {
    let setValue = value;
    if (typeof value === 'object') {
      setValue = JSON.stringify(value);
    }
    localStorage.setItem(key, setValue);
  },

  getItem(key) {
    let value = localStorage.getItem(key);
    try {
      value = JSON.parse(value);
    } catch (e) {
      logger.error(`Failed to get item`, e?.message);
    }
    return value;
  },

  removeItem(key) {
    localStorage.removeItem(key);
  },

  clear() {
    localStorage.clear();
  },

  hasItem(key) {
    return localStorage.getItem(key) !== null;
  },
};

export default localStorageHelper;
