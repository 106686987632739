import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// material-ui/core components
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import DeleteIcon from '@material-ui/icons/Delete';

const DeleteIconComp = (props) => {
  const { onClick, className, t } = props;

  return (
    <Tooltip title={t('forms.delete')} placement="top">
      <DeleteIcon
        className={className}
        onClick={onClick}
        style={{
          width: '0.7em ',
          height: '0.7em',
        }}
      />
    </Tooltip>
  );
};

DeleteIconComp.propTypes = {
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string,
  t: PropTypes.func,
};

const DeleteIconWithStylesTranslated = withTranslation()(DeleteIconComp);

export default DeleteIconWithStylesTranslated;
