/* Package */
import DateFnsUtils from '@date-io/date-fns';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { fetchWalletTransactionsForPDFExporting } from 'actions/WalletActions';
import { format, sub } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useState } from 'react';

/* Styles */
import useStyles from './styles';

function BankBBFAccountPDFGenerate(props) {
  const { isPDFGenerateModalOpen, handlePDFGenerateModalClose, treezorWallet } =
    props;

  const classnames = useStyles();

  const [selectedEndDate, setSelectedEndDate] = useState(new Date());
  const [selectedStartDate, setSelectedStartDate] = useState(
    sub(new Date(), { months: 1 })
  );

  const exportTransactionsToPDF = () => {
    const { walletId } = treezorWallet;

    return (
      fetchWalletTransactionsForPDFExporting(
        walletId,
        format(selectedStartDate, 'yyyy-MM-dd'),
        format(selectedEndDate, 'yyyy-MM-dd')
      )
        .then((res) => {
          const { pdfFileContentBase64, pdfFileName } = res;

          if ((pdfFileContentBase64, pdfFileName)) {
            const linkSource = `data:application/pdf;base64,${pdfFileContentBase64}`;
            const downloadLink = document.createElement('a');

            downloadLink.href = linkSource;
            downloadLink.download = pdfFileName;
            downloadLink.click();
          }

          // if (message) {
          //   setSnackbarMessage(message);
          //   setSnackbarOpen(true);
          // }
        })
        // .catch((err) => {
        //   const { message } = err;
        //   if (message) {
        //     setSnackbarMessage(message);
        //     setSnackbarOpen(true);
        //   }
        // })
        .finally(() => {
          handlePDFGenerateModalClose();
        })
    );
  };

  return (
    <Dialog
      open={isPDFGenerateModalOpen}
      onClose={handlePDFGenerateModalClose}
      aria-labelledby="form-dialog-title"
      fullWidth={false}
      maxWidth="sm"
    >
      <DialogTitle id="form-dialog-title" color="primary">
        Generate your statement
      </DialogTitle>
      <Divider variant="middle" style={{ border: '1px solid #3d319d' }} />

      <DialogContent>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container className={classnames.timepickerWrapper}>
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Start date"
              format="dd/MM/yyyy"
              value={selectedStartDate}
              onChange={setSelectedStartDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              maxDate={new Date()}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="End date"
              format="dd/MM/yyyy"
              value={selectedEndDate}
              onChange={setSelectedEndDate}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              maxDate={new Date()}
            />
          </Grid>
        </MuiPickersUtilsProvider>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={exportTransactionsToPDF}
        >
          Generate
        </Button>
        <Button onClick={handlePDFGenerateModalClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}

BankBBFAccountPDFGenerate.propTypes = {
  isPDFGenerateModalOpen: PropTypes.bool,
  handlePDFGenerateModalClose: PropTypes.func,
  treezorWallet: PropTypes.object,
};

export default BankBBFAccountPDFGenerate;
