import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Field, useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import { Warning } from '@material-ui/icons';
import {
  TableCell,
  TableRow,
  withStyles,
  TableHead,
  Table,
  TableBody,
} from '@material-ui/core';
import { userPreparePrepayment } from 'hooks/invoiceHooks';
import { roundedNumber } from 'helpers/utils';

import AppInput from '../../../../../../../components/AppInput';
import CustomGenericDialog from '../../../../../../../components/CustomGenericDialog';
import AppButton from '../../../../../../../components/AppButton';
import DatePicker from '../../../../../../../components/FormElementsDesigned/DatePicker';

import colors from '../../../../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  rowCenterSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mBlueText: {
    color: colors.light_blue,
    fontWeight: 'normal',
    fontSize: '15px',
  },
  mRedInput: {
    border: '1px solid #FF2B44',
    color: colors.black,
    lineHeight: '13px',
    fontSize: '11px',
    height: '23px',
    width: '132px',
    textAlign: 'right',
    padding: '1px 10px',
    borderRadius: '3px',
    margin: '1px 15px 0px 0px',
  },
  mGreyInput: {
    border: '1px solid #b2b2b2',
    color: colors.black,
    lineHeight: '13px',
    fontSize: '11px',
    height: '23px',
    width: '132px',
    textAlign: 'right',
    padding: '1px 10px',
    margin: '1px 15px 0px 0px',
    borderRadius: '3px',
  },
  mRedText: {
    color: colors.red,
    lineHeight: '13px',
    fontSize: '11px',
  },
  mBoldBlueText: {
    color: 'hsla(0,0%, 13%, 1)',
    fontWeight: 'bold',
    fontSize: '20px',
    margin: '0px',
  },
  mGreyText: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '13px',
  },
  dueDateContent: {
    marginTop: '0px !important',

    '&:focus-within': {
      border: 'none !important',
    },

    '& fieldset': {
      border: '1px solid #7F7F7F',
    },
  },
}));

export const StyledTableCell = withStyles({
  root: {
    backgroundColor: colors.backgroundGrey,
    border: 0,
    color: colors.white,
    height: 30,
    padding: '0 3px',
    fontWeight: 'normal',
    fontSize: '11px',
    textAlign: 'center',
    '& > div': {
      height: '23px',
      padding: '1px 10px',
      marginTop: '1px',
      '&:focus-within': {
        border: '1px solid #7F7F7F',
      },
      caretColor: '#7F7F7F',
    },

    '& input': {
      padding: '1px 10px',
      height: '23px',
      caretColor: '#7F7F7F',

      '&:focus': {
        // border: '1px solid #7F7F7F',
      },
    },
  },

  body: {
    background: colors.white,
    color: 'black',
    padding: '2px 3px',
  },
})(TableCell);

export const StyledTableHead = withStyles({
  root: {
    height: '30px',
    backgroundColor: colors.backgroundGrey,
  },
})(TableHead);

export const StyledTableRow = withStyles({
  root: {
    height: '34px',
    borderBottom: '1px solid grey',
  },
})(TableRow);

const MilestonesDialog = ({
  methods,
  grossTotal,
  paymentsConfirm,
  paymentsCancel,
  payments,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    values: { vat_details: vatDetails },
  } = useFormikContext();

  const {
    numberOfPrePayments,
    setNumberOfPrePayments,
    newPrePayments,
    amountError,
    descriptionError,
    changeDescription,
    changeAmount,
    changeDueDate,
    changeTaxRate,
    changeTaxAmount,
    changeNetAmount,
    prePaymentsList,
  } = methods;

  const {
    vatPercentPrePayment,
    remainingAmount,
    calculateVatAmountItem,
    calculateNetAmountItem,
  } = userPreparePrepayment(vatDetails, grossTotal, newPrePayments);

  function schedulePayments(e) {
    const { value } = e.target;
    setNumberOfPrePayments(value);
  }

  function changeAmountManualy(index) {
    return (e) => {
      const { value } = e.target;

      if (value && !Number.isNaN(value)) {
        changeAmount(index, Math.abs(roundedNumber(parseFloat(value))));
      } else {
        changeAmount(index, null);
      }
    };
  }

  function changeDescriptionManualy(index) {
    return (e) => {
      const { value } = e.target;
      changeDescription(index, value);
    };
  }

  function onSubmit() {
    if (!amountError && !descriptionError) {
      paymentsConfirm(newPrePayments.filter(({ amount }) => amount));
    }
  }

  const handleFrossTotalOfPrePayments = () => {
    const sum = newPrePayments.reduce((accumulator, object) => {
      return accumulator + object.amount;
    }, 0);
    return roundedNumber(sum);
  };

  useEffect(() => {
    if (!newPrePayments.length) {
      setNumberOfPrePayments(0);
    }

    if (newPrePayments.length > 0) {
      setNumberOfPrePayments(newPrePayments.length);

      newPrePayments.forEach((payment, index) => {
        const taxAmount = +calculateVatAmountItem(
          roundedNumber(payment.amount)
        );
        if (taxAmount !== payment.tax_amount) {
          changeTaxAmount(index, taxAmount);
        }
        const netAmount = +calculateNetAmountItem(
          roundedNumber(payment.amount)
        );
        if (netAmount !== payment.net_amount) {
          changeNetAmount(index, netAmount);
        }
        const taxRate = +vatPercentPrePayment;
        if (taxRate !== payment.tax_rate) {
          changeTaxRate(index, taxRate);
        }
      });
    }
  }, [newPrePayments]);

  return (
    <CustomGenericDialog
      maxWidth={'md'}
      showButtons={false}
      DialogContentStyle={{ paddingTop: '8px' }}
    >
      <p className={classes.mBoldBlueText}>{t('invoices.set_prepayments')}</p>

      <div className={classes.rowCenterSpaceBetween}>
        <p className={classes.mBlueText}>{t(`invoices.set_nb_prepayments`)} </p>
        <Field
          type="number"
          className={classes.mGreyInput}
          style={{ padding: '3px 20px 3px 20px' }}
          name="prepayments"
          min={0}
          // keyboard={true}
          value={+numberOfPrePayments}
          onChange={schedulePayments}
        />
      </div>

      <Table style={{ minWidth: '260px' }}>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell style={{ width: '5%' }}>
              {t('no')}.
            </StyledTableCell>
            <StyledTableCell style={{ width: '28%' }}>
              {t('template.form_label_description')}
            </StyledTableCell>
            <StyledTableCell style={{ width: '10%' }}>
              {t('item.gross')}
            </StyledTableCell>
            <StyledTableCell style={{ width: '10%' }}>
              {t('item.vat')}
            </StyledTableCell>
            <StyledTableCell style={{ width: '10%' }}>
              {t('item.vat_price')}
            </StyledTableCell>
            <StyledTableCell style={{ width: '15%' }}>
              {t('invoices.base_net')}
            </StyledTableCell>
            <StyledTableCell style={{ width: '26%' }}>
              {t('template.due_date')}
            </StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>

        <TableBody>
          {newPrePayments.map((payment, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell variant="body" style={{ width: '5%' }}>
                {index + 1}
              </StyledTableCell>
              <StyledTableCell variant="body" style={{ width: '28%' }}>
                <AppInput
                  className={
                    descriptionError ? classes.mRedInput : classes.mGreyInput
                  }
                  type="text"
                  name="milestone_descr"
                  value={payment.description}
                  color="secondary"
                  placeholder="description"
                  onChange={changeDescriptionManualy(index)}
                />
              </StyledTableCell>
              <StyledTableCell
                variant="body"
                style={{ width: '10%', textAlign: 'right' }}
              >
                <input
                  className={
                    amountError ? classes.mRedInput : classes.mGreyInput
                  }
                  value={payment.amount}
                  onChange={changeAmountManualy(index)}
                  type="number"
                  min={0}
                />
              </StyledTableCell>
              <StyledTableCell
                variant="body"
                style={{ width: '10%', textAlign: 'right' }}
              >
                <p className={classes.mGreyText}>{payment.tax_rate} %</p>
              </StyledTableCell>
              <StyledTableCell
                variant="body"
                style={{ width: '10%', textAlign: 'right' }}
              >
                <p className={classes.mGreyText}>{payment.tax_amount} €</p>
              </StyledTableCell>
              <StyledTableCell
                variant="body"
                style={{ width: '15%', textAlign: 'right' }}
              >
                <p className={classes.mGreyText}>{payment.net_amount} €</p>
              </StyledTableCell>
              <StyledTableCell variant="body" style={{ width: '26%' }}>
                <Field
                  customClass={classes.dueDateContent}
                  type="date"
                  name="due_date"
                  prePaymentType="pre_payment"
                  prePaymentsList={prePaymentsList}
                  component={DatePicker}
                  duePayments={payments}
                  // keyboard={true}
                  selectedIndex={index}
                  setDueDate={payment.due_date}
                  onChange={(date) => {
                    changeDueDate(index, date);
                  }}
                  InputP
                  fullWidth={false}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
      {amountError ? (
        <div className={classes.rowCenterSpaceBetween}>
          <Warning style={{ marginRight: '10px', color: 'red' }} />
          <p className={classes.mRedText}>
            {`${t('invoices.total_error')}:  ${grossTotal} €`}
          </p>
        </div>
      ) : (
        <div style={{ marginTop: '8px', textAlign: 'right' }}>
          <div>
            <div className={classes.rowCenterSpaceBetween}>
              <p
                style={{ margin: '8px 0', width: '80%' }}
                className={classes.mGreyText}
              >
                {t('invoices.total_gross')}:
              </p>
              <p
                style={{ margin: '8px 0', width: '20%' }}
                className={classes.mGreyText}
              >
                {roundedNumber(grossTotal)} €
              </p>
            </div>
            <div className={classes.rowCenterSpaceBetween}>
              <p
                style={{ margin: '8px 0', width: '80%' }}
                className={classes.mGreyText}
              >
                {t('invoices.gross_total_of_pre_payments')}:
              </p>
              <p
                style={{ margin: '8px 0', width: '20%' }}
                className={classes.mGreyText}
              >
                {handleFrossTotalOfPrePayments()} €
              </p>
            </div>
            <div className={classes.rowCenterSpaceBetween}>
              <p
                style={{ margin: '8px 0', width: '80%' }}
                className={classes.mGreyText}
              >
                {t('invoices.due_payable_amount')}:
              </p>
              <p
                style={{ margin: '8px 0', width: '20%' }}
                className={classes.mGreyText}
              >
                {roundedNumber(remainingAmount)} €
              </p>
            </div>
          </div>
        </div>
      )}

      {(newPrePayments.length > 0 &&
        newPrePayments[0].amount !== 0 &&
        descriptionError && (
          <div className={classes.rowCenterSpaceBetween}>
            <Warning style={{ marginRight: '10px', color: 'red' }} />
            <p className={classes.mRedText}>
              {`${t('invoices.description_error')}`}
            </p>
          </div>
        )) ||
        null}
      <div
        style={{ margin: '30px 0 0 0 ' }}
        className={classes.rowCenterSpaceBetween}
      >
        <AppButton
          color="primaryLight"
          isDisabled={false}
          text={t('cancel')}
          onClick={paymentsCancel}
        />
        <AppButton
          isDisabled={!!(amountError || descriptionError)}
          text="OK"
          onClick={onSubmit}
        />
      </div>
    </CustomGenericDialog>
  );
};

MilestonesDialog.propTypes = {
  t: PropTypes.func.isRequired,
  grossTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  paymentsCancel: PropTypes.func.isRequired,
  paymentsConfirm: PropTypes.func.isRequired,
  methods: PropTypes.object.isRequired,
  payments: PropTypes.object,
};

export default MilestonesDialog;
