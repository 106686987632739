import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { COMPANIES_RENEW } from 'constants/company';
import Layout from 'components/Layout';
import CircularProgressCentered from 'components/CircularProgressCentered';
import logger from 'helpers/logger';
import localStorageHelper from 'helpers/localStorageHelper';
import AppDialog from 'components/AppDialog';
import { withTranslation } from 'react-i18next';
import state from './state';
import dispatch from './dispatch';

const style = {
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  height: '100vh',
  width: '100vw',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  backgroundColor: 'white',
};

const NotificationSuccessfulDialog = ({ closeFormDialog, title, children }) => {
  return (
    <AppDialog
      title={title}
      closeDialog={closeFormDialog}
      onConfirmType="submit"
      onConfirmText="submit"
      iconClose
      color="secondary"
    >
      {children}
    </AppDialog>
  );
};

export class Callback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCheckoutPopup: false,
    };
    this.closeCheckoutDialog = this.closeCheckoutDialog.bind(this);
    this.params = new URLSearchParams(window?.location?.search);
  }

  componentDidMount() {
    const { location, handleAuthentication, history, initializeUser } =
      this.props;
    if (/access_token|id_token|error/.test(location.hash)) {
      handleAuthentication((err) => {
        if (err) {
          logger.warn('Callback - handleAuthentication() failed', err);
          history.push('/');
        } else {
          logger.info(
            'Callback - handleAuthentication() success triggers initializeUser() '
          );
          initializeUser();
        }
      });
    } else {
      this.redirect();
    }
  }

  redirect() {
    const { history } = this.props;

    const redirect = this.params.get('redirect');
    const statusRenewSubscription = this.params.get('renew-subscription');

    if (redirect) {
      const domain = window.location.hostname;
      const expr = `(http|https)://${domain}(:[0-9]+)?`;
      const rx = new RegExp(expr, 'g');
      history.push(redirect.replace(rx, ''));
    } else if (statusRenewSubscription === 'success') {
      this.setState({ showCheckoutPopup: true });
    } else {
      history.push('/');
    }
  }

  closeCheckoutDialog() {
    const { history } = this.props;
    this.setState({ showCheckoutPopup: false });
    history.push('/home');
  }

  renderBody() {
    const { showCheckoutPopup } = this.state;
    const { t } = this.props;
    const companyId = this.params.get('companyId');
    if (showCheckoutPopup && companyId) {
      const companyRenews = localStorageHelper.getItem(COMPANIES_RENEW) || {};
      localStorageHelper.setItem(COMPANIES_RENEW, {
        ...companyRenews,
        [companyId]: true,
      });

      return (
        <NotificationSuccessfulDialog
          closeFormDialog={this.closeCheckoutDialog}
          title={t('subscription.renew_subscription_success.title')}
        >
          <div>{t('subscription.renew_subscription_success.content')}</div>
        </NotificationSuccessfulDialog>
      );
    }

    return (
      <div style={style}>
        <CircularProgressCentered />
      </div>
    );
  }

  componentDidUpdate(prevProps) {
    const {
      loggedUser: { isFetchingUser },
    } = this.props;

    if (prevProps.loggedUser.isFetchingUser && !isFetchingUser) this.redirect();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return (
      <Layout
        header={null}
        sidebarLeft={false}
        sidebarRight={null}
        showUserCard={false}
        body={this.renderBody()}
      />
    );
  }
}

Callback.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
  loggedUser: PropTypes.object,
  handleAuthentication: PropTypes.func,
  initializeUser: PropTypes.func,
  t: PropTypes.func.isRequired,
};

NotificationSuccessfulDialog.propTypes = {
  showDialog: PropTypes.bool,
  closeFormDialog: PropTypes.func,
  title: PropTypes.string,
  children: PropTypes.node,
};

const CallbackTranslated = withTranslation()(Callback);

export default connect(state, dispatch)(CallbackTranslated);
