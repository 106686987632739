import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  dialogImage: {
    maxWidth: '100%',
    objectFit: 'contain',
    padding: '12px',
    borderRadius: '4px',
    backgroundColor: '#e2e2e2',
  },
  uploadImage: {
    maxWidth: '154px',
    maxHeight: '154px',
    cursor: 'pointer',
  },
  previewImg: {
    width: '100%',
    cursor: 'pointer',
  },
}));

const ImagePreview = ({
  isDialog,
  file,
  toggleDialog,
  shape,
  customStyles,
  isPreviewFile,
}) => {
  const classes = useStyles();

  function onImageClick(e) {
    if (toggleDialog) {
      toggleDialog(e);
    }
  }

  const makeImagePreviewStyle = (shapeProp) => {
    const defaultStyles = {
      margin: '0 auto',
      display: 'block',
    };
    if (shapeProp === 'logoImage') {
      return {
        ...defaultStyles,
        border: 'solid 3px #3d319d',
        borderRadius: customStyles?.borderRadius || '50%',
        width: '500px',
        height: customStyles?.height || '500px',
        objectFit: 'cover',
        padding: '5px',
      };
    }

    return defaultStyles;
  };

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <img
          className={
            isDialog || isPreviewFile
              ? classes.dialogImage
              : classes.uploadImage
          }
          src={file}
          alt={isDialog ? 'dialog' : 'alt'}
          style={makeImagePreviewStyle(shape)}
          onClick={onImageClick}
        />
      </Grid>
    </Grid>
  );
};

ImagePreview.propTypes = {
  isDialog: PropTypes.bool,
  file: PropTypes.string,
  toggleDialog: PropTypes.func,
  shape: PropTypes.string,
  customStyles: PropTypes.object,
  isPreviewFile: PropTypes.bool,
};

ImagePreview.defaultProps = {
  isDialog: false,
};

export default ImagePreview;
