import { mapKeys } from 'lodash';

import {
  FETCH_EMAIL_TEMPLATES,
  FETCH_EMAIL_TEMPLATES_SUCCESS,
  FETCH_EMAIL_TEMPLATES_FAILED,
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
} from '../../actions/Types';

export default (
  state = {
    fetchingEmailTemplate: false,
    fetchingEmailTemplateError: false,
    emailTemplates: {},
  },
  action
) => {
  switch (action.type) {
    case FETCH_EMAIL_TEMPLATES:
      return { ...state, fetchingEmailTemplate: true };
    case FETCH_EMAIL_TEMPLATES_SUCCESS:
      return {
        ...state,
        fetchingEmailTemplate: false,
        emailTemplates: mapKeys(action.payload, '_id'),
      };
    case FETCH_EMAIL_TEMPLATES_FAILED:
      return {
        ...state,
        fetchingEmailTemplateError: true,
        fetchingEmailTemplate: false,
      };
    case CREATE_EMAIL_TEMPLATE: {
      const res = {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          [action.payload.id]: action.payload.data,
        },
      };
      delete res.emailTemplates[action.payload.oldId];
      return res;
    }
    case UPDATE_EMAIL_TEMPLATE: {
      return {
        ...state,
        emailTemplates: {
          ...state.emailTemplates,
          [action.payload.id]: action.payload.data,
        },
      };
    }
    default:
      return state;
  }
};
