import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import { useTranslation } from 'react-i18next';
import { useFormikContext, Field } from 'formik';

import ErrorMessage from 'components/ErrorMessage';
import DatePicker from 'components/FormElementsDesigned/DatePicker';
import Textfield from 'components/FormElements/textField';
import UploadFile from 'components/UploadFile/formWrapper';
import Buttons from 'containers/revenue/Form/Buttons';
import SelectAutocomplete from 'components/SelectAutocomplete';
import { find } from 'lodash';
import RevenuesVATLineGroup from 'components/VATLineGroup/revenueVATLineGroup';

const SimpleForm = ({
  readOnly,
  modes,
  errorMessage,
  categories,
  vatRates,
}) => {
  const { values, touched, errors, setFieldValue, setFieldTouched } =
    useFormikContext();
  const [subCategories, setSubcategories] = useState([]);
  const [taxFromCategory, setTaxFromCategory] = useState([]);
  const [vatRatesAllowed, setVatRatesAllowed] = useState(vatRates);
  const { noVAT, payingMode, categoryId, subCategoryId } = values;

  const { loading } = useSelector((state) => state.revenues);
  const { t } = useTranslation();

  function handleDateChange(dateSelected) {
    setFieldValue('dateEmission', dateSelected);
  }

  const onSettlementMethodChange = (e) => {
    setFieldTouched('settlementMethodId', true);
    const { value } = e.target;
    if (!value) {
      return;
    }
    setFieldValue('payingMode', value);
  };

  const getModes = () => {
    if (!values.cashEnabled) {
      // eslint-disable-next-line
      modes = modes.filter((mode) => mode._id !== 'cash');
    }
    return modes;
  };

  const setValueToForm = (valuesForm, isCategory = false) => {
    const properties = ['noVAT', 'cashEnabled', 'turnover'];
    if (isCategory) {
      properties.push('categoryId', 'children', 'subCategoryId');
    } else {
      properties.push('subCategoryId');
    }
    properties.forEach((property) => {
      setFieldValue(property, valuesForm[property]);
    });
  };

  const clearAllCategory = () => {
    setFieldValue('categoryId', null);
    setFieldValue('subCategoryId', null);
  };

  const changeCategory = (clickedCategory) => {
    if (!clickedCategory) {
      clearAllCategory();
    }
    const categorySelected = {
      children: clickedCategory.children,
      cashEnabled: clickedCategory.cashEnabled,
      categoryId: clickedCategory._id,
      subCategoryId: null,
      turnover: clickedCategory.turnover,
      noVAT: !!clickedCategory.noVAT,
    };
    let vatAllowed = vatRates;
    if (categorySelected.categoryId === '5e837410323359931db850d2') {
      vatAllowed = vatRates.filter((vatRateItem) => vatRateItem.value === 20);
    }
    setVatRatesAllowed(vatAllowed);
    setTaxFromCategory(clickedCategory.vatRates);
    setValueToForm(categorySelected, true);
    setSubcategories(categorySelected?.children || []);
  };
  const changeSubCategory = (subCategory) => {
    const categorySelected = {
      cashEnabled: subCategory?.cashEnabled,
      subCategoryId: subCategory?._id,
      turnover: subCategory?.turnover,
      noVAT: !!subCategory?.noVAT,
    };
    setValueToForm(categorySelected);
  };

  return (
    <>
      {loading && (
        <LoadingIndicatorDialog title={t('loading_single')} open={true} />
      )}
      <Grid container spacing={2} alignItems="baseline">
        <Grid item xs={12} sm={12} md={6}>
          <UploadFile
            label={t('revenues.revenue.receipt')}
            withOcr
            isPreviewFile
          />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div>
              {errorMessage && (
                <ErrorMessage showError={true} error={errorMessage} />
              )}
              <SelectAutocomplete
                required
                name="categoryId"
                label={t('revenues.revenue.category')}
                getOptionLabel={(elem) => elem.display}
                /* Translation and sort categories options */
                values={categories.sort((a, b) =>
                  a.display.localeCompare(b.display)
                )}
                onChange={changeCategory}
                valueSelected={find(
                  categories,
                  (category) => category?._id === categoryId
                )}
              />
              <SelectAutocomplete
                required
                name="subCategoryId"
                label={t('revenues.revenue.subCategory')}
                getOptionLabel={(elem) => elem.display}
                /* Translation and sort categories options */
                values={(subCategories || []).sort((a, b) =>
                  a.display.localeCompare(b.display)
                )}
                onChange={changeSubCategory}
                valueSelected={find(
                  subCategories || [],
                  (category) => category._id === subCategoryId
                )}
                readOnly={!subCategories.length}
              />

              <Field
                disabled={readOnly}
                type="date"
                name="dateEmission"
                keyboard={true}
                component={DatePicker}
                onChange={(date) => handleDateChange(date)}
              />

              <FormControl variant="outlined" margin="dense" fullWidth required>
                <InputLabel htmlFor="payingMode">
                  {t('settlement_methods')}
                </InputLabel>
                <Select
                  labelId={t('settlement_methods')}
                  label={t('settlement_methods')}
                  name={'payingMode'}
                  value={payingMode}
                  onChange={onSettlementMethodChange}
                  // autoWidth={true}
                  disabled={readOnly ? true : modes.length === 0}
                >
                  <MenuItem value={''}>{''}</MenuItem>
                  {getModes().map((el, i) => (
                    <MenuItem key={i} value={el._id}>
                      {el.display}
                    </MenuItem>
                  ))}
                </Select>
                {errors.payingMode && touched.payingMode && (
                  <div style={{ color: 'red' }}>{errors.payingMode}</div>
                )}
                {getModes().length === 0 && (
                  <div style={{ color: 'red' }}>
                    {t('revenues.add_bank_acount')}
                  </div>
                )}
              </FormControl>

              <Textfield
                type="text"
                name={'description'}
                label={t('expenses.expense.description')}
              />
              <Textfield
                disabled={readOnly ? true : !noVAT}
                type="number"
                name={'grandTotalAmount'}
                label={t('expenses.expense.form_total_0')}
              />
              <RevenuesVATLineGroup
                readOnly={readOnly}
                noVAT={noVAT}
                vatRates={vatRatesAllowed}
                defaultTaxes={taxFromCategory}
              />
            </div>
            <div>
              <Buttons />
            </div>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

SimpleForm.propTypes = {
  categories: PropTypes.array.required,
  readOnly: PropTypes.bool,
  errorMessage: PropTypes.string,
  modes: PropTypes.array,
  vatRates: PropTypes.array,
};

export default SimpleForm;
