/* eslint-disable no-unused-vars */
import React, { useState, useEffect, Fragment } from 'react';
import Layout from 'components/Layout';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { fetchCompanySubscription } from 'actions/CompanySubscription';
import { fetchCompanySubscriptionHistory } from 'actions/CompanySubscriptionHistory';
import { useSelector } from 'react-redux';
import AppButton from 'components/AppButton';
import { useThunkDispatch } from 'hooks/generalHooks';
import {
  DISCOVERY_PACKAGE,
  STANDARD_FORMULA,
  EFFICIENCY_FORMULA,
  MONTHLY,
  YEARLY,
} from 'constants/subscriptions';
import spinnerService from 'services/SpinnerService';
import { WarningSharp } from '@material-ui/icons';
import { userCanUpdateSubscription } from 'selectors/rightsSelector/subscriptionRequest';
import SubscriptionHeader from '../components/ScupscriptionHeader';
import SubscriptionHistoryList from '../table/HistorySubcription';
import SubscriptionInfo from '../components/SubscriptionInfo';
import PaymentLinks from '../components/PaymentLinks';
import CancelSubscription from '../components/CancelSubscriptionDialog';
import UpgradeSubscriptionDialog from '../components/UpgradeSubsctriptionDialog';

const useStyles = makeStyles({
  container: {
    marginBottom: '40px',
  },
  buttonGroup: {
    display: 'flex',
  },
  buttonContent: {
    marginRight: '8px',
  },
  deactivated: {
    display: 'flex',
    justifyContent: 'center',
  },
});

const Subscription = () => {
  const [openUpgradeDialog, setOpenUpgradeDialog] = useState(false);
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();

  const [isOpenSubscription, setIsOpenSubscription] = useState(false);
  const companySubscription = useSelector(
    (state) => state.companySubscription.data || []
  );
  const subscriptionHistory = useSelector(
    (state) => state.companySubscriptionHistory.data || []
  );
  const canUpdateSubscription = useSelector(userCanUpdateSubscription);

  const checkCancelTime = () => {
    // Cancel time within 14 days from subscription start date
    if (companySubscription.startDate) {
      const startDate = new Date(companySubscription.startDate);
      const now = new Date();
      const subtractDate = Math.floor((now - startDate) / 1000 / 60 / 60 / 24);
      return subtractDate <= 14;
    }
    return true;
  };

  const checkRefund = () => {
    return (
      checkCancelTime() &&
      companySubscription.paymentLinkUsed === 0 &&
      (companySubscription.name === EFFICIENCY_FORMULA ||
        companySubscription.name === STANDARD_FORMULA) &&
      !companySubscription.subscriptionEndAt
    );
  };

  const checkDisableCancel = () => {
    if (companySubscription.name === DISCOVERY_PACKAGE) return false;

    // case cancel subscription or change subscription => disable button "cancel"
    if (
      companySubscription.subscriptionEndAt ||
      companySubscription.isChanged
    ) {
      return true;
    }
    switch (companySubscription.name) {
      // case DISCOVERY_PACKAGE:
      //   return (
      //     companySubscription.paymentLinkUsed > 0 ||
      //     companySubscription.subscriptionEndAt ||
      //     companySubscription.interval !== '-'
      //   );
      case STANDARD_FORMULA:
      case EFFICIENCY_FORMULA:
        switch (companySubscription.interval) {
          case MONTHLY:
          case YEARLY:
            return (
              !companySubscription.paymentLinkUsed >= 0 && !checkCancelTime()
            );
          default:
            return true;
        }
      default:
        return true;
    }
  };

  const fetchCompanySubscriptionData = () => {
    spinnerService.startSpinner();
    dispatch(fetchCompanySubscription()).finally(() =>
      spinnerService.endSpinner()
    );
  };
  const fetchCompanySubscriptionHistoryData = () => {
    spinnerService.startSpinner();
    dispatch(fetchCompanySubscriptionHistory()).finally(() =>
      spinnerService.endSpinner()
    );
  };

  useEffect(() => {
    fetchCompanySubscriptionData();
    fetchCompanySubscriptionHistoryData();

    return () => {
      spinnerService.resetSpinner();
    };
  }, []);

  const renderSubscriptionMessage = () => {
    if (companySubscription.isCanceled) {
      return t('settings_section.subscription.deactivated_account_message');
    }
    if (companySubscription.isChanged) {
      return t('settings_section.subscription.upgrade_account_message');
    }
    return null;
  };

  const renderBody = () => {
    return (
      <Fragment>
        {isOpenSubscription && (
          <CancelSubscription
            canRefund={checkRefund()}
            isOpen={isOpenSubscription}
            onClose={() => setIsOpenSubscription(false)}
          />
        )}
        {companySubscription.subscriptionEndAt && (
          <Grid xs={12} className={classes.deactivated}>
            <WarningSharp color="error" style={{ marginRight: '5px' }} />
            <Typography variant="h6" color={'error'}>
              {renderSubscriptionMessage()}
            </Typography>
          </Grid>
        )}
        <UpgradeSubscriptionDialog
          openDialog={openUpgradeDialog}
          closeDialog={() => setOpenUpgradeDialog(false)}
        />
        <Grid xs={12} className={classes.container}>
          <SubscriptionInfo />
          <Grid
            xs={12}
            item
            direction="row"
            justify="flex-end"
            className={classes.buttonGroup}
          >
            <Grid item className={classes.buttonContent}>
              <AppButton
                text={t('subscription.form.upgrade_subscription')}
                onClick={() => setOpenUpgradeDialog(true)}
                disabled={
                  companySubscription.isCanceled ||
                  companySubscription.isChanged ||
                  !canUpdateSubscription
                }
                noBorder
              />
            </Grid>
            <Grid item className={classes.buttonContent}>
              <AppButton
                disabled={checkDisableCancel() || !canUpdateSubscription}
                color="red"
                text={t('subscription.form.cancel_subscription')}
                onClick={() => setIsOpenSubscription(true)}
                noBorder
              />
            </Grid>
          </Grid>
        </Grid>
        <PaymentLinks subscription={companySubscription} />
        <SubscriptionHistoryList subscriptionHistory={subscriptionHistory} />
      </Fragment>
    );
  };
  return (
    <Layout header={<SubscriptionHeader />} showUserCard body={renderBody()} />
  );
};

export default Subscription;
