import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { makeStyles } from '@material-ui/core/styles';

import {
  TableCell,
  TableRow,
  withStyles,
  TableHead,
  Table,
  TableBody,
} from '@material-ui/core';
import { roundedNumber } from 'helpers/utils';
import { format } from 'date-fns';
import NumberFormat from 'helpers/NumberFormat';
import AppButton from '../../../../../../components/AppButton';
import MilestonesDialog from './MilestonesDialog';

// import NumberFormat from '../../../../../../helpers/NumberFormat';

import { typeHelpers } from '../../../../../../helpers/invoiceHelpers';

import { usePrePayments } from '../../../../../../hooks/invoiceHooks';

import colors from '../../../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  mGreyText: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '13px',
  },
  payment: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    height: '36px',
    borderRadius: '4px',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  paymentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-around',
  },
  dueDatesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  mRedText: {
    color: colors.red,
    lineHeight: '13px',
    fontSize: '11px',
  },
  greyWrapper: {
    borderRadius: '4px',
    width: '100%',
    border: '1px solid #A6A6A6',
    backgroundColor: colors.white,
  },
}));

export const StyledTableCell = withStyles({
  root: {
    backgroundColor: colors.backgroundGrey,
    border: 0,
    color: colors.white,
    height: 30,
    padding: '0 5px',
    fontWeight: 'normal',
    fontSize: '11px',
    textAlign: 'center',
  },
  body: {
    background: colors.white,
    color: 'black',
    padding: '2px 3px',
  },
})(TableCell);

export const StyledTableHead = withStyles({
  root: {
    height: '30px',
    backgroundColor: colors.backgroundGrey,
  },
})(TableHead);

export const StyledTableRow = withStyles({
  root: {
    height: '34px',
    borderBottom: '1px solid grey',
  },
})(TableRow);

const PrePaymentsButton = ({ toggleDialog }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    isSubmitting,
    values: { prepayments },
  } = useFormikContext();

  return (
    <div>
      {prepayments && prepayments.length ? (
        <div>
          <Table style={{ minWidth: '260px' }}>
            <StyledTableHead>
              <StyledTableRow>
                <StyledTableCell style={{ width: 'max-content' }} />

                <StyledTableCell style={{ width: 'max-content' }}>
                  {t('template.form_label_description')}
                </StyledTableCell>

                <StyledTableCell style={{ width: 'max-content' }}>
                  {t('invoices.amount_net')}
                </StyledTableCell>
                <StyledTableCell style={{ width: 'max-content' }}>
                  {t('item.vat')} %
                </StyledTableCell>
                <StyledTableCell style={{ width: 'max-content' }}>
                  {t('invoices.total_with_vat')}
                </StyledTableCell>
                <StyledTableCell style={{ width: 'max-content' }}>
                  {t('invoices.due_date')}
                </StyledTableCell>
              </StyledTableRow>
            </StyledTableHead>

            <TableBody>
              {prepayments.map((payment, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell
                    variant="body"
                    style={{ width: 'max-content' }}
                  >
                    {index === 0 && (
                      <AppButton
                        isDisabled={isSubmitting}
                        color="transparentGrey"
                        text={t('invoices.pre_payments')}
                        onClick={toggleDialog}
                      />
                    )}
                  </StyledTableCell>

                  <StyledTableCell
                    variant="body"
                    style={{ width: 'max-content' }}
                  >
                    <p className={classes.mGreyText}>{payment.description}</p>
                  </StyledTableCell>

                  <StyledTableCell
                    variant="body"
                    style={{ width: 'max-content' }}
                  >
                    <p className={classes.mGreyText}>
                      {NumberFormat.n(roundedNumber(payment.net_amount))} €
                    </p>
                  </StyledTableCell>
                  <StyledTableCell
                    variant="body"
                    style={{ width: 'max-content' }}
                  >
                    <p className={classes.mGreyText}>
                      {roundedNumber(payment.tax_rate)} %
                    </p>
                  </StyledTableCell>
                  <StyledTableCell
                    variant="body"
                    style={{ width: 'max-content' }}
                  >
                    <p className={classes.mGreyText}>
                      {NumberFormat.n(roundedNumber(payment.amount))} €
                    </p>
                  </StyledTableCell>

                  <StyledTableCell
                    variant="body"
                    style={{ width: 'max-content' }}
                  >
                    <p className={classes.mGreyText}>
                      {format(new Date(payment.due_date), 'dd/MM/yyyy')}
                    </p>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div
          style={{
            width: '40px',
          }}
        >
          <div style={{ width: '40px', padding: '4px' }}>
            <AppButton
              isDisabled={isSubmitting}
              color="transparentGrey"
              text={t('invoices.pre_payments')}
              onClick={toggleDialog}
            />
          </div>
        </div>
      )}
    </div>
  );
};

PrePaymentsButton.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
};

const PrePayments = ({ handlePrepaymentDueDates, prePaymentsList }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    values: {
      prepayments,
      gross_total: grossTotal,
      type: invoiceType,
      payments: listPayment,
    },
    setFieldValue,
    errors,
  } = useFormikContext();

  const [showDialog, setShowDialog] = useState(false);

  const {
    numberOfPrePayments,
    setNumberOfPrePayments,
    newPrePayments,
    amountError,
    descriptionError,
    changeDescription,
    changeAmount,
    changeDueDate,
    changeTaxRate,
    changeTaxAmount,
    changeNetAmount,
  } = usePrePayments(prepayments, grossTotal);

  useEffect(() => {
    handlePrepaymentDueDates(newPrePayments);
  }, [newPrePayments]);

  function toggleDialog() {
    setShowDialog(!showDialog);
  }

  function onPaymentsConfirm(value) {
    setFieldValue('prepayments', value, false);
    toggleDialog();
  }

  function renderDialog() {
    if (!showDialog) {
      return null;
    }

    return (
      <MilestonesDialog
        paymentsCancel={toggleDialog}
        paymentsConfirm={onPaymentsConfirm}
        grossTotal={grossTotal}
        t={t}
        payments={listPayment}
        methods={{
          numberOfPrePayments,
          setNumberOfPrePayments,
          newPrePayments,
          amountError,
          descriptionError,
          changeDescription,
          changeAmount,
          changeDueDate,
          changeTaxRate,
          changeTaxAmount,
          changeNetAmount,
          prePaymentsList,
        }}
      />
    );
  }

  if (
    !typeHelpers.isQuotation(invoiceType) &&
    !typeHelpers.isOrderForm(invoiceType)
  ) {
    return null;
  }

  return (
    <div className={classes.greyWrapper}>
      {renderDialog()}
      <PrePaymentsButton toggleDialog={toggleDialog} />
      {errors.prepayments && (
        <p className={classes.mRedText}>{errors.prepayments}</p>
      )}
    </div>
  );
};

PrePayments.propTypes = {
  handlePrepaymentDueDates: PropTypes.func,
  prePaymentsList: PropTypes.array,
};

export default PrePayments;
