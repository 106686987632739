import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Hidden, MenuItem, Select, Button } from '@material-ui/core';

import {
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Assessment,
} from '@material-ui/icons';

import { AppDividerS } from 'components/AppDividers';
import languageArray, {
  DEFAULT_LANGUAGE,
} from 'components/AppLanguage/languageArray';
import ChooseCompany from 'components/ChooseCompany';

import { style } from 'assets/jss/style';
import Home from 'assets/icon/tableau_de_bord.svg';
import Invoice from 'assets/icon/facturation.svg';
import Depenses from 'assets/icon/depenses.svg';
import Expense from 'assets/icon/notes_de_frais.svg';
import Card from 'assets/icon/cartes.svg';
import Bank from 'assets/icon/banques.svg';
import Accounting from 'assets/icon/comptabilite.svg';
import Chat from 'assets/icon/agent_ia.svg';
import AttachFile from 'assets/icon/depots.svg';
import Settings from 'assets/icon/parametres.svg';
import ExitToApp from 'assets/icon/deconnexion.svg';

import { SUBSCRIPTION_TYPE } from 'constants/subscriptions';
import { APP_USE_TREEZOR } from 'constants/treezor';
import AppSidebarButton from '../../components/AppSidebarButton';

import CompanyButton from './CompanyButton';
import state from './state';
import dispatch from './dispatch';
import logoSideBarS from '../../assets/images/BBF-logo-collapse.svg';
import logoSideBar from '../../assets/images/BBF-header-logo-white.svg';

const {
  mainSideBar,
  stretchContainer,
  centerContainer,
  collapseButton,
  menuSticky,
} = style;

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      pageSelected: window.location.pathname,
      languageSelected: DEFAULT_LANGUAGE,
    };
    this._onLogout = this._onLogout.bind(this);
    this._toggleDialog = this._toggleDialog.bind(this);
    this._handleClick = this._handleClick.bind(this);
  }

  // eslint-disable-next-line class-methods-use-this
  componentDidMount() {
    const { i18n } = this.props;
    if (i18n.language) {
      this.setState({ languageSelected: i18n.language });
    }
  }

  _handleClick(e, path) {
    const { history } = this.props;
    e.preventDefault();
    history.push(path);
    this.setState({
      pageSelected: window.location.pathname,
    });
  }

  _toggleDialog() {
    if (this.props.loggedUserCompany.companies.length > 1) {
      this.setState({
        openDialog: !this.state.openDialog,
      });
    }
  }

  _onLogout() {
    const { history } = this.props;
    if (this.props.onClose) {
      this.props.onClose();
    }
    history.push('/logout');
  }

  _chooseCompany() {
    return (
      <ChooseCompany
        closeDialog={() => this._toggleDialog()}
        userLoggedIn={true}
      />
    );
  }

  isActiveRoute(path) {
    const { pageSelected } = this.state;

    return pageSelected.split('/')[1] === path.split('/')[1];
  }

  _renderMenu() {
    const {
      t,
      classes,
      userHasCompanySettingsAccess,
      companyDataAreMissing,
      userDataAreMissing,
      userHasExpenseAccess,
      userHasInvoiceAccess,
      userHasBookKeepingAccess,
      userCanReadCard,
      loggedUserCompany: {
        company: { isActivateTreezor },
      },
      companySubscription,
    } = this.props;

    const activitiesTreezor = APP_USE_TREEZOR && isActivateTreezor;
    const isFreeSubscription =
      companySubscription?.data?.subscriptionType ===
      SUBSCRIPTION_TYPE.FREE_TIER;

    return (
      <div className={classes.sideBarButtonsContainer}>
        <AppSidebarButton
          text={t('home')}
          onClick={(e) => this._handleClick(e, '/home')}
          pageDisplayed={
            this.isActiveRoute('/home') ||
            this.isActiveRoute('/overviewExpenses')
          }
          iconSVG={Home}
        />

        {userHasInvoiceAccess && (
          <AppSidebarButton
            text={t('expenses.expense.income')}
            onClick={(e) => this._handleClick(e, '/invoices/list')}
            pageDisplayed={
              this.isActiveRoute('/invoices') || this.isActiveRoute('/revenue')
            }
            // iconName="invoice"
            iconSVG={Invoice}
            disabled={companyDataAreMissing || userDataAreMissing}
          />
        )}

        {userHasExpenseAccess && (
          <AppSidebarButton
            text={t('expenses.expense.other_expenses')}
            onClick={(e) => this._handleClick(e, '/purchases/list')}
            pageDisplayed={this.isActiveRoute('/purchases')}
            // iconName="achat"
            iconSVG={Depenses}
            disabled={companyDataAreMissing || userDataAreMissing}
          />
        )}
        {userHasExpenseAccess && (
          <AppSidebarButton
            text={t('expenses.expense.expenses')}
            onClick={(e) => this._handleClick(e, '/expenses/list')}
            pageDisplayed={this.isActiveRoute('/expenses')}
            // iconName="expenses"
            iconSVG={Expense}
            disabled={companyDataAreMissing || userDataAreMissing}
          />
        )}

        {userCanReadCard && (
          <AppSidebarButton
            text={t('cards')}
            onClick={(e) => this._handleClick(e, '/treezor-onboarding')}
            iconSVG={Card}
            pageDisplayed={
              this.isActiveRoute('/treezor') ||
              this.isActiveRoute('/treezor-onboarding') ||
              this.isActiveRoute('/treezorPending')
            }
            disabled={companyDataAreMissing || userDataAreMissing}
          />
        )}

        {userHasCompanySettingsAccess && !isFreeSubscription && (
          <AppSidebarButton
            text={t('banks')}
            onClick={(e) => this._handleClick(e, '/bank/accounts')}
            pageDisplayed={this.isActiveRoute('/bank')}
            iconSVG={Bank}
            disabled={companyDataAreMissing || userDataAreMissing}
          />
        )}
        {userHasCompanySettingsAccess && activitiesTreezor && (
          <AppSidebarButton
            text={`${t('bank')} BFF`}
            onClick={(e) => this._handleClick(e, '/bank-bbf/accounts')}
            pageDisplayed={this.isActiveRoute('/bank-bbf')}
            iconMUI={Assessment}
            disabled={companyDataAreMissing || userDataAreMissing}
          />
        )}
        {userHasBookKeepingAccess && (
          <AppSidebarButton
            text={t('expenses.expense.book_keeping')}
            onClick={(e) => this._handleClick(e, '/bookKeeping/export')}
            pageDisplayed={this.isActiveRoute('/bookKeeping')}
            // iconName="accounting"
            iconSVG={Accounting}
            disabled={companyDataAreMissing || userDataAreMissing}
          />
        )}
        {userHasBookKeepingAccess && window.debugger === 1 && (
          <AppSidebarButton
            text={t('expenses.expense.book_keeping')}
            onClick={(e) => this._handleClick(e, '/bookKeeping/export/test')}
            pageDisplayed={this.isActiveRoute('/bookKeeping')}
            // iconName="accounting"
            iconMUI={Accounting}
            disabled={companyDataAreMissing || userDataAreMissing}
          />
        )}
        <AppSidebarButton
          text={t('agent.title')}
          onClick={(e) => this._handleClick(e, '/agent/chat')}
          pageDisplayed={this.isActiveRoute('/agent')}
          iconSVG={Chat}
          disabled={companyDataAreMissing || userDataAreMissing}
        />
        {userHasBookKeepingAccess && (
          <AppSidebarButton
            text={t('deposit.title')}
            onClick={(e) => this._handleClick(e, '/deposit')}
            pageDisplayed={this.isActiveRoute('/deposit')}
            // iconName="accounting"
            iconSVG={AttachFile}
            disabled={companyDataAreMissing || userDataAreMissing}
          />
        )}
        {userHasCompanySettingsAccess && (
          <AppSidebarButton
            text={t('settings')}
            onClick={(e) => this._handleClick(e, '/settings')}
            pageDisplayed={this.isActiveRoute('/settings')}
            // iconName="settings"
            iconSVG={Settings}
            disabled={companyDataAreMissing || userDataAreMissing}
          />
        )}

        <AppSidebarButton
          text={t('logout')}
          onClick={() => this._onLogout()}
          iconSVG={ExitToApp}
        />
      </div>
    );
  }

  render() {
    const { loggedUserCompany, classes, changeLanguage } = this.props;

    if (this.props.isAuthenticated() && loggedUserCompany.company) {
      return (
        <div
          style={
            this.props.isCollapse === false
              ? mainSideBar
              : { ...mainSideBar, width: '68px' }
          }
        >
          <div style={centerContainer}>
            <a href="/">
              <picture>
                <source
                  srcSet={this.props.isCollapse ? logoSideBarS : logoSideBar}
                  media="(min-width: 1024px)"
                />
                <img
                  src={logoSideBarS}
                  alt="AAAAlogo"
                  style={{ width: '100%' }}
                />
              </picture>
            </a>
          </div>

          <div style={stretchContainer}>
            {this.state.openDialog && this._chooseCompany()}
            {!this.props.isCollapse && (
              <div className={classes.companyButtonContainer}>
                <div className={classes.companyButtonInnerContainer}>
                  <CompanyButton
                    name={loggedUserCompany.company.name}
                    multiple={this.props.loggedUserCompany.companies.length > 1}
                    toggleDialog={this._toggleDialog}
                  />
                </div>
              </div>
            )}
            <div style={menuSticky}>
              <Button
                style={collapseButton}
                onClick={() => {
                  this.props.resizeScreen(!this.props.isCollapse);
                }}
              >
                {this.props.isCollapse ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
              </Button>
              {this._renderMenu()}
            </div>
          </div>
        </div>
      );
    }

    if (!this.props.isAuthenticated()) {
      return (
        <div style={mainSideBar}>
          <div style={centerContainer}>
            <a href="/">
              <picture>
                <source srcSet={logoSideBar} media="(min-width: 1024px)" />
                <img src={logoSideBarS} alt="logo" style={{ width: '100%' }} />
              </picture>
            </a>
          </div>
          <AppDividerS />
          <div>
            {/* For now we hide the language change on mobile as it's not deisgned for it */}
            <Hidden xsDown>
              <Select
                id="languageSelector"
                style={{
                  backgroundColor: 'white',
                  padding: '4px 4px',
                  borderRadius: '4px',
                }}
                onChange={(event) => {
                  this.setState({ languageSelected: event.target.value });
                  changeLanguage(event.target.value);
                }}
                value={this.state.languageSelected}
              >
                {languageArray.map((element, index) => (
                  <MenuItem value={element.value} key={index}>
                    {element.display}
                  </MenuItem>
                ))}
              </Select>
            </Hidden>
          </div>
        </div>
      );
    }
    return null;
  }
}

SideBar.propTypes = {
  classes: PropTypes.object.isRequired,
  loggedUserCompany: PropTypes.object.isRequired,
  companySubscription: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onOpen: PropTypes.func,
  history: PropTypes.object,
  t: PropTypes.func,
  selectedCompany: PropTypes.func,
  changeLanguage: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.func.isRequired,
  userHasCompanySettingsAccess: PropTypes.bool,
  companyDataAreMissing: PropTypes.bool.isRequired,
  userDataAreMissing: PropTypes.bool.isRequired,
  userHasExpenseAccess: PropTypes.bool,
  userHasInvoiceAccess: PropTypes.bool,
  userHasBookKeepingAccess: PropTypes.bool,
  userCanReadCard: PropTypes.bool,
  isCollapse: PropTypes.bool,
  i18n: PropTypes.object,
  resizeScreen: PropTypes.func,
};

const SideBarWithStyle = withStyles(style)(SideBar);
const TranslatedSideBar = withTranslation()(SideBarWithStyle);
const SideBarWithRouter = withRouter(TranslatedSideBar);
const ConnectedSideBar = connect(state)(SideBarWithRouter);

export default connect(state, dispatch)(ConnectedSideBar);
