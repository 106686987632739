/*
 *
 * DEPRECATED - UNUSED - December 2019
 *
 */

import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Typography,
} from '@material-ui/core';
// components
import Layout from '../../../components/Layout';
import Refresh from '../../../components/Refresh';
import CustomDialog from '../../../components/CustomDialog';
import CustomGenericDialog from '../../../components/CustomGenericDialog';
import Form from '../Form';
// icons
import Delete from '../../../components/TableElements/DeleteIcon';
import Edit from '../../../components/TableElements/EditIcon';
// redux
import state from './state';
import dispatch from './dispatch';

import styles from '../../../assets/jss/root';

export class ClientsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDialog: false,
      selected: null,
      selectedClient: null,
      showDeletedDialog: false,
      idToDelete: null,
      clientId: null,
    };

    this._closeDeleteDialog = this._closeDeleteDialog.bind(this);
    this._deleteClientProject = this._deleteClientProject.bind(this);
  }

  _showDeleteDialog(clientId, idToDelete) {
    return () => {
      this.setState({ showDeletedDialog: true, idToDelete, clientId });
    };
  }

  _closeDeleteDialog() {
    this.setState({
      showDeletedDialog: false,
      idToDelete: null,
      clientId: null,
    });
  }

  _deleteClientProject() {
    this.props.deleteClientProject(
      this.state.clientId,
      this.state.idToDelete,
      this._closeDeleteDialog
    );
  }

  _renderDeletedDialog() {
    const { t } = this.props;

    if (this.state.showDeletedDialog) {
      return (
        <CustomDialog
          title={t('settings_section.projects.delete_project')}
          contentText={t('settings_section.projects.delete_client_content')}
          showButtons
          confirmText={t('ok')}
          onConfirm={this._deleteClientProject}
          cancelText={t('cancel')}
          onCancel={this._closeDeleteDialog}
        />
      );
    }

    return null;
  }

  _editState(showDialog, selected, selectedClient) {
    this.setState({ showDialog, selected, selectedClient });
  }

  _renderFormDialog() {
    const { t } = this.props;
    const { showDialog, selected, selectedClient } = this.state;

    if (showDialog) {
      return (
        <CustomGenericDialog
          title={t('settings_section.projects.create_title')}
        >
          <Form
            id={selected}
            clientId={selectedClient}
            closeDialog={() => this._editState(false)}
          />
        </CustomGenericDialog>
      );
    }

    return null;
  }

  _renderListData() {
    const { classes, clients, clientProjects } = this.props;
    return _.map(clientProjects.projects, (groupedProjects) =>
      _.map(
        groupedProjects,
        (project) =>
          !project.deleted && (
            <TableRow key={project._id} className={classes.highlight}>
              <TableCell> {project.name}</TableCell>
              <TableCell className={classes.hideOnMobile}>
                {project.description}
              </TableCell>

              <TableCell>
                <Link to="/settings/clients">
                  {clients.data[project.client_id].name}
                </Link>
              </TableCell>

              <TableCell className={classes.width25Perct}>
                <Edit
                  className={classes.hoverShow}
                  onClick={() => {
                    this._editState(true, project._id, project.client_id);
                  }}
                />
                <Delete
                  onClick={this._showDeleteDialog(
                    project.client_id,
                    project._id
                  )}
                />
              </TableCell>
            </TableRow>
          )
      )
    );
  }

  renderBody() {
    const { t, classes } = this.props;

    return (
      <>
        {this._renderFormDialog()}
        {this._renderDeletedDialog()}

        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>{t('settings_section.projects.table_name')}</TableCell>
              <TableCell className={classes.hideOnMobile}>
                {t('settings_section.projects.table_description')}
              </TableCell>
              <TableCell className={classes.hideOnMobile}>
                {t('settings_section.projects.client_name')}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>

          <TableBody>{this._renderListData()}</TableBody>
        </Table>
      </>
    );
  }

  renderBodyHeader() {
    const { classes, t } = this.props;

    return (
      <>
        <Typography variant="h5">
          {t('settings_section.projects.title')}
        </Typography>

        <div className={classes.tableHeader}>
          <div className={classes.tableHeaderRow}>
            <div
              style={{
                marginLeft: 'auto',
                marginRight: 20,
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  this._editState(true);
                }}
              >
                {t('create')}
              </Button>

              <Refresh loadData={this.props.fetchClientProjects} />
            </div>
          </div>
        </div>
      </>
    );
  }

  render() {
    return (
      <Layout
        header={this.renderBodyHeader()}
        siderbarLeft={true}
        sidebarRight={null}
        showUserCard={true}
        body={this.renderBody()}
      />
    );
  }
}

ClientsList.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func,
  history: PropTypes.object,
  clients: PropTypes.object,
  clientProjects: PropTypes.object,
  fetchClientProjects: PropTypes.object,
  deleteClientProject: PropTypes.func,
  updateClientProject: PropTypes.func,
};

const ClientsListWithStyle = withStyles(styles)(ClientsList);
const RouterClientsListWithStyle = withRouter(ClientsListWithStyle);
const RouterClientsListWithTranslate = withTranslation()(
  RouterClientsListWithStyle
);

const RouterClientsListWithConnect = connect(
  state,
  dispatch
)(RouterClientsListWithTranslate);

export default RouterClientsListWithConnect;
