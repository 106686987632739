import {
  FETCHING_TREEZOR_BALANCES,
  FETCH_TREEZOR_BALANCES_SUCCESS,
  FETCH_TREEZOR_BALANCES_FAILED,
} from 'actions/Types';
import service from '../../services/TreezorService';

export const gettingTreezorBalances = () => ({
  type: FETCHING_TREEZOR_BALANCES,
});

export const getTreezorBalancesSuccess = (resources) => ({
  type: FETCH_TREEZOR_BALANCES_SUCCESS,
  payload: { resources },
});

export const getTreezorBalancesFailed = (error) => ({
  type: FETCH_TREEZOR_BALANCES_FAILED,
  payload: { error },
});

export const getBalancesTreezor = () => async (dispatch) => {
  dispatch(gettingTreezorBalances());
  try {
    const result = await service.getBalances();
    dispatch(getTreezorBalancesSuccess(result.data));
  } catch (err) {
    dispatch(getTreezorBalancesFailed(err));
  }
};
