import React from 'react';
import PropTypes from 'prop-types';
import { map, sumBy } from 'lodash';
import {
  AppTable,
  AppTableBody,
  AppTableRow,
  AppTableCell,
} from '../../AppTableComponents';
import { AppTextSubTitle } from '../../AppText';

import { getPaymentById } from '../../../helpers/PaymentTypeHelpers';
import NumberFormat from '../../../helpers/NumberFormat';

const ExpenseOverviewTotals = ({
  data: { advanceTotal, paymentTypeTotal, expensesTotal },
  t,
}) => {
  if (!expensesTotal) return <></>;
  const total = sumBy(expensesTotal, 'total');
  let personal = 0;
  if (paymentTypeTotal) {
    const arrPersonal = paymentTypeTotal.filter(
      (value) => value.type === 'personal'
    );
    if (arrPersonal.length === 1) {
      personal = arrPersonal[0].total;
    }
  }
  return (
    <>
      <AppTextSubTitle>{'Totaux'}</AppTextSubTitle>
      <AppTable>
        <AppTableBody>
          {/* loop over Without VAT amounts */}
          {map(expensesTotal, (value, key) => (
            <AppTableRow key={key}>
              <AppTableCell>
                <strong>
                  {t('expenses.overview.total.amounts')} à {key}%
                </strong>
              </AppTableCell>
              <AppTableCell alignRight>
                {NumberFormat.n(value.amount)}€
              </AppTableCell>
            </AppTableRow>
          ))}
          {/* loop over VAT amounts */}
          {expensesTotal.map(({ vatRate, vat }) => (
            <AppTableRow key={vatRate}>
              <AppTableCell>
                <strong>
                  {t('expenses.overview.total.vat')} à {vatRate}%
                </strong>
              </AppTableCell>
              <AppTableCell alignRight>{NumberFormat.n(vat)}€</AppTableCell>
            </AppTableRow>
          ))}

          {/* TOTAL */}
          <AppTableRow key="total">
            <AppTableCell>
              <strong>{t('expenses.overview.total.total')}</strong>{' '}
            </AppTableCell>
            <AppTableCell alignRight>{NumberFormat.n(total)}€</AppTableCell>
          </AppTableRow>

          {/* by Payment Type */}
          {paymentTypeTotal.map(({ total: val, type }) => (
            <AppTableRow key={type}>
              <AppTableCell alignRight>
                <strong>{getPaymentById(type).display}</strong>
              </AppTableCell>
              <AppTableCell alignRight>{NumberFormat.n(val)}€</AppTableCell>
            </AppTableRow>
          ))}

          {/* Advance */}
          <AppTableRow key="advance">
            <AppTableCell>
              <strong>{t('expenses.overview.advance')}</strong>{' '}
            </AppTableCell>
            <AppTableCell alignRight>
              {NumberFormat.n(advanceTotal)}€
            </AppTableCell>
          </AppTableRow>

          {/* Refund */}
          <AppTableRow key="refund">
            <AppTableCell>
              <strong>{t('expenses.overview.refund')}</strong>{' '}
            </AppTableCell>
            <AppTableCell alignRight>
              {NumberFormat.n(personal - advanceTotal)}€
            </AppTableCell>
          </AppTableRow>
        </AppTableBody>
      </AppTable>
    </>
  );
};

ExpenseOverviewTotals.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func,
};

export default ExpenseOverviewTotals;
