export const DISCOVERY_PACKAGE = 'Formule Découverte';
export const STANDARD_FORMULA = 'Formule Standard';
export const EFFICIENCY_FORMULA = 'Formule Efficience';
export const MONTHLY = 'monthly';
export const QUATERLY = 'quaterly';
export const YEARLY = 'yearly';
export const ACTIVE = 'active';
export const INACTIVE = 'inactive';

export const SUBSCRIPTION_TYPE = {
  FREE_TIER: 'free-tier',
  PAID_TIER: 'paid-tier',
  CUSTOM_TIER: 'custom-tier',
};

export const SUBSCRIPTION_NAME = {
  DISCOVERY_PACKAGE: 'Formule Découverte',
  STANDARD_FORMULA: 'Formule Standard',
  EFFICIENCY_FORMULA: 'Formule Efficience',
};

export const CANCEL_SUBSCRIPTION_TYPE = {
  FREE_PERIOD: 'FREE_PERIOD',
  REFUND_PERIOD: 'REFUND_PERIOD',
  NON_REFUND_PERIOD: 'NON_REFUND_PERIOD',
};

export const CACHE_TYPE = {
  OCR: 'ocr',
  FORM: 'form',
  INVITE_USER: 'invite_user',
};
