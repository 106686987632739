import itemService from '../../services/ItemService';

import { setGlobalError } from '../GlobalError';

import {
  FETCH_ITEMS,
  FETCH_ITEMS_FAILED,
  // CREATE_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
} from '../Types';

import {
  userCanReadItem,
  userCanCreateItem,
  userCanUpdateItem,
  userCanDeleteItem,
} from '../../selectors/rightsSelector/itemRequests';

import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

export const fetchItems = () => async (dispatch, getState) => {
  try {
    if (!userCanReadItem(getState())) {
      dispatch({
        type: FETCH_ITEMS,
        payload: [],
      });

      return;
    }

    const companyId = selectLoggedUserCompanyId(getState());

    const result = await itemService.fetchItems({
      company_id: companyId,
    });
    dispatch({ type: FETCH_ITEMS, payload: result.data });
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: FETCH_ITEMS_FAILED, error });
  }
};

export const createItem = (items, cb) => async (dispatch, getState) => {
  try {
    if (!userCanCreateItem(getState())) {
      cb();
      return;
    }
    const itemList = items.map((item) => {
      // eslint-disable-next-line no-param-reassign
      const grossPrice =
        Number(item.net_price) +
        Number(item.net_price) * (Number(item.vat_rate) / 100);

      return { ...item, gross_price: grossPrice };
    });
    const payload = {
      items: itemList,
      company_id: selectLoggedUserCompanyId(getState()),
    };
    const response = (await itemService.createItem(payload)).data;
    cb(null, response);
  } catch (error) {
    cb(error);
  }
};

export const updateItem = (item, cb) => async (dispatch, getState) => {
  try {
    if (!userCanUpdateItem(getState())) {
      cb();
      return;
    }

    // eslint-disable-next-line no-param-reassign
    item.gross_price =
      parseFloat(item.net_price) +
      parseFloat(item.net_price) * (parseFloat(item.vat_rate) / 100);

    await itemService.updateItem(item);
    dispatch({ type: UPDATE_ITEM, payload: item });
    cb(null, item);
  } catch (error) {
    cb(error);
  }
};

export const deleteItem = (id, cb) => async (dispatch, getState) => {
  try {
    if (!userCanDeleteItem(getState())) {
      cb();
      return;
    }

    await itemService.deleteItem(id);
    dispatch({ type: DELETE_ITEM, payload: id });
    cb(null);
  } catch (error) {
    cb(error);
  }
};
