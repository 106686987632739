import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { find, isEmpty } from 'lodash';

import capitalizeForm from 'helpers/capitalizeForm';
import SelectAutocomplete from 'components/SelectAutocomplete';
import TextField from 'components/FormElements/textField';
import AppCheckbox from 'components/AppCheckbox';
import styles from '../../../assets/jss/root';
import AppButton from '../../AppButton';
import LoadingIndicator from '../../LoadingIndicatorDialog';
import AppCompanySearch from '../AppCompanySearch';
import AppCompanyInfoSettings from '../AppCompanyInfoSettings';
import {
  AppGreenDivider,
  AppGreyDivider,
  AppDividerColS,
  AppDividerM,
} from '../../AppDividers';
import { AppTextHeader } from '../../AppText';
import { shortSignUp } from '../../../containers/AppSignup/handleSubmit';

// import { debounceFn } from '../../../helpers/utils';

import ProgressGreen from '../../../assets/images/progress_green.svg';
import ProgressGrey from '../../../assets/images/progree_grey.svg';
import AppContractorInfoSettings from '../AppContractorInfoSettings';

const AppCompanyInfo = ({
  t,
  classes,
  // handleChange,
  errors,
  values,
  setFieldValue,
  setFieldTouched,
  fetchInfoGreffe,
  onCodeSelected,
  touched,
  onSirenChange,
  nextStage,
  prevStage,
  resetForm,
  setErrors,
  setSubmitting,
  signup,
  history,
  onBlur,
  isDisplayCompanySettingInfo = false,
}) => {
  const [displayFields, setFieldsDisplay] = useState(
    isDisplayCompanySettingInfo
  );
  const [isShortSubmitting, setIsShortSubmitting] = useState(false);
  const [confirmInfo, setConfirmInfo] = useState(false);
  // isContractor = true => hidden "<AppCompanySearch>"
  const isContractor = values?.isContractor;

  const isShortSubmitActive =
    isContractor || ((touched.name || !!values.name) && !errors.name);

  /**
   * isContractor = true => validation fields: 'siren', 'address', 'zipcode', 'city'
   * isContractor = false => validation fields: 'siren', 'address', 'zipcode', 'city','name', 'code_ape'
   * @returns boolean
   */
  const checkActiveNextButton = () => {
    const contractorValidationField = ['siren', 'address', 'zipcode', 'city'];
    const validationField = [...contractorValidationField, 'name', 'code_ape'];
    if (isContractor) {
      return contractorValidationField.every(
        (item) => !isEmpty(values[item]) && isEmpty(errors[item])
      );
    }

    return validationField.every(
      (item) => !isEmpty(values[item]) && isEmpty(errors[item])
    );
  };

  const renderLoading = () => {
    if (isShortSubmitting) {
      return <LoadingIndicator title={t('loading_single')} open={true} />;
    }
    return null;
  };

  // function onSelectedTypeChanged(e) {
  //   setFieldValue('rcsType', e.target.value);
  // }

  const displayTitleInCompanyMode = () => {
    const chooseTitles = [
      { label: 'M.', value: 'M.' },
      { label: 'Mme.', value: 'Mme.' },
    ];

    return (
      <Grid item xs={12} sm={12} md={2}>
        <SelectAutocomplete
          required
          name="title"
          label={t('client.title')}
          onChange={(title) => {
            setFieldValue('title', title ? title.value : '');
          }}
          getOptionLabel={(option) => option.label}
          values={chooseTitles}
          valueSelected={find(
            chooseTitles,
            (elem) => elem.value === values.title
          )}
        />
      </Grid>
    );
  };

  function onSubmit() {
    setIsShortSubmitting(true);
    shortSignUp(
      values,
      resetForm,
      setErrors,
      setSubmitting,
      signup,
      history,
      setIsShortSubmitting
    );
  }

  return (
    <Grid container spacing={2}>
      {renderLoading()}
      {/* Form title */}
      <Grid item xs={12} container justify="center">
        <AppTextHeader>{t('signup.company.step2')}</AppTextHeader>
      </Grid>
      {/* Form image stepper */}
      <Grid item xs={12} container justify="center">
        <img
          alt=""
          src={ProgressGreen}
          onClick={prevStage}
          style={{
            cursor: 'pointer',
          }}
        />
        <AppGreenDivider />
        <img alt="" src={ProgressGreen} />
        <AppGreyDivider />
        <img alt="" src={ProgressGrey} />
      </Grid>
      {/* Wide field to select a company with search field */}
      {!isContractor && (
        <Grid item xs={12}>
          <AppCompanySearch
            setFieldValue={setFieldValue}
            fetchInfoGreffe={fetchInfoGreffe}
            t={t}
            values={values}
            onSelect={() => {
              setFieldsDisplay(true);
              setFieldTouched('code_ape', true);
            }}
          />
        </Grid>
      )}
      {displayFields && isContractor ? (
        <>
          <Grid
            container
            spacing={2}
            item
            style={{ display: 'flex', padding: 0 }}
          >
            {displayTitleInCompanyMode()}
            {/* Row with Firstname and lastname */}
            <Grid item xs={12} sm={12} md={5}>
              <TextField
                type="text"
                value={values.userName}
                name="userName"
                label={t('signup.name')}
                placeholder={t('signup.name')}
                color="secondary"
                onChange={(e) => {
                  capitalizeForm(e, setFieldValue);
                }}
                required
              />
            </Grid>
            <Grid item xs={12} sm={12} md={5}>
              <TextField
                type="text"
                value={values.firstname}
                name="firstname"
                label={t('signup.first_name')}
                placeholder={t('signup.first_name')}
                color="secondary"
                onChange={(e) => {
                  capitalizeForm(e, setFieldValue);
                }}
                required
              />
            </Grid>
          </Grid>
          <AppContractorInfoSettings
            t={t}
            values={values}
            setFieldValue={setFieldValue}
            onCodeSelected={(code) => setFieldValue('code_ape', code?.Code)}
            onSirenChange={(e) => setFieldValue('siren', e.target.value)}
          />
        </>
      ) : (
        <AppCompanyInfoSettings
          t={t}
          errors={errors}
          values={values}
          classes={classes}
          setSubmitting={setSubmitting}
          setFieldValue={setFieldValue}
          history={history}
          touched={touched}
          fetchInfoGreffe={fetchInfoGreffe}
          onCodeSelected={onCodeSelected}
          onBlur={onBlur}
          onSirenChange={onSirenChange}
          isSettings={true}
        />
      )}
      <AppCheckbox
        onClick={() => {
          setConfirmInfo(!confirmInfo);
        }}
        checked={confirmInfo}
        labelText={t('onboarding.confirm_info')}
      />
      <AppDividerM />
      {/* Row with buttons */}
      <Grid item xs={12} container justify="flex-end">
        {!displayFields && (
          <>
            <AppButton
              color="secondaryLight"
              text={t('signup.company.complete_profile')}
              onClick={() => setFieldsDisplay(true)}
              noBorder
            />
            <AppDividerColS />
          </>
        )}

        <AppButton
          color="transparentGrey"
          text={t('company.sign_up_later')}
          onClick={onSubmit}
          noBorder
          isDisabled={!isShortSubmitActive}
        />
        {displayFields && (
          <>
            <AppDividerColS />
            <AppButton
              color="secondaryLight"
              text={t('next')}
              onClick={nextStage}
              noBorder
              isDisabled={!(confirmInfo && checkActiveNextButton())}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};
AppCompanyInfo.propTypes = {
  t: PropTypes.func,
  classes: PropTypes.object.isRequired,
  // handleChange: PropTypes.func.isRequired,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  onCodeSelected: PropTypes.func,
  resetForm: PropTypes.func,
  setErrors: PropTypes.func,
  setSubmitting: PropTypes.func,
  signup: PropTypes.func,
  setFieldValue: PropTypes.func,
  fetchInfoGreffe: PropTypes.func,
  history: PropTypes.func,
  onSirenChange: PropTypes.func,
  nextStage: PropTypes.string,
  prevStage: PropTypes.string,
  setFieldTouched: PropTypes.func,
  onBlur: PropTypes.func,
  // isSignup: PropTypes.bool,
  isDisplayCompanySettingInfo: PropTypes.bool,
};
AppCompanyInfo.propTypes = {
  // isSignup: false,
};

export default withStyles(styles)(AppCompanyInfo);
