import _ from 'lodash';

import {
  FETCH_TEMPLATES,
  FETCH_TEMPLATES_FAILED,
  CREATE_TEMPLATE,
  UPDATE_TEMPLATE,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_TEMPLATES:
      return _.mapKeys(action.payload, '_id');
    case CREATE_TEMPLATE: {
      return { ...state, [action.payload._id]: action.payload };
    }
    case UPDATE_TEMPLATE: {
      return { ...state, [action.payload.id]: action.payload.values };
    }
    case FETCH_TEMPLATES_FAILED:
      return {};
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return {};
    default:
      return state;
  }
};
