import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import Box from '../Box';
import RestApp from '../RestApp';
import '../index.css';

class Invoice extends Component {
  render() {
    const {
      title,
      color,
      logo,
      logoInfo,
      company,
      invoice,
      contact,
      client,
      reference,
      companyMentionBottom,
      widthLogo,
      heightLogo,
      handleStop,
      overlay,
      handleChange,
      userCompany,
    } = this.props;

    return (
      <Fragment>
        <div id="top">
          <Box
            isImage
            info={logoInfo}
            onStop={handleStop}
            overlay={overlay}
            onChange={handleChange}
            id="logo"
            name="logo"
            alt="logo"
            width={widthLogo}
            height={heightLogo}
            content="Logo"
            src={logo}
          />

          <Box
            info={company}
            onStop={handleStop}
            overlay={overlay}
            id="company_info"
            onChange={handleChange}
            name={'company'}
            content="Company info"
          >
            <div id="company_name" style={{ color }}>
              {userCompany.name}
            </div>
            <div style={{ lineHeight: 'normal' }}>
              {userCompany.address}
              <br />
              {userCompany.zipcode} {userCompany.city}
            </div>
          </Box>

          <Box
            info={title}
            onStop={handleStop}
            overlay={overlay}
            id="invoice_info"
            onChange={handleChange}
            name={'title'}
            content="Title info"
          >
            <div id="title_name" style={{ color }}>
              Facture
            </div>
          </Box>

          <Box
            info={invoice}
            onStop={handleStop}
            overlay={overlay}
            id="invoice_info"
            onChange={handleChange}
            name={'invoice'}
            content="Invoice info"
          >
            <div id="invoice_nb">Nº FAC00002</div>
            <div id="date">{format(new Date(), 'dd/MM/yyyy')}</div>
            {/* <div>Nº client: CLT00001</div> */}
          </Box>

          <Box
            info={contact}
            onStop={handleStop}
            overlay={overlay}
            id="contact_info"
            onChange={handleChange}
            name={'contact'}
            content="Contact info"
          >
            <div id="email">youremail@mail.com</div>
            <div id="contact_name">0101010101</div>
          </Box>

          <Box
            info={client}
            onStop={handleStop}
            overlay={overlay}
            id="client_info"
            onChange={handleChange}
            name={'client'}
            content="Client info"
          >
            <div id="client_name" style={{ color }}>
              Le Client
            </div>
            <div id="adress">
              10 Quai de la Marne <br />
              75019 Paris <br />
              France <br />
            </div>
          </Box>
          <Box
            info={reference}
            onStop={handleStop}
            overlay={overlay}
            id="reference"
            onChange={handleChange}
            name={'reference'}
            content="reference"
          >
            <div className="reference">Réf: à FAC00001</div>
          </Box>
        </div>
        <RestApp
          color={color}
          reference={reference}
          companyMentionBottom={companyMentionBottom}
          handleChange={handleChange}
          overlay={overlay}
          onStop={handleStop}
          company={userCompany}
          t={this.props.t}
        />
      </Fragment>
    );
  }
}

Invoice.propTypes = {
  title: PropTypes.object,
  color: PropTypes.string,
  logo: PropTypes.string,
  company: PropTypes.object,
  logoInfo: PropTypes.object,
  invoice: PropTypes.object,
  contact: PropTypes.object,
  client: PropTypes.object,
  reference: PropTypes.object,
  companyMentionBottom: PropTypes.object,
  widthLogo: PropTypes.number,
  heightLogo: PropTypes.number,
  handleStop: PropTypes.func,
  overlay: PropTypes.func,
  handleChange: PropTypes.func,
  userCompany: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default Invoice;
