import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';

const RefundBankInfoHeader = () => {
  const { t } = useTranslation();

  return (
    <Header name={t('invoices.header.refund_header')} spaceBetween></Header>
  );
};

RefundBankInfoHeader.propTypes = {};

export default RefundBankInfoHeader;
