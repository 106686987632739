import * as yup from 'yup';

/* eslint import/prefer-default-export: off */
export const validationSchema = ({ t }) =>
  yup.object().shape({
    name: yup.string().required(t('forms.mandatory_field')),
    description: yup.string(),
    net_price: yup
      .number(t('forms.must_be_number'))
      .required(t('forms.mandatory_field')),
    vat_rate: yup.number().required(t('forms.mandatory_field')),
    unit: yup.string(),
  });
