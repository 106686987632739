import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Container, Grid, Hidden } from '@material-ui/core';
import { SnackbarProvider, useSnackbar } from 'notistack';

import Sidebar from '../../containers/Sidebar';
import AppSelectUserCard from '../AppSelectUserCard';

import style from './style';
import SideBarRight from '../../containers/SideBarRight';
import Footer from '../Footer';

const useStyles = makeStyles(() => style);

const GlobalErrors = () => {
  const { enqueueSnackbar } = useSnackbar();
  const errors = useSelector((state) => state.globalErrors);

  useEffect(() => {
    if (errors.length) {
      enqueueSnackbar(errors.pop(), { variant: 'error' });
    }
  }, [errors]);

  return null;
};

const Layout = ({
  header,
  body,
  sidebarLeft = null,
  sidebarRight = null,
  sidebarTop = null,
  showUserCard,
  hasCardBodyStyles = true,
}) => {
  // const [isCollapse, setIsCollapse] = useState(false);
  const { isCollapse } = useSelector((state) => state.resizeScreen);
  // Apply CSS
  const customClasses = useStyles();
  // Because we're going to use the classes={classes notation, we can only include the keys allowed by the API (root, container and item)}
  const classes = {
    root: customClasses.root,
    container: customClasses.container,
    item: customClasses.item,
    sidebarLeft: customClasses.sidebarLeft,
    sidebarTop: customClasses.sidebarTop,
    positionSticky: customClasses.positionSticky,
  };

  // First we the size of the left sidebar and the main body. Screens with no sidebar should take the entire screen
  let sizeLeft;
  let sizeMain;

  if (sidebarLeft === true) {
    // the screen should have a left sidebar, so we calculate the correct sizes
    sizeLeft = {
      xs: 2,
      sm: 2,
      md: 2,
      lg: 2,
      xl: 2,
    };
    sizeMain = {
      xs: 10,
      sm: 10,
      md: 10,
      lg: 10,
      xl: 10,
    };
  } else {
    // the screen won't have a left sidebar. sizeLeft is useless (we do not render it).
    // We just need to set sizeMain
    sizeMain = {
      xs: 12,
      sm: 12,
      lg: 12,
    };
  }

  if (sidebarLeft && isCollapse) {
    // the screen should have a left sidebar, so we calculate the correct sizes
    sizeLeft = {
      // sm: 1,
      // md: 1,
      // lg: 1,
      // xl: 1,
    };
    sizeMain = {
      // sm: 11,
      // md: 11,
      // lg: 11,
      // xl: 11,
    };
  }

  // Now calculate the size of the first row of the main body. This is because some screens may show the user card. Other may not.
  let sizeBodyHearTitle;
  let sizeBodyHearCard;

  if (showUserCard === true) {
    sizeBodyHearTitle = { xs: 10 };
    sizeBodyHearCard = { xs: 2 };
  } else {
    sizeBodyHearTitle = { xs: 12 };
    // the card is not displayed in the render function
  }

  // Finally, we calculate the size of the second row of the main body. This is because some screens may show a right sidebar. Other may not.
  let sizeBodyMainContent;
  let sizeBodyMainSidebarRight;

  if (sidebarRight) {
    // note xs=12 on sizeBodyMainContent because no matter what, on xs, the body takes the whole width and the sidebar is hidden
    sizeBodyMainContent = { xs: 12, sm: 10 };
    sizeBodyMainSidebarRight = { sm: 2 };
  } else {
    sizeBodyMainContent = { xs: 12 };
    // the right sidebar is not shown in the render method
  }

  return (
    <Container maxWidth="xl" classes={{ root: customClasses.containerRoot }}>
      <SnackbarProvider maxSnack={5}>
        <GlobalErrors />
      </SnackbarProvider>
      <Grid
        // justify="flex-end"
        // container
        style={{ display: 'flex' }}
        spacing={isCollapse ? 1 : 2}
        classes={classes}
      >
        {/* First the left sidebar, if needed */}
        {sidebarLeft && (
          <Grid item className={classes.sidebarLeft} {...sizeLeft}>
            <Sidebar isCollapse={isCollapse} />
          </Grid>
        )}

        {/* Then the main content */}
        <Grid
          item
          container
          className={
            !isCollapse ? customClasses.sidebarRight : customClasses.sidebarLeft
          }
          {...sizeMain}
          spacing={2}
          alignContent={'flex-start'}
          style={{ width: 'calc(100% - 68px)' }}
        >
          {/* Show the header */}
          <Grid item xs={10} {...sizeBodyHearTitle}>
            {header}
          </Grid>
          {/* then on the same line the user card */}
          {showUserCard && (
            <Grid item {...sizeBodyHearCard}>
              <AppSelectUserCard />
            </Grid>
          )}
          {sidebarTop && (
            <Grid item xs={12} className={classes.positionSticky}>
              <div className={classes.sidebarTop}>{sidebarTop}</div>
            </Grid>
          )}
          {/* on a new line the body */}
          <Grid item {...sizeBodyMainContent}>
            <div className={hasCardBodyStyles && customClasses.mainBodyRoot}>
              {body}
            </div>
          </Grid>
          {/* then on the same line the right sidebar (if it exists)
              the right sidebar NEVER APPEARS ON MOBILE */}
          {sidebarRight && (
            <Hidden xsDown>
              <Grid item {...sizeBodyMainSidebarRight}>
                <SideBarRight>{sidebarRight}</SideBarRight>
              </Grid>
            </Hidden>
          )}

          {/* On a new line - The right sidebar is hidden on mobile, so we display it in the center instead (if it exists) */}
          {sidebarRight && (
            <Hidden smUp>
              <Grid item xs={12}>
                <SideBarRight>{sidebarRight}</SideBarRight>
              </Grid>
            </Hidden>
          )}
        </Grid>
      </Grid>
      <Footer />
    </Container>
  );
};

Layout.propTypes = {
  body: PropTypes.object.isRequired,
  header: PropTypes.object,
  sidebarLeft: PropTypes.bool,
  sidebarRight: PropTypes.object,
  sidebarTop: PropTypes.object,
  showUserCard: PropTypes.bool,
  hasCardBodyStyles: PropTypes.bool,
};

Layout.defaultProps = {
  body: PropTypes.object.isRequired,
  header: null,
  sidebarLeft: true,
  sidebarRight: null,
  sidebarTop: null,
  showUserCard: true,
  hasCardBodyStyles: true,
};

export default React.memo(Layout);
