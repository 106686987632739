import { simpleInstance as axios } from '../../helpers/axiosInterceptor';

const createCompany = async (data) =>
  axios.post(`${process.env.REACT_APP_SIGNUP_URL}/company/web`, data);

const getRcs = (zip, city) =>
  axios.get(`${process.env.REACT_APP_RCS_URL}?zip=${zip}&city=${city}`);

const checkStripeSession = async (sessionId) =>
  axios.post(`${process.env.REACT_APP_STRIPE_URL}/check-session`, {
    sessionId,
  });

const connectSubscription = async (sessionId, companyId) =>
  axios.post(`${process.env.REACT_APP_API_URL}/auth/company-subscription`, {
    sessionId,
    companyId,
  });
const connectSubscriptionRemoveApiVersion = async (subscriptionId, companyId) =>
  axios.post(
    `${process.env.REACT_APP_API_URL_NO_VERSION}/auth/company-subscription-new`,
    {
      subscriptionId,
      companyId,
    }
  );

export default {
  createCompany,
  connectSubscriptionRemoveApiVersion,
  getRcs,
  checkStripeSession,
  connectSubscription,
};
