import _ from 'lodash';

import paginateAction from 'helpers/paginateAction';
import invoiceService from '../../services/InvoiceService';
import s3FileService from '../../services/S3FileService';

import uploadFile from '../../helpers/fileUploadHelper';

import { setGlobalError } from '../GlobalError';

import {
  FETCH_INVOICES,
  FETCH_INVOICES_FAILED,
  FETCH_INVOICE,
  CREATE_INVOICE,
  VALIDATE_INVOICE,
  REFUND_INVOICE,
  UPDATE_INVOICE,
  INVOICE_TURNOVER,
  RESEND_INVOICE,
  FETCH_INVOICES_PAGINATE,
  REFRESH_INVOICES_PAGINATE,
} from '../Types';

import TemplateService from '../../services/TemplateService';

import {
  userCanCancelInvoice,
  userCanUpdateInvoice,
  userCanCreateInvoice,
  userCanReadInvoice,
  userCanValidateInvoice,
  userCanResendInvoice,
} from '../../selectors/rightsSelector/invoiceRequests';
import { userCanOverviewInvoice } from '../../selectors/rightsSelector/dashboardRequests';
import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

import { prepareInvoiceForSubmission } from '../../models/invoice';
import logger from '../../helpers/logger';

// options are backend filter. not used for now
export const fetchInvoices =
  (cb, options = {}) =>
  async (dispatch, getState) => {
    try {
      if (!userCanReadInvoice(getState())) {
        dispatch({
          type: FETCH_INVOICES,
          payload: [],
        });

        if (cb) cb();
        return;
      }

      const companyId = selectLoggedUserCompanyId(getState());

      const result = await invoiceService.fetchInvoices({
        company_id: companyId,
        ...options,
      });

      const results = result.data.docs.map((invoice) => {
        if (invoice && invoice.company) {
          /* eslint-disable-next-line */
          invoice.company.logo = invoice.company.logo || '';
        } else {
          logger.warn('Problem with Invoice here', invoice);
          if (invoice) {
            /* eslint-disable-next-line */
            invoice.company = {
              logo: '',
            };
          } else {
            /* eslint-disable-next-line */
            invoice = {
              company: {
                logo: '',
              },
            };
          }
        }
        return invoice;
      });

      dispatch({
        type: FETCH_INVOICES,
        payload: results,
      });
      if (cb) cb();
    } catch (error) {
      dispatch(setGlobalError(error));
      dispatch({ type: FETCH_INVOICES_FAILED, error });
      if (cb) cb(error);
    }
  };

export const fetchInvoicePaginate =
  (query, filters, refresh) => async (dispatch, getState) => {
    const { invoicePaginate } = getState();
    const res = await paginateAction({
      state: invoicePaginate,
      dispatch,
      service: invoiceService.fetchInvoicePaginate,
      query,
      filters,
      refresh,
      actions: {
        refresh: REFRESH_INVOICES_PAGINATE,
        fetch: FETCH_INVOICES_PAGINATE,
      },
      queryCustom: { invoiceIds: query?.invoiceIds },
    });
    return res;
  };

export const fetchInvoice = (id, cb) => async (dispatch, getState) => {
  try {
    if (!userCanReadInvoice(getState())) {
      if (cb) cb();
      return;
    }

    const result = (await invoiceService.fetchInvoice(id)).data;

    result.company.logo = result.company.logo || '';

    dispatch({
      type: FETCH_INVOICE,
      payload: result,
    });

    if (cb) cb(null, result);
  } catch (error) {
    if (cb) cb(error);
  }
};

export const fetchInvoicePDF = (invoice, cb) => async (dispatch, getState) => {
  // if (!userCanReadInvoice(getState())) {
  //   cb();
  //   return;
  // }

  // TODO : look deeper into why there is a race condition on the Client PDF page
  // it causes this function to be called with no invoice ?!
  if (!invoice) {
    cb();
    return;
  }

  if (invoice.state === 'validated' || invoice.state === 'paid') {
    const { url } = await s3FileService.geturl(invoice.filename);
    const data = await s3FileService.getContentPdfByUrl(url);
    cb(null, data);
  } else {
    const invoiceWithTemplate = { ...invoice };
    if (invoice.template && invoice.template !== 'default') {
      const templates = _.map(getState().templates, (template) => template);
      invoiceWithTemplate.template = templates.find(
        (template) => template._id === invoice.template
      );

      if (!invoiceWithTemplate.template) {
        try {
          const template = (
            await TemplateService.fetchTemplate(invoice.template)
          ).data;
          invoiceWithTemplate.template = template._id ? template : undefined;
        } catch (err) {
          invoiceWithTemplate.template = undefined;
        }
      }
    } else if (invoice.template === 'default') {
      invoiceWithTemplate.template = undefined;
    }

    invoiceService
      .getInvoicePDF(invoiceWithTemplate)
      .then((resp) => {
        cb(null, resp.data);
      })
      .catch((error) => {
        dispatch(setGlobalError(error));
        cb(error);
      });
  }
};

export const createInvoice = (values, cb) => async (dispatch, getState) => {
  try {
    if (!userCanCreateInvoice(getState())) {
      if (cb) cb();
      return;
    }
    const {
      loggedUser: { user },
      wallet: { success, wallet },
    } = getState();

    const invoice = prepareInvoiceForSubmission(
      values,
      user._id,
      success && wallet
    );

    const response = (await invoiceService.createInvoice(invoice)).data;
    dispatch({ type: CREATE_INVOICE, payload: response });

    if (cb) cb(null, response._id);
  } catch (error) {
    if (cb) cb(error);
  }
};

export const updateInvoice = (id, values, cb) => async (dispatch, getState) => {
  try {
    const {
      loggedUser: { user },
      wallet: { success, wallet },
    } = getState();

    if (!userCanUpdateInvoice(getState())) {
      if (cb) cb();
      return;
    }

    const invoice = prepareInvoiceForSubmission(
      values,
      user._id,
      success && wallet
    );

    const response = (await invoiceService.updateInvoice(id, invoice)).data;
    dispatch({ type: UPDATE_INVOICE, payload: { id, response } });
    if (cb) cb();
  } catch (error) {
    logger.log('ERR: ', error);
    if (cb) cb(error);
  }
};

export const validateInvoice =
  (id, values, emailContent, cb) => async (dispatch, getState) => {
    try {
      const {
        loggedUser: { user },
        loggedUserCompany: { company },
        wallet: { success, wallet },
      } = getState();

      if (!userCanValidateInvoice(getState())) {
        cb();
        return;
      }

      if (values) {
        const invoice = prepareInvoiceForSubmission(
          values,
          user._id,
          success && wallet
        );

        if (values.files) {
          const files = [];
          let file;
          for (let i = 0; i < values.files.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            file = await uploadFile(values.files[i], company);
            files.push(file);
          }
          invoice.attachedFilenames = files;
        }

        await invoiceService.updateInvoice(id, invoice);
      }
      const { oldInvoice, newInvoice } = (
        await invoiceService.validateInvoice(id, emailContent)
      ).data;

      dispatch({
        type: VALIDATE_INVOICE,
        payload: { id, oldInvoice, newInvoice },
      });
      cb();
    } catch (error) {
      cb(error);
    }
  };

export const cancelInvoice = (id, cb) => async (dispatch, getState) => {
  try {
    if (!userCanCancelInvoice(getState())) {
      cb();
      return;
    }

    const response = (await invoiceService.cancelInvoice(id)).data;

    if (response.invoice && response.creditNote) {
      const { invoice, creditNote } = response;
      dispatch({ type: REFUND_INVOICE, payload: { id, invoice, creditNote } });
      cb(null, creditNote._id);
    } else {
      dispatch({ type: UPDATE_INVOICE, payload: { id, response } });
      cb(null);
    }
  } catch (error) {
    cb(error);
  }
};

export const refundInvoice = (id, cb) => async (dispatch, getState) => {
  try {
    if (!userCanUpdateInvoice(getState())) {
      cb();
      return;
    }

    const { invoice, creditNote } = (await invoiceService.refundInvoice(id))
      .data;
    dispatch({ type: REFUND_INVOICE, payload: { id, invoice, creditNote } });
    cb(null, creditNote._id);
  } catch (error) {
    cb(error);
  }
};

export const sendInvoice =
  (id, values, emailContent, cb) => async (dispatch, getState) => {
    try {
      const {
        loggedUser: { user },
        loggedUserCompany: { company },
        wallet: { success, wallet },
      } = getState();

      if (!userCanUpdateInvoice(getState())) {
        cb();
        return;
      }

      if (values) {
        const invoice = prepareInvoiceForSubmission(
          values,
          user._id,
          success && wallet
        );
        if (values.files) {
          const files = [];
          let file;
          for (let i = 0; i < values.files.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            file = await uploadFile(values.files[i], company);
            files.push(file);
          }
          invoice.attachedFilenames = files;
        }

        await invoiceService.updateInvoice(id, invoice);
      }
      const response = (await invoiceService.sendInvoice(id, emailContent))
        .data;

      dispatch({ type: UPDATE_INVOICE, payload: { id, response } });
      cb();
    } catch (error) {
      cb(error);
    }
  };

export const resendInvoice =
  (id, emailContent, cb) => async (dispatch, getState) => {
    try {
      if (!userCanResendInvoice(getState())) {
        if (cb) {
          cb();
        }
        return;
      }
      const response = (await invoiceService.resendInvoice(id, emailContent))
        .data;

      dispatch({ type: RESEND_INVOICE, payload: { id, response } });
      cb();
    } catch (error) {
      cb(error);
    }
  };

export const payInvoice =
  (id, transaction, cb) => async (dispatch, getState) => {
    try {
      if (!userCanUpdateInvoice(getState())) {
        cb();
        return;
      }

      const transactionIso = {
        ...transaction,
        date: new Date(transaction.date).toISOString(),
      };
      const response = (await invoiceService.payInvoice(id, transactionIso))
        .data;
      dispatch({ type: UPDATE_INVOICE, payload: { id, response } });
      cb();
    } catch (error) {
      cb(error);
    }
  };

export const fetchTurnOver = (cb) => (dispatch, getState) => {
  if (!userCanOverviewInvoice(getState())) {
    if (cb) cb();
    dispatch({ type: INVOICE_TURNOVER, payload: [] });
    return;
  }

  const { endFiscalYear } = getState().loggedUserCompany.company;

  if (endFiscalYear) {
    invoiceService
      .fetchTurnOver(endFiscalYear)
      .then((result) => {
        dispatch({ type: INVOICE_TURNOVER, payload: result.data });
        if (cb) cb();
      })
      .catch((error) => {
        dispatch(setGlobalError(error));
        if (cb) cb(error);
      });
  }
};

export const fetchClientInvoice = (id, clientId, cb) => async (dispatch) => {
  try {
    const result = (await invoiceService.fetchClientInvoice(id, clientId)).data;

    result.company.logo = result.company.logo || '';

    dispatch({
      type: FETCH_INVOICE,
      payload: result,
    });

    if (cb) cb(null, result);
  } catch (error) {
    dispatch(setGlobalError(error));
    if (cb) cb(error);
  }
};

export const clientSignInvoice =
  (invoice, clientId, blob, cb) => async (dispatch) => {
    try {
      if (blob.type === 'application/pdf') {
        // TODO: convert pdf to png
      }

      const file = new File([blob], `signature.png`, { type: 'image/png' });
      const { oldInvoice, newInvoice } = (
        await invoiceService.clientSignInvoice(invoice._id, clientId, file)
      ).data;

      dispatch({
        type: VALIDATE_INVOICE,
        payload: { id: invoice._id, oldInvoice, newInvoice },
      });
      cb();
    } catch (error) {
      cb(error);
    }
  };

export const clientCancelInvoice =
  (id, clientId, emailContent, cb) => async (dispatch) => {
    try {
      const response = (
        await invoiceService.clientCancelInvoice(id, clientId, emailContent)
      ).data;
      dispatch({ type: UPDATE_INVOICE, payload: { id, response } });
      cb();
    } catch (error) {
      cb(error);
    }
  };

export const clientValidateInvoice = (id, clientId, cb) => async (dispatch) => {
  try {
    const { oldInvoice, newInvoice } = (
      await invoiceService.clientValidateInvoice(id, clientId)
    ).data;

    dispatch({
      type: VALIDATE_INVOICE,
      payload: { id, oldInvoice, newInvoice },
    });
    cb();
  } catch (error) {
    cb(error);
  }
};
