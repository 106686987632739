import { bindActionCreators } from 'redux';
import { fetchInvoicePDF, fetchInvoice } from '../../../actions/InvoiceActions';

export default (dispatch) =>
  bindActionCreators(
    {
      fetchInvoicePDF,
      fetchInvoice,
    },
    dispatch
  );
