const transferMultipleLevelSelect = [
  {
    title: 'pay_an_purchase',
    path: '/bank-bbf/transfer',
    filter: ['validated'],
    type: 'purchase',
  },
  {
    title: 'reimburse_an_expense_report',
    path: '/bank-bbf/transfer',
    filter: ['validated'],
    type: 'expense',
  },
  {
    title: 'other',
  },
];

export default transferMultipleLevelSelect;
