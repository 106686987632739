import React from 'react';

// Get all current sorting information needed
export const getSortState = (tableState) => {
  const sort = {};

  const colArray = [...tableState.columns];
  colArray.forEach((el, i) => {
    if (el.sortDirection && el.sortDirection !== 'none') {
      sort.idOfColumn = i;
      sort.sortDirection = el.sortDirection;
    }
  });

  return sort;
};

// Get all current filtering information needed
export const getFilterState = (tableState) => {
  const filter = [];

  const filterListArray = tableState.filterList;
  filterListArray.forEach((el, i) => {
    if (el.length !== 0) {
      filter.push({
        id: i,
        filterList: el,
      });
    }
  });

  return filter;
};

// get current page
export const getPageState = (tableState) => {
  const tableStatecp = { ...tableState };
  return { page: tableStatecp.page, rowsPerPage: tableStatecp.rowsPerPage };
};

const isSortWellFormated = (sort) => {
  if (!sort) {
    return false;
  }
  if (sort.idOfColumn === undefined) {
    return false;
  }
  if (sort.sortDirection === undefined) {
    return false;
  }
  if (!Number.isInteger(sort.idOfColumn)) {
    return false;
  }
  if (sort.sortDirection !== 'asc' && sort.sortDirection === 'desc') {
    return true;
  }
  if (sort.sortDirection !== 'desc' && sort.sortDirection === 'asc') {
    return true;
  }
  if (sort.sortDirection !== 'desc' && sort.sortDirection !== 'asc') {
    return false;
  }
  return true;
};

function isFilterWellFormatted(filter) {
  let myReturn = true;
  if (!filter) {
    myReturn = false;
  }
  if (!Array.isArray(filter)) {
    myReturn = false;
  }
  if (filter.length === 0) {
    myReturn = false;
  }
  // eslint-disable-next-line
  if (Array.isArray(filter)) {
    filter.forEach((element) => {
      if (element.id === undefined || element.filterList === undefined) {
        myReturn = false;
      }
      // eslint-disable-next-line
      if (!Number.isInteger(element.id) || !Array.isArray(element.filterList)) {
        myReturn = false;
      }

      let i = 0;
      let filters = element.filterList[i];
      while (filters !== undefined) {
        if (typeof filters !== 'string') {
          myReturn = false;
          break;
        }
        i += 1;
        filters = element.filterList[i];
      }
    });
  }
  // eslint-disable-next-line consistent-return
  return myReturn;
}

/** ******
 * This function updates the collumns passed as argument when we render the mui datatable component
 * ex:
 * return (
 *  <MUIDataTable
 *  data={invoices}
 *  columns={updateCollumnWithLocalStorage(columns)}
 *  options={updateOptionWithLocalStorage(options))} />);
 ******** */
export const _updateColumns = (columns, muiDataTableFilters) => {
  if (muiDataTableFilters !== null && muiDataTableFilters !== undefined) {
    const { sort } = muiDataTableFilters;
    const { filter } = muiDataTableFilters;

    if (sort === undefined && filter === undefined) {
      return columns;
    }
    if (sort === undefined && !isFilterWellFormatted(filter)) {
      return columns;
    }
    if (filter === undefined && !isSortWellFormated(sort)) {
      return columns;
    }

    if (columns.length === 0) {
      return columns;
    }
    // sort
    for (let index = 0; index < columns.length; index += 1) {
      if (columns[index].options) {
        // eslint-disable-next-line no-param-reassign
        columns[index].options.sortDirection = 'none';
      }
    }
    if (sort && columns[sort.idOfColumn] && columns[sort.idOfColumn].options) {
      // eslint-disable-next-line no-param-reassign
      columns[sort.idOfColumn].options.sortDirection = sort.sortDirection;
    }
    // filter
    if (filter && isFilterWellFormatted(filter)) {
      columns.forEach((useless, index) => {
        filter.forEach((myFilters) => {
          if (myFilters.id === index) {
            if (columns[index].options) {
              // eslint-disable-next-line no-param-reassign
              columns[index].options.filterList = myFilters.filterList;
            }
          }
        });
      });
    } else {
      columns.forEach((element) => {
        // eslint-disable-next-line
        element.options.filterList = undefined;
      });
    }

    return columns;
  }
  return columns;
};

/** ******
 * This function updates the collumns passed as argument when we render the mui datatable component
 * ex:
 * return (
 *  <MUIDataTable
 *  data={invoices}
 *  columns={updateCollumnWithLocalStorage(columns)}
 *  options={updateOptionWithLocalStorage(options))} />);
 ******** */
export const updateCollumnWithLocalStorage = (columns, type = null) => {
  let muiDataTableFilters = null;
  if (type) {
    muiDataTableFilters = JSON.parse(
      localStorage.getItem(`store${type}MuiTable`)
    );
  }
  // const isInvoice = window.location.pathname.includes('invoices/list');
  // const isExpense = window.location.pathname.includes('expenses/list');
  // const isPurchase = window.location.pathname.includes('purchases/list');
  // let muiDataTableFilters = null;
  // if (isInvoice) {
  //   muiDataTableFilters = JSON.parse(
  //     localStorage.getItem('storeInvoiceMuiTable')
  //   );
  // } else if (isExpense) {
  //   muiDataTableFilters = JSON.parse(
  //     localStorage.getItem('storeExpenseMuiTable')
  //   );
  // } else if (isPurchase) {
  //   muiDataTableFilters = JSON.parse(
  //     localStorage.getItem('storePurchaseMuiTable')
  //   );
  // }

  return _updateColumns(columns, muiDataTableFilters);
};

/** ******
 * This function updates the options passed as argument when we render the mui datatable component
 * ex:
 * return (
 *  <MUIDataTable
 *  data={invoices}
 *  columns={updateCollumnWithLocalStorage(columns, testState)}
 *  options={updateOptionWithLocalStorage(options))} />);
 ******** */
export const updateOptionWithLocalStorage = (
  additionalOptions,
  type = null
) => {
  let muiDataTableFilters = null;
  if (type) {
    muiDataTableFilters = JSON.parse(
      localStorage.getItem(`store${type}MuiTable`)
    );
  }
  let page;
  const additionalOptions2 = additionalOptions;
  if (
    muiDataTableFilters !== null &&
    muiDataTableFilters !== undefined &&
    muiDataTableFilters.page
  ) {
    // console.log(muiDataTableFilters.page);
    // console.log(typeof muiDataTableFilters.page);
    page = muiDataTableFilters.page;

    if (typeof page === 'object') {
      // console.log('page type', typeof page.page);
      if (typeof page.page === 'number') {
        additionalOptions2.page = page.page;
      }
      if (
        page.rowsPerPage === 10 ||
        page.rowsPerPage === 15 ||
        page.rowsPerPage === 100
      ) {
        additionalOptions2.rowsPerPage = page.rowsPerPage;
      }
      return additionalOptions2;
    }
  }
  return additionalOptions;
};

export const tableFrame = (columnsHeader, headerStyles) => {
  const keys = ['label', 'name'];
  const validated = columnsHeader.find((item) => {
    const headerKeys = Object.keys(item);
    const values = Object.values(item);

    if (headerKeys.length === 3) {
      headerKeys.splice(2, 1);
      values.splice(2, 1);
    }

    return (
      keys.sort().join() !== headerKeys.sort().join() ||
      values.some((val) => !val)
    );
  });

  if (!validated) {
    const columns = columnsHeader.map((column) => {
      const options = {
        // eslint-disable-next-line react/display-name, no-unused-vars
        customHeadRender: (columnItem) => {
          const styles = { ...headerStyles };
          // if (columnItem.name === 'phone' || columnItem.name === 'siret') {
          //   styles.textAlign = 'right';
          //   styles.paddingRight = '16px';
          // }
          return <th style={styles}>{column.label}</th>;
        },
        setCellProps: () => {
          return {
            style: {
              // textAlign:
              //   column.name === 'phone' || column.name === 'siret'
              //     ? 'right'
              //     : 'left',
              textAlign: 'left',
            },
          };
        },
      };
      const col = {
        name: column.name,
        options: column.options ? { ...options, ...column.options } : options,
      };
      return col;
    });
    return columns;
  }
  return [];
};
