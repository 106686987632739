import * as yup from 'yup';
import { PHONE_NUMBER_VALIDATE_SCHEMA } from 'constants/form';
import {
  TYPE_ENTREPRENEUR_INDIVIDUEL,
  TYPE_SOCIETE,
} from '../../constants/company';

/* eslint import/prefer-default-export: off */
export const validationSchema = ({ t }) =>
  yup.object().shape({
    isContractor: yup.boolean(),
    name: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.name_empty'), {
        excludeEmptyString: true,
      })
      .when('isContractor', {
        // isContractor can be null
        is: (isContractor) => isContractor !== true,
        then: (schema) => schema.required(t('signup.company.name_mandatory')),
      }),
    business_name: yup.string(),
    address: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.address_empty'), {
        excludeEmptyString: true,
      })
      .min(5, t('signup.company.address_mandatory'))
      .required(t('signup.company.address_mandatory')),
    zipcode: yup
      .string()
      .matches(/^[\w-]+$/, t('signup.company.zipcode_false'), {
        excludeEmptyString: true,
      })
      .max(15, t('signup.company.zipcode_max'))
      .required(t('signup.company.zipcode_mandatory')),
    city: yup.string().required(t('mandatory')),
    rcs: yup.string().matches(/[^\s\\]/, t('signup.company.rcs_empty'), {
      excludeEmptyString: true,
    }),
    siren: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.siren_empty'), {
        excludeEmptyString: true,
      })
      .min(9, t('signup.company.siren_min_mandatory'))
      .max(9, t('signup.company.siren_min_mandatory'))
      .required(t('signup.company.siren_mandatory')),
    legal_form: yup.string().required(t('mandatory')),
    capital: yup.string().when('companyType', {
      is: TYPE_SOCIETE,
      then: yup.string().required(t('mandatory')),
    }),
    code_ape: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.code_ape_empty'), {
        excludeEmptyString: true,
      }),
    fax_number: yup
      .string()
      .matches(
        /^(\+[1-9]{1,2})?[0-9]{3,14}$/,
        t('signup.company.fax_number_not_valid'),
        {
          excludeEmptyString: true,
        }
      ),
    phone_number: yup
      .string()
      .matches(
        PHONE_NUMBER_VALIDATE_SCHEMA,
        t('signup.company.valid_phone_number'),
        {
          excludeEmptyString: false,
        }
      ),
    website: yup
      .string()
      .matches(
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm,
        t('signup.company.website_not_valid'),
        {
          excludeEmptyString: true,
        }
      ),
    cga: yup.boolean().required(t('mandatory')),
    companyType: yup.string().required(t('mandatory')),
    tva: yup.boolean().required(t('mandatory')),
    bookkeeping: yup.string().required(t('mandatory')),
    activity: yup.string().required('error'),
    prestation: yup.boolean().when('vente', {
      is: (vente) => !vente,
      then: yup.boolean().oneOf([true], t('mandatory')),
    }),
    isCommercial: yup.boolean(),
    periodicityReport: yup.string().required(),
    intracomvat: yup
      .string()
      .when('tva', { is: true, then: yup.string().required(t('mandatory')) }),
    micro: yup.boolean().when('companyType', {
      is: TYPE_ENTREPRENEUR_INDIVIDUEL,
      then: yup.boolean().required(t('mandatory')),
    }),
    endFiscalYear: yup
      .string()
      .matches(
        /^((0[1-9]|[12][0-9]|3[01])\/(0[13578]|1[02])|(0[1-9]|[12][0-9]|30)\/(0[469]|11)|(0[1-9]|1[0-9]|2[0-8])\/02)$/,
        t('signup.end_fiscal_year')
      )
      .required(t('mandatory')),
    nic: yup.string(),
  });
