import { bindActionCreators } from 'redux';
import {
  createExpense,
  cancelExpenses,
  updateExpense,
  fetchExpenseFile,
  changeExpenseStatePending,
  validateExpenses,
  fetchExpense,
  payExpenses,
} from '../../../actions/ExpensesActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createExpense,
      cancelExpenses,
      updateExpense,
      fetchExpenseFile,
      changeExpenseStatePending,
      validateExpenses,
      fetchExpense,
      payExpenses,
    },
    dispatch
  );
