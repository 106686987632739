import React from 'react';
import PropTypes from 'prop-types';

import FilePreview from './FilePreview';
import CustomDialog from '../CustomDialog';

const FilePreviewDialog = ({
  toggleDialog,
  content,
  show,
  shape,
  customStyles,
}) => {
  if (!show) {
    return null;
  }

  return (
    <CustomDialog
      closeDialog={toggleDialog}
      userLoggedIn={true}
      setFullWidth={true}
    >
      <br />
      <br />
      <FilePreview
        content={content}
        shape={shape}
        customStyles={customStyles}
        isDialog
      />
    </CustomDialog>
  );
};

FilePreviewDialog.propTypes = {
  content: PropTypes.object,
  toggleDialog: PropTypes.func,
  show: PropTypes.bool,
  shape: PropTypes.string,
  customStyles: PropTypes.object,
};

FilePreviewDialog.defaultProps = {
  show: false,
};

export default FilePreviewDialog;
