import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core/';
import { style } from 'assets/jss/style';

const { blueContainer } = style;

const InvoiceCardPrePaymentInvoice = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={blueContainer}>
        <Typography variant="body2" component="p" style={{ color: 'white' }}>
          {t('invoices.total_gross')}
        </Typography>
        <Typography variant="body2" component="p" style={{ color: 'white' }}>
          {data.pre_payment_total} €
        </Typography>
      </div>
    </>
  );
};

InvoiceCardPrePaymentInvoice.propTypes = {
  data: PropTypes.object,
};

export default InvoiceCardPrePaymentInvoice;
