/* eslint-disable react/display-name */
import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import Layout from 'components/Layout';
import Header from 'components/Header';
import MaterialTable from 'components/MaterialTable';
import { useDispatch, useSelector } from 'react-redux';
import { fetchExpensePaginate } from 'actions/ExpensesActions';
import { Refresh } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import ExpenseState from 'components/states/ExpenseState';
import BeforeAfterDate from 'components/BeforeAfterDate';
import { useRouter } from 'hooks/routerHooks';
import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { orderBy } from 'lodash';
import FilterPopover from 'components/FilterPopover';

const expenseStates = ['draft', 'canceled', 'validated', 'paid'];

const DashBoardExpense = () => {
  const dispatch = useDispatch();
  const tableRef = React.createRef();
  const { history } = useRouter();
  const { t } = useTranslation();
  const users = useSelector((state) => state.users);
  const providers = useSelector((state) => state.providers);
  const categories = useSelector((state) => state.categories.data);
  const formatCategories = orderBy(categories, 'description', 'asc').filter(
    (cat) => cat.parent
  );

  const [refresh, setRefresh] = useState(false);

  const filters = JSON.parse(localStorage.getItem('expenseFilters'));
  localStorage.removeItem('expenseFilters');

  const [before, setBefore] = useState(filters ? filters.before : '');
  const [after, setAfter] = useState(filters ? filters.after : '');
  const [expenseState, setExpenseState] = useState(
    filters ? filters.state || [] : []
  );
  const [expenseCategories, setExpenseCategories] = useState(
    filters ? filters.categories || [] : []
  );

  function fetch() {
    return tableRef.current && tableRef.current.onQueryChange();
  }

  const clearFilters = () => {
    setExpenseState([]);
    setExpenseCategories([]);
    setBefore('');
    setAfter('');
    fetch();
  };

  useEffect(() => {
    if (refresh) {
      fetch();
    }
  }, [refresh]);

  const columns = [
    {
      title: t('expenses.expense.category'),
      field: 'category',
      sorting: false,
      render: (expense) =>
        expense.category_id
          ? categories[expense.category_id].description
          : t('expenses.expense.expense'),
    },
    {
      title: t('invoices.list.state'),
      field: 'state',
      sorting: false,

      // eslint-disable-next-line react/prop-types
      render: ({ state }) => (
        <ExpenseState state={t(`expenses.expense.state.${state}`)} />
      ),
    },
    {
      title: t('invoices.list.nb'),
      field: 'expense_nb',
      sorting: false,
    },
    {
      title: t('invoices.form_date'),
      field: 'date',
      defaultSort: 'desc',
      render: ({ date }) => format(new Date(date), 'dd/MM/yyyy'),
    },
    {
      title: t('expenses.expense.form_amount'),
      field: 'total',
      sorting: false,
      render: ({ total, grandTotalAmount }) =>
        `${Number(total || grandTotalAmount).toFixed(2)}€`,
    },
    {
      title: t('expenses.provider'),
      field: 'provider',
      sorting: false,
      render: (expense) => {
        if (expense.__t === 'Purchase')
          return providers[expense.provider]
            ? providers[expense.provider].name
            : '';
        return '';
      },
    },
    {
      title: t('expenses.expense.form_user'),
      field: 'provider',
      sorting: false,
      render: (expense) => {
        if (expense.__t !== 'Purchase')
          return users[expense.user_id]
            ? users[expense.user_id].display_name
            : '';
        return '';
      },
    },
  ];

  function renderToolBar() {
    return (
      <FilterPopover
        clearFilters={clearFilters}
        filters={
          expenseCategories.length || expenseState.length || before || after
        }
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          spacing={4}
        >
          <Grid item>
            <FormControl style={{ width: 200, marginRight: 10 }}>
              <InputLabel id="demo-mutiple-checkbox-label">
                {t('invoices.list.state')}
              </InputLabel>
              <Select
                onClose={() => {
                  fetch();
                }}
                fullWidth={true}
                multiple
                value={expenseState}
                onChange={(event) => setExpenseState(event.target.value)}
                input={<Input />}
                renderValue={(selected) =>
                  selected.map((s) => t(`state.${s}`)).join(', ')
                }
              >
                {expenseStates.map((state) => (
                  <MenuItem key={state} value={state}>
                    <Checkbox checked={expenseState.indexOf(state) > -1} />
                    <ListItemText primary={t(`state.${state}`)} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl style={{ width: 200, marginRight: 10 }}>
              <InputLabel id="demo-mutiple-checkbox-label">
                {'Categories'}
              </InputLabel>
              <Select
                onClose={() => {
                  fetch();
                }}
                fullWidth={true}
                multiple
                value={expenseCategories}
                onChange={(event) => setExpenseCategories(event.target.value)}
                input={<Input />}
                renderValue={(selected) =>
                  selected.map((s) => categories[s].description).join(', ')
                }
              >
                {formatCategories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    <Checkbox
                      checked={expenseCategories.indexOf(category._id) > -1}
                    />
                    <ListItemText primary={category.description} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item>
            <BeforeAfterDate
              onAfterChange={(value) => {
                setAfter(value);
                fetch();
              }}
              onBeforeChange={(value) => {
                setBefore(value);
                fetch();
              }}
              after={after}
              before={before}
              beforeTitle={t('invoice.filters.before')}
              afterTitle={t('invoice.filters.after')}
            />
          </Grid>
        </Grid>
      </FilterPopover>
    );
  }

  const renderBody = () => {
    return (
      <>
        {renderToolBar()}
        <MaterialTable
          tableRef={tableRef}
          data={(query) =>
            new Promise((resolve) => {
              dispatch(
                fetchExpensePaginate(
                  query,
                  {
                    state: expenseState,
                    categories: expenseCategories,
                    before: before ? `${before}T23:59:59.999Z` : '',
                    after,
                  },
                  refresh,
                  false
                )
              ).then((res) => {
                setRefresh(false);
                resolve({ ...res, data: res.data || [] });
              });
            })
          }
          columns={columns}
          options={{
            filtering: false,
            search: true,
            showTitle: false,
          }}
          actions={[
            {
              icon: () => <Refresh />,
              isFreeAction: true,
              onClick: () => setRefresh(true),
            },
          ]}
        />
      </>
    );
  };

  return (
    <Layout
      header={
        <Header
          goBack={() => history.push('/home')}
          name={t('expenses.headerTitle')}
        />
      }
      sidebarLeft={true}
      body={renderBody()}
      showUserCard={true}
    />
  );
};

export default DashBoardExpense;
