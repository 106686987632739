import colors from '../../../constants/colors.json';

const style = {
  radioGroup: {
    padding: '0',
    width: '100%',
  },
  captionBig: {
    color: colors.textGrey,
    fontFamily: 'Kumbh Sans',
    fontSize: '12px',
    lineHeight: '14px',
  },
  greySmall: {
    color: colors.textGrey,
    fontFamily: 'Kumbh Sans',
    fontSize: '10px',
    lineHeight: '11px',
  },
  captionBigSelected: {
    color: colors.textDarkGrey,
    fontFamily: 'Kumbh Sans',
    fontSize: '12px',
    lineHeight: '14px',
  },
  captionBigSelectedBold: {
    color: colors.textDarkGrey,
    fontFamily: 'Kumbh Sans',
    fontSize: '12px',
    lineHeight: '14px',
    fontWeight: 'bold',
  },
  captionBigDisabled: {
    color: colors.fillsDarkGrey,
    fontFamily: 'Kumbh Sans',
    fontSize: '12px',
    lineHeight: '14px',
  },
  '@media (max-width: 960px)': {
    radioGroupSwapToRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-start',
    },
  },
  rowFlexStart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
};

export default style;
