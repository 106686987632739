import React, { useCallback, useEffect } from 'react';
import { Box, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import NumberFormat from 'helpers/NumberFormat';

import SvgIcon from 'components/AppIcons/SvgIcon';
import ExpenseDoughnutChart from './ExpenseDoughnutChart';

import ArrowRight from '../../../assets/icon/arrow-right.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: theme.spacing(12.5),
    padding: '0 10%',
  },
  doughnutInformation: {
    flex: '1 0 0',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1.5),
    alignItems: 'flex-start',
  },
  rowContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    paddingBottom: theme.spacing(1.5),
    '&:not(:last-child)': {
      borderBottom: `1px solid ${theme.palette.primary[300]}`,
    },
    cursor: 'pointer',
  },
  rowLeftContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(1),
  },
  rowText: ({ strong }) => ({
    color: theme.palette.neutral[strong ? 900 : 600],
    fontSize: '0.875rem',
    lineHeight: '1.375rem',
  }),
}));

const Row = (props) => {
  const classes = useStyles(props);
  const { name, amount, strong, onClick } = props;

  const theme = useTheme();

  return (
    <Box className={classes.rowContainer} onClick={onClick}>
      <Box className={classes.rowLeftContainer}>
        <span className={classes.rowText}>{name}</span>
        <SvgIcon
          path={ArrowRight}
          color={theme.palette.neutral[strong ? 900 : 600]}
          width={17}
          height={17}
        />
      </Box>
      <span className={classes.rowText} style={{ fontWeight: 600 }}>
        {amount}
      </span>
    </Box>
  );
};

const ExpenseDoughnutOverview = (props) => {
  const classes = useStyles(props);
  const history = useHistory();
  const { t } = useTranslation();
  const {
    fetchExpenseOverview,
    companyId,
    hasExpenseValidateAccess,
    expenseOverview,
  } = props;
  const fetchExpenseOverviewCallback = useCallback(fetchExpenseOverview);

  const navigatePage = (url, queryParams) => {
    history.push(
      queryString.stringifyUrl(
        {
          url,
          query: queryParams,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  useEffect(() => {
    if (hasExpenseValidateAccess) {
      fetchExpenseOverviewCallback();
    }
  }, [companyId, fetchExpenseOverviewCallback, hasExpenseValidateAccess]);

  return (
    <Box className={classes.root}>
      <Box style={{ flex: 1 }}>
        <ExpenseDoughnutChart
          validatedPurchases={expenseOverview.validatedPurchases || 0}
          validatedExpenses={expenseOverview.validatedExpenses || 0}
        />
      </Box>
      <Box className={classes.doughnutInformation}>
        <Row
          name={t('expense.overview.validated_purchases')}
          amount={`${NumberFormat.n(expenseOverview.validatedPurchases || 0)}€`}
          onClick={() => {
            const expenseFilter = { state: ['validated'] };
            navigatePage('/purchases/list', expenseFilter);
          }}
        />
        <Row
          onClick={() => {
            const expenseFilter = { state: ['validated'] };
            navigatePage('/expenses/list', expenseFilter);
          }}
          name={t('expense.overview.validated_expenses')}
          amount={`${NumberFormat.n(expenseOverview.validatedExpenses || 0)}€`}
        />
        <Row
          onClick={() => {
            const expenseFilter = { state: ['validated'] };
            navigatePage('/purchases/list', expenseFilter);
          }}
          strong
          name={t('expense.overview.validated')}
          amount={`${NumberFormat.n(
            expenseOverview.validatedPurchases +
              expenseOverview.validatedExpenses || 0
          )}€`}
        />
      </Box>
    </Box>
  );
};

ExpenseDoughnutOverview.propTypes = {
  fetchExpenseOverview: PropTypes.func,
  companyId: PropTypes.string,
  hasExpenseValidateAccess: PropTypes.bool,
  expenseOverview: PropTypes.object,
};
Row.propTypes = {
  name: PropTypes.string,
  amount: PropTypes.string,
  strong: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ExpenseDoughnutOverview;
