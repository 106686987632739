import spinnerService from 'services/SpinnerService';

export default (values, { setErrors, setStatus, setSubmitting, props }) => {
  const {
    companySubscription: {
      data: {
        userLimited: { remaining: remainingUserInvitations },
      },
    },
    openAddUserFormDialog,
  } = props;

  const fetchData = () => {
    spinnerService.startSpinner();
    Promise.all([
      props.fetchCompanyUsers(),
      props.fetchCompanySubscription(),
    ]).finally(() => spinnerService.endSpinner());
  };
  const cb = (err) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      setStatus({ success: true });

      if (props.closeDialog) {
        props.closeDialog();
        fetchData();
      } else {
        setSubmitting(false);
      }
    }
  };

  if (!values._id) {
    if (remainingUserInvitations > 0) {
      props.createUser(values, cb);
    } else {
      setSubmitting(false);
      openAddUserFormDialog(values);
    }
  } else {
    props.updateUser({ ...values, avatar: undefined }, cb);

    if (props.loggedUser.user._id === values._id && values.language) {
      props.changeLanguage(values.language);
    }
  }
};
