// rename into ExpenseCategorieReducer
import { mapKeys } from 'lodash';

import {
  FETCHING_CATEGORIES,
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_FAILED,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  UPDATING_CATEGORY,
  UPDATE_CATEGORY_SUCCESS,
  UPDATE_CATEGORY_FAILED,
} from 'actions/Types';

import { transformCategoriesJSONintoChristmasTree } from 'helpers/utils';

const INITIAL_STATE = {
  loading: false,
  error: null,
  timestamp: null, // not used for now
  data: {},
  tree: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_CATEGORIES:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null,
      };

    case FETCH_CATEGORIES: {
      const { categories } = action.payload;
      const tree = transformCategoriesJSONintoChristmasTree(categories);
      const data = mapKeys(categories, '_id');
      return {
        ...state,
        loading: false,
        error: null,
        data,
        tree,
        timestamp: Date.now(),
      };
    }

    case FETCH_CATEGORIES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        data: {},
        tree: null,
        timestamp: null,
      };

    case UPDATING_CATEGORY: {
      return {
        ...state,
        loading: true,
        error: null,
      };
    }

    case UPDATE_CATEGORY_SUCCESS: {
      const { newCategory, id } = action.payload;
      const newCategories = {
        ...state.data,
        [id]: {
          ...state.data[id],
          ...newCategory,
        },
      };
      return {
        ...state,
        loading: false,
        error: null,
        data: newCategories,
        timestamp: Date.now(),
      };
    }

    case UPDATE_CATEGORY_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        timestamp: null,
      };
    }

    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return INITIAL_STATE;

    default:
      return state;
  }
};
