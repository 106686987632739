import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LOGO_FILE_TYPE } from 'constants/account';
import Avatar from './Avatar';
import './style.css';
import AppButton from '../AppButton';
import upload from '../../assets/images/upload.png';

class AvatarUpload extends Component {
  render() {
    const { image, onUpload, buttonText } = this.props;

    return (
      <>
        <input
          ref={(ref) => {
            this.fileInput = ref;
          }}
          type="file"
          style={{ display: 'none' }}
          onChange={(e) => {
            if (
              e.target.files[0] &&
              LOGO_FILE_TYPE.includes(e.target.files[0].type)
            ) {
              onUpload(e.target.files[0]);
            }
          }}
          accept={LOGO_FILE_TYPE}
        />
        <div
          style={{
            position: 'relative',
            cursor: 'pointer',
            alignSelf: 'center',
          }}
        >
          <img
            id="upload"
            src={upload}
            width={100}
            height={100}
            alt="upload"
            onClick={() => {
              this.fileInput.click();
            }}
          />
          <Avatar image={image} width={100} />
        </div>

        <AppButton
          color="transparentGrey"
          text={buttonText}
          noBorder
          fullWidth
          onClick={() => {
            this.fileInput.click();
          }}
        />
      </>
    );
  }
}

AvatarUpload.propTypes = {
  image: PropTypes.string,
  onUpload: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
};

export default AvatarUpload;
