/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import { DateRangePicker } from 'react-dates';
import Button from '@material-ui/core/Button';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import FileSaver from 'file-saver';
import { useSelector } from 'react-redux';
import * as dateFns from 'date-fns';

import FecService from 'services/FecService';

const styles = {
  rootContainer: {
    zIndex: 101,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  button: {
    width: 160,
    height: 32,
  },
  buttonIcon: {
    marginLeft: 8,
    fontSize: 20,
  },
};

const ExportRange = ({ setErrorMessage, tableRef, fetchUnExport }) => {
  // const [startDate, setStartDate] = useState(null);
  // const [endDate, setEndDate] = useState(null);
  // const [focusedInput, setFocusedInput] = useState(null);
  const [disabled, setDisabled] = useState(false);
  // const [buttonDisable, setButtonDisabled] = useState(false);
  const company = useSelector((state) => state.loggedUserCompany.company);

  const { _id: companyId, alternativeId } = company;

  const { t } = useTranslation();

  function init() {
    // setStartDate(null);
    // setEndDate(null);
    // setButtonDisabled(true);
    setDisabled(false);
  }

  function downloadFile(data) {
    if (!data) {
      setErrorMessage(t('book_keeping.download.error_message'));
      return 0;
    }

    const fileName = `${dateFns.format(
      new Date(),
      'yyyyMMdd'
    )}_${new Date().getTime()}.zip`;

    return FileSaver.saveAs(
      new Blob([data], { type: 'application/zip' }),
      fileName
    );
  }

  function onSubmit() {
    setDisabled(true);

    FecService.generateExport({
      company: {
        companyId,
        alternativeId,
      },
    })
      .then((res) => {
        if (res.data.error) {
          setErrorMessage(t('book_keeping.export.error_message'));
        } else {
          downloadFile(res.data);

          if (tableRef && tableRef.current && tableRef.current.onQueryChange) {
            tableRef.current.onQueryChange();
          }
          fetchUnExport();
        }
      })
      .catch(() => {
        setErrorMessage(t('book_keeping.download.error_message'));
      })
      .finally(init);
  }

  return (
    <div style={styles.rootContainer}>
      {/* <DateRangePicker
        startDatePlaceholderText={t('book_keeping.list.start')}
        startDate={startDate}
        startDateId="startDate"
        endDatePlaceholderText={t('book_keeping.list.end')}
        endDate={endDate}
        endDateId="endDate"
        onDatesChange={onDatesChange}
        focusedInput={focusedInput}
        onFocusChange={setFocusedInput}
        small={true}
        showDefaultInputIcon={true}
        isOutsideRange={doNotShowDate}
        displayFormat="DD/MM/YYYY"
        disabled={disabled}
      /> */}

      <Button
        variant="contained"
        color="primary"
        style={styles.button}
        onClick={onSubmit}
        disabled={disabled}
      >
        {t('book_keeping.list.download')}
        <CloudDownloadIcon style={styles.buttonIcon} />
      </Button>
    </div>
  );
};

ExportRange.propTypes = {
  setErrorMessage: PropTypes.func,
  tableRef: PropTypes.object,
  fetchUnExport: PropTypes.func,
};

export default ExportRange;
