/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  accountBalanceWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    gap: '18px',
  },

  accountBalanceWrapperTitle: {
    fontSize: '34px',
    fontWeight: 'bold',
  },

  accountBalanceWrapperIcon: {
    fontSize: '52px',
    fontWeight: 'bold',
  },

  accountBalanceEyeIcon: {
    fontSize: '30px',
  },

  spacer: {
    flex: 'auto',
  },
}));

export default useStyles;
