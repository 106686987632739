// deprecated : use src/components/CategoryList/index.js

import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';

import { DEFAULT_CATEGORY_ID } from 'config/Constants';
import CategoryIcon from '../AppCategoryIcons';
import categories from '../../__data__/categories';

const ExpensesCategories = ({ onSelect }) => (
  <Grid container spacing={3}>
    {categories
      .find((category) => category._id === DEFAULT_CATEGORY_ID)
      .children.map((cat, index) => (
        <Grid item xs={6} sm={4} md={4} lg={3} key={index}>
          <CategoryIcon
            icon={cat.icon}
            display={cat.display}
            onSelect={onSelect}
            category={cat}
          />
        </Grid>
      ))}
  </Grid>
);

ExpensesCategories.propTypes = {
  // categories: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
};

const TranslatedExpensesCategories = withTranslation()(ExpensesCategories);
export default TranslatedExpensesCategories;
