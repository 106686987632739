import { bindActionCreators } from 'redux';
import { createAdvance, updateAdvance } from '../../../actions/AdvanceActions';

import {
  fetchExpense,
  payExpenses,
  validateExpenses,
  cancelExpenses,
} from '../../../actions/ExpensesActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createAdvance,
      updateAdvance,
      fetchExpense,
      payExpenses,
      validateExpenses,
      cancelExpenses,
    },
    dispatch
  );
