import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';

import chequeImage from 'assets/images/cheque-image.png';

const BankBFFChequeGuideline = (props) => {
  const { handleConfirmComplete } = props;
  const { t } = useTranslation();
  return (
    <Box style={{ padding: '0 24px' }}>
      <Typography
        variant="h3"
        style={{ fontSize: '24px', textAlign: 'center' }}
      >
        {t('cheque.begin_title')}
      </Typography>
      <Typography
        variant="body1"
        style={{ fontSize: '14px', textAlign: 'center' }}
      >
        {t('cheque.begin_message')}
      </Typography>

      <Typography
        variant="body1"
        style={{ fontSize: '14px', textAlign: 'center' }}
      >
        {t('cheque.bbf_take_care')}
      </Typography>

      <Typography
        variant="body1"
        style={{ fontSize: '16px', textAlign: 'center', fontWeight: 'bold' }}
      >
        {t('cheque.cheque_checking')}
      </Typography>

      <img
        src={chequeImage}
        alt="cheque image"
        style={{ display: 'block', width: '100%', margin: '10px auto' }}
      />

      <Box style={{ display: 'flex', justifyContent: 'end' }}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleConfirmComplete}
        >
          {t('cheque.confirm_button')}
        </Button>
      </Box>
    </Box>
  );
};

BankBFFChequeGuideline.propTypes = {
  handleConfirmComplete: PropTypes.func,
};

export default BankBFFChequeGuideline;
