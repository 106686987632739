import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from '@material-ui/core';

const style = {
  display: 'flex',
  alignItems: 'center',
  padding: '0 50px',
};
const RadioButton = ({ text, onClick, checked = false, styles, textStyle }) => {
  // transform text to text uppercase first letter
  const textTransform = text.charAt(0).toUpperCase() + text.slice(1);
  return (
    <div style={{ ...style, ...styles }}>
      <Radio
        onClick={() => onClick(text)}
        checked={checked}
        color="primary"
        inputProps={{ 'aria-label': 'secondary checkbox' }}
      />
      {textStyle ? (
        <div style={textStyle || {}}>
          <span onClick={() => onClick(text)}>{textTransform}</span>
        </div>
      ) : (
        <span onClick={() => onClick(text)}>{textTransform}</span>
      )}
    </div>
  );
};

RadioButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  styles: PropTypes.object,
  checked: PropTypes.bool,
  textStyle: PropTypes.object,
};

export default RadioButton;
