import React from 'react';
import PropTypes from 'prop-types';
// import { useTranslation } from 'react-i18next';
import CardCreation from 'components/CardComponents/CardCreation';

const CardRouter = ({ virtual, history, ...props }) => {
  // const { t } = useTranslation();

  const setOpenCardConfig = () => {
    history.goBack();
  };

  return (
    <CardCreation
      virtual={virtual}
      setOpenCardConfig={setOpenCardConfig}
      {...props}
    />
  );
};

CardRouter.propTypes = {
  virtual: PropTypes.boolean,
  history: PropTypes.object,
  props: PropTypes.object,
};

export default CardRouter;
