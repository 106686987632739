import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appProgressBar';

const AppProgressBar = ({ classes, percentage }) => {
  const appProgressBarClass = classNames({
    [classes.appProgressBar]: true,
  });
  const appProgressBarPercentageClass = classNames({
    [classes.appProgressPercentage]: true,
  });

  return (
    <div className={appProgressBarClass}>
      <div
        className={appProgressBarPercentageClass}
        style={{ width: `${percentage}%` }}
      />
    </div>
  );
};

AppProgressBar.propTypes = {
  classes: PropTypes.object.isRequired,
  percentage: PropTypes.number,
};

AppProgressBar.defaultProps = {
  percentage: 0,
};

export default withStyles(style)(AppProgressBar);
