import Header from 'components/Header';
import { useHistory } from 'react-router-dom';
import React from 'react';
import { useTranslation } from 'react-i18next';
import AppPlusSelect from 'components/AppPlusSelect';
import PropTypes from 'prop-types';
import { AppRowCenterStart } from 'components/AppContainers';

const ActivitiesHeader = ({ handleAddActivityRecord }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const types = [
    {
      name: t('settings_section.analytical_fields.create'),
      onClick: () => {
        handleAddActivityRecord();
      },
    },
  ];
  return (
    <div>
      <Header
        name={t('settings_section.analytical_fields.title')}
        spaceBetween
        goBack={() => {
          history.goBack();
        }}
      >
        <AppRowCenterStart />
        <AppPlusSelect
          items={types}
          name="name"
          label={t('settings_section.analytical_fields.create')}
        />
      </Header>
    </div>
  );
};
ActivitiesHeader.propTypes = {
  handleAddActivityRecord: PropTypes.func,
};
export default ActivitiesHeader;
