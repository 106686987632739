import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useStyles = makeStyles(() => ({
  SelectedRowsMessage: {
    fontSize: 14,
    marginLeft: 20,
    fontWeight: 500,
  },
}));

const SelectedRowsMessage = ({ message }) => {
  const classes = useStyles();
  return <p className={classes.SelectedRowsMessage}>{message}</p>;
};

SelectedRowsMessage.propTypes = {
  message: PropTypes.string,
};

export default SelectedRowsMessage;
