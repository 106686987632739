const formStyle = {
  tableHeaderRow: {
    padding: '5px',
    display: 'flex',
  },
  tableHeaderSelect: {
    minWidth: '200px',
    float: 'left',
    marginRight: '20px',
    lineHeight: '100%',
    marginLeft: '10px',
    '& div[class^="MuiSelect-select"]': {
      padding: '10px',
    },
  },
  tableHeaderSelectItem: {},
  tableHeaderActionButton: {
    // minWidth: '150px',
    textAlign: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    float: 'left',
  },
};
export default formStyle;
