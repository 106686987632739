/* eslint-disable no-nested-ternary */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { PAYMENTS_METHODS_KEYS } from 'constants/company';

// import { capitalize } from 'lodash';

import ErrorMessage from 'components/ErrorMessage';
import AppCheckbox from '../../../../../components/AppCheckbox';

const CreateActions = (props) => {
  const {
    rowFlexStart,
    mGreyText,
    marginSM,
    marginMid,
    paymentsError,
    setPaymentsError,
  } = props;
  const companyPaymentsMethods = useSelector(
    (state) =>
      state.loggedUserCompany?.company?.settings?.payments_methods || {}
  );

  const { t } = useTranslation();

  const { isSubmitting, values, setFieldValue } = useFormikContext();

  const { paying_mode: payingMode, delivery_fee: deliveryFee, state } = values;

  function clickChecked(name) {
    const payingModeChecked = Object.keys(payingMode).filter(function (key) {
      return payingMode[key] === true;
    });
    if (payingModeChecked.length === 1 && payingModeChecked[0] === name) {
      return true;
    }
    return false;
  }

  function getPaymentMethodTranslateKey(key) {
    const translatePath = 'settings_section.company_settings.payments_methods.';
    if (key === PAYMENTS_METHODS_KEYS.HI_PAY) {
      return `${translatePath}hi_pay`;
    }
    if (key === PAYMENTS_METHODS_KEYS.BRIDGE_PAYMENT_LINK) {
      return `${translatePath}bridge_payment_link`;
    }
    return `${translatePath}${key}`;
  }

  function togglePaymentsType(e) {
    const { checked, name } = e.target;

    if (name === PAYMENTS_METHODS_KEYS.BRIDGE_PAYMENT_LINK) {
      setFieldValue('isUsePaymentLink', checked);
    }

    if (!clickChecked(name)) {
      const paying = { ...payingMode, [name]: checked };

      // concatenates all the selected types to a string separated with comma
      const text = Object.keys(paying)
        .filter((key) => key !== 'display')
        .filter((key) => paying[key])
        .map((key) => {
          return t(getPaymentMethodTranslateKey(key));
        })
        .join(', ');
      paying.display = text;
      setFieldValue('paying_mode', paying);
      if (checked && paymentsError) {
        setPaymentsError(false);
      }
    }
  }

  function toggleDelivery(e) {
    const { checked } = e.target;

    setFieldValue('delivery_fee', checked ? 0 : null);
  }

  function checkDisabledPaymentsMethod(key) {
    const keys = Object.keys(companyPaymentsMethods);
    if (keys.includes(key)) {
      return companyPaymentsMethods[key];
    }
    return false;
  }

  /**
   * invoice (quotation, order form, delevery form, invoice, credit note): state != 'draft' ~ send to client
   * => disable payment method
   * @returns boolean
   */
  function checkDisabledByState() {
    return state !== 'draft';
  }

  function renderCheckboxes() {
    return Object.keys(payingMode)
      .filter((key) => typeof payingMode[key] === 'boolean')
      .map((key) => {
        return (
          <div className={rowFlexStart} key={key}>
            <AppCheckbox
              checked={payingMode[key]}
              onClick={togglePaymentsType}
              labelText={t(getPaymentMethodTranslateKey(key))}
              labelStyle={{ textAlign: 'left' }}
              name={key}
              id={key}
              disabled={
                !checkDisabledPaymentsMethod(key) || checkDisabledByState()
              }
              isDisableClick={clickChecked(key)}
            />
          </div>
        );
      });
  }

  return (
    <Fragment>
      <div className={rowFlexStart}>
        <AppCheckbox
          checked={deliveryFee !== null}
          onClick={toggleDelivery}
          labelText={t('invoices.delivery')}
          disabled={isSubmitting || checkDisabledByState()}
          name="add_delivery"
          id="add_delivery"
        />
      </div>
      <div className={marginMid} />

      <div className={rowFlexStart}>
        <p className={mGreyText}>{t('invoices.payments')}</p>
      </div>

      {renderCheckboxes()}
      <ErrorMessage
        showError={paymentsError}
        error={t('settings_section.company_settings.payments_method_require')}
      />
      <div className={marginSM} />
    </Fragment>
  );
};

CreateActions.propTypes = {
  separatorStyle: PropTypes.string,
  rowFlexStart: PropTypes.string,
  mGreyText: PropTypes.string,
  marginSM: PropTypes.string,
  marginMid: PropTypes.string,
  paymentsError: PropTypes.bool,
  setPaymentsError: PropTypes.func,
};

export default CreateActions;
