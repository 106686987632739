/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

import subscriptionService from 'services/SubscriptionService';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import { useSelector } from 'react-redux';
import { DISCOVERY_PACKAGE } from 'constants/subscriptions';
import CancelSubscriptionForm from './CancelSubscriptionForm';
import CancelConfirmContent from './CancelConfirmContent';

import BUTTON_COLORS from '../constants';

const useStyles = makeStyles({
  dialogContainer: {
    padding: '0px 24px',
  },
  dialogTitle: {
    padding: '15px 24px',
    fontWeight: 'bold',
  },
  textContent: {
    fontSize: '20px',
    width: '100%',
    marginBottom: '20px',
  },
  button: {
    width: '450px',
    height: '56px',
    borderRadius: '50px',
    backgroundColor: BUTTON_COLORS.GREY,
    color: BUTTON_COLORS.WHITE,
    border: 'none',
    cursor: 'pointer',
    margin: '10px 60px',
    fontSize: '20px',
    inlineSize: 'fit-content',
    padding: '0 20px',
    minWidth: '250px',
    '&:hover': {
      backgroundColor: BUTTON_COLORS.HOVER_GREY,
    },
    '&:disabled': {
      backgroundColor: BUTTON_COLORS.HOVER_GREY,
      cursor: 'default',
    },
  },
  buttonDisabled: {
    backgroundColor: BUTTON_COLORS.GREY,
    '&:hover': {
      backgroundColor: BUTTON_COLORS.HOVER_GREY,
    },
  },
  buttonCancel: {
    backgroundColor: BUTTON_COLORS.BLUE,
    '&:hover': {
      backgroundColor: BUTTON_COLORS.HOVER_BLUE,
    },
  },
  buttonContent: {
    margin: '0 auto',
  },
});

const CancelSubscription = ({ isOpen, onClose, canRefund }) => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [surveyPage, setSurveyPage] = useState(false);
  const [isShowConfirmDialog, setIsShowConfirmDialog] = useState(false);
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const companySubscription = useSelector(
    (state) => state.companySubscription.data
  );

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await subscriptionService.cancelSubscriptionsReason(data);

      // isDeleted = true => cancel subscription
      // isDeleted = false => revert subscription
      await subscriptionService.cancelSubscriptions();
      onClose();
      history.push('/logout');
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleConfirmSubmit = (isSubmit) => {
    if (isSubmit) {
      handleSubmit();
    } else {
      setIsShowConfirmDialog(false);
    }
  };

  const checkButtonDisabled = () => {
    if (surveyPage) {
      return isEmpty(data);
    }
    return false;
  };

  const checkRevertSubscription = () => {
    if (!companySubscription) return false;
    return !surveyPage && companySubscription.name !== DISCOVERY_PACKAGE;
  };

  const handleOnClick = () => {
    if (surveyPage) {
      setIsShowConfirmDialog(true);
    } else {
      setSurveyPage(!surveyPage);
    }
  };

  const hanldeCloseDialog = () => {
    onClose();
  };

  return (
    <div className={classes.dialogContainer}>
      {loading && (
        <LoadingIndicatorDialog title={t('loading_single')} open={true} />
      )}
      <Dialog
        className={classes.dialogContainer}
        open={isOpen}
        // onClose={onClose}
        maxWidth="lg"
      >
        <DialogTitle className={classes.dialogTitle}>
          {t(
            surveyPage
              ? 'settings_section.subscription.confirm_title'
              : 'settings_section.subscription.cancel_questions_title'
          )}
        </DialogTitle>

        <DialogContent>
          {surveyPage ? (
            <CancelSubscriptionForm
              data={data}
              setData={setData}
              isShowConfirmDialog={isShowConfirmDialog}
              handleConfirmSubmit={handleConfirmSubmit}
            />
          ) : (
            <CancelConfirmContent />
          )}
        </DialogContent>
        <DialogActions className={classes.buttonContent}>
          <button
            className={classNames(classes.button, classes.buttonCancel)}
            onClick={hanldeCloseDialog}
          >
            {t('cancel')}
          </button>
          <button
            disabled={checkButtonDisabled()}
            className={classNames(
              classes.button,
              checkButtonDisabled() && classes.buttonDisabled
            )}
            onClick={() => {
              handleOnClick();
            }}
          >
            {t(
              surveyPage
                ? 'settings_section.subscription.confirm_questions'
                : 'settings_section.subscription.confirm_cancel'
            )}
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

CancelSubscription.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  canRefund: PropTypes.bool,
};

export default CancelSubscription;
