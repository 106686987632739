import {
  FETCH_PERSON_USER_TREEZOR,
  FETCH_PERSON_USER_TREEZOR_FAILED,
} from '../../actions/Types';

const INITIAL_STATE = {
  error: null,
  timestamp: null,
  personUserTreezor: {
    users: [{}],
  },
};

export default (state = INITIAL_STATE, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_PERSON_USER_TREEZOR:
      return {
        ...state,
        personUserTreezor: action.payload,
      };
    case FETCH_PERSON_USER_TREEZOR_FAILED:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
