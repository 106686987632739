import { map } from 'lodash';

import {
  Dashboard,
  Expense,
  AdvanceExpenses,
  Invoice,
  Template,
  Items,
  UserManagement,
  UserRoleManagement,
  CompanySettings,
  Clients,
  Projects,
  BookKeeping,
  EmailTemplate,
  Providers,
  Purchase,
  Inventory,
  Bank,
  Card,
  Subscription,
} from '../../../config/Rights';

const makeArray = (obj, name) => {
  return map(obj, (value) => ({
    title: value.replace(name, ''),
    value,
  }));
};

const Rights = [
  {
    name: 'Dashboard',
    rights: makeArray(Dashboard, 'Dashboard'),
  },
  {
    name: 'Invoices',
    rights: makeArray(Invoice, 'Invoice'),
  },
  {
    name: 'Purchase',
    rights: makeArray(Purchase, 'Purchase'),
  },
  {
    name: 'Expense',
    rights: makeArray(Expense, 'Expense'),
  },
  {
    name: 'AdvanceExpenses',
    rights: makeArray(AdvanceExpenses, 'AdvanceExpenses'),
  },
  {
    name: 'Templates',
    rights: makeArray(Template, 'Template'),
  },
  { name: 'EmailTemplate', rights: makeArray(EmailTemplate, 'EmailTemplate') },
  {
    name: 'Items',
    rights: makeArray(Items, 'Items'),
  },
  {
    name: 'Clients',
    rights: makeArray(Clients, 'Clients'),
  },
  {
    name: 'Projects',
    rights: makeArray(Projects, 'Projects'),
  },
  {
    name: 'BookKeeping',
    rights: makeArray(BookKeeping, 'BookKeeping'),
  },
  {
    name: 'UserManagement',
    rights: makeArray(UserManagement, 'UserManagement'),
  },
  {
    name: 'UserRoleManagement',
    rights: makeArray(UserRoleManagement, 'UserRoleManagement'),
  },
  {
    name: 'CompanySettings',
    rights: makeArray(CompanySettings, 'CompanySettings'),
  },
  {
    name: 'Providers',
    rights: makeArray(Providers, 'Providers'),
  },
  {
    name: 'Inventory',
    rights: makeArray(Inventory, 'Inventory'),
  },
  {
    name: 'Bank',
    rights: makeArray(Bank, 'Bank'),
  },
  {
    name: 'Card',
    rights: makeArray(Card, 'Card'),
  },
  {
    name: 'Subscription',
    rights: makeArray(Subscription, 'Subscription'),
  },
];

export default Rights;
