import React, { Fragment } from 'react';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';

import colors from '../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  vSmGreyText: {
    color: colors.grey,
    lineHeight: '12px',
    fontSize: '10px',
    textAlign: 'justify',
  },
  footerBlueContainer: {
    color: colors.backgroundGrey,
    lineHeight: '12px',
    fontSize: '10px',
    height: '55px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

const InvoiceFormFooter = () => {
  const classes = useStyles();

  const company = useSelector((state) => state.loggedUserCompany.company);

  const {
    values: { special_mentions: specialMentions, itemMessage },
  } = useFormikContext();

  return (
    <Fragment>
      <p className={classes.vSmGreyText}>
        <div>{itemMessage}</div>
        {specialMentions &&
          specialMentions.map((mention, index) => (
            <React.Fragment key={index}>
              <br />
              {mention.value}
            </React.Fragment>
          ))}
      </p>
      <div className={classes.footerBlueContainer}>
        {!!company.code_ape && <p>{`Code NAF : ${company.code_ape}`}</p>}
        {!!company.siren && <p>{`N° RCS ${company.siren}`}</p>}
        {!!company.rcs && <p>{`${company.rcs}`}</p>}
        <p>
          {!!company.legal_form && <>{`${company.legal_form}`}</>}
          {!!company.capital && (
            <>{` au capital social de ${company.capital}€`}</>
          )}
        </p>
        {!!company.nic && !!company.siren && (
          <p>{`Siret : ${company.siren}${company.nic}`}</p>
        )}
        {!!company.intracomvat && <p>{`N° TVA : ${company.intracomvat}`}</p>}
      </div>
    </Fragment>
  );
};

export default InvoiceFormFooter;
