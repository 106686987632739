// eslint-disable-next-line import/prefer-default-export
export const selectLoggedUserCompanyId = (state) => {
  const companyId =
    state &&
    typeof state === 'object' &&
    state.loggedUserCompany &&
    state.loggedUserCompany.company != null
      ? state.loggedUserCompany.company._id
      : undefined;
  // logger.debug('companyId', companyId)
  return companyId;
};

export const getSelectedCompany = (state) => {
  const companyId =
    state &&
    typeof state === 'object' &&
    state.loggedUserCompany &&
    state.loggedUserCompany.selectedCompanyId != null
      ? state.loggedUserCompany.selectedCompanyId
      : undefined;

  return companyId;
};
