import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import { withTranslation } from 'react-i18next';

import { omit } from 'lodash';

import TextField from '../../../components/FormElements/textField';
import Template from '../../../components/Template';
import TemplateEditor from '../../../components/Template/Editor';
import Header from '../../../components/Header';
import { AppSepLine } from '../../../components/AppDividers';
import AppButton from '../../../components/AppButton';
import LoadingIndicator from '../../../components/LoadingIndicatorDialog';

import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';

import state from './state';
import dispatch from './dispatch';

import './index.css';

import logo from '../../../assets/images/BBF-header-logo-blueberry.svg';
import Layout from '../../../components/Layout';

class TemplateForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fileUrl: null,
    };

    this._setValues = this._setValues.bind(this);
    this._editorOnCheckChanged = this._editorOnCheckChanged.bind(this);
    this._editorColorChanged = this._editorColorChanged.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const { filename } = this.props.loggedUserCompany.company;
    if (filename) {
      this.props.fetchCompanyLogo(filename, (err, { url }) => {
        const fileUrl = err ? null : url;
        this._changeFile(fileUrl);
      });
    } else {
      this._changeFile(logo);
    }
  }

  _changeFile(val) {
    this.setState({ fileUrl: val });
  }

  _setValues(values) {
    this.props.setValues({ ...this.props.values, ...values });
  }

  _editorOnCheckChanged(name, value) {
    const data = omit(this.props.values, 'display');
    const change = {};
    const obj = data[name.split('_')[1]];

    obj[name.split('_')[0]] = value;
    change[name.split('_')[1]] = obj;

    this._setValues(change);
  }

  _editorColorChanged(color) {
    this._setValues({ color });
  }

  onSubmit(e) {
    const { handleSubmit: formikSubmit, validateForm } = this.props;

    validateForm()
      .then((errors) => {
        if (!errors || (errors && Object.keys(errors).length === 0)) {
          formikSubmit(e);
        }
      })
      // eslint-disable-next-line no-console
      .catch(console.error);
  }

  renderLoading(isSubmitting) {
    const { t } = this.props;
    if (isSubmitting) {
      return <LoadingIndicator title={t('loading_single')} open={true} />;
    }
    return null;
  }

  renderSidebarRight() {
    const { t, isSubmitting, values } = this.props;

    const {
      title,
      invoice,
      contact,
      client,
      reference,
      logo: logoChecked,
      company,
    } = omit(values, 'display');

    return (
      <>
        {this.renderLoading(isSubmitting)}
        <React.Fragment>
          <AppButton
            fullWidth={true}
            isDisabled={isSubmitting}
            text={t('draft')}
            onClick={this.onSubmit}
          />

          <div style={{ marginTop: '30px' }} />

          <AppButton
            fullWidth={true}
            isDisabled={isSubmitting}
            text={t('cancel')}
            color="transparentGrey"
            onClick={() => this.props.history.push('/settings/template')}
          />
        </React.Fragment>

        <AppSepLine />

        <TemplateEditor
          t={t}
          logo={logoChecked}
          company={company}
          title={title}
          invoice={invoice}
          contact={contact}
          client={client}
          reference={reference}
          handleChange={this._editorOnCheckChanged}
          onColorChange={this._editorColorChanged}
        />
      </>
    );
  }

  renderBody() {
    const { t, errors, values, isSubmitting, loggedUserCompany } = this.props;

    return (
      <>
        {errors && errors.internalError && (
          <div className="row">
            <div className="input-field col s12">
              <span className="helper-text">{errors.internalError}</span>
            </div>
          </div>
        )}
        <div className="row">
          <TextField
            type="text"
            name="display"
            label={t('template.form_label_display')}
            placeholder={t('template.form_label_display')}
            disabled={isSubmitting}
            required
            color="secondary"
          />
        </div>

        <div style={{ marginTop: '30px' }} />

        <p
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
          }}
        >
          {this.props.t('templates.helpertext')}
        </p>

        <div style={{ marginTop: '30px' }} />

        <div
          className={`row template-wrapper ${
            isSubmitting ? 'template-wrapper-disabled' : ''
          }`}
        >
          <Template
            company={loggedUserCompany.company}
            data={omit(values, 'display')}
            t={t}
            logo={this.state.fileUrl}
            setValues={this._setValues}
            color={values.color}
            handleChange={this._editorOnCheckChanged}
          />
        </div>
      </>
    );
  }

  render() {
    const { t, history } = this.props;

    return (
      <Form>
        <Layout
          header={
            <Header
              name={t(`template.card_title`)}
              goBack={() => history.push('/settings/template')}
            />
          }
          sidebarLeft={true}
          sidebarRight={this.renderSidebarRight()}
          showUserCard={true}
          body={this.renderBody()}
        />
      </Form>
    );
  }
}

TemplateForm.propTypes = {
  t: PropTypes.func,
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  values: PropTypes.object,
  history: PropTypes.object,
  loggedUserCompany: PropTypes.object,
  setValues: PropTypes.func,
  fetchCompanyLogo: PropTypes.func,
  validateForm: PropTypes.func,
  handleSubmit: PropTypes.func,
};

const TemplateWithForm = withFormik({
  displayName: 'TemplateForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(TemplateForm);

const TranslatedTemplateFormWithStyles = withTranslation()(TemplateWithForm);

export default connect(state, dispatch)(TranslatedTemplateFormWithStyles);
