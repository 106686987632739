import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Invoice from './Invoice';

import './index.css';

class Template extends Component {
  constructor(props) {
    super(props);

    this.state = this.props.data;

    this.handleStop = this.handleStop.bind(this);
    this.overlay = this.overlay.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClickOutside() {
    if (this.edit) {
      this.edit.handleClickOutside();
    }

    this.setState({ overlay: 'none' }, this._setValues);
  }

  handleStop(element, event, data) {
    const change = {};
    const obj = this.state[element];
    // console.log('data', data);
    // console.log('element', element);
    obj.pos = [data.x, data.y];
    change[element] = obj;
    this.setState(change, this._setValues);
  }

  overlay(edit) {
    this.edit = edit;
    this.setState({ overlay: '' }, this._setValues);
  }

  render() {
    const {
      overlay,
      title,
      logo,
      company,
      invoice,
      contact,
      client,
      companyMentionBottom,
      reference,
    } = this.state;

    const { t, handleChange } = this.props;

    return (
      <div id="template">
        <div
          className="overlay"
          style={{ display: overlay }}
          onClick={this.handleClickOutside}
        />

        <div className="wrapper">
          <Invoice
            widthLogo={this.state.width_logo}
            heightLogo={this.state.height_logo}
            logo={this.props.logo}
            logoInfo={logo}
            title={title}
            color={this.props.color}
            company={company}
            invoice={invoice}
            contact={contact}
            client={client}
            reference={reference}
            companyMentionBottom={companyMentionBottom}
            handleStop={this.handleStop}
            overlay={this.overlay}
            handleChange={handleChange}
            userCompany={this.props.company}
            t={t}
          />
        </div>
      </div>
    );
  }
}

Template.propTypes = {
  data: PropTypes.object,
  setValues: PropTypes.func,
  logo: PropTypes.string,
  t: PropTypes.func.isRequired,
  company: PropTypes.object.isRequired,
  color: PropTypes.string,
  handleChange: PropTypes.func,
};

export default Template;
