/* Packages */
import { Box, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CopyContentToClipboard from 'components/CopyContentToClipboard';

/* Styles */
import useStyles from './styles';

function BankBBFAccountInformation(props) {
  const { hide, treezorWalletId } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  const { _id: companyId, name: companyName } = useSelector(
    (state) => state.loggedUserCompany.company
  );

  return (
    <Box className={classes.accountInformationWrapper}>
      <Typography
        variant="h3"
        className={classes.accountInformationText}
        color="primary"
      >
        {t('bank_bbf.your_account')}:{' '}
        {!hide ? (
          <>
            <span className={classes.accountInformationContent}>
              {treezorWalletId}
            </span>
            <CopyContentToClipboard values={treezorWalletId} />
          </>
        ) : (
          '*****'
        )}
      </Typography>
      <Typography
        variant="h3"
        className={classes.accountInformationText}
        color="primary"
      >
        {t('bank_bbf.your_company')}:{' '}
        {!hide ? (
          <>
            <span className={classes.accountInformationContent}>
              {companyName}
            </span>
            <CopyContentToClipboard values={companyName} />
          </>
        ) : (
          '*****'
        )}
      </Typography>
      <Typography
        variant="h3"
        className={classes.accountInformationText}
        color="primary"
      >
        {t('bank_bbf.your_company_id')}:{' '}
        {!hide ? (
          <>
            <span className={classes.accountInformationContent}>
              {companyId}
            </span>
            <CopyContentToClipboard values={companyId} />
          </>
        ) : (
          '*****'
        )}
      </Typography>
    </Box>
  );
}

BankBBFAccountInformation.propTypes = {
  hide: PropTypes.bool,
  treezorWalletId: PropTypes.string,
};

export default BankBBFAccountInformation;
