import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { companyCreateTypes, personalInforType } from '../utils';
import RadioButton from './RadioButton';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    padding: '15px',
    margin: 'auto',
  },
  managementContent: {
    margin: 'auto',
    justifyContent: 'center',
  },
}));

const CompanyType = ({ handleChooseCompanyType, data }) => {
  const classes = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const [checked, setChecked] = React.useState(null);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const getAdditionalInformation = queryParams.get('adf');

    // eslint-disable-next-line radix
    setChecked(parseInt(getAdditionalInformation));
  }, []);

  const handleOnClick = (index) => {
    setChecked(index);
    handleChooseCompanyType(index);
  };
  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      xs={12}
      direction="row"
      className={classes.managementContent}
    >
      {companyCreateTypes.map((item, idx) => {
        return (
          <RadioButton
            checked={checked === idx}
            key={idx}
            text={t(
              `onboarding.personal_infor_type.${data.account_type}.${
                personalInforType[data.account_type][item]
              }`
            )}
            onClick={() => handleOnClick(idx)}
            textStyle={{ fontSize: '25px', cursor: 'pointer' }}
          />
        );
      })}
    </Grid>
  );
};

CompanyType.propTypes = {
  value: PropTypes.string,
  // handleSelected: PropTypes.func,
  styles: PropTypes.object,
  handleChooseCompanyType: PropTypes.func,
  data: PropTypes.object,
};

export default CompanyType;
