import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from '@material-ui/core';

import CustomDialog from '../CustomDialog';

const LoadingDialog = ({ title }) => (
  <CustomDialog title={title}>
    <div className="progress" style={{ textAlign: 'center' }}>
      <CircularProgress />
    </div>
  </CustomDialog>
);

LoadingDialog.propTypes = {
  title: PropTypes.string,
};

export default LoadingDialog;
