import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import { AppTextTitle } from '../../AppText';

import ExpenseOverviewTotals from './ExpenseOverviewTotals';
import ExpensesList from './ExpensesList';

const ExpensesOverview = ({ data, t }) => {
  if (!data) return <></>;
  return (
    <>
      <AppTextTitle>{'Notes de frais'}</AppTextTitle>
      <ExpensesList data={data} t={t} />
      <ExpenseOverviewTotals data={data} t={t} />
    </>
  );
};

ExpensesOverview.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func,
};

export default withTranslation()(ExpensesOverview);
