import { CASH } from 'config/Constants';
import { TYPE_AUTO_ENTREPRENEUR } from 'constants/company';
import { getRcsFromConcatenatdString } from '../../helpers/utils';

export default ({
  name,
  // eslint-disable-next-line camelcase
  business_name,
  address,
  rcs,
  siren,
  nic,
  intracomvat,
  // eslint-disable-next-line camelcase
  code_ape,
  capital,
  // eslint-disable-next-line camelcase
  legal_form,
  // eslint-disable-next-line camelcase
  legal_form_id,
  userName,
  email,
  phonenumber,
  userFile,
  logoFile,
  city,
  zipcode,
  activity,
  bookkeeping,
  isCommercial,
  periodicityReport,
  firstname,
  return_on_debit: returnOnDebit,
  location,
  endFiscalYear,
  // eslint-disable-next-line camelcase
  periodic_VAT_returns,
}) => {
  const [rcsType, rcsWithoutPrefix] = getRcsFromConcatenatdString(rcs || '');

  const signedUser = location.state && location.state.user;
  let registrationEmail = email || '';
  if (signedUser) {
    registrationEmail = signedUser.email;
  }

  return {
    name: name || '',
    // eslint-disable-next-line camelcase
    business_name: business_name || '',
    address: address || '',
    rcs: rcsWithoutPrefix || '',
    rcsType,
    siren: siren || '',
    nic: nic || '',
    intracomvat: intracomvat || '',
    // eslint-disable-next-line camelcase
    code_ape: code_ape || '',
    // eslint-disable-next-line camelcase
    legal_form: legal_form || '',
    // eslint-disable-next-line camelcase
    legal_form_id: legal_form_id || '',
    capital: capital || 0,
    userName: userName || '',
    firstname: firstname || '',
    email: registrationEmail,
    phonenumber: phonenumber || '',
    userFile: userFile || '',
    logoFile: logoFile || '',
    city: city || '',
    zipcode: zipcode || '',
    tva: undefined,
    activity: activity || undefined,
    isCommercial: isCommercial || undefined,
    periodicityReport: periodicityReport || undefined,
    return_on_debit: returnOnDebit || undefined,
    // eslint-disable-next-line camelcase
    periodic_VAT_returns: periodic_VAT_returns || '',
    password: '',
    confirmPassword: '',
    bookkeeping: bookkeeping || CASH,
    endFiscalYear: endFiscalYear || '',
    signedUser: !!signedUser,
    kbiz: '',
    companyType:
      JSON.parse(localStorage.getItem('personalInfo') || '{}')?.companyType ||
      TYPE_AUTO_ENTREPRENEUR,
  };
};
