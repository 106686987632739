import * as yup from 'yup';

/* eslint import/prefer-default-export: off */
export const validationSchema = ({ t }) =>
  yup.object().shape({
    value: yup.string().required(t('forms.mandatory_field')), //
    display: yup.string().required(t('forms.mandatory_field')),
    description: yup.string().required(t('forms.mandatory_field')),
    // account: yup.number().required(t('forms.mandatory_field')),
  });
