/* eslint-disable */
import React, { Fragment, useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Formik as FormikProvider } from 'formik';

import _, { find, isEmpty } from 'lodash';
import LoadingIndicatorDialog from '../../../components/LoadingIndicatorDialog';

import Header from './Header';
import Form from './Form';
import InvoiceInlineSidebar from './Sidebar/inlineSidebar';

import { ClientFormDialog, ItemFormDialog } from './Dialogs';

import { InvoiceFormContextProvider } from '../../../contexts/InvoiceContexts/InvoiceFormContext';

import { useRouter } from '../../../hooks/routerHooks';
import { useThunkDispatch } from '../../../hooks/generalHooks';
import { useCreateDialog } from '../../../hooks/dialogHooks';

import {
  initializeInvoice,
  validateInvoiceForm,
} from '../../../models/invoice';

import { getTemplatesAsArray } from '../../../reducers/TemplateReducer/templateSelectors';
import { INVOICE_TYPE } from 'constants/invoice';

import {
  fetchInvoice,
  createInvoice,
  updateInvoice,
  sendInvoice,
  resendInvoice,
  validateInvoice,
  cancelInvoice,
  clientCancelInvoice,
  payInvoice,
  refundInvoice,
} from '../../../actions/InvoiceActions';
import Layout from '../../../components/Layout';
import invoiceService from 'services/InvoiceService';
import { roundingNumber } from 'utils';
import { fetchCompanySubscription } from 'actions/CompanySubscription';
import { PAYMENT_LINK_ELEMENT } from 'constants/invoice';
import { Grid } from '@material-ui/core';
import Pdf from '../Pdf';
import NumberFormat from 'helpers/NumberFormat';
import { useDecimalCharacter } from 'hooks/decimalCharacterHooks';
import ItemService from 'services/ItemService';
import { fetchItems } from 'actions/ItemsActions';
import { addDays } from 'date-fns';

const InvoicesForm = (props) => {
  const { id: invoiceId } = props.match.params;
  const { tempId, state: locationState } = props.location;
  const tempInvoice = useSelector((state) =>
    find(state.invoicePaginate.docs, (invoice) => invoice._id === tempId)
  );
  const itemsState = useSelector((state) => state.items);

  const dispatch = useThunkDispatch();

  const [error, setError] = useState(null);
  const [paymentsError, setPaymentsError] = useState(null);
  const [formikEnableReinitialize, setFormikEnableReinitialize] =
    useState(false);
  const [showLoadingDialog, setShowLoadingDialog] = useState(false);
  const [isFetchingInvoice, setIsFetchingInvoice] = useState(false);

  const { t } = useTranslation();
  const { history } = useRouter();

  const {
    showDialog: showNewItemDialog,
    toggleDialog: toggleNewItemDialog,
    newItem,
    setNewItem,
    removeNewItem,
  } = useCreateDialog();

  const {
    showDialog: showNewClientDialog,
    toggleDialog: toggleNewClientDialog,
    newItem: newClient,
    setNewItem: setNewClient,
    removeNewItem: removeNewClient,
  } = useCreateDialog();

  const invoices = useSelector((state) => state.invoices);
  const company = useSelector((state) => state.loggedUserCompany.company);
  const templates = useSelector(getTemplatesAsArray);
  const wallet = useSelector((state) => state.wallet);
  const user = useSelector((state) => state.loggedUser);
  const { decimalChar } = useDecimalCharacter();

  const remainingPaymentLink = useSelector(
    (state) => state.companySubscription?.data?.remainingPaymentLink || 0
  );

  const getCompanySubscription = async () => {
    if (!company || !company._id) return;
    dispatch(fetchCompanySubscription());
  };

  useEffect(() => {
    getCompanySubscription();
  }, [company && company._id]);

  function toggleFetchingInvoice() {
    setIsFetchingInvoice((prevValue) => !prevValue);
  }

  function onInvoiceFetched(err) {
    toggleFetchingInvoice();
    setFormikEnableReinitialize(false);
    setError(err && err.message ? err.message : err);
  }

  const createPaymentLink = async (amount = 0, desc = '') => {
    if (!invoiceId) return;

    if (
      invoices &&
      invoices[invoiceId] &&
      amount > 0 &&
      !invoices[invoiceId].paymentLink
    ) {
      const expired = invoices[invoiceId]?.payments[0]?.date
        ? new Date(invoices[invoiceId]?.payments[0]?.date)
        : new Date();

      const res = await invoiceService.createPaymentLink(
        {
          amount: roundingNumber(amount),
          desc,
          invoiceId,
          companyName: company.name,
          expiredDate: addDays(expired, 14),
        },
        user.user._id
      );

      if (res && res.data) {
        const { data } = res.data;
        // eslint-disable-next-line consistent-return
        return data;
      }
    } else {
      // eslint-disable-next-line consistent-return
      return invoices[invoiceId]?.paymentLink;
    }
  };

  const payToPaymentLink = async (paymentsLength) => {
    if (!invoiceId) return;

    const data = await invoiceService
      .createPaymentLinkToUsePaymentLink(
        invoiceId,
        paymentsLength,
        paymentsLength - remainingPaymentLink
      )
      .then((res) => res.data.data);

    return data;
  };

  const checkPayToPaymentLinkStatus = async (paymentLinkId) => {
    if (!paymentLinkId) return false;

    const data = await invoiceService
      .checkPayToPaymentLinkStatus(paymentLinkId)
      .then((res) => res.data.data)
      .then((data) => data.status === 'succeeded')
      .catch(() => false);

    return data;
  };

  function onSubmitted(formikMethods, err, redirectId, redirectToList) {
    setShowLoadingDialog(false);
    const { setSubmitting, setStatus, setFieldValue, action } = formikMethods;

    setSubmitting(false);
    setStatus({ success: !err });

    setFieldValue('action', null);
    setFieldValue('emailInput', null);
    setFieldValue('redirectToList', false);
    if (err) {
      setError(err.message ? err.message : t('forms.internal_error'));
      return;
    }

    switch (action) {
      case 'validate':
      case 'send':
      case 'cancel':
      case 'reject':
      case 'pay':
        history.push('/invoices/list');
        break;
      case 'resend':
        history.push('/invoices/list');
        break;
      default:
        if (redirectToList) {
          history.push(`/invoices/list`);
        }
        if (redirectId) {
          setFormikEnableReinitialize(true);
          history.push(`/invoices/edit/${redirectId}`);
          setFormikEnableReinitialize(false);
        }

        break;
    }
  }

  const fetchInvoiceCb = useCallback(
    () => dispatch(fetchInvoice(invoiceId, onInvoiceFetched)),
    [dispatch, invoiceId]
  );

  useEffect(() => {
    setFormikEnableReinitialize(true);
    if (invoiceId) {
      toggleFetchingInvoice();
      fetchInvoiceCb();
    } else {
      setTimeout(() => {
        setFormikEnableReinitialize(false);
      }, 10);
    }
  }, [fetchInvoiceCb]);

  const initialValues = initializeInvoice(
    company,
    invoices,
    invoiceId,
    tempInvoice,
    locationState,
    templates,
    wallet
  );
  const validationSchema = validateInvoiceForm(t);

  const updateItems = async (currItems, defaultItems) => {
    const notUsedItems = currItems
      .filter((item) => !item?.isUsed)
      .map((item) => item._id);

    const noUsedItemArr = Object.values(_.pick(defaultItems, notUsedItems)).map(
      (item) => ({ ...item, isUsed: true })
    );

    // update item to used
    const promises = noUsedItemArr.map(async (item) => {
      const response = await ItemService.updateItem(item);
      return response;
    });
    await Promise.all(promises);
    dispatch(fetchItems());
  };

  const onSubmit = async (values, rest) => {
    const { paying_mode } = values;
    const { action, emailInput, redirectToList, ...restValues } = values;

    const cb = (err, redirectId) =>
      onSubmitted({ ...rest, action }, err, redirectId, redirectToList);

    if (isEmpty(values.items)) {
      cb();
      return;
    }

    if (
      values.type !== INVOICE_TYPE.CREDIT_NOTE &&
      !paying_mode.bridgePaymentLink &&
      !paying_mode.hiPay &&
      !paying_mode.paypal &&
      !paying_mode.cash &&
      !paying_mode.check &&
      !paying_mode.bankTransfer &&
      !paying_mode.lcr
    ) {
      setPaymentsError(true);
      cb();
      return;
    }
    setShowLoadingDialog(true);

    setError(null);

    if (action === 'draft' && !invoiceId) {
      const { items, payments } = restValues;
      const formatItems = items.map((item) => ({
        ...item,
        unit_price: NumberFormat.getNumber(item.unit_price, decimalChar, ' '),
      }));
      const formatPayments = payments.map((payment) => ({
        ...payment,
        amount: NumberFormat.getNumber(
          payment.amount.toString(),
          decimalChar,
          ' '
        ),
        wasSent: false,
        status: 'pending',
      }));

      await updateItems(formatItems, itemsState);

      return dispatch(
        createInvoice(
          {
            ...restValues,
            items: formatItems,
            payments: formatPayments,
            paymentLinkUsed: 0,
          },
          cb
        )
      );
    }

    if (action === 'draft' && invoiceId) {
      const { items, payments } = restValues;
      const formatPayments = payments.map((payment) => ({
        ...payment,
        amount: NumberFormat.getNumber(
          payment.amount.toString(),
          decimalChar,
          ' '
        ),
        wasSent: payment?.wasSent ? payment?.wasSent : false,
        status: payment?.status ? payment?.status : 'pending',
        paymentLinkUsed: payment?.paymentLinkUsed || 0,
      }));

      await updateItems(items, itemsState);

      return dispatch(
        updateInvoice(
          invoiceId,
          { ...restValues, payments: formatPayments },
          cb
        )
      );
    }

    if (action === 'send' && invoiceId) {
      return dispatch(sendInvoice(invoiceId, restValues, emailInput, cb));
    }
    if (action === 'resend' && invoiceId) {
      return dispatch(resendInvoice(invoiceId, restValues, emailInput, cb));
    }

    if (action === 'validate' && invoiceId) {
      if (
        (restValues?.type === 'invoice' ||
          restValues?.type === 'pre_payment') &&
        restValues?.state === 'draft'
      ) {
        const pendingPayment = restValues?.payments.find(
          (item) => item.status === 'pending'
        );

        const amount =
          (restValues?.type === 'invoice'
            ? pendingPayment?.amount || values?.gross_total
            : values?.duePayableAmount) || 0;

        const element = PAYMENT_LINK_ELEMENT.AFTER_EDIT;

        let emailInputWithPaymentLink = '';
        if (emailInput.includes(element) && restValues.isUsePaymentLink) {
          const paymentLength = restValues?.payments.length;
          if (remainingPaymentLink - paymentLength < 0) {
            // case invoice don't have payment link
            if (!restValues.stripePaymentLink) {
              const paymentLink = await payToPaymentLink(paymentLength);

              restValues.stripePaymentLink = {
                paymentLinkId: paymentLink.paymentLinkId,
                url: paymentLink.url,
                _id: paymentLink._id,
              };

              // open new tab to payment link
              window.open(paymentLink.url, '_blank');

              values.action = 'draft';

              onSubmit(values, rest);

              return dispatch(updateInvoice(invoiceId, restValues, cb));
            }

            // case invoice have payment link but not pay yet

            const isPayToPaymentLink = await checkPayToPaymentLinkStatus(
              restValues.stripePaymentLink._id
            );

            if (!isPayToPaymentLink) {
              // open new tab to payment link
              window.open(restValues.stripePaymentLink.url, '_blank');

              values.action = 'draft';

              onSubmit(values, rest);

              return dispatch(updateInvoice(invoiceId, restValues, cb));
            }
          }

          if (pendingPayment) {
            const paymentLink = await createPaymentLink(
              amount,
              values?.invoice_nb
            );

            const paymentFormat = restValues?.payments.map((payment) => {
              if (payment._id === pendingPayment._id) {
                return {
                  ...payment,
                  paymentLink,
                };
              }
              return payment;
            });

            restValues.payments = paymentFormat;

            const paymentLinkTemplate = `<p><span style="color: rgb(0,85,0);font-size: 15px;font-family: sans-serif; font-weight: 600">Veuillez trouver le lien pour un  paiement direct de votre facture par virement automatique:  <a href="${paymentLink.url}" target="_blank">Cliquez ici</a></span>&nbsp;</p>`;

            // add paymentLinkTemplate at next line of element
            if (values.paying_mode?.bridgePaymentLink)
              emailInputWithPaymentLink = emailInput.replace(
                element,
                `${paymentLinkTemplate}`
              );
          }
        }
        dispatch(
          updateInvoice(invoiceId, {
            ...restValues,
            paymentLinkUsed: restValues?.paymentLinkUsed + 1 || 1,
          })
        );
        return dispatch(
          validateInvoice(invoiceId, restValues, emailInputWithPaymentLink, cb)
        );
      }

      if (
        restValues?.type === 'credit_note' &&
        restValues?.state === 'draft' &&
        restValues.isUsePaymentLink
      ) {
        if (remainingPaymentLink === 0 && !restValues.stripePaymentLink) {
          // case invoice don't have payment link
          const paymentLink = await payToPaymentLink();
          restValues.stripePaymentLink = {
            paymentLinkId: paymentLink.paymentLinkId,
            url: paymentLink.url,
            _id: paymentLink._id,
          };
          // open new tab to payment link
          window.open(paymentLink.url, '_blank');
          values.action = 'draft';
          onSubmit(values, rest);
          return dispatch(updateInvoice(invoiceId, restValues, cb));
        } else if (remainingPaymentLink === 0 && restValues.stripePaymentLink) {
          // case invoice have payment link but not pay yet
          const isPayToPaymentLink = await checkPayToPaymentLinkStatus(
            restValues.stripePaymentLink._id
          );

          if (!isPayToPaymentLink) {
            // open new tab to payment link
            window.open(restValues.stripePaymentLink.url, '_blank');
          } else {
            return dispatch(
              validateInvoice(invoiceId, restValues, emailInput, cb)
            );
          }
        }
      }
      return dispatch(validateInvoice(invoiceId, restValues, emailInput, cb));
    }

    if (action === 'cancel' && invoiceId) {
      return dispatch(cancelInvoice(invoiceId, cb));
    }

    if (action === 'refund' && invoiceId) {
      return dispatch(refundInvoice(invoiceId, cb));
    }

    if (action === 'reject' && invoiceId) {
      return dispatch(
        clientCancelInvoice(invoiceId, values.client._id, null, cb)
      );
    }

    if (action === 'pay' && invoiceId) {
      return dispatch(payInvoice(invoiceId, values.transaction, cb));
    }

    setShowLoadingDialog(false);
    return setError(t('error_action_message'));
  };

  if (isFetchingInvoice) {
    return (
      <div>
        <LoadingIndicatorDialog
          open={isFetchingInvoice}
          title={t('loading_single')}
        />
      </div>
    );
  }

  const renderBody = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6}>
          <Pdf invoiceId={invoiceId} invoices={invoices} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Form error={error} inv={invoices} />
        </Grid>
      </Grid>
    );
  };

  return (
    <Fragment>
      <ClientFormDialog
        showNewClientDialog={showNewClientDialog}
        toggleNewClientDialog={toggleNewClientDialog}
        setNewClient={setNewClient}
      />

      <ItemFormDialog
        showNewItemDialog={showNewItemDialog}
        toggleNewItemDialog={toggleNewItemDialog}
        setNewItem={setNewItem}
      />

      <LoadingIndicatorDialog
        open={showLoadingDialog}
        title={t('invoices.procesing')}
      />

      <FormikProvider
        enableReinitialize={formikEnableReinitialize}
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        displayName="InvoicesForm"
      >
        <InvoiceFormContextProvider
          value={{
            invoiceId,
            setError,
            toggleNewItemDialog,
            toggleNewClientDialog,
            newItem: {
              item: newItem,
              removeNewItem,
            },
            newClient: {
              client: newClient,
              removeNewClient,
            },
          }}
        >
          <Layout
            header={<Header />}
            sidebarLeft
            showUserCard
            sidebarTop={
              <InvoiceInlineSidebar
                inv={invoices}
                paymentsError={paymentsError}
                setPaymentsError={setPaymentsError}
              />
            }
            body={renderBody()}
          />
        </InvoiceFormContextProvider>
      </FormikProvider>
    </Fragment>
  );
};

InvoicesForm.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
};

export default InvoicesForm;
