import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';

import { CASH, COMMITMENT } from 'config/Constants';

import QuestionMarkInformation from 'components/QuestionMarkInformation';
import TextField from '../../FormElements/textField';
import AppCheckbox from '../../AppCheckbox';
import AppRadioGroup from '../../AppRadioGroup';
import AppRadioButton from '../../AppRadioButton';
import { AppRowStartStart } from '../../AppContainers';
import { AppTextTitle, AppTextHeader } from '../../AppText';
import AppError from '../../AppError';
import TextFieldWithAdornment from '../../FormElements/TextFieldWithAdornment';
import { AppGreenDivider } from '../../AppDividers';

import {
  TYPE_AUTO_ENTREPRENEUR,
  TYPE_ENTREPRENEUR_INDIVIDUEL,
  TYPE_SOCIETE,
} from '../../../constants/company';

import ProgressGreen from '../../../assets/images/progress_green.svg';

const AppCompanyVatInfo = ({
  t,
  computeVatNum,
  values,
  setFieldValue,
  errors,
  submitted,
  endFiscalYearEditable,
  stageOne,
  stageTwo,
  isSettings,
}) => {
  const { users } = useSelector((state) => state);
  const types = [
    TYPE_AUTO_ENTREPRENEUR,
    TYPE_ENTREPRENEUR_INDIVIDUEL,
    TYPE_SOCIETE,
  ];

  useEffect(() => {
    if (values.prestation === undefined && values.vente === undefined)
      setFieldValue('prestation', true);
    if (values.tva === undefined) setFieldValue('tva', true);
    setFieldValue('activity', ' ');
  }, [values]);

  const displayCapital = () => {
    return (
      <Grid item xs={12} sm={12} md={6}>
        <TextFieldWithAdornment
          styles={{ margin: '0 auto' }}
          adornmentPosition="end"
          adornmentText="€"
          name="capital"
          label={t('signup.company.capital')}
          placeholder={t('signup.company.capital')}
          color="secondary"
          type="number"
          required
          fullWidth
        />
      </Grid>
    );
  };

  return (
    <div>
      {isEmpty(users) && (
        <>
          <Grid item xs={12} container justify="center">
            <AppTextHeader>{t('signup.company.step3')}</AppTextHeader>
          </Grid>
          {/* Form image stepper */}
          <Grid item xs={12} container justify="center">
            <img
              alt=""
              src={ProgressGreen}
              onClick={stageOne}
              style={{
                cursor: 'pointer',
              }}
            />
            <AppGreenDivider />
            <img
              alt=""
              src={ProgressGreen}
              onClick={stageTwo}
              style={{
                cursor: 'pointer',
              }}
            />
            <AppGreenDivider />
            <img
              alt=""
              src={ProgressGreen}
              style={{
                cursor: 'pointer',
              }}
            />
          </Grid>
        </>
      )}

      {/* Section with VAT apply */}
      <Grid container style={{ marginBottom: '14px' }}>
        <Grid xs={12}>
          <AppTextTitle
            styles={{
              fontSize: '18px',
              fontWeight: '500',
              margin: 'revert',
            }}
          >
            {t('signup.company.tva_company')}
          </AppTextTitle>

          <AppError display={submitted} error={errors.tva} />
        </Grid>
        <Grid xs={12} sm={6} md={4} style={{ margin: 'auto 0' }}>
          <AppRadioGroup
            name="tva"
            valueSelected={
              /* eslint-disable-next-line */
              values.tva === undefined
                ? undefined
                : values.tva === 'true' || values.tva === true
            }
            onChange={(e) => {
              computeVatNum(values.siren, e.target.value);
              if (!e.target.value) {
                setFieldValue('intracomvat', '');
              }
              setFieldValue('tva', e.target.value);
            }}
            color="secondary"
            row
            disabled={false}
            styles={{ width: '100%' }}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div style={{ width: '50%', display: 'flex' }}>
                <AppRadioButton
                  color="secondary"
                  text={t('yes')}
                  value={true}
                  disabled={!isEmpty(users)}
                />
              </div>
              <div style={{ width: '50%', display: 'flex' }}>
                <AppRadioButton
                  color="secondary"
                  text={t('no')}
                  value={false}
                  disabled={!isEmpty(users)}
                />
              </div>
            </div>
          </AppRadioGroup>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          md={4}
          style={{
            display:
              values.tva === 'true' || values.tva === true ? 'block' : 'none',
            height: '56px',
          }}
        >
          <TextField
            type="text"
            name="intracomvat"
            label={t('signup.company.intracomvat')}
            placeholder={t('signup.company.intracomvat')}
            color="secondary"
            required={values.tva}
            disabled={!values.tva || !isEmpty(users)}
          />
          <AppError display={submitted} error={errors.intracomvat} />
        </Grid>
      </Grid>

      {/* Section with PERIODIC VAT RETURNS AND DOMAINE D'ACTIVITÉ */}
      {(values.tva === 'true' || values.tva === true) && (
        <Grid container style={{ marginBottom: '14px' }}>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid
                xs={12}
                style={{ paddingRight: '10px', display: 'flex', gap: 4 }}
              >
                <AppTextTitle
                  styles={{
                    margin: 'revert',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  {t('signup.company.periodic_VAT_returns_company')}
                </AppTextTitle>
                <QuestionMarkInformation
                  title={t(
                    'signup.company.periodic_VAT_returns_company_info_title'
                  )}
                  style={{ marginTop: 16 }}
                  content={
                    <p>
                      {t(
                        'signup.company.periodic_VAT_returns_company_info_description'
                      )}
                    </p>
                  }
                />
              </Grid>
              <Grid xs={12}>
                <AppRadioGroup
                  name="periodic_VAT_returns"
                  valueSelected={
                    /* eslint-disable-next-line */
                    values.periodic_VAT_returns === ''
                      ? 'monthly'
                      : values.periodic_VAT_returns
                  }
                  onChange={(e) => {
                    setFieldValue('periodic_VAT_returns', e.target.value);
                  }}
                  color="secondary"
                  row
                  disabled={false}
                  styles={{ width: '100%' }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                  >
                    <div style={{ width: '25%', display: 'flex' }}>
                      <AppRadioButton
                        color="secondary"
                        text={t('monthly')}
                        value="monthly"
                      />
                    </div>
                    <div style={{ width: '25%', display: 'flex' }}>
                      <AppRadioButton
                        color="secondary"
                        text={t('quaterly')}
                        value="quaterly"
                      />
                    </div>
                    <div style={{ width: '25%', display: 'flex' }}>
                      <AppRadioButton
                        color="secondary"
                        text={t('yearly')}
                        value="yearly"
                      />
                    </div>
                  </div>
                </AppRadioGroup>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <Grid container>
              <Grid xs={12} style={{ paddingRight: '10px' }}>
                <AppTextTitle
                  styles={{
                    margin: 'revert',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  {t('signup.company.activity_company')}
                </AppTextTitle>
              </Grid>
              <Grid xs={12} style={{ display: 'flex' }}>
                <div style={{ display: 'flex' }}>
                  <AppCheckbox
                    checked={values.prestation}
                    onClick={(e) => {
                      setFieldValue('prestation', e.target.checked);
                    }}
                    labelText={t('signup.company.prestation')}
                    id="prestation"
                  />

                  <AppCheckbox
                    checked={values.vente}
                    onClick={(e) => {
                      setFieldValue('vente', e.target.checked);
                    }}
                    labelText={t('signup.company.vente')}
                    id="vente"
                  />
                </div>
              </Grid>
              <AppError display={submitted} error={errors.prestation} />
            </Grid>
          </Grid>
        </Grid>
      )}
      {/* Section with TAX RETURN ON DEBIT */}
      <Grid container style={{ marginBottom: '10px' }}>
        {/* COMPTABILITÉ */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container>
            <Grid item xs={12} style={{ paddingRight: '10px' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <AppTextTitle
                  styles={{
                    margin: 'revert',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  {t('signup.company.bookkeeping')}
                </AppTextTitle>
                <QuestionMarkInformation
                  title={t('signup.company.bookkeeping_info_title')}
                  content={
                    <>
                      <p>
                        {t('signup.company.bookkeeping_info_description_1')}
                      </p>
                      <p>
                        {t('signup.company.bookkeeping_info_description_2')}
                      </p>
                    </>
                  }
                />
              </div>
              <AppError display={submitted} error={errors.bookkeeping} />
            </Grid>
            <Grid item xs={12}>
              <AppRadioGroup
                name="bookkeeping"
                valueSelected={values.bookkeeping}
                onChange={(e) => {
                  setFieldValue('bookkeeping', e.target.value);
                }}
                color="secondary"
                row
                styles={{ width: '100%' }}
              >
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <div style={{ width: '25%', display: 'flex' }}>
                    <AppRadioButton
                      color="secondary"
                      text={t('bookkeeping.cash')}
                      value={CASH}
                    />
                  </div>

                  <div style={{ width: '25%', display: 'flex' }}>
                    <AppRadioButton
                      color="secondary"
                      text={t('bookkeeping.commitment')}
                      value={COMMITMENT}
                    />
                  </div>
                </div>
              </AppRadioGroup>
            </Grid>
          </Grid>
        </Grid>
        {(values.tva === 'true' || values.tva === true) &&
          values.prestation && (
            <Grid item xs={12} sm={12} md={6}>
              <Grid container>
                <Grid item xs={12} style={{ paddingRight: '10px' }}>
                  <div
                    style={{ display: 'flex', gap: 4, alignItems: 'center' }}
                  >
                    <AppTextTitle
                      styles={{
                        margin: 'revert',
                        fontSize: '18px',
                        fontWeight: '500',
                      }}
                    >
                      {t('signup.company.return_on_debit_company')}
                    </AppTextTitle>
                    <QuestionMarkInformation
                      title={t(
                        'signup.company.return_on_debit_company_info_title'
                      )}
                      content={
                        <>
                          <p>
                            {t(
                              'signup.company.return_on_debit_company_info_description_1'
                            )}
                          </p>
                          <p>
                            {t(
                              'signup.company.return_on_debit_company_info_description_2'
                            )}
                          </p>
                          <p>
                            {t(
                              'signup.company.return_on_debit_company_info_description_3'
                            )}
                          </p>
                        </>
                      }
                    />
                  </div>
                  <AppError display={submitted} error={errors.tva} />
                </Grid>
                <Grid item xs={12}>
                  <AppRadioGroup
                    name="return_on_debit"
                    valueSelected={
                      /* eslint-disable-next-line */
                      values.return_on_debit
                        ? 'yes'
                        : values.return_on_debit === undefined
                        ? 'yes'
                        : 'no'
                    }
                    onChange={(e) => {
                      setFieldValue(
                        'return_on_debit',
                        e.target.value === 'yes'
                      );
                    }}
                    color="secondary"
                    row
                    disabled={false}
                    styles={{ width: '100%' }}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                    >
                      <div style={{ width: '25%', display: 'flex' }}>
                        <AppRadioButton
                          color="secondary"
                          text={t('yes')}
                          value="yes"
                        />
                      </div>
                      <div style={{ width: '25%', display: 'flex' }}>
                        <AppRadioButton
                          color="secondary"
                          text={t('no')}
                          value="no"
                        />
                      </div>
                    </div>
                  </AppRadioGroup>
                </Grid>
              </Grid>
            </Grid>
          )}
      </Grid>

      {/* Section with TYPE OF COMPANY */}
      <Grid container>
        <Grid item xs={12} style={{ paddingRight: '10px' }}>
          <Grid>
            <AppTextTitle
              styles={{
                fontSize: '18px',
                fontWeight: '500',
                margin: 'revert',
              }}
            >
              {t('signup.company.type')}
            </AppTextTitle>
          </Grid>
          <AppError display={submitted} error={errors.companyType} />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <AppRadioGroup
            name="companyType"
            valueSelected={values.companyType}
            onChange={(e) => {
              setFieldValue('companyType', e.target.value);

              // if user select option "Entrepreneur individuel" then set micro to values.micro or false for next question
              if (e.target.value === TYPE_ENTREPRENEUR_INDIVIDUEL) {
                setFieldValue('micro', values.micro || false);
              }
            }}
            styles={{ width: '100%' }}
            color="secondary"
            disabled={false}
          >
            <Grid container>
              {types.map((type, index) => (
                <Grid item key={index} xs={12} sm={6} md={4}>
                  <AppRadioButton
                    color="secondary"
                    text={type}
                    value={type}
                    disabled={values.isContractor || isSettings}
                  />
                </Grid>
              ))}
            </Grid>
          </AppRadioGroup>
        </Grid>
        {/* TextField Capital under society */}
        {values.companyType === TYPE_SOCIETE && displayCapital()}
      </Grid>

      <div style={{ marginBottom: '10px' }}></div>
      {/* Section with MICRO */}
      <AppRowStartStart>
        {values.companyType === types[1] && (
          <>
            <Grid xs={12} container>
              <AppRowStartStart>
                <Grid xs={4} style={{ paddingRight: '10px' }}>
                  <AppTextTitle
                    styles={{
                      margin: 'revert',
                      fontSize: '18px',
                      fontWeight: '500',
                    }}
                  >
                    {t('signup.company.ismicro')}
                  </AppTextTitle>
                </Grid>
                <Grid xs={8} style={{ margin: 'auto 0' }}>
                  <AppRadioGroup
                    name="micro"
                    valueSelected={
                      /* eslint-disable-next-line */
                      values.micro
                        ? 'yes2'
                        : values.micro === undefined
                        ? ''
                        : 'no2'
                    }
                    onChange={(e) => {
                      setFieldValue('micro', e.target.value === 'yes2');
                    }}
                    styles={{ width: '100%' }}
                    color="secondary"
                    disabled={false}
                    allColumn
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ width: '25%', display: 'flex' }}>
                        <AppRadioButton
                          color="secondary"
                          text={t('yes')}
                          value="yes2"
                        />
                      </div>

                      <div style={{ width: '25%', display: 'flex' }}>
                        <AppRadioButton
                          color="secondary"
                          text={t('no')}
                          value="no2"
                        />
                      </div>
                    </div>
                  </AppRadioGroup>
                  <AppError display={submitted} error={errors.micro} />
                </Grid>
              </AppRowStartStart>
              <div style={{ marginBottom: '10px' }}></div>
            </Grid>
          </>
        )}
      </AppRowStartStart>
      {/* Section with RATTACHÉ À UN CENTRE DE GESTION and END OF FISCAL YEAR */}
      <Grid xs={12} container>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container>
            <Grid item xs={12}>
              <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
                <AppTextTitle
                  styles={{
                    margin: 'revert',
                    fontSize: '18px',
                    fontWeight: '500',
                  }}
                >
                  {t('signup.company.cga')}
                </AppTextTitle>
                <QuestionMarkInformation
                  title={t('signup.company.cga_info_title')}
                  content={
                    <>
                      <p>{t('signup.company.cga_info_description')}</p>
                    </>
                  }
                />
              </div>
              <AppError display={submitted} error={errors.cga} />
            </Grid>
            <Grid item xs={12}>
              <AppRadioGroup
                name="cga"
                valueSelected={
                  /* eslint-disable-next-line */
                  values.cga ? 'yes1' : values.cga === undefined ? '' : 'no1'
                }
                styles={{ width: '100%' }}
                onChange={(e) => {
                  setFieldValue('cga', e.target.value === 'yes1');
                }}
                color="secondary"
                disabled={false}
              >
                <div style={{ display: 'flex', width: '100%' }}>
                  <div style={{ width: '25%', display: 'flex' }}>
                    <AppRadioButton
                      color="secondary"
                      text={t('yes')}
                      value="yes1"
                    />
                  </div>

                  <div style={{ width: '25%', display: 'flex' }}>
                    <AppRadioButton
                      color="secondary"
                      text={t('no')}
                      value="no1"
                    />
                  </div>
                </div>
              </AppRadioGroup>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Grid container>
            <Grid item xs={12} style={{ display: 'flex', gap: 4 }}>
              <AppTextTitle
                styles={{
                  margin: 'revert',
                  fontSize: '18px',
                  fontWeight: '500',
                }}
              >
                {t('signup.company.end_fiscal_year')}
              </AppTextTitle>
              <QuestionMarkInformation
                title={t('signup.company.end_fiscal_info_title')}
                content={
                  <>
                    <p>{t('signup.company.end_fiscal_info_description')}</p>
                  </>
                }
                style={{ marginTop: 14 }}
              />
            </Grid>
            <Grid item xs={12} style={{ height: '56px' }}>
              <TextField
                type="text"
                name="endFiscalYear"
                // label={t('signup.company.end_fiscal_year')}
                placeholder={t('signup.company.end_fiscal_year')}
                color="secondary"
                required
                disabled={endFiscalYearEditable}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <div style={{ marginBottom: '10px' }}></div>
    </div>
  );
};
AppCompanyVatInfo.propTypes = {
  t: PropTypes.func,
  computeVatNum: PropTypes.func,
  setFieldValue: PropTypes.func,
  values: PropTypes.object,
  isSettings: PropTypes.bool,
  stageOne: PropTypes.func,
  stageTwo: PropTypes.func,
  errors: PropTypes.object,
  submitted: PropTypes.bool,
  endFiscalYearEditable: PropTypes.bool.isRequired,
};

export default AppCompanyVatInfo;
