import {
  FETCHING_TREEZOR_BALANCES,
  FETCH_TREEZOR_BALANCES_SUCCESS,
  FETCH_TREEZOR_BALANCES_FAILED,
} from 'actions/Types';

const initialState = {
  loading: false,
  error: null,
  timestamp: null,
  data: null, // les statements
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_TREEZOR_BALANCES: {
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    }
    case FETCH_TREEZOR_BALANCES_FAILED: {
      const { error } = action.payload;
      return {
        ...state,
        data: null,
        loading: false,
        error,
      };
    }
    case FETCH_TREEZOR_BALANCES_SUCCESS: {
      const { resources } = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        data: resources,
      };
    }

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
};
