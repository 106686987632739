import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form, useFormikContext } from 'formik';
import _ from 'lodash';
import { format } from 'date-fns';

import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

import { INVOICE_STATES } from 'constants/invoice';
import { AppTextContent, AppTextTitle } from 'components/AppText';
import { AppColumnStartCenter } from 'components/AppContainers';
import InvoiceInformation from '../Information';
import InvoiceItems from '../Items';
import InvoicePrepayment from '../PrePayment';
import InvoiceAmounts from '../Amounts';
import InvoicePayments from '../Payments';
import Footer from '../Footer';
import Actions from '../Sidebar/Actions';

import { useInvoiceFormContext } from '../../../../hooks/invoiceHooks';

import { AppDividerM, AppDividerS } from '../../../../components/AppDividers';

import { userCanReadInvoice } from '../../../../selectors/rightsSelector/invoiceRequests';

import colors from '../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  marginMid: {
    marginTop: '20px',
  },
  marginLarge: {
    marginTop: '30px',
  },
  lRedText: {
    color: colors.red,
    lineHeight: '13px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  refusedMessageContent: {
    width: 'fit-content',
    marginBottom: '10px',
    padding: '10px',
    display: 'grid',
    border: '2px solid',
    borderColor: '#f44336',
  },
}));

const bodyStyle = {
  border: 'none',
  boxShadow: 'none',
};

const readOnlyStyle = {
  background: 'white',
  opacity: '0.5',
  pointerEvents: 'none',
};

const InvoiceForm = ({ error, inv }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const {
    values: { state, action, reminders, refuseMessage },
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormikContext();
  const { invoiceId } = useInvoiceFormContext();
  const canReadInvoice = useSelector(userCanReadInvoice);

  const [readOnly, setReadOnly] = useState(false);

  useEffect(() => {
    if (action) {
      handleSubmit();
    }
  }, [action]);

  // if an error occurs during the validation set action to null
  useEffect(() => {
    if (
      Object.values(errors).length &&
      Object.values(touched).length &&
      action
    ) {
      setFieldValue('action', null);
    }
  }, [errors, touched]);

  useEffect(() => {
    const isState = Object.values(INVOICE_STATES).includes(state);

    setReadOnly(isState || (invoiceId && !canReadInvoice));
  }, [state]);

  return (
    <Form className={classes.form}>
      {state === INVOICE_STATES.REJECTED && (
        <div className={classes.refusedMessageContent}>
          <span>
            <b>{t('invoices.refused_title')}</b>
          </span>
          <span>{refuseMessage}</span>
        </div>
      )}
      {readOnly && reminders && reminders.length > 0 && (
        <>
          <AppColumnStartCenter>
            <AppTextTitle>{t('invoices.reminders')}</AppTextTitle>
            {_.map(reminders, (reminder) => (
              <AppTextContent>
                {format(new Date(reminder), 'dd/MM/yyyy')}
              </AppTextContent>
            ))}
          </AppColumnStartCenter>
          <AppDividerS />
        </>
      )}
      <div
        style={{
          ...bodyStyle,
          ...(readOnly ? readOnlyStyle : {}),
        }}
      >
        {error && <p className={classes.lRedText}>{error}</p>}
        <InvoiceInformation />
        <div className={classes.marginLarge} />
        <InvoiceItems />
        <InvoicePrepayment />
        <InvoiceAmounts readOnly={readOnly} />
        <InvoicePayments />
        <Footer />
      </div>
      <AppDividerM />
      <Actions horizontal separatorStyle={classes.marginMid} inv={inv} />
    </Form>
  );
};

InvoiceForm.propTypes = {
  error: PropTypes.string,
  inv: PropTypes.object,
};

export default InvoiceForm;
