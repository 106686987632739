/* eslint-disable-next-line */
export default ({ id, items, importItem }) => {
  if (importItem) {
    return importItem;
  }
  if (id) {
    return items[id];
  }

  return {
    type: 'M',
    name: '',
    description: '',
    vat_rate: '',
    gross_price: '',
    net_price: '',
    unit: '',
  };
};
