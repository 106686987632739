import { inputTextBold } from './appText';

import colors from '../../../constants/colors.json';

const style = {
  greyState: {
    padding: '3px 8px',
    color: '#FFFFFF',
    marginLeft: '10px',
    width: 'fit-content',
    borderRadius: '3px',
    backgroundColor: colors.fillsDarkGrey,
    fontWeight: 'bold',
  },
  redState: {
    padding: '3px 8px',
    color: '#FFFFFF',
    marginLeft: '10px',
    width: 'fit-content',
    borderRadius: '3px',
    backgroundColor: colors.red,
    fontWeight: 'bold',
  },
  yellowState: {
    padding: '3px 8px',
    color: '#FFFFFF',
    marginLeft: '10px',
    width: 'fit-content',
    borderRadius: '3px',
    backgroundColor: colors.warningYellow,
    fontWeight: 'bold',
  },
  greenState: {
    padding: '3px 8px',
    color: '#FFFFFF',
    marginLeft: '10px',
    width: 'fit-content',
    borderRadius: '3px',
    backgroundColor: colors.green,
    fontWeight: 'bold',
  },
  bState: {
    ...inputTextBold,
    color: colors.fillsDarkGrey,
    padding: 0,
    margin: 0,
    marginLeft: '10px',
    fontWeight: 'bold',
    fontSize: '20px',
  },
  text: { ...inputTextBold, margin: 0 },
};

export default style;
