import React from 'react';
import { makeStyles, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { isEmpty } from 'lodash';
import Card from './CardOption';
import RenderCheckBoxes from './RenderCheckBoxes';
import { chunkArray } from '../utils';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  itemsContent: {
    border: 'none',
    borderRadius: '30px',
    margin: '0 auto',
    width: 'auto',
    padding: '30px',
    justifyContent: 'center',
  },
  itemsGroup: {
    displayL: 'grid !important',
  },
  stepper: {
    width: '100%',
    margin: 'auto',
    backgroundColor: 'transparent',
  },
}));

const RenderButtonContent = ({
  handleSelectedActivity,
  questionData,
  chunkSize,
}) => {
  const classes = useStyles();

  const questions = questionData.find(
    (question) => question.question_type === 'button'
  );
  const data = chunkArray(questions.answers, chunkSize);

  const handleSelected = (value, questionIndex) => {
    handleSelectedActivity(value, questionIndex);
  };

  return (
    <Grid
      container
      xs={12}
      spacing={4}
      direction="row"
      className={classes.itemsContent}
    >
      {data.map((tradingCompany) => {
        return (
          <Grid style={{ display: 'grid' }} key={tradingCompany} item xs={3}>
            {tradingCompany.map((value, index) => {
              return (
                <Card
                  key={value}
                  index={index}
                  values={value}
                  handleSelected={handleSelected}
                />
              );
            })}
          </Grid>
        );
      })}
    </Grid>
  );
};

const QuestionsPage = ({
  handleNext,
  data,
  setData,
  questionData,
  personalInfo,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isStepper, setIsStepper] = React.useState(false);
  const chunkSize = () => {
    if (data.account_type === 'liberal_professional') {
      return 3;
    }
    return 5;
  };

  const questionList = questionData.filter(
    (question) => question.question_type !== 'button'
  );
  const questionsButtonType = questionData.filter(
    (question) => question.question_type === 'button'
  );

  const handleSetData = (values) => {
    setData({ ...data, ...values });
  };

  const handleSelected = (value) => {
    handleSetData(value);
    handleNext();
  };

  const handleSelectedActivity = (value) => {
    // get value with translate in FR
    handleSetData({ activity: t(value, { lng: 'fr' }) });
    setIsStepper(true);
  };

  return (
    <>
      {isEmpty(questionsButtonType) || isStepper ? (
        <Grid contaner xs={12}>
          <RenderCheckBoxes
            handleSelected={handleSelected}
            questionData={questionList}
            handleNext={handleNext}
            handleSetData={handleSetData}
            data={data}
            personalInfo={personalInfo}
          />
        </Grid>
      ) : (
        <Grid className={classes.root} direction="row" xs={12} container>
          <Grid xs={12}>
            <RenderButtonContent
              handleSelectedActivity={handleSelectedActivity}
              questionData={questionData}
              data={data}
              chunkSize={chunkSize()}
            />
          </Grid>
        </Grid>
      )}
    </>
  );
};

QuestionsPage.propTypes = {
  handleNext: PropTypes.func,
  data: PropTypes.object,
  personalInfo: PropTypes.func,
  setData: PropTypes.func,
  questionData: PropTypes.object,
};

RenderButtonContent.propTypes = {
  handleSelectedActivity: PropTypes.func,
  questionData: PropTypes.object,
  chunkSize: PropTypes.func,
};

export default QuestionsPage;
