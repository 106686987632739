import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, useTheme } from '@material-ui/core';

import Logo from 'assets/images/BBF-logo-primary.png';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    padding: '40px  10% 0 10%',
    overflowY: 'scroll',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '95px',
    width: '100%',
  },
  welcomeContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  welcomeText: {
    display: 'flex',
    gap: '8px',
  },
  logoContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'center',
    width: '100%',
  },
}));

const NewChat = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { display_name: displayName } = useSelector(
    (state) => state.loggedUser.user
  );
  const { t } = useTranslation();

  return (
    <Box className={classes.container}>
      <Box className={classes.content}>
        <Box className={classes.welcomeContainer}>
          <Box className={classes.welcomeText}>
            <Typography
              variant="h1"
              style={{ color: theme.palette.primary.hover, fontWeight: 600 }}
            >
              {t('agent.onboarding.welcome_1')}
            </Typography>
            <Typography
              variant="h1"
              style={{ color: theme.palette.primary.main, fontWeight: 600 }}
            >
              {displayName}
            </Typography>
          </Box>
          <Typography
            variant="h1"
            style={{ color: theme.palette.neutral[500] }}
          >
            {t('agent.onboarding.welcome_2')}
          </Typography>
        </Box>
        <Box className={classes.logoContainer}>
          <img
            width={270}
            height={150}
            style={{ objectFit: 'contain' }}
            src={Logo}
            alt="logo BBF"
          />
          <Typography variant="h4" color="primary">
            {t('agent.onboarding.caption')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default NewChat;
