import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import MaterialTable from 'components/MaterialTable';
import { useSelector } from 'react-redux';
import { Refresh } from '@material-ui/icons';
import { DEFAULT_PAGESIZE } from 'config/Constants';
import FecService from 'services/FecService';
import DownloadExport from './DownloadExport';

const ExportList = ({ setErrorMessage, tableRef, setLoadingStatus }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGESIZE);
  const { t } = useTranslation();

  const companyId = useSelector((state) => state.loggedUserCompany.company._id);

  const columns = [
    {
      title: t('book_keeping.list.date_exported'),
      field: 'createdAt',
      defaultSort: 'desc',
      render: (value) => {
        return format(
          new Date(value.created_at || value.createdAt || new Date()),
          'dd/MM/yyyy HH:mm'
        );
      },
    },
    {
      title: t('book_keeping.list.download'),
      field: 'filename',
      sorting: false,
      // eslint-disable-next-line react/display-name
      render: (value) => {
        return (
          <DownloadExport
            value={value}
            fileName={value.filename}
            setErrorMessage={setErrorMessage}
          />
        );
      },
    },
  ];

  return (
    <MaterialTable
      tableRef={tableRef}
      data={(query) =>
        FecService.fetchExport(query, companyId)
          .then((res) => ({
            data: res.data.docs,
            totalCount: res.data.totalDocs,
            page: res.data.page - 1,
          }))
          .finally(() => {
            setLoadingStatus((prev) => ({
              ...prev,
              exportList: false,
            }));
          })
      }
      columns={columns}
      onChangeRowsPerPage={(ps) => {
        setPageSize(ps);
      }}
      actions={[
        {
          // eslint-disable-next-line react/display-name
          icon: () => <Refresh />,
          isFreeAction: true,
          onClick: () => {
            if (tableRef && tableRef.current && tableRef.current.onQueryChange)
              tableRef.current.onQueryChange();
          },
        },
      ]}
      options={{
        filtering: false,
        search: false,
        showTitle: false,
        pageSize,
      }}
    />
  );
};

ExportList.propTypes = {
  setErrorMessage: PropTypes.func,
  tableRef: PropTypes.object,
  setLoadingStatus: PropTypes.func,
};

export default ExportList;
