import React from 'react';
import PropTypes from 'prop-types';

import TreezorUserForm from './TreezorUserForm';

const UserFormContextProvider = ({
  countryList,
  personelTitle,
  identityList,
  identityList2,
  company,
  proofOfAddress,
  role,
  goBack,
  selectRole,
  setCreateUser,
  companyCredentials,
}) => {
  return (
    <TreezorUserForm
      countryList={countryList}
      personelTitle={personelTitle}
      identityList={identityList}
      identityList2={identityList2}
      proofOfAddress={proofOfAddress}
      companyCredentials={companyCredentials}
      company={company}
      role={role}
      goBack={goBack}
      selectRole={selectRole}
      setCreateUser={setCreateUser}
    />
  );
};

UserFormContextProvider.propTypes = {
  countryList: PropTypes.array,
  personelTitle: PropTypes.array,
  identityList: PropTypes.array,
  identityList2: PropTypes.array,
  proofOfAddress: PropTypes.array,
  companyCredentials: PropTypes.array,
  company: PropTypes.object,
  role: PropTypes.string,
  goBack: PropTypes.func,
  selectRole: PropTypes.func,
  setCreateUser: PropTypes.func,
};

export default UserFormContextProvider;
