import { bindActionCreators } from 'redux';
import {
  updateCompany,
  fetchCompanyLogo,
  changeBankDetails,
  uploadCompanyFile,
} from '../../actions/CompanyActions';
import { createBankConnector } from '../../actions/BankConnectorActions';
import { fetchInfoGreffe } from '../../actions/infoGreffeActions';

export default (dispatch) =>
  bindActionCreators(
    {
      updateCompany,
      fetchCompanyLogo,
      changeBankDetails,
      fetchInfoGreffe,
      uploadCompanyFile,
      createBankConnector,
    },
    dispatch
  );
