import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

// material-ui/core components
import Tooltip from '@material-ui/core/Tooltip';

// @material-ui/icons
import RestorePageIcon from '@material-ui/icons/RestorePage';

const ResendIconComp = (props) => {
  const { onClick, t } = props;

  return (
    <Tooltip title={t('forms.resend')} placement="top">
      <RestorePageIcon
        onClick={onClick}
        style={{
          width: '0.7em ',
          height: '0.7em',
          cursor: 'pointer',
          color: '#B2B2B2',
        }}
      />
    </Tooltip>
  );
};

ResendIconComp.propTypes = {
  onClick: PropTypes.func.isRequired,

  t: PropTypes.func,
};

const EditIconWithStylesTranslated = withTranslation()(ResendIconComp);

export default EditIconWithStylesTranslated;
