/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import Alert from '@material-ui/lab/Alert';
import { Container, Grid, Hidden, Snackbar } from '@material-ui/core';

import { fetchClientInvoice } from 'actions/InvoiceActions';

import { INVOICE_TYPE } from 'helpers/invoiceHelpers';
import NumberFormat from 'helpers/NumberFormat';

import { style } from 'assets/jss/style';

import logoSideBarS from 'assets/images/BBF-logo-alone-white.svg';
import logoSideBar from 'assets/images/BBF-header-logo-white.svg';

import InvoiceCard from './invoicecard/InvoiceCard';

const { mainSideBarClientPdf, centerContainer } = style;

const PaypalPage = ({ match }) => {
  const dispatch = useDispatch();
  const paypalRef = useRef();
  const { id, clientId, paypalClientId } = match.params;
  const [invoice, setInvoice] = useState(false);
  const [showPaypalButton, setShowPaypalButton] = useState(true);
  const [showSuccessMessage, setShowSuccessMessage] = useState({
    show: false,
    message: '',
    severity: 'success',
  });
  const [disablePaypal, setDisablePaypal] = useState(false);

  function invoiceCB(error, pInvoice) {
    if (pInvoice) {
      setInvoice(pInvoice);

      setTimeout(() => {
        window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              let value = pInvoice.duePayableAmount
                ? pInvoice.duePayableAmount
                : pInvoice.gross_total;
              let { description } = pInvoice;
              if (pInvoice.type === INVOICE_TYPE.PRE_PAYMENT) {
                value = pInvoice.toPay.amount;
                description = pInvoice.toPay.description;
              }
              return actions.order.create({
                intent: 'CAPTURE',
                purchase_units: [
                  {
                    description,
                    amount: {
                      value: NumberFormat.precise(value),
                      currency_code: 'EUR',
                    },
                  },
                ],
              });
            },

            onApprove: async (data, actions) => {
              setDisablePaypal(true);
              // eslint-disable-next-line no-unused-vars
              const order = await actions.order.capture();
              // eslint-disable-next-line no-console
              // console.log('------retour', order);
              // console.log('------data', data);
              if (order.status === 'COMPLETED') {
                setShowPaypalButton(false);
                setShowSuccessMessage({
                  show: true,
                  message: 'Paiement accepté!',
                  severity: 'success',
                });
              } else {
                setShowSuccessMessage({
                  show: true,
                  message:
                    'Une erreur est survenue, si cela persiste veuillez nous contacter',
                  severity: 'error',
                });
                setShowPaypalButton(true);
                setDisablePaypal(false);
              }
            },
            // eslint-disable-next-line no-unused-vars
            onError: (err) => {},
          })
          .render(paypalRef.current);
      }, 3000);
    } else {
      setShowSuccessMessage({
        show: true,
        message:
          'Une erreur est survenue, si cela persiste veuillez nous contacter',
        severity: 'error',
      });
    }
  }

  function handleClose() {
    setShowSuccessMessage({ show: false, message: '', severity: 'success' });
  }

  useEffect(() => {
    const script = document.createElement('script');
    script.src = `https://www.paypal.com/sdk/js?currency=EUR&client-id=${paypalClientId}`;
    script.async = true;
    document.body.appendChild(script);

    dispatch(fetchClientInvoice(id, clientId, invoiceCB));
  }, []);
  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        {/* Left Sidebar, on large screens only - horizontal (see below) on mobile */}
        <Hidden xsDown>
          <Grid item sm={1} md={2}>
            <div style={mainSideBarClientPdf}>
              <div style={centerContainer}>
                <picture>
                  <source srcSet={logoSideBar} media="(min-width: 1024px)" />
                  <img
                    src={logoSideBarS}
                    alt="logo"
                    style={{ width: '100%' }}
                  />
                </picture>
              </div>
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={11} md={10} style={{ marginTop: '2%' }}>
          {invoice && <InvoiceCard invoice={invoice} />}
          {showPaypalButton && (
            <div
              ref={paypalRef}
              style={{
                marginTop: '2%',
                pointerEvents: disablePaypal ? 'none' : '',
                opacity: disablePaypal ? 0.4 : '',
              }}
            ></div>
          )}
        </Grid>
      </Grid>

      <Snackbar
        open={showSuccessMessage.show}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={showSuccessMessage.severity}>
          {showSuccessMessage.message}
        </Alert>
      </Snackbar>
    </Container>
  );
};

PaypalPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      value: PropTypes.number,
    }),
  }),
};

export default PaypalPage;
