import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { Link } from 'react-router-dom';

import { Card, CardContent, Typography } from '@material-ui/core';

import styles from '../../assets/jss/home/card';

class OptionCard extends Component {
  render() {
    const { classes, icon, title, to, disabled, iconElement } = this.props;

    const optionClasses = classNames({
      [classes.cardContainer]: true,
      [classes.disabled]: disabled,
    });

    return (
      <div className={optionClasses}>
        <Card
          className={classes.card}
          button="true"
          component={(props) => (
            <Link
              style={disabled ? { cursor: 'not-allowed' } : {}}
              to={to}
              {...props}
              onClick={(e) => {
                if (disabled) e.preventDefault();
              }}
            />
          )}
        >
          <CardContent className={classes.cardContainer}>
            <div className={classes.CardContent}>
              <div className={classes.CardContentLeft}>
                <div className={classes.cardHeader}>
                  {!iconElement ? (
                    <img alt={title} src={icon} className={classes.icon} />
                  ) : (
                    <div className={classes.icon}>{iconElement}</div>
                  )}
                </div>
              </div>
              <div className={classes.CardContentRight}>
                <Typography
                  variant="h5"
                  component="h2"
                  color="textPrimary"
                  className={classes.cardTitle}
                >
                  {title}
                </Typography>
              </div>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  }
}

const OptionCardWithStyles = withStyles(styles)(OptionCard);

OptionCard.propTypes = {
  classes: PropTypes.object,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  iconElement: PropTypes.node,
  title: PropTypes.string,
  to: PropTypes.any,
  disabled: PropTypes.bool,
};

export default OptionCardWithStyles;
