import React, { useState } from 'react';
import { IconEdit, IconDelete } from 'components/AppIcons';
import { useTranslation } from 'react-i18next';
import SaveIcon from '@material-ui/icons/Save';
import PropTypes from 'prop-types';
import { Tooltip, TextField } from '@material-ui/core';

const ActivityItem = ({ activity, onSave, openDelete }) => {
  const { t } = useTranslation();
  const [isEdit, setIsEdit] = useState(!activity.display);
  const [display, setDisplay] = useState(activity.display);

  return (
    <div className={'activitiesItem'}>
      {isEdit ? (
        <TextField
          style={{ width: 250 }}
          placeholder={t('settings_section.analytical_fields.activity_name')}
          value={display}
          onChange={(e) => setDisplay(e.target.value)}
        />
      ) : (
        <div className={'activitiesItem__title'}>{display}</div>
      )}

      <div className={'actions'}>
        <IconEdit
          onClick={() => {
            setIsEdit(true);
          }}
        />
        <IconDelete
          onClick={() => {
            openDelete(activity);
          }}
        />
        <Tooltip title={t('save')} arrow placement="top-start">
          <button
            className={'saveBtn'}
            onClick={() => {
              setIsEdit(false);
              onSave({ ...activity, display });
            }}
          >
            <SaveIcon className={'saveIcon'} />
          </button>
        </Tooltip>
      </div>
    </div>
  );
};

ActivityItem.propTypes = {
  activity: PropTypes.object,
  onSave: PropTypes.func,
  openDelete: PropTypes.func,
};
export default ActivityItem;
