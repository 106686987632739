import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Redirect } from 'react-router-dom';

import { createRevenue, clearRevenue } from 'actions/RevenueActions';

import FormContainer from './TwoStepForm';

import { prepareData } from './handleSubmit';

const FormikContainer = () => {
  const revenueCreated = useSelector(
    (state) => state.revenues.revenue !== null
  );

  const dispatch = useDispatch();
  const onSubmit = (values) => {
    const formatedData = prepareData(values);
    dispatch(createRevenue(formatedData));
  };

  if (revenueCreated) {
    dispatch(clearRevenue());
    return <Redirect to="/invoices/list" />;
  }
  return <FormContainer onSubmit={onSubmit} />;
};

export default FormikContainer;
