import React from 'react';
import PropsType from 'prop-types';
import { format } from 'date-fns';

import {
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import MaterialTable from '../../components/MaterialTable';

const HistoryChangeViewerModal = (props) => {
  const { selectedHistory, show = false, onClosed } = props;
  const columns = [
    {
      title: 'Revised Budget HT (en Euro)',
      field: 'revisedHT',
      sorting: false,
    },
    {
      title: 'Revised Budget TVA (en Euro)',
      field: 'revisedTVA',
      sorting: false,
    },
    {
      title: 'Revised Budget TTC (en Euro)',
      field: 'revisedTTC',
      sorting: false,
    },
    {
      title: 'Changed Date',
      field: 'updated_at',
      // eslint-disable-next-line camelcase
      render: ({ updated_at }) =>
        format(new Date(updated_at.toString()), 'dd-MM-yyyy HH:mm:ss:aa'),
    },
  ];

  return (
    <Dialog
      modal={false}
      open={show}
      onClose={onClosed}
      maxWidth="sm"
      fullWidth={true}
    >
      <DialogTitle color="primary">
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Typography
            color="primary"
            variant="h6"
            style={{
              fontWeight: 'bold',
              textTransform: 'uppercase',
              display: 'block',
            }}
          >
            History Viewer
          </Typography>
          <CloseIcon onClick={onClosed} color="primary" />
        </div>
      </DialogTitle>
      <DialogContent>
        <MaterialTable
          title="Revised Budgets History"
          columns={columns}
          data={selectedHistory}
        />
      </DialogContent>
    </Dialog>
  );
};

HistoryChangeViewerModal.propTypes = {
  show: PropsType.bool,
  onClosed: PropsType.func,
  selectedHistory: PropsType.array,
};
export default HistoryChangeViewerModal;
