import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import AppButton from 'components/AppButton';
import MenuItem from '@material-ui/core/MenuItem';
import {
  Select,
  FormControl,
  InputLabel,
  Paper,
  Typography,
  Grid,
} from '@material-ui/core';
// import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { getListOfUserWithoutUserAlreadySelected } from 'helpers/TreezorHelper';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '2%',
    textAlign: 'center',
  },
  title: {
    fontSize: '25px',
    color: '#02cbcd',
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: '5%',
  },
  formControl: {
    margin: theme.spacing(1),
    marginTop: '4%',
    minWidth: 350,
    width: '100%',
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const SelectUser = ({ selectUser, addNewUser, users, treezorUsers }) => {
  const classes = useStyles();
  // const { t } = useTranslation();

  useEffect(() => {
    if (
      getListOfUserWithoutUserAlreadySelected(users, treezorUsers).length === 0
    ) {
      addNewUser();
    }
  }, []);

  return (
    <Paper className={classes.paper}>
      <Typography variant="h1" className={classes.title}>
        Sélectionner un actionnaire
      </Typography>
      <Grid container>
        {getListOfUserWithoutUserAlreadySelected(users, treezorUsers).length >
          0 && (
          <Grid item xs={5}>
            <Typography variant="p">
              Sélectionner un actionnaire parmi les utilisateurs de BBF.
            </Typography>
            <FormControl className={classes.formControl}>
              <InputLabel id="userSelection">
                Selectionner un utilisateur
              </InputLabel>
              <Select
                labelId="userSelection"
                onChange={selectUser}
                style={{ width: '100%' }}
              >
                {getListOfUserWithoutUserAlreadySelected(
                  users,
                  treezorUsers
                ).map((u) => {
                  return (
                    <MenuItem value={u} key={u._id}>
                      {u.display_name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item xs={2}>
          <Typography variant="p">OU</Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="p">Créer un nouvel actionnaire.</Typography>
          <AppButton
            text="Créer un nouvel actionnaire"
            style={{ marginTop: '5%' }}
            noBorder
            type="button"
            onClick={addNewUser}
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

SelectUser.propTypes = {
  selectUser: PropTypes.func,
  addNewUser: PropTypes.func,
  users: PropTypes.object,
  treezorUsers: PropTypes.object,
};

export default SelectUser;
