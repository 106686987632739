import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { archiveSplitPDFHistoryFile, getPDFHistory } from 'actions/PDFActions';
import Header from 'components/Header';
import Layout from 'components/Layout';
import MaterialTable from 'components/MaterialTable';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import axios from 'axios';
import { UnArchiveIcon, DownloadIcon } from 'components/AppIcons';
import S3FileService from 'services/S3FileService';

const PDFArchiveBody = ({ originalId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [tableLoading, setTableLoading] = useState(false);
  const [table, setTable] = useState({
    data: [],
    page: 0,
    total: 0,
  });

  useEffect(() => {
    setTableLoading(true);
    const getPDFArchiveFileInDb = async () => {
      const response = await dispatch(
        getPDFHistory(originalId, {
          page: table.page,
          limit: 5,
          isArchived: true,
        })
      );

      setTable({
        ...table,
        data: response.data,
        total: response.totalCount,
      });
    };
    setTableLoading(false);
    getPDFArchiveFileInDb();
  }, [originalId, table.page]);

  const downloadFile = async (fileURL, fileName) => {
    axios.get(fileURL, { responseType: 'blob' }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.addEventListener(
        'focus',
        () => {
          URL.revokeObjectURL(link.href);
        },
        {
          once: true,
        }
      );
      setTableLoading(false);
    });
  };

  const onClickUnArchiveButton = async (splitPDFHistoryId) => {
    setTableLoading(true);
    const response = await dispatch(
      archiveSplitPDFHistoryFile({
        splitPDFHistoryId,
        isArchived: false,
      })
    );

    setTable((prev) => {
      const tableDataCloned = [...prev.data];
      const newTableData = tableDataCloned.filter(
        (d) => d._id !== response.data.data._id
      );
      return {
        ...prev,
        data: newTableData,
      };
    });

    setTableLoading(false);
  };

  const columns = [
    {
      title: t('pdf_purchase.file_name'),
      field: 'splitFileKey',
      sorting: false,
      render: ({ customFileName, splitFileKey }) =>
        customFileName || splitFileKey,
    },
    {
      title: ' ',
      field: 'splitFileKey',
      sorting: false,

      // eslint-disable-next-line
      render: ({ splitFileKey, customFileName, id }) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <DownloadIcon
            sm
            onClick={async () => {
              setTableLoading(true);
              const { url } = await S3FileService.geturl(splitFileKey);
              downloadFile(url, customFileName || splitFileKey);
            }}
          />
          <UnArchiveIcon sm onClick={() => onClickUnArchiveButton(id)} />
        </div>
      ),
      editable: 'never',
    },
  ];

  return (
    <MaterialTable
      title={t('pdf_purchase.archived_files')}
      columns={columns}
      page={table.page}
      onChangePage={(p) => setTable({ ...table, page: p })}
      pageSize={table.total}
      isLoading={tableLoading}
      data={table.data}
      options={{ pageSizeOptions: [] }}
    />
  );
};

const PDFArchive = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { id: originalId } = useParams();

  return (
    <Layout
      header={
        <Header
          name={t('pdf_purchase.archived_files')}
          spaceBetween
          goBack={() => {
            history.goBack();
          }}
        />
      }
      body={<PDFArchiveBody originalId={originalId} />}
      showUserCard={true}
    />
  );
};

PDFArchiveBody.propTypes = {
  originalId: PropTypes.string,
};

export default PDFArchive;
