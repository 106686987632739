import { authInstance as axios } from 'helpers/axiosInterceptor';

const fetchAll = async (filter) =>
  axios.get(`${process.env.REACT_APP_REVENUE_CATEGORIES_URL}/categories`, {
    params: filter,
  });

export default {
  fetchAll,
};
