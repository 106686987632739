import { useState, useCallback } from 'react';

import { validator } from 'utils/validate';
import { xlsxToJson, parseUnitValue } from 'helpers/utils';
import { isNull, isUndefined } from 'lodash';
import { useSelector } from 'react-redux';

export class Item {
  constructor(index, row, isReAssign = false) {
    Item.keys = [
      'type',
      'name',
      'description',
      'vat_rate',
      'unit',
      'net_price',
    ];

    this.index = index;

    // value selected in the table
    this.isSelected = false;
    if (isReAssign) {
      Item.keys.forEach((key) => {
        this[key] = row[key];
      });
      this.isValidated = row.isValidated;
    } else {
      Item.keys.forEach((key, i) => {
        if (key === 'type') {
          this[key] =
            typeof row[i] === 'string' ? row[i].trim().charAt() : row[i];
        } else if (key === 'unit') {
          this[key] = parseUnitValue(row[i]);
        } else if (!(isNull(row[i]) && isUndefined(row[i]))) {
          this[key] = typeof row[i] === 'string' ? row[i].trim() : row[i];
        } else {
          this[key] = '';
        }
      });
    }
  }

  static toJson(data) {
    return data;
  }

  get isValidated() {
    return this._isValidated;
  }

  set isValidated(value) {
    this._isValidated = !!value;
  }

  toggleSelected() {
    this.isSelected = !this.isSelected;
  }

  /**
   * @param {boolean} value
   */
  setSelected(value) {
    this.isSelected = !!value;
  }
}

const useImportItem = () => {
  const [ItemList, setItemList] = useState([]);
  const { tax_registration: taxRegistration } = useSelector(
    (state) => state?.loggedUserCompany?.company?.settings
  );
  const checkValidateData = useCallback((dataInput = [], isSetData = true) => {
    const _validator = {
      unit: [validator.required],
      net_price: [validator.required],
    };
    const dataValidated = [];
    dataInput.forEach((item) => {
      let isValidated = true;
      Object.keys(_validator).forEach((key) => {
        const value = item[key];
        // eslint-disable-next-line no-bitwise
        isValidated &= _validator[key].every((func) => {
          if (func.name === 'validateUnique') {
            return func(value, dataValidated, key);
          }
          return func(value);
        });
      });
      // eslint-disable-next-line no-param-reassign
      item.isValidated = !!isValidated;
      // eslint-disable-next-line no-param-reassign
      if (isValidated) {
        dataValidated.push(item);
      }
    });

    if (isSetData) {
      const dataUpdate = dataInput.map((item) => {
        return new Item(item.index, item, true);
      });
      setItemList(dataUpdate);
    }
  }, []);

  // case VAT apply = false => VAT Rate always = 0%
  const formatItemByVATApply = (items) => {
    if (!taxRegistration?.tva) {
      return items?.map(
        (value, idx) => new Item(idx, { ...value, vat_rate: 0 }, true)
      );
    }
    return items;
  };
  const onFileChange = async (files) => {
    if (
      files &&
      files.length &&
      files[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      const jsonData = await xlsxToJson(files[0]);
      if (!jsonData.length) {
        return [];
      }
      const handleMapKeys = (data) => {
        return data.map((item, idx) => new Item(idx, item));
      };
      const dataResult = formatItemByVATApply(handleMapKeys(jsonData));
      checkValidateData(dataResult, false);
      setItemList(dataResult);
      return dataResult;
    }
    return null;
  };
  return {
    onFileChange,
    Items: { ItemList, setItemList },
    checkValidateData,
  };
};
export default useImportItem;
