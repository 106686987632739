import React from 'react';
import PropTypes from 'prop-types';
import CustomGenericDialog from '../CustomGenericDialog';
import Form from '../../containers/EmailTemplate/Form';

const EmailTemplateFormDialog = ({
  showDialog,
  selectedTemplateId,
  closeTemplateFormDialog,
}) => {
  if (showDialog) {
    return (
      <CustomGenericDialog
        title={'Edit Email Template'}
        iconClose
        closeDialog={closeTemplateFormDialog}
      >
        <Form id={selectedTemplateId} closeDialog={closeTemplateFormDialog} />
      </CustomGenericDialog>
    );
  }

  return null;
};

EmailTemplateFormDialog.propTypes = {
  showDialog: PropTypes.bool,
  selectedTemplateId: PropTypes.string,
  openTemplateFormDialog: PropTypes.func,
  closeTemplateFormDialog: PropTypes.func,
};

export default EmailTemplateFormDialog;
