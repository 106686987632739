/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';

import logger from 'helpers/logger';
import OcrService from 'services/OcrService';
import ConversationService from 'services/ConversationService';
import spinnerService from 'services/SpinnerService';

import Layout from 'components/Layout';
import Header from 'components/Header';
import AppDialog from 'components/AppDialog';
import ChatBox from './components/ChatBox';
import ConversationHistory from './components/ConversationHistory';

const sidebarWidth = 300;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: 'calc(100vh - 124px)',
    marginTop: '16px',
    overflow: 'hidden',
  },
  boxChatContainer: {
    transition: 'margin 0.6s ease-in-out, width 0.6s ease-in-out',
    position: 'relative',
    flexGrow: 1,
  },
}));

function parseMessage(input) {
  const sourcePattern = /\[\$source:\{.*?\}\]/g;

  const sourceMatches = input.match(sourcePattern);

  if (!sourceMatches || sourceMatches.length === 0) {
    return { message: input };
  }

  const sources = sourceMatches.map((match) => {
    const jsonString = match.replace('[$source:', '').replace(']', '');
    return JSON.parse(jsonString);
  });

  const message = input.replace(sourcePattern, '').trim();

  return {
    source: sources,
    message,
  };
}

const AgentIA = () => {
  const { t } = useTranslation();
  const [isHistoryOpen, setIsHistoryOpen] = useState(true);
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const classes = useStyles();
  const { id } = useParams();
  const { _id: userId } = useSelector((state) => state.loggedUser.user);
  const history = useHistory();
  const [messages, setMessages] = useState([]);
  const [histories, setHistories] = useState([]);
  const [page, setPage] = useState(1);

  const navigate = (path) => {
    history.push(path);
  };

  const toggleHistory = () => {
    setIsHistoryOpen(!isHistoryOpen);
  };

  const onSubmit = async (value, { setFieldValue }) => {
    if (!value?.message) return;
    const config = await OcrService.getOrcDimarcConfig();

    // create
    if (!id) {
      const dateTime = Date.now().toString();

      setMessages([
        {
          role: 'user',
          message: value?.message,
          dateTime,
        },
      ]);

      setFieldValue('message', '');
      const res = await ConversationService.requestConservation({
        query: value?.message,
        config,
      });
      const { message, source } = parseMessage(res.data);
      const createData = {
        userId,
        messages: [
          {
            role: 'user',
            message: value?.message,
            dateTime,
          },
          {
            role: 'assistant',
            message,
            dateTime,
          },
        ],
      };
      const resCreate = await ConversationService.createConservation(
        createData
      );

      if (resCreate) {
        const { conversationId } = resCreate;
        navigate(`/agent/chat/${conversationId}`);
      }
    }
    // continue chat
    else {
      const dateTime = Date.now().toString();
      setMessages([
        ...messages,
        {
          role: 'user',
          message: value?.message,
          dateTime,
        },
      ]);
      await ConversationService.pushMessage(id, {
        role: 'user',
        message: value?.message,
        dateTime,
        sources: [],
      });
      setFieldValue('message', '');

      const res = await ConversationService.requestConservation({
        query: value?.message,
        config,
        history: messages,
      });
      if (res) {
        const { message, source = [] } = parseMessage(res.data);
        const newHistories = [
          ...messages,
          {
            role: 'user',
            message: value?.message,
            dateTime,
          },
          {
            role: 'assistant',
            message,
            dateTime,
          },
        ];
        setMessages(newHistories);
        await ConversationService.pushMessage(id, {
          role: 'assistant',
          message,
          dateTime,
          sources: source,
        });
      }
    }
  };

  const getConversationList = async () => {
    try {
      const result = await ConversationService.getConservations(page, 1);
      if (result) {
        const { docs } = result;
        const formatConversations = docs?.map((item) => {
          const { conversation } = item;
          return {
            id: item.conversationId,
            name: conversation.name,
          };
        });
        setHistories(formatConversations);
      }
    } catch (error) {
      logger.log(error);
    }
  };

  const onDelete = async ({ values }) => {
    try {
      spinnerService.startSpinner();
      const { selectedConversation } = values;
      if (!selectedConversation) {
        return;
      }
      await ConversationService.deleteConservation(selectedConversation.id);
      await getConversationList(page);
      setShowDeleteDialog(false);
      navigate('/agent/chat');
    } catch (error) {
      logger.error(error);
    } finally {
      spinnerService.endSpinner();
    }
  };

  const onRenameConversation = async (conversationId, data) => {
    try {
      spinnerService.startSpinner();
      await ConversationService.updateConservation(conversationId, data);
      await getConversationList(page);
    } catch (error) {
      logger.error(error);
    } finally {
      spinnerService.endSpinner();
    }
  };

  const getConservation = async (conversationId) => {
    const result = await ConversationService.getConservationDetail(
      conversationId
    );

    if (result) {
      const { messages: historiesMessage } = result;
      setMessages(historiesMessage);
    }
  };

  const renderBody = () => {
    return (
      <Formik onSubmit={onSubmit} initialValues={{ message: '' }}>
        {({ values }) => (
          <Box className={classes.container}>
            {/* Chat Box */}
            <Box
              className={classes.boxChatContainer}
              style={{
                marginRight: isHistoryOpen ? 0 : -sidebarWidth,
              }}
            >
              <ChatBox data={messages} handleSendMessage={onSubmit} />
            </Box>
            {/* Chat History */}
            <ConversationHistory
              isHistoryOpen={isHistoryOpen}
              toggleHistory={toggleHistory}
              containerWidth={sidebarWidth}
              setShowDeleteDialog={setShowDeleteDialog}
              histories={histories}
              onRenameConversation={onRenameConversation}
            />
            {showDeleteDialog && (
              <AppDialog
                sm
                title={t('agent.confirm_delete_title')}
                color="secondary"
                footer
                contentText={t('agent.confirm_delete_content')}
                onConfirmText={t('delete')}
                onConfirm={() => onDelete({ values })}
                onCancelText={t('cancel')}
                closeDialog={() => {
                  setShowDeleteDialog(false);
                }}
              />
            )}
          </Box>
        )}
      </Formik>
    );
  };

  useEffect(() => {
    getConversationList(page);
  }, [page, id]);

  useEffect(() => {
    if (id) {
      getConservation(id);
    } else {
      setMessages([]);
    }
  }, [id]);

  return (
    <Layout
      header={
        <Header
          headerStyle={{ textTransform: 'none' }}
          name={t('agent.title')}
          spaceBetween
        >
          <div></div>
          <Button
            variant="contained"
            color="primary"
            endIcon={<Add />}
            onClick={() => navigate('/agent/chat')}
          >
            {t('agent.btn_create')}
          </Button>
        </Header>
      }
      body={renderBody()}
      sidebarLeft={true}
      showUserCard={true}
      hasCardBodyStyles={false}
    />
  );
};

AgentIA.propTypes = {};

export default AgentIA;
