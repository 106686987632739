const APP_ROUTING = {
  IMPORT_PROVIDER: '/settings/providers/import',
  IMPORT_CLIENT: '/settings/clients/import',
  IMPORT_COMPANIES: '/settings/clients/import-companies',
  IMPORT_INDIVIDUALS: '/settings/clients/import-individuals',
  ITEM_LIST: '/settings/items',
  IMPORT_ITEM_LIST: '/settings/items/import',
  ONBOARDING: '/onboarding',
  STRIPE_PRICING: '/stripe-pricing',
  SIGNUP: '/signup',
  SIGNUP_CONFIRM: '/signup-confirm',
};
export default APP_ROUTING;
