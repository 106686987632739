import { bindActionCreators } from 'redux';
import { isAuthenticated, renewToken } from '../../actions/AuthActions';
import { changeLanguage } from '../../actions/LanguageActions';
import { fetchCompanyUserData } from '../../actions/LoggedUserActions';

export default (dispatch) =>
  bindActionCreators(
    {
      isAuthenticated,
      changeLanguage,
      renewToken,
      fetchCompanyUserData,
    },
    dispatch
  );
