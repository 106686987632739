import * as yup from 'yup';
import { REGEX } from 'constants/form';

/* eslint import/prefer-default-export: off */
export const validationSchema = ({ t }) =>
  yup.object().shape({
    iban: yup
      .string()
      .max(34, t('settings_section.clients.iban_validation'))
      .min(23, t('forms.iban_minimum'))
      .matches(REGEX.IBAN, t('forms.iban_invalid')),
    bic: yup.string().max(11, t('settings_section.clients.bic_validation')),
  });
