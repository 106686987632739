import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import AppProgressBar from '../AppProgressBar';
import AppButton from '../AppButton';
import AppChecklist from '../AppChecklist';
import { AppTextTitle, AppTextSubTitle } from '../AppText';

import style from '../../assets/jss/components/AppCompleteSignup';

const AppCompleteSignup = ({
  classes,
  defaultPercentage = 0,
  styles,
  onButtonClick,
  title,
  subtitle,
  buttonText,
  checkList,
}) => {
  const AppCompleteSignupTitleWrapperClass = classNames({
    [classes.AppCompleteSignupTitleWrapper]: true,
  });

  const AppCompleteSignupSubtitleClass = classNames({
    [classes.AppCompleteSignupSubtitle]: true,
  });

  const AppCompleteChecklistClass = classNames({
    [classes.AppCompleteChecklist]: true,
  });

  function calculatePercentage() {
    const result = checkList
      .filter(({ checked }) => !checked)
      .reduce((acc, { percentage }) => acc + percentage, defaultPercentage);
    return result;
  }

  return (
    <div style={styles}>
      <div className={AppCompleteSignupTitleWrapperClass}>
        <div>
          <div>
            <AppTextTitle>{title}</AppTextTitle>
          </div>
          <div className={AppCompleteSignupSubtitleClass}>
            <AppTextSubTitle>{`${subtitle} ${calculatePercentage()}%`}</AppTextSubTitle>
          </div>
        </div>

        {calculatePercentage() !== 100 && (
          <div>
            <AppButton
              onClick={onButtonClick}
              fullWidth={true}
              color="secondaryLight"
              text={buttonText}
            />
          </div>
        )}
      </div>
      <div>
        <AppProgressBar percentage={calculatePercentage()} />
      </div>
      <div className={AppCompleteChecklistClass}>
        {checkList.map((item) => (
          <AppChecklist
            key={item.title}
            checked={!item.checked}
            title={item.title}
          />
        ))}
      </div>
    </div>
  );
};
AppCompleteSignup.propTypes = {
  classes: PropTypes.object.isRequired,
  defaultPercentage: PropTypes.number,
  styles: PropTypes.object,
  onButtonClick: PropTypes.func,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  buttonText: PropTypes.string,
  checkList: PropTypes.array,
};

AppCompleteSignup.defaultProps = {
  styles: {},
  onButtonClick: () => {},
  checkList: [],
};

export default withStyles(style)(AppCompleteSignup);
