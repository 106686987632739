// eslint-disable-next-line import/prefer-default-export
export const prepareData = (values, role) => {
  const DEFAULT_COUNTRY = {
    id: '250',
    name_fr: 'France',
    UE: 'True',
    alpha2: 'FR',
    alpha3: 'FRA',
  };
  const data = {
    specifiedUSPerson: values.specifiedUSPerson ? 0 : 1,
    userTypeId: 1,
    _id: values._id,
    title: values.title,
    firstname: values.firstname,
    lastname: values.lastname,
    email: values.userEmail,
    phone: values.userPhone,
    mobile: values.userMobile,
    occupation: role,
    parentType: values.parentType,
    incomeRange: values.incomeRange,
    personalAssets: values.personalAssets,
    birthday: values.birthday,
    placeOfBirth: values.placeOfBirth,
    birthCountry: values.birthCountry,
    nationality: values.nationality,
    taxResidence: values.taxResidence.country ? 'FR' : '',
    formSaved: true,
    userRole: role,
    shouldCreateNewUser: values.shouldCreateNewUser,
    address1: values.company.address,
    postcode: values.company.zipcode,
    city: values.company.city,
    country: DEFAULT_COUNTRY,
  };
  return data;
};
