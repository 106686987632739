import {
  userCanValidateExpense,
  userCanReadExpense,
} from '../../../selectors/rightsSelector/expenseRequests';

export default ({
  expenses,
  activities,
  categories,
  clients,
  clientProjects,
  distanceRates,
  vatRates,
  loggedUser,
  loggedUserCompany,
  ...restState
}) => ({
  expenses,
  activities,
  categories,
  clients,
  clientProjects,
  distanceRates,
  vatRates,
  userCanValidate: userCanValidateExpense(restState),
  userCanReadExpense: userCanReadExpense(restState),
  loggedUser,
  users: restState.users,
  loggedUserCompany,
});
