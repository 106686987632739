import { ROLE } from 'constants/roles';
import RoleHelper from 'helpers/RoleHelper';
import spinnerService from 'services/SpinnerService';

/* eslint-disable object-shorthand */
export default async (
  values,
  { setErrors, setStatus, setSubmitting, props, setFieldValue }
) => {
  const cb = (err) => {
    spinnerService.resetSpinner();
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      setStatus({ success: true });
    }
  };

  const { _id: id } = values;
  if (!id) {
    spinnerService.startSpinner();
    const res = await props.createAdvance(values, cb);

    if (res) {
      const { _id: resId } = res;

      if (!RoleHelper.hasRoles([ROLE.ADMINISTRATEUR])) {
        props.history.push(`/expenses/list`);
      } else if (resId) {
        const { location } = props;

        if (location) {
          const { state } = location;

          if (state) {
            props.history.push({
              pathname: `/expenses/advance/edit/${resId}`,
              state: state,
            });
          } else {
            props.history.push({
              pathname: `/expenses/advance/edit/${resId}`,
            });
          }
        } else {
          props.history.push({
            pathname: `/expenses/advance/edit/${resId}`,
          });
        }
      }
    } else {
      props.history.push(`/expenses/list`);
    }

    return;
  }
  if (values.update && id) {
    spinnerService.startSpinner();
    await props.updateAdvance(values, cb);
    setFieldValue('update', false);
  }
  if (values.validate && id) {
    spinnerService.startSpinner();
    await props.validateExpenses([id], cb);
    setFieldValue('validate', false);
  }
  if (values.reconcile && id) {
    spinnerService.startSpinner();
    await props.payExpenses([values._id], values.transaction, cb);
    setFieldValue('reconcile', false);
  }
  if (values.cancel && id) {
    spinnerService.startSpinner();
    await props.cancelExpenses(
      [{ id, refuseMessage: values?.refuseMessage }],
      cb
    );
    setFieldValue('cancel', false);
  }
};
