/* eslint-disable-next-line */
export default ({
  companyAccessRights,
  match: {
    params: { id },
  },
}) => {
  if (id) {
    const roleFromStore = companyAccessRights.roles.find((r) => r._id === id);
    const { role, rights } = roleFromStore;
    const readOnly = roleFromStore.company_id === 'template_fr';
    return { role, rights, readOnly };
  }
  return { role: '', rights: [], readOnly: false };
};
