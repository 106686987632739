/* eslint-disable react/no-unescaped-entities */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  Grid,
  IconButton,
  Tooltip,
  Table,
  TableBody,
  makeStyles,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import { useFormikContext } from 'formik';

import CurrencyTextField from 'components/FormElements/CurrencyTextField';
import VATLine from 'components/VATLine';
import { KM_CATEGORY_ID } from 'constants/expense';
import _ from 'lodash';

const useStyles = makeStyles({
  root: {
    paddingTop: '0px',
    paddingBottom: '0px',
    borderBottom: 'none',
  },
  vatline: {
    display: 'flex',
    width: '100%',
  },
  vatline__action: {
    marginTop: 6,
  },
});

export default function VATLineGroup({
  readOnly = false,
  noVAT = false,
  defaultTaxes = [],
  vatRates = [],
  numberOfVatLine,
  isPurchaseForm = false,
}) {
  /**
   * The two if statements below are setting the number of Actives Vat line
   * We need it for displaying, adding and deleting Vat Lines
   * It's assuming that if VatIdsArrayFromCategory is empty,
   * the number of active Vat lines is not always 0 (vat rate can be set later)
   */
  let numberOfActiveVats = 1;
  if (defaultTaxes.length === 0) {
    if (noVAT === true) {
      numberOfActiveVats = 0;
    }
  } else {
    numberOfActiveVats = defaultTaxes.length;
  }
  if (numberOfActiveVats > 4) {
    numberOfActiveVats = 4;
  } else if (numberOfActiveVats < 0) {
    numberOfActiveVats = 0;
  }

  // Should only be true in read only mode --> this is to facilitate insertion of values to vatline group :
  // 1 set values in formik with amount0, amount1, vat0, vatrate0, etc.
  // 2 set numberOfVatLine
  if (
    numberOfVatLine !== undefined &&
    readOnly === true &&
    defaultTaxes.length === 0
  ) {
    numberOfActiveVats = numberOfVatLine;
  }

  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const [numberActiveVatLines, setnumberActiveVatLines] =
    useState(numberOfActiveVats);

  // Set Variables that activates display of Vat Lines
  const tempArrayShowVAT = [false, false, false, false];
  // eslint-disable-next-line no-plusplus
  for (let index = 0; index < numberOfActiveVats; index++) {
    tempArrayShowVAT[index] = true;
  }
  const [showVat, setshowVat] = useState(tempArrayShowVAT);

  // If WithoutVat is True We have no visible vat line
  useEffect(() => {
    if (noVAT) {
      setnumberActiveVatLines(0);
      setFieldValue('numberOfActiveVats', 0);
      setshowVat([false, false, false, false]);
    }
    setFieldValue('numberOfActiveVats', numberOfActiveVats);
  }, []);

  const calculateTotals = () => {
    if (readOnly) return;
    setFieldValue(
      'vat',
      (
        parseFloat(values.vat0) +
        parseFloat(values.vat1) +
        parseFloat(values.vat2) +
        parseFloat(values.vat3)
      ).toFixed(2)
    );
    const amount = (
      parseFloat(values.amount0) +
      parseFloat(values.amount1) +
      parseFloat(values.amount2) +
      parseFloat(values.amount3)
    ).toFixed(2);
    setFieldValue('taxBasisTotalAmount', amount);
    // TODO : ugly but for now triplicates
    const grandTotalAmount = (
      parseFloat(values.total0) +
      parseFloat(values.total1) +
      parseFloat(values.total2) +
      parseFloat(values.total3)
    ).toFixed(2);

    setFieldValue('grandTotalAmount', grandTotalAmount);
    setFieldValue('duePayableAmount', parseFloat(grandTotalAmount));
    setFieldValue('taxTotalAmount', (grandTotalAmount - amount).toFixed(2));
    setFieldValue('totalForm', grandTotalAmount);
  };

  useEffect(() => {
    const number = values?.import_numberOfActiveVats;
    if (number) {
      const shows = _.fill(Array(4), false);
      const changeShows = shows.map((item, index) =>
        // eslint-disable-next-line no-unneeded-ternary
        index < number ? true : false
      );
      setshowVat(changeShows);
      setnumberActiveVatLines(number);
    }
  }, [values?.import_numberOfActiveVats]);

  // Effect to calculate Form Grand Total, Vat Total and Amount Total
  const timeOut = useRef(null);

  useEffect(() => {
    clearTimeout(timeOut.current);
    timeOut.current = setTimeout(() => {
      if (!values?.firstScan) {
        calculateTotals();
      }
      setFieldValue('updateOfTotalDone', true);
    }, 50);
  }, [values.updateOfTotalDone]);

  // Utils function to set back Values of Vat Line to 0 in Formik on Delete
  const setFormikValues = (UpdatedValues, i) => {
    setFieldValue(`amount${i}`, parseFloat(UpdatedValues.amount));
    setFieldValue(`vatrate${i}`, parseFloat(UpdatedValues.vat_rate));
    setFieldValue(`vat${i}`, parseFloat(UpdatedValues.vat));
    setFieldValue(`total${i}`, parseFloat(UpdatedValues.total));
  };

  const onDelete = (i) => {
    const newShowVat = [...showVat];
    newShowVat[i] = false;
    setshowVat(newShowVat);
    setnumberActiveVatLines(numberActiveVatLines - 1);
    setFieldValue('numberOfActiveVats', numberActiveVatLines - 1);
    setFormikValues(
      {
        amount: 0,
        vat_rate: 0,
        vat: 0,
        total: 0,
      },
      i
    );
    setFieldValue('updateOfTotalDone', false);
  };

  const onAdd = (i) => {
    const newShowVat = [...showVat];
    newShowVat[i] = true;
    setshowVat(newShowVat);
    setnumberActiveVatLines(numberActiveVatLines + 1);
    setFieldValue('numberOfActiveVats', numberActiveVatLines + 1);
  };

  const vatrateIdObj = defaultTaxes.reduce((acc, tax, index) => {
    const vatrateKey = `vatrate${index}id`;
    const vatrateValue = values[`vatrate${index}`];
    if (tax && tax.id) {
      acc[vatrateKey] = tax.id;
    } else if (vatrateValue) {
      for (let i = 0; i < vatRates.length; i += 1) {
        const el = vatRates[i];
        if (el.value === vatrateValue) {
          acc[vatrateKey] = el.id;
        }
      }
    }
    return acc;
  }, {});

  const classes = useStyles();

  const isDistanceCategorySelected = values.category_id === KM_CATEGORY_ID;

  const renderNoVatrateMessage = (value) => {
    return (
      <p style={{ marginTop: -8, color: 'red' }}>
        {t('expenses.expense.form_vatrate_no_exist.First')} {value}{' '}
        {t('expenses.expense.form_vatrate_no_exist.Second')}
      </p>
    );
  };

  const renderTotalVAT = () => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '8px',
          }}
        >
          <p className={classes.root} style={{ width: '30%' }}>
            {t('expenses.expense.form.total_net')}
          </p>
          <div className={classes.root}>
            <CurrencyTextField
              style={{ margin: 0 }}
              name={'taxBasisTotalAmount'}
              value={parseFloat(values.taxBasisTotalAmount).toFixed(2)}
              placeholder={'0,00'}
              disabled={true}
              required
            />
          </div>
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '8px',
          }}
        >
          <p className={classes.root} style={{ width: '30%' }}>
            {t('expenses.expense.form.vat_amount')}
          </p>
          <div className={classes.root}>
            <CurrencyTextField
              style={{ margin: 0 }}
              name={'taxTotalAmount'}
              value={parseFloat(values.taxTotalAmount).toFixed(2)}
              placeholder={'0,00'}
              disabled={true}
              required
            />
          </div>
        </div>
      </div>
    );
  };

  const isExistInVatrates = (value) => {
    return vatRates.map((vat) => vat.value).includes(value) || value === 0;
  };

  const renderVatLine = () => {
    return (
      <>
        {showVat?.length > 0 &&
          showVat.map((item, index) => {
            let amount = values[`amount${index}`];
            let vat = values[`vat${index}`];
            const vatrate = values[`vatrate${index}`];
            let total = values[`total${index}`];

            if (!isExistInVatrates(vatrate)) {
              amount = 0;
              vat = 0;
              total = 0;
            }
            return item ? (
              <div className={classes.vatline} key={index}>
                <Grid item xs={readOnly ? 12 : 10}>
                  <VATLine
                    readOnly={readOnly}
                    vatRates={vatRates}
                    selectedVatRate={vatrateIdObj[`vatrate${index}id`]}
                    amount_name={`amount${index}`}
                    vat_name={`vat${index}`}
                    vatrate_name={`vatrate${index}`}
                    total_name={`total${index}`}
                    amount={amount}
                    vat={vat}
                    vat_rate={vatrate}
                    total={total}
                    key={index}
                    index={index}
                    isPurchaseForm={isPurchaseForm}
                  />
                  {!isExistInVatrates(vatrate) &&
                    renderNoVatrateMessage(vatrate)}
                </Grid>
                {readOnly ? null : (
                  <Grid item xs={2}>
                    {numberActiveVatLines - 1 === index ? (
                      <div className={classes.vatline__action}>
                        {index > 0 && (
                          <Tooltip
                            title={t('delete_this_vat_line')}
                            placement="top"
                          >
                            <IconButton
                              color="primary"
                              aria-label="delete VAT Line"
                              onClick={() => {
                                onDelete(index);
                              }}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {index !== showVat.length - 1 && (
                          <Tooltip title={t('add_vat_line')} placement="top">
                            <IconButton
                              color="primary"
                              aria-label="add Vat Line"
                              onClick={() => {
                                onAdd(index + 1);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    ) : null}
                  </Grid>
                )}
              </div>
            ) : null;
          })}
      </>
    );
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container justifyContent="flex-end" alignItems="flex-end">
          {!isDistanceCategorySelected && renderVatLine()}
          {noVAT ? (
            ''
          ) : (
            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item xs={readOnly ? 12 : 10}>
                <Table aria-label="total details">
                  <TableBody>
                    {!isDistanceCategorySelected && renderTotalVAT()}

                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        padding: '8px',
                      }}
                    >
                      <p style={{ width: '30%' }} className={classes.root}>
                        {t('expenses.expense.form.total_gross')}
                      </p>
                      <div className={classes.root}>
                        <CurrencyTextField
                          style={{ margin: 0 }}
                          name={'grandTotalAmount'}
                          value={parseFloat(values.grandTotalAmount).toFixed(2)}
                          placeholder={'0,00'}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </TableBody>
                </Table>
              </Grid>
              <Grid item xs={readOnly ? 0 : 2}></Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
}

VATLineGroup.propTypes = {
  readOnly: PropTypes.bool,
  noVAT: PropTypes.bool,
  vatRates: PropTypes.array,
  defaultTaxes: PropTypes.array,
  numberOfVatLine: PropTypes.number,
  isPurchaseForm: PropTypes.bool,
};
