import React from 'react';
import PropTypes from 'prop-types';
import placeholder from '../../assets/images/avatarPlaceholder.png';

const Avatar = ({ image, width }) => (
  <>
    <img
      width={width || 45}
      height={width || 45}
      style={{ borderRadius: 50 }}
      src={image || placeholder}
      alt="profil"
    />
  </>
);

Avatar.propTypes = {
  image: PropTypes.string,
  width: PropTypes.number,
};

export default Avatar;
