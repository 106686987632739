/* eslint-disable no-unused-vars */
// amount, vat_rate, vat, total
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@material-ui/core';
import CurrencyTextField from 'components/FormElements/CurrencyTextField';

import { noVAT } from 'helpers/VATRateHelpers';
import logger from 'helpers/logger';

import '../../assets/css/deactivateUpDownArrowInputNumber.css';

export default function VATline({
  readOnly,
  selectedVatRate,
  vatRates = [],
  index,
  // eslint-disable-next-line camelcase
  amount_name,
  amount: propsAmount = 0,
  // eslint-disable-next-line camelcase
  vat_name,
  vat: propsvat = 0,
  // eslint-disable-next-line camelcase
  vatrate_name,
  vatrate: propsvatrate = 0,
  // eslint-disable-next-line camelcase
  total_name,
  total: propstotal = 0,
}) {
  const { setFieldValue, values } = useFormikContext();
  const { t } = useTranslation();
  const alreadySelectedVat = selectedVatRate !== undefined;

  // We check in the useeffect below if the preselected Vat Rate is valid in this case we update the value for the autocomplete to display
  // Beware that this is only a display string and the actual value of the vat could already be set through props
  useEffect(() => {
    if (alreadySelectedVat) {
      if (typeof selectedVatRate !== 'string') {
        logger.error(
          `selectedVatRate must be of type string. Received selectedVatRate = ${selectedVatRate} of type ${typeof selectedVatRate}`
        );
      } else if (selectedVatRate.length !== 24) {
        logger.error(
          `selectedVatRate must a valid id for the Vat Rate array in Redux state. Received selectedVatRate = ${selectedVatRate} with length ${selectedVatRate.length} which sould be 24`
        );
      } else {
        const tmp = vatRates.filter(
          (vatRate) => vatRate._id === selectedVatRate
        );
        if (tmp && tmp.length) {
          // selectedVatRateDisplay = tmp[0].display;
          setFieldValue(vatrate_name, tmp[0].value);
        } else {
          logger.error(
            'A string that did not evaluate to a valid id was passed as a VAT RATE id. received ',
            selectedVatRate,
            'pand expected something like 5b71a039550815b29a98ea75'
          );
        }
      }
    }
  }, []);

  const [amountValue, setAmountValue] = useState(
    !propsAmount ? 0 : parseFloat(propsAmount).toFixed(2)
  );

  const formatInputValue = (val) => {
    const value = val?.toString()?.replace(',', '.');
    if (!value) {
      return 0;
    }
    if (value?.endsWith('.')) {
      return `${value}`;
    }
    return parseFloat(value);
  };

  const formatDisplayValue = (val) => {
    return val.toString().replace('.', ',');
  };

  const setFormikValues = (UpdatedValues) => {
    setFieldValue(amount_name, UpdatedValues.amount);
    setFieldValue(vatrate_name, parseFloat(UpdatedValues.vat_rate));
    setFieldValue(vat_name, UpdatedValues.vat);
    setFieldValue(total_name, UpdatedValues.total);
  };

  const onAmountChanged = ({ target: { value: val } }) => {
    const value = formatInputValue(val);
    // logger.log('amount is set to : ', value);
    setAmountValue(value);
    // this boolean is required as a listener to know when to update total in VATlineGroup
    setFieldValue('updateOfTotalDone', false);
    const curVatrate = values[`vatrate${index}`];

    // calculate new total and vat value from vat rate and value(amount)
    if (value === 0) {
      setFormikValues({
        amount: value,
        vat_rate: curVatrate,
        vat: 0,
        total: 0,
      });
    } else if (curVatrate === 0) {
      setFormikValues({
        amount: value,
        vat_rate: curVatrate,
        vat: 0,
        total: value,
      });
    } else {
      const newVatValue = ((value * curVatrate) / 100).toFixed(2);
      setFormikValues({
        amount: value,
        vat_rate: curVatrate,
        vat: newVatValue,
        total: (parseFloat(newVatValue) + parseFloat(value)).toFixed(2),
      });
    }
  };

  const onTotalChanged = ({ target: { value: val } }) => {
    const value = formatInputValue(val);

    const curVatrate = values[`vatrate${index}`];
    const newAmount = (value / (1 + curVatrate / 100)).toFixed(2);
    setAmountValue(newAmount);
    setFormikValues({
      amount: newAmount,
      vat_rate: curVatrate,
      vat: ((newAmount * curVatrate) / 100).toFixed(2),
      total: value,
    });
    setFieldValue('updateOfTotalDone', false);
  };

  const onVatRateChanged = (event) => {
    const e = event.target.value;
    const value = parseFloat(e);
    const amount = values[`amount${index}`];
    // this boolean is required as a listener to know when to update total in VATlineGroup
    setFieldValue('updateOfTotalDone', false);

    // calculate new total and vat value from value(vatrate) and amount
    if (value === 0) {
      setFormikValues({
        amount,
        vat_rate: value,
        vat: 0,
        total: amount,
      });
    } else if (amount === 0) {
      setFormikValues({
        amount,
        vat_rate: value,
        vat: 0,
        total: 0,
      });
    } else {
      const newVatValue = ((amount * value) / 100).toFixed(2);

      setFormikValues({
        amount,
        vat_rate: value,
        vat: newVatValue,
        total: (parseFloat(newVatValue) + parseFloat(amount)).toFixed(2),
      });
    }
  };

  return (
    <div>
      <Grid container spacing={3} justify="center" alignItems="baseline">
        <Grid item xs={6} sm={3}>
          <CurrencyTextField
            className="alignRight"
            adornmentPosition="end"
            adornmentText={'€'}
            type="text"
            step="0.01"
            name={`amount${index}`}
            label={t('expenses.expense.form_amount_0')}
            placeholder={t('expenses.expense.form_amount_0')}
            value={formatDisplayValue(propsAmount)}
            onChange={onAmountChanged}
            disabled={readOnly ? true : noVAT(values[`vatrate${index}`])}
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <FormControl variant="outlined" margin="dense" fullWidth>
            <InputLabel htmlFor="outlined-vat_rate-native-simple">
              {t('expenses.expense.form_vatrate_0')}
            </InputLabel>
            <Select
              labelId={t('expenses.expense.form_vatrate_0')}
              label={t('expenses.expense.form_vatrate_0')}
              name={`vat_rate${index}`}
              value={values[`vatrate${index}`]}
              onChange={onVatRateChanged}
              autoWidth={true}
              disabled={readOnly ? true : vatRates.length === 0}
            >
              {vatRates.map((vatObject, i) => {
                return (
                  <MenuItem key={i} value={vatObject.value}>
                    {vatObject.display}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={3}>
          <CurrencyTextField
            className="alignRight"
            adornmentPosition="end"
            adornmentText={'€'}
            type="text"
            name={`vat${index}`}
            label={t('expenses.expense.form_vat_0')}
            placeholder={t('expenses.expense.form_vat_0')}
            onChange={() => {}}
            value={formatDisplayValue(propsvat)}
            variant="outlined"
            disabled
            fullWidth
          />
        </Grid>
        <Grid item xs={6} sm={3}>
          <CurrencyTextField
            className="alignRight"
            adornmentPosition="end"
            adornmentText={'€'}
            type="text"
            step="0.01"
            name={`total${index}`}
            label={t('expenses.expense.form_total_0')}
            placeholder={t('expenses.expense.form_total_0')}
            onChange={onTotalChanged}
            value={formatDisplayValue(propstotal)}
            disabled={readOnly ? true : vatRates?.length === 0}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
}

VATline.propTypes = {
  readOnly: PropTypes.bool,
  amount_name: PropTypes.string,
  vat_name: PropTypes.string,
  vatrate_name: PropTypes.string,
  total_name: PropTypes.string,
  amount: PropTypes.number,
  vat: PropTypes.number,
  vatrate: PropTypes.number,
  total: PropTypes.number,
  selectedVatRate: PropTypes.string,
  vatRates: PropTypes.array,
  index: PropTypes.number,
};
