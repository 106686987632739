import { UserManagement as Rights } from '../../config/Rights';
import { UserManagement as Feature } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreateUserManagement = () => {
  return hasRequestedAccess(Rights.UserManagementUserCreate);
};

export const userCanUpdateUserManagement = () => {
  return hasRequestedAccess(Rights.UserManagementUpdate);
};

export const userCanDeleteUserManagement = () => {
  return hasRequestedAccess(Rights.UserManagementDelete);
};

export const userHasUserManagementAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
