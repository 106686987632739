// import { setGlobalError } from 'actions/GlobalError';
import {
  FETCH_COMPANY_SUBSCRIPTION,
  FETCH_COMPANY_SUBSCRIPTION_START,
} from 'actions/Types';
import subscriptionService from 'services/SubscriptionService';
import {
  getSelectedCompany,
  selectLoggedUserCompanyId,
} from '../../selectors/loggedUserCompanySelectors';

// eslint-disable-next-line import/prefer-default-export
export const fetchCompanySubscription = () => async (dispatch, getState) => {
  try {
    const state = getState();
    const companyId =
      selectLoggedUserCompanyId(state) || getSelectedCompany(state);

    if (!companyId) {
      dispatch({
        type: FETCH_COMPANY_SUBSCRIPTION,
        payload: {},
      });
      return;
    }

    dispatch({
      type: FETCH_COMPANY_SUBSCRIPTION_START,
      payload: {},
    });

    const res = await subscriptionService.getSubscription();

    if (res?.data) {
      const { data } = res;
      data.remainingPaymentLink =
        data.remainingPaymentLink < 0 ? 0 : data.remainingPaymentLink;
    }

    dispatch({
      type: FETCH_COMPANY_SUBSCRIPTION,
      payload: res.data,
    });

    return;
  } catch (error) {
    // dispatch(setGlobalError(error));
    dispatch({ type: FETCH_COMPANY_SUBSCRIPTION, payload: {} });
  }
};
