import React from 'react';
import PropTypes from 'prop-types';
import AppButton from 'components/AppButton';
import {
  Paper,
  Typography,
  Card,
  CardHeader,
  Avatar,
  Grid,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import { ASSOCIE } from 'constants/roles';

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 345,
    margin: 'auto',
    marginTop: '2%',
    marginBottom: '2%',
  },
  paper: {
    padding: '2%',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#3D319D',
  },
  title: {
    fontSize: '25px',
    color: '#02cbcd',
    textAlign: 'center',
    textTransform: 'uppercase',
  },
}));

const ListOfUser = ({
  addUser,
  closePanel,
  selectRole,
  setRattacheAPersonneMorale,
  treezorUsers,
  companyName,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const addPhysicalPerson = () => {
    setRattacheAPersonneMorale(false);
    selectRole(ASSOCIE);
    addUser();
  };

  const addLegalPerson = () => {
    setRattacheAPersonneMorale(true);
    selectRole(ASSOCIE);
    addUser();
  };

  return (
    <Paper className={classes.paper}>
      <Typography variant="h1" className={classes.title}>
        Personnes Physiques
      </Typography>
      {treezorUsers.map((treezorUser, idx) => {
        if (
          treezorUser.userTypeId === '1' &&
          !treezorUser.rattacheAPersonneMorale
        ) {
          return (
            <Card className={classes.root} key={idx}>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    <b>{`${treezorUser.firstname
                      .toUpperCase()
                      .charAt(0)}${treezorUser.lastname
                      .toUpperCase()
                      .charAt(0)}`}</b>
                  </Avatar>
                }
                title={`${treezorUser.firstname.toUpperCase()} ${treezorUser.lastname.toUpperCase()}`}
                subheader={`${treezorUser.occupation} / ${Math.round(
                  treezorUser.effectiveBeneficiary
                )}% du capital`}
              />
            </Card>
          );
        }
        return null;
      })}
      {/* <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              <b>CQ</b>
            </Avatar>
          }
          title="CHARLOTTE QUERRET"
          subheader={`Bénéficiaire effectif & représentant légal \n 25% du capital`}
        />
      </Card> */}
      <Grid container>
        <Grid item xs={6} md={4} style={{ margin: '0 auto' }}>
          <AppButton
            text={t('Ajouter un actionnaire personne physique')}
            noBorder
            type="button"
            onClick={addPhysicalPerson}
            fullWidth
          />
        </Grid>
      </Grid>
      <hr style={{ width: '50%', marginTop: '2%', marginBottom: '2%' }} />
      <Typography variant="h1" className={classes.title}>
        Personnes Morales
      </Typography>
      {treezorUsers.map((treezorUser, idx) => {
        if (
          treezorUser.userTypeId === '1' &&
          treezorUser.rattacheAPersonneMorale
        ) {
          return (
            <Card className={classes.root} key={idx}>
              <CardHeader
                avatar={
                  <Avatar aria-label="recipe" className={classes.avatar}>
                    <b>{`${treezorUser.firstname
                      .toUpperCase()
                      .charAt(0)}${treezorUser.lastname
                      .toUpperCase()
                      .charAt(0)}`}</b>
                  </Avatar>
                }
                title={`${treezorUser.firstname.toUpperCase()} ${treezorUser.lastname.toUpperCase()}`}
                subheader={`${treezorUser.occupation} / ${Math.round(
                  treezorUser.effectiveBeneficiary
                )}% du capital`}
              />
            </Card>
          );
        }
        return null;
      })}
      {/* <Card className={classes.root}>
        <CardHeader
          avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              <b>MW</b>
            </Avatar>
          }
          title="MATTHIEU WANDOLSKI"
          subheader={`Bénéficiaire effectif Personne morale \n 25% du capital`}
        />
      </Card> */}
      <Grid container>
        <Grid item xs={6} md={4} style={{ margin: '0 auto' }}>
          <Typography variant="p">
            Uniquement si un des actionnaires de cette personne morale est
            bénéficiaire effectif de <b>{companyName}</b>
          </Typography>
          <AppButton
            text={t("Ajouter le représentant d'un actionnaire personne morale")}
            noBorder
            type="button"
            onClick={addLegalPerson}
            fullWidth
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} style={{ marginTop: '2%' }}>
          <Typography variant="p">
            Le ou les bénéficiaires effectifs de votre société figurent sur le
            RBE (Registre des bénéficiaires effectifs). Vous pouvez l’obtenir
            auprès du greffe du tribunal de commerce ou auprès de votre
            expert-comptable.
          </Typography>
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <Grid item xs={12} md={2}>
          <AppButton
            text={t('Continuer')}
            noBorder
            type="button"
            onClick={closePanel}
            fullWidth
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

ListOfUser.propTypes = {
  addUser: PropTypes.func,
  closePanel: PropTypes.func,
  selectRole: PropTypes.func,
  setRattacheAPersonneMorale: PropTypes.func,
  treezorUsers: PropTypes.array,
  companyName: PropTypes.string,
};

export default ListOfUser;
