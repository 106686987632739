import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

import FormActions from './FormActions';
import CreateProviderButton from './CreateProviderButton';

const useStyles = makeStyles(() => ({
  wapper: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));

const PurchaseSidebar = ({
  readOnly,
  expenseId,
  newProvider,
  toggleNewProviderDialog,
  // toggleClientFormDialog,
}) => {
  const classes = useStyles();

  return (
    <>
      <div className={classes.wapper}>
        <FormActions horizontal expenseId={expenseId} readOnly={readOnly} />
        {/* <AppButton
          onClick={toggleClientFormDialog}
          type="button"
          text={t('new_client')}
          color="secondaryLight"
          style={{ marginLeft: 16 }}
        /> */}
        <CreateProviderButton
          newProvider={newProvider}
          toggleNewProviderDialog={toggleNewProviderDialog}
        />
      </div>
    </>
  );
};

PurchaseSidebar.propTypes = {
  readOnly: PropTypes.bool,
  expenseId: PropTypes.string,
  newProvider: PropTypes.object.isRequired,
  categoryId: PropTypes.string,
  toggleNewProviderDialog: PropTypes.func.isRequired,
  toggleClientFormDialog: PropTypes.func.isRequired,
};

export default PurchaseSidebar;
