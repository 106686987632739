export const selectLoggedUser = ({ loggedUser: { user } }) => {
  return user;
};

export const selectLoggedUserId = ({ loggedUser: { user } }) => {
  return user ? user._id : null;
};

export const selectLoggedUserEmail = ({ loggedUser: { user } }) => {
  return user ? user.email : null;
};
