import { authInstance as axios } from '../../helpers/axiosInterceptor';

const subscriptionService = {
  getSubscriptions: async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SUBSCRIPTION_URL}/subscriptions`
    );
    return response.data;
  },
  getSubscription: async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SUBSCRIPTION_URL}/company-subscription`
    );
    return response.data;
  },
  getSubscriptionHistory: async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SUBSCRIPTION_URL}/company-subscription/history`
    );
    return response.data;
  },
  cancelSubscriptions: async () => {
    const response = await axios.delete(
      `${process.env.REACT_APP_SUBSCRIPTION_URL}/company-subscription`
    );
    return response.data;
  },
  cancelSubscriptionsReason: async (payload) => {
    await axios.post(
      `${process.env.REACT_APP_SUBSCRIPTION_URL}/subscription-cancellation`,
      {
        reason: payload,
      }
    );
  },
  refund: async () => {
    await axios.get(
      `${process.env.REACT_APP_SUBSCRIPTION_URL}/company-subscription/refund`
    );
  },

  checkoutSubscription: async (subscriptionId, companyId) => {
    const action = companyId ? 'upgrade' : 'register';
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL_NO_VERSION}/auth/checkout-session`,
      { subscriptionId, action }
    );
    return response.data;
  },
  buyPaymentLinks: async (payload) => {
    const response = await axios.post(
      `${process.env.REACT_APP_STRIPE_URL}/buy-payment-link`,
      {
        quantity: +payload.quantity ?? 0,
      }
    );
    return response.data;
  },

  markMigrated: async () => {
    const response = await axios.put(
      `${process.env.REACT_APP_API_URL_NO_VERSION}/subscription/mark-migrated`
    );
    return response.data;
  },
};

export default subscriptionService;
