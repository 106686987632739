import { authInstance as axios } from 'helpers/axiosInterceptor';

// Bank transfer
const createPayouts = (data) => {
  return axios.post(`${process.env.REACT_APP_TREEZOR_URL}/payouts`, data);
};

export default {
  createPayouts,
};
