import { fetchRevenueCategories } from 'actions/RevenueCategoriesActions';

import S3FileService from 'services/S3FileService';
import { urlToBase64 } from 'helpers/base64Helper';
import { fetchCompanySubscription } from 'actions/CompanySubscription';
import {
  FETCH_LOGGED_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAILED,
  APP_INITIALIZED,
  LOGOUT_USER_SUCCESS,
} from '../Types';

import { setGlobalError } from '../GlobalError';

import userService from '../../services/UserService';

import { fetchCategories } from '../CategoriesActions';
import { fetchInternalActivities } from '../InternalActivitiesActions';
import { fetchLoggedUserCompanies } from '../UserCompanyActions';
import { fetchRoles, fetchUserRoles } from '../RolesActions';

import { fetchClients } from '../ClientActions';
import { fetchClientProjects } from '../ClientProjectActions';
import { fetchCompanyUsers, fetchTreezorUsers } from '../UserActions';
import { fetchDistanceRates } from '../DistanceRatesActions';
import { fetchVATRates } from '../VATRatesActions';
import { fetchItems } from '../ItemsActions';
import { getTemplates } from '../TemplateActions';
import { getEmailTemplates } from '../EmailTemplateActions';
import { fetchProviders } from '../ProviderAction';
import { fetchWallet } from '../WalletActions';
import logger from '../../helpers/logger';

const fetchUserFailed = (payload) => ({
  type: LOGIN_USER_FAILED,
  payload:
    payload || new Error('An error has occured while retrieving the user'),
});

const fetchUserSuccess = (payload) => ({
  type: LOGIN_USER_SUCCESS,
  payload,
});

export const fetchLoggedUser = () => async (dispatch) => {
  dispatch({ type: FETCH_LOGGED_USER });

  try {
    // Probleme ici c'est les rehydarte qui bypass
    const result = await userService.fetchAuthUser();
    logger.debug('user', result);
    if (result && result.data && result.data.avatar) {
      S3FileService.geturl(result.data.avatar)
        .then(async ({ url }) => {
          const avatarBase64 = await urlToBase64(url);
          dispatch(fetchUserSuccess({ ...result.data, avatarBase64 }));
        })
        .catch(() => {
          dispatch(fetchUserSuccess(result.data));
        });
    } else if (result && result.data) {
      dispatch(fetchUserSuccess(result.data));
    } else {
      dispatch(fetchUserFailed());
    }
  } catch (error) {
    logger.error('fetchLoggedUser() failed with error', error);
    dispatch(setGlobalError(error));
    dispatch(fetchUserFailed(error));
    dispatch({ type: LOGOUT_USER_SUCCESS });
  }
};

export const initializeUser = () => async (dispatch) => {
  logger.log('[action] initializeUser()');
  try {
    await dispatch(fetchLoggedUser());
    await dispatch(fetchLoggedUserCompanies());
  } catch (error) {
    logger.log('[action] initializeUser() failed with', error);
    dispatch(setGlobalError(error));
  }
};

export const fetchCompanyUserData = (cb) => async (dispatch) => {
  logger.log('[action] fetchCompanyUserData()');
  try {
    await Promise.all([
      dispatch(fetchUserRoles()),
      dispatch(fetchCompanySubscription()),
    ]);
    // TODO:  Here regarding fetched roles
    //        we should dispatch actions for needed informations according to user rights
    await dispatch(fetchRoles());
    await dispatch(fetchCategories());
    dispatch(fetchProviders());
    dispatch(fetchRevenueCategories());
    dispatch(fetchInternalActivities());
    dispatch(fetchClients());
    dispatch(fetchClientProjects());
    dispatch(fetchCompanyUsers());
    await dispatch(fetchTreezorUsers());

    dispatch(fetchDistanceRates());
    dispatch(fetchVATRates());
    dispatch(fetchItems());
    dispatch(getTemplates());
    dispatch(getEmailTemplates());
    dispatch(fetchWallet());
    // dispatch(fetchExpenses());
    // dispatch(fetchInvoices());
    dispatch({ type: APP_INITIALIZED, payload: true });

    cb();
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: APP_INITIALIZED, payload: false });
  }
};
