/* eslint-disable */
import { useDropzone } from 'react-dropzone';
import { useFormikContext } from 'formik';

import { Typography } from '@material-ui/core';

import { AttachFile, PostAdd, SaveAlt } from '@material-ui/icons';

import useStyles from './styles';

const FileItem = (props) => {
  const { thumbnail, title } = props;
  const classes = useStyles();

  return (
    <div className={classes.fileItemContainer}>
      <div className={classes.thumbnail}>
        {thumbnail ? (
          <img src={thumbnail} alt={thumbnail} />
        ) : (
          <AttachFile
            style={{ height: '30px', width: '30px' }}
            color="primary"
          />
        )}
      </div>
      <div className={classes.contentBody}>
        <Typography variant="body1" color="primary" className={classes.title}>
          {title}
        </Typography>
      </div>
    </div>
  );
};

const SimpleUploadZone = () => {
  const { values, setFieldValue } = useFormikContext();
  const classes = useStyles();

  const { getInputProps, getRootProps, isDragActive } = useDropzone({
    accept:
      'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint, text/plain, application/pdf, image/*',
    onDrop: (acceptedFiles) => {
      setFieldValue('file', acceptedFiles);
    },
  });

  const renderDropzoneContent = () => {
    if (!values.file) {
      return isDragActive ? (
        <div className={classes.containerTitle}>
          <SaveAlt
            color="primary"
            style={{ height: '50px', width: '50px', marginBottom: '10px' }}
          />
          <Typography color="primary" variant="h6">
            Drop file here
          </Typography>
        </div>
      ) : (
        <div className={classes.containerTitle}>
          <PostAdd
            color="primary"
            style={{ height: '50px', width: '50px', marginBottom: '10px' }}
          />
          <Typography color="primary" variant="h6">
            Drag some files here, or click to select files
          </Typography>
        </div>
      );
    }
    const fileItems = values.file.map((item, index) => (
      <FileItem title={item.name} key={index} />
    ));
    return fileItems;
  };

  return (
    <div className={classes.container}>
      <div
        className={classes.dropzone}
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        {...getRootProps({ className: 'dropzone' })}
      >
        <input {...getInputProps()} />
        {renderDropzoneContent()}
      </div>
    </div>
  );
};

export default SimpleUploadZone;
