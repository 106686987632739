import colors from '../../../constants/colors.json';
import '../../css/fonts.css';
import { mWhiteBold } from './appText';

const style = {
  appPlusSelectContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  plusButton: {
    color: colors.white,
    border: 'none',
    margin: 0,
    textDecoration: 'none',
    height: '50px',
    width: '50px',
    textTransform: 'uppercase',
    borderRadius: '50%',
    backgroundColor: colors.blue,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 12px 0 7px',
    justifyContent: 'flex-end',
    transition:
      'width 0.2s ease, padding 0.4s ease, justifyContent 0s ease 0.2s, border-radius 0.1s ease 0.2s',
    '&:hover': {
      cursor: 'pointer',
      width: '170px',
      borderBottomRightRadius: '0',
      borderTopRightRadius: '4px',
      borderBottomLeftRadius: '0',
      borderTopLeftRadius: '4px',
      padding: '0 7px',
      justifyContent: 'space-between',
      transition: 'width 0.4s ease, justifyContent 0s ease 0.4s',
    },
    '&:hover $iconStyle': {
      transform: 'rotate(-90deg)',
      transition: 'transform 1.5s ease-in',
    },
    '&:hover $textStyle': {
      ...mWhiteBold,
      transform: 'scaleY(1)',
      opacity: 1,
      fontWeight: 'bold',
      transition: 'opacity 0.4s ease 0.4s',
    },

    '&:disabled': {},
  },

  iconStyle: {
    fontSize: '26px',
    height: '26px',
    transition: 'transform 0.3s ease',
  },

  textStyle: {
    ...mWhiteBold,
    fontSize: '15px',
    transform: 'scaleY(0)',
    opacity: 0,
    margin: 0,
    paddingLeft: '5px',
  },
};

export default style;
