import { getRcsFromConcatenatdString } from '../../helpers/utils';

/* eslint import/prefer-default-export: off, camelcase: off */
export default ({ loggedUserCompany }) => {
  const {
    _id,
    name,
    business_name,
    address,
    zipcode,
    city,
    country,
    rcs,
    siren,
    nic,
    legal_form,
    legal_form_id,
    capital,
    code_ape,
    intracomvat,
    filename,
    fax_number,
    phone_number,
    website,
    settings,
    bank_details,
    endFiscalYear,
    paypal,
    treezorUser,
    isContractor,
    isCommercial,
    periodicityReport,
  } = loggedUserCompany.company;
  let bankName;
  let bic;
  let iban;

  const tax_registration = settings?.tax_registration || {};

  // console.log({
  //   tax_registration,
  // });

  const bridgePaymentLinkValue = () => {
    // Bridge paymentLink is default checked if all of payments method are false
    if (
      !settings?.payments_methods?.check &&
      !settings?.payments_methods?.hiPay &&
      !settings?.payments_methods?.bridgePaymentLink &&
      !settings?.payments_methods?.cash &&
      !settings?.payments_methods?.lcr &&
      !paypal?.client_id?.trim()
    ) {
      return true;
    }
    return !!settings?.payments_methods?.bridgePaymentLink;
  };
  if (bank_details && bank_details[0]) {
    bankName = bank_details[0].bank_name;
    // eslint-disable-next-line prefer-destructuring
    bic = bank_details[0].bic;
    // eslint-disable-next-line prefer-destructuring
    iban = bank_details[0].iban;
  }

  // Format the rcs.
  const [rcsType, rcsWithoutPrefix] = getRcsFromConcatenatdString(rcs);

  return {
    _id,
    name,
    business_name,
    address,
    zipcode,
    city,
    country: country || 'FR',
    rcs: rcsWithoutPrefix,
    rcsType,
    siren,
    nic,
    legal_form,
    legal_form_id,
    capital,
    code_ape,
    intracomvat,
    filename,
    fax_number,
    phone_number,
    website,
    quotation:
      settings && settings.invoices ? settings.invoices.quotation : null,
    order_form:
      settings && settings.invoices ? settings.invoices.order_form : null,
    delivery_form:
      settings && settings.invoices ? settings.invoices.delivery_form : null,
    // is it the old version ?
    bankName,
    bic,
    iban,
    // -----
    ...tax_registration,
    bookkeeping: tax_registration.bookkeeping || 'cash',
    endFiscalYear,
    bank_details,
    client_id: paypal && paypal.client_id ? paypal.client_id.trim() : '',
    legalRegistrationDate: treezorUser
      ? treezorUser.legalRegistrationDate
      : null,
    legalShareCapital: treezorUser ? treezorUser.legalShareCapital : null,
    legalAnnualTurnOver: treezorUser ? treezorUser.legalAnnualTurnOver : null,
    legalNumberOfEmployeeRange: treezorUser
      ? treezorUser.legalNumberOfEmployeeRange
      : null,
    check: !!settings?.payments_methods?.check,
    hiPay: !!settings?.payments_methods?.hiPay,
    bridgePaymentLink: bridgePaymentLinkValue(),
    cash: !!settings?.payments_methods?.cash,
    lcr: !!settings?.payments_methods?.lcr,
    paypal: !!paypal?.client_id,
    bankTransfer: !!settings?.payments_methods?.bankTransfer,
    isContractor,
    isCommercial,
    periodicityReport,
  };
};
