/* Packages */
import { Box } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

/* Icons */
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import MenuIcon from '@material-ui/icons/Menu';
import DeleteIcon from '@material-ui/icons/Delete';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import { LocalAtmRounded } from '@material-ui/icons';

/* Styles */
import useStyles from './styles';

/* Local Components */
import AccountActionNewTransfer from './AccountActionNewTransfer';
import AccountActionSubmenu from './AccountActionSubmenu';

function BankBBFAccountAction(props) {
  const {
    handlePDFGenerateModalOpen,
    handleDepositSectionOpen,
    handleDebitManagementSectionOpen,
    handleDebitStepperSectionOpen,
    handleDefaultSectionOpen,
  } = props;

  const history = useHistory();
  const classes = useStyles();
  const { t } = useTranslation();

  const navigate = (path) => {
    history.push(path);
  };

  const debitManagementOptions = [
    {
      title: 'view_direct_debits',
      handler: handleDebitManagementSectionOpen,
    },
    {
      title: 'create_direct_debit',
      handler: handleDebitStepperSectionOpen,
    },
  ];

  return (
    <Box className={classes.accountActionWrapper}>
      <Box
        className={classes.accountActionItemWrapper}
        onClick={handleDefaultSectionOpen}
      >
        <MoreHorizIcon />
        {t('transactions')}
      </Box>

      <Box
        className={classes.accountActionItemWrapper}
        onClick={handlePDFGenerateModalOpen}
      >
        <ImportExportIcon />
        {t('bank_bbf.export_statement')}
      </Box>

      <AccountActionNewTransfer />

      <Box
        className={classes.accountActionItemWrapper}
        onClick={() => navigate('/bank-bbf/delete/payout')}
      >
        <DeleteIcon />
        {t('bank_bbf.delete_payout')}
      </Box>

      <AccountActionSubmenu
        title="manager_debit"
        subItems={debitManagementOptions}
      />

      <Box
        className={classes.accountActionItemWrapper}
        onClick={handleDepositSectionOpen}
      >
        <LocalAtmRounded />
        {t('bank_bbf.deposit_a_check')}
      </Box>

      <Box
        className={classes.accountActionItemWrapper}
        onClick={() => navigate('/bank-bbf/sepa/requests')}
      >
        <MenuIcon />
        {t('bank_bbf.sepa_request_tickets')}
      </Box>

      <Box
        className={classes.accountActionItemWrapper}
        onClick={() => navigate('/bank-bbf/wallet/delete')}
      >
        <WorkOffIcon />
        {t('close_wallet.close_wallet')}
      </Box>
    </Box>
  );
}

BankBBFAccountAction.propTypes = {
  handlePDFGenerateModalOpen: PropTypes.func,
  handleDepositSectionOpen: PropTypes.func,
  handleDebitManagementSectionOpen: PropTypes.func,
  handleDebitStepperSectionOpen: PropTypes.func,
  handleDefaultSectionOpen: PropTypes.func,
};

export default BankBBFAccountAction;
