import React from 'react';
import { Box, Typography, styled } from '@material-ui/core';

import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const StyledTypography = styled(Typography)(({ textColor }) => ({
  fontWeight: 600,
  color: textColor,
}));

const useStyles = makeStyles((theme) => ({
  root: ({ bgColor, onClick }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    alignSelf: 'stretch',
    borderRadius: theme.rounded.lg,
    backgroundColor: bgColor,
    padding: theme.spacing(3),
    cursor: onClick ? 'pointer' : 'inherit',
  }),
}));

const FiveBiggestCard = (props) => {
  const { titleLeft, titleRight, onClick, style, textColor } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.root} style={{ ...style }} onClick={onClick}>
      <StyledTypography textColor={textColor} variant="h5">
        {titleLeft}
      </StyledTypography>
      <StyledTypography textColor={textColor} variant="h3">
        {titleRight}
      </StyledTypography>
    </Box>
  );
};

FiveBiggestCard.propTypes = {
  titleLeft: PropTypes.string,
  titleRight: PropTypes.string,
  bgColor: PropTypes.string.isRequired,
  textColor: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
};

export default FiveBiggestCard;
