// import { setGlobalError } from 'actions/GlobalError';
import {
  FETCH_WALLET,
  FETCH_WALLET_FAILED,
  FETCH_WALLET_TRANSACTIONS,
  FETCH_WALLET_TRANSACTIONS_FAILED,
  UPDATE_WALLET_TRANSACTION,
  UPDATE_WALLET_TRANSACTION_FAILED,
  FETCH_TRANSACTIONS_PAGINATE,
  REFRESH_TRANSACTIONS_PAGINATE,
} from 'actions/Types';
import paginateAction from 'helpers/paginateAction';
import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

import service from '../../services/TreezorService';

// eslint-disable-next-line import/prefer-default-export
export const fetchWallet = () => async (dispatch, getState) => {
  try {
    const companyId = selectLoggedUserCompanyId(getState());
    const { treezorUser } = getState().treezor;
    if (!companyId) {
      dispatch({
        type: FETCH_WALLET,
        payload: {},
      });
      return;
    }

    if (treezorUser) {
      const wallet = await service.fetchWallet(companyId);
      dispatch({
        type: FETCH_WALLET,
        payload: wallet.data,
      });
    }
    return;
  } catch (error) {
    // dispatch(setGlobalError(error));
    dispatch({ type: FETCH_WALLET_FAILED, payload: error });
  }
};

export const fetchWalletTransactions = (walletId) => async (dispatch) => {
  try {
    const walletTransactions = await service.fetchWalletTransactions(walletId);
    let mappedTransactions = [];
    if (walletTransactions.data && walletTransactions.data.length > 0) {
      mappedTransactions = walletTransactions.data.map((el) => {
        return {
          ...el,
          amount: parseFloat(el.amount),
          totalItems: 0,
        };
      });
    }
    dispatch({
      type: FETCH_WALLET_TRANSACTIONS,
      payload: mappedTransactions,
    });
    return mappedTransactions;
  } catch (error) {
    // dispatch(setGlobalError(error));
    dispatch({ type: FETCH_WALLET_TRANSACTIONS_FAILED, payload: error });
    return error;
  }
};

export const fetchWalletTransactionsForPDFExporting = (
  walletId,
  startDate = null,
  endDate = null
) => {
  let qs = '';

  if (startDate && endDate) {
    qs = `startDate=${startDate}&endDate=${endDate}`;
  }
  return service
    .fetchWalletTransactionsForPDFExporting(walletId, qs)
    .then((res) => res && res.data)
    .catch((err) => {
      throw err;
    });
};

export const updateWalletTransaction = (walletId, data) => async (dispatch) => {
  try {
    const updatedWalletTransaction = await service.updateWalletTransaction(
      walletId,
      data
    );
    dispatch({
      type: UPDATE_WALLET_TRANSACTION,
      payload: updatedWalletTransaction,
    });
    return;
  } catch (error) {
    // dispatch(setGlobalError(error));
    dispatch({ type: UPDATE_WALLET_TRANSACTION_FAILED, payload: error });
  }
};

export const fetchTransactionPaginate =
  (walletId, query, filters, refresh) => async (dispatch, getState) => {
    try {
      const { transactionPaginate } = getState();
      const walletTransactions = await paginateAction({
        state: transactionPaginate,
        dispatch,
        service: service.fetchWalletTransactionsPaginate,
        walletId,
        query,
        filters,
        refresh,
        actions: {
          refresh: REFRESH_TRANSACTIONS_PAGINATE,
          fetch: FETCH_TRANSACTIONS_PAGINATE,
        },
      });

      //  This part for reconcile function in wallet transactions table
      let mappedTransactions = [];

      if (walletTransactions.data && walletTransactions.data.length > 0) {
        mappedTransactions = walletTransactions.data.map((el) => {
          return {
            ...el,
            amount: Number(el.amount),
            totalItems: 0,
          };
        });
      }

      dispatch({
        type: FETCH_WALLET_TRANSACTIONS,
        payload: mappedTransactions,
      });

      return walletTransactions;
    } catch (error) {
      dispatch({ type: FETCH_WALLET_TRANSACTIONS_FAILED, payload: error });
      return error;
    }
  };

export const fetchWalletTransaction = (qs) =>
  service.fetchWalletTransaction(qs);

export const getPayoutRequestAction = (qs) => service.getPayoutRequest(qs);

export const getValidatedPayoutRequestAction = (walletId, qs) =>
  service.getValidatedPayoutRequest(walletId, qs);

export const deletePendingPayoutRequestAction = (payoutId, qs) =>
  service.deletePendingPayoutRequest(payoutId, qs);

export const getPendingPayoutRequestAction = (qs) =>
  service.getPendingPayoutRequest(qs);

export const getPendingPayinRequestAction = (qs) =>
  service.getPendingPayinRequest(qs);

export const closeTreezorWalletRequestAction = (walletId) =>
  service.closeTreezorWalletRequest(walletId);
