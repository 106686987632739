/* eslint-disable no-restricted-syntax */
import {
  format,
  getYear,
  isBefore,
  isAfter,
  addYears,
  subYears,
} from 'date-fns';

const removeTrailing0 = (arr) => {
  while (arr[arr.length - 1] === 0) {
    // While the last element is a 0,
    arr.pop(); // Remove that last element
  }
};

export const flattenYears = (payload) => {
  //   console.log(payload);
  const result = {};
  const keys = Object.keys(payload);
  keys.forEach((key) => {
    const mask = [];
    for (let m = 0; m < 12; m += 1) {
      mask.push(0);
    }
    const months = payload[key];
    for (let i = 0; i < months.length; i += 1) {
      mask[months[i].month - 1] = months[i].total;
    }
    removeTrailing0(mask);
    result[key] = mask;
  });
  //   console.log(result);
  return result;
};
export const years = (payload) => {
  const keys = Object.keys(payload);

  return keys;
};

export const getSeries = (input) => {
  // console.log('input:', input);
  const result = [];
  const keys = Object.keys(input);
  keys.forEach((key) => {
    result.push(input[key]);
  });
  // console.log(result);
  return result;
};

export const getSum = (months) => {
  let result = 0;
  if (months) {
    for (let i = 0; i < months.length; i += 1) {
      result += months[i];
    }
  }

  return result;
};

const getData = (data) => {
  const newData = Array(12).fill(null);
  // eslint-disable-next-line guard-for-in
  for (const i in data) {
    newData[data[i].month - 1] = data[i].total;
  }
  return newData;
};

export const getDataSet = (data, t) => {
  const datasets = [];
  const defaultData = Array(12).fill(null);
  datasets.push({
    name: `${t('turn_over.invoice')} n -1`,
    data:
      data.invoice.nm1 && data.invoice.nm1.length
        ? getData(data.invoice.nm1)
        : defaultData,
  });
  datasets.push({
    name: `${t('turn_over.invoice')} n`,
    data:
      data.invoice.n && data.invoice.n.length
        ? getData(data.invoice.n)
        : defaultData,
  });

  return datasets;
};

export const monthFiscalYearHelper = (endFiscalYear, month, n) => {
  const [dayEndFiscalYear, monthEndFiscalYear] = endFiscalYear.split('/');
  const yearEndFiscalYear = getYear(new Date());
  let endFiscalYearDate = format(
    new Date(yearEndFiscalYear, monthEndFiscalYear - 1, dayEndFiscalYear),
    'yyyy-MM-dd'
  );
  if (
    isBefore(
      new Date(yearEndFiscalYear, monthEndFiscalYear - 1, dayEndFiscalYear),
      new Date()
    )
  )
    endFiscalYearDate = format(
      new Date(
        addYears(
          new Date(yearEndFiscalYear, monthEndFiscalYear - 1, dayEndFiscalYear),
          1
        )
      ),
      'yyyy-MM-dd'
    );

  let date = format(
    new Date(`${getYear(new Date(endFiscalYearDate))}/${month}/01`),
    'dd/MM/yyyy'
  );
  const [day, mm, year] = date.split('/');
  // if (date.isAfter(endFiscalYearDate)) date.subtract(1, 'year');
  if (
    isAfter(
      new Date(year, mm - 1, day),
      new Date(yearEndFiscalYear, monthEndFiscalYear - 1, dayEndFiscalYear)
    )
  )
    // date.subtract(1, 'year');
    date = format(
      new Date(subYears(new Date(year, mm - 1, day), 1)),
      'dd/MM/yyyy'
    );
  // if its on fiscal year n - 1
  if (n === 0 || n === 2)
    date = format(
      new Date(subYears(new Date(year, mm - 1, day), 1)),
      'dd/MM/yyyy'
    );
  const [dd, MM, yyyy] = date.split('/');
  return format(new Date(yyyy, MM - 1, dd), 'yyyy-MM-dd');
};

export const fiscalYearHelper = (endFiscalYear) => {
  const [dd, mm] = endFiscalYear.split('/');

  let endFiscalYearDate = new Date(`${getYear(new Date())}/${mm}/${dd}`);

  if (isBefore(new Date(endFiscalYearDate), new Date()))
    endFiscalYearDate = addYears(new Date(endFiscalYearDate), 1);

  return format(new Date(endFiscalYearDate), 'dd/MM/yyyy');
};
