import { FULL_VATRATES } from '../../actions/Types';

export default (state = [], action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FULL_VATRATES:
      return action.payload || [];

    default:
      return state;
  }
};
