import { mWhiteBold } from './appText';
import colors from '../../../constants/colors.json';

const style = {
  button: {
    ...mWhiteBold,
    border: 'none',
    margin: '0',
    padding: '0 12px',
    textDecoration: 'none',
    height: '40px',
    minWidth: '105px',
    borderRadius: '4px',
    backgroundColor: colors.blue,
    textTransform: 'uppercase',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(61, 49, 157,0.8)',
    },
    '&:active': {
      border: '1px solid #02CBCD',
      backgroundColor: 'rgba(61, 49, 157,0.8)',
    },
    '&:focus': {
      border: '1px solid #02CBCD',
      // backgroundColor: colors.blue,
    },
    '&:disabled': {
      backgroundColor: colors.fillsGrey,
    },
  },

  primaryLight: {
    backgroundColor: colors.white,
    color: colors.blue,
    border: '1px solid #3d319d',
    '&:hover': {
      backgroundColor: colors.blue,
      color: colors.white,
    },
    '&:disabled': {
      backgroundColor: colors.white,
      border: '1px solid #D7D7D7',
      color: colors.fillsGrey,
    },
  },

  secondary: {
    backgroundColor: colors.green,
    '&:hover': {
      backgroundColor: 'rgba(2, 203, 205,0.6)',
    },
    '&:active': {
      border: '1px solid pink',
      backgroundColor: 'rgba(2, 203, 205,0.6)',
    },
    '&:focus': {
      border: '1px solid #3d319d',
      color: colors.white,
      backgroundColor: colors.green,
    },
    '&:disabled': {
      backgroundColor: colors.fillsGrey,
    },
  },
  secondaryGrey: {
    backgroundColor: colors.green,
    '&:hover': {
      backgroundColor: 'rgba(2, 203, 205,0.6)',
    },
    '&:active': {
      border: '1px solid #3d319d',
      backgroundColor: 'rgba(2, 203, 205,0.6)',
    },
    '&:focus': {
      border: '1px solid #3d319d',
      backgroundColor: colors.green,
    },
    '&:disabled': {
      backgroundColor: colors.fillsGrey,
    },
  },

  secondaryLight: {
    border: '1px solid #02CBCD',
    backgroundColor: colors.white,
    color: colors.green,

    '&:hover': {
      backgroundColor: colors.green,
      color: colors.white,
      border: '1px solid #02CBCD',
    },
    '&:active': {
      border: '1px solid #3d319d',
      backgroundColor: 'rgba(2, 203, 205,0.6)',
    },
    '&:focus': {
      border: '1px solid #3d319d',
      color: colors.white,
      backgroundColor: colors.green,
    },
    '&:disabled': {
      backgroundColor: colors.white,
      border: '1px solid #D7D7D7',
      color: colors.fillsGrey,
    },
  },

  fullWidth: {
    width: '100%',
  },

  transparentGrey: {
    backgroundColor: 'transparent',
    color: '#B2B2B2',
    '&:hover': {
      border: 'none',
      backgroundColor: colors.fillsLightGrey,
    },
    '&:active': {
      border: '1px solid #888888',
      backgroundColor: colors.fillsLightGrey,
    },
    '&:focus': {
      border: '1px solid #888888',
      backgroundColor: colors.fillsLightGrey,
    },
    '&:disabled': {
      backgroundColor: colors.fillsGrey,
    },
  },
  red: {
    backgroundColor: colors.red,
    color: '#FFFFFF',
    '&:hover': {
      border: 'none',
      backgroundColor: colors.fillsLightGrey,
    },
    '&:active': {
      border: '1px solid #888888',
      backgroundColor: colors.red,
    },
    '&:focus': {
      border: '1px solid #888888',
      backgroundColor: colors.red,
    },
    '&:disabled': {
      backgroundColor: colors.fillsGrey,
    },
  },
  redLight: {
    border: '1px solid #CC2236',
    backgroundColor: colors.white,
    color: colors.red,

    '&:hover': {
      backgroundColor: colors.red,
      color: colors.white,
    },
    '&:active': {
      border: '1px solid #CC2236',
      backgroundColor: colors.red,
    },
    '&:focus': {
      border: '1px solid #CC2236',
      color: colors.white,
      backgroundColor: colors.red,
    },
    '&:disabled': {
      backgroundColor: colors.white,
      border: '1px solid #D7D7D7',
      color: colors.fillsGrey,
    },
  },
  noBorder: {
    border: 'none',
  },
};

export default style;
