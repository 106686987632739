import { useState, useEffect, useCallback, useContext, useMemo } from 'react';

import { format, addMonths } from 'date-fns';

import { isEqual, groupBy, map } from 'lodash';

import { roundingNumber } from 'utils';
import NumberFormat from 'helpers/NumberFormat';
import { useDecimalCharacter } from 'hooks/decimalCharacterHooks';
import { InvoiceFormContext } from '../../contexts/InvoiceContexts/InvoiceFormContext';

import { usePrevious } from '../generalHooks';

import { deepCopy } from '../../helpers/invoiceHelpers';

function useInvoiceTotal(fn, values) {
  const cb = useCallback(fn, []);
  // const itemsPreviousState = usePrevious(values.items);
  // const deliveryFeePreviousState = usePrevious(values.delivery_fee) || 0;

  useEffect(() => {
    let totalGross = 0;
    let totalNet = 0;
    let totalVat = 0;

    // if items has changed, re-calculate the total
    // if (itemsPreviousState && !isEqual(itemsPreviousState, values.items)) {
    ({ totalGross, totalNet } = values.items.reduce(
      (acc, it) => ({
        totalGross: acc.totalGross + parseFloat(it.gross_price),
        totalNet: acc.totalNet + parseFloat(it.net_price),
      }),
      { totalGross: 0, totalNet: 0 }
    ));
    // } else {
    //   totalGross = values.gross_total;
    //   totalNet = values.net_total;
    // }

    totalVat = totalGross - totalNet;

    // if the delivery fee has changed remove it from the previous total
    // if (
    //   deliveryFeePreviousState &&
    //   !isEqual(deliveryFeePreviousState, values.delivery_fee)
    // ) {
    //   // remove fee
    //   totalNet =
    //     parseFloat(values.net_total) -
    //     parseFloat(deliveryFeePreviousState) / 1.2;
    //   totalGross =
    //     parseFloat(values.gross_total) - parseFloat(deliveryFeePreviousState);
    //   totalVat =
    //     parseFloat(values.vat_total) -
    //     parseFloat(deliveryFeePreviousState) * 0.2;
    // }

    // add fee
    if (values.delivery_fee) {
      const deliveryFeeNet = parseFloat(values.delivery_fee) / 1.2;
      totalNet += deliveryFeeNet;
      totalGross += parseFloat(values.delivery_fee);
      totalVat += deliveryFeeNet * 0.2;
    }

    const totalPayments = [...values.payments];

    cb({
      totalGross: roundingNumber(totalGross),
      totalNet: roundingNumber(totalNet),
      totalVat: roundingNumber(totalVat),
      totalPayments,
    });
  }, [values.items, values.delivery_fee]);
}

function useInvoiceItemTotal(fn, item, decimalChar) {
  const cb = useCallback(fn, []);
  const itemPreviousState = usePrevious(item);
  useEffect(() => {
    const unitPrice =
      typeof item.unit_price === 'number'
        ? item.unit_price
        : NumberFormat.getNumber(
            item.unit_price.replace(decimalChar, '.').replace(' ', '')
          );

    // if the items has changed re-calculate the net and gross
    if (itemPreviousState && !isEqual(itemPreviousState, item)) {
      const netPrice =
        (unitPrice - (unitPrice * parseFloat(item.discount)) / 100) *
        item.quantity;
      const grossPrice =
        netPrice + (netPrice * parseFloat(item.vat_rate)) / 100;
      cb({
        netPrice: roundingNumber(netPrice),
        grossPrice: roundingNumber(grossPrice),
      });
    }
  }, [item.unit_price, item.quantity, item.discount, item.vat_rate]);
}

function useIvoiceVat(fn, items, deliveryFee) {
  const deliveryFeePreviousState = usePrevious(deliveryFee) || 0;
  const itemsPreviousState = usePrevious(items) || [];

  useEffect(() => {
    if (
      !isEqual(deliveryFeePreviousState, deliveryFee) ||
      !isEqual(itemsPreviousState, items)
    ) {
      const vatArray = map(groupBy(items, 'vat_rate'), (vatItems, vat) => {
        return {
          vat: parseFloat(vat, 10),
          ...vatItems.reduce(
            (acc, it) => {
              const gross = acc.gross + parseFloat(it.gross_price);
              const net = acc.net + parseFloat(it.net_price);
              return {
                gross: roundingNumber(gross),
                net: roundingNumber(net),
                totalVAT: roundingNumber(gross - net),
              };
            },
            { gross: 0, net: 0 }
          ),
        };
      });

      const index = vatArray.findIndex(({ vat }) => vat === 20);

      if (index !== -1 && deliveryFee) {
        const gross =
          parseFloat(vatArray[index].gross) + parseFloat(deliveryFee);
        const net =
          parseFloat(vatArray[index].net) + parseFloat(deliveryFee) / 1.2;
        vatArray[index] = {
          vat: 20,
          gross: roundingNumber(gross),
          net: roundingNumber(net),
          totalVAT: roundingNumber(gross - net),
        };
      } else if (index === -1 && deliveryFee) {
        const gross = parseFloat(deliveryFee);
        const net = parseFloat(deliveryFee) / 1.2;

        vatArray.push({
          vat: 20,
          gross: roundingNumber(gross),
          net: roundingNumber(net),
          totalVAT: roundingNumber(gross - net),
        });
      }

      fn(vatArray);
    }
  }, [items, deliveryFee]);
}

function useInvoiceFormContext() {
  return useContext(InvoiceFormContext);
}

function usePrePayments(prepayments, grossTotal) {
  const [numberOfPrePayments, setNumberOfPrePayments] = useState(
    prepayments.length || 1
  );
  const [newPrePayments, setNewPrePayments] = useState(deepCopy(prepayments));
  const [amountError, setAmountError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  function checkTotalAmount() {
    const newTotalAmount = newPrePayments.reduce(
      (acc, { amount }) => acc + parseFloat(amount),
      0
    );

    setAmountError(
      parseFloat(newTotalAmount.toFixed(2)) >
        parseFloat(parseFloat(grossTotal).toFixed(2))
    );
  }

  function checkDescriptions() {
    const paymentWithoutDescr = newPrePayments.filter(
      ({ description }) => !description
    );
    setDescriptionError(paymentWithoutDescr.length);
  }

  function changeAttr(index, attr, value) {
    const npc = deepCopy(newPrePayments);
    npc[index][attr] = value;
    setNewPrePayments(deepCopy(npc));
  }

  function changeDescription(index, value) {
    changeAttr(index, 'description', value);
  }

  function changeAmount(index, value) {
    changeAttr(index, 'amount', value);
  }

  function changeTaxRate(index, value) {
    changeAttr(index, 'tax_rate', +value);
  }

  function changeTaxAmount(index, value) {
    changeAttr(index, 'tax_amount', +value);
  }

  function changeNetAmount(index, value) {
    changeAttr(index, 'net_amount', +value);
  }

  function changeMilestones() {
    const changedPayments = [];

    let lastDueDate = null;
    for (let i = 0; i < numberOfPrePayments; i += 1) {
      const dueDate = !lastDueDate
        ? new Date()
        : new Date(lastDueDate.due_date);
      const newDueDate = dueDate.setDate(dueDate.getDate() + 1);
      const prepayment = newPrePayments[i] || {
        description: '',
        amount: 0,
        due_date: format(newDueDate, 'yyyy/MM/dd'),
      };

      lastDueDate = prepayment;
      changedPayments.push(prepayment);
    }

    setNewPrePayments(changedPayments);
  }

  function changeDueDate(index, value) {
    changeAttr(index, 'due_date', value);
  }

  useEffect(() => {
    changeMilestones();
  }, [numberOfPrePayments]);

  useEffect(() => {
    checkTotalAmount();
  }, [newPrePayments, grossTotal]);

  useEffect(() => {
    checkDescriptions();
  }, [newPrePayments]);

  return {
    numberOfPrePayments,
    setNumberOfPrePayments,
    newPrePayments,
    amountError,
    descriptionError,
    changeDescription,
    changeAmount,
    changeDueDate,
    changeTaxRate,
    changeTaxAmount,
    changeNetAmount,
  };
}

function useDueDates(payments, grossTotal, cb) {
  const [newDueDate, setNewDueDate] = useState(
    payments[0] ? payments[0].date : null
  );
  const [dueDateChosen, setDueDateChosen] = useState('');
  const [numberOfPayments, setNumberOfPayments] = useState(payments.length);
  const [newPayments, setNewPayments] = useState(deepCopy(payments));
  const [amountError, setAmountError] = useState(false);
  const { decimalChar } = useDecimalCharacter();

  function changeAttr(index, attr, value) {
    const npc = deepCopy(newPayments);
    npc[index][attr] = value;
    setNewPayments(deepCopy(npc));
  }

  function changeDate(index, value) {
    changeAttr(index, 'date', format(new Date(value), 'yyyy-MM-dd'));
  }

  function changeAmount(index, value) {
    changeAttr(index, 'amount', value);
  }

  function changeJustDueDates(sendCb) {
    const changedPayments = [...newPayments];
    const scheduledDate = newDueDate
      ? newDueDate.toString()
      : new Date().toString();

    changedPayments.forEach((payment, i) => {
      const addDueDate = format(
        new Date(addMonths(new Date(scheduledDate), i)),
        'yyyy-MM-dd'
      );

      // eslint-disable-next-line no-param-reassign
      payment.date = addDueDate;
    });

    setNewPayments(changedPayments);

    if (sendCb) {
      cb(changedPayments);
    }
  }

  function changeDueDates(len, sendCb) {
    const changedPayments = [];
    const scheduledDate = newDueDate
      ? newDueDate.toString()
      : new Date().toString();

    for (let i = 0; i < len; i += 1) {
      const addDueDate = format(
        new Date(addMonths(new Date(scheduledDate), i)),
        'yyyy-MM-dd'
      );

      changedPayments.push({
        date: addDueDate,
        amount: grossTotal / len,
      });
    }

    setNewPayments(changedPayments);

    if (sendCb) {
      cb(changedPayments);
    }
  }

  function changeDueAmount(len, sendCb) {
    const changedPayments = [...newPayments];

    changedPayments.forEach((payment) => {
      const newAmount = grossTotal / len;

      // eslint-disable-next-line no-param-reassign
      payment.amount = newAmount;
    });

    setNewPayments(changedPayments);

    if (sendCb) {
      cb(changedPayments);
    }
  }

  function getTotalDueAmount() {
    return newPayments.reduce(
      (acc, { amount }) =>
        acc + NumberFormat.getNumber(amount.toString(), decimalChar, ' '),
      0
    );
  }

  function checkTotalAmount() {
    const newTotalAmount = getTotalDueAmount();

    setAmountError(
      newTotalAmount.toFixed(2) !== parseFloat(grossTotal).toFixed(2)
    );
  }

  useEffect(() => {
    if (getTotalDueAmount().toFixed(2) !== parseFloat(grossTotal).toFixed(2)) {
      changeDueAmount(payments.length, true);
    }

    if (payments.length !== numberOfPayments) {
      setNumberOfPayments(payments.length);
    }
  }, [grossTotal]);

  useEffect(() => {
    changeDueDates(numberOfPayments, false);
  }, [numberOfPayments]);

  useEffect(() => {
    changeJustDueDates(false);
  }, [dueDateChosen, newDueDate]);

  useEffect(() => {
    checkTotalAmount();
  }, [newPayments, grossTotal]);
  useEffect(() => {
    setNewPayments(deepCopy(payments));
  }, [payments]);

  return {
    setNewDueDate,
    dueDateChosen,
    setDueDateChosen,
    numberOfPayments,
    setNumberOfPayments,
    newPayments,
    amountError,
    changeDate,
    changeAmount,
  };
}

function userPreparePrepayment(vatDetails, grossTotal, prepayments) {
  // get maximum vat on vatDetails
  const vatPercentPrePayment = useMemo(() => {
    if (vatDetails && vatDetails.length) {
      const _vatPercentPrePayment = vatDetails.reduce((acc, curr) => {
        if (acc.vat < curr.vat) {
          return curr;
        }
        return acc;
      });
      return _vatPercentPrePayment.vat;
    }
    return 0;
  }, [vatDetails]);

  // calculate Net amount
  const calculateNetAmountItem = useCallback(
    (amount = 0) => {
      if (vatPercentPrePayment && amount && !Number.isNaN(amount)) {
        const rs = +amount / (+vatPercentPrePayment / 100 + 1).toFixed(2);
        return rs.toFixed(2);
      }
      return 0;
    },
    [vatPercentPrePayment, grossTotal]
  );

  // calculate vat amount
  const calculateVatAmountItem = useCallback(
    (amount = 0) => {
      if (vatPercentPrePayment) {
        const netAmount = calculateNetAmountItem(amount);
        return ((netAmount * vatPercentPrePayment) / 100).toFixed(2);
      }
      return 0;
    },
    [vatPercentPrePayment, grossTotal]
  );

  // calculate remaining amount
  const remainingAmount = useMemo(() => {
    const total = prepayments.reduce((acc, curr) => {
      return acc + curr.amount;
    }, 0);

    return grossTotal - total;
  }, [prepayments, grossTotal]);

  return {
    vatPercentPrePayment,
    remainingAmount,
    calculateVatAmountItem,
    calculateNetAmountItem,
  };
}

export {
  useInvoiceTotal,
  useInvoiceItemTotal,
  useIvoiceVat,
  useInvoiceFormContext,
  useDueDates,
  usePrePayments,
  userPreparePrepayment,
};
