import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Typography } from '@material-ui/core/';

import { style } from 'assets/jss/style';

const { blueContainer } = style;

const InvoiceCardInvoice = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={blueContainer}>
        <Typography variant="body2" component="p" style={{ color: 'white' }}>
          {t('invoices.total_net')}
        </Typography>
        <Typography variant="body2" component="p" style={{ color: 'white' }}>
          {data.net_total}€
        </Typography>
      </div>
      <div style={blueContainer}>
        <Typography variant="body2" component="p" style={{ color: 'white' }}>
          {t('invoices.vat_amount')}
        </Typography>
        <Typography variant="body2" component="p" style={{ color: 'white' }}>
          {data.vat_total}€
        </Typography>
      </div>
      <div style={blueContainer}>
        <Typography variant="body2" component="p" style={{ color: 'white' }}>
          {t('invoices.total_gross')}
        </Typography>
        <Typography variant="body2" component="p" style={{ color: 'white' }}>
          {data.gross_total}€
        </Typography>
      </div>
      {data.duePayableAmount && (
        <>
          <div style={blueContainer}>
            <Typography
              variant="body2"
              component="p"
              style={{ color: 'white' }}
            >
              {t('invoices.set_prepayments')}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{ color: 'white' }}
            >
              {data.diff_to_pay}€
            </Typography>
          </div>
          <div style={blueContainer}>
            <Typography
              variant="body2"
              component="p"
              style={{ color: 'white' }}
            >
              {t('invoices.due_payable_amount')}
            </Typography>
            <Typography
              variant="body2"
              component="p"
              style={{ color: 'white' }}
            >
              {data.duePayableAmount}€
            </Typography>
          </div>
        </>
      )}
    </>
  );
};

InvoiceCardInvoice.propTypes = {
  data: PropTypes.object,
  invoice: PropTypes.object,
};

export default InvoiceCardInvoice;
