import {
  FETCH_COMPANY_SUBSCRIPTION,
  FETCH_COMPANY_SUBSCRIPTION_START,
} from '../../actions/Types';

const INITIAL_STATE = {
  loading: true,
  success: false,
  error: null,
  timestamp: null,
  data: null,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_COMPANY_SUBSCRIPTION_START:
      return {
        ...state,

        loading: true,
      };
    case FETCH_COMPANY_SUBSCRIPTION:
      return {
        ...state,
        success: true,
        loading: false,
        data: action?.payload,
      };

    default:
      return state;
  }
};
