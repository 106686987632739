import axios from 'axios';

export const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });

// eslint-disable-next-line import/prefer-default-export
export const urlToBase64 = async (url) =>
  new Promise((resolve, reject) => {
    axios({
      method: 'GET',
      url,
      responseType: 'blob',

      headers: {
        'Access-Control-Allow-Origin': '*',
        Origin: window.location.origin,
      },
    })
      .then(async (res) => {
        convertBlobToBase64(res.data)
          .then((b64) => {
            resolve(b64);
          })
          .catch((error) => {
            reject(error);
          });
      })
      .catch((err) => {
        reject(err);
      });
  });
