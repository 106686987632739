import { bindActionCreators } from 'redux';
import {
  createClient,
  updateClient,
  fetchClients,
} from '../../../actions/ClientActions';
import { fetchInfoGreffe } from '../../../actions/infoGreffeActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createClient,
      fetchInfoGreffe,
      fetchClients,
      updateClient,
    },
    dispatch
  );
