import React from 'react';
import PropTypes from 'prop-types';

import { PDF_MEDIA_TYPE, OCTET_STREAM_MEDIA_TYPE } from 'config/Constants';
import { Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { IconImage } from '../AppIcons';
import ImagePreview from './ImagePreview';
import PdfPreview from './PdfPreview';

const useStyles = makeStyles(() => ({
  maxHeight: {
    height: '100vh',
  },
}));
const FilePreview = ({
  content: { type, value },
  toggleDialog,
  isDialog,
  shape,
  customStyles,
  isPreviewFile,
  disabled,
}) => {
  const { t } = useTranslation();
  const pdfTypes = [PDF_MEDIA_TYPE, OCTET_STREAM_MEDIA_TYPE];
  const classes = useStyles();

  if (!value) {
    return (
      <>
        {disabled && (
          <Typography color="primary" variant="body1">
            {t('purchases.document.no_required')}
          </Typography>
        )}
        {isPreviewFile ? (
          <div className={classes.maxHeight}>
            <IconImage xl secondary fill disabled={disabled} />
          </div>
        ) : (
          <IconImage xl secondary fill disabled={disabled} />
        )}
      </>
    );
  }
  if (pdfTypes.includes(type)) {
    return <PdfPreview file={value} isDialog={isDialog} shape={shape} />;
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(255,255,255,0.3)',
      }}
    >
      <ImagePreview
        file={value}
        shape={shape}
        toggleDialog={toggleDialog}
        isDialog={isDialog}
        customStyles={customStyles}
        isPreviewFile={isPreviewFile}
      />
    </div>
  );
};

FilePreview.propTypes = {
  content: PropTypes.object,
  toggleDialog: PropTypes.func,
  isDialog: PropTypes.bool,
  shape: PropTypes.string,
  customStyles: PropTypes.object,
  isPreviewFile: PropTypes.bool,
  disabled: PropTypes.bool,
};

FilePreview.defaultProps = {
  isDialog: false,
  disabled: false,
};

export default FilePreview;
