/* eslint-disable guard-for-in */
import { compareQs } from 'helpers/querystringHelper';
// import { findIndex } from 'lodash';
import {
  FETCH_TRANSACTIONS_PAGINATE,
  REFRESH_TRANSACTIONS_PAGINATE,
} from 'actions/Types';

export default (state = { docs: [] }, action) => {
  switch (action.type) {
    case FETCH_TRANSACTIONS_PAGINATE:
      if (compareQs(action, state) && action.page === state.page + 1) {
        return {
          ...state,
          docs: [...state.docs, ...action.docs],
          page: action.page,
        };
      }
      return {
        docs: action.docs,
        filters: action.filters,
        page: action.page,
        limit: action.limit,
        search: action.search,
        orderBy: action.orderBy,
        orderDirection: action.orderDirection,
        totalCount: action.totalCount,
      };
    case REFRESH_TRANSACTIONS_PAGINATE: {
      return {
        ...state,
        docs: action.docs,
        totalCount: action.totalCount,
        page: action.page,
      };
    }
    default:
      return state;
  }
};
