import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

// import NumberFormat from '../../../../../helpers/NumberFormat';
import NumberFormat from 'helpers/NumberFormat';

import { useIvoiceVat } from '../../../../../hooks/invoiceHooks';

import { typeHelpers } from '../../../../../helpers/invoiceHelpers';

import colors from '../../../../../constants/colors.json';

export const StyledTableCell = withStyles({
  root: {
    backgroundColor: colors.backgroundGrey,
    border: 0,
    color: colors.white,
    height: 30,
    padding: '0 3px',
    fontWeight: 'normal',
    fontSize: '11px',
  },
  body: {
    background: colors.white,
    color: 'black',
    padding: '2px 3px',
  },
})(TableCell);

export const StyledTableHead = withStyles({
  root: {
    height: '30px',
    backgroundColor: colors.backgroundGrey,
  },
})(TableHead);
export const StyledTableRow = withStyles({
  root: {
    height: '34px',
    borderBottom: '1px solid grey',
  },
})(TableRow);

const VatTable = () => {
  const { t } = useTranslation();
  const language = useSelector((state) => state.guiLanguage);

  const {
    values: {
      items,
      delivery_fee: deliveryFee,
      type: invoiceType,
      vat_details: vatDetails,
    },
    setFieldValue,
  } = useFormikContext();

  useIvoiceVat(
    (arr) => {
      console.log('arr', arr);
      setFieldValue('vat_details', arr, false);
    },
    items,
    deliveryFee
  );

  function renderDeliveryFee() {
    if (!deliveryFee || typeHelpers.isCreditNote(invoiceType)) {
      return null;
    }

    return (
      <StyledTableRow key="delivery_fee">
        <StyledTableCell variant="body" style={{ width: '4%' }} />
        <StyledTableCell variant="body" style={{ width: '29%' }}>
          {t('invoices.include_delivery_fee')}
        </StyledTableCell>
        <StyledTableCell variant="body" style={{ width: '33%' }}>
          {`${parseFloat(deliveryFee / 1.2).toFixed(2)}€`}
        </StyledTableCell>
        <StyledTableCell variant="body" style={{ width: '33%' }}>
          {`${parseFloat((deliveryFee * 20) / 120).toFixed(2)}€`}
        </StyledTableCell>
      </StyledTableRow>
    );
  }

  if (typeHelpers.isPrePayment(invoiceType)) {
    return <div />;
  }

  return (
    <div style={{ display: 'flex', minWidth: '260px' }}>
      <Table style={{ minWidth: '260px' }}>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell style={{ width: '4%' }} />
            <StyledTableCell style={{ width: '29%' }}>
              {t('item.vat')}
            </StyledTableCell>
            <StyledTableCell style={{ width: '33%' }}>
              {t('invoices.base_net')}
            </StyledTableCell>

            <StyledTableCell style={{ width: '33%' }}>
              {t('item.vat_price')}
            </StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>

        <TableBody>
          {vatDetails.map(({ vat, net, totalVAT }, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell variant="body" style={{ width: '4%' }} />
              <StyledTableCell variant="body" style={{ width: '29%' }}>
                {`${vat.toFixed(2)} %`}
              </StyledTableCell>
              <StyledTableCell variant="body" style={{ width: '33%' }}>
                {`${NumberFormat.formatNumberByNation(net, language)}€`}
              </StyledTableCell>
              <StyledTableCell variant="body" style={{ width: '33%' }}>
                {`${NumberFormat.formatNumberByNation(totalVAT, language)}€`}
              </StyledTableCell>
            </StyledTableRow>
          ))}

          {renderDeliveryFee()}
        </TableBody>
      </Table>
    </div>
  );
};

export default VatTable;
