import spinnerService from 'services/SpinnerService';

export default (values, { setErrors, setStatus, setSubmitting, props }) => {
  const fetchData = () => {
    spinnerService.startSpinner();
    props.fetchItems().finally(() => spinnerService.endSpinner());
  };
  const cb = (err, item) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      if (err.message === 'Duplicate item exists')
        setErrors({
          internalError: props.t('settings_section.item.duplicate'),
        });
      else {
        setErrors({
          internalError: props.t('forms.internal_error'),
        });
      }
    } else {
      setStatus({ success: true });
      if (props.onCreated) {
        props.onCreated(item);
      }
      props.closeDialog();
      fetchData();
    }
  };

  if (props.importItem) {
    props.handleUpdateClient(values, props.id);
    props.closeDialog();
    setSubmitting(false);
  } else if (!values._id) {
    props.createItem([values], cb);
  } else {
    props.updateItem(values, cb);
  }
};
