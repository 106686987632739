/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  accountInformationWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },

  accountInformationText: {
    fontSize: '16px',
  },

  accountInformationContent: {
    fontSize: '16px',
    color: 'black',
  },
}));

export default useStyles;
