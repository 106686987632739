import { bindActionCreators } from 'redux';
import {
  createItem,
  updateItem,
  fetchItems,
} from '../../../actions/ItemsActions';
import { fetchLoggedUserCompanies } from '../../../actions/UserCompanyActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createItem,
      updateItem,
      fetchItems,
      fetchLoggedUserCompanies,
    },
    dispatch
  );
