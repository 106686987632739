import React, { useEffect } from 'react';
import { Typography, Box } from '@material-ui/core';

import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router-dom';
import AppSubscriptions from 'components/AppSubscriptions';
import UseLocalStorage from 'hooks/useLocalStorage';
import { LOCAL_STORAGE_SUBSCRIPTION } from 'config/Constants';
import { prepareQueryParams } from '../utils';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const ManagementSolution = ({ handleNext }) => {
  const history = useHistory();
  const location = useLocation();
  const { addItem: setSelectedSubscription } = UseLocalStorage(
    LOCAL_STORAGE_SUBSCRIPTION
  );
  const queryParams = new URLSearchParams(location.search);

  const changeUrl = (key, vlue) => {
    const params = prepareQueryParams(queryParams, key, vlue);
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
    if (queryParams.get('crr') === 'adf') {
      handleNext();
    }
  };

  useEffect(() => {
    changeUrl('type', 'mt');
    if (queryParams.get('crr') === 'adf') {
      handleNext();
    }
  }, []);

  const next = (subscriptionId) => {
    changeUrl('crr', 'adf');
    setSelectedSubscription({ subscriptionId });
    handleNext();
  };

  return <AppSubscriptions handleChoosePricing={next} />;
};

ManagementSolution.propTypes = {
  handleNext: PropTypes.func,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default ManagementSolution;
