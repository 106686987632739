import React, { useState } from 'react';
import './styles.css';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import spinnerService from 'services/SpinnerService';
import InternalActivityService from 'services/InternalActivityService';
import ConfirmDialog from 'components/ConfirmDialog';
import { globalDispatch } from 'store';
import { fetchInternalActivities } from 'actions/InternalActivitiesActions';
import ActivityItem from './ActivityItem';

const ActivitiesList = ({ activities = [], setActivitiesList }) => {
  const { t } = useTranslation();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [selectedActivity, setSelectedActivity] = useState({});

  const handleUpdateActivity = async (activity) => {
    try {
      spinnerService.startSpinner();
      if (activity?._id) {
        if (activity.company_id === 'template_fr') {
          const data = {
            ...activity,
            parentId: activity._id,
          };
          delete data._id;
          await InternalActivityService.createActivity(data);
        } else {
          const data = { ...activity };
          delete data._id;
          await InternalActivityService.updateActivity(activity);
        }
      } else {
        const data = {
          ...activity,
          description: activity.display,
          value: activity.display.toLowerCase().replaceAll(' ', '_'),
          parentId: '',
        };
        delete data.id;
        await InternalActivityService.createActivity(data);
      }
      globalDispatch(fetchInternalActivities());
    } catch (error) {
      throw new Error(error);
    } finally {
      spinnerService.resetSpinner();
    }
  };

  const handleOpenDeleteActivity = async (activity) => {
    setOpenConfirm(true);
    setSelectedActivity(activity);
  };

  const handleDelete = async (isConfirm) => {
    if (!isConfirm) return;

    if (selectedActivity?.id) {
      const filter = activities.filter(
        (item) => item?.id !== selectedActivity?.id
      );
      setActivitiesList(filter);
    } else {
      try {
        spinnerService.startSpinner();

        await InternalActivityService.deleteActivity(selectedActivity._id);
        const filter = activities.filter(
          (item) => item?._id !== selectedActivity._id
        );
        setActivitiesList(filter);
        globalDispatch(fetchInternalActivities());
      } catch (error) {
        throw new Error(error);
      } finally {
        spinnerService.resetSpinner();
      }
    }
  };

  return (
    <>
      <div className={'activitiesList'}>
        {activities?.map((activity) => (
          <ActivityItem
            key={activity._id || activity.id}
            activity={activity}
            onSave={handleUpdateActivity}
            openDelete={handleOpenDeleteActivity}
          />
        ))}
      </div>

      <ConfirmDialog
        toggleDialog={setOpenConfirm}
        confirmMessage={t('settings_section.analytical_fields.confirm_delete')}
        isShowDialog={openConfirm}
        onConfirm={handleDelete}
        isCancelConfirm={true}
      />
    </>
  );
};

ActivitiesList.propTypes = {
  activities: PropTypes.array,
  setActivitiesList: PropTypes.func,
};

export default ActivitiesList;
