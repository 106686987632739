import React from 'react';
import PropTypes from 'prop-types';

/* import MUI */

import { Box } from '@material-ui/core';

import BankBBFCreateCheckStepper from './BankBBFCreateCheckStepper';

const BankBBFDepositCheck = (props) => {
  const { handleReset, handleExit } = props;

  const renderIntroSectionHandler = () => (
    <BankBBFCreateCheckStepper
      handleExit={handleExit}
      handleReset={handleReset}
    />
  );

  return <Box>{renderIntroSectionHandler()}</Box>;
};

BankBBFDepositCheck.propTypes = {
  handleReset: PropTypes.func,
  handleExit: PropTypes.func,
};

export default BankBBFDepositCheck;
