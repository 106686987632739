import { captionBig, captionBigDisabled } from './appText';
import colors from '../../../constants/colors.json';

const style = {
  hiddenCheckbox: {
    display: 'none',
    visibility: 'hidden',
    opacity: 0,
  },

  contrainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    height: '30px',
  },

  secondaryCheckbox: {
    boxSizing: 'border-box',
    height: '15px',
    width: '15px',
    border: '1px solid #B2B2B2',
    borderRadius: '2px',
    marginRight: '7px',
    textAlign: 'center',
    cursor: 'pointer',
    '&:hover': {
      border: `1px solid ${colors.green}`,
    },
  },
  secondaryChecked: {
    marginRight: '7px',
    border: `1px solid ${colors.green}`,
    borderRadius: '2px',
    backgroundColor: colors.green,
    color: colors.white,
  },
  // '@media (min-width: 960px)': {
  //   secondaryChecked: {
  //     backgroundColor: 'pink',
  //   },
  // },
  primaryCheckbox: {
    boxSizing: 'border-box',
    height: '15px',
    width: '15px',
    border: '1px solid #B2B2B2',
    borderRadius: '2px',
    marginRight: '7px',
    textAlign: 'center',
    '&:hover': {
      border: `1px solid ${colors.blue}`,
    },
  },
  primaryChecked: {
    marginRight: '7px',
    border: `1px solid ${colors.blue}`,
    borderRadius: '2px',
    backgroundColor: colors.blue,
    color: colors.white,
  },

  disabled: {
    border: ' 1px solid #B2B2B2',
    borderRadius: '2px',
    backgroundColor: '#E8E8E8',
    '&:hover': {
      border: ' 1px solid #B2B2B2',
    },
  },
  text: {
    ...captionBig,
  },
  disabledText: {
    ...captionBigDisabled,
  },

  fullWidth: {
    width: '100%',
  },
};

export default style;
