import { UserRoleManagement as Rights } from '../../config/Rights';
import { UserRoleManagement as Feature } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreateUserRoleManagement = () => {
  return hasRequestedAccess(Rights.UserRoleManagementCreate);
};

export const userCanReadUserRoleManagement = () => {
  return hasRequestedAccess(Rights.UserRoleManagementRead);
};

export const userCanUpdateUserRoleManagement = () => {
  return hasRequestedAccess(Rights.UserRoleManagementUpdate);
};

export const userCanDeleteUserRoleManagement = () => {
  return hasRequestedAccess(Rights.UserRoleManagementDelete);
};

export const userHasUserRoleManagementAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
