import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@material-ui/core';

/* import assets */
import sepaImage from 'assets/images/sepa-logo.svg';

const DebitCreationFirstStep = (props) => {
  const { handleYesButton, handleNoButton } = props;
  const { t } = useTranslation();
  const translateDomain = 'debits_page.debits_creation_page';
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        padding: '20px',
      }}
    >
      <Box>
        <Typography variant="h5" align="center">
          {t(`${translateDomain}.title`)}
        </Typography>
        <Typography
          variant="body1"
          align="center"
          style={{
            padding: '20px',
          }}
        >
          {t(`${translateDomain}.sub_title`)}
        </Typography>
      </Box>

      <Box>
        <img src={sepaImage} />
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '100%',
        }}
      >
        <Button variant="contained" color="default" onClick={handleNoButton}>
          {t(`${translateDomain}.buttons.no`)}
        </Button>
        <Button variant="contained" color="primary" onClick={handleYesButton}>
          {t(`${translateDomain}.buttons.yes`)}
        </Button>
      </Box>

      <Box marginTop={3}>
        <Link to="#">{t(`${translateDomain}.hyperlink`)}</Link>
      </Box>
    </Box>
  );
};

DebitCreationFirstStep.propTypes = {
  handleYesButton: PropTypes.func,
  handleNoButton: PropTypes.func,
};

export default DebitCreationFirstStep;
