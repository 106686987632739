/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_ENTREPRENEUR_INDIVIDUEL, TYPE_SOCIETE } from 'constants/company';
import { Grid } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import PersonalInfo from '../Form/PersonalInfor';
import questionData from '../onboarding.json';
import CompanyType from './CompanyType';
import PricingPage from './PricingPage';
import QuestionsPage from './QuestionsPage';
import { prepareQueryParams, personalInfoCode, titleCode } from '../utils';
import ContractorInfo from '../Form/ContractorInfo';
import CompanyTypeOptions from './CompanyTypeOptions';
import storagePersonalInfo from '../Form/storagePersonalInfor';

const OnboardTypes = ({
  handleNext,
  data,
  setData,
  personalInfo,
  setPersonalInfo,
  setIsFeature,
}) => {
  const curPersonalInfo = JSON.parse(
    localStorage.getItem('personalInfo') || '{}'
  );

  const location = useLocation();
  const history = useHistory();

  const [isInputtedForm, setIsInputtedForm] = React.useState(false);
  const [questions, setQuestions] = React.useState([]);
  const [companyTypeChoosed, setCompanyTypeChoosed] = React.useState(false);
  const [personalInforType, setPersonalInforType] = React.useState(
    curPersonalInfo?.type || null
  );
  const [isShowPricing, setIsShowPricing] = React.useState(false);

  const queryParams = new URLSearchParams(location.search);
  const act = queryParams.get('act');
  const notCompanies = ['li', 'lm', 'a'];
  const companies = ['s', 'c'];
  const [companyType, setCompanyType] = React.useState(
    companies.includes(act)
      ? TYPE_SOCIETE
      : act === 'i'
      ? TYPE_ENTREPRENEUR_INDIVIDUEL
      : ''
  );

  const clearQueryParams = () => {
    history.replace({
      pathname: location.pathname,
      search: new URLSearchParams({}).toString(),
    });
  };

  const handleLoadQuestions = (questionList) => {
    const q = queryParams.get('q');
    const questionAnswerIndex = q.split('');
    const questionsData =
      questionList[
        `${!personalInforType ? 'future_' : ''}${data.account_type}`
      ];
    if (q && questionAnswerIndex.length === questionsData.length) {
      const personalInfoParse = questionAnswerIndex.reduce(
        (prev, curr, idx) => {
          if (
            // eslint-disable-next-line radix
            parseInt(curr) > questionsData[idx].answers.length - 1 ||
            // eslint-disable-next-line radix
            parseInt(curr) < 0
          ) {
            return prev;
          }
          // eslint-disable-next-line radix
          const answer = questionsData[idx].answers[parseInt(curr)];
          return { ...prev, [questionsData[idx].key]: answer };
        },
        {}
      );
      if (Object.keys(personalInfoParse).length === questionsData.length) {
        setPersonalInfo(personalInfoParse);
        handleNext();
      } else {
        clearQueryParams();
      }
    } else {
      clearQueryParams();
    }
  };

  // TODO: Mokup
  React.useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    const questionList = {
      sci: questionData.SCI,
      commercial_company: questionData['Société Commerciale'],
      liberal_professional: questionData['Activité Libérale'],
      lmp_lmnp: questionData['LMP/LMNP'],
      individual_entrepreneur: questionData['Entreprise Individuelle'],
      association: questionData.Association,

      future_sci: questionData['future SCI'],
      future_commercial_company: questionData['future Société Commerciale'],
      future_liberal_professional: questionData['future Activité Libérale'],
      future_lmp_lmnp: questionData['future LMP/LMNP'],
      future_individual_entrepreneur:
        questionData['future Entreprise Individuelle'],
      future_association: questionData.future_Association,
    };
    setQuestions(questionList);

    const crr = queryParams.get('crr');
    if (crr === 'adf') {
      // setCompanyTypeChoosed(true);
    }
    if (crr === 'pr') {
      setIsShowPricing(true);
    }
    if (crr === 'qp') {
      setIsInputtedForm(true);
    }

    if (crr === 'fn' || crr === 'rl') {
      handleLoadQuestions(questionList);
    }
  }, []);

  const handleSetPersonalInfor = (values) => {
    const newPersonalInfo = Object.entries(personalInfoCode).reduce(
      (prev, curr) => {
        if (curr[0] === 't') {
          const titleValue = Object.entries(titleCode).find(
            (item) => item[1] === values[curr[1]]
          );
          return { ...prev, [curr[0]]: titleValue[0] };
        }
        return { ...prev, [curr[0]]: values[curr[1]] };
      },
      {}
    );
    const newQueryParams = prepareQueryParams(
      queryParams,
      'crr',
      'pr',
      newPersonalInfo
    );
    history.replace({
      pathname: location.pathname,
      search: newQueryParams.toString(),
    });
    setIsShowPricing(true);
    setPersonalInfo(values);
  };

  const handleChooseCompanyType = (value) => {
    const newQueryParams = prepareQueryParams(queryParams, 'crr', 'adf');
    history.replace({
      pathname: location.pathname,
      search: newQueryParams.toString(),
    });
    setCompanyTypeChoosed(true);
    setPersonalInforType(!!value);
    setIsFeature(!!value);

    storagePersonalInfo({ type: !!value });
  };

  const handlePricingClick = () => {
    const newQueryParams = prepareQueryParams(queryParams, 'crr', 'qp');
    history.replace({
      pathname: location.pathname,
      search: newQueryParams.toString(),
    });
    setIsInputtedForm(true);
  };

  const pricingType = () => {
    if (personalInforType) {
      return `future_${data.account_type}`;
    }
    return data.account_type;
  };

  const companyIsContractor = (typeOfCompany) => {
    const companyTypeIsContractor = ['sci', 'commercial_company'];
    return !companyTypeIsContractor.includes(typeOfCompany);
  };

  const renderCompanyForm = () => {
    if (!companyTypeChoosed) return null;
    if (companyIsContractor(data?.account_type)) {
      return (
        <ContractorInfo
          isAccountInfo
          require={true}
          setPersonalInfo={handleSetPersonalInfor}
          isRaisonSociale={personalInforType}
          companyType={companyType}
        />
      );
    }
    return (
      <PersonalInfo
        setPersonalInfo={handleSetPersonalInfor}
        require={true}
        isRaisonSociale={personalInforType}
        companyType={companyType}
      />
    );
  };

  return (
    <Grid
      container
      alignItems="center"
      alignContent="center"
      xs={12}
      direction="row"
      style={{ justifyContent: 'center', margin: 'auto' }}
    >
      {isInputtedForm ? (
        <QuestionsPage
          handleNext={handleNext}
          data={data}
          setData={setData}
          personalInfo={personalInfo}
          questionData={
            questions[
              `${!personalInforType ? 'future_' : ''}${data.account_type}`
            ]
          }
        />
      ) : (
        <>
          {!isShowPricing ? (
            !notCompanies.includes(act) || companyType ? (
              <>
                <CompanyType
                  handleChooseCompanyType={handleChooseCompanyType}
                  data={data}
                />
                {renderCompanyForm()}
              </>
            ) : (
              <CompanyTypeOptions
                setCompanyType={(e) => {
                  setCompanyType(e);
                }}
                ignoreCompany
              />
            )
          ) : (
            <PricingPage
              type={pricingType()}
              onClick={handlePricingClick}
              isDescriptions
            />
          )}
        </>
      )}
    </Grid>
  );
};

OnboardTypes.propTypes = {
  handleNext: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
  personalInfo: PropTypes.object,
  setPersonalInfo: PropTypes.func,
  setIsFeature: PropTypes.func,
};

export default OnboardTypes;
