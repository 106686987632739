import { useReducer, useEffect } from 'react';

const mergeLocalStorageDefaultValue = (value, defaultValue) => {
  const { user: localUser, company: localCompany } = value;
  const { user: defaultUser, company: defaultCompany } = defaultValue;
  // eslint-disable-next-line
  for (const [key, val] of Object.entries(localCompany)) {
    if (defaultCompany[key] && val !== defaultCompany[key]) {
      localCompany[key] = defaultCompany[key];
    }
  }
  // eslint-disable-next-line
  for (const [key, val] of Object.entries(localUser[0])) {
    if (defaultUser[0][key] && val !== defaultUser[0][key]) {
      localUser[0][key] = defaultUser[0][key];
    }
  }
  return { localCompany, localUser };
};

function useLocalStorageReducer(key, reducer, defaultValue) {
  const [state, dispatch] = useReducer(reducer, defaultValue, () => {
    let value;
    try {
      value = JSON.parse(
        window.localStorage.getItem(key) || String(defaultValue)
      );
      const { localUser, localCompany } = mergeLocalStorageDefaultValue(
        value,
        defaultValue
      );
      value = { ...value, user: localUser, company: localCompany };
    } catch (e) {
      value = defaultValue;
    }
    return value;
  });

  useEffect(() => {
    if (Object.prototype.hasOwnProperty.call(state, 'cguTreezor')) {
      window.localStorage.setItem(key, JSON.stringify(state));
    }
  }, [key, state]);

  return [state, dispatch];
}

export default useLocalStorageReducer;
