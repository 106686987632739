import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CustomGenericDialog from '../../../../../components/CustomGenericDialog';
import ItemForm from '../../../../Item/Form';

const ItemFormDialog = ({
  showNewItemDialog,
  toggleNewItemDialog,
  setNewItem,
}) => {
  const { t } = useTranslation();

  if (!showNewItemDialog) {
    return null;
  }

  return (
    <CustomGenericDialog
      title={t('item.dialog_title')}
      color="secondary"
      iconClose
      closeDialog={toggleNewItemDialog}
    >
      <ItemForm closeDialog={toggleNewItemDialog} onCreated={setNewItem} />
    </CustomGenericDialog>
  );
};

ItemFormDialog.propTypes = {
  showNewItemDialog: PropTypes.bool.isRequired,
  toggleNewItemDialog: PropTypes.func.isRequired,
  setNewItem: PropTypes.func.isRequired,
};

export default ItemFormDialog;
