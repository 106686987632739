import { useMemo } from 'react';

export default function useQueryParams() {
  return useMemo(() => {
    if (window?.location?.search) {
      const searchParams = new URLSearchParams(window.location.search);
      return Object.fromEntries(searchParams.entries());
    }
    return {};
  }, [window?.location?.search]);
}
