import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appTableComponents';

const AppTableHeader = ({ classes, children, greyHeader, useStyle }) => {
  const tableBodyClasses = classNames({
    [classes.tableHead]: true,
  });
  const header = true;
  const childrenWithProps = React.Children.map(children, (child) =>
    React.cloneElement(child, {
      header,
      greyHeader,
    })
  );

  return (
    <thead className={tableBodyClasses} style={useStyle}>
      {childrenWithProps}
    </thead>
  );
};
AppTableHeader.propTypes = {
  classes: PropTypes.object.isRequired,

  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  header: PropTypes.bool,
  greyHeader: PropTypes.bool,
  useStyle: PropTypes.object,
};
AppTableHeader.defaultProps = {
  header: false,
  greyHeader: false,
};

export default withStyles(style)(AppTableHeader);
