import { Box, Tab, Tabs, useTheme } from '@material-ui/core';
import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import AppButton from 'components/AppButton';
import ExpenseByCategoriesChart from '../ExpenseByCategories/ExpenseByCategoriesChart';
import ActivityDebtsChart from '../ActivityDebtsChart';

const useStyles = makeStyles((theme) => ({
  tabContainer: {
    width: '100%',
    height: '100%',
  },
  tabs: {
    borderRadius: '8px',
    boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
    width: 'fit-content',
    backgroundColor: theme.palette.background.default,
  },
  tab: {
    '&:not(:last-child)': {
      borderRight: `1px solid ${theme.palette.neutral[200]}`,
    },
    textTransform: 'none',
    fontWeight: 500,
    fontSize: '1rem',
  },
  activeTab: {
    color: theme.palette.secondary.dark,
    backgroundColor: theme.palette.secondary[50],
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    alignSelf: 'stretch',
  },
}));

const ExpenseTabs = (props) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [chartDataType, setChartDataType] = useState('all');
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    companyId,
    appInitialized,
    fetchExpenseByCategories,
    hasExpenseValidateAccess,
    expenseByCategories,
  } = props;
  const tabList = [
    {
      title: t('turn_over.expenses'),
      component: (
        <ExpenseByCategoriesChart
          companyId={companyId}
          fetchExpenseByCategories={fetchExpenseByCategories}
          hasExpenseValidateAccess={hasExpenseValidateAccess}
          appInitialized={appInitialized}
          expenseByCategories={expenseByCategories}
          chartDataType={chartDataType}
        />
      ),
    },
    {
      title: t('dasboard.dept_activity'),
      component: <ActivityDebtsChart />,
    },
  ];
  const classes = useStyles(props);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const toggleChartDataType = useCallback(() => {
    if (chartDataType === 'all') {
      return setChartDataType('top10');
    }
    return setChartDataType('all');
  }, [chartDataType]);

  return (
    <Box className={classes.tabContainer}>
      <Box className={classes.tabsContainer}>
        <Tabs
          value={tabIndex}
          onChange={handleChange}
          indicatorColor="primary"
          variant="standard"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            style: { display: 'none' },
          }}
          className={classes.tabs}
        >
          {tabList.map((item, index) => {
            return (
              <Tab
                key={index}
                label={item.title}
                className={classNames(classes.tab, {
                  [classes.activeTab]: index === tabIndex,
                })}
              />
            );
          })}
        </Tabs>
        <AppButton
          text={t('dasboard.top10')}
          style={{
            minWidth: 'fit-content',
            backgroundColor: theme.palette.primary.hover,
          }}
          onClick={toggleChartDataType}
        />
      </Box>
      {tabList[tabIndex]?.component}
    </Box>
  );
};

ExpenseTabs.propTypes = {
  companyId: PropTypes.string,
  appInitialized: PropTypes.bool,
  hasExpenseValidateAccess: PropTypes.bool,
  expenseByCategories: PropTypes.object.isRequired,
  fetchExpenseByCategories: PropTypes.func.isRequired,
};

export default ExpenseTabs;
