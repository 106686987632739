import { compareQs } from 'helpers/querystringHelper';
import { findIndex } from 'lodash';

import {
  LOGOUT_USER_SUCCESS,
  CREATE_EXPENSE,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
  PENDING_EXPENSE,
  VALIDATE_EXPENSE,
  CANCEL_EXPENSE,
  PAY_EXPENSE,
  LOGGED_USER_COMPANY_SELECTED,
  FETCH_EXPENSES_PAGINATE,
  REFRESH_EXPENSES_PAGINATE,
  ADD_TRANSFERS_TO_EXPENSE_PAGINATE,
} from '../../actions/Types';

const INITIAL_STATE = { docs: [] };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REFRESH_EXPENSES_PAGINATE:
    case FETCH_EXPENSES_PAGINATE: {
      if (compareQs(action, state) && action.page === state.page + 1) {
        return {
          ...state,
          docs: [...state.docs, ...action.docs],
          page: action.page,
        };
      }
      return {
        docs: action.docs,
        filters: action.filters,
        page: action.page,
        limit: action.limit,
        search: action.search,
        orderBy: action.orderBy,
        orderDirection: action.orderDirection,
        totalCount: action.totalCount,
      };
    }
    case CREATE_EXPENSE: {
      return {
        ...state,
        docs: [action.payload, ...state.docs],
        totalCount: state.totalCount + 1,
      };
    }
    case UPDATE_EXPENSE:
    case PENDING_EXPENSE:
    case VALIDATE_EXPENSE:
    case CANCEL_EXPENSE:
    case PAY_EXPENSE: {
      const newDocs = state.docs;
      const index = findIndex(
        state.docs,
        (doc) => doc._id === action.payload.expense._id
      );
      if (index !== -1)
        newDocs[index] = { ...state.docs[index], ...action.payload.expense };

      return {
        ...state,
        docs: newDocs,
      };
    }
    case DELETE_EXPENSE: {
      const newDocs = state.docs;
      const index = findIndex(state.docs, (doc) => doc._id === action.payload);
      if (index !== -1) newDocs.splice(index, 1);

      return {
        ...state,
        docs: newDocs,
        totalDocs: state.totalDocs - 1,
      };
    }

    case ADD_TRANSFERS_TO_EXPENSE_PAGINATE: {
      const { expenseOId, transfers } = action.payload;

      const updatedDocs = [...state.docs];

      const foundIdx = updatedDocs.findIndex((doc) => doc._id === expenseOId);

      if (foundIdx > -1) {
        updatedDocs[foundIdx] = { ...updatedDocs[foundIdx], transfers };
      }

      return {
        ...state,
        docs: updatedDocs,
      };
    }

    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return { INITIAL_STATE };
    default:
      return state;
  }
};
