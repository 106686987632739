import React, { Fragment, useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import AppButton from '../../../../../components/AppButton';

import { useInvoiceFormContext } from '../../../../../hooks/invoiceHooks';

import { userCanCreateItem } from '../../../../../selectors/rightsSelector/itemRequests';

const CreateActions = ({ separatorStyle }) => {
  const { toggleNewClientDialog, toggleNewItemDialog, newItem, newClient } =
    useInvoiceFormContext();
  const { t } = useTranslation();
  const { isSubmitting, setFieldValue } = useFormikContext();
  const canCreateItem = useSelector(userCanCreateItem);

  const { item, removeNewItem } = newItem;
  useEffect(() => {
    if (item) {
      // setFieldValue('items', [
      //   ...values.items,
      //   {
      //     ...item,
      //     ...{
      //       quantity: 1,
      //       discount: 0,
      //       unit: t(item.unit),
      //       unit_price: item.net_price,
      //       vat_price:
      //         parseFloat(item.gross_price) - parseFloat(item.net_price),
      //     },
      //   },
      // ]);
      removeNewItem();
    }
  }, [item]);

  const { client, removeNewClient } = newClient;
  useEffect(() => {
    if (client) {
      setFieldValue('client', client._id);
      setFieldValue('address', client.address);
      removeNewClient();
    }
  }, [client]);

  function renderNewItem() {
    if (!canCreateItem) {
      return null;
    }

    return (
      <Fragment>
        <AppButton
          fullWidth
          color="secondaryLight"
          text={`+ ${t('item.title')}`}
          isDisabled={isSubmitting}
          onClick={toggleNewItemDialog}
        />

        <div className={separatorStyle} />
      </Fragment>
    );
  }

  return (
    <Fragment>
      {renderNewItem()}
      <AppButton
        fullWidth
        color="secondaryLight"
        text={`+ ${t('settings_section.clients.create')}`}
        isDisabled={isSubmitting}
        onClick={toggleNewClientDialog}
      />
    </Fragment>
  );
};

CreateActions.propTypes = {
  separatorStyle: PropTypes.string,
};

export default CreateActions;
