/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext, Field } from 'formik';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';
import { filter, flatten, sortBy } from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { WarningSharp } from '@material-ui/icons';

/* Import models */
import { updateMentions } from 'models/invoice';

/* Import components */
import DatePicker from 'components/FormElementsDesigned/DatePicker';
import TextField from 'components/FormElements/textField';
import AppClientsDropdown from 'components/AppClients/AppCientsDropdown';
import SelectAutocomplete from 'components/SelectAutocomplete';

/* Import helpers */
import { prettyInvoiceNumber } from 'helpers/utils';

/* Import constants */
import colors from 'constants/colors.json';

const useStyles = makeStyles(() => ({
  titleText: {
    color: colors.textDarkGrey,
    margin: 0,
    fontFamily: 'Kumbh Sans',
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 'bold',
  },
  marginSM: {
    marginTop: '7px',
  },
}));

const ClientInfo = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { isSubmitting, values, errors, touched, setFieldValue } =
    useFormikContext();

  const [intracomVAT, setIntracomVAT] = useState('');
  const [siret, setSiret] = useState('');

  let clientData = useSelector((state) => state.clients.data);
  clientData = filter(sortBy(clientData, 'name'), (o) => !o.deleted);

  const clientProjects = useSelector((state) => state.clientProjects.projects);

  const clients = {};
  clientData.forEach((element) => {
    clients[element._id] = element;
  });

  const getListProject = () => {
    const projects = [];
    Object.keys(clientProjects).forEach((item, idx) =>
      projects.push(clientProjects[item])
    );
    const flattenProject = flatten(projects);
    return flattenProject;
  };

  const projects = getListProject();

  const { invoice_nb: invoiceNumber, type, client, company } = values;

  if (client === '') {
    setFieldValue('client', {});
  }

  if (client) {
    values.special_mentions = updateMentions(company, client);
  }

  useEffect(() => {
    const project = projects.find(
      (item) => item.purchaseOrderNumber === values.clientRef
    );
    if (project) {
      setFieldValue('project', project._id);
      // setFieldValue('projectName', project.name);
      setFieldValue('client', clients[project.client_id]);
    }
  }, [values.clientRef]);

  useEffect(() => {
    setIntracomVAT(client?.intracom_vat || 'N/A');
    setSiret(client?.siret || 'N/A');
  }, [client]);

  useEffect(() => {
    if (
      client &&
      client?.billingAddress !== undefined &&
      client?.billingAddress.length !== 0 &&
      Object.keys(client?.billingAddress).length !== 0
    ) {
      setFieldValue('clientHasDifferentBillingAddress', true);
      setFieldValue(
        'client.billingAddress.address',
        client?.billingAddress?.address
      );
      setFieldValue('client.billingAddress.city', client?.billingAddress.city);
      setFieldValue(
        'client.billingAddress.zipcode',
        client?.billingAddress?.zipcode
      );
      setFieldValue(
        'client.billingAddress.country',
        client?.billingAddress?.country
      );
    } else {
      setFieldValue('clientHasDifferentBillingAddress', false);
    }
  }, [client]);

  function handleDateChange(dateSelected) {
    setFieldValue('date', dateSelected);
  }

  const onSetProject = (value) => {
    if (value) {
      setFieldValue('project', value._id);
      setFieldValue('projectName', value.name);
      setFieldValue('clientRef', value.purchaseOrderNumber);
    }
  };

  const isProjectExist = projects.some(
    (project) => project.client_id === values.client?._id
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <Grid item xs={12} style={{ marginTop: '8px' }}>
          {invoiceNumber ? (
            <div className={classes.titleText}>
              {t(`invoices.types.${type}`)} {t('invoices.invoice_number')}{' '}
              {invoiceNumber ? prettyInvoiceNumber(invoiceNumber) : '-'}
            </div>
          ) : (
            <div className={classes.titleText}>
              {t(`invoices.types.${type}`)} {t('invoices.invoice_number_tba')}
            </div>
          )}
        </Grid>

        <Grid item xs={12}>
          <Field
            type="date"
            name="date"
            keyboard={true}
            component={DatePicker}
            onChange={(date) => handleDateChange(date)}
          />
        </Grid>
        <div className={classes.marginSM} />

        <div className={classes.marginSM} />

        <Grid item xs={12}>
          <AppClientsDropdown
            values={clients}
            clients={clients}
            t={t}
            setFieldValue={setFieldValue}
            chosenClient={client}
            showError={errors.client && touched.client && !client}
            errorMessage={errors.client}
            mandatory
            fullWidth
          />
        </Grid>
        <div className={classes.marginSM} />

        <div className={classes.marginSM} />

        <div className={classes.marginSM} />

        {/* row with VAT checkbox, and if checked, shows the field to enter the VAT Intracom number */}
        <Grid item container xs={12} alignItems="center">
          {client?.intracom_vat ? (
            <TextField
              label={t('settings_section.clients.form_label_vat')}
              placeholder={t('settings_section.clients.form_label_vat')}
              color="secondary"
              name="intracom_vat"
              value={client?.intracom_vat}
              disabled={true}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginTop: '10px',
              }}
            >
              <WarningSharp color="error" style={{ marginRight: '5px' }} />
              <Typography color="error">
                {t('settings_section.clients.no_intracom_vat')}
              </Typography>
            </div>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="reference"
            label={t('invoices.header.reference_info')}
            placeholder="Reference"
            helperText={t('invoices.header.reference_helper')}
            color="secondary"
          />
        </Grid>
        <Grid item xs={12}>
          <SelectAutocomplete
            getOptionLabel={(element) => element.name}
            name="project"
            placeholder={t('invoices.list.project')}
            values={
              values.client?._id
                ? projects.filter(
                    (project) => project.client_id === values.client?._id
                  )
                : []
            }
            valueSelected={projects.find(
              (project) => project._id === values.project
            )}
            onChange={onSetProject}
            readOnly={!isProjectExist}
          />
        </Grid>
        {!isProjectExist && (
          <Typography color={'error'}>
            {t('invoices.header.project_not_exist')}
          </Typography>
        )}
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="clientRef"
            placeholder="Bon de commande"
            label={t('Bon de commande')}
            helperText={t('invoices.header.bon_de_commande_helper')}
            color="secondary"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="description"
            label={t('invoices.description')}
            placeholder={t('invoices.description')}
            color="secondary"
            disabled={isSubmitting}
          />
        </Grid>

        <div className={classes.marginSM} />

        <>
          <Grid item xs={12}>
            <TextField
              label={t('settings_section.clients.form_label_siret')}
              placeholder={t('settings_section.clients.form_label_siret')}
              color="secondary"
              name="siret"
              value={client?.siret ? client?.siret : 'N/A'}
              disabled={true}
            />
          </Grid>
        </>
      </Grid>
    </Grid>
  );
};

export default ClientInfo;
