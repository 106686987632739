import React from 'react';
import { useTranslation } from 'react-i18next';
import Header from '../../../components/Header';
import { useRouter } from '../../../hooks/routerHooks';

const ProviderImportedHeader = () => {
  const { t } = useTranslation();
  const { history } = useRouter();

  return (
    <Header
      name={t('settings_section.providers.import_providers')}
      spaceBetween
      goBack={() => {
        history.goBack();
      }}
    ></Header>
  );
};

export default ProviderImportedHeader;
