import {
  FETCH_ROLES,
  FETCH_ROLES_SUCCESS,
  FETCH_ROLES_FAILED,
  CREATE_ROLE,
  UPDATE_ROLE,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

const INITIAL_STATE = {
  roles: [],
  isFetchingRoles: false,
  errorFetchingRoles: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ROLES:
      return {
        ...state,
        isFetchingRoles: true,
        errorFetchingRoles: null,
      };

    case FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload || [],
        isFetchingRoles: false,
        errorFetchingRoles: null,
      };

    case CREATE_ROLE:
      return {
        ...state,
        roles: [...state.roles, action.payload],
      };

    case UPDATE_ROLE: {
      const index = state.roles.findIndex((r) => r._id === action.payload.id);

      const { roles } = state;
      roles[index] = action.payload.data;

      return {
        ...state,
        roles,
      };
    }

    case FETCH_ROLES_FAILED:
      return {
        ...state,
        roles: action.payload || [],
        isFetchingRoles: false,
        errorFetchingRoles: action.payload,
      };
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return INITIAL_STATE;
    default:
      return state;
  }
};
