import { mWhiteBold } from './appText';
import colors from '../../../constants/colors.json';

const style = {
  cursorPointer: {
    cursor: 'pointer',
  },

  cursorDefault: {
    cursor: 'default',
  },
  button: {
    ...mWhiteBold,
    border: 'none',
    margin: '0',
    marginBottom: '8px',
    height: '40px',
    padding: '0 12px',
    textDecoration: 'none',
    width: '100%',
    backgroundColor: colors.blue,
    textTransform: 'uppercase',
    textAlign: 'left',
    fontWeight: 'normal',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgba(255,255,255,0.2)',
      color: 'white',
    },
    '&:active': {
      cursor: 'auto',
      backgroundColor: colors.white,
      color: colors.blue,
      fontWeight: 'bold',
    },
    '&:focus': {
      backgroundColor: colors.white,
      color: colors.blue,
      fontWeight: 'bold',
    },
    '&:disabled': {
      cursor: 'not-allowed',
      backgroundColor: colors.blue,
      color: 'rgba(255,255,255,0.2)',
    },
  },
  companyButton: {
    ...mWhiteBold,
    border: 'none',
    margin: '0',
    padding: '0 12px',
    textDecoration: 'none',
    height: '60px',
    width: '100%',
    backgroundColor: colors.blue,
    textTransform: 'uppercase',
    textAlign: 'left',
    fontWeight: 'normal',
  },
  pageDisplayed: {
    backgroundColor: '#EFEFEFEF',
    color: colors.blue,
    fontWeight: 'bold',
    '&:hover': {
      backgroundColor: colors.white,
      color: colors.blue,
      fontWeight: 'bold',
    },
    '&:active': {
      backgroundColor: colors.white,
      color: colors.blue,
      fontWeight: 'bold',
    },
    '&:focus': {
      backgroundColor: colors.white,
      color: colors.blue,
      fontWeight: 'bold',
    },
  },

  companyButtonText: {
    paddingTop: '10px',
  },

  buttonText: {
    display: 'block',
  },
  icon: {
    display: 'none',
  },
  selectedCompanyElem: {
    backgroundColor: 'rgba(255,255,255,0.2)',
  },
  '@media (max-width: 1180px)': {
    button: {
      fontSize: '10px',
    },
  },
  '@media (max-width: 1023px)': {
    button: {
      textAlign: 'center',
      height: '50px',
    },
    buttonText: {
      display: 'none',
    },
    icon: {
      display: 'block',
      fontSize: '30px',
      paddingTop: '10px',
      textAlign: 'center',
    },
  },
  '@media (max-width: 767px)': {
    button: {
      textAlign: 'center',
      height: '60px',
    },
    icon: {
      fontSize: '20px',
    },
  },
};

export default style;
