import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appTableComponents';

const AppTableBody = ({ classes, children }) => {
  const tableBodyClasses = classNames({
    [classes.tableBody]: true,
  });

  return <tbody className={tableBodyClasses}>{children}</tbody>;
};
AppTableBody.propTypes = {
  classes: PropTypes.object.isRequired,

  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  header: PropTypes.bool,
};
AppTableBody.defaultProps = {
  header: false,
};

export default withStyles(style)(AppTableBody);
