import React, { useEffect, useState } from 'react';

// import { getPDFHistory } from 'actions/PDFActions';
import Header from 'components/Header';
import Layout from 'components/Layout';
import MaterialTable from 'components/MaterialTable';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import axios from 'axios';

/* Import actions */
import { getDepositPoolItems } from 'actions/PDFActions';

import { UnArchiveIcon, DownloadIcon } from 'components/AppIcons';

/* Import services */
import S3FileService from 'services/S3FileService';
import PDFService from 'services/PDFService';
import { DEFAULT_PAGESIZE } from 'config/Constants';
import logger from 'helpers/logger';

const ArchivedDepositBody = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedUser = useSelector((state) => state.loggedUser.user);
  const [tableLoading, setTableLoading] = useState(false);
  const [depositsData, setDepositsData] = useState({
    data: [],
    page: 0,
    total: 0,
  });
  const [pageSize, setPageSize] = useState(DEFAULT_PAGESIZE);

  const [isRefresh, setRefresh] = useState(0);

  const formatDepositTableData = (data) => {
    const tableDatas = data.map((item) => {
      const tableItem = {
        _id: item._id,
        tags: item.tags,
        filename: item.customFileName || item.originalFileKey,
        originalFileKey: item.originalFileKey,
        created_at: item.created_at,
      };
      return tableItem;
    });
    return tableDatas;
  };

  const fetchDepositData = async () => {
    try {
      setTableLoading(true);
      const response = await dispatch(
        getDepositPoolItems(loggedUser.id, {
          pageSize,
          page: depositsData.page,
          isArchived: true,
          // filters: {
          //   tag: tagsFilter,
          // },
        })
      );
      const responseData = response.data || [];
      const data = formatDepositTableData(responseData);
      setDepositsData({
        ...depositsData,
        data: [...data],
        total: response.totalCount,
      });
    } catch (error) {
      logger.error(error?.message);
    } finally {
      setTableLoading(false);
    }
  };

  useEffect(() => {
    fetchDepositData();
  }, [isRefresh]);

  const downloadFile = async (fileURL, fileName) => {
    axios.get(fileURL, { responseType: 'blob' }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.addEventListener(
        'focus',
        () => {
          URL.revokeObjectURL(link.href);
        },
        {
          once: true,
        }
      );
      setTableLoading(false);
    });
  };

  const onUnarchive = async (depositPDFPoolId) => {
    try {
      setTableLoading(true);
      await PDFService.archiveDepositPoolItemFile({
        isArchived: false,
        depositPDFPoolId,
      });

      // Re-fetch all data
      await fetchDepositData();
      setTableLoading(false);
    } catch (error) {
      setTableLoading(false);
    }
  };

  const columns = [
    {
      title: t('File name'),
      field: 'filename',
      sorting: false,
    },
    {
      title: ' ',
      field: 'functions',
      sorting: false,

      // eslint-disable-next-line
      render: ({ originalFileKey, _id }) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <DownloadIcon
            sm
            onClick={async () => {
              setTableLoading(true);
              const { url } = await S3FileService.geturl(originalFileKey);
              downloadFile(url, originalFileKey);
            }}
          />
          <UnArchiveIcon sm onClick={() => onUnarchive(_id)} />
        </div>
      ),
      editable: 'never',
    },
  ];

  return (
    <MaterialTable
      title={t('deposit.table_title')}
      columns={columns}
      page={depositsData.page}
      onChangePage={(p) => {
        setDepositsData({ ...depositsData, page: p });
        setRefresh((prev) => prev + 1);
      }}
      totalCount={depositsData.total}
      isLoading={tableLoading}
      data={depositsData.data}
      options={{ pageSize }}
      onChangeRowsPerPage={(ps) => {
        setPageSize(ps);
        setRefresh((prev) => prev + 1);
      }}
    />
  );
};

const ArchivedDeposit = () => {
  const history = useHistory();
  const { t } = useTranslation();
  // const { id: originalId } = useParams();

  return (
    <Layout
      header={
        <Header
          name={t('pdf_purchase.archived_files')}
          spaceBetween
          goBack={() => {
            history.goBack();
          }}
        />
      }
      body={<ArchivedDepositBody />}
      showUserCard={true}
    />
  );
};

export default ArchivedDeposit;
