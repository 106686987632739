import { selectLoggedUserCompanyId } from 'selectors/loggedUserCompanySelectors';
import service from 'services/InvoiceService';

import {
  GET_AGING_BALANCE,
  GET_AGING_BALANCE_SUCCESS,
  GET_AGING_BALANCE_FAIL,
  GET_AGING_BALANCE_OVERDUE,
  GET_AGING_BALANCE_OVERDUE_SUCCESS,
  GET_AGING_BALANCE_OVERDUE_FAIL,
} from '../Types';

export const getAgingBalance = () => async (dispatch, getState) => {
  const companyId = selectLoggedUserCompanyId(getState());
  if (!companyId) {
    return;
  }
  dispatch({ type: GET_AGING_BALANCE });
  try {
    const res = await service.getAgingBalance();
    dispatch({ type: GET_AGING_BALANCE_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: GET_AGING_BALANCE_FAIL, payload: error });
  }
};

export const getAgingBalanceOverdue = () => async (dispatch, getState) => {
  const companyId = selectLoggedUserCompanyId(getState());
  if (!companyId) {
    return;
  }
  dispatch({ type: GET_AGING_BALANCE_OVERDUE });
  try {
    const res = await service.getAgingBalanceOverdue();
    dispatch({ type: GET_AGING_BALANCE_OVERDUE_SUCCESS, payload: res.data });
  } catch (error) {
    dispatch({ type: GET_AGING_BALANCE_OVERDUE_FAIL, payload: error });
  }
};
