import React from 'react';
import PropTypes from 'prop-types';
// import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, Grid, CardMedia } from '@material-ui/core';
import { CreditCard } from '@material-ui/icons';

// const useStyles = makeStyles(() => ({
//   userCard: {
//     width: '300px',
//     height: '145px',
//     display: 'flex',
//     alignItems: 'center',
//   },
// }));

const UserCard = ({ user, hasCard, createCard }) => {
  // const classes = useStyles();
  return (
    <Grid
      item
      xs={12}
      sm={6}
      md={4}
      style={{
        cursor: 'pointer',
        margin: '0 auto',
        width: '350px',
      }}
    >
      <Card onClick={() => createCard(user)}>
        <CardContent style={{ display: 'flex', position: 'relative' }}>
          {user.display_name}
          <CardMedia style={{ position: 'absolute', right: '5%' }}>
            {hasCard && <CreditCard />}
          </CardMedia>
        </CardContent>
      </Card>
    </Grid>
  );
};

UserCard.propTypes = {
  user: PropTypes.object,
  hasCard: PropTypes.bool,
  createCard: PropTypes.func,
};

export default UserCard;
