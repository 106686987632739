import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { BLOCK_PIN } from 'config/Constants';
import {
  CircularProgress,
  Card,
  CardActions,
  CardContent,
  Box,
  Tabs,
  Tab,
  Grid,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Switch,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Chip,
  Tooltip,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import LockIcon from '@material-ui/icons/Lock';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import LoadingDialog from 'components/LoadingDialog';
import OTPDialog from 'components/OTPDialog';

import logger from 'helpers/logger';

import {
  lockUnlockCard,
  fetchDetailCard,
  getOTP,
  getCardPin,
} from 'actions/CardActions';

import CardTransactions from 'components/CardComponents/CardTransactions';
import CardCreation from 'components/CardComponents/CardCreation';

import service from 'services/TreezorService';

import blueCard from '../../../assets/images/bluecard.png';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    backgroundColor: '#f6f7f8',
  },
  SmallBullet: {
    display: 'inline-block',
    margin: '0 10px',
    transform: 'scale(0.8)',
  },
  bullet: {
    display: 'inline-block',
    transform: 'scale(1)',
  },
  containerImg: {
    padding: '2px',
    width: '70px',
    height: '90px',
    margin: '0px',
    cursor: 'pointer',
  },
  img: {
    width: '100%',
    height: '100%',
    borderRadius: '5px',
  },
  iconAndText: {
    marginTop: '-70px',
  },
  flexboxContainer: {
    marginBottom: '-50px',
  },
  ListItemText: {
    maxWidth: '120px',
  },
  toolTip: {
    maxWidth: 700,
    cursor: 'pointer',
  },
  alertBar: {
    marginLeft: '80px',
  },
});

const TabPanel = ({ indexTab, children, indexPanel }) => {
  return (
    <div>{indexTab === indexPanel && <Typography>{children}</Typography>}</div>
  );
};

const CardDetails = ({ ...props }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { id: cardOId } = props.match.params;

  const [card, setCard] = useState(null);
  const [cardLoading, setCardLoading] = useState(false);
  const [indexTab, setValue] = useState(0);
  const [showImage, setShowImage] = useState(false);
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [isPhysicalCard, setPhysicalCard] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [openCardConfig, setOpenCardConfig] = useState(false);
  const [openTooltip, setOpenTooltip] = useState(false);
  const [openOTP, setOpenOTP] = useState(false);
  const [cardPin, setCardPin] = useState('');
  const [previewImg, setPreviewImg] = useState(false);
  const [clickPreview, setClickPreview] = useState(false);

  const smallBull = <span className={classes.SmallBullet}>•</span>;
  const bull = <span className={classes.bullet}>•</span>;

  const putLockUnlockCard = async () => {
    setLoading(true);
    const data = {
      lockStatus: checked ? 1 : 0,
    };
    try {
      await dispatch(lockUnlockCard(cardOId, data));
      const currentLockStatus = card.statusCode === 'UNLOCK' ? 0 : 1;
      setChecked(!!currentLockStatus);
      setLoading(false);
    } catch (error) {
      logger.error('ERROR : CardDetails - putLockUnlockCard() ', error);
      setLoading(false);
    }
  };

  const fetchCardInfo = async () => {
    setCardLoading(true);
    const detailCard = await dispatch(fetchDetailCard(cardOId));
    setCard(detailCard);
    setChecked(detailCard.statusCode === 'UNLOCK');
    setLoading(false);
    setCardLoading(false);
  };

  const fotgotPINCard = async () => {
    try {
      setCardLoading(true);
      await dispatch(getOTP());
      setCardLoading(false);
      setOpenOTP(true);
    } catch (error) {
      setCardLoading(false);
    }
  };

  const submitOTP = async (otp) => {
    try {
      setCardLoading(true);
      const data = {
        otp: Number(otp),
      };
      const { data: result } = await dispatch(getCardPin(cardOId, data));

      // Allow user see card details when they enter valid OTP
      if (clickPreview && result) {
        setPreviewImg(true);
        setCardLoading(false);
        setOpenOTP(false);
        return;
      }

      const { pin } = result;
      setCardPin(pin);
      setCardLoading(false);
      setOpenOTP(false);
    } catch (error) {
      setCardLoading(false);
    }
  };

  useEffect(() => {
    fetchCardInfo();
  }, [checked]);

  useEffect(() => {
    if (card && (!!card.virtualConverted || !!card.physical)) {
      setPhysicalCard(true);
    }
  }, [card]);

  // eslint-disable-next-line consistent-return
  const closePreviewImgTimer = (type) => {
    let timer;

    switch (type) {
      case 'SET':
        timer = setTimeout(() => {
          setShowImage(false);
        }, 30000);
        break;
      case 'CLEAR':
        setShowImage(false);
        clearTimeout(timer);
        break;
      default:
        return false;
    }
  };

  const handleOpenPreviewImg = async () => {
    try {
      const res = await service.fetchCardImage(card.cardId);
      setImage(res.data.cardimages[0].file);
      setShowImage(true);
      //  wait 30 seconds and then close the image
      closePreviewImgTimer('SET');
    } catch (error) {
      logger.error(
        'ERROR : CardDetails - handleOpenPreviewImg() Get-Card-Image',
        error
      );
    }
  };

  const handleClosePreviewImg = () => {
    setPreviewImg(false);
    setClickPreview(false);
    closePreviewImgTimer('CLEAR');
  };

  useEffect(() => {
    if (previewImg) {
      handleOpenPreviewImg();
    }
  }, [previewImg]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const canPerformAction = (code) => {
    switch (code) {
      case 'LOST':
        return false;
      case 'STOLEN':
        return false;
      default:
        return true;
    }
  };

  const renderCardStatusIcon = (code) => {
    switch (code) {
      case 'LOCK':
        return <LockIcon />;
      case 'UNLOCK':
        return <VerifiedUserIcon />;
      case 'LOST':
        return <BlockIcon />;
      case 'STOLEN':
        return <BlockIcon />;
      default:
        return false;
    }
  };

  const renderLockUnlockSwitch = () => {
    const { statusCode } = card;
    if (!loading) {
      return canPerformAction(statusCode) ? (
        <Switch
          edge="end"
          onChange={() =>
            putLockUnlockCard(
              dispatch,
              cardOId,
              statusCode,
              checked,
              setChecked,
              setLoading
            )
          }
          checked={checked}
          disabled={!canPerformAction(statusCode)}
        />
      ) : (
        <Chip label={statusCode} />
      );
    }
    return <CircularProgress size={30} />;
  };

  const renderForgottenPin = () => {
    if (typeof cardPin === 'number') {
      return <Typography>PIN: {cardPin}</Typography>;
    }

    return (
      <Button onClick={fotgotPINCard} variant="outlined">
        Code PIN Oublié?
      </Button>
    );
  };

  const renderSettings = () => {
    return (
      <div>
        <List>
          <ListItem>
            <ListItemIcon>{renderCardStatusIcon(card.statusCode)}</ListItemIcon>
            <ListItemText
              primary={
                card.statusCode === 'UNLOCK'
                  ? t('lock_card')
                  : t('un_lock_card')
              }
              className={classes.ListItemText}
            />
            <ListItemText>{renderLockUnlockSwitch()}</ListItemText>

            {isPhysicalCard && renderForgottenPin()}
          </ListItem>
        </List>
        <CardCreation
          virtual={!isPhysicalCard}
          setOpenCardConfig={setOpenCardConfig}
          selectedTreezorCard={card}
        />
      </div>
    );
  };

  const handleTooltipOpen = () => {
    return openTooltip ? setOpenTooltip(false) : setOpenTooltip(true);
  };

  const renderAlertBar = (status, message) => {
    return (
      <MuiAlert variant="filled" severity={status} className={classes.alertBar}>
        {message}
      </MuiAlert>
    );
  };

  const handleClickPreviewImg = async () => {
    try {
      setClickPreview(true);
      setCardLoading(true);
      await dispatch(getOTP());
      setCardLoading(false);
      setOpenOTP(true);
    } catch (error) {
      setCardLoading(false);
    }
  };

  return (
    <>
      {showImage && (
        <Dialog
          onClose={() => {
            setShowImage(false);
          }}
          open={showImage}
        >
          <DialogTitle id="simple-dialog-title">{t('card_image')}</DialogTitle>
          <DialogContent dividers>
            <Grid container direction="column" alignItems="center">
              <img src={`data:image/png;base64, ${image}`} />
            </Grid>
            <p>{t('popup_auto_close_text')}</p>
          </DialogContent>
          <DialogActions>
            <Button
              autoFocus
              onClick={() => handleClosePreviewImg()}
              color="primary"
            >
              {t('close.tab')}
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {card && (
        <Card className={classes.root}>
          <CardContent>
            <Box
              display="flex"
              justifyContent="space-between"
              className={classes.flexboxContainer}
            >
              <div>
                <Box display="flex">
                  <Typography variant="h5" gutterBottom>
                    {/* AEWEB Online Expenses */}
                    {card.embossedName}
                  </Typography>
                  <div>
                    {card.pinTryExceeds === BLOCK_PIN &&
                      renderAlertBar('error', t(`blockedPINAlert`))}
                  </div>
                </Box>

                <Box display="flex">
                  <div>
                    {bull} {bull}
                    {card.maskedPan &&
                      card.maskedPan.substr(card.maskedPan.length - 4)}
                  </div>
                  <div>{smallBull}</div>
                  <div>{card.expiryDate}</div>
                </Box>
              </div>

              <div>
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Tooltip
                      title="Carte perdue ou volée? Commencer par bloquer votre carte, dans les réglages. Ensuite contactez nous via le support ci-dessous, ou par téléphone au 03 20 28 01 40."
                      placement="left"
                      arrow
                      disableFocusListener
                      disableHoverListener
                      disableTouchListener
                      className={classes.toolTip}
                      open={openTooltip}
                      onClick={handleTooltipOpen}
                    >
                      <HelpOutlineIcon style={{ fontSize: '3em' }} />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={8}>
                    <div
                      className={classes.containerImg}
                      onClick={() => handleClickPreviewImg()}
                    >
                      <img
                        className={classes.img}
                        src={blueCard}
                        alt="BeBusinessfocus BlueCard"
                      />
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.iconAndText}
                      >
                        <VisibilityIcon style={{ color: 'white' }} />
                        <sub style={{ color: 'white' }}>
                          {t('view.details')}
                        </sub>
                      </Grid>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </CardContent>
          <CardActions>
            <Tabs
              value={indexTab}
              onChange={handleChange}
              aria-label="simple tabs example"
            >
              <Tab label="Transactions" />
              <Tab label={t('settings')} />
            </Tabs>
          </CardActions>
          <CardContent>
            <TabPanel indexTab={indexTab} indexPanel={0}>
              {/* Transactions */}
              <CardTransactions cardId={card.cardId} />
            </TabPanel>
            <TabPanel indexTab={indexTab} indexPanel={1}>
              {/* Settings */}
              {renderSettings()}
            </TabPanel>
          </CardContent>
          {/* <CardContent>
            <Button
              onClick={() => history.push(`/cards`)}
              variant="contained"
              color="primary"
            >
              Back
            </Button>
          </CardContent> */}
        </Card>
      )}
      {cardLoading && <LoadingDialog title={t('loading_single')} />}
      <OTPDialog
        open={openOTP}
        handle={setOpenOTP}
        submit={submitOTP}
        resend={fotgotPINCard}
      />
    </>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  indexTab: PropTypes.any.isRequired,
  indexPanel: PropTypes.any.isRequired,
};
CardDetails.propTypes = {
  props: PropTypes.object,
  match: PropTypes.object,
};

export default CardDetails;
