import providerService from '../../services/ProviderService';
import { setGlobalError } from '../GlobalError';

import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

import {
  FETCH_PROVIDERS,
  UPDATE_PROVIDER,
  UPDATE_PROVIDER_BENEFICIARY,
} from '../Types';

export const fetchProviders = () => async (dispatch) => {
  try {
    const result = await providerService.fetchProviders();
    dispatch({
      type: FETCH_PROVIDERS,
      payload: result.data,
    });
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({
      type: FETCH_PROVIDERS,
      payload: [],
    });
  }
};

export const createProvider = (providers, cb) => async (dispatch, getState) => {
  try {
    // TODO: check provider access rights
    // if (!userCanCreateUserManagement(getState())) {
    //   cb();
    //   return;
    // }

    const companyId = selectLoggedUserCompanyId(getState());

    const response = (
      await providerService.createProvider({
        providers,
        company_id: companyId,
      })
    ).data;

    cb(null, response);
  } catch (error) {
    cb(error);
  }
};

export const updateProvider = (id, provider, cb) => async (dispatch) => {
  // TODO: check provider access rights
  // if (!userCanUpdateUserManagement(getState())) {
  //   cb();
  //   return;
  // }

  try {
    const { data } = await providerService.updateProvider(id, provider);

    dispatch({ type: UPDATE_PROVIDER, payload: data });

    cb(null);
  } catch (error) {
    cb(error);
  }
};

export const updateProviderBeneficiary = (data) => (dispatch) => {
  dispatch({ type: UPDATE_PROVIDER_BENEFICIARY, payload: data });
};
