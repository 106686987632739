/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { format } from 'date-fns';

import { useRouter } from 'hooks/routerHooks';

import MaterialTable from 'components/MaterialTable';
import { fetchRevenuePaginate } from 'actions/RevenueActions';
import { Refresh } from '@material-ui/icons';
import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Button,
} from '@material-ui/core';
import queryString from 'query-string';
import HorizontalBeforAfterDate from 'components/HorizontalBeforeAfterDate';
import { map } from 'lodash';
import { DEFAULT_PAGESIZE } from 'config/Constants';

const RevenueListBody = () => {
  const { t } = useTranslation();
  const { history, location } = useRouter();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGESIZE);
  const tableRef = React.createRef();
  const filters = queryString.parse(location?.search, { arrayFormat: 'index' });
  const [before, setBefore] = useState(filters ? filters.before || '' : '');
  const [after, setAfter] = useState(filters ? filters.after || '' : '');
  const [categories, setCategories] = useState(
    filters ? filters.categories || [] : []
  );

  //   const { history } = useRouter();
  const revenueCategories = useSelector(
    (state) => state.revenueCategories.data
  );

  function fetch() {
    return tableRef.current && tableRef.current.onQueryChange();
  }

  const clearFilters = () => {
    setBefore('');
    setAfter('');
    setCategories([]);
    fetch();
  };

  useEffect(() => {
    if (refresh) {
      fetch();
    }
  }, [refresh]);

  const columns = [
    {
      title: t('invoices.list.nb'),
      field: 'sequence_nb',
      options: {
        filter: false,
      },
      render: (value) => (
        // <div onClick={() => console.log(value)}>{value.sequence_nb}</div>
        <div
          onClick={() => history.push(`/revenue/${value._id}`)}
          style={{ cursor: 'pointer', fontWeight: 'bold' }}
        >
          {value.sequence_nb}
        </div>
      ),
    },
    {
      title: t('invoices.form_date'),
      field: 'date_emission',
      render: (value) =>
        value.date_emission
          ? format(new Date(value.date_emission), 'dd/MM/yyyy')
          : '-',
      defaultSort: 'desc',
    },
    {
      title: t('expenses.expense.category'),
      field: 'category_id',
      render: (value) =>
        revenueCategories[value.category_id]
          ? revenueCategories[value.category_id].display
          : '',
      sorting: false,
    },
    {
      title: t('expenses.expense.form_amount'),
      field: 'grand_total_amount',
      render: (value) => `${value.grand_total_amount}€`,
    },
    // {
    //   label: ' ',
    //   name: 'id',
    //   options: {
    //     viewColumns: false,
    //     filter: false,
    //     sort: false,
    //     search: false,
    //     // eslint-disable-next-line react/display-name
    //     customBodyRender: (value) => (
    //       <AppRowStartSpaceAround>
    //         <IconEdit
    //           sm
    //           disabled={refreshing}
    //           onClick={() => {
    //             history.push(`/invoices/edit/${value}`);
    //           }}
    //         />
    //       </AppRowStartSpaceAround>
    //     ),
    //   },
    // },
  ];

  function renderToolBar() {
    return (
      <Grid
        container
        direction="row"
        justify="flex-start"
        alignItems="center"
        spacing={4}
        style={{
          position: 'relative',
          paddingBottom: '30px',
          paddingTop: '10px',
        }}
      >
        <Grid item>
          <FormControl style={{ width: 200, marginRight: 10 }}>
            <InputLabel id="demo-mutiple-checkbox-label">
              {`${t('expenses.list.category')}(s)`}
            </InputLabel>
            <Select
              onClose={() => {
                fetch();
              }}
              fullWidth={true}
              multiple
              value={categories}
              onChange={(event) => setCategories(event.target.value)}
              input={<Input />}
              renderValue={(selected) =>
                selected.map((s) => revenueCategories[s].display).join(', ')
              }
            >
              {map(revenueCategories, (category) => (
                <MenuItem key={category._id} value={category._id}>
                  <Checkbox checked={categories.indexOf(category._id) > -1} />
                  <ListItemText primary={category.display} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <HorizontalBeforAfterDate
            onAfterChange={(value) => {
              setAfter(value);
              fetch();
            }}
            onBeforeChange={(value) => {
              setBefore(value);
              fetch();
            }}
            after={after}
            before={before}
            beforeTitle={t('invoice.filters.before')}
            afterTitle={t('invoice.filters.after')}
          />
        </Grid>

        <Grid item style={{ position: 'absolute', right: '0' }}>
          <Tooltip title={t('reset_filter')}>
            <Button
              startIcon={<Refresh />}
              onClick={() => clearFilters()}
              // disabled={selectedIndices.length < 1}
            >
              {t('reset_filter')}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {renderToolBar()}

      <MaterialTable
        title="Invoices"
        tableRef={tableRef}
        columns={columns}
        data={(query) =>
          new Promise((resolve) => {
            dispatch(
              fetchRevenuePaginate(
                query,
                { categories, before, after },
                refresh
              )
            ).then((res) => {
              setRefresh(false);
              resolve({ ...res, data: res.data || [] });
            });
          })
        }
        onChangeRowsPerPage={(ps) => {
          setPageSize(ps);
        }}
        options={{
          filtering: false,
          pageSize,
          search: true,
          showTitle: false,
        }}
        actions={[
          {
            icon: () => <Refresh />,
            isFreeAction: true,
            onClick: () => setRefresh(true),
          },
        ]}
      />
    </>
  );
};

RevenueListBody.propTypes = { refreshing: PropTypes.bool };

export default RevenueListBody;
