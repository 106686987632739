/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { globalDispatch } from 'store';
import {
  fetchLoggedUserCompanies,
  updateBankDetailsCompany,
} from 'actions/UserCompanyActions';
import spinnerService from 'services/SpinnerService';
import AppCheckbox from '../AppCheckbox';
import CircularProgressCentered from '../CircularProgressCentered';
import NumberFormat from '../../helpers/NumberFormat';
import { authInstance as axios } from '../../helpers/axiosInterceptor';

const AccountItem = ({
  account,
  // companyId,
  isSync,
  onSynchronize,
  disabled,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(isSync);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    if (isSync !== checked) setChecked(isSync);
  }, [isSync]);

  const { loggedUser, loggedUserCompany } = useSelector((state) => state);
  const { company, companies } = loggedUserCompany;
  const companyIndex = companies.findIndex((item) => item._id === company._id);

  const setSynchronize = async (_checked = true) => {
    spinnerService.startSpinner();
    setLoading(true);
    // TODO: action / service
    const res = await axios.post(
      `${process.env.REACT_APP_BRIDGE_URL}/connect-account/${account.id}?user_id=${loggedUser?.user?._id}`,
      {
        synchronize: _checked,
      }
    );
    await globalDispatch(fetchLoggedUserCompanies());
    await globalDispatch(updateBankDetailsCompany(companyIndex));
    if (res.data) {
      setChecked(!checked);
      setLoading(false);
    } else {
      setLoading(false);
      setError(t('error.try_again'));
    }
    spinnerService.resetSpinner();
  };

  const handleChange = (_checked = true) => {
    setSynchronize(_checked);
  };

  return (
    <div>
      {/* {JSON.stringify(account)} */}
      <ListItem key={account.id} style={{ height: '80px' }}>
        {/* <AccountItem account={el} /> */}
        <ListItemIcon>
          <AccountBalanceWalletIcon color="secondary" />
        </ListItemIcon>
        <ListItemText
          primary={account.name}
          secondary={`${NumberFormat.n(account.balance || 0)}€`}
        />
        {loading ? (
          <CircularProgressCentered />
        ) : error ? (
          <div>
            {error}
            <AppCheckbox
              onClick={(e) => {
                handleChange(e.target.checked);
                // console.log('is checked', e.target.checked);
              }}
              // disabled={isSubmitting}
              color="primary"
              checked={checked}
              labelText={t('synchronize')}
              name={`account${account.id}`}
              id={`account${account.id}`}
              disabled={disabled}
            />
          </div>
        ) : (
          <AppCheckbox
            onClick={(e) => {
              handleChange(e.target.checked);
              if (onSynchronize) onSynchronize(e.target.checked);
              // console.log('is checked', e.target.checked);
            }}
            // disabled={isSubmitting}
            color="primary"
            checked={checked}
            labelText={t('synchronize')}
            name={`account${account.id}`}
            id={`account${account.id}`}
            disabled={disabled}
          />
        )}
      </ListItem>
    </div>
  );
};

AccountItem.propTypes = {
  isSync: PropTypes.bool,
  account: PropTypes.object,
  companyId: PropTypes.string,
  onSynchronize: PropTypes.func,
  disabled: PropTypes.bool,
};

export default AccountItem;
