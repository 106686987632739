const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles(() => ({
  priceColumn: {
    borderRadius: '50px',
    padding: '34px 36px 28px',
    alignItems: 'center',
  },
  priceColumnInactive: {
    borderRadius: '50px',
    padding: '34px 36px 28px',
    alignItems: 'center',
    opacity: 0.7,
  },
  priceColumnRounded: {
    borderRadius: '50px',
    padding: '34px 36px 28px',
    alignItems: 'center',
    backgroundColor: 'hsla(0,0%,10%,.05)',
    border: '1px solid hsla(0,0%,10%,.1)',
  },
  product: {
    flexDirection: 'column',
  },
  title: {
    fontWeight: '600',
    fontSize: '20px',
    color: '#1a1a1a',
  },
  button: {
    backgroundColor: 'rgb(61, 49, 157)',
    borderColor: 'rgb(61, 49, 157)',
    color: '#ffffff',
    borderRadius: '22px',
    marginTop: '16px',
    padding: '12px 24px',
    cursor: 'pointer',
    height: '44px',
    border: '1px solid transparent',
  },
  buttonText: {
    whiteSpace: 'nowrap',
    fontWeight: '500',
  },
  features: {
    width: '100%',
    color: '#1a1a1a',
    flexDirection: 'column',
  },
  featureHeader: {
    margin: '12px 0',
    fontSize: '14px',
    color: '#1a1a1a',
  },
  featuresItem: {
    marginBottom: '12px',
    alignItems: 'flex-start',
    flexWrap: 'nowrap',
    display: 'flex',
  },
  featuresItemText: {
    lineHeight: '1.1',
    fontSize: '14px',
    color: '#1a1a1a',
  },
  checkContainer: {
    marginTop: '2px',
    minWidth: '28px',
    marginRight: '12px',
  },
  titleContent: {
    minHeight: '70px',
  },
  image: {
    borderRadius: '6px',
    marginBottom: '16px',
    maxHeight: '120px',
    maxWidth: '100%',
  },
  priceContainer: {
    display: 'flex',
  },
  price: {
    fontSize: '36px',
    fontWeight: '700',
    marginRight: '4px',
  },
  perMonth: { fontSize: '13px', opacity: '.5', margin: 'auto 0' },
  descriptions: {
    opacity: '.5',
    fontSize: '14px',
    color: '#1a1a1a',
  },
  validateTitleFontSize: {
    fontSize: '20px',
  },
  validateTextFontSize: {
    fontSize: '14px',
  },
}));

export default useStyles;
