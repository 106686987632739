import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

/* import components */
import CircularProgressCentered from 'components/CircularProgressCentered';
import AppDashboardCard from '../components/AppDashboardCard';
import NewInvoiceLineChart from '../InvoiceGraph/NewInvoiceLineChart';

const Overview = ({
  companyId,
  invoiceTurnOver,
  setInvoiceData,
  revenueOverview,
}) => {
  const { t } = useTranslation();
  const [grafData, setgrafData] = useState(null);

  function storeInvoiceData() {
    if (invoiceTurnOver && revenueOverview.data) {
      setgrafData({
        invoice: invoiceTurnOver.monthly || {},
        revenue: revenueOverview.data ? revenueOverview.data.overview : null,
      });
      setInvoiceData(invoiceTurnOver || 0);
    }
  }

  useEffect(() => {
    storeInvoiceData();
  }, [invoiceTurnOver, revenueOverview, companyId]);

  return (
    <AppDashboardCard title={t('turn_over.title')}>
      {grafData ? (
        <NewInvoiceLineChart inputData={grafData} />
      ) : (
        <CircularProgressCentered />
      )}
    </AppDashboardCard>
  );
};

Overview.propTypes = {
  companyId: PropTypes.string,
  invoiceTurnOver: PropTypes.object,
  setInvoiceData: PropTypes.func,
  revenueOverview: PropTypes.object,
};

export default Overview;
