import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';

/* eslint-disable-next-line */
import AuthPage from '../AuthPage';

import dispatch from './dispatch';

export class App extends Component {
  render() {
    const { isAuthenticated } = this.props;

    if (!isAuthenticated()) {
      return <AuthPage />;
    }

    return <Redirect to="/home" />;
  }
}

App.propTypes = {
  isAuthenticated: PropTypes.func.isRequired,
};

const AppWithRouter = withRouter(App);
export default connect(null, dispatch)(AppWithRouter);
