import { bindActionCreators } from 'redux';
import {
  updateEmailTemplate,
  createEmailTemplate,
  getEmailTemplates,
} from '../../../actions/EmailTemplateActions';

export default (dispatch) =>
  bindActionCreators(
    {
      updateEmailTemplate,
      createEmailTemplate,
      getEmailTemplates,
    },
    dispatch
  );
