/* eslint-disable camelcase */
import * as yup from 'yup';

const validationSchema = () =>
  yup.object().shape({
    description: yup.string().when('action', {
      is: (action) => action !== 'paid' && action !== 'send',
      then: yup.string().required('La description est obligatoire'),
    }),
    payment_type: yup.string().when('action', {
      is: (action) => action !== 'paid' && action !== 'send',
      then: yup.string().required('Le mode de paiement est obligatoire'),
    }),
    action: yup.string().nullable(true),
    // filename is the bas64 buffer : on ndf and ach only
    // filename: yup.mixed().when('action', {
    //   is: (action) => action !== 'paid' && action !== 'send',
    //   then: yup.mixed().when('isTravelWithDistanceRate', {
    //     is: (isTravelWithDistanceRate) => !isTravelWithDistanceRate,
    //     then: yup.mixed().required('La facture est obligatoire'),
    //   }),
    // }),
    travel_from: yup.string().when('isTravel', {
      is: (isTravel) => isTravel,
      then: yup.string().when('form', {
        is: (form) => form === 'ndf',
        then: yup.string().required('Le lieu de depart est obligatoire'),
      }),
    }),

    travel_to: yup.string().when('isTravel', {
      is: (isTravel) => isTravel,
      then: yup.string().when('form', {
        is: (form) => form === 'ndf',
        then: yup.string().required('La destination est obligatoire'),
      }),
    }),
    travel_distance: yup.number().when('isTravelWithDistanceRate', {
      is: (isTravelWithDistanceRate) => isTravelWithDistanceRate,
      then: yup.number().when('form', {
        is: (form) => form === 'ndf',
        then: yup.number().required('Le distance est obligatoire'),
      }),
    }),
    total0: yup.number().when('form', {
      is: (form) => form === 'ndf' || form === 'ach',
      then: yup
        .number()
        .required('Le montant est obligatoire')
        .notOneOf([0], 'Le montant est obligatoire'),
    }),
    travel_distance_rate: yup
      .string()
      .nullable(true)
      .when('isTravelWithDistanceRate', {
        is: (isTravelWithDistanceRate) => isTravelWithDistanceRate,
        then: yup.string().when('form', {
          is: (form) => form === 'ndf',
          then: yup.string().required('Le barème est obligatoire'),
        }),
      }),
    //
    date: yup.date().when('action', {
      is: (action) => action !== 'paid' && action !== 'send',
      then: yup.date().required('La date est obligatoire'),
    }),
    //
    invoice_nb: yup
      .string()
      .nullable(true)
      .when('action', {
        is: (action) => action !== 'paid' && action !== 'send',
        then: yup.string().when('form', {
          is: (form) => form === 'ach',
          then: yup.string().required('Le numéro de facture est obligatoire'),
        }),
      }),
    provider: yup
      .string()
      .nullable(true)
      .when('action', {
        is: (action) => action !== 'paid',
        then: yup.string().when('hasProvider', {
          is: (hasProvider) => {
            return hasProvider;
          },
          then: yup
            .string()
            .nullable(true)
            .required('Le fournisseur est obligatoire'),
          otherwise: yup.string().nullable(true),
        }),
      }),
    taxBasisTotalAmount: yup.number().when('action', {
      is: (action) => action !== 'paid' && action !== 'send',
      then: yup.number().when('form', {
        is: (form) => form === 'ach',
        then: yup
          .number()
          .required('Le montant net est obligatoire')
          .notOneOf([0], 'Le montant net est obligatoire'),
      }),
    }),
    taxTotalAmount: yup.number().when('action', {
      is: (action) => action !== 'paid' && action !== 'send',
      then: yup.number().when('form', {
        is: (form) => form === 'ach',
        then: yup.number().required('Le montant de TVA est obligatoire'),
      }),
    }),
    grandTotalAmount: yup.number().when('action', {
      is: (action) => action !== 'paid' && action !== 'send',
      then: yup
        .number()
        .required('Le Total est obligatoire')
        .notOneOf([0], 'Le Total est obligatoire'),
    }),
    duePayableAmount: yup.number().when('form', {
      is: (form) => form === 'ach',
      then: yup
        .number()
        .required('Solde à régler doit est obligatoire')
        .test(
          'duePayableTest',
          'Solde à régler doit est inférieur ou égal au montant TTC',
          // to alow this
          // eslint-disable-next-line
          function (value) {
            return value <= this.parent.grandTotalAmount && value > 0;
          }
        )
        .when('action', {
          is: (action) => action !== 'paid' && action !== 'send',
          then: yup.number().test(
            'duePayableTest',
            'Solde à régler doit est inférieur ou égal au montant TTC',
            // to alow this
            // eslint-disable-next-line
            function (value) {
              return value <= this.parent.grandTotalAmount;
            }
          ),
        }),
    }),
    client_id: yup.string().nullable(),
  });

export default validationSchema;
