import React from 'react';

// import { makeStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';

import CompanyInfo from './CompanyInfo';
// import CompanyExtraAddress from './CompanyInfo/CompanyExtraAddress';

import ClientInfo from './ClientInfo';
import ClientExtraAddress from './ClientInfo/ClientExtraAddress';

// import colors from '../../../../constants/colors.json';

// const useStyles = makeStyles(() => ({
//   greySmall: {
//     color: colors.textGrey,
//     fontFamily: 'Kumbh Sans',
//     fontSize: '10px',
//     lineHeight: '11px',
//   },
// }));
const InvoiceInformation = () => {
  // const classes = useStyles();
  const clientExtraFieldnames = {
    address: 'client.billingAddress.address',
    zipcode: 'client.billingAddress.zipcode',
    city: 'client.billingAddress.city',
    country: 'client.billingAddress.country',
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <CompanyInfo />
      </Grid>
      <Grid item xs={12}>
        <ClientInfo />
      </Grid>
      {/* Adding the row for recording different addresses - The dimensions NEED TO COPY the row above */}
      <Grid item xs={12} sm={6}>
        {/* <CompanyExtraAddress /> */}
      </Grid>
      <Grid item xs={11} sm={6}>
        <ClientExtraAddress fieldNames={clientExtraFieldnames} isInvoice />
      </Grid>
      {/* <Grid
        container
        item
        xs={1}
        sm={1}
        justify="flex-end"
        alignContent="flex-end"
      >
        <div className={classes.greySmall}>Page 1/1</div>
      </Grid> */}
    </Grid>
  );
};

export default InvoiceInformation;
