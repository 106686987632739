/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  accountModalTitle: {
    fontWeight: 'bold',
    textAlign: 'center',
    fontSize: '18px',
    width: '100%',
    margin: 0,
  },

  accountModalLabel: {
    fontWeight: 'normal',
    fontSize: '14px',
    margin: 0,
  },

  accountModalText: {
    fontWeight: 'bold',
    fontSize: '16px',
  },

  accountBoxWrapper: {
    backgroundColor: 'lightgrey',
    width: '100%',
    borderRadius: '5px',
    padding: '10px',
    marginTop: '8px',
  },

  accountDialogContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    width: '100%',
  },

  accountDialogItem: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    gap: '4px',
  },
}));

export default useStyles;
