import { v4 as uuidv4 } from 'uuid';
import { getFileExtension } from './utils';
import S3FileService from '../services/S3FileService';

async function uploadFile(file, company) {
  const ext = getFileExtension(file.name);
  const folder = `company-files-${company.alternativeId}`;
  const name = `${uuidv4()}-${new Date().toISOString()}.${ext}`;

  /* eslint-disable-next-line */
  const filename = `${folder}/${name}`;
  await S3FileService.upload(file, folder, name);
  return filename;
}

export default uploadFile;
