import _ from 'lodash';

import {
  FETCH_ITEMS,
  FETCH_ITEMS_FAILED,
  LOGOUT_USER_SUCCESS,
  CREATE_ITEM,
  UPDATE_ITEM,
  DELETE_ITEM,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

export default (state = {}, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_ITEMS:
      return _.mapKeys(action.payload, '_id');
    case CREATE_ITEM: {
      return { ...state, [action.payload._id]: action.payload };
    }
    case UPDATE_ITEM: {
      return {
        ...state,
        [action.payload._id]: {
          ...state[action.payload._id],
          ...action.payload,
        },
      };
    }
    case DELETE_ITEM:
      return {
        ...state,
        [action.payload]: {
          ...state[action.payload],
          deleted: true,
        },
      };
    case FETCH_ITEMS_FAILED:
      return {};
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return {};
    default:
      return state;
  }
};
