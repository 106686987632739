import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: 'rgb(var(--color-primary-main))' /*  #3F3399 */,
      hover: 'rgb(var(--color-primary-hover))' /*  #5949BD */,
      50: 'rgb(var(--color-primary-50))' /*  #F4F2FF */,
      100: 'rgb(var(--color-primary-100))' /*  #EAE8F6 */,
      200: 'rgb(var(--color-primary-200))' /*  #D5D1EE */,
      300: 'rgb(var(--color-primary-300))' /*  #C0BBE5 */,
      400: 'rgb(var(--color-primary-400))' /* #AAA3DD */,
      500: 'rgb(var(--color-primary-500))' /*  #948DD5 */,
      600: 'rgb(var(--color-primary-600))' /*  #8076CD */,
      700: 'rgb(var(--color-primary-700))' /*  #6C5FC4 */,
      900: 'rgb(var(--color-primary-900))' /*  #4A3DAB */,
      contrastText: 'rgb(var(--color-white))' /*  #ffffff */,
    },
    secondary: {
      // light: will be calculated from palette.primary.main,
      main: 'rgb(var(--color-secondary-main))' /* #02cbcd */,
      dark: 'rgb(var(--color-secondary-dark))' /* #027d7d */,
      light: 'rgb(var(--color-secondary-light))' /* #00b1b2 */,
      50: 'rgb(var(--color-secondary-50))' /* #ECF8F8 */,
      100: 'rgb(var(--color-secondary-100))' /* #d9f3f3 */,
      200: 'rgb(var(--color-secondary-200))' /* #b3e7e8 */,
      300: 'rgb(var(--color-secondary-300))' /* #7fd7d8 */,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    teal: {
      50: 'rgb(var(--color-teal-50))' /* #f9ffff */,
      100: 'rgb(var(--color-teal-100))' /* #c8fbff */,
      200: 'rgb(var(--color-teal-200))' /* #94e6eb */,
      300: 'rgb(var(--color-teal-300))' /* #5ad0d5 */,
      400: 'rgb(var(--color-teal-400))' /* #2bc2c5 */,
      500: 'rgb(var(--color-teal-500))' /* #00b1b2 */,
      600: 'rgb(var(--color-teal-600))' /* #00a3a2 */,
      700: 'rgb(var(--color-teal-700))' /* #119492 */,
      800: 'rgb(var(--color-teal-800))' /* #008482 */,
      900: 'rgb(var(--color-teal-900))' /* #086661 */,
    },
    yellow: {
      50: 'rgb(var(--color-yellow-50))' /* #feefd3 */,
      100: 'rgb(var(--color-yellow-100))' /* #feeac4 */,
      200: 'rgb(var(--color-yellow-200))' /* #fde2b0 */,
      300: 'rgb(var(--color-yellow-300))' /* #fdd996 */,
      400: 'rgb(var(--color-yellow-400))' /* #fccc73 */,
      500: 'rgb(var(--color-yellow-500))' /* #fbba45 */,
      600: 'rgb(var(--color-yellow-600))' /* #faaa16 */,
      700: 'rgb(var(--color-yellow-700))' /* #e29505 */,
      800: 'rgb(var(--color-yellow-800))' /* #c07e04 */,
      900: 'rgb(var(--color-yellow-900))' /* #a36b04 */,
    },
    red: {
      50: 'rgb(var(--color-red-50))' /* #FFEDE9 */,
      100: 'rgb(var(--color-red-100))' /* #FFDFD9 */,
      200: 'rgb(var(--color-red-200))' /* #F0ACA8 */,
      300: 'rgb(var(--color-red-300))' /* #DC9590 */,
      400: 'rgb(var(--color-red-400))' /* #ED7C6D */,
      500: 'rgb(var(--color-red-500))' /* #F36F55 */,
      600: 'rgb(var(--color-red-600))' /* #E36753 */,
      700: 'rgb(var(--color-red-700))' /* #D15B4B */,
      800: 'rgb(var(--color-red-800))' /* #C55645 */,
      900: 'rgb(var(--color-red-900))' /* #B74D39 */,
    },
    accent: {
      yellow: 'rgb(var(--color-accent-yellow))' /* #fbba45 */,
      red: 'rgb(var(--color-accent-red))' /* #ea5b3f */,
      green: 'rgb(var(--color-accent-green))' /* #13c296 */,
      blue: 'rgb(var(--color-accent-blue))' /* #005ce5 */,
    },
    neutral: {
      50: 'rgb(var(--color-neutral-50))' /* #F9FAFB */,
      100: 'rgb(var(--color-neutral-100))' /* #F3F4F6 */,
      200: 'rgb(var(--color-neutral-200))' /* #E5E7EB */,
      300: 'rgb(var(--color-neutral-300))' /* #D1D5DB */,
      400: 'rgb(var(--color-neutral-400))' /* #9CA3AF */,
      500: 'rgb(var(--color-neutral-500))' /* #6B7280 */,
      600: 'rgb(var(--color-neutral-600))' /* #4B5563 */,
      700: 'rgb(var(--color-neutral-700))' /* #374151 */,
      800: 'rgb(var(--color-neutral-800))' /* #1F2A37 */,
      900: 'rgb(var(--color-neutral-900))' /* #111928 */,
    },
    background: {
      default: 'rgb(var(--color-background-default))' /* #ffffff */,
    },
    text: {
      primary: 'rgb(var(--color-text))' /* #111111 */,
      grey: 'rgb(var(--color-text-grey))' /* #474747 */,
    },

    boxShadow: {
      brg: 'var(--shadow-brg)',
      bg2: 'var(--shadow-bg2)',
      bg3: 'var(--shadow-bg3)',
      md: 'var(--shadow-md)',
    },

    // error: will use the default color
  },

  rounded: {
    lg: 'var(--rounded-lg)',
    xl: 'var(--rounded-xl)',
  },

  typography: {
    useNextVariants: true,
    fontSize: 12,
    fontFamily: 'Kumbh Sans, sans-serif',

    // display2: {
    //   color: "#fff",
    // },
    h1: {
      fontSize: '38px',
      lineHeight: '46px',
    },
    h2: {
      fontSize: '30px',
      lineHeight: '38px',
    },
    h3: {
      fontSize: '24px',
      lineHeight: '32px',
    },
    h4: {
      fontSize: '20px',
      lineHeight: '28px',
    },
    h5: {
      fontSize: '16px',
      lineHeight: '24px',
    },
    body1: {
      fontSize: '14px',
      lineHeight: '22px',
    },
    caption: {
      fontSize: '12px',
      lineHeight: '20px',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        color: 'rgb(var(--color-button-color))' /* #34223B */,
      },
    },
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    MuiCardActions: {
      root: {
        display: 'block',
        textAlign: 'right',
      },
    },
    MuiRadio: {
      root: {
        padding: '8px !important',
      },
    },
  },
});

export default theme;
