import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import _ from 'lodash';

import { header } from 'assets/jss/components/appText';
import style from '../../assets/jss/components/appPlusSelect';
import '../../assets/css/icons.css';

const AppPlusSelect = ({ items, name, label, classes }) => {
  const [opened, toggleDropdown] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        wrapperRef.current &&
        !wrapperRef.current.contains(event.target) &&
        opened
      ) {
        toggleDropdown(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [opened]);

  const containerClasses = classNames({
    [classes.appPlusSelectContainer]: true,
  });
  const buttonClasses = classNames({
    [classes.plusButton]: true,
    [classes.open]: opened,
  });
  const iconClasses = classNames({
    [classes.iconStyle]: true,
    [classes.iconOpen]: opened,
    icon: true,
    'icon-add': true,
    [classes.cursorPointer]: true,
  });
  const textClasses = classNames({
    [classes.textStyle]: true,
    [classes.textOpen]: opened,
  });
  const itemsContainer = classNames({
    [classes.itemsContainer]: true,
    [classes.itemsContainerOpen]: opened,
  });
  const itemButtonClasses = classNames({
    [classes.itemButton]: true,
  });

  const handleOnClick = (option) => {
    const { onClick } = option;
    onClick();
    toggleDropdown(!opened);
  };

  return (
    <div
      className={containerClasses}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px',
      }}
      ref={wrapperRef}
    >
      {!opened && <p style={header}>{label}</p>}
      <button
        type="button"
        className={buttonClasses}
        onClick={() => toggleDropdown(!opened)}
      >
        <p className={textClasses}>{label}</p>
        <span className={iconClasses} />
      </button>

      <div className={itemsContainer}>
        {_.map(items, (item) => (
          <button
            key={item.name}
            type="button"
            className={itemButtonClasses}
            onClick={() => handleOnClick(item)}
          >
            {item[name]}
          </button>
        ))}
      </div>
    </div>
  );
};

AppPlusSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  items: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string,
  label: PropTypes.string,

  // values:
};
// AppPlusSelect.defaultProps = {};

export default withStyles(style)(AppPlusSelect);
