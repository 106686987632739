/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  conditionIconWrapper: {
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    justifyItems: 'center',
  },

  successIcon: {
    color: '#4caf50',
    fontSize: '24px',
  },

  errorIcon: {
    color: '#f44336',
    fontSize: '24px',
  },

  title: {
    fontSize: '16px',
  },
}));

export default useStyles;
