import { Dashboard as DashboardRights } from '../../config/Rights';

import { hasRequestedAccess } from '../../helpers/RoleHelper';

export const userCanOverviewBank = () => {
  return hasRequestedAccess(DashboardRights.BankOverview);
};

export const userCanOverviewInvoice = () => {
  return hasRequestedAccess(DashboardRights.InvoiceOverview);
};
