/* Packages */
import { Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

/* Components */
import { DeletePayoutIcon } from 'components/AppIcons';

/* Styles */
import useStyles from '../BankBBFAccountTable/styles';

/* Local Components */
import BankBBFTableDate from '../BankBBFTableDate';

const BankBBFPayoutPendingRequestTable = (props, tablePendingRef) => {
  const { getRequestData, handleOpenConfirmDialog } = props;

  const classnames = useStyles();
  const { t } = useTranslation();

  const onClickDeletePendingPayoutId = async (rowData) => {
    handleOpenConfirmDialog({
      payoutId: rowData.payoutId,
      description: {
        createdDate: rowData.createdDate,
        amount: rowData.amount,
        currency: rowData.currency,
      },
    });
  };

  const columns = [
    {
      title: 'Date',
      field: 'created_at',
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <BankBBFTableDate createdDate={rowData.created_at} />
      ),
      filtering: false,
      defaultSort: 'desc',
    },
    {
      title: t('bank_bbf.category'),
      field: 'source.expenseDetail.category.display',
      // eslint-disable-next-line react/display-name
      render: (rowData) => {
        const { __t: category } = rowData.source.expenseDetail;

        if (category.toLowerCase().includes('purchase')) {
          return t('expenses.expense.purchase');
        }

        return t('expenses.expense.expense');
      },
      filtering: false,
      sorting: false,
    },

    {
      title: t('bank_bbf.beneficiary'),
      field: 'source.expenseDetail.beneficiary.name',
      sorting: false,
      filtering: false,
    },
    {
      title: t('bank_bbf.number'),
      field: 'source.expenseDetail.expense_nb',
      sorting: false,
      filtering: false,
    },
    {
      title: t('bank_bbf.amount'),
      field: 'amount',
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <Typography
          className={
            rowData.transactionType === 'Payin'
              ? classnames.amountCredit
              : classnames.amountDebit
          }
        >
          {rowData.amount} {rowData.currency}
        </Typography>
      ),
      filtering: false,
    },
    {
      // eslint-disable-next-line react/display-name
      render: (rowData) => (
        <DeletePayoutIcon
          sm
          onClick={() => onClickDeletePendingPayoutId(rowData)}
        />
      ),
      filtering: false,
      sorting: false,
    },
  ];

  return (
    <MaterialTable
      tableRef={tablePendingRef}
      columns={columns}
      data={(queryTable) => getRequestData(queryTable, 'PENDING')}
      title=""
      options={{
        filtering: false,
        search: false,
        showTitle: false,
        pageSizeOptions: [],
      }}
    />
  );
};

BankBBFPayoutPendingRequestTable.propTypes = {
  getRequestData: PropTypes.func,
  handleOpenConfirmDialog: PropTypes.func,
};

export default forwardRef(BankBBFPayoutPendingRequestTable);
