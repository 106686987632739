import internalActivityService from '../../services/InternalActivityService';

import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

import { setGlobalError } from '../GlobalError';

import {
  FETCH_ACTIVITIES,
  FETCH_ACTIVITIES_FAILED,
  ADD_ACTIVITY,
  UPDATE_ACTIVITY,
} from '../Types';

export const fetchInternalActivities = () => async (dispatch, getState) => {
  try {
    const companyId = selectLoggedUserCompanyId(getState());
    if (!companyId) {
      dispatch({ type: FETCH_ACTIVITIES, payload: [] });
      return;
    }

    const result = await internalActivityService.fetchInternalActivities({
      company_id: companyId,
    });

    const sortedActivities = result.data.sort((a, b) =>
      a.display.localeCompare(b.display)
    );

    dispatch({ type: FETCH_ACTIVITIES, payload: sortedActivities });
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: FETCH_ACTIVITIES_FAILED, error });
  }
};

export const createInternalActivity =
  (activity, cb) => async (dispatch, getState) => {
    try {
      // eslint-disable-next-line no-param-reassign
      activity.company_id = selectLoggedUserCompanyId(getState());
      const response = (await internalActivityService.createActivity(activity))
        .data;
      dispatch({ type: ADD_ACTIVITY, payload: response });
      cb(null);
    } catch (error) {
      cb(error);
    }
  };

export const updateInternalActivity = (activity, cb) => async (dispatch) => {
  try {
    await internalActivityService.updateActivity(activity);
    dispatch({ type: UPDATE_ACTIVITY, payload: activity });
    cb(null);
  } catch (error) {
    cb(error);
  }
};
