import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, TextField } from '@material-ui/core';
import React, { useState } from 'react';

const ModalLoadingIndicator = () => (
  <div
    style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      background: 'gray',
      zIndex: '2',
      opacity: '0.7',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CircularProgress size={50} color="primary" />
  </div>
);

const DeletePendingPayoutConfirmDialog = (props) => {
  const { open = false, onClose, description, onConfirm, isLoading } = props;

  const { t } = useTranslation();
  const [reason, setReason] = useState('');

  const onChangeReasonField = (e) => setReason(e.target.value);

  const onDialogConfirm = () => {
    onConfirm({
      reason,
    });
    setReason('');
  };

  const onDialogClose = () => {
    setReason('');
    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onDialogClose}
      aria-labelledby="draggable-dialog-title"
    >
      {isLoading && <ModalLoadingIndicator />}

      <DialogTitle
        id="draggable-dialog-title"
        style={{ color: '#3D309D', textAlign: 'center' }}
      >
        {t('bank_bbf.delete_payout_confirm_dialog_title')}
      </DialogTitle>
      <DialogContent>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
          <Box>
            <label style={{ fontSize: 14 }}>
              {t('bank_bbf.delete_payout_confirm_dialog_date')}:
            </label>
            <p style={{ fontSize: 16, fontWeight: 'bold', margin: '5px 0' }}>
              {description?.createdDate}
            </p>
          </Box>
          <Box>
            <label style={{ fontSize: 14 }}>
              {t('bank_bbf.delete_payout_confirm_dialog_amount')}:
            </label>
            <p style={{ fontSize: 16, fontWeight: 'bold', margin: '5px 0' }}>
              {description?.amount} {description?.currency}
            </p>
          </Box>
          <Box>
            <p style={{ fontSize: 14, margin: '4px 0 8px' }}>
              {t('bank_bbf.delete_payout_confirm_dialog_reason')}:
            </p>
            <TextField
              value={reason}
              fullWidth={true}
              label={t('bank_bbf.delete_payout_confirm_dialog_placeholder')}
              variant="outlined"
              onChange={onChangeReasonField}
            />
          </Box>
          <p
            style={{
              color: '#3D309D',
              fontWeight: 'bold',
              fontSize: '16px',
              fontStyle: 'italic',
            }}
          >
            {t('bank_bbf.delete_payout_confirm_dialog_remind')}
          </p>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={onDialogClose} color="primary">
          {t('cancel')}
        </Button>
        <Button onClick={onDialogConfirm} color="primary">
          {t('confirm_label')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeletePendingPayoutConfirmDialog.propTypes = {
  open: PropTypes.bool,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  description: PropTypes.object,
};

DeletePendingPayoutConfirmDialog.defaultProps = {
  open: false,
  isLoading: false,
  onClose: null,
};

export default DeletePendingPayoutConfirmDialog;
