import React, { Component } from 'react';

import PropTypes from 'prop-types';

import CustomGenericDialog from '../CustomGenericDialog';
import ProjectForm from '../../containers/ClientProjects/Form';

export default class ClientDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.escFunction = this.escFunction.bind(this);
    this.toggleProjectDialogWithCheck =
      this.toggleProjectDialogWithCheck.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  escFunction(event) {
    const { closeDialog } = this.props;
    if (event.keyCode === 27) {
      closeDialog();
    }
  }

  toggleProjectDialogWithCheck() {
    const { formChanged } = this.state;
    const { closeDialog } = this.props;
    if (!formChanged) closeDialog();
  }

  handleChange() {
    this.setState({ formChanged: true });
  }

  render() {
    const { t, values, closeDialog, onCreated } = this.props;
    return (
      <CustomGenericDialog
        title={t('settings_section.projects.create')}
        closeDialog={() => this.toggleProjectDialogWithCheck()}
      >
        <ProjectForm
          clientId={values ? values.client_id : null}
          closeDialog={() => closeDialog()}
          onCreated={onCreated}
          onClick={(e) => {
            closeDialog(e);
          }}
          onChange={this.handleChange}
        />
      </CustomGenericDialog>
    );
  }
}

ClientDialog.propTypes = {
  values: PropTypes.object.isRequired,
  closeDialog: PropTypes.func,
  t: PropTypes.func,
  onCreated: PropTypes.func,
};
