import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import {
  Box,
  Typography,
  Button,
  FormControl,
  TextField,
  Radio,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Modal,
} from '@material-ui/core';

const CreateDebitThirdStepYesOption = (props) => {
  const { handleContinue, handleCancel } = props;

  /* handle toggle modal */
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const { values, errors, touched, setFieldValue } = useFormikContext();

  const translateDomain = 'debits_page.debits_creation_page';
  const { t } = useTranslation();

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        padding: '20px',
      }}
    >
      <Box>
        <Typography
          variant="h5"
          align="center"
          style={{
            padding: '20px',
          }}
        >
          {t(`${translateDomain}.steps.step3.title`)}
        </Typography>
      </Box>
      <Box
        style={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: '20px',
        }}
      >
        <FormControl>
          <FormLabel>
            {t(`${translateDomain}.steps.step3.urm_number`)}
          </FormLabel>

          <TextField
            // label={t(`${translateDomain}.steps.step3.ics`)}
            id="urm"
            type="text"
            defaultValue=""
            value={values.urm}
            onChange={(event) => setFieldValue('urm', event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            // disabled={isReviewMode}
            // {...field}
          />
          {errors.urm && touched.urm ? (
            <div style={{ color: 'red' }}>{errors.urm}</div>
          ) : null}
        </FormControl>

        <FormControl>
          <FormLabel>{t(`${translateDomain}.steps.step3.ics`)}</FormLabel>

          <TextField
            // label={t(`${translateDomain}.steps.step3.ics`)}
            id="ics"
            type="text"
            defaultValue=""
            value={values.ics}
            onChange={(event) => setFieldValue('ics', event.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            // disabled={isReviewMode}
            // {...field}
          />
          {errors.ics && touched.ics ? (
            <div style={{ color: 'red' }}>{errors.ics}</div>
          ) : null}
        </FormControl>

        <FormControl>
          <FormLabel id="demo-row-radio-buttons-group-label">
            {t(`${translateDomain}.steps.step3.payment_type`)}
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            onChange={(event) =>
              setFieldValue('paymentType', event.target.value)
            }
          >
            <FormControlLabel
              value="punctual"
              control={<Radio />}
              label={t(`${translateDomain}.steps.step3.punctual`)}
            />
            <FormControlLabel
              value="recurrent"
              control={<Radio />}
              label={t(`${translateDomain}.steps.step3.recurrent`)}
            />
          </RadioGroup>
          {errors.paymentType && touched.paymentType ? (
            <div style={{ color: 'red' }}>{errors.paymentType}</div>
          ) : null}
        </FormControl>
      </Box>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '100%',
          marginTop: '20px',
        }}
      >
        <Button variant="contained" color="default" onClick={handleCancel}>
          {t(`${translateDomain}.buttons.cancel`)}
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleOpen();
          }}
        >
          {t(`${translateDomain}.buttons.continue`)}
        </Button>
      </Box>

      <Modal
        open={open}
        onClose={handleClose}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: 'white',
            width: '50%',
            minHeight: '200px',
            padding: '20px',
          }}
        >
          <Typography variant="h5" align="center">
            {t(`${translateDomain}.steps.step4.title`)}
          </Typography>

          <Box
            style={{
              display: 'flex',
              justifyContent: 'space-around',
              marginTop: '20px',
            }}
          >
            <Button variant="contained" color="default" onClick={handleClose}>
              {t(`${translateDomain}.buttons.cancel`)}
            </Button>

            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleContinue();
              }}
            >
              {t(`${translateDomain}.buttons.continue`)}
            </Button>
          </Box>
        </div>
      </Modal>
    </Box>
  );
};

CreateDebitThirdStepYesOption.propTypes = {
  handleContinue: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default CreateDebitThirdStepYesOption;
