import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Box, Tooltip } from '@material-ui/core';
import NumberFormat from 'helpers/NumberFormat';
import style from 'assets/jss/components/cardNumber';
import SvgIcon from 'components/AppIcons/SvgIcon';
import EuroIcon from '../../../assets/icon/euro.svg';

const useStyles = makeStyles((theme) => style(theme));

const CardNumber = ({
  data,
  text,
  icon,
  bgIcon,
  className,
  href,
  readOnly = false,
  tooltipTitle,
  onClick = () => {},
}) => {
  const formatData = () => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(data)) {
      return `${NumberFormat.n(data, 0)}`;
    }
    return `${data}`;
  };

  const classes = useStyles({ bgIcon, readOnly });

  const renderCardBody = () => (
    <Box className={classNames(classes.root, className)} onClick={onClick}>
      <Box className={classNames(classes.boxIcon)}>{icon}</Box>
      <Box className={classes.boxContent}>
        <Box className={classes.boxTotal}>
          <span className={classes.number}>{`${formatData() || 0}`}</span>
          <SvgIcon path={EuroIcon} />
        </Box>
        <span className={classes.title}>{text}</span>
      </Box>
    </Box>
  );

  const renderBody = (children) => {
    if (href) {
      return (
        <Link to={href} onClick={onClick}>
          {children}
        </Link>
      );
    }
    return children;
  };

  return (
    <Box>
      {((child) => {
        if (tooltipTitle) {
          return (
            <Tooltip title={tooltipTitle} arrow placement="top">
              {child}
            </Tooltip>
          );
        }
        return child;
      })(renderBody(renderCardBody()))}
    </Box>
  );
};

CardNumber.propTypes = {
  data: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  icon: PropTypes.object,
  tooltipTitle: PropTypes.string,
  href: PropTypes.string,
  readOnly: PropTypes.bool,
  onClick: PropTypes.func,
  color: PropTypes.string,
  className: PropTypes.string,
  bgIcon: PropTypes.string,
};

export default CardNumber;
