import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withFormik, Form } from 'formik';
import { styled, withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import AppCheckbox from '../../../components/AppCheckbox';
import AppButton from '../../../components/AppButton';
import TextField from '../../../components/FormElements/textField';
import { AppDividerS } from '../../../components/AppDividers';
import LoadingIndicator from '../../../components/LoadingIndicatorDialog';

import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';

import Rights from './rights';

import state from './state';
import dispatch from './dispatch';

import styles from '../../../assets/jss/root';
import './index.css';

const AppRowCheckBox = styled(Box)(() => ({
  width: '100%',
  height: '100%',
  padding: 0,
  margin: 0,
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  alignItems: 'center',
  justifyContent: 'flex-start',
  rowGap: '10px',
  columnGap: '40px',
}));

class RoleForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: this._initOptions(),
    };

    this._onRoleChange = this._onRoleChange.bind(this);
    this._onCheckAllChange = this._onCheckAllChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    const { handleSubmit: formikSubmit } = this.props;
    formikSubmit(e);
  }

  renderLoading(isSubmitting) {
    const { t } = this.props;
    if (isSubmitting) {
      return <LoadingIndicator title={t('loading_single')} open={true} />;
    }
    return null;
  }

  _initOptions() {
    return Rights.map(({ name, rights }) => ({
      name,
      checked: rights.every((right) =>
        this.props.values.rights.includes(right.value)
      ),
    }));
  }

  _onRoleChange(e) {
    const {
      target: { checked, value },
    } = e;
    const {
      setFieldValue,
      values: { rights },
    } = this.props;

    if (checked) {
      setFieldValue('rights', [...rights, value]);
    } else {
      setFieldValue(
        'rights',
        rights.filter((r) => r !== value)
      );
    }

    setTimeout(() => {
      this.setState({ options: this._initOptions() });
    }, 1);
  }

  _onCheckAllChange(name) {
    return (e) => {
      const {
        target: { checked },
      } = e;
      const {
        setFieldValue,
        values: { rights },
      } = this.props;

      if (checked) {
        setFieldValue('rights', [
          ...rights,
          ...Rights.find((r) => r.name === name).rights.map(
            ({ value }) => value
          ),
        ]);
      } else {
        setFieldValue(
          'rights',
          rights.filter(
            (right) =>
              !Rights.find((r) => r.name === name)
                .rights.map(({ value }) => value)
                .includes(right)
          )
        );
      }

      setTimeout(() => {
        this.setState({ options: this._initOptions() });
      }, 1);
    };
  }

  _isChecked(value) {
    const {
      values: { rights },
    } = this.props;

    return rights.includes(value);
  }

  _areAllChecked(name) {
    return this.state.options.find((o) => o.name === name).checked;
  }

  _renderCheckboxes(rights, isSubmitting, readOnly, name) {
    const { t } = this.props;
    return (
      <AppRowCheckBox>
        <AppCheckbox
          disabled={isSubmitting || readOnly}
          type="checkbox"
          checked={this._areAllChecked(name)}
          onClick={this._onCheckAllChange(name)}
          labelText={t('roles.form.all')}
          id={name}
        />
        {rights.map(({ title, value }) => (
          <AppCheckbox
            disabled={isSubmitting || readOnly}
            type="checkbox"
            value={value}
            checked={this._isChecked(value)}
            onClick={this._onRoleChange}
            labelText={t(`roles.form.title.${title}`)}
            id={value}
            key={value}
          />
        ))}
      </AppRowCheckBox>
    );
  }

  _renderOptions(isSubmitting, readOnly) {
    const { t } = this.props;
    return Rights.map(({ name, rights }) => (
      <div className="rights row" key={name}>
        <h5>{t(`roles.form.name.${name}`)}</h5>
        <div className="rights-wrapper">
          {this._renderCheckboxes(rights, isSubmitting, readOnly, name)}
        </div>
      </div>
    ));
  }

  renderSidebarRight(readOnly) {
    const { t, isSubmitting } = this.props;

    return (
      <>
        {this.renderLoading(isSubmitting)}
        <AppButton
          fullWidth
          isDisabled={isSubmitting}
          color="primaryLight"
          text={t('draft')}
          type="button"
          onClick={this.onSubmit}
          // Disable The save Button, if ReadOnly
          disabled={readOnly}
        />
        <AppDividerS />
        <AppButton
          fullWidth
          isDisabled={isSubmitting}
          color="primary"
          text={t('cancel')}
          onClick={() => {
            this.props.history.push('/settings/roles');
          }}
        />
      </>
    );
  }

  render() {
    const {
      t,
      errors,
      classes,
      isSubmitting,
      values: { readOnly },
    } = this.props;
    return (
      // Make the form unclickable if ReadOnly, so to prevent modifications.
      <Form>
        <Layout
          header={
            <Header
              name={t('roles.role')}
              goBack={() => {
                this.props.history.push('/settings/roles');
              }}
            />
          }
          sidebarLeft={true}
          sidebarRight={this.renderSidebarRight(readOnly)}
          showUserCard={true}
          body={
            <div style={{ padding: '15px' }}>
              {errors && errors.internalError && (
                <div className="row">
                  <div className="input-field col s12">
                    <span className="helper-text">{errors.internalError}</span>
                  </div>
                </div>
              )}

              <TextField
                type="text"
                name="role"
                label={t('roles.role')}
                placeholder={t('roles.role')}
                className={classes.textFieldWide}
                // Disable The TextInput if ReadOnly
                disabled={readOnly}
              />

              {this._renderOptions(isSubmitting, readOnly)}
            </div>
          }
        />
      </Form>
    );
  }
}

RoleForm.propTypes = {
  classes: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  t: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  companyAccessRights: PropTypes.object,
  isSubmitting: PropTypes.bool,
  history: PropTypes.object,
  handleSubmit: PropTypes.func,
};

const RoleWithForm = withFormik({
  displayName: 'RoleForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(RoleForm);

const RoleFormWithStyles = withStyles(styles)(RoleWithForm);
const RoleFormWithRouter = withRouter(RoleFormWithStyles);
const TranslatedRoleFormWithStyles = withTranslation()(RoleFormWithRouter);

export default connect(state, dispatch)(TranslatedRoleFormWithStyles);
