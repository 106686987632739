import {
  EXPENSE_OVERVIEW,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  GET_EXPENSE_BY_CATEGORIES,
  GET_EXPENSE_BY_CATEGORIES_SUCCESS,
  GET_EXPENSE_BY_CATEGORIES_FAIL,
} from '../../actions/Types';

const initialState = {
  expenseOverview: {},
  byCategories: { loading: false, data: null, error: null },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case EXPENSE_OVERVIEW:
      return {
        ...state,
        expenseOverview: action.payload,
      };
    case GET_EXPENSE_BY_CATEGORIES:
      return {
        ...state,
        byCategories: { loading: true, data: null, error: null },
      };
    case GET_EXPENSE_BY_CATEGORIES_SUCCESS:
      return {
        ...state,
        byCategories: { loading: false, data: action.payload, error: null },
      };
    case GET_EXPENSE_BY_CATEGORIES_FAIL:
      return {
        ...state,
        byCategories: { loading: false, data: null, error: action.payload },
      };
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return initialState;
    default:
      return state;
  }
};
