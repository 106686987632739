import React from 'react';
import PropTypes from 'prop-types';

import SnackBar from '../Base';

const SnackBarView = ({
  message,
  vertical,
  horizontal,
  open,
  onClose,
  autoHideDuration,
  variant,
}) => {
  return (
    <SnackBar
      message={message}
      vertical={vertical}
      horizontal={horizontal}
      open={open}
      onClose={onClose}
      variant={variant}
      autoHideDuration={autoHideDuration}
    />
  );
};

SnackBarView.propTypes = {
  message: PropTypes.string,
  vertical: PropTypes.string,
  horizontal: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  variant: PropTypes.string,
};

export default SnackBarView;
