const mentions = [
  {
    key: 'escompte',
    value: "Pas d'escompte pour règlement anticipé.",
  },
  {
    key: 'retard',
    value: `En cas de retard de paiement,
    une pénalité égale à 3 fois le taux intérêt légal sera exigible (Article
    L441-6, alinéa 12 du Code de Commerce). Pour tout professionnel, en sus
    des indemnités de retard, toute somme, y compris l'acompte, non payée à
    sa date d'exigibilité produira de plein droit le paiement d'une indemnité
    forfaitaire de 40 euros due au titre des frais de recouvrement (Art. 441-6,
    I al. 12 du code de commerce et D.441-5).`,
  },
];

export default mentions;
