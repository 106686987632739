import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
// import axios from 'axios';
import { useLocation } from 'react-router-dom';

import bankinServices from 'services/BankinApiService';

import { useRouter } from 'hooks/routerHooks';
import Layout from 'components/Layout';
import Header from 'components/Header';
import CircularProgressCentered from 'components/CircularProgressCentered';
import BankTransactionTable from 'components/BankTransactionTable';

const BankAccountTransactions = (props) => {
  const location = useLocation();
  let bankName;
  let accountName;
  if (location.state) {
    accountName = location.state.accountName;
    bankName = location.state.bankName;
  }
  const HeaderTitle = `${bankName} - ${accountName}`;
  const { id: accountId } = props.match.params;
  // const { t } = useTranslation();
  const { history } = useRouter();
  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState({});
  const bankCategories = useSelector((state) => state.bankCategories.data);
  const errorCategories = useSelector((state) => state.bankCategories.error);
  const companyId = useSelector((state) => state.loggedUserCompany.company._id);
  const userId = useSelector((state) => state.loggedUser.user._id);

  const getTransactions = async () => {
    const res = await bankinServices.getTransactions(
      companyId,
      userId,
      accountId
    );

    if (res.data && res.data.data) {
      setTransactions(res.data.data);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(transactions).length === 0) {
      getTransactions();
    }
  }, []);

  const renderBody = () => {
    if (loading) {
      if (bankCategories === null && errorCategories) {
        return (
          <div>
            error happened <pre>{JSON.stringify(errorCategories, null, 2)}</pre>
          </div>
        );
      }
      return <CircularProgressCentered />;
    }
    if (!loading && errorCategories) {
      return (
        <div>
          error happened <pre>{JSON.stringify(errorCategories, null, 2)}</pre>
        </div>
      );
    }
    return (
      <BankTransactionTable
        accountId={accountId}
        ressources={transactions}
        companyId={companyId}
      />
    );
  };

  return (
    <Layout
      header={
        <Header
          name={HeaderTitle || 'Transactions'}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

BankAccountTransactions.propTypes = {
  match: PropTypes.object,
};

export default BankAccountTransactions;
