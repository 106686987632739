import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useField } from 'formik';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

import ErrorMessage from 'components/ErrorMessage';

// TODO: removes ugly ids, use only JSS here
import 'assets/css/TextAlignRightSelectAutocomplete.css';

// style
const style = {
  label: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    width: '70%',
  },
  inputRoot: {
    backgroundColor: 'white',
  },
};

const useStyles = makeStyles(() => ({
  ...style,
}));

const SelectAutocomplete = ({
  name,
  label,
  placeholder,
  color,
  values, // TODO" rename into options
  inputValue,
  valueSelected,
  useAsync,
  getOptionLabel,
  onChange,
  variant,
  required,
  // showError,
  noOptionsText,
  renderOption,
  backgroundColor,
  // t,
  readOnly,
  defaultValue,
  disabled,
  handleTextFieldChange = () => {},
}) => {
  const { t } = useTranslation();

  const [field, meta] = useField(name);
  const { error, touched } = meta;
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const loading = open && options.length === 0 && !inputValue;

  // build classes
  const classes = useStyles();

  // Helper function executed on handle Change. Use to perfrom any related actions (analytics, etc.)
  function handleChange(event, value) {
    // execute the onChange function if any is passed
    // the `value` parameter contains a single element of the `values` prop. So it could be a string, an object, etc. depending on what was given to the component
    if (onChange) {
      onChange(value);
    }
    // add any code below
  }

  const getInputChangeProps = () => {
    if (handleTextFieldChange) {
      return {
        inputValue,
        freeSolo: true,
        onInputChange: (event, newInputValue) => {
          if (handleTextFieldChange) handleTextFieldChange(newInputValue);
        },
      };
    }
    return null;
  };

  React.useEffect(() => {
    // eslint-disable-next-line no-unused-vars
    let active = true;

    if (!loading) {
      return undefined;
    }

    if (values && !useAsync) {
      // this component is used in "local mode", we don't need to search external values and can simply ue the values passed as props
      setOptions(values);
      return () => {
        active = false;
      };
    }

    // (async () => {
    //   const response = await axios.get(
    //     'https://country.register.gov.uk/records.json?page-size=5000'
    //   );
    //   const countries = await response.json();

    //   if (active) {
    //     setOptions(Object.keys(countries).map(key => countries[key].item[0]));
    //   }
    // })();

    return () => {
      active = false;
    };
  }, [loading]);

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <React.Fragment>
      <Autocomplete
        {...field}
        {...getInputChangeProps()}
        defaultValue={defaultValue}
        disabled={readOnly || disabled}
        style={style}
        name={name}
        id={name}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        getOptionLabel={(elem) => {
          if (!values || (values && !values.length) || !elem) {
            return '';
          }
          return getOptionLabel(elem);
        }} // handles the case where values are an empty array. In that case, the getOptionLabel passed will return an error
        options={values}
        value={valueSelected || ''}
        loading={loading}
        onChange={handleChange}
        classes={backgroundColor && backgroundColor === 'white' && classes}
        noOptionsText={noOptionsText || t('select.no_options')}
        renderOption={renderOption}
        renderInput={(params) => (
          <TextField
            fullWidth
            margin="dense"
            {...params}
            color={color}
            error={error && touched}
            label={label}
            placeholder={placeholder}
            variant={variant}
            required={required}
            onChange={(e) => {
              if (handleTextFieldChange) handleTextFieldChange(e.target.value);
            }}
            InputLabelProps={{ classes: { root: classes.label } }}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
      <ErrorMessage showError={error && touched} error={error} />
    </React.Fragment>
  );
};

SelectAutocomplete.propTypes = {
  name: PropTypes.string.isRequired,
  getOptionLabel: PropTypes.func.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  useAsync: PropTypes.bool,
  color: PropTypes.string,
  values: PropTypes.array,
  inputValue: PropTypes.string,
  valueSelected: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  variant: PropTypes.string,
  required: PropTypes.bool,
  // error: PropTypes.string,
  // showError: PropTypes.bool,
  noOptionsText: PropTypes.string,
  renderOption: PropTypes.func,
  backgroundColor: PropTypes.string,
  // t: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
  handleTextFieldChange: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
  disabled: PropTypes.bool,
};

SelectAutocomplete.defaultProps = {
  useAsync: false,
  color: 'primary',
  label: '',
  variant: 'outlined',
  required: false,
};

export default SelectAutocomplete;
