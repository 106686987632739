import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'hooks/routerHooks';
import Header from 'components/Header';
import Layout from 'components/Layout';
import { checkIfOneMoreDocumentIsRequired } from 'helpers/TreezorHelper';
import CircularProgressCentered from 'components/CircularProgressCentered';
import { putTreezorUser, sendTreezorNotif } from 'actions/UserTreezorActions';
import TreezorCompelentaryDocFormik from 'components/CardComponents/Treezor/TreezorComplementaryDoc/TreezorCompelentaryDocFormik';
import initialValues from './initialValues';
import { prepareData } from './handleSubmit';
import validationSchema from './validation';

const DocNeedFormik = () => {
  const dispatch = useDispatch();
  const { history } = useRouter();
  const [document, setDocument] = useState(null);
  const [treezorUser, setTreezorUser] = useState(null);
  const { treezorUsers } = useSelector((state) => state.treezor);
  const { company } = useSelector((reduxState) => reduxState.loggedUserCompany);
  const { t } = useTranslation();

  const onSubmit = (values, actions) => {
    const formatedData = prepareData(values, treezorUsers);
    dispatch(putTreezorUser(formatedData));
    setDocument(null);
    actions.setSubmitting(false);
  };

  const sendNotif = () => {
    dispatch(sendTreezorNotif());
    history.push('treezorPending');
  };

  useEffect(() => {
    const userWithOneMoreDocNeeded =
      checkIfOneMoreDocumentIsRequired(treezorUsers);
    if (userWithOneMoreDocNeeded) {
      const newDocument = {
        type: null,
        filename: null,
      };
      setDocument(newDocument);
      setTreezorUser(userWithOneMoreDocNeeded);
    }
  }, []);

  useEffect(() => {
    if (!checkIfOneMoreDocumentIsRequired(treezorUsers)) {
      sendNotif();
    }
  }, [treezorUsers]);

  const renderBody = () => {
    return (
      <>
        {document ? (
          <TreezorCompelentaryDocFormik
            document={document}
            treezorUser={treezorUser}
            company={company}
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          />
        ) : (
          <CircularProgressCentered />
        )}
      </>
    );
  };

  return (
    <Layout
      header={
        <Header
          name={t('Document complémentaire')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

export default DocNeedFormik;
