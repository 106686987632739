import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AppState from './AppState';

const InvoiceState = ({ state }) => {
  const { t } = useTranslation();

  if (state === t(`state.overdue`)) {
    return <AppState state="redState" value={t(`invoices.status.late`)} />;
  }

  if (state === t(`state.draft`)) {
    return <AppState state="greyState" value={t(`state.draft`)} />;
  }

  if (state === t(`state.pending`)) {
    return <AppState state="yellowState" value={t(`state.pending`)} />;
  }

  if (state === t(`state.validated`)) {
    return <AppState state="greenState" value={t(`state.validated`)} />;
  }

  if (state === t(`state.paid`)) {
    return <AppState state="greenState" value={t(`state.paid`)} />;
  }

  if (state === t(`state.canceled`)) {
    return <AppState state="greyState" value={t(`state.canceled`)} />;
  }

  if (state === t(`state.rejected`)) {
    return <AppState state="greyState" value={t(`state.rejected`)} />;
  }

  if (state === t(`state.upcoming`)) {
    return <AppState state="greenState" value={t(`state.upcoming`)} />;
  }

  return null;
};

InvoiceState.propTypes = {
  state: PropTypes.string.isRequired,
};

export default InvoiceState;
