import service from 'services/RevenueService';

import { selectLoggedUserCompanyId } from 'selectors/loggedUserCompanySelectors';

import { setGlobalError } from 'actions/GlobalError';

import {
  userCanCreateInvoice,
  userCanReadInvoice,
} from 'selectors/rightsSelector/invoiceRequests';

import {
  FETCHING_REVENUES,
  FETCH_REVENUES_FAILED,
  FETCH_REVENUES_SUCCESS,
  FETCHING_REVENUE,
  FETCH_REVENUE_SUCCESS,
  FETCH_REVENUE_FAILED,
  CREATING_REVENUE,
  CREATE_REVENUE_SUCCESS,
  CREATE_REVENUE_FAILED,
  CLEAR_REVENUE,
  FETCHING_REVENUE_OVERVIEW,
  FETCH_REVENUE_OVERVIEW_SUCCESS,
  FETCH_REVENUE_OVERVIEW_FAILED,
  FETCH_REVENUES_PAGINATE,
  REFRESH_REVENUES_PAGINATE,
} from 'actions/Types';
import uploadFile from 'helpers/fileUploadHelper';
import paginateAction from 'helpers/paginateAction';

// ----------------------------------------------------------------------------
//
// Fetch ALL
//
export const fetchingAllRevenues = () => ({
  type: FETCHING_REVENUES,
});

export const fetchAllRevenueSuccess = (revenues) => ({
  type: FETCH_REVENUES_SUCCESS,
  payload: { revenues },
});

export const fetchAllRevenuesFailure = (error) => ({
  type: FETCH_REVENUES_FAILED,
  payload: { error },
});

export const fetchRevenuePaginate =
  (query, filters, refresh) => async (dispatch, getState) => {
    const { revenuePaginate } = getState();
    const res = await paginateAction({
      state: revenuePaginate,
      dispatch,
      service: service.fetchRevenuePaginate,
      query,
      filters,
      refresh,
      actions: {
        refresh: REFRESH_REVENUES_PAGINATE,
        fetch: FETCH_REVENUES_PAGINATE,
      },
    });
    return res;
  };

export const fetchRevenues = () => async (dispatch, getState) => {
  if (!userCanReadInvoice(getState())) {
    fetchAllRevenuesFailure('403');
    return;
  }
  dispatch(fetchingAllRevenues());
  try {
    const companyId = selectLoggedUserCompanyId(getState());

    if (companyId) {
      const result = await service.fetchAll({
        company_id: companyId,
      });

      dispatch(fetchAllRevenueSuccess(result.data));
    } else {
      // not logged in yet so returns an empty array
      dispatch(fetchAllRevenueSuccess([]));
    }
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch(fetchAllRevenuesFailure(error));
  }
};

// ----------------------------------------------------------------------------
//
// fetch by id
//
export const fetchingRevenue = () => ({
  type: FETCHING_REVENUE,
});

export const fetchRevenueSuccess = (revenue) => ({
  type: FETCH_REVENUE_SUCCESS,
  payload: { revenue },
});

export const fetchRevenueFailure = (error) => ({
  type: FETCH_REVENUE_FAILED,
  payload: { error },
});

export const fetchRevenueById = (id) => async (dispatch, getState) => {
  if (!userCanReadInvoice(getState())) {
    fetchRevenueFailure('403');
    return;
  }
  dispatch(fetchingRevenue());
  try {
    const result = await service.fetchById({
      id,
    });
    dispatch(fetchRevenueSuccess(result.data));
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch(fetchRevenueFailure(error));
  }
};

// ----------------------------------------------------------------------------
//
// Create
//
export const creatingRevenue = () => ({
  type: CREATING_REVENUE,
});

export const createRevenueSuccess = (revenue) => ({
  type: CREATE_REVENUE_SUCCESS,
  payload: { revenue },
});

export const createRevenueFailure = (error) => ({
  type: CREATE_REVENUE_FAILED,
  payload: { error },
});

export const createRevenue = (data) => async (dispatch, getState) => {
  if (!userCanCreateInvoice(getState())) {
    createRevenueFailure('403');
    return;
  }
  dispatch(creatingRevenue());
  try {
    const filename = data.filename
      ? await uploadFile(data.filename, getState().loggedUserCompany.company)
      : '';
    const result = await service.create({ ...data, filename });
    dispatch(createRevenueSuccess(result.data));
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch(createRevenueFailure(error));
  }
};

// ----------------------------------------------------------------------------
//
// Overview
//
export const fetchingOverview = () => ({
  type: FETCHING_REVENUE_OVERVIEW,
});

export const fetchOverviewSuccess = (data) => ({
  type: FETCH_REVENUE_OVERVIEW_SUCCESS,
  payload: data,
});

export const fetchOverviewFailure = (error) => ({
  type: FETCH_REVENUE_OVERVIEW_FAILED,
  payload: { error },
});

export const fetchRevenueOverview = () => async (dispatch, getState) => {
  dispatch(fetchingOverview());
  const { endFiscalYear } = getState().loggedUserCompany.company;
  if (endFiscalYear) {
    try {
      const result = await service.fetchOverview(
        getState().loggedUserCompany.company.endFiscalYear
      );
      dispatch(fetchOverviewSuccess(result.data));
    } catch (error) {
      dispatch(setGlobalError(error));
      dispatch(fetchOverviewFailure(error));
    }
  }
};

// CLEAR_REVENUE

export const clearRevenue = () => ({
  type: CLEAR_REVENUE,
});
