const { makeStyles } = require('@material-ui/core');

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    height: '200px',
    border: '2px dashed #3d319da3',
    borderRadius: '10px',
    margin: '10px',
  },

  containerTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },

  fileItemContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100px',
    width: '100px',
    boxShadow: '#3d319d73 0px 1px 4px',
    padding: '10px',
    margin: '10px',
  },

  thumbnail: {
    flex: '0 0 60%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },

  contentBody: {
    flex: '0 0 40%',
  },

  title: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
}));

export default useStyles;
