import { bindActionCreators } from 'redux';
import {
  fetchClientProjects,
  deleteClientProject,
} from '../../../actions/ClientProjectActions';

export default (dispatch) =>
  bindActionCreators(
    {
      fetchClientProjects,
      deleteClientProject,
    },
    dispatch
  );
