import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { sub } from 'date-fns';
import PropTypes from 'prop-types';

const BankTransactionStatementPopup = ({
  selectedStartDate,
  setSelectedStartDate,
  selectedEndDate,
  setSelectedEndDate,
  exportTransactionsToPDF,
  setStatementDialogOpen,
  isStatementDialogOpen,
}) => {
  // eslint-disable-next-line no-unused-vars
  const [startDatePickerMaxDate, setStartDatePickerMaxDate] = useState(
    sub(new Date(), { days: 1 })
  );
  // eslint-disable-next-line no-unused-vars
  const [startDatePickerMinDate, setStartDatePickerMinDate] =
    useState(undefined);
  // eslint-disable-next-line no-unused-vars
  const [endDatePickerMaxDate, setEndDatePickerMaxDate] = useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [endDatePickerMinDate, setEndDatePickerMinDate] = useState(undefined);

  const handleCloseStatementDialog = () => {
    setStatementDialogOpen(false);
  };

  return (
    <Dialog
      onClose={() => handleCloseStatementDialog()}
      aria-labelledby="statement-dialog"
      open={isStatementDialogOpen}
    >
      <DialogTitle id="statement-dialog">
        <Typography
          color="primary"
          variant="h5"
          gutterBottom
          style={{ fontWeight: 'bold', textAlign: 'center' }}
        >
          Generate your statement
        </Typography>
      </DialogTitle>
      <Divider variant="middle" style={{ border: '1px solid #3d319d' }} />
      <Box m={2}>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <Grid container justifyContent="space-around">
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="Start date"
              format="dd/MM/yyyy"
              value={selectedStartDate}
              onChange={(date) => {
                setSelectedStartDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              maxDate={startDatePickerMaxDate}
              minDate={startDatePickerMinDate}
            />
            <KeyboardDatePicker
              margin="normal"
              id="date-picker-dialog"
              label="End date"
              format="dd/MM/yyyy"
              value={selectedEndDate}
              onChange={(date) => {
                setSelectedEndDate(date);
              }}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              maxDate={endDatePickerMaxDate}
              minDate={endDatePickerMinDate}
            />
          </Grid>
        </MuiPickersUtilsProvider>
        <Button
          variant="contained"
          color="primary"
          onClick={() => exportTransactionsToPDF()}
        >
          Generate
        </Button>
      </Box>
    </Dialog>
  );
};

BankTransactionStatementPopup.propTypes = {
  selectedStartDate: PropTypes.string,
  setSelectedStartDate: PropTypes.func,
  selectedEndDate: PropTypes.string,
  setSelectedEndDate: PropTypes.func,
  exportTransactionsToPDF: PropTypes.func,
  isStatementDialogOpen: PropTypes.bool,
  setStatementDialogOpen: PropTypes.func,
};

export default BankTransactionStatementPopup;
