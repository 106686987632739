import React from 'react';
import { get } from 'lodash';

const printErrors = (name, mandatory, errors, touched, errorMessage, style) => {
  const errorToPrint = get(errors, name);
  const touchedKey = get(touched, name);

  if (errorToPrint && touchedKey && mandatory) {
    return (
      <div style={style} className={errorMessage}>
        {errorToPrint}
      </div>
    );
  }

  // if (mandatory && !!(touchedKey && !!errorToPrint)) {
  //   return (
  //     <div style={style} className={errorMessage}>
  //       {errorToPrint}
  //     </div>
  //   );
  // }

  return null;
};

export default printErrors;
