import {
  userCanReadCard,
  userCanCreateCard,
  userCanUpdateCard,
} from 'selectors/rightsSelector/cardRequests';
import {
  userCanUpdateTemplate,
  userHasTemplateAccessFeature,
  userCanCreateTemplate,
} from '../../selectors/rightsSelector/templateRequests';

import { userHasCompanySettingsAccessFeature } from '../../selectors/rightsSelector/companySettingsRequests';

import {
  userHasExpenseAccessFeature,
  userCanCreateExpense,
  userCanUpdateExpense,
  userCanReadExpense,
} from '../../selectors/rightsSelector/expenseRequests';

import {
  userHasInvoiceAccessFeature,
  userCanReadInvoice,
  userCanCreateInvoice,
  userCanUpdateInvoice,
} from '../../selectors/rightsSelector/invoiceRequests';

import {
  userHasAdvanceExpenseAccessFeature,
  userCanReadAdvanceExpense,
  userCanCreateAdvanceExpense,
  userCanUpdateAdvanceExpense,
} from '../../selectors/rightsSelector/advanceExpenseRequests';

import { userHasItemAccessFeature } from '../../selectors/rightsSelector/itemRequests';

import { userHasUserManagementAccessFeature } from '../../selectors/rightsSelector/userManagementRequests';

import {
  userHasUserRoleManagementAccessFeature,
  userCanCreateUserRoleManagement,
  userCanUpdateUserRoleManagement,
} from '../../selectors/rightsSelector/userRoleManagementRequests';

import { userHasClientAccessFeature } from '../../selectors/rightsSelector/clientRequests';

import {
  companyDataAreMissing,
  userDataAreMissing,
} from '../../selectors/missingData';

import { userHasBookKeepingAccessFeature } from '../../selectors/rightsSelector/bookKeepingRequests';

import { userCanUpdateEmailTemplate } from '../../selectors/rightsSelector/emailTemplateRequests';

export default ({ loggedUser, appInitialized, ...restState }) => {
  return {
    loggedUser,
    loggedUserCompany: restState.loggedUserCompany,
    companySubscription: restState.companySubscription,
    appInitialized,
    permissions: {
      mandatoryDataAreMissing:
        companyDataAreMissing(restState).length > 0 ||
        userDataAreMissing({
          loggedUser,
          loggedUserRights: restState.loggedUserRights,
        }).length > 0,

      userHasExpenseAccess: userHasExpenseAccessFeature(restState),
      userCanCreateExpense: userCanCreateExpense(restState),
      userCanUpdateExpense: userCanUpdateExpense(restState),
      userCanReadExpense: userCanReadExpense(restState),

      userHasAdvanceExpenseAccess:
        userHasAdvanceExpenseAccessFeature(restState),
      userCanReadAdvanceExpense: userCanReadAdvanceExpense(restState),
      userCanCreateAdvanceExpense: userCanCreateAdvanceExpense(restState),
      userCanUpdateAdvanceExpense: userCanUpdateAdvanceExpense(restState),

      userHasInvoiceAccess: userHasInvoiceAccessFeature(restState),
      userCanReadInvoice: userCanReadInvoice(restState),
      userCanCreateInvoice: userCanCreateInvoice(restState),
      userCanUpdateInvoice: userCanUpdateInvoice(restState),

      userHasTemplateAccess: userHasTemplateAccessFeature(restState),
      userCanCreateTemplate: userCanCreateTemplate(restState),
      userCanUpdateTemplate: userCanUpdateTemplate(restState),
      userHasCompanySettingsAccess:
        userHasCompanySettingsAccessFeature(restState),

      userHasItemAccess: userHasItemAccessFeature(restState),
      userHasUserManagementAccess:
        userHasUserManagementAccessFeature(restState),

      userHasUserRoleManagementAccess:
        userHasUserRoleManagementAccessFeature(restState),
      userCanCreateUserRoleManagement:
        userCanCreateUserRoleManagement(restState),
      userCanUpdateUserRoleManagement:
        userCanUpdateUserRoleManagement(restState),

      userHasClientAccess: userHasClientAccessFeature(restState),
      userHasBookKeepingAccess: userHasBookKeepingAccessFeature(restState),

      userCanUpdateEmailTemplate: userCanUpdateEmailTemplate(restState),

      userCanReadCard: userCanReadCard(restState),
      userCanCreateCard: userCanCreateCard(restState),
      userCanUpdateCard: userCanUpdateCard(restState),
    },
  };
};
