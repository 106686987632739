/* eslint-disable-next-line */
export default ({ id, clientId, clientProjects, clients }) => {
  if (id && clientId) {
    const obj =
      clientProjects.projects[clientId].find(({ _id }) => _id === id) || {};
    obj.clientName = clients.data[clientId].name;
    return obj;
  }
  if (!id && clientId) {
    return {
      client_id: clientId,
      clientName: clients.data[clientId].name,
      name: '',
      description: '',
    };
  }

  return {
    client_id: '',
    clientName: '',
    name: '',
    description: '',
  };
};
