export const OnboardManagementTypes = ['account_monitoring', 'only_access'];

export const OnboardManagementTypesAndOptions = [
  {
    title: 'account_monitoring',
    options: [
      'account_monitoring_1',
      'account_monitoring_2',
      'account_monitoring_3',
      'account_monitoring_4',
      'account_monitoring_5',
      'account_monitoring_6',
      'account_monitoring_7',
    ],
  },
  {
    title: 'only_access',
    options: [
      'only_access_1',
      'only_access_2',
      'only_access_3',
      'only_access_4',
      'only_access_5',
      'only_access_6',
      'only_access_7',
    ],
  },
];

export const OnboardSteps = [
  'looking_for',
  'Ou en êtes-vous avec votre activité libérale',
  'adapted_formula',
];
export const OnboardSteps2 = [
  'looking_for',
  'i_choose',
  'adapted_formula',
  'validate',
];

export const CompanyTypes = [
  'sci',
  'commercial_company',
  'liberal_professional',
  'lmp_lmnp',
  'individual_entrepreneur',
  'association',
];

export const CompanyTypesUrlCode = {
  s: 'sci',
  c: 'commercial_company',
  li: 'liberal_professional',
  lm: 'lmp_lmnp',
  i: 'individual_entrepreneur',
  a: 'association',
};

export const ScientistSteps = [
  'subject_vat',
  'number_of_buildings',
  'locations',
  'taxes',
  'co_owned_building',
];

export const TradingCompanyList = [
  'car',
  'building_public_works',
  'wholesalers',
  'freight_transport',
  'public_transport',
  'industry_metallurgy',
  'business_advice',
  'regulated_professions',
  'purchases_and_resale_to_individuals',
  'processing_and_manufacturing',
  'restaurant',
  'playing_spaces',
  'agriculture',
  'medical_and_paramedical_professions',
  'safety_of_goods_and_people',
  'cash_transport',
  'personal_services',
  'undertakers',
];
export const IndividualEntrepreneurList = [
  'car',
  'building_public_works',
  'wholesalers',
  'freight_transport',
  'public_transport',
  'industry_metallurgy',
  'business_advice',
  'regulated_professions',
  'purchases_and_resale_to_individuals',
  'processing_and_manufacturing',
  'restaurant',
  'playing_spaces',
  'agriculture',
  'medical_and_paramedical_professions',
  'safety_of_goods_and_people',
  'cash_transport',
  'personal_services',
  'undertakers',
];
export const LiberalProfessionalList = [
  'regulated_professions',
  'business_advice',
  'medical_and_paramedical_professions',
  'para_medical_professions',
  'particular_services',
  'tranning',
];

export const TradingCompanySteps = [
  'activity',
  'found',
  'employees',
  'number_of_employees',
];
export const IndividualEntrepreneurSteps = [
  'activity',
  'found',
  'employees',
  'number_of_employees',
];
export const LiberalProfessionalSteps = [
  'profession',
  'profession_type',
  'employees',
  'number_of_employees',
];

export const AssociationsSteps = [
  'financial_association',
  'employees',
  'number_of_employees',
];

export const formulaData = [
  'access_to_management_tools',
  'monitoring_of_accounting_records',
  'preparation_of_tax_declarations',
  'preparation_and_presentation_of_financial_statements',
  'payment_links_included',
];

export const personalInfoCode = {
  n: 'name',
  u: 'userName',
  f: 'firstname',
  e: 'email',
  p: 'phonenumber',
  b: 'business_name',
  a: 'address',
  r: 'rcs',
  rt: 'rcsType',
  s: 'siren',
  nc: 'nic',
  cd: 'code_ape',
  ct: 'city',
  zc: 'zipcode',
  bk: 'bookkeeping',
  kb: 'kbiz',
  lf: 'legal_form',
  t: 'title',
};

export const titleCode = {
  0: 'Mme.',
  1: 'M.',
  2: '',
};

export const PricingTabList = ['monthly', 'annual'];
export const companyCreateTypes = ['create_new_company', 'created_company'];
export const currentLocation = ['adf', 'pr', 'qp', 'fn', 'rl'];

export const blocksPricingHeader = {
  sci: 'sci',
  commercial_company: 'commercial_company',
  individual_entrepreneur: 'individual_entrepreneur',
  lmp_lmnp: 'lmp_lmnp',
  liberal_professional: 'liberal_professional',
  association: 'association',

  future_sci: 'sci',
  future_commercial_company: 'future_commercial_company',
  future_individual_entrepreneur: 'future_individual_entrepreneur',
  future_lmp_lmnp: 'future_lmp_lmnp',
  future_liberal_professional: 'future_liberal_professional',
  future_association: 'future_association',
};

export const formuleTexData = {
  sci: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5, 6],
  },
  future_sci: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5],
  },
  commercial_company: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  future_commercial_company: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5, 6],
  },
  individual_entrepreneur: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  future_individual_entrepreneur: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5, 6],
  },
  lmp_lmnp: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5],
  },
  future_lmp_lmnp: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4],
  },
  liberal_professional: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  future_liberal_professional: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5, 6],
  },
  association: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5, 6, 7],
  },
  future_association: {
    title: {
      top: 'top',
      bottom: 'bottom',
      bottom_second: 'bottom_second',
    },
    texts: [0, 1, 2, 3, 4, 5, 6, 7],
  },
};

export const personalInforType = {
  sci: {
    create_new_company: 'create_new',
    created_company: 'created',
  },
  commercial_company: {
    create_new_company: 'create_new',
    created_company: 'created',
  },
  liberal_professional: {
    create_new_company: 'create_new',
    created_company: 'created',
  },
  lmp_lmnp: {
    create_new_company: 'create_new',
    created_company: 'created',
  },
  individual_entrepreneur: {
    create_new_company: 'create_new',
    created_company: 'created',
  },
  association: {
    create_new_company: 'create_new',
    created_company: 'created',
  },
};

export const finalPageMessage = {
  sci: 'sci_final_message',
  commercial_company: 'commercial_company_final_message',
  liberal_professional: 'liberal_professional_final_message',
  lmp_lmnp: 'lmp_lmnp_final_message',
  individual_entrepreneur: 'individual_entrepreneur_final_message',
  association: 'association_final_message',
  future_sci: 'future_sci_final_message',
  future_commercial_company: 'future_commercial_company_final_message',
  future_liberal_professional: 'future_liberal_professional_final_message',
  future_lmp_lmnp: 'future_lmp_lmnp_final_message',
  future_individual_entrepreneur:
    'future_individual_entrepreneur_final_message',
  future_association: 'future_association_final_message',
};

export const dataSubmitInit = {
  account_type: '',
  'is_association_non-profit': '',
  is_association_taxed: '',
  have_association_employees: '',
  have_cost_accounting: '',
  will_have_activity: '',
  partners_have: '',
  form_exercise: '',
  having_employees: '',
  have_cash_register: '',
  'association_be_non-profit': '',
  association_be_taxed: '',
  will_association_have_employees: '',
  need_cost_accounting: '',
  activity: '',
  partners_will_have: '',
  company_type: '',
  deposit_capital: '',
  have_a_crate: '',
  will_personal_assets_to_business: '',
  will_work_with_husband_wife: '',
  personal_assets_to_business: '',
  employees_expect: '',
  would_have_cash_register: '',
  income: '',
  a_professional: '',
  multiple_properties: '',
  income_be_less_than_15000: '',
  many_buildings: '',
  many_leasers: '',
  vat_or_not: '',
  will_have_tax_modality: '',
  work_with_husband_wife: '',
};

export const formulaDescriptions = {
  basique: ['0', '1', '2', '3'],
  standard: ['0', '1', '2', '3', '4', '5', '6'],
  premium: ['0', '1', '2', '3', '4', '5', '6'],
};

export const getQuestionKeys = (questionList) => {
  return questionList.reduce((prev, current) => {
    return [...prev, current.key];
  }, []);
};

export const chunkArray = (arr, size) => {
  return arr.reduce(
    (acc, _, i) => (i % size ? acc : [...acc, arr.slice(i, i + size)]),
    []
  );
};

export const prepareQueryParams = (queryParams, key, value, object) => {
  const params = {};
  // eslint-disable-next-line no-restricted-syntax
  for (const param of queryParams) {
    // eslint-disable-next-line prefer-destructuring
    params[param[0]] = param[1];
  }
  let newParams = {};
  if (key) {
    newParams = {
      ...params,
      ...{ [key]: value },
    };
  }
  if (object) {
    // Remnove empty item
    const data = Object.entries(object).reduce((a, [k, v]) => {
      // eslint-disable-next-line no-param-reassign
      const rs = !v ? a : ((a[k] = v), a);
      return rs;
    }, {});
    newParams = { ...params, ...{ ...newParams, ...data } };
  }
  const newQueryParams = new URLSearchParams(newParams);
  return newQueryParams;
};

export default {
  OnboardManagementTypes,
  OnboardSteps,
  CompanyTypes,
  ScientistSteps,
  TradingCompanyList,
  TradingCompanySteps,
  IndividualEntrepreneurList,
  IndividualEntrepreneurSteps,
  LiberalProfessionalList,
  LiberalProfessionalSteps,
  AssociationsSteps,
  formulaData,
  PricingTabList,
  dataSubmitInit,
  companyCreateTypes,
  formuleTexData,
  finalPageMessage,
  personalInforType,
  blocksPricingHeader,
  CompanyTypesUrlCode,
  currentLocation,
  personalInfoCode,
  titleCode,
  getQuestionKeys,
  chunkArray,
  prepareQueryParams,
};
