const style = (theme) => {
  return {
    root: (props) => ({
      display: 'flex',
      width: '100%',
      height: '100%',
      borderRadius: '8px',
      backgroundColor: 'white',
      padding: theme.spacing(2),
      alignItems: 'center',
      gap: theme.spacing(3),
      alignSelf: 'stretch',
      boxShadow: theme.palette.boxShadow.bg3,
      flex: '1 0 0',
      cursor: !props.readOnly ? 'pointer' : 'inherit',
    }),
    boxIcon: (props) => ({
      display: 'flex',
      width: '64px',
      height: '64px',
      padding: theme.spacing(1),
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '50%',
      backgroundColor: props.bgIcon,
    }),
    number: {
      color: theme.palette.neutral[800],
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 'normal',
    },
    boxContent: {
      display: 'flex',
      gap: theme.spacing(0.5),
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flex: '1 0 0',
    },
    boxTotal: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(0.25),
      alignItems: 'center',
    },
    currencyIcon: {
      width: '24px',
      height: '24px',
    },
    title: {
      fontSize: '0.875rem',
      fontWeight: 400,
      lineHeight: '150%',
      color: theme.palette.neutral[500],
    },
    iconButton: {
      display: 'flex',
      width: '40px',
      height: '40px',
      padding: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.palette.neutral[100],
    },
  };
};

export default style;
