import { useState } from 'react';

function useDialog() {
  const [showDialog, setDialog] = useState(false);

  function toggleDialog() {
    setDialog(!showDialog);
  }

  return {
    showDialog,
    toggleDialog,
  };
}

function useCreateDialog() {
  const { showDialog, toggleDialog } = useDialog();
  const [newItem, setNewItem] = useState(null);

  function removeNewItem() {
    setNewItem(null);
  }

  return {
    showDialog,
    toggleDialog,
    newItem,
    setNewItem,
    removeNewItem,
  };
}

export { useDialog, useCreateDialog };
