import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import Checkbox from '@material-ui/core/Checkbox';

import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appCheckbox';
import '../../assets/css/icons.css';

const AppCheckbox = ({
  checked,
  labelText,
  labelStyle,
  useStyle,
  onClick,
  disabled,
  id = `checkbox-${Math.random()}`,
  color,
  classes,
  ...rest
}) => {
  const checkboxTextClasses = classNames({
    [classes.text]: true,
    [classes.disabledText]: disabled,
  });

  const [checkedState, setCheckedState] = React.useState(checked);

  useEffect(() => {
    setCheckedState(checked);
  }, [checked]);

  return (
    <div className={classes.contrainer}>
      <Checkbox
        type="checkbox"
        color={color}
        id={id}
        checked={checkedState}
        disabled={disabled}
        onChange={(e) => {
          if (!rest.isDisableClick) {
            setCheckedState(e.target.checked);
          }
          if (onClick) {
            onClick(e);
          }
        }}
        {...rest}
      />

      <label htmlFor={id} style={labelStyle} className={checkboxTextClasses}>
        {labelText}
      </label>
    </div>
  );
};

AppCheckbox.propTypes = {
  labelText: PropTypes.string,
  checked: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  useStyle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classes: PropTypes.object,
  color: PropTypes.string,
  labelStyle: PropTypes.object,
};
AppCheckbox.defaultProps = {
  children: null,
  useStyle: 'secondary',
  checked: false,
  disabled: false,
  color: 'secondary',
  labelStyle: {},
};

export default withStyles(style)(AppCheckbox);
