import { userCanValidateExpense } from 'selectors/rightsSelector/expenseRequests';
import { userCanReadAdvanceExpense } from '../../../selectors/rightsSelector/advanceExpenseRequests';

export default ({ users, expenses, loggedUser, ...restState }) => ({
  loggedUser,
  users,
  expenses,
  userCanReadAdvanceExpense: userCanReadAdvanceExpense(restState),
  userCanValidate: userCanValidateExpense(restState),
});
