import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
// import { Clear } from '@material-ui/icons';
import AppButton from 'components/AppButton';
import {
  Grid,
  MenuItem,
  TextField,
  Typography,
  makeStyles,
  InputAdornment,
} from '@material-ui/core';
import { REGEX } from 'constants/form';
import { useFormikContext, Field, ErrorMessage } from 'formik';
import { Autocomplete } from 'formik-material-ui-lab';
import {
  TextField as FormikTextField,
  CheckboxWithLabel,
} from 'formik-material-ui';
// import ErrorMsg from 'components/ErrorMessage';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
// import { checkIfFieldIsDiplay } from 'helpers/TreezorHelper';
import PENDING_IMAGE from 'assets/images/pending-document.svg';
import {
  TYPE_AUTO_ENTREPRENEUR,
  TYPE_ENTREPRENEUR_INDIVIDUEL,
  TYPE_SOCIETE,
} from 'constants/company';
import CustomTextField from 'components/CustomTextField';
import { EMPLOYE, ASSOCIE, REPRESENTANT_LEGAL } from 'constants/roles';
// import listOfFields from 'components/CardComponents/Treezor/AutoEntrepreneurForm/listOfAutoEntrepreneurFieldsComapny';
import TreezorDropZone from '../NewCompagnyForm/TreezorDropZone';

const useStyles = makeStyles({
  pending: {
    width: '100px',
    height: 'auto',
  },
});

const UserFormFields = ({
  countryList,
  personelTitle,
  identityList,
  identityList2,
  company,
  setFieldValue,
  setDisableSendBtn,
  proofOfAddress,
  incomeRange,
  personalAssets,
  role,
  goBack,
  selectRole,
  setCreateUser,
  rattacheAPersonneMorale,
  newUser,
  companyCredentials,
  // listOfFields,
}) => {
  const { t } = useTranslation();
  const { values, errors, touched } = useFormikContext();
  // const [showError, setShowError] = useState(null);
  const classes = useStyles();

  const { companyType } = company?.settings?.tax_registration;

  const displayIfItCompany = () => {
    if (companyType === TYPE_SOCIETE) {
      if (role === EMPLOYE) {
        return false;
      }
      return true;
    }
    return true;
  };

  const changeRole = () => {
    if (!values.isRepresentantLegal) {
      selectRole(REPRESENTANT_LEGAL);
    } else {
      selectRole(ASSOCIE);
    }
  };

  const changeCreateUser = (e) => {
    if (e.target.value === 'false') {
      setCreateUser(true);
    } else {
      setCreateUser(false);
    }
  };

  // useEffect(() => {
  //   setShowError(checkIfFieldIsDiplay(errors, listOfFields));
  // }, [errors]);

  /* eslint-disable react/prop-types */
  const ErrorM = ({ children }) => {
    return (
      <div>
        <font color="red">{children}</font>
      </div>
    );
  };

  const getCodeBBFToRemove = (codeBBF, list) => {
    switch (codeBBF) {
      case 1:
        return list.filter((identity) => identity.codeBBF !== 5);
      case 5:
        return list.filter((identity) => identity.codeBBF !== 1);
      case 2:
      case 3:
        return list.filter((identity) => identity.codeBBF !== 6);
      case 6:
        return list.filter(
          (identity) => identity.codeBBF !== 3 && identity.codeBBF !== 2
        );
      default:
        return list;
    }
  };

  const getIdentityListWithoutDoublon = (listToClean) => {
    const user1CodeBBF = values.userDocuments.identity.type;
    const user2CodeBBF = values.userDocuments.identity2.type;
    if (listToClean === 1) {
      return getCodeBBFToRemove(user2CodeBBF, identityList);
    }
    return getCodeBBFToRemove(user1CodeBBF, identityList2);
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div style={{ position: 'relative', width: '100%' }}>
        {companyType === TYPE_SOCIETE && (
          <div
            style={{
              fontSize: '3em',
              position: 'absolute',
              top: '-2%',
              right: '0%',
              cursor: 'pointer',
            }}
          >
            {/* <Clear onClick={goBack} /> */}
            <AppButton
              text={t('cancel')}
              noBorder
              type="button"
              onClick={goBack}
            />
          </div>
        )}
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="p">
              <b>
                {newUser
                  ? 'Merci de compléter les informations.'
                  : 'Merci de vérifier les informations pré-remplies et compléter les champs vides.'}
              </b>
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Field
              fullWidth
              component={FormikTextField}
              select
              margin="dense"
              variant="outlined"
              name="title"
              abel={t('client.title')}
              placeholder={t('client.title')}
            >
              {personelTitle &&
                personelTitle.map((title, idx) => {
                  return (
                    <MenuItem key={idx} value={title.value}>
                      {title.label}
                    </MenuItem>
                  );
                })}
            </Field>
          </Grid>
          <Grid item xs={5}>
            <Field
              component={FormikTextField}
              required
              fullWidth
              type="text"
              name="lastname"
              label={t('settings_section.users.form_label_name')}
              placeholder={t('settings_section.users.form_helper_name')}
              color="primary"
              variant="outlined"
              margin="dense"
              disabled={false}
            />
          </Grid>
          <Grid item xs={4}>
            <Field
              component={FormikTextField}
              required
              fullWidth
              type="text"
              name="firstname"
              label={t('settings_section.users.form_label_firstname')}
              placeholder={t('settings_section.users.form_helper_firstname')}
              color="primary"
              variant="outlined"
              margin="dense"
              disabled={false}
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              component={FormikTextField}
              required
              fullWidth
              type="text"
              name="userEmail"
              label={t('settings_section.users.form_label_email')}
              placeholder={t('settings_section.users.form_label_email')}
              color="primary"
              variant="outlined"
              margin="dense"
              disabled={values._id !== null}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomTextField
              required
              type="text"
              name="userMobile"
              label={t('signup.company.mobilePhone')}
              placeholder={t('signup.company.mobilePhones')}
              color="primary"
              errorMessage={t('phone_number_example')}
              immediatelyEror={!values.userMobile.match(REGEX.PHONE.FR.E164)}
              value={values.userMobile}
              onChange={(e) => setFieldValue(e.target.name, e.target.value)}
            />
          </Grid>
          {/* {displayIfItCompany() && (
            <Grid item xs={6}>
              <Field
                component={FormikTextField}
                required
                fullWidth
                type="text"
                name="occupation"
                label={t('settings_section.users.form_label_function')}
                placeholder={t('settings_section.users.form_label_function')}
                color="primary"
                variant="outlined"
                margin="dense"
              />
            </Grid>
          )} */}
          <Grid item xs={3}>
            <Field
              name="nationality"
              component={Autocomplete}
              options={countryList}
              getOptionLabel={(option) => option.name_fr}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  margin="dense"
                  variant="outlined"
                  error={
                    (touched.user && touched.nationality) ||
                    (errors.user && errors.nationality)
                  }
                  helperText={errors.user && errors.nationality}
                  label={t('nationality')}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              name="birthCountry"
              component={Autocomplete}
              options={countryList}
              getOptionLabel={(option) => option.name_fr}
              renderInput={(params) => (
                <TextField
                  {...params}
                  required
                  margin="dense"
                  variant="outlined"
                  error={
                    (touched.user && touched.birthCountry) ||
                    (errors.user && errors.birthCountry)
                  }
                  helperText={errors.user && errors.birthCountry}
                  label={t('birth_country')}
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={FormikTextField}
              required
              fullWidth
              type="text"
              name="placeOfBirth"
              label={t('place_of_birth')}
              placeholder={t('place_of_birth')}
              color="primary"
              variant="outlined"
              margin="dense"
              // disabled={!!(values.placeOfBirth && values.placeOfBirth !== '')}
            />
          </Grid>
          <Grid item xs={3}>
            <Field
              component={KeyboardDatePicker}
              fullWidth
              name="birthday"
              label={t('birthday')}
              format="dd/MM/yyyy"
              inputVariant="outlined"
              color="primary"
              clearable={true}
              required
              margin="dense"
            />
          </Grid>
          {companyType === TYPE_AUTO_ENTREPRENEUR && (
            <Grid item xs={12}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="adressIsDifferent"
                Label={{
                  label: t('adress_is_different'),
                }}
              />
            </Grid>
          )}
          {values.userAddress1 !== null &&
            (companyType !== TYPE_AUTO_ENTREPRENEUR ||
              values.adressIsDifferent) && (
              <>
                <Grid item xs={12}>
                  <Typography variant="p">
                    <b>
                      Attention, il s&apos;agit de votre adresse personnelle.
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Field
                    component={FormikTextField}
                    required
                    fullWidth
                    type="text"
                    name="userAddress1"
                    label={t('address1')}
                    placeholder={t('address1')}
                    color="primary"
                    variant="outlined"
                    margin="dense"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Field
                    component={FormikTextField}
                    required
                    fullWidth
                    type="text"
                    name="userPostcode"
                    label={t('postcode')}
                    placeholder={t('postcode')}
                    color="primary"
                    variant="outlined"
                    margin="dense"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    component={FormikTextField}
                    required
                    fullWidth
                    type="text"
                    name="userCity"
                    label={t('city')}
                    placeholder={t('city')}
                    color="primary"
                    variant="outlined"
                    margin="dense"
                    disabled={false}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Field
                    name="userCountry"
                    component={Autocomplete}
                    options={countryList}
                    getOptionLabel={(option) => option.name_fr}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        required
                        margin="dense"
                        variant="outlined"
                        error={
                          (touched.user && touched.country) ||
                          (errors.user && errors.country)
                        }
                        helperText={errors.user && errors.country}
                        label={t('country')}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          <Grid container spacing={2} style={{ marginTop: '2%' }}>
            {displayIfItCompany() && companyType === TYPE_SOCIETE && (
              <Grid item xs={4}>
                <Grid item xs={12}>
                  <Typography variant="p">
                    <b>
                      Pourcentage du capital ou droits de vote que vous détenez.
                    </b>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={FormikTextField}
                    required
                    fullWidth
                    type="text"
                    name="effectiveBeneficiary"
                    label={t('effective_beneficiary')}
                    placeholder={t('effective_beneficiary')}
                    color="primary"
                    variant="outlined"
                    margin="dense"
                    disabled={false}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {companyType === TYPE_SOCIETE && rattacheAPersonneMorale && (
              <>
                <Grid item xs={4}>
                  <Typography variant="p">
                    <b>Merci de fournir le KBIS de la personne morale.</b>
                  </Typography>
                  <Grid item xs={12}>
                    {values &&
                    values.userDocuments &&
                    values.userDocuments.companyCredentials &&
                    values.userDocuments.companyCredentials.type !== null &&
                    values.userDocuments.companyCredentials.fileName !==
                      null ? (
                      <img
                        src={PENDING_IMAGE}
                        alt="Document is pending"
                        className={classes.pending}
                      />
                    ) : (
                      <div style={{ marginBottom: '10px' }}>
                        <TreezorDropZone
                          name="userDocuments.companyCredentials.type"
                          indentityFile="userDocuments.companyCredentials.fileName"
                          title={t('company_credentials')}
                          optionList={companyCredentials}
                          company={company}
                          setFieldValue={setFieldValue}
                          setDisableSendBtn={setDisableSendBtn}
                        />
                        <ErrorMessage
                          name="userDocuments.companyCredentials.fileName"
                          component={ErrorM}
                        />
                      </div>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="p">
                    <b>Raison social de la personne morale.</b>
                  </Typography>
                  <Field
                    component={FormikTextField}
                    required
                    fullWidth
                    type="text"
                    name="legalName"
                    label={t('treezor.legalName')}
                    placeholder={t('treezor.legalName')}
                    color="primary"
                    variant="outlined"
                    margin="dense"
                    disabled={false}
                  />
                </Grid>
              </>
            )}
          </Grid>
          {displayIfItCompany() && (
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                component={FormikTextField}
                select
                margin="dense"
                variant="outlined"
                name="incomeRange"
                label={`${t('income_range')} (K€)`}
              >
                {incomeRange &&
                  incomeRange.map((income, idx) => {
                    return (
                      <MenuItem key={idx} value={income.val}>
                        {income.val}
                      </MenuItem>
                    );
                  })}
              </Field>
            </Grid>
          )}
          {displayIfItCompany() && (
            <Grid item xs={12} md={6}>
              <Field
                fullWidth
                component={FormikTextField}
                select
                margin="dense"
                variant="outlined"
                name="personalAssets"
                label={`${t('personal_assets')} (K€)`}
              >
                {incomeRange &&
                  personalAssets.map((personalAsset, idx) => {
                    return (
                      <MenuItem key={idx} value={personalAsset.val}>
                        {personalAsset.val}
                      </MenuItem>
                    );
                  })}
              </Field>
            </Grid>
          )}
          {displayIfItCompany() && companyType === TYPE_SOCIETE && (
            <Grid item xs={12} md={12}>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name="isRepresentantLegal"
                Label={{
                  label: t('treezor.RL.question'),
                }}
                onClick={changeRole}
              />
            </Grid>
          )}
          <div style={{ marginBottom: '20px' }}></div>
        </Grid>

        <Grid container direction="row" spacing={2} style={{ marginTop: '4%' }}>
          {values && values.userDocuments && (
            <Grid item xs={12} md={12}>
              <Typography
                variant="button"
                style={{ fontSize: '20px', color: '#02cbcd' }}
              >
                Documents à fournir
              </Typography>
              <br />
              <Typography variant="p">
                {values.isRepresentantLegal ? (
                  <b>
                    Merci de télécharger deux pièces d&apos;identité et une
                    attestation de domicile à votre nom
                  </b>
                ) : (
                  <b>Merci de télécharger deux pièces d&apos;identité</b>
                )}
              </Typography>
            </Grid>
          )}
          {values && values.userDocuments && (
            <Grid item xs={12} md={3}>
              {values.userDocuments.identity &&
              values.userDocuments.identity.type !== null &&
              values.userDocuments.identity.fileName !== null ? (
                <img
                  src={PENDING_IMAGE}
                  alt="Document is pending"
                  className={classes.pending}
                />
              ) : (
                <>
                  <TreezorDropZone
                    name="userDocuments.identity.type"
                    indentityFile="userDocuments.identity.fileName"
                    title={t('identity')}
                    optionList={getIdentityListWithoutDoublon(1)}
                    company={company}
                    setFieldValue={setFieldValue}
                    setDisableSendBtn={setDisableSendBtn}
                  />
                  <ErrorMessage
                    name="documents.identity.fileName"
                    component={ErrorM}
                  />
                </>
              )}
            </Grid>
          )}
          {displayIfItCompany() && (
            <Grid item xs={12} md={3}>
              {values &&
              values.userDocuments &&
              values.userDocuments.identity2 &&
              values.userDocuments.identity2.type !== null &&
              values.userDocuments.identity2.fileName !== null ? (
                <img
                  src={PENDING_IMAGE}
                  alt="Document is pending"
                  className={classes.pending}
                />
              ) : (
                <>
                  <TreezorDropZone
                    name="userDocuments.identity2.type"
                    indentityFile="userDocuments.identity2.fileName"
                    title={t('identity')}
                    optionList={getIdentityListWithoutDoublon(2)}
                    company={company}
                    setFieldValue={setFieldValue}
                    setDisableSendBtn={setDisableSendBtn}
                  />
                  <ErrorMessage
                    name="documents.identity2.fileName"
                    component={ErrorM}
                  />
                </>
              )}
            </Grid>
          )}
          {((companyType !== TYPE_AUTO_ENTREPRENEUR &&
            displayIfItCompany() &&
            values.isRepresentantLegal) ||
            companyType === TYPE_ENTREPRENEUR_INDIVIDUEL) && (
            <Grid item xs={12} md={3}>
              {values &&
              values.userDocuments &&
              values.userDocuments.proofOfAddress &&
              values.userDocuments.proofOfAddress.type !== null &&
              values.userDocuments.proofOfAddress.fileName !== null ? (
                <img
                  src={PENDING_IMAGE}
                  alt="Document is pending"
                  className={classes.pending}
                />
              ) : (
                <>
                  <TreezorDropZone
                    name="userDocuments.proofOfAddress.type"
                    indentityFile="userDocuments.proofOfAddress.fileName"
                    title={t('proof_address')}
                    optionList={proofOfAddress}
                    company={company}
                    setFieldValue={setFieldValue}
                    setDisableSendBtn={setDisableSendBtn}
                  />
                  <ErrorMessage
                    name="documents.proofOfAddress.fileName"
                    component={ErrorM}
                  />
                </>
              )}
            </Grid>
          )}
        </Grid>
        <Typography variant="p">
          <b>
            Notre solution ne peut accepter de clients de nationalité
            Américaine, ni de non résident fiscal Français. Merci de le
            confirmer en cochant les deux cases suivantes.
          </b>
        </Typography>
        <Grid item xs={6}>
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="specifiedUSPerson"
            Label={{
              label: 'Je certifie que je ne suis pas une US Person.',
            }}
          />
          <ErrorMessage name="specifiedUSPerson" component={ErrorM} />
        </Grid>
        <Grid item xs={6}>
          <Field
            component={CheckboxWithLabel}
            type="checkbox"
            name="taxResidence.country"
            Label={{
              label:
                'Je certifie que je suis uniquement un résident fiscal français.',
            }}
          />
          <ErrorMessage name="taxResidence.country" component={ErrorM} />
        </Grid>
        {/* <ErrorMsg showError={showError} error={showError} /> */}
        {companyType === TYPE_SOCIETE && newUser && (
          <Grid item xs={12} md={12}>
            <Field
              component={CheckboxWithLabel}
              type="checkbox"
              name="shouldCreateNewUser"
              Label={{
                label: t(
                  'Souhaitez-vous créer un compte BBF pour cette utilisateur'
                ),
              }}
              onClick={changeCreateUser}
            />
          </Grid>
        )}
      </div>
    </MuiPickersUtilsProvider>
  );
};

UserFormFields.propTypes = {
  countryList: PropTypes.array,
  personelTitle: PropTypes.array,
  identityList: PropTypes.array,
  identityList2: PropTypes.array,
  proofOfAddress: PropTypes.array,
  incomeRange: PropTypes.array,
  personalAssets: PropTypes.array,
  readOnly: PropTypes.bool,
  company: PropTypes.object,
  setFieldValue: PropTypes.func,
  setDisableSendBtn: PropTypes.func,
  role: PropTypes.string,
  listOfFields: PropTypes.array,
  companyCredentials: PropTypes.array,
  goBack: PropTypes.func,
  selectRole: PropTypes.func,
  setCreateUser: PropTypes.func,
  rattacheAPersonneMorale: PropTypes.bool,
  newUser: PropTypes.bool,
};

export default UserFormFields;
