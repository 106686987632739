import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Field, Formik } from 'formik';

/* import service */
import { createPayin } from 'services/PayinService/index';

/* import asset */
import chequeDetailsImage from 'assets/images/cheque_details.png';

/* import UI component */
import { EuroRounded } from '@material-ui/icons';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';

import { Alert } from '@material-ui/lab';

/* import validation schema */
import FormLoading from 'components/CardComponents/Treezor/FormLoading';
import ConfirmChequeModal from './ModalConfirm';

/* import validation schema */
import validationSchema from './validation';

const BankBBFChequeForm = (props) => {
  const { handleCompleteStep, isReviewMode } = props;
  const walletId = useSelector((state) => state.wallet.wallet[0].walletId);
  const { t } = useTranslation();
  const [openModalConfirm, setOpenModalConfirm] = useState(false);
  const [isLoading, setLoadingStatus] = useState(false);

  const [isShowError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const renderAlertStack = (message, type) => (
    <Alert severity={type}>{message}</Alert>
  );

  const onSubmit = async (values) => {
    try {
      const payload = {
        walletId,
        paymentMethodId: 26,
        amount: values.amount,
        currency: 'EUR',
        additionalData: {
          cheque: {
            cmc7: {
              a: values.cmcA,
              b: values.cmcB,
              c: values.cmcC,
            },
            RLMCKey: values.rmlcKey,
            controlType: 'FILTRE',
            checkIssuer: values.issuer,
            issueDate: values.issueDate,
            drawerData: {
              isNaturalPerson: true,
              address: values.address,
              zipCode: values.codeZip,
              city: values.city,
            },
          },
        },
      };
      setLoadingStatus(true);
      await createPayin(payload);
      setLoadingStatus(false);
      handleCompleteStep();
    } catch (error) {
      setLoadingStatus(false);
      setShowError(true);
      setErrorMessage(error.message);
    }
  };

  return (
    <div>
      {isShowError && renderAlertStack(errorMessage, 'error')}
      <Formik
        initialValues={{
          amount: '',
          issuer: '',
          issueDate: '',
          rmlcKey: '',
          cmcA: '',
          cmcB: '',
          cmcC: '',
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, errors, touched }) => (
          <form
            style={{
              display: 'flex',
              flexDirection: 'column',
              padding: '0 24px',
            }}
            onSubmit={handleSubmit}
          >
            <div
              style={{
                borderTop: '1px solid #3d319d',
                borderBottom: '1px solid #3d319d',

                margin: '20px 0',
                padding: '5px 0',
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  padding: '5px ',
                  color: '#3d319d',
                  fontWeight: 'bold',
                }}
              >
                {t('cheque.information_provided')}
              </Typography>

              <Typography
                variant="body1"
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  padding: '5px ',
                  color: '#3d319d',
                }}
              >
                {t('cheque.bbf_require')}
              </Typography>
            </div>

            <Field name="issuer">
              {({ field }) => (
                <TextField
                  label={t('cheque.issuer')}
                  id="issuer"
                  disabled={isReviewMode}
                  {...field}
                />
              )}
            </Field>

            <Field name="issueDate">
              {({ field }) => (
                <TextField
                  label={t('cheque.issue_date')}
                  id="issueDate"
                  type="date"
                  defaultValue="2021-05-24"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  disabled={isReviewMode}
                  {...field}
                />
              )}
            </Field>

            <div
              style={{
                borderTop: '1px solid #3d319d',
                borderBottom: '1px solid #3d319d',

                margin: '20px 0',
                padding: '5px 0',
              }}
            >
              <Typography
                variant="body1"
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  padding: '5px ',
                  fontWeight: 'bold',
                  color: '#3d319d',
                }}
              >
                {t('cheque.bbf_need_to_communicate')}
              </Typography>

              <Typography
                variant="body1"
                style={{
                  fontSize: '14px',
                  textAlign: 'center',
                  padding: '5px ',
                  fontWeight: 'bold',
                  color: '#3d319d',
                }}
              >
                {t('cheque.bbf_cheque_amount_warning')}
              </Typography>
            </div>

            <img
              src={chequeDetailsImage}
              alt="cheque image"
              style={{ display: 'block', width: '100%', margin: '10px auto' }}
            />

            <Field name="amount">
              {({ field }) => (
                <TextField
                  label={`${t('cheque.amount')} *`}
                  id="amount"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <EuroRounded />
                      </InputAdornment>
                    ),
                  }}
                  disabled={isReviewMode}
                  {...field}
                />
              )}
            </Field>
            {errors.amount && touched.amount ? (
              <div style={{ color: 'red' }}>{errors.amount}</div>
            ) : null}

            <Field name="rmlcKey">
              {({ field }) => (
                <TextField
                  label={`${t('cheque.rlmc_key')} *`}
                  id="RLMCKey"
                  disabled={isReviewMode}
                  {...field}
                />
              )}
            </Field>
            {errors.rmlcKey && touched.rmlcKey ? (
              <div style={{ color: 'red' }}>{errors.rmlcKey}</div>
            ) : null}

            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <div>
                <Field name="cmcA">
                  {({ field }) => (
                    <TextField
                      label={`${t('cheque.cmc_a')} *`}
                      id="cmcA"
                      disabled={isReviewMode}
                      {...field}
                    />
                  )}
                </Field>
                {errors.cmcA && touched.cmcA ? (
                  <div style={{ color: 'red' }}>{errors.cmcA}</div>
                ) : null}
              </div>

              <div>
                <Field name="cmcB">
                  {({ field }) => (
                    <TextField
                      label={`${t('cheque.cmc_b')} *`}
                      id="cmcB"
                      disabled={isReviewMode}
                      {...field}
                    />
                  )}
                </Field>
                {errors.cmcB && touched.cmcB ? (
                  <div style={{ color: 'red' }}>{errors.cmcB}</div>
                ) : null}
              </div>

              <div>
                <Field name="cmcC">
                  {({ field }) => (
                    <TextField
                      label={`${t('cheque.cmc_c')} *`}
                      id="cmcC"
                      disabled={isReviewMode}
                      {...field}
                    />
                  )}
                </Field>
                {errors.cmcC && touched.cmcC ? (
                  <div style={{ color: 'red' }}>{errors.cmcC}</div>
                ) : null}
              </div>
            </Box>

            <Button
              variant="contained"
              color="primary"
              style={{ marginTop: '20px' }}
              onClick={() => setOpenModalConfirm(true)}
            >
              {isReviewMode ? 'Confirmer le dépôt' : t('cheque.next_button')}
            </Button>
            <ConfirmChequeModal
              open={openModalConfirm}
              onClose={() => setOpenModalConfirm(false)}
            />
            <FormLoading loading={isLoading} />
          </form>
        )}
      </Formik>
    </div>
  );
};

BankBBFChequeForm.propTypes = {
  handleCompleteStep: PropTypes.func,
  isReviewMode: PropTypes.bool,
};

export default BankBBFChequeForm;
