import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import AppButton from 'components/AppButton';
import AppDialog from 'components/AppDialog';

import { useDialog } from 'hooks/dialogHooks';

const PayButton = ({ separatorStyle, expenseId }) => {
  const { showDialog: showApproveDialog, toggleDialog: toggleApprovalDialog } =
    useDialog();

  const { t } = useTranslation();
  const { values, isSubmitting, setFieldValue } = useFormikContext();

  const { state } = values;

  function pay() {
    // toggleApprovalDialog();
    setFieldValue('action', 'pay', false);
  }

  function renderDialog() {
    if (showApproveDialog) {
      return (
        <AppDialog
          sm
          iconClose
          footer
          title={t('sure')}
          closeDialog={toggleApprovalDialog}
          onConfirmText={t('yes')}
          onCancelText={t('no')}
          onConfirm={pay}
          contentText={t('invoice.confirm.paid')}
          color="secondary"
        />
      );
    }

    return null;
  }

  if (state !== 'validated' || !expenseId) {
    return null;
  }

  return (
    <Fragment>
      {renderDialog()}
      <AppButton
        fullWidth
        isDisabled={isSubmitting}
        text={t(`transaction.reconcile`)}
        color="secondary"
        onClick={() => {
          pay();
        }}
      />
      <div className={separatorStyle} />
    </Fragment>
  );
};

PayButton.propTypes = {
  separatorStyle: PropTypes.string,
  expenseId: PropTypes.string,
};

export default PayButton;
