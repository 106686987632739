import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { format } from 'date-fns';
import MUIDataTable from 'mui-datatables';
import WarningIcon from '@material-ui/icons/Warning';

import FecService from 'services/FecService';
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';

import { userCanReadBookKeeping } from '../../../selectors/rightsSelector/bookKeepingRequests';

import ExportRange from './ExportRange';
import ExportList from './ExportList';

import colors from '../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  lRedText: {
    color: colors.red,
    lineHeight: '13px',
    fontSize: '15px',
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
  },
  unexportTable: {
    marginBottom: '20px',
  },
}));

const columns = (t) => {
  return [
    {
      label: t('book_keeping.unexport_list.journal_code'),
      name: 'journalCode',
    },
    { label: t('book_keeping.unexport_list.journal_lib'), name: 'journalLib' },
    {
      label: t('book_keeping.unexport_list.ecriture_num'),
      name: 'ecritureNum',
    },
    {
      label: t('book_keeping.unexport_list.ecriture_date'),
      name: 'ecritureDate',
      options: {
        customBodyRender: (value) =>
          value ? format(new Date(value), 'dd/MM/yyyy HH:mm') : value,
      },
    },
    { label: t('book_keeping.unexport_list.compte_num'), name: 'compteNum' },
    { label: t('book_keeping.unexport_list.compte_lib'), name: 'compteLib' },
    { label: t('book_keeping.unexport_list.comp_aux_num'), name: 'compAuxNum' },
    { label: t('book_keeping.unexport_list.comp_aux_lib'), name: 'compAuxLib' },
    { label: t('book_keeping.unexport_list.piece_ref'), name: 'pieceRef' },
    {
      label: t('book_keeping.unexport_list.piece_date'),
      name: 'pieceDate',
      options: {
        customBodyRender: (value) =>
          value ? format(new Date(value), 'dd/MM/yyyy HH:mm') : value,
      },
    },
    {
      label: t('book_keeping.unexport_list.ecriture_lib'),
      name: 'ecritureLib',
    },
    { label: t('book_keeping.unexport_list.debit'), name: 'debit' },
    { label: t('book_keeping.unexport_list.credit'), name: 'credit' },
    {
      label: t('book_keeping.unexport_list.ecriture_let'),
      name: 'ecritureLet',
      options: {
        display: false,
      },
    },
    {
      label: t('book_keeping.unexport_list.date_let'),
      name: 'dateLet',
      options: {
        customBodyRender: (value) =>
          value ? format(new Date(value), 'dd/MM/yyyy HH:mm') : value,
        display: false,
      },
    },
    {
      label: t('book_keeping.unexport_list.valid_date'),
      name: 'validDate',
      options: {
        customBodyRender: (value) =>
          value ? format(new Date(value), 'dd/MM/yyyy HH:mm') : value,
        display: false,
      },
    },
    {
      label: t('book_keeping.unexport_list.montantdevise'),
      name: 'montantdevise',
      options: {
        display: false,
      },
    },
    {
      label: t('book_keeping.unexport_list.idevise'),
      name: 'idevise',
      options: {
        display: false,
      },
    },
    {
      label: t('book_keeping.unexport_list.activitite_code'),
      name: 'activititeCode',
      options: {
        display: false,
      },
    },
    {
      label: t('book_keeping.unexport_list.client_code'),
      name: 'clientCode',
      options: {
        display: false,
      },
    },
  ];
};

const DownloadExpenses = () => {
  const tableRef = React.createRef();

  const classes = useStyles();
  const { t } = useTranslation();

  const canReadBookKeeping = useSelector(userCanReadBookKeeping);

  const [error, setError] = useState(null);
  const [data, setData] = useState();

  const fetchUnExportList = async () => {
    const res = await FecService.fetchUnexports();
    if (res.status === 200) {
      setData(res.data);
    }
  };

  const options = {
    filter: false,
    print: false,
    download: false,
    selectableRows: false,
    textLabels: {
      body: {
        noMatch: t('book_keeping.unexport_list.no_data'),
      },
      pagination: {
        rowsPerPage: t('book_keeping.unexport_list.item_per_page'),
      },
    },
  };

  useEffect(() => {
    fetchUnExportList();
  }, []);

  function setErrorMessage(msg) {
    setError(msg);

    setTimeout(() => {
      setError(null);
    }, 5000);
  }

  function renderBody() {
    return (
      <>
        {error && (
          <div className={classes.lRedText}>
            <WarningIcon />
            <p>{error}</p>
          </div>
        )}

        {/* <h3>
          Le Fichier des Ecritures Comptables est encore en test, Less factures
          et dépenses seront bien prises en compte quand il sera opérationnel.
          Merci de votre compréhension
        </h3> */}
        <MUIDataTable
          title={t('book_keeping.unexport_list.title')}
          columns={columns(t)}
          options={options}
          className={classes.unexportTable}
          data={data}
        />

        {canReadBookKeeping && (
          <ExportList setErrorMessage={setErrorMessage} tableRef={tableRef} />
        )}
      </>
    );
  }

  /*
   * MAIN RENDER
   */

  // const isProd = window.location.href.startsWith(
  //   'https://app.bebusinessfocus.com/'
  // );

  return (
    <Layout
      header={
        <Header name={t(`roles.form.name.BookKeeping`)} spaceBetween>
          <div></div>
          {/* {!isProd && ( */}
          <ExportRange
            fetchUnExport={fetchUnExportList}
            setErrorMessage={setErrorMessage}
            tableRef={tableRef}
          />
          {/* )} */}
        </Header>
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

export default DownloadExpenses;
