import { authInstance as axios } from '../../helpers/axiosInterceptor';

const subscriptionHistoryService = {
  getSubscriptionHistory: async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_SUBSCRIPTION_URL}/company-subscription/history`
    );
    return response.data;
  },
};

export default subscriptionHistoryService;
