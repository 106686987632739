import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import { Link } from 'react-router-dom';

import classNames from 'classnames';

import { makeStyles } from '@material-ui/core/styles';

import Header from '../../../../components/Header';
import { AppRowCenterSpaceBetween } from '../../../../components/AppContainers';

import { useRouter } from '../../../../hooks/routerHooks';

import colors from '../../../../constants/colors.json';

import IconPrevious from '../../../../assets/images/inactivePrevious.svg';
import IconNext from '../../../../assets/images/inactiveNext.svg';

const useStyles = makeStyles(() => ({
  midWidth: {
    width: '80px',
  },
  iconStyle: {
    height: '30px',
    width: '30px',
  },
  duplicateIcon: {
    color: colors.fillsDarkGrey,
    transform: 'rotate(90deg)',
    fontWeight: 'bold',
    fontSize: '25px',
    padding: '20px 20px 0px 20px',
  },
}));

const InvoiceFormHeader = () => {
  const classes = useStyles();

  const { t } = useTranslation();
  const { history } = useRouter();
  const { values } = useFormikContext();

  const iconClasses = `icon icon-duplicate`;

  return (
    <Header
      name={t(`invoices.${values.state}_${values.type}`)}
      goBack={() => {
        history.push('/invoices/list');
      }}
      spaceBetween
    >
      {!!values._id && (
        <Link
          to={{
            pathname: `/invoices/new`,
            tempId: values._id,
          }}
        >
          <span
            alt="Duplicate"
            className={classNames(classes.duplicateIcon, iconClasses)}
          />
        </Link>
      )}
      <AppRowCenterSpaceBetween className={classes.midWidth}>
        {values.parent != null ? (
          <Link to={`/invoices/edit/${values.parent}`}>
            <img
              src={IconPrevious}
              alt="Précédent"
              className={classes.iconStyle}
            />
          </Link>
        ) : (
          <p />
        )}

        {values.child != null ? (
          <Link to={`/invoices/edit/${values.child}`}>
            <img src={IconNext} alt="Suivant" className={classes.iconStyle} />
          </Link>
        ) : (
          <p />
        )}
      </AppRowCenterSpaceBetween>
      {/* {values.rejectMessage && (
        <div dangerouslySetInnerHTML={{ __html: values.rejectMessage }} />
      )} */}
    </Header>
  );
};

export default InvoiceFormHeader;
