import * as yup from 'yup';
import { PHONE_NUMBER_VALIDATE_SCHEMA, REGEX } from 'constants/form';

/* eslint import/prefer-default-export: off */
export const validationSchema = ({ t }) =>
  yup.object().shape({
    name: yup.string().required(t('forms.mandatory_field')),
    address: yup.string().required(t('forms.mandatory_field')),
    zipcode: yup
      .string()
      .matches(/^[\w-]+$/, t('signup.company.zipcode_false'), {
        excludeEmptyString: true,
      })
      .max(15, t('signup.company.zipcode_max'))
      .required(t('forms.mandatory_field')),
    city: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.adress_mandatory'), {
        excludeEmptyString: true,
      })
      .required(t('forms.mandatory_field')),
    country: yup.string(), // .required(t('forms.mandatory_field')),
    email: yup
      .string()
      .notRequired()
      .email(t('signup.company.email_valid_mandatory')),
    intracomvat: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.intracomvat_empty'), {
        excludeEmptyString: true,
      }),
    iban: yup
      .string()
      .max(34, t('settings_section.clients.iban_validation'))
      .min(23, t('forms.iban_minimum'))
      .matches(REGEX.IBAN, t('forms.iban_invalid')),
    phone: yup
      .string()
      .min(10, t('signup.company.valid_phone_number'))
      .matches(
        PHONE_NUMBER_VALIDATE_SCHEMA,
        t('signup.company.valid_phone_number')
      ),
    bic: yup.string().max(11, t('settings_section.clients.bic_validation')),
    siret: yup
      .string()
      .matches(/[^\s\\]/, t('signup.company.siren_empty'), {
        excludeEmptyString: true,
      })
      .min(14, t('settings_section.clients.siret_validation'))
      .max(14, t('settings_section.clients.siret_validation'))
      .when('notRequiredSiret', {
        is: false,
        then: yup.string().required(t('forms.mandatory_field')),
        otherwise: yup.string().notRequired(),
      }),
    subCategory: yup.string().required(t('forms.mandatory_field')),
    category: yup.string().required(t('forms.mandatory_field')),
    activity: yup.string().notRequired(t('forms.mandatory_field')),
    // .required(t('forms.mandatory_field')),
  });
