/* eslint-disable-next-line */
export default ({ id, providers, selectedProvider, isImport, tree }) => {
  if (id && providers) {
    const { _id, ...provider } = providers[id];
    const mergeObjects = {
      ...provider,
      ...provider.address,
      ...provider.contact,
    };
    delete mergeObjects.contact;
    return mergeObjects;
  }
  if (isImport && selectedProvider) {
    return selectedProvider;
  }

  return {
    name: '',
    address: '',
    zipcode: '',
    city: '',
    country: 'FR',
    intracomvat: '',
    siret: '',
    iban: '',
    bic: '',
    isCompany: true,
    subCategory: '',
    category: '',
    activity: '',
    title: '',
  };
};
