import React from 'react';
import { useTranslation } from 'react-i18next';

import Header from 'components/Header';

const DepositHeader = () => {
  const { t } = useTranslation();
  return <Header name={t('deposit.title')} />;
};

export default DepositHeader;
