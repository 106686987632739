export default (values, { setErrors, setStatus, setSubmitting, props }) => {
  const cb = (err) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      setStatus({ success: true });

      if (props.closeDialog) {
        props.closeDialog();
      } else {
        props.redirectToHome();
      }
    }
  };

  if (!values._id) {
    props.createUser(values, cb);
  } else {
    props.updateUser(values, cb);

    if (props.loggedUser.user._id === values._id && values.language) {
      props.changeLanguage(values.language);
    }
  }
};
