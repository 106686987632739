import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'hooks/routerHooks';
import Header from 'components/Header';
import Layout from 'components/Layout';
import { getInvalidDocument } from 'helpers/TreezorHelper';
import CircularProgressCentered from 'components/CircularProgressCentered';
import { putTreezorUser, sendTreezorNotif } from 'actions/UserTreezorActions';
import TreezorDocsNeedFormik from 'components/CardComponents/Treezor/TreezorDocsNeed/treezorDocsNeedFormik';
import initialValues from './initialValues';
import { prepareData } from './handleSubmit';
import validationSchema from './validation';

const DocNeedFormik = () => {
  const dispatch = useDispatch();
  const { history } = useRouter();
  const [document, setDocument] = useState(null);
  const [treezorUser, setTreezorUser] = useState(null);
  const { treezorUsers } = useSelector((state) => state.treezor);
  const { company } = useSelector((reduxState) => reduxState.loggedUserCompany);
  const { t } = useTranslation();

  const onSubmit = (values, actions) => {
    const formatedData = prepareData(values, treezorUsers);
    dispatch(putTreezorUser(formatedData));
    setDocument(null);
    actions.setSubmitting(false);
  };

  const sendNotif = () => {
    dispatch(sendTreezorNotif());
    history.push('treezorPending');
  };

  useEffect(() => {
    const userWithInvalidDoc = getInvalidDocument(treezorUsers);
    if (userWithInvalidDoc.length === 0) {
      sendNotif();
    }
    if (userWithInvalidDoc.length > 0) {
      setDocument(userWithInvalidDoc[0]);
      setTreezorUser(userWithInvalidDoc[0]);
    }
  }, [treezorUsers]);

  const renderBody = () => {
    return (
      <>
        {document ? (
          <TreezorDocsNeedFormik
            document={document}
            treezorUser={treezorUser}
            company={company}
            onSubmit={onSubmit}
            initialValues={initialValues}
            validationSchema={validationSchema}
          />
        ) : (
          <CircularProgressCentered />
        )}
      </>
    );
  };

  return (
    <Layout
      header={
        <Header
          name={t('Documents Invalides')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

export default DocNeedFormik;
