import {
  GET_AGING_BALANCE,
  GET_AGING_BALANCE_SUCCESS,
  GET_AGING_BALANCE_FAIL,
  GET_AGING_BALANCE_OVERDUE,
  GET_AGING_BALANCE_OVERDUE_SUCCESS,
  GET_AGING_BALANCE_OVERDUE_FAIL,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
} from 'actions/Types';

const initialState = {
  agingBalanceComing: {
    loading: false,
    data: null,
    error: null,
  },
  agingBalanceOverdue: {
    loading: false,
    data: null,
    error: null,
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AGING_BALANCE:
      return {
        ...state,
        agingBalanceComing: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case GET_AGING_BALANCE_FAIL:
      return {
        ...state,
        agingBalanceComing: {
          loading: false,
          data: null,
          error: action.payload,
        },
      };
    case GET_AGING_BALANCE_SUCCESS:
      return {
        ...state,
        agingBalanceComing: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case GET_AGING_BALANCE_OVERDUE:
      return {
        ...state,
        agingBalanceOverdue: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case GET_AGING_BALANCE_OVERDUE_FAIL:
      return {
        ...state,
        agingBalanceOverdue: {
          loading: false,
          data: null,
          error: action.payload,
        },
      };
    case GET_AGING_BALANCE_OVERDUE_SUCCESS:
      return {
        ...state,
        agingBalanceOverdue: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };

    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return initialState;

    default:
      return state;
  }
};
