/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Container, Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
// import { style } from 'assets/jss/style';
// import logoSideBarS from 'assets/images/BBF-logo-alone-white.svg';
import { SnackbarProvider, useSnackbar } from 'notistack';
import logoSideBar from 'assets/images/BBF-header-logo-white.svg';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import queryString from 'query-string';
import LoadingDialog from 'components/LoadingDialog';

import HipayAction from 'actions/HipayActions';

// const { mainSideBarClientPdf, centerContainer } = style;

const useStyles = makeStyles({
  cardContainer: {
    display: 'flex',
    width: '100%',
    height: '100vh',
    justifyContent: 'center',
    alignContent: 'center',
  },
  root: {
    maxWidth: '350px',
    width: '350px',
  },
  imgContainer: {
    padding: '5%',
    backgroundColor: '#3D319D',
    borderTopRightRadius: '2%',
    borderTopLeftRadius: '2%',
  },
  media: {
    height: 140,
    backgroundColor: '#3D319D',
  },
});

const GlobalErrors = () => {
  const { enqueueSnackbar } = useSnackbar();
  const errors = useSelector((state) => state.globalErrors);

  useEffect(() => {
    if (errors.length) {
      enqueueSnackbar(errors.pop(), { variant: 'error' });
    }
  }, [errors]);

  return null;
};

const HipayPage = ({ match, ...props }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [amount, setAmout] = useState(null);
  const { status } = match.params;
  const { t } = useTranslation();

  const hipay = useSelector((state) => state.hipay);
  useEffect(() => {
    (async () => {
      const qsObj = queryString.parse(props.location.search);
      if (qsObj && qsObj.amount) {
        setAmout(qsObj.amount);

        const hipayData = { ...qsObj, orderId: qsObj.orderid };
        delete hipayData.orderid;

        await dispatch(HipayAction.storeHipay(hipayData));
      }
    })();
  }, []);

  const goToBBF = () => {
    window.location.href = 'https://bebusinessfocus.com/';
  };

  const renderMessage = (pStatus) => {
    switch (pStatus) {
      case 'accept':
        return (
          <Typography variant="h4" gutterBottom color="primary">
            {t('paymentAccepted')}
          </Typography>
        );
      case 'decline':
        return (
          <Typography variant="h4" gutterBottom color="error">
            {t('paymentDeclined')}
          </Typography>
        );
      case 'pending':
        return (
          <Typography variant="h4" gutterBottom color="primary">
            {t('paymentPending')}
          </Typography>
        );
      case 'cancel':
        return (
          <Typography variant="h4" gutterBottom color="error">
            {t('paymentCanceled')}
          </Typography>
        );
      default:
        return (
          <Typography variant="h4" gutterBottom color="error">
            {t('paymentException')}
          </Typography>
        );
    }
  };

  return (
    <Container maxWidth="md">
      <Grid container spacing={2} className={classes.cardContainer}>
        <Card className={classes.root}>
          <CardActionArea onClick={goToBBF}>
            <div className={classes.imgContainer}>
              <CardMedia
                className={classes.media}
                image={logoSideBar}
                title="Contemplative Reptile"
              />
            </div>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h5">
                {renderMessage(status)}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                {amount && (
                  <>
                    {t('amount')}: <b>{amount} €</b>
                  </>
                )}
                <br />
                <br />
                Si vous êtes un professionnel, venez tester nos solutions
                digitales Be Business Focus
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Button size="small" color="primary" onClick={goToBBF}>
              En savoir plus
            </Button>
          </CardActions>
        </Card>
        {hipay.loading && <LoadingDialog />}
        <SnackbarProvider maxSnack={5}>
          <GlobalErrors />
        </SnackbarProvider>

        {/* Left Sidebar, on large screens only - horizontal (see below) on mobile
        <Hidden xsDown>
          <Grid item sm={1} md={2}>
            <div style={mainSideBarClientPdf}>
              <div style={centerContainer}>
                <picture>
                  <source srcSet={logoSideBar} media="(min-width: 1024px)" />
                  <img
                    src={logoSideBarS}
                    alt="logo"
                    style={{ width: '100%' }}
                  />
                </picture>
              </div>
            </div>
          </Grid>
        </Hidden>
        <Grid item xs={12} sm={11} md={10} style={{ marginTop: '2%' }}>
          {renderMessage(status)}
          Si vous etes un professional, venez tester nos solutions digitales Be
          Business Focus
        </Grid> */}
      </Grid>
    </Container>
  );
};

HipayPage.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      value: PropTypes.number,
    }),
  }),
};

export default HipayPage;
