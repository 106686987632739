import {
  getCategoryById,
  getCorseVatRateByCategory,
  getVatRates,
} from './CategoryHelpers';
import { store } from '../store';
import {
  isCorseProvider,
  isEuProvider,
  isNonEuProvider,
} from './providerHelpers';

const DEFAULT_VATRATE = 20;

const getVATRates = () => {
  const { vatRates } = store.getState();
  if (vatRates) {
    return vatRates;
  }
  return [];
};

const isCategoryWithoutVAT = (id) => {
  const category = getCategoryById(id);

  if (category && category.vat && category.vat.isWithoutVAT) {
    return true;
  }
  return false;
};

const noVAT = (value) => {
  if (typeof value === 'undefined' || Number(value) === 0) {
    return true;
  }
  return false;
};

const getVATValuesByCatId = (id) => {
  const category = getCategoryById(id);
  const vatValues = [];
  if (category && category.vat && category.vat.vatRates) {
    const rates = getVATRates();
    for (let i = 0; i < category.vat.vatRates.length; i += 1) {
      const rate = rates.find((e) => e._id === category.vat.vatRates[i].id);
      if (rate) {
        vatValues.push(rate.value);
      }
    }
  }
  return vatValues;
};

const checkForVatRates = (objects) => {
  let flag = true;

  for (let i = 0; i < objects.length; i += 1) {
    if (parseInt(objects[i].item.vat_rate, 10) === 20) {
      flag = false;
    }
  }
  return flag;
};

const sortAscending = (a, b) => {
  // if (parseInt(a.vat) < parseInt(b.vat)) return -1;
  // if (parseInt(a.vat) > parseInt(b.vat)) return 1;
  if (parseInt(a.vat, 10) < parseInt(b.vat, 10)) return -1;
  if (parseInt(a.vat, 10) > parseInt(b.vat, 10)) return 1;
  return 0;
};

const getVatRatesByCategory = (
  currentCategory,
  fullVatRates,
  provider = null
) => {
  if (provider && isNonEuProvider(provider)) {
    return [fullVatRates[0]];
  }

  const vatIds = currentCategory?.vat.vatRates.map((item) => item.id) || [];
  if (!currentCategory) {
    return [];
  }

  const filterVAT = fullVatRates.filter((item) => vatIds.includes(item._id));

  if (isCorseProvider(provider) && currentCategory?.vat?.corseVatRates) {
    return getCorseVatRateByCategory(filterVAT, currentCategory);
  }
  return filterVAT;
};

const isExistInVatrates = (
  amount,
  categories,
  fullVatRates,
  provider = null
) => {
  return (
    getVatRates(
      amount.category_id,
      amount.sub_category_id,
      categories,
      fullVatRates,
      provider
    )
      .map((vat) => vat.value)
      .includes(amount.vat_rate) || amount.vat_rate === 0
  );
};

const formatVat = ({ vat, provider }) => {
  if (provider && isEuProvider(provider)) {
    return 0;
  }
  return vat;
};

export {
  DEFAULT_VATRATE,
  isCategoryWithoutVAT,
  noVAT,
  getVATValuesByCatId,
  sortAscending,
  checkForVatRates,
  getVatRatesByCategory,
  isExistInVatrates,
  formatVat,
};
