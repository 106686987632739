import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';
import style from '../../assets/jss/components/appSideButton';
import '../../assets/css/icons.css';

const MyTooltip = withStyles(() => ({
  tooltip: {
    fontSize: 12,
  },
}))(Tooltip);

const CompanyButton = ({
  classes,
  name,
  multiple = false,
  toggleDialog = () => {},
}) => {
  const wrapperClasses = classNames({
    [classes.companyButton]: true,
    [classes.cursorPointer]: multiple,
    [classes.cursorDefault]: !multiple,
  });

  const iconClasses = classNames({
    [classes.icon]: true,
    [`icon icon-company`]: true,
  });

  const textClasses = classNames({
    [classes.buttonText]: true,
    [classes.companyButtonText]: true,
  });

  if (name && name.length > 25)
    return (
      <MyTooltip title={name} placement="top">
        <div className={wrapperClasses} onClick={toggleDialog}>
          <div className={iconClasses} />
          <div className={textClasses}>{`${name.slice(0, 24)}...`}</div>
        </div>
      </MyTooltip>
    );
  return (
    <div className={wrapperClasses} onClick={toggleDialog}>
      <div className={iconClasses} />
      <div className={textClasses}>{name}</div>
    </div>
  );
};

CompanyButton.propTypes = {
  name: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  multiple: PropTypes.bool,
  toggleDialog: PropTypes.func,
};

export default withStyles(style)(CompanyButton);
