/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-expressions */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';
import S3FileService from 'services/S3FileService';
import { format } from 'date-fns';
import styles from '../../../../../../assets/jss/root';

import AppButton from '../../../../../../components/AppButton';

const RecoveryButton = ({ separatorStyle, horizontal, classes, isOverdue }) => {
  const { t } = useTranslation();
  const { values, isSubmitting, setFieldValue } = useFormikContext();

  const loggedUserCompany = useSelector(
    (state) => state.loggedUserCompany.company
  );
  const loggedUser = useSelector((state) => state.loggedUser.user);

  if (isOverdue) {
    return (
      <Fragment>
        <AppButton
          fullWidth={!horizontal}
          isDisabled={isSubmitting}
          text={t('invoices.ruby_payeur')}
          onClick={async () => {
            // let url = null;

            // if (values.filename) {
            //   const resultUrl = await S3FileService.geturl(values.filename);
            //   url = resultUrl.url;
            // }

            const formatDate = (date) => {
              return format(new Date(date), 'dd/MM/yyyy');
            };

            let text = `https://rubypayeur.com/mon-compte/creances/dossier-recouvrement-amiable?`;

            text += `siren=${loggedUserCompany.siren || ''}`; // 1 siren
            text += `&debt[email]=${loggedUser.email || ''}`; // 2 email
            text += `&debt[gender]=${
              loggedUser.title === 'M.' ? 'male' : 'female' || ''
            }`; // 3 genre
            text += `&debt[first_name]=${loggedUser.firstname || ''}`; // 4 firstname
            text += `&debt[last_name]=${loggedUser.name || ''}`; // 5 name
            text += `&debt[phone]=${loggedUser.phonenumber || ''}`; // 6 phone Number
            text += `&debt[items_attributes][0][amount]=${
              values.gross_total || ''
            }`; // 7
            text += `&debt[items_attributes][0][invoice_number]=${
              values.invoice_nb || ''
            }`; // 8
            text += `&debt[items_attributes][0][invoiced_on]=${
              values.date ? formatDate(values.date) : ''
            }`; // 9
            text += `&debt[items_attributes][0][due_date]=${
              values.payments[0].date ? formatDate(values.payments[0].date) : ''
            }`; // 10
            // text += `debt[items_attributes][0][billing_proof_remote_url]=${
            //   url || ''
            // }`; // 11
            window.open(text, '_blank');
          }}
        />
        <div className={separatorStyle} />
      </Fragment>
    );
  }
  return null;
};

RecoveryButton.propTypes = {
  separatorStyle: PropTypes.string,
  invoiceId: PropTypes.string,
  horizontal: PropTypes.bool,
  classes: PropTypes.object,
  isOverdue: PropTypes.bool,
};

RecoveryButton.defaultProps = {
  horizontal: false,
};

export default withStyles(styles)(RecoveryButton);
