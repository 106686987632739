import { STORE_SCAN_OCR_VALUE } from '../../actions/Types';

const initialState = {
  siret: '',
  isAutoCreateProvider: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_SCAN_OCR_VALUE: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};
