import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SimpleUpload = ({ onChange, text }) => {
  const { t } = useTranslation();

  return (
    <form>
      {text}
      <input
        type="file"
        onChange={(e) => {
          if (e.target.files[0]) onChange(e.target.files);
        }}
        multiple={true}
      />
      <button
        type="reset"
        onClick={() => {
          onChange(null);
        }}
      >
        {t('cancel')}
      </button>
    </form>
  );
};

SimpleUpload.propTypes = {
  onChange: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default SimpleUpload;
