import React from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { Typography } from '@material-ui/core';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import AppButton from '../../components/AppButton';
import dispatch from './dispatch';

import logo from '../../assets/images/BBF-header-logo-blueberry.svg';
import octopus from '../../assets/images/octopus.svg';
import styles from '../../assets/jss/home/home';

const RegisterPage = (props) => {
  const { classes, t, history, isAuthenticated } = props;

  function handleRegisterClick() {
    history.push('/onboarding');
  }

  const renderRegisterPage = () => {
    return (
      <div className={classes.registerContainer}>
        <div className={classes.registerContentContainer}>
          <img
            style={{ marginTop: '30px', marginBottom: '30px' }}
            src={logo}
            alt="Be Business Focus"
            width="300px"
          />
          <Typography variant="h3" style={{ margin: 20 }}>
            {t('registerPage.welcomeTitle')}
          </Typography>
          <Typography variant="h4">
            {t('registerPage.welcomeSubTitle')}
          </Typography>

          <div>&nbsp;</div>
          <AppButton
            text={t('register')}
            onClick={handleRegisterClick}
            style={{ width: '25%' }}
          />
          <div>&nbsp;</div>
          <img
            style={{ marginTop: '30px', marginBottom: '30px' }}
            src={octopus}
            alt="mascotte"
            width="300px"
          />
        </div>
      </div>
    );
  };
  if (!isAuthenticated()) {
    return renderRegisterPage();
  }

  return <Redirect to="/home" />;
};

RegisterPage.propTypes = {
  classes: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  isAuthenticated: PropTypes.func.isRequired,
};

const RegisterPageWithStyles = withStyles(styles)(RegisterPage);
const RegisterPageTranslated = withTranslation()(RegisterPageWithStyles);
const RegisterPagewithRouter = withRouter(RegisterPageTranslated);
const Register = connect(null, dispatch)(RegisterPagewithRouter);
export default Register;
