import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { TextField } from 'formik-material-ui';
import { MenuItem, CircularProgress } from '@material-ui/core';
import { Field, useFormikContext } from 'formik';
import { DispatchContext } from 'contexts/TreezorContext/TreezorFormContext';
import { uploadUserFileInS3 } from 'contexts/TreezorContext/TreezorActions';
import UploadFile from 'components/NewUploadFile/uploadFile';
import { useTranslation } from 'react-i18next';
import { SCAN_FILE_TYPE } from 'constants/account';

const TreezorDropZone = ({
  name,
  title,
  optionList,
  company,
  setFieldValue,
  indentityFile,
  resetUploadName,
  setDisableSendBtn,
}) => {
  const dispatchContext = useContext(DispatchContext);
  const { t } = useTranslation();
  const [showSpinner, setShowSpinner] = useState(false);
  const [showDropzone, toggleShowDropzone] = useState(false);
  const [showError, setShowError] = useState(false);
  const { handleChange } = useFormikContext();

  const uploadFile = async (file) => {
    setShowSpinner(true);
    setDisableSendBtn(true);
    try {
      await uploadUserFileInS3(
        dispatchContext,
        file,
        indentityFile,
        company,
        setFieldValue
      );
    } catch (e) {
      setShowError(true);
    }
    setShowSpinner(false);
    setDisableSendBtn(false);
  };
  return (
    <>
      {showSpinner && <CircularProgress color="secondary" size={50} />}
      {!showSpinner && (
        <>
          <Field
            fullWidth
            component={TextField}
            select
            margin="dense"
            variant="outlined"
            name={name}
            label={title}
            style={{ marginBottom: '10%' }}
            onChange={(e) => {
              handleChange(e);
              toggleShowDropzone(true);
            }}
          >
            {optionList &&
              optionList.map((option, idx) => {
                return (
                  <MenuItem key={idx} value={option.codeBBF}>
                    {t(option.label)}
                  </MenuItem>
                );
              })}
          </Field>
          {showDropzone && (
            <UploadFile
              accept={SCAN_FILE_TYPE}
              onFileSelected={uploadFile}
              name={name}
              reset={resetUploadName}
            />
          )}
          {showError && (
            <span style={{ color: 'red' }}>{t('uploadError')}</span>
          )}
        </>
      )}
    </>
  );
};

TreezorDropZone.propTypes = {
  name: PropTypes.string,
  indentityFile: PropTypes.string,
  title: PropTypes.string,
  optionList: PropTypes.array,
  company: PropTypes.object,
  setFieldValue: PropTypes.func,
  setDisableSendBtn: PropTypes.func,
  resetUploadName: PropTypes.bool,
};

export default TreezorDropZone;
