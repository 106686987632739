import { Grid } from '@material-ui/core';
import AppButton from 'components/AppButton';
import AppCheckbox from 'components/AppCheckbox';
import AppContractorInfoSettings from 'components/AppCompany/AppContractorInfoSettings';
import TextField from 'components/FormElements/textField';
import SelectAutocomplete from 'components/SelectAutocomplete';
import { Form, withFormik } from 'formik';
import { TYPE_SOCIETE } from 'constants/company';
import capitalizeForm from 'helpers/capitalizeForm';
import { find, isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import UseLocalStorage from 'hooks/useLocalStorage';
import { LOCAL_STORAGE_SUBSCRIPTION } from 'config/Constants';
import mapPropsToValues from './contractorFormState';
import validationSchema from './contractorValidation';
import dispatch from './dispatch';
import state from './state';
import storagePersonalInfo from './storagePersonalInfor';

const ContractorInfo = ({
  require,
  values,
  setFieldValue,
  isAccountInfo,
  companyType,
  setPersonalInfo,
  errors,
  isRaisonSociale,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const [confirmInfo, setConfirmInfo] = React.useState(false);
  const [disabledSubmit, setDisabledSubmit] = useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const queryParams = new URLSearchParams(location.search);

  const { getData: getSubscription } = UseLocalStorage(
    LOCAL_STORAGE_SUBSCRIPTION
  );

  useEffect(() => {
    if (companyType) {
      setFieldValue('companyType', companyType);
    }
  }, [companyType]);
  const checkValidation = () => {
    const validationField = [
      'userName',
      'firstname',
      'title',
      'email',
      'phonenumber',
    ];
    const { subscriptionId } = getSubscription();

    if (!subscriptionId) {
      setErrorMessage(t('onboarding.validate_subscriptionId'));
      return false;
    }
    setErrorMessage('');

    if (isRaisonSociale) {
      validationField.push('address', 'siren', 'city', 'zipcode', 'legal_form');
    }
    return validationField.every(
      (item) => !isEmpty(values[item]) && isEmpty(errors[item])
    );
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (checkValidation()) {
      const { subscriptionId } = getSubscription();
      const companyInfo = {
        ...values,
        companyType,
        name:
          companyType !== TYPE_SOCIETE
            ? values?.business_name || values?.userName
            : values?.userName,
      };
      if (require) {
        setPersonalInfo(companyInfo);
      } else {
        history.push(`/signup?subscription_id=${subscriptionId}`);
      }
      storagePersonalInfo(companyInfo);
    }
  };

  const displayTitleInCompanyMode = () => {
    const chooseTitles = [
      { label: 'M.', value: 'M.' },
      { label: 'Mme.', value: 'Mme.' },
    ];

    return (
      <Grid item xs={12} sm={12} md={2}>
        <SelectAutocomplete
          required
          name="title"
          label={t('client.title')}
          onChange={(title) => {
            setFieldValue('title', title ? title.value : '');
          }}
          getOptionLabel={(option) => option.label}
          values={chooseTitles}
          valueSelected={find(
            chooseTitles,
            (elem) => elem.value === values.title
          )}
        />
      </Grid>
    );
  };

  const displayCompany = () => {
    return (
      <AppContractorInfoSettings
        t={t}
        values={values}
        setFieldValue={setFieldValue}
        onCodeSelected={(code) => setFieldValue('code_ape', code?.Code)}
        onSirenChange={(e) => setFieldValue('siren', e.target.value)}
        isSettings={true}
        isOnboarding
        companyType={companyType}
      />
    );
  };
  useEffect(() => {
    const disableSubmitButton = !(confirmInfo && checkValidation());
    setDisabledSubmit(disableSubmitButton);
  }, [confirmInfo, values, errors]);
  return (
    <Form onSubmit={handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} container justify="center">
          <h2
            style={{
              color: 'rgb(61, 49, 157)',
              fontSize: '27px',
              fontFamily: 'Kumbh Sans',
              lineHeight: '37px',
              letterSpacing: '0.5px',
            }}
          >
            {t(
              `onboarding.${
                isAccountInfo
                  ? 'account_personal_info'
                  : 'business_personal_info'
              }`
            )}
          </h2>
        </Grid>
        {errorMessage && (
          <Grid item xs={12}>
            <div className="row">
              <div className="input-field col s12">
                <span className="helper-text" style={{ color: 'red' }}>
                  {errorMessage}
                </span>
              </div>
            </div>
          </Grid>
        )}
        <Grid container spacing={2} item style={{ display: 'flex' }}>
          {displayTitleInCompanyMode()}
          {/* Row with Firstname and lastname */}
          <Grid item xs={12} sm={12} md={5}>
            <TextField
              type="text"
              value={values.userName}
              name="userName"
              label={t('signup.name')}
              placeholder={t('signup.name')}
              color="secondary"
              onChange={(e) => {
                capitalizeForm(e, setFieldValue);
              }}
              required
            />
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <TextField
              type="text"
              value={values.firstname}
              name="firstname"
              label={t('signup.first_name')}
              placeholder={t('signup.first_name')}
              color="secondary"
              onChange={(e) => {
                capitalizeForm(e, setFieldValue);
              }}
              required
            />
          </Grid>
        </Grid>
        {(isRaisonSociale || queryParams.get('adf') === '1') && (
          <Grid item xs={12}>
            {displayCompany()}
          </Grid>
        )}

        {/* Row with Email */}
        <Grid item xs={5} sm={6}>
          <TextField
            type="text"
            name="email"
            label={t('signup.company.email')}
            placeholder={t('signup.company.email')}
            color="secondary"
            required
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={6} sm={6}>
          <TextField
            setWidthManualy="32%"
            label={t('settings_section.clients.form_label_phone')}
            placeholder={t('settings_section.clients.form_label_phone')}
            color="secondary"
            name="phonenumber"
            type="text"
            required
            onChange={(e) => {
              setFieldValue(e.target.name, e.target.value);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <AppCheckbox
            onClick={() => {
              setConfirmInfo(!confirmInfo);
            }}
            checked={confirmInfo}
            labelText={t('onboarding.confirm_info')}
          />
        </Grid>
        <Grid item xs={12} container justify="flex-end">
          <AppButton
            disabled={disabledSubmit}
            color="secondaryLight"
            text={t('next')}
            type="submit"
          />
        </Grid>
      </Grid>
    </Form>
  );
};

ContractorInfo.propTypes = {
  setPersonalInfo: PropTypes.func,
  values: PropTypes.object.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  isAccountInfo: PropTypes.bool,
  require: PropTypes.bool,
  isRaisonSociale: PropTypes.bool,
  companyType: PropTypes.string,
  errors: PropTypes.object,
};

const UseFormWithFormik = withFormik({
  displayName: 'ContractorInfo',
  enableReinitialize: true,
  validationSchema,
  mapPropsToValues,
  handleSubmit: () => {},
})(ContractorInfo);
const TranslatedUserFormWithStyles = withTranslation()(UseFormWithFormik);
export default connect(state, dispatch)(TranslatedUserFormWithStyles);
