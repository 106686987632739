import React from 'react';
import PropTypes from 'prop-types';
import Draggable from 'react-draggable';

import SmallBox from '../SmallBox';
import EditBox from '../EditBox';

class Box extends React.Component {
  constructor(props) {
    super(props);
    this.refEdit = React.createRef();
    this.boxRef = React.createRef();
    this.state = {
      overlay: 'none',
      smallBox: 'none',
      editBox: 'none',
      zIndex: 0,
    };
    this.handleMouseEnter = this.handleMouseEnter.bind(this);
    this.handleMouseLeave = this.handleMouseLeave.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleMouseUp = this.handleMouseUp.bind(this);
    this.handleStop = this.handleStop.bind(this);
  }

  handleMouseEnter() {
    this.setState({ zIndex: this.state.zIndex + 1 });
    this.setState({ smallBox: '' });
  }

  handleMouseLeave() {
    this.setState({ zIndex: this.state.zIndex - 1 });
    this.setState({ smallBox: 'none' });
  }

  handleEdit() {
    this.props.overlay(this);
    const editpos = this.boxRef.current.offsetWidth + 10;
    this.setState({
      overlay: '',
      editBox: '',
      editBoxPos: `${editpos}px`,
      zIndex: 3,
    });
  }

  handleClickOutside() {
    this.setState({ overlay: 'none', editBox: 'none', zIndex: 0 });
  }

  handleMouseUp() {
    const editpos = this.boxRef.current.offsetWidth + 10;
    this.setState({ editBoxPos: editpos });
  }

  handleStop(e, data) {
    this.props.onStop(this.props.name, e, data);
  }

  renderBox() {
    const defaultStyle = {
      fontWeight: this.props.info.bold,
      fontStyle: this.props.info.italic,
      textDecoration: this.props.info.underline,
      zIndex: this.state.zIndex,
      fontSize: `${this.props.info.fs}px`,
      fontFamily: this.props.info.ff,
      visibility: this.props.info.visible,
    };
    const { style } = this.props;

    return (
      <Draggable
        disabled={!this.props.draggable}
        position={{ x: this.props.info.pos[0], y: this.props.info.pos[1] }}
        onStop={this.handleStop}
        bounds="parent"
        cancel="input"
      >
        <div
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          className={`box ${!this.props.draggable ? 'not-draggable' : ''}`}
          id={this.props.id}
          style={{ ...defaultStyle, ...style }}
          ref={this.boxRef}
        >
          <SmallBox
            handleEdit={this.handleEdit}
            refEdit={this.refEdit}
            content={this.props.content}
            display={this.state.smallBox}
          />
          <EditBox
            handleMouseUp={this.handleMouseUp}
            handleChange={this.props.onChange}
            fs={this.props.info.fs}
            ff={this.props.info.ff}
            visible={this.props.info.visible}
            info={this.props.info}
            name={this.props.name}
            reference={this.refEdit}
            display={this.state.editBox}
            pos={this.state.editBoxPos}
          />
          {this.props.children}
        </div>
      </Draggable>
    );
  }

  renderImageBox() {
    const defaultStyle = {
      zIndex: this.state.zIndex,
      visibility: this.props.info.visible,
    };
    const { style } = this.props;

    return (
      <Draggable
        disabled={!this.props.draggable}
        position={{ x: this.props.info.pos[0], y: this.props.info.pos[1] }}
        onStop={this.handleStop}
        bounds="parent"
        cancel="input"
      >
        <div
          style={{ ...defaultStyle, ...style }}
          onMouseEnter={this.handleMouseEnter}
          onMouseLeave={this.handleMouseLeave}
          className={`image-box ${
            !this.props.draggable ? 'not-draggable' : ''
          }`}
          id={this.props.id}
          ref={this.boxRef}
        >
          <SmallBox
            overlay={this.props.overlay}
            handleEdit={this.handleEdit}
            refEdit={this.refEdit}
            content={this.props.content}
            display={this.state.smallBox}
          />
          <EditBox
            isImage={true}
            handleMouseUp={this.handleMouseUp}
            handleChange={this.props.onChange}
            width={this.props.info.width}
            height={this.props.info.height}
            visible={this.props.info.visible}
            info={this.props.info}
            name={this.props.name}
            reference={this.refEdit}
            display={this.state.editBox}
            pos={this.state.editBoxPos}
          />
          <img
            width={`${this.props.info.width}px`}
            src={this.props.src}
            alt={this.props.alt}
            id={this.props.id}
            draggable={false}
          />
        </div>
      </Draggable>
    );
  }

  render() {
    if (this.props.isImage) {
      return this.renderImageBox();
    }

    return this.renderBox();
  }
}

Box.propTypes = {
  handleChange: PropTypes.func,
  overlay: PropTypes.func,
  onChange: PropTypes.func,
  onStop: PropTypes.func,
  handleMouseUp: PropTypes.func,
  name: PropTypes.string,
  info: PropTypes.object,
  style: PropTypes.object,
  isImage: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string,
  content: PropTypes.string,
  alt: PropTypes.string,
  src: PropTypes.string,
  draggable: PropTypes.bool,
};

Box.defaultProps = {
  isImage: false,
  draggable: true,
  style: {},
};

export default Box;
