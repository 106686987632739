import service from 'services/BankinApiService';
import { setGlobalError } from 'actions/GlobalError';

import {
  CREATING_BANK_LINK,
  CREATE_BANK_LINK_FAILED,
  CREATE_BANK_LINK_SUCCESS,
  FETCHING_BANK_LINK,
  FETCH_BANK_LINK_FAILED,
  FETCH_BANK_LINK_SUCCESS,
  FETCHING_BANK_LINK_FUNNEL_URL,
  FETCH_BANK_LINK_FUNNEL_URL_FAILED,
  FETCH_BANK_LINK_FUNNEL_URL_SUCCESS,
} from 'actions/Types';

/**
 *
 * getting connection url for bankin
 */

export const gettingConnectionUrl = () => ({
  type: FETCHING_BANK_LINK_FUNNEL_URL,
});

export const getConnectionUrlSuccess = (bankurl) => ({
  type: FETCH_BANK_LINK_FUNNEL_URL_SUCCESS,
  payload: { bankurl },
});

export const getConnectionUrlFailed = (error) => ({
  type: FETCH_BANK_LINK_FUNNEL_URL_FAILED,
  payload: { error },
});

/**
 * GET connection by company id
 */
export const gettingConnection = () => ({
  type: FETCHING_BANK_LINK,
});

export const getConnectionSuccess = (bankConnector) => ({
  type: FETCH_BANK_LINK_SUCCESS,
  payload: { bankConnector },
});

export const getConnectionFailed = (error) => ({
  type: FETCH_BANK_LINK_FAILED,
  payload: { error },
});

export const getBankConnection = (companyId) => async (dispatch) => {
  dispatch(gettingConnection());
  try {
    const result = await service.fetchBankConnection(companyId);
    dispatch(getConnectionSuccess(result.data));
    return true;
    // dispatch(getBankAccounts(companyId));
  } catch (error) {
    // dispatch(setGlobalError(error));
    dispatch(getConnectionFailed(error));
    return false;
  }
};

/**
 * Create connection by company id / user id / mail
 */
export const creatingBankConnector = () => ({
  type: CREATING_BANK_LINK,
});

export const createBankConnectorSuccess = (bankConnector) => ({
  type: CREATE_BANK_LINK_SUCCESS,
  payload: { bankConnector },
});

export const createBankConnectorFailure = (error) => ({
  type: CREATE_BANK_LINK_FAILED,
  payload: { error },
});

export const createBankConnector =
  (companyId, userId, email) => async (dispatch) => {
    dispatch(creatingBankConnector());
    try {
      const result = await service.createBankConnection(
        companyId,
        userId,
        email
      );
      dispatch(createBankConnectorSuccess(result.data));
    } catch (error) {
      dispatch(setGlobalError(error));
      dispatch(createBankConnectorFailure(error));
    }
  };

export const createBankConnectionUrl = (userId) => async (dispatch) => {
  dispatch(gettingConnection());
  try {
    const result = await service.createBankConnection(userId);
    dispatch(getConnectionSuccess(result.data));
    return true;
    // dispatch(getBankAccounts(companyId));
  } catch (error) {
    // dispatch(setGlobalError(error));
    dispatch(getConnectionFailed(error));
    return false;
  }
};
