import React from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { useRouter } from 'hooks/routerHooks';
import UserCard from './UserCard';

const CardsOwners = () => {
  const { history } = useRouter();
  const { users, treezor, loggedUser } = useSelector((state) => state);
  const { treezorUsers } = treezor;

  const getUsersList = () => {
    const usersList = [];
    /* eslint-disable no-unused-vars */
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(users)) {
      if (value._id !== loggedUser.user._id) usersList.push(value);
    }
    return usersList;
  };

  // const canCreateCard = (userId) => {
  //   const hasCard = treezorUsers.some(
  //     (treezorUser) => treezorUser.userTag === userId
  //   );
  //   return hasCard;
  // };

  const createCard = (user) => {
    const userSelected = treezorUsers.find(
      (tUser) => tUser.userTag === user._id
    );
    if (userSelected) {
      // SHOW CRÉATION CARD
      history.push(`/cards/select/${user._id}`);
    } else {
      // SHOW treezor creation accompte
      history.push(`/NewTreezorUser/${user._id}`);
    }
  };

  const hasCard = (userId) => {
    return true;
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {getUsersList().map((user, index) => (
          <UserCard
            key={index}
            user={user}
            hasCard={hasCard(user._id)}
            createCard={createCard}
          />
        ))}
      </Grid>
    </div>
  );
};

export default CardsOwners;
