/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FieldArray, useFormikContext } from 'formik';
import {
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
  IconButton,
  Divider,
} from '@material-ui/core';
import CurrencyTextField from 'components/FormElements/CurrencyTextField';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { formatVat, isExistInVatrates, noVAT } from 'helpers/VATRateHelpers';

import 'assets/css/deactivateUpDownArrowInputNumber.css';
import SelectAutocomplete from 'components/SelectAutocomplete';
import {
  getSubCategories,
  getTranslateSubCategory,
  getVatRates,
} from 'helpers/CategoryHelpers';
import { find } from 'lodash';
import MAX_PRICE_ITEM from 'constants/purchase';
import { roundingNumber } from 'utils';
import { useSelector } from 'react-redux';

export default function PurchaseVATline({
  readOnly,
  categories,
  isSimpleForm,
  vatRates,
}) {
  const { setFieldValue, values } = useFormikContext();
  const { t } = useTranslation();

  const providers = useSelector((state) => state.providers);
  const currentProvider = providers[values?.provider];

  const handleChangeCategory = (category, index) => {
    if (!category) return;
    setFieldValue(`amounts.${index}.category_id`, category?.category_id);
  };
  const handleChangeSubCategory = (subCategory, index) => {
    if (!subCategory) return;
    setFieldValue(`amounts.${index}.sub_category_id`, subCategory?._id);
  };

  const handleChangeVatRate = (event, index, amountsValue) => {
    const vatRate = event.target.value;
    setFieldValue(`amounts.${index}.vat_rate`, vatRate);
    const { amount } = amountsValue;
    if (vatRate !== 0) {
      const newVatValue = formatVat({
        vat: ((amount * vatRate) / 100).toFixed(2),
        provider: currentProvider,
      });
      setFieldValue(`amounts.${index}`, {
        ...amountsValue,
        vat_rate: vatRate,
        vat: newVatValue,
        total: (parseFloat(newVatValue) + parseFloat(amount)).toFixed(2),
      });
    } else {
      setFieldValue(`amounts.${index}`, {
        ...values.amounts[index],
        vat_rate: vatRate,
        vat: 0,
        total: parseFloat(amount).toFixed(2),
      });
    }
  };

  const handleChangeAmount = (e, index, amountsValue) => {
    const amountInput = e.target.value;
    const { vat_rate: vatRate } = amountsValue;
    const newVatValue = formatVat({
      vat: ((amountInput * vatRate) / 100).toFixed(2),
      provider: currentProvider,
    });
    setFieldValue(`amounts.${index}.amount`, amountInput);

    setFieldValue(`amounts.${index}.vat`, newVatValue);
    setFieldValue(
      `amounts.${index}.total`,
      (parseFloat(newVatValue) + parseFloat(amountInput)).toFixed(2)
    );
  };

  const handleChangeTotal = (e, index, amountsValue) => {
    const totalInput = +e.target.value;

    const { vat_rate: vatRate } = amountsValue;
    const newVatValue = formatVat({
      vat: (totalInput * (vatRate / (100 + vatRate))).toFixed(2),
      provider: currentProvider,
    });
    setFieldValue(`amounts.${index}.total`, totalInput);
    setFieldValue(
      `amounts.${index}.amount`,
      (totalInput - newVatValue).toFixed(2)
    );
    setFieldValue(`amounts.${index}.vat`, newVatValue);
  };

  const renderNoVatrateMessage = (value) => {
    return (
      <p style={{ marginTop: -8, color: 'red' }}>
        {t('expenses.expense.form_vatrate_no_exist.First')} {value}{' '}
        {t('expenses.expense.form_vatrate_no_exist.Second')}
      </p>
    );
  };

  return (
    <>
      <FieldArray
        name="amounts"
        render={(arrayHelpers) => (
          <>
            {values.amounts.map((amount, idx) => {
              let { amount: amountHT, vat, total, vat_rate: vatRate } = amount;
              const listVatRates = getVatRates(
                amount.category_id,
                amount.sub_category_id,
                categories,
                vatRates,
                providers[values.provider]
              );

              if (!listVatRates.map((item) => item.value).includes(vatRate)) {
                amountHT = null;
                vat = null;
                total = null;
                vatRate = null;
              }

              return (
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="center"
                  key={idx}
                  spacing={3}
                >
                  <Grid item xs={readOnly ? 12 : 10}>
                    <Grid container spacing={1} alignItems="baseline">
                      {!isSimpleForm && (
                        <>
                          <Grid item xs={6} sm={6}>
                            <SelectAutocomplete
                              required
                              name={`amounts.${idx}.category_id`}
                              label={t('providers.form.category')}
                              getOptionLabel={(elem) => {
                                return elem.category_display;
                              }}
                              values={categories.sort((a, b) =>
                                a.category_display.localeCompare(
                                  b.category_display
                                )
                              )}
                              onChange={(e) => handleChangeCategory(e, idx)}
                              valueSelected={find(
                                categories,
                                (category) =>
                                  category.category_id === amount.category_id
                              )}
                            />
                          </Grid>
                          <Grid item xs={6} sm={6}>
                            <SelectAutocomplete
                              required
                              name={`amounts.${idx}.sub_category_id`}
                              label={t('providers.form.subCategory')}
                              getOptionLabel={(e) =>
                                getTranslateSubCategory(e, t)
                              }
                              values={getSubCategories(
                                amount.category_id,
                                categories
                              )}
                              onChange={(e) => handleChangeSubCategory(e, idx)}
                              valueSelected={find(
                                getSubCategories(
                                  amount.category_id,
                                  categories
                                ),
                                (category) =>
                                  category._id === amount.sub_category_id
                              )}
                            />
                          </Grid>
                        </>
                      )}

                      <Grid item xs={6} sm={3}>
                        <CurrencyTextField
                          className="alignRight"
                          adornmentPosition="end"
                          adornmentText={'€'}
                          type="number"
                          step="0.01"
                          name={`amounts.${idx}.amount`}
                          label={t('expenses.expense.form_amount_0')}
                          placeholder={t('expenses.expense.form_amount_0')}
                          value={roundingNumber(amountHT)}
                          disabled={readOnly ? true : noVAT(vatRate)}
                          fullWidth
                          onChange={(e) => handleChangeAmount(e, idx, amount)}
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <FormControl
                          variant="outlined"
                          margin="dense"
                          fullWidth
                        >
                          <InputLabel htmlFor="outlined-vat_rate-native-simple">
                            {t('expenses.expense.form_vatrate_0')}
                          </InputLabel>
                          <Select
                            labelId={t('expenses.expense.form_vatrate_0')}
                            label={t('expenses.expense.form_vatrate_0')}
                            name={`amounts.${idx}.vat_rate`}
                            autoWidth={true}
                            disabled={readOnly ? true : !listVatRates.length}
                            onChange={(e) =>
                              handleChangeVatRate(e, idx, amount)
                            }
                            value={vatRate}
                          >
                            {listVatRates.map((vatObject, i) => (
                              <MenuItem key={i} value={vatObject?.value}>
                                {vatObject?.display}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <CurrencyTextField
                          className="alignRight"
                          adornmentPosition="end"
                          adornmentText={'€'}
                          type="number"
                          name={`amounts.${idx}.vat`}
                          label={t('expenses.expense.form_vat_0')}
                          placeholder={t('expenses.expense.form_vat_0')}
                          value={roundingNumber(vat)}
                          variant="outlined"
                          disabled
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6} sm={3}>
                        <CurrencyTextField
                          className="alignRight"
                          adornmentPosition="end"
                          adornmentText={'€'}
                          type="number"
                          step="0.01"
                          name={`amounts.${idx}.total`}
                          value={roundingNumber(total)}
                          label={t('expenses.expense.form_total_0')}
                          placeholder={t('expenses.expense.form_total_0')}
                          disabled={readOnly ? true : !listVatRates.length}
                          fullWidth
                          onChange={(e) => handleChangeTotal(e, idx, amount)}
                        />
                      </Grid>
                    </Grid>
                    {!isExistInVatrates(
                      amount,
                      categories,
                      vatRates,
                      providers[values.provider]
                    ) && renderNoVatrateMessage(vatRate)}
                  </Grid>
                  {!readOnly && (
                    <Grid
                      item
                      xs={2}
                      alignItems="center"
                      alignContent="flex-end"
                    >
                      {values.amounts?.length > 1 && (
                        <Tooltip
                          title={t('delete_this_vat_line')}
                          placement="top"
                        >
                          <IconButton
                            color="primary"
                            aria-label="delete VAT Line"
                            onClick={() => {
                              arrayHelpers.remove(idx);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                      {values.amounts?.length === idx + 1 &&
                        values.amounts?.length < MAX_PRICE_ITEM && (
                          <Tooltip title={t('add_vat_line')} placement="top">
                            <IconButton
                              color="primary"
                              aria-label="add VAT Line"
                              onClick={() => {
                                arrayHelpers.push({
                                  amount: 0,
                                  vat_rate: 0,
                                  total: 0,
                                  vat: 0,
                                  category_id: values?.category_id,
                                  sub_category_id: values.sub_category_id,
                                });
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </>
        )}
      ></FieldArray>
    </>
  );
}

PurchaseVATline.propTypes = {
  readOnly: PropTypes.bool,
  categories: PropTypes.array,
  vatRates: PropTypes.array,
  isSimpleForm: PropTypes.bool,
};
