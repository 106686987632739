import React from 'react';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { AppTextTitle } from 'components/AppText';
import { AppDividerXS } from 'components/AppDividers';
import AppButton from 'components/AppButton';
import { Link } from 'react-router-dom';
import BankCard from './BankCard';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',
    marginTop: 20,
  },
  gridList: {
    flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
  },
}));

const BankList = ({
  banks = [],
  setFieldValue,
  bankDetailsError = false,
  isSetting = false,
  companyDataAreMissing = false,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const arrBanks = [...Array(banks.length || 1).keys()];

  const handleAddBank = () => {
    const newBanks = [...banks];
    const init = {
      account: (Number(newBanks.pop().account) + 1).toString(),
      bank_name: '',
      bic: '',
      iban: '',
      journalCode: `BQ${banks.length + 1}`,
    };

    setFieldValue('bank_details', [...banks, init]);
  };

  const handleChooseDefaultBank = (chosenBank) => {
    // const current = banks.find((bank) => bank.account === chosenBank.account);
    // if (current.isDefault === true) return;
    const newBanks = banks.map((bank) => ({
      ...bank,
      isDefault: bank.account === chosenBank.account,
    }));

    setFieldValue('bank_details', newBanks);
  };

  return (
    <div className={classes.root}>
      <div style={{ width: '100%', textAlign: 'left' }}>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <AppTextTitle
            styles={{ margin: 'revert', width: '100%', textAlign: 'left' }}
          >
            {t('company.bank.details').toUpperCase()}
          </AppTextTitle>
          {!companyDataAreMissing && (
            <AppButton
              style={{ minWidth: 160 }}
              text={t('add_bank')}
              type="button"
              color="primaryLight"
              onClick={handleAddBank}
            />
          )}
        </div>
        <AppDividerXS />
        <Link to="/bank/accounts">Connecter votre compte bancaire</Link>
        {' ou renseigner ici le compte à utiliser sur vos factures.'}
      </div>
      <Grid container className={classes.root} justify="center" spacing={2}>
        {arrBanks.map((key) => (
          <Grid key={key} item lg={4} md={6} xs={12}>
            <BankCard
              required
              notFirstItem={!banks[key]?.accountId}
              bankDetailsError={bankDetailsError}
              // disabled={key > 0}
              bank={banks[key]}
              isSetting={isSetting}
              onChange={(value, field) => {
                setFieldValue(`bank_details[${key}].${field}`, value);
              }}
              handleChooseDefaultBank={handleChooseDefaultBank}
            />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

BankList.propTypes = {
  banks: PropTypes.array,
  setFieldValue: PropTypes.func.isRequired,
  bankDetailsError: PropTypes.bool,
  isSetting: PropTypes.bool,
  companyDataAreMissing: PropTypes.bool,
};

export default BankList;
