import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { map } from 'lodash';

import {
  EXPORT_FILE_TYPE,
  EXPORT_BOOK_TYPE,
  EXPORT_FILE_CONTENT_TYPE,
} from 'config/Constants';
import { exportFile } from 'helpers/utils';
import { getCategoryName } from 'helpers/providerHelpers';

import useImportProviders from '../hooks/useImportProviders';
import { IconEdit } from '../../../components/AppIcons';
import { AppRowStartSpaceAround } from '../../../components/AppContainers';
import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';
import useImportAction from '../hooks/useImportAction';
import { tableFrame } from '../../../helpers/mui-datatableHelper';

const ProviderListBody = ({ openProviderFormDialog }) => {
  const { t } = useTranslation();
  const { providers: providerList } = useImportProviders();
  const { getActivity, getSubCategory } = useImportAction(providerList);
  const categories = useSelector((state) => state.categories);
  const providersList = useSelector((state) => state.providers);

  const tableColumns = useMemo(() => {
    const columnsHeader = [
      { label: t('providers.form.company_name'), name: 'name' },
      { label: t('providers.form.form_label_activity'), name: 'activity' },
      { label: t('providers.form.category'), name: 'category' },
      { label: t('providers.form.subCategory'), name: 'subCategory' },
      { label: t('providers.form.form_label_contact'), name: 'nomDuContact' },
      { label: t('providers.form.form_label_phone'), name: 'phone' },
      { label: t('providers.form.form_label_email'), name: 'email' },
      { label: t('providers.form.siret'), name: 'siret' },
    ];

    const headerStyles = {
      textAlign: 'left',
      paddingLeft: '16px',
      borderBottom: '1px solid rgba(224, 224, 224, 1)',
      fontWeight: '500',
    };

    const columns = tableFrame(columnsHeader, headerStyles);

    columns.push({
      label: ' ',
      name: 'id',
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        search: false,
        // eslint-disable-next-line react/display-name
        customBodyRender: (value) => {
          return (
            <AppRowStartSpaceAround>
              <IconEdit
                sm
                onClick={() => {
                  openProviderFormDialog(value);
                }}
              />
            </AppRowStartSpaceAround>
          );
        },
      },
    });
    return columns;
  }, [openProviderFormDialog]);

  const providers = map(providersList).map((provider) => ({
    id: provider._id,
    name: provider.name,
    activity: getActivity(provider.activity),
    category: getCategoryName(provider, categories, t),
    subCategory: getSubCategory(provider),
    nomDuContact: provider.contact
      ? provider.contact.title + provider.contact.contactName
      : '',
    contactName: provider.contact?.contactName,
    phone: provider.contact?.phone,
    email: provider.contact?.email,
    siret: provider.siret,
  }));

  const exportData = () => {
    return map(providersList).map((provider) => ({
      SIRET: provider.siret,
      'Raison Sociale': provider.name,
      Adresse: provider.address.address,
      'Code Postal': provider.address.zipcode,
      Ville: provider.address.city,
      Pays: provider.address.country,
      Catégorie: provider.category,
      'Sous-catégorie': getSubCategory(provider),
      Activité: getActivity(provider.activity),
      'Assujetti à la TVA?':
        provider.isVAT || provider.intracomvat ? 'Oui' : 'Non',
      'TVA Intracommunautaire': provider.intracomvat,
      Civilité: provider.contact.title,
      'Nom du contact': provider.contact.contactName,
      Téléphone: provider.contact.phone,
      'E-Mail': provider.contact.email,
    }));
  };

  const options = {
    download: true,
    print: false,
    onDownload: () => {
      const json = exportData();
      exportFile(
        json,
        'providers',
        EXPORT_FILE_CONTENT_TYPE,
        EXPORT_FILE_TYPE,
        EXPORT_BOOK_TYPE
      );
      return false;
    },
  };
  return (
    <MUIDataTable
      data={providers}
      columns={tableColumns}
      options={getMuiDatatableOptions(t, providers.length, options)}
    />
  );
};

ProviderListBody.propTypes = {
  openProviderFormDialog: PropTypes.func,
};

export default ProviderListBody;
