/* eslint-disable react/display-name */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { map, orderBy } from 'lodash';
import { format } from 'date-fns';
import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Button,
} from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import queryString from 'query-string';

/* import custom hooks */
import { useRouter } from 'hooks/routerHooks';

/* import helpers */
import NumberFormat from 'helpers/NumberFormat';
import { getExpenseURL } from 'helpers/expenseHelpers';
import { formatBeforeDate } from 'helpers/BeforeDateHelper';

/* import state managers */
import { userCanUpdateExpense } from 'selectors/rightsSelector/expenseRequests';
import { fetchExpensePaginate } from 'actions/ExpensesActions';

import SUBSCRIPTION_CATEGORY_ID from 'constants/expense';

/* import components */
import { IconDelete, IconEdit } from 'components/AppIcons';
import MaterialTable from 'components/MaterialTable';
import HorizontalBeforAfterDate from 'components/HorizontalBeforeAfterDate';
import { AppRowStartSpaceAround } from 'components/AppContainers';
import ConfirmDialog from 'components/ConfirmDialog';
import spinnerService from 'services/SpinnerService';
import ExpenseService from 'services/ExpenseService';
import { exportToCSV } from 'helpers/fileHelper';
import { DEFAULT_PAGESIZE } from 'config/Constants';
import PurchaseState from '../../../components/states/PurchaseState';

const expenseStates = [
  'draft',
  'canceled',
  'paid',
  'payoutpending',
  'overdue',
  'upcoming',
];

const PurchaseListBody = ({ canReadExpense, refreshing }) => {
  const [pageSize, setPageSize] = useState(DEFAULT_PAGESIZE);

  const { t } = useTranslation();
  const { history, location } = useRouter();
  const dispatch = useDispatch();

  const tableRef = React.createRef();

  const providers = useSelector((state) => state.providers);
  const categories = useSelector((state) => state.categories);
  const activities = useSelector((state) => state.activities);
  const clients = map(useSelector((state) => state.clients.data)).filter(
    (client) => !client.deleted
  );
  const clientsProject = useSelector((state) => state.clientProjects.projects);

  const formatCategories = orderBy(
    categories.data,
    'description',
    'asc'
  ).filter((cat) => cat.parent);

  const canUpdateExpense = useSelector(userCanUpdateExpense);

  const filters = queryString.parse(location?.search, { arrayFormat: 'index' });

  const [before, setBefore] = useState(filters ? filters.before || '' : '');
  const [after, setAfter] = useState(filters ? filters.after || '' : '');
  const [expenseState, setExpenseState] = useState(
    filters ? filters.state || [] : []
  );
  const [orderByTotal, setOrderByTotal] = useState(
    filters ? !!filters.orderByTotal : false
  );

  const [expenseCategories, setExpenseCategories] = useState(
    filters ? filters.categories || [] : []
  );
  const [selectedProviders, setSelectedProviders] = useState(
    filters ? filters.provider || [] : []
  );
  const [selectedActivities, setSelectedActivities] = useState(
    filters ? filters.activity || [] : []
  );
  const [paymentBefore, setPaymentBefore] = useState(
    filters ? filters.paymentBefore : ''
  );
  const [paymentAfter, setPaymentAfter] = useState(
    filters ? filters.paymentAfter : ''
  );

  const [selectedClients, setSelectedClients] = useState(
    filters ? filters.client || [] : []
  );

  const [selectedProjects, setSelectedProjects] = useState(
    filters ? filters.project || [] : []
  );

  const [selectedPurchase, setSelectedPurchase] = useState(null);
  const [openConfirm, setOpenConfirm] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const [queryTable, setQueryTable] = useState({});

  function fetch() {
    return tableRef.current && tableRef.current.onQueryChange({ page: 0 });
  }

  const clearFilters = () => {
    setBefore('');
    setAfter('');
    setPaymentAfter('');
    setPaymentBefore('');
    setExpenseState([]);
    setExpenseCategories([]);
    setSelectedProviders([]);
    setSelectedClients([]);
    setSelectedProjects([]);
    fetch();
  };

  useEffect(() => {
    if (refresh) {
      fetch();
    }
  }, [refresh]);

  const flattenProject = () => {
    const clientsId = Object.keys(clientsProject);
    const projects = [];
    clientsId.forEach((clientId) => {
      clientsProject[clientId].forEach((project) => projects.push(project));
    });
    return projects;
  };
  const projects = flattenProject();

  function renderType(value) {
    switch (value) {
      case 'GroupedExpense':
      case 'SimpleExpense':
        return t('expenses.expense.type.note');
      case 'AdvancedExpense':
        return t('expenses.expense.type.advance');
      case 'InvoiceExpense':
      case 'Purchase':
        return t('expenses.expense.type.invoice');
      default:
        return 0;
    }
  }

  function renderEntity(exp) {
    if (exp.__t === 'Purchase' || exp.__t === 'InvoiceExpense') {
      // if (providers[exp.provider] && providers[exp.provider].name) {
      //   return providers[exp.provider].name;
      // }
      return providers[exp.provider]
        ? providers[exp.provider].name
        : t('not_setted');
    }

    if (exp.__t === 'GroupedExpense') {
      if (
        exp.expenses &&
        exp.expenses[0] &&
        providers[exp.expenses[0].provider] &&
        providers[exp.expenses[0].provider].name
      ) {
        return providers[exp.expenses[0].provider].name;
      }
    }

    if (exp.__t === 'SimpleExpense') {
      if (
        exp.provider &&
        providers[exp.provider] &&
        providers[exp.provider].name
      ) {
        return providers[exp.provider].name;
      }
    }
    return t('not_setted');
  }

  function renderCategory(exp) {
    let catId = null;

    switch (exp.__t) {
      case 'GroupedExpense':
        catId = exp.expenses[0].category_id;
        break;
      case 'SimpleExpense':
      case 'InvoiceExpense':
      case 'Purchase':
        catId = exp.category_id;
        break;
      default:
        catId = null;
    }

    return catId && categories.data && categories.data[catId]
      ? categories.data[catId].description
      : '';
  }

  const renderProjectValue = (selected) => {
    const foundProject = selected
      .map((s) => projects.find((c) => c._id === s)?.name || '')
      .join(', ');

    return foundProject;
  };

  const updatePurchaseState = (purchase) =>
    t(`expenses.expense.state.${purchase.state}`);

  const getPaymentData = (exp) => {
    if (exp.transfers.length !== 0) {
      return exp.transfers[0].payoutId.created_at;
    }
    if (
      exp.transfers.length === 0 &&
      exp.category_id === SUBSCRIPTION_CATEGORY_ID
    ) {
      return exp.created_at;
    }
    return null;
  };
  const formatData = (data) => {
    const result = data.map((exp) => {
      return {
        ...exp,
        category: renderCategory(exp),
        type: renderType(exp.__t),
        expense_nb: exp.expense_nb,
        // state: t(`expenses.expense.state.${exp.displayState}`),
        state: updatePurchaseState(exp),
        date: exp.date,
        entity: renderEntity(exp),
        gross_total: `${NumberFormat.n(exp.total || exp.grandTotalAmount)}€`,
        duePayableAmount: `${NumberFormat.n(exp.duePayableAmount)}€`,
        id: exp._id,
        paymentDate: getPaymentData(exp),
        canDelete: exp.state === 'draft',
      };
    });
    return result;
  };

  function editPath(expense) {
    const url =
      expense.__t === 'GroupedExpense'
        ? getExpenseURL(expense.expenses[0].payment_type)
        : getExpenseURL(expense.payment_type);

    switch (expense.__t) {
      case 'GroupedExpense':
      case 'SimpleExpense':
        return {
          pathname: `/${url}/edit/${expense._id}`,
          state: {
            payment: expense.payment_type,
            title: t('expenses.expense.expense'),
          },
        };
      case 'AdvancedExpense':
        return `/expenses/advance/edit/${expense._id}`;
      case 'InvoiceExpense':
      case 'Purchase':
        return {
          pathname: `/purchases/edit/${expense._id}`,
          state: {
            payment: expense.payment_type,
            title: t('expenses.expense.expense'),
          },
        };

      default:
        return '/';
    }
  }

  const columns = [
    {
      title: t('expenses.expense.category'),
      field: 'category',
      sorting: false,
    },
    {
      title: t('invoices.list.state'),
      field: 'state',
      sorting: false,

      // eslint-disable-next-line react/prop-types
      render: ({ state }) => <PurchaseState state={state} />,
    },
    {
      title: t('invoices.list.nb'),
      field: 'expense_nb',
      sorting: false,
    },
    {
      title: t('invoices.form_date'),
      field: 'date',
      sorting: !orderByTotal,
      defaultSort: !orderByTotal ? 'desc' : undefined,
      render: ({ date }) => format(new Date(date), 'dd/MM/yyyy'),
    },
    {
      title: t('expenses.list.provider'),
      field: 'entity',
      sorting: false,
    },
    {
      title: t('expenses.expense.form_amount'),
      field: 'gross_total',
      sorting: false,
    },
    {
      title: t('invoices.list.activities'),
      field: 'internal_activity',
      sorting: false,
      render: ({ internal_activity: activity }) =>
        activities[activity]?.display || '-',
    },
    {
      title: t('invoices.list.project'),
      field: 'project',
      type: 'project',
      render: ({ project_id: projectId }) =>
        projects.find((item) => item._id === projectId)?.name || '-',
    },
    {
      title: t('invoices.form_client'),
      field: 'client',
      sorting: false,
      render: ({ client_id: clientId }) =>
        clients.find((item) => item._id === clientId)?.display_name || '-',
    },
    {
      title: t('invoices.to_pay'),
      field: 'duePayableAmount',
      sorting: orderByTotal,
      defaultSort: orderByTotal ? 'desc' : undefined,
    },
    {
      title: t('invoices.due_date'),
      field: 'due_date',
      softing: false,
      // eslint-disable-next-line camelcase
      render: ({ due_date }) => format(new Date(due_date), 'dd/MM/yyyy'),
    },
    {
      title: t('invoices.payment_date'),
      field: 'paymentDate',
      render: ({ paymentDate }) => {
        if (!paymentDate) return '-';
        return format(new Date(paymentDate), 'dd/MM/yyyy');
      },
    },
    {
      title: ' ',
      field: 'id',
      sorting: false,

      // eslint-disable-next-line react/display-name
      render: (value) => {
        const { __t: expenseType, canDelete } = value;

        const canSeeExpensePage = canUpdateExpense || canReadExpense;

        const userCanSeeButton =
          [
            'GroupedExpense',
            'SimpleExpense',
            'InvoiceExpense',
            'Purchase',
          ].includes(expenseType) && canSeeExpensePage;

        if (!userCanSeeButton) {
          return null;
        }

        return (
          <AppRowStartSpaceAround>
            <IconEdit
              disabled={refreshing}
              sm
              onClick={() => {
                history.push(editPath(value));
              }}
            />

            {canDelete && (
              <IconDelete
                sm
                onClick={() => {
                  setOpenConfirm(true);
                  setSelectedPurchase(value);
                }}
              />
            )}
          </AppRowStartSpaceAround>
        );
      },
      export: false,
    },
  ];

  // const formatFilterStates = (states) => {
  //   const foundPayoutPending = states.find(
  //     (state) => state === 'payoutpending'
  //   );

  //   if (foundPayoutPending) {
  //     const foundValidated = states.find((state) => state === 'validated');

  //     if (foundValidated) {
  //       return states.filter((state) => state !== 'payoutpending');
  //     }

  //     const mappedStates = states.filter((state) => state !== 'payoutpending');

  //     mappedStates.push('validated');
  //     return mappedStates;
  //   }

  //   return states;
  // };

  const renderClientValue = (selected) => {
    const foundClient = selected
      .map((s) => clients.find((c) => c._id === s)?.display_name || '')
      .join(', ');

    return foundClient;
  };

  function renderToolBar() {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        style={{
          position: 'relative',
          paddingBottom: '30px',
          paddingTop: '10px',
        }}
      >
        <Grid item>
          <FormControl style={{ width: 200, marginRight: 10 }}>
            <InputLabel id="demo-mutiple-checkbox-label">
              {t('invoices.list.state')}
            </InputLabel>
            <Select
              onClose={() => {
                fetch();
              }}
              fullWidth={true}
              multiple
              value={expenseState}
              onChange={(event) => setExpenseState(event.target.value)}
              input={<Input />}
              renderValue={(selected) =>
                selected.map((s) => t(`expenses.expense.state.${s}`)).join(', ')
              }
            >
              {expenseStates.map((state) => (
                <MenuItem key={state} value={state}>
                  <Checkbox checked={expenseState.indexOf(state) > -1} />
                  <ListItemText
                    primary={t(`expenses.expense.state.${state}`)}
                  />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: 200, marginRight: 10 }}>
            <InputLabel id="demo-mutiple-checkbox-label">
              {'Categories'}
            </InputLabel>
            <Select
              onClose={() => {
                fetch();
              }}
              fullWidth={true}
              multiple
              value={expenseCategories}
              onChange={(event) => setExpenseCategories(event.target.value)}
              input={<Input />}
              renderValue={(selected) =>
                selected.map((s) => categories.data[s]?.description).join(', ')
              }
            >
              {formatCategories.map((category) => (
                <MenuItem key={category} value={category._id}>
                  <Checkbox
                    checked={expenseCategories.indexOf(category._id) > -1}
                  />
                  <ListItemText primary={category.description} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: 100, marginRight: 10 }}>
            <InputLabel id="demo-mutiple-checkbox-label">
              {t('roles.form.name.Providers')}
            </InputLabel>
            <Select
              onClose={() => {
                fetch();
              }}
              fullWidth={true}
              multiple
              value={selectedProviders}
              onChange={(event) => setSelectedProviders(event.target.value)}
              input={<Input />}
              renderValue={(selected) =>
                selected.map((elem) => providers[elem].name).join(', ')
              }
            >
              {map(providers, (c) => c).map((provider) => (
                <MenuItem key={provider._id} value={provider._id}>
                  <Checkbox
                    checked={selectedProviders.indexOf(provider._id) > -1}
                  />
                  <ListItemText primary={provider.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: 100, marginRight: 10 }}>
            <InputLabel id="demo-mutiple-checkbox-label">
              {t('invoices.list.activities')}
            </InputLabel>
            <Select
              onClose={() => {
                fetch();
              }}
              fullWidth={true}
              multiple
              value={selectedActivities}
              onChange={(event) => setSelectedActivities(event.target.value)}
              input={<Input />}
              renderValue={(selected) =>
                selected.map((elem) => activities[elem].display).join(', ')
              }
            >
              {map(activities, (c) => c).map((activity) => (
                <MenuItem key={activity._id} value={activity._id}>
                  <Checkbox
                    checked={selectedActivities.indexOf(activity._id) > -1}
                  />
                  <ListItemText primary={activity.display} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: 100, marginRight: 10 }}>
            <InputLabel id="demo-mutiple-checkbox-label">
              {'Client(s)'}
            </InputLabel>
            <Select
              onClose={() => {
                fetch();
              }}
              fullWidth={true}
              multiple
              value={selectedClients}
              onChange={(event) => setSelectedClients(event.target.value)}
              input={<Input />}
              renderValue={renderClientValue}
            >
              {map(clients, (c) => c).map((client) => (
                <MenuItem key={client._id} value={client._id}>
                  <Checkbox
                    checked={selectedClients.indexOf(client._id) > -1}
                  />
                  <ListItemText primary={client.display_name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl style={{ width: 100, marginRight: 10 }}>
            <InputLabel id="demo-mutiple-checkbox-label">
              {t('invoices.list.project')}
            </InputLabel>
            <Select
              onClose={() => {
                fetch();
              }}
              fullWidth={true}
              multiple
              value={selectedProjects}
              onChange={(event) => setSelectedProjects(event.target.value)}
              input={<Input />}
              renderValue={renderProjectValue}
            >
              {projects.map((project) => (
                <MenuItem key={project._id} value={project._id}>
                  <Checkbox
                    checked={selectedProjects.indexOf(project._id) > -1}
                  />
                  <ListItemText primary={project.name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>

        <Grid item>
          <HorizontalBeforAfterDate
            onAfterChange={(value) => {
              setAfter(value);
              fetch();
            }}
            onBeforeChange={(value) => {
              setBefore(value);
              fetch();
            }}
            after={after}
            before={before}
            beforeTitle={t('invoice.filters.before')}
            afterTitle={t('invoice.filters.after')}
          />
        </Grid>
        <Grid item>
          <HorizontalBeforAfterDate
            onAfterChange={(value) => {
              setPaymentAfter(value);
              fetch();
            }}
            onBeforeChange={(value) => {
              setPaymentBefore(value);
              fetch();
            }}
            after={paymentAfter}
            before={paymentBefore}
            beforeTitle={t('invoice.filters.payment_before')}
            afterTitle={t('invoice.filters.payment_after')}
          />
        </Grid>
        <Grid item style={{ position: 'absolute', right: '0' }}>
          <Tooltip title={t('reset_filter')}>
            <Button
              startIcon={<Refresh />}
              onClick={() => clearFilters()}
              // disabled={selectedIndices.length < 1}
            >
              {t('reset_filter')}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }

  const handleFetchPurchases = (query) => {
    return new Promise((resolve) => {
      dispatch(
        fetchExpensePaginate(
          query,
          {
            type: 'purchase',
            state: /* formatFilterStates(expenseState) */ expenseState,
            categories: expenseCategories,
            before: before ? `${before}T23:59:59.999Z` : '',
            after,
            paymentBefore: paymentBefore ? formatBeforeDate(paymentBefore) : '',
            paymentAfter,
            providers: selectedProviders,
            activities: selectedActivities,
            clients: selectedClients,
            projects: selectedProjects,
          },
          refresh,
          false
        )
      ).then((res) => {
        setRefresh(false);
        const data = formatData(res.data);
        resolve({ ...res, data });
      });
    });
  };

  const handleDeleteDraft = async (isConfirm) => {
    if (isConfirm)
      try {
        spinnerService.startSpinner();
        await ExpenseService.deleteExpense(selectedPurchase.id);
        setRefresh(true);
      } catch (error) {
        throw new Error(error);
      } finally {
        spinnerService.endSpinner();
      }
  };

  const formatPurchasesToExport = (data = []) => {
    const newData = data?.map((item) => {
      const newItem = {
        [t('expenses.expense.category')]: item.category,
        [t('invoices.list.state')]: item.state,
        [t('invoices.list.nb')]: item.expense_nb,
        [t('invoices.form_date')]: format(new Date(item.date), 'dd/MM/yyyy'),
        [t('expenses.list.provider')]: renderEntity(item),
        [t('expenses.expense.form_amount')]: item.gross_total,
        [t('invoices.list.activities')]:
          activities[item.internal_activity]?.display || '-',
        [t('invoices.list.project')]:
          projects.find((project) => project._id === item.projectId)?.name ||
          '-',
        [t('invoices.form_client')]:
          clients.find((client) => client._id === item.client_id)
            ?.display_name || '-',
        [t('invoices.to_pay')]: item.duePayableAmount,
        [t('invoices.due_date')]: format(new Date(item.due_date), 'dd/MM/yyyy'),
        [t('invoices.payment_date')]: !item.paymentDate
          ? '-'
          : format(new Date(item.paymentDate), 'dd/MM/yyyy'),
      };
      return newItem;
    });
    return newData;
  };

  return (
    <Fragment>
      <ConfirmDialog
        toggleDialog={setOpenConfirm}
        confirmMessage={t('expenses.expense.confirm_delete_text')}
        isShowDialog={openConfirm}
        // type={confirmType}
        onConfirm={handleDeleteDraft}
        isCancelConfirm={true}
      />
      {renderToolBar()}
      <MaterialTable
        tableRef={tableRef}
        data={(query) => {
          setQueryTable(query);
          return handleFetchPurchases(query);
        }}
        columns={columns}
        onChangeRowsPerPage={(ps) => {
          setPageSize(ps);
        }}
        options={{
          filtering: false,
          // sorting: false,
          search: true,
          showTitle: false,
          exportButton: { csv: true },
          pageSize,
          exportCsv: async () => {
            const result = await handleFetchPurchases({
              ...queryTable,
              pagination: false,
            });
            const exportData = formatPurchasesToExport(result?.data);
            exportToCSV(exportData, 'data');
          },
        }}
        actions={[
          {
            icon: () => <Refresh />,
            isFreeAction: true,
            onClick: () => setRefresh(true),
          },
        ]}
        onOrderChange={() => {
          setOrderByTotal(false);
        }}
      />
    </Fragment>
  );
};

PurchaseListBody.propTypes = {
  refreshing: PropTypes.bool.isRequired,
  canReadExpense: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
};

export default PurchaseListBody;
