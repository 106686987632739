import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Grid, Typography } from '@material-ui/core';
import { AddAPhoto } from '@material-ui/icons';
import { LOGO_FILE_TYPE, SCAN_FILE_TYPE } from 'constants/account';

/* Import components */
import { AppGreyBold } from 'components/AppText';
import UploadFile from 'components/UploadFile';

/* Import actions */
import { fetchCompanyLogo } from 'actions/CompanyActions';

const CompanyIntro = ({ companyValues, setFieldValues }) => {
  const { t } = useTranslation();

  const loggedUserCompany = useSelector(
    // eslint-disable-next-line no-shadow
    (state) => state.loggedUserCompany.company
  );

  const onBusinessLogoSelected = (file) => {
    setFieldValues('logoFile', file);
  };

  const onChangeCompanyKBIZ = (file) => {
    setFieldValues('kbizFile', file);
  };
  const onChangeCompanySirenDoc = (file) => {
    setFieldValues('sirenDocsFile', file);
  };

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item sm={12} md={6} direction="column">
        <AppGreyBold>
          <Typography
            color="primary"
            variant="h6"
            style={{
              width: '100%',
              textAlign: 'center',
              marginBottom: '2rem',
              textTransform: 'uppercase',
            }}
          >
            {loggedUserCompany.name} Logo
          </Typography>
        </AppGreyBold>
        {/* <AppDividerXS /> */}
        <UploadFile
          accept={LOGO_FILE_TYPE}
          multiple={false}
          showButton={false}
          style={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
          }}
          customStyles={{ borderRadius: '0px', height: 'auto' }}
          shape="logoImage"
          // filename={values.filename}
          filename={loggedUserCompany.filename || ''}
          onFileSelected={(file) => {
            onBusinessLogoSelected(file, companyValues);
          }}
          fetchCompanyLogo={fetchCompanyLogo}
          buttonText={
            loggedUserCompany.filename === ''
              ? t('signup.company.upload')
              : t('signup.company.change')
          }
          // errors={errors}
          defaultIcon={
            <AddAPhoto
              style={{
                width: '100%',
                fontSize: '4.5em',
                marginTop: '15%',
              }}
            />
          }
        />
        {/* {logoError && <div style={{ color: 'red' }}>{t('error')}</div>} */}
        <p style={{ width: '100%', textAlign: 'center' }}>
          {t('signup.company.logo_upload_help')}
        </p>
      </Grid>

      {loggedUserCompany?.isContractor?.toString() === 'true' ? (
        <Grid item sm={12} md={6}>
          <AppGreyBold>
            <Typography
              color="primary"
              variant="h6"
              style={{
                width: '100%',
                textAlign: 'center',
                marginBottom: '2rem',
                textTransform: 'uppercase',
              }}
            >
              Avis répertoire SIRENE
            </Typography>
          </AppGreyBold>
          <UploadFile
            accept={SCAN_FILE_TYPE}
            shape="companyDocument"
            multiple={false}
            showButton={false}
            style={{
              width: '100%',
              height: '100%',
              textAlign: 'center',
            }}
            filename={loggedUserCompany.sirenDoc || ''}
            onFileSelected={(file) => {
              onChangeCompanySirenDoc(file, loggedUserCompany);
            }}
            fetchCompanyLogo={fetchCompanyLogo}
            buttonText={
              loggedUserCompany.filename === ''
                ? t('signup.company.upload')
                : t('signup.company.change')
            }
            defaultIcon={
              <AddAPhoto
                style={{
                  width: '100%',
                  fontSize: '4.5em',
                  marginTop: '15%',
                }}
              />
            }
          />
          <p style={{ width: '100%', textAlign: 'center' }}>
            {t('signup.company.siren_upload_help')}
          </p>
        </Grid>
      ) : (
        <Grid item sm={12} md={6}>
          <AppGreyBold>
            <Typography
              color="primary"
              variant="h6"
              style={{
                width: '100%',
                textAlign: 'center',
                marginBottom: '2rem',
                textTransform: 'uppercase',
              }}
            >
              Kbis
            </Typography>
          </AppGreyBold>
          {/* <AppDividerXS /> */}

          <UploadFile
            accept={SCAN_FILE_TYPE}
            multiple={false}
            showButton={false}
            style={{
              width: '100%',
              height: '100%',
              textAlign: 'center',
            }}
            // filename={values.filename}
            filename={loggedUserCompany.kbiz || ''}
            onFileSelected={(file) => {
              onChangeCompanyKBIZ(file, loggedUserCompany);
            }}
            fetchCompanyLogo={fetchCompanyLogo}
            buttonText={
              loggedUserCompany.filename === ''
                ? t('signup.company.upload')
                : t('signup.company.change')
            }
            // errors={errors}
            defaultIcon={
              <AddAPhoto
                style={{
                  width: '100%',
                  fontSize: '4.5em',
                  marginTop: '15%',
                }}
              />
            }
            shape="companyDocument"
          />

          <p style={{ width: '100%', textAlign: 'center' }}>
            {t('signup.company.kbis_upload_help')}
          </p>
        </Grid>
      )}
    </Grid>
  );
};

CompanyIntro.propTypes = {
  companyValues: PropTypes.object,
  setFieldValues: PropTypes.func,
};

export default CompanyIntro;
