import { AppBar, Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import PricingColumn from 'components/PricingColumn';
import { PricingTabList } from 'containers/Onboarding/utils';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import subscriptionService from 'services/SubscriptionService';
import spinnerService from 'services/SpinnerService';

import {
  DISCOVERY_PACKAGE,
  EFFICIENCY_FORMULA,
  MONTHLY,
  STANDARD_FORMULA,
  YEARLY,
} from 'constants/subscriptions';
import FormuleBasique from '../../assets/images/formule-asique.png';
import FormulePremium from '../../assets/images/formule-premium.png';
import FormulaStandard from '../../assets/images/formule-standard.png';

import useStyles from './styles';

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

const AppSubscriptions = ({ handleChoosePricing }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);
  const [subscriptions, setSubscriptions] = useState([]);

  const fetchSubscriptions = async () => {
    spinnerService.startSpinner();
    try {
      const data = await subscriptionService.getSubscriptions();
      if (data) {
        setSubscriptions(data.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      spinnerService.endSpinner();
    }
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getSubscriptionByNameAndInterval = (subscriptionName, interval) => {
    if (!subscriptions.length) return null;
    return subscriptions.find(
      (item) => item.name === subscriptionName && item.interval === interval
    );
  };

  useEffect(() => {
    fetchSubscriptions();
  }, []);

  return (
    subscriptions && (
      <div className={classes.root}>
        <AppBar className={classes.appBar} position="static" color="default">
          <Tabs
            className={classes.tabs}
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: { display: 'none' },
            }}
          >
            {PricingTabList.map((item, index) => {
              return (
                <Tab
                  key={item}
                  label={t(`onboarding.${item}`)}
                  className={
                    index === value ? classes.buttonActive : classes.button
                  }
                />
              );
            })}
          </Tabs>
        </AppBar>

        <TabPanel value={value} index={0}>
          <Typography variant="h6" align="center">
            {t('onboarding.pricing_title')}
          </Typography>
          <div className={classes.pricingContainer}>
            <PricingColumn
              handleChoosePricing={handleChoosePricing}
              title="discovery_formula"
              descriptions="discovery_description"
              price="4,90"
              dataName="basique"
              icon={FormuleBasique}
              isRounded={false}
              buttonText={'start_trial'}
              monthly
              dataSubscription={getSubscriptionByNameAndInterval(
                DISCOVERY_PACKAGE,
                MONTHLY
              )}
            />
            <PricingColumn
              handleChoosePricing={handleChoosePricing}
              title="standard_formula"
              descriptions="standard_descriptions"
              price="14,90"
              dataName="standard"
              icon={FormulaStandard}
              isRounded
              buttonText={'start_trial'}
              monthly
              dataSubscription={getSubscriptionByNameAndInterval(
                STANDARD_FORMULA,
                MONTHLY
              )}
            />
            <PricingColumn
              handleChoosePricing={handleChoosePricing}
              title="efficiency_formula"
              descriptions="efficiency_descriptions"
              price="18,90"
              dataName="premium"
              icon={FormulePremium}
              isRounded={false}
              buttonText={'start_trial'}
              monthly
              dataSubscription={getSubscriptionByNameAndInterval(
                EFFICIENCY_FORMULA,
                MONTHLY
              )}
            />
          </div>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Typography variant="h6" align="center">
            {t('onboarding.pricing_title')}
          </Typography>
          <div className={classes.pricingContainer}>
            <PricingColumn
              handleChoosePricing={handleChoosePricing}
              title="standard_formula"
              descriptions="standard_descriptions"
              price="159"
              dataName="standard"
              icon={FormulaStandard}
              isRounded
              buttonText={'start_trial'}
              dataSubscription={getSubscriptionByNameAndInterval(
                STANDARD_FORMULA,
                YEARLY
              )}
            />
            <PricingColumn
              handleChoosePricing={handleChoosePricing}
              title="efficiency_formula"
              descriptions="efficiency_descriptions"
              price="199"
              dataName="premium"
              icon={FormulePremium}
              isRounded={false}
              buttonText={'start_trial'}
              dataSubscription={getSubscriptionByNameAndInterval(
                EFFICIENCY_FORMULA,
                YEARLY
              )}
            />
          </div>
        </TabPanel>
      </div>
    )
  );
};

AppSubscriptions.propTypes = {
  handleChoosePricing: PropTypes.func,
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

export default AppSubscriptions;
