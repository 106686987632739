import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import { useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { map } from 'lodash';
import { Link } from 'react-router-dom';
import {
  EXPORT_FILE_TYPE,
  EXPORT_BOOK_TYPE,
  EXPORT_FILE_CONTENT_TYPE,
} from 'config/Constants';
import { exportFile } from 'helpers/utils';

import { IconDelete, IconEdit } from '../../../components/AppIcons';
import { AppRowStartSpaceAround } from '../../../components/AppContainers';
import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';
import { contentText } from '../../../assets/jss/components/appText';
import { tableFrame } from '../../../helpers/mui-datatableHelper';
import {
  userCanUpdateClient,
  userCanDeleteClient,
} from '../../../selectors/rightsSelector/clientRequests';

import {
  userCanUpdateProject,
  userCanReadProject,
  userCanDeleteProject,
} from '../../../selectors/rightsSelector/projectRequests';

const useStyles = makeStyles(() => ({
  contentText,
  hoverShow: {
    visibility: 'visible',
    '&:nth-child(2)': {
      marginLeft: '5px',
    },
  },
}));

const ClientProjects = ({ clientId, openProjectFormDialog }) => {
  const classes = useStyles();
  const clientProjects = useSelector((state) => state.clientProjects.projects);

  return map(
    clientProjects[clientId],
    (project) =>
      !project.deleted && (
        <Link
          key={project._id}
          to="/settings/clients"
          onClick={(e) => {
            e.preventDefault();
            openProjectFormDialog(clientId, project._id);
          }}
        >
          <div className={classes.contentText}>{project.name}</div>
        </Link>
      )
  );
};

const ClientListBody = ({
  openProjectFormDialog,
  openClientFormDialog,
  showDeleteDialog,
}) => {
  const { t } = useTranslation();

  const canUpdateClient = useSelector(userCanUpdateClient);
  const canDeleteClient = useSelector(userCanDeleteClient);

  const canReadProject = useSelector(userCanReadProject);
  const canUpdateProject = useSelector(userCanUpdateProject);
  const canDeleteProject = useSelector(userCanDeleteProject);
  const clientList = useSelector((state) => state.clients.data);

  const clients = Object.values(clientList).reduce((prev, curr) => {
    if (!curr.deleted) {
      const values = {
        id: curr._id,
        currType: curr.isCompany
          ? t('settings_section.clients.companies')
          : t('settings_section.clients.individuals'),
        name: curr.name,
        is_prospect: curr.is_prospect
          ? t('settings_section.clients.yes')
          : t('settings_section.clients.no'),
        contact: curr.isCompany
          ? curr.contact
          : [curr.title, curr.name].join(' '),
        phone: curr.phone,
        email: curr.email,
        account: curr.account,
      };
      return [...prev, values];
    }
    return prev;
  }, []);

  const columnsHeader = [
    { label: t('settings_section.clients.client_type'), name: 'clientType' },
    { label: t('settings_section.clients.table_name'), name: 'name' },
    {
      label: t('settings_section.clients.form_is_prospect'),
      name: 'is_prospect',
    },
    {
      label: t('settings_section.clients.form_label_contact'),
      name: 'contact',
    },
    { label: t('settings_section.clients.form_label_phone'), name: 'phone' },
    { label: t('settings_section.clients.form_label_email'), name: 'email' },
  ];
  const headerStyles = {
    paddingLeft: '16px',
    textAlign: 'left',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    fontWeight: '500',
  };

  const columns = tableFrame(columnsHeader, headerStyles);

  const exportData = () => {
    const clientsCompany = [];
    const clientsPrivate = [];
    Object.values(clientList).forEach((client) => {
      if (client.isCompany) {
        const company = {
          'Raison Sociale': client.name,
          Description: client.description,
          'Prospect?': client.is_prospect ? 'Oui' : 'Non',
          'Adresse de facturation': client.address,
          'Code Postal': client?.address?.zipcode,
          Ville: client.city,
          Pays: client.country,
          Civilité: client.title,
          'Nom du contact': client.contact,
          Téléphone: client.phone,
          'E-Mail': client.email,
          IBAN: client.iban,
          BIC: client.bic,
          SIRET: client.siret,
          'Assujetti à la TVA?': client.intracom_vat,
          'Numéro de TVA intracommunautaire': client?.adresses[0]?.address,
          'Adresse de livraison?': client?.adresses?.length > 0 ? 'Oui' : 'Non',
          'Adresse de livraison': client?.adresses[0]?.address,
          'Adresse de livraison - Code Postal': client?.adresses[0]?.zipcode,
          'Adresse de livraison - Ville': client?.adresses[0]?.city,
          'Adresse de livraison - Pays': client?.adresses[0]?.country,
        };
        clientsCompany.push(company);
      } else {
        const privateClient = {
          Civilité: client.title,
          'Nom du contact': client.name,
          'Prospect?': client.is_prospect ? 'Oui' : 'Non',
          Description: client.description,
          'Adresse de facturation': client.address,
          'Code Postal': client.zipcode,
          Ville: client.city,
          Pays: client.country,
          Téléphone: client.phone,
          'E-Mail': client.email,
          IBAN: client.iban,
          BIC: client.bic,
          'Adresse de livraison?': client?.adresses?.length > 0 ? 'Oui' : 'Non',
          'Adresse de livraison': client?.adresses[0]?.address,
          'Adresse de livraison - Code Postal': client?.adresses[0]?.zipcode,
          'Adresse de livraison - Ville': client?.adresses[0]?.city,
          'Adresse de livraison - Pays': client?.adresses[0]?.country,
        };
        clientsPrivate.push(privateClient);
      }
    });
    return { company: clientsCompany, private: clientsPrivate };
  };

  const options = {
    download: true,
    print: false,
    onDownload: () => {
      const jsons = exportData();
      exportFile(
        jsons.company,
        'clients company',
        EXPORT_FILE_CONTENT_TYPE,
        EXPORT_FILE_TYPE,
        EXPORT_BOOK_TYPE
      );
      exportFile(
        jsons.private,
        'clients private',
        EXPORT_FILE_CONTENT_TYPE,
        EXPORT_FILE_TYPE,
        EXPORT_BOOK_TYPE
      );
      return false;
    },
  };

  if (canReadProject || canUpdateProject || canDeleteProject) {
    columns.push({
      label: t('settings_section.clients.table_projects'),
      name: 'id',
      options: {
        // eslint-disable-next-line react/display-name
        customBodyRender: (value) => {
          return (
            <th>
              <ClientProjects
                clientId={value}
                openProjectFormDialog={openProjectFormDialog}
              />
            </th>
          );
        },
      },
    });
  }

  columns.push({
    label: ' ',
    name: 'id',
    options: {
      // eslint-disable-next-line react/display-name
      customBodyRender: (value) => (
        <AppRowStartSpaceAround>
          {canDeleteClient && (
            <IconDelete sm onClick={() => showDeleteDialog(value)} />
          )}

          {canUpdateClient && (
            <IconEdit
              sm
              onClick={() => {
                openClientFormDialog(value);
              }}
            />
          )}
        </AppRowStartSpaceAround>
      ),
    },
  });

  return (
    <MUIDataTable
      data={clients}
      columns={columns}
      options={getMuiDatatableOptions(t, clients.length, options)}
    />
  );
};

ClientListBody.propTypes = {
  openProjectFormDialog: PropTypes.func,
  openClientFormDialog: PropTypes.func,
  showDeleteDialog: PropTypes.func,
};

ClientProjects.propTypes = {
  openProjectFormDialog: PropTypes.func,
  clientId: PropTypes.string,
};

export default ClientListBody;
