import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AppCheckbox from 'components/AppCheckbox';
import APP_ROUTING from 'constants/appRouting';
import {
  ONBOARDING_DOCUMENT,
  STRIPE_PRICING_TRIAL_SECTION,
} from 'config/Constants';
import PricingColumn from 'components/PricingColumn';
import FormuleBasique from '../../../assets/images/formule-asique.png';

const useStyles = makeStyles({
  root: {
    fontSize: '25px',
    margin: 'auto 0',
  },
  container: {
    textAlign: 'center',
  },
  optionText: {
    margin: 'auto 0',
  },
  optionContent: {
    display: 'flex',
    marginTop: '40px',
  },
  rightContent: {
    margin: 'auto 0',
    padding: '44px 30px 0 30px',
  },
  leftContent: {
    padding: '0 10px',
  },
  button: {
    fontSize: '25px',
    width: '450px',
    height: '70px',
    borderRadius: '50px',
    backgroundColor: '#3d319d',
    color: '#FFFFFF',
    border: 'none',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(61, 49, 157,0.8)',
    },
  },
  buttonDisabled: {
    fontSize: '25px',
    width: '450px',
    height: '70px',
    borderRadius: '50px',
    backgroundColor: '#D7D7D7',
    color: '#FFFFFF',
    border: 'none',
    cursor: 'pointer',
  },
  buttonContent: {
    textAlign: 'right',
    // marginTop: '40px',
  },
  checkbox: {
    color: '#3d319d',
  },
  link: {
    cursor: 'pointer',
  },
  checkboxContent: {
    margin: 'auto 0',
  },
});

const SIGNUP_URL = `${APP_ROUTING.SIGNUP}?session_id=${STRIPE_PRICING_TRIAL_SECTION}`;

const ValidatePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();
  const [cgcChecked, setCgcChecked] = useState(false);
  const [antiMoney, setAntiMoney] = useState(false);

  return (
    <Grid container xs={12} spacing={2} className={classes.root}>
      <Grid xs={4} className={classes.leftContent}>
        <PricingColumn
          title="discovery_formula"
          descriptions="discovery_description"
          price="0"
          dataName="basique"
          icon={FormuleBasique}
          isRounded={false}
          buttonText={'subscribe'}
          monthly
          isValidate
        />
      </Grid>
      <Grid xs={8} className={classes.rightContent}>
        <Grid className={classes.optionText}>
          {t('onboarding.validate_confirm_text')}
        </Grid>
        <Grid spacing={2} direction="row" className={classes.optionContent}>
          <Grid xs={1} className={classes.checkboxContent}>
            <Grid>
              <AppCheckbox
                onClick={() => {
                  setCgcChecked(!cgcChecked);
                }}
                color="primary"
                checked={cgcChecked}
              />
            </Grid>
          </Grid>
          <Grid xs={11} className={classes.optionText}>
            {t('onboarding.cgc')}
            <a
              href={ONBOARDING_DOCUMENT.CGC}
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              {t('onboarding.validate_link')}
            </a>
          </Grid>
        </Grid>
        <Grid spacing={2} direction="row" className={classes.optionContent}>
          <Grid xs={1} className={classes.checkboxContent}>
            <Grid>
              <AppCheckbox
                onClick={() => {
                  setAntiMoney(!antiMoney);
                }}
                color="primary"
                checked={antiMoney}
              />
            </Grid>
          </Grid>
          <Grid xs={11} className={classes.optionText}>
            {t('onboarding.anti_money')}
            <a
              href={ONBOARDING_DOCUMENT.ANTI_MONEY_LAUNDERING}
              className={classes.link}
              target="_blank"
              rel="noreferrer"
            >
              {t('onboarding.validate_link')}
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid xs={12} className={classes.buttonContent}>
        <button
          className={
            !cgcChecked || !antiMoney ? classes.buttonDisabled : classes.button
          }
          onClick={() => history.push(SIGNUP_URL)}
          disabled={!cgcChecked && !antiMoney}
        >
          {t('onboarding.validate')}
        </button>
      </Grid>
    </Grid>
  );
};

ValidatePage.propTypes = {};

export default ValidatePage;
