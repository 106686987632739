import {
  APP_INITIALIZED,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

export default (state = false, action) => {
  switch (action.type) {
    case APP_INITIALIZED:
      return action.payload;
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return false;
    default:
      return state;
  }
};
