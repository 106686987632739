import { format } from 'date-fns';

const initialValues = (companyId, userId, subCategory) => {
  return {
    numberOfActiveVats: 0,
    companyId,
    userId,
    turnover: Boolean(subCategory?.turnover),
    categoryId: subCategory?.categoryId || null,
    subCategoryId: subCategory?.subCategoryId || null,
    children: subCategory?.children || [],
    dateEmission: `${format(new Date(), 'yyyy-MM-dd')}T${
      format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx").split('T')[1]
    }`,
    cashEnabled: Boolean(subCategory?.cashEnabled),
    payingMode: '',
    description: '',
    filename: '',
    state: 'draft',
    grandTotalAmount: '',
    totalForm: '',
    amount0: 0,
    vatrate0: 0,
    vat0: 0,
    total0: 0,
    amount1: 0,
    vatrate1: 0,
    vat1: 0,
    total1: 0,
    amount2: 0,
    vatrate2: 0,
    vat2: 0,
    total2: 0,
    amount3: 0,
    vatrate3: 0,
    vat3: 0,
    total3: 0,
    noVAT: true,
  };
};

/* eslint-disable import/prefer-default-export */
export default initialValues;
