/* eslint-disable no-console */
/**
 *  debug log
 * @param {*} params
 */
const debug = (data, ...params) => {
  console.debug(data, ...params);
};
/**
 *  debug log
 * @param {*} params
 */
const log = (data, ...params) => {
  console.log(data, ...params);
};
/**
 * information log
 * @param {*} params
 */
const info = (data, ...params) => {
  console.info(data, ...params);
};
/**
 * warning log
 * @param {*} params
 */
const warn = (data, ...params) => {
  console.warn(data, ...params);
};
/**
 * error log
 * @param {*} params
 */
const error = (data, ...params) => {
  console.error(data, ...params);
};

export default {
  debug,
  log,
  info,
  warn,
  error,
};
