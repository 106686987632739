import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';

import {
  STRIPE_PRICING_TRIAL_SECTION,
  LOCAL_STORAGE_PERSONAL_INFO,
} from 'config/Constants';
import APP_ROUTING from 'constants/appRouting';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';

import logger from 'helpers/logger';
import { TYPE_SOCIETE } from 'constants/company';
import Layout from '../../components/Layout';

import AppUserInfo from '../../components/AppCompany/AppUserInfo';
import AppCompanyInfo from '../../components/AppCompany/AppCompanyInfo';
import AppCompanyVatInfoSettings from '../../components/AppCompany/AppCompanyVatInfoSettings';
import AppCompanySetting from '../../components/AppCompany/AppCompanySetting';
import AppButton from '../../components/AppButton';
import LoadingDialog from '../../components/LoadingDialog';
import { AppDividerM, AppDividerColS } from '../../components/AppDividers';
import { AppRowCenterEnd } from '../../components/AppContainers';
import AppDialog from '../../components/AppDialog';
// formik
import { validationSchema } from './validation';
import handleSubmit from './handleSubmit';
// redux
import mapPropsToValues from './formstate';
import dispatch from './dispatch';
// helpers and style
import styles from '../../assets/jss/root';
import helperIntracom from '../../helpers/TVAIntracom';
import SignUpService from '../../services/SignUpService';

class Register extends Component {
  constructor(props) {
    super(props);

    // get stage from query params
    const currentStage = this.getQueryParams('stage')
      ? this.getQueryParams('stage')
      : 'STAGE_1';

    const user =
      this.props.location.state && this.props.location.state.user
        ? this.props.location.state.user
        : null;

    const displayStage = user ? currentStage : 'STAGE_1';

    this.state = {
      uploadErrors: false,
      width: window.innerWidth,
      height: window.innerHeight,
      fileUploaded: false,
      displayStage,
      user,
      submitted: false,
      showEmailIndicator: false,
      showUserExistsDialog: false,
      isDisplayCompanySettingInfo: false,
      loading: false,
      personalInfo: null,
    };

    if (currentStage !== displayStage) {
      this.setQueryParams('stage', displayStage);
    }

    this._onUserLogoSelected = this._onUserLogoSelected.bind(this);
    this._onBusinessLogoSelected = this._onBusinessLogoSelected.bind(this);
    this._onCodeSelected = this._onCodeSelected.bind(this);
    this._swithStages = this._swithStages.bind(this);
    this.computeVatNum = this.computeVatNum.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this._toggleEmailIndicator = this._toggleEmailIndicator.bind(this);
    this._showUserExistsDialog = this._showUserExistsDialog.bind(this);
    this.setQueryParams = this.setQueryParams.bind(this);
  }

  onSubmit(e) {
    const { handleSubmit: formikSubmit } = this.props;
    const { fileUploaded } = this.state;

    this._checkUploadErrors(!fileUploaded);
    this.setState({ submitted: true });
    formikSubmit(e);
  }

  // eslint-disable-next-line class-methods-use-this
  onUnload(event) {
    // eslint-disable-next-line no-param-reassign
    event.returnValue = true;
  }

  fetchRcs(name, value) {
    const { values, setFieldValue } = this.props;
    setFieldValue(name, value);
    const zipcode = this.name === 'zipcode' ? value : values.zipcode;
    const city = this.name === 'city' ? value : values.city;
    if (zipcode.match(/[\d]{5}/) && city) {
      SignUpService.getRcs(zipcode, city).then((res) => {
        if (res.data.rcs) setFieldValue('rcs', res.data.rcs);
      });
    }
  }

  _swithStages(stage) {
    this.setState(
      {
        displayStage: stage,
      },
      () => {
        // set stage to query params
        this.setQueryParams('stage', stage);
      }
    );
  }

  _onFileSelected(value, file) {
    this.props.setFieldValue(value, file);
  }

  _onUserLogoSelected(file) {
    this._onFileSelected('userFile', file);
    if (file) {
      this.setState({
        fileUploaded: true,
      });
    }
  }

  _onBusinessLogoSelected(file) {
    this._onFileSelected('logoFile', file);
  }

  _checkUploadErrors(errors) {
    logger.log('erros: ', errors);
    if (errors) {
      this.setState({
        uploadErrors: true,
      });
    }
  }

  _showLoadingDialog() {
    if (this.props.status) {
      return <LoadingDialog title={this.props.t('signup.company.signup')} />;
    }
    return null;
  }

  // _showEmailCheckingDialog() {
  //   if (this.state.showEmailIndicator) {
  //     return <LoadingDialog title={this.props.t('signup.email_check')} />;
  //   }
  //   return null;
  // }

  _toggleEmailIndicator() {
    this.setState(({ showEmailIndicator }) => ({
      showEmailIndicator: !showEmailIndicator,
    }));
  }

  _showUserExistsDialog(showUserExistsDialog) {
    this.setState({ showUserExistsDialog });
  }

  _onCodeSelected(code) {
    this.props.setFieldValue('code_ape', code && code.Code ? code.Code : code);
  }

  computeVatNum(siren, tva) {
    const { setFieldValue } = this.props;
    if (/^[0-9]{9}$/.test(siren) && tva) {
      setFieldValue('intracomvat', helperIntracom(siren));
    }
  }

  updateDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  // get query params
  getQueryParams(key = null) {
    const params = new URLSearchParams(this.props.location.search);
    if (key && params.has(key)) {
      return params.get(key);
    }

    return null;
  }

  setQueryParams(key, value) {
    const params = new URLSearchParams(this.props.location.search);
    params.set(key, value);
    this.props.history.push({
      pathname: '/signup',
      search: params.toString(),
    });
  }

  // map values form in localstore => CompanySignUpForm
  parsePersonalInfo() {
    const { setFieldValue } = this.props;
    const personalInfo = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_PERSONAL_INFO)
    );

    if (personalInfo) {
      const personalKeys = Object.keys(personalInfo);
      personalKeys.forEach((key) => {
        if (key === 'phonenumber') {
          setFieldValue('phone_number', personalInfo[key]);
        }
        setFieldValue(key, personalInfo[key]);
      });
      this.setState({ isDisplayCompanySettingInfo: true });
    }
  }

  checkSessionId(sessionId) {
    const personalInfo = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_PERSONAL_INFO)
    );
    const { history } = this.props;

    this.setState({ loading: true });
    if (sessionId === STRIPE_PRICING_TRIAL_SECTION) {
      this.parsePersonalInfo();
      this.setState({ loading: false });
      return;
    }
    if (sessionId && sessionId !== STRIPE_PRICING_TRIAL_SECTION) {
      SignUpService.checkStripeSession(sessionId)
        .then((res) => {
          this.parsePersonalInfo();
          const isUpdateSubContext =
            res.data?.data?.sessionContext === 'update_subscription';
          if (isUpdateSubContext) {
            history.push('/');
          }
        })
        .catch(() => {
          history.replace({
            pathname: personalInfo
              ? APP_ROUTING.STRIPE_PRICING
              : APP_ROUTING.ONBOARDING,
          });
        })
        .finally(() => this.setState({ loading: false }));
      return;
    }

    history.replace({
      pathname: APP_ROUTING.ONBOARDING,
    });
  }

  checkSubscriptionId() {
    const { history } = this.props;
    const subscriptionId = this.getQueryParams('subscription_id');
    if (subscriptionId) {
      this.parsePersonalInfo();
      return;
    }

    history.replace({
      pathname: APP_ROUTING.ONBOARDING,
    });
  }

  //

  componentDidMount() {
    const sessionId = this.getQueryParams('session_id');

    if (sessionId) {
      this.checkSessionId(sessionId);
    } else {
      this.checkSubscriptionId();
    }

    window.addEventListener('beforeunload', this.onUnload);
  }

  componentWillUnmount() {
    window.removeEventListener('beforeunload', this.onUnload);
  }

  _renderUserExistsDialog() {
    const { t } = this.props;
    if (this.state.showUserExistsDialog) {
      return (
        <AppDialog
          sm
          iconClose
          footer
          title={t('sure')}
          closeDialog={() => this.setState({ showUserExistsDialog: false })}
          onConfirmText={t('yes')}
          onCancelText={t('no')}
          onConfirm={() => {
            this._swithStages('STAGE_2');
            this._showUserExistsDialog(false);
            this.props.setFieldValue('signedUser', true, false);
          }}
          contentText={t('signup.email_message')}
          color="secondary"
        />
      );
    }

    return null;
  }

  renderBody() {
    const {
      classes,
      errors,
      touched,
      t,
      status,
      values,
      handleChange,
      setFieldValue,
      resetForm,
      setErrors,
      setSubmitting,
      signup,
      history,
      fetchInfoGreffe,
      setFieldTouched,
    } = this.props;
    const { uploadErrors, displayStage, isDisplayCompanySettingInfo, loading } =
      this.state;

    return (
      <>
        {loading && (
          <LoadingIndicatorDialog title={t('loading_single')} open={true} />
        )}
        {this._showLoadingDialog()}
        {/* {this._showEmailCheckingDialog()} */}
        {this._renderUserExistsDialog()}
        <Form>
          {errors && errors.internalError && (
            <div className="row">
              <div className="input-field col s12">
                <span className="helper-text" style={{ color: 'red' }}>
                  {errors.internalError}
                </span>
              </div>
            </div>
          )}
          {displayStage === 'STAGE_1' && (
            <AppUserInfo
              t={t}
              errors={errors}
              values={values}
              touched={touched}
              classes={classes}
              uploadErrors={uploadErrors}
              onSelectLogo={this._onUserLogoSelected}
              nextStage={() => this._swithStages('STAGE_2')}
              setFieldValue={setFieldValue}
              toggleEmailIndicator={this._toggleEmailIndicator}
              showUserExistsDialog={this._showUserExistsDialog}
            />
          )}
          {displayStage === 'STAGE_2' && (
            <AppCompanyInfo
              t={t}
              errors={errors}
              values={values}
              classes={classes}
              setSubmitting={setSubmitting}
              setErrors={setErrors}
              setFieldValue={setFieldValue}
              setFieldTouched={setFieldTouched}
              resetForm={resetForm}
              signup={signup}
              history={history}
              touched={touched}
              fetchInfoGreffe={fetchInfoGreffe}
              onBusinesLogoSelected={this._onBusinessLogoSelected}
              onCodeSelected={this._onCodeSelected}
              onZipcodeCityChange={() => {
                this.fetchRcs(values.zipcode, values.city);
              }}
              onSirenChange={(e) => {
                handleChange(e);
                this.computeVatNum(e.target.value, values.tva);
              }}
              nextStage={() => {
                if (values.companyType !== TYPE_SOCIETE)
                  setFieldValue(
                    'name',
                    values?.business_name || values.userName
                  );
                this._swithStages('STAGE_3');
              }}
              prevStage={() => this._swithStages('STAGE_1')}
              isSignup
              isDisplayCompanySettingInfo={isDisplayCompanySettingInfo}
            />
          )}

          {displayStage === 'STAGE_3' && (
            <>
              {values.companyType === TYPE_SOCIETE ? (
                <AppCompanySetting
                  t={t}
                  classes={classes}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  isSettings
                  touched={touched}
                  computeVatNum={this.computeVatNum}
                  stageTwo={() => this._swithStages('STAGE_2')}
                  stageOne={() => this._swithStages('STAGE_1')}
                />
              ) : (
                <AppCompanyVatInfoSettings
                  t={t}
                  classes={classes}
                  setFieldValue={setFieldValue}
                  values={values}
                  errors={errors}
                  submitted={this.state.submitted}
                  isSettings
                  touched={touched}
                  computeVatNum={this.computeVatNum}
                  stageTwo={() => this._swithStages('STAGE_2')}
                  stageOne={() => this._swithStages('STAGE_1')}
                />
              )}
              <AppDividerM />
              <AppRowCenterEnd>
                <AppButton
                  color="secondaryLight"
                  type="button"
                  onClick={this.onSubmit}
                  text={t('submit')}
                  isDisabled={status}
                  noBorder
                />
                <AppDividerColS />
                <AppDividerColS />
                <AppDividerColS />
                <AppDividerColS />
              </AppRowCenterEnd>
            </>
          )}
        </Form>
      </>
    );
  }

  render() {
    // redirect the user if they are already signed up
    if (this.props.isAuthenticated() && !this.state.user) {
      return <Redirect to="/home" />;
    }
    // else show the normal page
    return (
      <Layout
        header={null}
        sidebarLeft={true}
        sidebarRight={null}
        showUserCard={false}
        body={this.renderBody()}
      />
    );
  }
}

Register.propTypes = {
  setFieldValue: PropTypes.func,
  resetForm: PropTypes.func,
  setErrors: PropTypes.func,
  setSubmitting: PropTypes.func,
  signup: PropTypes.func,
  history: PropTypes.object,
  fetchInfoGreffe: PropTypes.func,
  values: PropTypes.object,
  t: PropTypes.func,
  classes: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  isAuthenticated: PropTypes.func,
  logout: PropTypes.func,
  handleSubmit: PropTypes.func,
  setFieldTouched: PropTypes.func,
  status: PropTypes.bool,
  location: PropTypes.object,
};

const RegisterWithForm = withFormik({
  displayName: 'CompanySignUpForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(Register);
const RegisterWithStyle = withStyles(styles)(RegisterWithForm);
const TranslatedRegister = withTranslation()(RegisterWithStyle);
const ConnectedRegister = connect(null, dispatch)(TranslatedRegister);
export default ConnectedRegister;
