import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ColorButton from '../ColorButton';
import AppCheckbox from '../../AppCheckbox';
import { AppSepLine } from '../../AppDividers';

class Editor extends Component {
  constructor(props) {
    super(props);

    this._onVisibilityChanged = this._onVisibilityChanged.bind(this);
  }

  _renderHeaderCheckboxes() {
    const { logo, company, title, invoice, contact, client, reference, t } =
      this.props;

    return (
      <fieldset>
        <legend>{t('template.form_header')}</legend>

        <div className="visibilities-wrapper">
          <div className="visibilities">
            <AppCheckbox
              checked={logo.visible === 'visible'}
              onClick={this._onVisibilityChanged}
              labelText={t('template.header.logo')}
              name="visible_logo"
              id="visible_logo"
            />

            <AppCheckbox
              checked={company.visible === 'visible'}
              onClick={this._onVisibilityChanged}
              labelText={t('template.header.company_info')}
              name="visible_company"
              id="visible_company"
            />

            <AppCheckbox
              checked={title.visible === 'visible'}
              onClick={this._onVisibilityChanged}
              labelText={t('template.header.title_info')}
              name="visible_title"
              id="visible_title"
            />

            <AppCheckbox
              checked={invoice.visible === 'visible'}
              onClick={this._onVisibilityChanged}
              labelText={t('template.header.invoice_info')}
              name="visible_invoice"
              id="visible_invoice"
            />

            <AppCheckbox
              checked={contact.visible === 'visible'}
              onClick={this._onVisibilityChanged}
              labelText={t('template.header.contract_info')}
              name="visible_contact"
              id="visible_contact"
            />
            <AppCheckbox
              checked={client.visible === 'visible'}
              onClick={this._onVisibilityChanged}
              labelText={t('template.header.client_info')}
              name="visible_client"
              id="visible_client"
            />

            <AppCheckbox
              checked={reference.visible === 'visible'}
              onClick={this._onVisibilityChanged}
              labelText={t('template.header.reference_info')}
              name="visible_reference"
              id="visible_reference"
            />
          </div>
        </div>
      </fieldset>
    );
  }

  // _renderFooterCheckboxes() {
  //   const {
  //     companyMentionBottom,
  //     companyNameBottom,
  //     companyEmailBottom,
  //     companyApeBottom,
  //     companySiretBottom,
  //     FooterText,
  //     FooterMentionText,
  //     FooterCompanyNameText,
  //     FooterEmailText,
  //     FooterApeText,
  //     FooterSiretText,
  //   } = this.props;

  //   return (
  //     <fieldset>
  //       <legend>{FooterText}</legend>

  //       <div className="visibilities-wrapper">
  //         <div className="visibilities">
  //           <label>
  //             <input
  //               type="checkbox"
  //               name="visible_companyMentionBottom"
  //               value="companyMentionBottom"
  //               onChange={this._onVisibilityChanged}
  //               checked={companyMentionBottom.visible === 'visible'}
  //             />
  //             {FooterMentionText}
  //           </label>

  //           <label>
  //             <input
  //               type="checkbox"
  //               name="visible_companyNameBottom"
  //               value="companyNameBottom"
  //               onChange={this._onVisibilityChanged}
  //               checked={companyNameBottom.visible === 'visible'}
  //             />
  //             {FooterCompanyNameText}
  //           </label>
  //         </div>

  //         <div className="visibilities">
  //           <label>
  //             <input
  //               type="checkbox"
  //               name="visible_companyEmailBottom"
  //               value="companyEmailBottom"
  //               onChange={this._onVisibilityChanged}
  //               checked={companyEmailBottom.visible === 'visible'}
  //             />
  //             {FooterEmailText}
  //           </label>

  //           <label>
  //             <input
  //               type="checkbox"
  //               name="visible_companyApeBottom"
  //               value="companyApeBottom"
  //               onChange={this._onVisibilityChanged}
  //               checked={companyApeBottom.visible === 'visible'}
  //             />
  //             {FooterApeText}
  //           </label>
  //         </div>

  //         <div className="visibilities">
  //           <label>
  //             <input
  //               type="checkbox"
  //               name="visible_companySiretBottom"
  //               value="companySiretBottom"
  //               onChange={this._onVisibilityChanged}
  //               checked={companySiretBottom.visible === 'visible'}
  //             />
  //             {FooterSiretText}
  //           </label>
  //         </div>
  //       </div>
  //     </fieldset>
  //   );
  // }

  _onVisibilityChanged(e) {
    this.props.handleChange(
      e.target.name,
      e.target.checked ? 'visible' : 'hidden'
    );
  }

  render() {
    const { color, onColorChange, t } = this.props;
    return (
      <Fragment>
        <div className="color">
          <ColorButton
            color={color}
            onColorChange={onColorChange}
            title={t('template.form_choose_color')}
          />
        </div>

        <h5>{t('template.form_visible_zones')}</h5>
        <AppSepLine />
        <div className="checkboxes">
          {this._renderHeaderCheckboxes()}
          {/* {this._renderFooterCheckboxes()} */}
        </div>
      </Fragment>
    );
  }
}

Editor.propTypes = {
  color: PropTypes.string,
  logo: PropTypes.object,
  company: PropTypes.object,
  title: PropTypes.object,
  invoice: PropTypes.object,
  contact: PropTypes.object,
  client: PropTypes.object,
  companyMentionBottom: PropTypes.object,
  companyNameBottom: PropTypes.object,
  companyEmailBottom: PropTypes.object,
  companyApeBottom: PropTypes.object,
  companySiretBottom: PropTypes.object,
  onColorChange: PropTypes.func,
  handleChange: PropTypes.func,
  t: PropTypes.func.isRequired,
  reference: PropTypes.object.isRequired,
};

export default Editor;
