/* Packages */
import React from 'react';
import PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';

/* Icons */
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

function ShowHideVisibilityIcon(props) {
  const { hide = false, handleChangeHideField } = props;

  return (
    <>
      {hide ? (
        <IconButton
          onClick={handleChangeHideField}
          size="small"
          style={{ padding: 2 }}
        >
          <VisibilityIcon color="primary" />
        </IconButton>
      ) : (
        <IconButton
          onClick={handleChangeHideField}
          size="small"
          style={{ padding: 2 }}
        >
          <VisibilityOffIcon color="primary" />
        </IconButton>
      )}
    </>
  );
}

ShowHideVisibilityIcon.propTypes = {
  hide: PropTypes.bool,
  handleChangeHideField: PropTypes.func,
};

export default ShowHideVisibilityIcon;
