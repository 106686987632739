import { bindActionCreators } from 'redux';
import { createUser, updateUser } from '../../actions/UserActions';
import { changeLanguage } from '../../actions/LanguageActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createUser,
      updateUser,
      changeLanguage,
    },
    dispatch
  );
