const initialValues = (treezorUserId, document, companyId) => {
  return {
    companyId,
    treezorUserId,
    type: null,
    filename: null,
    status: document.status,
  };
};

export default initialValues;
