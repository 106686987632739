import React from 'react';
import PropTypes from 'prop-types';
import { GOOGLE_DIRECTION_BASE_URL } from '../../config/Constants';

const GoogleDirectionLink = (props) => {
  const { origin, destination } = props;
  return (
    <a
      href={`${GOOGLE_DIRECTION_BASE_URL}origin=${encodeURI(
        origin
      )}&destination=${encodeURI(destination)}`}
      target="_blank"
      rel="noopener noreferrer"
    >
      google maps
    </a>
  );
};

GoogleDirectionLink.propTypes = {
  origin: PropTypes.string.isRequired,
  destination: PropTypes.string.isRequired,
};

export default GoogleDirectionLink;
