import _ from 'lodash';
import { fetchCompanySubscription } from 'actions/CompanySubscription';
import spinnerService from 'services/SpinnerService';
// import { TYPE_AUTO_ENTREPRENEUR } from 'constants/company';
import userServices from '../../services/UserService';
import userCompaniesService from '../../services/UserCompaniesService';
import roleServices from '../../services/RoleService';
import treezorService from '../../services/TreezorService';

import { setGlobalError } from '../GlobalError';

import { getFileExtension } from '../../helpers/utils';

import {
  FETCH_USERS,
  FETCH_USERS_FAILED,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  CHANGE_AVATAR,
  UPDATE_LOGGED_USER,
  CHANGE_PASSPORT,
  CHANGE_NEW_IDENTITY,
  // UPDATE_COMPANY,
  FETCHING_TREEZOR_USERS,
  FETCH_TREEZOR_USERS_SUCCESS,
  FETCH_TREEZOR_USERS_FAILED,
  UPDATE_USER_BENEFICIARY,
} from '../Types';

import {
  userCanCreateUserManagement,
  userCanDeleteUserManagement,
  userCanUpdateUserManagement,
} from '../../selectors/rightsSelector/userManagementRequests';

import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

import S3FileService from '../../services/S3FileService';

// export const fetchUsers = () => async (dispatch) => {
//   try {
//     const result = await userServices.fetchUsers();
//     dispatch({
//       type: FETCH_USERS,
//       payload: result.data,
//     });
//   } catch (error) {
//     dispatch(setGlobalError(error));
//     dispatch({
//       type: FETCH_USERS_FAILED,
//       error,
//     });
//   }
// };

export const fetchCompanyUsers = () => async (dispatch, getState) => {
  try {
    const companyId = selectLoggedUserCompanyId(getState());
    if (!companyId) {
      dispatch({
        type: FETCH_USERS,
        payload: { companyUsers: [] },
      });
      return;
    }
    const userId = getState().loggedUser.user._id;

    const {
      data: { companyUsers, userFunc, userCompanyId, treezorUser },
    } = await userCompaniesService.fetchUserCompaniesUsers({
      user_id: userId,
      company_id: companyId,
    });
    dispatch({
      type: FETCH_USERS,
      payload: { companyUsers, userFunc, userCompanyId, treezorUser },
    });
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({
      type: FETCH_USERS_FAILED,
    });
  }
};

export const createUser = (user, cb) => async (dispatch, getState) => {
  try {
    if (!userCanCreateUserManagement(getState())) {
      cb();
      return;
    }

    const {
      loggedUserCompany: { company },
    } = getState();

    const companyId = company._id;

    const roles = {
      addRoles: user.role,
      removeRoles: [],
    };

    const response = (
      await userServices.invite({
        ...user,
        roles,
        company_id: companyId,
      })
    ).data;
    // eslint-disable-next-line no-param-reassign
    user._id = response._id;
    // eslint-disable-next-line no-param-reassign
    user.created_at = response.created_at;
    dispatch({ type: CREATE_USER, payload: user });
    if (cb) {
      cb(null);
    }
  } catch (error) {
    if (cb) {
      cb(error);
    }
  }
};

export const updateUser = (user, cb) => async (dispatch, getState) => {
  if (!userCanUpdateUserManagement(getState())) {
    cb();
    return;
  }

  const {
    loggedUserCompany: { company },
    users,
    loggedUser,
  } = getState();

  const companyId = company._id;
  const userId = user._id;
  const treezorUserId = loggedUser.user.treezorUser?.userId;

  const userRoles = _.find(users, (u) => u._id === userId).role;

  let addRoles = [];
  const removeRoles = [];

  if (user.role.sort().join(',') !== userRoles.sort().join(',')) {
    addRoles = user.role.filter((role) => !userRoles.includes(role));

    userRoles.forEach((userRole) =>
      !user.role.includes(userRole) ? removeRoles.push(userRole) : null
    );
  }

  try {
    await userServices.updateUser({ ...user, company_id: companyId });

    if (treezorUserId) {
      await treezorService.updateTreezorProfile(treezorUserId, {
        mobile: user.phonenumber,
      });
    }

    if (addRoles.length || removeRoles.length) {
      await roleServices.handleUserRole(companyId, userId, {
        addRoles,
        removeRoles,
      });
    }

    dispatch({ type: UPDATE_USER, payload: user });
    if (user._id === loggedUser.user._id) {
      dispatch({
        type: UPDATE_LOGGED_USER,
        payload: {
          ...user,
          avatar: loggedUser.user.avatar,
          passport: loggedUser.user.passport,
        },
      });
    }
    cb(null);
  } catch (error) {
    cb(error);
  }
};

console.log('updateUserBeneficiary');

export const deleteUser = (id, cb) => async (dispatch, getState) => {
  try {
    if (!userCanDeleteUserManagement(getState())) {
      cb();
      return;
    }

    const {
      loggedUserCompany: { company },
    } = getState();

    const result = await userCompaniesService.fetchUserCompanies({
      company_id: company._id,
    });

    const conId = result.data.find(
      (d) => d.user_id === id && d.company_id === company._id
    )._id;

    await userCompaniesService.deleteUserCompany(conId);
    await dispatch(fetchCompanySubscription());
    dispatch({
      type: DELETE_USER,
      payload: id,
    });
    cb();
  } catch (error) {
    cb(error);
  }
};

export const changeAvatar =
  ({ file, b64 }, cb) =>
  (dispatch, getState) => {
    const {
      loggedUserCompany: { company },
      loggedUser: { user },
    } = getState();

    const userId = user._id;
    const ext = getFileExtension(file.name);
    const folder = `company-files-${company.alternativeId}`;
    const fileName = `${userId}-${new Date().toISOString()}.${ext}`;
    spinnerService.startSpinner();
    S3FileService.upload(file, folder, fileName)
      .then(() => {
        const avatar = `${folder}/${fileName}`;

        userServices
          .updateUser({ _id: user._id, avatar })
          .then(() => {
            dispatch({ type: CHANGE_AVATAR, payload: { avatar, b64 } });
            cb();
          })
          .catch(cb);
      })
      .catch(cb)
      .finally(() => spinnerService.endSpinner());
  };

export const changePassport = (file, cb) => (dispatch, getState) => {
  const {
    loggedUserCompany: { company },
    loggedUser: { user },
  } = getState();

  const userId = user._id;
  const ext = getFileExtension(file.name);
  const folder = `company-files-${company.alternativeId}`;
  const fileName = `${userId}-passeport-${new Date().toISOString()}.${ext}`;

  spinnerService.startSpinner();
  S3FileService.upload(file, folder, fileName)
    .then(() => {
      const passport = `${folder}/${fileName}`;

      userServices
        .updateUser({ _id: user._id, passport })
        .then(() => {
          dispatch({ type: CHANGE_PASSPORT, payload: passport });
          cb();
        })
        .catch(cb);
    })
    .catch(cb)
    .finally(() => spinnerService.endSpinner());
};

export const changeNewIdentityCard = (file, cb) => (dispatch, getState) => {
  const {
    loggedUserCompany: { company },
    loggedUser: { user },
  } = getState();

  const userId = user._id;
  const ext = getFileExtension(file.name);
  const folder = `company-files-${company.alternativeId}`;
  const fileName = `${userId}-passeport-${new Date().toISOString()}.${ext}`;

  spinnerService.startSpinner();
  S3FileService.upload(file, folder, fileName)
    .then(() => {
      const newIdentityCard = `${folder}/${fileName}`;

      userServices
        .updateUser({ _id: user._id, newIdentityCard })
        .then(() => {
          dispatch({ type: CHANGE_NEW_IDENTITY, payload: newIdentityCard });
          cb();
        })
        .catch(cb);
    })
    .catch(cb)
    .finally(() => spinnerService.endSpinner());
};

export const fetchTreezorUsers = () => async (dispatch, getState) => {
  const companyId = selectLoggedUserCompanyId(getState());
  if (!companyId) {
    // dispatch({
    //   type: FETCH_TREEZOR_USERS_SUCCESS,
    //   payload: { treezorUsers: [] },
    // });
    return;
  }
  dispatch({
    type: FETCHING_TREEZOR_USERS,
  });

  try {
    const result = await userServices.fetchTreezorUsers();
    const users = result.data;
    dispatch({
      type: FETCH_TREEZOR_USERS_SUCCESS,
      payload: { treezorUsers: users },
    });
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({
      type: FETCH_TREEZOR_USERS_FAILED,
      error,
    });
  }
};

export const updateUserBeneficiary = (data) => (dispatch) => {
  dispatch({ type: UPDATE_USER_BENEFICIARY, payload: data });
};
