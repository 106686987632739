import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { map } from 'lodash';
import { useFormikContext } from 'formik';

import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  // FormLabel,
  TextField,
  // Grid,
} from '@material-ui/core';
import CustomGenericDialog from 'components/CustomGenericDialog';
import Form from 'containers/ProvidersPage/Form';

const ProviderForm = (props) => {
  const { showModal, title, closeProviderFormDialog, activities } = props;
  if (showModal) {
    return (
      <CustomGenericDialog
        open={showModal}
        title={title}
        iconClose
        onConfirmType="submit"
        onConfirmText="submit"
        color="secondary"
        closeDialog={closeProviderFormDialog}
      >
        <Form closeDialog={closeProviderFormDialog} activities={activities} />
      </CustomGenericDialog>
    );
  }
  return null;
};

const CreateDebitSecondStepYesOption = (props) => {
  const activities = useSelector((state) => state.activities);
  const { handleContinue, handleCancel } = props;
  const { setFieldValue, errors, touched, values } = useFormikContext();
  const categoryTree = useSelector((state) => state.categories.tree || []);
  const providers = map(useSelector((state) => state.providers)).map(
    (provider) => ({
      id: provider._id,
      name: provider.name,
      account: provider.account,
    })
  );

  const translateDomain = 'debits_page.debits_creation_page';
  const { t } = useTranslation();

  const [provider, setProvider] = useState('');
  const [category, setCategory] = useState('');
  const [showFormProvider, setShowFormProvider] = useState(false);

  const handleCloseProviderModal = () => setShowFormProvider(false);
  const handleOpenProviderModal = () => setShowFormProvider(true);

  useEffect(() => {
    setProvider(values.provider);
    setCategory(values.category);
  }, []);

  const handleChangeProvider = (event) => {
    setProvider(event.target.value);
  };

  const handleChangeCategory = (event) => {
    setCategory(event.target.value);
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        padding: '20px',
      }}
    >
      <Box>
        <Typography
          variant="h5"
          align="center"
          style={{
            padding: '20px',
          }}
        >
          {t(`${translateDomain}.steps.step2.title`)}
        </Typography>
      </Box>
      <Box style={{ width: '100%' }}>
        <FormControl fullWidth style={{ display: 'flex', gap: '20px' }}>
          <InputLabel>
            {t(`${translateDomain}.steps.step2.bbf_provider`)}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={provider}
            label={t(`${translateDomain}.steps.step2.bbf_provider`)}
            onChange={(event) => {
              handleChangeProvider(event);
              setFieldValue('provider', event.target.value);
            }}
          >
            {providers &&
              providers.map((item, index) => (
                <MenuItem value={item.id} key={index}>
                  {item.name}
                </MenuItem>
              ))}
          </Select>
          {errors.provider && touched.provider ? (
            <div style={{ color: 'red' }}>{errors.provider}</div>
          ) : null}

          <Button
            variant="contained"
            color="secondary"
            onClick={handleOpenProviderModal}
            style={{ marginBottom: '20px' }}
          >
            {t(`${translateDomain}.steps.step2.create_provider`)}
          </Button>
        </FormControl>

        <FormControl fullWidth>
          <InputLabel>
            {t(`${translateDomain}.steps.step2.category`)}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={category}
            label={t(`${translateDomain}.steps.step2.bbf_provider`)}
            onChange={(event) => {
              handleChangeCategory(event);
              setFieldValue('category', event.target.value);
            }}
          >
            {categoryTree &&
              categoryTree.map((item, index) => (
                <MenuItem value={item._id} key={index}>
                  {item.display}
                </MenuItem>
              ))}
          </Select>
          {errors.category && touched.category ? (
            <div style={{ color: 'red' }}>{errors.category}</div>
          ) : null}
        </FormControl>

        <FormControl fullWidth style={{ marginTop: '20px' }}>
          {/* <FormLabel>{t(`${translateDomain}.steps.step2.amount`)}</FormLabel> */}

          <TextField
            // label={t(`${translateDomain}.steps.step3.ics`)}
            id="amount"
            type="text"
            defaultValue=""
            // value={values.amount}
            onChange={(event) => setFieldValue('amount', event.target.value)}
            variant="standard"
            label={t(`${translateDomain}.steps.step2.amount`)}
            // InputLabelProps={{
            //   shrink: true,
            // }}
            // disabled={isReviewMode}
            // {...field}
          />
          {errors.amount && touched.amount ? (
            <div style={{ color: 'red' }}>{errors.amount}</div>
          ) : null}
        </FormControl>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '100%',
          marginTop: '20px',
        }}
      >
        <Button variant="contained" color="default" onClick={handleCancel}>
          {t(`${translateDomain}.buttons.cancel`)}
        </Button>
        <Button variant="contained" color="primary" onClick={handleContinue}>
          {t(`${translateDomain}.buttons.continue`)}
        </Button>
      </Box>
      <ProviderForm
        title={t('settings_section.providers.create_title')}
        showModal={showFormProvider}
        closeProviderFormDialog={handleCloseProviderModal}
        activities={activities}
      />
    </Box>
  );
};

ProviderForm.propTypes = {
  showModal: PropTypes.bool,
  title: PropTypes.string,
  closeProviderFormDialog: PropTypes.func,
  activities: PropTypes.object,
};

CreateDebitSecondStepYesOption.propTypes = {
  handleContinue: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default CreateDebitSecondStepYesOption;
