/* THIS FILE SHOULD MOVE TO SHARED LIB */

const initialValues = (prefill) => {
  const today = new Date();
  let data = {
    otp: null,
    beneficiary: null,
    disableBeneficiary: false,
    beneficiaryType: null,
    disableBeneficiaryType: false,
    reference: '',
    amount: 0,
    transferDate: today.toISOString(),
    note: '',
    isShowedUploadPart: false,
    contractFiles: [],
    iban: '',
    bic: '',
  };

  if (prefill) {
    const { beneficiaryType, beneficiary, amount } = prefill;

    if (beneficiaryType) {
      data = { ...data, beneficiaryType, disableBeneficiaryType: true };
    }

    if (beneficiary) {
      data = { ...data, beneficiary, disableBeneficiary: true };
    }

    if (amount) {
      data = { ...data, amount };
    }
  }

  return data;
};

/* eslint-disable import/prefer-default-export */
export { initialValues };
