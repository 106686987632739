import {
  LOGGED_USER_COMPANY_SELECTED,
  LOGGED_USER_COMPANY_LOADED,
  FETCH_LOGGED_USER,
  UPDATE_COMPANY,
  LOGOUT_USER_SUCCESS,
  INVOICE_TURNOVER,
  FETCH_TREEZOR_USERS_SUCCESS,
  ONBOARDING_TREEZOR,
  UPDATE_BANK_DETAILS_COMPANY,
  SELECT_COMPANY,
} from 'actions/Types';

const INITIAL_STATE = {
  company: null,
  companies: [],
  isFetchingCompanies: false,
  errorFetchingRoles: null,
  selectedCompanyId: null,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_LOGGED_USER:
      return {
        ...state,
        isFetchingCompanies: true,
        errorFetchingRoles: null,
      };
    case LOGGED_USER_COMPANY_SELECTED:
      return {
        ...state,
        company: action.payload.selectedCompany || null,
        isFetchingCompanies: false,
        errorFetchingRoles: null,
      };
    case LOGGED_USER_COMPANY_LOADED:
      return {
        ...state,
        companies: action.payload.docs || [],
        isFetchingCompanies: false,
        errorFetchingRoles: null,
      };
    case UPDATE_BANK_DETAILS_COMPANY:
      return {
        ...state,
        company: {
          ...state.company,
          bank_details: action.payload.bank_details,
        },
        isFetchingCompanies: false,
        errorFetchingRoles: null,
      };
    case UPDATE_COMPANY: {
      const index = state.companies.findIndex(
        (c) => c._id === action.payload.id
      );
      const { companies } = state;
      companies[index] = action.payload.data;

      return {
        ...state,
        company: { ...state.company, ...action.payload.data },
        companies,
      };
    }
    case ONBOARDING_TREEZOR: {
      return {
        ...state,
        company: { ...state.company, treezorUser: action.payload },
      };
    }
    case FETCH_TREEZOR_USERS_SUCCESS: {
      let treezorUser;
      const { treezorUsers } = action.payload;
      if (treezorUsers.length === 1) {
        // use the first element of the array
        [treezorUser] = treezorUsers;
      } else if (treezorUsers.length === 0) {
        treezorUser = null;
      } else {
        const companyOId = state.company._id;
        for (let a = 0; a < treezorUsers.length && !treezorUser; a += 1) {
          const u = treezorUsers[a];
          if (u.userTag === companyOId) {
            treezorUser = u;
          }
        }
      }
      return {
        ...state,
        company: { ...state.company, treezorUser },
      };
    }
    case INVOICE_TURNOVER: {
      return {
        ...state,
        invoiceTurnOver: action.payload,
      };
    }
    case LOGOUT_USER_SUCCESS:
      return INITIAL_STATE;

    case SELECT_COMPANY:
      return {
        ...state,
        selectedCompanyId: action.payload,
      };
    default:
      return state;
  }
};
