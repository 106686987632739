import React, { useState } from 'react';
import {
  Card,
  TextField,
  makeStyles,
  CardContent,
  Grid,
  Checkbox,
} from '@material-ui/core';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import { REGEX } from 'constants/form';
import { AppDividerXS } from 'components/AppDividers';
import { useTranslation } from 'react-i18next';
import ErrorMessage from 'components/ErrorMessage';
import { isEmpty } from 'lodash';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    margin: 20,
    padding: 5,
  },
});

const BankCard = ({
  bank = {},
  onChange,
  disabled,
  synchronizeButton,
  hideJournal,
  required = false,
  disabledFields = {},
  bankDetailsError = false,
  notFirstItem = true,
  isSetting = false,
  handleChooseDefaultBank,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [bankNameTouched, setBankNameTouched] = useState(false);
  const [ibanTouched, setIbanTouched] = useState(false);
  const [bicTouched, setBicTouched] = useState(false);

  const handleOnchange = (e) => {
    if (onChange) {
      onChange(e.target.value, e.target.name);
    }
  };

  const handleOnchangeCheckbox = () => {
    if (handleChooseDefaultBank) handleChooseDefaultBank(bank);
  };

  const checkRequired = (name, isTouched) => {
    const invalid = name === 'bank_name' ? true : notFirstItem;
    return (
      (isEmpty(bank[name]) && required && invalid && isTouched) ||
      (bankDetailsError && isEmpty(bank[name]) && required && invalid)
    );
  };

  const getIbanValdation = () => {
    const validate = { invalid: false, message: '' };
    const invalidRegex = notFirstItem
      ? !bank.iban?.match(REGEX.IBAN)
      : !bank.iban?.match(REGEX.IBAN) && bank.iban?.length > 0;

    if (ibanTouched) {
      if (!bank.iban && required && notFirstItem) {
        validate.invalid = true;
        validate.message = t('forms.mandatory_field');
      } else if (bank.iban?.length < 23 && notFirstItem) {
        validate.invalid = true;
        validate.message = t('forms.iban_minimum');
      } else if (bank.iban?.length > 34) {
        validate.invalid = true;
        validate.message = t('settings_section.clients.iban_validation');
      } else if (invalidRegex) {
        validate.invalid = true;
        validate.message = t('forms.iban_invalid');
      }
    }
    return validate;
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        <TextField
          required={required}
          variant="outlined"
          type="text"
          name={`bank_name`}
          label={t('signup.name')}
          placeholder={t('signup.name')}
          color="secondary"
          error={checkRequired('bank_name', bankNameTouched)}
          fullWidth
          disabled={disabled || disabledFields.bank_name}
          value={bank.bank_name || ''}
          onChange={(e) => handleOnchange(e)}
          onBlur={() => {
            if (!bankNameTouched) {
              setBankNameTouched(true);
            }
          }}
        />
        <ErrorMessage
          showError={checkRequired('bank_name', bankNameTouched)}
          error={t('forms.mandatory_field')}
        />
        <AppDividerXS />
        <TextField
          required={required && notFirstItem}
          variant="outlined"
          type="text"
          name={`iban`}
          label={'IBAN'}
          placeholder={'FRXX XXXX XXXX'}
          color="secondary"
          error={getIbanValdation().invalid}
          fullWidth
          disabled={disabled || disabledFields.iba}
          value={bank.iban || ''}
          onChange={(e) => handleOnchange(e)}
          onBlur={() => {
            if (!ibanTouched) {
              setIbanTouched(true);
            }
          }}
        />
        <ErrorMessage
          showError={getIbanValdation().invalid}
          error={getIbanValdation().message}
        />
        <AppDividerXS />
        <TextField
          required={required && notFirstItem}
          variant="outlined"
          type="text"
          name={`bic`}
          label={'BIC'}
          placeholder={'AA BB CC XXX'}
          color="secondary"
          error={checkRequired('bic', bicTouched)}
          fullWidth
          disabled={disabled || disabledFields.bic}
          value={bank.bic || ''}
          onChange={(e) => handleOnchange(e)}
          onBlur={() => {
            if (!bicTouched) {
              setBicTouched(true);
            }
          }}
        />
        <ErrorMessage
          showError={checkRequired('bic', bicTouched)}
          error={t('forms.mandatory_field')}
        />
        {!hideJournal && (
          <>
            <AppDividerXS />
            <TextField
              InputLabelProps={{
                shrink: !!bank.journalCode,
              }}
              variant="outlined"
              type="text"
              name={`journalCode`}
              label={t('signup.code_journal')}
              color="secondary"
              fullWidth
              disabled={true}
              value={bank.journalCode}
            />
            <AppDividerXS />
            <TextField
              InputLabelProps={{
                shrink: !!bank.account,
              }}
              variant="outlined"
              type="text"
              name={`account`}
              label={t('signup.account')}
              color="secondary"
              fullWidth
              disabled={true}
              value={bank.account}
            />
          </>
        )}
        {isSetting && (
          <Grid
            style={{
              marginTop: 8,
              marginBottom: -20,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Checkbox
              name="isDefault"
              checked={bank?.isDefault || false}
              disabled={bank?.isDefault}
              onClick={(e) => {
                handleOnchangeCheckbox(e);
              }}
            />
            <span style={{ color: 'gray' }}>{t('company.bank.default')}</span>
          </Grid>
        )}
        {synchronizeButton && (
          <>
            <AppDividerXS />
            {synchronizeButton()}
          </>
        )}
      </CardContent>
    </Card>
  );
};

BankCard.propTypes = {
  bank: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  synchronizeButton: PropTypes.func,
  hideJournal: PropTypes.bool,
  disabledFields: PropTypes.object,
  required: PropTypes.bool,
  bankDetailsError: PropTypes.bool,
  isSetting: PropTypes.bool,
  handleChooseDefaultBank: PropTypes.func,
  notFirstItem: PropTypes.bool,
};

export default BankCard;
