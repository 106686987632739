import {
  Button,
  Chip,
  Divider,
  Grid,
  IconButton,
  Popover,
  TextField,
} from '@material-ui/core';
import {
  Archive,
  Check,
  Clear,
  CloudUpload,
  Refresh,
  Save,
} from '@material-ui/icons';
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import React, {
  useCallback,
  useEffect,
  useMemo /* useEffect */,
  useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  extractPDF,
  getAllPDFOriginal,
  renameOriginalFile,
  uploadPDF,
} from 'actions/PDFActions';
import { ExtractPDFIcon, IconSee, RenameIcon } from 'components/AppIcons';
import CircularProgressCentered from 'components/CircularProgressCentered';
import Header from 'components/Header';
import Layout from 'components/Layout';
import MaterialTable from 'components/MaterialTable';
import { useRouter } from 'hooks/routerHooks';
import { DEFAULT_PAGESIZE } from 'config/Constants';
import styles from './styles.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  color: 'white',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const PDFContainer = () => {
  const { search, state } = useLocation();
  const originalId = new URLSearchParams(search).get('id');

  const { t } = useTranslation();
  const { history } = useRouter();
  const dispatch = useDispatch();
  const tableRef = React.createRef();

  const [showSpinner, setShowSpinner] = useState(false);
  const [pdf, setPDF] = useState(null);
  const [pdfImages, setPdfImages] = useState([]);
  const [selectedIndices, setSelectedIndices] = useState([]);
  const [original, setOriginal] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [selectedPages, setSelectedPages] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [extract, setExtract] = useState(null);
  const [extractedImages, setExtractedImages] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);

  const [tableLoading, setTableLoading] = useState(false);
  const [editPDFField, setEditPDFField] = useState('');
  const [tableData, setTableData] = useState([]);
  const [tablePage, setTablePage] = useState(0);
  const [tableRowPerPage, setTableRowPerPage] = useState(DEFAULT_PAGESIZE);
  const [tableTotal, setTableTotal] = useState(0);
  const [selectedRowOId, setSelectedRowOId] = useState(null);
  const [refresh, setRefresh] = useState(0);
  const [isExtracting, setIsExtracting] = useState(false);

  // async function getFileFromUrl(url, name) {
  //   const response = await fetch(url);
  //   const data = await response.blob();
  //   return new File([data], name, {
  //     type: data.type,
  //   });
  // }

  useEffect(() => {
    const getDataInDb = async () => {
      setTableLoading(true);

      const data = await dispatch(
        getAllPDFOriginal({
          page: tablePage,
          limit: tableRowPerPage,
        })
      );

      setTableData(data.data);
      setTableTotal(data.totalCount);
      setTableLoading(false);
    };
    getDataInDb();
  }, [tablePage, tableRowPerPage, refresh]);

  const convertPDFToImage = useCallback(async ({ pdfDoc, scale }) => {
    const canvas = document.createElement('canvas');
    const images = [];

    // eslint-disable-next-line no-plusplus
    for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
      // eslint-disable-next-line no-await-in-loop
      const page = await pdfDoc.getPage(pageNum);
      const viewport = page.getViewport({ scale });
      // const canvas = canvasRef.current;
      const context = canvas.getContext('2d', { alpha: false });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      // eslint-disable-next-line no-await-in-loop
      await page.render({ canvasContext: context, viewport }).promise;
      const image = canvas.toDataURL('image/png');
      images.push(image);
    }
    canvas.remove();
    return images;
    // setPdfImages(images);
  }, []);

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        setPDF(file);
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    const [pdfArrayBuffer] = await Promise.all(
      acceptedFiles.map((file) => readFileAsync(file))
    );
    const loadingTask = pdfjs.getDocument(pdfArrayBuffer);

    const pdfDoc = await loadingTask.promise;

    // setPDF(pdfDoc);
    const images = await convertPDFToImage({ pdfDoc, scale: 1 });
    setPdfImages(images);
  }, []);

  const clearSelectedFile = () => {
    setPDF(null);
    setPdfImages([]);
    setSelectedIndices([]);
    setOriginal(null);
    setSelectedPages(null);
    setExtract(null);
    setExtractedImages([]);
  };

  const clearSelectedPages = () => {
    setSelectedIndices([]);
    setSelectedPages([]);
  };

  const extractFilesPDF = async (selectItems) => {
    let originalS3 = original;
    if (!originalS3) {
      originalS3 = await dispatch(uploadPDF(pdf));
    }
    const values = {
      file: originalS3,
      indices: selectedIndices,
    };
    const { originalOId, splitFileKey, splitId } = await dispatch(
      extractPDF(values)
    );

    if (selectItems === 0) {
      clearSelectedFile();
      history.push(`/pdf`);
      setRefresh(refresh + 1);
    } else {
      history.push(
        `/pdf/item?originalId=${
          originalId || originalOId
        }&extractedKey=${splitFileKey}&splitId=${splitId}`
      );
    }
  };

  const onExtract = async (selectItems) => {
    setShowSpinner(true);
    setIsExtracting(true);

    await extractFilesPDF(selectItems);

    // setExtract(splitFileKey);
    // const extractedFile = await dispatch(getPDFFile(splitFileKey));
    // const file = await getFileFromUrl(extractedFile, splitFileKey);

    // const pdfArrayBuffer = await readFileAsync(file);
    // const loadingTask = pdfjs.getDocument(pdfArrayBuffer);

    // const pdfDoc = await loadingTask.promise;

    // const images = await convertPDFToImage({ pdfDoc, scale: 1 });
    // setExtractedImages(images);
    setShowSpinner(false);
    setIsExtracting(false);
  };

  const handleOpenRenamePopover = (event, id) => {
    setSelectedRowOId(id);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelectedRowOId(null);
  };

  // useEffect(() => {
  //   (async () => {
  //     if (originalId) {
  //       setShowSpinner(true);
  //       const originalFileKey = await dispatch(getOriginalById(originalId));
  //       if (originalFileKey) {
  //         setOriginal(originalFileKey);

  //         const originalFile = await dispatch(getPDFFile(originalFileKey));

  //         const file = await getFileFromUrl(originalFile, originalFileKey);

  //         const pdfArrayBuffer = await readFileAsync(file);
  //         const loadingTask = pdfjs.getDocument(pdfArrayBuffer);

  //         const pdfDoc = await loadingTask.promise;

  //         const images = await convertPDFToImage({ pdfDoc, scale: 1 });
  //         setPdfImages(images);

  //         const splitedHistory = await dispatch(
  //           getPDFHistory(originalId, 'limit=100')
  //         );
  //         const splited = splitedHistory.data
  //           .map((item) => item.splitParams)
  //           .flat();

  //         setSelectedPages(splited);
  //       }
  //       setShowSpinner(false);
  //       // console.log('🚀 ~ file: index.js ~ line 175 ~ history', history);
  //     }
  //   })();
  // }, []);

  const {
    getRootProps,
    getInputProps,
    // fileRejections,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    onDrop,
  });
  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const onChangeEditPDFInput = (e) => {
    setEditPDFField(e.target.value);
  };

  const onClickEditPDFField = async () => {
    setTableLoading(true);
    handleClose();
    if (editPDFField === '') return;

    const editOriginFileNameResponse = await dispatch(
      renameOriginalFile(selectedRowOId, `${editPDFField}.pdf`)
    );

    setTableData((prev) => {
      const prevCloned = [...prev];

      const foundIndex = prevCloned.findIndex(
        (i) => i._id === editOriginFileNameResponse.data.data._id
      );

      if (foundIndex === -1) return prevCloned;

      prevCloned[foundIndex] = {
        ...prevCloned[foundIndex],
        customFileName: editOriginFileNameResponse.data.data.customFileName,
      };

      return prevCloned;
    });

    setTableLoading(false);
  };

  const columns = [
    {
      title: t('pdf_purchase.created_on'),
      field: 'created_at',
      // eslint-disable-next-line react/prop-types
      render: ({ created_at: createdAt }) =>
        `${new Date(createdAt).toLocaleDateString()} - ${new Date(
          createdAt
        ).toLocaleTimeString()}`,
      sorting: false,
    },
    {
      title: t('pdf_purchase.file_name'),
      field: 'originalFileKey',
      // render: ({ type }) => renderType(type),
      sorting: false,
      render: ({ customFileName, originalFileKey }) =>
        customFileName || originalFileKey,
    },
    {
      title: t('pdf_purchase.status'),
      sorting: false,
      // eslint-disable-next-line react/prop-types, react/display-name
      render: ({ usedPages, numberOfPages, markAsDone }) => {
        const getBadgeStatus = () => {
          if (markAsDone)
            return <Chip label={t('pdf_purchase.done')} color="primary" />;

          if (!usedPages || !numberOfPages)
            return <Chip label={t('pdf_purchase.no_status')} />;
          // eslint-disable-next-line react/prop-types, react/display-name
          if (numberOfPages === usedPages?.length)
            return <Chip label={t('pdf_purchase.done')} color="primary" />;

          return (
            <Chip label={t('pdf_purchase.in_progress')} color="secondary" />
          );
        };

        return getBadgeStatus();
      },
    },

    // {
    //   title: 'Number of extraction',
    //   field: 'history',
    //   render: ({ history: originalHistory }) => originalHistory.length,
    //   sorting: false,
    // },

    {
      title: ' ',
      field: 'id',
      sorting: false,
      // eslint-disable-next-line react/prop-types, react/display-name
      render: ({ id }) => (
        <div style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
          <RenameIcon sm onClick={(e) => handleOpenRenamePopover(e, id)} />
          <Popover
            id={id}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div style={{ padding: 12, display: 'flex', gap: 20 }}>
              <TextField
                variant="outlined"
                label={t('pdf_purchase.rename')}
                size="small"
                onChange={onChangeEditPDFInput}
              />
              <IconButton
                size="small"
                aria-label="rename"
                onClick={() => {
                  onClickEditPDFField();
                }}
              >
                <Check />
              </IconButton>
            </div>
          </Popover>
          <ExtractPDFIcon
            sm
            onClick={() => {
              history.push(`/pdf/split?id=${id}`);
            }}
          />
          <IconSee
            sm
            onClick={() => {
              history.push(`/pdf/item/${id}`);
            }}
          />
        </div>
      ),
    },
  ];

  const onSelectPage = (indice) => {
    const indiceIdx = selectedIndices.indexOf(indice + 1);
    if (indiceIdx !== -1) {
      setSelectedIndices(selectedIndices.filter((i) => i !== indice + 1));
    } else {
      setSelectedIndices([...selectedIndices, indice + 1]);
    }
  };

  const renderToolbar = () => (
    <Grid
      container
      alignItems="center"
      style={{
        display: 'flex',
        justifyContent: 'end',
        marginTop: 10,
        gap: 10,
        padding: '10px 0',
      }}
    >
      <Button startIcon={<Clear />} onClick={() => clearSelectedFile()}>
        {t('cancel')}
      </Button>
      <Button
        startIcon={<Refresh />}
        onClick={() => clearSelectedPages()}
        disabled={selectedIndices.length < 1}
      >
        {t('pdf_purchase.deselect_all')}
      </Button>
      <Divider orientation="vertical" flexItem />
      <Button
        startIcon={<Archive />}
        disabled={isExtracting}
        onClick={() => onExtract(selectedIndices.length)}
      >
        {t('pdf_purchase.save_draft')}
      </Button>
      <Button
        variant="contained"
        color="primary"
        startIcon={<Save />}
        onClick={() => onExtract()}
        disabled={selectedIndices.length === 0}
      >
        {t('pdf_purchase.extract')}
      </Button>
    </Grid>
  );

  const renderDragNDropZone = () => {
    if (extractedImages && extractedImages.length > 0) {
      return extractedImages.map((image, index) => {
        const indice = index + 1;
        return (
          <div className={styles['extract-item']} key={index}>
            <img
              key={index}
              style={{
                width: '100%',
                height: '100%',
              }}
              src={image}
              alt="preview"
            ></img>
            <div className={styles.indice}>{indice}</div>
          </div>
        );
      });
    }

    if (pdfImages && pdfImages.length > 0) {
      return pdfImages.map((image, index) => {
        const indice = index + 1;
        const disable =
          selectedPages && selectedPages.find((item) => item === indice);
        return (
          <div
            // style={{ cursor: disable ? 'not-allowed' : 'pointer' }}
            style={{ cursor: 'pointer' }}
            className={
              selectedIndices.find((selected) => selected === indice) === indice
                ? styles['split-item-active']
                : styles['split-item']
            }
            key={index}
            onClick={() => {
              // if (!disable) {
              //   onSelectPage(index);
              // }
              onSelectPage(index);
            }}
          >
            <img
              key={index}
              style={{
                width: '100%',
                height: '100%',
                ...(disable
                  ? {
                      objectFit: 'cover',
                      opacity: '0.4',
                    }
                  : {}),
              }}
              src={image}
              alt="preview"
            ></img>
            <div className={styles.indice}>{indice}</div>
          </div>
        );
      });
    }

    return (
      <div className={styles.dropzone}>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} accept="application/pdf" />
          <CloudUpload style={{ fontSize: 80 }} />
          <h2>{t('pdf_purchase.drag_and_drop_to_upload_file')}</h2>
          <div style={{ marginBottom: 12, fontSize: 15 }}>
            {t('pdf_purchase.or')}
          </div>
          <Button
            className={styles['upload-button']}
            style={{ background: 'white' }}
            variant="contained"
            component="span"
          >
            <span style={{ color: '#5257ad' }}>
              {t('pdf_purchase.browse_file')}
            </span>
          </Button>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    return showSpinner ? (
      <CircularProgressCentered />
    ) : (
      <>
        {pdf && renderToolbar()}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              gap: '20px 100px',
              maxHeight: 400,
              overflow: 'auto',
              border: '1px solid #dddddd',
              borderRadius: 16,
              padding: pdfImages && pdfImages.length > 0 ? '20px 0' : 0,
            }}
          >
            {renderDragNDropZone()}
          </div>
          <MaterialTable
            title={t('pdf_purchase.global_files')}
            tableRef={tableRef}
            columns={columns}
            data={
              tableData
              // (query) => {
              //   console.log('🚀 ~ query', query);

              //   return new Promise((resolve) => {
              //     dispatch(getAllPDFOriginal(query)).then((res) => {
              //       resolve({ ...res });
              //     });
              //   });
              // }
            }
            isLoading={tableLoading}
            totalCount={tableTotal}
            onChangePage={(p) => setTablePage(p)}
            page={tablePage}
            onChangeRowsPerPage={(perPage) => setTableRowPerPage(perPage)}
            pageSize={tableRowPerPage}
            options={{ pageSizeOptions: [] }}
          />
        </div>
      </>
    );
  };

  const goBack = () => {
    history.push({
      pathname: state?.goBackPath || '/purchases/list',
      search: state?.goBackQueryParams,
      state: {
        pageTitle: state?.pageTitle,
      },
    });
  };

  return (
    <Layout
      header={
        <Header
          name={t('pdf_purchase.import_global_file')}
          goBack={goBack}
          spaceBetween
        />
      }
      // sidebarLeft={true}
      // sidebarRight={renderSidebarRight()}
      body={renderBody()}
      showUserCard={true}
    />
  );
};

export default PDFContainer;
