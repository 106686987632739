import React, { useState, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import AppSelect from '../../AppSelect';
import AppTextInputSecondary from '../../AppTextInputSecondary';
import AppSelectItem from '../../AppSelectItem';
import style from '../../../assets/jss/components/appClientsDropdown';
import { mGreyText } from '../../../assets/jss/components/appText';

const AppClientsDropdown = ({
  clients,
  chosenClient,
  setFieldValue,
  t,
  classes,
  showError,
  errorMessage,
  mandatory,
}) => {
  const [searchedClient, onSearch] = useState('');
  const [selectedClient, selectClient] = useState('');
  const [clientData, setData] = useState(chosenClient);

  const containerClasses = classNames({
    [classes.container]: true,
    [classes.errorButton]: showError,
  });

  useEffect(() => setData(chosenClient), [chosenClient]);

  const useClients =
    searchedClient !== ''
      ? _.filter(clients, (client) =>
          client.name
            .toString()
            .toLowerCase()
            .includes(searchedClient.toLowerCase())
        )
      : clients;

  function selectClientAndSet(e) {
    setFieldValue('client', clients[e], false);

    selectClient(e);
  }

  return (
    <div>
      <div className={containerClasses}>
        <AppSelect
          helperText="ela"
          name="choseClient"
          color="secondaryGrey"
          label={t('invoices.add_client')}
          fullWidth={true}
          noBorder={true}
          maxHeight="maxHeightM"
          valueSelected={selectedClient}
          onClick={(e) => selectClientAndSet(e.target.value)}
          values={clients.data}
          textAfterSelected={t('invoices.add_another_client')}
          heigherIndex
          mandatory={mandatory}
          errorMessage={errorMessage}
          showError={showError}
          noErrowBorder
        >
          <AppTextInputSecondary
            placeholder={t('search.client')}
            fullWidth={true}
            value={searchedClient}
            onChange={(e) => onSearch(e.target.value)}
            withSearchIcon
            color="secondary"
          />
          {_.map(
            useClients,
            (client) =>
              !client.deleted && (
                <AppSelectItem
                  value={client._id}
                  text={
                    client.email
                      ? `${client.name} - ${client.email}`
                      : client.name
                  }
                  key={client._id}
                />
              )
          )}
        </AppSelect>
        {clientData && clientData.isCompany ? (
          <div
            key={clientData._id}
            style={{ ...mGreyText, ...{ paddingLeft: '15px' } }}
          >
            <p>{clientData.name}</p>
            <p>
              {clientData.email
                ? `${clientData.contact} - ${clientData.email}`
                : clientData.contact}
            </p>
            <p>
              {clientData.address} {clientData.zipcode} {clientData.city}
            </p>
          </div>
        ) : (
          clientData && (
            <div
              key={clientData._id}
              style={{ ...mGreyText, ...{ paddingLeft: '15px' } }}
            >
              <p>
                {clientData.title} {clientData.first_name} {clientData.name}
              </p>
              <p>{clientData.address}</p>
              <p>
                {clientData.zipcode} {clientData.city} {clientData.country}
              </p>
            </div>
          )
        )}
      </div>

      {clientData && (
        <p className={classes.mGreyText}>{t('invoices.client_helper_text')}</p>
      )}
    </div>
  );
};

AppClientsDropdown.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  clients: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  chosenClient: PropTypes.object,
  t: PropTypes.func,
  showError: PropTypes.bool,
  mandatory: PropTypes.bool,
  errorMessage: PropTypes.string,
};
AppClientsDropdown.defaultProps = {
  chosenClient: null,
  showError: false,
  mandatory: false,
  errorMessage: null,
};

const AppClientsDropdownT = withTranslation()(AppClientsDropdown);

export default withStyles(style)(AppClientsDropdownT);
