import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';
import '../../assets/css/icons.css';
import { titleText } from '../../assets/jss/components/appText';

class CustomGenericDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: true };
  }

  _renderButtons() {
    const { showButtons, onConfirm, onCancel, confirmText, cancelText } =
      this.props;

    if (showButtons) {
      return (
        <DialogActions>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              width: '100%',
              alignItems: 'flex-start',
              justifyContent: 'space-between',
            }}
          >
            <Button onClick={onCancel} color="primary">
              {cancelText}
            </Button>
            <Button onClick={onConfirm} color="primary">
              {confirmText}
            </Button>
          </div>
        </DialogActions>
      );
    }

    return null;
  }

  render() {
    const {
      title,
      contentText,
      children,
      style,
      stylePaper,
      closeDialog,
      maxWidth,
      iconClose,
      DialogContentStyle,
    } = this.props;

    return (
      <Dialog
        style={style}
        open={this.state.open}
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        // onClose={closeDialog}
        maxWidth={maxWidth}
        scroll="paper"
        PaperProps={{
          style: stylePaper,
        }}
      >
        {title && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              paddingRight: '25px',
              paddingTop: '15px',
              paddingLeft: '25px',
            }}
          >
            <p style={titleText}>{title}</p>
            {iconClose && (
              <div
                className="icon icon-close"
                style={{ color: '#888888', cursor: 'pointer' }}
                onClick={closeDialog}
              />
            )}
          </div>
        )}

        <DialogContent
          style={{
            display: 'block',
            width: '100%',
            overflow: 'auto',
            ...DialogContentStyle,
          }}
        >
          <div style={{ width: '100%' }}>
            {contentText && (
              <DialogContentText>{contentText}</DialogContentText>
            )}
            {children}
            {this._renderButtons()}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}

CustomGenericDialog.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  confirmText: PropTypes.string,
  maxWidth: PropTypes.string,
  cancelText: PropTypes.string,
  showButtons: PropTypes.bool,
  iconClose: PropTypes.bool,
  style: PropTypes.object,
  stylePaper: PropTypes.object,
  closeDialog: PropTypes.func,
  DialogContentStyle: PropTypes.object,
};

CustomGenericDialog.defaultProps = {
  maxWidth: 'sm',
};

export default CustomGenericDialog;
