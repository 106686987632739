import service from 'services/RevenueCategoriesService';

import { selectLoggedUserCompanyId } from 'selectors/loggedUserCompanySelectors';

import { setGlobalError } from 'actions/GlobalError';

import {
  FETCHING_REVENUE_CATEGORIES,
  FETCH_REVENUE_CATEGORIES_FAILED,
  FETCH_REVENUE_CATEGORIES_SUCCESS,
} from 'actions/Types';

export const fetchingRevenueCategories = () => ({
  type: FETCHING_REVENUE_CATEGORIES,
});

export const fetchRevenueCategoriesSuccess = (categories) => ({
  type: FETCH_REVENUE_CATEGORIES_SUCCESS,
  payload: { categories },
});

export const fetchRevenueCategoriesFailure = (error) => ({
  type: FETCH_REVENUE_CATEGORIES_FAILED,
  payload: { error },
});

export const fetchRevenueCategories = () => async (dispatch, getState) => {
  dispatch(fetchingRevenueCategories());
  try {
    const companyId = selectLoggedUserCompanyId(getState());

    if (companyId) {
      const result = await service.fetchAll({
        company_id: companyId,
      });

      dispatch(fetchRevenueCategoriesSuccess(result.data));
    } else {
      dispatch(fetchRevenueCategoriesSuccess([]));
    }
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch(fetchRevenueCategoriesFailure(error));
  }
};
