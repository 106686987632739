import React, { useState } from 'react';
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import { Close, Search } from '@material-ui/icons';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AppSearchInput = (props) => {
  const [searchText, setSearchText] = useState('');
  const { queryFunction, label, placeholder } = props;
  const { t } = useTranslation();

  return (
    <TextField
      onKeyDown={(e) => {
        if (e.key === 'Enter') {
          queryFunction(searchText);
        }
      }}
      onChange={(e) => setSearchText(e.target.value)}
      value={searchText}
      placeholder={placeholder || t('tables.search')}
      label={label}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton
              onClick={() => {
                queryFunction(searchText);
              }}
            >
              <Search />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              onClick={() => {
                setSearchText('');
                queryFunction('');
              }}
            >
              <Close />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
};

AppSearchInput.propTypes = {
  queryFunction: PropTypes.func,
  label: PropTypes.string,
  placeholder: PropTypes.string,
};

export default AppSearchInput;
