import React from 'react';
import AppDialog from 'components/AppDialog';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import {
  DISCOVERY_PACKAGE,
  EFFICIENCY_FORMULA,
  STANDARD_FORMULA,
} from 'constants/subscriptions';

const ApproveDialog = ({
  toggleDialog,
  handleApprove,
  companySubscription,
}) => {
  const { t } = useTranslation();

  let cost;
  switch (companySubscription.name) {
    case DISCOVERY_PACKAGE:
      cost = 2;
      break;
    case STANDARD_FORMULA:
      cost = 1.25;
      break;
    case EFFICIENCY_FORMULA:
      cost = 1;
      break;
    default:
      // eslint-disable-next-line no-unused-vars
      cost = 0;
      break;
  }

  const contentApproveDialog =
    t('invoice.additional_payment_link_message_1') +
    cost +
    t('invoice.additional_payment_link_message_2');
  return (
    <AppDialog
      sm
      iconClose
      footer
      title={t('sure')}
      closeDialog={toggleDialog}
      onConfirmText={t('accept')}
      onCancelText={t('cancel')}
      onConfirm={handleApprove}
      contentText={contentApproveDialog}
      color="secondary"
      setWidthManualy="500px"
      setHeightManualy="200px"
    />
  );
};

ApproveDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  handleApprove: PropTypes.func.isRequired,
  companySubscription: PropTypes.object,
};
export default ApproveDialog;
