/* Packages */
import React from 'react';
import { useTranslation } from 'react-i18next';

/* Components */
import Header from 'components/Header';
import Layout from 'components/Layout';

/* Local Components */
import BankBBFAccountsBody from '../../components/BankBBFAccountsBody';

const BankBBFAccounts = () => {
  const { t } = useTranslation();

  return (
    <Layout
      header={<Header name={t('Banque')} goBack={null} />}
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={<BankBBFAccountsBody />}
    />
  );
};

export default BankBBFAccounts;
