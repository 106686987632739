import { authInstance as axios } from 'helpers/axiosInterceptor';

// Create Payin (Cheque)
export const createPayin = (data) =>
  axios.post(`${process.env.REACT_APP_TREEZOR_URL}/payins`, data);

export const getChequePayins = (walletId, queryString) =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/cheque-payins/${walletId}?${queryString}`
  );
