import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CustomGenericDialog from '../../../components/CustomGenericDialog';
import Form from '../Form';

const ClientFormDialog = ({
  showDialog,
  selectedClientId,
  closeClientFormDialog,
  clients,
  updateClientImport,
}) => {
  const { t } = useTranslation();
  if (showDialog) {
    return (
      <CustomGenericDialog
        title={
          selectedClientId
            ? t('settings_section.clients.dialog_title_edit')
            : t('settings_section.clients.dialog_title_create')
        }
        iconClose
        closeDialog={closeClientFormDialog}
      >
        <Form
          id={selectedClientId}
          closeDialog={closeClientFormDialog}
          clientImport={clients}
          updateClientImport={updateClientImport}
        />
      </CustomGenericDialog>
    );
  }

  return null;
};

ClientFormDialog.propTypes = {
  showDialog: PropTypes.bool,
  selectedClientId: PropTypes.string,
  openClientFormDialog: PropTypes.func,
  closeClientFormDialog: PropTypes.func,
  clients: PropTypes.array,
  updateClientImport: PropTypes.func,
};

export default ClientFormDialog;
