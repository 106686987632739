import React, { useRef, useEffect } from 'react';
import { useRouter } from 'hooks/routerHooks';
import { useSelector, useDispatch } from 'react-redux';
import { TreezorDocumentTypes } from '@bebusinessfocus/compta-hub-core';
import personelTitle from 'assets/data/personelTitle.json';
import countryList from 'assets/data/countryList.json';
import { useParams } from 'react-router-dom';
import { onBoardingTreezorUser } from 'actions/UserTreezorActions';
import { prepareUsers } from 'helpers/TreezorHelper';
import PrepareFormContainer from 'containers/CardContainers/Treezor/userFormContainer/prepareFormContainer';
import { initialValues } from './initialValues';
import { prepareData } from './handleSubmit';
import { validationSchema } from './validation';

const NewUserAfterOnboardingContainer = () => {
  const init = useRef(true);
  const { history } = useRouter();
  const { loggedUserCompany, users, treezor } = useSelector(
    (reduxState) => reduxState
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const { companyType } =
    loggedUserCompany?.company?.settings?.tax_registration;

  const onSubmit = (user) => {
    if (treezor.treezorUser) {
      const { userId } = treezor.treezorUser;
      const company = {
        userId,
      };
      dispatch(
        onBoardingTreezorUser(prepareUsers([user], companyType)[0], company)
      );
    }
  };

  useEffect(() => {
    if (init.current) {
      init.current = false;
    } else {
      history.push('/cards'); // redirect after the treezorUser has been updated
    }
  }, [treezor.treezorUsers]);

  const goBack = () => {
    history.push('/cards');
  };

  return (
    <PrepareFormContainer
      company={loggedUserCompany.company}
      identityList={TreezorDocumentTypes.identityPrimaryList}
      countryList={countryList}
      personelTitle={personelTitle}
      identityList2={TreezorDocumentTypes.identitySecondaryList}
      proofOfAddress={TreezorDocumentTypes.proofOfAddress}
      newUser={users[id]}
      onSubmit={onSubmit}
      initialValues={initialValues}
      prepareData={prepareData}
      validationSchema={validationSchema}
      goBackFuntion={goBack}
    />
  );
};

export default NewUserAfterOnboardingContainer;
