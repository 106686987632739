import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const style = {
  border: '1px solid rgb(142 145 150 / 76%)',
  textAlign: 'center',
  marginBottom: '15px',
  cursor: 'pointer',
  borderRadius: '30px',
  minHeight: '50px',
  fontWeight: 'bold',
  backgroundColor: '#ffff',
  padding: '15px',
  height: 'fit-content',
};

const Card = ({ values, handleSelected, styles, index }) => {
  const { t } = useTranslation();
  return (
    <button
      style={{ ...style, ...styles }}
      onClick={() => handleSelected(values.value, index)}
    >
      {t(values.value)}
    </button>
  );
};

Card.propTypes = {
  values: PropTypes.object,
  handleSelected: PropTypes.func,
  styles: PropTypes.object,
  index: PropTypes.number,
};

export default Card;
