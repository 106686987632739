/* Packages */
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PropTypes from 'prop-types';
import qs from 'qs';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from '@material-ui/core';

/* Components */
import Header from 'components/Header';
import Layout from 'components/Layout';
import BankBBFValidatedRequestTable from 'containers/BankBBF/components/BankBBFPayoutValidatedRequestTable';
import BankBBFPayoutPendingRequestTable from 'containers/BankBBF/components/BankBBFPayoutPendingRequestTable';
import DeletePendingPayoutConfirmDialog from 'containers/BankBBF/components/DeletePendingPayoutConfirmDialog';

/* Icons */
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import HourglassFullIcon from '@material-ui/icons/HourglassFull';

/* Actions */
import {
  deletePendingPayoutRequestAction,
  getPayoutRequestAction,
} from 'actions/WalletActions';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const BankBBFDeletePayout = () => {
  const tablePendingRef = useRef();
  const tableValidatedRef = useRef();
  const { t } = useTranslation();

  const [tableTab, setTableTab] = useState(0);
  const [confirmDialogHandler, setConfirmDialogHandler] = useState({
    isOpen: false,
    description: {},
    payoutId: null,
    isLoading: false,
  });
  const [snackBarHandler, setSnackBarHandler] = useState({
    isOpen: false,
    status: 'success',
    description: '',
  });

  const treezorWallet = useSelector((state) =>
    state.wallet && state.wallet.wallet ? state.wallet.wallet[0] : null
  );

  const getRequestData = async (queryTable, requestType) => {
    const parseObjectToQueryString = qs.stringify({
      ...queryTable,
      walletId: treezorWallet.walletId,
      payoutStatus: requestType,
      page: queryTable.page + 1,
    });

    const response = await getPayoutRequestAction(parseObjectToQueryString);

    return {
      data: response.data.docs,
      totalCount: response.data.totalDocs,
      page: response.data.page - 1,
    };
  };

  const handleChangeTableTab = (e, tabIndex) => {
    setTableTab(tabIndex);
  };

  const handleCloseConfirmDialog = () =>
    setConfirmDialogHandler({
      isOpen: false,
      isLoading: false,
      render: null,
      payoutId: null,
      title: '',
    });

  const handleOpenConfirmDialog = ({ payoutId, description }) => {
    setConfirmDialogHandler({
      payoutId,
      description,
      isOpen: true,
    });
  };

  const handleCloseSnackbar = () => {
    setSnackBarHandler({
      ...snackBarHandler,
      isOpen: false,
    });
  };

  const handleOpenSnackbar = (status, description) => {
    setSnackBarHandler({
      isOpen: true,
      description,
      status,
    });
  };

  const handleConfirmDialogLoading = (isLoading) =>
    setConfirmDialogHandler({
      ...confirmDialogHandler,
      isLoading,
    });

  const handleDeletePendingPayout = async ({ reason }) => {
    try {
      handleConfirmDialogLoading(true);

      const parseObjectToQueryString =
        reason &&
        qs.stringify({
          deleteReason: reason,
        });

      const response = await deletePendingPayoutRequestAction(
        confirmDialogHandler.payoutId,
        parseObjectToQueryString
      );

      if (response) {
        handleConfirmDialogLoading(false);
        handleCloseConfirmDialog();
        handleOpenSnackbar('success', 'Delete successfully');
      }

      tablePendingRef.current?.onQueryChange();
    } catch (error) {
      handleConfirmDialogLoading(false);
      handleCloseConfirmDialog();
      handleOpenSnackbar('error', error[0]?.errorMessage);
    }
  };

  return (
    <SetupLayout>
      <div>
        <Tabs
          value={tableTab}
          onChange={handleChangeTableTab}
          indicatorColor="primary"
          textColor="primary"
          centered
        >
          <Tab
            label={t('bank_bbf.pending_payout')}
            icon={<HourglassFullIcon />}
          />
          <Tab
            label={t('bank_bbf.validated_payout')}
            icon={<CheckCircleIcon />}
          />
        </Tabs>
        {treezorWallet?.walletId && tableTab === 0 && (
          <BankBBFPayoutPendingRequestTable
            getRequestData={getRequestData}
            handleDeletePendingPayout={handleDeletePendingPayout}
            handleOpenConfirmDialog={handleOpenConfirmDialog}
            ref={tablePendingRef}
          />
        )}

        {treezorWallet?.walletId && tableTab === 1 && (
          <BankBBFValidatedRequestTable
            getRequestData={getRequestData}
            ref={tableValidatedRef}
          />
        )}

        {/* Snackbar */}
        <Snackbar
          open={snackBarHandler.isOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        >
          <Alert
            onClose={handleCloseSnackbar}
            severity={snackBarHandler.status}
          >
            {snackBarHandler.description}
          </Alert>
        </Snackbar>

        {/* Delete Confirm Dialog */}
        <DeletePendingPayoutConfirmDialog
          isLoading={confirmDialogHandler.isLoading}
          open={confirmDialogHandler.isOpen}
          onClose={handleCloseConfirmDialog}
          description={confirmDialogHandler.description}
          title={confirmDialogHandler.title}
          onConfirm={handleDeletePendingPayout}
        />
      </div>
    </SetupLayout>
  );
};

const SetupLayout = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Layout
      header={
        <Header
          name={t('bank_bbf.delete_payout')}
          goBack={() => history.goBack()}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={children}
    />
  );
};

SetupLayout.propTypes = {
  children: PropTypes.any,
};

export default BankBBFDeletePayout;
