import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchTemplates = async (filter) =>
  axios.get(`${process.env.REACT_APP_TEMPLATE_URL}/templates`, {
    params: filter,
  });

const fetchTemplate = async (id) =>
  axios.get(`${process.env.REACT_APP_TEMPLATE_URL}/templates/${id}`);

const createTemplate = async (data) =>
  axios.post(`${process.env.REACT_APP_TEMPLATE_URL}/templates`, data);

const updateTemplate = async (id, data) =>
  axios.put(`${process.env.REACT_APP_TEMPLATE_URL}/templates/${id}`, data);

const deleteTemplate = async (id) =>
  axios.delete(`${process.env.REACT_APP_TEMPLATE_URL}/templates/${id}`);

export default {
  fetchTemplates,
  fetchTemplate,
  createTemplate,
  updateTemplate,
  deleteTemplate,
};
