import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';

const WalletDeleteConfirmDialog = (props) => {
  const { onClose, open, handleCloseTreezorWallet, loadingConfirmDialog } =
    props;

  const { t } = useTranslation();

  const [confirmTextValue, setConfirmTextValue] = useState('');

  const onChangeConfirmTextValue = (e) => {
    setConfirmTextValue(e.target.value);
  };

  const onClickConfirm = () => {
    setConfirmTextValue('');
    handleCloseTreezorWallet();
  };

  const handleCloseDialog = () => {
    setConfirmTextValue('');
    if (loadingConfirmDialog) return;
    onClose();
  };

  const isCorrectConfirmWord = Boolean(confirmTextValue === 'DELETE');

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle style={{ color: '#3d319d', fontWeight: 'bold' }}>
        {t('close_wallet.close_wallet')}
      </DialogTitle>
      <DialogContent>
        <Box style={{ paddingBottom: '15px' }}>
          <Typography style={{ fontSize: '16px' }}>
            {t('close_wallet.dialog.content-1')}
          </Typography>
          <Typography style={{ fontSize: '16px' }}>
            {t('close_wallet.dialog.content-2')}
          </Typography>
        </Box>
        <Box style={{ width: '100%' }}>
          <TextField
            value={confirmTextValue}
            label={t('close_wallet.dialog.text-field-confirm')}
            fullWidth={true}
            variant="outlined"
            onChange={onChangeConfirmTextValue}
            disabled={loadingConfirmDialog}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCloseDialog}>
          {t('close_wallet.dialog.cancel')}
        </Button>
        <Tooltip
          title={
            !isCorrectConfirmWord
              ? t('close_wallet.dialog.text-field-confirm')
              : ''
          }
          placement="top"
        >
          <span>
            <Button
              onClick={onClickConfirm}
              variant="contained"
              disabled={!isCorrectConfirmWord}
              color="primary"
              startIcon={
                loadingConfirmDialog && (
                  <CircularProgress size={16} color="inherit" />
                )
              }
            >
              {t('close_wallet.dialog.confirm')}
            </Button>
          </span>
        </Tooltip>
      </DialogActions>
    </Dialog>
  );
};

WalletDeleteConfirmDialog.propTypes = {
  open: PropTypes.bool,
  loadingConfirmDialog: PropTypes.bool,
  onClose: PropTypes.func,
  handleCloseTreezorWallet: PropTypes.func,
};

export default WalletDeleteConfirmDialog;
