import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';

import Layout from '../../../components/Layout';
import {
  AppTable,
  AppTableCell,
  AppTableHead,
  AppTableBody,
  AppTableRow,
} from '../../../components/AppTableComponents';

import { IconEdit, IconRefresh } from '../../../components/AppIcons';
import AppPlusButton from '../../../components/AppPlusButton';
import AppDialog from '../../../components/AppDialog';
import Header from '../../../components/Header';

import Form from '../Form';

import state from './state';
import dispatch from './dispatch';

import styles from '../../../assets/jss/root';

export class InternalActivitiesList extends Component {
  constructor(props) {
    super(props);

    this.state = { showDialog: false, selected: null };
  }

  _editState(showDialog, selected) {
    this.setState({ showDialog, selected });
  }

  _renderFormDialog() {
    const { t } = this.props;
    const { showDialog, selected } = this.state;

    if (showDialog) {
      return (
        <AppDialog
          title={t('settings_section.internal_activity.dialog_title')}
          closeDialog={() => this._editState(false)}
          onConfirmType="submit"
          onConfirmText="submit"
          iconClose
          color="secondary"
        >
          <Form id={selected} closeDialog={() => this._editState(false)} />
        </AppDialog>
      );
    }

    return null;
  }

  _renderListData() {
    const { activities } = this.props;

    return _.map(activities, (activity) => (
      <AppTableRow key={activity._id}>
        <AppTableCell>{activity.value}</AppTableCell>
        <AppTableCell>{activity.display}</AppTableCell>
        <AppTableCell>{activity.description}</AppTableCell>
        <AppTableCell>
          <IconEdit
            onClick={() => {
              this._editState(true, activity._id);
            }}
            sm
          />
        </AppTableCell>
      </AppTableRow>
    ));
  }

  _renderList() {
    const { t } = this.props;

    return (
      <>
        <AppTable>
          <AppTableHead>
            <AppTableRow>
              <AppTableCell>
                {t('settings_section.internal_activity.form_label_value')}
              </AppTableCell>
              <AppTableCell>
                {t('settings_section.internal_activity.table_name')}
              </AppTableCell>
              <AppTableCell>
                {t('settings_section.internal_activity.table_description')}
              </AppTableCell>
              <AppTableCell />
            </AppTableRow>
          </AppTableHead>
          <AppTableBody>{this._renderListData()}</AppTableBody>
        </AppTable>

        {this._renderFormDialog()}
      </>
    );
  }

  render() {
    const { t, fetchInternalActivities } = this.props;

    return (
      <Layout
        header={
          <Header
            name={t('settings_section.internal_activity.title')}
            spaceBetween
          >
            {' '}
            <IconRefresh onClick={fetchInternalActivities} />
            <AppPlusButton
              label={t('create')}
              onClick={() => this._editState(true)}
            />
          </Header>
        }
        sidebarLeft={true}
        sidebarRight={null}
        showUserCard={true}
        body={this._renderList()}
      />
    );
  }
}

InternalActivitiesList.propTypes = {
  classes: PropTypes.object.isRequired,
  t: PropTypes.func,
  history: PropTypes.object,
  activities: PropTypes.object,
  fetchInternalActivities: PropTypes.func,
};

const InternalActivitiesListWithStyle = withStyles(styles)(
  InternalActivitiesList
);
const RouterInternalActivitiesListWithStyle = withRouter(
  InternalActivitiesListWithStyle
);
const RouterInternalActivitiesListWithTranslate = withTranslation()(
  RouterInternalActivitiesListWithStyle
);

const RouterInternalActivitiesListWithConnect = connect(
  state,
  dispatch
)(RouterInternalActivitiesListWithTranslate);

export default RouterInternalActivitiesListWithConnect;
