import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect, useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
// HOC
import { Grid } from '@material-ui/core';
import { withUnmounted } from '../../../components/HighOrderComponents';
// components
import Layout from '../../../components/Layout';
import Header from '../../../components/Header';
import CategoryIcon from '../../../components/AppCategoryIcons';

import { AppDividerS, AppDividerColS } from '../../../components/AppDividers';
// dialog compoenents
import ClientDialog from '../../../components/ClientDialog';
import ProjectDialog from '../../../components/ProjectDialog';
import LoadingIndicatorDialog from '../../../components/LoadingIndicatorDialog';
// redux
import state from './state';

import {
  getExpenseTitle,
  getExpenseURL,
} from '../../../helpers/expenseHelpers';

const ExpensesPage = ({ t, history, isMounted, location }) => {
  const categories = useSelector((s) => s.categories.tree || []);
  const [showClientFormDialog, setClientFormDialog] = useState(false);
  const [showProjectFormDialog, setProjectFormDialog] = useState(false);
  const [showLoadingDialog, setShowLoadingDialog] = useState(true); // show loader by default

  // hide loader when categories are successfully fetched
  if (categories && !categories.loading && showLoadingDialog) {
    setShowLoadingDialog(false);
  }

  function toggleClientFormDialog() {
    if (isMounted) {
      setClientFormDialog(!showClientFormDialog);
    }
  }

  function toggleProjectFormDialog() {
    if (isMounted) {
      setProjectFormDialog(!showProjectFormDialog);
    }
  }

  function renderMainBody() {
    if (showLoadingDialog) {
      return (
        <LoadingIndicatorDialog
          open={showLoadingDialog}
          title={t('invoices.procesing')}
        />
      );
    }

    const isPurchase = window.location.pathname.includes('purchase');

    return (
      <>
        {showClientFormDialog && (
          <ClientDialog t={t} closeDialog={toggleClientFormDialog} />
        )}

        {showProjectFormDialog && (
          <ProjectDialog t={t} closeDialog={toggleProjectFormDialog} />
        )}

        {isPurchase === false ? (
          <Grid container spacing={3}>
            {categories
              .filter((category) => category.order === 0)
              .map((category) =>
                category.children.map((cat, index) =>
                  cat.travel &&
                  isPurchase &&
                  cat.travel.isTravelWithDistanceRate === true ? null : (
                    <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
                      <CategoryIcon
                        icon={cat.icon}
                        display={cat.display}
                        category={cat}
                        onSelect={(sel) =>
                          history.push(
                            `/${getExpenseURL(location.state.payment)}/new`,
                            {
                              payment: location.state.payment,
                              category: sel._id,
                            }
                          )
                        }
                      />
                      <AppDividerColS />
                    </Grid>
                  )
                )
              )}
          </Grid>
        ) : (
          ''
        )}
        <>
          <AppDividerS />

          {isPurchase === true ? (
            <Grid container spacing={3}>
              {categories
                .filter((category) => category.order > 0)
                .map((category, index) => (
                  <Grid item xs={12} sm={4} md={3} lg={3} key={index}>
                    <CategoryIcon
                      icon={category.icon}
                      display={category.display}
                      onSelect={(cat) =>
                        history.push(
                          `/${getExpenseURL(
                            location.state.payment
                          )}/invoice/new`,
                          {
                            category: cat._id,
                            payment: location.state.payment,
                          }
                        )
                      }
                      category={category}
                      disabled={!category.enabled}
                    />
                    <AppDividerColS />
                  </Grid>
                ))}
            </Grid>
          ) : (
            ''
          )}
        </>
      </>
    );
  }

  return (
    <Layout
      header={<Header name={t(getExpenseTitle(location.state.payment))} />}
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderMainBody()}
    />
  );
};

ExpensesPage.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isMounted: PropTypes.bool,
  location: PropTypes.object.isRequired,
};

ExpensesPage.defaultProps = {
  location: {
    state: {},
  },
};

const TranslatedExpensesPage = withTranslation()(ExpensesPage);

const TranslatedExpensesPageWithUmounted = withUnmounted(
  TranslatedExpensesPage
);

export default connect(state, null)(TranslatedExpensesPageWithUmounted);
