import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import NumberFormat from 'helpers/NumberFormat';

import Layout from 'components/Layout';

import MaterialTable from 'components/MaterialTable';

import { flatten } from 'lodash';

import {
  fetchClientProjects,
  deleteClientProject,
} from 'actions/ClientProjectActions';

import { AppRowStartStart } from 'components/AppContainers';
import { DeletePayoutIcon, IconEdit } from 'components/AppIcons';

import { setGlobalError } from 'actions/GlobalError';
import AppDialog from 'components/AppDialog';
import ProjectCreationModal from './createProjectModal';
import ProjectHeader from './header';
import HistoryChangeViewerModal from './historyChangeViewerModal';

const Projects = () => {
  // Get filter values from Localstorage and then remove it
  const filters = JSON.parse(localStorage.getItem('projectFilters'));
  localStorage.removeItem('projectFilters');

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(true);
  const [showHistoryViewer, setShowViewer] = useState(false);
  const [chosenHistory, setChosenHistory] = useState(null);
  const [showCreationDialog, setShowCreationDialog] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState({
    projectId: '',
    clientId: '',
  });
  const [editMode, setEditMode] = useState(false);
  const [searchParams] = useState(filters ? filters.search || '' : '');

  const company = useSelector((state) => state.loggedUserCompany.company);
  const clients = useSelector((state) => state.clients.data);
  const clientProjects = useSelector((state) => state.clientProjects.projects);

  const openCreationDialogHandler = () => {
    setSelectedProject({ ...selectedProject, projectId: '', clientId: '' });
    setEditMode(false);
    setShowCreationDialog(true);
  };

  const closeHistoryViewer = () => setShowViewer(false);
  const openHistoryViewer = (selectedHistory) => {
    setChosenHistory(selectedHistory);
    setShowViewer(true);
  };

  const closeConfirmDeleteDialogHandler = () => setShowConfirmDialog(false);

  const STATUS_STATE = [
    t('project.status_state.todo'),
    t('project.status_state.in_progress'),
    t('project.status_state.closed'),
  ];

  const fetchProjects = async () => {
    setIsLoading(true);
    try {
      await dispatch(fetchClientProjects());
    } catch (exception) {
      setGlobalError(exception);
    }
    setIsLoading(false);
  };

  const deleteProject = async (project) => {
    try {
      await dispatch(
        deleteClientProject(
          project.clientId,
          project.projectId,
          closeConfirmDeleteDialogHandler
        )
      );
      fetchProjects();
    } catch (exception) {
      dispatch(setGlobalError(exception, closeConfirmDeleteDialogHandler));
    }
  };

  const renderRevisedBudget = (budgetsRevised) => {
    let sumRevisedBudget = 0;
    if (budgetsRevised.length) {
      // get latest revised budget
      const { revisedHT, revisedTTC, revisedTVA } = budgetsRevised.at(-1);
      sumRevisedBudget =
        Number(revisedHT) + Number(revisedTTC) + Number(revisedTVA);
      return sumRevisedBudget;
    }

    return sumRevisedBudget;
  };

  const handleOnClose = (isFetchData) => {
    setShowCreationDialog(false);
    if (isFetchData) {
      fetchProjects();
    }
  };

  useEffect(() => {
    fetchProjects();
  }, []);

  const renderTable = () => {
    const columns = [
      {
        title: t('project.status'),
        field: 'status',
        sorting: false,
        render: ({ status }) => STATUS_STATE[status],
      },
      {
        title: t('project.name'),
        field: 'name',
        sorting: true,
      },
      {
        title: t('project.client'),
        field: 'client_id',
        sorting: false,
        // eslint-disable-next-line camelcase
        render: ({ client_id }) => clients[client_id].name,
      },
      {
        title: t('project.budget_table'),
        field: 'ht',
        sorting: false,
        render: ({ ht }) => NumberFormat.n(ht),
      },
      {
        title: t('project.budget_revised_table'),
        field: 'revisedHT',
        sorting: false,
        render: ({ budgetsRevised }) => renderRevisedBudget(budgetsRevised),
      },
      {
        title: t('project.estimated_cost'),
        field: 'estimatedCost',
        sorting: false,
        render: ({ estimatedCost }) => NumberFormat.n(estimatedCost),
      },
      {
        field: '_id',
        sorting: false,
        // eslint-disable-next-line react/display-name, camelcase, react/prop-types
        render: ({ _id, client_id }) => (
          <AppRowStartStart>
            <IconEdit
              sm
              onClick={() => {
                setSelectedProject({
                  ...selectedProject,
                  clientId: client_id,
                  projectId: _id,
                });
                setEditMode(true);
                setShowCreationDialog(true);
              }}
            />
            <DeletePayoutIcon
              sm
              onClick={() => {
                setSelectedProject({
                  ...selectedProject,
                  clientId: client_id,
                  projectId: _id,
                });
                setShowConfirmDialog(true);
              }}
            />
          </AppRowStartStart>
        ),
      },
    ];
    return (
      <MaterialTable
        title={t('project.header-title')}
        columns={columns}
        data={flatten(Object.values(clientProjects))}
        isLoading={isLoading}
        options={{
          searchText: searchParams,
        }}
      />
    );
  };

  const renderBody = () => {
    return (
      <div>
        <div>{renderTable()}</div>
        <ProjectCreationModal
          show={showCreationDialog}
          isEdit={editMode}
          onClose={(isFetchData) => {
            handleOnClose(isFetchData);
          }}
          clients={clients}
          selectedProject={selectedProject}
          companyId={company._id}
          onViewHistory={openHistoryViewer}
        />
        {showConfirmDialog && (
          <AppDialog
            sm
            title="Confirm to delete?"
            color="secondary"
            footer
            contentText="Do you want to delete this project"
            onConfirmText="Confirm"
            onConfirm={() => deleteProject(selectedProject)}
            onCancelText="Cancel"
            closeDialog={() => setShowConfirmDialog(false)}
          />
        )}
        <HistoryChangeViewerModal
          selectedHistory={chosenHistory}
          show={showHistoryViewer}
          onClosed={closeHistoryViewer}
        />
      </div>
    );
  };
  return (
    <Layout
      header={<ProjectHeader onCreateProject={openCreationDialogHandler} />}
      sidebarLeft={true}
      sidebarRight={null}
      body={renderBody()}
    />
  );
};

export default Projects;
