import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import VATLineGroup from 'components/VATLineGroup';
import { useTranslation } from 'react-i18next';
import { AppDividerL as Divider } from 'components/AppDividers';

import TextField from 'components/FormElements/textField';
import CurrencyTextField from 'components/FormElements/CurrencyTextField';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { getVatRatesByCategory } from 'helpers/VATRateHelpers';

const DetailsInfo = ({
  defaultTaxes,
  readOnly,
  isTravelWithDistanceRate,
  selectedCategory,
}) => {
  const { t } = useTranslation();
  const { values } = useFormikContext();
  const [filterVatRates, setFilterVatRates] = useState([]);
  const categories = useSelector((state) => state.categories.data);
  const fullVatRates = useSelector((state) => state.fullVatRates);
  const providers = useSelector((state) => state.providers);

  useEffect(() => {
    const currentCategory = categories[values.category_id];
    const filterVAT = getVatRatesByCategory(
      currentCategory,
      fullVatRates,
      providers[values?.provider]
    );
    setFilterVatRates(filterVAT);
  }, [values.category_id, values?.provider, providers]);

  return (
    <Fragment>
      <TextField
        required
        multiline
        helperText={
          selectedCategory && selectedCategory.helptext
            ? selectedCategory.helptext
            : ''
        }
        type="text"
        name={'description'}
        label={t('expenses.expense.form_description')}
        placeholder={t('expenses.expense.form_description')}
        fullWidth
      />
      <Divider style={{ margin: '10px 0' }} />
      {!isTravelWithDistanceRate && (
        <VATLineGroup
          readOnly={readOnly}
          vatRates={filterVatRates}
          defaultTaxes={defaultTaxes}
          categoryId={values.category_id}
        />
      )}
      {isTravelWithDistanceRate && (
        <CurrencyTextField
          type="number"
          step="0.01"
          label={t('expenses.expense.form_total_gross')}
          name={`grandTotalAmount`}
          value={values.grandTotalAmount}
          disabled={true}
          fullWidth
        />
      )}
    </Fragment>
  );
};

DetailsInfo.propTypes = {
  defaultTaxes: PropTypes.array,
  readOnly: PropTypes.bool,
  isTravelWithDistanceRate: PropTypes.bool,
  selectedCategory: PropTypes.object,
};

export default React.memo(DetailsInfo);
