export default [
  { type: 4, key: 'companyCredentials' },
  { type: 12, key: 'ProofAddress' },
  { type: 13, key: 'ProofAddress' },
  { type: 14, key: 'ProofAddress' },
  { type: 9, key: 'identity' },
  { type: 15, key: 'identity' },
  { type: 16, key: 'identity' },
  { type: 17, key: 'identity' },
];
