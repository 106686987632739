import { map } from 'lodash';

// eslint-disable-next-line import/prefer-default-export
export const getTemplatesAsArray = (state) => {
  if (state && state.templates && state.templates !== {}) {
    return map(state.templates, (template) => template);
  }

  return [];
};
