/* Packages */
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import React from 'react';
import PropTypes from 'prop-types';

/* Icons */
import LocalAtmIcon from '@material-ui/icons/LocalAtm';

/* Local Components */
import ShowHideVisibilityIcon from '../ShowHideVisibilityIcon';

/* Styles */
import useStyles from './styles';

function BankBBFAccountBalance(props) {
  const { treezorWallet, hide, handleChangeHideField } = props;

  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Box className={classes.accountBalanceWrapper}>
      <LocalAtmIcon
        className={classes.accountBalanceWrapperIcon}
        color="primary"
      />
      <Typography
        variant="h2"
        color="primary"
        className={classes.accountBalanceWrapperTitle}
      >
        {t('bank_bbf.balance')}
      </Typography>
      <Box className={classes.spacer}></Box>

      <Typography variant="h2" className={classes.accountBalanceWrapperTitle}>
        {!hide ? `${treezorWallet?.solde || 0}  €` : '*****'}
      </Typography>
      <ShowHideVisibilityIcon
        hide={hide}
        handleChangeHideField={handleChangeHideField}
      />
    </Box>
  );
}

BankBBFAccountBalance.propTypes = {
  treezorWallet: PropTypes.object,
  hide: PropTypes.bool,
  handleChangeHideField: PropTypes.func,
};

export default BankBBFAccountBalance;
