import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';

import { isObject } from 'lodash';

// components
import Layout from 'components/Layout';
import LoadingDialog from 'components/LoadingDialog';
import ChooseCompany from 'components/ChooseCompany';

import logger from 'helpers/logger';

import styles from 'assets/jss/root';

import state from './state';
import dispatch from './dispatch';

class PrivateRoute extends Component {
  constructor(props) {
    super(props);
    this.state = {
      screenName: '',
      loading: true,
      dataFetched: props.loggedUserCompany.company,
    };

    this._onTokenRefresh = this._onTokenRefresh.bind(this);
    this._toggleLoading = this._toggleLoading.bind(this);
    this._onCompanySelected = this._onCompanySelected.bind(this);
  }

  _toggleLoading() {
    this.setState({ loading: !this.state.loading });
  }

  _onTokenRefresh(error) {
    if (error) {
      logger.warn('_onTokenRefresh received error', { error });
      this.props.fetchCompanyUserData(this._toggleLoading);
    } else if (!this.props.appInitialized) {
      logger.info('_onTokenRefresh triggers fetchCompanyUserData()');
      this.props.fetchCompanyUserData(this._toggleLoading);
    } else {
      logger.warn('this.props.appInitialized');
      this._toggleLoading();
    }
  }

  _onCompanySelected() {
    this.setState({ dataFetched: true });
  }

  _hasPermissions() {
    const { restrictedFeature, permissions } = this.props;
    const permissionExists = (permission) => permissions[permission];

    if (!restrictedFeature) {
      return true;
    }

    if (isObject(restrictedFeature)) {
      if (restrictedFeature.$or) {
        return restrictedFeature.$or.some(permissionExists);
      }

      if (restrictedFeature.$and) {
        return restrictedFeature.$and.every(permissionExists);
      }

      return false;
    }

    return permissions[restrictedFeature];
  }

  componentDidMount() {
    const { isAuthenticated, appInitialized, renewToken } = this.props;

    if (isAuthenticated() && !appInitialized) {
      logger.debug('PrivateRoute triggers renewToken()');
      renewToken(this._onTokenRefresh);
    } else {
      this._toggleLoading();
    }
  }

  render() {
    const {
      isAuthenticated,
      shouldFillMandatoryData,
      mandatoryDataAreMissing,
      isUserHasRights,
      companySubscription,
      subscriptionTypes = null,
    } = this.props;

    const { loading } = this.state;

    if (loading) {
      return (
        <Layout
          header={null}
          sidebarLeft={false}
          sidebarright={null}
          showUserCard={false}
          body={<LoadingDialog title={this.props.t('loading_single')} />}
        />
      );
    }

    if (!isAuthenticated()) {
      return (
        <Redirect to={`/?r=${encodeURIComponent(window.location.href)}`} />
      );
    }

    if (!isUserHasRights()) {
      return <ChooseCompany closeDialog={this._onCompanySelected} />;
    }

    if (!this.state.dataFetched) {
      return <ChooseCompany closeDialog={this._onCompanySelected} />;
    }

    if (!this._hasPermissions()) {
      return <Redirect to="/" />;
    }

    if (shouldFillMandatoryData && mandatoryDataAreMissing) {
      return <Redirect to="/home" />;
    }

    if (
      subscriptionTypes &&
      subscriptionTypes.length > 0 &&
      !subscriptionTypes.includes(companySubscription.data.subscriptionType)
    ) {
      return <Redirect to="/home" />;
    }
    return (
      <Route
        {...this.props}
        render={(props) =>
          this.props.render({
            ...props,
            toggleDrawerVisibility: this.props.toggleDrawerVisibility,
          })
        }
      />
    );
  }
}

PrivateRoute.propTypes = {
  isAuthenticated: PropTypes.func,
  isUserHasRights: PropTypes.func,
  restrictedFeature: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOfType([
      PropTypes.shape({
        $or: PropTypes.array.isRequired,
      }),
      PropTypes.shape({
        $and: PropTypes.array.isRequired,
      }),
    ]),
  ]),
  render: PropTypes.func,
  toggleDrawerVisibility: PropTypes.object,
  loggedUser: PropTypes.object,
  loggedUserCompany: PropTypes.object,
  renewToken: PropTypes.func,
  fetchCompanyUserData: PropTypes.func,
  appInitialized: PropTypes.bool,
  t: PropTypes.func,
  classes: PropTypes.object,
  mandatoryDataAreMissing: PropTypes.bool,
  shouldFillMandatoryData: PropTypes.bool,
  permissions: PropTypes.object,
  companySubscription: PropTypes.object,
  subscriptionTypes: PropTypes.array,
};
PrivateRoute.defaultProps = {
  shouldFillMandatoryData: true,
};

const TranslatedPrivateRoute = withTranslation()(PrivateRoute);
const PrivateRouteWithStyles = withStyles(styles, { withTheme: true })(
  TranslatedPrivateRoute
);

export default connect(state, dispatch)(PrivateRouteWithStyles);
