import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import AppButton from 'components/AppButton';

const CreateProviderButton = ({ toggleNewProviderDialog, newProvider }) => {
  const { t } = useTranslation();
  const { isSubmitting, setFieldValue } = useFormikContext();

  const { provider, removeNewProvider } = newProvider;
  useEffect(() => {
    if (provider) {
      setFieldValue('provider', provider._id);
      removeNewProvider();
    }
  }, [provider]);

  return (
    <AppButton
      isDisabled={isSubmitting}
      color="secondaryLight"
      text={`+ ${t('settings_section.providers.create')}`}
      onClick={toggleNewProviderDialog}
    />
  );
};

CreateProviderButton.propTypes = {
  toggleNewProviderDialog: PropTypes.func.isRequired,
  newProvider: PropTypes.object.isRequired,
};

export default CreateProviderButton;
