import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    borderRadius: '4px',
    boxShadow: 'var(--shadow-md)',
    backgroundColor: 'white',
    padding: `10px 36px 10px 12px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px',
  },
  seriesList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '6px',
  },
  seriesItem: {
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    alignItems: 'center',
    alignSelf: 'stretch',
  },
  seriesShape: ({ seriesColor }) => ({
    width: '8px',
    height: '8px',
    backgroundColor: seriesColor,
    borderRadius: '50%',
  }),
  label: {
    fontWeight: 600,
    color: 'var(--color-neutral-900)',
    maxWidth: '300px',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: '1rem',
    lineHeight: '1.5rem',
  },
  seriesName: {
    color: 'var(--color-neutral-500)',
    fontSize: '0.75rem',
    lineHeight: '125%',
  },
  seriesValue: {
    fontWeight: 600,
    color: 'var(--color-neutral-900)',
    fontSize: '0.75rem',
    lineHeight: '125%',
  },
}));

const SeriesItem = (props) => {
  const { label, value } = props;
  const classes = useStyles(props);

  return (
    <Box className={classes.seriesItem}>
      <Box className={classes.seriesShape} />
      <span className={classes.seriesName}>{label}:</span>
      <span className={classes.seriesValue}>{value}</span>
    </Box>
  );
};

const ApexChartToolTip = (props) => {
  const classes = useStyles();
  const {
    dataPointIndex,

    w: {
      globals: {
        colors,
        series,
        categoryLabels,
        dataLabels: {
          style: { colors: defaultColors },
        },
        isBarHorizontal,
        yLabelFormatters,
        xLabelFormatter,
        initialConfig: {
          labels,
          tooltip: { x: formatter },
        },
        seriesNames,
      },
    },
  } = props;

  const seriesValue =
    series?.length && series.some((item) => item[dataPointIndex]);

  const formatValue = (value) => {
    if (isBarHorizontal && xLabelFormatter) {
      return xLabelFormatter(value);
    }
    if (yLabelFormatters) {
      return yLabelFormatters[0](value);
    }
    return value;
  };

  const formatLabel = (label) => {
    const { formatter: formatterTooltipLabel } = formatter;
    if (formatterTooltipLabel) {
      return formatterTooltipLabel(label);
    }
    return label;
  };

  return (
    <>
      {seriesValue ? (
        <Box className={classes.root}>
          <span className={classes.label}>
            {formatLabel(
              labels[dataPointIndex] || categoryLabels[dataPointIndex]
            )}
          </span>
          <Box className={classes.seriesList}>
            {series?.length > 0 &&
              series.map((item, index) => {
                const value = series[index][dataPointIndex];
                if (value) {
                  return (
                    <SeriesItem
                      key={index}
                      label={seriesNames[index]}
                      seriesColor={
                        colors?.length > 0
                          ? colors[index]
                          : defaultColors[index]
                      }
                      value={formatValue(value)}
                    />
                  );
                }
                return null;
              })}
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </>
  );
};

ApexChartToolTip.propTypes = {
  dataPointIndex: PropTypes.number.isRequired,
  seriesIndex: PropTypes.number.isRequired,
  w: PropTypes.object.isRequired,
};

SeriesItem.propTypes = {
  seriesColor: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default ApexChartToolTip;
