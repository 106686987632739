import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function useDecimalCharacter() {
  const language = useSelector((state) => state.guiLanguage);

  const decimalChar = useMemo(
    () => (language === 'fr' ? ',' : '.'),
    [language]
  );

  const exceptDecimalChar = useMemo(
    () => (language === 'fr' ? '.' : ','),
    [language]
  );
  return { decimalChar, exceptDecimalChar };
}

// eslint-disable-next-line import/prefer-default-export
export { useDecimalCharacter };
