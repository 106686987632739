/* eslint-disable react/display-name */

import React from 'react';
import { CLEAR_EXPENSE } from 'actions/Types';
import dispatch from 'components/ChooseCompany/dispatch';
import { getExpenseURL } from 'helpers/expenseHelpers';
import NumberFormat from 'helpers/NumberFormat';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import {
  userCanReadAdvanceExpense,
  userCanUpdateAdvanceExpense,
} from 'selectors/rightsSelector/advanceExpenseRequests';
import {
  userCanReadExpense,
  userCanUpdateExpense,
} from 'selectors/rightsSelector/expenseRequests';
import { EyesIcon } from 'components/AppIcons';
import { format } from 'date-fns';
import ExpenseState from 'components/states/ExpenseState';

const useFormatExpenseTransferData = () => {
  const users = useSelector((state) => state.users);
  const categories = useSelector((state) => state.categories);
  const history = useHistory();
  const location = useLocation();

  const canUpdateExpense = useSelector(userCanUpdateExpense);
  const canReadAdvanceExpens = useSelector(userCanReadAdvanceExpense);
  const canUpdateAdvanceExpense = useSelector(userCanUpdateAdvanceExpense);
  const canReadExpense = useSelector(userCanReadExpense);

  const { t } = useTranslation();

  function renderType(value) {
    switch (value) {
      case 'GroupedExpense':
      case 'SimpleExpense':
        return t('expenses.expense.type.note');
      case 'AdvancedExpense':
        return t('expenses.expense.type.advance');
      case 'Purchase':
      case 'InvoiceExpense':
        return t('expenses.expense.type.invoice');
      default:
        return 0;
    }
  }

  function renderEntity(exp) {
    if (exp.__t === 'AdvancedExpense') {
      return users[exp.payee_id]
        ? users[exp.payee_id].display_name
        : t('not_setted');
    }

    return users[exp.user_id]
      ? users[exp.user_id].display_name
      : t('not_setted');
  }

  function renderTotal(exp) {
    switch (exp.__t) {
      case 'AdvancedExpense': {
        return `${NumberFormat.n(exp.total)}€` || `${t('not_setted')}`;
      }
      case 'GroupedExpense': {
        const calculateTotal = exp.expenses
          .map((oneExpense) => oneExpense.grandTotalAmount || oneExpense.total)
          .reduce((a, b) => a + b, 0);
        return `${NumberFormat.n(calculateTotal)}€` || `${t('not_setted')}`;
      }
      case 'SimpleExpense': {
        return (
          `${NumberFormat.n(exp.grandTotalAmount)}€` || `${t('not_setted')}`
        );
      }
      case 'InvoiceExpense':
      case 'Purchase':
        return (
          `${NumberFormat.n(exp.duePayableAmount)}€` || `${t('not_setted')}`
        );
      default:
        return 0;
    }
  }

  function renderCategory(exp) {
    let catId = null;

    switch (exp.__t) {
      case 'GroupedExpense':
        catId = exp.expenses[0].category_id;
        break;
      case 'SimpleExpense':
      case 'InvoiceExpense':
      case 'Purchase':
        catId = exp.category_id;
        break;
      default:
        catId = null;
    }

    return catId && categories.data && categories.data[catId]
      ? categories.data[catId].description
      : '';
  }

  const formatData = (data) =>
    data.map((exp) => {
      return {
        ...exp,
        category: renderCategory(exp),
        type: renderType(exp.__t),
        expense_nb: exp.expense_nb,
        state: t(`expenses.expense.state.${exp.displayState}`),
        date: exp.date,
        entity: renderEntity(exp),
        gross_total: renderTotal(exp),
        id: exp._id,
      };
    });

  function editPath(expense) {
    const url =
      expense.__t === 'GroupedExpense'
        ? getExpenseURL(expense.expenses[0].payment_type)
        : getExpenseURL(expense.payment_type);

    switch (expense.__t) {
      case 'GroupedExpense':
      case 'SimpleExpense':
        return {
          pathname: `/${url}/edit/${expense._id}`,
          state: {
            payment: expense.payment_type,
            title: t('expenses.expense.expense'),
          },
        };
      case 'AdvancedExpense':
        return `/expenses/advance/edit/${expense._id}`;
      case 'InvoiceExpense':
      case 'Purchase':
        return {
          pathname: `/purchases/edit/${expense._id}`,
          state: {
            payment: expense.payment_type,
            title: t('expenses.expense.expense'),
          },
        };

      default:
        return '/';
    }
  }

  const columns = [
    {
      title: t('invoices.list.type'),
      field: 'type',
      sorting: false,
    },
    {
      title: t('invoices.list.state'),
      field: 'state',
      sorting: false,

      // eslint-disable-next-line react/prop-types
      render: ({ state }) => <ExpenseState state={state} />,
    },
    {
      title: t('invoices.list.nb'),
      field: 'expense_nb',
      sorting: false,
    },
    {
      title: t('invoices.form_date'),
      field: 'date',
      defaultSort: 'desc',
      render: ({ date }) => format(new Date(date), 'dd/MM/yyyy'),
    },
    {
      title: t('expenses.list.user'),
      field: 'entity',
      sorting: false,
    },
    {
      title: t('expenses.expense.form_amount'),
      field: 'gross_total',
      sorting: false,
    },
    {
      title: ' ',
      field: 'id',
      sorting: false,

      // eslint-disable-next-line react/prop-types
      render: (value) => {
        const { __t: expenseType } = value;

        const canSeeExpensePage = canUpdateExpense || canReadExpense;
        const canSeeAdvancedExpensePage =
          canReadAdvanceExpens || canUpdateAdvanceExpense;

        const userCanSeeButton =
          (['GroupedExpense', 'SimpleExpense', 'Purchase'].includes(
            expenseType
          ) &&
            canSeeExpensePage) ||
          (expenseType === 'AdvancedExpense' && canSeeAdvancedExpensePage);

        if (!userCanSeeButton) {
          return null;
        }

        return (
          <EyesIcon
            sm
            onClick={() => {
              dispatch({ type: CLEAR_EXPENSE });
              history.push({
                pathname:
                  typeof editPath(value) === 'string'
                    ? editPath(value)
                    : editPath(value).pathname,
                state: {
                  ...editPath(value).state,
                  goBackPath: location.pathname,
                  goBackQueryParams: location.search,
                  pageTitle: location.state?.pageTitle || 'new_transfer',
                  // notShowReconclilier: true,
                },
              });
            }}
          />
        );
      },
      options: {
        viewColumns: false,
        filter: false,
        sort: false,
        search: false,
      },
    },
  ];

  return {
    formatData,
    editPath,
    columns,
  };
};

export default useFormatExpenseTransferData;
