import _ from 'lodash';

import {
  FETCH_EXPORTS,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXPORTS:
      return _.mapKeys(action.payload, '_id');
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return {};
    default:
      return state;
  }
};
