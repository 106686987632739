import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import AppButton from '../AppButton';

import style from '../../assets/jss/components/appPlusDivider';
import '../../assets/css/icons.css';

const AppPlusDivider = ({
  onClick,
  classes,
  disabled,
  md,
  message,
  fullWidth,
}) => {
  const containerClasses = classNames({
    [classes.appContainer]: true,
  });
  const buttonClasses = classNames({
    [classes.plusButton]: true,
    [classes.mdPlusButton]: md,
    [classes.disabled]: disabled,
  });
  const dividerClasses = classNames({
    [classes.divider]: true,
    [classes.disabled]: disabled,
  });
  const iconClasses = classNames({
    [classes.iconStyle]: true,
    [classes.mdIconStyle]: md,
    icon: true,
    'icon-add': true,
    [classes.cursorPointer]: true,
  });

  return message ? (
    <AppButton
      onClick={onClick}
      type="button"
      fullWidth={fullWidth}
      text={message}
      color="secondary"
      noBorder={true}
      style={{ marginTop: '30px', marginBottom: '30px' }}
    />
  ) : (
    <div className={containerClasses}>
      <div className={dividerClasses} />
      <button type="button" className={buttonClasses} onClick={onClick}>
        <p className={iconClasses} />
      </button>
      <div className={dividerClasses} />
    </div>
  );
};
AppPlusDivider.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  md: PropTypes.bool,
  message: PropTypes.string,
  fullWidth: PropTypes.bool,
};
AppPlusDivider.defaultProps = {
  disabled: false,
  md: false,
  fullWidth: true,
};

export default withStyles(style)(AppPlusDivider);
