/**
 * Rounding number to the nearest based on custom rule
 * ex: 1.055 => 1.06 or 1.054 => 1.05 or 1.056 => 1.06
 * 1.555 => 1.55 (5 stays the same), 1.554 => 1.55, 1.556 => 1.56
 * @param {number} value
 * @param {number} decimals
 * @return {number}
 */
export const roundingNumber = (value, decimals = 2) => {
  const factor = 10 ** decimals;
  const tempNumber = value * factor;
  const integerPart = Math.floor(tempNumber); // Get the integer part of the temporary number
  const fractionPart = tempNumber - integerPart; // Get the fractional part of the temporary number

  let roundedTempNumber;
  if (fractionPart > 0.5) {
    roundedTempNumber = Math.ceil(tempNumber);
  } else if (fractionPart < 0.5) {
    roundedTempNumber = Math.floor(tempNumber);
  } else {
    roundedTempNumber = integerPart; // Keep the integer part if the fractional part is 0.5
  }

  return roundedTempNumber / factor;
};

export default {
  roundingNumber,
};
