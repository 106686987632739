import React, { createContext } from 'react';
import PropTypes from 'prop-types';
import treezorReducer from 'contexts/TreezorContext/treezorReducer';
import useLocalStorageReducer from 'hooks/treezorHooks/useLocalStorageReducer';

const TreezorFormContext = createContext([]);
export const DispatchContext = createContext();

const TreezorFormContextProvider = ({ children, value }) => {
  const [TreezorValue, dispatch] = useLocalStorageReducer(
    'TreezorValue',
    treezorReducer,
    value
  );
  return (
    <TreezorFormContext.Provider value={TreezorValue}>
      <DispatchContext.Provider value={dispatch}>
        {children}
      </DispatchContext.Provider>
    </TreezorFormContext.Provider>
  );
};

TreezorFormContextProvider.propTypes = {
  children: PropTypes.node,
  value: PropTypes.object,
};

export { TreezorFormContext, TreezorFormContextProvider };
