import colors from '../../../constants/colors.json';
import { smDarkGreyD, inputLabel } from './appText';

const style = {
  selectContainer: {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D7D7D7',
    },
    '&::-webkit-scrollbar': {
      backgroundColor: '#E9E9E9',
    },
    '&::-webkit-scrollbar-track': {
      // backgroundColor: 'red',
    },
  },

  itemsContainer: {
    position: 'absolute',
    width: '100%',
    borderBottom: '2px solid #3d319d',
    backgroundColor: colors.white,
    right: '0px',
    top: '41px',
    padding: '0',
    transform: 'scaleY(0)',
    transition: 'transform 0.2s ease',
    transformOrigin: 'top',
    height: 'auto',
    zIndex: 200,
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
  },

  itemsUserCard: {
    position: 'absolute',
    display: 'flex',
    flexDirection: 'column',
    borderBottom: '2px solid #3d319d',
    backgroundColor: colors.white,
    padding: '0',
    top: '108px',
    transform: 'scaleY(0)',
    transition: 'transform 0.2s ease',
    transformOrigin: 'top',
    height: 'auto',
    zIndex: 200,
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
  },
  selectItemUserCard: {
    ...smDarkGreyD,
    border: 'none',
    margin: '0 auto',
    textDecoration: 'none',
    height: '40px',
    width: '178px',
    backgroundColor: colors.white,
    textAlign: 'left',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.background,
      borderRadius: '2px',
    },
    '&:focus': {
      backgroundColor: colors.background,
      borderRadius: '2px',
    },
  },
  primaryError: {
    border: ' 1px solid #FF2B44',
    '&:active': {
      border: '1px solid #FF2B44',
    },
    '&:hover': {
      border: '1px solid #FF2B44',
    },
    '&:focus-within': { border: '1px solid #FF2B44' },
  },
  secondaryError: {
    border: ' 1px solid #FF2B44',
    '&:hover': {
      border: '1px solid #FF2B44',
    },
    '&:active': {
      border: '1px solid #FF2B44',
    },
    '&:focus-within': { border: '1px solid #FF2B44' },
  },
  simpleSecondaryError: {
    border: ' 1px solid #FF2B44',
    '&:hover': {
      border: '1px solid #FF2B44',
    },
    '&:active': {
      border: '1px solid #FF2B44',
    },
    '&:focus-within': { border: '1px solid #FF2B44' },
  },
  open: {
    transform: 'scaleY(1)',
    transformOrigin: 'top',
    transition: 'transform 0.5s ease',
  },

  primaryErrorMessage: {
    ...inputLabel,
    color: colors.red,
    position: 'absolute',
    width: 'auto',
    backgroundColor: colors.white,
    zIndex: 100,
    // left: '10px',
    top: '35px',
    padding: '0 3px',
    transform: 'scaleY(1)',
  },
  secondaryErrorMessage: {
    ...inputLabel,
    color: colors.red,
    position: 'absolute',
    width: 'auto',
    backgroundColor: colors.white,
    zIndex: 100,
    // left: '10px',
    top: '35px',
    padding: '0 3px',
    transform: 'scaleY(1)',
  },
  simpleSecondaryErrorMessage: {
    ...inputLabel,
    color: colors.red,
    position: 'absolute',
    width: 'auto',
    backgroundColor: colors.white,
    zIndex: 100,
    // left: '10px',
    top: '35px',
    padding: '0 3px',
    transform: 'scaleY(1)',
  },

  simpleSecondary: {
    borderBottom: '2px solid #02CBCD',
  },

  secondary: {
    borderBottom: '2px solid #02CBCD',
  },
  secondaryGrey: {
    borderBottom: '2px solid #02CBCD',
  },
  maxHeightS: {
    maxHeight: '195px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D7D7D7',
    },
    '&::-webkit-scrollbar': {
      backgroundColor: '#E9E9E9',
    },
    '&::-webkit-scrollbar-track': {
      // backgroundColor: 'red',
    },
  },
  maxHeightM: {
    maxHeight: '290px',
    overflowY: 'scroll',
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#D7D7D7',
    },
    '&::-webkit-scrollbar': {
      backgroundColor: '#E9E9E9',
    },
    '&::-webkit-scrollbar-track': {
      // backgroundColor: 'red',
    },
  },

  label: {
    ...inputLabel,
    color: colors.darker_grey,
    position: 'absolute',
    width: 'auto',
    // backgroundColor: colors.white,
    // zIndex: 100,
    left: '10px',
    top: '13px',
    padding: '0 3px',
    transform: 'scaleY(0)',
  },

  labelFocused: {
    color: colors.darker_grey,
  },
  showLabel: {
    transform: 'scaleY(1)',
    top: '-8px',
    transition: 'top 0.4s ease',
  },

  heigherIndex: {
    zIndex: 11,
  },

  // to remove console warning
  inputContainer: {},
  fullWidth: {},
  inputField: {},
  alignRight: {},
  iconStyle: {},
  primaryCaret: {},
  secondaryCaret: {},
  primaryWithValue: {},
  secondaryWithValue: {},
  primaryLabel: {},
  secondaryLabel: {},
  primaryLabelFocused: {},
  secondaryLabelFocused: {},
  primaryLabelError: {},
  secondaryLabelError: {},
  mandatory: {},
  disabled: {},
  TPcenter: {},
  TPnone: {},
};

export default style;
