import { queryStringHelper } from 'helpers/querystringHelper';
import { setGlobalError } from 'actions/GlobalError';
import querystring from 'qs';

const paginateAction = async ({
  dispatch,
  service,
  walletId = '',
  query: queryPagination,
  filters,
  refresh,
  actions,
  queryCustom,
}) => {
  try {
    // When refreshing: refetching every docs until this page to avoid bug with redux state etc...
    if (refresh) {
      const qsRefresh = queryStringHelper({
        ...queryPagination,
        page: 0,
        pageSize: (queryPagination.page + 1) * queryPagination.pageSize,
        filters,
      });

      const res =
        walletId !== ''
          ? await service(walletId, qsRefresh.querystring)
          : await service(qsRefresh.querystring);
      // const res = await service(qsRefresh.querystring);
      dispatch({
        type: actions.refresh,
        docs: res.data.docs,
        totalCount: res.data.totalDocs,
        page: queryPagination.page + 1,
      });
      return {
        data: res.data.docs.slice(
          queryPagination.page * queryPagination.pageSize,
          (queryPagination.page + 1) * queryPagination.pageSize
        ),
        page: queryPagination.page,
        totalCount: res.data.totalDocs,
      };
    }

    const qs = queryStringHelper({ ...queryPagination, filters });
    const queryCustomString = querystring.stringify(queryCustom);
    const queryString = queryCustomString
      ? `${qs.querystring}&${queryCustomString}`
      : qs.querystring;

    const res =
      walletId !== ''
        ? await service(walletId, queryString)
        : await service(queryString);
    // const res = await service(qs.querystring);
    dispatch({
      type: actions.fetch,
      ...qs,
      docs: res.data.docs,
      totalCount: res.data.totalDocs,
    });
    return {
      data: res.data.docs,
      page: res.data.page - 1,
      totalCount: res.data.totalDocs,
    };
  } catch (error) {
    dispatch(setGlobalError(error));
    return {
      data: [],
      page: 0,
      totalCount: 0,
    };
  }
};

export default paginateAction;
