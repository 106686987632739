import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import FlagIcon from './FlagIcon';
import { FlagFR } from '../FlagsComponent';

const Fr = ({ t, onlyflag = false, onlytext = false }) => (
  <FlagIcon
    icon={FlagFR}
    onlyflag={onlyflag}
    onlytext={onlytext}
    text={t('languages.french')}
  />
);

Fr.propTypes = {
  t: PropTypes.func,
  onlyflag: PropTypes.bool,
  onlytext: PropTypes.bool,
};

export default withTranslation()(Fr);
