import { uploadCompanyFile } from 'actions/CompanyActions';
import companyHelpers from 'actions/CompanyActions/CompanyHelpers';
import logger from 'helpers/logger';
import spinnerService from 'services/SpinnerService';

export default async (
  values,
  { setErrors, setStatus, setSubmitting, ...props }
) => {
  const cb = (err) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      props.history.push('/home');
    }
  };
  try {
    console.log('values', values);
    spinnerService.startSpinner();

    logger.debug('[CompanySettings] handleSubmit()', { values });

    let data = companyHelpers.valuesToData(values);

    // kbiz
    if (values?.kbizFile) {
      const filename = await uploadCompanyFile(values?.kbizFile, cb);
      if (filename) {
        data = { ...data, kbiz: filename };
      }
    }

    // sirenDocs
    if (values?.sirenDocsFile) {
      const filename = await uploadCompanyFile(values?.sirenDocsFile, cb);
      if (filename) {
        data = { ...data, sirenDoc: filename };
      }
    }

    // logo
    if (values?.logoFile) {
      const file = values.logoFile;
      const filename = await uploadCompanyFile(file, cb);
      if (filename) {
        data = { ...data, filename };
      }
    }
    const defaultBank = data.bank_details.find((item) => item?.isDefault);
    if (!defaultBank) {
      data.bank_details[0] = { ...data.bank_details[0], isDefault: true };
    }

    await props.updateCompany(data, cb);
  } catch (error) {
    cb(error);
  } finally {
    spinnerService.endSpinner();
  }
};
