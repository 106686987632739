import React from 'react';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import FlagIcon from './FlagIcon';
import { FlagGB } from '../FlagsComponent';

// const En = ({ t }) => (
//   <>
//     <FlagGB /> {t('languages.english')}
//   </>
// );

const En = ({ t, onlyflag = false, onlytext = false }) => (
  <FlagIcon
    icon={FlagGB}
    onlyflag={onlyflag}
    onlytext={onlytext}
    text={t('languages.english')}
  />
);

En.propTypes = {
  t: PropTypes.func.isRequired,
  onlyflag: PropTypes.bool,
  onlytext: PropTypes.bool,
};

export default withTranslation()(En);
