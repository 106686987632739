// TODO: rename into ExpenseCategoriesActions

import categoryService from 'services/CategoryService';

import {
  FETCHING_CATEGORIES,
  FETCH_CATEGORIES,
  FETCH_CATEGORIES_FAILED,
  UPDATE_CATEGORY_FAILED,
  UPDATE_CATEGORY_SUCCESS,
  UPDATING_CATEGORY,
} from 'actions/Types';
import { setGlobalError } from 'actions/GlobalError';

import { selectLoggedUserCompanyId } from 'selectors/loggedUserCompanySelectors';

export const fetchingCategories = () => ({
  type: FETCHING_CATEGORIES,
});

export const fetchCategoriesSuccess = (categories) => ({
  type: FETCH_CATEGORIES,
  payload: { categories },
});

export const fetchCategoriesFailure = (error) => ({
  type: FETCH_CATEGORIES_FAILED,
  payload: { error },
});

export const fetchCategories = () => async (dispatch, getState) => {
  dispatch(fetchingCategories());
  try {
    const companyId = selectLoggedUserCompanyId(getState());

    if (companyId) {
      const result = await categoryService.fetchCategories({
        company_id: companyId,
      });
      dispatch(fetchCategoriesSuccess(result.data));
    } else {
      // not ideal
      dispatch(fetchCategoriesSuccess([]));
    }
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch(fetchCategoriesFailure(error));
  }
};

// === Update ===
export const updatingCategory = () => ({
  type: UPDATING_CATEGORY,
});

export const updateCategorySuccess = (newCategory, id) => {
  return {
    type: UPDATE_CATEGORY_SUCCESS,
    payload: { newCategory, id },
  };
};

export const updateCategoryFailure = (error) => ({
  type: UPDATE_CATEGORY_FAILED,
  payload: { error },
});

export async function updateCategory(
  dispatch,
  companyId,
  newCategory,
  oldCategory
) {
  try {
    const submitData = {
      ...oldCategory,
      ...newCategory,
      reference: oldCategory.reference || oldCategory._id,
      company_id: companyId,
    };
    delete submitData._id;

    // Update then refetch
    await categoryService.updateCategory(submitData);
    dispatch(fetchCategories());
    return { status: UPDATE_CATEGORY_SUCCESS };
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch(updateCategoryFailure(error));
    return { status: UPDATE_CATEGORY_FAILED, error };
  }
}
