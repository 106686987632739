import { authInstance as axios } from '../../helpers/axiosInterceptor';

/* eslint import/prefer-default-export: off */
const infoGreffe = (name, options) =>
  axios.get(`${process.env.REACT_APP_GREFFE_URL}/info`, {
    timeout: 60000,
    params: {
      q: name,
    },
    ...options,
  });

export default {
  infoGreffe,
};
