/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Box, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { getThemeColor } from 'helpers/utils';
import ApexChart from 'components/ApexChart';
import {
  getDataSet,
  monthFiscalYearHelper,
} from 'helpers/InvoicesTurnoverHelpers';
import { endOfMonth, format, getYear } from 'date-fns';
import { useSelector } from 'react-redux';
import { useRouter } from 'hooks/routerHooks';
import queryString from 'query-string';
import { getMonthLabels } from 'constants/chart';

const useStyles = makeStyles(() => ({}));

const NewInvoiceLineChart = (props) => {
  const { inputData } = props;
  const { t } = useTranslation();
  const classes = useStyles();
  const theme = useTheme();
  const datasets = inputData ? getDataSet(inputData, t) : [];
  const endFiscalYear = useSelector(
    (state) => state.loggedUserCompany.company.endFiscalYear
  );
  const { history } = useRouter();

  const onClickElementChart = (dataPointIndex, seriesIndex) => {
    // settings invoice filters when click on invoice bar (dataset_index <= 1)
    const invoiceFilters = {
      state: ['validated', 'paid'],
      type: ['invoice', 'credit_note', 'pre_payment'],
    };

    invoiceFilters.after = monthFiscalYearHelper(
      endFiscalYear,
      dataPointIndex + 1,
      seriesIndex
    );
    invoiceFilters.before = format(
      endOfMonth(new Date(invoiceFilters.after)),
      'yyyy-MM-dd'
    );

    // catégories prises en comte dans le CA : Honoraire, Redevances de collaboration, Redevances et droits d’auteurs
    invoiceFilters.categories = [
      '5e836fa7b001900efa8a639f',
      '5e8374a1323359931db85325',
      '5e8374cb323359931db853ab',
    ];
    history.push(
      queryString.stringifyUrl(
        {
          url: 'invoices/list',
          query: invoiceFilters,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  const chartData = {
    series: datasets,
    options: {
      chart: {
        type: 'line',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        events: {
          markerClick(event, chartContext, { dataPointIndex, seriesIndex }) {
            onClickElementChart(dataPointIndex, seriesIndex);
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'straight',
      },
      colors: [
        getThemeColor(theme.palette.accent.blue),
        getThemeColor(theme.palette.accent.green),
      ],
      tooltip: {
        x: {
          formatter(value) {
            return `${value} ${getYear(new Date())}`;
          },
        },
      },
      labels: getMonthLabels(t),
      yaxis: {
        labels: {
          formatter(val) {
            return `${val}€`;
          },
          style: {
            colors: [
              getThemeColor(theme.palette.neutral[400]),
              getThemeColor(theme.palette.neutral[500]),
            ],
            fontSize: '0.875rem',
            fontWeight: 600,
          },
        },
      },
      xaxis: {
        labels: {
          style: {
            colors: getThemeColor(theme.palette.neutral[500]),
            fontSize: '0.75rem',
            fontWeight: 500,
          },
        },
      },
    },
  };

  return (
    <ApexChart
      options={chartData.options}
      series={chartData.series}
      type="line"
      height={400}
    />
  );
};

NewInvoiceLineChart.propTypes = {
  inputData: PropTypes.object.isRequired,
};

export default NewInvoiceLineChart;
