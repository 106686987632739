import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import _ from 'lodash';

import { withStyles } from '@material-ui/core';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withTranslation } from 'react-i18next';
import { IconEdit } from 'components/AppIcons';
import MUIDataTable from 'mui-datatables';
import { getMuiDatatableOptions } from 'helpers/tableHelpers';
import { useSelector } from 'react-redux';

import Header from 'components/Header';
import { SUB_CATEGORIES_PREFIX } from 'constants/expense';
import Layout from 'components/Layout';
import CustomGenericDialog from 'components/CustomGenericDialog';
import styles from 'assets/jss/root';
import { exportFile } from 'helpers/utils';
import {
  EXPORT_BOOK_TYPE,
  EXPORT_FILE_CONTENT_TYPE,
  EXPORT_FILE_TYPE,
} from 'config/Constants';
import LoadingDialog from 'components/LoadingDialog';
import CategoryForm from '../Form';

const Categories = (props) => {
  const { t } = props;
  const categories = useSelector((state) => state.categories);
  const loggedUserCompany = useSelector((state) => state.loggedUserCompany);
  const history = useHistory();

  const [showDialog, setShowDialog] = useState(false);
  const [selectedCate, setSelectedCate] = useState(false);

  function getModifiedField(category, fieldName) {
    if (
      category?.referenceData &&
      category?.referenceData[fieldName] &&
      category?.referenceData[fieldName] !== category[fieldName]
    ) {
      return category[fieldName];
    }
    return null;
  }

  function translateSubcategory(display) {
    const translation = t(`${SUB_CATEGORIES_PREFIX}${display}`);

    if (translation.includes(SUB_CATEGORIES_PREFIX)) return display;

    return translation;
  }

  function mapListCategories(category) {
    const res = {
      id: category._id,
      // get default category display if modified
      display:
        translateSubcategory(category?.referenceData?.display) ||
        translateSubcategory(category.display),
      display_modified: translateSubcategory(
        getModifiedField(category, 'display')
      ),
      // get default account if modified
      account: category?.referenceData?.account || category.account,
      account_modified: getModifiedField(category, 'account'),
      parent: t(`expenses.categories.${category?.parentData?.display}`),
    };

    const accountType =
      loggedUserCompany?.company?.settings?.tax_registration?.bookkeeping;

    if (typeof res.account !== 'string') {
      const finalAccount = res?.account?.find(
        (item) => item.type === accountType
      )?.account;
      res.account = finalAccount;
    }

    return res;
  }

  function resetModal() {
    setShowDialog(false);
    setSelectedCate(null);
  }

  const listCategories = React.useMemo(() => {
    return _.map(categories?.data)
      ?.filter((item) => item.account)
      ?.map((item) => mapListCategories(item))
      ?.sort((item1, item2) => item1.parent.localeCompare(item2.parent));
  }, [categories]);

  const selectedCateInfo = React.useMemo(() => {
    const filterCategories = _.map(categories?.data)?.filter(
      (item) => item.account
    );
    if (selectedCate) {
      return filterCategories.find((item) => item._id === selectedCate);
    }

    return null;
  }, [selectedCate]);

  function renderFormDialog() {
    if (showDialog) {
      return (
        <CustomGenericDialog
          title={t('category.dialog_title')}
          closeDialog={resetModal}
          iconClose
          color="secondary"
        >
          <CategoryForm
            closeCategoryFormDialog={resetModal}
            showDialog={showDialog}
            selectedCateInfo={selectedCateInfo}
          />
        </CustomGenericDialog>
      );
    }

    return null;
  }

  function mapColForDownload() {
    return listCategories.map((category) => ({
      [t('category.parent')]: t(`expenses.categories.${category.parent}`),
      [t('category.display')]: translateSubcategory(category.display),
      [t('category.display_modified')]: translateSubcategory(
        category.display_modified
      ),
      [t('category.account')]: category.account,
      [t('category.account_modified')]: category.account_modified,
    }));
  }

  const options = {
    download: true,
    print: false,
    onDownload: () => {
      exportFile(
        mapColForDownload(),
        'list categories',
        EXPORT_FILE_CONTENT_TYPE,
        EXPORT_FILE_TYPE,
        EXPORT_BOOK_TYPE
      );
      return false;
    },
  };

  function renderList() {
    const columns = [
      {
        label: t('category.parent'),
        name: 'parent',
      },
      {
        label: t('category.display'),
        name: 'display',
      },
      {
        label: t('category.display_modified'),
        name: 'display_modified',
      },
      {
        label: t('category.account'),
        name: 'account',
      },
      {
        label: t('category.account_modified'),
        name: 'account_modified',
      },
      {
        label: ' ',
        name: 'id',
        options: {
          viewColumns: false,
          filter: false,
          sort: false,
          search: false,
          customBodyRender: (value) => {
            return (
              <IconEdit
                sm
                onClick={() => {
                  setShowDialog(true);
                  setSelectedCate(value);
                }}
              />
            );
          },
        },
      },
    ];

    return (
      <>
        {renderFormDialog()}
        {categories.loading && <LoadingDialog title={t('loading_single')} />}

        <MUIDataTable
          data={listCategories}
          columns={columns}
          options={getMuiDatatableOptions(t, listCategories.length, options)}
        />
      </>
    );
  }

  return (
    <div>
      <Layout
        header={
          <Header
            name={t('settings_section.category.title')}
            spaceBetween
            goBack={() => {
              history.goBack();
            }}
          ></Header>
        }
        sidebarLeft={true}
        sidebarRight={null}
        showUserCard={true}
        body={renderList()}
      />
    </div>
  );
};

Categories.propTypes = {
  t: PropTypes.func,
};

const CategoriesWithStyle = withStyles(styles)(Categories);
const RouterCategoriesWithStyle = withRouter(CategoriesWithStyle);
const RouterCategoriesWithTranslate = withTranslation()(
  RouterCategoriesWithStyle
);

export default RouterCategoriesWithTranslate;
