import React from 'react';
import { Container, Grid, Box, Link } from '@material-ui/core';
import { FOOTER_DOCUMENT } from '../../config/Constants';

export default function Footer() {
  return (
    <footer>
      <Box spacing={5}>
        <Container maxWidth="lg">
          <Grid container spacing={5}>
            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}>
                <Link
                  href={FOOTER_DOCUMENT.CONDITIONS}
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Conditions générales
                </Link>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}>
                <Link
                  href={FOOTER_DOCUMENT.POLITIQUE}
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Politique de confidentialité
                </Link>
              </Box>
            </Grid>

            <Grid item xs={12} sm={4}>
              <Box borderBottom={1}>
                <Link
                  href="https://bebusinessfocus.zendesk.com/hc/fr/requests/new"
                  color="inherit"
                  target="_blank"
                  rel="noreferrer"
                >
                  Support
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
}
