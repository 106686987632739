import { APP_LOADING } from 'actions/Types';
import { globalDispatch } from '../../store';

let loadingCount = 0;
const showSpinner = (isLoading) => {
  globalDispatch({
    type: APP_LOADING,
    payload: { isLoading },
  });
};

const startSpinner = () => {
  if (!loadingCount) {
    showSpinner(true);
  }
  // eslint-disable-next-line no-plusplus
  loadingCount++;
};

const endSpinner = () => {
  // eslint-disable-next-line no-plusplus
  loadingCount--;
  if (!loadingCount) {
    showSpinner(false);
  }
};

const resetSpinner = () => {
  loadingCount = 0;
  showSpinner(false);
};

const spinnerService = { startSpinner, endSpinner, resetSpinner };
export default spinnerService;
