import React from 'react';
import PropTypes from 'prop-types';
// import { useFormikContext } from 'formik';
import { Grid } from '@material-ui/core';
import PublicIcon from '@material-ui/icons/Public';
import { useTranslation } from 'react-i18next';
import WifiIcon from '@material-ui/icons/Wifi';
import LanguageIcon from '@material-ui/icons/Language';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import SelectMe from './SelectMe';

const Security = ({ virtual }) => {
  const { t } = useTranslation();
  // const { setFieldValue, values } = useFormikContext();

  // const [value, setValue] = useState(values.permsGroup);
  // const perms = values.permsGroup.slice(-2);
  // let bitVal = (parseInt(perms, 10) - 1).toString(2);
  // if (bitVal.length === 0) {
  //   bitVal = '0000';
  // } else if (bitVal.length === 1) {
  //   bitVal = `000${bitVal}`;
  // } else if (bitVal.length === 2) {
  //   bitVal = `00${bitVal}`;
  // } else if (bitVal.length === 3) {
  //   bitVal = `0${bitVal}`;
  // }
  // const tmp = bitVal[0] === '1';
  // const tmp1 = bitVal[1] === '1';
  // const tmp2 = bitVal[2] === '1';
  // const tmp3 = bitVal[3] === '1';
  // const [contactless, setContactless] = useState(tmp);
  // const [atm, setAtm] = useState(tmp1);
  // const [onLine, setOnLine] = useState(tmp2);
  // const [foreign, setForeign] = useState(tmp3);

  // useEffect(() => {
  //   const value1 = contactless ? '1' : '0';
  //   const value2 = atm ? '1' : '0';
  //   const value3 = onLine ? '1' : '0';
  //   const value4 = foreign ? '1' : '0';
  //   const bitVal2 = parseInt(value1 + value2 + value3 + value4, 2);
  //   const bitValToString =
  //     bitVal2 + 1 > 9 ? `${bitVal2 + 1}` : `0${bitVal2 + 1}`;
  //   setValue(`TRZ-CU-0${bitValToString}`);
  //   setFieldValue('permsGroup', `TRZ-CU-0${bitValToString}`);
  // }, [contactless, onLine, foreign, atm]);

  return (
    <Grid container style={{ padding: '20px' }} spacing={4}>
      <Grid item xs={12} sm={6}>
        <SelectMe
          Icon={WifiIcon}
          text={t('contact_less_payment')}
          name={'nfc'}
          disabled={virtual}
          // checked={contactless}
          // onChange={() => {
          //   setContactless(!contactless);
          // }}
        />
        <SelectMe
          Icon={LanguageIcon}
          text={t('internet_payment')}
          name={'onLine'}
          disabled={false}
          // checked={onLine}
          // onChange={() => {
          //   setOnLine(!onLine);
          // }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <SelectMe
          Icon={LocalAtmIcon}
          text={t('atm_with_drawal')}
          name={'atm'}
          disabled={virtual}
          // checked={atm}
          // onChange={() => {
          //   setAtm(!atm);
          // }}
        />
        <SelectMe
          Icon={PublicIcon}
          text={t('foreign_payment')}
          name={'foreign'}
          disabled={false}
          // checked={foreign}
          // onChange={() => {
          //   setForeign(!foreign);
          // }}
        />
      </Grid>
    </Grid>
  );
};

Security.propTypes = {
  dummy: PropTypes.string,
  virtual: PropTypes.bool,
};

export default Security;
