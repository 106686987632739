import React, { createRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import AppButton from '../AppButton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  canvas: {
    border: '2px dotted #ccc',
    borderRadius: '4px',
    cursor: 'crosshair',
    width: 300,
    height: 150,
  },
  control: {
    padding: theme.spacing(2),
  },
  signature: {
    border: '1px solid #ddd',
    borderRadius: '4px',
    padding: '5px',
    '&:hover': {
      boxShadow: '0 0 2px 1px rgba(0, 140, 186, 0.5)',
    },
  },
  error: {
    color: 'red',
  },
}));

const ClientSignature = ({ sendSignature }) => {
  const classes = useStyles();
  const canvas = createRef();

  const { t } = useTranslation();

  const [sizeError, setSizeError] = useState(false);
  const [drawing, setDrawing] = useState(false);
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });
  const [lastPos, setLastPos] = useState({ x: 0, y: 0 });
  const [signature, setSignature] = useState(null);
  const [clientSigned, setClientSigned] = useState(false);
  const [signatureFile, setFile] = useState(null);
  const [IsUnSupportedFile, setIsUnsupportedFile] = useState(false);

  useEffect(() => {
    if (signature) {
      sendSignature(signature);
    }
  }, [signature]);

  function onDraw() {
    if (drawing) {
      const ctx = canvas.current.getContext('2d');
      ctx.strokeStyle = '#0055ff';
      ctx.lineWidth = 2;
      ctx.moveTo(lastPos.x, lastPos.y);
      ctx.lineTo(mousePos.x, mousePos.y);
      ctx.stroke();
      setLastPos(mousePos);
    }
  }

  function clearCanvas() {
    // eslint-disable-next-line no-self-assign
    canvas.current.width = canvas.current.width;
    setClientSigned(false);
  }

  function submit() {
    if (signatureFile) {
      setSignature(signatureFile);
    } else {
      canvas.current.toBlob(setSignature);
    }
  }

  function saveFileSignature(e) {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (!file.type.includes('image/')) {
        setIsUnsupportedFile(true);
        setClientSigned(false);
        setFile(null);
        setSizeError(false);
      } else if (file.size < 3000) {
        setClientSigned(true);
        setFile(file);
        setSizeError(false);
        setIsUnsupportedFile(false);
      } else {
        setSizeError(true);
        setClientSigned(false);
        setFile(null);
        setIsUnsupportedFile(false);
      }
    }
  }

  function getMousePos(canvasDom, mouseEvent) {
    const rect = canvasDom.getBoundingClientRect();
    return {
      x: mouseEvent.clientX - rect.left,
      y: mouseEvent.clientY - rect.top,
    };
  }

  function getTouchPos(canvasDom, touchEvent) {
    const rect = canvasDom.getBoundingClientRect();
    return {
      x: touchEvent.touches[0].clientX - rect.left,
      y: touchEvent.touches[0].clientY - rect.top,
    };
  }

  // --------------- When user starts drawing ------------------
  function handleMouseDown(e) {
    setDrawing(true);
    setLastPos(getMousePos(canvas.current, e));
    setClientSigned(true);
  }

  function handleTouchStart(e) {
    setDrawing(true);
    setMousePos(getTouchPos(canvas.current, e));
    setLastPos(getTouchPos(canvas.current, e));
    setClientSigned(true);
  }
  // -----------------------------------------------------------

  // --------------- When user stops drawing ------------------
  function handleMouseUp() {
    setDrawing(false);
  }

  function handleTouchEnd() {
    setDrawing(false);
  }
  // ----------------------------------------------------------

  // --------------- When user moves mouse -------------------
  function handleMouseMove(e) {
    setMousePos(getMousePos(canvas.current, e));
    onDraw(e);
  }

  function handleTouchMove(e) {
    setMousePos(getTouchPos(canvas.current, e));
    onDraw(e);
  }
  // ----------------------------------------------------------

  return (
    <Grid container className={classes.root} spacing={2}>
      <Grid item xs={12}>
        <canvas
          ref={canvas}
          className={classes.canvas}
          style={{ touchAction: 'none' }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={handleTouchEnd}
          disabled={signatureFile}
        ></canvas>
      </Grid>
      <Grid item xs={12}>
        <Grid container justify="flex-start" spacing={3}>
          <Grid item>
            <AppButton
              color="secondary"
              text={t('reset')}
              onClick={clearCanvas}
            />
          </Grid>

          <Grid item>
            <p>{t('confirm.download_signature')}</p>
            <input type="file" accept="image/*" onChange={saveFileSignature} />
            {sizeError && (
              <span className={classes.error}>{t('file_size_error')}</span>
            )}
            {IsUnSupportedFile && (
              <span className={classes.error}>{t('unsupported_file')}</span>
            )}
          </Grid>

          <Grid item>
            <AppButton
              color="primary"
              style={{ marginTop: '15px' }}
              text={t('send')}
              isDisabled={IsUnSupportedFile || !clientSigned || sizeError}
              onClick={submit}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ClientSignature.propTypes = {
  sendSignature: PropTypes.func,
};

export default ClientSignature;
