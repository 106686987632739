import * as yup from 'yup';

const validationSchema = () =>
  yup.object().shape({
    description: yup.string().required('La description est obligatoire'),
    payment_type: yup.string().required('Le mode de paiement est obligatoire'),
    action: yup.string().nullable(true),
    // filename is the bas64 buffer : on ndf and ach only
    filename: yup.mixed().required('La facture est obligatoire'),
    date: yup.date().when('action', {
      is: (action) => action !== 'paid',
      then: yup.date().required('La date est obligatoire'),
    }),

    due_date: yup.date().when('action', {
      is: (action) => action !== 'paid',
      then: yup
        .date()
        .min(
          yup.ref('date'),
          "La date d'échéance ne peut pas être antérieure à la date d'émission"
        ),
    }),

    invoice_nb: yup
      .string()
      .nullable(true)
      .when('action', {
        is: (action) => action !== 'paid',
        then: yup.string().when('form', {
          is: (form) => form === 'ach',
          then: yup.string().required('Le numéro de facture est obligatoire'),
        }),
      }),
    provider: yup
      .string()
      .nullable(true)
      .when('action', {
        is: (action) => action !== 'paid',
        then: yup.string().when('hasProvider', {
          is: (hasProvider) => {
            return hasProvider;
          },
          then: yup
            .string()
            .nullable(true)
            .required('Le fournisseur est obligatoire'),
          otherwise: yup.string().nullable(true),
        }),
      }),
    taxBasisTotalAmount: yup.number().when('action', {
      is: (action) => action !== 'paid',
      then: yup
        .number()
        .required('Le montant net est obligatoire')
        .notOneOf([0], 'Le montant net est obligatoire'),
      // then: yup.number().when('form', {
      //   is: (form) => form === 'ach',
      //   then: yup
      //     .number()
      //     .required('Le montant net est obligatoire')
      //     .notOneOf([0], 'Le montant net est obligatoire'),
      // }),
    }),
    taxTotalAmount: yup.number().when('action', {
      is: (action) => action !== 'paid',
      then: yup.number(),
      // .required('Le montant de TVA est obligatoire')
      // .notOneOf([0], 'Le montant de TVA est obligatoire'),

      // then: yup.number().when('form', {
      //   is: (form) => form === 'ach',
      //   then: yup.number().required('Le montant de TVA est obligatoire'),
      // }),
    }),
    grandTotalAmount: yup.number().when('action', {
      is: (action) => action !== 'paid',
      then: yup
        .number()
        .required('Le Total est obligatoire')
        .notOneOf([0], 'Le Total est obligatoire'),
    }),
    travel_from: yup.string().when('isTravel', {
      is: (isTravel) => isTravel,
      then: yup.string().when('form', {
        is: (form) => form === 'ndf',
        then: yup.string().required('Le lieu de depart est obligatoire'),
      }),
    }),

    travel_to: yup.string().when('isTravel', {
      is: (isTravel) => isTravel,
      then: yup.string().when('form', {
        is: (form) => form === 'ndf',
        then: yup.string().required('La destination est obligatoire'),
      }),
    }),
    duePayableAmount: yup.number().when('form', {
      is: (form) => form === 'ach',
      then: yup
        .number()
        .required('Solde à régler doit est obligatoire')
        .test(
          'duePayableTest',
          'Solde à régler doit est inférieur ou égal au montant TTC',
          // to alow this
          // eslint-disable-next-line
          function (value) {
            return value <= this.parent.grandTotalAmount && value > 0;
          }
        )
        .when('action', {
          is: (action) => action !== 'paid',
          then: yup.number().test(
            'duePayableTest',
            'Solde à régler doit est inférieur ou égal au montant TTC',
            // to alow this
            // eslint-disable-next-line
            function (value) {
              return value <= this.parent.grandTotalAmount;
            }
          ),
        }),
    }),
    client_id: yup.string().nullable(),
    providerSiret: yup
      .string()
      .matches(/^[0-9]{14}$/, 'Siret est sur 14 caractères'),
    billNumber: yup.string(),
    reference: yup.string(),
    purchaseNumber: yup.string(),
    orderNumber: yup.string(),
    amounts: yup.array().of(
      yup.object().shape({
        category_id: yup
          .string()
          .nullable()
          .when('hasProvider', {
            is: true,
            then: (schema) => schema.required('Ce champ est obligatoire'),
          }),

        sub_category_id: yup
          .string()
          .nullable()
          .when('hasProvider', {
            is: true,
            then: (schema) => schema.required('Ce champ est obligatoire'),
          }),
      })
    ),
  });

export default validationSchema;
