import {
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  FETCHING_BANK_ACCOUNTS,
  FETCH_BANK_ACCOUNT_FAILED,
  FETCH_BANK_ACCOUNT_SUCCESS,
} from 'actions/Types';

import logger from 'helpers/logger';

const initialState = {
  loading: false,
  error: null,
  timestamp: null,
  data: null, // les statements
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCHING_BANK_ACCOUNTS:
      return {
        ...state,
        loading: true,
        data: null,
        error: null,
      };
    case FETCH_BANK_ACCOUNT_FAILED: {
      const { error } = action.payload;
      logger.error('xx_BANKCONNECTORx_FAILED', error);
      return {
        ...state,
        data: null,
        loading: false,
        error,
      };
    }
    case FETCH_BANK_ACCOUNT_SUCCESS: {
      const { resources } = action.payload;
      return {
        ...state,
        loading: false,
        error: null,
        data: resources,
      };
    }

    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return initialState;

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
};
