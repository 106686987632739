import formStyle from './form/form';
import colors from '../../constants/colors.json';
import { inputLabel } from './components/appText';

const drawerWidth = 280;
const iconHeight = 55;
const styles = (theme) => ({
  ...formStyle,
  root: {
    flexGrow: 1,
    backgroundColor: colors.light_grey,
  },
  appFrame: {
    zIndex: 1,
    position: 'relative',
    backgroundColor: colors.light_grey,
    display: 'flex',
    width: '1336px',
    margin: '0 auto',
    paddingTop: '20px',
    height: 'fill-available',
    flexGrow: 1,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    backgroundColor: colors.light_grey,
    backgroundPosition: 'bottom center',
    backgroundRepeat: 'no-repeat',
  },
  ErrorMessage: {
    ...inputLabel,
    fontSize: '13px',
    color: colors.red,
    width: 'auto',
    backgroundColor: colors.white,
    zIndex: 200,
    padding: '15px 0px 5px 10px',
    transform: 'scaleY(1)',
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  checkButton: {
    color: 'green !important',
  },
  flex: {
    flexGrow: 1,
  },
  hide: {
    display: 'none',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignCenter: {
    textAlign: 'center',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: colors.blue,
    minHeight: '570px',
    position: 'relative',
    height: '100vh',
    borderRadius: '4px',
    boxShadow: '0 10px 14px 0 rgba(0,0,0,0.3)',
  },

  drawerIcon: {
    height: iconHeight,
    padding: '15px 0px 5px 10px',
  },
  drawerDivider: { display: 'none' },
  drawerPaperList: {
    '& span': {
      fontSize: '14px',
      letterSpacing: '0.4px',
      color: '#ffffff',
    },
    '& svg': {
      color: '#ffffff',
    },
  },

  drawerLogout: {
    color: '#fff',
    width: '75%',
    backgroundColor: '#754d85',
    textAlign: 'center',
  },

  tableWrap: {
    width: '100%',
    marginTop: theme.spacing(3),
    overflowX: 'auto',
    overflowY: 'auto',
  },
  table: {
    minWidth: '100%',
  },
  highlight: {
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.07)',
    },
    '&:hover $hoverShow': {
      visibility: 'visible',
    },
  },
  '@media (max-width: 375px)': {
    hideOnMobile: {
      display: 'none',
    },
    width25Perct: {
      width: '35%',
    },
    hoverShow: {
      visibility: 'visible',
      '&:nth-child(2)': {
        marginLeft: '5px',
      },
    },
  },
  '@media (min-width: 376px)': {
    width25Perct: {
      width: '25%',
    },
    hoverShow: {
      visibility: 'hidden',
      marginLeft: '10px',
    },
  },
  '@media (min-height: 568px)': {
    drawerDivider: {
      display: 'block',
      backgroundColor: '#442d4e',
      height: '2px',
    },
    stickyBottom: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alighItems: 'center',
      marginBottom: '5px',
      width: 'auto',
      backgroundColor: 'red',
    },
    scrollDrawer: {
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
      alighItems: 'center',

      // maxHeight: '80vh',
    },
  },

  '@media (min-width: 960px)': {
    contentWithMargin: {
      marginLeft: drawerWidth,
      position: 'relative',
    },
    drawerIcon: {
      height: '95px',
      padding: '25px 20px 25px 20px',
    },
  },
  btnNoStyle: {
    color: 'inherit',
    cursor: 'pointer',
    margin: 0,
    border: 0,
    display: 'inline - flex',
    padding: '0 !important',
    outline: 'none',
    position: 'relative',
    userSelect: 'none',
    alignItems: 'center',
    borderRadius: 0,
    verticalAlign: 'middle',
    justifyContent: 'center',
    appearance: 'none',
    textDecoration: 'none',
    backgroundColor: 'transparent',
    // -webkit-tap-highlight-color: transparent,
    '&:hover': {
      backgroundColor: 'unset !important',
      padding: '0 !important',
    },
  },
  border50: {
    'border-radius': '50%',
  },
  hoverShow: {
    height: '30px',
    width: '30px',
    padding: '4px',
    color: 'rgba(0,0,0,0.87)',
    opacity: '0.8',
    verticalAlign: 'middle',
    '&:focus, &:active': {
      backgroundColor: 'rgba(0,0,0,.1)',
      backgroundSize: '30px 30px',
      borderRadius: '50%',
      height: '30px',
      width: '30px',
      padding: '4px',
    },
  },
  textFieldWide: {
    width: '100%',
  },
  fixedBottomRightBtn: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  card: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardcontent: {
    flex: '1 0 auto',
  },
  cover: {
    width: 151,
    height: 151,
  },
  controls: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  receiptIcon: {
    height: 72,
    width: 72,
  },
  categoryItem: {
    paddingLeft: 3 * theme.spacing(1),
  },
  categoryGroup: {
    fontWeight: 900,
    opacity: 1,
  },

  selctScrollWrap: {
    overflowY: 'scroll',
    maxHeight: '400px',
    width: '100%',
    margin: '20px 10px',
  },

  scrollAreaContent: {
    minHeight: '410px',
  },
  scrollbar: {
    width: '100px',
  },
  selectCompanyContainer: {
    display: 'flex',
    margin: '10px',
    padding: '10px',
    border: '1px solid grey',
    width: '100%',
    flexDirection: 'row',
    borderRadius: '4px',
    alignItems: 'center',
  },
  midText: {
    fontSize: '15px',
    color: 'grey',
  },
  selectButton: {
    borderRadius: '4px',
    width: '100%',
    backgroundColor: '#d8d6d6',
  },
  noResults: {
    display: 'flex',
    margin: '10px',
    padding: '10px',
    border: '1px solid red',
    width: '100%',
    flexDirection: 'row',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'center',
  },
  actionButton: {
    border: '1px solid grey',
    width: '100%',
    padding: '1% 0',
    borderRadius: '4px',
    color: 'black',
    marginTop: '10px',
  },
  itemOptionButton: {
    border: '1px solid #e2e2e2',
    color: '#898989',
    backgroundColor: '#ffffff',
    textAlign: 'center',
    marginRight: '15px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  noPadding: {
    padding: '0px',
    margin: '0px',
    textAlign: 'center',
    width: '5%',
  },
  noMargin: {
    margin: 'auto 0px auto 0px',
  },
  chip: {
    margin: theme.spacing(0.25),
  },
  width15: {
    width: '15%',
  },
  disableFiels: {
    pointerEvents: 'none',
    cursor: 'pointer',
    color: '#c4c4c4',
  },
  addButton: {
    width: '50px',
    height: '50px',
    borderRadius: '50%',
    backgroundColor: 'red',
    color: 'white',
    border: '1px solid rgba(117, 77, 133, 0.27)',
    boxShadow: '1px 4px 4px 0 rgba(117, 77, 133, 0.27)',
    fontSize: '35px',
    textAlign: 'center',
    display: 'inline-flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  whiteText: {
    color: 'white',
    fontWeight: 'bold',
  },

  sideBarRightContainer: {
    // position: 'fixed',
    width: '150px',
    backgroundColor: 'pink',
  },
  drawerRight: {
    width: '200px',
    minHeight: '570px',
    borderRadius: '4px',
  },
  fuzzySearchElement: {
    padding: 10,
    color: 'black',
    '&:hover': {
      backgroundColor: 'blue !important',
      color: 'white',
      cursor: 'pointer',
    },
  },
  hoverPointer: {
    '&hover:': {
      hover: 'pointer',
    },
  },
  refreshIcon: {
    color: colors.fillsDarkGrey,
    transform: 'rotate(0deg)',
    fontWeight: 'bold',
    padding: '12px',
    fontSize: '25px',
  },
  refreshIconClicked: {
    transform: 'rotate(180deg)',
    transition: 'all 1s ease',
  },

  cursorPointer: {
    cursor: 'pointer',
  },

  secondaryLight: {
    color: colors.green,
    '&:hover': {
      color: 'rgba(2, 203, 205,0.6)',
      cursor: 'pointer',
    },
    '&:active': {
      color: 'rgba(2, 203, 205,0.6)',
    },
    '&:focus': {
      color: colors.green,
    },
  },

  secondaryLightDisabled: {
    color: colors.fillsGrey,
    pointerEvents: 'none',
    cursor: 'pointer',
    '&:active': {
      color: colors.fillsGrey,
      pointerEvents: 'none',
      cursor: 'pointer',
    },
    '&:focus': {
      color: colors.fillsGrey,
      pointerEvents: 'none',
      cursor: 'pointer',
    },
  },

  displayFlex: {
    display: 'flex',
  },

  '@media (max-width: 960px)': {
    appFlexPDF: {
      display: 'flex',
      width: '100vw',
      flexDirection: 'coulumn',
    },
    mainWrapPDF: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    mainBodyWrapPdf: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
    },
  },
});

export default styles;
