import * as yup from 'yup';

const validationSchema = () =>
  yup.object().shape({
    name: yup.string().required('Ce champ ne doit pas être vide'),
    client_id: yup.string().required('Ce champ ne doit pas être vide'),
    ht: yup.number().required('Ce champ est obligatoire'),
    tva: yup.number().required('Ce champ est obligatoire'),
    ttc: yup.number().required('Ce champ est obligatoire'),
    revisedHT: yup.number().required('Ce champ est obligatoire'),
    revisedTVA: yup.number().required('Ce champ est obligatoire'),
    revisedTTC: yup.number().required('Ce champ est obligatoire'),
    budget: yup.number().required('Ce champ est obligatoire'),
    budgetRevised: yup.number().required('Ce champ est obligatoire'),
    status: yup.number().required('Ce champ ne doit pas être vide'),
    // purchaseOrderNumber: yup
    //   .string()
    //   .required('Ce champ ne doit pas être vide'),
  });

export default validationSchema;
