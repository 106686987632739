// eslint-disable-next-line import/prefer-default-export
export const TIME_FORMAT = {
  DAY: 'dd-MM-yyyy',
  MONTH: 'MM-yyyy',
  YEAR: 'yyyy',
  DATETIME: 'dd-MM-yyyy HH:mm:ss',

  Y_M_D: 'yyyy-MM-dd',
  D_M_Y: 'dd/MM/yyyy',
};
