import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import AppButton from '../../../components/AppButton';

const Buttons = () => {
  const { isSubmitting, handleSubmit, setSubmitting } = useFormikContext();
  const { t } = useTranslation();
  const { error } = useSelector((state) => state.revenues);
  useEffect(() => {
    if (error) {
      setSubmitting(false);
    }
  }, [error]);
  return (
    <div style={{ paddingTop: '20px' }}>
      <Tooltip arrow title={t('revenues.tooltip.revenue.save')} placement="top">
        <span>
          <AppButton
            onClick={handleSubmit}
            type="button"
            fullWidth={true}
            text={t('revenues.revenue.save')}
            color="primary"
            noBorder={true}
            isDisabled={isSubmitting}
          />
        </span>
      </Tooltip>
    </div>
  );
};

Buttons.propTypes = {
  dummy: PropTypes.string,
};

export default Buttons;
