import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appSelectButton';
import '../../assets/css/icons.css';

const AppSelectButton = ({
  text,
  toggleDropdown,
  classes,
  color,
  selectOpened,
  noBorder,
  showError,
  errorMessage,
  mandatory,
  noErrowBorder,
  inputDisplay,
  valueSelected,
  ...rest
}) => {
  const btnSelectClasses = classNames({
    [classes.selectButton]: true,
    [classes.noBorder]: noBorder,
    [classes[`${color}InputDisplay`]]: inputDisplay,
    [classes[`${color}InputDisplayOpened`]]: inputDisplay && selectOpened,
    [classes[color]]: color,
    [classes[`${color}Selected`]]: valueSelected != null,
    [classes[`${color}SelectOpened`]]: selectOpened,
    [classes.errorButton]: showError && !selectOpened && !noErrowBorder,
  });
  const errorMessageClasses = classNames({
    [classes.errorMessage]: !selectOpened,
  });
  const iconClasses = classNames({
    'icon icon-arrow-up': selectOpened,
    'icon icon-arrow-down': !selectOpened,
    [classes.iconInput]: inputDisplay,
    [classes.iconSelectOpened]: selectOpened,
  });

  return (
    <>
      <button
        type="button"
        className={btnSelectClasses}
        onClick={toggleDropdown}
        {...rest}
      >
        <p style={{ marginRight: '3px' }}>
          {text}
          {mandatory}
        </p>

        <span style={{ paddingTop: '5px' }} className={iconClasses} />
      </button>

      {showError && errorMessage && (
        <div className={errorMessageClasses}>{errorMessage}</div>
      )}
    </>
  );
};

AppSelectButton.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  onClick: PropTypes.func,
  toggleDropdown: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  selectOpened: PropTypes.bool.isRequired,
  noBorder: PropTypes.bool,
  color: PropTypes.string,
  form: PropTypes.object,
  field: PropTypes.object,
  showError: PropTypes.bool,
  noErrowBorder: PropTypes.bool,
  mandatory: PropTypes.bool,
  inputDisplay: PropTypes.bool,
  errorMessage: PropTypes.string,
  valueSelected: PropTypes.any,
};
AppSelectButton.defaultProps = {
  // isDisabled: false,
  noErrowBorder: false,
  color: 'primary',
  noBorder: false,
  inputDisplay: false,
  form: null,
  field: null,
  showError: false,
  mandatory: false,
  errorMessage: null,
};

export default withStyles(style)(AppSelectButton);
