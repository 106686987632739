import React, { Fragment, useState, useCallback } from 'react';

import Layout from '../../../components/Layout';
import ProviderImportedBody from '../Table/ProviderImportedBody';
import ProviderImportedHeader from '../components/ProviderImportedHeader';
import ProviderImportedFormDialog from '../components/ProviderImportedFormDialog';

const ProviderList = () => {
  const [selectedProvider, setSelectedProvider] = useState({});
  const [showFormDialog, setShowFormDialog] = useState(false);

  function openProviderFormDialog(provider) {
    setShowFormDialog(true);
    setSelectedProvider(provider);
  }

  const closeProviderFormDialog = useCallback(() => {
    setShowFormDialog(false);
    setSelectedProvider(null);
  }, [showFormDialog, selectedProvider]);

  const handleUpdateProvider = (provider) => {
    setSelectedProvider(provider);
  };

  function renderBody() {
    return (
      <Fragment>
        <ProviderImportedFormDialog
          showDialog={showFormDialog}
          closeProviderFormDialog={closeProviderFormDialog}
          selectedProvider={selectedProvider}
          handleUpdateProvider={handleUpdateProvider}
        />

        <ProviderImportedBody
          openProviderFormDialog={openProviderFormDialog}
          updatedProvider={selectedProvider}
        />
      </Fragment>
    );
  }

  return (
    <Layout
      header={<ProviderImportedHeader />}
      showUserCard
      body={renderBody()}
    />
  );
};

export default ProviderList;
