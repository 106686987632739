import colors from '../../../constants/colors.json';
import '../../css/fonts.css';
import { mWhiteBold, mBlueBold } from './appText';

const style = {
  appPlusSelectContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
  plusButton: {
    color: colors.white,
    border: 'none',
    margin: 0,
    textDecoration: 'none',
    height: '50px',
    width: '50px',
    textTransform: 'uppercase',
    borderRadius: '50%',
    backgroundColor: colors.blue,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '0 12px 0 7px',
    justifyContent: 'flex-end',
    transition:
      'width 0.2s ease, padding 0.4s ease, justifyContent 0s ease 0.2s, border-radius 0.1s ease 0.2s',
    '&:hover': {},
    '&:active': {},
    '&:focus': {},
    '&:disabled': {},
  },

  open: {
    width: '250px',
    borderBottomRightRadius: '0',
    borderTopRightRadius: '4px',
    borderBottomLeftRadius: '0',
    borderTopLeftRadius: '4px',
    padding: '0 7px',
    justifyContent: 'space-between',
    transition: 'width 0.4s ease, justifyContent 0s ease 0.4s',
  },

  iconStyle: {
    fontSize: '26px',
    height: '26px',
  },

  cursorPointer: {
    cursor: 'pointer',
  },

  iconOpen: {
    transform: 'rotate(-45deg)',
    transition: 'transform 0.4s ease',
  },

  textStyle: {
    ...mWhiteBold,
    fontSize: '15px',
    transform: 'scaleY(0)',
    margin: 0,
    paddingLeft: '5px',
  },

  textOpen: {
    ...mWhiteBold,
    transform: 'scaleY(1)',
    fontWeight: 'bold',
    transition: 'transform 0s ease 0.4s',
  },

  itemsContainer: {
    position: 'absolute',
    width: '0',
    backgroundColor: colors.white,
    right: '0px',
    top: '55px',
    transition: 'transform 0.2s ease',
    transformOrigin: 'top',
    // height: '0',
    transform: 'scaleY(0)',
    zIndex: 10,
    overflowY: 'hidden',
  },

  itemsContainerOpen: {
    zIndex: 1000,
    borderBottom: '2px solid #3d319d',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
    padding: '10px 3px',
    // height: '195px',
    transform: 'scaleY(1)',
    width: '100%',
    transition:
      // 'height 0.5s ease 0s, padding 0.2s ease 0.1s, width 0.4s ease 0s',
      'transform 0.5s ease 0s, padding 0.2s ease 0.1s, width 0.2s ease 0s',
  },

  itemButton: {
    ...mBlueBold,
    textTransform: 'uppercase',
    border: 'none',
    margin: 0,
    textDecoration: 'none',
    height: '35px',
    width: '100%',
    backgroundColor: colors.white,

    textAlign: 'left',
    '&:hover': {
      backgroundColor: 'rgba(61, 49, 157,0.8)',
      cursor: 'pointer',
      color: colors.white,
    },
    '&:active': {
      ...mWhiteBold,
      backgroundColor: colors.blue,
      cursor: 'pointer',
    },
    '&:focus': {
      ...mWhiteBold,
      backgroundColor: colors.blue,
      cursor: 'pointer',
    },
  },
};

export default style;
