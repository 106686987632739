/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import AppButton from 'components/AppButton';
import { useHistory } from 'react-router-dom';
import AppSubscriptions from 'components/AppSubscriptions';
import subscriptionService from 'services/SubscriptionService';
import spinnerService from 'services/SpinnerService';
import { useSelector } from 'react-redux';

const StripePricingTablePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const appMode = process.env.REACT_APP_MODE;
  const companyId = useSelector(
    (state) => state.loggedUserCompany?.company?._id
  );

  const checkoutSubscription = async (subscriptionId) => {
    spinnerService.resetSpinner();
    try {
      spinnerService.startSpinner();
      const res = await subscriptionService.checkoutSubscription(
        subscriptionId,
        companyId
      );
      if (res) {
        window.open(res, '_self');
      }
    } catch (error) {
      console.log(error);
    } finally {
      spinnerService.resetSpinner();
    }
  };

  const handleChoosePricing = (subscriptionId) => {
    if (subscriptionId) {
      // checkoutSubscription(subscriptionId);
      if (!companyId) {
        history.push(`/signup?subscription_id=${subscriptionId}`);
      } else {
        checkoutSubscription(subscriptionId);
      }
    }
  };

  return (
    <>
      <AppSubscriptions handleChoosePricing={handleChoosePricing} />
    </>
  );
};

export default StripePricingTablePage;
