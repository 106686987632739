import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  AppTable,
  AppTableHead,
  AppTableRow,
  AppTableCell,
} from '../../../../../components/AppTableComponents';

import ItemsTableBody from './ItemsTableBody';

const ItemsTable = () => {
  const { t } = useTranslation();

  return (
    <>
      <div>
        <AppTable>
          <AppTableHead
            greyHeader
            useStyle={{ width: '100%', tableLayout: 'fixed' }}
          >
            <AppTableRow>
              <AppTableCell
                setWidthManualy="15%"
                alignLeft
                text={t('item.name')}
              />
              <AppTableCell
                setWidthManualy="10%"
                alignLeft
                text={t('item.description')}
              />
              <AppTableCell
                setWidthManualy="15%"
                alignCenter
                text={t('item.unit_price')}
              />
              <AppTableCell
                setWidthManualy="8%"
                alignCenter
                text={t('item.quantity')}
              />
              <AppTableCell
                setWidthManualy="100px"
                alignCenter
                text={t('item.discount')}
              />
              <AppTableCell
                setWidthManualy="15%"
                alignCenter
                text={t('item.net')}
              />
              <AppTableCell
                setWidthManualy="70px"
                alignCenter
                text={t('item.vat')}
              />
              <AppTableCell
                setWidthManualy="15%"
                alignCenter
                text={t('item.gross')}
              />
              <AppTableCell setWidthManualy="40px" />
            </AppTableRow>
          </AppTableHead>
        </AppTable>
      </div>
      <div style={{ overflowY: 'auto', maxHeight: '200px' }}>
        <AppTable>
          <ItemsTableBody />
        </AppTable>
      </div>
    </>
  );
};

export default ItemsTable;
