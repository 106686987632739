import { titleText, inputTextBold } from './appText';
import colors from '../../../constants/colors.json';

const style = {
  rowContainer: {
    ...titleText,
    width: '270px',
    height: '50px',
    backgroundColor: colors.white,
  },
  lineDisplay0: {
    height: '1px',
    width: '70%',
    borderBottom: '4px solid #02CBCD',
  },
  lineDisplay1: {
    height: '1px',
    width: '70%',
    borderBottom: '4px solid #3d319d',
  },
  lineDisplayText: {
    ...inputTextBold,
  },
  graphStyle: {
    '& .ct-series-a': {
      '& .ct-point': { stroke: '#02CBCD' },
      '& .ct-line': { stroke: '#02CBCD' },
    },
    '& .ct-series-b': {
      '& .ct-point': { stroke: '#3d319d' },
      '& .ct-line': { stroke: '#3d319d' },
    },
    '& .ct-label': {
      height: '11px',
      width: '35px',
      color: '#888888',
      fontFamily: 'Kumbh Sans',
      fontSize: '10px',
      lineHeight: '11px',
    },
    '& .ct-grid': {
      stroke: ' #B2B2B2',
      strokeDasharray: 0,
    },
  },
};

export default style;
