import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ChromePicker } from 'react-color';

class ColorButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      color: this.props.color,
      displayColorPicker: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  handleClick() {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  }

  handleClose() {
    this.setState({ displayColorPicker: false });
  }

  handleChange(color) {
    this.setState({ color: color.hex });
    this.props.onColorChange(color.hex);
  }

  render() {
    const popover = {
      position: 'absolute',
      zIndex: '2',
    };
    const cover = {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
    };
    return (
      <div>
        <button type="button" onClick={this.handleClick}>
          {this.props.title}
        </button>
        {this.state.displayColorPicker ? (
          <div style={popover}>
            <div style={cover} onClick={this.handleClose} />
            <ChromePicker
              color={this.state.color}
              disableAlpha={true}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

ColorButton.propTypes = {
  title: PropTypes.string,
  color: PropTypes.string,
  onColorChange: PropTypes.func,
};

export default ColorButton;
