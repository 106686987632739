const formState = {
  name: '',
  client_id: '',
  ht: 0,
  tva: 0,
  ttc: 0,
  revisedHT: 0,
  revisedTVA: 0,
  revisedTTC: 0,
  budget: 0,
  budgetRevised: 0,
  estimatedCost: 0,
  status: 0,
  purchaseOrderNumber: '',
};

export default formState;
