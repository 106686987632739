import _ from 'lodash';

import {
  FETCH_EXPENSES,
  FETCH_EXPENSES_FAILED,
  FETCH_EXPENSE,
  LOGOUT_USER_SUCCESS,
  CREATE_EXPENSE,
  UPDATE_EXPENSE,
  DELETE_EXPENSE,
  PENDING_EXPENSE,
  VALIDATE_EXPENSE,
  CANCEL_EXPENSE,
  CLEAR_EXPENSE,
  PAY_EXPENSE,
  LOGGED_USER_COMPANY_SELECTED,
  ADD_TRANSFERS_TO_EXPENSE,
} from '../../actions/Types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_EXPENSES:
      return _.mapKeys(action.payload, '_id');
    case FETCH_EXPENSE: {
      const expense = action.payload;

      return { [expense._id]: expense, ...state };
    }
    case CLEAR_EXPENSE: {
      return {};
    }
    case CREATE_EXPENSE: {
      return { [action.payload._id]: action.payload, ...state };
    }
    case UPDATE_EXPENSE:
    case PENDING_EXPENSE:
    case VALIDATE_EXPENSE:
    case CANCEL_EXPENSE:
    case PAY_EXPENSE: {
      const newState = { ...state };
      newState[action.payload.id] = {
        ...newState[action.payload.id],
        ...action.payload.expense,
      };

      return newState;
    }
    case DELETE_EXPENSE: {
      const newState = { ...state };
      newState[action.payload] = {
        ...newState[action.payload],
        deleted: true,
      };

      return newState;
    }
    case ADD_TRANSFERS_TO_EXPENSE: {
      const { expenseOId, transfers } = action.payload;
      const newState = { ...state };
      newState[expenseOId] = { ...newState[expenseOId], transfers };

      return newState;
    }

    case FETCH_EXPENSES_FAILED:
      return {};
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return {};
    default:
      return state;
  }
};
