import { bindActionCreators } from 'redux';
import { fetchItems, deleteItem } from '../../../actions/ItemsActions';

export default (dispatch) =>
  bindActionCreators(
    {
      fetchItems,
      deleteItem,
    },
    dispatch
  );
