import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, Typography, useTheme } from '@material-ui/core';
import { getUnixTime, differenceInMinutes } from 'date-fns';
import { useSelector } from 'react-redux';
import queryString from 'query-string';
import { makeStyles } from '@material-ui/core/styles';

/* Import custom hooks */
import { useRouter } from 'hooks/routerHooks';

/* Import components */

import CircularProgressCentered from 'components/CircularProgressCentered';

/* Import services */
import InvoiceService from 'services/InvoiceService';
import AppDashboardCard from 'components/AppDashboard/components/AppDashboardCard';
import AppDashboardListItem from 'components/AppDashboard/components/AppDashboardListItem';
import FiveBiggestCard from 'components/AppDashboard/components/FiveBiggestCard';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    minHeight: 300,
  },
}));

const LatePaymentOverview = () => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const clients = useSelector((state) => state.clients);
  const theme = useTheme();
  const { t } = useTranslation();
  const { history } = useRouter();
  const [error, setError] = useState(null);
  const [top10HighestLateClients, setTopHighestLateClients] = useState([]);
  const agingBalance = useSelector((state) => state.agingBalance);

  const navigatePage = (url, queryParams) => {
    history.push(
      queryString.stringifyUrl(
        {
          url,
          query: queryParams,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  const formatInvoiceState = (inv) => {
    const tmp = inv.payments;
    let youngestDate;
    tmp.forEach((element) => {
      if (
        !youngestDate ||
        getUnixTime(youngestDate) < getUnixTime(element.date)
      ) {
        youngestDate = element.date;
      }
    });
    if (youngestDate) {
      if (differenceInMinutes(new Date(), new Date(youngestDate)) >= 0) {
        return 'overdue';
      }
      return inv.state;
    }
    return inv.state;
  };

  const fetchAllInvoices = async () => {
    try {
      setLoading(true);
      const response = await (
        await InvoiceService.fetchInvoicePaginate('pagination=false')
      ).data.docs;

      const lateAndDebtsInvoices = response
        .filter(
          (invoice) =>
            invoice.type === 'invoice' &&
            (invoice.state === 'overdue' || invoice.state === 'validated') &&
            invoice.client.display_name
        )
        .map((item) => ({ ...item, state: formatInvoiceState(item) }));

      const lateInvoices = lateAndDebtsInvoices.filter(
        (item) => item.state === 'overdue'
      );

      const lateInvoicesReduce = [
        ...lateInvoices
          .reduce((acc, current) => {
            const { client } = current;
            const grouped = acc.get(client._id);
            if (!grouped) {
              acc.set(client._id, {
                client_id: client._id,
                gross_total: current.gross_total,
                name: client.name || clients.data[current.client_id]?.name,
              });
            } else {
              acc.set(client._id, {
                ...grouped,
                gross_total: grouped.gross_total + current.gross_total,
              });
            }
            return acc;
          }, new Map())
          .values(),
      ];

      const getTop10HighestLateTimes = lateInvoicesReduce
        .sort((first, second) => second.gross_total - first.gross_total)
        .slice(0, 9);

      setTopHighestLateClients([...getTop10HighestLateTimes]);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllInvoices();
  }, []);

  const getElementAtEvent = (selectClient) => {
    if (selectClient) {
      if (selectClient.name === selectClient.client_id) {
        setError(
          'This customer details cannot be opened because the customer name is empty'
        );
        return;
      }
      const invoiceFilters = {
        client: [selectClient.client_id],
        state: ['overdue'],
      };
      history.push(
        queryString.stringifyUrl(
          { url: 'invoices/list', query: invoiceFilters },
          {
            arrayFormat: 'index',
          }
        )
      );
    }
  };

  return (
    <AppDashboardCard
      title={t('dasboard.top_10_late')}
      titleColor={theme.palette.red[500]}
    >
      {error && <Typography color="error">{error}</Typography>}
      {!loading ? (
        <Box className={classes.container}>
          <List style={{ padding: 0 }}>
            {top10HighestLateClients?.length > 0 &&
              top10HighestLateClients.map((client, index) => {
                return (
                  <AppDashboardListItem
                    key={index}
                    titleLeft={client?.name}
                    titleRight={`${client?.gross_total}€`}
                    onClick={() => getElementAtEvent(client)}
                  />
                );
              })}
          </List>
          {agingBalance?.agingBalanceOverdue?.data && (
            <FiveBiggestCard
              titleLeft={`${t('agingBalance.overdue.fiveBiggest')} :`}
              titleRight={`${agingBalance?.agingBalanceOverdue?.data?.fiveBiggest} €`}
              bgColor={theme.palette.red[50]}
              textColor={theme.palette.red[500]}
              onClick={() => {
                const invoiceFilters = {
                  state: ['overdue'],
                  type: ['invoice', 'pre_payment'],
                  pageSize: 5,
                  sortByPrice: true,
                };
                navigatePage('invoices/list', invoiceFilters);
              }}
            />
          )}
        </Box>
      ) : (
        <CircularProgressCentered />
      )}
    </AppDashboardCard>
  );
};

export default LatePaymentOverview;
