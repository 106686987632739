/* Packages */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';

/* Icons */
import AddCircleIcon from '@material-ui/icons/AddCircle';

/* Styles */
import useStyles from './styles';

/* Styled Components */
import { StyledMenu } from '../AccountActionNewTransfer/styledComponents';

const AccountActionSubmenu = (props) => {
  const { title, subItems } = props;
  const { t } = useTranslation();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState(null);

  const onClickButton = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const onCloseParent = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={onClickButton}
        className={classes.actionNewTransferButton}
      >
        <AddCircleIcon style={{ marginRight: '10px' }} />
        {t(`bank_bbf.${title}`)}
      </Button>

      <StyledMenu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={onCloseParent}
      >
        {subItems?.map((item) => (
          <MenuItem key={item.title} onClick={item.handler}>
            {t(`bank_bbf.${item.title}`)}
          </MenuItem>
        ))}
      </StyledMenu>
    </>
  );
};

AccountActionSubmenu.propTypes = {
  subItems: PropTypes.array,
  title: PropTypes.string,
};

export default AccountActionSubmenu;
