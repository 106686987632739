import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useRouter } from 'hooks/routerHooks';
import { TreezorFormContextProvider } from 'contexts/TreezorContext/TreezorFormContext';
import Header from 'components/Header';
import Layout from 'components/Layout';
import { EMPLOYE } from 'constants/roles';
import NewUserAfterOnboardingContainer from './newUserAfterOnboardingContainer';

const CreateOnlyNewTreezorUser = () => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const { loggedUserCompany, users } = useSelector((reduxState) => reduxState);
  const { id } = useParams();

  const renderBody = () => {
    return (
      <TreezorFormContextProvider
        value={{
          company: loggedUserCompany.company,
          user: [users[id]],
          showAddCollaborateur: false,
          activeStep: 0,
          role: EMPLOYE,
          rattacheAPersonneMorale: false,
          newUser: false,
          shouldCreateNewUser: false,
        }}
      >
        <NewUserAfterOnboardingContainer />
      </TreezorFormContextProvider>
    );
  };

  return (
    <Layout
      header={
        <Header
          name={t('cards')}
          goBack={() => {
            // eslint-disable-next-line no-restricted-globals
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

export default CreateOnlyNewTreezorUser;
