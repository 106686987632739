/* eslint-disable no-unused-vars */
import { setGlobalError } from 'actions/GlobalError';
import treezorService from '../../services/TreezorService';

const storingHipay = () => ({
  type: 'STORING_HIPAY',
});

const storeHipaySuccess = () => ({
  type: 'STORE_HIPAY_SUCCESS',
});

const storeHipayFailed = (error) => ({
  type: 'STORE_HIPAY_FAILED',
  payload: { error },
});

const storeHipay = (data) => async (dispatch) => {
  try {
    dispatch(storingHipay());
    await treezorService.storeHipay(data);
    dispatch(storeHipaySuccess());
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch(storeHipayFailed({ message: error.message }));
  }
};

export default { storeHipay };
