import React from 'react';
import PropTypes from 'prop-types';
import TextFieldWithAdornment from './TextFieldWithAdornment';
import '../../assets/css/deactivateUpDownArrowInputNumber.css';

const CurrencyTextField = ({
  name,
  currency = '€',
  currencyPosition = 'end',
  ...rest
}) => {
  return (
    <TextFieldWithAdornment
      className="alignRight"
      adornmentText={currency}
      adornmentPosition={currencyPosition}
      step="0.01"
      type="number"
      name={name}
      {...rest}
    />
  );
};

CurrencyTextField.propTypes = {
  name: PropTypes.string.isRequired,
  currency: PropTypes.string,
  currencyPosition: PropTypes.string,
};

export default CurrencyTextField;
