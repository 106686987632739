import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Doughnut } from 'react-chartjs-2';
import { useRouter } from 'hooks/routerHooks';
import { Grid } from '@material-ui/core';
import { AppTextContent, AppTextTitle } from 'components/AppText';
import queryString from 'query-string';
import { EXPENSE_STATE } from 'constants/expense';
import NumberFormat from '../../../helpers/NumberFormat';

const ExpenseDougnut = ({ orange, yellow }) => {
  const { t } = useTranslation();
  const { history } = useRouter();

  /**
   * charge engagee vert
   * t('expense.overview.incurred')
   *
   * depenses a payer orange
   * t('expense.overview.validatedPurchases')
   *
   * notes de frais a rembourser jaune
   * t('expense.overview.validatedExpenses')
   *
   * t('expense.overview.validated')
   * expenseOverview.validatedPurchases + expenseOverview.validatedExpenses || 0
   *  */
  const data = {
    datasets: [
      {
        data: [orange, yellow],
        backgroundColor: ['#fffa4d', '#fdb212'],
      },
    ],

    // These labels appear in the legend and in the tooltips when hovering different arcs
    labels: [
      t('expense.overview.validated_purchases'),
      t('expense.overview.validated_expenses'),
    ],
  };
  const [legend, setLegend] = useState();

  const onRefChange = useCallback((node) => {
    // ref value changed to node
    if (node !== null) {
      setLegend(node.chartInstance.generateLegend());
    }
  }, []);

  const handleLegendClick = (event, datasetIndex, chartEl) => {
    const e = event?.target;
    // get element of custom legend in legendCallback
    const labelEl = document.querySelector(
      `#chart-doughnut-legend-${datasetIndex}-item`
    );
    const isHiddenChartEl = chartEl.getDatasetMeta(0).data[datasetIndex].hidden;
    // eslint-disable-next-line no-param-reassign
    chartEl.getDatasetMeta(0).data[datasetIndex].hidden = !isHiddenChartEl;
    labelEl.style.textDecoration = !isHiddenChartEl ? 'line-through' : 'none';
    e.classList.toggle('crossed-line');
    chartEl.update();
  };

  const options = {
    responsive: true,
    maintainAspectRatio: true,
    rotation: Math.PI,
    legend: {
      display: false,
    },

    // eslint-disable-next-line react/display-name
    legendCallback: (chartEl) => {
      const { labels, datasets } = chartEl.data;
      return (
        <ul
          className="chart-legend "
          style={{ listStyleType: 'none', padding: 0, margin: 0 }}
        >
          {labels &&
            labels.map((_l, i) => (
              <li
                key={i}
                id={`chart-doughnut-legend-${i}-item`}
                onClick={(event) => handleLegendClick(event, i, chartEl)}
                style={{ marginBottom: '10px' }}
              >
                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  {datasets && (
                    <span
                      style={{
                        height: '30px',
                        width: '30px',
                        backgroundColor: datasets[0]?.backgroundColor[i],
                        marginRight: '10px',
                        borderRadius: '50%',
                      }}
                    />
                  )}
                  {labels && (
                    <AppTextContent
                      className="legend-label"
                      styles={{
                        width: 'calc(100% - 40px)',
                        lineHeight: '30px',
                        verticalAlign: 'middle',
                      }}
                    >
                      {labels[i]}
                    </AppTextContent>
                  )}
                </div>
              </li>
            ))}
        </ul>
      );
    },

    onHover: (event, chartElement) => {
      // eslint-disable-next-line no-param-reassign
      event.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    tooltips: {
      callbacks: {
        label: (tooltipItem, mdata) => {
          return ` ${mdata.labels[tooltipItem.index]} ${NumberFormat.n(
            mdata.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              ? mdata.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]
              : 0
          )}€`;
        },
      },
    },
  };

  const navigatePage = (url, queryParams) => {
    history.push(
      queryString.stringifyUrl(
        {
          url,
          query: queryParams,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  return (
    <Grid container spacing={2} justify="space-between" alignItems="center">
      <Grid item xs={12} sm={6} md={4}>
        <AppTextTitle styles={{ fontSize: '24px', marginBottom: '20px' }}>
          {t('dasboard.expenses.title')}
        </AppTextTitle>
        {legend}
      </Grid>
      <Grid item xs={12} sm={6} md={8}>
        <Doughnut
          ref={onRefChange}
          data={data}
          options={options}
          getElementAtEvent={(e) => {
            if (e[0]) {
              if (e[0]._index < 2) {
                const expenseFilters = {
                  state: [EXPENSE_STATE.UPCOMING, EXPENSE_STATE.OVERDUE],
                };
                if (e[0]._index === 0)
                  navigatePage('purchases/list', expenseFilters);
                else navigatePage('expenses/list', expenseFilters);
              } else {
                const expenseFilters = {
                  state: ['paid'],
                };
                navigatePage('purchases/list', expenseFilters);
              }
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

ExpenseDougnut.propTypes = {
  green: PropTypes.number,
  yellow: PropTypes.number,
  orange: PropTypes.number,
};

export default ExpenseDougnut;
