import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'hooks/routerHooks';
import { useParams } from 'react-router-dom';

import Header from 'components/Header';
import Layout from 'components/Layout';
import CardDetails from 'components/CardComponents/CardDetails';
import ActivateCard from 'components/CardComponents/CardCreation/ActivateCard';
import { useSelector } from 'react-redux';

const CardDetail = ({ ...props }) => {
  const { id } = useParams();
  const { history } = useRouter();
  const { t } = useTranslation();
  let isLive = true;

  const card = useSelector((reduxState) => {
    return reduxState.cardReducer.cards.find((e) => e._id === id);
  });

  // Check if card is not undefined
  if (card) {
    isLive = card.isLive;
  }

  const renderBody = () => {
    return (
      <div>
        {isLive ? <CardDetails {...props} /> : <ActivateCard cardId={id} />}
      </div>
    );
  };
  return (
    <Layout
      header={
        <Header
          name={t('card')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

CardDetail.propTypes = {
  props: PropTypes.object,
};

export default CardDetail;
