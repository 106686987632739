import { inputLabel } from './appText';
import colors from '../../../constants/colors.json';

const style = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    textAlign: 'left',
    minHeight: '127px',
    border: '1px solid #C2C2C2',
    borderRadius: '4px',
    backgroundColor: 'white',
    color: 'grey',
    textTransform: 'none',
    zIndex: 10,
  },
  errorButton: {
    border: ' 1px solid #FF2B44',

    '&:active': {
      border: '1px solid #FF2B44',
    },
    '&:focus-within': { border: '1px solid #FF2B44' },
  },

  errorMessage: {
    ...inputLabel,
    color: colors.red,
    textTransform: 'capitalize',
    width: 'auto',
    backgroundColor: colors.white,
    zIndex: 11,
    top: '35px',
    fontWeight: '300',
    padding: '0 3px',
    transform: 'scaleY(1)',
  },
  mGreyText: {
    color: colors.grey,
    lineHeight: '1em',
    fontSize: '0.6428571428571428rem',
    marginLeft: '14px',
  },
};

export default style;
