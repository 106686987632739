import React from 'react';
import PropTypes from 'prop-types';
import AppDialog from '../../../components/AppDialog';
import Form from '../Form';

const RenderFormDialog = ({
  showDialog,
  onClose,
  selected,
  importItem,
  handleUpdateClient,
  t,
}) => {
  if (showDialog) {
    return (
      <AppDialog
        title={t('item.dialog_title')}
        color="secondary"
        iconClose
        closeDialog={() => onClose()}
      >
        <Form
          id={selected}
          closeDialog={() => onClose()}
          importItem={importItem}
          handleUpdateClient={handleUpdateClient}
        />
      </AppDialog>
    );
  }

  return null;
};

RenderFormDialog.propTypes = {
  showDialog: PropTypes.bool,
  onClose: PropTypes.func,
  selected: PropTypes.string,
  t: PropTypes.func,
  importItem: PropTypes.array,
  handleUpdateClient: PropTypes.func,
};

export default RenderFormDialog;
