import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import NewUserForm from 'components/CardComponents/Treezor/NewUserForm/TreezorUserFormFormik';
import { Formik } from 'formik';

const UserFormFormik = ({
  company,
  countryList,
  personelTitle,
  identityList,
  identityList2,
  proofOfAddress,
  user,
  setUser,
  initialValues,
  prepareData,
  validationSchema,
  role,
  submit,
  goBack,
  selectRole,
  rattacheAPersonneMorale,
  setCreateUser,
  companyCredentials,
  treezorUsers,
}) => {
  const init = useRef(true);
  const onSubmit = (values, actions) => {
    actions.setSubmitting(false);
    const formatedData = prepareData(values, role, rattacheAPersonneMorale);
    submit(formatedData);
  };

  useEffect(() => {
    if (init.current) {
      init.current = false;
    } else {
      setUser(null); // reset user after the treezorUser has been updated
    }
  }, [treezorUsers]);

  return (
    <Formik
      initialValues={initialValues(user, company)}
      validationSchema={validationSchema(
        company,
        role,
        rattacheAPersonneMorale
      )}
      onSubmit={onSubmit}
    >
      <NewUserForm
        countryList={countryList}
        personelTitle={personelTitle}
        identityList={identityList}
        identityList2={identityList2}
        proofOfAddress={proofOfAddress}
        companyCredentials={companyCredentials}
        company={company}
        setUser={setUser}
        goBack={goBack}
        selectRole={selectRole}
        setCreateUser={setCreateUser}
      />
    </Formik>
  );
};

UserFormFormik.propTypes = {
  company: PropTypes.object,
  countryList: PropTypes.array,
  personelTitle: PropTypes.array,
  identityList: PropTypes.array,
  identityList2: PropTypes.array,
  proofOfAddress: PropTypes.array,
  companyCredentials: PropTypes.array,
  setUser: PropTypes.func,
  user: PropTypes.object,
  validationSchema: PropTypes.func,
  initialValues: PropTypes.func,
  prepareData: PropTypes.func,
  role: PropTypes.string,
  submit: PropTypes.func,
  goBack: PropTypes.func,
  selectRole: PropTypes.func,
  setCreateUser: PropTypes.func,
  rattacheAPersonneMorale: PropTypes.bool,
  treezorUsers: PropTypes.array,
};

export default UserFormFormik;
