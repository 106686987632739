import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import { renderToString } from 'react-dom/server';
import PropTypes from 'prop-types';
import { getThemeColor } from 'helpers/utils';
import Fonts from 'config/fontFamily';

import ReactApexChart from 'react-apexcharts';
import ApexChartToolTip from './Tooltip';

const ApexChart = ({ options, height, ...rest }) => {
  const theme = useTheme();

  const ChartOptions = {
    ...options,
    tooltip: {
      custom(tooltipProps) {
        return renderToString(<ApexChartToolTip {...tooltipProps} />);
      },
      ...options.tooltip,
    },
    legend: {
      fontSize: '12px',
      fontWeight: 500,
      labels: {
        colors: getThemeColor(theme.palette.neutral[500]),
      },
      ...options.legend,
    },
    chart: {
      ...options.chart,
      fontFamily: Fonts.primary,
    },
  };
  return (
    <Box style={{ width: '100%', height: height || '100%' }}>
      <ReactApexChart options={ChartOptions} height={'100%'} {...rest} />
    </Box>
  );
};

ApexChart.propTypes = {
  type: PropTypes.oneOf([
    'line',
    'area',
    'bar',
    'pie',
    'donut',
    'radialBar',
    'scatter',
    'bubble',
    'heatmap',
    'candlestick',
    'boxPlot',
    'radar',
    'polarArea',
    'rangeBar',
    'rangeArea',
    'treemap',
  ]),
  series: PropTypes.object,
  options: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default ApexChart;
