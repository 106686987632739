/* Packages */
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  cardWrapper: {
    padding: '10px 15px',
    height: '100%',
  },

  cardTitleWrapper: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    padding: '5px 0 15px',
  },

  cardConditionWrapper: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'column',
    padding: '5px 0 20px',
  },

  successIcon: {
    color: '#4caf50',
  },

  errorIcon: {
    color: '#f44336',
  },

  cardTitle: {
    fontSize: '19px',
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },

  cardTitleIcon: {
    fontSize: '24px',
    color: '#3E319D',
  },
}));

export default useStyles;
