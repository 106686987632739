import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Divider, Grid } from '@material-ui/core';
import UploadFile from 'components/UploadFile/formWrapper';
import { find } from 'lodash';
import SelectAutocomplete from 'components/SelectAutocomplete';
import DatePicker from 'components/FormElementsDesigned/DatePicker';
import AppCheckbox from 'components/AppCheckbox';
import TextField from 'components/FormElements/textField';
import { filteredTree } from 'helpers/CategoryHelpers';
import { EXPENSE_TYPE } from 'constants/expense';
import ProviderInfo from '../../../../components/Expenses/NewForm/ProviderInfo';
import ExpenseInfo from '../../../../components/Expenses/NewForm/ExpenseInfo';
import DistanceInfo from '../../../../components/Expenses/NewForm/DistanceInfo';
import PurchasePriceItem from './PurchasePriceItem';

const PurchaseForm = ({
  defaultTaxes,
  readOnly,
  paymentTypes,
  selectedCategory,
  toggleNewProviderDialog,
  toggleClientFormDialog,
  setOpenConfirmScan,
  onOcr,
  ocrError,
}) => {
  const { t } = useTranslation();
  const tree = useSelector((state) => state.categories.tree || []);
  const categories = useMemo(() => filteredTree(tree, t), [tree]);
  const { values, isSubmitting, setFieldValue, errors } = useFormikContext();

  const {
    date,
    // eslint-disable-next-line camelcase
    due_date,
    payment_type: paymentType,
    is_intern: intern,
    is_billable: billable,
    providerName,
  } = values;

  function changeDate(value) {
    setFieldValue('date', value);
  }

  function changeDueDate(value) {
    setFieldValue('due_date', value);
  }

  function changePaymentType(paymTypeClicked) {
    if (!paymTypeClicked) {
      // the user cleared the selection - we do nothing. This will force the user to select a new value
      return;
    }
    setFieldValue('payment_type', paymTypeClicked._id);
  }

  function setIntern({ target: { checked } }) {
    setFieldValue('is_intern', checked);

    if (checked) {
      setFieldValue('is_billable', false);
      setFieldValue('client_id', null);
      setFieldValue('project_id', null);
    } else {
      setFieldValue('internal_activity', null);
    }
  }

  function setBillable({ target: { checked } }) {
    setFieldValue('is_billable', checked);
  }

  function renderPaymentType() {
    return (
      <SelectAutocomplete
        required
        name={`payment_type`}
        label={t('expenses.expense.form_paymenttype')}
        getOptionLabel={(elem) => elem.display}
        onChange={changePaymentType}
        values={paymentTypes}
        valueSelected={find(paymentTypes, (type) => type._id === paymentType)}
      />
    );
  }

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
      {/* upload file */}
      <Grid item xs={6}>
        {values.uploadError && (
          <span style={{ color: 'red' }}>{t('uploadError')}</span>
        )}

        {errors?.filename && (
          <span style={{ color: 'red' }}>
            {t('expenses.expense.receipt_upload_mandatory')}
          </span>
        )}

        <UploadFile
          label={t('expenses.expense.receipt')}
          withOcr
          defaultTaxes={defaultTaxes}
          isPreviewFile
          toggleNewProviderDialog={toggleNewProviderDialog}
          toggleClientFormDialog={toggleClientFormDialog}
          isPurchase
          canAsyncScan
          setOpenConfirmScan={setOpenConfirmScan}
          onOcr={onOcr}
          ocrError={ocrError}
        />
      </Grid>
      {/* This row is on 1 line on bigger screen but on 2 lines on smaller device */}
      <Grid item xs={6}>
        <ProviderInfo type={EXPENSE_TYPE.PURCHASE} />

        <Field
          type="date"
          value={date}
          name={`date`}
          label="Date"
          keyboard={true}
          component={DatePicker}
          onChange={changeDate}
        />
        {renderPaymentType()}
        <AppCheckbox
          checked={intern}
          onClick={setIntern}
          labelText={t('expenses.expense.form_isIntern')}
          name={`is_intern`}
          id={`is_intern`}
          disabled={isSubmitting || billable}
        />
        <AppCheckbox
          checked={billable}
          onClick={setBillable}
          labelText={t('expenses.expense.form_isBbillable')}
          name={`is_billable`}
          id={`is_billable`}
          disabled={isSubmitting || intern}
        />
        <TextField
          name={`billNumber`}
          label={t('expenses.expense.form_bill_number')}
          keyboard={true}
          onChange={(e) => {
            setFieldValue('billNumber', e.target.value);
            setFieldValue(
              'description',
              `${providerName}${
                e.target.value !== '' ? ` N° ${e.target.value}` : ''
              }`
            );
          }}
        />
        <TextField
          name={`reference`}
          label={t('expenses.expense.form_reference')}
          keyboard={true}
          onChange={(e) => setFieldValue('reference', e.target.value)}
        />
        <TextField
          name={`orderNumber`}
          label={t('expenses.expense.form_purchase_order_number')}
          onChange={(e) => setFieldValue('orderNumber', e.target.value)}
          keyboard={true}
        />
        <Field
          type="date"
          // eslint-disable-next-line camelcase
          value={due_date}
          name={`due_date`}
          label={t('expenses.expense.form_due_date')}
          keyboard={true}
          component={DatePicker}
          required
          onChange={changeDueDate}
        />
        <Divider style={{ margin: '10px 0' }} />
        <ExpenseInfo />
        <Divider style={{ margin: '10px 0' }} />
        <DistanceInfo selectedCategory={selectedCategory || {}} />
        <PurchasePriceItem
          defaultTaxes={defaultTaxes}
          readOnly={readOnly}
          categories={categories}
        />
      </Grid>
    </Grid>
  );
};

PurchaseForm.propTypes = {
  isPurchase: PropTypes.bool,
  defaultTaxes: PropTypes.array,
  readOnly: PropTypes.bool,
  paymentTypes: PropTypes.array,
  onSelectFormType: PropTypes.func,
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.func,
  toggleNewProviderDialog: PropTypes.func,
  toggleClientFormDialog: PropTypes.func,
  setOpenConfirmScan: PropTypes.func,
  onOcr: PropTypes.func,
  ocrError: PropTypes.object,
};

export default PurchaseForm;
