import { setGlobalError } from 'actions/GlobalError';
import {
  FETCH_KYC_USER,
  FETCH_KYC_USER_FAILED,
  FETCH_USER_TREEZOR,
  FETCH_USER_TREEZOR_FAILED,
  FETCH_PERSON_USER_TREEZOR,
  FETCH_PERSON_USER_TREEZOR_FAILED,
  UPDATE_TREEZOR_DOC,
  CREATE_TREEZOR_USER_FAILED,
  FETCHING_TREEZOR_USERS,
  ONBOARDING_TREEZOR,
  FETCH_WALLET,
  FETCH_TREEZOR_USERS_SUCCESS,
} from 'actions/Types';
import { fetchTreezorUsers } from '../UserActions';
import service from '../../services/TreezorService';
import userServices from '../../services/UserService';

export const fetchUserTreezor = () => async (dispatch, getState) => {
  try {
    const { userId } = getState().wallet.wallet[0]; // TODO selector like selectLoggedUserCompanyId
    const user = await service.fetchUserTreezor(userId);
    dispatch({
      type: FETCH_USER_TREEZOR,
      payload: user.data,
    });
    return;
  } catch (error) {
    // dispatch(setGlobalError(error));
    dispatch({ type: FETCH_USER_TREEZOR_FAILED, payload: error });
  }
};

export const fetchPersonUserTreezor = () => async (dispatch, getState) => {
  try {
    const { userId } = getState().wallet.wallet[0]; // TODO selector like selectLoggedUserCompanyId
    const user = await service.fetchPersonUserTreezor(userId);
    dispatch({
      type: FETCH_PERSON_USER_TREEZOR,
      payload: user.data,
    });
    return;
  } catch (error) {
    // dispatch(setGlobalError(error));
    dispatch({ type: FETCH_PERSON_USER_TREEZOR_FAILED, payload: error });
  }
};

export const fetchKycUser = () => async (dispatch, getState) => {
  const { userId } = getState().wallet.wallet[0]; // TODO selector like selectLoggedUserCompanyId
  try {
    const kycCheck = await service.fetchKycUser(userId);
    dispatch({
      type: FETCH_KYC_USER,
      payload: kycCheck,
    });
    return;
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: FETCH_KYC_USER_FAILED, payload: error });
  }
};

export const putTreezorUser = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_TREEZOR_USERS,
  });
  try {
    const userTreezor = await service.putUser(data.userId, {
      documents: data.documents,
    });
    dispatch({
      type: UPDATE_TREEZOR_DOC,
      payload: userTreezor.data,
    });
    const result = await userServices.fetchTreezorUsers();
    const users = result.data;
    dispatch({
      type: FETCH_TREEZOR_USERS_SUCCESS,
      payload: { treezorUsers: users },
    });
  } catch (error) {
    dispatch(setGlobalError(error));
  }
};

export const onBoardingTreezorAutoEntrepreneur = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_TREEZOR_USERS,
  });
  try {
    const res = await service.onboardingAutoEntrepreneur(data);
    await service.createTreezorWallet({
      userId: res.data.userId,
    });
    dispatch({
      type: FETCH_WALLET,
      payload: res.data,
    });
    dispatch(fetchTreezorUsers());
    dispatch({
      type: ONBOARDING_TREEZOR,
      payload: res.data,
    });
  } catch (error) {
    error.errors?.forEach((err) => {
      const errorMessage = { message: err?.errorMessage };
      dispatch(setGlobalError(errorMessage));
      dispatch({
        type: CREATE_TREEZOR_USER_FAILED,
        payload: errorMessage,
      });
    });
  }
};

export const onBoardingTreezorCompany = (data) => async (dispatch) => {
  dispatch({
    type: FETCHING_TREEZOR_USERS,
  });
  try {
    const res = await service.onboardingCompany(data);
    dispatch({
      type: ONBOARDING_TREEZOR,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setGlobalError(err));
    if (err.errors) {
      dispatch({
        type: CREATE_TREEZOR_USER_FAILED,
        payload: err.errors[0].errorMessage,
      });
    }
  }
};

export const onBoardingTreezorUser = (user, company) => async (dispatch) => {
  dispatch({
    type: FETCHING_TREEZOR_USERS,
  });
  try {
    const res = await service.onboardingUser(user, company);
    dispatch(fetchTreezorUsers());
    dispatch({
      type: ONBOARDING_TREEZOR,
      payload: res.data,
    });
  } catch (err) {
    dispatch(setGlobalError(err));
    if (err.errors) {
      dispatch({
        type: CREATE_TREEZOR_USER_FAILED,
        payload: err.errors[0].errorMessage,
      });
    }
  }
};

export const onBoardingTreezorWallet = (userId) => async (dispatch) => {
  dispatch({
    type: FETCHING_TREEZOR_USERS,
  });
  try {
    const res = await service.createTreezorWallet({
      userId,
    });
    dispatch({
      type: FETCH_WALLET,
      payload: [res.data],
    });
  } catch (err) {
    dispatch(setGlobalError(err));
    if (err.errors) {
      dispatch({
        type: CREATE_TREEZOR_USER_FAILED,
        payload: err.errors[0].errorMessage,
      });
    }
  }
};

export const sendTreezorNotif = () => async (dispatch) => {
  try {
    await service.onboardingNotify();
  } catch (err) {
    dispatch(setGlobalError(err));
  }
};

export const cancelTreezorUser = (idUser) => async (dispatch, getState) => {
  const { treezorUsers } = getState().treezor;
  const treezorUserToCancel = treezorUsers.find(
    (tUser) => tUser.userTag === idUser
  );
  try {
    await service.cancelTreezorUser(treezorUserToCancel._id);
  } catch (err) {
    dispatch(setGlobalError(err));
  }
};
