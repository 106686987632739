/* eslint-disable react/display-name */
/* Packages */
import MaterialTable from 'material-table';
import qs from 'qs';
import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';

/* Actions */
import { getExpensePaginateAction } from 'actions/ExpensesActions';

/* Hooks */
import useFormatPurchaseTransferData from 'containers/BankBBF/hooks/useFormatPurchaseTransferData';
import useFormatExpenseTransferData from 'containers/BankBBF/hooks/useFormatExpenseTransferData';

/* Local Components */
import TransferPageLayout from './TransferPageLayout';

const TransferPage = () => {
  const location = useLocation();
  const tableRef = useRef();
  const { formatData, columns } = useFormatPurchaseTransferData();
  const { formatData: expenseFormatData, columns: expenseColumns } =
    useFormatExpenseTransferData();

  const getSearchQuery = location.search.split('?')[1];
  const parseQueryStringToObject = qs.parse(getSearchQuery);
  const queryTableType = parseQueryStringToObject.filters?.type;

  const getTransferData = async (queryTable) => {
    const restructureObject = {
      page: queryTable.page + 1,
      limit: queryTable.pageSize,
      orderDirection: queryTable.orderDirection,
      search: queryTable.search,
    };

    const parseObjectToQueryString = qs.stringify(restructureObject);

    const response = await getExpensePaginateAction(
      `${parseObjectToQueryString}&${getSearchQuery}`
    );

    return {
      data:
        queryTableType === 'purchase'
          ? formatData(response.data.docs)
          : expenseFormatData(response.data.docs),
      totalCount: response.data.totalDocs,
      page: response.data.page - 1,
    };
  };

  return (
    <TransferPageLayout queryTableType={queryTableType}>
      <MaterialTable
        tableRef={tableRef}
        columns={queryTableType === 'purchase' ? columns : expenseColumns}
        data={getTransferData}
        options={{
          filtering: false,
          search: false,
          showTitle: false,
          pageSizeOptions: [],
        }}
      />
    </TransferPageLayout>
  );
};

export default TransferPage;
