import {
  FETCH_VATRATES,
  FETCH_VATRATES_FAILED,
  FETCH_VATRATES_FOR_NO_VAT,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

export default (state = [], action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_VATRATES:
      return action.payload || [];
    case FETCH_VATRATES_FAILED:
      return [];
    case FETCH_VATRATES_FOR_NO_VAT:
      return action.payload || [];
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return [];
    default:
      return state;
  }
};
