import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'hooks/routerHooks';
// import { useThunkDispatch } from 'hooks/generalHooks';

// import { AppRowCenterStart } from 'components/AppContainers';
// import AppPlusButton from 'components/AppPlusButton';
import AppPlusSelect from 'components/AppPlusSelect';
import Header from 'components/Header';
// import Refresh from 'components/Refresh';

// import { fetchExpenses } from 'actions/ExpensesActions';
import { userCanCreateExpense } from 'selectors/rightsSelector/expenseRequests';

const PurchaseListHeader = () => {
  // const { refreshing, canReadExpense, toggleRefresh } = props;
  const canCreateExpense = useSelector(userCanCreateExpense);

  const { t } = useTranslation();
  const { history } = useRouter();
  // const dispatch = useThunkDispatch();

  // const fetchExpensesCb = useCallback(() => dispatch(fetchExpenses()), [
  //   dispatch,
  // ]);

  function renderCreateButton() {
    if (canCreateExpense) {
      const purchasesCreateMenuItems = [
        {
          name: t('pdf_purchase.import_global_file'),
          onClick: () =>
            history.push({
              pathname: '/pdf',
            }),
        },
        {
          name: t('create'),
          onClick: () =>
            history.push({
              pathname: '/purchases/new',
              state: {
                payment: 'company',
              },
            }),
        },
      ];
      // return (
      //   <>
      //     <div style={{ marginRight: 10 }}>
      //       <AppPlusButton
      //         label={t('pdf_purchase.import_global_file')}
      //         onClick={() => {
      //           history.push({
      //             pathname: '/pdf',
      //           });
      //         }}
      //       />
      //     </div>
      //     <AppPlusButton
      //       label={t('create')}
      //       onClick={() => {
      //         history.push({
      //           pathname: '/purchases/new',
      //           state: {
      //             payment: 'company',
      //           },
      //         });
      //       }}
      //     />
      //   </>
      // );
      return (
        <AppPlusSelect
          items={purchasesCreateMenuItems}
          name="name"
          label={t('create')}
        />
      );
    }
    return null;
  }

  return (
    <Header name={t(`expenses.purchases.title`)} flexEnd>
      {/* <AppRowCenterStart> */}
      {/* <Refresh
          loadData={fetchExpensesCb}
          loading={refreshing}
          callback={toggleRefresh}
          preFn={toggleRefresh}
          disabled={!canReadExpense}
        /> */}
      {/* </AppRowCenterStart> */}
      {renderCreateButton()}
    </Header>
  );
};

PurchaseListHeader.propTypes = {
  refreshing: PropTypes.bool.isRequired,
  canReadExpense: PropTypes.bool.isRequired,
  toggleRefresh: PropTypes.func.isRequired,
};

export default PurchaseListHeader;
