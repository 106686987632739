import colors from '../../../constants/colors.json';

const styles = {
  cardContainer: {
    border: '1px solid #02CBCD',
    // boxShadow: '1px 4px 4px 0 #3d319d',
    borderRadius: '6px',
    width: '280px',
    height: '105px',
  },
  card: {
    position: 'relative',
    minHeight: '265px',
    borderRadius: '6px',
    backgroundColor: '#ffffff',
    boxShadow: '0 1px 4px 0 #3d319d',
    '& a': {
      color: '#34223b',
    },
  },
  title: {
    marginBottom: 16,
    fontSize: 18,
    fontWeight: 500,
    color: '#555555',
  },
  cardHeader: {
    width: '85px',
    height: '85px',
    textAlign: 'center',
    marginLeft: '-10px',
    marginTop: '-35px',
    marginRight: '10px',
    padding: '10px 10px',
    borderRadius: '6px',
    backgroundColor: '#3d319d',
    boxShadow: '1px 4px 4px 0 #3d319d',
    float: 'left',
    '& svg': {
      color: '#fff',
    },
  },
  disabled: {
    cursor: 'not-allowed',
    backgroundColor: colors.light_grey,
    color: colors.darker_grey,
  },
  // divider: {
  //   // backgroundColor: 'rgba(216, 216, 216, 0.4)',
  //   backgroundColor: '#3d319d',
  //   marginBottom: '25px',
  //   marginTop: '55px',
  //   width: '100%',
  // },
  pos: {
    marginBottom: 12,
    fontSize: 14,
    lineHeight: 1.29,
    clear: 'both',
    color: '#737373',
  },
  icon: {
    textAlign: 'center',
    fontSize: 56,
    height: 56,
    width: 56,
    overflow: 'unset',
  },
  cardContent: {
    display: 'flex',
  },
  cardContentLeft: {
    width: '35%',
  },
  cardContentRight: {
    width: '65%',
    marginLeft: '8px',
    textAlign: 'center',
  },
};

export default styles;
