import { FETCH_INVOICES, FETCH_EXPENSES } from '../../actions/Types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_INVOICES:
      return { ...state, invoices: Date.now() };
    case FETCH_EXPENSES:
      return { ...state, expenses: Date.now() };
    default:
      return state;
  }
};
