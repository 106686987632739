/* eslint-disable react/display-name */
/* Packages */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

/* Components */
import PurchaseState from 'components/states/PurchaseState';

/* Helpers */
import NumberFormat from 'helpers/NumberFormat';
import { getExpenseURL } from 'helpers/expenseHelpers';

/* LocalComponents */
import BankBBFTableDate from '../components/BankBBFTableDate';
import TransferTableEdit from '../components/TransferTableEdit';

const useFormatPurchaseTransferData = () => {
  const { t } = useTranslation();
  const location = useLocation();

  const pageTitle = location.state?.pageTitle || 'new_transfer';

  const providers = useSelector((state) => state.providers);
  const categories = useSelector((state) => state.categories);

  const renderType = (value) => {
    switch (value) {
      case 'GroupedExpense':
      case 'SimpleExpense':
        return t('expenses.expense.type.note');
      case 'AdvancedExpense':
        return t('expenses.expense.type.advance');
      case 'InvoiceExpense':
      case 'Purchase':
        return t('expenses.expense.type.invoice');
      default:
        return 0;
    }
  };

  const renderEntity = (exp) => {
    if (exp.__t === 'Purchase' || exp.__t === 'InvoiceExpense') {
      return providers[exp.provider]
        ? providers[exp.provider].name
        : t('not_setted');
    }

    if (exp.__t === 'GroupedExpense') {
      if (
        exp.expenses &&
        exp.expenses[0] &&
        providers[exp.expenses[0].provider] &&
        providers[exp.expenses[0].provider].name
      ) {
        return providers[exp.expenses[0].provider].name;
      }
    }

    if (exp.__t === 'SimpleExpense') {
      if (
        exp.provider &&
        providers[exp.provider] &&
        providers[exp.provider].name
      ) {
        return providers[exp.provider].name;
      }
    }
    return t('not_setted');
  };

  const renderCategory = (exp) => {
    let catId = null;

    switch (exp.__t) {
      case 'GroupedExpense':
        catId = exp.expenses[0].category_id;
        break;
      case 'SimpleExpense':
      case 'InvoiceExpense':
      case 'Purchase':
        catId = exp.category_id;
        break;
      default:
        catId = null;
    }

    return catId && categories.data && categories.data[catId]
      ? categories.data[catId].description
      : '';
  };

  const formatData = (data) =>
    data.map((exp) => {
      return {
        ...exp,
        category: renderCategory(exp),
        type: renderType(exp.__t),
        expense_nb: exp.expense_nb,
        state: t(`expenses.expense.state.${exp.displayState}`),
        date: exp.date,
        entity: renderEntity(exp),
        gross_total: `${NumberFormat.n(exp.total || exp.grandTotalAmount)}€`,
        duePayableAmount: `${NumberFormat.n(exp.duePayableAmount)}€`,
        id: exp._id,
      };
    });

  const editPath = (expense) => {
    const url =
      expense.__t === 'GroupedExpense'
        ? getExpenseURL(expense.expenses[0].payment_type)
        : getExpenseURL(expense.payment_type);

    switch (expense.__t) {
      case 'GroupedExpense':
      case 'SimpleExpense':
        return {
          pathname: `/${url}/edit/${expense._id}`,
          state: {
            payment: expense.payment_type,
            title: t('expenses.expense.expense'),
          },
        };
      case 'AdvancedExpense':
        return `/expenses/advance/edit/${expense._id}`;
      case 'InvoiceExpense':
      case 'Purchase':
        return {
          pathname: `/purchases/edit/${expense._id}`,
          state: {
            payment: expense.payment_type,
            title: t('expenses.expense.expense'),
          },
        };

      default:
        return '/';
    }
  };

  const columns = [
    {
      title: t('invoices.form_date'),
      field: 'date',
      defaultSort: 'desc',
      render: (rowData) => <BankBBFTableDate createdDate={rowData.date} />,
    },
    {
      title: t('expenses.expense.category'),
      field: 'category',
      sorting: false,
    },
    {
      title: t('invoices.list.state'),
      field: 'state',
      sorting: false,
      render: (rowData) => <PurchaseState state={rowData.state} />,
    },
    {
      title: t('invoices.list.nb'),
      field: 'expense_nb',
      sorting: false,
    },
    {
      title: t('expenses.list.provider'),
      field: 'entity',
      sorting: false,
    },
    {
      title: t('expenses.expense.form_amount'),
      field: 'gross_total',
      sorting: false,
    },
    {
      title: t('invoices.to_pay'),
      field: 'duePayableAmount',
      sorting: false,
    },
    {
      title: '',
      field: 'id',
      sorting: false,
      render: (rowData) => (
        <TransferTableEdit
          rowData={rowData}
          editPath={editPath}
          pageTitle={pageTitle}
        />
      ),
    },
  ];

  return {
    formatData,
    editPath,
    columns,
  };
};

export default useFormatPurchaseTransferData;
