import { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

const useThunkDispatch = () => useDispatch();

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });

  return ref.current;
}

export { useThunkDispatch, usePrevious };
