const useStyles = () => ({
  pdfPage: {
    width: '100%',
    height: 'auto',
    '& canvas': {
      width: '100% !important',
      height: 'auto',
    },
    '& .react-pdf__Page__textContent': {
      display: 'none',
    },
  },
});

export default useStyles;
