/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { getThemeColor } from 'helpers/utils';
import ApexChart from 'components/ApexChart';
import { useSelector } from 'react-redux';
import { useRouter } from 'hooks/routerHooks';
import { useTranslation } from 'react-i18next';
import CircularProgressCentered from 'components/CircularProgressCentered';
import AppError from 'components/AppError';
import NumberFormat from 'helpers/NumberFormat';
import { fiscalYearHelper } from 'helpers/InvoicesTurnoverHelpers';
import { find } from 'lodash';
import { EXPENSE_STATE } from 'constants/expense';
import { format, subYears } from 'date-fns';
import queryString from 'query-string';
import categories from 'constants/categories';

const useStyles = makeStyles(() => ({}));

const ExpenseByCategoriesChart = (props) => {
  const [series, setSeries] = useState([]);
  const [labels, setLabels] = useState([]);
  const {
    companyId,
    appInitialized,
    fetchExpenseByCategories,
    hasExpenseValidateAccess,
    expenseByCategories,
    chartDataType,
  } = props;
  const theme = useTheme();

  const classes = useStyles();
  const { t } = useTranslation();
  const { history } = useRouter();

  const endFiscalYear = useSelector(
    // eslint-disable-next-line no-shadow
    (state) => state.loggedUserCompany.company.endFiscalYear
  );

  const getElementAtEvent = (label) => {
    const endFiscalYearDate = fiscalYearHelper(endFiscalYear);
    const [dd, mm, yyyy] = endFiscalYearDate.split('/');

    const category = find(categories, (cat) => cat.name === label);
    const expenseFilters = {
      state: [
        EXPENSE_STATE.UPCOMING,
        EXPENSE_STATE.PAID,
        EXPENSE_STATE.OVERDUE,
      ],
      before: format(new Date(`${yyyy}-${mm}-${dd}`), 'yyyy-MM-dd'),
      after: format(
        new Date(subYears(new Date(`${yyyy}-${mm}-${dd}`), 1)),
        'yyyy-MM-dd'
      ),
      categories: category.categories,
    };

    history.push(
      queryString.stringifyUrl(
        {
          url: 'purchases/list',
          query: expenseFilters,
        },
        {
          arrayFormat: 'index',
        }
      )
    );
  };

  const chartData = {
    series: [
      {
        data: series,
      },
    ],
    options: {
      chart: {
        type: 'bar',
        zoom: {
          enabled: false,
        },
        toolbar: {
          show: false,
        },
        events: {
          dataPointSelection(event, chartContext, opts) {
            const {
              dataPointIndex,
              w: {
                globals: { labels: categoryLabels },
              },
            } = opts;
            getElementAtEvent(categoryLabels[dataPointIndex]);
          },
        },
      },
      grid: {
        show: false,
      },
      colors: [getThemeColor(theme.palette.primary[600])],
      plotOptions: {
        bar: {
          horizontal: true,
          dataLabels: {
            position: 'top',
          },
        },
      },
      dataLabels: {
        style: {
          colors: [getThemeColor(theme.palette.neutral[900])],
          fontWeight: 600,
        },
        textAnchor: 'start',
        offsetX: 99999,
        formatter(val) {
          return `${NumberFormat.n(val, 0)}€`;
        },
      },
      tooltip: {
        shared: true,
        intersect: false,
      },
      labels,
      xaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: false,
          formatter(val) {
            return `${NumberFormat.n(val, 0)}€`;
          },
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        labels: {
          show: true,
          style: {
            colors: getThemeColor(theme.palette.neutral[700]),
            fontWeight: 400,
            fontSize: '0.875rem',
          },
        },
      },
    },
  };

  const renderGraph = () => {
    if (expenseByCategories.loading) return <CircularProgressCentered />;
    if (expenseByCategories.error) return <AppError error="error" />;
    if (!labels?.length && !series?.length) {
      return null;
    }
    return (
      <ApexChart
        options={chartData.options}
        series={chartData.series}
        type="bar"
        height={300}
      />
    );
  };

  useEffect(() => {
    if (appInitialized && hasExpenseValidateAccess) {
      // TODO replace with ExpenseOverview
      fetchExpenseByCategories();
    }
  }, [
    companyId,
    appInitialized,
    hasExpenseValidateAccess,
    fetchExpenseByCategories,
  ]);

  useEffect(() => {
    if (expenseByCategories?.data) {
      let dataInput;

      // validate expenseByCategories.data is an array and not an object and not empty array else set dataInput to empty array
      if (
        Array.isArray(expenseByCategories.data) &&
        expenseByCategories.data.length > 0
      ) {
        if (chartDataType === 'top10') {
          // filter top 10
          dataInput = [...expenseByCategories.data]
            .sort((a, b) => b.total - a.total)
            .slice(0, 10);
        } else {
          dataInput = expenseByCategories.data;
        }
      } else {
        dataInput = [];
      }

      setLabels(dataInput.map((item) => item.label));
      setSeries(dataInput.map((item) => item.total));
    }
  }, [expenseByCategories?.data, chartDataType]);

  if (appInitialized) {
    return <Box style={{ width: '100%' }}>{renderGraph()}</Box>;
  }
  return null;
};

ExpenseByCategoriesChart.propTypes = {
  companyId: PropTypes.string,
  appInitialized: PropTypes.bool,
  hasExpenseValidateAccess: PropTypes.bool,
  expenseByCategories: PropTypes.object.isRequired,
  fetchExpenseByCategories: PropTypes.func.isRequired,
  chartDataType: PropTypes.string.isRequired,
};

export default ExpenseByCategoriesChart;
