import { LOCAL_STORAGE_PERSONAL_INFO } from 'config/Constants';

const storagePersonalInfo = (values) => {
  const currentPersonalInfo = JSON.parse(
    localStorage.getItem('personalInfo') || '{}'
  );
  localStorage.setItem(
    LOCAL_STORAGE_PERSONAL_INFO,
    JSON.stringify({ ...currentPersonalInfo, ...values })
  );
};

export default storagePersonalInfo;
