import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core/';

const InvoiceMapPayment = ({ payments }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography gutterBottom variant="h5" component="h2">
        {t('expenses.invoices.due_date')}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        <span style={{ paddingLeft: '2%' }}>
          {payments.map((payment, index) => {
            return (
              <span key={index}>{`${payment.amount}€ ${payment.date}`}</span>
            );
          })}
        </span>
      </Typography>
    </>
  );
};
InvoiceMapPayment.propTypes = {
  payments: PropTypes.array,
};

export default InvoiceMapPayment;
