import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Grid } from '@material-ui/core';
import { useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import AppButton from 'components/AppButton';
import FormLoading from '../FormLoading';
import TreezorDocsNeedField from './treezorDocsNeedField';

const TreezorDocsNeedForm = ({
  optionList,
  document,
  company,
  title,
  treezorUser,
}) => {
  const { t } = useTranslation();
  const { handleSubmit, isSubmitting, setFieldValue, values } =
    useFormikContext();
  const { treezorUsers } = useSelector((state) => state.treezor);
  const [resetUploadName, setResetUploadName] = useState(false);
  const [disableSendBtn, setDisableSendBtn] = useState(true);
  const [displayName, setDisplayName] = useState('');

  useEffect(() => {
    const user = treezorUsers.find(
      (tUser) => tUser._id === treezorUser.treezorUserID
    );
    setDisplayName(`${user.firstname} ${user.lastname}`);
  });

  return (
    <>
      <FormLoading loading={isSubmitting} />

      <TreezorDocsNeedField
        message={document.message}
        name="type"
        title={title}
        displayName={displayName}
        optionList={optionList}
        company={company}
        setFieldValue={setFieldValue}
        resetUploadName={resetUploadName}
        setDisableSendBtn={setDisableSendBtn}
      />
      <Grid container justify="center">
        <Grid item xs={2} style={{ marginTop: '2%' }}>
          <AppButton
            text={t('save')}
            noBorder
            type="button"
            isDisabled={disableSendBtn}
            onClick={() => {
              setResetUploadName(!resetUploadName);
              handleSubmit(values);
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

TreezorDocsNeedForm.propTypes = {
  optionList: PropTypes.array,
  document: PropTypes.object,
  company: PropTypes.object,
  title: PropTypes.string,
  treezorUser: PropTypes.object,
};

export default TreezorDocsNeedForm;
