import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import dispatch from './dispatch';

class Logout extends Component {
  render() {
    const { isAuthenticated, location, logout } = this.props;

    if (location.pathname === '/logout/redirect') {
      logout();
      return <Redirect to="/" />;
    }

    if (!isAuthenticated()) {
      return <Redirect to="/" />;
    }

    window.location = `https://${process.env.REACT_APP_AUTH0_DOMAIN}/v2/logout?returnTo=${process.env.REACT_APP_AUTH0_LOGOUT_URL}&client_id=${process.env.REACT_APP_AUTH0_CLIENT_ID}`;

    return null;
  }
}

Logout.propTypes = {
  loggedUser: PropTypes.object,
  logout: PropTypes.func,
  isAuthenticated: PropTypes.func,
  location: PropTypes.object,
};

const LogoutWithRouter = withRouter(Logout);
export default connect(null, dispatch)(LogoutWithRouter);
