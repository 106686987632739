/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { Card, CardContent, Grid } from '@material-ui/core/';
import { format } from 'date-fns';

import NumberFormat from 'helpers/NumberFormat';
import { INVOICE_TYPE } from 'helpers/invoiceHelpers';

import InvoiceCardInvoice from './InvoiceCardInvoice';
import InvoiceCardPrePaymentInvoice from './InvoiceCardPrePaymentInvoice';
import InvoiceMapPrePayment from './InvoiceMapPrePayment';
import InvoiceMapPayment from './InvoiceMapPayment';

import InvoiceHeader from './InvoiceHeader';

const InvoiceCard = ({ invoice }) => {
  const { t } = useTranslation();

  const data = {
    title:
      invoice.type === INVOICE_TYPE.PRE_PAYMENT
        ? t('invoices.types.pre_payment')
        : t('invoices.types.invoice'),
    date_emission: format(new Date(invoice.date_emission), 'dd/MM/yyyy'),
    company_name: invoice.company.name,
    company_address1: invoice.company.address,
    company_address2: `${invoice.company.zipcode} ${invoice.company.city}`,
    client_name: invoice.client.name,
    client_contact: invoice.client.contact,
    client_address1: invoice.client.address,
    client_address2: `${invoice.client.zipcode} ${invoice.client.city}`,
    net_total: NumberFormat.n(invoice.net_total),
    vat_total: NumberFormat.n(invoice.vat_total),
    gross_total: NumberFormat.n(invoice.gross_total),
    diff_to_pay: NumberFormat.n(invoice.duePayableAmount - invoice.gross_total),
    duePayableAmount: NumberFormat.n(invoice.duePayableAmount),
    client_email: invoice.client.email,
    invoice_nb: invoice.invoice_nb,
  };

  data.payments = invoice.payments.map((payment) => {
    // eslint-disable-next-line no-param-reassign
    payment.amount = NumberFormat.n(payment.amount);
    if (payment.date.length === 24) {
      // eslint-disable-next-line no-param-reassign
      payment.date = format(new Date(payment.date), 'dd/MM/yyyy');
    }
    return payment;
  });

  if (invoice.prepayments.length) {
    data.pre_payments = invoice.prepayments.map((prepayment) => {
      // eslint-disable-next-line no-param-reassign
      prepayment.amount = NumberFormat.n(prepayment.amount);
      return prepayment;
    });
  }

  if (invoice.type === INVOICE_TYPE.PRE_PAYMENT) {
    data.pre_payment_total = NumberFormat.n(invoice.toPay.amount);
  }

  return (
    <Card>
      <CardContent>
        <InvoiceHeader data={data} />
        <Grid container justify="flex-end">
          <Card style={{ background: '#6D7278' }}>
            <CardContent style={{ textAlign: 'right' }}>
              {invoice.type === INVOICE_TYPE.PRE_PAYMENT && (
                <InvoiceCardPrePaymentInvoice data={data} />
              )}
              {invoice.type === INVOICE_TYPE.INVOICE && (
                <InvoiceCardInvoice data={data} />
              )}
            </CardContent>
          </Card>
        </Grid>

        {invoice.prepayments.length > 1 && (
          <InvoiceMapPrePayment pre_payments={data.pre_payments} />
        )}
        {invoice.payments.length > 0 && (
          <InvoiceMapPayment payments={data.payments} />
        )}
      </CardContent>
    </Card>
  );
};

InvoiceCard.propTypes = {
  invoice: PropTypes.object,
};

export default InvoiceCard;
