import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle } from '@material-ui/core';
import { useSelector } from 'react-redux';

import CircularProgressCentered from '../CircularProgressCentered';

// eslint-disable-next-line no-unused-vars
const AppLoadingDialog = ({ title }) => {
  const { isLoading } = useSelector((state) => state);
  return (
    <Dialog
      open={isLoading}
      fullWidth={true}
      aria-labelledby="responsive-dialog-title"
    >
      {title && (
        <DialogTitle
          style={{ textAlign: 'center' }}
          id="responsive-dialog-title"
        >
          {title}
        </DialogTitle>
      )}

      <CircularProgressCentered />
    </Dialog>
  );
};

AppLoadingDialog.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
};

export default AppLoadingDialog;
