import {
  FETCH_WALLET_TRANSACTIONS,
  FETCH_WALLET_TRANSACTIONS_FAILED,
  UPDATE_WALLET_TRANSACTION,
  UPDATE_WALLET_TRANSACTION_FAILED,
} from '../../actions/Types';

const INITIAL_STATE = {
  success: false,
  error: null,
  transactions: [],
};

export default (state = INITIAL_STATE, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_WALLET_TRANSACTIONS:
      return {
        ...state,
        success: true,
        transactions: action.payload,
      };
    case FETCH_WALLET_TRANSACTIONS_FAILED:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    case UPDATE_WALLET_TRANSACTION: {
      const { _id: updatedTransactionOID } = action.payload;
      const { transactions: walletTransactions } = state;
      const foundIdx = walletTransactions.findIndex(
        (el) => el._id === updatedTransactionOID
      );

      walletTransactions[foundIdx] = { ...action.payload };

      return {
        ...state,
        success: true,
        transactions: [...walletTransactions],
      };
    }
    case UPDATE_WALLET_TRANSACTION_FAILED:
      return {
        ...state,
        success: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
