import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appChecklist';
import { AppTextSubTitle } from '../AppText';
import ProgressGreen from '../../assets/images/progress_green.svg';
import ProgressGrey from '../../assets/images/progree_grey.svg';

const AppChecklist = ({ classes, checked, title }) => {
  const appChecklistWrapperClass = classNames({
    [classes.appChecklistWrapper]: true,
  });

  const appChecklistTextClass = classNames({
    [classes.appChecklistText]: true,
  });

  return (
    <div className={appChecklistWrapperClass}>
      <img src={checked ? ProgressGreen : ProgressGrey} alt="..." />
      <div className={appChecklistTextClass}>
        <AppTextSubTitle>{title}</AppTextSubTitle>
      </div>
    </div>
  );
};
AppChecklist.propTypes = {
  classes: PropTypes.object.isRequired,
  checked: PropTypes.bool,
  title: PropTypes.string,
};

AppChecklist.defaultProps = {
  checked: false,
};

export default withStyles(style)(AppChecklist);
