import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import { withStyles } from '@material-ui/core/styles';
import { AddAPhoto } from '@material-ui/icons';

import { SCAN_FILE_TYPE } from 'constants/account';
import Layout from '../../components/Layout';
import Form from '../../components/UserProfile';
import Header from '../../components/Header';
import UploadFile from '../../components/UploadFile';

import S3FileService from '../../services/S3FileService';

import state from './state';
import AvatarUpload from '../../components/Avatar/AvatarUpload';
import { AppGreyBold } from '../../components/AppText';
import { AppDividerXS } from '../../components/AppDividers';
import { AppColumnStartStart } from '../../components/AppContainers';
import dispatch from './dispatch';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userAvatar: props.loggedUser.user.avatarBase64,
    };

    this.redirectToHome = this.redirectToHome.bind(this);
  }

  openDialog(dialogName, index) {
    if (dialogName === 'addUser') {
      this.setState({ openDialogEditUser: true, userIndex: index });
    }
  }

  renderAvatar() {
    const { t, changeAvatar } = this.props;

    return (
      <>
        <AvatarUpload
          buttonText={
            this.state.userAvatar === ''
              ? t('signup.company.upload')
              : t('signup.company.change')
          }
          onUpload={(file) => {
            const reader = new FileReader();
            reader.onload = (e) => {
              this.setState({ userAvatar: e.target.result });
              changeAvatar({ file, b64: e.target.result }, (err) => {
                if (err) {
                  // console.log(err);
                  this.setState({ errorAvatar: true });
                } else {
                  this.setState({ errorAvatar: false });
                }
              });
            };
            reader.readAsDataURL(file);
          }}
          image={this.state.userAvatar}
        />
        {this.state.errorAvatar && (
          <div style={{ color: 'red' }}>{t('error')}</div>
        )}
      </>
    );
  }

  renderId() {
    const {
      t,
      loggedUser: {
        user: { passport },
      },
    } = this.props;
    return (
      <>
        <div
          style={{ width: '100%', textAlign: 'center', marginBottom: '10px' }}
        >
          <AppGreyBold>{t('signup.company.userFile_alt')}</AppGreyBold>
        </div>
        <UploadFile
          accept={SCAN_FILE_TYPE}
          multiple={false}
          shape="profileLogo"
          showButton={true}
          style={{
            width: '100%',
            height: '100%',
            textAlign: 'center',
          }}
          filename={passport}
          onFileSelected={(file) => {
            this.props.changePassport(file, (err) => {
              if (err) {
                // console.log(err);
                this.setState({ errorPassport: true });
              } else this.setState({ errorPassport: false });
            });
          }}
          fetchCompanyLogo={(filename, cb) => {
            S3FileService.geturl(filename)
              .then(({ url, type }) => cb(null, { url, type }))
              .catch((error) => {
                cb(error);
              });
          }}
          buttonText={
            !passport ? t('signup.company.upload') : t('signup.company.change')
          }
          defaultIcon={
            <AddAPhoto
              style={{
                width: '100%',
                fontSize: '4.5em',
                marginTop: '15%',
              }}
            />
          }
        />
        {this.state.errorPassport && (
          <div style={{ color: 'red' }}>{t('error')}</div>
        )}
      </>
    );
  }

  redirectToHome() {
    this.props.history.push('/home');
  }

  render() {
    const {
      history,
      loggedUser: { user },
      t,
    } = this.props;

    return (
      <Layout
        header={
          <Header
            name={`${t('settings_section.users.profile_title')}`}
            goBack={() => history.goBack()}
          />
        }
        sidebarLeft={true}
        sidebarRight={
          <>
            <AppColumnStartStart>
              <div style={{ width: '100%', textAlign: 'center' }}>
                <AppGreyBold>{t('avatar')}</AppGreyBold>
              </div>
              <AppDividerXS />
              {this.renderAvatar()}
              <AppDividerXS />
              {/* here only if ceo */}
              {this.renderId()}
              {/* /here only if ceo */}
            </AppColumnStartStart>
          </>
        }
        showUserCard={true}
        body={
          <Form
            redirectToHome={this.redirectToHome}
            id={user._id}
            authorizedUser
          />
        }
      />
    );
  }
}

Account.propTypes = {
  loggedUser: PropTypes.object.isRequired,
  t: PropTypes.func,
  changeAvatar: PropTypes.func.isRequired,
  changePassport: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const AccountWithStyle = withStyles(styles)(Account);
const TranslatedAccountWithStyle = withTranslation()(AccountWithStyle);

export default connect(state, dispatch)(withRouter(TranslatedAccountWithStyle));
