import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appTextInputSecondary';
import '../../assets/css/icons.css';

const AppTextInputSecondary = ({
  placeholder,
  fullWidth,
  value,
  onChange,
  classes,
  color,
  disabled,
  withSearchIcon,
}) => {
  const inputSearchContainerClasses = classNames({
    [classes.inputSearchContainer]: true,
    [classes.fullWidth]: fullWidth,
    [classes[color]]: color,
    [classes[`${color}WithValue`]]: value.length > 0,
    [classes.disabled]: disabled,
  });
  const inputSearchClasses = classNames({
    [classes.inputSearch]: true,
    [classes.fullWidth]: fullWidth,
  });

  return (
    <div className={inputSearchContainerClasses}>
      {withSearchIcon && (
        <span className="icon icon-search " style={style.iconStyle} />
      )}
      <input
        className={inputSearchClasses}
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        autoComplete="off"
        disabled={disabled}
      />
    </div>
  );
};

AppTextInputSecondary.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  withSearchIcon: PropTypes.bool,
  color: PropTypes.string,
};
AppTextInputSecondary.defaultProps = {
  placeholder: 'Search',
  fullWidth: false,
  disabled: false,
  withSearchIcon: false,
  color: 'primary',
};

export default withStyles(style)(AppTextInputSecondary);
