import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Box, Typography, Button } from '@material-ui/core';
import { CheckCircleRounded } from '@material-ui/icons';

const CreateDebitFinalStepYesOption = (props) => {
  const { handleCreateNewDebit, handleViewDebit } = props;
  const translateDomain = 'debits_page.debits_creation_page';
  const { t } = useTranslation();
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        padding: '20px',
      }}
    >
      <Box>
        <CheckCircleRounded
          style={{
            height: '100px',
            width: '100px',
            color: 'green',
          }}
        />
      </Box>
      <Box>
        <Typography
          variant="h5"
          align="center"
          style={{
            padding: '20px',
          }}
        >
          {t(`${translateDomain}.steps.final.title`)}
        </Typography>
        <Typography
          align="center"
          style={{
            padding: '5px',
          }}
        >
          {t(`${translateDomain}.steps.final.message`)}
        </Typography>
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '100%',
          marginTop: '20px',
        }}
      >
        <Button variant="contained" color="default" onClick={handleViewDebit}>
          {t(`${translateDomain}.buttons.view_table`)}
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCreateNewDebit}
        >
          {t(`${translateDomain}.buttons.create_new_one`)}
        </Button>
      </Box>
    </Box>
  );
};

CreateDebitFinalStepYesOption.propTypes = {
  handleCreateNewDebit: PropTypes.func,
  handleViewDebit: PropTypes.func,
};

export default CreateDebitFinalStepYesOption;
