/* eslint-disable-next-line */
export default ({
  match: {
    params: { id },
  },
  templates,
}) => {
  if (id) {
    return templates[id];
  }

  return {
    display: '',
    color: '#6FB8F7',
    company: {
      visible: 'visible',
      fs: 12,
      ff: 'Arial',
      pos: [0, 140],
      bold: '',
      italic: '',
      underline: '',
    },
    title: {
      visible: 'visible',
      fs: '20',
      ff: 'Arial',
      pos: [476, 33],
      bold: 'bold',
      italic: '',
      underline: '',
    },
    client: {
      visible: 'visible',
      fs: 12,
      ff: 'Arial',
      pos: [476, 175],
      bold: '',
      italic: '',
      underline: '',
    },
    contact: {
      visible: 'visible',
      fs: 12,
      ff: 'Arial',
      pos: [0, 228],
      bold: '',
      italic: '',
      underline: '',
    },
    invoice: {
      visible: 'visible',
      fs: 12,
      ff: 'Arial',
      pos: [476, 65],
      bold: '',
      italic: '',
      underline: '',
    },
    logo: { visible: 'visible', width: '120', pos: [0, 0] },
    reference: {
      visible: 'visible',
      fs: '11',
      ff: 'Arial',
      pos: [476, 126],
      bold: '',
      italic: '',
      underline: '',
    },
    companyMentionBottom: {
      visible: 'visible',
      fs: 9,
      ff: 'Arial',
      pos: [0, 0],
      bold: '',
      italic: '',
      underline: '',
    },
    overlay: 'none',
  };
};
