/* THIS FILE SHOULD MOVE TO SHARED LIB */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import { useLocation } from 'react-router-dom';

import { createPayout, uploadContractFiles } from 'actions/PayoutActions';
import { updateProviderBeneficiary } from 'actions/ProviderAction';
import { updateClientBeneficiary } from 'actions/ClientActions';
import { updateUserBeneficiary } from 'actions/UserActions';
import {
  addTransfersToExpense,
  addTransfersToExpensePaginate,
} from 'actions/ExpensesActions';
import { getOTP } from 'actions/CardActions';

import { useRouter } from 'hooks/routerHooks';

import BankTransferForm from './BankTransferForm';

import { initialValues } from './initialValues';
import { prepareData } from './handleSubmit';
import { validationSchema } from './validation';

const BankTransferFormik = ({
  providers,
  clients,
  users,
  companyAccounts,
  beneficiaryTypes,
  source,
  prefill,
  setPayDialog,
  expenseId,
  setAlertHandler,
}) => {
  const { t } = useTranslation();
  const { history } = useRouter();
  const dispatch = useDispatch();
  const location = useLocation();

  const goBackPath = location.state?.goBackPath;
  const goBackQueryParams = location.state?.goBackQueryParams;
  const pageTitle = location.state?.pageTitle;

  const currentWallet = useSelector((state) =>
    state.wallet && state.wallet.wallet && state.wallet.wallet.length > 0
      ? state.wallet.wallet[0]
      : {}
  );

  const expense = useSelector(
    (state) => state.expenses && state.expenses[expenseId]
  );

  const { company } = useSelector((state) => state.loggedUserCompany);

  const [showOTPPart, setShowOTPPart] = useState(false);
  const [showBeneficiaryExtraInfo, setShowBeneficiaryExtraInfo] =
    useState(false);
  const [showIban, setShowIban] = useState(false);
  const [showBic, setShowBic] = useState(false);

  // useEffect(() => {
  //   setShowOTPPart(false);
  //   if (setPayDialog) {
  //     setPayDialog(false);
  //   }
  // }, []);

  const handleGetOTP = async () => {
    await dispatch(getOTP());
  };

  const onSubmit = async (values, actions) => {
    if (!showOTPPart) {
      actions.setFieldValue('otp', null);
      await handleGetOTP();
      setShowOTPPart(true);
      actions.setSubmitting(false);
    } else {
      let payoutDocuments;

      if (values.isShowedUploadPart) {
        actions.setSubmitting(true);
        payoutDocuments = await dispatch(
          uploadContractFiles(values.contractFiles, company)
        );
        actions.setSubmitting(false);
      }

      const { walletId } = currentWallet;
      const formatedData = prepareData(
        values,
        walletId,
        payoutDocuments,
        source,
        showIban,
        showBic,
        expenseId
      );
      actions.setSubmitting(true);

      const response = await dispatch(createPayout(formatedData));

      if (response.message) {
        setShowOTPPart(false);
        setAlertHandler({
          isOpen: true,
          message: response.message,
          status: 'error',
        });
      }

      const { beneficiaryId, treezorPayoutData } = response;

      const { _id: payoutOId } = treezorPayoutData;

      const { transfers: expenseTransfers } = expense;

      let transfers = [];

      if (expenseTransfers && expenseTransfers.length > 0) {
        transfers = [...expenseTransfers];
      }

      transfers = [...transfers, { type: 'payout', _id: payoutOId }];

      await dispatch(
        addTransfersToExpense({ expenseOId: expenseId, transfers })
      );

      await dispatch(
        addTransfersToExpensePaginate({ expenseOId: expenseId, transfers })
      );

      if (beneficiaryId) {
        const { beneficiaryType } = values;
        const { entityOId } = formatedData;

        if (beneficiaryType === 'provider') {
          dispatch(
            updateProviderBeneficiary({ beneficiaryId, providerOId: entityOId })
          );
        } else if (beneficiaryType === 'client') {
          dispatch(
            updateClientBeneficiary({ beneficiaryId, clientOId: entityOId })
          );
        } else if (beneficiaryType === 'user') {
          dispatch(
            updateUserBeneficiary({ beneficiaryId, userOId: entityOId })
          );
        }
      }

      setShowOTPPart(false);
      actions.setSubmitting(false);

      history.push({
        pathname: goBackPath || '/expenses/list',
        search: goBackQueryParams,
        state: {
          pageTitle,
          goBackPath,
          goBackQueryParams,
        },
      });

      setPayDialog(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues(prefill)}
      validationSchema={validationSchema(t, showOTPPart, showIban, showBic)}
      onSubmit={onSubmit}
    >
      {(props) => (
        <div>
          {
            <BankTransferForm
              {...props}
              providers={providers}
              clients={clients}
              users={users}
              companyAccounts={companyAccounts}
              beneficiaryTypes={beneficiaryTypes}
              showOTPPart={showOTPPart}
              handleGetOTP={handleGetOTP}
              showBeneficiaryExtraInfo={showBeneficiaryExtraInfo}
              setShowBeneficiaryExtraInfo={setShowBeneficiaryExtraInfo}
              showIban={showIban}
              setShowIban={setShowIban}
              showBic={showBic}
              setShowBic={setShowBic}
              wallet={currentWallet}
            />
          }
        </div>
      )}
    </Formik>
  );
};

BankTransferFormik.propTypes = {
  showSubmitButton: PropTypes.bool,
  handleNext: PropTypes.func,
  providers: PropTypes.array,
  clients: PropTypes.array,
  users: PropTypes.array,
  companyAccounts: PropTypes.object,
  beneficiaryTypes: PropTypes.array,
  source: PropTypes.object,
  prefill: PropTypes.object,
  setPayDialog: PropTypes.func,
  expenseId: PropTypes.number,
  setAlertHandler: PropTypes.func,
};

export default BankTransferFormik;
