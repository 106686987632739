/* eslint-disable prefer-template */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  format,
  getUnixTime,
  differenceInMinutes,
  isAfter,
  differenceInDays,
  startOfDay,
} from 'date-fns';
import { Refresh } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { isNumber, map } from 'lodash';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import * as dateFns from 'date-fns';
import AppButton from 'components/AppButton';
import { AppRowStartSpaceAround } from 'components/AppContainers';
import { IconEdit, IconDuplicate, IconDelete } from 'components/AppIcons';
import ResendIcon from 'components/TableElements/ResendIcon';
import MaterialTable from 'components/MaterialTable';
import HorizontalBeforAfterDate from 'components/HorizontalBeforeAfterDate';
import InvoiceState from 'components/states/InvoiceState';
import CustomGenericDialog from 'components/CustomGenericDialog';
import RichTextEditor from 'components/richTextEditor';

import { useRouter } from 'hooks/routerHooks';
import { userCanUpdateInvoice } from 'selectors/rightsSelector/invoiceRequests';
import { useDialog } from 'hooks/dialogHooks';

import NumberFormat from 'helpers/NumberFormat';
import { formatNum } from 'helpers/utils';
import {
  typeHelpers,
  getInvoiceEmail,
  stateHelpers,
} from 'helpers/invoiceHelpers';
import { exportToCSV } from 'helpers/fileHelper';
import { formatBeforeDate } from 'helpers/BeforeDateHelper';

// import { getMuiDatatableOptions } from 'helpers/tableHelpers';

// import {
//   updateCollumnWithLocalStorage,
//   updateOptionWithLocalStorage,
// } from 'helpers/mui-datatableHelper';

// import MaterialTable from 'material-table';

import {
  fetchInvoicePaginate,
  fetchInvoices,
  resendInvoice,
  // sendInvoice,
} from 'actions/InvoiceActions';

import {
  Checkbox,
  FormControl,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import queryString from 'query-string';
import { fetchCompanySubscription } from 'actions/CompanySubscription';
import ConfirmDialog from 'components/ConfirmDialog';
import InvoiceService from 'services/InvoiceService';
import spinnerService from 'services/SpinnerService';
import logger from 'helpers/logger';
import { DEFAULT_PAGESIZE } from 'config/Constants';
import InvoiceResendMailDialog from './Dialogs/InvoiceResendMailDialog';

// import { Refresh } from '@material-ui/icons';

const invoiceTypes = [
  'quotation',
  'order_form',
  'delivery_form',
  'invoice',
  'credit_note',
  'pre_payment',
];
const invoiceStates = [
  'draft',
  'pending',
  'canceled',
  'rejected',
  'validated',
  'paid',
  'overdue',
  'upcoming',
];

const InvoiceListBody = ({ canReadInvoice, refreshing }) => {
  const dispatch = useDispatch();
  const { showDialog: showEmailDialog, toggleDialog: toggleEmailDialog } =
    useDialog();
  const [showDialog, setShowDialog] = useState(false);
  const [contentDialog, setContentDialog] = useState({
    title: '',
    description: '',
  });
  const canUpdateInvoice = useSelector(userCanUpdateInvoice);
  const clientsProject = useSelector((state) => state.clientProjects.projects);
  const products = useSelector((state) => state.items);
  const clients = map(useSelector((state) => state.clients.data)).filter(
    (client) => !client.deleted
  );
  const loggedUserCompany = useSelector(
    (state) => state.loggedUserCompany.company
  );
  const loggedUser = useSelector((state) => state.loggedUser.user);
  const emailTemplates = useSelector(
    (state) => state.emailTemplate.emailTemplates
  );
  const companySubscription = useSelector(
    (state) => state.companySubscription.data
  );
  const { t } = useTranslation();
  const { history, location } = useRouter();
  const tableRef = React.createRef();

  const filters = queryString.parse(location?.search, { arrayFormat: 'index' });
  const [sortByPrice, setSortByPrice] = useState(
    filters ? !!filters.sortByPrice : false
  );
  const [pageSize, setPageSize] = useState(
    filters ? filters.pageSize || DEFAULT_PAGESIZE : DEFAULT_PAGESIZE
  );
  const [invoiceType, setInvoiceType] = useState(
    filters ? filters.type || [] : []
  );

  const [invoiceState, setInvoiceState] = useState(
    filters ? filters.state || [] : []
  );

  const [before, setBefore] = useState(filters ? filters.before : '');
  const [after, setAfter] = useState(filters ? filters.after : '');
  const [paymentBefore, setPaymentBefore] = useState(
    filters ? filters.paymentBefore : ''
  );
  const [paymentAfter, setPaymentAfter] = useState(
    filters ? filters.paymentAfter : ''
  );
  const [selectedClients, setSelectedClients] = useState(
    filters ? filters.client || [] : []
  );

  const [selectedProjects, setSelectedProjects] = useState(
    filters ? filters.project || [] : []
  );

  // item ~ product
  const [selectedProduct, setSelectedProduct] = useState(filters?.product);

  const [refresh, setRefresh] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState(null);

  const [queryTable, setQueryTable] = useState({});

  const [openConfirm, setOpenConfirm] = useState(false);
  function fetch() {
    return tableRef.current && tableRef.current.onQueryChange({ page: 0 });
  }

  const clearFilters = () => {
    setSelectedClients([]);
    setPaymentAfter('');
    setPaymentBefore('');
    setBefore('');
    setAfter('');
    setInvoiceState([]);
    setInvoiceType([]);
    setSelectedClients([]);
    setSelectedProjects([]);
    setSelectedProduct(null);
    fetch();
  };

  const flattenProject = () => {
    const clientsId = Object.keys(clientsProject);
    const projects = [];
    clientsId.forEach((clientId) => {
      clientsProject[clientId].forEach((project) => projects.push(project));
    });
    return projects;
  };
  const projects = flattenProject();

  useEffect(() => {
    if (refresh) {
      fetch();
    }
  }, [refresh]);

  function getTotal(inv) {
    let total = null;
    if (typeHelpers.isPrePayment(inv.type) && inv.toPay) {
      total = inv.toPay.amount;
    } else {
      total = inv.gross_total;
    }
    return NumberFormat.n(total);
  }

  function getRemainingTotal(inv) {
    let price = null;
    if (
      !typeHelpers.isPrePayment(inv.type) &&
      !typeHelpers.isInvoice(inv.type) &&
      !typeHelpers.isCreditNote(inv.type)
    ) {
      return '-';
    }

    const isPaid = !inv?.payments?.find((item) => item?.state === 'pending');

    if (inv.state === 'paid' || isPaid) {
      return `${NumberFormat.n(0)}€`;
    }
    if (typeHelpers.isPrePayment(inv.type) && inv.toPay) {
      price = inv.toPay.amount;
    } else if (inv.duePayableAmount !== null) {
      price = inv.duePayableAmount;
    } else {
      price = inv.gross_total;
    }

    return price !== null ? `${NumberFormat.n(price)}€` : `${t('not_setted')}`;
  }

  function renderType(value) {
    return t(`invoices.types.${value}`);
  }

  const renderClientName = (client) => {
    if (client && client.display_name) {
      if (client.isCompany) {
        return client.name;
      }
      return client.display_name;
    }
    return '-';
  };

  const invoiceIsOverdue = (invoicePayments) => {
    let earlierDate;
    invoicePayments.forEach((element) => {
      if (
        !earlierDate ||
        getUnixTime(earlierDate) < getUnixTime(element.date)
      ) {
        earlierDate = element.date;
      }
    });
    return (
      earlierDate && differenceInMinutes(new Date(), new Date(earlierDate)) >= 0
    );
  };

  /**
   * if type = invoice, state = 'validated' or 'overdue' => can resend mail
   * @param {*} invoice
   * @returns bool
   */
  const canResendEmail = (type, state, payments) => {
    if (!payments) return false;

    const isPaid =
      payments.filter((item) => item?.status === 'paid').length ===
      payments.length;

    return (
      typeHelpers.isInvoice(type) &&
      (stateHelpers.isValidated(state) ||
        (invoiceIsOverdue(payments) && stateHelpers.isValidated(state))) &&
      !isPaid
    );
  };

  const getInvoiceState = (payments, state, type) => {
    if (state === 'draft') return t('state.draft');
    if (state === 'paid') return t('state.paid');

    if (payments.length === 1) {
      if (
        (type === 'invoice' || type === 'pre_payment') &&
        state === 'validated'
      ) {
        const overdue = invoiceIsOverdue(payments);
        return overdue ? t(`state.overdue`) : t(`state.upcoming`);
      }
      return t(`state.${state}`);
    }

    const firstPendingPayment = payments.find(
      (item) => item?.status === 'pending'
    );

    if (!firstPendingPayment) return t('state.paid');

    const { date } = firstPendingPayment;

    if (isAfter(startOfDay(new Date()), new Date(date))) {
      return t('state.overdue');
    }

    return t('state.upcoming');
  };

  const getDueDate = (payments) => {
    const firstPendingPayment = payments.find(
      (item) => item?.status === 'pending'
    );

    if (firstPendingPayment) return firstPendingPayment.date;

    return null;
  };

  const getAmountLeft = (inv) => {
    const { transactions, gross_total: grossTotal, duePayableAmount } = inv;
    const total =
      (transactions?.length &&
        transactions.reduce((preVal, curVal) => {
          return preVal + curVal.amount;
        }, 0)) ||
      0;
    return NumberFormat.n(
      isNumber(duePayableAmount) ? duePayableAmount : grossTotal - total
    );
  };

  const formatInvoices = (invoices) =>
    invoices.map((inv) => {
      // eslint-disable-next-line prefer-const
      let overdue = false;
      const { type, state, payments, client } = inv;

      const reminders = inv.reminders ? inv.reminders.length : 0;
      const numberOfPaid =
        state === 'paid'
          ? payments.length
          : payments.filter((item) => item?.status === 'paid').length;
      const currPayment = payments.find((item) => item?.status === 'pending');

      const remaining = currPayment?.amount
        ? NumberFormat.n(currPayment.amount) + '€'
        : getRemainingTotal(inv);

      return {
        type,
        state: getInvoiceState(payments, state, type),
        invoice_nb: formatNum(inv),
        date_emission: inv.date_emission,
        client: renderClientName(client),
        gross_total: getTotal(inv),
        remaining_total: remaining,
        id: inv._id,
        reminders: overdue ? reminders : null,
        project: inv.project,
        // dueDate: payments?.length ? payments[0]?.date : null,
        dueDate:
          getDueDate(payments) || payments[payments.length - 1]?.date || null,
        paymentLink: inv.paymentLink,
        stripePaymentLink: inv.stripePaymentLink,
        canResend: canResendEmail(type, state, payments),
        canDelete: state === 'draft',
        company: inv.company,
        number_of_paid: `${numberOfPaid} / ${payments.length}`,
        payments: inv.payments,
        paymentLinkUsed: inv?.paymentLinkUsed || 0,
        amount_left: getAmountLeft(inv),
      };
    });

  const renderUnableResendDialog = () => {
    return (
      <Dialog
        open={showDialog}
        modal={true}
        onClose={() => setShowDialog(false)}
      >
        <DialogTitle disableTypography>
          <h2 style={{ marginBottom: -20 }}>
            {t('invoices.unable_resend_title')}
          </h2>

          <CloseIcon
            onClick={() => setShowDialog(false)}
            style={{ position: 'absolute', right: 15, top: 15 }}
          />
        </DialogTitle>
        <DialogContent>
          <p style={{ fontSize: 14 }}>
            {t('invoices.unable_resend_description')}
          </p>
        </DialogContent>
      </Dialog>
    );
  };

  const columns = [
    {
      title: t('invoices.list.type'),
      field: 'type',
      render: ({ type }) => renderType(type),
      sorting: false,
    },
    {
      title: t('invoices.list.state'),
      field: 'state',
      // eslint-disable-next-line react/prop-types
      render: ({ state }) => <InvoiceState state={state} />,
      sorting: false,
    },
    {
      title: t('invoices.list.reminders'),
      field: 'reminders',
      sorting: false,
    },
    {
      title: t('invoices.list.nb'),
      field: 'invoice_nb',
      sorting: true,
      defaultSort: sortByPrice ? undefined : 'desc',
    },
    {
      title: t('invoices.form_date'),
      field: 'date_emission',
      type: 'date',
      defaultSort: sortByPrice ? undefined : 'desc',
      // eslint-disable-next-line camelcase
      render: ({ date_emission }) =>
        format(new Date(date_emission), 'dd/MM/yyyy'),
    },
    {
      title: t('invoices.collection_date'),
      field: 'dueDate',
      type: 'date',
      defaultSort: sortByPrice ? undefined : 'desc',
      render: ({ dueDate }) => {
        if (!dueDate) return '-';
        return format(new Date(dueDate), 'dd/MM/yyyy');
      },
    },
    {
      title: t('invoices.form_client'),
      field: 'client',
      sorting: false,
    },
    {
      title: t('expenses.expense.form_amount'),
      field: 'gross_total',
      defaultSort: sortByPrice ? 'desc' : undefined,
      render: (value) => {
        return `${value?.gross_total}€`;
      },
    },
    {
      title: t('expenses.expense.form_amount_left'),
      field: 'amount_left',
      sorting: false,
      render: (value) => {
        return `${value?.amount_left}€`;
      },
    },
    {
      title: t('expenses.expense.remain_to_pay'),
      field: 'remaining_total',
      sorting: false,
    },
    {
      title: t('invoices.number_of_paid'),
      field: 'number_of_paid',
      sorting: false,
    },
    {
      title: ' ',
      field: 'id',
      sorting: false,
      // eslint-disable-next-line react/prop-types
      render: (value) => {
        const { id, canResend, canDelete } = value;

        return (
          <AppRowStartSpaceAround>
            {canUpdateInvoice && (
              <IconDuplicate
                sm
                disabled={refreshing}
                onClick={() => {
                  history.push({
                    pathname: `/invoices/new`,
                    tempId: id,
                  });
                }}
              />
            )}

            {(canReadInvoice || canUpdateInvoice) && (
              <IconEdit
                sm
                disabled={refreshing}
                onClick={() => {
                  history.push(`/invoices/edit/${id}`);
                }}
              />
            )}

            {canUpdateInvoice && canResend && (
              <ResendIcon
                onClick={() => {
                  const { payments } = value;
                  if (value?.paymentLinkUsed === payments.length) {
                    setShowDialog(true);
                    setContentDialog({
                      title: t('invoices.unable_resend_title'),
                      description: t('invoices.reconcile_no_resend'),
                    });
                    return;
                  }

                  const currPayment = payments.find(
                    (item) => item?.status === 'pending'
                  );
                  if (currPayment && !currPayment?.paymentLink) {
                    const diff = differenceInDays(
                      new Date(currPayment.date),
                      new Date()
                    );
                    if (diff > 7) {
                      setContentDialog({
                        title: t('invoices.unable_resend_title'),
                        description: t('invoices.unable_resend_description'),
                      });
                      setShowDialog(true);

                      return;
                    }
                  }
                  setSelectedInvoice(value);
                  toggleEmailDialog();
                }}
              />
            )}

            {canDelete && (
              <IconDelete
                sm
                onClick={() => {
                  setOpenConfirm(true);
                  setSelectedInvoice(value);
                }}
              />
            )}
          </AppRowStartSpaceAround>
        );
      },
      export: false,
    },
  ];

  const renderClientValue = (selected) => {
    const foundClient = selected
      .map((s) => clients.find((c) => c._id === s)?.display_name || '')
      .join(', ');

    return foundClient;
  };

  const renderProjectValue = (selected) => {
    const foundProject = selected
      .map((s) => projects.find((c) => c._id === s)?.name || '')
      .join(', ');

    return foundProject;
  };

  function renderToolBar() {
    return (
      <Grid
        container
        direction="row"
        alignItems="center"
        spacing={4}
        style={{ paddingBottom: '30px', paddingTop: '10px' }}
      >
        <Grid item xs={10}>
          <Grid container direction="row" alignItems="center" spacing={4}>
            <Grid item>
              <FormControl style={{ width: 100, marginRight: 10 }}>
                <InputLabel id="demo-mutiple-checkbox-label">
                  {t('invoices.list.type')}
                </InputLabel>
                <Select
                  onClose={() => {
                    fetch();
                  }}
                  fullWidth={true}
                  multiple
                  value={invoiceType}
                  onChange={(event) => setInvoiceType(event.target.value)}
                  input={<Input />}
                  renderValue={(selected) =>
                    selected.map((s) => renderType(s)).join(', ')
                  }
                >
                  {invoiceTypes.map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox checked={invoiceType.indexOf(type) > -1} />
                      <ListItemText primary={renderType(type)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl style={{ width: 100 }}>
                <InputLabel id="demo-mutiple-checkbox-label">
                  {t('invoices.list.state')}
                </InputLabel>
                <Select
                  onClose={() => {
                    fetch();
                  }}
                  fullWidth={true}
                  multiple
                  value={invoiceState}
                  onChange={(event) => setInvoiceState(event.target.value)}
                  input={<Input />}
                  renderValue={(selected) =>
                    selected.map((s) => t(`state.${s}`)).join(', ')
                  }
                >
                  {invoiceStates.map((state) => (
                    <MenuItem key={state} value={state}>
                      <Checkbox checked={invoiceState.indexOf(state) > -1} />
                      <ListItemText primary={t(`state.${state}`)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <HorizontalBeforAfterDate
                onBeforeChange={(value) => {
                  setBefore(value);
                  fetch();
                }}
                onAfterChange={(value) => {
                  setAfter(value);
                  fetch();
                }}
                before={before}
                after={after}
                afterTitle={t('invoice.filters.after')}
                beforeTitle={t('invoice.filters.before')}
              />
            </Grid>
            <Grid item>
              <FormControl style={{ width: 100, marginRight: 10 }}>
                <InputLabel id="demo-mutiple-checkbox-label">
                  {'Client(s)'}
                </InputLabel>
                <Select
                  onClose={() => {
                    fetch();
                  }}
                  fullWidth={true}
                  multiple
                  value={selectedClients}
                  onChange={(event) => setSelectedClients(event.target.value)}
                  input={<Input />}
                  renderValue={renderClientValue}
                >
                  {map(clients, (c) => c).map((client) => (
                    <MenuItem key={client._id} value={client._id}>
                      <Checkbox
                        checked={selectedClients.indexOf(client._id) > -1}
                      />
                      <ListItemText primary={client.display_name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl style={{ width: 100, marginRight: 10 }}>
                <InputLabel id="demo-mutiple-checkbox-label">
                  {t('expenses.expense.form_project')}
                </InputLabel>
                <Select
                  onClose={() => {
                    fetch();
                  }}
                  fullWidth={true}
                  multiple
                  value={selectedProjects}
                  onChange={(event) => setSelectedProjects(event.target.value)}
                  input={<Input />}
                  renderValue={renderProjectValue}
                >
                  {projects.map((project) => (
                    <MenuItem key={project._id} value={project._id}>
                      <Checkbox
                        checked={selectedProjects.indexOf(project._id) > -1}
                      />
                      <ListItemText primary={project.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl style={{ width: 100, marginRight: 10 }}>
                <InputLabel>{t('invoices.list.product')}</InputLabel>
                <Select
                  onClose={() => {
                    fetch();
                  }}
                  fullWidth={true}
                  value={selectedProduct}
                  onChange={(event) => setSelectedProduct(event?.target?.value)}
                  input={<Input />}
                  renderValue={(selected) => selected ?? selected.name}
                >
                  {Object.keys(products).map((productId) => (
                    <MenuItem
                      key={products[productId]._id}
                      value={products[productId].name}
                    >
                      <ListItemText primary={products[productId].name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <HorizontalBeforAfterDate
                onAfterChange={(value) => {
                  setPaymentAfter(value);
                  fetch();
                }}
                onBeforeChange={(value) => {
                  setPaymentBefore(value);
                  fetch();
                }}
                after={paymentAfter}
                before={paymentBefore}
                beforeTitle={t('invoice.filters.payment_before')}
                afterTitle={t('invoice.filters.payment_after')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={2} style={{ display: 'flex', justifyContent: 'end' }}>
          <Tooltip title={t('reset_filter')}>
            <Button
              startIcon={<Refresh />}
              onClick={() => clearFilters()}
              // disabled={selectedIndices.length < 1}
            >
              {t('reset_filter')}
            </Button>
          </Tooltip>
        </Grid>
      </Grid>
    );
  }
  const getCompanySubscription = async () => {
    dispatch(fetchCompanySubscription());
  };

  useEffect(() => {
    getCompanySubscription();
  }, []);

  const handleFetchInvoices = (query) => {
    return new Promise((resolve) => {
      dispatch(
        fetchInvoicePaginate(
          query,
          {
            types: invoiceType,
            states: invoiceState,
            before: before ? `${before}T23:59:59.999Z` : '',
            after,
            paymentBefore: paymentBefore ? formatBeforeDate(paymentBefore) : '',
            paymentAfter,
            clients: selectedClients,
            projects: selectedProjects,
            itemName: selectedProduct,
          },
          refresh
        )
      ).then((res) => {
        setRefresh(false);
        resolve({ ...res, data: formatInvoices(res.data || []) });
      });
    });
  };

  const handleDeleteDraft = async (isConfirm) => {
    if (isConfirm)
      try {
        spinnerService.startSpinner();
        await InvoiceService.deleteInvoice(selectedInvoice.id);
        setRefresh(true);
      } catch (error) {
        throw new Error(error);
      } finally {
        spinnerService.endSpinner();
      }
  };

  const formatInvoicesToExport = (data = []) => {
    const formatData = data?.map((item) => {
      const newItem = {
        [t('invoices.list.type')]: renderType(item.type),
        [t('invoices.list.state')]: t(item.state.toLowerCase()),
        [t('invoices.list.reminders')]: item.reminders,
        [t('invoices.list.nb')]: item.invoice_nb,
        [t('invoices.form_date')]: format(
          new Date(item.date_emission),
          'dd/MM/yyyy'
        ),
        [t('invoices.collection_date')]: !item.dueDate
          ? '-'
          : format(new Date(item.dueDate), 'dd/MM/yyyy'),
        [t('invoices.list.project')]:
          item.projects?.find((item) => item._id === item.project)?.name || '-',
        [t('invoices.form_client')]: item?.client,
        [t('expenses.expense.form_amount')]: `${item?.gross_total}€`,
        [t('expenses.expense.remain_to_pay')]: item?.remaining_total,
        [t('invoices.number_of_paid')]: `'${item?.number_of_paid}'`,
      };
      return newItem;
    });
    return formatData;
  };

  const handleExportInvoice = async () => {
    try {
      spinnerService.startSpinner();
      const result = await handleFetchInvoices({
        ...queryTable,
        pagination: false,
      });

      const invoices = result.data ?? [];
      if (!invoices.length) {
        return;
      }

      const response = await InvoiceService.downloadInvoice(
        invoices.map((item) => item.id)
      );

      const fileName = `${dateFns.format(new Date(), 'yyyyMMdd')}_invoice.zip`;
      FileSaver.saveAs(
        new Blob([response.data], { type: 'application/zip' }),
        fileName
      );
    } catch (error) {
      logger.log(error);
    } finally {
      spinnerService.endSpinner();
    }
  };

  return (
    <>
      {renderToolBar()}
      {renderUnableResendDialog()}
      {showEmailDialog && selectedInvoice && (
        <InvoiceResendMailDialog
          toggleDialog={toggleEmailDialog}
          invoice={selectedInvoice}
          loggedUser={loggedUser}
          loggedUserCompany={loggedUserCompany}
          emailTemplates={emailTemplates}
          companySubscription={companySubscription}
        />
      )}
      <ConfirmDialog
        toggleDialog={setOpenConfirm}
        confirmMessage={t('expenses.expense.confirm_delete_text')}
        isShowDialog={openConfirm}
        // type={confirmType}
        onConfirm={handleDeleteDraft}
        isCancelConfirm={true}
      />
      <MaterialTable
        title="Invoices"
        tableRef={tableRef}
        columns={columns}
        data={async (query) => {
          setQueryTable(query);
          const invoices = await handleFetchInvoices(query);
          return invoices;
        }}
        onChangeRowsPerPage={(ps) => {
          setPageSize(ps);
        }}
        options={{
          filtering: false,
          pageSize,
          search: true,
          showTitle: false,
          exportButton: { csv: true },
          exportCsv: () => handleExportInvoice(),
          columnsButton: true,
        }}
        actions={[
          {
            icon: () => <Refresh />,
            isFreeAction: true,
            onClick: () => setRefresh(true),
          },
        ]}
        onOrderChange={() => {
          setSortByPrice(false);
        }}
        exportName={t('material_table.toolbar.export_title')}
      />
    </>
  );
};

InvoiceListBody.propTypes = {
  canReadInvoice: PropTypes.bool.isRequired,
  refreshing: PropTypes.bool.isRequired,
};

export default InvoiceListBody;
