import { Box, Button, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const WalletDeleteWarning = (props) => {
  const { onChangeStep } = props;
  const history = useHistory();

  const { t } = useTranslation();

  const onClickContinue = () => {
    onChangeStep();
  };

  const onClickKeepMyAccount = () => {
    history.push('/bank-bbf/accounts');
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box style={{ padding: '10px 0', maxWidth: '800px' }}>
        <Box style={{ paddingBottom: '10px' }}>
          <Typography
            variant="h4"
            color="primary"
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              paddingBottom: '5px',
            }}
          >
            {t('close_wallet.warning.title')}
          </Typography>
          <Typography
            variant="h5"
            style={{ color: 'black', textAlign: 'center' }}
          >
            {t('close_wallet.warning.sub-title')}
          </Typography>
        </Box>
        <Box
          style={{
            paddingBottom: '30px',
            color: '#f44336',
          }}
        >
          <ul>
            <li>
              <Typography style={{ fontSize: '16px' }}>
                {t('close_wallet.warning.condition-1')}
              </Typography>
            </li>
            <li>
              <Typography style={{ fontSize: '16px' }}>
                {t('close_wallet.warning.condition-2-3')}
              </Typography>
            </li>
          </ul>

          <Typography style={{ fontSize: '16px' }}>
            {t('close_wallet.warning.content')}
          </Typography>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '0 120px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onClickContinue}
          >
            {t('close_wallet.warning.continue')}
          </Button>
          <Button
            variant="outlined"
            color="default"
            size="large"
            onClick={onClickKeepMyAccount}
          >
            {t('close_wallet.warning.keep_my_account')}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

WalletDeleteWarning.propTypes = {
  onChangeStep: PropTypes.func,
};

export default WalletDeleteWarning;
