import logger from 'helpers/logger';
import queryString from 'query-string';
import {
  LOCAL_STORAGE_PERSONAL_INFO,
  LOCAL_STORAGE_SUBSCRIPTION,
} from 'config/Constants';
import APP_ROUTING from 'constants/appRouting';

export default (
  values,
  { resetForm, setErrors, setSubmitting, props, setStatus }
) => {
  const cb = (err) => {
    setStatus(false);
    if (err) {
      setSubmitting(false);
      setErrors({
        internalError: err?.message || 'une erreur interne est survenue',
      });
    } else {
      localStorage.removeItem(LOCAL_STORAGE_PERSONAL_INFO);
      localStorage.removeItem(LOCAL_STORAGE_SUBSCRIPTION);
      resetForm();
      props.history.push(APP_ROUTING.SIGNUP_CONFIRM);
    }
  };

  const {
    cga,
    tva,
    companyType,
    micro,
    prestation,
    vente,
    return_on_debit: returnOnDebit,
    bookkeeping,
    /* eslint-disable-next-line */
    periodic_VAT_returns,
  } = values;

  const newValues = { ...values };
  // delete newValues.phone_number;
  newValues.settings = {
    tax_registration: {
      cga,
      tva,
      companyType,
      micro,
      prestation,
      vente,
      return_on_debit: tva ? returnOnDebit : false,
      /* eslint-disable-next-line */
      periodic_VAT_returns: tva ? periodic_VAT_returns : undefined,
      bookkeeping,
    },
  };
  const qs = window.location.search;
  if (qs) {
    logger.log('QueryString is [', qs, ']');
    const qsObj = queryString.parse(qs);
    newValues.subscriptionId = qsObj.subscription_id;
  }

  // Format the rcs
  // eslint-disable-next-line no-param-reassign
  values.rcs = `${values.rcsType} ${values.rcs}`;

  setStatus(true);
  props.signupWithRemoveApiVersion(newValues, cb);
};

export const shortSignUp = (
  values,
  resetForm,
  setErrors,
  setSubmitting,
  signup,
  history,
  setIsShortSubmitting
) => {
  const cb = (err) => {
    // console.log('CALLED');
    if (err) {
      // console.log('ERROR');
      setSubmitting(false);
      setIsShortSubmitting(false);
      setErrors({
        internalError: 'une erreur interne est survenue',
      });
    } else {
      // console.log('RESETFORM');
      localStorage.removeItem(LOCAL_STORAGE_PERSONAL_INFO);
      localStorage.removeItem(LOCAL_STORAGE_SUBSCRIPTION);
      resetForm();
      history.push(APP_ROUTING.SIGNUP_CONFIRM);
    }
  };
  const newValues = { ...values };
  delete newValues.phone_number;
  const qs = window.location.search;
  if (qs) {
    logger.log('QueryString is [', qs, ']');
    const qsObj = queryString.parse(qs);
    newValues.utm = qsObj;
  }
  setSubmitting(true);
  signup(newValues, cb);
};
