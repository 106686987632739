import {
  CREATING_BANK_LINK,
  CREATE_BANK_LINK_FAILED,
  CREATE_BANK_LINK_SUCCESS,
  FETCHING_BANK_LINK,
  FETCH_BANK_LINK_FAILED,
  FETCH_BANK_LINK_SUCCESS,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  FETCHING_BANK_LINK_FUNNEL_URL,
  FETCH_BANK_LINK_FUNNEL_URL_FAILED,
  FETCH_BANK_LINK_FUNNEL_URL_SUCCESS,
} from 'actions/Types';

import logger from 'helpers/logger';

const initialState = {
  loading: false,
  error: null,
  timestamp: null,
  connectionUrl: null,
  accounts: null,
  connection: null, // Lien entre company_id et le "user" bankin
  data: {}, // les statements
};

export default (state = initialState, action) => {
  switch (action.type) {
    // case FETCHING_BANK_ACCOUNTS:
    //   return {
    //     ...state,
    //     loading: true,
    //     accounts: null,
    //     error: null,
    //   };
    // case FETCH_BANK_ACCOUNT_FAILED: {
    //   const { error } = action.payload;
    //   logger.error('xx_BANKCONNECTORx_FAILED', error);
    //   return {
    //     ...state,
    //     accounts: null,
    //     loading: false,
    //     error,
    //   };
    // }
    // case FETCH_BANK_ACCOUNT_SUCCESS: {
    //   const { resources } = action.payload;
    //   return {
    //     ...state,
    //     loading: false,
    //     error: null,
    //     accounts: resources,
    //   };
    // }
    case FETCHING_BANK_LINK_FUNNEL_URL:
      return {
        ...state,
        loading: true,
        connectionUrl: null,
        error: null,
      };
    case FETCH_BANK_LINK_FUNNEL_URL_FAILED: {
      const { error } = action.payload;
      logger.error('xx_BANKCONNECTORx_FAILED', error);
      return {
        ...state,
        connectionUrl: null,
        loading: false,
        error,
      };
    }
    case FETCH_BANK_LINK_FUNNEL_URL_SUCCESS: {
      const { bankurl } = action.payload;
      // logger.log('bankurl', bankurl);
      return {
        ...state,
        loading: false,
        error: null,
        connectionUrl: bankurl,
      };
    }
    case FETCHING_BANK_LINK:
    case CREATING_BANK_LINK:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null,
        connection: null,
      };

    case FETCH_BANK_LINK_FAILED:
    case CREATE_BANK_LINK_FAILED: {
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      const { error } = action.payload;
      logger.error('xx_BANKCONNECTORx_FAILED', error);

      return {
        ...state,
        loading: false,
        error,
        connection: null,
      };
    }

    case FETCH_BANK_LINK_SUCCESS:
    case CREATE_BANK_LINK_SUCCESS: {
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      const { bankConnector } = action.payload;
      // TODO: add the new evenue in data
      return {
        ...state,
        loading: false,
        error: null,
        connection: bankConnector,
      };
    }

    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return initialState;

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
};
