import { format } from 'date-fns';

/* eslint-disable-next-line */
export default ({
  match: {
    params: { id },
  },
  expenses,
}) => {
  if (id && expenses[id]) {
    const obj = expenses[id];
    obj.date = format(new Date(obj.date), 'yyyy-MM-dd');
    return obj;
  }

  return {
    date: format(new Date(), 'yyyy-MM-dd'),
    payee_id: '',
    total: '',
    currency: 'EUR',
  };
};
