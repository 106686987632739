import { bindActionCreators } from 'redux';
import { handleAuthentication } from '../../actions/AuthActions';
import { initializeUser } from '../../actions/LoggedUserActions';

export default (dispatch) =>
  bindActionCreators(
    {
      handleAuthentication,
      initializeUser,
    },
    dispatch
  );
