const styles = (theme) => ({
  heading: {
    display: 'flex',
    paddingBottom: theme.spacing(2),
    alignItems: 'flex-start',
    gap: theme.spacing(2),
    alignSelf: 'stretch',
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
  },
  contentLeft: {
    display: 'flex',
    flex: '1 0 0',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
    cursor: 'pointer',
  },
  contentLeftSubTitle: {
    color: theme.palette.neutral[500],
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  contentLeftBoxTotal: {
    display: 'flex',
    alignItems: 'flex-end',
    gap: theme.spacing(0.25),
  },
  contentLeftTotal: {
    color: theme.palette.red[600],
    fontSize: '1.875rem',
    fontWeight: 700,
    lineHeight: '1.875rem',
  },
  contentRight: {
    display: 'flex',
    gap: theme.spacing(5),
    alignItems: 'center',
  },

  dataLabelContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  ellipse: ({ color }) => ({
    width: '8px',
    height: '8px',
    borderRadius: '50%',
    backgroundColor: color,
  }),
  dataLabelTitle: {
    color: theme.palette.neutral[500],
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '1.5rem',
  },
  dataLabelBoxTitle: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
  },
  dataLabelBoxAmount: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.25),
  },
  dataLabelAmount: {
    color: theme.palette.neutral[700],
    fontSize: '1.25rem',
    lineHeight: '1.875rem',
    fontWeight: 700,
  },
  boxFiveBiggestCard: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: theme.spacing(3),
    flexDirection: 'row',
    width: '100%',
  },
});

export default styles;
