const initialValues = (treezorUserId, document, companyId) => {
  return {
    companyId,
    index: document.index,
    treezorUserId,

    type: null,
    filename: null,
    status: document.status,
    message: document.message,
  };
};

export default initialValues;
