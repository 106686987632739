import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

import PlafondPaiement from './PlafondPaiement';
import MailAlert from './MailAlert';

const Alert = () => {
  const { t } = useTranslation();
  const { values } = useFormikContext();

  return (
    <Grid container style={{ padding: '20px' }} spacing={4}>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="button"
          style={{ fontSize: '18px', color: '#02cbcd' }}
        >
          {t('payment_limit')}
        </Typography>
        <div>
          <PlafondPaiement
            name="limitPaymentDay"
            textFieldName="weeklyPayment"
            checked={values.limitPaymentDay}
          />
          <PlafondPaiement
            name="limitPaymentWeek"
            textFieldName="dailyPayment"
            checked={values.limitPaymentWeek}
          />
          <PlafondPaiement
            name="limitPaymentMonth"
            textFieldName="montlyPayment"
            checked={values.limitPaymentMonth}
          />
          <PlafondPaiement
            name="limitPaymentYear"
            textFieldName="yearPayment"
            lastone={true}
            checked={values.limitPaymentYear}
          />
        </div>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography
          variant="button"
          style={{ fontSize: '18px', color: '#02cbcd' }}
        >
          {t('with_drawal_limit')}
        </Typography>
        <div>
          <PlafondPaiement
            name="limitAtmDay"
            textFieldName="dailyAtm"
            checked={values.limitAtmDay}
          />
          <PlafondPaiement
            name="limitAtmWeek"
            textFieldName="weeklyAtm"
            checked={values.limitAtmWeek}
          />
          <PlafondPaiement
            name="limitAtmMonth"
            textFieldName="montlyAtm"
            checked={values.limitAtmMonth}
          />
          <PlafondPaiement
            name="limitAtmYear"
            textFieldName="yearAtm"
            lastone={true}
            checked={values.limitAtmYear}
          />
        </div>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant="button"
          style={{ fontSize: '18px', color: '#02cbcd' }}
        >
          {t('mail_alerts')}
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <MailAlert text={t('payment_limit_alert')} name="alertLimit" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MailAlert text={t('with_drawal_limit_alert')} name="aliertAtm" />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

Alert.propTypes = {
  dummy: PropTypes.string,
};

export default Alert;
