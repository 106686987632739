/* eslint-disable react/display-name */

import Header from 'components/Header';
import Layout from 'components/Layout';
import MaterialTable from 'components/MaterialTable';
import { useRouter } from 'hooks/routerHooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
// import { IconMessage } from 'components/AppIcons';
import { Archive, Check, Close } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import S3FileService from 'services/S3FileService';

import {
  Button,
  Divider,
  IconButton,
  Popover,
  TextField,
  Tooltip,
} from '@material-ui/core';
import {
  archiveSplitPDFHistoryFile,
  getOriginalById,
  getPDFHistory,
  markAsDonePDFFile,
  renameSplitFile,
} from 'actions/PDFActions';
import axios from 'axios';
import {
  ArchiveIcon,
  DownloadIcon,
  EyesIcon,
  FileIcon,
  RenameIcon,
} from 'components/AppIcons';

const PDFItem = (props) => {
  const { t } = useTranslation();
  // eslint-disable-next-line react/prop-types
  const { id: originalId } = props.match.params;

  const { history } = useRouter();
  const dispatch = useDispatch();
  const tableRef = React.createRef();

  const [original, setOriginal] = useState(null);
  const [editPDFField, setEditPDFField] = useState('');
  const [selectedId, setSelectedId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);
  const [table, setTable] = useState({
    data: [],
    page: 0,
    rowPerPage: 5,
    total: 0,
  });

  useEffect(() => {
    const getPDFHistoryInDb = async () => {
      setTableLoading(true);

      const response = await dispatch(
        getPDFHistory(originalId, {
          page: table.page,
          limit: table.rowPerPage,
        })
      );

      // setPDFItemData(response);
      setTable({
        ...table,
        data: response.data,
      });
      setTableLoading(false);
    };
    getPDFHistoryInDb();
  }, [table.page, table.rowPerPage]);

  useEffect(() => {
    (async () => {
      const originalFile = await dispatch(getOriginalById(originalId));
      setOriginal(originalFile);
    })();
  }, [originalId]);

  const handleOpenRenamePopover = (event, id) => {
    setSelectedId(id);
    setAnchorEl(event.currentTarget);
  };

  const onClickEditPDFField = async () => {
    setAnchorEl(null);
    if (editPDFField === '') return;
    setTableLoading(true);

    const renameSplitFileResponse = await dispatch(
      renameSplitFile(selectedId, `${editPDFField}.pdf`)
    );

    setTable((prev) => {
      const tableDataCloned = [...prev.data];
      const foundIndex = tableDataCloned.findIndex(
        (i) => i._id === renameSplitFileResponse.data.data._id
      );

      if (foundIndex === -1) return tableDataCloned;
      tableDataCloned[foundIndex] = {
        ...tableDataCloned[foundIndex],
        customFileName: renameSplitFileResponse.data.data.customFileName,
      };
      return { ...prev, data: tableDataCloned };
    });
    setTableLoading(false);
  };

  const onClickMarkAsDone = async (isDone) => {
    setTableLoading(true);

    await dispatch(
      markAsDonePDFFile({ splitPDFPoolId: originalId, markAsDone: isDone })
    );

    setOriginal((prev) => ({
      ...prev,
      markAsDone: !original.markAsDone,
    }));
    setTableLoading(false);
  };

  const downloadFile = (fileURL, fileName) => {
    axios.get(fileURL, { responseType: 'blob' }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      link.remove();

      window.addEventListener(
        'focus',
        () => {
          URL.revokeObjectURL(link.href);
        },
        {
          once: true,
        }
      );
      setTableLoading(false);
    });
  };

  const onClickArchiveButton = async (splitPDFHistoryId) => {
    setTableLoading(true);
    const response = await dispatch(
      archiveSplitPDFHistoryFile({
        splitPDFHistoryId,
        isArchived: true,
      })
    );

    setTable((prev) => {
      const tableDataCloned = [...prev.data];
      const newTableData = tableDataCloned.filter(
        (d) => d._id !== response.data.data._id
      );
      return {
        ...prev,
        data: newTableData,
      };
    });

    setTableLoading(false);
  };

  const columns = [
    {
      title: t('pdf_purchase.created_on'),
      field: 'created_at',
      // eslint-disable-next-line react/prop-types
      render: ({ created_at: createdAt }) =>
        `${new Date(createdAt).toLocaleDateString()} - ${new Date(
          createdAt
        ).toLocaleTimeString()}`,
      sorting: false,
      editable: 'never',
    },
    {
      title: t('pdf_purchase.file_name'),
      field: 'splitFileKey',
      // render: ({ type }) => renderType(type),
      sorting: false,
      render: ({ customFileName, splitFileKey }) =>
        customFileName || splitFileKey,
    },
    {
      title: t('pdf_purchase.pages'),
      field: 'splitParams',
      sorting: false,
      // eslint-disable-next-line react/prop-types
      render: ({ splitParams }) => splitParams.join(', '),
      editable: 'never',
    },
    {
      title: t('pdf_purchase.number_of_pages'),
      field: 'splitParams',
      sorting: false,
      // eslint-disable-next-line react/prop-types
      render: ({ splitParams }) => splitParams.length,
      editable: 'never',
    },
    {
      title: t('expenses.expense.type.expense'),
      field: 'splitParams',
      sorting: false,
      // eslint-disable-next-line react/prop-types
      render: ({ expenseOId }) =>
        expenseOId ? (
          <Link to={`/purchases/edit/${expenseOId}`}>
            {t('pdf_purchase.counted')}
          </Link>
        ) : (
          t('pdf_purchase.not_counted')
        ),
      editable: 'never',
    },
    {
      title: ' ',
      field: 'splitFileKey',
      sorting: false,

      // eslint-disable-next-line react/prop-types
      render: ({ splitFileKey, id, customFileName, expenseOId }) => (
        <div style={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
          <RenameIcon sm onClick={(e) => handleOpenRenamePopover(e, id)} />
          <Popover
            id={id}
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <div style={{ padding: 12, display: 'flex', gap: 20 }}>
              <TextField
                variant="outlined"
                label={t('pdf_purchase.rename')}
                size="small"
                onChange={(e) => setEditPDFField(e.target.value)}
              />
              <IconButton
                size="small"
                aria-label="rename"
                onClick={() => onClickEditPDFField()}
              >
                <Check />
              </IconButton>
            </div>
          </Popover>

          <DownloadIcon
            sm
            onClick={async () => {
              setTableLoading(true);
              const { url } = await S3FileService.geturl(splitFileKey);
              downloadFile(url, customFileName || splitFileKey);
            }}
          />

          {!expenseOId && (
            <FileIcon
              sm
              style={{ color: '#B2B2B2' }}
              onClick={() => {
                history.push(
                  `/purchases/new?extractKey=${splitFileKey}&splitId=${id}`
                );
              }}
            />
          )}

          <Tooltip title={t('pdf_purchase.view_mode')} placement="top">
            <EyesIcon
              sm
              tooltip={t('pdf_purchase.view_mode')}
              onClick={() => {
                history.push(
                  `/pdf/item?originalId=${originalId}&extractedKey=${splitFileKey}&splitId=${id}&viewMode=true`
                );
              }}
            />
          </Tooltip>

          <ArchiveIcon sm onClick={() => onClickArchiveButton(id)} />
        </div>
      ),
      editable: 'never',
    },
  ];

  const renderBody = () => {
    return (
      <div>
        <div
          style={{
            display: 'flex',
            marginBottom: '15px',
            // justifyContent: 'space-between',
            alignItems: 'center',
            gap: '10px',
          }}
        >
          <div
            style={{
              flex: 1,
              wordBreak: 'break-all',
              fontWeight: 'bold',
              fontSize: '16px',
            }}
          >
            {original !== null &&
              (original.customFileName || original.originalFileKey)}
          </div>
          <Divider orientation="vertical" flexItem />
          <Tooltip
            title={
              original?.numberOfPages === original?.usedPages.length
                ? t('pdf_purchase.mark_as_done_warning')
                : ''
            }
          >
            <span>
              {original?.markAsDone ? (
                <Button
                  startIcon={<Close />}
                  variant="outlined"
                  color="primary"
                  onClick={() => onClickMarkAsDone(false)}
                  disabled={
                    tableLoading ||
                    original?.numberOfPages === original?.usedPages.length
                  }
                >
                  {t('pdf_purchase.unmark_as_done')}
                </Button>
              ) : (
                <Button
                  startIcon={<Check />}
                  variant="outlined"
                  color="primary"
                  disabled={
                    tableLoading ||
                    original?.numberOfPages === original?.usedPages.length
                  }
                  onClick={() => onClickMarkAsDone(true)}
                >
                  {t('pdf_purchase.mark_as_done')}
                </Button>
              )}
            </span>
          </Tooltip>

          <Divider orientation="vertical" flexItem />
          <Button
            startIcon={<Archive />}
            variant="contained"
            color="primary"
            disabled={tableLoading}
            onClick={() => {
              history.push(`/pdf/item/${originalId}/archive`);
            }}
          >
            {t('pdf_purchase.archived_files')}
          </Button>
        </div>
        <MaterialTable
          title={t('pdf_purchase.split_file')}
          tableRef={tableRef}
          columns={columns}
          page={table.page}
          onChangePage={(p) => setTable({ ...table, page: p })}
          onChangeRowsPerPage={(perPage) =>
            setTable({ ...table, rowPerPage: perPage })
          }
          pageSize={table.rowPerPage}
          isLoading={tableLoading}
          data={
            table.data
            // (query) =>
            // new Promise((resolve) => {
            //   dispatch(getPDFHistory(originalId, query)).then((res) => {
            //     resolve({ ...res });
            //   });
            // })
          }
          options={{ pageSizeOptions: [] }}
        />
      </div>
    );
  };

  return (
    <Layout
      header={
        <Header
          name="PDF History"
          spaceBetween
          goBack={() => {
            history.goBack();
          }}
        />
      }
      // sidebarLeft={true}
      // sidebarRight={false}
      body={renderBody()}
      showUserCard={true}
    />
  );
};

export default PDFItem;
