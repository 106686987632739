import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appCategoryIcons';
import '../../assets/css/icons.css';
import OutsideClick from '../../helpers/outsideClick';
// import colors from '../../constants/colors.json';

const Icon = ({
  onClick,
  onSelect,
  classes,
  disabled,
  category,
  display,
  icon,
}) => {
  const [displayDropdown, toggleDropdown] = useState(false);
  // const [isGreen, setIsGreen] = useState(false);

  // const setGreenBackToFalse = () => {
  //   if (setIsGreen !== undefined) {
  //     setIsGreen(false);
  //   }
  //   window.removeEventListener('click', setGreenBackToFalse);
  // };

  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.disabled]: disabled,
    [classes.displayOpened]: displayDropdown && !disabled,
    [`icon icon-${icon}`]: true,
  });
  const containerClasses = classNames({
    [classes.container]: true,
    [classes.disabled]: disabled,
  });
  const iconContainerClasses = classNames({
    [classes.iconContainer]: true,
  });
  const optionDropdownClasses = classNames({
    [classes.optionButton]: true,
    [classes.disabledOptionButton]: disabled,
    [classes.displayOpened]: displayDropdown,
  });
  const itemsContainerClasses = classNames({
    [classes.itemsContainer]: true,
    [classes.open]: displayDropdown && !disabled,
  });

  // const greenBackground = { backgroundColor: colors.green };
  return (
    <OutsideClick
      displayDropdown={displayDropdown}
      uponClick={() => {
        toggleDropdown(!displayDropdown);
      }}
      disabled={false}
    >
      <div
        style={{ width: '150px' }}
        onClick={() => {
          if (!disabled) {
            // setTimeout(() => {
            //   window.addEventListener('click', setGreenBackToFalse);
            // }, 10);
            // setIsGreen(true);
          }
        }}
      >
        <div
          className={containerClasses}
          onClick={() =>
            onClick || (category && category.children)
              ? toggleDropdown(!displayDropdown)
              : onSelect(category)
          }
          // style={isGreen ? greenBackground : {}}
        >
          <div className={iconContainerClasses}>
            <div type="button" className={iconClasses} onClick={onClick} />
          </div>
          <button className={optionDropdownClasses}>{display}</button>
          <div className={itemsContainerClasses}>
            {category &&
              category.children &&
              category.children.map((subCat) =>
                subCat.displayed === true ? (
                  <p
                    key={subCat._id}
                    id={subCat._id}
                    onClick={subCat.enabled ? () => onSelect(subCat) : () => {}}
                    style={
                      subCat.enabled
                        ? {}
                        : {
                            cursor: 'not-allowed',
                          }
                    }
                  >
                    {subCat.display}
                  </p>
                ) : null
              )}
          </div>
        </div>
      </div>
    </OutsideClick>
  );
};
Icon.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  category: PropTypes.object,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func,
  display: PropTypes.string.isRequired,
  icon: PropTypes.string,
};
Icon.defaultProps = {
  disabled: false,
  onClick: null,
  category: null,
};
export default withStyles(style)(Icon);
