/* Packages */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

/* Actions */
import {
  getPendingPayinRequestAction,
  getPendingPayoutRequestAction,
} from 'actions/WalletActions';

/* Components */
import Layout from 'components/Layout';
import Header from 'components/Header';
import WalletDeleteCondition from 'containers/BankBBF/components/WalletDeleteCondition';
import WalletDeleteWarning from 'containers/BankBBF/components/WalletDeleteWarning';
import WalletDeleteConfirm from 'containers/BankBBF/components/WalletDeleteConfirm';
// import WalletDeleteReConfirm from 'containers/BankBBF/components/WalletDeleteReConfirm';
import WalletDeleteReConfirm2 from 'containers/BankBBF/components/WalletDeleteReConfirm2';
import WalletDeleteReConfirmCompleteCondition from 'containers/BankBBF/components/WalletDeleteReConfirmCompleteCondition';

/* Styles */
// import useStyles from './styles';

const WalletDeletePage = () => {
  const treezorWallet = useSelector((state) =>
    state.wallet && state.wallet.wallet ? state.wallet.wallet[0] : null
  );

  const [walletDeleteStep, setWalletDeleteStep] = useState(1);
  const [loadingCheckCondition, setLoadingCheckCondition] = useState(false);
  const [pendingPayout, setPendingPayout] = useState([]);
  const [pendingPayin, setPendingPayin] = useState([]);

  useEffect(() => {
    if (!treezorWallet) return;
    setLoadingCheckCondition(true);

    const getPendingRequest = async () => {
      const parseObjectToQueryString = qs.stringify({
        walletId: treezorWallet.walletId,
      });

      const pendingPayoutResponse = await getPendingPayoutRequestAction(
        parseObjectToQueryString
      );
      const pendingPayinResponse = await getPendingPayinRequestAction(
        parseObjectToQueryString
      );

      setPendingPayout(pendingPayoutResponse?.data);
      setPendingPayin(pendingPayinResponse?.data);

      setLoadingCheckCondition(false);
    };
    getPendingRequest();
  }, [treezorWallet]);

  const isDoneBalanceCondition = Boolean(treezorWallet?.solde === 0);
  const isDonePendingPayoutCondition = Boolean(pendingPayout?.length === 0);
  const isDonePendingPayinCondition = Boolean(pendingPayin?.length === 0);

  // const isCompleteAllCondition = true;
  const isCompleteAllCondition =
    isDoneBalanceCondition &&
    isDonePendingPayoutCondition &&
    isDonePendingPayinCondition;

  const handleChangeStep = (nexStep) => setWalletDeleteStep(Number(nexStep));

  const handleChangeStepAfterCheckCondition = () => {
    if (isCompleteAllCondition && !loadingCheckCondition) {
      return setWalletDeleteStep(5);
    }

    return setWalletDeleteStep(4);
  };

  return (
    <SetupLayout>
      {!loadingCheckCondition ? (
        <>
          {walletDeleteStep === 1 && (
            <WalletDeleteConfirm onChangeStep={handleChangeStep} />
          )}
          {walletDeleteStep === 2 && (
            <WalletDeleteWarning
              onChangeStep={handleChangeStepAfterCheckCondition}
            />
          )}
          {/* {walletDeleteStep === 3 && <WalletDeleteReConfirm />} */}
          {walletDeleteStep === 4 && (
            <WalletDeleteReConfirm2 onChangeStep={handleChangeStep} />
          )}
          {walletDeleteStep === 5 && (
            <WalletDeleteReConfirmCompleteCondition
              onChangeStep={handleChangeStep}
            />
          )}
          {walletDeleteStep === 6 && <WalletDeleteCondition />}
        </>
      ) : (
        <CircularProgress size={22} color="inherit" />
      )}
    </SetupLayout>
  );
};

const SetupLayout = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <Layout
      header={
        <Header
          name={t('close_wallet.close_wallet')}
          goBack={() => history.goBack()}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={<Box>{children}</Box>}
    />
  );
};

SetupLayout.propTypes = {
  children: PropTypes.any,
};

export default WalletDeletePage;
