import auth0 from 'auth0-js';
import jwtDecode from 'jwt-decode';
import { format, formatISO, isBefore } from 'date-fns';

import logger from 'helpers/logger';

import {
  ACCESS_TOKEN,
  ID_TOKEN,
  EXPIRES_AT,
  STATE,
  NONCE,
  TREEZOR_VALUE,
  // COMPANY_ID,
  // LAST_UPDATED,
} from 'config/Constants';

const initializeAuth0 = (url) =>
  new auth0.WebAuth({
    domain: process.env.REACT_APP_AUTH0_DOMAIN,
    clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
    redirectUri: url
      ? `${process.env.REACT_APP_AUTH0_REDIRECT_URI}?redirect=${url}`
      : process.env.REACT_APP_AUTH0_REDIRECT_URI,
    responseType: 'token id_token',
    scope: 'openid',
  });

const storeAuthResult = (authResult) => {
  logger.debug('[service] storeAuthResult()', authResult);
  localStorage.setItem(ACCESS_TOKEN, authResult.accessToken);
  localStorage.setItem(ID_TOKEN, authResult.idToken);
  localStorage.setItem(EXPIRES_AT, authResult.idTokenPayload.exp);
  localStorage.setItem(STATE, authResult.state);
  localStorage.setItem(NONCE, authResult.idTokenPayload.nonce);
};

const login = (url) => {
  logger.info('[service] login()', url);
  const auth = initializeAuth0(url);
  return auth.authorize();
};

const logout = () => {
  logger.info('[service] logout()');
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(EXPIRES_AT);
  localStorage.removeItem(STATE);
  localStorage.removeItem(NONCE);
  localStorage.removeItem(TREEZOR_VALUE);
};

const isAuthenticated = () => {
  logger.debug('[service] isTokenValid()');
  // Check whether the current time is past the
  // id token's expiry time
  const now = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
  const expiresTime = localStorage.getItem(EXPIRES_AT);
  const idToken = localStorage.getItem(ID_TOKEN);

  if (!(expiresTime && idToken)) return false;

  const expiresAt = format(
    new Date(parseInt(expiresTime, 10) * 1000),
    "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"
  );
  logger.debug(
    formatISO(new Date(now)),
    formatISO(new Date(expiresAt)),
    isBefore(new Date(now), new Date(expiresAt))
  );
  const isTokenStillValid = isBefore(new Date(now), new Date(expiresAt));
  logger.debug('isTokenStillValid', isTokenStillValid);
  return isTokenStillValid;
};

const getUserId = () => {
  logger.info('[service] getUserId()');
  if (isAuthenticated()) {
    const token = jwtDecode(localStorage.getItem(ID_TOKEN));
    logger.debug('auth0 userId', token.sub);
    return token.sub || null;
  }
  return null;
};

const handleAuthentication = async () =>
  new Promise((resolve, reject) => {
    logger.info('[service] handleAuthentication()');
    const auth = initializeAuth0();

    auth.parseHash((err, authResult) => {
      if (err) {
        logger.warn('parseHash error', err);
        return reject(err);
      }
      logger.debug('auth success', { authResult });
      if (
        authResult &&
        authResult.accessToken &&
        authResult.idToken &&
        authResult.state &&
        authResult.idTokenPayload
      ) {
        storeAuthResult(authResult);
        return resolve(authResult);
      }
      logger.warn('Missing data in authResult', { authResult });
      return reject(Error('Missing data in authResult'));
    });
  });

const renewToken = async () =>
  new Promise((resolve, reject) => {
    logger.debug('[service] renewToken()');
    const nonce = localStorage.getItem(NONCE);
    // const lastUpdated = moment(localStorage.getItem(LAST_UPDATED));

    // if (lastUpdated.isValid() && !moment().diff(lastUpdated, 'days')) {
    //   logger.debug('pas de renew en fait');
    //   return resolve();
    // }

    const auth = initializeAuth0();
    auth.checkSession({ nonce }, (error, authResult) => {
      if (error || !authResult) {
        logger.warn('auth.checkSession() failed', { error, authResult });
        return reject(error);
      }

      logger.debug('auth.checkSession() success', { authResult });
      // localStorage.setItem(LAST_UPDATED, moment().toISOString());
      storeAuthResult(authResult);
      return resolve(authResult);
    });
  });

export default {
  login,
  logout,
  isAuthenticated,
  getUserId,
  handleAuthentication,
  renewToken,
};
