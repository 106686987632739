import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Field } from 'formik';
import { Checkbox, TextField } from 'formik-material-ui';
import InputAdornment from '@material-ui/core/InputAdornment';

const PlafondPaiement = ({ lastone, checked, name, textFieldName }) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        height: '90px',
        width: '100%',
        border: '1px solid blue',
        borderBottom: lastone ? '1px solid blue' : 'none',
        borderRadius: '2px',
        display: 'flex',
        paddingLeft: '15px',
        alignItems: 'center',
      }}
    >
      <Field component={Checkbox} type="checkbox" name={name} />
      <Typography
        variant="subtitle1"
        color="primary"
        style={{ marginLeft: '15px', width: '40%' }}
      >
        {t(name)}
      </Typography>
      <div style={{ width: '15%' }}></div>
      {checked ? (
        <Field
          component={TextField}
          name={textFieldName}
          label={t(name)}
          variant="outlined"
          color="primary"
          fullWidth
          required
          InputProps={{
            endAdornment: <InputAdornment position="end">€</InputAdornment>,
          }}
        />
      ) : null}
      <div style={{ width: '4%' }}></div>
    </div>
  );
};

PlafondPaiement.propTypes = {
  text: PropTypes.string,
  lastone: PropTypes.bool,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  textFieldName: PropTypes.string,
};

export default PlafondPaiement;
