import React from 'react';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { AppGreenDivider } from '../AppDividers';
import { AppRowCenterCenter } from '../AppContainers';
import AppButton from '../AppButton';
import logo from '../../assets/images/BBF-header-logo-blueberry.svg';
import ProgressGreen from '../../assets/images/progress_green.svg';

const CompanySignUpConfirmation = (props) => {
  const { history, t } = props;

  return (
    <div>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          marginBottom: '25px',
        }}
      >
        <AppRowCenterCenter>
          <img alt="" src={ProgressGreen} />
          <AppGreenDivider />
          <img alt="" src={ProgressGreen} />
          <AppGreenDivider />
          <img alt="" src={ProgressGreen} />
        </AppRowCenterCenter>
      </div>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          marginBottom: '25px',
        }}
      >
        <img src={logo} alt="logo of a company" width="300px" />
      </div>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          marginBottom: '25px',
        }}
      >
        <p>{t('signup.company.welcome')}</p>
        <p>{t('signup.company.thanks_register')}</p>
        <p>{t('signup.company.check_email')}</p>
        <p>{t('thanks')}</p>

        <AppButton
          text={t('OK')}
          onClick={() => {
            history.push('/login');
          }}
          style={{ width: '25%' }}
        />
      </div>
    </div>
  );
};

CompanySignUpConfirmation.propTypes = {
  history: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

const CompanySignUpConfirmationTranslated = withTranslation()(
  CompanySignUpConfirmation
);
const CompanySignUpConfirmationWithRouter = withRouter(
  CompanySignUpConfirmationTranslated
);
export default CompanySignUpConfirmationWithRouter;
