import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AppDialog from '../../../components/AppDialog';
import useImportAction from '../hooks/useImportActions';

const ClientImportDeleteDialog = ({ showDialog, closeDialog }) => {
  const { t } = useTranslation();

  const { handleDeleteRow } = useImportAction();

  if (showDialog) {
    return (
      <AppDialog
        title={t('sure')}
        closeDialog={closeDialog}
        sm
        iconClose
        onConfirmText={t('yes')}
        onCancelText={t('no')}
        footer
        onConfirm={handleDeleteRow()}
        contentText={t('settings_section.clients.delete_client_content')}
        color="secondary"
      />
    );
  }

  return null;
};

ClientImportDeleteDialog.propTypes = {
  showDialog: PropTypes.bool,
  selectedClientId: PropTypes.string,
  closeDialog: PropTypes.func,
};

export default ClientImportDeleteDialog;
