const style = {
  appChecklistWrapper: {
    display: 'flex',
    marginBottom: '10px',
    textAlign: 'center',
  },
  appChecklistText: {
    marginLeft: '9px',
    marginTop: '2px',
  },
};

export default style;
