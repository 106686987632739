import _ from 'lodash';

import {
  FETCH_USERS,
  FETCH_USERS_FAILED,
  LOGOUT_USER_SUCCESS,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  LOGGED_USER_COMPANY_SELECTED,
  UPDATE_USER_BENEFICIARY,
} from '../../actions/Types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_USERS: {
      if (action.payload.companyUsers) {
        return _.mapKeys(action.payload.companyUsers, '_id');
      }
      return state;
    }
    case CREATE_USER: {
      return { ...state, [action.payload._id]: action.payload };
    }
    case UPDATE_USER: {
      return {
        ...state,
        [action.payload._id]: {
          ...state[action.payload._id],
          ...action.payload,
        },
      };
    }
    case UPDATE_USER_BENEFICIARY: {
      return {
        ...state,
        [action.payload.userOId]: {
          ...state[action.payload.userOId],
          beneficiaryId: action.payload.beneficiaryId,
        },
      };
    }
    case DELETE_USER: {
      const { [action.payload]: foo, ...rest } = state;
      return rest;
    }
    case FETCH_USERS_FAILED:
      return {};
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return {};
    default:
      return state;
  }
};
