import _ from 'lodash';

import {
  FETCH_INVOICES,
  FETCH_INVOICES_FAILED,
  FETCH_INVOICE,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  VALIDATE_INVOICE,
  REFUND_INVOICE,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  RESEND_INVOICE,
} from '../../actions/Types';

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_INVOICES:
      return _.mapKeys(action.payload, '_id');
    case FETCH_INVOICE: {
      const invoice = action.payload;
      return { ...state, [invoice._id]: invoice };
    }
    case CREATE_INVOICE: {
      return { ...state, [action.payload._id]: action.payload };
    }
    case VALIDATE_INVOICE: {
      if (action.payload.oldInvoice && action.payload.newInvoice) {
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            ...action.payload.oldInvoice,
          },
          [action.payload.newInvoice._id]: action.payload.newInvoice,
        };
      }
      if (action.payload.newInvoice && !action.payload.oldInvoice) {
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            ...action.payload.newInvoice,
          },
        };
      }

      return state;
    }
    case RESEND_INVOICE:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.response,
        },
      };
    case REFUND_INVOICE: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.invoice,
        },
        [action.payload.creditNote._id]: action.payload.creditNote,
      };
    }
    case UPDATE_INVOICE: {
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.response,
        },
      };
    }
    case FETCH_INVOICES_FAILED:
      return {};
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return {};
    default:
      return state;
  }
};
