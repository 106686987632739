import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

/* import MUI */
import { Modal, Button, Typography, Box, TextField } from '@material-ui/core';

const ConfirmChequeModal = (props) => {
  const { open, onClose } = props;

  const { values, submitForm } = useFormikContext();

  const { t } = useTranslation();

  const onConfirmSubmit = async () => {
    await submitForm();
    onClose();
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={onClose}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            background: 'white',
            width: '30%',
            minHeight: '200px',
            padding: ' 20px',
          }}
        >
          <Typography variant="h4" align="center">
            {t('cheque.popup_confirm.title')}
          </Typography>
          <Typography variant="body1" align="center">
            {t('cheque.popup_confirm.message')}
          </Typography>
          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              margin: '20px ',
            }}
          >
            <TextField
              value={values.issuer}
              label={t('cheque.issuer')}
              disabled
            />
            <TextField
              value={values.issueDate}
              label={t('cheque.issue_date')}
              disabled
            />
            <TextField
              value={values.amount}
              label={`${t('cheque.amount')} *`}
              disabled
            />
            <TextField
              value={values.rmlcKey}
              label={`${t('cheque.rlmc_key')} *`}
              disabled
            />
            <TextField
              value={values.cmcA}
              label={`${t('cheque.cmc_a')} *`}
              disabled
            />
            <TextField
              value={values.cmcB}
              label={`${t('cheque.cmc_b')} *`}
              disabled
            />
            <TextField
              value={values.cmcC}
              label={`${t('cheque.cmc_c')} *`}
              disabled
            />
          </Box>
          <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={onConfirmSubmit}
            >
              {t('cheque.popup_confirm.confirm')}
            </Button>
            <Button variant="outlined" onClick={onClose}>
              {t('cheque.popup_confirm.edit')}
            </Button>
          </Box>
        </div>
      </Modal>
    </div>
  );
};

ConfirmChequeModal.propTypes = {
  values: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ConfirmChequeModal;
