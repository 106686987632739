import ClientServices from '../../services/ClientService';

import {
  FETCHING_CLIENTS,
  FETCH_CLIENTS,
  FETCH_CLIENTS_FAILED,
  UPDATE_CLIENT,
  DELETE_CLIENT,
  UPDATE_CLIENT_BENEFICIARY,
} from '../Types';

import {
  userCanReadClient,
  userCanCreateClient,
  userCanUpdateClient,
  userCanDeleteClient,
} from '../../selectors/rightsSelector/clientRequests';

import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

import { setGlobalError } from '../GlobalError';

export const fetchClients = () => async (dispatch, getState) => {
  try {
    if (!userCanReadClient(getState())) {
      dispatch({
        type: FETCH_CLIENTS,
        payload: [],
      });
      return;
    }
    dispatch({ type: FETCHING_CLIENTS });
    const companyId = selectLoggedUserCompanyId(getState());
    const clients = await ClientServices.fetchClients({
      company_id: companyId,
    });
    dispatch({ type: FETCH_CLIENTS, payload: clients.data });
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: FETCH_CLIENTS_FAILED, payload: error });
  }
};

export const createClient = (client, cb) => async (dispatch, getState) => {
  try {
    if (!userCanCreateClient(getState())) {
      cb();
      return;
    }

    // eslint-disable-next-line no-param-reassign
    client.company_id = selectLoggedUserCompanyId(getState());

    const response = (await ClientServices.createClient(client)).data;
    cb(null, response);
  } catch (error) {
    cb(error);
  }
};

export const updateClient = (id, client, cb) => async (dispatch, getState) => {
  try {
    if (!userCanUpdateClient(getState())) {
      cb();
      return;
    }

    await ClientServices.updateClient(id, client);
    dispatch({ type: UPDATE_CLIENT, payload: { id, client } });
    cb(null, client);
  } catch (error) {
    cb(error);
  }
};

export const deleteClient = (id, cb) => async (dispatch, getState) => {
  try {
    if (!userCanDeleteClient(getState())) {
      cb();
      return;
    }

    await ClientServices.deleteClient(id);
    dispatch({ type: DELETE_CLIENT, payload: id });
    cb(null);
  } catch (error) {
    cb(error);
  }
};

export const updateClientBeneficiary = (data) => (dispatch) => {
  dispatch({ type: UPDATE_CLIENT_BENEFICIARY, payload: data });
};
