import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import styleAsset from '../../assets/jss/components/appSelectItem';

const AppSelectItem = ({
  text,
  value,
  valueSelected,
  classes,
  color,
  toggleandselect,
  style,
}) => {
  const selectItemClasses = classNames({
    [classes.selectItem]: true,
    [classes[color]]: color,
    [classes[`${color}Selected`]]:
      value && valueSelected && value.toString() === valueSelected.toString(),
  });

  return (
    <button
      style={style}
      type="button"
      value={value}
      className={selectItemClasses}
      onClick={(e) => toggleandselect(e)}
    >
      {text}
    </button>
  );
};

AppSelectItem.propTypes = {
  style: PropTypes.object,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]).isRequired,
  toggleandselect: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  valueSelected: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
};

AppSelectItem.defaultProps = {
  noBorder: false,
};

export default withStyles(styleAsset)(AppSelectItem);
