import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Formula from './FormulaPage';

const RenderButtonOptions = ({ handleChoose, options }) => {
  const history = useHistory();
  const location = useLocation();
  React.useEffect(() => {
    const params = new URLSearchParams({ type: 'ec' });
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  }, []);

  return (
    <Grid container xs={12} spacing={2} direction="column">
      <Grid
        xs={12}
        direction="row"
        style={{ marginBottom: '50px', display: 'inline-flex' }}
      >
        <Formula type={options[0]} handleChoose={handleChoose} />
        <Formula type={options[3]} handleChoose={handleChoose} />
        <Formula type={options[1]} handleChoose={handleChoose} />
      </Grid>
      <Grid
        xs={12}
        direction="row"
        style={{ marginBottom: '50px', display: 'inline-flex' }}
      >
        <Formula type={options[4]} handleChoose={handleChoose} />
        <Formula type={options[2]} handleChoose={handleChoose} />
        <Formula type={options[5]} handleChoose={handleChoose} />
      </Grid>
    </Grid>
  );
};

RenderButtonOptions.propTypes = {
  handleChoose: PropTypes.func,
  data: PropTypes.object,
  setData: PropTypes.func,
  options: PropTypes.array,
};

export default RenderButtonOptions;
