import colors from '../../../constants/colors.json';
import '../../css/fonts.css';

export const smDarkGrey = {
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '11px',
  lineHeight: '15px',
};
export const smDarkGreyD = {
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '12px',
  lineHeight: '15px',
};
export const inputPlaceholder = {
  color: colors.placeholderGray,
  fontFamily: 'Kumbh Sans',
  fontSize: '0.8rem',
  lineHeight: '15px',
  letterSpacing: '0.4px',
  fontWeight: '400',
};
export const fillsDarkGrey = {
  color: colors.fillsDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '10px',
  lineHeight: '14px',
  letterSpacing: '0.4px',
};
export const inputPlaceholderActive = {
  color: colors.placeholderGray,
  fontFamily: 'Kumbh Sans',
  fontSize: '11px',
  lineHeight: '15px',
  letterSpacing: '0.4px',
  width: 'auto',
  fontWeight: '400',
};
export const inputText = {
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight: 'normal',
  letterSpacing: 'normal',
};
export const contentText = {
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  margin: 0,
  padding: 0,
  fontSize: '13px',
  lineHeight: '17px',
  fontWeight: 'normal',
  letterSpacing: 'normal',
};
export const capitalContentText = {
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '13px',
  lineHeight: '28px',
  fontWeight: 'bold',
  letterSpacing: '0.2px',
  textTransform: 'uppercase',
};
export const inputTextBold = {
  padding: 0,
  margin: 0,
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '13px',
  lineHeight: '15px',
  fontWeight: 'bold',
  letterSpacing: '0.2px',
};
export const mGreyText = {
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight: 'normal',
  letterSpacing: 'normal',
};
export const sGreyBold = {
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '11px',
  lineHeight: '13px',
  fontWeight: 'bold',
  letterSpacing: '0.17px',
};

export const inputLabel = {
  // color: colors.labelGray,
  fontFamily: 'Kumbh Sans',
  fontSize: '1em',
  letterSpacing: '0.36px',
  lineHeight: '14px',
};

export const mWhiteBold = {
  fontFamily: 'Kumbh Sans',
  color: colors.white,
  fontSize: '13px',
  fontWeight: 'bold',
  letterSpacing: '0.5px',
  lineHeight: '18px',
};
export const mWhite = {
  fontFamily: 'Kumbh Sans',
  color: colors.white,
  fontSize: '10px',
  fontWeight: 'normal',
  letterSpacing: 'normal',
  lineHeight: '11px',
};
export const mBlueBold = {
  fontFamily: 'Kumbh Sans',
  color: colors.blue,
  fontSize: '13px',
  fontWeight: 'bold',
  letterSpacing: '0.5px',
  lineHeight: '18px',
};
export const mBlueBoldN = {
  fontFamily: 'Kumbh Sans',
  color: colors.blue,
  fontSize: '13px',
  fontWeight: 'bold',
  letterSpacing: 'normal',
  lineHeight: '18px',
  // lineHeight: '28px',
  padding: 0,
  margin: 0,
};
export const mBlueN = {
  fontFamily: 'Kumbh Sans',
  color: colors.blue,
  fontSize: '13px',
  fontWeight: 'normal',
  letterSpacing: 'normal',
  lineHeight: '18px',
  // lineHeight: '28px',
  padding: 0,
  margin: 0,
};
export const captionBig = {
  color: colors.textGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '12px',
  lineHeight: '14px',
};
export const greySmall = {
  color: colors.textGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '10px',
  lineHeight: '11px',
};
export const captionBigSelected = {
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '12px',
  lineHeight: '14px',
};
export const captionBigSelectedBold = {
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: 'bold',
};
export const captionBigDisabled = {
  color: colors.fillsDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '12px',
  lineHeight: '14px',
};
export const greyNormal = {
  color: colors.fillsDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '13px',
  lineHeight: '28px',
};
export const titleText = {
  color: colors.textDarkGrey,
  margin: 0,
  fontFamily: 'Kumbh Sans',
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight: 'bold',
};
export const subtitleText = {
  margin: 0,
  fontFamily: 'Kumbh Sans',
  color: colors.textGrey,
  fontSize: '13px',
  lineHeight: '17px',
};
export const dropDownItemSelected = {
  color: colors.blueOpacity,
  fontFamily: 'Kumbh Sans',
  fontSize: '12px',
  lineHeight: '15px',
  fontWeight: 'bold',
};
export const dropDownItemSelectedSecondary = {
  color: colors.green,
  fontFamily: 'Kumbh Sans',
  fontSize: '11px',
  lineHeight: '15px',
  fontWeight: 'bold',
};
export const selectButtonPrimary = {
  color: colors.blueOpacity,
  fontFamily: 'Kumbh Sans',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '0.5px',
};
export const openedSelectButton = {
  color: colors.white,
  fontFamily: 'Kumbh Sans',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '0.5px',
};

export const selectButtonSecondary = {
  color: colors.green,
  fontFamily: 'Kumbh Sans',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '19px',
  letterSpacing: '0.5px',
};

export const header = {
  color: colors.blue,
  fontSize: '27px',
  fontFamily: 'Kumbh Sans',
  lineHeight: '37px',
  letterSpacing: '0.5px',
  textTransform: 'uppercase',
};
export const smallTitleText = {
  color: colors.textDarkGrey,
  fontFamily: 'Kumbh Sans',
  fontSize: '13px',
  lineHeight: '12px',
  fontWeight: 'normal',
  letterSpacing: 'normal',
};
export const titleDangerText = {
  color: colors.red,
  margin: 0,
  fontFamily: 'Kumbh Sans',
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight: 'bold',
};
