import { userHasCompanySettingsAccessFeature } from 'selectors/rightsSelector/companySettingsRequests';
import { userHasExpenseAccessFeature } from 'selectors/rightsSelector/expenseRequests';
import { userHasAdvanceExpenseAccessFeature } from 'selectors/rightsSelector/advanceExpenseRequests';
import { userHasInvoiceAccessFeature } from 'selectors/rightsSelector/invoiceRequests';
import {
  userCanReadCard,
  userCanUpdateCard,
} from 'selectors/rightsSelector/cardRequests';
import {
  companyDataAreMissing,
  userDataAreMissing,
} from 'selectors/missingData';

import { userHasBookKeepingAccessFeature } from 'selectors/rightsSelector/bookKeepingRequests';

export default ({ loggedUser, ...restState }) => ({
  loggedUserCompany: restState.loggedUserCompany,
  companySubscription: restState.companySubscription,
  userHasCompanySettingsAccess: userHasCompanySettingsAccessFeature(restState),
  companyDataAreMissing: companyDataAreMissing(restState).length > 0,
  userDataAreMissing:
    userDataAreMissing({
      loggedUser,
      loggedUserRights: restState.loggedUserRights,
    }).length > 0,
  userHasExpenseAccess:
    userHasExpenseAccessFeature(restState) ||
    userHasAdvanceExpenseAccessFeature(restState),
  userHasInvoiceAccess: userHasInvoiceAccessFeature(restState),
  userHasBookKeepingAccess: userHasBookKeepingAccessFeature(restState),
  userCanReadCard: userCanReadCard(restState),
  userCanUpdateCard: userCanUpdateCard(restState),
});
