import React from 'react';
import PropTypes from 'prop-types';

const FlagIcon = ({ icon, text, onlyflag = false, onlytext = false }) => (
  <>
    {!onlytext && icon()} {!onlyflag && text}
  </>
);

FlagIcon.propTypes = {
  icon: PropTypes.func.isRequired,
  text: PropTypes.string,
  onlyflag: PropTypes.bool,
  onlytext: PropTypes.bool,
};

export default FlagIcon;
