/* eslint-disable */

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

/* import hooks */
import { useTranslation } from 'react-i18next';

/* import MUI */
import { Box, Typography, Button } from '@material-ui/core';
import { FileCopyRounded } from '@material-ui/icons';

/* import component */
import UploadFile from 'components/NewUploadFile/uploadFile';
import { useFormikContext } from 'formik';
import SimpleUploadZone from 'components/SimpleUploadZone';

const CreateDebitFirstStepYesOption = (props) => {
  const { handleContinue, handleCancel } = props;
  const { setFieldValue, errors, touched, values } = useFormikContext();
  const translateDomain = 'debits_page.debits_creation_page';
  const { t } = useTranslation();

  const [selectedFile, setFileName] = useState(undefined);
  console.log(
    '🚀 ~ file: index.js ~ line 24 ~ CreateDebitFirstStepYesOption ~ fileName',
    selectedFile
  );

  console.log(values.file);

  useEffect(() => {
    setFileName(values.file);
  }, []);

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        padding: '20px',
      }}
    >
      <Box>
        <Typography
          variant="h5"
          align="center"
          style={{
            padding: '20px',
          }}
        >
          {t(`${translateDomain}.steps.step1.title`)}
        </Typography>
        <Typography
          align="center"
          style={{
            padding: '5px',
          }}
        >
          {t(`${translateDomain}.steps.step1.message`)}
        </Typography>
        <Typography
          align="center"
          style={{
            padding: '5px',
          }}
        >
          {t(`${translateDomain}.steps.step1.message_2`)}
        </Typography>
      </Box>
      <Box style={{ width: '100%' }}>
        <SimpleUploadZone />
        {errors.file && touched.file ? (
          <div style={{ color: 'red' }}>{errors.file}</div>
        ) : null}
      </Box>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '100%',
          marginTop: '20px',
        }}
      >
        <Button variant="contained" color="default" onClick={handleCancel}>
          {t(`${translateDomain}.buttons.cancel`)}
        </Button>
        <Button variant="contained" color="primary" onClick={handleContinue}>
          {t(`${translateDomain}.buttons.continue`)}
        </Button>
      </Box>
    </Box>
  );
};

CreateDebitFirstStepYesOption.propTypes = {
  handleContinue: PropTypes.func,
  handleCancel: PropTypes.func,
};

export default CreateDebitFirstStepYesOption;
