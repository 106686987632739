import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { ListItem } from '@material-ui/core';
import classNames from 'classnames';
import Fonts from 'config/fontFamily';

const useStyles = makeStyles((theme) => ({
  listItem: {
    display: 'flex',
    padding: `${theme.spacing(1)}px ${theme.spacing(1.5)}px`,
    gap: theme.spacing(3),
    alignItems: 'center',
    height: '57px',
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    width: '100%',
  },
  listItemText: {
    fontSize: '1rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
    fontFamily: Fonts.primary,
  },
  listItemLeft: {
    flex: '1 0 0',
    color: theme.palette.neutral[700],
  },
  listItemRight: {
    color: theme.palette.neutral[900],
  },
}));

const AppDashboardListItem = (props) => {
  const { onClick, titleLeft, titleRight } = props;
  const classes = useStyles(props);
  return (
    <ListItem button className={classes.listItem} onClick={onClick}>
      <span className={classNames(classes.listItemText, classes.listItemLeft)}>
        {titleLeft}
      </span>
      <span className={classNames(classes.listItemText, classes.listItemRight)}>
        {titleRight}
      </span>
    </ListItem>
  );
};

AppDashboardListItem.propTypes = {
  onClick: PropTypes.func,
  titleLeft: PropTypes.string,
  titleRight: PropTypes.string,
};

export default AppDashboardListItem;
