import spinnerService from 'services/SpinnerService';
import { isEquivalent } from '../../../helpers/utils';

export default (values, { setErrors, setStatus, setSubmitting, props }) => {
  const submitData = { ...values };

  console.log({
    values,
  });
  if (!submitData.clientHasDifferentBillingAddress) {
    submitData.adresses = [];
  }
  const fetchData = () => {
    spinnerService.startSpinner();
    props.fetchClients().finally(() => spinnerService.endSpinner());
  };
  const cb = (err, client) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      setStatus({ success: true });

      if (props.onCreated) {
        props.onCreated(client);
      }
      props.closeDialog();
      fetchData();
    }
  };

  if (props.clientImport) {
    props.updateClientImport(submitData, props.id);
    setSubmitting(false);
    props.closeDialog();
  } else if (!submitData._id) {
    props.createClient(
      { clients: [submitData], company_id: submitData.company_id },
      cb
    );
  } else if (
    submitData._id &&
    isEquivalent(submitData, props.clients.data[submitData._id])
  ) {
    if (props.onCreated) {
      fetchData();
      props.onCreated({
        clients: [submitData],
        company_id: submitData.company_id,
      });
    }
    props.closeDialog();
  } else {
    props.updateClient(submitData._id, submitData, cb);
  }
};
