/* eslint-disable no-unused-vars */
import PropTypes from 'prop-types';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppDialog from 'components/AppDialog';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AppButton from 'components/AppButton';
import { useSelector } from 'react-redux';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptions';

const useStyles = makeStyles({
  dialogContentText: {
    textAlign: 'justify',
  },
  dialogActions: {
    justifyContent: 'space-around',
  },
  textStyle: {
    marginBottom: '10px',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '1rem',
  },
});

const UpgradeSubscriptionDialog = ({ openDialog, closeDialog }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const classes = useStyles();

  const upgradeTrialContents = ['0', '1', '2'];

  const handleConfirmUpgrade = () => {
    closeDialog(false);
    history.push('/stripe-pricing');
  };

  const companySubscriptionType = useSelector(
    (state) => state?.companySubscription?.data?.companySubscriptionType
  );

  const renderContent = () => {
    if (
      companySubscriptionType &&
      companySubscriptionType === SUBSCRIPTION_TYPE.FREE_TIER
    ) {
      return upgradeTrialContents.map((item) => (
        <Typography
          variant="subtitle1"
          key={item}
          className={classes.textStyle}
        >
          {t(`subscription.upgrade_trial_subscription_content.${item}`)}
        </Typography>
      ));
    }
    return (
      <Typography variant="subtitle1" className={classes.textStyle}>
        {t('subscription.upgrade_subscription_content')}
      </Typography>
    );
  };

  return (
    <Dialog
      open={openDialog}
      aria-labelledby="upgrade-dialog-title"
      aria-describedby="upgrade-dialog-description"
      maxWidth="sm"
    >
      <DialogContent>
        <DialogContentText
          className={classes.dialogContentText}
          id="upgrade-dialog-description"
        >
          {renderContent()}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <AppButton
          color="transparentGrey"
          text={t('cancel')}
          onClick={closeDialog}
          noBorder
        />
        <AppButton
          onClick={handleConfirmUpgrade}
          color="primary"
          autoFocus
          variant="contained"
          text={t('confirm_label')}
        />
      </DialogActions>
    </Dialog>
  );
};

UpgradeSubscriptionDialog.propTypes = {
  openDialog: PropTypes.bool.isRequired,
  closeDialog: PropTypes.func.isRequired,
};
export default UpgradeSubscriptionDialog;
