/* eslint-disable consistent-return */
import * as Yup from 'yup';

const REQUIRED = 'Ce champ est obligatoire';
/**
 * Explanation:
 * =============
 *   We use validationShema as a function that return an object, because the pin field is required.
 *   However, for a virtual card, there is no pin field, and when we click on the submit button,
 *   formik doesn't submit the form, since pin is required.
 *
 *   So we have 2 validation schema. One used when pin field exist (virual==false), and a second Validation
 *   Schema, used for virtual cards (no pin and virtual == true).
 */
const validationSchema = (virtual, isUpdateConfig) => {
  // if (virtual || isUpdateConfig) {
  //   return Yup.object().shape({
  //     userId: Yup.object().required(REQUIRED),
  //   });
  // }
  // return Yup.object().shape({
  //   pin: Yup.string().min(4, 'pin must be complete').required(REQUIRED),
  //   // userId: Yup.object().required(REQUIRED).nullable(),
  // });

  if (!isUpdateConfig) {
    if (!virtual) {
      return Yup.object().shape({
        pin: Yup.string().min(4, 'pin must be complete').required(REQUIRED),
      });
    }
  }
};

/* eslint-disable import/prefer-default-export */
export default validationSchema;
