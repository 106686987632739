export const Dashboard = {
  ExpenseOverview: 'ExpenseOverview',
  InvoiceOverview: 'InvoiceOverview',
  BankOverview: 'BankOverview',
  ActivityAnalysis: 'ActivityAnalysis',
  Balancesheet: 'Balancesheet',
  Result: 'Result',
  Top10ProductsServices: 'Top10ProductsServices',
  Top10Customers: 'Top10Customers',
  ActivityFollowUp: 'ActivityFollowUp',
  ProjectsTracking: 'ProjectsTracking',
  Overdue: 'Overdue',
  Unpaid: 'Unpaid',
  FutureReceipts: 'FutureReceipts',
  ComingPayments: 'ComingPayments',
  OverduePayments: 'OverduePayments',
  FiveBiggestPayments: 'FiveBiggestPayments',
  Top10LateCustomers: 'Top10LateCustomers',
  FiveBiggestLatePayments: 'FiveBiggestLatePayments',
  Top10MostIndebtedClients: 'Top10MostIndebtedClients',
  Expenses: 'Expenses',
  AnalyticalSortOfDebts: 'AnalyticalSortOfDebts',
  MyExpenses: 'MyExpenses',
  Top10Debts: 'Top10Debts',
  Top10PaidProviders: 'Top10PaidProviders',
  LateInDisbursement: 'LateInDisbursement',
  TotalToBeDisbursed: 'TotalToBeDisbursed',
  UpcomingDisbursements: 'UpcomingDisbursements',
  DisbursementDelays: 'DisbursementDelays',
  FiveBiggestPaymentDelays: 'FiveBiggestPaymentDelays',
  FiveLargestUpcomingPayouts: 'FiveLargestUpcomingPayouts',
};

export const Expense = {
  ExpenseCreate: 'ExpenseCreate',
  ExpenseRead: 'ExpenseRead',
  ExpenseUpdate: 'ExpenseUpdate',
  ExpenseDelete: 'ExpenseDelete',
  ExpenseValidate: 'ExpenseValidate',
};

export const Purchase = {
  PurchaseCreate: 'PurchaseCreate',
  PurchaseRead: 'PurchaseRead',
  PurchaseUpdate: 'PurchaseUpdate',
  PurchaseDelete: 'PurchaseDelete',
  PurchaseValidate: 'PurchaseValidate',
};

export const AdvanceExpenses = {
  AdvanceExpensesCreate: 'AdvanceExpensesCreate',
  AdvanceExpensesRead: 'AdvanceExpensesRead',
  AdvanceExpensesUpdate: 'AdvanceExpensesUpdate',
  AdvanceExpensesDelete: 'AdvanceExpensesDelete',
};

export const Invoice = {
  InvoiceCreate: 'InvoiceCreate',
  InvoiceRead: 'InvoiceRead',
  InvoiceUpdate: 'InvoiceUpdate',
  InvoiceCancel: 'InvoiceCancel',
  InvoiceValidate: 'InvoiceValidate',
};

export const Template = {
  TemplateCreate: 'TemplateCreate',
  TemplateRead: 'TemplateRead',
  TemplateUpdate: 'TemplateUpdate',
  TemplateDelete: 'TemplateDelete',
};

export const Items = {
  ItemsCreate: 'ItemsCreate',
  ItemsRead: 'ItemsRead',
  ItemsUpdate: 'ItemsUpdate',
  ItemsDelete: 'ItemsDelete',
};

export const Providers = {
  ProvidersCreate: 'ProvidersCreate',
  ProvidersRead: 'ProvidersRead',
  ProvidersUpdate: 'ProvidersUpdate',
  ProvidersDelete: 'ProvidersDelete',
};

export const Clients = {
  ClientsCreate: 'ClientsCreate',
  ClientsRead: 'ClientsRead',
  ClientsUpdate: 'ClientsUpdate',
  ClientsDelete: 'ClientsDelete',
};

export const Projects = {
  ProjectsCreate: 'ProjectsCreate',
  ProjectsRead: 'ProjectsRead',
  ProjectsUpdate: 'ProjectsUpdate',
  ProjectsDelete: 'ProjectsDelete',
};

// TO MERGE----------------

export const CompanySettings = {
  CompanySettingsUpdate: 'CompanySettingsUpdate',
};

export const UserManagement = {
  UserManagementUserCreate: 'UserManagementUserCreate',
  UserManagementUpdate: 'UserManagementUserUpdate',
  UserManagementDelete: 'UserManagementUserDelete',
};

// ROLE ?
export const UserRoleManagement = {
  UserRoleManagementCreate: 'UserRoleManagementCreate',
  UserRoleManagementUpdate: 'UserRoleManagementUpdate',
  UserRoleManagementDelete: 'UserRoleManagementDelete',
  UserRoleManagementRead: 'UserRoleManagementRead',
};

export const EmailTemplate = {
  EmailTemplateUpdate: 'EmailTemplateUpdate',
};

// ------------------

export const BookKeeping = {
  BookKeepingRead: 'BookKeepingRead',
  BookKeepingExport: 'BookKeepingExport',
};

export const Inventory = {
  InventoryRead: 'InventoryRead',
  InventoryCreate: 'InventoryCreate',
  InventoryUpdate: 'InventoryUpdate',
  InventoryDelete: 'InventoryDelete',
};

export const Bank = {
  BankRead: 'BankRead',
  BankCreate: 'BankCreate',
  BankUpdate: 'BankUpdate',
  BankDelete: 'BankDelete',
};

export const Card = {
  CardRead: 'CardRead',
  CardCreate: 'CardCreate',
  CardUpdate: 'CardUpdate',
  CardDelete: 'CardDelete',
};

// --------SUBSCRIPTION----------
export const Subscription = {
  SubscriptionRead: 'SubscriptionRead',
  SubscriptionUpdate: 'SubscriptionUpdate',
};
