import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import { pdfjs } from 'react-pdf';

import state from './state';
import dispatch from './dispatch';

import useStyles from './styles';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

class Pdf extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      error: null,
      pdf: null,
      numPages: null,
      pageNumber: 1,
    };

    this._onInvoicePdfFetched = this._onInvoicePdfFetched.bind(this);
  }

  changeState(loading, error) {
    this.setState({ loading, error });
  }

  componentDidMount() {
    const { invoices, clientProjects, fetchInvoicePDF, invoiceId } = this.props;
    const id = this.props?.match?.params?.id || invoiceId;
    if (!id) {
      this.changeState(false, this.props.t('invoices.pdf.errorGeneratePdf'));
    } else if (invoices[id]) {
      const invoiceTemplate = {
        ...invoices[id],
        projectName: this.findClientProject(invoices[id], clientProjects),
      };
      fetchInvoicePDF(invoiceTemplate, this._onInvoicePdfFetched);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  findClientProject(invoice, clientProjects) {
    if (!clientProjects) return '';
    if (invoice !== undefined && invoice.client) {
      const projectName = clientProjects[invoice.client._id]?.find(
        (project) => project._id === invoice.project
      )?.name;
      return projectName;
    }
    return '';
  }

  _onInvoicePdfFetched(err, pdf) {
    if (err) {
      this.changeState(false, err.data ? err.data.message : err.message);
      return;
    }
    const fileBlob = new Blob([pdf], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(fileBlob);
    this.setState({ pdf: fileURL });
    this.changeState(false, null);
  }

  render() {
    const { loading, error, pdf } = this.state;
    const { classes } = this.props;

    return (
      <div className={classes.pdfViewContaier}>
        {loading && (
          <div className={classes.pdfLoading}>
            {this.props.t('pdf.loading')}...
          </div>
        )}
        <iframe className={classes.pdfPreview} src={pdf} />
        {error && <div className={classes.pdfError}>{error}</div>}
      </div>
    );
  }
}

Pdf.propTypes = {
  t: PropTypes.func.isRequired,
  match: PropTypes.object,
  classes: PropTypes.object,
  invoices: PropTypes.object,
  clientProjects: PropTypes.object,
  fetchInvoicePDF: PropTypes.func,
  toggleDrawerVisibility: PropTypes.func,
  fetchInvoice: PropTypes.func,
  invoiceId: PropTypes.string,
};

const PdfWithStyle = withStyles(useStyles)(Pdf);
const pdfTranslated = withTranslation()(PdfWithStyle);

export default connect(state, dispatch)(pdfTranslated);
