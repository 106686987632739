const SUBSCRIPTION_CATEGORY_ID = '6487313c723ae12e86eca367';

export const KM_CATEGORY_ID = '5d1cc145529fc3c8af0b9fa0';

export const SUB_CATEGORIES_PREFIX = 'expenses.sub_categories.';
export const CATEGORIES_PREFIX = 'expenses.categories.';

export default SUBSCRIPTION_CATEGORY_ID;

export const EXPENSE_STATE = {
  PAID: 'paid',
  UPCOMING: 'upcoming',
  OVERDUE: 'overdue',
  DRAFT: 'draft',
  CANCELED: 'canceled',
  PAYOUT_PENDING: 'payoutpending',
  VALIDATED: 'validated',
  PENDING: 'pending',
};

export const EXPENSE_TYPE = {
  PURCHASE: 'purchase',
  EXPENSE: 'expense',
};
