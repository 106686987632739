import React from 'react';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import { format } from 'date-fns';
import { AppTableRow, AppTableCell } from '../../AppTableComponents';

import { getCategoryById } from '../../../helpers/CategoryHelpers';
import NumberFormat from '../../../helpers/NumberFormat';
import { getPaymentById } from '../../../helpers/PaymentTypeHelpers';
import { getUserById } from '../../../helpers/UsersHelpers';

const SimpleExpense = ({ expense }) => {
  // console.log('SimpleExpense', expense);
  if (!expense || !expense.amounts) return <></>;
  return (
    <>
      {map(expense.amounts, (line) => (
        <AppTableRow key={line._id}>
          <AppTableCell>
            {format(new Date(expense.date), 'dd/MM/yyyy')}
          </AppTableCell>
          <AppTableCell>{expense.expense_nb}</AppTableCell>
          <AppTableCell>
            {getUserById(expense.user_id)
              ? getUserById(expense.user_id).name
              : '-'}
          </AppTableCell>
          <AppTableCell>
            {getCategoryById(expense.category_id)
              ? getCategoryById(expense.category_id).display
              : '-'}
          </AppTableCell>
          <AppTableCell alignRight>{NumberFormat.n(line.amount)}€</AppTableCell>
          <AppTableCell alignRight>{line.vat_rate}%</AppTableCell>
          <AppTableCell alignRight>{NumberFormat.n(line.vat)}€</AppTableCell>
          <AppTableCell alignRight>{NumberFormat.n(line.total)}€</AppTableCell>
          <AppTableCell>
            {getPaymentById(expense.payment_type)
              ? getPaymentById(expense.payment_type).display
              : '-'}
          </AppTableCell>
        </AppTableRow>
      ))}
    </>
  );
};

SimpleExpense.propTypes = {
  expense: PropTypes.object.isRequired,
};

export default SimpleExpense;
