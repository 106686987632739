/* Packages */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  Snackbar,
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import qs from 'qs';

/* Icons */
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import CloseIcon from '@material-ui/icons/Close';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {
  closeTreezorWalletRequestAction,
  getPendingPayinRequestAction,
  getPendingPayoutRequestAction,
} from 'actions/WalletActions';

/* Components */
import WalletDeleteConditionIcon from 'containers/BankBBF/components/WalletDeleteConditionIcon';
import BankBBFAccountInformation from 'containers/BankBBF/components/BankBBFAccountInformation';
import WalletDeleteConfirmDialog from 'containers/BankBBF/components/WalletDeleteConfirmDialog';

/* Styles */
import useStyles from './styles';

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const WalletDeleteCondition = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const treezorWallet = useSelector((state) =>
    state.wallet && state.wallet.wallet ? state.wallet.wallet[0] : null
  );

  const [loadingCheckCondition, setLoadingCheckCondition] = useState(false);
  const [loadingConfirmDialog, setLoadingConfirmDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [pendingPayout, setPendingPayout] = useState([]);
  const [pendingPayin, setPendingPayin] = useState([]);
  const [snackbarControl, setSnackbarControl] = useState({
    open: false,
    status: 'success',
    message: '',
  });

  useEffect(() => {
    if (!treezorWallet) return;
    setLoadingCheckCondition(true);

    const getPendingRequest = async () => {
      const parseObjectToQueryString = qs.stringify({
        walletId: treezorWallet.walletId,
      });

      const pendingPayoutResponse = await getPendingPayoutRequestAction(
        parseObjectToQueryString
      );
      const pendingPayinResponse = await getPendingPayinRequestAction(
        parseObjectToQueryString
      );

      setPendingPayout(pendingPayoutResponse?.data);
      setPendingPayin(pendingPayinResponse?.data);

      setLoadingCheckCondition(false);
    };
    getPendingRequest();
  }, [treezorWallet]);

  const handleClickOpenConfirmDialog = () => setOpenConfirmDialog(true);
  const handleCloseConfirmDialog = () => setOpenConfirmDialog(false);
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbarControl({ ...snackbarControl, open: false });
  };

  const handleCloseTreezorWallet = async () => {
    setLoadingConfirmDialog(true);
    try {
      const closeResponse = await closeTreezorWalletRequestAction(
        treezorWallet?.walletId
      );

      if (closeResponse.message === 'Wallet canceled') {
        setSnackbarControl({
          open: true,
          status: 'success',
          message: 'Close account successful',
        });
      }

      setLoadingConfirmDialog(false);
      setOpenConfirmDialog(false);

      setSnackbarControl({
        open: true,
        status: 'error',
        message: 'Something when wrong!',
      });
    } catch (error) {
      setLoadingConfirmDialog(false);
      setOpenConfirmDialog(false);

      setSnackbarControl({
        open: true,
        status: 'error',
        message: 'Something when wrong!',
      });
    }
  };

  const getTreezorWalletSolde = () => {
    if (treezorWallet?.solde === null) return 0;
    return treezorWallet?.solde;
  };

  const isDoneBalanceCondition = Boolean(getTreezorWalletSolde() === 0);
  const isDonePendingPayoutCondition = Boolean(pendingPayout?.length === 0);
  const isDonePendingPayinCondition = Boolean(pendingPayin?.length === 0);

  const isCompleteAllCondition =
    isDoneBalanceCondition &&
    isDonePendingPayoutCondition &&
    isDonePendingPayinCondition;

  const tooltipMessage = {
    balance: !isDoneBalanceCondition
      ? `Your current balance is ${getTreezorWalletSolde()} ${
          treezorWallet?.currency
        } / 0 ${treezorWallet?.currency}  to delete`
      : 'Success',
    payin: !isDonePendingPayinCondition
      ? `You sill have ${pendingPayin?.length} payin / 0 payin to delete`
      : 'Success',
    payout: !isDonePendingPayoutCondition
      ? `You still have ${pendingPayout?.length} payout / 0 payout to delete`
      : 'Success',
  };

  return (
    <>
      {treezorWallet && (
        <Box>
          <Box className={classes.cardWrapper} elevation={3}>
            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box>
                <Box className={classes.cardTitleWrapper}>
                  <AccountCircleIcon
                    color="primary"
                    className={classes.cardTitleIcon}
                  />
                  <Typography
                    variant="h3"
                    color="primary"
                    className={classes.cardTitle}
                  >
                    {t('bank_bbf.your_account_information')}
                  </Typography>
                </Box>
                <BankBBFAccountInformation
                  hide={false}
                  treezorWalletId={treezorWallet.walletId}
                />
              </Box>
              <Box>
                <Box className={classes.cardTitleWrapper}>
                  <AccountBalanceWalletIcon
                    color="primary"
                    className={classes.cardTitleIcon}
                  />
                  <Typography
                    variant="h3"
                    color="primary"
                    className={classes.cardTitle}
                  >
                    {t('bank_bbf.balance')}
                  </Typography>
                </Box>
                <Typography
                  variant="h3"
                  color="primary"
                  style={{ fontWeight: 'bold' }}
                >
                  {`${getTreezorWalletSolde()} ${treezorWallet?.currency}`}
                </Typography>
              </Box>
            </Box>
          </Box>

          <hr style={{ margin: '15px 0' }} />

          <Box className={classes.cardWrapper} elevation={3}>
            <Box className={classes.cardTitleWrapper}>
              <WorkOffIcon color="primary" className={classes.cardTitleIcon} />
              <Typography
                variant="h3"
                color="primary"
                className={classes.cardTitle}
              >
                {t('close_wallet.condition')}
              </Typography>
            </Box>

            <Box className={classes.cardConditionWrapper}>
              {!loadingCheckCondition ? (
                <>
                  <WalletDeleteConditionIcon
                    status={isDoneBalanceCondition}
                    title={t('close_wallet.condition_balance')}
                    tooltipTitle={tooltipMessage.balance}
                  />
                  <WalletDeleteConditionIcon
                    status={isDonePendingPayoutCondition}
                    title={t('close_wallet.condition_payout')}
                    tooltipTitle={tooltipMessage.payout}
                  />
                  <WalletDeleteConditionIcon
                    status={isDonePendingPayinCondition}
                    title={t('close_wallet.condition_payin')}
                    tooltipTitle={tooltipMessage.payin}
                  />
                </>
              ) : (
                <CircularProgress size={22} color="inherit" />
              )}
            </Box>

            {!loadingCheckCondition && (
              <Button
                color="primary"
                variant="contained"
                size="large"
                startIcon={<CloseIcon />}
                onClick={handleClickOpenConfirmDialog}
                disabled={!isCompleteAllCondition}
              >
                {t('close_wallet.close_wallet')}
              </Button>
            )}
          </Box>

          <Snackbar
            open={snackbarControl.open}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Alert
              onClose={handleCloseSnackbar}
              severity={snackbarControl.status}
            >
              {snackbarControl.message}
            </Alert>
          </Snackbar>

          <WalletDeleteConfirmDialog
            open={openConfirmDialog}
            onClose={handleCloseConfirmDialog}
            handleCloseTreezorWallet={handleCloseTreezorWallet}
            loadingConfirmDialog={loadingConfirmDialog}
          />
        </Box>
      )}
    </>
  );
};

export default WalletDeleteCondition;
