import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  DISCOVERY_PACKAGE,
  STANDARD_FORMULA,
  EFFICIENCY_FORMULA,
  MONTHLY,
  YEARLY,
} from 'constants/subscriptions';
import {
  discovery,
  standard,
  efficiency,
  DISCOVERY,
  STANDARD,
  EFFICIENCY,
  INTERVAL_NONE,
  MAPPING_CANCEL_SUBSCRIPTION_TYPE,
} from '../utils';

const styles = {
  textContent: {
    fontSize: '20px',
    width: '100%',
    marginBottom: '20px',
  },
};

const CancelConfirmContent = () => {
  const companySubscription = useSelector(
    (state) => state.companySubscription.data || {}
  );
  const { t } = useTranslation();
  let content = {};
  const handleContent = (type, data) => {
    if ([MONTHLY, YEARLY].includes(companySubscription.interval)) {
      const prefix =
        companySubscription.interval === MONTHLY ? `MONTHLY` : `YEARLY`;
      const cancelSubscriptionType =
        MAPPING_CANCEL_SUBSCRIPTION_TYPE[
          `${prefix}_${companySubscription.cancelSubscriptionContentType}`
        ];
      return {
        type,
        interval: cancelSubscriptionType,
        content: data[cancelSubscriptionType],
      };
    }

    return {
      type,
      interval: INTERVAL_NONE,
      content: data[INTERVAL_NONE],
    };
  };
  switch (companySubscription.name) {
    case DISCOVERY_PACKAGE:
      content = handleContent(DISCOVERY, discovery);
      break;
    case STANDARD_FORMULA:
      content = handleContent(STANDARD, standard);
      break;
    case EFFICIENCY_FORMULA:
      content = handleContent(EFFICIENCY, efficiency);
      break;
    default:
      content = [];
  }
  return (
    <>
      {content?.content?.map((item) => {
        return (
          <div key={item} style={styles.textContent}>
            {t(
              `subscription.refund_contents.${content.type}.${content.interval}.${item}`
            )}
          </div>
        );
      })}
    </>
  );
};

export default CancelConfirmContent;
