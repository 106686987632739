import React from 'react';
import PropTypes from 'prop-types';

import { Snackbar } from '@material-ui/core';
import SnackbarContentWrapper from './Wrapper';

const BaseSnackBar = (props) => {
  const {
    onClose,
    autoHideDuration,
    variant,
    message,
    vertical,
    horizontal,
    open,
  } = props;

  function handleClose() {
    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  }

  return (
    <Snackbar
      anchorOrigin={{
        vertical,
        horizontal,
      }}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
    >
      <SnackbarContentWrapper
        onClose={onClose}
        variant={variant}
        message={message}
        showCloseButton={!!(onClose && typeof onClose === 'function')}
      />
    </Snackbar>
  );
};

BaseSnackBar.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  autoHideDuration: PropTypes.number,
  variant: PropTypes.oneOf(['error', 'info', 'success', 'warning', ''])
    .isRequired,
  vertical: PropTypes.oneOf(['top', 'bottom']),
  horizontal: PropTypes.oneOf(['center', 'right', 'left']),
};

BaseSnackBar.defaultProps = {
  autoHideDuration: null,
  vertical: 'top',
  horizontal: 'center',
  open: false,
  variant: '',
};

export default BaseSnackBar;
