import React from 'react';
import PropTypes from 'prop-types';
import useStyles from './styles';

const ViewCustomerPdf = ({ error, pdf }) => {
  const classes = useStyles();
  return (
    <div className={classes.pdfViewContaier}>
      <iframe className={classes.pdfPreview} src={pdf} />
      {error && <div className={classes.pdfError}>{error}</div>}
    </div>
  );
};

ViewCustomerPdf.propTypes = {
  pdf: PropTypes.string,
  error: PropTypes.string,
};

export default ViewCustomerPdf;
