import companyService from 'services/CompanyService';
// import { COMPANY_ID } from '../../config/Constants';

import { setGlobalError } from '../GlobalError';

import {
  LOGGED_USER_COMPANY_LOADED,
  LOGGED_USER_COMPANY_SELECTED,
  SELECT_COMPANY,
  UPDATE_BANK_DETAILS_COMPANY,
} from '../Types';

export const fetchLoggedUserCompanies = (cb) => async (dispatch) => {
  try {
    const { data } = await companyService.fetchUserCompanies();
    dispatch({ type: LOGGED_USER_COMPANY_LOADED, payload: data });

    if (cb) {
      cb(null);
    }
  } catch (error) {
    dispatch(setGlobalError(error));
    cb(error);
  }
};

export const selectedCompany = (index, cb) => (dispatch, getState) => {
  const {
    loggedUserCompany: { companies },
  } = getState();

  // localStorage.setItem(COMPANY_ID, companies[index]._id);
  // dispatch({ type: LOGGED_USER_COMPANY_SELECTED, payload: companies[index] });

  dispatch({
    type: LOGGED_USER_COMPANY_SELECTED,
    payload: { selectedCompany: companies[index] },
  });
  if (cb && typeof cb === 'function') {
    cb();
  }
};

export const updateBankDetailsCompany = (index, cb) => (dispatch, getState) => {
  const {
    loggedUserCompany: { companies },
  } = getState();

  dispatch({
    type: UPDATE_BANK_DETAILS_COMPANY,
    payload: { bank_details: companies[index].bank_details },
  });
  if (cb && typeof cb === 'function') {
    cb();
  }
};

export const selectedCompanyId = (companyId, cb) => (dispatch) => {
  dispatch({
    type: SELECT_COMPANY,
    payload: companyId,
  });
  if (cb && typeof cb === 'function') {
    cb();
  }
};
