import React from 'react';
import PropTypes from 'prop-types';

import FormActions from './FormActions';
import OtherActions from './OtherActions';

const SideBar = ({
  category,
  newProvider,
  toggleNewProviderDialog,
  ...restProps
}) => (
  <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
    {category && <FormActions horizontal {...restProps} />}
    <OtherActions
      readOnly={restProps.readOnly}
      t={restProps.t}
      showClientFormDialog={restProps.showClientFormDialog}
      showProjectFormDialog={restProps.showProjectFormDialog}
      onSave={restProps.onSave}
      newProvider={newProvider}
      toggleNewProviderDialog={toggleNewProviderDialog}
    />
  </div>
);
SideBar.propTypes = {
  category: PropTypes.string,
  t: PropTypes.func,
  showClientFormDialog: PropTypes.func,
  showProjectFormDialog: PropTypes.func,
  readOnly: PropTypes.bool,
  newProvider: PropTypes.object,
  toggleNewProviderDialog: PropTypes.func,
};

export default SideBar;
