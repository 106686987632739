import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appTableComponents';

const AppTableCell = ({
  classes,
  alignCenter,
  alignRight,
  alignLeft,
  alignRightContainer,
  header,
  text,
  setWidthManualy,
  children,
  greyHeader,
  useStyle,
  colSpan,
}) => {
  const tableCellClasses = classNames({
    [classes.tableCell]: true,
    [classes.headerCell]: header,
    [classes.greyHeader]: greyHeader,
    [classes.alignRight]: alignRight,
    [classes.alignLeft]: alignLeft,
    [classes.alignRightContainer]: alignRightContainer,
    [classes.alignCenter]: alignCenter,
  });
  const textClasses = classNames({
    [classes.headerInnerDiv]: true,
    [classes.alignRight]: alignRight,
    [classes.alignLeft]: alignLeft,
    [classes.alignCenter]: alignCenter,
  });

  const setWidth = setWidthManualy ? { width: setWidthManualy } : null;

  return header ? (
    <th className={tableCellClasses} style={{ ...useStyle, ...setWidth }}>
      {children ? <div className={textClasses}>{children}</div> : <p>{text}</p>}
    </th>
  ) : (
    <td
      className={tableCellClasses}
      style={{ ...useStyle, ...setWidth }}
      colSpan={colSpan}
    >
      {children || <p>{text}</p>}
    </td>
  );
};
AppTableCell.propTypes = {
  classes: PropTypes.object.isRequired,
  useStyle: PropTypes.object,
  children: PropTypes.any,
  setWidthManualy: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  text: PropTypes.string,
  header: PropTypes.bool,
  greyHeader: PropTypes.bool,
  alignCenter: PropTypes.bool,
  alignRight: PropTypes.bool,
  alignLeft: PropTypes.bool,
  alignRightContainer: PropTypes.bool,
  colSpan: PropTypes.number,
};
AppTableCell.defaultProps = {
  alignRight: false,
  alignLeft: false,
  alignCenter: false,
  greyHeader: false,
  header: true,
  children: null,
  useStyle: null,
  setWidthManualy: null,
};

export default withStyles(style)(AppTableCell);
