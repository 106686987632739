import {
  FETCHING_CARD_TRANSACTIONS,
  FETCH_CARD_TRANSACTIONS_SUCCESS,
  FETCH_CARD_TRANSACTIONS_FAILED,
} from '../../actions/Types';

const INITIAL_STATE = {
  loading: false,
  success: false,
  error: null,
  timestamp: null,
  cardTransactions: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_CARD_TRANSACTIONS:
      return {
        ...state,
        success: false,
        loading: true,
      };
    case FETCH_CARD_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        success: true,
        loading: false,
        cardTransactions: [...action.payload],
      };
    case FETCH_CARD_TRANSACTIONS_FAILED: {
      const { error } = action.payload;

      return {
        ...state,
        success: false,
        loading: false,
        error,
      };
    }
    default:
      return state;
  }
};
