import { v4 as uuidv4 } from 'uuid';
import { ADD_OWNER, REMOVE_OWNER } from '../constants/actions';

const findDeep = (arr, func, nestAttribute) => {
  for (let i = 0; i < arr.length; i += 1) {
    if (func(arr[i])) return arr[i];
    if (arr[i][nestAttribute]) {
      const deep = findDeep(arr[i][nestAttribute], func, nestAttribute);
      if (deep) return deep;
    }
  }
  return 0;
};

const reducer = (state, action) => {
  switch (action.type) {
    case ADD_OWNER: {
      if (!action.payload.parentId)
        return [...state, { id: uuidv4(), ...action.payload }];

      const parent = findDeep(
        state,
        (owner) => owner.id === action.payload.parentId,
        'subOwners'
      );
      if (parent) {
        if (parent.subOwners)
          parent.subOwners.push({ id: uuidv4(), ...action.payload });
        else parent.subOwners = [{ id: uuidv4(), ...action.payload }];
      }
      return [...state];
    }
    case REMOVE_OWNER: {
      if (action.payload.parentId) {
        const parent = findDeep(
          state,
          (owner) => owner.id === action.payload.parentId,
          'subOwners'
        );
        if (parent)
          parent.subOwners = parent.subOwners.filter(
            (owner) => owner.id !== action.payload.id
          );
        return [...state];
      }
      return state.filter((owner) => owner.id !== action.payload.id);
    }
    default:
      return state;
  }
};

export default reducer;
