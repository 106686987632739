/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { Field, useFormikContext } from 'formik';
import { useSelector } from 'react-redux';
import { roundingNumber } from 'utils';
import { makeStyles } from '@material-ui/core/styles';

import { Warning } from '@material-ui/icons';

import { deepCopy, typeHelpers } from 'helpers/invoiceHelpers';
import { addDays, addMonths, format } from 'date-fns';
import { InputAdornment, TextField } from '@material-ui/core';
import { useDecimalCharacter } from 'hooks/decimalCharacterHooks';
import CurrencyHelper from 'helpers/currencyHelper';
import InputHelper from 'helpers/inputHelper';
import DatePicker from '../../../../../../../components/FormElementsDesigned/DatePicker';
import CustomGenericDialog from '../../../../../../../components/CustomGenericDialog';
import AppButton from '../../../../../../../components/AppButton';
import AppRadioGroup from '../../../../../../../components/AppRadioGroup';
import AppRadioButton from '../../../../../../../components/AppRadioButton';
import TextFieldMUI from '../../../../../../../components/FormElements/textField';
import { calculateDueDate } from '../../../../../../../helpers/DueDateHelper';
import NumberFormat from '../../../../../../../helpers/NumberFormat';

import colors from '../../../../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  rowCenterSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  mBlueText: {
    flex: 1,
    color: colors.light_blue,
    fontWeight: 'normal',
    fontSize: '15px',
  },
  mRedInput: {
    border: '1px solid #FF2B44',
    color: colors.black,
    lineHeight: '13px',
    fontSize: '11px',
    height: '23px',
    width: '132px',
    textAlign: 'right',
    padding: '1px 10px',
    borderRadius: '3px',
    margin: '1px 15px 0px 0px',
  },
  mGreyInput: {
    border: '1px solid #7F7F7F',
    color: colors.black,
    lineHeight: '13px',
    fontSize: '11px',
    height: '23px',
    width: '132px',
    textAlign: 'right',
    padding: '1px 10px',
    margin: '1px 15px 0px 0px',
    borderRadius: '3px',
  },
  mRedText: {
    color: colors.red,
    lineHeight: '13px',
    fontSize: '11px',
  },
  mBoldBlueText: {
    color: colors.light_blue,
    fontWeight: 'bold',
    fontSize: '15px',
  },
  mGreyText: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '13px',
  },
  input: {
    flex: 1,
  },
}));

const defaultSeletedType = '30';

const PaymentsDialog = ({
  grossTotal,
  paymentsConfirm,
  paymentsCancel,
  methods,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const {
    values: { type: invoiceType, payments, duePayableOption },
    setFieldValue,
  } = useFormikContext();
  const {
    setNewDueDate,
    setDueDateChosen,
    numberOfPayments,
    setNumberOfPayments,
    newPayments,
    // prePaymentsList,
  } = methods;
  const [typeSelected, setTypeSelected] = useState(
    duePayableOption || defaultSeletedType
  );
  const [numberOfMultiPayment, setNumberOfMultiPayment] =
    useState(numberOfPayments);
  const [listPayment, setListPayment] = useState(newPayments);
  const [dueDate, setDueDate] = useState(payments[0].date);
  const [amountError, setAmountError] = useState(false);
  const { decimalChar, exceptDecimalChar } = useDecimalCharacter();
  const language = useSelector((state) => state.guiLanguage);

  function onTypeSelected(e) {
    const { value } = e.target;
    setTypeSelected(value);
    setDueDate(calculateDueDate(value));
    setFieldValue('duePayableOption', value);
  }

  const changeDueDates = (len) => {
    const changedPayments = [];
    const scheduledDate = dueDate ? dueDate.toString() : new Date().toString();

    const optionNumber =
      // eslint-disable-next-line no-nested-ternary
      typeSelected === 'custom'
        ? 0
        : typeSelected?.includes('+')
        ? Number(typeSelected.slice(0, -1))
        : typeSelected || defaultSeletedType;

    for (let i = 0; i < len; i += 1) {
      const addDueDate =
        Number(optionNumber) === 0
          ? format(
              new Date(addMonths(new Date(scheduledDate), i)),
              'yyyy-MM-dd'
            )
          : format(
              new Date(addDays(new Date(scheduledDate), i * optionNumber)),
              'yyyy-MM-dd'
            );
      const amount =
        i === len - 1
          ? roundingNumber(
              grossTotal - roundingNumber(grossTotal / len, 2) * (len - 1),
              2
            )
          : roundingNumber(grossTotal / len, 2);
      changedPayments.push({
        date: addDueDate,
        amount,
      });
    }
    return changedPayments;
  };

  const changeJustDueDates = () => {
    const changedPayments = [...listPayment];

    const scheduledDate = dueDate ? dueDate.toString() : new Date().toString();

    const optionNumber =
      // eslint-disable-next-line no-nested-ternary
      typeSelected === 'custom'
        ? 0
        : typeSelected?.includes('+')
        ? Number(typeSelected.slice(0, -1))
        : typeSelected || defaultSeletedType;

    changedPayments.forEach((payment, i) => {
      const addDueDate =
        Number(optionNumber) === 0
          ? format(
              new Date(addMonths(new Date(scheduledDate), i)),
              'yyyy-MM-dd'
            )
          : format(
              new Date(addDays(new Date(scheduledDate), i * optionNumber)),
              'yyyy-MM-dd'
            );

      payment.date = addDueDate;
      payment.amount = NumberFormat.getNumber(
        payment.amount.toString(),
        decimalChar,
        ' '
      )?.toFixed(2);
    });

    return changedPayments;
  };

  function changeValueByAttr(index, attr, value) {
    const npc = deepCopy(listPayment);
    npc[index][attr] = value;
    setListPayment(deepCopy(npc));
  }

  const checkTotalAmount = () => {
    const newTotalAmount = listPayment.reduce(
      (acc, { amount }) =>
        acc + NumberFormat.getNumber(amount.toString(), decimalChar, ' '),
      0
    );

    setAmountError(
      roundingNumber(newTotalAmount) !== roundingNumber(parseFloat(grossTotal))
    );
  };

  function schedulePayments(e) {
    const { value } = e.target;
    setNumberOfMultiPayment(value);
  }

  function changeAmountManualy(index) {
    return (e) => {
      const val = e.target.value;

      const formatValue = InputHelper.getNumberFormatInput(
        val,
        decimalChar,
        exceptDecimalChar,
        language
      );

      if (formatValue === null) return;

      changeValueByAttr(index, 'amount', formatValue);
    };
  }

  function changeDateManualy(index) {
    return (date) => {
      changeValueByAttr(index, 'date', date);
    };
  }

  function onSubmit() {
    if (!amountError) {
      setDueDateChosen(typeSelected);
      setNewDueDate(dueDate);
      setNumberOfPayments(numberOfMultiPayment);
      paymentsConfirm(listPayment);
    }
  }

  useEffect(() => {
    setListPayment(changeDueDates(numberOfMultiPayment));
  }, [numberOfMultiPayment]);

  useEffect(() => {
    setListPayment(changeJustDueDates());
  }, [typeSelected, dueDate]);

  useEffect(() => {
    checkTotalAmount();
  }, [listPayment, grossTotal]);

  return (
    <CustomGenericDialog maxWidth={'xs'} showButtons={false}>
      <p className={classes.mBoldBlueText}>{t('invoices.set_payments')}</p>

      <div className={classes.rowSpaceBetween}>
        <AppRadioGroup
          name="duePayableOption"
          valueSelected={typeSelected}
          onChange={onTypeSelected}
          color="secondary"
        >
          <AppRadioButton value="0" text={t('invoices.create_date')} />
          <AppRadioButton value="30" text={t('invoices.in_30')} />
          <AppRadioButton value="45" text={t('invoices.in_45')} />
          <AppRadioButton value="60" text={t('invoices.in_60')} />
        </AppRadioGroup>
        <AppRadioGroup
          color="secondary"
          name="duePayableOption"
          valueSelected={typeSelected}
          onChange={onTypeSelected}
        >
          <AppRadioButton value="custom" text={t('invoices.custom')} />
          <AppRadioButton value="30+" text={t('invoices.in_30_end_month')} />
          <AppRadioButton value="45+" text={t('invoices.in_45_end_month')} />
          <AppRadioButton value="60+" text={t('invoices.in_60_end_month')} />
        </AppRadioGroup>
      </div>
      {!typeHelpers.isCreditNote(invoiceType) && (
        <div className={classes.rowCenterSpaceBetween}>
          <p className={classes.mBlueText}>
            {t(`invoices.set_multi_payments`)}{' '}
          </p>
          <Field
            type="number"
            component={TextFieldMUI}
            className={classes.input}
            name="payments"
            value={numberOfMultiPayment}
            onChange={schedulePayments}
          />
        </div>
      )}
      {listPayment.map((payment, index) => (
        <div className={classes.rowCenterSpaceBetween} key={index}>
          {!typeHelpers.isCreditNote(invoiceType) && (
            <>
              <p style={{ width: '40px' }} className={classes.mGreyText}>
                {index + 1}
              </p>
              <TextField
                type="text"
                size="small"
                style={{ marginTop: 8, marginRight: 4 }}
                variant="outlined"
                value={CurrencyHelper.handleDisplayCurrency(
                  payment.amount,
                  decimalChar
                )}
                onChange={changeAmountManualy(index)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">€</InputAdornment>
                  ),
                }}
              />
            </>
          )}
          <Field
            type="date"
            name="due_date"
            prePaymentType="pre_payment"
            selectedIndex={index}
            prePaymentsList={listPayment.map((item) => ({
              ...item,
              due_date: item.date,
            }))}
            component={DatePicker}
            // keyboard={true}
            setDueDate={payment.date}
            onChange={changeDateManualy(index)}
            fullWidth={false}
            disabled={index === 0 && typeSelected !== 'custom'}
          />
        </div>
      ))}
      {amountError && (
        <div className={classes.rowCenterSpaceBetween}>
          <Warning style={{ marginRight: '10px', color: 'red' }} />
          <p className={classes.mRedText}>
            {`${t('invoices.total_error')}:  ${NumberFormat.n(grossTotal)} €`}
          </p>
        </div>
      )}
      <div
        style={{ margin: '30px 0 0 0 ' }}
        className={classes.rowCenterSpaceBetween}
      >
        <AppButton
          color="primaryLight"
          isDisabled={false}
          text={t('cancel')}
          onClick={paymentsCancel}
        />
        <AppButton isDisabled={amountError} text="OK" onClick={onSubmit} />
      </div>
    </CustomGenericDialog>
  );
};

PaymentsDialog.propTypes = {
  t: PropTypes.func.isRequired,
  grossTotal: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  paymentsCancel: PropTypes.func.isRequired,
  paymentsConfirm: PropTypes.func.isRequired,
  methods: PropTypes.object.isRequired,
};

export default PaymentsDialog;
