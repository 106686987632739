import React from 'react';
import { Box } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: ({ padding }) => ({
    backgroundColor: theme.palette.background.default,
    padding: padding || theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    justifyContent: 'flex-start',
    flex: 1,
    borderRadius: '8px',
    boxShadow: theme.palette.boxShadow.bg3,
    width: '100%',
    height: '100%',
  }),
  boxTitle: ({ titlePosition = 'left' }) => ({
    textAlign: titlePosition,
    width: '100%',
  }),
  title: ({ titleColor }) => ({
    color: titleColor || theme.palette.neutral[900],
    fontSize: '1.5rem',
    lineHeight: '1.5rem',
    fontWeight: 600,
  }),
  header: {
    paddingBottom: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    width: '100%',
  },
}));

const AppDashboardCard = (props) => {
  const classes = useStyles(props);
  const { children, title, headerAction } = props;

  return (
    <Box className={classes.root}>
      {title && (
        <Box className={classes.header}>
          <Box className={classes.boxTitle}>
            <span className={classes.title}>{title}</span>
          </Box>
          {headerAction}
        </Box>
      )}
      {children}
    </Box>
  );
};

AppDashboardCard.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  titlePosition: PropTypes.oneOf('center', 'right', 'left'),
  titleColor: PropTypes.string,
  padding: PropTypes.string,
  headerAction: PropTypes.node,
};
export default AppDashboardCard;
