import * as Yup from 'yup';

const validationSchema = Yup.object({
  file: Yup.mixed().required('File field must be full fill!'),
  provider: Yup.string().required('Provider field must be full fill!'),
  category: Yup.string().required('Category is required!'),
  urm: Yup.string().required('URM is required!'),
  ics: Yup.string().required('ICS is required!'),
  paymentType: Yup.string().required('Payment type is required!'),
  otp: Yup.string().required('OTP is required!'),
  amount: Yup.string().required('Amount is required!'),
});

export default validationSchema;
