import { LANGUAGE_CHANGED } from '../Types';
import i18n from '../../i18n';

/* eslint import/prefer-default-export: off */
export const changeLanguage = (lng) => (dispatch, getState) => {
  // eslint-disable-next-line no-console
  console.log('changeLanguage', lng);
  if (!lng) return;
  const stateLng = getState().guiLanguage;
  if (stateLng !== lng) {
    i18n.changeLanguage(lng);
    dispatch({
      type: LANGUAGE_CHANGED,
      payload: {
        language: lng,
      },
    });
  }
};
