import { inputPlaceholder, inputText } from './appText';
import colors from '../../../constants/colors.json';

const style = {
  inputSearchContainer: {
    ...inputText,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flexStart',
    padding: '14px 0 10px 0',
    borderBottom: '2px solid #f2f2f2',
    borderTopRightRadius: '4px',
    borderTopLeftRadius: '4px',

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.background,
    },
    '&:active': {
      backgroundColor: colors.background,
    },
    '&:focus-within': {
      backgroundColor: colors.background,
      borderBottom: '2px solid #3d319d',
    },
    '&:disabled': {
      backgroundColor: colors.fillsGrey,
    },
  },

  secondary: {
    '&:focus-within': {
      backgroundColor: colors.background,
      borderBottom: '2px solid #02CBCD',
    },
  },
  secondaryGrey: {
    '&:focus-within': {
      backgroundColor: colors.background,
      borderBottom: '2px solid #02CBCD',
    },
  },

  primaryWithValue: {
    borderBottom: '2px solid #3d319d',
  },
  fullWidth: {
    width: '100%',
  },
  inputSearch: {
    ...inputText,
    textAlign: 'left',
    border: 'none',
    paddingLeft: '5px',
    backgroundColor: 'transparent',
    '&::placeholder': {
      ...inputPlaceholder,
    },
    '&:hover': {
      cursor: 'auto',
      backgroundColor: colors.background,
    },
    '&:active': {
      backgroundColor: colors.background,
    },
    '&:focus': {
      backgroundColor: colors.background,
    },
    '&:disabled': {
      //   backgroundColor: colors.fillsGrey,
    },
  },

  iconStyle: {
    ...inputPlaceholder,
    fontSize: '20px',
    paddingLeft: '5px',
  },

  secondaryWithValue: {
    borderBottom: '2px solid #02CBCD',
  },
  secondaryGreyWithValue: {
    borderBottom: '2px solid #02CBCD',
  },

  disabled: {
    backgroundColor: colors.background,
    '&:hover': {
      cursor: 'auto',
      backgroundColor: colors.background,
    },
    '&:active': {
      backgroundColor: colors.background,
      borderBottom: '2px solid #B2B2B2',
    },
    '&:focus': {
      backgroundColor: colors.background,
    },
    '&:disabled': {
      backgroundColor: colors.fillsGrey,
    },
  },
};

export default style;
