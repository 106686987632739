import React from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { TreezorDocumentTypes } from '@bebusinessfocus/compta-hub-core';
import { combineIdentityOptions } from 'helpers/TreezorHelper';
import TREEZOR_TYPE from 'assets/data/TREEZOR_DOCUMENTS_TYPE';
import TreezorDocsNeedForm from 'components/CardComponents/Treezor/TreezorDocsNeed/treezorDocsNeedForm';

const TreezorDocsNeedFormik = ({
  document,
  treezorUser,
  company,
  initialValues,
  onSubmit,
  validationSchema,
}) => {
  const getTitle = () => {
    let title = '';
    if (document) {
      TREEZOR_TYPE.forEach((t) => {
        if (t.type === document.type) title = t.key;
      });
    }
    return title;
  };

  const getOptionList = () => {
    switch (document.type) {
      case 2:
      case 6:
      case 7:
      case 8:
      case 11:
      case 18:
      case 20:
      case 21:
      case 22:
      case 23:
      case 24:
      case 25:
      case 26:
      case 27:
        return TreezorDocumentTypes.treezorDocumentTypes;
      case 19:
        return TreezorDocumentTypes.companyRegistrationSecondary;
      case 4:
        return TreezorDocumentTypes.independantRegistration;
      case 9:
      case 15:
      case 16:
      case 17:
        return combineIdentityOptions();
      case 12:
      case 13:
      case 14:
        return TreezorDocumentTypes.proofOfAddress;
      default:
        return TreezorDocumentTypes.soleTraderRegistration;
    }
  };

  return (
    <Formik
      initialValues={initialValues(
        treezorUser.treezorUserID,
        document,
        company._id
      )}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <TreezorDocsNeedForm
        treezorUser={treezorUser}
        document={document}
        title={getTitle()}
        optionList={getOptionList()}
        company={company}
      />
    </Formik>
  );
};

TreezorDocsNeedFormik.propTypes = {
  document: PropTypes.array,
  company: PropTypes.object,
  treezorUser: PropTypes.object,
  initialValues: PropTypes.func,
  onSubmit: PropTypes.func,
  validationSchema: PropTypes.func,
};

export default TreezorDocsNeedFormik;
