/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useFormikContext } from 'formik';
import {
  ChevronLeft,
  ChevronRight,
  KeyboardArrowDown,
  MoreVert,
} from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import MenuConversation from './MenuConversation';

const useStyles = makeStyles((theme) => ({
  historyContainer: ({ containerWidth }) => ({
    width: containerWidth,
    overflow: 'hidden',
    transition: 'transform 0.6s ease-in-out, width 0.6s ease-in-out',
    position: 'relative',
    paddingBottom: '20px',
    marginTop: '100px',
    display: 'flex',
    flexDirection: 'column',
    gap: '48px',
    alignItems: 'center',
    alignSelf: 'stretch',
  }),
  historyOpen: {
    transform: 'translateX(0)',
  },
  historyClosed: ({ containerWidth }) => ({
    transform: `translateX(${containerWidth}px)`,
  }),
  header: ({ containerWidth }) => ({
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    padding: '8px 12px',
    backgroundColor: theme.palette.primary[50],
    gap: '20px',
    position: 'absolute',
    width: containerWidth,
    right: 8,
    transition: 'width 0.6s ease-in-out',
  }),
  headerCollapse: {
    width: '160px !important',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
  },
  collapseIcon: {
    width: '32px',
    height: '32px',
    backgroundColor: '#fff',
  },
  conversationItem: {
    width: '100%',
    padding: '12px 20px',
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    gap: '8px',
  },
  listConversation: {
    width: '100%',
    overflowY: 'auto',
  },
  conversationItem__title: {
    flex: '1 0 0',
    color: theme.palette.neutral[900],
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  conversationItemSelected: {
    backgroundColor: theme.palette.primary[100],
  },
  menuButton: {
    backgroundColor: 'white',
    width: '18px',
    height: '18px',
  },
  btnShowMore: {
    padding: '10px 16px',
    textTransform: 'capitalize',
    color: theme.palette.neutral[600],
    fontSize: '0.875rem',
    lineHeight: '150%',
    fontWeight: 500,
  },
  inputRename: {
    width: '100%',
    padding: '10px',
    fontSize: '1rem',
    backgroundColor: 'white',
    borderRadius: '4px',
    border: 'none',
    outline: 'none',
  },
}));

const ConversationHistory = (props) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [conversationName, setConversationName] = useState('');
  const {
    isHistoryOpen,
    toggleHistory,
    setShowDeleteDialog,
    histories,
    onRenameConversation,
  } = props;
  const classes = useStyles(props);
  const { t } = useTranslation();
  const theme = useTheme();
  const history = useHistory();
  const { id } = useParams();
  const navigate = (path) => {
    history.push(path);
  };

  const { setFieldValue, values } = useFormikContext();
  const inputRef = useRef(null);

  const onClickItem = (index) => {
    const conversation = histories.find((item) => item.id === index);
    if (conversation) {
      setFieldValue('selectedConversation', conversation);
    }

    navigate(`/agent/chat/${index}`);
  };

  const handleClickMenu = (event) => {
    setMenuAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchorEl(null);
  };

  useEffect(() => {
    if (inputRef.current && values?.selectedConversation) {
      setConversationName(values?.selectedConversation?.name);
      inputRef.current.focus();
    }
  }, [inputRef.current]);

  return (
    <>
      <Box
        className={`${classes.header} ${
          !isHistoryOpen && classes.headerCollapse
        }`}
      >
        <IconButton
          color="primary"
          onClick={toggleHistory}
          className={classes.collapseIcon}
        >
          {isHistoryOpen ? <ChevronRight /> : <ChevronLeft />}
        </IconButton>
        <Typography color="primary" variant="h5" style={{ fontWeight: 600 }}>
          {t('agent.history')}
        </Typography>
      </Box>
      <Box
        className={`${classes.historyContainer} ${
          isHistoryOpen ? classes.historyOpen : classes.historyClosed
        }`}
      >
        <Box className={classes.content}>
          <List className={classes.listConversation}>
            {histories?.map((item, index) => (
              <ListItem
                key={item.id}
                className={classNames(classes.conversationItem, {
                  [classes.conversationItemSelected]: item.id === id,
                })}
                button
                onClick={() => onClickItem(item.id)}
              >
                {values?.rename &&
                values?.selectedConversation?.id === item.id ? (
                  <input
                    value={conversationName}
                    className={classes.inputRename}
                    ref={inputRef}
                    onKeyDown={(e) => {
                      if (e.key === 'Escape') {
                        inputRef.current.blur();
                      } else if (e.key === 'Enter') {
                        onRenameConversation(item.id, { name: e.target.value });
                      }
                    }}
                    onBlur={() => {
                      setFieldValue('rename', false);
                    }}
                    onChange={(e) => setConversationName(e.target.value)}
                  />
                ) : (
                  <Typography
                    className={classes.conversationItem__title}
                    variant="body1"
                  >
                    {item.name}
                  </Typography>
                )}

                {item.id === id && (
                  <IconButton
                    className={classes.menuButton}
                    onClick={handleClickMenu}
                  >
                    <MoreVert color="primary" />
                  </IconButton>
                )}
              </ListItem>
            ))}
          </List>
        </Box>
        <MenuConversation
          menuAnchorEl={menuAnchorEl}
          handleCloseMenu={handleCloseMenu}
          setShowDeleteDialog={setShowDeleteDialog}
        />
      </Box>
    </>
  );
};

ConversationHistory.propTypes = {
  isHistoryOpen: PropTypes.bool,
  toggleHistory: PropTypes.func,
  containerWidth: PropTypes.number,
  setShowDeleteDialog: PropTypes.func,
  histories: PropTypes.array,
  onRenameConversation: PropTypes.func,
};

export default ConversationHistory;
