/* eslint-disable consistent-return */
import { setGlobalError } from 'actions/GlobalError';
import {
  FETCHING_CARD_TRANSACTIONS,
  FETCH_CARD_TRANSACTIONS_SUCCESS,
  FETCH_CARD_TRANSACTIONS_FAILED,
} from '../Types';

import service from '../../services/TreezorService';

export const fetchingCardTransactions = () => ({
  type: FETCHING_CARD_TRANSACTIONS,
});

export const fetchCardTransactionsFailed = (error) => ({
  type: FETCH_CARD_TRANSACTIONS_FAILED,
  payload: { error },
});

export const fetchCardTransactionsSuccess = (transactions) => ({
  type: FETCH_CARD_TRANSACTIONS_SUCCESS,
  payload: [...transactions],
});

export const fetchCardTransactions = (cardId) => async (dispatch) => {
  try {
    dispatch(fetchingCardTransactions());
    const response = (await service.fetchCardTransactions(cardId)).data;
    dispatch(fetchCardTransactionsSuccess(response));
  } catch (err) {
    dispatch(setGlobalError(err));
    dispatch(fetchCardTransactionsFailed(err));
  }
};
