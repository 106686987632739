export const companyTaxRegistrationDataAreMissing = (missingFields) => {
  const taxRegistrationGroup = ['settings.tax_registration'];
  return {
    checked: missingFields.some((k) => taxRegistrationGroup.includes(k)),
    percentage: 20,
  };
};

export const companyEndFiscalYearDataAreMissing = (missingFields) => {
  const taxRegistrationGroup = ['endFiscalYear'];
  return {
    checked: missingFields.some((k) => taxRegistrationGroup.includes(k)),
    percentage: 20,
  };
};

export const companyIdentityDataAreMissing = (missingFields) => {
  const identityGroup = ['siren', 'capital', 'legal_form'];
  return {
    checked: missingFields.some((k) => identityGroup.includes(k)),
    percentage: 20,
  };
};

export const companyAddressDataAreMissing = (missingFields) => {
  const addressGroup = ['address', 'city', 'zipcode'];

  return {
    checked: missingFields.some((k) => addressGroup.includes(k)),
    percentage: 20,
  };
};

export const companyKBISDataIsMissing = (missingFields) => {
  const addressGroup = ['kbiz'];

  return {
    checked: missingFields.some((k) => addressGroup.includes(k)),
    percentage: 20,
  };
};
export const companySIRENDataIsMissing = (missingFields) => {
  const sirenDocField = ['sirenDoc'];

  return {
    checked: missingFields.some((k) => sirenDocField.includes(k)),
    percentage: 20,
  };
};

// export const companyLogoDataIsMissing = (missingFields) => {
//   const addressGroup = ['filename'];

//   return {
//     checked: missingFields.some((k) => addressGroup.includes(k)),
//     percentage: 10,
//   };
// };

export const userAvatarIsMissing = (missingFields) => ({
  checked: missingFields.some((k) => k === 'avatar'),
  percentage: 10,
});

export const userPassportIsMissing = (missingFields) => ({
  checked: missingFields.some((k) => k === 'passport'),
  percentage: 10,
});

export const userEmailIsMissing = (missingFields) => ({
  checked: missingFields.some((k) => k === 'email'),
  percentage: 10,
});

export const userPhoneNumberIsMissing = (missingFields) => ({
  checked: missingFields.some((k) => k === 'phonenumber'),
  percentage: 10,
});
