import colors from '../../../constants/colors.json';

// todo : remove this and replace by the muitheme error color
const style = {
  root: {
    display: 'flex',
    fontWeight: 400,
    fontFamily: 'Kumbh Sans',
    color: colors.red,
    width: 'auto',
    padding: '0 3px',
  },
};

export default style;
