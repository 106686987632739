import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AppInput from 'components/AppInput';
import questions from '../questions.json';
import RadioButton from './RadioButton';
import ConfirmDialog from './ConfirmDialog';

const useStyles = makeStyles(() => ({
  inputContainer: {
    width: '200px',
  },
  questionsContainer: {
    width: '100%',
    padding: '10px',
  },
  question: {
    textAlign: 'left',
    margin: 'auto 0',
    fontSize: '20px',
    marginBottom: '10px',
    padding: '0 50px',
  },
  questionsContent: {
    justifyContent: 'right',
    display: 'inline-flex',
    fontSize: '20px',
  },
  submitButton: {
    textAlign: 'right',
  },
  input: {
    minWidth: '300px',
    paddingRight: '50px',
  },
  textContent: {
    fontSize: '20px',
    width: '100%',
    marginBottom: '20px',
  },
}));

const RenderOptions = ({ options, handleCheck, handleOnchange, values }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [checked, setChecked] = React.useState(null);

  const handleOnClick = (questionKey, value, idx) => {
    setChecked(idx);
    handleCheck(questionKey, value);
  };

  return (
    <>
      {options.question_type === 'radio' && (
        <>
          {options.answers.map((item, idx) => {
            return (
              <RadioButton
                key={idx}
                text={t(item.value)}
                onClick={() => handleOnClick(options.key, item.value, idx)}
                checked={idx === checked}
                textStyle={{ cursor: 'pointer' }}
              />
            );
          })}
        </>
      )}
      {options.question_type === 'text' && (
        <div className={classes.input}>
          <AppInput
            placeholder={t('settings_section.subscription.input_placeholder')}
            type="text"
            onChange={(e) => handleOnchange(e, options.key)}
            value={values[options.key] || ''}
          />
        </div>
      )}
    </>
  );
};

const CancelSubscriptionForm = ({
  data,
  setData,
  isShowConfirmDialog,
  handleConfirmSubmit,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const companySubscription = useSelector(
    (state) => state.companySubscription.data || {}
  );

  const handleCheck = (questionKey, value) => {
    setData({ ...data, [questionKey]: value });
  };

  const handleOnchange = (e, questionKey) => {
    setData({ ...data, [questionKey]: e.target.value });
  };

  const checkDisplayField = (questionIndex) => {
    const currQuestionType =
      questions.cancel_questions[questionIndex]?.question_type;
    const prevQuestionValue =
      data[questions.cancel_questions[questionIndex - 1]?.key];
    if (currQuestionType === 'text' && prevQuestionValue !== 'Oui') {
      return false;
    }
    return true;
  };

  const confirmMessage = () => {
    if (companySubscription.paymentLinkUsed === 0) {
      return t('subscription.instantly_deactivated');
    }
    return t('subscription.deactivated_at_the_end_current_month');
  };

  return (
    <Grid container xs={12}>
      {isShowConfirmDialog && (
        <ConfirmDialog
          title={t('sure')}
          closeDialog={() => {
            handleConfirmSubmit(false);
          }}
          onConfirm={() => {
            handleConfirmSubmit(true);
          }}
          contentText={confirmMessage()}
        />
      )}

      <Grid xs={12}>
        <div className={classes.textContent}>
          {t('settings_section.subscription.cancel_question_text_1')}
        </div>
        <div className={classes.textContent}>
          {t('settings_section.subscription.cancel_question_text_2')}
        </div>
      </Grid>
      <Grid xs={12}>
        {questions.cancel_questions.map((question, index) => {
          return (
            <>
              {checkDisplayField(index) && (
                <div key={index} className={classes.questionsContainer}>
                  <Grid xs={12} style={{ display: 'flex' }}>
                    <Grid xs={7} className={classes.question}>
                      <span>{t(question.question)}</span>
                    </Grid>
                    <Grid xs={5} className={classes.questionsContent}>
                      <div style={{ display: 'flex' }}>
                        <RenderOptions
                          options={question}
                          index={index}
                          handleCheck={handleCheck}
                          handleOnchange={handleOnchange}
                          values={data}
                        />
                      </div>
                    </Grid>
                  </Grid>
                </div>
              )}
            </>
          );
        })}
      </Grid>
    </Grid>
  );
};

CancelSubscriptionForm.propTypes = {
  data: PropTypes.object,
  setData: PropTypes.func,
  isShowConfirmDialog: PropTypes.bool,
  handleConfirmSubmit: PropTypes.func,
};
RenderOptions.propTypes = {
  options: PropTypes.object,
  index: PropTypes.number,
  handleCheck: PropTypes.func,
  inputValue: PropTypes.number,
  handleOnchange: PropTypes.func,
  values: PropTypes.object,
  questionIndex: PropTypes.number,
};

export default CancelSubscriptionForm;
