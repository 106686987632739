import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import MUIDataTable from 'mui-datatables';

import DragNDropZone from 'components/DragNDropZone';
import ValidationWarning from 'components/ImportValidationWarning';
import UploadInstructions from 'components/UploadInstructions';
import { SnackBarView } from 'components/SnackBars';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import { debounceFn } from 'helpers/utils';
import SelectedRowsMessage from 'components/SelectedRowsMessage';
import AppButton from '../../../components/AppButton';
import { IconEdit, IconDelete } from '../../../components/AppIcons';
import { getMuiDatatableOptions } from '../../../helpers/tableHelpers';
import RenderFormDialog from '../components/ItemFormDialog';
import { tableFrame } from '../../../helpers/mui-datatableHelper';
import { AppRowCenterCenter } from '../../../components/AppContainers';
import NumberFormat from '../../../helpers/NumberFormat';

const ItemImportBody = ({
  onFileChange,
  Items,
  handleDeleteRow,
  handleUpdateClient,
  checkValidateData,
  handleSave,
  snackBarMessage,
  isOpenSnackBar,
  handlOnCloseSnackBar,
  autoHideDuration,
  snackBarType,
  showSpinner,
}) => {
  const { t } = useTranslation();
  const [showDialog, setShowDialog] = useState(false);
  const [selected, setSelected] = useState(null);
  const { company } = useSelector((state) => state.loggedUserCompany);
  const isApplyVAT = company.settings.tax_registration.tva;
  const columnsHeader = [
    { label: t('item.type'), name: 'type' },
    {
      label: t('item.name'),
      name: 'name',
    },
    {
      label: t('item.description'),
      name: 'description',
    },
    {
      label: t('item.net'),
      name: 'net_price',
      options: {
        customBodyRender: (value) => {
          return Number(value) ? `${NumberFormat.n(value)} €` : '';
        },
      },
    },
    {
      label: t('item.vat'),
      name: 'vat_rate',
      options: {
        customBodyRender: (value) => {
          return value === 0 || value ? `${value} %` : '';
        },
      },
    },
  ];
  const headerStyles = {
    paddingLeft: '16px',
    textAlign: 'left',
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    fontWeight: '500',
  };
  const columns = tableFrame(columnsHeader, headerStyles);
  columns.push({
    label: ' ',
    name: 'index',
    options: {
      // eslint-disable-next-line react/display-name
      customBodyRender: (value) => (
        <AppRowCenterCenter>
          <IconDelete
            sm
            onClick={() => {
              handleDeleteRow(value);
            }}
          />

          <IconEdit
            sm
            onClick={() => {
              setShowDialog(true);
              setSelected(value);
            }}
          />
        </AppRowCenterCenter>
      ),
    },
  });

  const options = {
    selectableRows: true,
    onRowSelectionChange: (val) => {
      if (!Items.ItemList) {
        return;
      }
      if (!val || !val.length) {
        Items.ItemList.forEach((elem) => {
          // eslint-disable-next-line no-param-reassign
          elem.setSelected(false);
        });
        // return;
      } else if (val.length > 1 && Items.ItemList.length === val.length) {
        Items.ItemList.forEach((elem) => {
          // eslint-disable-next-line no-param-reassign
          elem.setSelected(true);
        });
        // return;
      } else if (val.length === 1) {
        const [{ index }] = val;
        Items.ItemList[index].toggleSelected();
      }
    },
    setRowProps: (_, index) => {
      if (!Items || !Items.ItemList[index]) {
        return '';
      }
      const isValidate = Items.ItemList[index].isValidated;
      return {
        style: isValidate
          ? {}
          : {
              border: '4px solid',
              borderColor: 'red',
            },
      };
    },
    isRowSelectable: (dataIndex) => {
      if (!Items && !Items.ItemList[dataIndex]) {
        return false;
      }
      return Items.ItemList[dataIndex].isValidated;
    },
  };
  return !isEmpty(Items.ItemList) ? (
    <Fragment>
      <RenderFormDialog
        showDialog={showDialog}
        selected={selected}
        importItem={Items.ItemList[selected]}
        handleUpdateClient={handleUpdateClient}
        checkValidateData={checkValidateData}
        onClose={() => setShowDialog(false)}
        t={t}
      />
      <SnackBarView
        message={snackBarMessage}
        open={isOpenSnackBar}
        onClose={handlOnCloseSnackBar}
        autoHideDuration={autoHideDuration}
        variant={snackBarType || 'error'}
      />
      <div style={{ textAlign: 'right', marginTop: '8px' }}>
        {showSpinner && (
          <LoadingIndicatorDialog title={t('loading_single')} open={true} />
        )}
        <AppButton
          // isDisabled={isEmpty(selectedRows)}
          style={{ marginRight: '8px' }}
          color="secondaryLight"
          text={t('create_income')}
          onClick={debounceFn(() => handleSave(), 500)}
        />
      </div>
      {!isApplyVAT && (
        <ValidationWarning
          message="settings_section.item.withoutTVA"
          style={{ color: '#ffbf00' }}
        />
      )}
      {Items.ItemList.some((item) => !item._isValidated) && (
        <ValidationWarning />
      )}
      <SelectedRowsMessage message={t('item.hint_import')} />

      <MUIDataTable
        data={Items.ItemList}
        columns={columns}
        options={getMuiDatatableOptions(t, Items.ItemList.length, options)}
      />
    </Fragment>
  ) : (
    <Fragment>
      <UploadInstructions type="item" />
      <DragNDropZone
        chooseFile={onFileChange}
        acceptFile=".xlsx"
        isMultiple={false}
      />
    </Fragment>
  );
};

ItemImportBody.propTypes = {
  onFileChange: PropTypes.func,
  Items: PropTypes.array,
  handleDeleteRow: PropTypes.func,
  handleUpdateClient: PropTypes.func,
  checkValidateData: PropTypes.func,
  handleSave: PropTypes.func,
  snackBarMessage: PropTypes.string,
  isOpenSnackBar: PropTypes.bool,
  handlOnCloseSnackBar: PropTypes.func,
  autoHideDuration: PropTypes.number,
  snackBarType: PropTypes.string,
  showSpinner: PropTypes.bool,
};

export default ItemImportBody;
