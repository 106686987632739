/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-await-in-loop */
import React, { useState, useCallback, useEffect } from 'react';
import Header from 'components/Header';
import Layout from 'components/Layout';
import CircularProgressCentered from 'components/CircularProgressCentered';
import { useRouter } from 'hooks/routerHooks';
import { useTranslation } from 'react-i18next';

import { Button } from '@material-ui/core';

import { useLocation } from 'react-router-dom';
import * as pdfjs from 'pdfjs-dist';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import { useDispatch } from 'react-redux';
import {
  getPDFFile,
  markAsDonePDFFile,
  getOriginalById,
} from 'actions/PDFActions';
import FilePreviewDialog from 'components/UploadFile/FilePreviewDialog';

import styles from './styles.module.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PDFItem = () => {
  const { search } = useLocation();
  const extractedKey = new URLSearchParams(search).get('extractedKey');
  const originalId = new URLSearchParams(search).get('originalId');
  const splitId = new URLSearchParams(search).get('splitId');
  const viewMode = new URLSearchParams(search).get('viewMode');
  const depositId = new URLSearchParams(search).get('depositId');
  const { t } = useTranslation();
  const { history } = useRouter();
  const dispatch = useDispatch();

  const [showSpinner, setShowSpinner] = useState(false);
  const [extractedImages, setExtractedImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState();

  const [openDialog, setOpenDialog] = useState(false);
  const [original, setOriginal] = useState(null);

  const toggleDialog = () => {
    // e.stopPropagation();
    setOpenDialog((prevValue) => !prevValue);
  };

  const readFileAsync = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsArrayBuffer(file);
    });
  };

  async function getFileFromUrl(url, name) {
    const response = await fetch(url);
    const data = await response.blob();
    return new File([data], name, {
      type: data.type,
    });
  }

  const convertPDFToImage = useCallback(async ({ pdfDoc, scale }) => {
    const canvas = document.createElement('canvas');
    const images = [];

    for (let pageNum = 1; pageNum <= pdfDoc.numPages; pageNum++) {
      const page = await pdfDoc.getPage(pageNum);
      const viewport = page.getViewport({ scale });
      // const canvas = canvasRef.current;
      const context = canvas.getContext('2d', { alpha: false });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      await page.render({ canvasContext: context, viewport }).promise;
      const image = canvas.toDataURL('image/png');
      images.push(image);
    }
    canvas.remove();
    return images;
    // setPdfImages(images);
  }, []);

  const onClickMarkAsDone = async (isDone) => {
    await dispatch(
      markAsDonePDFFile({ splitPDFPoolId: originalId, markAsDone: isDone })
    );

    setOriginal((prev) => ({
      ...prev,
      markAsDone: !original.markAsDone,
    }));

    history.push('/pdf');
  };

  useEffect(() => {
    (async () => {
      if (extractedKey) {
        setShowSpinner(true);

        const extractedFile = await dispatch(getPDFFile(extractedKey));

        const file = await getFileFromUrl(extractedFile, extractedKey);

        const pdfArrayBuffer = await readFileAsync(file);
        const loadingTask = pdfjs.getDocument(pdfArrayBuffer);

        const pdfDoc = await loadingTask.promise;

        const images = await convertPDFToImage({ pdfDoc, scale: 1 });
        setExtractedImages(images);

        setShowSpinner(false);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const originalFile = await dispatch(getOriginalById(originalId));
      setOriginal(originalFile);
    })();
  }, [originalId]);

  const goBack = () => {
    history.push(`/pdf/split?id=${originalId}`);
  };

  const renderHeader = () => (
    <Header
      name={viewMode ? t('pdf_purchase.view_mode') : t('pdf_purchase.extract')}
      goBack={goBack}
      flexEnd
    >
      {extractedImages.length && !viewMode ? (
        <>
          <Button
            style={{ margin: '1rem 1.5rem 1rem 0' }}
            variant="outlined"
            color="primary"
            onClick={() => onClickMarkAsDone(true)}
          >
            {t('pdf_purchase.mark_as_done')}
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '1rem 1.5rem 1rem 0' }}
            onClick={() => {
              history.push(
                `/purchases/new?extractKey=${extractedKey}&splitId=${splitId}&depositId=${depositId}`
              );
            }}
          >
            {t('pdf_purchase.create_an_purchase')}
          </Button>
        </>
      ) : (
        <></>
      )}
    </Header>
  );

  const renderBody = () => {
    return (
      <div>
        {showSpinner ? (
          <CircularProgressCentered />
        ) : (
          <div className={styles.split}>
            {extractedImages.length ? (
              extractedImages.map((image, index) => {
                const indice = ++index;
                return (
                  <div
                    className={styles['extract-item']}
                    key={index}
                    onClick={() => {
                      setSelectedImage({ value: image, type: 'image/png' });
                      toggleDialog();
                    }}
                  >
                    <img
                      key={index}
                      style={{
                        width: '100%',
                        height: '100%',
                      }}
                      src={image}
                      alt="preview"
                    ></img>
                    <div className={styles.indice}>{indice}</div>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        )}
        <FilePreviewDialog
          content={selectedImage}
          toggleDialog={toggleDialog}
          show={openDialog}
        />
      </div>
    );
  };
  return (
    <Layout
      header={renderHeader()}
      sidebarLeft={true}
      sidebarRight={false}
      body={renderBody()}
      showUserCard={true}
    />
  );
};

export default PDFItem;
