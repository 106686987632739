const getCurrentSubscription = ({ companySubscription: { data } }) => {
  return data;
};

export const getCurrentSubscriptionName = ({
  companySubscription: { data },
}) => {
  return data?.name;
};

export default getCurrentSubscription;
