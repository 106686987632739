import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Field, useFormikContext } from 'formik';
import { find } from 'lodash';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Divider, Grid } from '@material-ui/core';

import { EXPENSE_TYPE, KM_CATEGORY_ID } from 'constants/expense';
import { DEFAULT_CATEGORY_ID } from 'config/Constants';
import DatePicker from 'components/FormElementsDesigned/DatePicker';
import AppCheckbox from 'components/AppCheckbox';
import SelectAutocomplete from 'components/SelectAutocomplete';
import UploadFile from 'components/UploadFile/formWrapper';
import TextField from 'components/FormElements/textField';
import { getTranslateSubCategory } from 'helpers/CategoryHelpers';
import ProviderInfo from './ProviderInfo';
import ExpenseInfo from './ExpenseInfo';
import DistanceInfo from './DistanceInfo';
import DetailsInfo from './DetailsInfo';

const GeneralInfo = ({
  paymentTypes,
  defaultTaxes,
  onSelectFormType,
  isPurchase,
  selectedCategory,
  setSelectedCategory,
  isDistanceRate,
  readOnly,
  onOcr,
  ocrError,
  setOpenConfirmScan,
}) => {
  const { t } = useTranslation();
  const tree = useSelector((state) => state.categories.tree || []);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const filteredTree = useMemo(() => {
    // Finding the target category by its _id
    const targetCategory = tree.find(
      (category) => category._id === DEFAULT_CATEGORY_ID
    );
    // Filtering the children of targetCategory based on travel.isTravelWithDistanceRate property
    const filteredChildren = targetCategory?.children?.filter(
      (category) => !category.travel.isTravelWithDistanceRate
    );
    // Filtering the tree based on order property
    const filteredTreeData = tree.filter((category) => category.order > 0);

    // Merging the filteredChildren with filteredTree and mapping to include only display and children properties
    const mappingCategories = [
      ...(filteredChildren || []),
      ...filteredTreeData,
    ].map((category) => ({
      category_display: category.display,
      category_id: category._id,
      children: category.children,
    }));

    // Modify categories for Expense form
    if (!isPurchase) {
      const removedCategories = [
        'Salaire et charges',
        'Banque',
        'Dirigeant',
        'Taxes',
      ];

      const addedCategories = [
        {
          category_display: 'Indemnités kilométriques',
          category_id: KM_CATEGORY_ID,
          children: [],
        },
      ];

      return [...mappingCategories, ...addedCategories]
        .filter(
          (category) =>
            removedCategories.indexOf(category.category_display) === -1
        )
        .map((item) => ({
          ...item,
          category_display: t(`expenses.categories.${item.category_display}`),
        }));
    }

    return mappingCategories.map((item) => ({
      ...item,
      category_display: t(`expenses.categories.${item.category_display}`),
    }));
  }, [tree]);

  const { values, isSubmitting, setFieldValue } = useFormikContext();

  const {
    date,
    // eslint-disable-next-line camelcase
    due_date,
    payment_type: paymentType,
    is_intern: intern,
    is_billable: billable,
    category_id: subCategoryId,
    providerName,
  } = values;

  const { category_id: categoryId, category_display: categoryDisplay } =
    selectedCategory;

  const isKilometricAllowances = categoryId === KM_CATEGORY_ID;

  useEffect(() => {
    const selected = {
      category_id: values.import_category,
      category_display: values.import_categoryDisplay,
      children: values.import_categoryChildren,
    };
    setSelectedCategory(selected);
  }, [values?.import_category, values?.import_subCategory]);

  useEffect(() => {
    const display = categoryDisplay || values?.import_categoryDisplay;
    if (display) {
      const foundItem = find(filteredTree, (category) => {
        return category.category_display === display;
      });

      if (foundItem) {
        setSelectedCategories(foundItem.children);
      }
    }
  }, [categoryDisplay, values?.import_categoryDisplay]);

  function changeDate(value) {
    setFieldValue('date', value);
  }

  function changeDueDate(value) {
    setFieldValue('due_date', value);
  }

  function changePaymentType(paymTypeClicked) {
    if (!paymTypeClicked) {
      // the user cleared the selection - we do nothing. This will force the user to select a new value
      return;
    }
    setFieldValue('payment_type', paymTypeClicked._id);
  }

  function changeCategory(clickedCategory) {
    if (!clickedCategory) {
      // the user cleared the selection - we do nothing. This will force the user to select a new value
      return;
    }

    setFieldValue('category_id', clickedCategory.category_id);
    setFieldValue('category_display', clickedCategory.category_display);
    setFieldValue('form', null); // parent dont have form

    setSelectedCategories(clickedCategory.children);
    if (setSelectedCategory) {
      setSelectedCategory(clickedCategory);
    }
  }

  function changeSubCategory(clickedCategory) {
    if (!clickedCategory) {
      // the user cleared the selection - we do nothing. This will force the user to select a new value
      return;
    }
    const { _id, display, hasProvider, form } = clickedCategory;

    setFieldValue('category_id', _id);
    setFieldValue('category_display', display);
    setFieldValue('hasProvider', hasProvider);
    // setFieldValue('hasProvider, ')

    if (!form) {
      onSelectFormType('other');
      setFieldValue('form', 'other');
      return;
    }
    setFieldValue('form', 'ndf');
    onSelectFormType('ndf');
  }

  function setIntern({ target: { checked } }) {
    setFieldValue('is_intern', checked);

    if (checked) {
      setFieldValue('is_billable', false);
      setFieldValue('client_id', null);
      setFieldValue('project_id', null);
    } else {
      setFieldValue('internal_activity', null);
    }
  }

  function setBillable({ target: { checked } }) {
    setFieldValue('is_billable', checked);
  }

  function renderPaymentType() {
    return (
      <SelectAutocomplete
        required
        name={`payment_type`}
        label={t('expenses.expense.form_paymenttype')}
        getOptionLabel={(elem) => elem.display}
        onChange={changePaymentType}
        values={paymentTypes}
        valueSelected={find(paymentTypes, (type) => type._id === paymentType)}
      />
    );
  }

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="flex-start">
      {/* upload file */}
      <Grid item xs={6}>
        {values.uploadError && (
          <span style={{ color: 'red' }}>{t('uploadError')}</span>
        )}
        <UploadFile
          label={t('expenses.expense.receipt')}
          required={!isKilometricAllowances}
          withOcr
          defaultTaxes={defaultTaxes}
          isPreviewFile
          disabled={isKilometricAllowances}
          onOcr={onOcr}
          ocrError={ocrError}
          setOpenConfirmScan={setOpenConfirmScan}
          canAsyncScan
        />
      </Grid>

      {/* This row is on 1 line on bigger screen but on 2 lines on smaller device */}
      <Grid item xs={6}>
        {!isKilometricAllowances && (
          <ProviderInfo type={EXPENSE_TYPE.EXPENSE} />
        )}
        <SelectAutocomplete
          required
          name="category_display"
          label={t('providers.form.category')}
          getOptionLabel={(elem) => {
            return elem.category_display;
          }}
          onChange={changeCategory}
          /* Translation and sort categories options */
          values={filteredTree.sort((a, b) =>
            a.category_display.localeCompare(b.category_display)
          )}
          valueSelected={find(
            filteredTree,
            (category) => category.category_id === categoryId
          )}
        />
        {!isKilometricAllowances && (
          <SelectAutocomplete
            required
            name="category_id"
            label={t('providers.form.subCategory')}
            getOptionLabel={(e) => getTranslateSubCategory(e, t)}
            onChange={changeSubCategory}
            values={selectedCategories}
            valueSelected={find(
              selectedCategories,
              (category) => category._id === subCategoryId
            )}
          />
        )}
        <Field
          type="date"
          value={date}
          name={`date`}
          label="Date"
          keyboard={true}
          component={DatePicker}
          onChange={changeDate}
        />
        {renderPaymentType()}
        <AppCheckbox
          checked={intern}
          onClick={setIntern}
          labelText={t('expenses.expense.form_isIntern')}
          name={`is_intern`}
          id={`is_intern`}
          disabled={isSubmitting || billable}
        />
        <AppCheckbox
          checked={billable}
          onClick={setBillable}
          labelText={t('expenses.expense.form_isBbillable')}
          name={`is_billable`}
          id={`is_billable`}
          disabled={isSubmitting || intern}
        />

        {!isKilometricAllowances && (
          <>
            <TextField
              name={`billNumber`}
              label={t('expenses.expense.form_bill_number')}
              keyboard={true}
              onChange={(e) => {
                // if (e.target.value === '') {
                //   setFieldValue('description', '');
                //   setFieldValue('billNumber', '');
                //   return;
                // }
                setFieldValue('billNumber', e.target.value);
                setFieldValue(
                  'description',
                  `${providerName}${
                    e.target.value !== '' ? ` N° ${e.target.value}` : ''
                  }`
                );
              }}
            />
            <TextField
              name={`reference`}
              label={t('expenses.expense.form_reference')}
              keyboard={true}
              onChange={(e) => setFieldValue('reference', e.target.value)}
            />
            <TextField
              name={`orderNumber`}
              label={t('expenses.expense.form_purchase_order_number')}
              onChange={(e) => setFieldValue('orderNumber', e.target.value)}
              keyboard={true}
            />
          </>
        )}
        <Field
          type="date"
          // eslint-disable-next-line camelcase
          value={due_date}
          name={`due_date`}
          label={t('expenses.expense.form_due_date')}
          keyboard={true}
          component={DatePicker}
          required
          onChange={changeDueDate}
        />
        <Divider style={{ margin: '10px 0' }} />
        <ExpenseInfo />
        <Divider style={{ margin: '10px 0' }} />
        <DistanceInfo selectedCategory={selectedCategory || {}} />
        <DetailsInfo
          // selectedCategory={selectedCategory}
          defaultTaxes={defaultTaxes}
          isTravelWithDistanceRate={isDistanceRate}
          readOnly={readOnly}
        />
      </Grid>
    </Grid>
  );
};

GeneralInfo.propTypes = {
  paymentTypes: PropTypes.array,
  defaultTaxes: PropTypes.array,
  onSelectFormType: PropTypes.func,
  isPurchase: PropTypes.bool,
  selectedCategory: PropTypes.any,
  setSelectedCategory: PropTypes.func,
  readOnly: PropTypes.bool,
  isDistanceRate: PropTypes.bool,
  ocrError: PropTypes.object,
  onOcr: PropTypes.func,
  setOpenConfirmScan: PropTypes.func,
};

export default GeneralInfo;
