import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from 'helpers/RoleHelper';
import { AdvanceExpenses as Rights } from '../../config/Rights';
import { AdvanceExpenses as Feature } from '../../config/Features';

export const userCanCreateAdvanceExpense = () => {
  return hasRequestedAccess(Rights.AdvanceExpensesCreate);
};

export const userCanReadAdvanceExpense = () => {
  return hasRequestedAccess(Rights.AdvanceExpensesRead);
};

export const userCanUpdateAdvanceExpense = () => {
  return hasRequestedAccess(Rights.AdvanceExpensesUpdate);
};

export const userCanDeleteAdvanceExpense = () => {
  return hasRequestedAccess(Rights.AdvanceExpensesDelete);
};

export const userHasAdvanceExpenseAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
