/* eslint-disable react/display-name */
/* Packages */
import { Typography } from '@material-ui/core';
import MaterialTable from 'material-table';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

/* Components */
import { SEPARequestIcon } from 'components/AppIcons';

/* Styles */
import useStyles from '../BankBBFAccountTable/styles';

/* Local Components */
import BankBBFTableDate from '../BankBBFTableDate';

const BankBBFPayoutValidatedRequestTable = (props, tableValidatedRef) => {
  const { getRequestData } = props;

  const classnames = useStyles();
  const { t } = useTranslation();
  const history = useHistory();

  const columns = [
    {
      title: 'Date',
      field: 'created_at',
      render: (rowData) => (
        <BankBBFTableDate createdDate={rowData.created_at} />
      ),
      filtering: false,
      defaultSort: 'desc',
    },
    {
      title: t('bank_bbf.category'),
      field: 'source.expenseDetail.category.display',
      render: (rowData) => {
        const { __t: category } = rowData.source.expenseDetail;

        if (category.toLowerCase().includes('purchase')) {
          return t('expenses.expense.purchase');
        }

        return t('expenses.expense.expense');
      },
      filtering: false,
      sorting: false,
    },

    {
      title: t('bank_bbf.beneficiary'),
      field: 'source.expenseDetail.beneficiary.name',
      sorting: false,
      filtering: false,
    },
    {
      title: t('bank_bbf.number'),
      field: 'source.expenseDetail.expense_nb',
      sorting: false,
      filtering: false,
    },
    {
      title: t('bank_bbf.amount'),
      field: 'amount',
      render: (rowData) => (
        <Typography className={classnames.amountDebit}>
          {rowData.amount} {rowData.currency}
        </Typography>
      ),
      filtering: false,
    },
    {
      render: (rowData) => (
        <SEPARequestIcon
          sm
          onClick={() => {
            history.push({
              pathname: '/bank-bbf/sepa/request',
              // search: `?foreignId=${rowData.payoutId}&transactionType=Payout&amount=${rowData.amount}&currency=${rowData.currency}&createdDate=${rowData.createdDate}`,
              state: {
                foreignId: rowData.payoutId,
                transactionType: 'Payout',
                amount: rowData.amount,
                currency: rowData.currency,
                createdDate: rowData.createdDate,
              },
            });
          }}
        />
      ),
      filtering: false,
      sorting: false,
    },
  ];

  return (
    <MaterialTable
      tableRef={tableValidatedRef}
      columns={columns}
      data={(queryTable) => getRequestData(queryTable, 'VALIDATED')}
      title=""
      options={{
        filtering: false,
        search: false,
        showTitle: false,
        pageSizeOptions: [],
      }}
    />
  );
};

BankBBFPayoutValidatedRequestTable.propTypes = {
  getRequestData: PropTypes.func,
  tableValidatedRef: PropTypes.any,
};

export default forwardRef(BankBBFPayoutValidatedRequestTable);
