import { bindActionCreators } from 'redux';
import { isAuthenticated } from 'actions/AuthActions';
import { fetchCompanyUserData } from 'actions/LoggedUserActions';
import {
  selectedCompany,
  fetchLoggedUserCompanies,
  selectedCompanyId,
} from 'actions/UserCompanyActions';

export default (dispatch) =>
  bindActionCreators(
    {
      isAuthenticated,
      selectedCompany,
      fetchCompanyUserData,
      fetchLoggedUserCompanies,
      selectedCompanyId,
    },
    dispatch
  );
