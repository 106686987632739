const initialValues = (selectedTreezorCard, userId) => {
  const data = {
    nfc: true,
    atm: true,
    onLine: true,
    foreign: true,
    walletId: null,
    // userId: treezorUser,
    limitPaymentDay: false,
    limitPaymentWeek: false,
    limitPaymentMonth: false,
    limitPaymentYear: false,
    limitAtmDay: false,
    limitAtmWeek: false,
    limitAtmMonth: false,
    limitAtmYear: false,
    permsGroup: 'TRZ-CU-016',
    dailyPayment: undefined,
    weeklyPayment: undefined,
    montlyPayment: undefined,
    yearPayment: undefined,
    dailyAtm: undefined,
    weeklyAtm: undefined,
    montlyAtm: undefined,
    yearAtm: undefined,
    alertLimit: false,
    aliertAtm: false,
    mondayChecked: true,
    tuesdayChecked: true,
    wednesdayChecked: true,
    thursdayChecked: true,
    fridayChecked: true,
    saturdayChecked: true,
    sundayChecked: true,
    pin: '',
    cardUser: '',
    cardTag: null,
    companyId: null,
    bbfUserId: null,
    userId,
  };

  if (selectedTreezorCard) {
    data.walletId = selectedTreezorCard.walletId;
    data.bbfUserId = selectedTreezorCard.bbfUserId;
    data.companyId = selectedTreezorCard.companyId;
    data.cardTag = selectedTreezorCard.cardTag;
    data.nfc = !!selectedTreezorCard.optionNfc;
    data.onLine = !!selectedTreezorCard.optionOnline;
    data.atm = !!selectedTreezorCard.optionAtm;
    data.foreign = !!selectedTreezorCard.optionForeign;
    data.permsGroup = selectedTreezorCard.permsGroup;
    data.limitPaymentDay = !!selectedTreezorCard.limitPaymentDay;
    data.weeklyPayment = data.limitPaymentDay
      ? `${selectedTreezorCard.limitPaymentDay}`
      : 0;
    data.limitPaymentWeek = !!selectedTreezorCard.limitPaymentWeek;
    data.dailyPayment = data.limitPaymentWeek
      ? `${selectedTreezorCard.limitPaymentWeek}`
      : 0;
    data.limitPaymentMonth = !!selectedTreezorCard.limitPaymentMonth;
    data.montlyPayment = data.limitPaymentMonth
      ? `${selectedTreezorCard.limitPaymentMonth}`
      : 0;
    data.limitPaymentYear = !!selectedTreezorCard.limitPaymentYear;
    data.yearPayment = data.limitPaymentYear
      ? `${selectedTreezorCard.limitPaymentYear}`
      : 0;
    data.limitAtmDay = !!selectedTreezorCard.limitAtmDay;
    data.dailyAtm = data.limitAtmDay ? `${selectedTreezorCard.limitAtmDay}` : 0;
    data.limitAtmWeek = !!selectedTreezorCard.limitAtmWeek;
    data.weeklyAtm = data.limitAtmWeek
      ? `${selectedTreezorCard.limitAtmWeek}`
      : 0;
    data.limitAtmMonth = !!selectedTreezorCard.limitAtmMonth;
    data.montlyAtm = data.limitAtmMonth
      ? `${selectedTreezorCard.limitAtmMonth}`
      : 0;
    data.limitAtmYear = !!selectedTreezorCard.limitAtmYear;
    data.yearAtm = data.limitAtmYear
      ? `${selectedTreezorCard.limitAtmYear}`
      : 0;
  }

  return data;
};
export default initialValues;
