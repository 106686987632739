import { SUBSCRIPTION_NAME } from 'constants/subscriptions';

const SubscriptionRight = {
  [SUBSCRIPTION_NAME.DISCOVERY_PACKAGE]: [
    'InvoiceCreate',
    'InvoiceRead',
    'InvoiceUpdate',
    'InvoiceCancel',
    'InvoiceValidate',
    'InvoiceOverview',
    'TemplateCreate',
    'TemplateRead',
    'TemplateDelete',
    'TemplateUpdate',
    'ItemsCreate',
    'ItemsRead',
    'ItemsUpdate',
    'ItemsDelete',
    'UserManagementUserCreate',
    'UserManagementUserUpdate',
    'UserManagementUserDelete',
    'CompanySettingsUpdate',
    // 'UserRoleManagementCreate',
    // 'UserRoleManagementUpdate',
    // 'UserRoleManagementDelete',
    'UserRoleManagementRead',
    'UserManagementUpdate',
    'UserManagementDelete',
    // 'AdvanceExpensesCreate',
    // 'AdvanceExpensesRead',
    // 'AdvanceExpensesUpdate',
    // 'AdvanceExpensesDelete',
    'ClientsCreate',
    'ClientsRead',
    'ClientsUpdate',
    'ClientsDelete',
    'ProjectsCreate',
    'ProjectsRead',
    'ProjectsUpdate',
    'ProjectsDelete',
    // 'BookKeepingRead',
    // 'BookKeepingExport',
    'EmailTemplateUpdate',
    // 'PurchaseCreate',
    // 'PurchaseRead',
    // 'PurchaseUpdate',
    // 'PurchaseDelete',
    // 'PurchaseValidate',
    'InventoryRead',
    // 'InventoryCreate',
    // 'InventoryUpdate',
    // 'InventoryDelete',
    // 'BankRead',
    // 'BankCreate',
    // 'BankUpdate',
    // 'BankDelete',
    // 'BankOverview',
    // 'CardRead',
    // 'CardCreate',
    // 'CardUpdate',
    // 'CardDelete',
    // 'ProvidersCreate',
    'ProvidersRead',
    // 'ProvidersUpdate',
    // 'ProvidersDelete',
    // 'ExpenseCreate',
    // 'ExpenseRead',
    // 'ExpenseUpdate',
    // 'ExpenseDelete',
    // 'ExpenseValidate',
    // 'ExpenseOverview',
    'SubscriptionRead',
    'SubscriptionUpdate',
    'ActivityAnalysis',
    // 'Balancesheet',
    // 'Result',
    'Top10ProductsServices',
    'Top10Customers',
    'ActivityFollowUp',
    'ProjectsTracking',
    'Overdue',
    'Unpaid',
    'FutureReceipts',
    'ComingPayments',
    'OverduePayments',
    'FiveBiggestPayments',
    'Top10LateCustomers',
    'FiveBiggestLatePayments',
    'Top10MostIndebtedClients',
    // 'Expenses',
    // 'AnalyticalSortOfDebts',
    'MyExpenses',
    // 'Top10Debts',
    // 'Top10PaidProviders',
    // 'LateInDisbursement',
    // 'TotalToBeDisbursed',
    // 'UpcomingDisbursements',
    // 'DisbursementDelays',
    // 'FiveBiggestPaymentDelays',
    // 'FiveLargestUpcomingPayouts',
  ],
  [SUBSCRIPTION_NAME.STANDARD_FORMULA]: [
    'InvoiceCreate',
    'InvoiceRead',
    'InvoiceUpdate',
    'InvoiceCancel',
    'InvoiceValidate',
    'InvoiceOverview',
    'TemplateCreate',
    'TemplateRead',
    'TemplateDelete',
    'TemplateUpdate',
    'ItemsCreate',
    'ItemsRead',
    'ItemsUpdate',
    'ItemsDelete',
    'UserManagementUserCreate',
    'UserManagementUserUpdate',
    'UserManagementUserDelete',
    'CompanySettingsUpdate',
    'UserRoleManagementCreate',
    'UserRoleManagementUpdate',
    'UserRoleManagementDelete',
    'UserRoleManagementRead',
    'UserManagementUpdate',
    'UserManagementDelete',
    'AdvanceExpensesCreate',
    'AdvanceExpensesRead',
    'AdvanceExpensesUpdate',
    'AdvanceExpensesDelete',
    'ClientsCreate',
    'ClientsRead',
    'ClientsUpdate',
    'ClientsDelete',
    'ProjectsCreate',
    'ProjectsRead',
    'ProjectsUpdate',
    'ProjectsDelete',
    'BookKeepingRead',
    'BookKeepingExport',
    'EmailTemplateUpdate',
    'PurchaseCreate',
    'PurchaseRead',
    'PurchaseUpdate',
    'PurchaseDelete',
    'PurchaseValidate',
    'InventoryRead',
    'InventoryCreate',
    'InventoryUpdate',
    'InventoryDelete',
    'BankRead',
    'BankCreate',
    'BankUpdate',
    'BankDelete',
    'BankOverview',
    'CardRead',
    'CardCreate',
    'CardUpdate',
    'CardDelete',
    'ProvidersCreate',
    'ProvidersRead',
    'ProvidersUpdate',
    'ProvidersDelete',
    'ExpenseCreate',
    'ExpenseRead',
    'ExpenseUpdate',
    'ExpenseDelete',
    'ExpenseValidate',
    'ExpenseOverview',
    'SubscriptionRead',
    'SubscriptionUpdate',
    'ActivityAnalysis',
    'Balancesheet',
    'Result',
    'Top10ProductsServices',
    'Top10Customers',
    'ActivityFollowUp',
    'ProjectsTracking',
    'Overdue',
    'Unpaid',
    'FutureReceipts',
    'ComingPayments',
    'OverduePayments',
    'FiveBiggestPayments',
    'Top10LateCustomers',
    'FiveBiggestLatePayments',
    'Top10MostIndebtedClients',
    'Expenses',
    'AnalyticalSortOfDebts',
    'MyExpenses',
    'Top10Debts',
    'Top10PaidProviders',
    'LateInDisbursement',
    'TotalToBeDisbursed',
    'UpcomingDisbursements',
    'DisbursementDelays',
    'FiveBiggestPaymentDelays',
    'FiveLargestUpcomingPayouts',
  ],
  [SUBSCRIPTION_NAME.EFFICIENCY_FORMULA]: [
    'InvoiceCreate',
    'InvoiceRead',
    'InvoiceUpdate',
    'InvoiceCancel',
    'InvoiceValidate',
    'InvoiceOverview',
    'TemplateCreate',
    'TemplateRead',
    'TemplateDelete',
    'TemplateUpdate',
    'ItemsCreate',
    'ItemsRead',
    'ItemsUpdate',
    'ItemsDelete',
    'UserManagementUserCreate',
    'UserManagementUserUpdate',
    'UserManagementUserDelete',
    'CompanySettingsUpdate',
    'UserRoleManagementCreate',
    'UserRoleManagementUpdate',
    'UserRoleManagementDelete',
    'UserRoleManagementRead',
    'UserManagementUpdate',
    'UserManagementDelete',
    'AdvanceExpensesCreate',
    'AdvanceExpensesRead',
    'AdvanceExpensesUpdate',
    'AdvanceExpensesDelete',
    'ClientsCreate',
    'ClientsRead',
    'ClientsUpdate',
    'ClientsDelete',
    'ProjectsCreate',
    'ProjectsRead',
    'ProjectsUpdate',
    'ProjectsDelete',
    'BookKeepingRead',
    'BookKeepingExport',
    'EmailTemplateUpdate',
    'PurchaseCreate',
    'PurchaseRead',
    'PurchaseUpdate',
    'PurchaseDelete',
    'PurchaseValidate',
    'InventoryRead',
    'InventoryCreate',
    'InventoryUpdate',
    'InventoryDelete',
    'BankRead',
    'BankCreate',
    'BankUpdate',
    'BankDelete',
    'BankOverview',
    'CardRead',
    'CardCreate',
    'CardUpdate',
    'CardDelete',
    'ProvidersCreate',
    'ProvidersRead',
    'ProvidersUpdate',
    'ProvidersDelete',
    'ExpenseCreate',
    'ExpenseRead',
    'ExpenseUpdate',
    'ExpenseDelete',
    'ExpenseValidate',
    'ExpenseOverview',
    'SubscriptionRead',
    'SubscriptionUpdate',
    'ActivityAnalysis',
    'Balancesheet',
    'Result',
    'Top10ProductsServices',
    'Top10Customers',
    'ActivityFollowUp',
    'ProjectsTracking',
    'Overdue',
    'Unpaid',
    'FutureReceipts',
    'ComingPayments',
    'OverduePayments',
    'FiveBiggestPayments',
    'Top10LateCustomers',
    'FiveBiggestLatePayments',
    'Top10MostIndebtedClients',
    'Expenses',
    'AnalyticalSortOfDebts',
    'MyExpenses',
    'Top10Debts',
    'Top10PaidProviders',
    'LateInDisbursement',
    'TotalToBeDisbursed',
    'UpcomingDisbursements',
    'DisbursementDelays',
    'FiveBiggestPaymentDelays',
    'FiveLargestUpcomingPayouts',
  ],
};

export default SubscriptionRight;
