// it is a component... shouldn't be in the helper folder
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import owl from '../assets/images/owl.png';
import AppButton from '../components/AppButton';

import { postError } from '../services/ErrorReport';
import logger from './logger';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, name: null, message: null, page: null };
  }

  static getDerivedStateFromError(/* error */) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidUpdate(_, prevState) {
    if (
      prevState.name !== this.state.name ||
      prevState.message !== this.state.message ||
      prevState.page !== this.state.page
    ) {
      postError({
        owl: true,
        name: this.state.name,
        message: this.state.message,
        page: this.state.page,
      }).catch(logger.error);
    }
  }

  // eslint-disable-next-line
  componentDidCatch(error, info) {
    // You can also log the error to an error reporting service
    // eslint-disable-next-line
    console.error(error, info);

    if (this.state.name !== error.name) {
      this.setState({ name: error.name });
    }

    if (this.state.message !== error.message) {
      this.setState({ message: error.message });
    }

    if (this.state.page !== window.location.href) {
      this.setState({ page: window.location.href });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div
          style={{
            textAlign: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
          }}
        >
          <div>
            <img src={owl} width="256" height="256" alt="error" />
            <h1>Oups</h1>
            <h2>je crois que je suis totalement en panne sur cette action.</h2>
          </div>
          <p>
            Si le problème persiste merci de contacter votre support technique.
          </p>
          <a href="/">
            <AppButton text="OK" />
          </a>
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object,
};

export default ErrorBoundary;
