/* eslint-disable */
import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import { Box, Typography, TextField, Button } from '@material-ui/core';
import MessageModal from 'containers/BankBBF/components/MessageModal';

import service from 'services/TreezorService';

const VerificationSettingUpDebitStep = (props) => {
  const { handleCancel } = props;
  const { setFieldValue, submitForm, touched, errors, values } =
    useFormikContext();

  const translateDomain = 'debits_page.debits_creation_page.steps';
  const { t } = useTranslation();

  const [otp, setOTP] = useState('');
  const [isShowErrorModal, setShowErrorsModal] = useState(false);

  const getOtp = async () => {
    await service.getOTP();
  };

  useEffect(() => {
    getOtp();
    setOTP(values.otp);
    console.log(errors);
  }, []);

  const checkErrors = () => {
    if (Object.keys(errors).length === 0) {
      return false;
    }
    return true;
  };

  const handleCloseModal = () => setShowErrorsModal(false);

  const handleSubmit = async () => {
    await submitForm();
    console.log(errors);
    const isValid = checkErrors();
    setShowErrorsModal(isValid);
  };

  const handleChange = (event) => {
    setOTP(event.target.value);
  };

  const handleClose = () => {
    handle(false);
    setOTP('');
  };

  return (
    <Fragment>
      <Box
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'center',
          height: '100%',
          padding: '20px',
        }}
      >
        <Box>
          <Typography
            variant="h5"
            align="center"
            style={{
              padding: '20px',
            }}
          >
            {t(`${translateDomain}.step5.title`)}
          </Typography>
          <Typography
            align="center"
            style={{
              padding: '5px',
            }}
          >
            {t(`${translateDomain}.step5.message`)}
          </Typography>
        </Box>
        <Box>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            marginTop={2}
          >
            <TextField
              variant="outlined"
              inputProps={{ style: { textAlign: 'center' } }}
              style={{
                marginRight: '10px',
              }}
              value={otp}
              onChange={(event) => {
                handleChange(event);
                setFieldValue('otp', event.target.value);
              }}
            />
            {errors.otp && touched.otp ? (
              <div style={{ color: 'red' }}>{errors.otp}</div>
            ) : null}
            <Button
              color="primary"
              style={{
                marginTop: '10px',
              }}
              onClick={() => {
                console.log('Resend');
              }}
            >
              {t(`${translateDomain}.step5.otp-resend`)}
            </Button>
          </Box>
        </Box>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            width: '100%',
            marginTop: '20px',
          }}
        >
          <Button variant="contained" color="default" onClick={handleCancel}>
            {t(`debits_page.debits_creation_page.buttons.cancel`)}
          </Button>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            {t(`debits_page.debits_creation_page.buttons.submit`)}
          </Button>
        </Box>
      </Box>
      <MessageModal
        open={isShowErrorModal}
        type="error"
        title={t('debits_page.message_box.error.title')}
        message={t('debits_page.message_box.error.message')}
        handleCloseModal={handleCloseModal}
      />
    </Fragment>
  );
};

VerificationSettingUpDebitStep.propTypes = {
  handleCancel: PropTypes.func,
};

export default VerificationSettingUpDebitStep;
