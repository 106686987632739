import React, { useState, forwardRef } from 'react';
import { withTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import style from '../../assets/jss/components/appIcons';
import '../../assets/css/icons.css';

// disable react/display-name eslint rule for forwardRef components
/* eslint-disable react/display-name */
const Generic = forwardRef(
  (
    {
      className,
      onClick,
      classes,
      disabled,
      secondary,
      to,
      sm,
      target,
      ...rest
    },
    ref
  ) => {
    const iconClasses = classNames({
      [className]: true,
      [classes.icon]: true,
      [classes.secondary]: secondary,
      [classes.sm]: sm,
      [classes.disabled]: disabled,
    });
    return to ? (
      <Link
        ref={ref}
        type="button"
        className={iconClasses}
        to={to}
        target={target}
        {...rest}
      />
    ) : (
      <IconButton
        ref={ref}
        disabled={disabled}
        onClick={onClick}
        style={{ padding: 0 }}
        {...rest}
      >
        <span className={iconClasses} />
      </IconButton>
    );
  }
);

Generic.propTypes = {
  className: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
};
const GenericWithStyle = withStyles(style)(Generic);

const GenericWithTooltip = ({
  className,
  onClick,
  disabled,
  secondary,
  to,
  sm,
  target,
  tooltip,
  ...rest
}) => {
  if (tooltip && !disabled) {
    return (
      <Tooltip title={tooltip} placement="top">
        <GenericWithStyle
          className={className}
          to={to}
          target={target}
          sm={sm}
          secondary={secondary}
          disabled={disabled}
          onClick={onClick}
          {...rest}
        />
      </Tooltip>
    );
  }
  return (
    <GenericWithStyle
      className={className}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      {...rest}
    />
  );
};
GenericWithTooltip.propTypes = {
  className: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  tooltip: PropTypes.string,
};

GenericWithTooltip.defaultProps = {
  secondary: false,
  onClick: null,
  to: null,
  sm: false,
  disabled: false,
  target: '_self',
  tooltip: null,
};

// ----------------------------------------------------------------------------

const SortBy = ({ up, onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,

    [classes.disabled]: disabled,
    'icon icon-sort-down': !up,
    'icon icon-sort-up': up,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
SortBy.propTypes = {
  up: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
SortBy.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconSortBy = withStyles(style)(SortBy);

const Arrow = ({ up, onClick, classes, disabled, secondary, leftRight }) => {
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.leftRight]: leftRight,
    [classes.disabled]: disabled,
    [classes.secondary]: secondary,
    'icon icon-arrow-down': !up,
    'icon icon-arrow-up': up,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Arrow.propTypes = {
  up: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  leftRight: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
export const IconArrow = withStyles(style)(Arrow);

const Add = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-add': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Add.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
Add.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconAdd = withStyles(style)(Add);

const Write = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-write': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Write.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
Write.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconWrite = withStyles(style)(Write);

const Undo = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-undo': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Undo.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
Undo.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconUndo = withStyles(style)(Undo);

const Send = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-send': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Send.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
Send.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconSend = withStyles(style)(Send);

const Search = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-search': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Search.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
Search.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconSearch = withStyles(style)(Search);

const Refresh = ({
  onClick,
  classes,
  disabled,
  secondary,
  sm,
  md,
  lg,
  refreshing,
}) => {
  const [rotate, triggerRotate] = useState(false);

  function onClickRefresh() {
    triggerRotate(true);
    onClick();
    setTimeout(() => {
      triggerRotate(false);
    }, 2500);
  }

  const iconClasses = classNames({
    'icon icon-refresh': true,
    [classes.icon]: true,
    [classes.rotate]: rotate,
    [classes.spin]: refreshing,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return (
    <link
      type="button"
      className={iconClasses}
      onClick={() => onClickRefresh()}
    />
  );
};
Refresh.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  refreshing: PropTypes.bool,
};
Refresh.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconRefresh = withStyles(style)(Refresh);

const Export = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-export': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Export.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  refreshing: PropTypes.bool,
};
Export.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconExport = withStyles(style)(Export);

const Options = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-options': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Options.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
Options.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconOptions = withStyles(style)(Options);

const Message = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-message': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Message.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
Message.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconMessage = withStyles(style)(Message);

const Image = ({
  onClick,
  classes,
  disabled,
  secondary,
  sm,
  md,
  lg,
  xl,
  fill,
}) => {
  const iconClasses = classNames({
    'icon icon-image': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.xl]: xl,
    [classes.fill]: fill,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Image.propTypes = {
  onClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
  xl: PropTypes.bool,
  fill: PropTypes.bool,
};
Image.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
  xl: false,
  fill: false,
};
export const IconImage = withStyles(style)(Image);

const Close = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-close': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Close.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
Close.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconClose = withStyles(style)(Close);

const Date = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-date': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Date.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
Date.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconDate = withStyles(style)(Date);

const Check = ({ onClick, classes, disabled, secondary, sm, md, lg }) => {
  const iconClasses = classNames({
    'icon icon-check': true,
    [classes.icon]: true,
    [classes.secondary]: secondary,
    [classes.sm]: sm,
    [classes.md]: md,
    [classes.lg]: lg,
    [classes.disabled]: disabled,
  });
  return <link type="button" className={iconClasses} onClick={onClick} />;
};
Check.propTypes = {
  onClick: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,
  sm: PropTypes.bool,
  md: PropTypes.bool,
  lg: PropTypes.bool,
};
Check.defaultProps = {
  secondary: false,
  disabled: false,
  sm: false,
  md: false,
  lg: false,
};
export const IconCheck = withStyles(style)(Check);

const Pdf = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'icon icon-pdf': true,
  });

  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('pdf.print')}
    />
  );
};
Pdf.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};

export const IconPdf = withTranslation()(Pdf);

const Duplicate = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'icon icon-duplicate': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('duplicate')}
    />
  );
};

Duplicate.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const IconDuplicate = withTranslation()(Duplicate);

export const Edit = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'icon icon-edit': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('forms.edit')}
    />
  );
};
Edit.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const IconEdit = withTranslation()(Edit);

const Delete = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'icon icon-delete': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('forms.delete')}
    />
  );
};
Delete.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const IconDelete = withTranslation()(Delete);

// eslint-disable-next-line no-unused-vars
const See = ({
  onClick,
  disabled,
  secondary,
  to,
  sm,
  target,
  t,
  contentTooltip,
}) => {
  const iconClasses = classNames({
    'far fa-eye': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={
        contentTooltip ? t(contentTooltip) : t('pdf_purchase.view_history')
      }
    />
  );
};
See.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
  contentTooltip: PropTypes.string,
};
export const IconSee = withTranslation()(See);

const ExtractPDF = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'icon icon-pdf': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('pdf_purchase.extract')}
    />
  );
};
ExtractPDF.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const ExtractPDFIcon = withTranslation()(ExtractPDF);

const ArchivePDF = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'fas fa-archive': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('pdf_purchase.archive')}
    />
  );
};
ArchivePDF.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const ArchivePDFIcon = withTranslation()(ArchivePDF);

const Rename = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'icon icon-edit': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('pdf_purchase.rename')}
    />
  );
};
Rename.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const RenameIcon = withTranslation()(Rename);

// eslint-disable-next-line no-unused-vars
const Download = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'fas fa-download': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('pdf.download')}
    />
  );
};
Download.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const DownloadIcon = withTranslation()(Download);

// eslint-disable-next-line no-unused-vars
const File = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'fas fa-file-alt': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('expenses.create.purchase')}
    />
  );
};
File.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const FileIcon = withTranslation()(File);

// eslint-disable-next-line no-unused-vars
const Archive = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'fas fa-archive': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('pdf_purchase.archived_files')}
    />
  );
};
Archive.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const ArchiveIcon = withTranslation()(Archive);

// eslint-disable-next-line no-unused-vars
const UnArchive = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'fas fa-box-open': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('pdf_purchase.unarchive')}
    />
  );
};
UnArchive.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const UnArchiveIcon = withTranslation()(UnArchive);

// eslint-disable-next-line no-unused-vars
const DoubleLink = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'fas fa-link': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('transaction.reconcile')}
    />
  );
};
DoubleLink.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const DoubleLinkIcon = withTranslation()(DoubleLink);

// eslint-disable-next-line no-unused-vars
const Note = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'fas fa-paper-plane': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('bank_bbf.note')}
    />
  );
};
Note.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};
export const NoteIcon = withTranslation()(Note);

// eslint-disable-next-line no-unused-vars
const Eyes = ({ onClick, disabled, secondary, to, sm, target, t, tooltip }) => {
  const iconClasses = classNames({
    'far fa-eye': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={tooltip || t('sepa.view')}
    />
  );
};
Eyes.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
  tooltip: PropTypes.string,
};
export const EyesIcon = withTranslation()(Eyes);

// eslint-disable-next-line no-unused-vars
const SEPARequest = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'fas fa-paper-plane': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('sepa.send_SEPA_request')}
    />
  );
};
SEPARequest.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};

export const SEPARequestIcon = withTranslation()(SEPARequest);

// eslint-disable-next-line no-unused-vars
const DeletePayout = ({ onClick, disabled, secondary, to, sm, target, t }) => {
  const iconClasses = classNames({
    'fas fa-trash': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t('bank_bbf.delete_payout')}
    />
  );
};
DeletePayout.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
};

export const DeletePayoutIcon = withTranslation()(DeletePayout);

const ShoppingCart = ({
  onClick,
  disabled,
  secondary,
  to,
  sm,
  target,
  t,
  contentTooltip,
}) => {
  const iconClasses = classNames({
    'fas fa-cart-plus': true,
  });
  return (
    <GenericWithTooltip
      className={iconClasses}
      to={to}
      target={target}
      sm={sm}
      secondary={secondary}
      disabled={disabled}
      onClick={onClick}
      tooltip={t(contentTooltip)}
    />
  );
};
ShoppingCart.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  secondary: PropTypes.bool,
  sm: PropTypes.bool,
  disabled: PropTypes.bool,
  target: PropTypes.string,
  t: PropTypes.func,
  contentTooltip: PropTypes.string,
};

export const ShoppingCartIcon = withTranslation()(ShoppingCart);
