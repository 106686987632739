import { withStyles, TableCell, TableHead, TableRow } from '@material-ui/core';
import colors from '../../constants/colors.json';

// const drawerWidth = '250px';
// const iconHeight = 55;
export const dropDownItemSelected = {
  color: colors.blueOpacity,
  fontFamily: 'Kumbh Sans',
  fontSize: '11px',
  lineHeight: '15px',
  fontWeight: 'bold',
};

export const style = {
  '@global': {
    body: {
      fontFamily: 'Kumbh Sans',
      fontWeight: 400,
    },
  },
  app: {
    margin: 0,
    paddingTop: 0,
    width: '100%',
    backgroundColor: 'white',
    minWidth: '320px',
  },

  bottomSideBar: {
    width: '100%',
    textAlign: 'center',
  },

  selectedCompany: {
    float: 'left',
    color: '#ffffff',
    marginLeft: 21,
    textAlign: 'center',
    lineHeight: '40px',
    fontSize: '12px',
  },

  stretchContainer: {
    height: '85%',
    paddingTop: '18px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // justifyContent: 'space-between',
  },

  menuSticky: {
    position: 'sticky',
    top: 20,
  },

  // MAIN DIV CONTAINER

  appContainer: {
    width: '100vw',
    height: '100vh',
    backgroundColor: 'white',
    margin: 0,
    padding: 0,
    overflowY: 'scroll',
  },

  // MAIN APP GRID

  appGridWrap: {
    position: 'absolute',
    left: 0,
    right: 0,
    display: 'grid',
    width: '1200px',
    margin: '0 auto',
    padding: '14px',
    height: '100vh',
    gridTemplateColumns: 'repeat(12, 1fr)',
    gridTemplateRows: '90px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
    gridGap: '20px',
    // backgroundColor: 'white',
    // overflow: 'hidden',
    top: 0,
  },

  // LAYOUT GRID WRAPS

  mainSideBar: {
    backgroundColor: colors.blue,
    padding: '30px 0 0 0 ',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
  },

  mainSideBarClientPdf: {
    backgroundColor: colors.blue,
    padding: '30px 0 0 0 ',
    borderRadius: '4px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
    width: '100%',
  },

  mainSideBarClientPdfHeaderVersion: {
    backgroundColor: colors.blue,
    padding: '10px',
    borderRadius: '4px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
  },

  sideBarButtonsContainer: {
    margin: '0 4px',
  },

  companyButtonContainer: {
    width: '100%',
    backgroundColor: 'rgba(255,255,255,0.2)',
    marginBottom: '20px',
  },
  companyButtonInnerContainer: {
    width: 'calc(100% - 10px)',
    marginLeft: '10px',
  },
  userCardWrap: {
    minHeight: '90px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    borderRadius: '4px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
    cursor: 'pointer',
  },
  mainWrap: {
    gridColumn: '3/13',
    gridRow: '1/10',
  },

  // GRID WRAPS INCIDE SCREENS
  mainHeaderWrap: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  componentWrap: {
    display: 'flex',
  },

  rightSideBarWrap: {
    margin: 0,
  },
  rightSideBarWrapForm: {
    minWidth: '110px',
  },

  noneSidebar: {
    display: 'none',
  },
  blockSidebar: {
    display: 'none',
  },

  componentWrapNoSideBar: {
    gridColumn: '1/11',
    gridRow: '2/10',
  },

  // MAIN BODY WRAP GRID

  headerContainer: {
    gridColumn: '3/14',
    gridRow: '1/2',
    gridGap: '20px',
    display: 'grid',
    gridTemplateColumns: 'repeat(9, 1fr)',
    alignItems: 'center',
  },

  sideBarRightContainer: {
    minWidth: '175px',
    minHeight: '615px',
    gridColumn: '9/11',
  },
  hiddenSidebarWrapForm: {
    display: 'none',
  },
  hiddenSidebar: {
    display: 'none',
  },
  userWrapper: {
    borderRadius: '4px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
    width: '100%',
    height: '100%',
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '0 8px 0 8px',
  },
  toolsContainer: {
    borderRadius: '4px',
    padding: '14px',
    boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
    width: '100%',
    minHeight: '100%',
    height: 'auto',
    backgroundColor: colors.white,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    textAlign: 'center',
  },
  smLogo: {
    height: '17px',
    width: '17px',
    marginLeft: '25px',
  },
  wrapper: {
    backgroundColor: colors.white,
    width: '766px',
  },
  midWidth: {
    width: '80px',
  },
  iconStyle: {
    height: '30px',
    width: '30px',
  },
  containerColumn: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },
  containerRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    backgroundColor: colors.white,
  },

  formContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'flex-start',
    backgroundColor: colors.white,
    height: '1033px',
    maxWidth: '766px',
    padding: '21px',
    boxShadow: '0 10px 14px 0 rgba(0,0,0,0.3)',
    borderRadius: '3px',
  },

  statusContainer: {},

  formHeaderContainer: {
    display: 'flex',
    flex: 1,
    maxHeight: '306px',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  spaceAround: {
    paddingLeft: '10px',
    paddingRight: '10px',
  },
  centerContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '0 auto',
  },
  rowCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: 'red',
  },
  deleteIcon: {
    paddingTop: '3px',
    height: '15px',
    color: colors.grey,
  },
  reorderIcon: {
    paddingTop: '3px',
    height: '19px',
    color: colors.grey,
  },
  lRedText: {
    color: colors.red,
    lineHeight: '13px',
    fontSize: '15px',
    fontWeight: 'bold',
  },
  marginSM: {
    marginTop: '7px',
  },
  marginMid: {
    marginTop: '30px',
  },

  imageStyle: {
    maxWidth: '240px',
    height: '140px',
    width: 'auto',
  },
  // ICONS STYLE

  blueContainer: {
    backgroundColor: colors.backgroundGrey,
    color: colors.white,
    height: '34px',
    width: '260px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 14px',
    fontWeight: 'normal',
    fontSize: '11px',
  },

  deliveryContainer: {
    backgroundColor: colors.white,
    height: '35px',
    width: '260px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 5px 0 14px',
    marginBottom: '8px',
  },

  blueContainerStart: {
    backgroundColor: colors.backgroundGrey,
    color: colors.white,
    height: '34px',
    width: '260px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 14px',
    fontWeight: 'normal',
    fontSize: '10px',
  },
  whiteContainerStart: {
    backgroundColor: colors.white,
    color: 'black',
    height: '34px',
    width: '260px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 14px',
    fontWeight: 'normal',
    fontSize: '10px',
  },
  whiteContainer: {
    color: 'black',
    height: '34px',
    width: '260px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 14px',
    fontWeight: 'normal',
    fontSize: '10px',
    // borderBottom: '1px solid grey',
  },

  greyWrapper: {
    borderRadius: '4px',
    border: '1px solid #A6A6A6',
    backgroundColor: colors.white,
  },
  lWhiteContainerStart: {
    backgroundColor: colors.white,
    color: 'black',
    height: '34px',
    width: '382px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: '0 14px',
    fontWeight: 'normal',
    fontSize: '10px',
  },

  footerBlueContainer: {
    color: colors.backgroundGrey,
    lineHeight: '12px',
    fontSize: '10px',
    height: '55px',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },

  // COMMON FLEX CONTAINERS

  columnStartSpaceBetween: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  columnStartSpaceAround: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
  },
  columnStartSpaceCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },

  imageCompanyContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    width: 'auto',
    // width: '270px',
    margin: '0 auto',
  },
  columnStartStretch: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'flex-start',
    // justifyContent: 'stretch',
  },
  columnCenterCenter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  columnEndCenter: {
    display: 'flex',
    flexDirection: 'column',
    // alignItems: 'flex-end',
    justifyContent: 'flex-end',
  },

  rowSpaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
  },
  rowFlexStart: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    width: '140px',
  },
  rowFlexEnd: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '140px',
  },
  deliveryInput: {
    color: colors.grey,
    backgroundColor: colors.white,
    width: '80px',
    borderRadius: '4px',
    border: `1px solid ${colors.black_transparent}`,
    height: '28px',
    margin: '5px 0',
  },

  // PADDINGS

  mPaddingTop: {},

  // COMMON TEXT

  lBoldBlueText: {
    color: colors.light_blue,
    fontWeight: 'bold',
    fontSize: '18px',
  },
  xlPurpleText: {
    color: colors.blue,
    fontSize: '27px',
    lineHeight: '37px',
  },

  blueText: {
    color: colors.blue,
    fontSize: '18px',
    lineHeight: '26px',
  },
  mBoldBlueText: {
    color: colors.light_blue,
    fontWeight: 'bold',
    fontSize: '15px',
  },
  mWhiteText: {
    color: colors.white,
    fontSize: '13px',
  },
  mBoldWhiteText: {
    fontWeight: 'bold',
    color: colors.white,
    fontSize: '13px',
  },
  smBoldBlueText: {
    color: colors.light_blue,
    fontWeight: 'bold',
    lineHeight: '30px',
    fontSize: '11px',
  },
  smBlueText: {
    color: colors.light_blue,
    lineHeight: '13px',
    fontSize: '11px',
  },
  smGreyTextPadding: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '11px',
    paddingRight: '20px',
    width: '45px',
  },
  smGreyText: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '11px',
  },
  smPurpleText: {
    color: colors.blue,
    lineHeight: '13px',
    fontSize: '11px',
  },
  mGreyText: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '13px',
  },
  mPurpleText: {
    color: colors.blue,
    lineHeight: '13px',
    fontSize: '13px',
    paddingLeft: '15px',
  },
  mGreyTextPadding: {
    color: colors.grey,
    lineHeight: '13px',
    fontSize: '13px',
    paddingLeft: '15px',
  },
  vSmGreyText: {
    color: colors.grey,
    lineHeight: '12px',
    fontSize: '10px',
    textAlign: 'justify',
  },
  vSmBlueText: {
    color: colors.light_blue,
    lineHeight: '12px',
    fontSize: '10px',
    textAlign: 'justify',
  },
  smBlueBoldText: {
    color: colors.light_blue,
    lineHeight: '13px',
    fontSize: '11px',
  },
  smBlackText: {
    color: 'black',
    fontWeight: 'normal',
    fontSize: '11px',
  },

  pencil: { color: colors.grey, marginLeft: '30px' },
  sepLine: {
    height: '1px',
    width: '140px',
    borderBottom: '1px solid #D7D7D7',
    marginTop: '34px',
    marginBottom: '34px',
    // backgroundColor: colors.fillsLightGrey,
    // color: colors.fillsLightGrey,
  },

  duplicateIcon: {
    color: colors.fillsDarkGrey,
    transform: 'rotate(90deg)',
    fontWeight: 'bold',
    fontSize: '25px',
    padding: '20px 20px 0px 20px',
  },

  collapseButton: {
    marginBottom: '16px',
    color: 'white',
    width: '100%',
  },

  '@media (max-width: 960px)': {
    appFlexPDF: {
      display: 'flex',
      width: '100vw',
      flexDirection: 'coulumn',
    },
    mainWrapPDF: {
      display: 'flex',
      width: '100%',
      height: '100%',
    },
    mainBodyWrapPdf: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
    },
  },
  '@media (max-width: 1199px)': {
    appGridWrap: {
      width: '100vw',
    },
  },
  // TP breakpoint
  '@media (max-width: 1023px)': {
    appGridWrap: {
      width: '100vw',
    },
    sideBarButtonsContainer: {
      margin: '0 4px',
    },
    companyButtonInnerContainer: {
      // width: 'calc(100% - 5px)',
      marginLeft: '5px',
    },
  },
  '@media (max-width: 767px)': {
    appGridWrap: {
      minWidth: '320px',
    },
    hiddenSidebarWrapForm: {
      display: 'block',
      gridColumn: '9/11',
      gridRow: '1/10',
    },
    hiddenSidebar: {
      display: 'block',
      gridColumn: '9/11',
      gridRow: '2/10',
    },
    hiddenSidebarNotOpen: {
      width: '100%',
    },
    hiddenSidebarOpen: {
      // transition: 'width 1s ease',
      width: '180px',
      position: 'fixed',
      right: '15px',
      height: 'calc(100% - 135px)',
      overflowY: 'scroll',
      overflowX: 'hidden',
      borderRadius: '4px',
      boxShadow: '0 1px 5px 0 rgba(0,0,0,0.2)',
    },
    hiddenSidebarOpenNoWrap: {
      top: '125px',
    },
    noneSidebar: {
      display: 'none',
    },
    blockSidebar: {
      display: 'block',
    },
  },
};

export const StyledTableCell = withStyles({
  root: {
    backgroundColor: colors.backgroundGrey,
    border: 0,
    color: colors.white,
    height: 30,
    padding: '0 3px',
    fontWeight: 'normal',
    fontSize: '11px',
  },
  body: {
    background: colors.white,
    color: 'black',
    padding: '2px 3px',
  },
})(TableCell);

export const StyledTableHead = withStyles({
  root: {
    height: '30px',
    backgroundColor: colors.backgroundGrey,
  },
})(TableHead);
export const StyledTableRow = withStyles({
  root: {
    height: '34px',
    borderBottom: '1px solid grey',
  },
})(TableRow);
