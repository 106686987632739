const INITIAL_STATE = {
  loading: false,
  error: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case 'STORING_HIPAY':
      return {
        ...state,
        loading: true,
      };
    case 'STORE_HIPAY_SUCCESS':
      return {
        ...state,
        loading: false,
      };
    case 'STORE_HIPAY_FAILED':
      return {
        ...state,
        loading: false,
        error: { ...action.payload.error },
      };
    default:
      return state;
  }
};
