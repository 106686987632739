import { Grid, makeStyles } from '@material-ui/core';
import { addDays } from 'date-fns';
import AppButton from 'components/AppButton';
import CustomGenericDialog from 'components/CustomGenericDialog';
import RichTextEditor from 'components/richTextEditor';
import { PAYMENT_LINK_ELEMENT } from 'constants/invoice';
import { getInvoiceEmail } from 'helpers/invoiceHelpers';
import { useDialog } from 'hooks/dialogHooks';
import PropTypes from 'prop-types';
import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import InvoiceService from 'services/InvoiceService';
import { roundingNumber } from 'utils';
import { resendInvoice } from 'actions/InvoiceActions';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import { UPDATE_INVOICE } from 'actions/Types';
import { setGlobalError } from 'actions/GlobalError';
import ApproveDialog from './ApproveDialog';
import ChooseBankDialog from './ChooseBankDialog';

const useStyles = makeStyles(() => ({
  groupActions: {
    display: 'flex',
    justifyContent: 'end',
  },
  signatureEmail: {
    display: 'block',
    fontWeight: 'bold',
    fontSize: 16,
  },
}));
const InvoiceResendMailDialog = ({
  toggleDialog,
  invoice,
  loggedUser,
  loggedUserCompany,
  emailTemplates,
  companySubscription,
}) => {
  const classes = useStyles();
  const { id, type, payments } = invoice;
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    showDialog: showChooseBankDialog,
    toggleDialog: toggleChooseBankDialog,
  } = useDialog();
  const { showDialog: showApproveDialog, toggleDialog: toggleApproveDialog } =
    useDialog();
  const initialHtml = getInvoiceEmail(
    type,
    loggedUser,
    loggedUserCompany,
    emailTemplates,
    true
  );
  const renderContentEmailRAW = () => {
    return initialHtml.replace(
      PAYMENT_LINK_ELEMENT.RAW,
      PAYMENT_LINK_ELEMENT.AFTER_EDIT
    );
  };
  const ref = useRef(renderContentEmailRAW());

  const handleApproveResendMail = () => {
    toggleApproveDialog();
    toggleChooseBankDialog();
  };

  const resendMailCb = () => {
    toggleChooseBankDialog();
    toggleDialog();
  };

  const checkBridgeLinkExprided = async (paymentLinkId) => {
    try {
      setLoading(true);
      const res = await InvoiceService.checkPaymentLinkExprided(paymentLinkId);
      const status = res?.data?.data?.status;
      setLoading(false);
      return status === 'expired' || status === 'canceled';
    } catch (error) {
      dispatch(setGlobalError(error));
      return false;
    }
  };

  const createBridgeLink = async (amount = 0, desc = '') => {
    if (!id && !parseFloat(amount)) return null;
    const expired = invoice?.dueDate ? new Date(invoice.dueDate) : new Date();

    const res = await InvoiceService.createPaymentLink(
      {
        amount: roundingNumber(parseFloat(amount)),
        desc,
        invoiceId: id,
        companyName: loggedUserCompany?.name,
        expiredDate: addDays(expired, 14),
      },
      loggedUser?._id
    );
    return res?.data?.data;
  };

  const createStripeLink = async () => {
    if (!id) return null;
    const res = await InvoiceService.createPaymentLinkToUsePaymentLink(id);
    return res?.data?.data;
  };
  const prepareContentMailBeforeSend = (bridgeLinkUrl) => {
    const emailInput = ref.current?.htmlToGet;
    const paymentLinkTemplate = `
    <p><span style="color: rgb(0,85,0);font-size: 15px;font-family: sans-serif; font-weight: 600">
    Veuillez trouver le lien pour un  paiement direct de votre facture par virement automatique:  
    <a href="${bridgeLinkUrl}" target="_blank">Cliquez ici</a></span>&nbsp;</p>
    `;
    // insert paymentLinkTemplate into emailInput before string "<p><span style="color: rgb(85,85,85);font-size: 15px;font-family: sans-serif;">Bien cordialement,</span>&nbsp;</p>"
    const emailInputWithBridgeLink = emailInput?.replace(
      PAYMENT_LINK_ELEMENT.AFTER_EDIT,
      `${paymentLinkTemplate}`
    );
    return emailInputWithBridgeLink;
  };

  // case payment link is exprided and remaining payment link > 0
  const resendMailWithNewBridgeLink = async (amount, currPaymentId = '') => {
    setLoading(true);
    const bridgeLink = await createBridgeLink(amount, invoice?.invoice_nb);
    const upPaymentLinkUsed = invoice?.paymentLinkUsed
      ? invoice?.paymentLinkUsed + 1
      : 1;

    const restValues = {
      paymentLink: {
        id: bridgeLink?.id,
        url: bridgeLink?.url,
      },
    };
    if (bridgeLink) {
      const emailInputWithPaymentLink = prepareContentMailBeforeSend(
        bridgeLink.url
      );
      await dispatch(
        resendInvoice(id, emailInputWithPaymentLink, resendMailCb)
      );

      if (currPaymentId) {
        const formatPayments = payments.map((item) => {
          if (item._id === currPaymentId) {
            return { ...item, paymentLink: bridgeLink, wasSent: true };
          }
          return item;
        });
        const response = (
          await InvoiceService.updateInvoice(id, {
            payments: formatPayments,
            paymentLinkUsed: upPaymentLinkUsed,
          })
        ).data;
        await dispatch({ type: UPDATE_INVOICE, payload: { id, response } });
      } else {
        const response = (
          await InvoiceService.updateInvoice(id, {
            ...restValues,
            paymentLinkUsed: upPaymentLinkUsed,
          })
        ).data;
        await dispatch({ type: UPDATE_INVOICE, payload: { id, response } });
      }
    }

    setLoading(false);
  };

  // case payment link (bridge link) is exprided + remaining payment link = 0
  const resendMailWithNewStripeLink = async (bankInfo) => {
    setLoading(true);
    const stripeLink = await createStripeLink();
    window.open(stripeLink?.url, '_blank');
    const restValues = {
      stripePaymentLink: {
        url: stripeLink?.url,
        _id: stripeLink?._id,
      },
      company: {
        ...invoice.company,
        bank_details: bankInfo,
      },
    };
    const response = (await InvoiceService.updateInvoice(id, restValues)).data;
    await dispatch({ type: UPDATE_INVOICE, payload: { id, response } });
    resendMailCb();
    setLoading(false);
  };

  // case have payment link and it not expride
  const resendMailWithPaymentLink = async (bridgeLink) => {
    setLoading(true);
    const emailInputWithPaymentLink = prepareContentMailBeforeSend(bridgeLink);
    await dispatch(resendInvoice(id, emailInputWithPaymentLink, resendMailCb));
    setLoading(false);
  };

  const handleConfirmSendMail = async () => {
    const currPayment = payments.find((item) => item?.status === 'pending');

    if (currPayment) {
      if (!currPayment?.paymentLink) {
        resendMailWithNewBridgeLink(currPayment.amount, currPayment._id);
      } else {
        const newPaymentLink = currPayment?.paymentLink || invoice?.paymentLink;
        const paymentLinkisExprided = await checkBridgeLinkExprided(
          newPaymentLink?.id
        );
        if (!paymentLinkisExprided) {
          const bridgeLink = newPaymentLink?.url;

          resendMailWithPaymentLink(bridgeLink);
        } else {
          const { remainingPaymentLink } = companySubscription;
          if (remainingPaymentLink > 0) {
            resendMailWithNewBridgeLink(currPayment?.amount);
          } else {
            toggleApproveDialog();
          }
        }
      }
    } else if (invoice?.paymentLink) {
      // TODO: remove later
      const paymentLink = { invoice };
      const paymentLinkisExprided = await checkBridgeLinkExprided(
        paymentLink?.id
      );
      if (!paymentLinkisExprided) {
        const bridgeLink = paymentLink?.url;

        resendMailWithPaymentLink(bridgeLink);
      } else {
        const { remainingPaymentLink } = companySubscription;
        if (remainingPaymentLink > 0) {
          resendMailWithNewBridgeLink(invoice?.gross_total);
        } else {
          toggleApproveDialog();
        }
      }
    }
  };

  return (
    <>
      <CustomGenericDialog
        title={t('invoice.form.email.dialog.title')}
        onCancel={toggleDialog}
        confirmText={t('invoice.form.email.dialog.confirm')}
        cancelText={t('no')}
        iconClose
        closeDialog={toggleDialog}
        color="secondary"
        maxWidth="md"
      >
        {showApproveDialog && (
          <ApproveDialog
            toggleDialog={toggleApproveDialog}
            handleApprove={handleApproveResendMail}
            companySubscription={companySubscription}
          />
        )}
        {showChooseBankDialog && (
          <ChooseBankDialog
            toggleDialog={toggleChooseBankDialog}
            loggedUserCompany={loggedUserCompany}
            handleConfirmChooseBank={resendMailWithNewStripeLink}
          />
        )}
        <LoadingIndicatorDialog
          open={loading}
          title={t('invoices.procesing')}
        />

        {/* content email */}
        <div>{t('invoice.form.email.dialog.description')}</div>
        <RichTextEditor ref={ref} initialHtml={renderContentEmailRAW()} />
        <span className={classes.signatureEmail}>{loggedUserCompany.name}</span>
        <span className={classes.signatureEmail}>
          {loggedUser.display_name}
        </span>
        <Grid item xs={12}>
          <div className={classes.groupActions}>
            <AppButton
              color="transparentGrey"
              text={t('no')}
              onClick={toggleDialog}
            />
            <AppButton
              color="secondaryLight"
              text={t('invoice.form.email.dialog.confirm')}
              noBorder={true}
              onClick={() => handleConfirmSendMail()}
            />
          </div>
        </Grid>
      </CustomGenericDialog>
    </>
  );
};

InvoiceResendMailDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  invoice: PropTypes.object,
  loggedUser: PropTypes.object,
  loggedUserCompany: PropTypes.object,
  emailTemplates: PropTypes.object,
  companySubscription: PropTypes.object,
};

export default InvoiceResendMailDialog;
