/* import packages */
import React from 'react';
import { Box, Typography, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

/* import icon */
import { ArrowForwardIos } from '@material-ui/icons';
/* import asset */
import chequeImage from 'assets/images/online.png';

const BankBBFDepositCheckIntro = (props) => {
  const { showCreateCheque, showTableDeposit } = props;
  const { t } = useTranslation();
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '20px',
      }}
    >
      <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="outlined"
          color="primary"
          style={{
            marginBottom: '10px',
          }}
          onClick={showTableDeposit}
          endIcon={<ArrowForwardIos />}
        >
          {t('cheque.consult_deposit')}
        </Button>
      </Box>
      <Typography
        variant="h3"
        align="center"
        style={{
          fontSize: '20px',
          fontWeight: 600,
          textTransform: 'uppercase',
        }}
      >
        {t('cheque.intro_title')}
      </Typography>
      <Typography
        align="justify"
        style={{
          fontSize: '14px',
          fontWeight: 600,
          textTransform: 'uppercase',
          padding: '20px',
        }}
      >
        {t('cheque.intro_message')}
      </Typography>
      <img
        src={chequeImage}
        alt="Cheque icon"
        style={{ width: '70%', margin: 'auto' }}
      />
      <Box
        style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}
      >
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '10px' }}
          onClick={showCreateCheque}
        >
          {t('cheque.deposit_check')}
        </Button>
      </Box>
    </Box>
  );
};

BankBBFDepositCheckIntro.propTypes = {
  showCreateCheque: PropTypes.func,
  showTableDeposit: PropTypes.func,
};

export default BankBBFDepositCheckIntro;
