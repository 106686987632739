import colors from '../../../constants/colors.json';

const style = {
  mainHeaderWrap: {
    gridColumn: '1/9',
    gridRow: '1/2',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '1px solid #3d319d',
  },

  '@media (max-width: 960px)': {
    pdfHeader: {
      width: '100%',
      backgrondColor: 'white',
      height: '50px',
      // position: 'fixed',
      // zIndex: 10,
    },
  },
  backIcon: {
    color: colors.fillsDarkGrey,
    transform: 'rotate(90deg)',
    fontWeight: 'bold',
    fontSize: '28px',
    padding: '20px 20px 0px 20px',
    cursor: 'pointer',
  },
  flexStartRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },

  flexOne: {
    flex: 1,
  },
};

export default style;
