import {
  Expense as Rights,
  Dashboard as DashboardRights,
} from '../../config/Rights';
import { Expense as Feature } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreateExpense = () => {
  return hasRequestedAccess(Rights.ExpenseCreate);
};

export const userCanReadExpense = () => {
  return hasRequestedAccess(Rights.ExpenseRead);
};

export const userCanUpdateExpense = () => {
  return hasRequestedAccess(Rights.ExpenseUpdate);
};

export const userCanDeleteExpense = () => {
  return hasRequestedAccess(Rights.ExpenseDelete);
};

export const userCanValidateExpense = () => {
  return hasRequestedAccess(Rights.ExpenseValidate);
};

export const userCanOverviewExpense = () => {
  return hasRequestedAccess(DashboardRights.ExpenseOverview);
};

export const userHasExpenseAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
