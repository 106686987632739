import React from 'react';
import PropTypes from 'prop-types';

class EditBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      underline: this.props.info.underline,
      bold: this.props.info.bold,
      italic: this.props.info.italic,
      underline_check: this.props.info.underline === 'underline',
      bold_check: this.props.info.bold === 'bold',
      italic_check: this.props.info.italic === 'italic',
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleCheck = this.handleCheck.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleChange(name, value) {
    this.props.handleChange(name, value);
  }

  handleClick() {
    this.props.quit();
  }

  onChange(e) {
    this.props.handleChange(e.target.name, e.target.value);
  }

  handleCheck(e) {
    const name = e.target.name.split('_')[0];
    const change = {};

    if (this.state[`${name}_check`]) {
      change[`${name}_check`] = false;
      change[name] = '';
    } else {
      change[`${name}_check`] = true;
      change[name] = name;
    }

    const cbName = e.target.name;
    this.setState(change, () => this.handleChange(cbName, change[name]));
  }

  renderTextEdit() {
    return (
      <div
        className="edit-box"
        ref={this.props.reference}
        style={{ display: this.props.display, left: this.props.pos }}
      >
        <div>fontsize: {this.props.fs}</div>
        <input
          onChange={this.onChange}
          onMouseUp={this.props.handleMouseUp}
          value={this.props.fs}
          type="range"
          min="8"
          max="72"
          name={`fs_${this.props.name}`}
        />
        <div>
          <select
            name={`ff_${this.props.name}`}
            value={this.props.ff}
            onChange={this.onChange}
          >
            police:
            <option value="Arial">Arial</option>
            <option value="Open Sans">Open Sans</option>
          </select>
        </div>
        <input
          onChange={this.handleCheck}
          type="checkbox"
          name={`bold_${this.props.name}`}
          value={this.state.bold}
          checked={this.state.bold_check}
        />
        <label className="bold">Gras</label>
        <br />
        <input
          onChange={this.handleCheck}
          type="checkbox"
          name={`italic_${this.props.name}`}
          value={this.state.italic}
          checked={this.state.italic_check}
        />
        <label className="italic">Italic</label>
        <br />
        <input
          onChange={this.handleCheck}
          type="checkbox"
          name={`underline_${this.props.name}`}
          value={this.state.underline}
          checked={this.state.underline_check}
        />
        <label className="underline">Sousligné</label>
        <br />
      </div>
    );
  }

  renderImageEdit() {
    return (
      <div>
        <div
          className="edit-box"
          ref={this.props.reference}
          style={{ display: this.props.display, left: this.props.pos }}
        >
          <div>image width: {this.props.width}</div>
          <input
            onChange={this.onChange}
            onMouseUp={this.props.handleMouseUp}
            value={this.props.width}
            type="range"
            min="40"
            max="600"
            name={`width_${this.props.name}`}
          />

          {/* <div>image height: {this.props.height}</div>
          <input
            onChange={this.onChange}
            onMouseUp={this.props.handleMouseUp}
            value={this.props.height}
            type="range"
            min="40"
            max="600"
            name={`height_${this.props.name}`}
          /> */}
        </div>
      </div>
    );
  }

  render() {
    const { isImage } = this.props;

    if (isImage) {
      return this.renderImageEdit();
    }

    return this.renderTextEdit();
  }
}

EditBox.propTypes = {
  handleChange: PropTypes.func,
  quit: PropTypes.func,
  handleMouseUp: PropTypes.func,
  reference: PropTypes.object,
  display: PropTypes.string,
  name: PropTypes.string,
  fs: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pos: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ff: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isImage: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  info: PropTypes.object,
};

EditBox.defaultProps = {
  isImage: false,
};

export default EditBox;
