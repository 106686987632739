/* eslint-disable radix */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Stepper, Step, StepButton, makeStyles, Grid } from '@material-ui/core';
// import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Layout from 'components/Layout';
import { isEmpty } from 'lodash';
import { withFormik } from 'formik';
import { useLocation, useHistory } from 'react-router-dom';
import OnboardingButton from 'components/OnboardingButton';
import { useTranslation } from 'react-i18next';
import {
  TYPE_AUTO_ENTREPRENEUR,
  TYPE_ENTREPRENEUR_INDIVIDUEL,
} from 'constants/company';
import { LOGOUT_USER_SUCCESS } from 'actions/Types';
import AuthService from 'services/AuthService';
import OnboardTypes from './components/OnboardLists';
import OnboardingHeader from './components/OnboardingHeader';
import {
  OnboardManagementTypes,
  OnboardSteps,
  CompanyTypes,
  CompanyTypesUrlCode,
  currentLocation,
  titleCode,
  OnboardManagementTypesAndOptions,
} from './utils';
import storagePersonalInfo from './Form/storagePersonalInfor';
import ManagementSolution from './components/ManagementSolutions';
import PersonalInfo from './Form/PersonalInfor';
import RenderButtonOptions from './components/RenderButtonOptions';
import FinalPage from './components/FinalPage';
import ValidatePage from './components/ValidatePage';
import CompanyTypeOptions from './components/CompanyTypeOptions';
import ContractorInfo from './Form/ContractorInfo';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '15px',
    margin: 'auto',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  stepTitle: {
    textAlign: 'center',
    fontSize: '25px',
  },
  stepper: {
    width: '60%',
    margin: 'auto',
    backgroundColor: 'transparent',
  },
  stepBack: {
    // marginTop: '20px',
    cursor: 'pointer',
  },
  buttonOptions: {
    textAlign: 'center',
  },
  managementContent: {
    display: 'flex',
    alignItems: 'start',
  },
}));

const OnboardingPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  const [activeStep, setActiveStep] = useState(0);
  const [data, setData] = useState({});
  const [managementType, setManagementType] = useState(null);
  const [companyType, setCompanyType] = useState(null);
  const [personalInfo, setPersonalInfo] = useState({});
  const [isChooseManagement, setIsChooseManagement] = useState(false);
  const [isFeature, setIsFeature] = useState(false);

  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    AuthService.logout();
    dispatch({ type: LOGOUT_USER_SUCCESS });
  }, []);

  const handleNext = () => {
    setActiveStep(activeStep + 1);
  };
  const clearParams = () => {
    history.replace({
      pathname: location.pathname,
      search: new URLSearchParams({}).toString(),
    });
    setActiveStep(0);
  };

  const handleStep = (step) => () => {
    if (step === 0 && queryParams.has('type')) {
      setManagementType(null);
      setIsChooseManagement(false);
      clearParams();
    }
    setActiveStep(step);
  };

  const handleManagementTools = (value) => {
    setManagementType(value);
  };

  const checkValidPersonalInfoData = () => {
    const name = queryParams.get('n');
    const userName = queryParams.get('u');
    const firstname = queryParams.get('f');
    const email = queryParams.get('e');
    const phonenumber = queryParams.get('p');
    const address = queryParams.get('a');
    const rcs = queryParams.get('r');
    const siren = queryParams.get('s');
    const nic = queryParams.get('nc');
    const codeApe = queryParams.get('cd');
    const city = queryParams.get('ct');
    const zipcode = queryParams.get('zc');
    const legalForm = queryParams.get('lf');
    const title = queryParams.get('t');
    const isValidEmail = /\S+@\S+\.\S+/.test(email);
    const isValidPhoneNumber =
      /^(0|(\+33[\s]?([0]?|[(0)]{3}?)))[1-9]([-. ]?[0-9]{2}){4}$/.test(
        phonenumber
      );
    const isValidAddress = /[^\s\\]/.test(address);
    const isValidZipcode = /[\d]{5}/.test(zipcode);
    const isValidCity = /[^\s\\]/.test(city);
    const isValidRcs = /[^\s\\]/.test(rcs);
    const isValidSiren = /[^\s\\]/.test(siren);
    const isValidCodeApp = /[^\s\\]/.test(codeApe);
    if (
      (name &&
        (!address ||
          !rcs ||
          !siren ||
          !nic ||
          !codeApe ||
          !city ||
          !zipcode ||
          !legalForm ||
          !isValidAddress ||
          !isValidZipcode ||
          !isValidCity ||
          !isValidRcs ||
          !isValidSiren ||
          !isValidCodeApp)) ||
      (!name &&
        (address ||
          rcs ||
          siren ||
          nic ||
          codeApe ||
          city ||
          zipcode ||
          legalForm)) ||
      !userName ||
      !firstname ||
      !email ||
      !phonenumber ||
      !title ||
      parseInt(title) > 2 ||
      parseInt(title) < 0 ||
      !/^\d+$/.test(title) ||
      !isValidPhoneNumber ||
      !isValidEmail
    ) {
      return false;
    }
    const personnalInforData = {
      name,
      userName,
      firstname,
      email,
      phonenumber,
      address,
      rcs,
      siren,
      nic,
      code_ape: codeApe,
      city,
      zipcode,
      legal_orm: legalForm,
      title: titleCode[parseInt(title)],
    };
    setPersonalInfo(personnalInforData);
    storagePersonalInfo(personnalInforData);
    return true;
  };

  const ManagementTools = () => {
    return (
      <Grid
        container
        alignItems="center"
        alignContent="center"
        xs={12}
        direction="row"
        className={classes.managementContent}
      >
        {OnboardManagementTypesAndOptions.map((item, index) => {
          return (
            <Grid key={index} xs={6} className={classes.buttonOptions}>
              <OnboardingButton
                onClick={() => {
                  setIsChooseManagement(true);
                  handleManagementTools(item.title);
                }}
                title={item.title}
                options={item.options}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  const stepContent = () => {
    let curentManagementType = managementType;
    useEffect(() => {
      const params = {};
      const crr = queryParams.get('crr');
      const act = queryParams.get('act');
      // eslint-disable-next-line no-restricted-syntax
      for (const param of queryParams) {
        // eslint-disable-next-line prefer-destructuring
        params[param[0]] = param[1];
      }
      setIsChooseManagement(false);
      const management = queryParams.get('type');
      if (
        (crr === 'pr' ||
          crr === 'fn' ||
          crr === 'pr' ||
          crr === 'rl' ||
          crr === 'qp') &&
        !checkValidPersonalInfoData()
      ) {
        clearParams();
      } else if (management === 'ec') {
        if (
          (params.type && params.type !== 'ec' && params.type !== 'mt') ||
          !params.type ||
          (crr && !act) ||
          (crr && !currentLocation.includes(crr)) ||
          (act && !CompanyTypesUrlCode[act])
        ) {
          clearParams();
        } else {
          setData({ type: 'ec' });
          setIsChooseManagement(true);
          // eslint-disable-next-line prefer-destructuring
          curentManagementType = OnboardManagementTypes[0];
          setManagementType(OnboardManagementTypes[0]);
          if (act && CompanyTypesUrlCode[act]) {
            setData({
              ...data,
              account_type: CompanyTypesUrlCode[act],
            });
            setManagementType('account_monitoring');
            setActiveStep(1);
          }
        }
      } else if (management === 'mt') {
        if (crr && crr !== 'adf') {
          clearParams();
        } else {
          // eslint-disable-next-line prefer-destructuring
          curentManagementType = OnboardManagementTypes[0];
          setManagementType(OnboardManagementTypes[1]);
          handleNext();
        }
      } else {
        clearParams();
      }
    }, []);

    const companyIsContractor = (typeOfCompany) => {
      const companyTypeIsContractor = [
        TYPE_AUTO_ENTREPRENEUR,
        TYPE_ENTREPRENEUR_INDIVIDUEL,
      ];
      return companyTypeIsContractor.includes(typeOfCompany);
    };

    const handleChooseCompanyType = (selected) => {
      const type = queryParams.get('type');
      const act = Object.keys(CompanyTypesUrlCode).find(
        (key) => CompanyTypesUrlCode[key] === selected
      );
      const params = { type, act };
      const newQueryParams = new URLSearchParams(params);
      history.replace({
        pathname: location.pathname,
        search: newQueryParams.toString(),
      });
      const dataSubmit = { ...data };
      dataSubmit.account_type = selected;
      setData(dataSubmit);
      handleNext();
    };
    switch (activeStep) {
      case 0:
        switch (isChooseManagement) {
          case true:
            switch (curentManagementType) {
              case 'account_monitoring':
                return (
                  <Grid>
                    <RenderButtonOptions
                      handleChoose={handleChooseCompanyType}
                      options={CompanyTypes}
                      data={data}
                      setData={setData}
                    />
                  </Grid>
                );
              case 'only_access':
                return handleNext();
              default:
                return null;
            }
          case false:
            return (
              <Grid>
                <ManagementTools />
              </Grid>
            );
          default:
            return null;
        }
      case 1:
        switch (curentManagementType) {
          case 'account_monitoring':
            return (
              <OnboardTypes
                handleNext={handleNext}
                data={data}
                setData={setData}
                personalInfo={personalInfo}
                setPersonalInfo={setPersonalInfo}
                setIsFeature={setIsFeature}
              />
            );
          case 'only_access':
            return <ManagementSolution handleNext={handleNext} />;
          default:
            return null;
        }
      case 2:
        switch (curentManagementType) {
          case 'account_monitoring':
            return <FinalPage data={data} handleNext={handleNext} />;
          case 'only_access':
            return (
              <CompanyTypeOptions
                setCompanyType={(e) => {
                  setCompanyType(e);
                  handleNext();
                }}
              />
            );
          default:
            return null;
        }
      case 3:
        if (curentManagementType === 'account_monitoring') {
          return <ValidatePage />;
        }
        if (companyIsContractor(companyType)) {
          return (
            <ContractorInfo
              isAccountInfo
              companyType={companyType}
              isRaisonSociale
            />
          );
        }
        return (
          <PersonalInfo
            personalInfo={personalInfo}
            isRaisonSociale={true}
            companyType={companyType}
            isAccountInfo
          />
        );

      default:
        return null;
    }
  };

  const RenderStepTitle = () => {
    let text = '';

    if (activeStep === 0) {
      if (managementType) {
        text = '';
      } else {
        text = `onboarding.stepper_0_title`;
      }
    } else if (managementType === 'account_monitoring') {
      if (isEmpty(personalInfo)) {
        text = `onboarding.${data.account_type}_text`;
      } else {
        text = `onboarding.questions_stepper_title.${
          !isFeature ? 'future_' : ''
        }${data.account_type}`;
      }
    }
    return (
      <div className={classes.stepTitle}>
        <b>{t(text)}</b>
      </div>
    );
  };

  const renderBody = () => {
    return (
      <div className={classes.root}>
        {activeStep < 2 && (
          <>
            {/* <ArrowBackIosIcon
              className={classes.stepBack}
              disabled={activeStep === 0}
              onClick={() => handleBack()}
            /> */}
            <RenderStepTitle />
            <Stepper
              activeStep={activeStep}
              alternativeLabel
              className={classes.stepper}
            >
              {OnboardSteps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={handleStep(index)}></StepButton>
                </Step>
              ))}
            </Stepper>
          </>
        )}
        <div>{stepContent()}</div>
      </div>
    );
  };

  return (
    <Layout
      header={<OnboardingHeader />}
      showUserCard={false}
      body={renderBody()}
    />
  );
};

OnboardingPage.propTypes = {};

const RegisterWithForm = withFormik({
  displayName: 'CompanySignUpForm',
  enableReinitialize: true,
})(OnboardingPage);

export default RegisterWithForm;
