import React from 'react';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';

import NumberFormat from '../../../../../helpers/NumberFormat';

import { typeHelpers } from '../../../../../helpers/invoiceHelpers';

import colors from '../../../../../constants/colors.json';

export const StyledTableCell = withStyles({
  root: {
    backgroundColor: colors.backgroundGrey,
    border: 0,
    color: colors.white,
    height: 30,
    padding: '0 3px',
    fontWeight: 'normal',
    fontSize: '11px',
  },
  body: {
    background: colors.white,
    color: 'black',
    padding: '2px 3px',
  },
})(TableCell);

export const StyledTableHead = withStyles({
  root: {
    height: '30px',
    backgroundColor: colors.backgroundGrey,
  },
})(TableHead);
export const StyledTableRow = withStyles({
  root: {
    height: '34px',
    borderBottom: '1px solid grey',
  },
})(TableRow);

const PrepaymentsTable = () => {
  const { t } = useTranslation();
  const {
    values: { type: invoiceType, prepayments_display: prepayents },
  } = useFormikContext();

  if (
    !typeHelpers.isInvoice(invoiceType) ||
    !prepayents ||
    (prepayents && !prepayents.length)
  ) {
    return <div />;
  }

  return (
    <div style={{ display: 'flex', minWidth: '260px' }}>
      <Table style={{ minWidth: '260px' }}>
        <StyledTableHead>
          <StyledTableRow>
            <StyledTableCell>
              {t('invoices.prepayment.description')}
            </StyledTableCell>
            <StyledTableCell>{t('invoices.prepayment.amount')}</StyledTableCell>
          </StyledTableRow>
        </StyledTableHead>

        <TableBody>
          {prepayents.map(({ amount, description }, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell variant="body">{description}</StyledTableCell>
              <StyledTableCell variant="body">
                {`${NumberFormat.n(amount)} €`}
              </StyledTableCell>
            </StyledTableRow>
          ))}

          <StyledTableRow key="total">
            <StyledTableCell
              style={{
                backgroundColor: colors.backgroundGrey,
                border: 0,
                color: colors.white,
              }}
            >
              {t('invoices.total_gross')}
            </StyledTableCell>
            <StyledTableCell>
              {`${NumberFormat.n(
                prepayents.reduce((acc, { amount }) => acc + amount, 0)
              )} €`}
            </StyledTableCell>
          </StyledTableRow>
        </TableBody>
      </Table>
    </div>
  );
};

export default PrepaymentsTable;
