import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CustomGenericDialog from '../../../../../components/CustomGenericDialog';
import ClientForm from '../../../../Clients/Form';

const ClientFormDialog = ({
  showNewClientDialog,
  toggleNewClientDialog,
  setNewClient,
}) => {
  const { t } = useTranslation();

  if (!showNewClientDialog) {
    return null;
  }

  return (
    <CustomGenericDialog
      title={t('settings_section.clients.dialog_title_create')}
      color="secondary"
      iconClose
      closeDialog={toggleNewClientDialog}
    >
      <ClientForm
        closeDialog={toggleNewClientDialog}
        onCreated={setNewClient}
      />
    </CustomGenericDialog>
  );
};

ClientFormDialog.propTypes = {
  showNewClientDialog: PropTypes.bool.isRequired,
  toggleNewClientDialog: PropTypes.func.isRequired,
  setNewClient: PropTypes.func.isRequired,
};

export default ClientFormDialog;
