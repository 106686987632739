import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { EmojiObjectsSharp } from '@material-ui/icons';

import Header from '../../components/Header';
import OptionCard from '../../components/OptionCard';

import state from './state';
import Layout from '../../components/Layout';
import IconClients from '../../assets/images/Telos_Clients.svg';
import IconCompany from '../../assets/images/Telos_Company.svg';
import IconItems from '../../assets/images/Telos_Items.svg';
import IconRoles from '../../assets/images/Telos_Roles.svg';
import IconTemplates from '../../assets/images/Telos_Templates.svg';
import IconUsers from '../../assets/images/Telos_Users.svg';
import IconSubscription from '../../assets/images/subscription-icon.svg';

const styles = (theme) => ({
  root: {
    paddingTop: '20px',
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
});

const SettingsPage = (props) => {
  function renderBody() {
    const {
      t,
      classes,
      userCanUpdateUsers,
      userCanUpdateEmailTemplate,
      userCanUpdateCompany,
      userHasTemplateAccess,
      userHasUserRoleManagementAccess,
      userHasItemAccessFeature,
      userHasClientAccess,
    } = props;

    return (
      <Grid
        container
        spacing={10}
        alignContent="center"
        alignItems="center"
        classes={{ root: classes.root }}
      >
        {/* <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              icon={props => <ImportContacts {...props} />}
              title={t('settings_section.internal_activity.card_title')}
              description={t(
                'settings_section.internal_activity.card_description'
              )}
              buttonText={t('settings_section.internal_activity.card_button')}
              to="/settings/internal-activities"
            />
          </Grid> */}

        {userCanUpdateCompany && (
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              icon={IconCompany}
              title={t('company.card_title')}
              description={t('company.card_description')}
              buttonText={t('company.card_button')}
              to="/settings/company"
            />
          </Grid>
        )}
        {userCanUpdateUsers && (
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              icon={IconUsers}
              title={t('settings_section.users.card_title')}
              description={t('settings_section.users.card_description')}
              buttonText={t('settings_section.users.card_button')}
              to="/settings/users"
            />
          </Grid>
        )}
        {userHasUserRoleManagementAccess && (
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              icon={IconRoles}
              title={t('roles.card_title')}
              description={t('roles.card_description')}
              buttonText={t('roles.card_button')}
              to="/settings/roles"
            />
          </Grid>
        )}

        {userHasTemplateAccess && (
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              icon={IconTemplates}
              title={t('template.card_title')}
              description={t('template.card_description')}
              buttonText={t('template.card_button')}
              to="/settings/template"
            />
          </Grid>
        )}

        {/* TODO: find icon for email template */}
        {userCanUpdateEmailTemplate && (
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              icon={IconTemplates}
              title={t('settings_section.emailTemplate.title')}
              description={t('settings_section.emailTemplate.description')}
              buttonText={t('settings_section.item.card_button')}
              to="/settings/emailTemplates"
            />
          </Grid>
        )}

        {userHasClientAccess && (
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              icon={IconClients}
              title={t('settings_section.clients.card_title')}
              description={t('settings_section.clients.card_description')}
              buttonText={t('settings_section.clients.card_button')}
              to="/settings/clients"
            />
          </Grid>
        )}

        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            icon={IconUsers}
            title={t('settings_section.providers.card_title')}
            to="/settings/providers"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            iconElement={
              <EmojiObjectsSharp style={{ fontSize: '65px' }} color="primary" />
            }
            title={t('settings_section.clients.client_project')}
            to="/projects"
          />
        </Grid>

        {userHasItemAccessFeature && (
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              icon={IconItems}
              title={t('settings_section.item.card_title')}
              description={t('settings_section.item.card_description')}
              buttonText={t('settings_section.item.card_button')}
              to="/settings/items"
            />
          </Grid>
        )}

        {/* {userHasItemAccessFeature && (
          <Grid item xs={12} sm={6} md={4}>
            <OptionCard
              disabled
              icon={IconItems}
              title={t('inventory.title')}
              description={t('inventory.title')}
              buttonText={t('inventory.title')}
              to="/settings/inventory"
            />
          </Grid>
        )} */}

        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            icon={IconSubscription}
            title={t('settings_section.subscription.card_title')}
            to="/settings/subscriptions"
          />
        </Grid>

        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            icon={IconSubscription}
            title={t('settings_section.category.label')}
            to="/settings/categories"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <OptionCard
            icon={IconSubscription}
            title={t('settings_section.analytical_fields.label')}
            to="/settings/analytical-fields"
          />
        </Grid>
      </Grid>
    );
  }

  const { t } = props;
  return (
    <Layout
      header={<Header name={t(`settings`)} />}
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

SettingsPage.propTypes = {
  t: PropTypes.func.isRequired,
  classes: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  userCanUpdateUsers: PropTypes.bool,
  userCanUpdateCompany: PropTypes.bool,
  userHasTemplateAccess: PropTypes.bool,
  userHasUserRoleManagementAccess: PropTypes.bool,
  userHasItemAccessFeature: PropTypes.bool,
  userHasClientAccess: PropTypes.bool,
  userCanUpdateEmailTemplate: PropTypes.bool,
};

const TranslatedSettingsPage = withTranslation()(SettingsPage);

const TranslatedSettingsPageWithStyles = withStyles(styles)(
  TranslatedSettingsPage
);
export default connect(state, null)(TranslatedSettingsPageWithStyles);
