import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

const WalletDeleteConfirm = (props) => {
  const { onChangeStep } = props;
  const history = useHistory();

  const [confirmChecker, setConfirmChecker] = useState(false);

  const onChangeConfirmChecker = (e) => setConfirmChecker(e.target.checked);

  const onClickContinue = () => {
    onChangeStep(2);
  };

  const onClickKeepMyAccount = () => {
    history.push('/bank-bbf/accounts');
  };

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box style={{ padding: '10px 0', maxWidth: '800px' }}>
        <Box style={{ paddingBottom: '10px' }}>
          <Typography
            variant="h4"
            color="primary"
            style={{
              textAlign: 'center',
              fontWeight: 'bold',
              paddingBottom: '5px',
            }}
          >
            Vous souhaitez clôturer votre compte
          </Typography>
          <Typography
            variant="h5"
            style={{ color: 'black', textAlign: 'center' }}
          >
            Vous êtes sur le point d’arrêter définitivement l’utilisation de
            l’ensemble de nos services de paiements
          </Typography>
        </Box>
        <Box
          style={{
            // paddingBottom: '5px',
            color: '#f44336',
          }}
        >
          <Typography
            style={{
              fontSize: '16px',
              textAlign: 'center',
              fontWeight: 'bold',
            }}
          >
            Attention, en fermant votre compte :
          </Typography>
          <ul>
            <li>
              <Typography style={{ fontSize: '16px' }}>
                L’IBAN actuellement en vigueur ne sera plus valide
              </Typography>
            </li>
            <li>
              <Typography style={{ fontSize: '16px' }}>
                votre/vos carte(s) de paiement seront désactivée(s)
              </Typography>
            </li>
            <li>
              <Typography style={{ fontSize: '16px' }}>
                Vous ne pourrez plus recevoir ou émettre de virements, ni être
                prélevé ou encaisser des chèques.
              </Typography>
            </li>
          </ul>
        </Box>
        <FormControlLabel
          style={{
            paddingBottom: '30px',
          }}
          label="Je comprends que la clôture de mon compte est une opération définitive et irréversible"
          control={
            <Checkbox
              checked={confirmChecker}
              onChange={onChangeConfirmChecker}
              name="confirmChecker"
            />
          }
        />
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            padding: '0 120px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={onClickContinue}
            disabled={!confirmChecker}
          >
            Continuer
          </Button>
          <Button
            variant="outlined"
            color="default"
            size="large"
            onClick={onClickKeepMyAccount}
          >
            Conserver mon compte
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

WalletDeleteConfirm.propTypes = {
  onChangeStep: PropTypes.func,
};

export default WalletDeleteConfirm;
