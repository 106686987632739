import React, { useState } from 'react';
import { withStyles } from '@material-ui/core';
import styles from 'assets/jss/root';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
import { withTranslation } from 'react-i18next';
import Layout from 'components/Layout';
import ActivitiesList from './ActivitiesList';
import ActivitiesHeader from './ActivitiesList/ActivitiesHeader';

const AnalyticalFields = () => {
  const activities = Object.values(useSelector((state) => state.activities));
  const [activitiesList, setActivitiesList] = useState(activities);

  const handleAddActivityRecord = () => {
    setActivitiesList([...activitiesList, { display: '', id: Math.random() }]);
  };

  return (
    <Layout
      header={
        <ActivitiesHeader handleAddActivityRecord={handleAddActivityRecord} />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={
        <ActivitiesList
          activities={activitiesList}
          setActivitiesList={setActivitiesList}
        />
      }
    />
  );
};

const AnalyticalFieldsWithStyle = withStyles(styles)(AnalyticalFields);
const RouterAnalyticalFieldsWithStyle = withRouter(AnalyticalFieldsWithStyle);
const RouterAnalyticalFieldsWithTranslate = withTranslation()(
  RouterAnalyticalFieldsWithStyle
);
export default RouterAnalyticalFieldsWithTranslate;
