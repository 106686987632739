import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import AppPlusSelect from 'components/AppPlusSelect';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import APP_ROUTING from 'constants/appRouting';

import { useRouter } from '../../../hooks/routerHooks';
import Refresh from '../../../components/Refresh';
import Header from '../../../components/Header';
import { useThunkDispatch } from '../../../hooks/generalHooks';

import {
  userCanCreateClient,
  userCanReadClient,
} from '../../../selectors/rightsSelector/clientRequests';
import { userCanReadProject } from '../../../selectors/rightsSelector/projectRequests';
import { fetchClients } from '../../../actions/ClientActions';
import { fetchClientProjects } from '../../../actions/ClientProjectActions';

const ClientHeader = ({
  openClientFormDialog,
  isCreateActions,
  screenType,
}) => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();
  const { history } = useRouter();

  const [refreshing, setRefreshing] = useState(false);

  const canReadClient = useSelector(userCanReadClient);
  const canReadProject = useSelector(userCanReadProject);
  const canCreateClient = useSelector(userCanCreateClient);

  const types = useMemo(
    () => [
      {
        name: t('settings_section.clients.create'),
        show: true,
        onClick: () => openClientFormDialog(),
      },
      {
        name: t('settings_section.clients.import_individuals'),
        show: true,
        onClick: () => history.push(APP_ROUTING.IMPORT_INDIVIDUALS),
      },
      {
        name: t('settings_section.clients.import_companies'),
        show: true,
        onClick: () => history.push(APP_ROUTING.IMPORT_COMPANIES),
      },
    ],
    [history, openClientFormDialog]
  );

  const fetchClientsCb = useCallback(
    () => dispatch(fetchClients()),
    [dispatch]
  );

  const fetchClientProjectsCb = useCallback(
    () => dispatch(fetchClientProjects()),
    [dispatch]
  );

  const refreshArr = [];

  if (canReadClient) {
    refreshArr.push(fetchClientsCb);
  }

  if (canReadProject) {
    refreshArr.push(fetchClientProjectsCb);
  }

  function toogleRefreshing() {
    setRefreshing((prevValue) => !prevValue);
  }

  return (
    <Header
      name={
        screenType
          ? [t('settings_section.clients.title'), screenType].join(' - ')
          : t('settings_section.clients.title')
      }
      spaceBetween
      goBack={() => {
        history.goBack();
      }}
    >
      <Refresh
        disabled={!canReadClient}
        loadData={refreshArr}
        loading={refreshing}
        callback={toogleRefreshing}
        preFn={toogleRefreshing}
      />
      {canCreateClient && isCreateActions && (
        <AppPlusSelect
          items={types.filter((type) => type.show)}
          name="name"
          label={t('create')}
        />
      )}
    </Header>
  );
};

ClientHeader.propTypes = {
  openClientFormDialog: PropTypes.func,
  isCreateActions: PropTypes.bool,
  screenType: PropTypes.string,
};

export default ClientHeader;
