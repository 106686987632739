import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import queryString from 'query-string';

/* Import custom hooks */
import { useRouter } from 'hooks/routerHooks';

/* Import components */
import CircularProgressCentered from 'components/CircularProgressCentered';

/* Import services */
import InvoiceService from 'services/InvoiceService';
import AppDashboardCard from 'components/AppDashboard/components/AppDashboardCard';
import { Box, List, useTheme } from '@material-ui/core';
import AppDashboardListItem from 'components/AppDashboard/components/AppDashboardListItem';
import FiveBiggestCard from 'components/AppDashboard/components/FiveBiggestCard';
import { useSelector } from 'react-redux';
import { format } from 'date-fns';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    minHeight: 300,
  },
}));

const DebtsOverview = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const { history } = useRouter();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [top10HighestDebtsData, setTopHighestDebts] = useState([]);
  const agingBalance = useSelector((state) => state.agingBalance);

  const navigatePage = (url, queryParams) => {
    history.push(
      queryString.stringifyUrl(
        {
          url,
          query: queryParams,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  const fetchAllInvoices = async () => {
    try {
      setLoading(true);
      const response = await (
        await InvoiceService.fetchInvoicePaginate('pagination=false')
      ).data.docs;

      const debts = response.filter(
        (item) =>
          (item.state === 'validated' || item.state === 'overdue') &&
          (item.type === 'invoice' ||
            item.type === 'credit_note' ||
            item.type === 'pre_payment') &&
          item.client.display_name
      );

      const reduceDebtsClient = [
        ...debts
          .reduce((acc, current) => {
            const { client } = current;
            const grouped = acc.get(client._id);

            if (!grouped) {
              acc.set(client._id, {
                client_id: client._id,
                amount: Math.abs(current.gross_total),
                name: client?.name,
              });
            } else {
              acc.set(client._id, {
                ...grouped,
                amount: grouped.amount + Math.abs(current.gross_total),
              });
            }

            return acc;
          }, new Map())
          .values(),
      ];

      const top10HighestDebts = reduceDebtsClient
        .sort((first, second) => second.amount - first.amount)
        .slice(0, 9);

      setTopHighestDebts([...top10HighestDebts]);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAllInvoices();
  }, []);

  const getElementAtEvent = (selectClient) => {
    if (selectClient) {
      const invoiceFilters = {
        client: [selectClient.client_id],
        state: ['overdue', 'validated'],
        type: ['invoice', 'credit_note', 'pre_payment'],
      };
      history.push(
        queryString.stringifyUrl(
          { url: 'invoices/list', query: invoiceFilters },
          {
            arrayFormat: 'index',
          }
        )
      );
    }
  };

  return (
    <AppDashboardCard
      title={t('dasboard.top_10_depts')}
      titleColor={theme.palette.secondary.dark}
    >
      {error && <p>{error}</p>}
      {!loading ? (
        <Box className={classes.container}>
          <List style={{ padding: 0 }}>
            {top10HighestDebtsData?.length > 0 &&
              top10HighestDebtsData.map((clients, index) => {
                return (
                  <AppDashboardListItem
                    key={index}
                    titleLeft={clients?.name}
                    titleRight={`${clients?.amount}€`}
                    onClick={() => getElementAtEvent(clients)}
                  />
                );
              })}
          </List>
          {agingBalance?.agingBalanceComing.data && (
            <FiveBiggestCard
              titleLeft={`${t('agingBalance.coming.fiveBiggest')} :`}
              titleRight={`${agingBalance?.agingBalanceComing?.data?.fiveBiggest} €`}
              bgColor={theme.palette.secondary[50]}
              textColor={theme.palette.secondary.dark}
              onClick={() => {
                const invoiceFilters = {
                  state: ['paid', 'validated'],
                  type: ['invoice', 'credit_note', 'pre_payment'],
                  paymentAfter: format(new Date(), 'yyyy-MM-dd'),
                  pageSize: 5,
                  sortByPrice: true,
                };
                navigatePage('invoices/list', invoiceFilters);
              }}
            />
          )}
        </Box>
      ) : (
        <CircularProgressCentered />
      )}
    </AppDashboardCard>
  );
};

export default DebtsOverview;
