import React, { useState } from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PropTypes from 'prop-types';
import cl from 'classnames';
import './styles.css';

export default function QuestionMarkInformation({
  title,
  content,
  className = '',
  ...props
}) {
  const [show, setShow] = useState(false);

  return (
    <div className={cl('fiscalInfo', className)} {...props}>
      <HelpOutlineIcon
        className="fiscalInfo__icon"
        onClick={() => setShow(true)}
      />
      <Dialog
        open={show}
        modal={true}
        onClose={() => setShow(false)}
        className="fiscalInfo__dialog"
      >
        <DialogTitle disableTypography className="dialog__title">
          <h2>{title}</h2>

          <CloseIcon onClick={() => setShow(false)} className="dialog__icon" />
        </DialogTitle>
        <DialogContent>{content}</DialogContent>
      </Dialog>
    </div>
  );
}

QuestionMarkInformation.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
};
