import React from 'react';
import { WarningSharp } from '@material-ui/icons';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';

import { map, find, sortBy, filter } from 'lodash';

import { Grid, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import SelectAutocomplete from '../../SelectAutocomplete';

const ExpenseInfo = () => {
  const { values, setFieldValue } = useFormikContext();
  const clientProjects = useSelector((state) => state.clientProjects.projects);
  const clients = useSelector((state) => state.clients.data);
  const activities = useSelector((state) => state.activities);
  const { t } = useTranslation();

  const {
    internal_activity: activity,
    client_id: clientId,
    project_id: projectId,
  } = values;

  function changeActivity(activityClicked) {
    if (activityClicked) {
      setFieldValue('internal_activity', activityClicked._id);
    } else {
      setFieldValue('internal_activity', '');
    }
  }

  function setProject(projectClicked) {
    if (projectClicked) {
      setFieldValue('project_id', projectClicked._id);
      setFieldValue('orderNumber', projectClicked.purchaseOrderNumber);
      setFieldValue('grandTotalAmount', projectClicked.ht);
    } else {
      setFieldValue('project_id', '');
    }
  }

  function setClient(clientSelected) {
    if (clientSelected) {
      setFieldValue('client_id', clientSelected._id);
      const projectSelected = clientProjects[clientSelected._id];
      if (projectSelected && projectSelected.length === 1) {
        setFieldValue('project_id', projectSelected[0]._id);
      } else {
        setFieldValue('project_id', null);
      }
    } else {
      setFieldValue('client_id', '');
      setFieldValue('project_id', '');
    }
  }

  function handleProjectSelected() {
    if (clientId && projectId) {
      return find(clientProjects[clientId], (proj) => proj._id === projectId);
    }
    return null;
  }

  const displayProjects = clientProjects[clientId] !== undefined;
  const errorNoExisting = !Object.keys(clients).length;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <SelectAutocomplete
          name={`internal_activity`}
          label={t('expenses.expense.form_activity')}
          getOptionLabel={(elem) => (elem && elem.display) || ''}
          values={map(activities, (ac) => ac)}
          valueSelected={activity ? activities[activity] : null}
          onChange={changeActivity}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectAutocomplete
          name={`client_id`}
          label={t('expenses.expense.form_client')}
          getOptionLabel={(elem) => elem.name || ''}
          values={filter(sortBy(clients, 'name'), (o) => !o.deleted)}
          valueSelected={clientId ? clients[clientId] : null}
          onChange={setClient}
          readOnly={errorNoExisting}
        />
        {errorNoExisting ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <WarningSharp color="error" style={{ marginRight: '5px' }} />
            <Typography color="error">
              {t('expenses.expense.clients.no_clients')}
            </Typography>
          </div>
        ) : null}
      </Grid>
      <Grid item xs={12} sm={4}>
        <SelectAutocomplete
          readOnly={!displayProjects}
          name={`project_id`}
          label={t('expenses.expense.form_project')}
          getOptionLabel={(elem) => elem.name}
          values={displayProjects ? clientProjects[clientId] : []}
          valueSelected={handleProjectSelected()}
          onChange={setProject}
        />
      </Grid>
    </Grid>
  );
};

export default ExpenseInfo;
