import { Subscription as Rights } from '../../config/Rights';
import { hasRequestedAccess } from '../../helpers/RoleHelper';

export const userCanReadSubscription = () => {
  return hasRequestedAccess(Rights.SubscriptionRead);
};

export const userCanUpdateSubscription = () => {
  return hasRequestedAccess(Rights.SubscriptionUpdate);
};
