const style = {
  constructionPage: {
    textAlign: 'center',
    position: 'relative',
    fontSize: 24,
    marginTop: -100,
  },
  constructionPage__script: {
    position: 'absolute',
    left: '50%',
    bottom: '10%',
    transform: 'translateX(-50%)',
  },
};

export default style;
