import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import Layout from 'components/Layout';
import Header from 'components/Header';
import { useRouter } from 'hooks/routerHooks';
import { useParams, useLocation } from 'react-router-dom';
import IncomeTransaction from 'components/Transaction/IncomeTransaction';
import BankinApiService from 'services/BankinApiService';
import CircularProgressCentered from 'components/CircularProgressCentered';
import OutcomeTransaction from 'components/Transaction/OutcomeTransaction';

const Transaction = () => {
  const { history } = useRouter();
  const { id, type: transactionType } = useParams();
  const location = useLocation();

  const documentId = location.state?.documentId;
  const [transaction, setTransaction] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [walletTransaction, setWalletTransaction] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true);
  const companyId = useSelector((state) => state.loggedUserCompany.company._id);

  const getTransaction = async () => {
    const res = await BankinApiService.getTransaction(companyId, id);
    if (res.data && res.data.data) {
      setTransaction(res.data.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!transaction && transactionType === 'bank') {
      getTransaction();
    }
  }, []);

  const renderBody = () => {
    if (loading) {
      return <CircularProgressCentered />;
    }

    if (transaction && transactionType === 'bank' && +transaction?.amount > 0) {
      return (
        <IncomeTransaction
          transaction={transaction}
          transactionType={'bank'}
          getTransaction={getTransaction}
          invoiceId={documentId}
        ></IncomeTransaction>
      );
    }
    if (transaction && transactionType === 'bank' && +transaction?.amount < 0) {
      return (
        <OutcomeTransaction
          transaction={transaction}
          transactionType={'bank'}
          getTransaction={getTransaction}
          expenseId={documentId}
        ></OutcomeTransaction>
      );
    }

    if (
      transactionType === 'wallet' &&
      walletTransaction.transactionType === 'Payin'
    ) {
      return (
        <IncomeTransaction
          transaction={walletTransaction}
          transactionType={'wallet'}
          getTransaction={getTransaction}
          invoiceId={documentId}
        ></IncomeTransaction>
      );
    }
    if (
      transactionType === 'wallet' &&
      walletTransaction.transactionType === 'Payout'
    ) {
      return (
        <OutcomeTransaction
          transaction={walletTransaction}
          transactionType={'wallet'}
          getTransaction={getTransaction}
          expenseId={documentId}
        ></OutcomeTransaction>
      );
    }
    return <p>missing transaction</p>;
  };

  return (
    <Layout
      header={
        <Header
          name={'transaction'}
          spaceBetween
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

export default React.memo(Transaction);
