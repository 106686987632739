/* eslint-disable consistent-return */
import { setGlobalError } from 'actions/GlobalError';
import {
  FETCH_CARD,
  FETCH_CARD_FAILED,
  CREATING_CARD,
  CREATE_CARD_SUCCESS,
  CREATE_CARD_FAILED,
  PROCESSING_LOCK_UNLOCK_CARD,
  LOCK_UNLOCK_CARD_FAILED,
  LOCK_UNLOCK_CARD_SUCCESS,
  UPDATING_CARD_CONFIG,
  UPDATE_CARD_CONFIG_SUCCESS,
  UPDATE_CARD_CONFIG_FAILED,
  FETCH_DETAIL_CARD_SUCCESS,
  FETCHING_DETAIL_CARD,
  FETCH_DETAIL_CARD_FAILED,
} from 'actions/Types';
import service from 'services/TreezorService';
import { selectLoggedUserId } from '../../selectors/loggedUserSelectors';

// eslint-disable-next-line import/prefer-default-export
export const fetchCards = (userId) => async (dispatch, getState) => {
  if (!userId) {
    // eslint-disable-next-line no-param-reassign
    userId = selectLoggedUserId(getState());
  }
  try {
    const card = await service.fetchCards(userId);
    dispatch({
      type: FETCH_CARD,
      payload: card.data,
    });
    return card.data;
  } catch (error) {
    // dispatch(setGlobalError(error));
    dispatch({ type: FETCH_CARD_FAILED, payload: error });
    return false;
  }
};

export const creatingCard = () => ({
  type: CREATING_CARD,
});

export const createCardFailed = (error) => ({
  type: CREATE_CARD_FAILED,
  payload: { error },
});

export const createCardSuccess = (card) => ({
  type: CREATE_CARD_SUCCESS,
  payload: { card },
});

export const createPhysicalCard = (cardData) => async (dispatch) => {
  try {
    dispatch(creatingCard());
    const res = await service.createPhysicalCard(cardData);
    dispatch(createCardSuccess(res.data));
    return res.data;
  } catch (err) {
    dispatch(setGlobalError(err));
    dispatch(createCardFailed(err));
    // if (err.errors) {
    //   dispatch({
    //     type: CREATE_TREEZOR_USER_FAILED,
    //     payload: err.errors[0].errorMessage,
    //   });
    // }
  }
};

export const createVirtualCard = (data) => async (dispatch) => {
  try {
    dispatch(creatingCard());
    const response = (await service.createVirtualCard({ ...data })).data;
    dispatch(createCardSuccess(response));
    return response;
  } catch (err) {
    dispatch(setGlobalError(err));
    dispatch(createCardFailed(err));
  }
};

export const processingLockUnlockCard = () => ({
  type: PROCESSING_LOCK_UNLOCK_CARD,
});

export const lockUnlockCardFailed = (error) => ({
  type: LOCK_UNLOCK_CARD_FAILED,
  payload: { error },
});

export const lockUnlockCardSuccess = (card) => ({
  type: LOCK_UNLOCK_CARD_SUCCESS,
  payload: { card },
});

export const lockUnlockCard = (cardOId, data) => async (dispatch) => {
  try {
    dispatch(processingLockUnlockCard());
    const response = (await service.lockUnlockCard(cardOId, { ...data })).data;
    dispatch(lockUnlockCardSuccess(response));
  } catch (err) {
    dispatch(setGlobalError(err));
    dispatch(lockUnlockCardFailed(err));
  }
};

export const updatingCardConfig = () => ({
  type: UPDATING_CARD_CONFIG,
});

export const updateCardConfigSuccess = (card) => ({
  type: UPDATE_CARD_CONFIG_SUCCESS,
  payload: { card },
});

export const updateCardConfigFailed = (error) => ({
  type: UPDATE_CARD_CONFIG_FAILED,
  payload: { error },
});

export const configCard = (cardOId, data) => async (dispatch) => {
  try {
    dispatch(updatingCardConfig());
    const response = (await service.configCard(cardOId, { ...data })).data;
    dispatch(updateCardConfigSuccess(response));
    return true;
  } catch (err) {
    dispatch(setGlobalError(err));
    dispatch(updateCardConfigFailed(err));
    return false;
  }
};

export const activatePhysicalCard = (cardOId, data) => async (dispatch) => {
  try {
    await service.activatePhysicalCard(cardOId, data);
    // If activate card successully
    dispatch(setGlobalError([]));
  } catch (err) {
    dispatch(setGlobalError(err));
  }
};

export const fetchingDetailCard = () => ({
  type: FETCHING_DETAIL_CARD,
});

export const fetchDetailCardSuccess = (card) => ({
  type: FETCH_DETAIL_CARD_SUCCESS,
  payload: card,
});

export const fetchDetailCardFailed = (error) => ({
  type: FETCH_DETAIL_CARD_FAILED,
  payload: { error },
});

export const fetchDetailCard = (cardOId) => async (dispatch) => {
  try {
    dispatch(fetchingDetailCard());
    const card = await service.fetchDetailCard(cardOId);
    dispatch({
      type: FETCH_DETAIL_CARD_SUCCESS,
      payload: card.data,
    });
    return card.data;
  } catch (err) {
    dispatch(setGlobalError(err));
    dispatch(fetchDetailCardFailed(err));
  }
};

export const getOTP = () => async (dispatch) => {
  try {
    return await service.getOTP();
  } catch (error) {
    dispatch(setGlobalError(error));
    throw error;
  }
};

export const getCardPin = (cardOId, data) => async (dispatch) => {
  try {
    return await service.getCardPin(cardOId, data);
  } catch (error) {
    dispatch(setGlobalError(error));
    throw error;
  }
};
