import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core/';

const InvoiceMapPrePayment = ({ prepayments }) => {
  const { t } = useTranslation();

  return (
    <>
      <Typography gutterBottom variant="h5" component="h2">
        {t('invoices.types.pre_payment')}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="p">
        <span style={{ paddingLeft: '2%' }}>
          {prepayments.map((prepayment, index) => {
            return <span key={index}>{prepayment.amount}</span>;
          })}
        </span>
      </Typography>
    </>
  );
};

InvoiceMapPrePayment.propTypes = {
  prepayments: PropTypes.array,
};

export default InvoiceMapPrePayment;
