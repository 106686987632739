import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appInput';
import asterix from '../../assets/images/asterix.svg';

const AppInput = ({
  placeholder,
  fullWidth,
  setWidthManualy,
  value,
  type,
  onChange,
  classes,
  noBoder,
  color,
  label,
  disabled,
  alignRight,
  alignCenter,
  mandatory,
  useStyle,
  withSideText,
  id,
  name,
  onBlur,
}) => {
  const [labelFocused, changeLabelFocus] = useState(false);
  const [setValue, handleValueChange] = useState('');
  const inputContainerClasses = classNames({
    [classes.inputContainer]: true,
    [classes.fullWidth]: fullWidth,
    [classes[color]]: color,
    [classes[`${color}Caret`]]: color,
    [classes[`${color}WithValue`]]: value && value.length > 0,
    [classes.disabled]: disabled,
    [classes.noBoder]: noBoder,
    [classes.mandatory]: mandatory,
  });
  const inputClasses = classNames({
    [classes.inputField]: true,
    [classes.fullWidth]: true,
    [classes.alignRight]: alignRight,
    [classes.alignCenter]: alignCenter,
  });
  const inputLabel = classNames({
    [classes[`${color}Label`]]: label,
    [classes.showLabel]: label && value && value.length > 0,
    [classes[`${color}LabelFocused`]]: labelFocused,
  });

  const setWidth = setWidthManualy ? { width: setWidthManualy } : null;

  useEffect(() => handleValueChange(value), [value]);

  function placelholderLength() {
    let positionLeft = placeholder.length * 8.5;
    if (placeholder.length < 6) {
      positionLeft = placeholder.length * 8 + 15;
    }
    if (placeholder.length > 11) {
      positionLeft = placeholder.length * 7.5;
    }
    return positionLeft;
  }

  function handleOnBlur(bool) {
    changeLabelFocus(bool);
    if (onBlur) {
      onBlur();
    }
  }

  return (
    <div className={inputContainerClasses} style={{ ...useStyle, ...setWidth }}>
      {!!label && (
        <div className={inputLabel}>
          {label} {mandatory && <span style={{ color: 'red' }}>*</span>}
        </div>
      )}
      <input
        onFocus={() => changeLabelFocus(true)}
        onBlur={() => handleOnBlur(true)}
        className={inputClasses}
        type={type}
        placeholder={placeholder}
        value={setValue}
        onChange={onChange}
        autoComplete="Vasenka"
        disabled={disabled}
        name={name}
        id={id}
      />
      {!!withSideText && <p style={{ paddingRight: '5px' }}>{withSideText}</p>}
      {mandatory && value.length < 1 && (
        <img
          src={asterix}
          alt="Asterix"
          style={{
            position: 'absolute',
            width: '3px',
            top: '10px',
            left: `${placelholderLength()}px`,
          }}
        />
      )}
    </div>
  );
};

AppInput.propTypes = {
  classes: PropTypes.object.isRequired,
  form: PropTypes.object,
  field: PropTypes.object,
  useStyle: PropTypes.object,
  value: PropTypes.any,
  withSideText: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  setWidthManualy: PropTypes.string,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  mandatory: PropTypes.bool,
  noBoder: PropTypes.bool,
  error: PropTypes.bool,
  alignRight: PropTypes.bool,
  alignCenter: PropTypes.bool,
  color: PropTypes.string,
  label: PropTypes.string,
  onBlur: PropTypes.func,
};
AppInput.defaultProps = {
  placeholder: 'Search',
  type: 'text',
  fullWidth: false,
  disabled: false,
  noBoder: false,
  mandatory: false,
  error: false,
  value: '',
  id: '',
  label: '',
  alignRight: false,
  alignCenter: false,
  setWidthManualy: null,
  name: null,
  form: null,
  withSideText: null,
  field: null,
  useStyle: null,
  onChange: null,
  color: 'primary',
};

export default withStyles(style)(AppInput);
