import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import MuiDeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import CurrencyHelper from 'helpers/currencyHelper';
import InputHelper from 'helpers/inputHelper';
import { getCorseVatRates } from 'helpers/CategoryHelpers';
import { isCorseCompany } from 'actions/CompanyActions/CompanyHelpers';
import { isEuClient } from 'helpers/ClientHelpers';
import { useDecimalCharacter } from 'hooks/decimalCharacterHooks';

import {
  AppTableRow,
  AppTableCell,
} from '../../../../../../../components/AppTableComponents';
import AppInput from '../../../../../../../components/AppInput';
import TextFieldWithAdornment from '../../../../../../../components/FormElements/TextFieldWithAdornment';

import { useInvoiceItemTotal } from '../../../../../../../hooks/invoiceHooks';

import NumberFormat from '../../../../../../../helpers/NumberFormat';
import { typeHelpers } from '../../../../../../../helpers/invoiceHelpers';

import colors from '../../../../../../../constants/colors.json';

const useStyles = makeStyles(() => ({
  deleteIcon: {
    paddingTop: '3px',
    height: '15px',
    color: colors.grey,
  },
  '@media (max-width: 375px)': {
    hideOnMobile: {
      display: 'none',
    },
    width25Perct: {
      width: '35%',
    },
    hoverShow: {
      visibility: 'visible',
      '&:nth-child(2)': {
        marginLeft: '5px',
      },
    },
  },
  '@media (min-width: 376px)': {
    width25Perct: {
      width: '25%',
    },
    hoverShow: {
      visibility: 'hidden',
      marginLeft: '10px',
    },
  },
  smallInput: {
    fontSize: '12px',
  },
  textFiledStyle: {
    width: '100%',
    padding: '0 5px',
    margin: 0,
  },
}));

const ItemRow = ({ item, index }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();
  const vatRates = useSelector((state) => state.vatRates);
  const language = useSelector((state) => state.guiLanguage);
  const { company } = useSelector((state) => state.loggedUserCompany);

  const { decimalChar, exceptDecimalChar } = useDecimalCharacter();

  // const isApplyVAT = useSelector(
  //   (state) => state.loggedUserCompany.company.settings.tax_registration.tva
  // );

  // useEffect(() => {
  //   if (!isApplyVAT) {
  //     setFieldValue(`items[${index}].vat_rate`, 0);
  //   }
  // }, []);

  useInvoiceItemTotal(
    ({ netPrice, grossPrice }) => {
      setFieldValue(`items[${index}].net_price`, netPrice, false);
      setFieldValue(`items[${index}].gross_price`, grossPrice, false);
    },
    item,
    decimalChar
  );

  function deleteItem() {
    setFieldValue(
      'items',
      values.items.filter((_, i) => i !== index)
    );
  }

  function onNameChanged(e) {
    const { value } = e.target;
    setFieldValue(`items[${index}].name`, value);
  }

  function onDescriptionChanged(e) {
    const { value } = e.target;
    setFieldValue(`items[${index}].description`, value);
  }

  function onUnitPriceChanged(e) {
    // const { value } = e.target;

    // let price = value;

    // if (value && value < 0) {
    //   price = values.items[index].unit_price;
    // } else {
    //   price = parseFloat(value, 10);
    // }

    // setFieldValue(`items[${index}].unit_price`, parseFloat(price));
    const val = e.target.value;

    const formatValue = InputHelper.getNumberFormatInput(
      val,
      decimalChar,
      exceptDecimalChar,
      language
    );

    if (formatValue === null) return;

    setFieldValue(`items[${index}].unit_price`, formatValue);
  }

  function onQuantityChanged(e) {
    const { value } = e.target;

    let quantity = value;

    if (value && value < 0 && !typeHelpers.isCreditNote(values.type)) {
      quantity = 0;
    } else if (value && value > 0 && typeHelpers.isCreditNote(values.type)) {
      quantity = parseFloat(-value);
    } else {
      quantity = parseFloat(value);
    }

    setFieldValue(`items[${index}].quantity`, quantity);
  }

  function onDiscountChanged(e) {
    const { value } = e.target;
    if (!value || value < 0) {
      setFieldValue(`items[${index}].discount`, 0);
    } else {
      setFieldValue(`items[${index}].discount`, parseFloat(value));
    }
  }

  function onVATChanged(e) {
    const { value } = e.target;
    setFieldValue(`items[${index}].vat_rate`, value);
  }

  const getVatRates = () => {
    if (isCorseCompany(company)) {
      return getCorseVatRates();
    }
    return vatRates;
  };

  useEffect(() => {
    if (isEuClient(values?.client)) {
      setFieldValue(`items[${index}].vat_rate`, 0);
    }
  }, []);

  if (item.name == null) {
    return (
      <AppTableRow key={index}>
        <AppTableCell colSpan={8}>
          <AppInput
            placeholder={t(`item.description`)}
            type="text"
            onChange={onDescriptionChanged}
            value={item.description}
          />
        </AppTableCell>
        <AppTableCell>
          <MuiDeleteIcon className={classes.hoverShow} onClick={deleteItem} />
        </AppTableCell>
      </AppTableRow>
    );
  }

  return (
    <AppTableRow
      key={index}
      useStyle={{
        padding: '0',
        borderBottom: '1px solid #D7D7D7',
        width: '100%',
        tableLayout: 'fixed',
      }}
    >
      <AppTableCell setWidthManualy="15%">
        <TextField
          className={classes.textFiledStyle}
          type="text"
          color="secondary"
          name="name"
          placeholder={t('item.name')}
          value={item.name}
          onChange={onNameChanged}
          InputProps={{ className: classes.smallInput }}
        />
      </AppTableCell>
      <AppTableCell setWidthManualy="10%">
        <TextField
          className={classes.textFiledStyle}
          InputProps={{ className: classes.smallInput }}
          type="text"
          color="secondary"
          name="decription"
          placeholder={t('item.description')}
          onChange={onDescriptionChanged}
          value={item.description}
        />
      </AppTableCell>
      <AppTableCell setWidthManualy="15%">
        {/* <CurrencyTextField
          inputStyle={{ fontSize: '12px' }}
          value={parseFloat(item.unit_price).toFixed(2)}
          onChange={onUnitPriceChanged}
          placeholder={t('item.unit_price')}
          name="unit_price" 
          type="number"
          color="secondary"
        /> */}
        <TextField
          className={classes.textFiledStyle}
          fullWidth
          type="text"
          value={CurrencyHelper.handleDisplayCurrency(
            item.unit_price,
            decimalChar
          )}
          placeholder={language === 'fr' ? '0,00' : '0.00'}
          onChange={onUnitPriceChanged}
          InputProps={{
            endAdornment: <InputAdornment position="start">€</InputAdornment>,
            className: classes.smallInput,
          }}
        />
      </AppTableCell>
      <AppTableCell setWidthManualy="8%" alignRight>
        <TextField
          className={classes.textFiledStyle}
          InputProps={{ className: classes.smallInput }}
          type="number"
          color="secondary"
          name="quantity"
          placeholder={t('item.quantity')}
          value={item.quantity}
          onChange={onQuantityChanged}
        />
      </AppTableCell>
      <AppTableCell setWidthManualy="100px">
        <TextFieldWithAdornment
          className={classes.textFiledStyle}
          InputProps={{
            className: classes.smallInput,
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          type="number"
          color="secondary"
          name="discount"
          placeholder={t('item.discount')}
          onChange={onDiscountChanged}
          value={item.discount}
        />
      </AppTableCell>
      <AppTableCell setWidthManualy="15%" alignCenter>
        {`${NumberFormat.formatNumberByNation(item.net_price, language)}€`}
      </AppTableCell>
      <AppTableCell setWidthManualy="70px" alignRight>
        <FormControl className={classes.formControl}>
          <Select
            style={{ fontSize: '12px' }}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={item.vat_rate}
            onChange={onVATChanged}
          >
            {getVatRates().map((element, i) => (
              <MenuItem key={i} value={element.value}>
                {element.display}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </AppTableCell>

      <AppTableCell setWidthManualy="15%" alignCenter>
        {`${NumberFormat.formatNumberByNation(item.gross_price, language)}€`}
      </AppTableCell>
      <AppTableCell alignCenter setWidthManualy="40px">
        <MuiDeleteIcon className={classes.deleteIcon} onClick={deleteItem} />
      </AppTableCell>
    </AppTableRow>
  );
};

ItemRow.propTypes = {
  item: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default ItemRow;
