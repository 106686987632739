// eslint-disable-next-line import/prefer-default-export
import React from 'react';
import { TablePagination } from '@material-ui/core';
import TablePaginationActions from 'components/MaterialTable/TablePaginationActions';
import {
  getSortState,
  getFilterState,
  getPageState,
} from './mui-datatableHelper';

export const getMuiDatatableOptions = (t, count, options = {}, type = null) => {
  return {
    customFooter: (
      countPage,
      page,
      rowsPerPage,
      changeRowsPerPage,
      changePage
    ) => {
      return (
        <TablePagination
          count={countPage}
          page={page}
          component={'div'}
          onPageChange={(e, pageNumber) => changePage(pageNumber)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(e) => changeRowsPerPage(e.target.value)}
          labelRowsPerPage={t('tables.row_per_page')}
          labelDisplayedRows={({ count: countNum, from, to }) => {
            return (
              <p>
                {from}-{to} {t('tables.of')} {countNum}
              </p>
            );
          }}
          ActionsComponent={TablePaginationActions}
        />
      );
    },
    elevation: 0,
    filter: true,
    selectableRows: 'none',
    filterType: 'dropdown',
    // responsive: 'scroll',
    rowsPerPage: 10,
    UNSAFE_componentWillReceiveProps: true,
    page: 0,
    count,
    // sortColumnIndex: 3,
    // sortColumnDirection: 'asc',
    download: false,
    onDownload: () => {},
    print: true,
    ...options,
    onTableChange: (action, tableState) => {
      if (action !== 'propsUpdate') {
        if (action === 'changePage') {
          if (options.callbackOnPageChange) {
            options.callbackOnPageChange(tableState.page);
          }
        } else {
          const muiDataTableFilters = {
            sort: getSortState(tableState),
            filter: getFilterState(tableState),
            page: getPageState(tableState),
          };
          if (type) {
            localStorage.setItem(
              `store${type}MuiTable`,
              JSON.stringify(muiDataTableFilters)
            );
          }
        }
      }
    },
    textLabels: {
      body: {
        noMatch: t('tables.nomatch'),
        toolTip: t('tables.sort'),
        columnHeaderTooltip: (column) =>
          `${t('tables.sortfor')} ${column.label}`,
      },
      pagination: {
        next: t('tables.next_page'),
        previous: t('tables.previous_page'),
        rowsPerPage: t('tables.row_per_page'),
        displayRows: t('tables.of'),
      },
      toolbar: {
        search: t('tables.search'),
        downloadCsv: t('tables.download'),
        print: t('tables.print'),
        viewColumns: t('tables.view_columns'),
        filterTable: t('tables.filter_table'),
      },
      filter: {
        all: t('tables.all'),
        title: t('tables.filters'),
        reset: t('tables.reset'),
      },
      viewColumns: {
        title: t('tables.show_columns'),
        titleAria: t('tables.show_columns_aria'),
      },
      selectedRows: {
        text: t('tables.selected_rows'),
        delete: t('tables.delete'),
        deleteAria: t('tables.delete_aria'),
      },
    },
  };
};

export const uselessVariable = {};
