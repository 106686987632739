import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  // DialogTitle,
  TextField,
  Box,
} from '@material-ui/core';
import OTPIcon from 'assets/images/otp.svg';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  otpIcon: {
    display: 'block',
    width: 120,
    margin: '10px auto',
  },
  title: {
    fontWeight: 600,
    fontSize: 20,
  },
  submitBtn: {
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
  },
  otpInput: {
    marginRight: 10,
  },
});

export default function OTPDialog({ open, handle, submit, resend }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [otp, setOTP] = useState('');

  const handleChange = (event) => {
    setOTP(event.target.value);
  };

  const handleClose = () => {
    handle(false);
    setOTP('');
  };

  const handleSubmit = () => {
    submit(otp);
  };

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogContent>
          <img src={OTPIcon} className={classes.otpIcon} alt="OTP Icon" />
          <DialogContentText className={classes.title}>
            {t('otp-title')}
          </DialogContentText>
          <DialogContentText>{t('otp-message')}</DialogContentText>
          <Box display="flex" flexDirection="row" alignItems="center">
            <TextField
              variant="outlined"
              className={classes.otpInput}
              value={otp}
              onChange={handleChange}
            />
            <Button color="primary" onClick={resend}>
              {t('otp-resend')}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            {t('otp-cancel')}
          </Button>
          <Button
            color="primary"
            variant="contained"
            className={classes.submitBtn}
            onClick={handleSubmit}
          >
            {t('otp-submit')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

OTPDialog.propTypes = {
  open: PropTypes.bool,
  handle: PropTypes.func,
  submit: PropTypes.func,
  resend: PropTypes.func,
};
