const capitalize = (str) => {
  const arr = str ? str.split(' ') : [];
  const capitalizedArr = [];

  arr.forEach((e) => {
    capitalizedArr.push(
      `${e.charAt(0).toUpperCase()}${e.slice(1).toLowerCase()}`
    );
  });
  return capitalizedArr.join(' ');
};

export default capitalize;
