import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appTableComponents';
import AppTableColGroup from './AppTableColGroup';

const AppTable = ({ classes, children }) => {
  const tableClasses = classNames({
    [classes.table]: true,
  });

  const columns = children[0] && children[0].props.children.props.children;
  return (
    <table className={tableClasses}>
      <AppTableColGroup columns={columns} />
      {children}
    </table>
  );
};
AppTable.propTypes = {
  classes: PropTypes.object.isRequired,

  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  header: PropTypes.bool,
};
AppTable.defaultProps = {
  header: false,
};

export default withStyles(style)(AppTable);
