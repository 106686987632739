import * as yup from 'yup';
import { format, addMonths, formatISO, isSameYear, isBefore } from 'date-fns';
import mentionsHelper from '../helpers/MentionsHelper';
import {
  typeHelpers,
  invoiceHasDifferentBillingAddress,
  invoiceHasdifferentClientBillingAddress,
} from '../helpers/invoiceHelpers';
import { isInsideUE, isDomTom } from '../helpers/countriesHelper';
import { sortAscending } from '../helpers/VATRateHelpers';

const getCompanyBankDetails = (type, company) => {
  if (!typeHelpers.isCreditNote(type) && company.bank_details) {
    const defaultBanks =
      company.bank_details.find((bank) => bank?.isDefault) ||
      company.bank_details[0];

    return defaultBanks;
  }
  return null;
};

const preparePayments = (plusMonths = 0, amount = 0) => {
  return {
    date: format(new Date(addMonths(new Date(), plusMonths + 1)), 'yyyy-MM-dd'),
    amount,
  };
};

const preparePayModes = (modes = null) => {
  return (
    modes || {
      display: '',
      bridgePaymentLink: false,
      hiPay: false,
      paypal: false,
      check: false,
      cash: false,
      bankTransfer: false,
      lcr: false,
    }
  );
};
const addExtraAttributes = (inv = {}) => {
  return {
    address: inv.client ? inv.client.address : null,
    clientRef: inv.client ? inv.client.reference : null,
    action: null,
  };
};

const prepareSpecialMentions = (company) => {
  return company.settings && company.settings?.tax_registration
    ? mentionsHelper(company.settings.tax_registration)
    : [];
};

const prepareDefaultAttributes = (company, inv = {}, templates = []) => {
  let template = '';

  if (inv.template) {
    template = inv.template;
  } else if (templates.length) {
    // eslint-disable-next-line prefer-destructuring
    template = templates[0]._id;
  }
  return {
    prepayments: inv.prepayments || [],
    toPay: inv.toPay || null,
    duePayableAmount: inv.duePayableAmount || null,
    template,
    special_mentions: inv.special_mentions || prepareSpecialMentions(company),
    vat_details: [],
    gross_total: inv.gross_total || 0,
    net_total: inv.net_total || 0,
    vat_total: inv.vat_total || 0,
    description: inv.description || '',
  };
};

const prepareItems = (items = {}, type) => {
  if (!items) {
    return [];
  }

  return items.rows.map((item) => ({
    _id: item._id,
    type: item.item.type,
    name: item.item.name,
    description: item.item.description,
    gross_price: item.gross_price,
    vat_rate: item.item.vat_rate,
    unit: item.item.unit,
    net_price: item.net_price,
    quantity: item.quantity,
    discount: parseFloat(item.discount),
    unit_price: item.item.unit_price,
    vat_price: typeHelpers.isCreditNote(type)
      ? item.gross_price + item.net_price
      : item.gross_price - item.net_price,
  }));
};

const createTempInvoice = (invoice, company) => {
  const {
    _id,
    payments,
    paying_mode: modes,
    items,
    rejectMessage,
    ...inv
  } = invoice;

  return {
    ...inv,
    invoice_nb: null,
    reference: null,
    signature: null,
    parent: null,
    child: null,
    paymentLink: null,
    stripePaymentLink: null,
    isUsePaymentLink: false,
    date: formatISO(new Date()),
    state: 'draft',
    payments: payments.map(({ amount }, index) =>
      preparePayments(index, amount)
    ),
    items: prepareItems(items, inv.type),
    paying_mode: preparePayModes(modes),
    itemMessage: '',
    ...prepareDefaultAttributes(company, inv),
    ...addExtraAttributes(inv),
  };
};

const createExistedInvoice = (invoice, company) => {
  const {
    date_emission: dateEmission,
    paying_mode: payingMode,
    items,
    ...inv
  } = invoice;
  const payments = {
    bridgePaymentLink: payingMode.bridgePaymentLink || false,
    hiPay: payingMode.hiPay || false,
    paypal: payingMode.paypal || false,
    check: payingMode.check || false,
    cash: payingMode.cash || false,
    bankTransfer: payingMode.bankTransfer || false,
    lcr: payingMode.lcr || false,
    display: payingMode.display || '',
  };
  const modes = preparePayModes(payments);
  return {
    ...inv,
    isUsePaymentLink: inv?.isUsePaymentLink ?? false,
    paymentLink: inv?.paymentLink || null,
    stripePaymentLink: inv?.stripePaymentLink || null,
    hasDifferentBillingAddress: invoiceHasDifferentBillingAddress(invoice),
    clientHasDifferentBillingAddress:
      invoiceHasdifferentClientBillingAddress(invoice),
    date: formatISO(new Date(dateEmission)),
    items: prepareItems(items, inv.type),
    paying_mode: modes,
    reference: inv.reference,
    itemMessage: '',
    ...prepareDefaultAttributes(company, inv),
    ...addExtraAttributes(inv),
  };
};

const createNewInvoice = (company, locationState, templates) => {
  const { _id: companyId } = company;

  const type =
    locationState && locationState.type ? locationState.type : 'invoice';
  let isPaypal = false;
  if (company.paypal && company.paypal.client_id) {
    isPaypal = true;
  }
  const modes = {
    bridgePaymentLink: false,
    hiPay: false,
    paypal: false,
    check: false,
    cash: false,
    bankTransfer: false,
    lcr: false,
  };
  return {
    hasDifferentBillingAddress: false,
    clientHasDifferentBillingAddress: false,
    company_id: companyId,
    company: {
      ...company,
      logo: company.filename,
      bank_details: getCompanyBankDetails(type, company),
    },
    date: format(new Date(), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx"),
    client: {},
    payments: [preparePayments()],
    items: [],
    delivery_fee: null,
    state: 'draft',
    type,
    reference: '',
    rcs: '',
    rcsType: 'RCS',
    itemMessage: '',
    isUsePaymentLink: false,

    paymentLink: null,
    stripePaymentLink: null,

    paying_mode: preparePayModes(modes, isPaypal),
    ...prepareDefaultAttributes(company, {}, templates),
    ...addExtraAttributes(),
  };
};

export const updateMentions = (company, client) => {
  const settings = { ...company.settings?.tax_registration };

  if (Object.entries(client).length !== 0) {
    settings.clientIsFrench =
      client.country === '' ? true : client.country === 'FR';
    settings.clientIsInsideUE = isInsideUE(
      client.country !== '' ? client.country : 'FR'
    );
    settings.clientCountry = client.country;
    settings.clientIsInDomTom = isDomTom(client.country);
    settings.clientHasNumIntraCom = client.intracom_vat !== '';
    settings.isCompany = client.isCompany;
  }
  return mentionsHelper(settings);
};

export const initializeInvoice = (
  company,
  invoices,
  id,
  tempInvoice,
  locationState,
  templates,
  wallet
) => {
  if (tempInvoice) {
    const invoice = { ...tempInvoice };
    return createTempInvoice(invoice, company);
  }

  if (id && invoices[id]) {
    const invoice = { ...invoices[id] };
    return createExistedInvoice(invoice, company);
  }

  return createNewInvoice(company, locationState, templates, wallet);
};

export const prepareInvoiceForSubmission = (
  values,
  loggedUser,
  companyWallet
) => {
  const {
    items,
    delivery_fee: deliveryFee,
    vat_details: vatDetails,
    supervisor,
    client,
    rcs,
    rcsType,
    paying_mode: payingMode,
    ...restValues
  } = values;

  const { debitCard } = payingMode;

  let treezorWalletId;
  let treezorUserId;
  if (companyWallet && companyWallet.length > 0) {
    const [wallet] = companyWallet;
    treezorWalletId = wallet.walletId;
    treezorUserId = wallet.userId;
  }

  const date = formatISO(new Date(values.date));

  const itemRows = items.map((item) => ({
    // add feeconsole.log('mpike2');
    item: {
      name: item.name,
      type: item.type,
      description: item.description,
      unit: item.unit,
      unit_price: item.unit_price,
      vat_rate: item.vat_rate,
    },
    quantity: item.quantity,
    discount: item.discount,
    gross_price: item.gross_price,
    net_price: item.net_price,
  }));

  const vatArray = vatDetails.map(({ vat, totalVAT, net }) => ({
    vat,
    value: net,
    vat_amount: totalVAT,
  }));

  return {
    ...restValues,
    paying_mode: payingMode,
    delivery_fee: typeHelpers.isCreditNote(values.type)
      ? 0
      : parseFloat(deliveryFee),
    date_emission: date,
    date_budget: date,
    client:
      client !== ''
        ? {
            ...client,
            reference: values.clientRef,
          }
        : {
            reference: values.clientRef,
          },
    items: {
      rows: itemRows,
      subtotals: [],
    },
    vat_details: vatArray.sort(sortAscending),
    supervisor: supervisor || loggedUser,
    rcs: `${rcsType} ${rcs}`,
    ...(debitCard &&
      treezorWalletId &&
      treezorUserId && {
        treezorWalletId,
        treezorUserId,
      }),
  };
};

export const validateInvoiceForm = () =>
  yup.object().shape({
    action: yup.string().nullable(),
    date: yup.date().when('action', {
      is: (action) => action && action !== 'draft',
      then: yup
        .date()
        .required('La date est obligatoire')
        .test(
          'maxDate',
          'Une date future est interdite',
          (value) =>
            isBefore(new Date(value), new Date()) ||
            isSameYear(new Date(value), new Date())
        ),
    }),
    client: yup.object().required('Le client est obligatoire'),
    description: yup.string(),
    items: yup.array().required('Les articles sont obligatoires'),
    prepayments: yup.array().test(
      'prepayments-test',
      'La somme des acomptes doit être au maximum égale au montant total',
      /* eslint-disable-next-line */
      function (value) {
        if (typeHelpers.isCreditNote(this.parent.type)) {
          return true;
        }

        const grossTotal = this.parent.gross_total;
        const total = value.reduce((acc, { amount }) => acc + amount, 0);

        return total <= grossTotal;
      }
    ),
  });
