import React, { useState } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';

import { header } from 'assets/jss/components/appText';
import style from '../../assets/jss/components/appPlusButton';
import '../../assets/css/icons.css';

const AppPlusButton = ({ onClick, classes, label }) => {
  const [isShown, setIsShown] = useState(false);

  const containerClasses = classNames({
    [classes.appPlusSelectContainer]: true,
  });
  const buttonClasses = classNames({
    [classes.plusButton]: true,
  });
  const iconClasses = classNames({
    [classes.iconStyle]: true,
    icon: true,
    'icon-add': true,
    [classes.cursorPointer]: true,
  });
  const textClasses = classNames({
    [classes.textStyle]: true,
  });

  return (
    <div
      className={containerClasses}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: '20px',
      }}
    >
      {!isShown && <p style={header}>{label}</p>}

      <button
        type="button"
        className={buttonClasses}
        onClick={onClick}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
      >
        <p className={textClasses}>{label}</p>
        <span className={iconClasses} />
      </button>
    </div>
  );
};
AppPlusButton.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  label: PropTypes.string,
};
// AppPlusSelect.defaultProps = {};

export default withStyles(style)(AppPlusButton);
