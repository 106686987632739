import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Modal } from '@material-ui/core';

const FormLoading = ({ loading }) => (
  <div>
    <Modal
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={loading}
      onClose={() => null}
    >
      <CircularProgress color="secondary" size={50} />
    </Modal>
  </div>
);

FormLoading.propTypes = {
  loading: PropTypes.bool,
};

export default FormLoading;
