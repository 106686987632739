/* eslint-disable import/prefer-default-export */
import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    borderRadius: '12px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
    keepMounted
    {...props}
  />
));

export { StyledMenu };
