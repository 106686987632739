import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchItems = async (filter) =>
  axios.get(`${process.env.REACT_APP_ITEMS_URL}/items`, {
    params: filter,
  });

const createItem = async (item) =>
  axios.post(`${process.env.REACT_APP_ITEMS_URL}/items`, item);

const updateItem = async (item) =>
  axios.put(`${process.env.REACT_APP_ITEMS_URL}/items/${item._id}`, item);

const deleteItem = async (id) =>
  axios.delete(`${process.env.REACT_APP_ITEMS_URL}/items/${id}`);

export default {
  fetchItems,
  createItem,
  updateItem,
  deleteItem,
};
