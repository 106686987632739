import { CompanySettings as Rights } from '../../config/Rights';
import { CompanySettings as Feature } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanUpdateCompanySettings = () => {
  return hasRequestedAccess(Rights.CompanySettingsUpdate);
};

export const userHasCompanySettingsAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
