import React from 'react';
import PropTypes from 'prop-types';

class SmallBox extends React.Component {
  constructor(props) {
    super(props);
    this.handleEdit = this.handleEdit.bind(this);
  }

  handleEdit() {
    this.props.handleEdit(this.props.refEdit);
  }

  render() {
    return (
      <div className="small-box" style={{ display: this.props.display }}>
        <button type="button" onClick={this.handleEdit} className="edit">
          <img
            draggable={false}
            width="12px"
            alt="edit-icon"
            src="https://image.flaticon.com/icons/svg/61/61456.svg"
          />
        </button>
      </div>
    );
  }
}

SmallBox.propTypes = {
  handleEdit: PropTypes.func,
  refEdit: PropTypes.object,
  content: PropTypes.string,
  display: PropTypes.string,
};

export default SmallBox;
