export default ({
  users,
  companyAccessRights,
  loggedUser,
  companySubscription,
}) => ({
  users,
  companyAccessRights,
  loggedUser,
  companySubscription,
});
