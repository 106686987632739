import React from 'react';
import PropTypes from 'prop-types';
import Box from '../Box';

class RestApp extends React.Component {
  render() {
    const {
      color,
      companyMentionBottom,
      handleChange,
      overlay,
      onStop,
      company,
      t,
    } = this.props;

    const mention = `Pas d'escompte pour règlement anticipé. En cas de retard de
    paiement, une pénalité égale à 3 fois le taux intérêt légal sera
    exigible (Article L441-6, alinéa 12 du Code de Commerce). Pour tout
    professionnel, en sus des indemnités de retard, toute somme, y
    compris l'acompte, non payée à sa date d'exigibilité produira de
    plein droit le paiement d'une indemnité forfaitaire de 40 euros due
    au titre des frais de recouvrement (Art. 441-6, I al. 12 du code de
    commerce et D. 441-5).`;

    return (
      <div>
        <div id="invoice-table">
          <table className="first-table">
            <thead>
              <tr className="top-table" style={{ backgroundColor: color }}>
                <th style={{ maxWidth: '50mm' }}>Libellé</th>
                <th>Qté</th>
                <th>Unité</th>
                <th>PU HT</th>
                <th>Rem.</th>
                <th>Montant HT</th>
                <th>TVA</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  planche0001 / Planche <br />
                  planche de bois
                </td>
                <td>12</td>
                <td>pièce</td>
                <td>12€</td>
                <td>0 %</td>
                <td className="td-right">144€</td>
                <td className="td-right">20 %</td>
              </tr>

              <tr>
                <td>
                  buche0002 / buche <br />
                  stere de buche
                </td>
                <td>5</td>
                <td>stere</td>
                <td>50€</td>
                <td>0 %</td>
                <td className="td-right">250€</td>
                <td className="td-right">20 %</td>
              </tr>

              <tr>
                <td>
                  clou0002 / clou <br />
                  clou en acier
                </td>
                <td>500</td>
                <td>pièce</td>
                <td>0,10€</td>
                <td>0 %</td>
                <td className="td-right">50€</td>
                <td className="td-right">20 %</td>
              </tr>
            </tbody>
          </table>
        </div>
        <footer style={{ marginTop: '20px' }}>
          <hr />
          <table style={{ width: '80mm', float: 'left' }}>
            <tbody>
              <tr>
                <th className="top-table" style={{ backgroundColor: color }}>
                  Détail de la TVA
                </th>
              </tr>
            </tbody>
          </table>

          <table style={{ width: '60mm', float: 'right' }}>
            <tbody>
              <tr id="total_ht">
                <td>{t('invoices.total_net')}</td>
                <td style={{ textAlign: 'right' }}>444€</td>
                <td />
              </tr>

              <tr>
                <td>{t('invoices.vat_amount')}</td>
                <td style={{ textAlign: 'right' }}>88,8€</td>

                <td />
              </tr>

              <tr id="total_ttc" style={{ color }}>
                <td>{t('invoices.total_gross')}</td>
                <td style={{ textAlign: 'right' }}>532,8€</td>

                <td />
              </tr>
            </tbody>
          </table>

          <table style={{ width: '80mm' }}>
            <tbody>
              <tr>
                <td>{t('invoices.base_net')}</td>
                <td>{t('expenses.expense.vatrate')}</td>
                <td>{t('expenses.expense.total')}</td>
              </tr>
              <tr>
                <td>444€</td>
                <td>20 %</td>
                <td>88,8€</td>
              </tr>
            </tbody>
          </table>

          <table style={{ width: '80mm', borderCollapse: 'collapse' }}>
            <tbody>
              <tr>
                <th className="top-table" style={{ backgroundColor: color }}>
                  {t('invoices.payments')}
                </th>
                <td>{t('invoices.check')}</td>

                <td />
              </tr>

              <tr>
                <th className="top-table" style={{ backgroundColor: color }}>
                  {t('invoices.change_due_date')}
                </th>

                <td>532,8€ au 01/01/2019</td>

                <td />
              </tr>
            </tbody>
          </table>

          <table style={{ width: '120mm', float: 'left', marginTop: 10 }}>
            <tbody>
              <tr>
                <th
                  className="top-table"
                  style={{
                    backgroundColor: color,
                  }}
                >
                  {t('invoices.bank_details')}
                </th>
              </tr>
            </tbody>
          </table>
          <table style={{ width: '120mm', clear: 'both' }}>
            <tbody>
              <tr>
                <td>Nom</td>
                <td>Ma Banque</td>
              </tr>
              <tr>
                <td>IBAN</td>
                <td>FRXX XXXX XXXX XXXX XXXX XX</td>
              </tr>
              <tr>
                <td>BIC</td>
                <td>AAAA BB CC XXX</td>
              </tr>
            </tbody>
          </table>

          <Box
            draggable={false}
            info={companyMentionBottom}
            onStop={onStop}
            overlay={overlay}
            style={{ position: 'relative', marginTop: 5 }}
            id="company_mention"
            onChange={handleChange}
            name={'companyMentionBottom'}
            content="company mention"
          >
            <div className="mention">{mention}</div>
          </Box>
        </footer>
        <div
          id="bottom"
          style={{
            position: 'absolute',
            bottom: 15,
            width: '100%',
            textAlign: 'center',
            lineHeight: 'normal',
          }}
        >
          {`Code NAF (APE): ${company.code_ape} · NºRCS: ${company.siren} ·
          ${company.rcs}`}
          <br />
          {`${company.legal_form} au capital social de ${
            company.capital ? company.capital : 'x'
          }€ · `}
          {`Siret: ${company.siren}${company.nic}${
            company.intracomvat ? ` · NºTVA: ${company.intracomvat}` : ''
          }`}
        </div>

        <div
          style={{
            position: 'absolute',
            bottom: 5,
            right: 0,
            marginRight: '10px',
            fontSize: '7px',
            float: 'right',
            color: 'grey',
          }}
        >
          page 1/1
        </div>
      </div>
    );
  }
}

RestApp.propTypes = {
  color: PropTypes.string,
  companyMentionBottom: PropTypes.object,
  companyNameBottom: PropTypes.object,
  companyEmailBottom: PropTypes.object,
  companyApeBottom: PropTypes.object,
  companySiretBottom: PropTypes.object,
  handleChange: PropTypes.func,
  overlay: PropTypes.func,
  onStop: PropTypes.func,
  company: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default RestApp;
