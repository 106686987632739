import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import classNames from 'classnames';
import { formulaDescriptions } from 'containers/Onboarding/utils';
import { INACTIVE } from 'constants/subscriptions';
import useStyles from './styles';

const PricingColumn = ({
  handleChoosePricing,
  title,
  descriptions,
  price,
  dataName,
  buttonText,
  icon,
  monthly,
  isValidate = false,
  dataSubscription,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const featuresData = formulaDescriptions[dataName];

  const subscriptionIsInActive = () => {
    return dataSubscription?.status === INACTIVE;
  };
  return (
    <div
      className={
        subscriptionIsInActive()
          ? classes.priceColumnInactive
          : classes.priceColumn
      }
    >
      <div>
        <div>
          <img className={classes.image} src={icon} />
        </div>
        <div className={classes.titleContent}>
          <div
            className={classNames(classes.title, classes.validateTitleFontSize)}
          >
            {t(`onboarding.${title}`)}
          </div>
          <span
            className={classNames(
              classes.descriptions,
              classes.validateTextFontSize
            )}
          >
            {t(`onboarding.${descriptions}`)}
          </span>
        </div>
      </div>
      {!isValidate && (
        <div>
          <div className={classes.priceContainer}>
            <span className={classes.price}>{price} € HT</span>
            <span className={classes.perMonth}>
              par
              <br />
              {monthly ? 'mois' : 'an'}
            </span>
          </div>
          <button
            className={classes.button}
            onClick={() => handleChoosePricing(dataSubscription?._id)}
            disabled={subscriptionIsInActive()}
          >
            {t(`onboarding.${buttonText}`)}
          </button>
        </div>
      )}
      <div className={classes.features}>
        <div
          className={classNames(
            classes.featureHeader,
            classes.validateTextFontSize
          )}
        >
          {t(`onboarding.features`)}
        </div>
      </div>
      <div>
        {featuresData.map((data, index) => {
          return (
            <div key={index} className={classes.featuresItem}>
              <CheckCircleOutlineIcon
                fontSize="small"
                className={classes.checkContainer}
              />
              <span
                className={classNames(
                  classes.featuresItemText,
                  classes.validateTextFontSize
                )}
              >
                {t(`onboarding.formula_descriptions.${dataName}.${data}`)}
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

PricingColumn.propTypes = {
  handleChoosePricing: PropTypes.func,
  title: PropTypes.string,
  price: PropTypes.number,
  dataName: PropTypes.string,
  buttonText: PropTypes.string,
  icon: PropTypes.string,
  descriptions: PropTypes.string,
  monthly: PropTypes.bool,
  isValidate: PropTypes.bool,
  dataSubscription: PropTypes.object,
};

export default PricingColumn;
