import logger from 'helpers/logger';

// eslint-disable-next-line import/prefer-default-export
export const prepareData = (values) => {
  logger.log('THIS FORM IS getting FORMIK VALUES', values);
  let vatDetails = [];

  for (let index = 0; index < values.numberOfActiveVats; index += 1) {
    if (index === 0) {
      vatDetails.push({
        amount: values.amount0,
        vat_rate: values.vatrate0,
        vat_amount: values.vat0,
        total: values.total0,
      });
    } else if (index === 1) {
      vatDetails.push({
        amount: values.amount1,
        vat_rate: values.vatrate1,
        vat_amount: values.vat1,
        total: values.total1,
      });
    } else if (index === 2) {
      vatDetails.push({
        amount: values.amount2,
        vat_rate: values.vatrate2,
        vat_amount: values.vat2,
        total: values.total2,
      });
    } else if (index === 3) {
      vatDetails.push({
        amount: values.amount3,
        vat_rate: values.vatrate3,
        vat_amount: values.vat3,
        total: values.total3,
      });
    }
  }

  if (values.noVAT) {
    vatDetails = [
      {
        amount: values.totalForm,
        vat_rate: 0,
        vat_amount: 0,
        total: values.totalForm,
      },
    ];
  }

  const formatedData = {
    company_id: values.companyId,
    user_id: values.userId,
    category_id: values.subCategoryId || values.categoryId,
    turnover: values.turnover,
    date_emission: values.dateEmission,
    paying_mode: values.payingMode,
    description: values.description,
    filename: values.filename,
    state: 'paid',
    grand_total_amount: values.grandTotalAmount,
    vat_details: vatDetails,
  };

  logger.log('THIS FORM IS SUBMITING DATA', formatedData);
  return formatedData;
};
