import React from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import PropTypes from 'prop-types';
import useStyles from './styles';

const CopyContentToClipboard = ({ values = '', title = '' }) => {
  const classes = useStyles();
  const [isCopied, setIsCopied] = React.useState(false);
  const handleOnClick = () => {
    setIsCopied(true);
    setTimeout(function () {
      navigator.clipboard.writeText(values);
      setIsCopied(false);
    }, 1000);
  };
  return (
    <IconButton
      size="small"
      color="primary"
      className={classes.accountInformationCopyButton}
      onClick={() => {
        handleOnClick();
      }}
    >
      <Tooltip
        style={{ cursor: 'pointer' }}
        title={isCopied ? 'Copied!' : `Copy ${title}`}
      >
        <FileCopyIcon className={classes.accountInformationCopyIcon} />
      </Tooltip>
    </IconButton>
  );
};

CopyContentToClipboard.propTypes = {
  values: PropTypes.string,
  title: PropTypes.string,
};

export default CopyContentToClipboard;
