import { useEffect } from 'react';

export default function usePreventEnter() {
  const preventEnterEvent = () => {
    const keyDownHandler = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    };

    document.addEventListener('keydown', keyDownHandler);

    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  };

  useEffect(() => {
    preventEnterEvent();
  }, []);
}
