const regex =
  /(T|\s)[0-9]{2}:[0-9]{2}:[0-9]{2}[.][0-9]{3}(Z|\+|-)([0-9]{2}:[0-9]{2})?/;
// eslint-disable-next-line import/prefer-default-export
export const formatBeforeDate = (dateValue) => {
  let dateFormated = dateValue;

  if (dateFormated.match(regex)) {
    dateFormated = dateFormated.replace(regex, 'T23:59:59.999Z');
  }

  return dateFormated;
};
