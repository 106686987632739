import { bindActionCreators } from 'redux';
import {
  createProvider,
  updateProvider,
  fetchProviders,
} from '../../../actions/ProviderAction';

import { fetchInfoGreffe } from '../../../actions/infoGreffeActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createProvider,
      updateProvider,
      fetchInfoGreffe,
      fetchProviders,
    },
    dispatch
  );
