/* eslint-disable no-unused-vars */
import logger from 'helpers/logger';

// export default async (
export default (values, { setErrors, setStatus, setSubmitting, props }) => {
  logger.debug('submit values', values);

  const upsertCb = (err, id) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      setSubmitting(false);
      setStatus({ success: true });
      if (id) {
        props.history.push(`/expenses/edit/${id}`);
        // setTimeout(() => setErrors({}), 1);
        setErrors({});
      }
    }
  };

  const sendCb = (err) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      setSubmitting(false);
      setStatus({ success: true });
      props.history.push(`/expenses/list`);
    }
  };

  const validateCb = (err) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      setSubmitting(false);
      setStatus({ success: true });
      props.history.push(`/expenses/list`);
    }
  };

  if (['canceled'].includes(values.state) || !props.isMounted) {
    setSubmitting(false);
    props.history.push(`/expenses/list`);
    return;
  }

  let { id } = props.match.params;

  const group = props.location.state ? props.location.state.group : null;

  let newExpense = values;

  if (group) {
    const groupExpenses = props.expenses[group].expenses;
    groupExpenses.push(values);
    newExpense = { values: props.expenses[group], expenses: groupExpenses };

    id = group;
  }

  const expenses = [];
  if (!id) {
    expenses.push(values);
  }

  if (values.draft && !id) {
    const { provider } = values;
    const newValues = { ...values, provider: !provider ? null : provider };
    const newExpenses = expenses.map((item) => ({
      ...item,
      provider: !item.provider ? null : item.provider,
    }));
    // await props.createExpense({ values, expenses }, upsertCb);
    props.createExpense({ values: newValues, expenses: newExpenses }, upsertCb);
    return;
  }

  if (values.draft && id) {
    // await props.updateExpense(id, newExpense, upsertCb);
    props.updateExpense(id, newExpense, upsertCb);
    return;
  }

  if (values.validate && id) {
    // await props.validateExpenses([id], validateCb);
    props.validateExpenses([id], validateCb);
    return;
  }

  if (values.pay && id) {
    // await props.payExpenses([id], validateCb);
    props.payExpenses([id], values.transaction, validateCb);
    return;
  }

  if (values.cancel && id) {
    // await props.cancelExpenses([id], validateCb);
    props.cancelExpenses(
      [{ id, refuseMessage: values?.refuseMessage }],
      validateCb
    );
    return;
  }

  // await props.changeExpenseStatePending(id, { values, expenses }, sendCb);
  props.changeExpenseStatePending(id, { values, expenses }, sendCb);
};
