import React, {
  useEffect,
  useCallback,
  useState,
  Fragment,
  useMemo,
} from 'react';
import { connect, useSelector } from 'react-redux';
import { useLocation, withRouter } from 'react-router-dom';
import { withFormik, Form, Field } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _, { find, map } from 'lodash';
import { TRANSACTION_TYPE } from 'constants/banks';
import {
  filteredTree,
  getSubCategories,
  getTranslateSubCategory,
} from 'helpers/CategoryHelpers';
import { TreezorBeneficiaryTypes } from '@bebusinessfocus/compta-hub-core';
import { Dialog, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import RoleHelper from 'helpers/RoleHelper';

import { EXPENSE_STATE } from 'constants/expense';
import { ROLE } from 'constants/roles';
import CancelExpenseFormDialog from 'containers/ExpensesPage/Components/CancelExpenseFormDialog';
import TextField from 'components/FormElements/textField';
import { BankTransferFormik } from 'components/CardComponents/Treezor/BankTransferForm';
import { AppColumnCenterSpaceBetween } from 'components/AppContainers';
import TransactionDialog from 'components/TransactionDialog';
import CircularProgressCentered from 'components/CircularProgressCentered';
import Layout from '../../../components/Layout';
import AppButton from '../../../components/AppButton';
import Header from '../../../components/Header';
import SelectAutocomplete from '../../../components/SelectAutocomplete';
import TextFieldWithAdornment from '../../../components/FormElements/TextFieldWithAdornment';

import DatePicker from '../../../components/FormElementsDesigned/DatePicker';

import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';

import state from './state';
import dispatch from './dispatch';

import styles from '../../../assets/jss/root';

const AdvanceExpenseForm = ({
  handleSubmit: formikHanldeSubmit,
  t,
  errors,
  values,
  isSubmitting,
  isValid,
  setFieldValue,
  history,
  users,
  fetchExpense,
  expenses,
  match: {
    params: { id: expenseId },
  },
  loggedUser: { user },
  userCanReadAdvanceExpense,
  userCanValidate,
}) => {
  const providers = useSelector((rootState) => rootState.providers);
  const clients = useSelector((rootState) => rootState.clients);
  const companyAccounts = useSelector((reduxState) =>
    reduxState.loggedUserCompany.companies
      ? reduxState.loggedUserCompany.companies
      : {}
  );

  const tree = useSelector((rootState) => rootState.categories.tree || []);
  const categories = useMemo(() => filteredTree(tree, t), [tree]);
  const activities = useSelector((rootState) => rootState.activities);

  const location = useLocation();
  const [loading, setLoading] = useState(!!expenseId);
  const [error, setError] = useState(null);
  const [payees, setPayees] = useState([]);
  const [showTransactionDialog, setShowTransactionDialog] = useState(false);
  const [showApprovalDialog, setApprovalDialogVisibility] = useState(false);
  const [showExpenseCancelDialog, setShowExpenseCancelDialog] = useState(false);

  const { internal_activity: activity } = values;

  function toggleApprovalDialog() {
    setApprovalDialogVisibility(!showApprovalDialog);
  }

  const readOnly =
    [
      EXPENSE_STATE.VALIDATED,
      EXPENSE_STATE.PAID,
      EXPENSE_STATE.CANCELED,
    ].includes(values.state) ||
    (expenseId && user._id !== values.user_id) ||
    (expenseId && !userCanReadAdvanceExpense);
  const isShowSideBar = [
    EXPENSE_STATE.PENDING,
    EXPENSE_STATE.VALIDATED,
  ].includes(values.state);

  const fetchExpenseCallback = useCallback(fetchExpense);

  function handleDateChange(date) {
    setFieldValue('date', date);
  }

  function getPayees() {
    let listPayees = Object.values(users);

    if (
      !RoleHelper.hasRoles([ROLE.ADMINISTRATEUR]) &&
      RoleHelper.hasRoles([ROLE.UTILISATEUR_FRAIS])
    ) {
      listPayees = listPayees.filter((item) => item._id === user?._id);
    }
    setPayees(listPayees);
  }

  function goBack() {
    if (location && location.state) {
      history.push({
        pathname: location?.state?.goBackPath,
        search: location?.state?.goBackQueryParams,
        state: {
          pageTitle: location?.state?.pageTitle,
        },
      });
    } else {
      history.push('/expenses/list');
    }
  }

  function setPayee(userClicked) {
    if (userClicked && userClicked._id) {
      setFieldValue('payee_id', userClicked._id);
    } else {
      setFieldValue('payee_id', '');
    }
  }

  useEffect(() => {
    if (
      payees?.length > 0 &&
      !RoleHelper.hasRoles([ROLE.ADMINISTRATEUR]) &&
      RoleHelper.hasRoles([ROLE.UTILISATEUR_FRAIS])
    ) {
      setFieldValue('payee_id', user?._id);
    }
  }, [payees]);

  useEffect(() => {
    if (users) {
      getPayees();
    }
  }, [users]);

  useEffect(() => {
    if (expenseId && !expenses[expenseId]) {
      fetchExpenseCallback(expenseId, setError);
    }
  }, [expenseId, expenses, fetchExpenseCallback]);

  useEffect(() => {
    if (values && values.state) {
      setLoading(false);
    }
  }, [values, isShowSideBar]);
  let BodyWithSideBarStyle = {
    border: 'none',
    boxShadow: 'none',
  };

  if (readOnly) {
    BodyWithSideBarStyle = {
      ...BodyWithSideBarStyle,
      background: 'white',
      opacity: '0.5',
      pointerEvents: 'none',
    };
  }

  function renderApproveActionDialog() {
    let source = {};
    let userOId;
    let prefill = {};
    if (
      expenseId &&
      Object.entries(expenses).length !== 0 &&
      expenses[expenseId]
    ) {
      const { transfers } = expenses[expenseId];
      let totalPayoutAmount = 0;

      if (transfers && transfers.length > 0) {
        // eslint-disable-next-line
        transfers.map((transfer) => {
          const { payoutId } = transfer;

          if (payoutId && typeof payoutId === 'object') {
            const { amount } = payoutId;

            totalPayoutAmount += Number(amount);
          }
        });
      }

      source = {
        id: expenseId,
        type: 'expense',
        filename: expenses[expenseId].filename,
      };

      userOId =
        expenses[expenseId] && expenses[expenseId].user_id
          ? expenses[expenseId].user_id
          : null;

      prefill = {
        beneficiaryType: expenses[expenseId] ? 'user' : null,
        beneficiary: userOId ? users[userOId] : null,
        amount:
          Object.entries(expenses).length !== 0
            ? expenses[expenseId].total - totalPayoutAmount
            : 0,
      };
    }

    return (
      <Dialog
        maxWidth="md"
        open={showApprovalDialog}
        onClose={toggleApprovalDialog}
        aria-labelledby="pay-dialog-title"
      >
        <DialogTitle id="pay-dialog-title">{t(`Refund expenses`)}</DialogTitle>
        <DialogContent>
          <BankTransferFormik
            expenseId={expenseId}
            providers={[
              ..._.values(providers).map((provider) => ({
                ...provider,
                id: provider._id,
              })),
            ]}
            clients={[
              ..._.values(clients.data).map((client) => ({
                ...client,
                id: client._id,
              })),
            ]}
            users={[
              ..._.values(users).map((ele) => ({
                ...ele,
                id: ele._id,
              })),
            ]}
            companyAccounts={[
              ...companyAccounts.map((account) => ({
                ...account,
                id: account._id,
              })),
            ]}
            beneficiaryTypes={TreezorBeneficiaryTypes}
            source={source}
            prefill={prefill}
          />
        </DialogContent>
      </Dialog>
    );
  }

  const renderTransactionDialog = () => {
    return (
      <TransactionDialog
        toggleDialog={() => {
          setShowTransactionDialog(!showTransactionDialog);
        }}
        onSubmit={(transaction) => {
          setFieldValue('reconcile', true, false);
          setFieldValue('transaction', transaction);
          setShowTransactionDialog(false);
          formikHanldeSubmit(transaction);
        }}
        defaultAmount={values.duePayableAmount || values.total}
        type={TRANSACTION_TYPE.DEBIT}
        documentId={values?.id}
      />
    );
  };

  function renderBody() {
    return (
      <div style={BodyWithSideBarStyle}>
        {renderApproveActionDialog()}
        {showTransactionDialog && renderTransactionDialog()}
        <Form>
          {errors && errors.internalError && (
            <div className="row">
              <div className="input-field col s12">
                <span className="helper-text">{errors.internalError}</span>
              </div>
            </div>
          )}

          {error && (
            <div className="row">
              <div className="input-field col s12">
                <span className="helper-text">{error}</span>
              </div>
            </div>
          )}
          <Grid
            container
            spacing={2}
            justifyContent="center"
            alignItems="flex-start"
          >
            <Grid item xs={12}>
              <Field
                type="date"
                value={values.date}
                name="date"
                keyboard={true}
                component={DatePicker}
                onChange={handleDateChange}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectAutocomplete
                required
                label={t('expenses.advance.form_label_payee')}
                name="payee_id"
                getOptionLabel={(elem) =>
                  `${elem.display_name} - ${elem.email}`
                }
                values={payees}
                valueSelected={
                  values.payee_id ? users[values.payee_id] : undefined
                }
                onChange={setPayee}
              />
            </Grid>
            <Grid item xs={12}>
              <TextFieldWithAdornment
                adornmentPosition="end"
                adornmentText="€"
                type="number"
                required
                label={t('expenses.advance.form_label_value')}
                placeholder={t('expenses.advance.form_label_value')}
                name="total"
              />
            </Grid>
            <Grid item xs={12}>
              <SelectAutocomplete
                required
                name={`category_id`}
                label={t('providers.form.category')}
                getOptionLabel={(elem) => {
                  return elem.category_display;
                }}
                values={categories.sort((a, b) =>
                  a.category_display.localeCompare(b.category_display)
                )}
                onChange={(category) =>
                  setFieldValue(`category_id`, category?.category_id)
                }
                valueSelected={find(
                  categories,
                  (category) => category.category_id === values.category_id
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectAutocomplete
                required
                name={`sub_category_id`}
                label={t('providers.form.subCategory')}
                getOptionLabel={(e) => getTranslateSubCategory(e, t)}
                values={getSubCategories(values.category_id, categories)}
                onChange={(subCategory) => {
                  setFieldValue(`sub_category_id`, subCategory?._id);
                }}
                valueSelected={find(
                  getSubCategories(values.category_id, categories),
                  (category) => category._id === values.sub_category_id
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectAutocomplete
                name={`internal_activity`}
                label={t('expenses.expense.form_activity')}
                getOptionLabel={(elem) => (elem && elem.display) || ''}
                values={map(activities, (ac) => ac)}
                valueSelected={activity ? activities[activity] : null}
                onChange={(activityClicked) => {
                  setFieldValue(
                    'internal_activity',
                    activityClicked?._id || ''
                  );
                  setFieldValue(
                    'internal_activity_name',
                    activityClicked?.display || ''
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                required
                name={'reason'}
                label={t('expenses.advance.form_label_reason')}
                keyboard={true}
                onChange={(e) => setFieldValue('reason', e.target.value)}
              />
            </Grid>
            {values?.refuseMessage && (
              <Grid item xs={12}>
                <TextField
                  readOnly
                  name={'refuseMessage'}
                  label={t('expenses.expense.form_reason_reject')}
                />
              </Grid>
            )}
            <Grid
              item
              xs={12}
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <AppButton
                color="transparentGrey"
                isDisabled={isSubmitting}
                type="button"
                text={t('cancel')}
                onClick={goBack}
              />

              <AppButton
                color="primaryLight"
                isDisabled={isSubmitting || !isValid}
                type="button"
                text={t('draft')}
                onClick={(e) => {
                  setFieldValue('update', true, false);
                  setTimeout(() => {
                    formikHanldeSubmit(e);
                  }, 100);
                }}
              />
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  }

  function onPaySubmit() {
    toggleApprovalDialog();
  }

  const onReconcile = () => {
    setShowTransactionDialog(true);
  };

  const onValidate = (e) => {
    setFieldValue('validate', true, false);
    setTimeout(() => {
      formikHanldeSubmit(e);
    }, 100);
  };

  const onClickCancel = () => {
    setShowExpenseCancelDialog(true);
  };

  const onCancel = ({ refuseMessage }) => {
    setFieldValue('cancel', true, false);
    setFieldValue('refuseMessage', refuseMessage);
    setTimeout(() => {
      setShowExpenseCancelDialog(false);
      formikHanldeSubmit();
    }, 100);
  };

  const AdvanceExpenseSideBar = () => (
    <AppColumnCenterSpaceBetween
      addStyle={{
        gap: 8,
      }}
    >
      {/* <AppButton
        onClick={onPay}
        type="button"
        fullWidth={true}
        text={t('pay')}
        noBorder={true}
        color="primary"
        isDisabled={isSubmitting}
      /> */}
      {userCanValidate && values.state === EXPENSE_STATE.VALIDATED && (
        <AppButton
          onClick={onReconcile}
          type="button"
          fullWidth={true}
          text={t(`transaction.reconcile`)}
          noBorder={true}
          color="primary"
          isDisabled={isSubmitting}
        />
      )}
      {userCanValidate && values.state === EXPENSE_STATE.PENDING && (
        <>
          <AppButton
            onClick={onValidate}
            type="button"
            fullWidth={true}
            text={t(`validate`)}
            noBorder={true}
            color="primary"
            isDisabled={isSubmitting}
          />
          <AppButton
            onClick={onClickCancel}
            type="button"
            fullWidth={true}
            text={t('reject')}
            noBorder={true}
            color="secondary"
            isDisabled={isSubmitting}
          />
        </>
      )}
    </AppColumnCenterSpaceBetween>
  );

  function renderSideBarRight() {
    if (isShowSideBar && !loading) {
      return (
        <>
          <AdvanceExpenseSideBar
            userCanValidate={userCanValidate}
            state={values.state}
            onPay={onPaySubmit}
            isSubmitting={isSubmitting}
            t={t}
          />
        </>
      );
    }
    return ' ';
  }

  return (
    <Fragment>
      {renderApproveActionDialog()}
      <CancelExpenseFormDialog
        showDialog={showExpenseCancelDialog}
        closeFormDialog={() => setShowExpenseCancelDialog(false)}
        handleSubmitCancel={onCancel}
      />
      <Layout
        header={
          <Header
            name={t('expenses.advance.title')}
            goBack={goBack}
            spaceBetween
          />
        }
        sidebarLeft={true}
        sidebarRight={isShowSideBar ? renderSideBarRight() : null}
        showUserCard={true}
        body={loading ? <CircularProgressCentered /> : renderBody()}
      />
    </Fragment>
  );
};

AdvanceExpenseForm.propTypes = {
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  values: PropTypes.object.isRequired,
  t: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool,
  isValid: PropTypes.bool,
  history: PropTypes.object.isRequired,
  users: PropTypes.object,
  fetchExpense: PropTypes.func,
  expenses: PropTypes.object,
  match: PropTypes.object,
  loggedUser: PropTypes.object,
  userCanReadAdvanceExpense: PropTypes.bool,
  handleSubmit: PropTypes.func,
  userCanValidate: PropTypes.func,
};

const AdvanceExpenseWithForm = withFormik({
  displayName: 'AdvanceExpenseForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(AdvanceExpenseForm);

const AdvanceExpenseFormWithStyles = withStyles(styles)(AdvanceExpenseWithForm);
const AdvanceExpenseFormWithRouter = withRouter(AdvanceExpenseFormWithStyles);
const TranslatedCAdvanceExpenseForm = withTranslation()(
  AdvanceExpenseFormWithRouter
);

export default connect(state, dispatch)(TranslatedCAdvanceExpenseForm);
