import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import ProjectForm from '../../ClientProjects/Form';
import AppDialog from '../../../components/AppDialog';

const ProjectFormDialog = ({
  showDialog,
  selectedClientId,
  selectedProjectId,
  closeProjectFormDialog,
  showDeleteDialog,
}) => {
  const { t } = useTranslation();

  if (showDialog) {
    return (
      <AppDialog
        title={t('settings_section.projects.create_titles')}
        closeDialog={closeProjectFormDialog}
        onConfirmType="submit"
        onConfirmText="submit"
        iconClose
        color="secondary"
        setHeightManualy="450px"
      >
        <ProjectForm
          id={selectedProjectId}
          clientId={selectedClientId}
          closeDialog={closeProjectFormDialog}
          deleteDialog={() =>
            showDeleteDialog(selectedProjectId, selectedClientId)
          }
        />
      </AppDialog>
    );
  }

  return null;
};

ProjectFormDialog.propTypes = {
  showDialog: PropTypes.bool,
  selectedClientId: PropTypes.string,
  selectedProjectId: PropTypes.string,
  closeProjectFormDialog: PropTypes.func,
  showDeleteDialog: PropTypes.func,
};

export default ProjectFormDialog;
