// TODO : create a collection and a GUI to easily maintain this values.... duplicated in expense backend

const categories = [
  {
    name: 'Achats  MP & Marchandises',
    categories: [
      '5de7dab1d314f35e2979804e',
      '5de7dadcd314f35e2979804f',
      '5de7db0ad314f35e29798050',
    ],
  },
  {
    name: 'Achats de prestations',
    categories: [
      '5de7de98d314f35e29798054',
      '5de7dff4d314f35e2979805a',
      '5de7e018d314f35e2979805b',
      '5de7e040d314f35e2979805c',
      '5e6bab13a90f911120eac529',
      '5e6baf15a90f911120eac52a',
      '5e6bb5efa90f911120eac52f',
    ],
  },
  {
    name: 'Achat de petit matériel et fourniture',
    categories: [
      '5d1f6ccb0392e81104dca6ef',
      '5d1f6dd50392e81104dca6f2',
      '5d1f31bb80df550f1829a068',
    ],
  },
  {
    name: 'Location & crédit bail',
    categories: [
      '5d1f301a80df550f1829a061',
      '5d1f6d260392e81104dca6f1',
      '5de7def2d314f35e29798055',
      '5de7df3ed314f35e29798056',
      '5de7df67d314f35e29798057',
      '5e6a2b97c780edb96ececd32',
      '5e6a2d1dc780edb96ececd33',
      '5e6a2edec780edb96ececd37',
      '5e6a4fb551053e01cc9e5a14',
      '5e6a505351053e01cc9e5a16',
      '5e6a709151053e01cc9e5a29',
      '64d50b6d24d6230a27783793',
      '6487313c723ae12e86eca367',
    ],
  },
  {
    name: 'Entretien',
    categories: [
      '5d1f30db80df550f1829a064',
      '5d1f2ffe80df550f1829a060',
      '5de7df8fd314f35e29798058',
      '5e6a2fadc780edb96ececd38',
      '5e6a2fd8c780edb96ececd39',
      '5e6a6f7d51053e01cc9e5a27',
    ],
  },
  {
    name: 'Déplacement',
    categories: [
      '5d1f2eff80df550f1829a05a',
      '5d1f2fab80df550f1829a05d',
      '5d1cc145529fc3c8af0b9fa0',
      '5d1f2edf80df550f1829a059',
      '5d1f2f1780df550f1829a05b',
      '5d1f2aae80df550f1829a056',
      '5d1cc153529fc3c8af0b9fa2',
      '5d1cc157529fc3c8af0b9fa3',
      '5d1f2a7f80df550f1829a055',
      '5d95d63d7b23c3afb1b0ba33',
    ],
  },
  {
    name: 'Restauration',
    categories: [
      '5d1f29b680df550f1829a051',
      '5d1f29ea80df550f1829a053',
      '5d1f29dc80df550f1829a052',
    ],
  },
  {
    name: 'Rémunération des personnels',
    categories: [
      '5df2665d3f7d8fccd56be31c',
      '5df266c73f7d8fccd56be31e',
      '5df2672c3f7d8fccd56be320',
      '5df267c43f7d8fccd56be322',
      '5e6a5dd351053e01cc9e5a24',
      '5e6a5e2551053e01cc9e5a25',
    ],
  },
  {
    name: 'Assurances',
    categories: [
      '5de7dfc0d314f35e29798059',
      '5e6a2d5ec780edb96ececd34',
      '5e6a500d51053e01cc9e5a15',
      '5e6a701051053e01cc9e5a28',
    ],
  },
  {
    name: 'Téléphonie & frais postaux',
    categories: [
      '5d1f315f80df550f1829a066',
      '5d1f310580df550f1829a065',
      '5d1f318d80df550f1829a067',
      '5e6a2e35c780edb96ececd35',
      '5e6a2e74c780edb96ececd36',
    ],
  },
  {
    name: 'Formation & documentation',
    categories: [
      // '5d1f6dd50392e81104dca6f2',
      '5d1f31bb80df550f1829a068',
      '5e6baf94a90f911120eac52b',
    ],
  },
  {
    name: 'Taxes',
    categories: [
      '5e6a5d2a51053e01cc9e5a21',
      '5e6a5d6c51053e01cc9e5a22',
      '5e6a5d9351053e01cc9e5a23',
      '5e6a5e9d51053e01cc9e5a26',
    ],
  },
  {
    name: 'Frais divers',
    categories: [
      '5d1f30a580df550f1829a063',
      '5de7e091d314f35e2979805e',
      '5e6a2ad9c780edb96ececd31',
      '5e6bb0ffa90f911120eac52c',
      '5e6bb141a90f911120eac52d',
      '5e6bb203a90f911120eac52e',
    ],
  },
  {
    name: 'Frais bancaires et financiers',
    categories: [
      '5de7e064d314f35e2979805d',
      '5df2684c3f7d8fccd56be324',
      '5df2686b3f7d8fccd56be325',
      '64d50ba27f45f2dd208ec6eb',
    ],
  },
  {
    name: 'Rémunération du dirigeants',
    categories: [
      '5df2669c3f7d8fccd56be31d',
      '5df267013f7d8fccd56be31f',
      '5e6a559851053e01cc9e5a1a',
      '5e6a580e51053e01cc9e5a1b',
      '5e6a587151053e01cc9e5a1c',
      '5e6a588e51053e01cc9e5a1d',
    ],
  },
];

export default categories;
