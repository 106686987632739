import { authInstance as axios } from '../../helpers/axiosInterceptor';
import { store } from '../../store';
import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

const fetchRoles = async (filter) =>
  axios.get(`${process.env.REACT_APP_ROLES_URL}/roles`, { params: filter });

const createRole = async (role) =>
  axios.post(`${process.env.REACT_APP_ROLES_URL}/roles`, role);

const updateRole = async (id, role) =>
  axios.put(`${process.env.REACT_APP_ROLES_URL}/roles/${id}`, role);

const deleteRole = async (id) =>
  axios.delete(`${process.env.REACT_APP_ROLES_URL}/roles/${id}`);

const fetchUserRoles = async (filter) =>
  axios.get(`${process.env.REACT_APP_ROLES_URL}/userroles`, { params: filter });

const createUserRole = async (ur) =>
  axios.post(`${process.env.REACT_APP_ROLES_URL}/userroles`, ur);

const editUserRole = async (id, ur) =>
  axios.put(`${process.env.REACT_APP_ROLES_URL}/userroles/${id}`, ur);

const deleteUserRole = async (id) =>
  axios.delete(`${process.env.REACT_APP_ROLES_URL}/userroles/${id}`);

const handleUserRole = async (companyId, userId, roles) =>
  axios.put(
    `${process.env.REACT_APP_ROLES_URL}/userroles/change`,
    { roles },
    { params: { company_id: companyId, user_id: userId } }
  );

const fetchAuthUserRoles = async () => {
  try {
    const companyId = selectLoggedUserCompanyId(store.getState());

    if (companyId) {
      const response = await axios.get(
        `${process.env.REACT_APP_ROLES_URL}/myroles`
      );

      return response;
    }
    // else user is not logged in: returns an empty array
    return new Promise((resolve) => {
      resolve({ data: [] });
    });
  } catch (error) {
    // if error occurs: returns an empty array
    return new Promise((resolve) => {
      resolve({ data: [], msg: error });
    });
  }
};

export default {
  fetchRoles,
  createRole,
  updateRole,
  deleteRole,
  fetchUserRoles,
  createUserRole,
  editUserRole,
  deleteUserRole,
  handleUserRole,
  fetchAuthUserRoles,
};
