import { authInstance as axios } from 'helpers/axiosInterceptor';

const postCompany = async (data) =>
  axios.post(`${process.env.REACT_APP_TREEZOR_URL}/api/users`, data);

const putCompany = async (companyId, data) =>
  axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/api/users/${companyId}`,
    data
  );

const walletCreate = async (companyId, data) =>
  axios.post(`${process.env.REACT_APP_TREEZOR_URL}/wallets/${companyId}`, data);

const postUser = async (data) =>
  axios.post(`${process.env.REACT_APP_TREEZOR_URL}/onboarding`, data);

const onboardingAutoEntrepreneur = async (data) =>
  axios.post(`${process.env.REACT_APP_TREEZOR_URL}/onboarding/company`, data);

const onboardingCompany = async (data) =>
  axios.post(`${process.env.REACT_APP_TREEZOR_URL}/onboarding/company`, data);

const onboardingUser = async (user, company) =>
  axios.post(`${process.env.REACT_APP_TREEZOR_URL}/onboarding/users`, {
    company,
    user,
  });

const onboardingNotify = async () =>
  axios.post(`${process.env.REACT_APP_TREEZOR_URL}/onboarding/notify`);

const createTreezorWallet = async (data) => {
  return axios.post(
    `${process.env.REACT_APP_TREEZOR_URL}/onboarding/wallets`,
    data
  );
};

const putUser = async (treezorUserId, data) =>
  axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/users/${treezorUserId}`,
    data
  );

const uploadDocs = async (values, data) =>
  axios.post(
    `${process.env.REACT_APP_TREEZOR_URL}/documents/${values.company.companyId}`,
    data
  );

// Wallet Serivce
const fetchWallet = async () =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/wallets`);

const fetchWalletTransactions = async (walletId) =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/wallets/${walletId}/transactions`
  );

const fetchWalletTransactionsPaginate = async (walletId, qs = '') =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/wallets/${walletId}/transactions?${qs}`
  );

const fetchWalletTransactionsForPDFExporting = async (walletId, qs = '') =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/wallets/${walletId}/transactions/exporting?${qs}`
  );
// axios.get(
//   `http://localhost:3001/treezor/v1/wallets/${walletId}/transactions/exporting?${qs}`
// );

const updateWalletTransaction = async (walletId, data) =>
  axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/wallets/transactions/${walletId}`,
    data
  );

const fetchWalletTransaction = async (qs) =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/wallets/transaction?${qs}`);

// UserTreezor Service
const fetchUserTreezor = async (userId) =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/api/users/${userId}`
    // `http://localhost:8080/users/${userId}`
  );

const fetchPersonUserTreezor = async (userId) =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/api/users?parentUserId=${userId}&userStatus=VALIDATED`
  );

const fetchKycUser = async (userId) =>
  axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/api/users/${userId}/Kycreview/`
    // `http://localhost:8080/users/${userId}/Kycreview`
  );

const updateTreezorProfile = async (treezorUserId, data) =>
  axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/users/profile/${treezorUserId}`,
    data
  );

const cancelTreezorUser = async (userId) =>
  axios.delete(`${process.env.REACT_APP_TREEZOR_URL}/users/${userId}/delete`);

// Card Service
const fetchCards = async (userId) =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/cards/?userId=${userId}`);
const fetchCardImage = (cardId) =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/api/cardimages?cardId=${cardId} `
  );
const lockUnlockCard = (cardOId, data) =>
  axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/cards/${cardOId}/lock/`,
    data
  );
const createPhysicalCard = (data) =>
  axios.post(
    `${process.env.REACT_APP_TREEZOR_URL}/cards/physical`, // TODO create deux route
    // `${process.env.REACT_APP_TREEZOR_URL}/api/cards/${data.companyId}/${data.bbfUserId}`,
    // `${process.env.REACT_APP_TREEZOR_URL}/cards/${data.companyId}/${data.bbfUserId}`,
    data
  );
const createVirtualCard = (data) => {
  return axios.post(`${process.env.REACT_APP_TREEZOR_URL}/cards/virtual`, data);
};
const configCard = (cardOId, data) =>
  axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/cards/${cardOId}/config/`,
    data
  );
const activatePhysicalCard = (cardOId, data) => {
  const result = axios.put(
    `${process.env.REACT_APP_TREEZOR_URL}/cards/${cardOId}`,
    data
  );

  return result;
};
const fetchCardTransactions = async (cardId) =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/cards/${cardId}/transactions`
  );

const fetchDetailCard = (cardOId) =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/cards/${cardOId}`);

const getOTP = async () => {
  return axios.get(`${process.env.REACT_APP_TREEZOR_URL}/otp`);
};

const getCardPin = async (cardOId, data) => {
  return axios.post(
    `${process.env.REACT_APP_TREEZOR_URL}/cards/${cardOId}/pin`,
    data
  );
};

const getBalances = async () =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/balances`);

const storeHipay = (data) =>
  axios.post(`${process.env.REACT_APP_TREEZOR_URL}/hipay/reference`, data);

/* sepa request */
const createNewSEPARequest = (data) =>
  axios.post(`${process.env.REACT_APP_TREEZOR_URL}/sepa/request`, data);

const getSEPARequests = (qs = '') =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/sepa/requests?${qs}`);

const getSEPARequest = (requestOId) =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/sepa/request?requestOId=${requestOId}`
  );

const updateSEPARequest = async (data) =>
  axios.put(`${process.env.REACT_APP_TREEZOR_URL}/sepa/request`, data);

const getValidatedPayoutRequest = async (walletId, qs = '') =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/wallets/${walletId}/transactions?${qs}`
  );

const getPayoutRequest = async (qs) =>
  axios.get(`${process.env.REACT_APP_TREEZOR_URL}/payouts-paginate?${qs}`);

const deletePendingPayoutRequest = async (payoutId, qs) =>
  axios.delete(
    `${process.env.REACT_APP_TREEZOR_URL}/payouts/${payoutId}?${qs}`
  );

const getPendingPayoutRequest = async (qs) =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/treezor-pending-payouts?${qs}`
  );

const getPendingPayinRequest = async (qs) =>
  axios.get(
    `${process.env.REACT_APP_TREEZOR_URL}/treezor-pending-payins?${qs}`
  );

const closeTreezorWalletRequest = async (walletId) =>
  axios.delete(
    `${process.env.REACT_APP_TREEZOR_URL}/wallets/${walletId}/close`
  );

export default {
  postCompany,
  putCompany,
  postUser,
  putUser,
  uploadDocs,
  walletCreate,
  fetchWallet,
  fetchWalletTransactions,
  updateWalletTransaction,
  fetchKycUser,
  fetchUserTreezor,
  cancelTreezorUser,
  fetchPersonUserTreezor,
  fetchCards,
  fetchCardImage,
  lockUnlockCard,
  createPhysicalCard,
  onboardingAutoEntrepreneur,
  createTreezorWallet,
  onboardingCompany,
  onboardingUser,
  createVirtualCard,
  onboardingNotify,
  configCard,
  activatePhysicalCard,
  fetchCardTransactions,
  fetchDetailCard,
  getOTP,
  getCardPin,
  updateTreezorProfile,
  fetchWalletTransactionsPaginate,
  fetchWalletTransactionsForPDFExporting,
  fetchWalletTransaction,
  getBalances,
  storeHipay,

  // SEPA request
  createNewSEPARequest,
  getSEPARequests,
  getSEPARequest,
  updateSEPARequest,

  // Payout request
  getPayoutRequest,
  getValidatedPayoutRequest,
  deletePendingPayoutRequest,
  getPendingPayoutRequest,

  // Payin request
  getPendingPayinRequest,

  // Treezor
  closeTreezorWalletRequest,
};
