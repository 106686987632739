/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropsType from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';

import {
  createClientProject,
  updateClientProject,
} from '../../actions/ClientProjectActions';

import validationSchema from './validation';
import { format } from 'date-fns';
import formState from './formState';
import { setGlobalError } from '../../actions/GlobalError';
import WarningIcon from '@material-ui/icons/Warning';
import LoadingDialog from 'components/LoadingDialog';

const ProjectCreationModal = (props) => {
  const {
    show,
    onClose,
    clients,
    companyId,
    selectedProject,
    isEdit = false,
    onViewHistory,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const VATRates = useSelector((state) => state.vatRates);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clientProjects = useSelector((state) => state.clientProjects.projects);

  const STATUS_STATE = [
    t('project.status_state.todo'),
    t('project.status_state.in_progress'),
    t('project.status_state.closed'),
  ];

  const [status, setStatus] = useState('');
  const [error, setError] = useState(false);

  const onHandleSubmit = async (values) => {
    setIsLoading(true);
    if (!isEdit) {
      values.budget = values.ht;
      // eslint-disable-next-line no-use-before-define
      await dispatch(
        createClientProject(values, () => {
          // eslint-disable-next-line no-use-before-define
          formik.resetForm();
          onClose(true);
        })
      );
    } else {
      // eslint-disable-next-line no-use-before-define
      await dispatch(
        updateClientProject(values, () => {
          // eslint-disable-next-line no-use-before-define
          formik.resetForm();
          onClose(true);
        })
      );
    }
    setIsLoading(false);
  };

  const getClientProjectData = () => {
    try {
      const clientProjectsData = clientProjects[selectedProject.clientId];
      let projectData = clientProjectsData.find(
        (item) => item._id === selectedProject.projectId
      );
      return projectData;
    } catch (exception) {
      setGlobalError(exception);
    }
    return [];
  };

  const onCheckHistoryEmpty = () => {
    const projectData = getClientProjectData();
    if (!projectData || projectData.budgetsRevised === undefined) {
      setError(true);
    } else {
      setError(false);
    }
  };

  const onChooseHistory = () => {
    const clientProjectsData = clientProjects[selectedProject.clientId];
    let projectData = clientProjectsData.find(
      (item) => item._id === selectedProject.projectId
    );
    if (projectData.budgetsRevised) {
      onViewHistory(projectData.budgetsRevised);
    } else {
      setError(true);
    }
  };

  const setFormEditData = () => {
    const clientProjectsData = clientProjects[selectedProject.clientId];
    let projectData = clientProjectsData.find(
      (item) => item._id === selectedProject.projectId
    );
    formik.setFieldValue('_id', projectData._id);
    formik.setFieldValue('name', projectData.name);
    formik.setFieldValue('client_id', projectData.client_id);
    formik.setFieldValue(
      'purchaseOrderNumber',
      projectData.purchaseOrderNumber
    );
    formik.setFieldValue('ht', projectData.ht);
    formik.setFieldValue('tva', projectData.tva);
    formik.setFieldValue('ttc', projectData.ttc);

    if (projectData.budgetsRevised) {
      if (projectData.budgetsRevised.length > 0) {
        const lastestRecord = projectData.budgetsRevised.length - 1;
        formik.setFieldValue(
          'revisedHT',
          projectData.budgetsRevised[lastestRecord].revisedHT
        );
        formik.setFieldValue(
          'revisedTVA',
          projectData.budgetsRevised[lastestRecord].revisedTVA
        );
        formik.setFieldValue(
          'revisedTTC',
          projectData.budgetsRevised[lastestRecord].revisedTTC
        );
      } else {
        formik.setFieldValue('revisedHT', 0);
        formik.setFieldValue('revisedTVA', 0);
        formik.setFieldValue('revisedTTC', 0);
      }
    } else {
      setGlobalError('This project is currently used outdated data');
    }

    formik.setFieldValue('estimatedCost', projectData.estimatedCost);
    formik.setFieldValue('status', projectData.status);
    formik.setFieldValue('updated_at', projectData.updated_at);
    formik.setFieldValue('created_at', projectData.created_at);
    setStatus(projectData.status);
  };

  useEffect(() => {
    onCheckHistoryEmpty();
    if (!isEdit) {
      formik.resetForm();
    }
    if (isEdit) {
      setFormEditData();
    }
  }, [selectedProject]);

  const formik = useFormik({
    initialValues: {
      ...formState,
      company_id: companyId,
    },
    onSubmit: onHandleSubmit,
    validationSchema,
  });

  const changeStatus = (event) => {
    formik.setFieldValue('status', event.target.value);
    setStatus(event.target.value);
  };

  const renderClientList = () => {
    const clientsId = Object.keys(clients);

    return clientsId.map((item, idx) => (
      <MenuItem key={idx} value={item}>
        {clients[item]?.display_name}
      </MenuItem>
    ));
  };

  const calculateTTC = (event, isRevised = false) => {
    const value = isRevised ? formik.values.revisedHT : formik.values.ht;

    const ttc =
      Number.parseFloat(value) +
      Number.parseFloat(value * (Number.parseFloat(event.target.value) / 100));

    isRevised
      ? formik.setFieldValue('revisedTTC', ttc)
      : formik.setFieldValue('ttc', ttc);
  };

  return (
    <Dialog
      open={show}
      // onClose={onClose}
      aria-labelledby="form-dialog-title"
      fullWidth={true}
      maxWidth="sm"
    >
      {isLoading && <LoadingDialog title={t('loading_single')} />}
      <DialogTitle
        color="primary"
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="h6"
          style={{ fontWeight: 'bold', textTransform: 'uppercase' }}
        >
          {!isEdit
            ? t('project.create_header_title')
            : t('project.edit_header_title')}
        </Typography>
      </DialogTitle>
      <Divider variant="middle" style={{ border: '1px solid #3d319d' }} />
      <DialogContent>
        <Box style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
          <TextField
            style={{ width: '100%' }}
            name="name"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.name}
            label={t('project.name')}
            placeholder="Name"
            color="secondary"
          />
          {formik.errors.name && formik.touched.name && (
            <span style={{ color: 'red' }}>{formik.errors.name}</span>
          )}
          <FormControl fullWidth>
            <InputLabel id="client-simple-select-label">
              {t('project.client')}
            </InputLabel>
            <Select
              disabled={isEdit}
              name="client_id"
              type="text"
              value={formik.values.client_id}
              onChange={formik.handleChange}
              labelId="client-simple-select-label"
              id="client-simple-select"
              label={t('project.client')}
            >
              {renderClientList()}
            </Select>
          </FormControl>
          {formik.errors.client_id && formik.touched.client_id && (
            <span style={{ color: 'red' }}>{formik.errors.client_id}</span>
          )}
          <TextField
            style={{ width: '100%' }}
            name="purchaseOrderNumber"
            type="text"
            onChange={formik.handleChange}
            value={formik.values.purchaseOrderNumber}
            label={t('project.purchase_order')}
            placeholder={t('project.purchase_order')}
            color="secondary"
          />
          {/* {formik.errors.purchaseOrderNumber &&
            formik.touched.purchaseOrderNumber && (
              <span style={{ color: 'red' }}>
                {formik.errors.purchaseOrderNumber}
              </span>
            )} */}
          <div>
            <Typography style={{ fontWeight: 'bold' }}>
              {t('project.budget')}
            </Typography>
            <Box
              style={{
                display: 'flex',
                gap: '15px',
                flex: '0 0 calc(33.33% - 20px)',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 0 calc(33.33% - 10px)',
                }}
              >
                <TextField
                  name="ht"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.ht}
                  label={t('project.hors-taxes')}
                  color="secondary"
                  disabled={isEdit}
                />
                <span style={{ color: 'red' }}>{formik.errors.ht}</span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 0 calc(33.33% - 10px)',
                }}
              >
                <FormControl>
                  <InputLabel id="tva-simple-select-label" color="secondary">
                    {t('project.tva')}
                  </InputLabel>
                  <Select
                    label={t('project.tva')}
                    name="tva"
                    onChange={(event) => {
                      formik.handleChange(event);
                      calculateTTC(event);
                    }}
                    value={formik.values.tva}
                    color="secondary"
                    disabled={isEdit}
                  >
                    {VATRates.map((item, idx) => (
                      <MenuItem key={idx} value={item.value}>
                        {item.display}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <span style={{ color: 'red' }}>{formik.errors.tva}</span>
              </div>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  flex: '0 0 calc(33.33% - 10px)',
                }}
              >
                <TextField
                  name="ttc"
                  type="text"
                  onChange={formik.handleChange}
                  value={formik.values.ttc}
                  label={t('project.ttc')}
                  color="secondary"
                  disabled={isEdit}
                />
                <span style={{ color: 'red' }}>{formik.errors.ttc}</span>
              </div>
            </Box>
          </div>

          <Box style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <TextField
              style={{ width: '100%' }}
              name="estimatedCost"
              type="number"
              onChange={formik.handleChange}
              value={formik.values.estimatedCost}
              label={t('project.estimated_cost')}
              placeholder={t('project.estimated_cost')}
              color="secondary"
            />
            {formik.errors.estimatedCost && formik.touched.estimatedCost && (
              <span style={{ color: 'red' }}>
                {formik.errors.estimatedCost}
              </span>
            )}
          </Box>

          {isEdit && (
            <div>
              <div>
                <Typography style={{ fontWeight: 'bold' }}>
                  {t('project.budget_revised')}
                </Typography>
                <Box style={{ display: 'flex', gap: '15px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: '0 0 calc(33.33% - 10px)',
                    }}
                  >
                    <TextField
                      style={{ width: '100%' }}
                      name="revisedHT"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.revisedHT}
                      label={t('project.hors-taxes')}
                      color="secondary"
                    />
                    <span style={{ color: 'red' }}>
                      {formik.errors.revisedHT}
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: '0 0 calc(33.33% - 10px)',
                    }}
                  >
                    <FormControl>
                      <InputLabel
                        id="input-lable-project-revised-vat"
                        color="secondary"
                      >
                        {t('project.tva')}
                      </InputLabel>
                      <Select
                        label={t('project.tva')}
                        name="revisedTVA"
                        value={formik.values.revisedTVA}
                        color="secondary"
                        onChange={(event) => {
                          formik.handleChange(event);
                          calculateTTC(event, true);
                        }}
                      >
                        {VATRates.map((item, idx) => (
                          <MenuItem key={idx} value={item.value}>
                            {item.display}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <span style={{ color: 'red' }}>
                      {formik.errors.revisedTVA}
                    </span>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flex: '0 0 calc(33.33% - 10px)',
                    }}
                  >
                    <TextField
                      style={{ width: '100%' }}
                      name="revisedTTC"
                      type="text"
                      onChange={formik.handleChange}
                      value={formik.values.revisedTTC}
                      label={t('project.ttc')}
                      color="secondary"
                    />
                    <span style={{ color: 'red' }}>
                      {formik.errors.revisedTTC}
                    </span>
                  </div>
                </Box>
              </div>
              <div>
                {formik.values.created_at !== formik.values.updated_at && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      alignItems: 'end',
                    }}
                  >
                    <div>
                      <Typography style={{ fontWeight: 'bold' }}>
                        {t('project.time_update')}
                      </Typography>

                      <Typography>
                        {formik.values.updated_at &&
                          format(
                            new Date(formik.values.updated_at.toString()),
                            'dd-MM-yyyy HH:mm:ss:aa'
                          )}
                      </Typography>
                    </div>
                    {!error ? (
                      <Typography
                        color="primary"
                        style={{ fontWeight: 'bold', cursor: 'pointer' }}
                        onClick={onChooseHistory}
                      >
                        View history
                      </Typography>
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          gap: '5px',
                          alignItems: 'center',
                        }}
                      >
                        <WarningIcon color="error" />
                        <Typography
                          color="error"
                          style={{ fontWeight: 'bold', cursor: 'pointer' }}
                        >
                          Empty history because of using old data format
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          <FormControl variant="standard" style={{ width: '100%' }}>
            <InputLabel id="demo-simple-select-standard-label">
              {t('project.status')}
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              style={{ width: '100%' }}
              name="status"
              type="text"
              label={t('project.status')}
              value={status}
              onChange={changeStatus}
              color="secondary"
            >
              {STATUS_STATE.map((item, idx) => (
                <MenuItem key={idx} value={idx}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <span style={{ color: 'red' }}>{formik.errors.status}</span>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          onClick={() => {
            onClose(false);
          }}
        >
          {t('project.cancel')}
        </Button>
        <Button
          variant="contained"
          style={{ zIndex: 10 }}
          color="primary"
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {isEdit ? t('project.update') : t('project.create')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ProjectCreationModal.propTypes = {
  show: PropsType.bool,
  onClose: PropsType.func,
  clients: PropsType.object,
  companyId: PropsType.string,
  selectedProject: PropsType.object,
  isEdit: PropsType.bool,
  onViewHistory: PropsType.func,
};

export default ProjectCreationModal;
