const PaymentTypes = [
  {
    _id: 'personal',
    display: 'Personnel',
  },
  {
    _id: 'company1',
    display: 'CB entreprise',
  },
  {
    _id: 'company2',
    display: 'Chèque entreprise',
  },
  {
    _id: 'company3',
    display: 'Virement bancaire',
  },
  {
    _id: 'company4',
    display: 'Prélèvement bancaire',
  },
  {
    _id: 'cash',
    display: 'Espèces entreprise',
  },
  {
    _id: 'lcr',
    display: 'LCR',
  },
];

export default PaymentTypes;
