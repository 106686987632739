/* Packages & components */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, Field } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  Typography,
  MenuItem,
  TextField as MUITextField,
  InputAdornment,
} from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Autocomplete } from 'formik-material-ui-lab';
import { TextField } from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import DateFnsUtils from '@date-io/date-fns';
import { CountryCodes as HighRiskCountryList } from '@bebusinessfocus/compta-hub-core';
import countryList from 'assets/data/countryList.json';
import UploadFile from 'components/NewUploadFile/uploadFile';

// Question : new Formstate file with it so it would be reused accros initialValues, handleSumnit, validation etc
const BENEFICIARY_TYPE = 'beneficiaryType';
const BENEFICIARY = 'beneficiary';
const REFERENCE = 'reference';
const AMOUNT = 'amount';
const NOTE = 'note';
const TRANSFER_DATE = 'transferDate';

const BankTransferFormFields = ({
  readOnly,
  providers,
  clients,
  users,
  companyAccounts,
  beneficiaryTypes,
  setDisabledSubmitButton,
  showBeneficiaryExtraInfo,
  setShowBeneficiaryExtraInfo,
  showIban,
  setShowIban,
  showBic,
  setShowBic,
}) => {
  const { t } = useTranslation();
  const { setFieldValue, touched, errors, values } = useFormikContext();

  const [filteredBeneficiaries, setFilteredBeneficiaries] = useState([
    ...providers,
  ]);
  const [showUploadContract, setShowUploadContract] = useState(false);
  const [extraInfoCol, setExtraInfoCol] = useState(12);

  const handleBenecifiaryTypeChange = (event) => {
    const { target } = event;
    if (target && target.value) {
      switch (target.value) {
        case 'provider':
          setFieldValue(BENEFICIARY, null);
          setFilteredBeneficiaries([...providers]);
          setFieldValue(BENEFICIARY_TYPE, target.value);
          break;
        case 'client':
          setFieldValue(BENEFICIARY, null);
          setFilteredBeneficiaries([...clients]);
          setFieldValue(BENEFICIARY_TYPE, target.value);
          break;
        case 'user':
          setFieldValue(BENEFICIARY, null);
          setFilteredBeneficiaries([...users]);
          setFieldValue(BENEFICIARY_TYPE, target.value);
          break;
        case 'company':
          setFieldValue(BENEFICIARY, null);
          setFilteredBeneficiaries([...companyAccounts]);
          setFieldValue(BENEFICIARY_TYPE, target.value);
          break;
        default:
          setFieldValue(BENEFICIARY, null);
          setFilteredBeneficiaries([]);
          setFieldValue(BENEFICIARY_TYPE, null);
          break;
      }
    } else {
      setFieldValue(BENEFICIARY_TYPE, null);
      setFilteredBeneficiaries([]);
      setFieldValue(BENEFICIARY, null);
    }
  };

  // Handle show upload contract for verification
  const isUEIban = (iban) => {
    let isMatched = false;
    const ibanCountryCode = iban.substring(0, 2);
    countryList.forEach((country) => {
      if (country.alpha2 === ibanCountryCode) {
        isMatched = country.UE === 'True';
      }
    });
    return isMatched;
  };

  const handlePayoutVerification = (iban, amount) => {
    const ibanCountryCode = iban.substring(0, 2);
    const isMatchedCountryCode = HighRiskCountryList.includes(ibanCountryCode);
    if (isMatchedCountryCode) {
      setShowUploadContract(true);
      setDisabledSubmitButton(true);
      setFieldValue('isShowedUploadPart', true);
    } else if (amount > 4999 && !isUEIban) {
      setShowUploadContract(true);
      setDisabledSubmitButton(true);
      setFieldValue('isShowedUploadPart', true);
    } else if (amount > 9900 && isUEIban) {
      setShowUploadContract(true);
      setDisabledSubmitButton(true);
      setFieldValue('isShowedUploadPart', true);
    } else {
      setShowUploadContract(false);
      setDisabledSubmitButton(false);
      setFieldValue('isShowedUploadPart', false);
    }
  };

  useEffect(() => {
    const { beneficiary, amount } = values;
    if (showUploadContract && amount < 1000) {
      setShowUploadContract(false);
      setDisabledSubmitButton(false);
      setFieldValue('isShowedUploadPart', false);
    }
    if (beneficiary) {
      let { iban } = beneficiary;

      iban = iban || values.iban;

      if (iban && amount) {
        handlePayoutVerification(iban, amount);
      }

      if (amount > 9999) {
        setShowUploadContract(true);
        setDisabledSubmitButton(true);
        setFieldValue('isShowedUploadPart', true);
      }
    }
  }, [values.beneficiary, values.amount, values.iban]);

  const handleUploadFile = (files) => {
    if (files && files.length > 0) {
      setFieldValue('contractFiles', Object.values(files));
    }
  };

  useEffect(() => {
    const { beneficiaryType, beneficiary } = values;
    let cols = 0;

    if (beneficiaryType !== 'company') {
      if (beneficiary && !beneficiary.beneficiaryId) {
        setShowBeneficiaryExtraInfo(true);

        if (!beneficiary.iban) {
          cols += 1;
          setShowIban(true);
        }

        if (!beneficiary.bic) {
          cols += 1;
          setShowBic(true);
        }

        setExtraInfoCol(12 / cols);
      }
    }
  }, []);

  const renderBeneficiaryExtraInfo = () => {
    return (
      <>
        {showBic && (
          <Grid item xs={extraInfoCol}>
            <Field
              name="bic"
              label={t('BIC')}
              component={TextField}
              required
              fullWidth
              disabled={readOnly}
              variant="outlined"
              size="small"
            />
          </Grid>
        )}
        {showIban && (
          <Grid item xs={extraInfoCol}>
            <Field
              name="iban"
              label={t('IBAN')}
              component={TextField}
              required
              fullWidth
              disabled={readOnly}
              variant="outlined"
              size="small"
            />
          </Grid>
        )}
      </>
    );
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <div>
        <Grid container spacing={2}>
          {/* Faire un virement en euros */}
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {t('Faire un virement en euros')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name={BENEFICIARY}
                component={Autocomplete}
                select
                required
                fullWidth
                options={filteredBeneficiaries}
                getOptionLabel={(option) => option.name}
                disabled={values.disableBeneficiary}
                renderInput={(params) => (
                  <MUITextField
                    {...params}
                    required
                    error={touched.beneficiary && !!errors.beneficiary}
                    helperText={touched.beneficiary && errors.beneficiary}
                    label={t('Nom du bénéficiaire')}
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                name={BENEFICIARY_TYPE}
                label={t('Nature du bénéficiaire')}
                component={TextField}
                select
                required
                fullWidth
                disabled={values.disableBeneficiaryType}
                onChange={handleBenecifiaryTypeChange}
                variant="outlined"
                size="small"
              >
                {beneficiaryTypes &&
                  beneficiaryTypes.map((beneficiaryType, idx) => {
                    return (
                      <MenuItem key={idx} value={beneficiaryType.code}>
                        {t(beneficiaryType.label)}
                      </MenuItem>
                    );
                  })}
              </Field>
            </Grid>
            {showBeneficiaryExtraInfo && renderBeneficiaryExtraInfo()}
          </Grid>
          {/* Informations sur le virement */}
          <Grid container item xs={12} spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">
                {t('Informations sur le virement')}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Field
                name={REFERENCE}
                label={t('Libellé du virement')}
                component={TextField}
                required
                fullWidth
                disabled={readOnly}
                variant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                type="number"
                name={AMOUNT}
                label={t('Montant en euros')}
                component={TextField}
                required
                fullWidth
                disabled={readOnly}
                variant="outlined"
                size="small"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">€</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={TRANSFER_DATE}
                label={t('Programmer le virement')}
                component={KeyboardDatePicker}
                disabled
                required
                fullWidth
                clearable={true}
                format="dd/MM/yyyy"
                placeholder="__/__/____"
                inputVariant="outlined"
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                name={NOTE}
                label={t('Ajouter une note complémentaire')}
                component={TextField}
                fullWidth
                multiline
                rows={4}
                disabled={readOnly}
                variant="outlined"
                size="small"
              />
            </Grid>
            {showUploadContract && (
              <>
                <Grid item xs={12}>
                  <Typography variant="h6">
                    {t('Please add the contract for verification')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <UploadFile
                    accept="application/pdf, image/*"
                    multiple
                    onFileSelected={handleUploadFile}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </div>
    </MuiPickersUtilsProvider>
  );
};

BankTransferFormFields.propTypes = {
  readOnly: PropTypes.bool,
  providers: PropTypes.array,
  clients: PropTypes.array,
  users: PropTypes.array,
  companyAccounts: PropTypes.array,
  beneficiaryTypes: PropTypes.array,
  setDisabledSubmitButton: PropTypes.func,
  showBeneficiaryExtraInfo: PropTypes.bool,
  setShowBeneficiaryExtraInfo: PropTypes.func,
  showIban: PropTypes.bool,
  setShowIban: PropTypes.func,
  showBic: PropTypes.bool,
  setShowBic: PropTypes.func,
};

export default BankTransferFormFields;
