import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from 'helpers/RoleHelper';
import { BookKeeping as Rights } from '../../config/Rights';
import { BookKeeping as Feature } from '../../config/Features';

export const userCanReadBookKeeping = () => {
  return hasRequestedAccess(Rights.BookKeepingRead);
};

export const userCanExportBookKeeping = () => {
  return hasRequestedAccess(Rights.BookKeepingExport);
};

export const userHasBookKeepingAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
