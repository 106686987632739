import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import classNames from 'classnames';
import style from '../../assets/jss/components/appButton';

const AppButton = ({
  text,
  onClick,
  isDisabled,
  type,
  classes,
  color,
  fullWidth,
  noBorder,
  toggleDropdown,
  form,
  field,
  ...rest
}) => {
  const btnClasses = classNames({
    [classes.button]: true,
    [classes[color]]: color,
    [classes.fullWidth]: fullWidth,
    [classes.noBorder]: noBorder,
  });

  function onClickFunc(e) {
    if (toggleDropdown) {
      /* eslint-disable-next-line */
      return toggleDropdown(e), onClick(e);
    }
    if (onClick) {
      return onClick(e);
    }
    return null;
  }

  return (
    <button
      type={type}
      className={btnClasses}
      onClick={isDisabled ? () => {} : onClickFunc}
      disabled={isDisabled}
      {...rest}
    >
      {text}
    </button>
  );
};

AppButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  form: PropTypes.object,
  field: PropTypes.object,
  color: PropTypes.string,
  toggleDropdown: PropTypes.func,
  type: PropTypes.string,
  noBorder: PropTypes.bool,
};
AppButton.defaultProps = {
  isDisabled: false,
  onClick: null,
  fullWidth: false,
  toggleDropdown: null,
  color: 'primary',
  noBorder: false,
  type: 'button',
  form: null,
  field: null,
};

export default withStyles(style)(AppButton);
