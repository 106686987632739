import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

import AppButton from '../../../../../../components/AppButton';
import AppDialog from '../../../../../../components/AppDialog';

import { useDialog } from '../../../../../../hooks/dialogHooks';

import { invoiceButtons } from '../../../../../../helpers/invoiceHelpers';

const CancelButton = ({ separatorStyle, invoiceId, horizontal }) => {
  const { showDialog: showApproveDialog, toggleDialog: toggleApprovalDialog } =
    useDialog();

  const { t } = useTranslation();
  const { values, isSubmitting, setFieldValue } = useFormikContext();

  const { type } = values;

  function cancel() {
    toggleApprovalDialog();
    setFieldValue('action', 'cancel', false);
  }

  function renderDialog() {
    if (showApproveDialog) {
      return (
        <AppDialog
          sm
          iconClose
          footer
          title={t('sure')}
          closeDialog={toggleApprovalDialog}
          onConfirmText={t('yes')}
          onCancelText={t('no')}
          onConfirm={cancel}
          contentText={t(`invoice.confirm.cancel_${values.type}`)}
          color="secondary"
        />
      );
    }

    return null;
  }

  if (!invoiceButtons[type].canCancel(values) || !invoiceId) {
    return null;
  }

  return (
    <Fragment>
      {renderDialog()}
      <AppButton
        fullWidth={!horizontal}
        isDisabled={isSubmitting}
        color="primaryLight"
        text={t(`invoices.cancel_${values.type}`)}
        onClick={() => {
          toggleApprovalDialog();
        }}
      />
      <div className={separatorStyle} />
    </Fragment>
  );
};

CancelButton.propTypes = {
  separatorStyle: PropTypes.string,
  invoiceId: PropTypes.string,
  horizontal: PropTypes.bool,
};

CancelButton.defaultProps = {
  horizontal: false,
};

export default CancelButton;
