import React from 'react';
import { useTranslation } from 'react-i18next';

/* import MUI */
import { Box, Typography } from '@material-ui/core';
import MaterialTable from 'components/MaterialTable';

/* import styles */
import useStyles from './styles';

const BankBBFAccountDebitManagement = () => {
  const { t } = useTranslation();
  const classNames = useStyles();

  // const tableRef = useRef;

  const translateDomain = 'debits_page.management_page';

  const columns = [
    {
      title: t(`${translateDomain}.columns.creation_date`),
      field: 'creationDate',
    },
    {
      title: t(`${translateDomain}.columns.rum`),
      field: 'rum',
    },
    {
      title: t(`${translateDomain}.columns.receiver`),
      field: 'receiver',
    },
    {
      title: t(`${translateDomain}.columns.amount`),
      field: 'amount',
    },
    {
      title: t(`${translateDomain}.columns.payment_type`),
      field: 'paymentType',
    },
    {
      title: t(`${translateDomain}.columns.debit_date`),
      field: 'debitDate',
    },
    {
      title: t(`${translateDomain}.columns.status`),
      field: 'status',
    },
  ];

  return (
    <Box className={classNames.accountDebitSection}>
      <Typography variant="h5">{t(`${translateDomain}.title`)}</Typography>
      <Typography className={classNames.accountDebitText}>
        {t(`${translateDomain}.sub_title`)}
      </Typography>
      <Box className={classNames.accountDebitTableContainer}>
        <MaterialTable title="Debit" columns={columns} />
      </Box>
    </Box>
  );
};

export default BankBBFAccountDebitManagement;
