import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  Button,
} from '@material-ui/core';

import { Close } from '@material-ui/icons';

class CustomDialog extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { open: true };
  }

  _renderButtons() {
    const { showButtons, onConfirm, onCancel, confirmText, cancelText } =
      this.props;

    if (showButtons) {
      return (
        <DialogActions>
          <Button onClick={onConfirm} color="primary">
            {confirmText}
          </Button>
          <Button onClick={onCancel} color="primary">
            {cancelText}
          </Button>
        </DialogActions>
      );
    }

    return null;
  }

  render() {
    const {
      title,
      contentText,
      children,
      closeDialog,
      userLoggedIn,
      setFullWidth,
    } = this.props;

    return (
      <Dialog
        open={this.state.open}
        fullWidth={true}
        fullScreen={setFullWidth}
        aria-labelledby="responsive-dialog-title"
        onClose={userLoggedIn && closeDialog}
      >
        {title && (
          <DialogTitle
            style={{ textAlign: 'center' }}
            id="responsive-dialog-title"
          >
            {title}
          </DialogTitle>
        )}

        <DialogContent>
          {userLoggedIn && (
            <Button
              onClick={closeDialog}
              style={{
                position: 'absolute',
                right: '2%',
                top: '2%',
                width: '30px',
                height: '30px',
              }}
            >
              <Close />
            </Button>
          )}
          {contentText && <DialogContentText>{contentText}</DialogContentText>}
          {children}
        </DialogContent>

        {this._renderButtons()}
      </Dialog>
    );
  }
}

CustomDialog.propTypes = {
  title: PropTypes.string,
  contentText: PropTypes.string,
  children: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  closeDialog: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,

  showButtons: PropTypes.bool,
  userLoggedIn: PropTypes.bool,
  setFullWidth: PropTypes.bool,
};

CustomDialog.defaultProps = {
  setFullWidth: false,
  contentText: '',
};

export default CustomDialog;
