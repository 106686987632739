import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { AppRowCenterStart } from 'components/AppContainers';
import AppPlusSelect from 'components/AppPlusSelect';
import Header from 'components/Header';
// import Refresh from 'components/Refresh';

// import { useThunkDispatch } from 'hooks/generalHooks';
import { useRouter } from 'hooks/routerHooks';

// import { fetchExpenses } from 'actions/ExpensesActions';

import { userCanCreateExpense } from 'selectors/rightsSelector/expenseRequests';
import { userCanCreateAdvanceExpense } from 'selectors/rightsSelector/advanceExpenseRequests';

const ExpenseListHeader = () => {
  // const { refreshing, canReadExpense, toggleRefresh } = props;
  const canCreateExpense = useSelector(userCanCreateExpense);
  const canCreateAdvanceExpense = useSelector(userCanCreateAdvanceExpense);

  const { t } = useTranslation();
  const { history } = useRouter();
  // const dispatch = useThunkDispatch();

  // const fetchExpensesCb = useCallback(() => dispatch(fetchExpenses()), [
  //   dispatch,
  // ]);

  function renderCreateButton() {
    const types = [];

    if (canCreateExpense) {
      types.push({
        name: t('expenses.expense.expense'),
        type: 'expense',
        onClick: () =>
          history.push({
            pathname: '/expenses/new',
            state: {
              payment: 'personal',
            },
          }),
      });
    }

    if (canCreateAdvanceExpense) {
      types.push({
        name: t('expenses.advance.card_title'),
        type: 'advance_expense',
        onClick: () => history.push('/expenses/advance/new'),
      });
    }

    return (
      <AppPlusSelect items={types} name="name" label={t('create_expense')} />
    );
  }

  return (
    <Header name={t(`expenses.expense.title`)} spaceBetween>
      <AppRowCenterStart>
        {/* <Refresh
          loadData={fetchExpensesCb}
          loading={refreshing}
          callback={toggleRefresh}
          preFn={toggleRefresh}
          disabled={!canReadExpense}
        /> */}
      </AppRowCenterStart>
      {renderCreateButton()}
    </Header>
  );
};

ExpenseListHeader.propTypes = {
  refreshing: PropTypes.bool.isRequired,
  canReadExpense: PropTypes.bool.isRequired,
  toggleRefresh: PropTypes.func.isRequired,
};

export default ExpenseListHeader;
