import { Badge, Grid, IconButton, Popover, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterList from '@material-ui/icons/FilterList';
import CloseIcon from '@material-ui/icons/Close';

import { useTranslation } from 'react-i18next';

const FilterPopover = ({ children, clearFilters, filters }) => {
  const { t } = useTranslation();
  const [openPopover, setOpenPopover] = useState(false);

  return (
    <>
      <Tooltip title={t('popover.filters')}>
        <IconButton onClick={() => setOpenPopover(!openPopover)}>
          <Badge variant="dot" color="error" invisible={!filters}>
            <FilterList />
          </Badge>
        </IconButton>
      </Tooltip>

      <Popover open={openPopover} onClose={() => setOpenPopover(false)}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          style={{ padding: 20 }}
        >
          <Tooltip title={t('popover.clear')}>
            <IconButton onClick={clearFilters}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('close.tab')}>
            <IconButton onClick={() => setOpenPopover(!openPopover)}>
              <CloseIcon />
            </IconButton>
          </Tooltip>
          {children}
        </Grid>
      </Popover>
    </>
  );
};

FilterPopover.propTypes = {
  children: PropTypes.object,
  clearFilters: PropTypes.func,
  filters: PropTypes.bool,
};

export default FilterPopover;
