import * as Yup from 'yup';

const validation = Yup.object({
  amount: Yup.number().required('Required!'),
  name: Yup.string(),
  rmlcKey: Yup.string().min(2, 'Minimum 2 characters').required('Required!'),
  cmcA: Yup.string().required('Required!'),
  cmcB: Yup.string().required('Required!'),
  cmcC: Yup.string().required('Required!'),
});

export default validation;
