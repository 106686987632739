import React from 'react';
import PropTypes from 'prop-types';
import Formula from './FormulaPage';

const PricingPage = ({
  type,
  onClick,
  isDescriptions,
  isValidatePg = false,
}) => {
  return (
    <Formula
      type={type}
      handleChoose={onClick}
      isDescriptions={isDescriptions}
      isValidatePg={isValidatePg}
    />
  );
};

PricingPage.propTypes = {
  onClick: PropTypes.func,
  type: PropTypes.string,
  isDescriptions: PropTypes.bool,
  isValidatePg: PropTypes.bool,
};

export default PricingPage;
