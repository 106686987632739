import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { FormControl, Button, Box } from '@material-ui/core';
import {
  COMPANIES_RENEW,
  COMPANY_STATUS,
  COMPANY_TYPE,
} from 'constants/company';
import logger from 'helpers/logger';
import localStorageHelper from 'helpers/localStorageHelper';
import PricingUpdateDialog from 'components/PricingUpdateDialog';
import AppRadioGroup from '../AppRadioGroup';
import AppRadioButton from '../AppRadioButton';
import AppButton from '../AppButton';
import CustomDialog from '../CustomDialog';
import LoadingDialog from '../LoadingDialog';
import styles from '../../assets/jss/root';
import { titleText } from '../../assets/jss/components/appText';

import state from './state';
import dispatch from './dispatch';

class ChooseCompany extends Component {
  constructor(props) {
    super(props);

    const {
      loggedUserCompany: { companies, company },
    } = props;

    this.state = {
      value: companies.findIndex((item) =>
        company
          ? item._id === company._id
          : !ChooseCompany.isCompanyDisabled(item)
      ),
      fetchCompanyData:
        !company &&
        companies.length === 1 &&
        !companies[0].isSubscriptionMigrated,
      fetchCompaniesData: false,
      isSubscriptionMigrated:
        companies.length === 1 ? companies[0].isSubscriptionMigrated : false,
      showCompanyDialog: true,
      showConfirmDialog: false,
    };

    this._handleChange = this._handleChange.bind(this);
    this._onSubmit = this._onSubmit.bind(this);
    this._onDataFetched = this._onDataFetched.bind(this);
    this._clickChooseCompany = this._clickChooseCompany.bind(this);
    this.companyRenews = localStorageHelper.getItem(COMPANIES_RENEW) || {};
  }

  componentDidMount() {
    const {
      loggedUserCompany: { companies, company },
    } = this.props;

    if (!company && companies.length === 1) {
      this._selectFirstCompany();
    } else {
      this.setState({ fetchCompaniesData: true });
      this.props.fetchLoggedUserCompanies(() => {
        this.setState({ fetchCompaniesData: false });
      });
    }
  }

  _selectFirstCompany() {
    this.setState({ fetchCompanyData: true });
    this.props.selectedCompany(this.state.value);
    this._fetchCompanyData();
  }

  _onDataFetched() {
    const { history, closeDialog } = this.props;
    this.setState({ fetchCompanyData: false });
    if (closeDialog) {
      closeDialog();
    }

    const url = window.location.href;
    const domain = window.location.hostname;
    const expr = `(http|https)://${domain}(:[0-9]+)?`;
    const rx = new RegExp(expr, 'g');
    history.push(url.replace(rx, ''));
  }

  _fetchCompanyData() {
    logger.debug('ChooseCompany triggers fetchCompanyUserData()');
    this.props.fetchCompanyUserData(this._onDataFetched);
  }

  _fetchCompanyLoading() {
    const {
      loggedUserCompany: { company, companies },
      t,
    } = this.props;

    const title = `${t('loading')} ${
      company ? company.name : companies[0].name
    }`;
    return <LoadingDialog title={title} />;
  }

  _fetchCompaniesLoading() {
    const { t } = this.props;
    const title = `${t('loading_companies')}`;
    return <LoadingDialog title={title} />;
  }

  _onSubmit() {
    const { selectedCompany } = this.props;
    selectedCompany(this.state.value);
    this.setState({ fetchCompanyData: true });
    this._fetchCompanyData();
  }

  _handleChange(event) {
    const {
      loggedUserCompany: { companies },
    } = this.props;

    this.setState({ value: event.target.value });
    this.setState({
      isSubscriptionMigrated:
        companies[+event.target.value]?.isSubscriptionMigrated || false,
    });
  }

  _clickChooseCompany() {
    const { isSubscriptionMigrated, value } = this.state;
    const {
      loggedUserCompany: { companies },
      selectedCompanyId,
    } = this.props;
    if (isSubscriptionMigrated) {
      this.setState({ showCompanyDialog: false, showConfirmDialog: true });
      selectedCompanyId(companies[+value]._id);
    } else {
      this._onSubmit();
    }
  }

  static isCompanyDisabled(company) {
    return (
      company?.status === COMPANY_STATUS.DEACTIVATED &&
      company.type !== COMPANY_TYPE.ADMIN
    );
  }

  render() {
    const {
      loggedUserCompany: { companies, company },
      t,
      closeDialog,
      userLoggedIn,
      classes,
    } = this.props;

    const { showCompanyDialog, showConfirmDialog } = this.state;

    if (this.state.fetchCompanyData) return this._fetchCompanyLoading();

    if (this.state.fetchCompaniesData) return this._fetchCompaniesLoading();

    if (!companies.length) {
      return (
        <CustomDialog
          title={t('no_company')}
          contentText={t('you_have_no_company')}
        >
          <Button
            onClick={() => {
              window.location.href = '/logout';
            }}
            variant="contained"
            className={classes.button}
            fullWidth={true}
          >
            OK
          </Button>
        </CustomDialog>
      );
    }

    if (
      !company &&
      companies.length === 1 &&
      !ChooseCompany.isCompanyDisabled(company)
    ) {
      this._selectFirstCompany();
    }

    return (
      <>
        {showCompanyDialog && (
          <CustomDialog closeDialog={closeDialog} userLoggedIn={userLoggedIn}>
            <FormControl
              component="fieldset"
              style={{
                width: '50%',
                margin: '0 25%',
              }}
            >
              <p style={titleText}>{t('please_choose_the_company')}</p>

              <AppRadioGroup
                color="secondary"
                name="gender1"
                valueSelected={`${this.state.value}`}
                onChange={this._handleChange}
              >
                {companies.map((item, index) => (
                  <Box
                    key={index}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <AppRadioButton
                      value={`${index}`}
                      text={item.name}
                      disabled={ChooseCompany.isCompanyDisabled(item)}
                    />
                    {ChooseCompany.isCompanyDisabled(item) &&
                      !this.companyRenews[item._id] && (
                        <a
                          style={{ textDecoration: 'underline' }}
                          target="_blank"
                          rel="noreferrer"
                          href={`${process.env.REACT_APP_API_URL_NO_VERSION}/subscription/checkout-session?companyId=${item._id}`}
                        >
                          {t('subscription.renew_subscription')}
                        </a>
                      )}
                  </Box>
                ))}
              </AppRadioGroup>
              <AppButton
                onClick={this._clickChooseCompany}
                type="submit"
                fullWidth={true}
                text="OK"
                color="secondaryLight"
                noBorder={true}
              />
            </FormControl>
          </CustomDialog>
        )}
        <PricingUpdateDialog
          open={showConfirmDialog}
          onContinue={this._onSubmit}
        />
      </>
    );
  }
}

ChooseCompany.propTypes = {
  classes: PropTypes.object.isRequired,
  companies: PropTypes.array,
  t: PropTypes.func,
  closeDialog: PropTypes.func,
  userLoggedIn: PropTypes.bool,
  loggedUser: PropTypes.object,
  loggedUserCompany: PropTypes.object,
  isAuthenticated: PropTypes.func.isRequired,
  selectedCompany: PropTypes.func,
  history: PropTypes.object,
  fetchCompanyUserData: PropTypes.func,
  fetchLoggedUserCompanies: PropTypes.func,
  selectedCompanyId: PropTypes.func,
  dispatch: PropTypes.func,
};

const TranslatedChooseCompany = withTranslation()(ChooseCompany);
const ChooseCompanyWithStyle = withStyles(styles, { withTheme: true })(
  TranslatedChooseCompany
);
const ChooseCompanyWithRouter = withRouter(ChooseCompanyWithStyle);

export default connect(state, dispatch)(ChooseCompanyWithRouter);
