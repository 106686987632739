import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  accountDebitSection: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '20px',
  },

  accountDebitText: {
    margin: '20px 0',
    textAlign: 'center',
  },

  accountDebitTableContainer: {
    width: '100%',
  },
}));

export default useStyles;
