import _ from 'lodash';
import { store } from '../store';

/* eslint import/prefer-default-export: off */
export const getUserById = (id) => {
  const { users } = store.getState();

  if (!_.isEmpty(users)) {
    return users[id];
  }

  return undefined;
};
