import authService from 'services/AuthService';
import signUpService from 'services/SignUpService';
import s3FileService from 'services/S3FileService';
import companyService from 'services/CompanyService';

import { getFileExtension } from 'helpers/utils';
import logger from 'helpers/logger';

import { FETCH_LOGGED_USER, LOGOUT_USER_SUCCESS } from '../Types';

export const isAuthenticated = () => (dispatch, getState) => {
  const {
    loggedUser: { user },
  } = getState();

  return user && authService.isAuthenticated() && authService.getUserId();
};

export const isUserHasRights = () => (dispatch, getState) => {
  const {
    loggedUserRights: { roles },
  } = getState();

  // if roles is an array and empty -> return false
  if (roles.length === 0) {
    return false;
  }
  return true;
};

export const login = (url) => (dispatch) => {
  logger.info('[action] login()');
  dispatch({ type: FETCH_LOGGED_USER });
  authService.login(url);
};

export const handleAuthentication = (cb) => () =>
  authService
    .handleAuthentication()
    .then(() => cb())
    .catch(cb);

export const logout = () => (dispatch) => {
  logger.info('[action] logout()');
  authService.logout();
  dispatch({ type: LOGOUT_USER_SUCCESS });
};

export const signupWithRemoveApiVersion = (values, cb) => async () => {
  try {
    let { logoFile, userFile } = values;

    const email = values.email.toLowerCase();
    const data = { ...values, logoFile, userFile, email };

    const createCompanyResult = await signUpService.createCompany(data);
    if (createCompanyResult) {
      await signUpService.connectSubscriptionRemoveApiVersion(
        data.subscriptionId,
        createCompanyResult.data._id
      );
    }

    if (userFile) {
      const ext = getFileExtension(userFile.name);

      const folder = `company-files-${createCompanyResult.alternativeId}`;
      const fileName = `${
        createCompanyResult._id
      }-id-${new Date().toISOString()}.${ext}`;

      await s3FileService.upload(userFile, folder, fileName);
      userFile = `${folder}/${fileName}`;

      await companyService.updateCompany({ ...createCompanyResult, userFile });
    }

    if (logoFile) {
      const ext = getFileExtension(logoFile.name);

      const folder = `company-files-${createCompanyResult.alternativeId}`;
      const fileName = `${
        createCompanyResult._id
      }-${new Date().toISOString()}.${ext}`;

      await s3FileService.upload(logoFile, `${folder}/${fileName}.${ext}`);
      logoFile = `${folder}/${fileName}`;

      await companyService.updateCompany({ ...createCompanyResult, logoFile });
    }

    cb();
  } catch (error) {
    cb(error);
  }
};
export const signup = (values, cb) => async () => {
  try {
    let { logoFile, userFile } = values;

    const email = values.email.toLowerCase();
    const data = { ...values, logoFile, userFile, email };

    const createCompanyResult = await signUpService.createCompany(data);
    if (data.utm.session_id) {
      await signUpService.connectSubscription(
        data.utm.session_id,
        createCompanyResult.data._id
      );
    }

    if (userFile) {
      const ext = getFileExtension(userFile.name);

      const folder = `company-files-${createCompanyResult.alternativeId}`;
      const fileName = `${
        createCompanyResult._id
      }-id-${new Date().toISOString()}.${ext}`;

      await s3FileService.upload(userFile, folder, fileName);
      userFile = `${folder}/${fileName}`;

      await companyService.updateCompany({ ...createCompanyResult, userFile });
    }

    if (logoFile) {
      const ext = getFileExtension(logoFile.name);

      const folder = `company-files-${createCompanyResult.alternativeId}`;
      const fileName = `${
        createCompanyResult._id
      }-${new Date().toISOString()}.${ext}`;

      await s3FileService.upload(logoFile, `${folder}/${fileName}.${ext}`);
      logoFile = `${folder}/${fileName}`;

      await companyService.updateCompany({ ...createCompanyResult, logoFile });
    }

    cb();
  } catch (error) {
    cb(error);
  }
};

export const renewToken = (cb) => async (/* dispatch */) => {
  logger.info('[action] renewToken()');
  try {
    await authService.renewToken();
    cb();
  } catch (e) {
    logger.error(e);
    // dispatch(logout());
    cb(e);
  }
};
