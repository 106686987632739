import { authInstance as axios } from '../../helpers/axiosInterceptor';

const fetchEmailTemplates = async (filter) =>
  axios.get(`${process.env.REACT_APP_EMAIL_TEMPLATE_URL}/emails`, {
    params: filter,
  });

const createEmailTemplate = async (data) =>
  axios.post(`${process.env.REACT_APP_EMAIL_TEMPLATE_URL}/emails`, data);

const updateEmailTemplate = async (id, data) =>
  axios.put(`${process.env.REACT_APP_EMAIL_TEMPLATE_URL}/emails/${id}`, data);

export default {
  fetchEmailTemplates,
  createEmailTemplate,
  updateEmailTemplate,
};
