import React from 'react';
import PropTypes from 'prop-types';

import { Grid } from '@material-ui/core';

import { Field } from 'formik';
import logger from 'helpers/logger';
import TextField from '../../FormElements/textField';
import PasswordStrengthMeter from '../../FormElements/PasswordStrengthMeter';
import AppButton from '../../AppButton';
import { AppGreyDivider, AppGreenGreyDivider } from '../../AppDividers';
import { AppTextHeader } from '../../AppText';
import capitalizeForm from '../../../helpers/capitalizeForm';
import { isValidEmail } from '../../../helpers/utils';

import userervice from '../../../services/UserService';

import ProgressGreen from '../../../assets/images/progress_green.svg';
import ProgressGrey from '../../../assets/images/progree_grey.svg';

const AppUserInfo = ({
  t,
  errors,
  values,
  nextStage,
  touched,
  setFieldValue,
  toggleEmailIndicator,
  showUserExistsDialog,
}) => {
  logger.log('formik', { errors });

  const isActive =
    (touched.userName || values.userName.length > 0) &&
    !errors.userName &&
    (touched.firstname || values.firstname.length > 0) &&
    !errors.firstname &&
    (touched.email || values.email.length > 0) &&
    !errors.email &&
    !errors.userPassword &&
    !errors.confirmPassword;

  function checkIfUserExists() {
    if (isValidEmail(values.email)) {
      setTimeout(() => {
        toggleEmailIndicator();
        userervice.getUserByEmail(values.email).then(({ data: { exists } }) => {
          toggleEmailIndicator();
          showUserExistsDialog(exists);
        });
      }, 300);
    }
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} container justify="center">
        <AppTextHeader>{t('signup.company.step1')}</AppTextHeader>
      </Grid>

      <Grid item xs={12} container justify="center">
        <img alt="" src={ProgressGreen} />
        <AppGreenGreyDivider />
        <img alt="" src={ProgressGrey} />
        <AppGreyDivider />
        <img alt="" src={ProgressGrey} />
      </Grid>

      {/* Row with Email */}
      <Grid item xs={12}>
        <TextField
          type="text"
          name="email"
          label={t('signup.company.email')}
          placeholder={t('signup.company.email')}
          color="secondary"
          required
          onBlur={checkIfUserExists}
        />
      </Grid>

      {/* Row with Firstname and lastname */}
      <Grid item xs={6}>
        <TextField
          type="text"
          name="userName"
          label={t('signup.name')}
          placeholder={t('signup.name')}
          color="secondary"
          required
          onChange={(e) => {
            capitalizeForm(e, setFieldValue);
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          type="text"
          name="firstname"
          label={t('signup.first_name')}
          placeholder={t('signup.first_name')}
          color="secondary"
          required
          onChange={(e) => {
            capitalizeForm(e, setFieldValue);
          }}
        />
      </Grid>

      {/* Row with password and confirm password */}
      <Grid item xs={12} sm={6}>
        <Field
          as={PasswordStrengthMeter}
          variant="outlined"
          margin="dense"
          type="password"
          name="userPassword"
          label={t('auth.password')}
          placeholder={t('auth.password')}
          helperText={t('signup.password_helper')}
          color="secondary"
          required
          withStrengthMeter
        />
        {/* <TextField
          type="password"
          name="userPassword"
          label={t('auth.password')}
          placeholder={t('auth.password')}
          helperText={t('signup.password_helper')}
          color="secondary"
          required
          withPasswordVisibility
        /> */}
      </Grid>

      <Grid item xs={12} sm={6}>
        <Field
          as={PasswordStrengthMeter}
          variant="outlined"
          margin="dense"
          type="password"
          name="confirmPassword"
          label={t('auth.confirm_password')}
          placeholder={t('auth.confirm_password')}
          color="secondary"
          required
        />
        {/* <TextField
          type="password"
          name="confirmPassword"
          label={t('auth.confirm_password')}
          placeholder={t('auth.confirm_password')}
          color="secondary"
          required
          withPasswordVisibility
        /> */}
      </Grid>

      <Grid item xs={12} container justify="flex-end">
        <AppButton
          color="secondaryLight"
          text={t('next')}
          onClick={nextStage}
          noBorder
          isDisabled={!isActive}
        />
      </Grid>
    </Grid>
  );
};
AppUserInfo.propTypes = {
  t: PropTypes.func,
  errors: PropTypes.object,
  values: PropTypes.object,
  touched: PropTypes.object,
  uploadErrors: PropTypes.func,
  nextStage: PropTypes.func,
  onSelectLogo: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  toggleEmailIndicator: PropTypes.func.isRequired,
  showUserExistsDialog: PropTypes.func.isRequired,
};
AppUserInfo.propTypes = {};

export default AppUserInfo;
