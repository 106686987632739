import React, { useEffect } from 'react';
import { Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { map, isObject, find } from 'lodash';
import Grid from '@material-ui/core/Grid';
import { INSEE as codeList } from '@bebusinessfocus/compta-hub-core';
import TextField from '../../FormElements/textField';
import SelectAutoComplete from '../../SelectAutocomplete';
import legalFormArray from '../../../assets/legalForm';

const AppContractorInfoSettings = ({
  t,
  values,
  setFieldValue,
  onCodeSelected,
  onBlur,
  onSirenChange,
  onZipcodeCityChange,
  isOnboarding = false,
  isCompanySetting = false,
  companyType,
}) => {
  const { isContractor } = values;

  const findDefaultLegalForm = () => {
    const type =
      (companyType || values.companyType) === 'Auto-entrepreneur'
        ? 'Auto entrepreneur/Micro entreprise'
        : companyType || values.companyType;

    const legalFormDefault = legalFormArray.find(
      (item) => item.display === type
    );
    if (legalFormDefault) {
      setFieldValue('legal_form', legalFormDefault.display);
      setFieldValue('legal_form_id', legalFormDefault.code);
    } else {
      setFieldValue('legal_form', '');
      setFieldValue('legal_form_id', undefined);
    }
  };

  useEffect(() => {
    findDefaultLegalForm();
  }, []);

  return (
    <>
      {/* Section with Company name and Business name */}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            type="text"
            name="business_name"
            label={t('signup.company.business_name')}
            placeholder={t('signup.company.business_name')}
            color="secondary"
            fullWidth
          />
        </Grid>
      </Grid>
      {/* Section with Address, Zip code and City */}
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={6}>
          <TextField
            type="text"
            name="address"
            label={t('signup.company.address')}
            placeholder={t('signup.company.address')}
            color="secondary"
            required
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            name="zipcode"
            label={t('signup.company.zipcode')}
            placeholder={t('signup.company.zipcode')}
            color="secondary"
            onBlur={onBlur}
            onChange={onZipcodeCityChange}
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            name="city"
            label={t('signup.company.city')}
            placeholder={t('signup.company.city')}
            color="secondary"
            onBlur={onBlur}
            onChange={onZipcodeCityChange}
            required
            fullWidth
          />
        </Grid>
      </Grid>
      {/* Section with Legal form, SIREN, NIC and RCS */}
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} sm={6}>
          {isOnboarding ? (
            <SelectAutoComplete
              mandatory
              required
              name="legal_form"
              label={t('signup.company.legal_form')}
              getOptionLabel={(elem) => elem.display}
              values={map(legalFormArray, (el) => el)}
              valueSelected={
                find(
                  legalFormArray,
                  (elem) => elem.display === values.legal_form
                ) ||
                find(
                  legalFormArray,
                  (elem) => elem.code === values.legal_form_id
                )
              }
              readOnly={isContractor}
            />
          ) : (
            <TextField
              type="text"
              name="phone_number"
              label={t('signup.company.phonenumber')}
              placeholder={t('signup.company.phonenumber')}
              color="secondary"
              fullWidth
            />
          )}
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            name="siren"
            label={t('signup.company.siren')}
            placeholder={t('signup.company.siren')}
            onChange={onSirenChange}
            color="secondary"
            required
            fullWidth
          />
        </Grid>

        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            name="nic"
            required
            label={t('signup.company.nic')}
            placeholder={t('signup.company.nic')}
            color="secondary"
            fullWidth
          />
          <Typography color="textSecondary" style={{ fontSize: 10 }}>
            {t('signup.company.nic_info')}
          </Typography>
        </Grid>
      </Grid>
      {/* Section with Activity code */}
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid item xs={isCompanySetting ? 6 : 12}>
          <SelectAutoComplete
            mandatory
            name="code_ape"
            label={t('signup.company.code_ape')}
            getOptionLabel={(elem) => {
              if (!elem || (elem && isObject(elem) && !elem.Code)) {
                return '';
              }

              if (elem.Code) {
                return `${elem.Code} - ${elem.Intitulés_NAF}`;
              }

              const item = codeList.find((i) => i.Code === elem);

              if (item) {
                return `${item.Code} - ${item.Intitulés_NAF}`;
              }

              return elem;
            }}
            values={map(codeList, (ac) => ac)}
            valueSelected={values.code_ape || undefined}
            onChange={onCodeSelected}
          />
        </Grid>
        {!isOnboarding && (
          <Grid item xs={12} sm={6}>
            <SelectAutoComplete
              mandatory
              required
              name="legal_form"
              label={t('signup.company.legal_form')}
              getOptionLabel={(elem) => elem.display}
              values={map(legalFormArray, (el) => el)}
              valueSelected={
                find(
                  legalFormArray,
                  (elem) =>
                    elem.display ===
                    (values.companyType === 'Auto-entrepreneur'
                      ? 'Auto entrepreneur/Micro entreprise'
                      : values.companyType)
                ) ||
                find(
                  legalFormArray,
                  (elem) => elem.code === values.legal_form_id
                )
              }
              readOnly={isContractor}
            />
          </Grid>
        )}
      </Grid>
      {/* Treezor Informations */}
      <Grid container spacing={2} direction="row" alignItems="center">
        {values.legalRegistrationDate && (
          <Grid item xs={12} sm={3}>
            <TextField
              type="text"
              name="legalRegistrationDate"
              label={t('legal_registration_date')}
              color="secondary"
              fullWidth
              disabled
            />
          </Grid>
        )}
        {values.legalRegistrationDate && (
          <Grid item xs={12} sm={3}>
            <TextField
              type="text"
              name="legalAnnualTurnOver"
              label={t('legal_annual_turn_over')}
              color="secondary"
              fullWidth
              disabled
            />
          </Grid>
        )}
        {values.legalRegistrationDate && (
          <Grid item xs={12} sm={3}>
            <TextField
              type="text"
              name="legalShareCapital"
              label={t('capital')}
              color="secondary"
              fullWidth
              disabled
            />
          </Grid>
        )}
        {values.legalRegistrationDate && (
          <Grid item xs={12} sm={3}>
            <TextField
              type="text"
              name="legalNumberOfEmployeeRange"
              label={t('legal_number_of_employee_range')}
              color="secondary"
              fullWidth
              disabled
            />
          </Grid>
        )}
      </Grid>
    </>
  );
};
AppContractorInfoSettings.propTypes = {
  t: PropTypes.func,
  values: PropTypes.object,
  onCodeSelected: PropTypes.func,
  setFieldValue: PropTypes.func,
  onBusinesLogoSelected: PropTypes.func,
  onSirenChange: PropTypes.func,
  onBlur: PropTypes.func,
  isOnboarding: PropTypes.bool,
  onZipcodeCityChange: PropTypes.func,
  isCompanySetting: PropTypes.bool,
  companyType: PropTypes.string,
};
AppContractorInfoSettings.propTypes = {};

export default AppContractorInfoSettings;
