import { fillsDarkGrey, inputText, mWhite } from './appText';
import colors from '../../../constants/colors.json';

const style = {
  // TABLE
  table: {
    flex: 1,
    width: '100%',
    borderCollapse: 'collapse',
    borderSpacing: 0,
  },

  // TABLE COLGROUP

  colGroupTest: {
    backgroundColor: 'pink',
  },
  widthText: {
    backgroundColor: 'yellow',
    width: '30%',
  },

  // TABLE HEAD

  tableHead: {
    '& tr:nth-child(odd)': {
      backgroundColor: colors.white,
    },
  },

  // TABLE BODY

  tableBody: {
    '& tr:nth-child(odd)': {
      backgroundColor: colors.background,
    },
  },

  // TABLE ROW

  tableRow: {
    display: 'flex',
    flexDirection: 'row',
    alighItems: 'center',
  },

  // TABLE CELL

  tableCell: {
    ...inputText,
    flex: 1,
    height: '50px',
    padding: '9px',
  },
  alignCenter: {
    textAlign: 'center',
  },
  alignRight: {
    textAlign: 'right',
  },
  alignLeft: {
    textAlign: 'left',
  },
  alignRightContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-end',
    // backgroundColor: 'pink',
  },
  headerCell: {
    ...fillsDarkGrey,
    textTransform: 'uppercase',
  },
  greyHeader: {
    ...mWhite,
    textTransform: 'uppercase',
    backgroundColor: '#6D7278',
    height: '30px',
  },
  headerInnerDiv: {
    display: 'flex',
    flexDirection: 'row',
    alighItems: 'center',
    justifyContent: 'flex-start',
  },
  sortIcon: {
    paddingLeft: '10px',
  },
};

export default style;
