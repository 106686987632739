/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TreezorCardConfigForm from 'components/CardComponents/NewTreezorCardConfig/treezorCardConfigForm';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  createPhysicalCard,
  createVirtualCard,
  configCard,
} from 'actions/CardActions';
import { Formik } from 'formik';
import initialValues from './initalValues';
import { prepareData } from './handleSubmit';
import validationSchema from './validation';
import { useRouter } from '../../../../hooks/routerHooks';
import { TYPE_AUTO_ENTREPRENEUR } from 'constants/company';
import qs from 'qs';

const CardFormFormik = ({ virtual, closeCardConfig, cardId }) => {
  const { wallet } = useSelector((state) => state.wallet);
  const { treezorUsers } = useSelector((state) => state.treezor);
  const { users, loggedUserCompany, loggedUser } = useSelector(
    (state) => state
  );
  let selectedTreezorCard = null;
  const dispatch = useDispatch();
  const { history } = useRouter();
  const { userId } = qs.parse(history.location.search, {
    ignoreQueryPrefix: true,
  });
  const [otp, setOtp] = useState(null);
  const [openOTP, setOpenOTP] = useState(false);

  if (cardId) {
    selectedTreezorCard = useSelector((reduxState) => {
      return reduxState.cardReducer.cards.find((e) => e.cardId === cardId);
    });
  }

  const onSubmit = async (values, actions) => {
    let user = null;
    if (values.userId) {
      user = users[values.userId];
    } else {
      user = loggedUser.user;
    }
    const { companyType } =
      loggedUserCompany?.company?.settings?.tax_registration;
    let treezorUser = null;
    console.log('treezorUsers', treezorUsers);
    console.log('user', user);

    if (companyType === TYPE_AUTO_ENTREPRENEUR) {
      treezorUser = treezorUsers.find(
        (tUser) => tUser.userTag === loggedUserCompany.company._id
      );
    } else {
      treezorUser = treezorUsers.find((tUser) => tUser.userTag === user._id);
    }
    const formatedData = prepareData(
      user,
      !!selectedTreezorCard,
      values,
      wallet,
      treezorUsers || selectedTreezorCard,
      treezorUser,
      virtual,
      otp
    );

    let response;

    actions.setSubmitting(true);
    if (selectedTreezorCard) {
      const { _id: cardOId } = selectedTreezorCard;
      if (cardOId) {
        const res = await dispatch(configCard(cardOId, formatedData));
        if (res) {
          setOpenOTP(false);
        }
      }
    } else {
      if (virtual) {
        response = await dispatch(createVirtualCard(formatedData));
      } else {
        response = await dispatch(createPhysicalCard(formatedData));
      }
    }

    actions.setSubmitting(false);
    if (response._id) {
      if (virtual) {
        history.push(`/card/${response._id}`);
      } else {
        history.push(`/cards`);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues(selectedTreezorCard, userId)}
      validationSchema={validationSchema(virtual, !!selectedTreezorCard)}
      onSubmit={onSubmit}
    >
      <TreezorCardConfigForm
        virtual={virtual}
        closeCardConfig={closeCardConfig}
        cardId={cardId}
        setOtp={setOtp}
        openOTP={openOTP}
        setOpenOTP={setOpenOTP}
      />
    </Formik>
  );
};

CardFormFormik.propTypes = {
  closeCardConfig: PropTypes.func,
  virtual: PropTypes.bool,
  cardId: PropTypes.number,
};

export default CardFormFormik;
