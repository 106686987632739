export const EMPLOYE = 'Employé';
export const REPRESENTANT_LEGAL = 'Représentant légal';
export const ASSOCIE = 'Associé';
export const roles = [REPRESENTANT_LEGAL, ASSOCIE, EMPLOYE];
export const roles2 = [REPRESENTANT_LEGAL, ASSOCIE];
export const PARENT_TYPE_RL = 'leader';
export const PARENT_TYPE_BE = 'shareholder';
export const PARENT_TYPE_EMPLOYEE = 'employee';

export const ROLE = {
  ADMINISTRATEUR: 'Administrateur',
  UTILISATEUR_FRAIS: 'Utilisateur Frais',
  UTILISATEUR_FACTURATION: 'Utilisateur Facturation',
  EXPERT_COMPTABLE: 'Expert comptable (externe)',
  UTILISATEUR_COMPTABILITE: 'Utilisateur comptabilité',
  UTILISATEUR_STOCK: 'Utilisateur stock',
  UTILISATEUR_ACHAT: 'Utilisateur Achat',
};
