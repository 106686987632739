import {
  UPDATE_TREEZOR_COMPANY,
  UPDATE_TREEZOR_USER,
  TREEZOR_CGU_FORM,
  CGU_FORM,
  SET_ROLE,
  SET_SHOW_ADD_COLLABORATEUR,
  SET_STEP,
  IS_PERSONNE_MORALE,
  IS_NEW_USER,
  CREATE_NEW_USER,
} from './TreezorType';

const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_TREEZOR_COMPANY:
      return { ...state, company: action.payload, closePanelCompany: true };
    case UPDATE_TREEZOR_USER: {
      /* eslint-disable-next-line */
      const { user } = state;
      /* eslint-disable-next-line */
      const index = user.findIndex((u) => u._id === action.payload._id);
      if (index !== -1) {
        user[index] = action.payload;
      } else {
        user.push(action.payload);
      }
      return { ...state, user };
    }
    case SET_SHOW_ADD_COLLABORATEUR: {
      return { ...state, showAddCollaborateur: action.payload };
    }
    case CGU_FORM: {
      return { ...state, cgu: action.payload };
    }
    case TREEZOR_CGU_FORM: {
      return { ...state, cguTreezor: action.payload };
    }
    case SET_ROLE: {
      return { ...state, role: action.payload };
    }
    case SET_STEP: {
      return { ...state, activeStep: action.payload };
    }
    case IS_PERSONNE_MORALE: {
      return { ...state, rattacheAPersonneMorale: action.payload };
    }
    case IS_NEW_USER: {
      return { ...state, newUser: action.payload };
    }
    case CREATE_NEW_USER: {
      return { ...state, shouldCreateNewUser: action.payload };
    }
    default:
      return state;
  }
};

export default reducer;
