import colors from '../../../constants/colors.json';
import { smDarkGreyD, inputLabel, inputPlaceholder } from './appText';

const style = {
  container: {
    height: 'auto',
    color: colors.green,
    backgroundColor: colors.greenOpacity,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: colors.green,
      color: 'white',
    },
    '&:hover $optionButton': {
      backgroundColor: colors.green,
      color: 'white',
    },
    '&:active': {
      backgroundColor: colors.green,
      color: 'white',
    },
    '&:focus': {
      backgroundColor: colors.green,
      color: 'white',
    },
  },

  icon: {
    display: 'flex',
    color: 'inherit',
    fontSize: '90px',
    height: '90px',
    zIndex: 3,
    cursor: 'pointer',
    '&:hover': {
      color: 'inherit',
      textDecoration: 'none',
    },
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    borderBottom: '2px solid white',
    padding: '15%',
  },
  optionButton: {
    ...inputPlaceholder,
    backgroundColor: colors.white,
    border: 'none',
    margin: '0 auto',
    textDecoration: 'none',
    textTransform: 'uppercase',
    height: '36px',
    width: '100%',
    textAlign: 'center',
    cursor: 'pointer',
  },
  displayOpened: {
    backgroundColor: colors.green,
    color: 'white',
  },
  disabledOptionButton: {
    ...inputLabel,
    backgroundColor: 'white',
    cursor: 'not-allowed',
  },
  itemsContainer: {
    position: 'absolute',
    minWidth: '144px',
    width: 'auto',
    borderBottom: '2px solid #02CBCD',
    backgroundColor: colors.white,
    padding: '0',
    transform: 'scaleY(0)',
    transition: 'transform 0.2s ease',
    transformOrigin: 'top',
    height: 'auto',
    zIndex: 10,
    boxShadow:
      '0 3px 5px 1px rgba(178,178,178,0.2), 0 2px 5px 1px rgba(178,178,178,0.18), 0 5px 5px -3px rgba(178,178,178,0.23)',
  },
  open: {
    transform: 'scaleY(1)',
    transformOrigin: 'top',
    transition: 'transform 0.5s ease',
    '& p': {
      ...smDarkGreyD,
      height: '30px',
      padding: '0 15px',
      margin: '0 5px',
      display: 'flex',
      alignItems: 'center',
    },
    '& :nth-child(odd)': {
      backgroundColor: colors.white,
    },
    '& :nth-child(even)': {
      backgroundColor: colors.background,
    },
  },

  disabled: {
    backgroundColor: colors.background,
    color: colors.fillsGrey,
    pointerEvents: 'none',
    cursor: 'not-allowed',
    '&:hover $optionButton': {
      backgroundColor: colors.white,
      ...inputLabel,
    },
  },
};

export default style;
