import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import queryString from 'query-string';
import FileSaver from 'file-saver';
import { pdfjs } from 'react-pdf';

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Hidden, Typography } from '@material-ui/core';
import { debounceFn } from '../../../helpers/utils';
import ClientSignature from '../../../components/ClientSignature';
import LoadingIndicatorDialog from '../../../components/LoadingIndicatorDialog';
import AppButton from '../../../components/AppButton';

import state from './state';
import dispatch from './dispatch';

import { style } from '../../../assets/jss/style';
import useStyles from './styles';

import CustomGenericDialog from '../../../components/CustomGenericDialog';
import logger from '../../../helpers/logger';

import logoSideBarS from '../../../assets/images/BBF-logo-alone-white.svg';
import logoSideBar from '../../../assets/images/BBF-header-logo-white.svg';
import ViewCustomerPdf from './ViewPDF';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

// colors
const {
  mainSideBarClientPdf,
  centerContainer,
  mainSideBarClientPdfHeaderVersion,
} = style;

class CustomerDocumentPage extends Component {
  constructor(props) {
    super(props);
    // set screenName so we can override some styles
    props.setScreenName('clientPDFScreen');

    this.signatureCanvas = React.createRef();

    this.state = {
      /* eslint-disable-next-line */
      id: new Buffer.from(this.props.match.params.id, 'base64').toString(
        'ascii'
      ),
      /* eslint-disable-next-line */
      clientId: new Buffer.from(
        this.props.match.params.clientId,
        'base64'
      ).toString('ascii'),
      showSignArea:
        /* eslint-disable-next-line */
        new Buffer.from(
          queryString.parse(this.props.location.search).s,
          'base64'
        ).toString('ascii') === 'true',
      loading: true,
      error: null,
      pdf: null,
      numPages: 1,
      pageNumber: 1,
      accept: null,
      successMessage: null,

      showMessage: true,
      errorDecline: null,
      clientMessage: '',
      noMessage: false,

      signature: null, // uploaded signature
      signatureCanvas: this.signatureCanvas, // just to see the state
    };

    this._onInvoiceReceived = this._onInvoiceReceived.bind(this);
    this._onInvoicePDFRecievied = this._onInvoicePDFRecievied.bind(this);
    this._validateInvoice = this._validateInvoice.bind(this);
    this._onInvoiceValidated = this._onInvoiceValidated.bind(this);
    this._onRadioSelected = this._onRadioSelected.bind(this);
    this._cancelInvoice = this._cancelInvoice.bind(this);
    this._onInvoiceCanceled = this._onInvoiceCanceled.bind(this);
    this._download = this._download.bind(this);
    this._renderConfirmationText = this._renderConfirmationText.bind(this);
    this._onDocumentLoadSuccess = this._onDocumentLoadSuccess.bind(this);

    this._renderSignArea = this._renderSignArea.bind(this);
    this._renderSideBar = this._renderSideBar.bind(this);
    this._clientValidateForm = this._clientValidateForm.bind(this);
  }

  changeState(loading, error) {
    this.setState({ loading, error });
  }

  componentDidMount() {
    const { invoices, fetchClientInvoice, fetchInvoicePDF } = this.props;

    const { id, clientId } = this.state;

    if (!invoices[id]) {
      fetchClientInvoice(id, clientId, this._onInvoiceReceived);
    } else {
      fetchInvoicePDF(invoices[id], this._onInvoicePDFRecievied);
    }
  }

  _renderLoadingIndicator() {
    const { t } = this.props;
    return (
      <LoadingIndicatorDialog
        open={this.state.loading}
        title={t('invoices.procesing')}
      />
    );
  }

  _validateInvoice(blob) {
    const { clientSignInvoice, invoices } = this.props;
    const { id, clientId } = this.state;
    this.setState({ loading: true });

    clientSignInvoice(invoices[id], clientId, blob, this._onInvoiceValidated);
  }

  _onInvoiceValidated(error) {
    const { invoices, fetchInvoicePDF, t } = this.props;
    const { id, showSignArea } = this.state;

    this.setState({ loading: false, error });

    if (error) {
      this.setState({ error });
    } else {
      fetchInvoicePDF(invoices[id], this._onInvoicePDFRecievied);
      const message = t(
        `confirm.${invoices[id].type}${
          showSignArea && invoices[id].type === 'quotation' ? '2' : ''
        }`
      );
      this.setState({ error: null, successMessage: message });
    }
  }

  _onInvoiceCanceled(error) {
    const { invoices, t } = this.props;
    const { id } = this.state;

    this.setState({ loading: false, error });

    if (error) {
      this.setState({ error });
    } else {
      this.setState({
        error: null,
        successMessage: t(`confirm.cancel.${invoices[id].type}`),
        canceled: true,
      });
    }
  }

  _cancelInvoice() {
    if (this.state.clientMessage) {
      const { id, clientId } = this.state;
      this.setState({ loading: true });
      this.props.clientCancelInvoice(
        id,
        clientId,
        this.state.clientMessage,
        this._onInvoiceCanceled
      );
    } else {
      this.setState({ noMessage: true });
    }
  }

  _onInvoicePDFRecievied(err, pdf) {
    if (err) {
      this.changeState(false, err.data ? err.data.message : err.message);
      return;
    }
    const fileBlob = new Blob([pdf], { type: 'application/pdf' });
    const fileURL = URL.createObjectURL(fileBlob);
    this.setState({ pdf: fileURL });

    this.changeState(false, null);
  }

  _onInvoiceReceived(err) {
    const { invoices, fetchInvoicePDF } = this.props;
    const { id } = this.state;

    if (err) {
      this.changeState(false, err.data ? err.data.message : err.message);
    } else {
      fetchInvoicePDF(invoices[id], this._onInvoicePDFRecievied);
    }
  }

  _onDocumentLoadSuccess({ numPages }) {
    this.setState({ numPages });
  }

  _renderContent() {
    const { error, pdf } = this.state;

    if (error) {
      return <div>{error.message}</div>;
    }

    if (!pdf) {
      return null;
    }

    return (
      <ViewCustomerPdf loading={this.state.loading} error={error} pdf={pdf} />
    );
  }

  _onRadioSelected(e) {
    this.setState({ accept: e.target.value });
  }

  _renderRadioGroup() {
    const { t } = this.props;

    return (
      <RadioGroup
        aria-label="acceptation"
        name="type"
        value={this.state.accept}
        onChange={this._onRadioSelected}
      >
        <FormControlLabel
          value={'true'}
          control={<Radio />}
          label={t('accept')}
        />

        <FormControlLabel
          value={'false'}
          control={<Radio />}
          label={t('decline')}
        />
      </RadioGroup>
    );
  }

  _clientValidateForm() {
    this.setState({ loading: true });
    this.props.clientValidateInvoice(
      this.state.id,
      this.state.clientId,
      this._onInvoiceValidated
    );
  }

  _renderSignArea() {
    const { t } = this.props;

    if (!this.state.showSignArea) {
      return (
        <AppButton
          text={t('send')}
          onClick={debounceFn(this._clientValidateForm)}
        />
      );
    }

    return (
      <div style={{ textAlign: 'center' }}>
        <p>{t('confirm.sign')}</p>

        <ClientSignature
          ref={this.signatureCanvas}
          sendSignature={debounceFn(this._validateInvoice)}
        />
      </div>
    );
  }

  _renderSideBar() {
    const { t, invoices } = this.props;
    const { id } = this.state;

    if (invoices[id] && invoices[id].state !== 'pending') {
      return null;
    }

    return (
      <>
        {this._renderRadioGroup()}
        {this.state.accept === 'true' && this._renderSignArea()}
        {this.state.accept === 'false' && (
          <>
            <p>{this.props.t('confirm.precise')}</p>
            <textarea
              cols="20"
              rows="10"
              onChange={(e) => {
                this.setState({ clientMessage: e.target.value });
              }}
              style={{ marginBottom: 5, resize: 'vertical', width: '100%' }}
            />
            {this.state.noMessage && (
              <p style={{ color: 'red' }}>{t('mandatory')}</p>
            )}
            <AppButton
              text={t('reject')}
              onClick={debounceFn(this._cancelInvoice)}
            />
          </>
        )}
      </>
    );
  }

  _download() {
    const { invoices } = this.props;
    const { id } = this.state;

    if (invoices[id].state === 'validated') {
      window.open(this.state.pdf, '_blank');
    } else {
      FileSaver.saveAs(
        new Blob([this.state.pdf], { type: 'application/pdf' }),
        `${invoices[id].invoice_nb}.pdf`
      );
    }
  }

  _renderConfirmationText() {
    const { showMessage, successMessage } = this.state;

    if (successMessage && showMessage) {
      return (
        <CustomGenericDialog
          title="Votre demande a été prise en compte"
          closeDialog={() => this.setState({ showMessage: false })}
          iconClose
          color="secondary"
        >
          <Typography>{successMessage}</Typography>
        </CustomGenericDialog>
      );
    }

    return null;
  }

  render() {
    logger.debug('CustomerDocumentPage.render()');

    return (
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          {/* Left Sidebar, on large screens only - horizontal (see below) on mobile */}
          <Hidden xsDown>
            <Grid item sm={2} lg={2}>
              <div style={mainSideBarClientPdf}>
                <div style={centerContainer}>
                  <picture>
                    <source srcSet={logoSideBar} media="(min-width: 1024px)" />
                    <img
                      src={logoSideBarS}
                      alt="logo"
                      style={{ width: '100%' }}
                    />
                  </picture>
                </div>
              </div>
            </Grid>
          </Hidden>

          {/* Central band */}
          <Grid item xs={12} sm={7} lg={8} container>
            {/* This is now the "center" - First, we show the Logo and blueberry ribbon at the top on Mobile only */}
            <Hidden smUp>
              <Grid item xs={12}>
                <div style={mainSideBarClientPdfHeaderVersion}>
                  <div style={centerContainer}>
                    <picture>
                      <source
                        srcSet={logoSideBar}
                        media="(min-width: 1024px)"
                      />
                      <img
                        src={logoSideBarS}
                        alt="logo"
                        style={{ width: '100%' }}
                      />
                    </picture>
                  </div>
                </div>
              </Grid>
            </Hidden>
            {this._renderLoadingIndicator()}
            {this._renderConfirmationText()}

            {/* Then the signature block - FOR MOBILE ONLY */}
            <Hidden lgUp>
              <Grid item xs={12}>
                <div style={{ paddingLeft: '20px' }}>
                  {this._renderSideBar()}
                </div>
              </Grid>
            </Hidden>

            {/* And finally the PDF document itself */}
            <Grid item xs={12}>
              {this._renderContent()}
            </Grid>
          </Grid>

          {/* Right Sidebar - automatically HIDDEN for mobile: on mobile the block is shown inside the main band */}
          <Hidden mdDown>
            <Grid item sm={3} lg={2}>
              <>{this._renderSideBar()}</>
            </Grid>
          </Hidden>
        </Grid>
      </Container>
    );
  }
}

CustomerDocumentPage.propTypes = {
  t: PropTypes.func,
  match: PropTypes.object,
  invoices: PropTypes.object,
  fetchClientInvoice: PropTypes.func,
  fetchInvoicePDF: PropTypes.func,
  clientSignInvoice: PropTypes.func,
  clientCancelInvoice: PropTypes.func,
  clientValidateInvoice: PropTypes.func,
  setScreenName: PropTypes.func.isRequired,
  pdfPage: PropTypes.bool,
  location: PropTypes.object,
};

const CustomerDocumentPageWithStyle =
  withStyles(useStyles)(CustomerDocumentPage);
const CustomerDocumentPageWithTranslation = withTranslation()(
  CustomerDocumentPageWithStyle
);
export default connect(state, dispatch)(CustomerDocumentPageWithTranslation);
