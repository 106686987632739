import { bindActionCreators } from 'redux';
import { fetchInfoGreffe } from 'actions/infoGreffeActions';

export default (dispatch) =>
  bindActionCreators(
    {
      fetchInfoGreffe,
    },
    dispatch
  );
