import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  makeStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import BUTTON_COLORS from '../constants';

const useStyles = makeStyles({
  dialogContainer: {
    padding: '0px 24px',
  },
  dialogTitle: {
    padding: '15px 24px',
    fontWeight: 'bold',
  },
  textContent: {
    fontSize: '20px',
    width: '100%',
    marginBottom: '20px',
    lineHeight: 'unset',
  },
  button: {
    width: '450px',
    height: '56px',
    borderRadius: '50px',
    backgroundColor: BUTTON_COLORS.GREY,
    color: BUTTON_COLORS.WHITE,
    border: 'none',
    cursor: 'pointer',
    margin: '10px 60px',
    fontSize: '20px',
    inlineSize: 'fit-content',
    padding: '0 20px',
    minWidth: '250px',
    '&:hover': {
      backgroundColor: BUTTON_COLORS.HOVER_GREY,
    },
    '&:disabled': {
      backgroundColor: BUTTON_COLORS.HOVER_GREY,
      cursor: 'default',
    },
  },
  buttonDisabled: {
    backgroundColor: BUTTON_COLORS.GREY,
    '&:hover': {
      backgroundColor: BUTTON_COLORS.HOVER_GREY,
    },
  },
  buttonCancel: {
    backgroundColor: BUTTON_COLORS.BLUE,
    '&:hover': {
      backgroundColor: BUTTON_COLORS.HOVER_BLUE,
    },
  },
  buttonContent: {
    margin: '0 auto',
  },
});

let intervalId;

const ConfirmDialog = ({ title, closeDialog, contentText, onConfirm }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const [timeLeft, setTimeLeft] = useState(5);

  useEffect(() => {
    // save intervalId to clear the interval when the
    // component re-renders
    intervalId = setInterval(
      () => setTimeLeft((_timeLeft) => _timeLeft - 1),
      1000
    );

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (timeLeft === 0) {
      clearInterval(intervalId);
    }
  }, [timeLeft]);

  return (
    <Dialog className={classes.dialogContainer} open={true} maxWidth="md">
      <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>

      <DialogContent>
        <p className={classes.textContent}>{contentText}</p>
      </DialogContent>
      <DialogActions className={classes.buttonContent}>
        <button
          className={classNames(classes.button, classes.buttonCancel)}
          onClick={closeDialog}
        >
          {t('no')}
        </button>
        <button
          disabled={!!timeLeft}
          className={classNames(
            classes.button,
            !!timeLeft && classes.buttonDisabled
          )}
          onClick={() => {
            onConfirm();
          }}
        >
          {t('yes')} {!!timeLeft && `(${timeLeft})`}
        </button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmDialog.propTypes = {
  closeDialog: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  title: PropTypes.string,
  contentText: PropTypes.string,
  countDown: PropTypes.number,
};

export default React.memo(ConfirmDialog);
