import advanceService from '../../services/AdvanceService';

import { CREATE_EXPENSE, UPDATE_EXPENSE } from '../Types';

export const createAdvance = (values, cb) => async (dispatch, getState) => {
  try {
    const {
      loggedUser: { user },
      loggedUserCompany: { company },
    } = getState();

    /* eslint-disable-next-line */
    values.company_id = company._id;
    /* eslint-disable-next-line */
    values.user_id = user._id;

    const response = (await advanceService.createAdvance(values)).data;
    // TODO: CREATE_ADVANCE_EXPENSE
    dispatch({ type: CREATE_EXPENSE, payload: response });
    cb(null);

    return response;
  } catch (error) {
    cb(error);
    return error;
  }
};

export const updateAdvance = (values, cb) => async (dispatch) => {
  try {
    const { data } = await advanceService.updateAdvance(values);
    // TODO: UPDATE_ADVANCE_EXPENSE
    dispatch({ type: UPDATE_EXPENSE, payload: data });
    cb(null);
  } catch (error) {
    cb(error);
  }
};
