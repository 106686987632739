import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  MenuItem,
  InputAdornment,
  Typography,
  makeStyles,
  FormLabel,
  FormControlLabel,
  FormControl,
  Radio,
  RadioGroup as MaterialRadioGroup,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  TextField as FormikTextField,
  CheckboxWithLabel,
  RadioGroup,
} from 'formik-material-ui';
import { KeyboardDatePicker } from 'formik-material-ui-pickers';
import PENDING_IMAGE from 'assets/images/pending-document.svg';
import ErrorMsg from 'components/ErrorMessage';
import { TYPE_AUTO_ENTREPRENEUR, TYPE_SOCIETE } from 'constants/company';
import { checkIfFieldIsDiplay } from 'helpers/TreezorHelper';
import TreezorDropZone from './TreezorDropZone';
// import listOfCompanyFields from './listOfCompanyFields';

const useStyles = makeStyles({
  pending: {
    width: '100px',
    height: 'auto',
  },
  field: {
    width: '100%',
  },
  activityOutsideEuContent: {
    width: '100%',
  },
  activityOutsideEuTitle: {
    margin: 'auto 0',
  },
  activityOutsideContainer: {
    display: 'flex',
  },
});

const TreezorCompanyFormFields = ({
  showTvaAndCapital,
  legalAnnualTurnOvers,
  legalNetIncomeRanges,
  legalNumberOfEmployeeRanges,
  companyCredentials,
  proofOfAddress,
  companyRegistrationSecondary,
  setFieldValue,
  company,
  setDisableSendBtn,
  listOfFields,
}) => {
  const { t } = useTranslation();
  const { values, errors } = useFormikContext();
  const classes = useStyles();
  const { companyType, tva } = company?.settings?.tax_registration;
  const [showError, setShowError] = useState(null);

  /* eslint-disable react/prop-types */
  const ErrorM = ({ children }) => {
    return (
      <div>
        <font color="red">{children}</font>
      </div>
    );
  };

  useEffect(() => {
    setShowError(checkIfFieldIsDiplay(errors, listOfFields));
  }, [errors]);

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Grid container spacing={2} direction="row">
        <Grid item xs={12} md={12}>
          <Typography
            variant="button"
            style={{ fontSize: '20px', color: '#02cbcd' }}
          >
            Informations relatives à votre activité professionnelle
          </Typography>
          <br />
          <Typography variant="button">
            Merci de compléter les informations demandées ci-dessous au titre de
            votre activité professionnelle.
          </Typography>
        </Grid>
        {companyType !== TYPE_AUTO_ENTREPRENEUR && (
          <>
            <Grid item xs={8} md={3}>
              <Field
                component={FormikTextField}
                required
                fullWidth
                name="email"
                label={t('contact_email')}
                placeholder={t('contact_email')}
                color="primary"
                variant="outlined"
                margin="dense"
              />
            </Grid>

            <Grid item xs={4} md={3}>
              <Field
                component={FormikTextField}
                required
                fullWidth
                type="text"
                name="phone"
                label={t('signup.company.phonenumber')}
                placeholder={t('signup.company.phonenumber')}
                color="primary"
                variant="outlined"
                margin="dense"
              />
            </Grid>
          </>
        )}
        {showTvaAndCapital && tva && (
          <Grid item xs={12} md={3}>
            <Field
              component={FormikTextField}
              required
              fullWidth
              type="text"
              name="legalTvaNumber"
              label={t('signup.company.intracomvat')}
              placeholder={t('signup.company.intracomvat_help')}
              color="primary"
              variant="outlined"
              margin="dense"
            />
          </Grid>
        )}
        {showTvaAndCapital && (
          <Grid item xs={12} md={3}>
            <Field
              component={FormikTextField}
              adornmentText="€"
              required
              fullWidth
              type="text"
              name="capital"
              label={t('signup.company.capital')}
              placeholder={t('signup.company.capital')}
              color="primary"
              variant="outlined"
              margin="dense"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
      </Grid>

      <Grid container direction="row" spacing={2}>
        <Grid item xs={12} md={3}>
          <Field
            fullWidth
            component={FormikTextField}
            select
            margin="dense"
            variant="outlined"
            name="legalNetIncomeRange"
            label={`${t('legal_net_income_range')} (k€)`}
          >
            {legalNetIncomeRanges &&
              legalNetIncomeRanges.map((legalNetIncomeRange, idx) => {
                return (
                  <MenuItem key={idx} value={legalNetIncomeRange.val}>
                    {legalNetIncomeRange.val}
                  </MenuItem>
                );
              })}
          </Field>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            fullWidth
            component={FormikTextField}
            select
            margin="dense"
            variant="outlined"
            name="legalAnnualTurnOver"
            label={`${t('legal_annual_turn_over')} (k€)`}
          >
            {legalAnnualTurnOvers &&
              legalAnnualTurnOvers.map((legalAnnualTurnOver, idx) => {
                return (
                  <MenuItem key={idx} value={legalAnnualTurnOver.val}>
                    {legalAnnualTurnOver.val}
                  </MenuItem>
                );
              })}
          </Field>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            fullWidth
            component={FormikTextField}
            select
            margin="dense"
            variant="outlined"
            name="legalNumberOfEmployeeRange"
            label={t('legal_number_of_employee_range')}
          >
            {legalNumberOfEmployeeRanges &&
              legalNumberOfEmployeeRanges.map(
                (legalNumberOfEmployeeRange, idx) => {
                  return (
                    <MenuItem key={idx} value={legalNumberOfEmployeeRange.val}>
                      {legalNumberOfEmployeeRange.val}
                    </MenuItem>
                  );
                }
              )}
          </Field>
        </Grid>
        <Grid item xs={12} md={3}>
          <Field
            fullWidth
            component={KeyboardDatePicker}
            name="legalRegistrationDate"
            label={t('legal_registration_date')}
            format="dd/MM/yyyy"
            inputVariant="outlined"
            color="primary"
            clearable={true}
            required
            margin="dense"
          />
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={2} style={{ marginTop: '4%' }}>
        <Grid item xs={12} md={12}>
          <Typography
            variant="button"
            style={{ fontSize: '20px', color: '#02cbcd' }}
          >
            Documents à fournir relativement à votre activité professionnelle
          </Typography>
          <br />
          <Typography variant="button">
            Merci de fournir deux justificatifs d&apos;immatriculation de votre
            activité professionnelle.
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          {values &&
          values.documents &&
          values.documents.companyCredentials &&
          values.documents.companyCredentials.type !== null &&
          values.documents.companyCredentials.fileName !== null ? (
            <img
              src={PENDING_IMAGE}
              alt="Document is pending"
              className={classes.pending}
            />
          ) : (
            <div style={{ marginBottom: '10px' }}>
              <TreezorDropZone
                name="documents.companyCredentials.type"
                indentityFile="documents.companyCredentials.fileName"
                title={t('company_credentials')}
                optionList={companyCredentials}
                company={company}
                setFieldValue={setFieldValue}
                setDisableSendBtn={setDisableSendBtn}
              />
              <ErrorMessage
                name="documents.companyCredentials.fileName"
                component={ErrorM}
              />
            </div>
          )}
        </Grid>
        {companyType === TYPE_AUTO_ENTREPRENEUR && (
          <Grid item xs={12} md={3}>
            {values &&
            values.documents &&
            values.documents.proofOfAddress &&
            values.documents.proofOfAddress.type !== null &&
            values.documents.proofOfAddress.fileName !== null ? (
              <img
                src={PENDING_IMAGE}
                alt="Document is pending"
                className={classes.pending}
              />
            ) : (
              <>
                <TreezorDropZone
                  name="documents.proofOfAddress.type"
                  indentityFile="documents.proofOfAddress.fileName"
                  title={t('proof_address')}
                  optionList={proofOfAddress}
                  company={company}
                  setFieldValue={setFieldValue}
                  setDisableSendBtn={setDisableSendBtn}
                />
                <ErrorMessage
                  name="documents.proofOfAddress.fileName"
                  component={ErrorM}
                />
              </>
            )}
          </Grid>
        )}
        {companyType === TYPE_SOCIETE && (
          <Grid item xs={12} md={3}>
            {values &&
            values.documents &&
            values.documents.companyRegistrationSecondary &&
            values.documents.companyRegistrationSecondary.type !== null &&
            values.documents.companyRegistrationSecondary.fileName !== null ? (
              <img
                src={PENDING_IMAGE}
                alt="Document is pending"
                className={classes.pending}
              />
            ) : (
              <>
                <TreezorDropZone
                  name="documents.companyRegistrationSecondary.type"
                  indentityFile="documents.companyRegistrationSecondary.fileName"
                  title={t('company_credentials')}
                  optionList={companyRegistrationSecondary}
                  company={company}
                  setFieldValue={setFieldValue}
                  setDisableSendBtn={setDisableSendBtn}
                />
                <ErrorMessage
                  name="documents.companyRegistrationSecondary.fileName"
                  component={ErrorM}
                />
              </>
            )}
          </Grid>
        )}
      </Grid>
      <ErrorMsg showError={showError} error={showError} />
      <Grid item xs={6}>
        <Field
          component={CheckboxWithLabel}
          type="checkbox"
          name="activityOutsideEu"
          Label={{
            label: 'Y-a-t-il/elle une activité commerciale hors UE ?',
          }}
        />
        <ErrorMessage name="activityOutsideEu" component={ErrorM} />
      </Grid>
      {values.activityOutsideEu && (
        <>
          <Grid item xs={12}>
            <FormControl
              className={classes.activityOutsideEuContent}
              component="fieldset"
            >
              <MaterialRadioGroup row>
                <Field
                  component={RadioGroup}
                  name="residentCountriesSanctions"
                  className={classes.field}
                >
                  <Grid xs={12} className={classes.activityOutsideContainer}>
                    <Grid xs={10} className={classes.activityOutsideEuTitle}>
                      <FormLabel component="legend">
                        Le client, ses succursales, ses filiales ou ses
                        joint-ventures sont-ils physiquement présents dans, ou
                        opèrent-ils depuis, des pays soumis à des sanctions
                        étendues ?
                      </FormLabel>
                    </Grid>
                    <Grid xs={2} style={{ textAlign: 'right' }}>
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label="Non"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label="Oui"
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </MaterialRadioGroup>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl
              className={classes.activityOutsideEuContent}
              component="fieldset"
            >
              <MaterialRadioGroup row aria-label="position" name="position">
                <Field
                  component={RadioGroup}
                  name="economicSanctions"
                  className={classes.field}
                >
                  <Grid xs={12} className={classes.activityOutsideContainer}>
                    <Grid xs={10} className={classes.activityOutsideEuTitle}>
                      <FormLabel component="legend">
                        Le client, ses succursales, ses filiales,
                        joint-ventures, ses dirigeants ou ses employés sont-ils
                        visés par des mesures de Sanctions Économiques ?
                      </FormLabel>
                    </Grid>
                    <Grid xs={2} style={{ textAlign: 'right' }}>
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label="Non"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label="Oui"
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </MaterialRadioGroup>
            </FormControl>
          </Grid>
          <>
            <FormControl component="fieldset">
              <MaterialRadioGroup row aria-label="position" name="position">
                <Field
                  component={RadioGroup}
                  name="involvedSanctions"
                  className={classes.field}
                >
                  <Grid xs={12} className={classes.activityOutsideContainer}>
                    <Grid xs={10}>
                      <FormLabel
                        component="legend"
                        xs={10}
                        className={classes.activityOutsideEuTitle}
                      >
                        Le client, après un examen minutieux et à votre
                        connaissance, ses succursales, ses filiales ou ses
                        joint-ventures sont-ils impliqués dans des opérations,
                        des investissements, des activités ou toutes autres
                        transactions impliquant ou profitant directement ou
                        indirectement à (i) des pays soumis à des sanctions
                        étendues ou à (ii) toute personne ou entité visée par
                        des Sanctions (« Personnes Sanctionnées ») ?
                      </FormLabel>
                    </Grid>
                    <Grid xs={2} style={{ textAlign: 'right' }}>
                      <FormControlLabel
                        value="no"
                        control={<Radio color="primary" />}
                        label="Non"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio color="primary" />}
                        label="Oui"
                        labelPlacement="start"
                      />
                    </Grid>
                  </Grid>
                </Field>
              </MaterialRadioGroup>
            </FormControl>
          </>
        </>
      )}
    </MuiPickersUtilsProvider>
  );
};

TreezorCompanyFormFields.propTypes = {
  showTvaAndCapital: PropTypes.bool,
  readOnly: PropTypes.bool,
  legalFormArray: PropTypes.array,
  legalAnnualTurnOvers: PropTypes.array,
  legalNetIncomeRanges: PropTypes.array,
  legalNumberOfEmployeeRanges: PropTypes.array,
  companyRegistrationSecondary: PropTypes.array,
  companyCredentials: PropTypes.array,
  setFieldValue: PropTypes.func,
  setDisableSendBtn: PropTypes.func,
  proofOfAddress: PropTypes.array,
  company: PropTypes.object,
  listOfFields: PropTypes.array,
};

export default TreezorCompanyFormFields;
