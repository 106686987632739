import { authInstance as axios } from 'helpers/axiosInterceptor';

const createBankConnection = async (userId) =>
  axios.get(`${process.env.REACT_APP_BRIDGE_URL}/connect-item`, {
    params: { userId },
  });

const getBankAccounts = async (companyId) =>
  axios.get(`${process.env.REACT_APP_BRIDGE_URL}/accounts-connected`, {
    headers: {
      'Content-Type': 'application/json',
      'X-Company-Id': companyId,
    },
  });

const getTransactions = async (companyId, userId, accountId) =>
  axios.get(
    `${process.env.REACT_APP_BRIDGE_URL}/transactions/account/${accountId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'x-user-id': userId,
        'X-Company-Id': companyId,
      },
    }
  );

const getTransaction = async (companyId, transactionId) =>
  axios.get(
    `${process.env.REACT_APP_BRIDGE_URL}/transactions/${transactionId}`,
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Company-Id': companyId,
      },
    }
  );

const updateTransaction = async (companyId, userId, transactionId, data) =>
  axios.patch(
    `${process.env.REACT_APP_BRIDGE_URL}/transaction/${transactionId}`,
    data,
    {
      headers: {
        'Content-Type': 'application/json',
        'X-Company-Id': companyId,
        'X-User-Id': userId,
      },
    }
  );

export default {
  createBankConnection,
  getBankAccounts,
  getTransactions,
  getTransaction,
  updateTransaction,
};
