/* eslint-disable no-unused-vars */
import cl from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import {
  Box,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import theme from 'assets/jss/theme';
import PropTypes from 'prop-types';
import { formatTimestamp } from 'helpers/dateHelper';
import { useFormikContext } from 'formik';
import AssistantMessage from './AssistantMessage';
import UserMessage from './UserMessage';
import NewChat from './NewChat';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
    alignItems: 'flex-start',
    alignSelf: 'stretch',
    padding: '0 0 20px 20px',
    height: '100%',
    justifyContent: 'space-between',
  },
  boxChat: {
    flex: '1 0 0',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    overflowY: 'scroll',
  },
  boxChat__content: {
    width: '75%',
    backgroundColor: 'white',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '8px',
  },
  boxAction: {
    width: '100%',
    paddingRight: '12px',
  },
  boxInput: ({ isFocusInput }) => ({
    width: '100%',
    borderRadius: '12px',
    backgroundColor: theme.palette.neutral[50],
    position: 'relative',
    '&:hover': {
      backgroundColor: !isFocusInput ? theme.palette.neutral[200] : 'inherit',
    },
    transition: 'background-color 0.3s ease-in-out',
    padding: '12px 60px 12px 20px',
    maxHeight: '48px',
  }),
  input: {
    width: '100%',
    outline: 'none',
    border: 'none',
    fontSize: '1rem',
    lineHeight: '1.5rem',
    backgroundColor: 'transparent',
  },
  icon: {
    position: 'absolute',
    right: '20px',
    top: '50%',
    transform: 'translateY(-50%)',
    padding: '8px',
    borderRadius: '8px',
    backgroundColor: theme.palette.neutral[200],
    color: theme.palette.neutral[400],
    width: 32,
    height: 32,
  },
  iconDisabled: {
    color: theme.palette.neutral[400],
    backgroundClip: theme.palette.neutral[200],
  },
  iconActive: {
    backgroundColor: theme.palette.primary[900],
    color: 'white',
  },
  boxInputFocus: {
    backgroundColor: theme.palette.neutral[50],
    border: `1px solid ${theme.palette.primary[600]}`,
  },
}));

const ChatBox = ({ data }) => {
  const [isFocusInput, setIsFocusInput] = useState(false);

  const classes = useStyles({ isFocusInput });
  const { t } = useTranslation();
  const { handleChange, values, isSubmitting, submitForm } = useFormikContext();
  const boxRef = useRef(null);

  const scrollToBottom = () => {
    boxRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleSubmit = () => {
    setIsFocusInput(false);
    submitForm();
  };

  useEffect(() => {
    scrollToBottom();
  }, [data]);

  return (
    <Box className={classes.container}>
      {data?.length ? (
        <Box className={classes.boxChat}>
          <Box className={classes.boxChat__content}>
            {data?.map((message) => (
              <>
                {message.role === 'user' && (
                  <UserMessage
                    content={message.message}
                    time={formatTimestamp(message.dateTime * 1)}
                    key={Math.random()}
                  />
                )}
                {message.role === 'assistant' && (
                  <AssistantMessage
                    time={formatTimestamp(message.dateTime * 1)}
                    content={message.message}
                    key={Math.random()}
                  />
                )}
              </>
            ))}
            <div ref={boxRef} />
          </Box>
        </Box>
      ) : (
        <NewChat />
      )}

      <Box className={classes.boxAction}>
        <Box
          className={cl(classes.boxInput, {
            [classes.boxInputFocus]: isFocusInput,
          })}
        >
          {!isSubmitting ? (
            <input
              placeholder={t('agent.input_chat_placeholder')}
              className={classes.input}
              name="message"
              onChange={handleChange}
              value={values?.message || ''}
              disabled={isSubmitting}
              onFocus={() => {
                setIsFocusInput(true);
              }}
              onBlur={() => {
                setIsFocusInput(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
          ) : (
            <Box style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
              <CircularProgress
                style={{ color: theme.palette.neutral[900] }}
                size={20}
                disableShrink
              />
              <Typography
                variant="h5"
                style={{ color: theme.palette.neutral[900] }}
              >
                {t('agent.generate_message')}
              </Typography>
            </Box>
          )}

          <IconButton
            color="primary"
            type="button"
            disabled={isSubmitting}
            className={cl(classes.icon, {
              [classes.iconActive]: isFocusInput,
              [classes.iconDisabled]: isSubmitting,
            })}
            onClick={handleSubmit}
          >
            <ArrowUpwardIcon className="arrow-icon" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

ChatBox.propTypes = { data: PropTypes.object };

export default ChatBox;
