/* eslint-disable react/display-name */
/* Packages */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import MaterialTable from 'material-table';
import { Typography } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';

import { fetchTransactionPaginate } from 'actions/WalletActions';
import { DoubleLinkIcon, SEPARequestIcon } from 'components/AppIcons';
import { useRouter } from 'hooks/routerHooks';

/* Components */
import BankBBFTableDate from '../BankBBFTableDate';

/* Styles */
import useStyles from './styles';

function BankBBFAccountTable(props) {
  const { treezorWallet } = props;

  const classnames = useStyles();
  const { t } = useTranslation();
  const { history } = useRouter();
  const tableRef = useRef();
  const dispatch = useDispatch();

  const [refresh, setRefresh] = useState(false);

  // For filter button later
  const filters = {};

  function fetch() {
    return tableRef.current && tableRef.current.onQueryChange();
  }

  useEffect(() => {
    if (!refresh) return;
    fetch();
  }, [refresh]);

  const columns = [
    {
      title: t('Date'),
      field: 'date',
      filtering: false,
      sorting: false,
      render: (rowData) => (
        <BankBBFTableDate createdDate={rowData.createdDate} />
      ),
    },
    {
      title: t('bank_bbf.amount'),
      field: 'amount',
      sorting: false,
      filtering: false,

      render: (rowData) => (
        <Typography
          className={
            rowData.transactionType === 'Payin'
              ? classnames.amountCredit
              : classnames.amountDebit
          }
        >
          {rowData.amount} {rowData.currency}
        </Typography>
      ),
    },
    {
      field: t('reconcile'),
      filtering: false,
      render: (rowData) => {
        return (
          <>
            <DoubleLinkIcon
              sm
              onClick={() => {
                history.push(`/transaction/wallet/${rowData._id}`);
              }}
            />
            <SEPARequestIcon
              sm
              onClick={() => {
                history.push(
                  // `/bank-bbf/sepa/request?foreignId=${rowData.foreignId}&transactionType=${rowData.transactionType}&amount=${rowData.amount}&currency=${rowData.currency}&createdDate=${rowData.createdDate}`
                  {
                    pathname: '/bank-bbf/sepa/request',
                    state: {
                      foreignId: rowData.payoutId || rowData.foreignId,
                      transactionType: 'Payout',
                      amount: rowData.amount,
                      currency: rowData.currency,
                      createdDate: rowData.createdDate,
                    },
                  }
                );
              }}
            />
          </>
        );
      },
    },
  ];

  const fetchData = async (query) => {
    const res = await dispatch(
      fetchTransactionPaginate(treezorWallet.walletId, query, filters, refresh)
    );
    if (res) {
      setRefresh(false);
      return { ...res, data: res.data || [] };
    }

    return null;
  };

  return (
    <MaterialTable
      tableRef={tableRef}
      title="Transactions"
      data={(query) => fetchData(query)}
      columns={columns}
      options={{
        filtering: false,
        search: false,
        showTitle: false,
      }}
      actions={[
        {
          icon: () => <Refresh />,
          isFreeAction: true,
          onClick: () => setRefresh(true),
        },
      ]}
    />
  );
}

BankBBFAccountTable.propTypes = {
  treezorWallet: PropTypes.object,
};

export default BankBBFAccountTable;
