import { authInstance as axios } from '../../helpers/axiosInterceptor';

const createAdvance = async (data) =>
  axios.post(`${process.env.REACT_APP_EXPENSES_URL}/advances`, data);

const updateAdvance = async (data) =>
  axios.put(`${process.env.REACT_APP_EXPENSES_URL}/advances/${data._id}`, data);

export default {
  createAdvance,
  updateAdvance,
};
