import React, { useState, useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, TextField } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'hooks/routerHooks';
import { format } from 'date-fns';
import Layout from 'components/Layout';
import Header from 'components/Header';
import defaultCompanyLogo from 'assets/images/image-placeholder.png';
import { fetchCompanyLogo } from 'actions/CompanyActions';
import UploadFile from 'components/UploadFile';

const RevenueDetail = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { history } = useRouter();
  const dispatch = useDispatch();
  const [companyLogo, setCompanyLogo] = useState(defaultCompanyLogo);
  const [typeFile, setTypeFile] = useState(null);

  const selectedRevenue = useSelector(
    // eslint-disable-next-line no-shadow
    (state) => state.revenuePaginate.docs.find((revenue) => revenue._id === id)
  );

  const revenueCategories = useSelector(
    (state) => state.revenueCategories.data
  );

  function onCompanyLogoFetched(err, { url, type }) {
    setCompanyLogo(url);
    setTypeFile(type);
  }

  const fetchCompanyLogoCb = useCallback(
    () =>
      dispatch(
        fetchCompanyLogo(selectedRevenue?.filename, onCompanyLogoFetched)
      ),
    [dispatch, selectedRevenue?.filename]
  );

  useEffect(() => {
    if (selectedRevenue?.filename) {
      fetchCompanyLogoCb();
    }
  }, [fetchCompanyLogoCb]);

  const fixedFloatNum = (number) => {
    return parseFloat(number).toFixed(2);
  };

  const calcVATBasis = () => {
    let VATBaisis = 0;
    VATBaisis = selectedRevenue?.vat_details.reduce(
      (accumulator, currentValue) => accumulator + currentValue.amount,
      0
    );

    return `${fixedFloatNum(VATBaisis)} €`;
  };

  const calcVATAmount = () => {
    let VATAmount = 0;

    VATAmount = selectedRevenue?.vat_details.reduce(
      (accumulator, currentValue) => accumulator + currentValue.vat_amount,
      0
    );
    return `${fixedFloatNum(VATAmount)} €`;
  };

  const renderCategory = () => {
    const category = revenueCategories[selectedRevenue?.category_id];
    if (category && !category?.parent) {
      return category.display;
    }
    const parent = revenueCategories[category?.parent];
    return parent?.display;
  };
  const renderSubCategory = () => {
    const category = revenueCategories[selectedRevenue?.category_id];
    return category && category?.parent ? category.display : ' ';
  };

  const renderVATLineGroup = () => {
    return (
      <>
        {selectedRevenue?.vat_details.map((item, index) => (
          <Grid container spacing={5} key={index}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                label={t('expenses.expense.form_amount_0')}
                defaultValue={`${fixedFloatNum(item.amount)} €`}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                label={t('expenses.expense.form_vatrate_0')}
                defaultValue={`${item.vat_rate} %`}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                label={t('expenses.expense.form_vat_0')}
                defaultValue={`${fixedFloatNum(item.vat_amount)} €`}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                margin="dense"
                variant="outlined"
                label={t('expenses.expense.form_gross')}
                defaultValue={`${fixedFloatNum(item.total)} €`}
                disabled
              />
            </Grid>
          </Grid>
        ))}

        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4}></Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                VAT basis
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  defaultValue={calcVATBasis()}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                VAT Amount
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  defaultValue={calcVATAmount()}
                  disabled
                />
              </Grid>
            </Grid>

            <Grid container spacing={2} alignItems="center">
              <Grid item xs={6}>
                Total Price (Incl.VAT)
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  defaultValue={`${fixedFloatNum(
                    selectedRevenue?.grand_total_amount
                  )} €`}
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderBody = () => {
    return (
      <Grid container spacing={5}>
        <Grid item xs={11} sm={6}>
          <UploadFile
            fileUrl={companyLogo}
            readOnly={true}
            buttonText={t('invoices.tooltip.invoice.draft.preview')}
            type={typeFile}
            isPreviewFile
          />
        </Grid>
        <Grid item xs={11} sm={6} style={{ paddingRight: '55px' }}>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label={t('expenses.expense.category')}
            defaultValue={renderCategory()}
            disabled
          />
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label={t('revenues.revenue.subCategory')}
            defaultValue={renderSubCategory()}
            disabled
          />

          {selectedRevenue?.date_emission && (
            <TextField
              fullWidth
              margin="dense"
              variant="outlined"
              // label={t('expenses.expense.category')}
              defaultValue={format(
                new Date(selectedRevenue?.date_emission),
                'dd/MM/yyyy'
              )}
              disabled
            />
          )}

          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label={t('settlement_methods')}
            defaultValue={selectedRevenue?.paying_mode}
            disabled
          />

          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label={t('expenses.expense.description')}
            defaultValue={selectedRevenue?.description}
            disabled
          />

          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label={t('expenses.expense.form_total_0')}
            defaultValue={fixedFloatNum(selectedRevenue?.grand_total_amount)}
            disabled
          />
          {renderVATLineGroup()}
        </Grid>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Layout
        header={
          <Header
            name={t('revenues.revenue.create')}
            goBack={() => {
              history.goBack();
            }}
            spaceBetween
          />
        }
        sidebarLeft={true}
        sidebarRight={null}
        showUserCard={true}
        body={renderBody()}
      />
    </React.Fragment>
  );
};

export default RevenueDetail;
