import React, { useEffect, useState } from 'react';

/* Import services */
import InvoiceService from 'services/InvoiceService';
import { List } from '@material-ui/core';
import logger from 'helpers/logger';
import queryString from 'query-string';
/* Import custom hooks */
import { useRouter } from 'hooks/routerHooks';
import CircularProgressCentered from 'components/CircularProgressCentered';
import AppDashboardListItem from './AppDashboardListItem';

const TopServiceProducts = () => {
  const [topProducts, setTopProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { history } = useRouter();

  const fetchTopProducts = async () => {
    try {
      setLoading(true);
      const res = await InvoiceService.fetchInvoicePaginate('pagination=false');

      const reduceListProducts = Object.values(
        (res.data.docs || []).reduce((prev, item) => {
          const prevMap = prev;
          if (
            (item.type === 'invoice' ||
              item.type === 'credit_note' ||
              item.type === 'pre_payment') &&
            (item.state === 'validated' ||
              item.state === 'paid' ||
              item.state === 'overdue')
          ) {
            item?.items?.rows?.forEach((elem) => {
              const _id = elem?._id || '';
              const product = elem?.item.name || '';
              const grandTotalAmount = elem?.net_price || '';

              const key = product;

              if (!key) {
                return;
              }

              const prevItem = prevMap[key];

              if (!prevItem) {
                prevMap[key] = {
                  _id,
                  product,
                  grandTotalAmount,
                };
                return;
              }

              prevMap[key] = {
                ...prevItem,
                grandTotalAmount: prevItem.grandTotalAmount + grandTotalAmount,
              };
            });
            return prev;
          }
          return prevMap;
        }, {})
      );

      const top10Products = reduceListProducts
        .sort((a, b) => b.grandTotalAmount - a.grandTotalAmount)
        .slice(0, 10);

      setTopProducts(top10Products);
    } catch (_error) {
      logger.error({ _error });
    } finally {
      setLoading(false);
    }
  };

  const onClickProduct = (product) => {
    if (!product) {
      return;
    }
    const invoiceFilters = {
      type: ['invoice', 'credit_note', 'pre_payment'],
      state: ['validated', 'paid', 'overdue'],
      product: [product?.product],
    };

    history.push(
      queryString.stringifyUrl(
        {
          url: 'invoices/list',
          query: invoiceFilters,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  useEffect(() => {
    fetchTopProducts();
  }, []);

  return (
    <>
      {!loading ? (
        <List style={{ padding: 0 }}>
          {topProducts?.length > 0 &&
            topProducts.map((product, index) => {
              return (
                <AppDashboardListItem
                  key={index}
                  onClick={() => onClickProduct(product)}
                  titleLeft={product?.product}
                  titleRight={`${product?.grandTotalAmount}€`}
                />
              );
            })}
        </List>
      ) : (
        <CircularProgressCentered />
      )}
    </>
  );
};

TopServiceProducts.propTypes = {};

export default TopServiceProducts;
