import { Items as Rights } from '../../config/Rights';
import { Items as Feature } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreateItem = () => {
  return hasRequestedAccess(Rights.ItemsCreate);
};

export const userCanReadItem = () => {
  return hasRequestedAccess(Rights.ItemsRead);
};

export const userCanUpdateItem = () => {
  return hasRequestedAccess(Rights.ItemsUpdate);
};

export const userCanDeleteItem = () => {
  return hasRequestedAccess(Rights.ItemsDelete);
};

export const userHasItemAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
