import { lookupTreezorDocumentTypeByCode } from 'helpers/TreezorHelper';

// eslint-disable-next-line import/prefer-default-export
export const prepareData = (values, treezorUsers) => {
  const user = treezorUsers.find((u) => u._id === values.treezorUserId);
  if (values.filename !== null && values.type !== null) {
    user.documents.push({
      filename: values.filename,
      message: '',
      status: undefined,
      type: lookupTreezorDocumentTypeByCode(values.type),
      codeBBF: values.type,
    });
  }

  return {
    documents: user.documents,
    userId: user._id,
    companyId: values.companyId,
  };
};
