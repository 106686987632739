import { isObject } from 'lodash';
import logger from 'helpers/logger';

import { GLOBAL_ERROR } from '../Types';

// eslint-disable-next-line import/prefer-default-export
export const setGlobalError = (e) => {
  if (!isObject(e) || (e.message && !e.message.isCanceled)) {
    logger.error('[setGlobalError] raw eror is', { error: e });
    return {
      type: GLOBAL_ERROR,
      payload: e.message,
    };
  }

  return null;
};
