import React from 'react';
import PropTypes from 'prop-types';

import Radio from '@material-ui/core/Radio';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { makeStyles } from '@material-ui/core/styles';
import style from '../../assets/jss/components/appRadioButton';

const useStyles = makeStyles(() => style);

const { rowFlexStart } = style;

const AppRadioButton = (props) => {
  const { text, disabled, color, value, valueselected } = props;

  const classes = useStyles();

  return (
    <>
      <div style={rowFlexStart}>
        <FormControlLabel
          value={value}
          control={<Radio color={color} />}
          label={text}
          disabled={disabled}
          classes={{
            label:
              (disabled && classes.captionBigDisabled) ||
              (valueselected === value && classes.captionBigSelected) ||
              classes.captionBig,
          }}
        />
      </div>
      <div style={{ marginBottom: '11px' }} />
    </>
  );
};

AppRadioButton.propTypes = {
  text: PropTypes.string.isRequired,
  valueselected: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  color: PropTypes.string,
};
AppRadioButton.defaultProps = {
  disabled: false,
  value: null,
  color: 'primary',
};

export default AppRadioButton;
