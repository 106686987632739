import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { useLocation, useHistory } from 'react-router-dom';
import logo from '../../../assets/images/BBF-logo-round-white.svg';
import { finalPageMessage, prepareQueryParams } from '../utils';

const useStyles = makeStyles({
  container: {
    textAlign: 'center',
  },
  image: {
    width: '14rem',
    marginBottom: '50px',
  },
  textContent: {
    fontSize: '45px',
    fontWeight: '700',
    margin: 'auto',
    width: '100%',
  },
  button: {
    width: '450px',
    height: '70px',
    borderRadius: '50px',
    backgroundColor: '#3d319d',
    color: '#FFFFFF',
    border: 'none',
    cursor: 'pointer',
    marginTop: '50px',
  },
});

const FinalPage = ({ data, handleNext }) => {
  const location = useLocation();
  const history = useHistory();
  const queryParams = new URLSearchParams(location.search);
  const isFeature = data.account_type.includes('future_');
  const { t } = useTranslation();
  const classes = useStyles();
  const type = isFeature ? `future_${data.account_type}` : data.account_type;

  useEffect(() => {
    if (queryParams.get('crr') === 'rl') {
      handleNext();
    }
  }, []);
  const handleOnClick = () => {
    const newQueryParams = prepareQueryParams(queryParams, 'crr', 'rl');
    history.replace({
      pathname: location.pathname,
      search: newQueryParams.toString(),
    });
    handleNext();
  };

  return (
    <div className={classes.container}>
      <img src={logo} alt="logo" className={classes.image} />
      <div className={classes.textContent}>
        <b>{t(`onboarding.final_page_message.${finalPageMessage[type]}`)}</b>
      </div>
      {!isFeature && (
        <button className={classes.button} onClick={() => handleOnClick()}>
          {t('onboarding.access_management_tools')}
        </button>
      )}
    </div>
  );
};

FinalPage.propTypes = {
  data: PropTypes.object,
  handleNext: PropTypes.func,
};

export default FinalPage;
