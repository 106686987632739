import { format, addMonths, endOfMonth, addDays } from 'date-fns';
/* eslint import/prefer-default-export: off */
export const calculateDueDate = (dateValues) => {
  let payments = null;

  let dueDate = null;

  switch (dateValues) {
    case '0':
      dueDate = format(new Date(), 'yyyy-MM-dd');
      break;
    case 'custom':
      dueDate = format(new Date(), 'yyyy-MM-dd');
      break;
    case '30':
      dueDate = format(new Date(addMonths(new Date(), 1)), 'yyyy-MM-dd');
      break;
    case '45':
      dueDate = format(new Date(addDays(new Date(), 45)), 'yyyy-MM-dd');
      break;
    case '60':
      dueDate = format(new Date(addMonths(new Date(), 2)), 'yyyy-MM-dd');
      break;
    case '30+':
      dueDate = format(
        new Date(endOfMonth(new Date(addMonths(new Date(), 1)))),
        'yyyy-MM-dd'
      );
      break;
    case '45+':
      dueDate = format(
        new Date(endOfMonth(new Date(addDays(new Date(), 45)))),
        'yyyy-MM-dd'
      );
      break;
    case '60+':
      dueDate = format(
        new Date(endOfMonth(new Date(addMonths(new Date(), 2)))),
        'yyyy-MM-dd'
      );
      break;
    default:
      dueDate = null;
      break;
  }
  payments = [dueDate];

  return payments;
};
