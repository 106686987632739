import {
  FETCHING_CLIENT_PROJECTS,
  FETCH_CLIENT_PROJECTS,
  FETCH_CLIENT_PROJECTS_FAILED,
  CREATE_CLIENT_PROJECT,
  UPDATE_CLIENT_PROJECT,
  LOGOUT_USER_SUCCESS,
  DELETE_CLIENT_PROJECT,
  LOGGED_USER_COMPANY_SELECTED,
} from '../../actions/Types';

const INITIAL_STATE = {
  projects: {},
  isFetchingProjects: false,
  errorFetchingProjects: null,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(`action type =>  ${action.type}`);
  let actionClientId;
  let newArrayOfProjects = [];
  if (action.type === CREATE_CLIENT_PROJECT) {
    const temp = state.projects;
    actionClientId = action.payload.client_id;
    if (temp[actionClientId]) {
      newArrayOfProjects = [...temp[actionClientId], action.payload];
    } else {
      newArrayOfProjects = [action.payload];
    }
  }
  switch (action.type) {
    case FETCHING_CLIENT_PROJECTS:
      return {
        ...state,
        isFetchingProjects: true,
        errorFetchingProjects: null,
      };
    case FETCH_CLIENT_PROJECTS: {
      return {
        ...state,
        projects: action.payload,
        isFetchingProjects: false,
        errorFetchingProjects: null,
      };
    }
    case CREATE_CLIENT_PROJECT:
      return {
        ...state,
        projects: {
          ...state.projects,
          [actionClientId]: newArrayOfProjects,
        },
        isFetchingClients: false,
        errorFetchingClients: null,
      };
    case UPDATE_CLIENT_PROJECT: {
      const objIndex = state.projects[action.payload.client_id].findIndex(
        (obj) => obj._id === action.payload._id
      );
      const updatedObj = {
        ...state.projects[action.payload.client_id][objIndex],
        ...action.payload,
      };
      const projects = {
        ...state.projects,
        [action.payload.client_id]: [
          ...state.projects[action.payload.client_id].slice(0, objIndex),
          updatedObj,
          ...state.projects[action.payload.client_id].slice(objIndex + 1),
        ],
      };

      return {
        ...state,
        projects,
        isFetchingClients: false,
        errorFetchingClients: null,
      };
    }
    case DELETE_CLIENT_PROJECT: {
      return {
        ...state,
        projects: {
          ...state.projects,
          [action.payload.clientId]: state.projects[
            action.payload.clientId
          ].filter(({ _id }) => _id !== action.payload.id),
        },
        isFetchingClients: false,
        errorFetchingClients: null,
      };
    }
    case FETCH_CLIENT_PROJECTS_FAILED:
      return {
        ...state,
        projects: {},
        isFetchingProjects: false,
        errorFetchingProjects: action.payload,
      };
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return INITIAL_STATE;
    default:
      return state;
  }
};
