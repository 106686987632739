import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import withStyles from '@material-ui/core/styles/withStyles';
import richTextEditorStyle from '../../assets/jss/components/richTextEditor';

class RichTextEditor extends Component {
  constructor(props) {
    super(props);
    const { initialHtml } = props;
    this.getEditedEmailInvoice = this.getEditedEmailInvoice.bind(this);
    this.onEditorStateChange = this.onEditorStateChange.bind(this);
    this.updateContent = this.updateContent.bind(this);

    this.state = {
      editorState: this.updateContent(initialHtml),
    };
  }

  updateContent(initialHtml) {
    this.content = initialHtml || '';
    this.htmlToGet = '';
    const editableHtml = this.content.match(
      /<!-- EDITABLE BEGIN -->.*<!-- EDITABLE END -->/s
    );
    const contentBlock = htmlToDraft(
      editableHtml === null ? '' : editableHtml[0]
    );
    let editorState = EditorState.createEmpty();
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      editorState = EditorState.createWithContent(contentState);
    }

    return editorState;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.initialHtml !== this.props.initialHtml) {
      this.setState({
        editorState: this.updateContent(this.props.initialHtml),
      });
    }
  }

  getEditedEmailInvoice(html) {
    let firstPart = this.content.match(/^.*<!-- EDITABLE BEGIN -->/s);
    firstPart = firstPart ? firstPart[0] : '';
    let secondPart = this.content.match(/<!-- EDITABLE END -->.*$/s);
    secondPart = secondPart ? secondPart[0] : '';
    return firstPart + html + secondPart;
  }

  onEditorStateChange(editorState) {
    this.setState({
      editorState,
    });
  }

  render() {
    const { editorState } = this.state;
    const editedHtml = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    this.htmlToGet = this.getEditedEmailInvoice(editedHtml);
    return (
      <div className="editor" style={{ position: 'relative' }}>
        {this.props.readOnly && (
          <div
            style={{
              width: '100%',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              zIndex: 100,
              backgroundColor: 'rgba(163, 163, 194,0.3)',
            }}
          ></div>
        )}
        <Editor
          editorState={editorState}
          onEditorStateChange={this.onEditorStateChange}
          toolbar={{
            options: [
              'inline',
              'blockType',
              'fontSize',
              'fontFamily',
              'colorPicker',
              'list',
              'textAlign',
              'emoji',
              'history',
            ],
            fontSize: {
              options: [
                8, 9, 10, 11, 12, 14, 15, 16, 18, 24, 30, 36, 48, 60, 72, 96,
              ],
            },
            fontFamily: {
              options: [
                'Arial',
                'Georgia',
                'Impact',
                'Tahoma',
                'Times New Roman',
                'Verdana',
                'sans-serif',
              ],
            },
            inline: { inDropdown: true },
            list: { inDropdown: true },
            textAlign: { inDropdown: true },
            link: { inDropdown: true },
            history: { inDropdown: true },
          }}
          readOnly={this.props.readOnly}
        />
        {/* <textarea value={this.getEditedEmailInvoice(edited_html)} /> */}
      </div>
    );
  }
}

RichTextEditor.propTypes = {
  readOnly: PropTypes.bool,
  initialHtml: PropTypes.string.isRequired,
};

export default withStyles(richTextEditorStyle)(RichTextEditor);
