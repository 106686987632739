import React, { useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { setGlobalError } from 'actions/GlobalError';
import spinnerService from 'services/SpinnerService';
import PropTypes from 'prop-types';
import { withFormik, Form, useFormikContext } from 'formik';
import { Grid, makeStyles } from '@material-ui/core';
import AppRadioGroup from 'components/AppRadioGroup';
import AppRadioButton from 'components/AppRadioButton';
import TextField from 'components/FormElements/textField';
import AppButton from 'components/AppButton';
import AppCheckbox from 'components/AppCheckbox';
import refundService from 'services/RefundBankInfo';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import mapPropsToValues from './formDefaultValue';
import { validationSchema } from './validation';

const countryList = require('../../../assets/data/countryList.json');

const useStyles = makeStyles({
  container: {
    margin: 'auto',
  },
  subTitle: {
    fontSize: '16px',
    fontWeight: '500',

    '& > p': {
      marginTop: '6px',
    },
  },
});
const RefundForm = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const sessionEditing = queryParams.get('sessionEditing');

  const getBankInfoData = async () => {
    try {
      spinnerService.startSpinner();
      const response = await refundService.getClientRefund(sessionEditing);

      if (response) {
        const { data } = response.data;

        const country = countryList.find(
          (elem) => elem.alpha2 === data.country
        );

        data.country = `${country.name_fr} (${country.alpha2})` || '';

        props.setValues({
          ...props.values,
          ...data,
        });
      }
    } catch (error) {
      // redirect to home page
      // history.push('/home');
      // dispatch(setGlobalError(error));
    } finally {
      spinnerService.endSpinner();
    }
  };

  useEffect(() => {
    getBankInfoData();
  }, []);

  const displayName = () => {
    if (props.values.isCompany) {
      return (
        <TextField
          label={t('signup.company.name')}
          placeholder={t('signup.company.name')}
          name="name"
          disabled={true}
        />
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={3}>
          <TextField
            label={t('client.title')}
            color="secondary"
            name="title"
            disabled={true}
          />
        </Grid>
        <Grid item xs={12} sm={9}>
          <TextField
            label={t('settings_section.clients.form_label_contact')}
            placeholder={t('settings_section.clients.form_label_contact')}
            color="secondary"
            name="contact"
            disabled={true}
          />
        </Grid>
      </Grid>
    );
  };

  const formik = useFormikContext();

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    const { values } = formik;
    const { iban, bic } = values;

    try {
      spinnerService.startSpinner();
      const response = await refundService.updateClientBankInfo(
        {
          iban,
          bic,
        },
        queryParams
      );
      if (response?.status === 200) {
        const { data } = response.data;

        console.log('data', data);
        history.push('/home');
      }
    } catch (error) {
      dispatch(setGlobalError(error));
    } finally {
      spinnerService.endSpinner();
    }
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <Grid container xs={6} spacing={2} className={classes.container}>
        <Grid item xs={12}>
          <div>
            <p
              className={classes.subTitle}
              dangerouslySetInnerHTML={{
                __html: t('invoices.refund_bank_sub_title'),
              }}
            ></p>
          </div>
        </Grid>
        <Grid item xs={12}>
          <AppRadioGroup
            color="secondary"
            name="isCompany"
            row
            disabled={true}
            valueSelected={
              props.values.isCompany ? 'isCompany' : 'isNotCompany'
            }
          >
            <AppRadioButton
              value="isCompany"
              checked={true}
              text={t('client.type.company')}
              key="isCompany"
              disabled={false}
            />
            <div style={{ padding: '10px' }} />
            <AppRadioButton
              value="isNotCompany"
              text={t('client.type.person')}
              key="isNotCompany"
              disabled={false}
            />
          </AppRadioGroup>
        </Grid>
        <Grid item xs={12}>
          {displayName()}
        </Grid>

        <Grid item xs={12}>
          <TextField
            name="address"
            fullWidth
            label={t('settings_section.clients.form_label_address')}
            placeholder={t('settings_section.clients.form_label_address')}
            color="secondary"
            id="address"
            disabled={true}
          />
        </Grid>

        <Grid item xs={6} sm={4}>
          <TextField
            setWidthManualy="30%"
            name="zipcode"
            label={t('settings_section.clients.form_label_zip')}
            placeholder={t('settings_section.clients.form_label_zip')}
            color="secondary"
            id="zipcode"
            disabled={true}
          />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextField
            setWidthManualy="30%"
            name="city"
            label={t('settings_section.clients.form_label_city')}
            placeholder={t('settings_section.clients.form_label_city')}
            color="secondary"
            id="city"
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} sm={4}>
          <TextField
            setWidthManualy="30%"
            name="country"
            label={t('settings_section.clients.form_label_country')}
            placeholder={t('settings_section.clients.form_label_country')}
            color="secondary"
            id="country"
            disabled={true}
          />
        </Grid>

        {props.values.isCompany && (
          <>
            <Grid item xs={12} sm={4}>
              <TextField
                label={t('client.title')}
                color="secondary"
                name="title"
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <TextField
                label={t('settings_section.clients.form_label_contact')}
                placeholder={t('settings_section.clients.form_label_contact')}
                color="secondary"
                name="contact"
                disabled={true}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} sm={4}>
          <TextField
            setWidthManualy="32%"
            label={t('settings_section.clients.form_label_phone')}
            placeholder={t('settings_section.clients.form_label_phone')}
            color="secondary"
            name="phone"
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} sm={8}>
          <TextField
            setWidthManualy="64%"
            label={t('settings_section.clients.form_label_email')}
            placeholder={t('settings_section.clients.form_label_email')}
            color="secondary"
            name="email"
            disabled={true}
          />
        </Grid>

        <Grid item xs={12} sm={7}>
          <TextField
            required
            setWidthManualy="64%"
            label={t('invoices.IBAN')}
            placeholder={t('invoices.IBAN')}
            color="secondary"
            name="iban"
            value={props.values.iban}
            onChange={(e) => props.setFieldValue('iban', e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={5}>
          <TextField
            setWidthManualy="32%"
            label={t('invoices.BIC')}
            placeholder={t('invoices.BIC')}
            color="secondary"
            name="bic"
            value={props.values.bic}
            onChange={(e) => props.setFieldValue('bic', e.target.value)}
          />
        </Grid>

        {props.values.isCompany && (
          <>
            <Grid item xs={12}>
              <TextField
                label={t('settings_section.clients.form_label_siret')}
                placeholder={t('settings_section.clients.form_label_siret')}
                color="secondary"
                name="siret"
                disabled={true}
              />
            </Grid>

            <Grid item container xs={12} alignItems="center">
              <Grid item xs={4}>
                <AppCheckbox
                  onClick={() => {}}
                  disabled={true}
                  checked={!props.values.intracom_vat}
                  labelText={t('client.vat')}
                  name="vat"
                  id="vat"
                />
              </Grid>

              <Grid item xs={8}>
                {!!props.values.intracom_vat && (
                  <TextField
                    label={t('settings_section.clients.form_label_vat')}
                    placeholder={t('settings_section.clients.form_label_vat')}
                    color="secondary"
                    name="intracom_vat"
                    disabled={true}
                  />
                )}
              </Grid>
            </Grid>
          </>
        )}

        <Grid item container xs={12} justify="flex-end">
          <AppButton
            color="secondaryLight"
            text={t('draft')}
            type="submit"
            noBorder={true}
          />
        </Grid>
      </Grid>
    </Form>
  );
};

RefundForm.propTypes = {
  values: PropTypes.object.object,
  setFieldValue: PropTypes.object.func,
  setValues: PropTypes.object.func,
};

const CreditNoteBankInfoWithForm = withFormik({
  displayName: 'RefundForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
})(RefundForm);
const TranslatedUserFormWithStyles = withTranslation()(
  CreditNoteBankInfoWithForm
);
export default TranslatedUserFormWithStyles;
