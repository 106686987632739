import { LANGUAGE_CHANGED } from '../../actions/Types';

export default (state = '', action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case LANGUAGE_CHANGED:
      return action.payload.language;
    default:
      return state;
  }
};
