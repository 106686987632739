import combinations from '../assets/data/combinationsMentions';
import DEFAULT_MENTIONS from '../assets/data/mentions';

export default function mentionsHelper(settings) {
  let mentions = [...DEFAULT_MENTIONS];
  combinations.forEach((combination) => {
    let add = true;
    // eslint-disable-next-line
    for (const key in combination.settings) {
      if (Object.prototype.hasOwnProperty.call(combination.settings, key)) {
        if (combination.settings[key] !== settings[key]) {
          add = false;
          break;
        }
      }
    }
    if (add) {
      mentions = mentions.concat(combination.mentions);
    }
  });
  return mentions;
}
