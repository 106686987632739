/* eslint-disable react/display-name */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';

import ExpenseService from 'services/ExpenseService';
import CircularProgressCentered from 'components/CircularProgressCentered';
import { InputAdornment, TextField, Checkbox, Grid } from '@material-ui/core';
import { AppTextContent, AppTextTitle } from 'components/AppText';
import _, { map, reduce } from 'lodash';
import AppButton from 'components/AppButton';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ErrorMessage from 'components/ErrorMessage';
import MaterialTable from 'components/MaterialTable';
import BankinApiService from 'services/BankinApiService';
import { fetchExpensePaginate } from 'actions/ExpensesActions';
import { updateWalletTransaction } from 'actions/WalletActions';
import { useDecimalCharacter } from 'hooks/decimalCharacterHooks';
import NumberFormat from 'helpers/NumberFormat';
import CurrencyHelper from 'helpers/currencyHelper';
import InputHelper from 'helpers/inputHelper';

const OutcomeTransaction = ({
  transaction,
  transactionType,
  getTransaction,
  expenseId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [expenses, setExpenses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState();
  const [toPay, setToPay] = useState({});
  const [amounts, setAmounts] = useState({});

  const baseAmount = React.useMemo(() => {
    return (
      Math.abs(+transaction.amount) - Math.abs(+transaction?.amountUsed || 0)
    );
  }, [transaction.amount, transaction.amountUsed]);

  const [amountLeft, setAmountLeft] = useState(baseAmount);

  const companyId = useSelector((state) => state.loggedUserCompany.company._id);
  const userId = useSelector((state) => state.loggedUser.user._id);
  const language = useSelector((state) => state.guiLanguage);

  const { decimalChar, exceptDecimalChar } = useDecimalCharacter();

  let account;

  if (transactionType === 'bank') {
    account = useSelector((state) => {
      // eslint-disable-next-line camelcase
      const { bank_details } = state.loggedUserCompany.company;
      const bankAccount = bank_details.find(
        // eslint-disable-next-line eqeqeq
        (bank) => bank.accountId == transaction.account_id
      );
      return bankAccount ? bankAccount?.account : null;
    });
  } else if (transactionType === 'wallet') {
    account = useSelector((state) => {
      if (state.wallet && state.wallet.wallet.length > 0) {
        return state.wallet.wallet[0];
      }
      return {};
    });
  }

  const formatExp = (exp) => {
    const newexp = exp.map((expense) => ({
      expense_nb: expense.expense_nb,
      duePayableAmount:
        expense.duePayableAmount || expense.grandTotalAmount || expense.total,
      id: expense._id,
      date_emission: expense.date,
      description: expense.description,
    }));
    return newexp;
  };

  useEffect(() => {
    const formatAmounts = _.mapValues(amounts, (value) =>
      typeof value === 'number'
        ? value
        : NumberFormat.getNumber(value, decimalChar, ' ')
    );

    const arr = map(formatAmounts, (amount) =>
      amount ? parseFloat(amount) : 0
    );
    const sumAmount = reduce(arr, (sum, amount) => sum + amount);
    setAmountLeft(Math.round((baseAmount - (sumAmount || 0)) * 100) / 100);
  }, [amounts]);

  useEffect(() => {
    if (!expenseId || !expenses?.length) {
      return;
    }
    const expenseIds = expenseId.split(',');
    const newToPay = { ...toPay };
    const newAmounts = { ...amounts };
    expenseIds.forEach((id) => {
      const expense = expenses.find((exp) => exp.id === id);
      if (expense) {
        newToPay[expense.id] = true;
        newAmounts[expense.id] = expense.duePayableAmount;
      }
    });
    setToPay(newToPay);
    setAmounts(newAmounts);
  }, [expenseId, expenses]);

  const sendTransaction = () => {
    setLoading(true);
    const promises = [];
    let totalAmount = 0;
    if (amountLeft < 0) return;
    const keys = Object.keys(toPay);

    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];

      if (toPay[key]) {
        if (typeof amounts[key] === 'number') {
          totalAmount += Math.abs(+amounts[key]);
        } else {
          totalAmount += Math.abs(
            +NumberFormat.getNumber(amounts[key], decimalChar, ' ')
          );
        }
        promises.push(
          ExpenseService.payExpense(key, {
            amount:
              typeof amounts[key] === 'number'
                ? amounts[key]
                : NumberFormat.getNumber(amounts[key], decimalChar, ' '),
            account,
            date: transaction.date,
            type: transactionType,
          })
        );
      }
    }

    Promise.allSettled(promises).then((results) => {
      const items = [];
      let errors = 0;
      results.forEach((res) => {
        if (res.status === 'fulfilled') {
          items.push({
            id: res.value.data._id, // objectid from expense
            date: format(new Date(), 'yyyy-MM-dd'), // when it has been associated
            amount: amounts[res.value.data._id],
            type: 'expense',
          });
        } else errors += 1;
      });

      if (errors)
        setError({ message: `${errors} ${t('transaction.outcome_error')}` });
      if (items.length) {
        if (transactionType === 'bank') {
          BankinApiService.updateTransaction(
            companyId,
            userId,
            transaction._id,
            {
              amount: -totalAmount,
            }
          )
            .then(() => {
              setSuccess(true);
              getTransaction();
            })
            .finally(() => {
              setLoading(false);
            });
        } else if (transactionType === 'wallet') {
          dispatch(
            updateWalletTransaction(transaction._id, {
              items: [...transaction.items, ...items],
            })
          )
            .then(() => {
              setSuccess(true);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      } else setLoading(false);
    });
  };

  const isPayable = () => {
    let payable = true;
    if (amountLeft < 0) return true;
    const keys = Object.keys(toPay);
    for (let i = 0; i < keys.length; i += 1) {
      const key = keys[i];
      if (toPay[key]) {
        payable = false;
        if (!amounts[key]) return true;
      }
    }
    return payable;
  };

  const renderTable = () => {
    return (
      <MaterialTable
        title="expenses"
        columns={[
          {
            label: t('transaction.reconcile'),
            field: 'checkbox',
            // eslint-disable-next-line react/prop-types
            render: ({ id, duePayableAmount }) => (
              <Checkbox
                checked={toPay[id] || false}
                onChange={(e) => {
                  setToPay({ ...toPay, [id]: e.target.checked });
                  setAmounts({
                    ...amounts,
                    [id]: e.target.checked ? duePayableAmount : '',
                  });
                }}
              />
            ),
            sorting: false,
          },
          {
            title: t('invoices.list.nb'),
            field: 'expense_nb',
            sorting: false,
          },
          {
            title: 'Date',
            field: 'date_emission',
            type: 'date',
            defaultSort: 'desc',
            render: (value) =>
              format(new Date(value.date_emission), 'dd/MM/yyyy'),
          },
          { title: 'Description', field: 'description', sorting: false },
          {
            title: t('expenses.expense.remain_to_pay'),
            field: 'duePayableAmount',
            sorting: false,
            // eslint-disable-next-line react/prop-types
            render: ({ duePayableAmount }) =>
              duePayableAmount ? `${duePayableAmount.toFixed(2)}€` : '',
          },
          {
            title: t('expenses.expense.total'),
            field: 'amount',
            sorting: false,
            // eslint-disable-next-line react/prop-types
            render: ({ id }) => {
              if (toPay[id]) {
                return (
                  <div style={{ width: 100 }}>
                    <TextField
                      fullWidth
                      type="text"
                      value={CurrencyHelper.handleDisplayCurrency(
                        amounts[id],
                        decimalChar
                      )}
                      placeholder={language === 'fr' ? '0,00' : '0.00'}
                      onChange={(e) => {
                        const val = e.target.value;

                        const formatValue = InputHelper.getNumberFormatInput(
                          val,
                          decimalChar,
                          exceptDecimalChar,
                          language
                        );

                        if (formatValue === null) return;

                        setAmounts({
                          ...amounts,
                          [id]: formatValue,
                        });
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">€</InputAdornment>
                        ),
                      }}
                    />
                  </div>
                );
              }
              return null;
            },
          },
        ]}
        data={(query) =>
          new Promise((resolve) => {
            dispatch(
              fetchExpensePaginate(
                { ...query },
                {
                  state: ['validated'],
                  ids: expenseId ? expenseId.split(',') : undefined,
                },
                false
              )
            ).then((res) => {
              const formatted = formatExp(res.data);
              setExpenses(formatted);
              resolve({ ...res, data: formatted || [] });
            });
          })
        }
        options={{
          filtering: false,
          search: false,
          showTitle: false,
        }}
      />
    );
  };

  const renderBody = () => {
    if (error) {
      return (
        <Grid container direction="row" justify="center" alignItems="center">
          <div style={{ margin: 10 }}>
            <ErrorMessage showError error={error.message || t('error')} />
          </div>
        </Grid>
      );
    }
    if (success) {
      return (
        <Grid container direction="row" justify="center" alignItems="center">
          <div style={{ margin: 10, display: 'inline' }}>
            <AppTextTitle>{t('transaction.success')}!</AppTextTitle>
          </div>
        </Grid>
      );
    }
    if (loading) {
      return <CircularProgressCentered />;
    }
    if (expenses)
      return (
        <>
          {renderTable()}
          <div style={{ marginTop: 20 }}>
            <AppButton
              text={t('transaction.reconcile')}
              disabled={isPayable()}
              onClick={() => {
                sendTransaction();
              }}
            />
          </div>
        </>
      );
    return null;
  };

  if (transaction) {
    return (
      <>
        <div style={{ margin: 10 }}>
          <div style={{ margin: 10 }}>
            <AppTextTitle>{`Transaction No ${
              transaction.id || transaction.transactionId
            }:`}</AppTextTitle>
          </div>
          <Grid container justify="space-between">
            <div style={{ margin: 10 }}>
              <AppTextContent>{`${
                transaction.clean_description
              }, d'un montant de ${transaction.amount}€ realisé le ${format(
                new Date(transaction.date || transaction.valueDate),
                'dd/MM/yyyy'
              )}`}</AppTextContent>
            </div>
            <div>
              <AppTextTitle>
                {t('transaction.amount_left')}:{' '}
                <span style={amountLeft < 0 ? { color: 'red' } : {}}>
                  {amountLeft}€
                </span>
              </AppTextTitle>
            </div>
          </Grid>
        </div>
        {renderBody()}
      </>
    );
  }
  return null;
};

OutcomeTransaction.propTypes = {
  transaction: PropTypes.object.isRequired,
  transactionType: PropTypes.string.isRequired,
  getTransaction: PropTypes.func.isRequired,
  expenseId: PropTypes.string,
};

export default React.memo(OutcomeTransaction);
