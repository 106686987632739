import { styled, useTheme } from '@material-ui/core';
import { getThemeColor } from 'helpers/utils';
import React from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useRouter } from 'hooks/routerHooks';
import queryString from 'query-string';
import { EXPENSE_STATE } from 'constants/expense';
import NumberFormat from 'helpers/NumberFormat';
import Fonts from 'config/fontFamily';

const StyledApexChart = styled(ReactApexChart)(() => ({
  '& .apexcharts-legend': {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    alignItems: 'flex-start',
  },
}));

const ExpenseDoughnutChart = (props) => {
  const { validatedPurchases, validatedExpenses } = props;
  const { t } = useTranslation();
  const theme = useTheme();
  const { history } = useRouter();

  const navigatePage = (url, queryParams) => {
    history.push(
      queryString.stringifyUrl(
        {
          url,
          query: queryParams,
        },
        { arrayFormat: 'index' }
      )
    );
  };

  const getElementAtEvent = (dataPointIndex) => {
    if (dataPointIndex < 2) {
      const expenseFilters = {
        state: [EXPENSE_STATE.UPCOMING, EXPENSE_STATE.OVERDUE],
      };
      if (dataPointIndex === 0) navigatePage('purchases/list', expenseFilters);
      else navigatePage('expenses/list', expenseFilters);
    } else {
      const expenseFilters = {
        state: ['paid'],
      };
      navigatePage('purchases/list', expenseFilters);
    }
  };

  const chartData = {
    series: [validatedPurchases, validatedExpenses],
    options: {
      labels: [
        t('expense.overview.validated_purchases'),
        t('expense.overview.validated_expenses'),
      ],
      tooltip: {
        y: {
          formatter(value) {
            return `${NumberFormat.n(value)}€`;
          },
        },
      },
      chart: {
        type: 'donut',
        events: {
          dataPointSelection(event, chartContext, opts) {
            const { dataPointIndex } = opts;
            getElementAtEvent(dataPointIndex);
          },
        },
      },
      legend: {
        position: 'bottom',
        markers: {
          shape: 'square',
        },
        labels: {
          colors: '#595959',
        },
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: Fonts.primary,
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 0,
      },
      colors: [
        getThemeColor(theme.palette.primary.main),
        getThemeColor(theme.palette.primary[300]),
      ],
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
          },
        },
      ],
    },
  };

  return (
    <StyledApexChart
      options={chartData.options}
      series={chartData.series}
      type="donut"
      height={300}
    />
  );
};

ExpenseDoughnutChart.propTypes = {
  validatedPurchases: PropTypes.number.isRequired,
  validatedExpenses: PropTypes.number.isRequired,
};

export default ExpenseDoughnutChart;
