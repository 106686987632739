/* eslint-disable camelcase */
import { parse } from 'date-fns';
import { filteredTree } from 'helpers/CategoryHelpers';
import { setProvider } from 'helpers/providerHelpers';
import { getFileExtension } from 'helpers/utils';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import GreffeService from 'services/GreffeService';
import OcrService from 'services/OcrService';
import { roundingNumber, trimAll } from 'utils';
import { v4 as uuidv4 } from 'uuid';
import mediaService from 'services/S3FileService';
import { EXPENSE_TYPE } from 'constants/expense';
/* eslint-disable import/prefer-default-export */
export const useScanOcr = ({
  setFieldValue,
  values,
  toggleNewProviderDialog,
  type,
}) => {
  const [ocrError, setOcrError] = useState(null);
  const providersState = useSelector((state) => state.providers);
  const tree = useSelector((state) => state.categories.tree || []);
  const providers = Object.values(providersState);
  const companyId = useSelector((state) => state.loggedUserCompany.company._id);
  const { _id: userId } = useSelector((state) => state.loggedUser.user);
  const { t } = useTranslation();

  const syncScanOcr = async () => {
    const config = await OcrService.getOrcDimarcConfig();

    const { data } = await OcrService.scanORCwithDimarc(values.filename, {
      token_type: config.token_type,
      access_token: config.access_token,
    });
    return data;
  };
  const setFormikValues = (UpdatedValues, i) => {
    setFieldValue(`amount${i}`, parseFloat(UpdatedValues.amount));
    setFieldValue(`vatrate${i}`, parseFloat(UpdatedValues.vat_rate));
    setFieldValue(`vat${i}`, parseFloat(UpdatedValues.vat));
    setFieldValue(`total${i}`, parseFloat(UpdatedValues.total));
  };

  const scanItems = (items, provider) => {
    if (!items) return;

    const format = items?.map((item) => {
      const { ratio_tva, final_price_ttc, total_price_ht, price_tva } = item;
      const data = {
        amount: total_price_ht,
        vat: price_tva,
        total: final_price_ttc,
        vat_rate: ratio_tva * 100,
      };

      if (provider) {
        return {
          ...data,
          category_id: provider.category,
          sub_category_id: provider.subCategory,
        };
      }
      return data;
    });

    setFieldValue('amounts', format);

    setFieldValue('numberOfActiveVats', format?.length);
    setFieldValue('import_numberOfActiveVats', format?.length || 1);

    format?.forEach((item, i) => setFormikValues(item, i));
    setFieldValue('updateOfTotalDone', false);
  };

  const setFieldDate = (name, date) => {
    const parsedDueDate = parse(date, 'dd/MM/yyyy', new Date());
    setFieldValue(name, parsedDueDate);
  };

  const onOcrDimarc = async (data) => {
    try {
      setFieldValue('hasProvider', true);
      setFieldValue('withOcr', true);
      const { invoice_infos, item_lines = null, seller } = data;
      let { iban } = seller;
      const { bic } = seller;
      iban = trimAll(iban);
      const siret = seller.siret.split(' ').join('');
      const siren = seller.siren.split(' ').join('');
      const {
        amount_ex_vat: prixHT,
        amount_vat: prixTVA,
        total_amount: prixTTC,
        due_date: dateEcheance,
        creation_date: date,
        invoice_id: billNumber,
      } = invoice_infos;
      const vatRate = roundingNumber((prixTVA / prixHT) * 100, 0);
      if (dateEcheance) {
        setFieldDate('due_date', dateEcheance);
      }

      if (date) {
        setFieldDate('date', date);
      }

      setFieldValue('grandTotalAmount', prixTTC);
      setFieldValue('taxBasisTotalAmount', prixHT);
      setFieldValue('duePayableAmount', prixTTC);
      setFieldValue('taxTotalAmount', prixTVA);
      setFieldValue('totalForm', prixTTC);

      setFieldValue('prixTTC', prixTTC);
      setFieldValue('prixHT', prixHT);
      setFieldValue('prixTVA', prixTVA);

      if (type === EXPENSE_TYPE.EXPENSE) {
        setFieldValue('amount0', prixHT);
        setFieldValue('vat0', prixTVA);
        setFieldValue('total0', prixTTC);
        setFieldValue('vatrate0', vatRate);
      } else {
        setFieldValue('amounts.0.amount', prixHT);
        setFieldValue('amounts.0.vat', prixTVA);
        setFieldValue('amounts.0.total', prixTTC);
        setFieldValue('amounts.0.vat_rate', vatRate);
      }

      const invoiceNb = billNumber ? `N° ${billNumber}` : '';
      setFieldValue('billNumber', billNumber);
      setFieldValue('description', invoiceNb);

      if (siret || siren) {
        const existedProvider = providers.find(
          (provider) => provider.siret === siret
        );

        if (existedProvider) {
          setProvider(
            existedProvider,
            setFieldValue,
            values,
            filteredTree(tree, t),
            t
          );
          setFieldValue('amounts.0.category_id', existedProvider.category);
          setFieldValue(
            'amounts.0.sub_category_id',
            existedProvider.subCategory
          );
          setFieldValue('description', `${existedProvider.name} ${invoiceNb}`);
          scanItems(item_lines, existedProvider);
        } else {
          let formatProvider = {};
          const providerInGreffe = await GreffeService.infoGreffe(
            siret?.endsWith('00000') ? siren : siret || siren
          );
          scanItems(item_lines, null);
          const { data: info } = providerInGreffe;
          if (info?.length > 1) {
            formatProvider = {
              name: siren,
              searchInfoGreffe: true,
            };
          } else if (info?.length === 1) {
            const firstProvider = info[0];
            const tvaIntraccom = `FR ${
              (3 * (firstProvider.siren % 97) + 12) % 97
            }${firstProvider.siren}`;

            formatProvider = {
              siret,
              iban: iban || '',
              bic: bic || '',
              address: firstProvider?.adresse,
              name: firstProvider?.denomination,
              zipcode: firstProvider?.code_postal,
              nic: firstProvider?.nic,
              city: firstProvider?.ville,
              intracomvat: tvaIntraccom,
              code_ape: firstProvider?.code_ape,
              legal_form_id: firstProvider?.id_forme_juridique,
              isCompany: true,
            };
            setFieldValue(
              'description',
              `${firstProvider?.denomination} ${invoiceNb}`
            );
          } else {
            const tvaIntraccom = `FR ${(3 * (siren % 97) + 12) % 97}${siren}`;
            const { adresse: address } = seller;
            const { pays = 'FR' } = address;
            formatProvider = {
              siret,
              iban: iban || '',
              bic: bic || '',
              address: address.rue,
              name: seller.raison_sociale,
              zipcode: address.code_postal,
              nic: seller?.nic,
              city: address?.ville,
              intracomvat: tvaIntraccom,
              code_ape: '',
              legal_form_id: '',
              isCompany: true,
              country: pays,
            };

            setFieldValue(
              'description',
              `${seller.raison_sociale} ${invoiceNb}`
            );
          }
          toggleNewProviderDialog();
          setFieldValue('providerForm', formatProvider);
        }
      }
    } catch (error) {
      setOcrError(error);
    }
  };

  async function onOcr() {
    try {
      setFieldValue('loading', true);
      setFieldValue('firstScan', true);
      const dataScan = await syncScanOcr();
      await onOcrDimarc(dataScan);
    } catch (error) {
      setOcrError(error);
    } finally {
      setFieldValue('loading', false);
      setFieldValue('firstScan', false);
    }
  }

  const handleCache = async () => {
    try {
      setFieldValue('loading', true);
      const { ...rest } = values;

      // file
      const file = values.filename;
      const ext = getFileExtension(values.filename.name);
      const folder = `company-files-${companyId}`;
      const fileName = `${uuidv4()}-${new Date().toISOString()}.${ext}`;

      const filename = `${folder}/${fileName}`;

      await mediaService.upload(file, folder, fileName);

      const cache = await OcrService.cacheData(
        userId,
        { ...rest, filename, redirectUrl: window?.location?.href },
        file?.name,
        file?.size
      );

      const config = await OcrService.getOrcDimarcConfig();
      await OcrService.asyncScanORCwithDimarc(
        values.filename,
        {
          token_type: config.token_type,
          access_token: config.access_token,
        },
        cache.data._id
      );
    } catch (error) {
      console.error(error);
    } finally {
      setFieldValue('loading', false);
    }
  };

  const getValuesFromCache = async (cacheId) => {
    try {
      setFieldValue('loading', true);
      if (cacheId) {
        const cacheValues = await OcrService.getCacheData(cacheId);
        if (cacheValues && cacheValues.data) {
          const { data, temp } = cacheValues.data;
          Object.keys(data).forEach((key) => {
            setFieldValue(key, data[key]);
          });
          await onOcrDimarc(temp.data, null, null);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setFieldValue('loading', false);
    }
  };

  return { onOcr, ocrError, onOcrDimarc, handleCache, getValuesFromCache };
};
