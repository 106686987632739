/* eslint-disable-next-line */
export default ({ id, users, loggedUser }) => {
  const { treezorUser } = loggedUser.user;
  if (id && users[id]) {
    return {
      ...users[id],
      title: users[id].title ? users[id].title : '',
      avatar: undefined,
      passport: undefined,
      birthday: treezorUser ? treezorUser.birthday : null,
      birthCountry: treezorUser ? treezorUser.birthCountry : null,
      placeOfBirth: treezorUser ? treezorUser.placeOfBirth : null,
      address1: treezorUser ? treezorUser.address1 : null,
      city: treezorUser ? treezorUser.city : null,
      country: treezorUser ? treezorUser.country : null,
      postcode: treezorUser ? treezorUser.postcode : null,
    };
  }

  return { role: [], title: '' };
};
