import colors from '../../../constants/colors.json';
import {
  selectButtonPrimary,
  openedSelectButton,
  selectButtonSecondary,
  inputLabel,
  inputText,
} from './appText';

const style = {
  selectInput: {
    ...selectButtonPrimary,
    height: '100%',
    width: '100%',
    paddingLeft: '10px',
    backgroundColor: colors.white,
    '&:hover': {
      backgroundColor: colors.background,
    },
    '&:active': {},
    '&:focus': {},
    '&:disabled': {
      backgroundColor: colors.fillsGrey,
    },
  },
  selectButton: {
    ...selectButtonPrimary,
    border: 'none',
    borderTopLeftRadius: '4px',
    borderTopRightRadius: '4px',
    margin: '0 auto',
    textDecoration: 'none',
    height: '40px',
    width: '100%',
    backgroundColor: colors.white,
    textTransform: 'uppercase',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: '2px solid transparent',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: colors.background,
      borderBottom: '2px solid #3d319d',
    },
    '&:active': {},
    '&:focus': {},
    '&:disabled': {
      backgroundColor: colors.fillsGrey,
    },
    '& p': {
      margin: 0,
    },
  },

  secondary: {
    ...selectButtonSecondary,
    '&:hover': {
      borderBottom: '2px solid #02CBCD',
    },
    '&:disabled': {
      backgroundColor: colors.white,
      color: colors.fillsGrey,
    },
  },

  simpleSecondary: {
    ...selectButtonSecondary,
    color: colors.fillsGrey,
    textTransform: 'capitalize',
    borderBottom: `1px solid ${colors.fillsDarkGrey}`,
    '& span': {
      color: colors.green,
    },
    '& input': {
      textTransform: 'capitalize',
    },
    '&:hover': {
      borderBottom: `1px solid ${colors.fillsDarkGrey}`,
    },
    '&:disabled': {
      backgroundColor: colors.white,
      color: colors.fillsGrey,
    },
  },

  simpleSecondarySelected: {
    ...selectButtonSecondary,
    color: colors.grey,
    textTransform: 'capitalize',
    borderBottom: `1px solid ${colors.fillsDarkGrey}`,
    '& span': {
      color: colors.green,
    },
    '& input': {
      textTransform: 'capitalize',
      color: colors.grey,
    },
    '&:hover': {
      borderBottom: `1px solid ${colors.fillsDarkGrey}`,
    },
    '&:disabled': {
      backgroundColor: colors.white,
      color: colors.fillsGrey,
    },
  },

  secondaryGrey: {
    textAlign: 'left',
    border: '1px solid #C2C2C2',
    borderRadius: '4px',
    backgroundColor: colors.white,
    color: colors.fillsDarkGrey,
    '&:hover': {
      borderBottomLeftRadius: '0',
      paddingTop: '3px',
      paddingLeft: '8px',
      borderBottomRightRadius: '0',
      borderBottom: '2px solid #02CBCD',
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
      color: colors.green,
    },
  },

  primarySelectOpened: {
    ...openedSelectButton,
    backgroundColor: colors.blueOpacity,
    '&:hover': {
      backgroundColor: colors.blueOpacity,
    },
  },
  secondarySelectOpened: {
    ...openedSelectButton,
    backgroundColor: colors.green,
    '&:hover': {
      backgroundColor: colors.green,
    },
  },

  simpleSecondarySelectOpened: {
    ...openedSelectButton,
    color: colors.grey,
    backgroundColor: colors.fillsLightGrey,
    '&:hover': {
      color: colors.grey,
      backgroundColor: colors.fillsLightGrey,
    },
  },

  secondaryGreySelectOpened: {
    ...openedSelectButton,
    backgroundColor: colors.green,
    color: colors.white,
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    borderTop: 'none',
    borderRight: 'none',
    borderLeft: 'none',
    '&:hover': {
      backgroundColor: colors.green,
      color: colors.white,
      borderTop: 'none',
      borderRight: 'none',
      borderLeft: 'none',
    },
  },

  noBorder: {
    border: 'none',
  },

  secondaryInputDisplay: {
    ...inputText,
    textAlign: 'left',
    border: '1px solid transparent',
    height: '35px',
    borderRadius: '4px',
    backgroundColor: colors.white,
    margin: 0,
    '&:hover': {
      borderRadius: '4',
      border: `1px solid ${colors.fillsDarkGrey}`,
      backgroundColor: colors.white,
      color: colors.textDarkGrey,
      '& span': {
        color: colors.textDarkGrey,
      },
    },
    '&:disabled': {
      backgroundColor: colors.white,
      color: colors.fillsGrey,
    },
  },

  secondaryInputDisplayOpened: {
    border: `1px solid ${colors.green}`,
    '&:hover': {
      border: `1px solid ${colors.green}`,
    },
  },

  simpleSecondaryInputDisplayOpened: {
    border: `1px solid ${colors.textDarkGrey}`,
    '&:hover': {
      border: `1px solid ${colors.textDarkGrey}`,
    },
  },

  iconInput: {
    color: 'transparent',
    '&:hover': {
      color: colors.textDarkGrey,
    },
  },
  iconSelectOpened: {
    color: colors.textDarkGrey,
  },

  errorButton: {
    border: '1px solid #FF2B44',
    borderRadius: '4px',
    '&:active': {
      border: '1px solid #FF2B44',
    },
    '&:focus-within': { border: '1px solid #FF2B44' },
    '&:hover': {
      border: 'none',
    },
    '&:disabled': {
      backgroundColor: colors.white,
      color: colors.fillsGrey,
    },
  },

  errorMessage: {
    ...inputLabel,
    color: colors.red,
    position: 'absolute',
    width: 'auto',
    backgroundColor: colors.white,
    zIndex: 100,
    fontWeight: '300',
    top: '45px',
    padding: '0 3px',
    transform: 'scaleY(1)',
  },

  fullWidth: {
    width: '100%',
  },
};

export default style;
