import React, { Component } from 'react';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';

import dispatch from './dispatch';

class Login extends Component {
  componentDidMount() {
    this.props.login();
  }

  // eslint-disable-next-line class-methods-use-this
  render() {
    return <></>;
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
};

export default connect(null, dispatch)(Login);
