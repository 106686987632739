import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

function useOutsideClick(ref, dsp, uponClick, disabled) {
  function handleClickOutside(event) {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      dsp &&
      !disabled
    ) {
      uponClick();
    }
  }

  useEffect(() => {
    if (dsp) {
      // listen only if teh dropdown menu has been open. Otherwise, all other similar divs in the page will add a listener to the DOM
      document.addEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
}

const OutsideClick = ({ children, displayDropdown, uponClick, disabled }) => {
  const wrapperRef = useRef(null);

  useOutsideClick(wrapperRef, displayDropdown, uponClick, disabled);

  return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClick;
OutsideClick.propTypes = {
  uponClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  displayDropdown: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};
