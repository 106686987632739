import {
  FETCH_KYC_USER,
  FETCH_KYC_USER_FAILED,
  FETCH_USER_TREEZOR,
  FETCH_USER_TREEZOR_FAILED,
  CREATE_TREEZOR_USER_FAILED,
  UPDATE_TREEZOR_DOC,
  FETCHING_TREEZOR_USERS,
  FETCH_TREEZOR_USERS_SUCCESS,
  ONBOARDING_TREEZOR,
  PROCESSING_CANCEL_TREEZOR_USER,
  SUCCESS_CANCEL_TREEZOR_USER,
} from '../../actions/Types';

const INITIAL_STATE = {
  loading: false,
  error: null,
  timestamp: null,
  treezorUser: null,
  treezorUsers: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_TREEZOR_USERS:
      return {
        ...state,
        loading: true,
      };
    case FETCH_TREEZOR_USERS_SUCCESS: {
      let treezorUser;
      const { treezorUsers } = action.payload;
      if (treezorUsers.length === 1) {
        // use the first element of the array
        [treezorUser] = treezorUsers;
      } else if (treezorUsers.length === 0) {
        treezorUser = null;
      } else {
        for (let a = 0; a < treezorUsers.length && !treezorUser; a += 1) {
          const u = treezorUsers[a];
          if (u.userTypeId === '2') {
            treezorUser = u;
          }
        }
      }
      return {
        ...state,
        treezorUser,
        treezorUsers: action.payload.treezorUsers,
        loading: false,
      };
    }
    case UPDATE_TREEZOR_DOC:
    case FETCH_USER_TREEZOR: // TODO remove this line
    case ONBOARDING_TREEZOR:
      return {
        ...state,
        loading: false,
        treezorUser: action.payload,
      };
    case FETCH_USER_TREEZOR_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case FETCH_KYC_USER:
      return { ...state, treezorUser: action.payload, loading: false };
    case FETCH_KYC_USER_FAILED:
      return { ...state, error: action.payload, loading: false };
    case CREATE_TREEZOR_USER_FAILED: {
      return { ...state, error: action.payload, loading: false };
    }
    case PROCESSING_CANCEL_TREEZOR_USER: {
      return { ...state, loading: true };
    }
    case SUCCESS_CANCEL_TREEZOR_USER: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};
