import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Lottie from 'react-lottie';
import * as PendingLottie from 'assets/lottieAnimations/pendingAnimation.json';
import { Typography, makeStyles, Grid } from '@material-ui/core';
import AppButton from 'components/AppButton';
import Header from 'components/Header';
import Layout from 'components/Layout';
import { useRouter } from 'hooks/routerHooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  getScreenToDisplay,
  getInvalidDocument,
  checkIfOneMoreDocumentIsRequired,
} from 'helpers/TreezorHelper';
import { KYCStatus } from '@bebusinessfocus/compta-hub-core';
import { fetchTreezorUsers } from 'actions/UserActions';
import { CARDS } from 'constants/treezor';

const useStyles = makeStyles({
  paragraphe: {
    textAlign: 'justifier',
    margin: '2% auto',
  },
  iban_bic: {
    textAlign: 'left',
    margin: '4% auto',
    width: '30%',
    // marginBottom: '2%',
  },
  numcompte: {
    marginLeft: '2%',
    padding: '5px',
    border: '1px solid #09B6C6',
    color: '#09B6C6',
  },
});

const TreezorDocsPending = () => {
  const { history } = useRouter();
  const classes = useStyles();
  const pendingOption = {
    loop: true,
    autoplay: true,
    animationData: PendingLottie.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const refreshTreezorUser = () => {
    dispatch(fetchTreezorUsers());
  };
  const { treezor, loggedUserCompany, wallet } = useSelector((state) => state);
  const treezorUserLoggedUserCompany = loggedUserCompany.company.treezorUser;
  const { companyType } =
    loggedUserCompany?.company?.settings?.tax_registration;
  const [treezorWallet, setTreezorWallet] = useState(null);

  useEffect(() => {
    if (wallet.wallet && wallet.wallet.length > 0) {
      setTreezorWallet(wallet.wallet.find((w) => w.bic.includes('TRZOFR')));
    }
  }, [wallet.wallet]);

  useEffect(() => {
    const status = KYCStatus.getKYCStatus(
      Number(treezor.treezorUser.kycLevel),
      Number(treezor.treezorUser.kycReview)
    );
    const companyStatus = KYCStatus.getKYCStatus(
      Number(treezorUserLoggedUserCompany.kycLevel),
      Number(treezorUserLoggedUserCompany.kycReview)
    );
    const screenToDisplay = getScreenToDisplay(status, companyStatus);
    if (screenToDisplay === CARDS) {
      history.push('cards');
    }
  });

  useEffect(() => {
    if (checkIfOneMoreDocumentIsRequired(treezor.treezorUsers)) {
      history.push('/treezorDocumentNeeded');
    }
    if (getInvalidDocument(treezor.treezorUsers).length > 0) {
      history.push('treezorDocumentsInvalids');
    }
  }, [treezor.treezorUsers]);

  const renderBody = () => {
    return (
      <>
        {companyType === 'Entrepreneur individuel' && treezorWallet && (
          <Grid container spacing={2}>
            <Grid item md={10} className={classes.paragraphe}>
              <Typography variant="h4" component="h2" color="primary">
                {t('treezor.last_step')}
              </Typography>
              <Typography
                variant="p"
                component="p"
                className={classes.paragraphe}
              >
                {t('treezor.complete_items_message_1')}
                <br />
                {t('treezor.complete_items_message_2')}
              </Typography>
            </Grid>
            <Grid item md={4}>
              <div className={classes.iban_bic_container}>
                <div className={classes.iban_bic}>
                  IBAN
                  <span className={classes.numcompte}>
                    {treezorWallet.iban}
                  </span>
                </div>
                <div className={classes.iban_bic}>
                  BIC
                  <span className={classes.numcompte}>{treezorWallet.bic}</span>
                </div>
              </div>
            </Grid>
            <Grid item md={10} className={classes.paragraphe}>
              <Typography
                variant="p"
                component="p"
                className={classes.paragraphe}
              >
                {t('treezor.problem_message')}
                <a href="mailto:inscription@bebusinessfocus.com">
                  inscription@bebusinessfocus.com
                </a>{' '}
                {t('treezor.call_us')}
                <a href="tel:+33320280140">03 20 28 01 40</a>
              </Typography>
            </Grid>
          </Grid>
        )}
        {(companyType !== 'Entrepreneur individuel' ||
          (companyType === 'Entrepreneur individuel' && !treezorWallet)) && (
          <div style={{ textAlign: 'center' }}>
            <Typography variant="h4" component="h2" color="primary">
              {t('treezor.documents_awaiting_verification')}
            </Typography>
            <Lottie options={pendingOption} height={300} width={300} />
            <AppButton
              onClick={refreshTreezorUser}
              type="button"
              fullWidth={true}
              text="Actualiser"
              color="primary"
              noBorder={true}
            />
          </div>
        )}
      </>
    );
  };

  return (
    <Layout
      header={
        <Header
          name={t('treezor.header')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

TreezorDocsPending.propTypes = {
  loggedUserCompany: PropTypes.object,
  user: PropTypes.object,
};

export default TreezorDocsPending;
