/* Packages */
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

/* Selectors */
import { userCanCreateExpense } from 'selectors/rightsSelector/expenseRequests';
import { userCanCreateAdvanceExpense } from 'selectors/rightsSelector/advanceExpenseRequests';

/* Components */
import Layout from 'components/Layout';
import AppPlusSelect from 'components/AppPlusSelect';
import Header from 'components/Header';

const LayoutHeader = ({ queryTableType }) => {
  const location = useLocation();
  const history = useHistory();
  const { t } = useTranslation();

  const canCreateExpense = useSelector(userCanCreateExpense);
  const canCreateAdvanceExpense = useSelector(userCanCreateAdvanceExpense);

  const pageTitle = location.state?.pageTitle || 'new_transfer';

  const stateToGoBack = {
    goBackPath: location.pathname,
    goBackQueryParams: location.search,
    pageTitle,
  };

  const purchasesCreateMenuItems = [
    {
      name: t('pdf_purchase.import_global_file'),
      onClick: () =>
        history.push({
          pathname: '/pdf',
          state: stateToGoBack,
        }),
    },
    {
      name: t('create'),
      onClick: () =>
        history.push({
          pathname: '/purchases/new',
          state: {
            payment: 'company',
            ...stateToGoBack,
          },
        }),
    },
  ];

  const expenseCreateMenuItems = () => {
    const types = [];

    if (canCreateExpense) {
      types.push({
        name: t('expenses.expense.expense'),
        type: 'expense',
        onClick: () =>
          history.push({
            pathname: '/expenses/new',
            state: {
              payment: 'personal',
              ...stateToGoBack,
            },
          }),
      });
    }

    if (canCreateAdvanceExpense) {
      types.push({
        name: t('expenses.advance.card_title'),
        type: 'advance_expense',
        onClick: () =>
          history.push({
            pathname: '/expenses/advance/new',
            state: stateToGoBack,
          }),
      });
    }

    return types;
  };

  return (
    <Header
      name={t(`bank_bbf.${pageTitle}`)}
      goBack={() =>
        history.push({
          pathname: '/bank-bbf/accounts',
        })
      }
      flexEnd
    >
      {canCreateExpense && (
        <AppPlusSelect
          name="name"
          label={
            queryTableType === 'purchase'
              ? t('create_purchase')
              : t('create_expense')
          }
          items={
            queryTableType === 'purchase'
              ? purchasesCreateMenuItems
              : expenseCreateMenuItems()
          }
        />
      )}
    </Header>
  );
};

const TransferPageLayout = ({ children, queryTableType }) => {
  return (
    <Layout
      header={<LayoutHeader queryTableType={queryTableType} />}
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={children}
    />
  );
};

LayoutHeader.propTypes = {
  pageTitle: PropTypes.string,
  queryTableType: PropTypes.string,
};

TransferPageLayout.propTypes = {
  children: PropTypes.any,
  queryTableType: PropTypes.string,
};

export default TransferPageLayout;
