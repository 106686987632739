import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';

import _ from 'lodash';

import { Checkbox, Typography } from '@material-ui/core';
import { WarningSharp } from '@material-ui/icons';

import {
  AppTable,
  AppTableCell,
  AppTableBody,
  AppTableRow,
} from '../../../components/AppTableComponents';
// import AppState from '../../../components/AppState';
import AppSelect from '../../../components/AppSelect';
import AppTextInputSecondary from '../../../components/AppTextInputSecondary';
// import AppCheckbox from '../../../components/AppCheckbox';
import state from './state';
import dispatch from './dispatch';
import styles from '../../../assets/jss/root';
import {
  capitalContentText,
  contentText,
  greyNormal,
} from '../../../assets/jss/components/appText';
import NumberFormat from '../../../helpers/NumberFormat';

const listStyles = {
  dateContainer: {
    width: '12%',
  },
  invoiceNumberColumn: {
    width: '22%',
  },
  statusColumn: {
    width: '7%',
  },
  amountColumn: {
    width: '17%',
  },
  statusContainer: {
    height: '250px',
  },
};

const useStyles = { ...styles, listStyles };

export class ItemsDropdownList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: null,
      checked: [],
      search: '',
      showDeletedDialog: false,
      idToDelete: null,
    };

    this._onSearch = this._onSearch.bind(this);
    this.onConfirmFunc = this.onConfirmFunc.bind(this);
  }

  _selectRow(checkboxStatus, index) {
    const { checked } = this.state;
    let newChecked = [...checked];

    if (checkboxStatus) {
      newChecked.push(index);
    } else {
      newChecked = newChecked.filter((item) => item !== index);
    }

    this.setState({
      checked: newChecked,
    });

    this.props.onSelect(newChecked);
  }

  _onSearch(e) {
    this.setState({ search: e.target.value });
  }

  _renderListData() {
    const { items } = this.props;

    const { checked, search } = this.state;

    let searchedItems = items;

    if (search !== '') {
      const skipped = ['company_id', '_id'];
      searchedItems = _.filter(items, (item) =>
        Object.keys(item).find(
          (key) =>
            !skipped.includes(key) &&
            item[key].toString().toLowerCase().includes(search.toLowerCase())
        )
      );
    }

    return _.map(
      searchedItems,
      (item) =>
        !item.deleted && (
          <AppTableRow key={item._id} useStyle={{ margin: '30px' }}>
            {/* <AppTableRow key={item._id} useStyle={{ marginLeft: '20px' }}> */}
            <AppTableCell setWidthManualy="7%">
              <Checkbox
                onClick={(e) => {
                  this._selectRow(e.target.checked, item._id);
                }}
                name={item._id}
                id={item._id}
                checked={checked.indexOf(item._id) !== -1}
              />
            </AppTableCell>
            <AppTableCell setWidthManualy="8%">
              {item.type.substr(0, 1)}
            </AppTableCell>
            <AppTableCell setWidthManualy="20%" useStyle={capitalContentText}>
              {item.name}
            </AppTableCell>
            <AppTableCell setWidthManualy="15%" useStyle={contentText}>
              {`${NumberFormat.n(item.net_price)} €`}
            </AppTableCell>
            <AppTableCell setWidthManualy="50%" useStyle={greyNormal}>
              {item.description}
            </AppTableCell>
          </AppTableRow>
        )
    );
  }

  _renderList() {
    return (
      <AppTable>
        <AppTableBody>{this._renderListData()}</AppTableBody>
      </AppTable>
    );
  }

  onConfirmFunc(func) {
    this.setState({ checked: [], search: '' });
    func();
  }

  render() {
    const { t, onConfirm, errorMessage, showError } = this.props;
    return (
      <>
        <AppSelect
          color="secondaryGrey"
          label={t('invoices.add_item')}
          textOnSelectOpened={t('click.confirm')}
          onButtonClose={() => {
            this.setState({ checked: [], search: '' });
            onConfirm();
          }}
          showError={showError}
          errorMessage={errorMessage}
          mandatory
          maxHeight="maxHeightS"
        >
          <AppTextInputSecondary
            placeholder={t('search.item')}
            fullWidth={true}
            value={this.state.search}
            onChange={this._onSearch}
            withSearchIcon
            color="secondary"
          />
          {this._renderList()}
        </AppSelect>
        {!this.props.invoiceItems.length > 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              marginTop: '10px',
            }}
          >
            <WarningSharp color="error" style={{ marginRight: '5px' }} />
            <Typography color="error">
              {t('invoices.required_choose_item')}
            </Typography>
          </div>
        )}
      </>
    );
  }
}

ItemsDropdownList.propTypes = {
  t: PropTypes.func,
  items: PropTypes.object,
  onSelect: PropTypes.func,
  onNewButtonClick: PropTypes.func,
  deleteItem: PropTypes.func,
  onConfirm: PropTypes.func,
  confirmText: PropTypes.string,
  showError: PropTypes.bool,
  errorMessage: PropTypes.string,
  invoiceItems: PropTypes.array,
};

ItemsDropdownList.defaultProps = {
  showError: false,
  errorMessage: null,
};

const ItemsListWithStyle = withStyles(useStyles)(ItemsDropdownList);
const RouterItemsListWithStyle = withRouter(ItemsListWithStyle);
const RouterItemsListWithTranslate = withTranslation()(
  RouterItemsListWithStyle
);

const RouterItemsListWithConnect = connect(
  state,
  dispatch
)(RouterItemsListWithTranslate);

export default RouterItemsListWithConnect;
