import { combineReducers } from 'redux';

import CategoriesReducer from './CategoriesReducer';
import DistanceRatesReducer from './DistanceRatesReducer';
import InternalActivitiesReducer from './InternalActivitiesReducer';
import VATRatesReducer from './VATRatesReducer';
import RolesReducer from './RolesReducer';
import UserRolesReducer from './UserRolesReducer';
import LoggedUserReducer from './LoggedUserReducer';
import LoggedUserCompany from './LoggedUserCompanyReducer';
import LanguageReducer from './LanguageReducer';
import ClientsReducer from './ClientReducer';
import ClientProjectsReducer from './ClientProjectsReducer';
import UserReducer from './UserReducer';
import ExpensesReducer from './ExpensesReducer';
import ExpenseWithVATListReducer from './ExpenseWithVATListReducer';
import ExpenseOverviewReducer from './ExpenseOverviewReducer';
import ItemsReducer from './ItemsReducer';
import InvoiceReducer from './InvoiceReducer';
import TemplateReducer from './TemplateReducer';
import AppInitializedReducer from './AppInitializedReducer';
import BookKeepingReducer from './BookKeepingReducer';
import ProviderReducer from './ProviderReducer';
import GlobalErrorReducer from './GlobalErrorReducer';
import EmailTemplateReducer from './EmailTemplateReducer';
import TimeStampsReducer from './TimeStampsReducer';
import RevenueCategoriesReducer from './RevenueCategoriesReducer';
import RevenueReducer from './RevenueReducer';
import BankFeedReducer from './BankFeedReducer';
import BankAccountsReducer from './BankAccountsReducer';
import BankCategoriesReducer from './BankCategoriesReducer';
import WalletReducer from './WalletReducer';
import UserTreezorReducer from './UserTreezorReducer';
import UserPersonTreezorReducer from './UserPersonTreezorReducer';
import CardReducer from './CardReducer';
import WalletTransactionsReducer from './WalletTransactionsReducer';

import BalancesReducer from './BalancesReducer';
import AgingBalanceReducer from './AgingBalance';
import invoicePaginate from './invoicePaginate';
import revenuePaginate from './RevenuePaginate';
import Transaction from './TransactionReducer';
import expensePaginate from './ExpensePaginate';
import CardTransactionReducer from './CardTransactionReducer';
import TransactionPaginate from './TransactionPaginate';
import treezorBalances from './TreezorBalancesReducer';
import HipayReducer from './HipayReducer';
import ScanOCRReducer from './ScanOCRReducer';
import ResizeScreenReducer from './ResizeScreenReducer';
import CompanySubscriptionReducer from './CompanySubscriptionReducer';
import CompanySubscriptionHistoryReducer from './CompanySubscriptionHistoryReducer';
import LoadingReducer from './LoadingReducer';
import FullVATRatesReducer from './FullVATRatesReducer';

// BankFeedReducer
// bankConnector
export default combineReducers({
  // logged User
  loggedUser: LoggedUserReducer,
  loggedUserCompany: LoggedUserCompany,
  // user and rights
  users: UserReducer,
  companyAccessRights: RolesReducer,
  loggedUserRights: UserRolesReducer,
  // clients
  clients: ClientsReducer,
  clientProjects: ClientProjectsReducer,
  // Lists
  activities: InternalActivitiesReducer,
  categories: CategoriesReducer,
  distanceRates: DistanceRatesReducer,
  vatRates: VATRatesReducer,
  fullVatRates: FullVATRatesReducer,
  // Expenses
  expenses: ExpensesReducer,
  expenseVatList: ExpenseWithVATListReducer,
  expenseOverview: ExpenseOverviewReducer,
  // Invoices
  items: ItemsReducer,
  invoices: InvoiceReducer,
  templates: TemplateReducer,
  emailTemplate: EmailTemplateReducer,
  // Revenue
  revenueCategories: RevenueCategoriesReducer,
  revenues: RevenueReducer,
  // Provider
  providers: ProviderReducer,
  // Bank API
  bankConnector: BankFeedReducer,
  bankAccounts: BankAccountsReducer,
  bankCategories: BankCategoriesReducer,
  bankBalances: BalancesReducer,
  // TREEZOR ACCOUNT
  treezorBalances,
  // Book Keeping
  bookExports: BookKeepingReducer,
  // UI
  guiLanguage: LanguageReducer,
  appInitialized: AppInitializedReducer,
  globalErrors: GlobalErrorReducer,
  // Wallet
  wallet: WalletReducer,
  // TreezorUsers
  treezor: UserTreezorReducer,
  // UserPersonTreezor
  personUserTreezor: UserPersonTreezorReducer,
  // Card
  cardReducer: CardReducer,
  // wallet transactions
  walletTransactions: WalletTransactionsReducer,
  // timestamps
  timeStamps: TimeStampsReducer,
  // aging balance
  agingBalance: AgingBalanceReducer,
  invoicePaginate,
  revenuePaginate,
  Transaction,
  expensePaginate,
  // Card transactions
  cardTransactions: CardTransactionReducer,
  transactionPaginate: TransactionPaginate,
  hipay: HipayReducer,
  scanOCR: ScanOCRReducer,
  // Resize screen
  resizeScreen: ResizeScreenReducer,

  // Subscriptions
  companySubscription: CompanySubscriptionReducer,
  companySubscriptionHistory: CompanySubscriptionHistoryReducer,

  // App loading
  isLoading: LoadingReducer,
});
