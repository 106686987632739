export const PAYMENT_LINK_ELEMENT = {
  RAW: '<!-- __Lien_de_paiement__ -->',
  AFTER_EDIT: '__Lien de paiement__',
};
export const INVOICE_STATES = {
  CANCELED: 'canceled',
  REJECTED: 'rejected',
  VALIDATED: 'validated',
  PENDING: 'pending',
  PAID: 'paid',
};
export const INVOICE_TYPE = {
  CREDIT_NOTE: 'credit_note',
  QUOTAION: 'quotation',
  INVOICE: 'invoice',
};

export default {
  PAYMENT_LINK_ELEMENT,
  INVOICE_STATES,
  INVOICE_TYPE,
};
