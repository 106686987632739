import {
  smDarkGreyD,
  dropDownItemSelected,
  dropDownItemSelectedSecondary,
} from './appText';
import colors from '../../../constants/colors.json';

const style = {
  selectItem: {
    ...smDarkGreyD,
    border: 'none',
    margin: '0 auto',
    textDecoration: 'none',
    height: '40px',
    width: '100%',
    backgroundColor: colors.white,
    textAlign: 'left',
    '&:hover': {
      backgroundColor: colors.background,
      borderRadius: '2px',
    },
    '&:focus': {
      backgroundColor: colors.background,
      borderRadius: '2px',
    },
  },

  primarySelected: {
    ...dropDownItemSelected,
  },
  secondarySelected: {
    ...dropDownItemSelectedSecondary,
  },
  secondaryGreySelected: {
    ...dropDownItemSelectedSecondary,
  },
};

export default style;
