import { bindActionCreators } from 'redux';
import {
  isAuthenticated,
  renewToken,
  isUserHasRights,
} from '../../actions/AuthActions';
import { fetchCompanyUserData } from '../../actions/LoggedUserActions';

export default (dispatch) =>
  bindActionCreators(
    {
      isAuthenticated,
      renewToken,
      fetchCompanyUserData,
      isUserHasRights,
    },
    dispatch
  );
