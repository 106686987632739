import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Field } from 'formik';
import { Checkbox } from 'formik-material-ui';

const SelectMe = ({ Icon, text, name, disabled }) => {
  return (
    <div
      style={{
        height: '90px',
        width: '100%',
        border: '1px solid blue',
        borderRadius: '2px',
        display: 'flex',
        paddingLeft: '15px',
        marginBottom: '15px',
        alignItems: 'center',
      }}
    >
      <Icon fontSize="large" color="secondary" />
      <Typography
        variant="subtitle1"
        color="primary"
        style={{ marginLeft: '15px', width: '50%' }}
      >
        {text}
      </Typography>
      <div style={{ width: '19%' }}></div>
      <Field
        disabled={disabled}
        component={Checkbox}
        type="checkbox"
        name={name}
      />
    </div>
  );
};

SelectMe.propTypes = {
  Icon: PropTypes.any,
  text: PropTypes.string,
  checked: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default SelectMe;
