import { useState } from 'react';

const UseLocalStorage = (key) => {
  const initialData = JSON.parse(localStorage.getItem(key)) || {};
  const [data, setData] = useState(initialData);

  const saveData = (newData) => {
    localStorage.setItem(key, JSON.stringify(newData));
    setData(newData);
  };

  const addItem = (item) => {
    const newData = { ...data, ...item };
    saveData(newData);
  };

  const removeItem = () => {
    localStorage.removeItem(key);
  };

  const getData = () => {
    return data;
  };

  return {
    addItem,
    getData,
    removeItem,
  };
};

export default UseLocalStorage;
