import { authInstance as axios } from 'helpers/axiosInterceptor';

const fetchAll = async (filter) =>
  axios.get(`${process.env.REACT_APP_REVENUE_URL}/revenues`, {
    params: filter,
  });

const fetchRevenuePaginate = async (qs) =>
  axios.get(`${process.env.REACT_APP_REVENUE_URL}/revenues?${qs}`);

const fetchById = async (id) =>
  axios.get(`${process.env.REACT_APP_REVENUE_URL}/revenues/${id}`);

const create = async (data) =>
  axios.post(`${process.env.REACT_APP_REVENUE_URL}/revenues`, data);

const fetchOverview = async (endFiscalYear) =>
  axios.get(
    `${process.env.REACT_APP_REVENUE_URL}/overview?endFiscalYear=${endFiscalYear}`
  );

export default {
  fetchAll,
  fetchById,
  create,
  fetchOverview,
  fetchRevenuePaginate,
};
