import * as yup from 'yup';

/* eslint import/prefer-default-export: off */
export const validationSchema = ({ t }) =>
  yup.object().shape({
    total: yup
      .number()
      .moreThan(0, t('forms.must_be_number_positive'))
      .typeError(t('forms.must_be_number'))
      .required(t('forms.mandatory_field')),
    date: yup.string().required(t('forms.mandatory_field')),
    payee_id: yup.string().required(t('forms.mandatory_field')),
    category_id: yup.string().required(t('forms.mandatory_field')),
    sub_category_id: yup.string().required(t('forms.mandatory_field')),
    reason: yup.string().required(t('forms.mandatory_field')),
  });
