/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import CustomGenericDialog from 'components/CustomGenericDialog';
import { AppDividerS } from 'components/AppDividers';
import { Grid, TextField } from '@material-ui/core';
import AppButton from 'components/AppButton';

const BuyPaymentLinksDialog = ({ toggleDialog, onSubmit, errorMessage }) => {
  const [quantity, setQuantity] = useState(0);

  const { t } = useTranslation();
  return (
    <CustomGenericDialog
      title={t('subscription.form.payment_links.dialog.title')}
      contentText={t('subscription.form.payment_links.dialog.content')}
      iconClose
      closeDialog={toggleDialog}
      color="secondary"
      maxWidth="xs"
    >
      {errorMessage && (
        <div className="row">
          <div className="input-field col s12">
            <span className="helper-text" style={{ color: 'red', padding: 4 }}>
              {errorMessage}
            </span>
          </div>
        </div>
      )}

      <Grid item container xs={12} style={{ margin: '20px 0px 10px 0px' }}>
        <Grid item xs={12}>
          <TextField
            fullWidth
            margin="dense"
            variant="outlined"
            label={t('subscription.form.payment_links.dialog.quantity_field')}
            type="number"
            value={quantity}
            onChange={(e) => {
              setQuantity(e.target.value);
            }}
          />
        </Grid>
      </Grid>
      <Grid item container xs={12} justify="flex-end">
        <AppButton
          color="transparentGrey"
          text={t('subscription.form.payment_links.dialog.cancel_btn')}
          onClick={toggleDialog}
        />
        <AppButton
          color="secondaryLight"
          text={t('subscription.form.payment_links.dialog.submit_btn')}
          type="button"
          onClick={() => {
            onSubmit({ quantity });
          }}
          noBorder={true}
        />
      </Grid>
    </CustomGenericDialog>
  );
};

BuyPaymentLinksDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
};
export default BuyPaymentLinksDialog;
