import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { cloneDeep } from 'lodash';
import { DEFAULT_CATEGORY_ID } from 'config/Constants';
import CustomGenericDialog from '../../../components/CustomGenericDialog';

import Form from '../Form';

const ProviderImportedFormDialog = ({
  showDialog,
  closeProviderFormDialog,
  selectedProvider,
  handleUpdateProvider,
}) => {
  const { t } = useTranslation();

  const activities = useSelector((state) => state.activities);
  const tree = useSelector((state) => state.categories.tree);

  const filteredTree = !tree
    ? []
    : [
        ...tree
          .filter((category) => category._id === DEFAULT_CATEGORY_ID)[0]
          .children.filter(
            (category) => category.travel.isTravelWithDistanceRate === false
          ),
        ...tree.filter((category) => category.order > 0),
      ].map((item) => ({
        display: item.display,
        _id: item._id,
        children: item.children,
      }));

  const provider = cloneDeep(selectedProvider);
  if (selectedProvider && selectedProvider.category) {
    const category = filteredTree.find(
      (item) => item.display === selectedProvider.category
    );
    if (category) {
      const subCategory = category.children.find(
        (item) => item.display === selectedProvider.subCategory
      );
      if (subCategory) {
        provider.subCategory = subCategory._id;
      }
    }
  }

  if (showDialog) {
    return (
      <CustomGenericDialog
        title={t('settings_section.providers.edit_title')}
        closeDialog={closeProviderFormDialog}
        onConfirmType="submit"
        onConfirmText="submit"
        iconClose
        color="secondary"
        //   setHeightManualy="650px"
      >
        <Form
          closeDialog={closeProviderFormDialog}
          tree={filteredTree}
          activities={activities}
          isImport
          selectedProvider={provider}
          updateProviderImport={handleUpdateProvider}
        />
      </CustomGenericDialog>
    );
  }

  return null;
};

ProviderImportedFormDialog.propTypes = {
  showDialog: PropTypes.bool,
  closeProviderFormDialog: PropTypes.func,
  selectedProvider: PropTypes.object,
  handleUpdateProvider: PropTypes.func,
};

export default ProviderImportedFormDialog;
