import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';

import AppButton from 'components/AppButton';
import AppInput from 'components/AppInput';
import { registerOnboarding } from 'services/OnboardingService';
import LoadingIndicatorDialog from 'components/LoadingIndicatorDialog';
import RadioButton from './RadioButton';
import { prepareQueryParams, CompanyTypesUrlCode } from '../utils';

const useStyles = makeStyles(() => ({
  inputContainer: {
    width: '200px',
  },
  questionsContainer: {
    width: '100%',
    boxShadow:
      'var(--sjs-general-shadow-small, 0px 1px 4px 0px rgba(0, 0, 0, 0.5))',
    marginBottom: '20px',
    borderRadius: '4px',
    padding: '30px',
  },
  question: {
    textAlign: 'left',
    margin: 'auto 0',
    fontSize: '25px',
    marginBottom: '10px',
  },
  questionsContent: {
    justifyContent: 'left',
    display: 'inline-flex',
    fontSize: '25px',
  },
  submitButton: {
    textAlign: 'right',
  },
  input: {
    minWidth: '300px',
  },
}));

const RenderOptions = ({
  options,
  index,
  handleCheck,
  inputValue,
  handleOnchange,
  queryParams,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const getChecked = queryParams.get(`q${index}`);
  const [checked, setChecked] = useState(
    /^\+?(0|[1-9]\d*)$/.test(getChecked) ? parseFloat(getChecked) : null
  );

  const handleOnClick = (item, idx) => {
    setChecked(idx);
    handleCheck(item, index);
  };

  return (
    <>
      {options.question_type === 'radio' && (
        <>
          {options.answers.map((item, idx) => {
            return (
              <RadioButton
                key={idx}
                text={t(item.value)}
                onClick={() => handleOnClick(item, idx)}
                checked={idx === checked}
                textStyle={{ cursor: 'pointer' }}
              />
            );
          })}
        </>
      )}
      {options.question_type === 'number' && (
        <div className={classes.input}>
          <AppInput
            placeholder={t('onboarding.question_input_placeholder')}
            type="number"
            onChange={(e) => handleOnchange(e, index)}
            value={inputValue}
          />
        </div>
      )}
    </>
  );
};

const RenderCheckBoxes = ({
  handleSelected,
  questionData,
  data,
  handleNext,
  personalInfo,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  const [inputValue, setInputValue] = useState('');
  const [questionInputed, setQuestionInputed] = useState({});
  const [loading, setLoading] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  useEffect(() => {
    const queryData = questionData.reduce((prev, curr, idx) => {
      const answerIndex = queryParams.get(`q${idx}`);
      // eslint-disable-next-line radix
      if (
        /^\+?(0|[1-9]\d*)$/.test(answerIndex) &&
        // eslint-disable-next-line radix
        parseInt(answerIndex) >= 0 &&
        // eslint-disable-next-line radix
        parseInt(answerIndex) <= questionData.length - 1
      ) {
        return {
          ...prev,
          // get value translate with t(value)
          // eslint-disable-next-line radix
          [curr.key]: t(curr.answers[parseInt(answerIndex)].value),
        };
      }
      return prev;
    }, {});
    setQuestionInputed({
      ...questionInputed,
      ...queryData,
    });
    if (queryParams.get('crr') === 'fn') {
      handleNext();
    }
  }, []);

  const handleSubmit = async () => {
    const keys = Object.keys(questionInputed);
    const questions = keys.reduce((prev, curr) => {
      const answers = questionData.find((item) => {
        return item.key === curr;
      });
      const answerIndex = answers.answers.findIndex(
        (item) => t(item.value) === questionInputed[curr]
      );

      return [...prev, answerIndex];
    }, []);
    const newQueryParams = prepareQueryParams(queryParams, 'crr', 'fn', {
      q: questions.join(''),
    });
    history.replace({
      pathname: location.pathname,
      search: newQueryParams.toString(),
    });
    let companyInfo = { ...data, ...questionInputed };
    if (!companyInfo.account_type) {
      companyInfo = {
        ...companyInfo,
        account_type: CompanyTypesUrlCode[queryParams.get('act')],
      };
    }
    try {
      setLoading(true);
      await registerOnboarding({
        contactInfo: personalInfo,
        companyInfo,
      });
      handleSelected(questionInputed);
      handleNext();
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const handleCheck = (values, questionIndex) => {
    const questionValues = {
      // get value with translate in FR
      [questionData[questionIndex].key]: t(values.value, { lng: 'fr' }),
    };

    setQuestionInputed({
      ...questionInputed,
      ...questionValues,
    });
  };

  const handleOnchange = (event, index) => {
    setInputValue(event.target.value);
    setQuestionInputed({
      ...questionInputed,
      [questionData[index].key]: event.target.value,
    });
  };

  const checkDisableSubmit = () => {
    return questionData.length === Object.keys(questionInputed).length;
  };

  return (
    <div>
      {loading && (
        <LoadingIndicatorDialog title={t('loading_single')} open={true} />
      )}
      {questionData.map((question, index) => {
        return (
          <div key={index} className={classes.questionsContainer}>
            <div>
              <div className={classes.question}>
                <b>{t(question.question)}</b>
              </div>
              <div className={classes.questionsContent}>
                <RenderOptions
                  options={question}
                  index={index}
                  handleCheck={handleCheck}
                  inputValue={inputValue}
                  handleOnchange={handleOnchange}
                  queryParams={queryParams}
                />
              </div>
            </div>
          </div>
        );
      })}
      <div className={classes.submitButton}>
        <AppButton
          color="secondaryLight"
          text={t('next')}
          onClick={() => handleSubmit()}
          disabled={!checkDisableSubmit()}
        />
      </div>
    </div>
  );
};

RenderCheckBoxes.propTypes = {
  handleSelected: PropTypes.func,
  questionData: PropTypes.array,
  data: PropTypes.object,
  handleNext: PropTypes.func,
  personalInfo: PropTypes.func,
};

RenderOptions.propTypes = {
  options: PropTypes.object,
  index: PropTypes.number,
  handleCheck: PropTypes.func,
  inputValue: PropTypes.number,
  handleOnchange: PropTypes.func,
  queryParams: PropTypes.object,
};

export default RenderCheckBoxes;
