import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CloudUpload } from '@material-ui/icons';

const useStyles = makeStyles({
  uploadContent: {
    textAlign: 'center',
  },
  uploadButton: {
    margin: '0.5rem 0',
  },
  dropzone: {
    backgroundColor: '#5257ad',
    padding: '2rem 5rem',
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '0px 100px',
    overflow: 'auto',
    border: '1px solid #dddddd',
    borderRadius: 16,
    backgroundColor: '#5257ad',
  },
});

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  color: 'white',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  cursor: 'pointer',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

const DragNDropZone = ({ chooseFile, acceptFile, isMultiple }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const onDrop = useCallback(async (acceptedFiles) => {
    chooseFile(acceptedFiles);
  }, []);

  const { getInputProps, getRootProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
    });
  const dropzoneStyle = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  return (
    <div className={classes.content}>
      <div className={classes.dropzone}>
        <div {...getRootProps({ style: dropzoneStyle })}>
          <input
            {...getInputProps()}
            multiple={isMultiple}
            accept={acceptFile}
          />
          <CloudUpload style={{ fontSize: 80 }} />
          <h2>{t('pdf_purchase.drag_and_drop_to_upload_file')}</h2>
          <div style={{ marginBottom: 12, fontSize: 15 }}>
            {t('pdf_purchase.or')}
          </div>
          <Button
            className={classes.uploadButton}
            style={{ background: 'white' }}
            variant="contained"
            component="span"
          >
            <span style={{ color: '#5257ad' }}>
              {t('pdf_purchase.browse_file')}
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

DragNDropZone.propTypes = {
  chooseFile: PropTypes.func,
  acceptFile: PropTypes.string,
  isMultiple: PropTypes.bool,
};

export default DragNDropZone;
