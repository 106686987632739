import { NEEDED } from 'constants/treezor';
import { TYPE_SOCIETE } from 'constants/company';
import {
  ASSOCIE,
  EMPLOYE,
  REPRESENTANT_LEGAL,
  PARENT_TYPE_RL,
  PARENT_TYPE_BE,
  PARENT_TYPE_EMPLOYEE,
} from 'constants/roles';

const initialValues = (user, company, role) => {
  const DEFAULT_COUNTRY = {
    id: '250',
    name_fr: 'France',
    UE: 'True',
    alpha2: 'FR',
    alpha3: 'FRA',
  };
  const companyType =
    company &&
    company.settings &&
    company.settings.tax_registration &&
    company.settings.tax_registration.companyType;
  let parentType = null;
  if (companyType === TYPE_SOCIETE) {
    if (role === ASSOCIE) {
      parentType = PARENT_TYPE_BE;
    } else if (role === REPRESENTANT_LEGAL) {
      parentType = PARENT_TYPE_RL;
    } else if (role === EMPLOYE) {
      parentType = PARENT_TYPE_EMPLOYEE;
    }
  }
  const formState = {
    companyType,
    parentType,
    _id: user._id || null,
    DocumentTypeID: 24,
    specifiedUSPerson: user.specifiedUSPerson || false,
    title: 'M',
    lastname: user.name || user.lastname,
    firstname: user.firstname,
    userEmail: user.email || '',
    userMobile: user.phonenumber || user.mobile,
    userPhone: user.phonenumber || user.phone,
    userAddress1: null,
    description: user.description,
    occupation: role,
    isRepresentantLegal: false,
    birthday: user.birthday || null,
    placeOfBirth: user.placeOfBirth || '',
    birthCountry: user.birthCountry || DEFAULT_COUNTRY,
    nationality: user.nationality || DEFAULT_COUNTRY,
    documentState: user.documentState || NEEDED,
    taxResidence: {
      country: (user.taxResidence && user.taxResidence.country) || false,
    },
    formSaved: company.formSaved || false,
    shouldCreateNewUser: false,
    company,
  };
  return formState;
};

/* eslint-disable import/prefer-default-export */
export { initialValues };
