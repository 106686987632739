import React from 'react';
import { Button, Grid, Typography, makeStyles } from '@material-ui/core';
import {
  TYPE_AUTO_ENTREPRENEUR,
  TYPE_ENTREPRENEUR_INDIVIDUEL,
  TYPE_SOCIETE,
} from 'constants/company';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import '../styles/companyTypeOptions.css';

const useStyles = makeStyles(() => ({
  buttonOption: {
    height: '3rem',
    margin: '5px',
    backgroundColor: 'rgb(64 48 195 / 89%)',
    color: 'white',
    width: 'calc(100% - 40px)',
    borderRadius: '1.5rem',
    textTransform: 'none',
    boxShadow: 'rgb(33 85 133) 0px 2px 8px',
    '&:hover': {
      backgroundColor: 'rgb(64 48 195 / 99%)',
    },
  },
  stepTitle: {
    textAlign: 'center',
    fontSize: '25px',
    marginBottom: '20px',
  },
}));

const CompanyTypeOptions = ({ setCompanyType, ignoreCompany = false }) => {
  const companyType = ignoreCompany
    ? [TYPE_AUTO_ENTREPRENEUR, TYPE_ENTREPRENEUR_INDIVIDUEL]
    : [TYPE_AUTO_ENTREPRENEUR, TYPE_ENTREPRENEUR_INDIVIDUEL, TYPE_SOCIETE];
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Grid container className={'companyTypeOptions'}>
      <Grid xs={12}>
        <Typography className={classes.stepTitle}>
          {t('onboarding.company_type_options.title')}
        </Typography>
      </Grid>
      <div className="options">
        {companyType.map((item, index) => {
          return (
            <Grid
              className="optionItem"
              key={index}
              xs={12}
              sm={12}
              md={6}
              lg={4}
              onClick={() => setCompanyType(item)}
            >
              <div className="optionItem__title">
                {t(`onboarding.company_type_options.${item}.title`)}
              </div>
              <div className="optionItem__description">
                {t(`onboarding.company_type_options.${item}.description`)}
              </div>
              <div style={{ flex: '1 1 0%' }}>
                <Button
                  className={classes.buttonOption}
                  onClick={(e) => {
                    e.stopPropagation();
                    setCompanyType(item);
                  }}
                >
                  {t(`onboarding.company_type_options.${item}.button`)}
                </Button>
              </div>
            </Grid>
          );
        })}
      </div>
    </Grid>
  );
};

CompanyTypeOptions.propTypes = {
  setCompanyType: PropTypes.func,
  ignoreCompany: PropTypes.bool,
};

export default CompanyTypeOptions;
