/* eslint-disable guard-for-in */
import { compareQs } from 'helpers/querystringHelper';
import { findIndex } from 'lodash';
import {
  FETCH_INVOICES_PAGINATE,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  VALIDATE_INVOICE,
  CREATE_INVOICE,
  UPDATE_INVOICE,
  REFUND_INVOICE,
  RESEND_INVOICE,
  REFRESH_INVOICES_PAGINATE,
} from '../../actions/Types';

export default (state = { docs: [] }, action) => {
  switch (action.type) {
    case FETCH_INVOICES_PAGINATE:
      if (compareQs(action, state) && action.page === state.page + 1) {
        return {
          ...state,
          docs: [...state.docs, ...action.docs],
          page: action.page,
        };
      }
      return {
        docs: action.docs,
        filters: action.filters,
        page: action.page,
        limit: action.limit,
        search: action.search,
        orderBy: action.orderBy,
        orderDirection: action.orderDirection,
        totalCount: action.totalCount,
      };
    case REFRESH_INVOICES_PAGINATE: {
      return {
        ...state,
        docs: action.docs,
        totalCount: action.totalCount,
        page: action.page,
      };
    }
    case CREATE_INVOICE: {
      return {
        ...state,
        docs: [action.payload, ...state.docs],
        totalCount: state.totalCount + 1,
      };
    }
    case VALIDATE_INVOICE: {
      const newDocs = state.docs;
      const index = findIndex(
        state.docs,
        (doc) => doc._id === action.payload.id
      );
      if (action.payload.oldInvoice && action.payload.newInvoice) {
        if (index !== -1)
          newDocs[index] = {
            ...state.docs[index],
            ...action.payload.oldInvoice,
          };
        newDocs.unshift(action.payload.newInvoice);
        return {
          ...state,
          docs: newDocs,
          totalCount: state.totalCount + 1,
        };
      }
      if (action.payload.newInvoice && !action.payload.oldInvoice) {
        if (index !== -1)
          newDocs[index] = {
            ...state.docs[index],
            ...action.payload.newInvoice,
          };
        return {
          ...state,
          docs: newDocs,
        };
      }
      return state;
    }
    case RESEND_INVOICE: {
      const newDocs = state.docs;
      const index = findIndex(
        state.docs,
        (doc) => doc._id === action.payload.id
      );
      newDocs[index] = { ...state.docs[index], ...action.payload.response };
      return {
        ...state,
        docs: newDocs,
      };
    }
    case REFUND_INVOICE: {
      const newDocs = state.docs;
      const index = findIndex(
        state.docs,
        (doc) => doc._id === action.payload.id
      );
      newDocs[index] = { ...state.docs[index], ...action.payload.invoice };
      newDocs.unshift(action.payload.creditNote);
      return {
        ...state,
        docs: newDocs,
        totalCount: state.totalCount + 1,
      };
    }
    case UPDATE_INVOICE: {
      const newDocs = state.docs;
      const index = findIndex(
        state.docs,
        (doc) => doc._id === action.payload.id
      );
      newDocs[index] = { ...state.docs[index], ...action.payload.response };
      return {
        ...state,
        docs: newDocs,
      };
    }
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return { docs: [] };
    default:
      return state;
  }
};
