import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import { format } from 'date-fns';

import CustomGenericDialog from 'components/CustomGenericDialog';
import { AppDividerS, AppDividerXS } from 'components/AppDividers';
import AppButton from 'components/AppButton';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'hooks/routerHooks';
import NumberFormat from 'helpers/NumberFormat';
import { useSelector } from 'react-redux';
import { SUBSCRIPTION_TYPE } from 'constants/subscriptions';
import { buildQueryString } from 'helpers/querystringHelper';

const TransactionDialog = ({
  toggleDialog,
  onSubmit,
  defaultAmount,
  type,
  documentId,
}) => {
  const { history } = useRouter();
  const { t } = useTranslation();

  const subscriptionType = useSelector((state) => state.companySubscription)
    .data?.subscriptionType;

  const [date, setDate] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [amount, setAmount] = useState(
    NumberFormat.precise(defaultAmount) || ''
  );

  // console.log({ accountsWithoutSync });

  const renderDateAndAmount = () => {
    return (
      <>
        <TextField
          label="Date du paiement"
          type="date"
          onChange={(e) => {
            setDate(e.target.value);
          }}
          value={date}
        />
        <AppDividerXS />

        <TextField
          label="Montant"
          type="number"
          placeholder="0.00"
          value={amount}
          onChange={(e) => {
            setAmount(e.target.value);
          }}
          InputProps={{
            startAdornment: <InputAdornment position="start">€</InputAdornment>,
          }}
        />
      </>
    );
  };

  return (
    <CustomGenericDialog
      title={t('transaction.title')}
      iconClose
      closeDialog={toggleDialog}
      color="secondary"
    >
      <AppDividerS />
      <Grid container direction="row" spacing={5}>
        <Grid item xs={6}>
          <Typography variant="p">{t('transaction.script')}</Typography>
          <AppDividerXS />
        </Grid>
        <Grid item xs={6}>
          <AppDividerXS />
          <Grid>{t('transaction.cash_script')}</Grid>
          <AppDividerXS />
          <Grid item xs={8}>
            {renderDateAndAmount()}
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="row" spacing={5}>
        <Grid item xs={6}>
          <AppButton
            text={t('transaction.synchronize')}
            isDisabled={subscriptionType === SUBSCRIPTION_TYPE.FREE_TIER}
            onClick={() => {
              const url = buildQueryString('/bank/accounts', {
                type,
                documentId,
              });
              history.push(url);
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <AppButton
            text={t('transaction.cash')}
            onClick={() => {
              onSubmit({
                method: 'cash',
                type: 'cash',
                account: null,
                date,
                amount: parseFloat(amount),
              });
            }}
            fullWidth
          />
        </Grid>

        {/* {renderButtons()} */}
      </Grid>
    </CustomGenericDialog>
  );
};

TransactionDialog.propTypes = {
  toggleDialog: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  defaultAmount: PropTypes.number,
  type: PropTypes.string,
  documentId: PropTypes.string,
};

export default TransactionDialog;
