import { isEmpty } from 'lodash';
import { store } from '../store';
import { isEuCountry } from './countriesHelper';

export const getClientById = (id) => {
  const {
    clients: { clients },
  } = store.getState();
  if (clients) {
    return clients.find((e) => e._id === id);
  }
  return undefined;
};

export const getClientProjectById = (id) => {
  const {
    clientProjects: { projects },
  } = store.getState();
  if (projects) {
    return projects.find((e) => e._id === id);
  }
  return undefined;
};

export const isEuClient = (client) => {
  return (
    !isEmpty(client) && isEuCountry(client?.country) && client?.intracom_vat
  );
};
