/* eslint-disable no-nested-ternary */
import { format, parse } from 'date-fns';
import { PDFDocument } from 'pdf-lib';
import { useMemo, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { setProvider } from 'helpers/providerHelpers';
import { DEFAULT_CATEGORY_ID } from 'config/Constants';
import { extractAttachments, xmlToJson } from 'helpers/fileHelper';
import ProviderService from 'services/ProviderService';
import GreffeService from 'services/GreffeService';

const useImportPurchase = ({
  values,
  setFieldValue,
  toggleNewProviderDialog,
  // toggleClientFormDialog,
}) => {
  const [importError, setImportError] = useState(null);
  const [showScan, setShowScan] = useState(false);

  const tree = useSelector((state) => state.categories.tree || []);

  const { t } = useTranslation();

  const formatItems = (jsonObject, provider) => {
    const itemsData =
      jsonObject.CrossIndustryInvoice.SupplyChainTradeTransaction
        .IncludedSupplyChainTradeLineItem;

    const data = itemsData
      ? _.isArray(itemsData)
        ? itemsData
        : [itemsData]
      : [];

    if (!itemsData) {
      const curItem =
        jsonObject.CrossIndustryInvoice.SupplyChainTradeTransaction
          .ApplicableHeaderTradeSettlement.ApplicableTradeTax;
      const netPriceString = curItem?.BasisAmount?.BasisAmount || 0;
      const netPrice = parseFloat(netPriceString);
      const vatRate =
        parseFloat(curItem?.RateApplicablePercent?.RateApplicablePercent) || 0;
      const vat = parseFloat(curItem?.CalculatedAmount?.CalculatedAmount) || 0;

      if (provider) {
        return [
          {
            amount: netPrice,
            vat,
            total: netPrice + vat,
            vat_rate: vatRate,
            category_id: provider.category,
            sub_category_id: provider.subCategory,
          },
        ];
      }

      return [
        {
          amount: netPrice,
          vat,
          total: netPrice + vat,
          vat_rate: vatRate,
        },
      ];
    }

    const itemsFormat = data.map((item) => {
      const netPriceString =
        item.SpecifiedLineTradeSettlement
          .SpecifiedTradeSettlementLineMonetarySummation.LineTotalAmount
          .LineTotalAmount;
      const vatRate =
        item.SpecifiedLineTradeSettlement.ApplicableTradeTax
          .RateApplicablePercent.RateApplicablePercent * 1;
      // eslint-disable-next-line radix
      const netPrice = parseFloat(netPriceString);
      const vat = (netPrice * vatRate) / 100;

      if (provider) {
        return {
          amount: netPrice,
          vat,
          total: netPrice + vat,
          vat_rate: vatRate,
          category_id: provider.category,
          sub_category_id: provider.subCategory,
        };
      }

      return {
        amount: netPrice,
        vat,
        total: netPrice + vat,
        vat_rate: vatRate,
      };
    });

    return itemsFormat;
  };

  const filteredTree = useMemo(() => {
    // Finding the target category by its _id
    const targetCategory = tree.find(
      (category) => category._id === DEFAULT_CATEGORY_ID
    );
    // Filtering the children of targetCategory based on travel.isTravelWithDistanceRate property
    const filteredChildren = targetCategory?.children?.filter(
      (category) => !category.travel.isTravelWithDistanceRate
    );
    // Filtering the tree based on order property
    const filteredTreeData = tree.filter((category) => category.order > 0);

    // Merging the filteredChildren with filteredTree and mapping to include only display and children properties
    const mappingCategories = [
      ...(filteredChildren || []),
      ...filteredTreeData,
    ];

    return mappingCategories.map((item) => ({
      ...item,
      display: t(`expenses.categories.${item.display}`),
    }));
  }, [tree]);

  const handleCreateProvider = async (
    provider,
    iban = '',
    providerInGreffe
  ) => {
    const legal = provider.SpecifiedLegalOrganization?.ID?.ID;
    const tvaIntraccom = `FR ${(3 * (providerInGreffe.siren % 97) + 12) % 97}${
      providerInGreffe.siren
    }`;

    const formatProvider = {
      siret: providerInGreffe.siren + providerInGreffe.nic || legal,
      iban: iban || '',
      address: providerInGreffe?.adresse,
      name: providerInGreffe?.denomination,
      zipcode: providerInGreffe?.code_postal,
      nic: providerInGreffe?.nic,
      city: providerInGreffe?.ville,
      intracomvat: tvaIntraccom,
      email: provider.URIUniversalCommunication?.URIID?.URIID,
      code_ape: providerInGreffe?.code_ape,
      legal_form_id: providerInGreffe?.id_forme_juridique,
      isCompany: true,
    };
    toggleNewProviderDialog();
    setFieldValue('providerForm', formatProvider);
  };

  const getExistedProvider = async (provider) => {
    const result = await ProviderService.fetchProviders();
    const providers = Object.values(result.data);
    const existedProvider = providers.find(
      (p) => p.siret === provider?.siren + provider?.nic
    );
    return existedProvider;
  };

  const handleImportXml = (file) => {
    if (!file) {
      return;
    }
    const reader = new FileReader();

    reader.onload = async function (e) {
      const xmlString = e.target.result;
      const parser = new DOMParser();
      const xmlDoc = parser.parseFromString(xmlString, 'text/xml');
      const jsonObject = xmlToJson(xmlDoc);
      setFieldValue('hasProvider', true);

      // issue date
      const date =
        jsonObject.CrossIndustryInvoice.ExchangedDocument.IssueDateTime
          .DateTimeString.DateTimeString;
      const parsedDate = parse(date, 'yyyyMMdd', new Date());
      setFieldValue('date', parsedDate);

      // invoice nb
      const invoiceNb = jsonObject.CrossIndustryInvoice.ExchangedDocument.ID.ID;
      setFieldValue('billNumber', invoiceNb);

      // import client
      // await handleImportClient(
      //   jsonObject,
      //   clients,
      //   setFieldValue,
      //   companyId,
      //   toggleClientFormDialog
      // );

      const providerData =
        jsonObject.CrossIndustryInvoice.SupplyChainTradeTransaction
          .ApplicableHeaderTradeAgreement.SellerTradeParty;

      const legal = providerData.SpecifiedLegalOrganization?.ID?.ID;
      const providerInGreffe = await GreffeService.infoGreffe(legal);
      const { data: infoGreffe } = providerInGreffe;

      const provider = infoGreffe.length ? infoGreffe[0] : {};

      const existedProvider = await getExistedProvider(provider);

      if (existedProvider) {
        setProvider(existedProvider, setFieldValue, values, filteredTree, t);
        setFieldValue(
          'description',
          `${existedProvider.name}${invoiceNb !== '' ? ` N° ${invoiceNb}` : ''}`
        );
      } else {
        const iban =
          jsonObject.CrossIndustryInvoice.SupplyChainTradeTransaction
            .ApplicableHeaderTradeSettlement
            ?.SpecifiedTradeSettlementPaymentMeans
            ?.PayeePartyCreditorFinancialAccount?.IBANID?.IBANID || '';
        setFieldValue('importError', true);
        handleCreateProvider(providerData, iban, provider);
      }

      // CURRENCY
      const currency =
        jsonObject.CrossIndustryInvoice.SupplyChainTradeTransaction
          .ApplicableHeaderTradeSettlement.InvoiceCurrencyCode
          .InvoiceCurrencyCode;
      setFieldValue('currency', currency);

      // items
      const items = formatItems(jsonObject, existedProvider);
      setFieldValue('amounts', items);

      const dueDate =
        jsonObject.CrossIndustryInvoice.SupplyChainTradeTransaction
          .ApplicableHeaderTradeSettlement?.SpecifiedTradePaymentTerms
          ?.DueDateDateTime?.DateTimeString?.DateTimeString ||
        format(new Date(), 'yyyyMMdd');
      const parsedDueDate = parse(dueDate, 'yyyyMMdd', new Date());
      setFieldValue('due_date', parsedDueDate);
    };

    reader.readAsText(file);
  };

  const handleFileChange = async (file) => {
    // const file = event.target.files[0];
    if (file.type !== 'application/pdf') {
      return;
    }

    try {
      const readerBase64 = new FileReader();
      // get base64 format data url
      readerBase64.readAsDataURL(file);
      readerBase64.onload = async () => {
        const { name: fName, type: fType, size } = file;
        const base64 = readerBase64.result;

        const fileInfo = {
          name: fName,
          type: fType,
          size: `${Math.round(size / 1024)} kB`,
          base64,
          file,
        };
        setFieldValue('fileContent', [fileInfo]);

        const reader = new FileReader();
        reader.onload = async (e) => {
          const pdfData = new Uint8Array(e.target.result);
          const pdfDoc = await PDFDocument.load(pdfData);

          // Access embedded files
          const attachments = await extractAttachments(pdfDoc);

          if (_.isEmpty(attachments)) {
            setImportError(t('expenses.expense.no_xml_attached'));
            setShowScan(true);
            return;
          }
          setShowScan(false);
          setImportError(null);
          handleImportXml(attachments[0]);
        };

        reader.readAsArrayBuffer(file);
      };
    } catch (error) {
      console.error('Error extracting attachments:', error);
    }
  };

  return {
    handleFileChange,
    importError,
    showScan,
    handleImportXml,
    filteredTree,
    setShowScan,
  };
};

export default useImportPurchase;
