import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { deleteClientProject } from '../../../actions/ClientProjectActions';
import CustomDialog from '../../../components/CustomDialog';
import { useThunkDispatch } from '../../../hooks/generalHooks';

const ProjectDeleteDialog = ({
  showDialog,
  closeDialog,
  selectedClientId,
  selectedProjectId,
}) => {
  const { t } = useTranslation();
  const dispatch = useThunkDispatch();

  const deleteProjectCb = useCallback(
    () =>
      dispatch(
        deleteClientProject(selectedClientId, selectedProjectId, closeDialog)
      ),
    [dispatch, selectedClientId, selectedProjectId]
  );

  if (showDialog) {
    return (
      <CustomDialog
        title={t('settings_section.projects.delete_project')}
        contentText={t('settings_section.projects.delete_client_content')}
        showButtons
        confirmText={t('ok')}
        onConfirm={deleteProjectCb}
        cancelText={t('cancel')}
        onCancel={closeDialog}
      />
    );
  }

  return null;
};

ProjectDeleteDialog.propTypes = {
  showDialog: PropTypes.bool,
  selectedClientId: PropTypes.string,
  selectedProjectId: PropTypes.string,
  closeDialog: PropTypes.func,
};

export default ProjectDeleteDialog;
