import {
  FETCH_USER_ROLES,
  FETCH_USER_ROLES_SUCCESS,
  FETCH_USER_ROLES_FAILED,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  UPDATE_USER_ROLE,
} from '../../actions/Types';

const INITIAL_STATE = {
  roles: [],
  isFetchingRoles: false,
  errorFetchingRoles: null,
};

export default (state = INITIAL_STATE, action) => {
  // console.log(`action type =>  ${action.type}`);
  switch (action.type) {
    case FETCH_USER_ROLES:
      return {
        ...state,
        isFetchingRoles: true,
        errorFetchingRoles: null,
      };

    case FETCH_USER_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload || [],
        isFetchingRoles: false,
        errorFetchingRoles: null,
      };
    }
    case FETCH_USER_ROLES_FAILED:
      return {
        ...state,
        roles: action.payload || [],
        isFetchingRoles: false,
        errorFetchingRoles: action.payload,
      };

    case UPDATE_USER_ROLE: {
      const { roles } = state;
      if (action.payload.id) {
        const index = state.roles.findIndex((r) => r._id === action.payload.id);
        if (index !== -1) {
          roles[index] = action.payload.data;
        }
      }
      return {
        ...state,
        roles,
      };
    }

    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return INITIAL_STATE;
    default:
      return state;
  }
};
