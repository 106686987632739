import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Tooltip from '@material-ui/core/Tooltip';
import classNames from 'classnames';

import style from '../../assets/jss/components/appMinusDivider';
import '../../assets/css/icons.css';

const AppPlusDivider = ({ onClick, classes, disabled, md }) => {
  const containerClasses = classNames({
    [classes.appContainer]: true,
  });
  const buttonClasses = classNames({
    [classes.plusButton]: true,
    [classes.mdPlusButton]: md,
    [classes.disabled]: disabled,
  });
  const dividerClasses = classNames({
    [classes.divider]: true,
    [classes.disabled]: disabled,
  });
  const iconClasses = classNames({
    [classes.iconStyle]: true,
    [classes.mdIconStyle]: md,
    icon: true,
    'icon-delete': true,
    [classes.cursorPointer]: true,
  });

  return (
    <div className={containerClasses}>
      <div className={dividerClasses} />
      <Tooltip title="supprimer en dessous">
        <button
          type="button"
          className={buttonClasses}
          onClick={onClick}
          style={{ marginRight: '10px' }}
        >
          <p className={iconClasses} />
        </button>
      </Tooltip>
      <div className={dividerClasses} />
    </div>
  );
};
AppPlusDivider.propTypes = {
  classes: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  md: PropTypes.bool,
};
AppPlusDivider.defaultProps = {
  disabled: false,
  md: false,
};

export default withStyles(style)(AppPlusDivider);
