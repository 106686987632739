const CategoryTree = [
  {
    displayed: true,
    enabled: true,
    _id: '5d1cc13d529fc3c8af0b9f9f',
    company_id: 'template_fr',
    icon: 'frais',
    order: 0,
    display: 'Frais',
    description: 'Frais generaux',
    helptext: '',
    __v: 0,
    travel: {
      isTravel: false,
      isTravelWithDistanceRate: false,
    },
    vat: {
      vatRates: [],
    },
    children: [
      {
        vat: { vatRates: [], isWithoutVAT: true },
        travel: { isTravel: true, isTravelWithDistanceRate: true },
        displayed: true,
        enabled: true,
        _id: '5d1cc145529fc3c8af0b9fa0',
        company_id: 'template_fr',
        parent: '5d1cc13d529fc3c8af0b9f9f',
        icon: 'indemnite-km',
        order: 0,
        display: 'Indemnité kilomètrique',
        description: 'Indemnité kilomètrique',
        helptext: "Préciser les points de départ/d'arrivée",
        account: '625100',
        __v: 0,
      },
      {
        vat: { vatRates: [] },
        travel: { isTravel: false, isTravelWithDistanceRate: false },
        displayed: true,
        enabled: true,
        _id: '5d1cc14a529fc3c8af0b9fa1',
        company_id: 'template_fr',
        parent: '5d1cc13d529fc3c8af0b9f9f',
        icon: 'abonnement',
        order: 1,
        display: 'Abonnement',
        description: 'les abonnements',
        helptext: '',
        __v: 0,
        children: [
          {
            vat: { vatRates: [], isWithoutVAT: true },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1cc157529fc3c8af0b9fa3',
            company_id: 'template_fr',
            order: 0,
            parent: '5d1cc14a529fc3c8af0b9fa1',
            display: 'Métro/Tramway/RER',
            description: 'Abonnement Métro / Tramway / RER',
            helptext: '',
            account: '625100',
            __v: 0,
          },
          {
            vat: { vatRates: [], isWithoutVAT: true },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1cc153529fc3c8af0b9fa2',
            company_id: 'template_fr',
            parent: '5d1cc14a529fc3c8af0b9fa1',
            order: 1,
            display: 'Train',
            description: 'Abonnement train',
            helptext: '',
            account: '625100',
            __v: 0,
          },
        ],
      },
      {
        vat: { vatRates: [] },
        travel: { isTravel: false, isTravelWithDistanceRate: false },
        displayed: true,
        enabled: true,
        _id: '5d1f297680df550f1829a050',
        company_id: 'template_fr',
        parent: '5d1cc13d529fc3c8af0b9f9f',
        icon: 'repas',
        order: 2,
        display: 'Repas',
        description: 'les repas',
        helptext: '',
        __v: 0,
        children: [
          {
            vat: {
              vatRates: [{ id: '5b71a036550815b29a98ea74' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f29b680df550f1829a051',
            company_id: 'template_fr',
            parent: '5d1f297680df550f1829a050',
            display: 'sans boisson',
            order: 0,
            description: 'Restaurant (sans boisson)',
            helptext:
              'Préciser personnes concernées (Nom Prénom Qualité Société)',
            account: '625100',
            __v: 0,
          },
          {
            vat: {
              vatRates: [
                { id: '5b71a036550815b29a98ea74' },
                { id: '5b71a033550815b29a98ea73' },
              ],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f29dc80df550f1829a052',
            company_id: 'template_fr',
            parent: '5d1f297680df550f1829a050',
            order: 1,
            display: 'avec boisson',
            description: 'Restaurant avec boisson',
            helptext:
              'Préciser personnes concernées (Nom Prénom Qualité Société)',
            account: '625100',
            __v: 0,
          },
          {
            vat: {
              vatRates: [{ id: '5b71a039550815b29a98ea75' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f29ea80df550f1829a053',
            company_id: 'template_fr',
            parent: '5d1f297680df550f1829a050',
            order: 2,
            display: 'Nourriture',
            description: 'Nourriture',
            helptext: 'Préciser le marchand / commerçant',
            account: '625100',
            __v: 0,
          },
        ],
      },
      {
        vat: { vatRates: [] },
        travel: { isTravel: false, isTravelWithDistanceRate: false },
        displayed: true,
        enabled: true,
        _id: '5d1f2a3780df550f1829a054',
        company_id: 'template_fr',
        parent: '5d1cc13d529fc3c8af0b9f9f',
        icon: 'hebergement',
        order: 3,
        display: 'Hébergement',
        description: 'les hébergements',
        helptext: '',
        __v: 0,
        children: [
          {
            vat: {
              vatRates: [{ id: '5b71a036550815b29a98ea74' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f2a7f80df550f1829a055',
            company_id: 'template_fr',
            parent: '5d1f2a3780df550f1829a054',
            order: 0,
            display: 'Seul',
            description: 'Hôtel / Hébergement',
            helptext:
              'Préciser personnes concernées (Nom Prénom Qualité Société)',
            account: '625100',
            __v: 0,
          },
          {
            vat: {
              vatRates: [
                { id: '5b71a036550815b29a98ea74' },
                { id: '5b71a033550815b29a98ea73' },
              ],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f2aae80df550f1829a056',
            company_id: 'template_fr',
            parent: '5d1f2a3780df550f1829a054',
            order: 1,
            display: 'avec boisson/parking',
            description: 'Hôtel / Hébergement (avec boisson et/ou parking)',
            helptext:
              'Préciser personnes concernées (Nom Prénom Qualité Société)',
            account: '625100',
            __v: 0,
          },
        ],
      },
      {
        vat: { vatRates: [] },
        travel: { isTravel: false, isTravelWithDistanceRate: false },
        displayed: true,
        enabled: true,
        _id: '5d1f2ae280df550f1829a057',
        company_id: 'template_fr',
        parent: '5d1cc13d529fc3c8af0b9f9f',
        icon: 'transport',
        order: 4,
        display: 'Transport',
        description: 'les transports',
        helptext: '',
        __v: 0,
        children: [
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f2edf80df550f1829a059',
            company_id: 'template_fr',
            parent: '5d1f2ae280df550f1829a057',
            order: 1,
            display: 'Péage/Parking',
            description: 'Péage / Parking',
            helptext: 'Préciser le lieu',
            account: '625100',
            __v: 0,
          },
          {
            vat: { vatRates: [], isWithoutVAT: true },
            travel: { isTravel: true, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f2eff80df550f1829a05a',
            company_id: 'template_fr',
            parent: '5d1f2ae280df550f1829a057',
            order: 2,
            display: 'Taxi/Uber',
            description: 'Taxi / Uber',
            helptext: "Préciser les points de départ/d'arrivée",
            account: '625100',
            __v: 0,
          },
          {
            vat: { vatRates: [], isWithoutVAT: true },
            travel: { isTravel: true, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f2f1780df550f1829a05b',
            company_id: 'template_fr',
            parent: '5d1f2ae280df550f1829a057',
            order: 3,
            display: 'Train/Avion',
            description: 'Train / avion',
            helptext: "Préciser les points de départ/d'arrivée",
            account: '625100',
            __v: 0,
          },
        ],
      },
      {
        vat: { vatRates: [] },
        travel: { isTravel: false, isTravelWithDistanceRate: false },
        displayed: true,
        enabled: true,
        _id: '5d1f2f6880df550f1829a05c',
        company_id: 'template_fr',
        parent: '5d1cc13d529fc3c8af0b9f9f',
        icon: 'vehicule',
        order: 5,
        display: 'Véhicule',
        description: 'les transports avec véhicule',
        helptext: '',
        __v: 0,
        children: [
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f2fab80df550f1829a05d',
            company_id: 'template_fr',
            parent: '5d1f2f6880df550f1829a05c',
            order: 0,
            display: 'Carburant',
            description: 'frais de carburant',
            helptext: 'Préciser le type de carburant',
            account: '606140',
            __v: 0,
          },
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f2ffe80df550f1829a060',
            company_id: 'template_fr',
            parent: '5d1f2f6880df550f1829a05c',
            order: 1,
            display: 'Entretien Véhicule',
            description: "frais d'entretien de véhicule",
            helptext: '',
            account: '615000',
            __v: 0,
          },
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f301a80df550f1829a061',
            company_id: 'template_fr',
            parent: '5d1f2f6880df550f1829a05c',
            order: 2,
            display: 'Location Véhicule',
            description: 'Location Véhicule',
            helptext:
              'Type de véhicule, préciser Véhicule Utilitaire ou Véhicule Particulier',
            account: '613500',
            __v: 0,
          },
        ],
      },
      {
        vat: { vatRates: [] },
        travel: { isTravel: false, isTravelWithDistanceRate: false },
        displayed: true,
        enabled: true,
        _id: '5d1f306a80df550f1829a062',
        company_id: 'template_fr',
        parent: '5d1cc13d529fc3c8af0b9f9f',
        icon: 'divers',
        order: 6,
        display: 'Divers',
        description: 'les frais divers',
        helptext: '',
        __v: 0,
        children: [
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f30a580df550f1829a063',
            company_id: 'template_fr',
            parent: '5d1f306a80df550f1829a062',
            order: 0,
            display: 'Cadeaux clients',
            description: 'Cadeaux clients',
            helptext: 'Préciser entreprises/personnes',
            account: '623400',
            __v: 0,
          },
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f30db80df550f1829a064',
            company_id: 'template_fr',
            parent: '5d1f306a80df550f1829a062',
            order: 1,
            display: 'Pressing',
            description: 'Entretien vêtements (pressing)',
            helptext: 'Indiquer le commercant',
            account: '615580',
            __v: 0,
          },
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f310580df550f1829a065',
            company_id: 'template_fr',
            parent: '5d1f306a80df550f1829a062',
            order: 2,
            display: 'Téléphone/Internet',
            description: 'Téléphone / Internet',
            helptext: 'Indiquer la nature',
            account: '626200',
            __v: 0,
          },
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f315f80df550f1829a066',
            company_id: 'template_fr',
            parent: '5d1f306a80df550f1829a062',
            order: 3,
            display: "Frais d'expédition",
            description: "Frais d'expédition (avec TVA)",
            helptext: "Indiquer la nature de l'envoi",
            account: '626100',
            __v: 0,
          },
          {
            vat: { vatRates: [], isWithoutVAT: true },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f318d80df550f1829a067',
            company_id: 'template_fr',
            parent: '5d1f306a80df550f1829a062',
            order: 4,
            display: 'Frais Postaux',
            description: 'Frais Postaux (sans TVA)',
            helptext: "Indiquer la nature de l'envoi",
            account: '626100',
            __v: 0,
          },
          {
            vat: {
              vatRates: [{ id: '5b71a039550815b29a98ea75' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f31bb80df550f1829a068',
            company_id: 'template_fr',
            parent: '5d1f306a80df550f1829a062',
            order: 5,
            display: 'Documentation / Livre',
            description: 'Documentation / Livre',
            helptext: 'Indiquer le commercant',
            account: '618100',
            __v: 0,
          },
          {
            vat: {
              vatRates: [{ id: '5b71a039550815b29a98ea75' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f6dd50392e81104dca6f2',
            company_id: 'template_fr',
            parent: '5d1f306a80df550f1829a062',
            order: 6,
            display: 'Petites fournitures',
            description: 'Petites fournitures',
            helptext: 'Indiquer le commercant',
            account: '618100',
            __v: 0,
          },
        ],
      },
      {
        vat: { vatRates: [] },
        travel: { isTravel: false, isTravelWithDistanceRate: false },
        displayed: true,
        enabled: true,
        _id: '5d1f6c920392e81104dca6ee',
        company_id: 'template_fr',
        parent: '5d1cc13d529fc3c8af0b9f9f',
        icon: 'matriel',
        order: 7,
        display: 'Materiel',
        description: 'les frais sur le matériel',
        helptext: '',
        __v: 0,
        children: [
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f6ccb0392e81104dca6ef',
            company_id: 'template_fr',
            parent: '5d1f6c920392e81104dca6ee',
            order: 0,
            display: 'petit matériel <500€',
            description: 'petit matériel <500€',
            helptext: 'Préciser la nature, quel petit matériel acheté',
            account: '606300',
            __v: 0,
          },
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f6cf70392e81104dca6f0',
            company_id: 'template_fr',
            parent: '5d1f6c920392e81104dca6ee',
            order: 1,
            display: 'matériel >500€',
            description: 'matériel >500€',
            helptext: 'Préciser la nature, quel matériel acheté',
            account: '606300',
            __v: 0,
          },
          {
            vat: {
              vatRates: [{ id: '5b71a033550815b29a98ea73' }],
              isWithoutVAT: false,
            },
            travel: { isTravel: false, isTravelWithDistanceRate: false },
            displayed: true,
            enabled: true,
            _id: '5d1f6d260392e81104dca6f1',
            company_id: 'template_fr',
            parent: '5d1f6c920392e81104dca6ee',
            order: 2,
            display: 'location de matériel',
            description: 'location de matériel',
            helptext: 'Préciser la nature, quel produit loué',
            account: '613550',
            __v: 0,
          },
        ],
      },
    ],
  },
  {
    displayed: true,
    enabled: false,
    _id: '5d1cc1ee529fc3c8af0b9fa4',
    company_id: 'template_fr',
    icon: 'achat',
    order: 1,
    display: 'Achats',
    description: 'Achats',
    helptext: '',
    travel: {
      isTravel: false,
      isTravelWithDistanceRate: false,
    },
    vat: {
      vatRates: [],
    },
    // children: [],
    __v: 0,
  },
];

export default CategoryTree;
