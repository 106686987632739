/* eslint-disable import/no-unresolved */
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { forOwn } from 'lodash';

import { Divider, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { KM_CATEGORY_ID } from 'constants/expense';
import { useFormikContext } from 'formik';
import TextField from '../../FormElements/textField';
import SelectAutocomplete from '../../SelectAutocomplete';
import GoogleDirectionLink from '../../GoogleDirectionLink';

function createDistanceRate(dd) {
  const arr = [];
  forOwn(dd, (types, distanceRateIndex) =>
    forOwn(types, (type, typeIndex) => {
      arr.push(type[0].vehicle || `${distanceRateIndex} - ${typeIndex}`);
    })
  );

  return arr;
}

const DistanceInfo = ({ selectedCategory }) => {
  const { values, setFieldValue } = useFormikContext();

  const distanceRates = useSelector((state) => state.distanceRates);

  const { t } = useTranslation();

  const {
    travel_distance_rate: travelDistanceRate,
    travel_from: from,
    travel_to: to,
  } = values;

  const isDistanceCategorySelected =
    selectedCategory.category_id === KM_CATEGORY_ID;

  function displayGoogleLink() {
    return isDistanceCategorySelected && from !== '' && to !== '';
  }

  // Comment to always show the distance
  if (!isDistanceCategorySelected) {
    return null;
  }

  function calculateGrossAmountFromDistance(distanceRate, distance) {
    const vatRate = values.vatrate0;

    if (distanceRate && distance) {
      const [type, power] = distanceRate.split('-');

      const newDistance = !distance ? 1 : distance;

      const d = distanceRates[type.trim()][power.trim()].find((dr) => {
        const dmin = dr.dmin || 0;
        const dmax = dr.dmax || newDistance;
        return newDistance > dmin && newDistance <= dmax;
      });

      const newTotal = distance * d.rate + d.delta;
      const ht = newTotal / (1 + vatRate / 100);
      const newVat = newTotal - ht;

      setFieldValue('amount0', ht.toFixed(2));
      setFieldValue('vat0', newVat.toFixed(2));
      setFieldValue('total0', newTotal.toFixed(2));
      setFieldValue('taxTotalAmount', newVat.toFixed(2));
      setFieldValue('taxBasisTotalAmount', ht.toFixed(2));
      setFieldValue('grandTotalAmount', newTotal.toFixed(2));
      setFieldValue('duePayableAmount', newTotal.toFixed(2));
    }
  }

  return (
    <Fragment>
      <Grid container spacing={2} direction="row" alignItems="center">
        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            name={`travel_from`}
            label={t('expenses.expense.form_expensefrom')}
            placeholder={t('expenses.expense.form_expensefrom')}
            required
          />
        </Grid>
        <Grid item xs={12} sm={3}>
          <TextField
            type="text"
            name={`travel_to`}
            placeholder={t('expenses.expense.form_expenseto')}
            label={t('expenses.expense.form_expenseto')}
            required
          />
        </Grid>
        {/* Comment to always show the distance */}
        {isDistanceCategorySelected && (
          <Grid
            container
            item
            xs={12}
            sm={6}
            alignItems="center"
            justify="center"
            spacing={2}
            style={{ paddingRight: '0px' }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                name={`travel_distance`}
                placeholder={t('expenses.expense.form_distance')}
                label={t('expenses.expense.form_distance')}
                required
                onChange={(e) => {
                  const { value } = e.target;
                  setFieldValue('travel_distance', value);
                  calculateGrossAmountFromDistance(
                    values.travel_distance_rate,
                    value
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={6} style={{ paddingRight: '0px' }}>
              <SelectAutocomplete
                required
                name={`travel_distance_rate`}
                label={t('expenses.expense.form_distance_rate')}
                helperText={t('expenses.expense.form_distance_rate')}
                getOptionLabel={(elem) =>
                  elem.includes('_') ? t(`expenses.VEHICLE_TYPE.${elem}`) : elem
                }
                values={createDistanceRate(distanceRates).sort()}
                valueSelected={travelDistanceRate || null}
                onChange={(value) => {
                  setFieldValue('travel_distance_rate', value);
                  calculateGrossAmountFromDistance(
                    value,
                    values.travel_distance
                  );
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {displayGoogleLink() && (
        <GoogleDirectionLink origin={from} destination={to} />
      )}
      <Divider style={{ margin: '10px 0' }} />
    </Fragment>
  );
};

DistanceInfo.propTypes = {
  selectedCategory: PropTypes.object,
};

export default DistanceInfo;
