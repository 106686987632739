import { bindActionCreators } from 'redux';
import { fetchInternalActivities } from '../../../actions/InternalActivitiesActions';

export default (dispatch) =>
  bindActionCreators(
    {
      fetchInternalActivities,
    },
    dispatch
  );
