import spinnerService from 'services/SpinnerService';

export default (values, { setErrors, setStatus, setSubmitting, props }) => {
  const fetchData = () => {
    spinnerService.startSpinner();
    props.getEmailTemplates().finally(() => spinnerService.endSpinner());
  };
  const cb = (err) => {
    if (err) {
      setSubmitting(false);
      setStatus({ success: false });
      setErrors({
        internalError: props.t('forms.internal_error'),
      });
    } else {
      setStatus({ success: true });
      if (props.closeDialog) props.closeDialog();
      fetchData();
    }
  };
  if (values.company_id === 'template_fr')
    props.createEmailTemplate(
      values._id,
      { ...values, company_id: props.company._id, _id: undefined },
      cb
    );
  else props.updateEmailTemplate(values._id, values, cb);
};
