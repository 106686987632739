import colors from '../../../constants/colors.json';

const style = {
  appProgressBar: {
    backgroundColor: colors.greenOpacity,
    height: 10,
    borderRadius: 20,
    boxShadow:
      '0 1px 1px 0 rgba(178, 178, 178, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(178, 178, 178, 0.2)',
  },
  appProgressPercentage: {
    backgroundColor: colors.green,
    height: 10,
    borderRadius: '20px 0 0 20px',
  },
};

export default style;
