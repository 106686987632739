import { bindActionCreators } from 'redux';
import { createRole, updateRole } from '../../../actions/RolesActions';

export default (dispatch) =>
  bindActionCreators(
    {
      createRole,
      updateRole,
    },
    dispatch
  );
