import React, { useState } from 'react';
import { Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
import AppButton from 'components/AppButton';
import subscriptionService from 'services/SubscriptionService';
import spinnerService from 'services/SpinnerService';
import BuyPaymentLinksDialog from './BuyPaymentLinksDialog';

const styles = {
  inputLablel: {
    color: '#1a1a1a',
    fontWeight: '500',
  },
  textField: {
    marginBottom: '20px',
  },
  textFieldValue: {
    color: '#1a1a1a',
  },
  leftGroup: {
    paddingRight: '10px',
  },
  rightGroup: {
    paddingLeft: '10px',
  },
  title: {
    marginBottom: '20px',
  },
  container: {
    padding: '10px',
    marginBottom: '40px',
  },
};
const useStyles = makeStyles({
  buttonGroup: {
    display: 'flex',
  },
});
const PaymentLinks = ({ subscription }) => {
  const [showBuyPaymentLinksDialog, setShowBuyPaymentLinksDialog] =
    useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { t } = useTranslation();
  const classes = useStyles();

  const handleSubmitBuyPaymentLinks = async ({ quantity }) => {
    if (quantity > 0) {
      try {
        spinnerService.startSpinner();
        const res = await subscriptionService.buyPaymentLinks({ quantity });
        if (res.data) {
          const { url } = res.data;
          setErrorMessage('');
          setShowBuyPaymentLinksDialog(false);
          window.open(url, '_blank');
        }
      } catch (error) {
        setErrorMessage(error?.message);
      } finally {
        spinnerService.resetSpinner();
      }
    }
  };
  const renderBuyPaymentLinksDialog = () => {
    return (
      <BuyPaymentLinksDialog
        toggleDialog={() => {
          setShowBuyPaymentLinksDialog(!showBuyPaymentLinksDialog);
        }}
        onSubmit={handleSubmitBuyPaymentLinks}
        errorMessage={errorMessage}
      />
    );
  };
  return (
    <>
      <Grid container spacing={2} direction="row" style={styles.container}>
        <Typography style={styles.title} variant="h4">
          {t('subscription.form.payment_links_title')}
        </Typography>
        <Grid container spacing={2} direction="row">
          <Grid item xs={4} style={styles.leftGroup}>
            <TextField
              style={styles.textField}
              inputProps={{
                style: styles.textFieldValue,
              }}
              InputLabelProps={{ style: styles.inputLablel }}
              variant="outlined"
              type="number"
              name={`iban`}
              label={t('subscription.form.payment_links_quota')}
              color="secondary"
              fullWidth
              value={subscription?.remainingPaymentLink || 0}
              disabled
            />
          </Grid>
          <Grid item xs={4} style={styles.rightGroup}>
            <TextField
              style={styles.textField}
              inputProps={{
                style: styles.textFieldValue,
              }}
              InputLabelProps={{ style: styles.inputLablel }}
              variant="outlined"
              type="number"
              name={`iban`}
              label={t('subscription.form.payment_links_used_from_quota')}
              color="secondary"
              fullWidth
              value={subscription?.paymentLinkUsed || 0}
              disabled
            />
          </Grid>
          <Grid item xs={4} style={styles.rightGroup}>
            <TextField
              style={styles.textField}
              inputProps={{
                style: styles.textFieldValue,
              }}
              InputLabelProps={{ style: styles.inputLablel }}
              variant="outlined"
              type="number"
              name={`paymentLinkBought`}
              label={t('subscription.form.payment_links_bought')}
              color="secondary"
              fullWidth
              value={subscription?.paymentLinkBought || 0}
              disabled
            />
          </Grid>
        </Grid>
        <Grid
          xs={12}
          item
          direction="row"
          justify="flex-end"
          className={classes.buttonGroup}
        >
          <Grid item>
            <AppButton
              text={t('subscription.form.buy_payment_links_btn')}
              noBorder
              onClick={() => setShowBuyPaymentLinksDialog(true)}
            />
          </Grid>
        </Grid>
      </Grid>
      {showBuyPaymentLinksDialog && renderBuyPaymentLinksDialog()}
    </>
  );
};

PaymentLinks.propTypes = {
  subscription: PropTypes.object.required,
};

export default PaymentLinks;
