import {
  FETCH_REVENUES_PAGINATE,
  REFRESH_REVENUES_PAGINATE,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  CREATE_REVENUE_SUCCESS,
} from 'actions/Types';

import { compareQs } from 'helpers/querystringHelper';

const INITIAL_STATE = { docs: [] };

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_REVENUES_PAGINATE: {
      if (compareQs(action, state) && action.page === state.page + 1) {
        return {
          ...state,
          docs: [...state.docs, ...action.docs],
          page: action.page,
        };
      }
      return {
        docs: action.docs,
        filters: action.filters,
        page: action.page,
        limit: action.limit,
        search: action.search,
        orderBy: action.orderBy,
        orderDirection: action.orderDirection,
        totalCount: action.totalCount,
      };
    }
    case REFRESH_REVENUES_PAGINATE: {
      return {
        ...state,
        docs: action.docs,
        totalCount: action.totalCount,
        page: action.page,
      };
    }
    case CREATE_REVENUE_SUCCESS: {
      return {
        ...state,
        docs: [action.payload.revenue, ...state.docs],
        totalCount: state.totalCount + 1,
      };
    }
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return INITIAL_STATE;

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
};
