import axios from 'axios';
import { authInstance } from '../../helpers/axiosInterceptor';

const getConservations = async (page = 1, limit = 1) => {
  console.log('pagination', page, limit);
  // pagination later
  const res = await authInstance.get(
    `${process.env.REACT_APP_API_URL_NO_VERSION}/conversation?&pagination=false&orderDirection=desc&orderBy=createdAt`
  );
  return res.data;
};

const getConservationDetail = async (conversationId) => {
  const res = await authInstance.get(
    `${process.env.REACT_APP_API_URL_NO_VERSION}/conversation/${conversationId}`
  );
  return res.data;
};

const deleteConservation = async (conversationId) => {
  const res = await authInstance.delete(
    `${process.env.REACT_APP_API_URL_NO_VERSION}/conversation/${conversationId}`
  );
  return res.data;
};

const createConservation = async (data) => {
  const res = await authInstance.post(
    `${process.env.REACT_APP_API_URL_NO_VERSION}/conversation`,
    data
  );
  return res.data;
};

const updateConservation = async (conversationId, data) => {
  const res = await authInstance.put(
    `${process.env.REACT_APP_API_URL_NO_VERSION}/conversation/${conversationId}`,
    data
  );
  return res.data;
};

const pushMessage = async (conversationId, message) => {
  const res = await authInstance.patch(
    `${process.env.REACT_APP_API_URL_NO_VERSION}/conversation/${conversationId}`,
    message
  );
  return res.data;
};

const requestConservation = async ({
  query,
  history = [],
  userPreference = '',
  agentReference = 'bb039e66-4d56-4781-b4db-2ecd0bcd7d8b',
  config,
}) => {
  return axios.post(
    `${process.env.REACT_APP_DIMARC_URL}/agents/request`,
    { query, history, userPreference, agentReference },
    {
      headers: {
        [config.token_type]: config.access_token,
      },
    }
  );
};

export default {
  getConservations,
  getConservationDetail,
  createConservation,
  deleteConservation,
  updateConservation,
  requestConservation,
  pushMessage,
};
