import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withFormik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { Grid } from '@material-ui/core';

import { AppGreyBold } from 'components/AppText';
import AppButton from '../AppButton';
import LoadingIndicator from '../LoadingIndicatorDialog';

import TextField from '../FormElements/textField';
import SelectAutocomplete from '../SelectAutocomplete';
import languageArray from '../AppLanguage/languageArray';

import { validationSchema } from './validation';
import mapPropsToValues from './formstate';
import handleSubmit from './handleSubmit';

import state from './state';
import dispatch from './dispatch';

import styles from '../../assets/jss/root';

import capitalizeForm from '../../helpers/capitalizeForm';

class UserProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit(e) {
    const { handleSubmit: formikSubmit } = this.props;
    formikSubmit(e);
  }

  renderLoading(isSubmitting) {
    const { t } = this.props;
    if (isSubmitting) {
      return <LoadingIndicator title={t('loading_single')} open={true} />;
    }
    return null;
  }

  _renderRolesOrLanguage() {
    const { t, values, setFieldValue } = this.props;

    return (
      <div className="row">
        <SelectAutocomplete
          name="language"
          color="primary"
          label={t('languages.language')}
          onChange={(lang) => {
            localStorage.setItem('storeInvoiceMuiTable', JSON.stringify({}));
            localStorage.setItem('storeExpenseMuiTable', JSON.stringify({}));
            localStorage.setItem('storePurchaseMuiTable', JSON.stringify({}));
            if (lang) {
              setFieldValue('language', lang.value);
            } else {
              // do nothing on clear. Will force the user to set a new value
            }
          }}
          renderOption={(option) => option.display}
          getOptionLabel={(option) => t(option.label)}
          values={languageArray}
          valueSelected={_.find(
            languageArray,
            (elem) => elem.value === values.language
          )}
        />
      </div>
    );
  }

  render() {
    const {
      t,
      errors,
      values,
      classes,
      setFieldValue,
      closeDialog,
      isSubmitting,
      isTreezorForm,
    } = this.props;
    const chooseTitles = [
      { label: 'M.', value: 'M.' },
      { label: 'Mme.', value: 'Mme.' },
    ];
    return (
      <div className="section p-10">
        {this.renderLoading(isSubmitting)}
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {errors && errors.internalError && (
                <div className="row">
                  <div className="input-field col s12">
                    <span className="helper-text">{errors.internalError}</span>
                  </div>
                </div>
              )}
            </Grid>

            <Grid item xs={2}>
              <SelectAutocomplete
                name="title"
                label={t('client.title')}
                onChange={(title) => {
                  if (title) {
                    this.props.setFieldValue('title', title.value);
                  } else {
                    // if the user clears the field we reset the value
                    this.props.setFieldValue('title', '');
                  }
                }}
                getOptionLabel={(option) => option.label}
                values={chooseTitles}
                valueSelected={_.find(
                  chooseTitles,
                  (elem) => elem.value === values.title
                )}
              />
            </Grid>

            <Grid item xs={5}>
              <TextField
                type="text"
                required
                name="name"
                label={t('settings_section.users.form_label_name')}
                placeholder={t('settings_section.users.form_helper_name')}
                onChange={(e) => {
                  capitalizeForm(e, setFieldValue);
                }}
              />
            </Grid>

            <Grid item xs={5}>
              <TextField
                type="text"
                required
                name="firstname"
                label={t('settings_section.users.form_label_firstname')}
                placeholder={t('settings_section.users.form_helper_firstname')}
                onChange={(e) => {
                  capitalizeForm(e, setFieldValue);
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="text"
                required
                name="email"
                label={t('settings_section.users.form_label_email')}
                placeholder={t('settings_section.users.form_label_email')}
                className={classes.textFieldWide}
                disabled={true}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                type="text"
                required
                name={isTreezorForm ? 'phonenumberUser' : 'phonenumber'}
                label={t('signup.company.mobilePhone')}
                placeholder={t('signup.company.mobilePhone')}
                className={classes.textFieldWide}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="description"
                label={t('settings_section.users.form_label_description')}
                placeholder={t('settings_section.users.form_label_description')}
                className={classes.textFieldWide}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                type="text"
                name="function"
                label={t('settings_section.users.form_label_function')}
                placeholder={t('settings_section.users.form_label_function')}
                className={classes.textFieldWide}
              />
            </Grid>

            <Grid item xs={12}>
              {this._renderRolesOrLanguage()}
            </Grid>
            {values.birthday && (
              <Grid item xs={3}>
                <TextField
                  type="text"
                  name="birthday"
                  label={t('birthday')}
                  className={classes.textFieldWide}
                  disabled={true}
                />
              </Grid>
            )}

            {values.placeOfBirth && (
              <Grid item xs={3}>
                <TextField
                  type="text"
                  name="placeOfBirth"
                  label={t('place_of_birth')}
                  className={classes.textFieldWide}
                  disabled={true}
                />
              </Grid>
            )}
            {values.birthCountry && (
              <Grid item xs={3}>
                <TextField
                  type="text"
                  name="birthCountry"
                  label={t('birth_country')}
                  className={classes.textFieldWide}
                  disabled={true}
                />
              </Grid>
            )}

            {values.address1 && (
              <>
                <div
                  style={{
                    width: '100%',
                    textAlign: 'center',
                    paddingTop: '1%',
                  }}
                >
                  <AppGreyBold>{t('address1')}</AppGreyBold>
                </div>

                <Grid item xs={3}>
                  <TextField
                    type="text"
                    name="address1"
                    label={t('address1')}
                    className={classes.textFieldWide}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    type="text"
                    name="city"
                    label={t('city')}
                    className={classes.textFieldWide}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    type="text"
                    name="postcode"
                    label={t('postcode')}
                    className={classes.textFieldWide}
                    disabled={true}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    type="text"
                    name="country"
                    label={t('country')}
                    className={classes.textFieldWide}
                    disabled={true}
                  />
                </Grid>
              </>
            )}

            {/* <div
              style={{ width: '100%', textAlign: 'center', paddingTop: '1%' }}
            >
              <AppGreyBold>{t('bank')}</AppGreyBold>
            </div>

            <Grid item xs={7}>
              <TextField
                type="text"
                name="iban"
                label={t('invoices.IBAN')}
                placeholder={t('invoices.IBAN')}
                className={classes.textFieldWide}
              />
            </Grid>
            <Grid item xs={5}>
              <TextField
                type="text"
                name="bic"
                label={t('invoices.BIC')}
                placeholder={t('invoices.BIC')}
                className={classes.textFieldWide}
              />
            </Grid> */}

            <Grid item container xs={12} justify="flex-end">
              {closeDialog && (
                <AppButton
                  color="transparentGrey"
                  text={t('cancel')}
                  onClick={closeDialog}
                />
              )}

              {this.props.isTreezorForm ? null : (
                <AppButton
                  color="secondaryLight"
                  text={
                    this.props.id
                      ? t('draft')
                      : t('settings_section.users.invite')
                  }
                  noBorder={true}
                  type="button"
                  onClick={this.onSubmit}
                />
              )}
            </Grid>
          </Grid>
        </Form>
      </div>
    );
  }
}

UserProfile.propTypes = {
  classes: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  closeDialog: PropTypes.func,
  status: PropTypes.object,
  values: PropTypes.object.isRequired,
  t: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  users: PropTypes.object,
  companyAccessRights: PropTypes.object,
  loggedUser: PropTypes.object,
  id: PropTypes.string,
  isSubmitting: PropTypes.bool,
  handleSubmit: PropTypes.func,
  isTreezorForm: PropTypes.bool,
};

const UserProfileWithFormik = withFormik({
  displayName: 'UserForm',
  enableReinitialize: true,
  mapPropsToValues,
  validationSchema,
  handleSubmit,
})(UserProfile);

const UserProfileWithStyles = withStyles(styles)(UserProfileWithFormik);

const TranslatedUserProfileWithStyles = withTranslation()(
  UserProfileWithStyles
);

export default connect(state, dispatch)(TranslatedUserProfileWithStyles);
