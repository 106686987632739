/* eslint-disable no-unused-vars */
import { setGlobalError } from 'actions/GlobalError';

import { getFileExtension } from 'helpers/utils';
import { queryStringHelper } from 'helpers/querystringHelper';

import S3FileService from 'services/S3FileService';
import PDFService from 'services/PDFService';

export const getOriginalById = (originalId) => async (dispatch, getState) => {
  try {
    const {
      loggedUserCompany: {
        company: { _id: companyId },
      },
      loggedUser: {
        user: { _id: userId },
      },
    } = getState();
    const result = await PDFService.getPDFOriginal(
      companyId,
      userId,
      originalId
    );

    const response = result.data.data;
    return response;

    // TODO: call api to get s3 key of pdf by id
  } catch (error) {
    dispatch(setGlobalError(error));
    return false;
  }
};

export const uploadPDF = (file) => async (dispatch, getState) => {
  try {
    const {
      loggedUserCompany: { company },
      loggedUser: {
        user: { _id: userId },
      },
    } = getState();

    const ext = getFileExtension(file.name);
    const folder = `company-files-${company.alternativeId}`;
    const fileName = `${userId}-${new Date().toISOString()}.${ext}`;

    await S3FileService.upload(file, folder, fileName);
    return `${folder}/${fileName}`;
  } catch (error) {
    dispatch(setGlobalError(error));
    return false;
  }
};

export const getPDFFile = (fileKey) => async (dispatch, getState) => {
  try {
    const { url, type } = await S3FileService.geturl(fileKey);
    return url;
  } catch (error) {
    dispatch(setGlobalError(error));
    return false;
  }
};

export const extractPDF = (values) => async (dispatch, getState) => {
  try {
    const {
      loggedUserCompany: {
        company: { _id: companyId, alternativeId },
      },
      loggedUser: {
        user: { _id: userId },
      },
    } = getState();

    // TODO: call api to send s3 key and array indice
    const result = await PDFService.splitPDF(companyId, userId, {
      ...values,
      alternativeId,
    });
    const {
      splitFileInfo: {
        splitFileKey,
        splitPDFPoolId: originalOId,
        _id: splitId,
      },
    } = result.data;

    return { originalOId, splitFileKey, splitId };
  } catch (error) {
    dispatch(setGlobalError(error));
    return false;
  }
};

export const getAllPDFOriginal = (query) => async (dispatch, getState) => {
  try {
    const {
      loggedUserCompany: {
        company: { _id: companyId },
      },
      loggedUser: {
        user: { _id: userId },
      },
    } = getState();

    const qs = queryStringHelper({ ...query });

    const result = await PDFService.getAllPDFOriginal(
      companyId,
      userId,
      qs.querystring
    );

    const { docs, page, totalDocs } = result.data.data;

    return {
      data: docs,
      page: page - 1,
      totalCount: totalDocs,
    };
  } catch (error) {
    dispatch(setGlobalError(error));
    return false;
  }
};

export const getPDFHistory =
  (originalId, query) => async (dispatch, getState) => {
    try {
      const {
        loggedUserCompany: {
          company: { _id: companyId },
        },
        loggedUser: {
          user: { _id: userId },
        },
      } = getState();
      const qs = queryStringHelper({ ...query });

      const result = await PDFService.getPDFHistory(
        companyId,
        userId,
        originalId,
        qs.querystring
      );

      const { docs, page, totalDocs } = result.data.data;
      return {
        data: docs,
        page: page - 1,
        totalCount: totalDocs,
      };
    } catch (error) {
      dispatch(setGlobalError(error));
      return false;
    }
  };

export const renameOriginalFile =
  (splitPDFPoolId, customFileName) => async (dispatch, getState) => {
    try {
      const result = await PDFService.renameOriginalFile({
        splitPDFPoolId,
        customFileName,
      });
      return result;
    } catch (error) {
      dispatch(setGlobalError(error));
      return false;
    }
  };

export const renameSplitFile =
  (splitPDFHistoryId, customFileName) => async (dispatch, getState) => {
    try {
      const result = await PDFService.renameSplitFile({
        splitPDFHistoryId,
        customFileName,
      });
      return result;
    } catch (error) {
      dispatch(setGlobalError(error));
      return false;
    }
  };

export const archiveSplitPDFHistoryFile =
  ({ splitPDFHistoryId, isArchived }) =>
  async (dispatch, getState) => {
    try {
      const result = await PDFService.archiveHistoryFile({
        splitPDFHistoryId,
        isArchived,
      });
      return result;
    } catch (error) {
      dispatch(setGlobalError(error));
      return false;
    }
  };

export const markAsDonePDFFile =
  ({ splitPDFPoolId, markAsDone }) =>
  async (dispatch, getState) => {
    try {
      const result = await PDFService.markAsDone({
        splitPDFPoolId,
        markAsDone,
      });
      return result;
    } catch (error) {
      dispatch(setGlobalError(error));
      return false;
    }
  };

export const getDepositPoolItems = (userId, query) => async (dispatch) => {
  try {
    const qs = queryStringHelper({ ...query });
    const result = await PDFService.getDepositPoolItems(userId, qs.querystring);
    const { docs, page, totalDocs } = result.data.data;
    return {
      data: docs,
      page: page - 1,
      totalCount: totalDocs,
    };
  } catch (exception) {
    dispatch(setGlobalError(exception));
    return false;
  }
};
