export default () => {
  return {
    userName: '',
    firstname: '',
    email: '',
    phonenumber: '',
    business_name: '',
    address: '',
    siren: '',
    nic: '',
    code_ape: '',
    city: '',
    zipcode: '',
    legal_form: '',
    title: '',
    companyType: null,
    isContractor: true,
  };
};
