import _ from 'lodash';
import clientProjectService from '../../services/ClientProjectService';

import {
  FETCHING_CLIENT_PROJECTS,
  FETCH_CLIENT_PROJECTS,
  FETCH_CLIENT_PROJECTS_FAILED,
  CREATE_CLIENT_PROJECT,
  UPDATE_CLIENT_PROJECT,
  DELETE_CLIENT_PROJECT,
} from '../Types';

import {
  userCanReadProject,
  userCanCreateProject,
  userCanUpdateProject,
  userCanDeleteProject,
} from '../../selectors/rightsSelector/projectRequests';

import { selectLoggedUserCompanyId } from '../../selectors/loggedUserCompanySelectors';

import { setGlobalError } from '../GlobalError';

export const fetchClientProjects = (cb) => async (dispatch, getState) => {
  if (!userCanReadProject(getState())) {
    dispatch({
      type: FETCH_CLIENT_PROJECTS,
      payload: {},
    });
    return;
  }

  const companyId = selectLoggedUserCompanyId(getState());
  if (!companyId) {
    dispatch({
      type: FETCH_CLIENT_PROJECTS,
      payload: {},
    });
    return;
  }

  dispatch({ type: FETCHING_CLIENT_PROJECTS });
  try {
    const projects = (
      await clientProjectService.fetchClientProjects({
        company_id: companyId,
      })
    ).data;
    dispatch({
      type: FETCH_CLIENT_PROJECTS,
      payload: _.groupBy(projects, 'client_id'),
    });
    if (typeof cb === 'function') {
      cb(null, projects);
    }
  } catch (error) {
    dispatch(setGlobalError(error));
    dispatch({ type: FETCH_CLIENT_PROJECTS_FAILED, payload: error });
  }
};

export const createClientProject =
  (project, cb) => async (dispatch, getState) => {
    try {
      if (!userCanCreateProject(getState())) {
        cb();
        return;
      }

      // eslint-disable-next-line no-param-reassign
      project.company_id = selectLoggedUserCompanyId(getState());

      const response = (await clientProjectService.createClientProject(project))
        .data;
      dispatch({ type: CREATE_CLIENT_PROJECT, payload: response });
      cb(null, response);
    } catch (error) {
      cb(error);
    }
  };

export const updateClientProject =
  (project, cb) => async (dispatch, getState) => {
    try {
      if (!userCanUpdateProject(getState())) {
        cb();
        return;
      }

      await clientProjectService.editClientProject(project);
      dispatch({ type: UPDATE_CLIENT_PROJECT, payload: project });
      cb(null, project);
    } catch (error) {
      cb(error);
    }
  };

export const deleteClientProject =
  (clientId, id, cb) => async (dispatch, getState) => {
    try {
      if (!userCanDeleteProject(getState())) {
        cb();
        return;
      }

      await clientProjectService.deleteClientProject(id);
      dispatch({ type: DELETE_CLIENT_PROJECT, payload: { clientId, id } });
      cb(null);
    } catch (error) {
      cb(error);
    }
  };
