import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Error as ErrorIcon } from '@material-ui/icons';

import style from 'assets/jss/components/errorMessage';

const useStyles = makeStyles(() => style);

/**
 * ErrorMessage
 *
 * @param {*} props
 *
 * - showError display (true) or hide (false, default) the error message
 * - error is the error message is not required but would be silly to forget when showError is true !
 */
const ErrorMessage = ({ showError = false, error }) => {
  const classes = useStyles();
  if (!showError) {
    return null;
  }
  return (
    <span className={classes.root}>
      <ErrorIcon fontSize="small" />
      &nbsp;{error}
    </span>
  );
};

ErrorMessage.propTypes = {
  classes: PropTypes.object,
  error: PropTypes.string,
  showError: PropTypes.bool,
};

export default ErrorMessage;
