/* Packages */
import { Box, Grid, Icon, Paper, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AppButton from 'components/AppButton';
import { AppDividerXS } from 'components/AppDividers';

/* Actions */
import { fetchWallet } from 'actions/WalletActions';

/* Icons */
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

/* Local Components */
import BankBBFAccountBalance from '../BankBBFAccountBalance';
import BankBBFAccountInformation from '../BankBBFAccountInformation';
import BankBBFAccountTable from '../BankBBFAccountTable';
import BankBBFAccountAction from '../BankBBFAccountAction';
import BandBBFAccountRIBModal from '../BankBBFAccountRIBModal';
import BankBBFAccountPDFGenerate from '../BankBBFAccountPDFGenerate';
import ShowHideVisibilityIcon from '../ShowHideVisibilityIcon';
import BankBBFDepositCheck from '../BankBBFDepositCheck';
import BankBBFChequeTable from '../BankBBFDepositCheck/BankBBFChequeTable';
import BankBBFDepositCheckIntro from '../BankBBFDepositCheck/BankBBFDepositCheckIntro';

/* Styles */
import useStyles from './styles';

import BankBBFAccountDebitManagement from '../BankBBFAccountDebitManagement';
import BankBBFDebitCreator from '../BankBBFDebitCreator';
import DebitCreationFirstStep from '../BankBBFDebitCreator/FirstStep';

function BankBBFAccountsBody() {
  const sections = [
    'DEFAULT',
    'DEPOSIT_INTRO',
    'DEPOSIT_TABLE',
    'DEPOSIT',
    'DEBIT',
    'DEBIT_TABLE',
    'DEBIT_STEPPER',
  ];

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  const [isRIBModalOpen, setIsRIBModalOpen] = useState(false);
  const [isPDFGenerateModalOpen, setIsPDFGenerateModalOpen] = useState(false);
  const [hideField, setHideField] = useState({
    accountInfo: true,
    balance: true,
  });
  const [sectionOpen, setOpenSection] = useState('DEFAULT');

  const handleRIBModalOpen = () => setIsRIBModalOpen(true);
  const handleRIBModalClose = () => setIsRIBModalOpen(false);
  const handlePDFGenerateModalOpen = () => setIsPDFGenerateModalOpen(true);
  const handlePDFGenerateModalClose = () => setIsPDFGenerateModalOpen(false);
  const handleDefaultSectionOpen = () => setOpenSection('DEFAULT');
  const handleDepositSectionOpen = () => setOpenSection('DEPOSIT_INTRO');
  const handleDepositStepperOpen = () => setOpenSection('DEPOSIT');
  const handleDepositTableOpen = () => setOpenSection('DEPOSIT_TABLE');
  const handleDebitTableOpen = () => setOpenSection('DEBIT_TABLE');
  const handleDebitCreatorOpen = () => setOpenSection('DEBIT');
  const handleDebitCreationStepperOpen = () => setOpenSection('DEBIT_STEPPER');

  const treezorWallet = useSelector((state) =>
    state.wallet && state.wallet.wallet ? state.wallet.wallet[0] : null
  );
  const banks = useSelector(
    (state) => state.loggedUserCompany.company.bank_details
  );

  useEffect(() => {
    if (!treezorWallet) {
      dispatch(fetchWallet());
    }
  }, [treezorWallet]);

  const handleChangeHideField = (key) => {
    setHideField({ ...hideField, [key]: !hideField[key] });
  };

  const renderSectionBody = () => {
    switch (sectionOpen) {
      case sections[1]: {
        return (
          <BankBBFDepositCheckIntro
            showTableDeposit={handleDepositTableOpen}
            showCreateCheque={handleDepositStepperOpen}
          />
        );
      }
      case sections[2]: {
        return (
          <BankBBFChequeTable
            openDepositCreateHandler={handleDepositStepperOpen}
            treezorWallet={treezorWallet}
          />
        );
      }
      case sections[3]: {
        return (
          <BankBBFDepositCheck
            handleReset={handleDepositSectionOpen}
            handleExit={handleDepositTableOpen}
          />
        );
      }
      case sections[4]: {
        return (
          <BankBBFDebitCreator
            handleOpenNewDebit={handleDebitCreationStepperOpen}
            handleOpenTableDebit={handleDebitTableOpen}
          />
        );
      }
      case sections[5]: {
        return <BankBBFAccountDebitManagement />;
      }
      case sections[6]: {
        return (
          <DebitCreationFirstStep
            handleNoButton={handleDefaultSectionOpen}
            handleYesButton={handleDebitCreatorOpen}
          />
        );
      }
      default: {
        return <BankBBFAccountTable treezorWallet={treezorWallet} />;
      }
    }
  };

  return (
    <>
      {treezorWallet && (
        <>
          <Grid container className={classes.root} spacing={2}>
            <Grid item xs={4}>
              <Paper className={classes.cardWrapper} elevation={3}>
                <Box className={classes.cardTitleWrapper}>
                  <AccountCircleIcon
                    color="primary"
                    className={classes.cardTitleIcon}
                  />
                  <Typography
                    variant="h3"
                    color="primary"
                    className={classes.cardTitle}
                  >
                    {t('bank_bbf.your_account_information')}
                  </Typography>
                  <ShowHideVisibilityIcon
                    hide={hideField.accountInfo}
                    handleChangeHideField={() =>
                      handleChangeHideField('accountInfo')
                    }
                  />
                </Box>
                <BankBBFAccountInformation
                  hide={hideField.accountInfo}
                  treezorWalletId={treezorWallet.walletId}
                />
                <AppDividerXS />
                <AppButton
                  text={t('bank_bbf.show_rib_iban')}
                  onClick={handleRIBModalOpen}
                  noBorder
                />
              </Paper>
            </Grid>
            <Grid item xs={8}>
              <Paper className={classes.cardWrapper} elevation={3}>
                <BankBBFAccountBalance
                  treezorWallet={treezorWallet}
                  hide={hideField.balance}
                  handleChangeHideField={() => handleChangeHideField('balance')}
                />
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.cardWrapper} elevation={3}>
                <Box className={classes.cardTitleWrapper}>
                  <Icon className={`${classes.cardTitleIcon} fas fa-rocket`} />
                  <Typography
                    variant="h3"
                    color="primary"
                    className={classes.cardTitle}
                  >
                    {t('bank_bbf.actions')}
                  </Typography>
                </Box>
                <BankBBFAccountAction
                  treezorWalletId={treezorWallet.walletId}
                  handleDefaultSectionOpen={handleDefaultSectionOpen}
                  handlePDFGenerateModalOpen={handlePDFGenerateModalOpen}
                  handleDepositSectionOpen={handleDepositSectionOpen}
                  handleDebitManagementSectionOpen={handleDebitTableOpen}
                  handleDebitStepperSectionOpen={handleDebitCreationStepperOpen}
                />
              </Paper>
            </Grid>
            <Grid item xs={8}>
              {renderSectionBody()}
            </Grid>
          </Grid>

          {/* Modal */}
          <BandBBFAccountRIBModal
            isRIBModalOpen={isRIBModalOpen}
            handleRIBModalClose={handleRIBModalClose}
            banks={banks}
          />
          <BankBBFAccountPDFGenerate
            isPDFGenerateModalOpen={isPDFGenerateModalOpen}
            handlePDFGenerateModalClose={handlePDFGenerateModalClose}
            treezorWallet={treezorWallet}
          />
        </>
      )}
    </>
  );
}

export default BankBBFAccountsBody;
