import { useState, useCallback } from 'react';
import { validator } from 'utils/validate';
import { xlsxToJson } from 'helpers/utils';

export class Client {
  constructor(index, row, isCompany, t, isReAssign = false) {
    if (!isCompany) {
      Client.keys = [
        'title',
        'name',
        'is_prospect',
        'description',
        'address',
        'zipcode',
        'city',
        'country',
        'phone',
        'email',
        'iban',
        'bic',
        'clientHasDifferentBillingAddress',
        'deliveryAddress',
        'deliveryZipcode',
        'deliveryCity',
        'deliveryCountry',
      ];
    } else {
      Client.keys = [
        'name',
        'description',
        'is_prospect',
        'address',
        'zipcode',
        'city',
        'country',
        'title',
        'contact',
        'phone',
        'email',
        'iban',
        'bic',
        'siret',
        'isVAT',
        'intracom_vat',
        'clientHasDifferentBillingAddress',
        'deliveryAddress',
        'deliveryZipcode',
        'deliveryCity',
        'deliveryCountry',
      ];
    }

    this.index = index;

    this.isCompany = isCompany;

    // value selected in the table
    this.isSelected = false;
    if (isReAssign) {
      Client.keys.forEach((key) => {
        this[key] = row[key];
      });
      this.isValidated = row.isValidated;
      this.nomDuContact = row.nomDuContact;
      this.adresses = row.adresses;
      this.is_prospect = row.is_prospect
        ? t('settings_section.clients.yes')
        : t('settings_section.clients.no');
    } else {
      Client.keys.forEach((key, i) => {
        this[key] =
          row[i] && typeof row[i] === 'string' ? row[i].trim() : row[i];
      });
      this.adresses = [
        {
          address: this.deliveryAddress || '',
          zipcode: this.deliveryZipcode || '',
          city: this.deliveryCity || '',
          country: this.deliveryCountry || '',
        },
      ];
    }

    if (
      !Object.values(this.adresses).some((item) => item !== '') ||
      this.clientHasDifferentBillingAddress === 'Non' ||
      this.clientHasDifferentBillingAddress === 'NO'
    ) {
      this.clientHasDifferentBillingAddress = false;
    } else if (
      this.clientHasDifferentBillingAddress === 'Oui' ||
      this.clientHasDifferentBillingAddress === 'YES'
    ) {
      this.clientHasDifferentBillingAddress = true;
    }

    if (this.civility && this.nomDuContact) {
      this.contactName = [this.civility, this.nomDuContact].join(' ');
      this.title = '';
    }
  }

  static toJson(data) {
    const dataSubmit = { ...data };
    if (dataSubmit.is_prospect === 'Oui' || dataSubmit.is_prospect === true) {
      dataSubmit.is_prospect = true;
    } else {
      dataSubmit.is_prospect = false;
    }
    return dataSubmit;
  }

  get isValidated() {
    return this._isValidated;
  }

  set isValidated(value) {
    this._isValidated = !!value;
  }

  get nomDuContact() {
    return this._nomDuContact;
  }

  set nomDuContact(value) {
    this._nomDuContact = value;
  }

  toggleSelected() {
    this.isSelected = !this.isSelected;
  }

  /**
   * @param {boolean} value
   */
  setSelected(value) {
    this.isSelected = !!value;
  }
}

const useImportClients = (isCompany, t) => {
  const [clientList, setClientList] = useState([]);
  const checkValidateData = useCallback(
    (dataInput = [], isSetData = true) => {
      let _validator = {};
      if (isCompany) {
        _validator = {
          siret: [validator.siret, validator.unique],
          name: [validator.required],
          address: [validator.required],
          zipcode: [validator.required],
          city: [validator.required],
          country: [validator.required],
          phone: [validator.phone],
          email: [validator.required, validator.email],
          title: [validator.required],
          contact: [validator.required],
          iban: [validator.iban],
        };
      } else {
        _validator = {
          name: [validator.required],
          address: [validator.required],
          zipcode: [validator.required],
          city: [validator.required],
          country: [validator.required],
          phone: [validator.phone],
          email: [validator.required, validator.email],
          title: [validator.required],
          iban: [validator.iban],
        };
      }

      const dataValidated = [];
      dataInput.forEach((item) => {
        let isValidated = true;
        Object.keys(_validator).forEach((key) => {
          const value = item[key];
          // eslint-disable-next-line no-bitwise
          isValidated &= _validator[key].every((func) => {
            if (func.name === 'validateUnique') {
              return func(value, dataValidated, key);
            }
            return func(value);
          });
        });
        // eslint-disable-next-line no-param-reassign
        item.isValidated = !!isValidated;
        // eslint-disable-next-line no-param-reassign
        item.nomDuContact = [item.title, item.name].join(' ');
        if (isValidated) {
          dataValidated.push(item);
        }
      });

      if (isSetData) {
        const dataUpdate = dataInput.map((client) => {
          return new Client(client.index, client, client.isCompany, t, true);
        });
        setClientList(dataUpdate);
      }
    },
    [clientList, setClientList]
  );

  const onFileChange = async (files) => {
    if (
      files &&
      files.length &&
      files[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ) {
      const jsonData = await xlsxToJson(files[0]);
      if (!jsonData.length) {
        return [];
      }

      const handleMapKeys = (data) => {
        return data.map((item, idx) => new Client(idx, item, isCompany, t));
      };

      const dataResult = handleMapKeys(jsonData);
      checkValidateData(dataResult, false);
      setClientList(dataResult);
      return dataResult;
    }
    return null;
  };

  return {
    onFileChange,
    clients: { clientList, setClientList },
    checkValidateData,
  };
};

export default useImportClients;
