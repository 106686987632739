/* eslint-disable import/prefer-default-export */
export const getMonthLabels = (t) => [
  t('turn_over.months.jan'),
  t('turn_over.months.feb'),
  t('turn_over.months.mar'),
  t('turn_over.months.apr'),
  t('turn_over.months.may'),
  t('turn_over.months.jun'),
  t('turn_over.months.jul'),
  t('turn_over.months.aug'),
  t('turn_over.months.sep'),
  t('turn_over.months.oct'),
  t('turn_over.months.nov'),
  t('turn_over.months.dec'),
];
