import { mapKeys } from 'lodash';

import {
  FETCHING_REVENUES,
  FETCH_REVENUES_FAILED,
  FETCH_REVENUES_SUCCESS,
  FETCHING_REVENUE,
  FETCH_REVENUE_SUCCESS,
  FETCH_REVENUE_FAILED,
  CREATING_REVENUE,
  CREATE_REVENUE_SUCCESS,
  CREATE_REVENUE_FAILED,
  CLEAR_REVENUE,
  LOGOUT_USER_SUCCESS,
  LOGGED_USER_COMPANY_SELECTED,
  FETCHING_REVENUE_OVERVIEW,
  FETCH_REVENUE_OVERVIEW_SUCCESS,
  FETCH_REVENUE_OVERVIEW_FAILED,
} from 'actions/Types';

import logger from 'helpers/logger';

const INITIAL_STATE = {
  loading: false, // same boolean for  fetchAll, fetch by id, and create...
  error: null, // same for fetchAll, fetch by id, and create...
  timestamp: null, // for fetchAll
  data: {}, // all
  revenue: null, // one found by id or created
  overview: {
    loading: false,
    data: null,
    error: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCHING_REVENUES:
    case FETCHING_REVENUE:
    case CREATING_REVENUE:
      // Mark the state as "loading" so we can show a spinner or something
      // Also, reset any errors. We're starting fresh.
      return {
        ...state,
        loading: true,
        error: null,
        revenue: null,
      };

    case FETCH_REVENUES_SUCCESS: {
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      const { revenues } = action.payload;
      const data = mapKeys(revenues, '_id');
      return {
        ...state,
        loading: false,
        error: null,
        data,
        timestamp: Date.now(),
      };
    }

    case FETCH_REVENUES_FAILED:
    case FETCH_REVENUE_FAILED:
    case CREATE_REVENUE_FAILED: {
      // The request failed, but it did stop, so set loading to "false".
      // Save the error, and we can display it somewhere
      // Since it failed, we don't have items to display anymore, so set it empty.
      const { error } = action.payload;
      logger.error('xx_REVENUEx_FAILED', error);

      return {
        ...state,
        loading: false,
        error,
        revenue: null,
      };
    }

    case CREATE_REVENUE_SUCCESS:
    case FETCH_REVENUE_SUCCESS: {
      // All done: set loading "false".
      // Also, replace the items with the ones from the server
      const { revenue } = action.payload;
      const id = revenue._id;
      // TODO: add the new evenue in data
      return {
        ...state,
        loading: false,
        error: null,
        revenue,
        data: { ...state.data, [id]: revenue },
      };
    }

    case CLEAR_REVENUE:
      return {
        ...state,
        loading: false,
        error: null,
        revenue: null,
      };
    case FETCHING_REVENUE_OVERVIEW:
      return {
        ...state,
        overview: {
          loading: true,
          data: null,
          error: null,
        },
      };
    case FETCH_REVENUE_OVERVIEW_SUCCESS:
      return {
        ...state,
        overview: {
          loading: false,
          data: action.payload,
          error: null,
        },
      };
    case FETCH_REVENUE_OVERVIEW_FAILED:
      return {
        ...state,
        overview: {
          loading: false,
          data: null,
          error: action.payload.error,
        },
      };
    case LOGOUT_USER_SUCCESS:
    case LOGGED_USER_COMPANY_SELECTED:
      return INITIAL_STATE;

    default:
      // ALWAYS have a default case in a reducer
      return state;
  }
};
