import AppDialog from 'components/AppDialog';
import { Formik } from 'formik';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as yup from 'yup';
import { Grid } from '@material-ui/core';
import TextField from 'components/FormElements/textField';
import AppButton from 'components/AppButton';

const validationSchema = ({ t }) =>
  yup.object().shape({
    refuseMessage: yup.string().required(t('forms.mandatory_field')),
  });

const CancelExpenseFormDialog = ({
  showDialog,
  closeFormDialog,
  handleSubmitCancel,
}) => {
  const { t } = useTranslation();

  if (!showDialog) {
    return null;
  }

  return (
    <AppDialog
      title={t('invoice.confirm.reject')}
      closeDialog={closeFormDialog}
      onConfirmType="submit"
      onConfirmText="submit"
      iconClose
      color="secondary"
    >
      <Formik
        initialValues={{
          refuseMessage: '',
        }}
        validationSchema={validationSchema({ t })}
        onSubmit={(values, { setSubmitting }) => {
          handleSubmitCancel({ refuseMessage: values?.refuseMessage });
          setSubmitting(false);
        }}
      >
        {({ handleSubmit, errors, isSubmitting }) => {
          return (
            <form onSubmit={handleSubmit} className="section p-10">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    type="text"
                    required
                    name="refuseMessage"
                    label={t('expenses.expense.form_reason_reject')}
                    placeholder={t('expenses.expense.form_reason_reject')}
                    multiline
                    rows={2}
                  />
                </Grid>

                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <AppButton
                    type="submit"
                    color="secondaryLight"
                    text={t('submit')}
                    noBorder={true}
                    isDisabled={
                      (errors && Object.keys(errors).length) || isSubmitting
                    }
                  />
                </Grid>
              </Grid>
            </form>
          );
        }}
      </Formik>
    </AppDialog>
  );
};

CancelExpenseFormDialog.propTypes = {
  showDialog: PropTypes.bool,
  closeFormDialog: PropTypes.func,
  handleSubmitCancel: PropTypes.func.isRequired,
};

export default CancelExpenseFormDialog;
