import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useRouter } from '../../../hooks/routerHooks';

import Header from '../../../components/Header';
import Layout from '../../../components/Layout';
import CardRouter from '../CardRouter';

const CardContainer = ({ virtual, ...props }) => {
  const { history } = useRouter();
  const { t } = useTranslation();
  const renderBody = () => {
    return (
      <div>{<CardRouter virtual={virtual} history={history} {...props} />}</div>
    );
  };
  return (
    <Layout
      header={
        <Header
          name={t('card')}
          goBack={() => {
            history.goBack();
          }}
        />
      }
      sidebarLeft={true}
      sidebarRight={null}
      showUserCard={true}
      body={renderBody()}
    />
  );
};

CardContainer.propTypes = {
  virtual: PropTypes.boolean,
  props: PropTypes.object,
};

export default CardContainer;
