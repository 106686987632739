import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import IconButton from '@material-ui/core/IconButton';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

import * as dateFns from 'date-fns';
import FileSaver from 'file-saver';
import FecService from 'services/FecService';

import { debounceFn } from '../../../helpers/utils';

const DownloadExport = ({ value, setErrorMessage }) => {
  const [disabled, setDisabled] = useState(false);
  const { t } = useTranslation();

  function downloadFile(data) {
    if (!data) {
      setErrorMessage(t('book_keeping.download.error_message'));
      return 0;
    }

    const fileName = `${dateFns.format(
      new Date(),
      'yyyyMMdd'
    )}_${new Date().getTime()}.zip`;

    return FileSaver.saveAs(
      new Blob([data], { type: 'application/zip' }),
      fileName
    );
  }

  function onSubmit() {
    setDisabled(true);

    FecService.downloadExport(value._id)
      .then((res) => {
        if (res.data.error) {
          setErrorMessage(t('book_keeping.export.error_message'));
        } else {
          downloadFile(res.data);
        }
      })
      .catch(() => {
        setErrorMessage(t('book_keeping.download.error_message'));
      });
  }

  return (
    <IconButton onClick={debounceFn(onSubmit)} disabled={disabled}>
      <CloudDownloadIcon />
    </IconButton>
  );
};

DownloadExport.propTypes = {
  fileName: PropTypes.string,
  setErrorMessage: PropTypes.func,
  value: PropTypes.object,
};

export default DownloadExport;
