import { Template as Rights } from '../../config/Rights';
import { Template as Feature } from '../../config/Features';

import {
  hasRequestedAccess,
  hasRequestedAccessFeature,
} from '../../helpers/RoleHelper';

export const userCanCreateTemplate = () => {
  return hasRequestedAccess(Rights.TemplateCreate);
};

export const userCanReadTemplate = () => {
  return hasRequestedAccess(Rights.TemplateRead);
};

export const userCanUpdateTemplate = () => {
  return hasRequestedAccess(Rights.TemplateUpdate);
};

export const userCanDeleteTemplate = () => {
  return hasRequestedAccess(Rights.TemplateDelete);
};

export const userHasTemplateAccessFeature = () => {
  return hasRequestedAccessFeature(Feature);
};
