import { format } from 'date-fns';
import legalFormArray from 'assets/legalForm';
import { find, mapKeys } from 'lodash';
import { ASSOCIE, EMPLOYE, REPRESENTANT_LEGAL } from 'constants/roles';
import {
  TreezorDocumentTypes,
  KYCStatus,
} from '@bebusinessfocus/compta-hub-core';
import {
  CARDS,
  REFUSED,
  DOCS_NEEDED,
  TREEZOR_PENDING,
  LOADING,
} from 'constants/treezor';
import {
  TYPE_AUTO_ENTREPRENEUR,
  TYPE_ENTREPRENEUR_INDIVIDUEL,
} from 'constants/company';

// eslint-disable-next-line import/prefer-default-export
export const getInvalidDocument = (treezorUsers) => {
  const docs = [];
  if (treezorUsers) {
    treezorUsers.forEach((treezorUser) => {
      const { documents } = treezorUser;
      if (documents) {
        documents.forEach((doc, index) => {
          if (doc.status === 3) {
            // eslint-disable-next-line no-param-reassign
            doc.index = index;
            // eslint-disable-next-line no-param-reassign
            doc.treezorUserID = treezorUser._id;
            docs.push(doc);
          }
        });
      }
    });
  }
  return docs;
};

const createArrayOfDocuments = (objectDocuments) => {
  const documents = [];
  // eslint-disable-next-line no-restricted-syntax
  for (const values of Object.values(objectDocuments)) {
    if (values.type !== null && values.fileName !== null) {
      documents.push({
        type: values.type,
        filename: values.fileName,
        codeBBF: values.codeBBF,
      });
    }
  }

  return documents;
};

const getControllingPersonType = (userRole, companyType) => {
  if (companyType === TYPE_AUTO_ENTREPRENEUR) {
    return undefined;
  }
  if (companyType === TYPE_ENTREPRENEUR_INDIVIDUEL) {
    return 3;
  }
  if (userRole === ASSOCIE) {
    return 1;
  }
  if (userRole === REPRESENTANT_LEGAL) {
    return 3;
  }
  return undefined;
};

const getParentType = (userRole, companyType) => {
  if (companyType === TYPE_AUTO_ENTREPRENEUR) {
    return undefined;
  }
  if (companyType === TYPE_ENTREPRENEUR_INDIVIDUEL) {
    return 'leader';
  }
  switch (userRole) {
    case ASSOCIE:
      return 'shareholder';
    case REPRESENTANT_LEGAL:
      return 'leader';
    case EMPLOYE:
      return 'employee';
    default:
      return undefined;
  }
};

const getEmployeeType = (userRole) => {
  if (userRole === EMPLOYE) {
    return 2;
  }
  if (userRole === REPRESENTANT_LEGAL) {
    return 1;
  }
  return undefined;
};

export const prepareUsers = (users, companyType) => {
  const treezorUsers = [];
  users.forEach((u) => {
    // let userOId = null;
    // if (index === 0) {
    //   userOId = (loggedUser && loggedUser.user._id) || null;
    // } else if (u._id) {
    //   userOId = u._id;
    // }
    const user = {
      userOId: u._id,
      specifiedUSPerson: u.specifiedUSPerson,
      title: u.title,
      lastname: u.lastname,
      firstname: u.firstname,
      birthday: format(new Date(u.birthday), 'yyyy-MM-dd'),
      placeOfBirth: u.placeOfBirth,
      birthCountry: u.birthCountry.alpha2,
      nationality: u.nationality.alpha2,
      parentType: getParentType(u.userRole, companyType),
      email: u.email,
      phone: u.mobile,
      mobile: u.mobile,
      address1: u.address1,
      postcode: u.postcode,
      incomeRange: u.incomeRange,
      personalAssets: u.personalAssets,
      city: u.city,
      country: u.country?.alpha2,
      occupation: u.occupation || u.userRole,
      documents: u.documents ? createArrayOfDocuments(u.documents) : [],
      controllingPersonType: getControllingPersonType(u.userRole, companyType),
      employeeType: getEmployeeType(u.userRole),
      effectiveBeneficiary: u.effectiveBeneficiary,
      rattacheAPersonneMorale: u.rattacheAPersonneMorale,
      legalName: u.legalName ? u.legalName : undefined,
    };
    treezorUsers.push(user);
  });
  return treezorUsers;
};

export const prepareDataToCreateNewUser = (users) => {
  const user = {};
  user.firstname = users[0].firstname;
  user.name = users[0].lastname;
  user.role = [];
  user.description = users[0].occupation || users[0].userRole;
  user.phone = users[0].mobile;
  user.email = users[0].email;
  user.title = users[0].title;
  return user;
};

export const prepareData = (company, users, loggedUserCompany) => {
  const { companyType } = loggedUserCompany?.settings?.tax_registration;
  const data = {
    companyType,
    users: users.length > 0 ? prepareUsers(users, companyType) : [],
    company: {
      legalName: company.legalName,
      legalShareCapital: company.legalShareCapital,
      legalRegistrationNumber: company.legalRegistrationNumber,
      legalTvaNumber: company.legalTvaNumber,
      legalRegistrationDate: company.legalRegistrationDate,
      legalForm: find(legalFormArray, (e) => e.display === company.legalForm)
        .code,
      legalSector: company.legalSector.replace('.', ''),
      legalAnnualTurnOver: company.legalAnnualTurnOver,
      legalNetIncomeRange: company.legalNetIncomeRange,
      legalNumberOfEmployeeRange: company.legalNumberOfEmployeeRange,
      documents: createArrayOfDocuments(company.documents),
      address1: company.address1,
      postcode: company.postcode,
      city: company.city,
      country: company.country,
      email: company.email,
      phone: company.phone,
      activityOutsideEu: company.activityOutsideEu ? 1 : 0,
      economicSanctions: company.economicSanctions === 'yes' ? 1 : 0,
      residentCountriesSanctions:
        company.residentCountriesSanctions === 'yes' ? 1 : 0,
      involvedSanctions: company.involvedSanctions === 'yes' ? 1 : 0,
      entityType: 4,
    },
  };
  return data;
};

export const lookupTreezorDocumentTypeByCode = (codeBBF) => {
  const documentsTypes = [
    ...TreezorDocumentTypes.identityPrimaryList,
    ...TreezorDocumentTypes.identitySecondaryList,
    ...TreezorDocumentTypes.proofOfAddress,
    ...TreezorDocumentTypes.soleTraderRegistration,
    ...TreezorDocumentTypes.companyRegistration,
    ...TreezorDocumentTypes.independantRegistration,
    ...TreezorDocumentTypes.companyRegistrationSecondary,
    ...TreezorDocumentTypes.treezorDocumentTypes,
  ];
  if (codeBBF) {
    return documentsTypes.find((doc) => doc.codeBBF === codeBBF).type;
  }
  return null;
};

/**
 * To add a new document the status must be 3 (kycLevel = 1 && kycReview = 3)
 * All document must have a 'documentId' property
 * So we can add only one document because when the user add a new doc,
 *  the new document doesn't have the 'documentId' property
 * because it doesn't check by treezor.
 */
export const checkIfOneMoreDocumentIsRequired = (treezorUsers) => {
  for (let i = 0; i < treezorUsers.length; i += 1) {
    const tUser = treezorUsers[i];
    const status = KYCStatus.getKYCStatus(
      Number(tUser.kycLevel),
      Number(tUser.kycReview)
    );
    if (status === 3 && tUser.kycReviewComment !== '') {
      for (let y = 0; y < tUser.documents.length; y += 1) {
        // eslint-disable-next-line
        if (!tUser.documents[y].hasOwnProperty('documentId')) {
          return tUser;
        }
      }
    }
  }
  return null;
};

export const getScreenToDisplay = (status, companyStatus) => {
  // TODO TEST THIS CASE
  // if (
  //   treezorUsers &&
  //   treezorUsers.length > 0 &&
  //   isUsersDocumentsAreInvalid()
  // ) {
  //   return (
  //     <TreezorDocsNeed
  //       company={loggedUserCompany.company}
  //       treezorUsers={treezorUsers}
  //     />
  //   );
  // }
  if (
    companyStatus === 1 ||
    companyStatus === 2 ||
    companyStatus === 8 ||
    status === 1 ||
    status === 2 ||
    status === 8
  )
    return TREEZOR_PENDING;
  if (
    companyStatus === 3 ||
    companyStatus === 4 ||
    companyStatus === 7 ||
    status === 3 ||
    status === 4 ||
    status === 7
  )
    return DOCS_NEEDED;
  if (companyStatus === 5 && status === 5) return REFUSED;
  if (companyStatus === 6 && status === 6) return CARDS;
  return LOADING;
};

export const checkIfFieldIsDiplay = (errors, listOfFieds) => {
  let showError = null;
  mapKeys(errors, (value, key) => {
    if (!listOfFieds.includes(key)) {
      showError = value;
    }
  });
  return showError;
};

export const hasAtLeastOneUser = (treezorUsers) => {
  return treezorUsers.find((user) => user.userTypeId === '1');
};

export const getListOfUserWithoutUserAlreadySelected = (users, treezorUser) => {
  const userList = [];
  Object.values(users).forEach((user) => {
    if (!treezorUser.find((tUser) => tUser.userTag === user._id)) {
      userList.push(user);
    }
  });
  return userList;
};

export const combineIdentityOptions = () => {
  let identityOptions = [
    ...TreezorDocumentTypes.identityPrimaryList,
    ...TreezorDocumentTypes.identitySecondaryList,
  ];
  identityOptions = identityOptions.filter((identityOption) => {
    const { codeBBF } = identityOption;
    if (codeBBF !== 5 && codeBBF !== 6) {
      return identityOption;
    }
    return null;
  });
  return identityOptions;
};
